import { ExchangeID, SupportDWCoinInfo } from "TradeAPILib";
import { APIKeyInfo } from "TradeLib/TradeTypes";
import { apiKey2ExchangeApiKey, BalanceHelper, BalanceProvider, CoinBalance, ExchangeFullBalanceV2 } from "trade_asset_lib";
import { map } from "rxjs/operators";
import { useEffect, useMemo, useState } from "react";
import ExchangeDataProvider from "src/TradeLib/ExchangeDataProvider";
import { LocalStorageKey } from "src/utils/LocalStorageUtils";
import { EXPIRED_TYPE, getDataWithCache, SafeDecimal } from "trade_utils_lib";
import { useBalanceAll } from "./useBalance";
import useAccountInfo from "./useAccountInfo";
import { aggregateObservable } from "src/utils/RxTools";
import { QuotaChannel, QuotaCheckType, QuotaDirection, useRemainQuota } from "utils/complianceQuota";
import { DWType } from "landings/DepositWithdraw";

/**
 * TODO: 使用多播，确保多次订阅均使用同一个订阅源数据，且不会发起多次请求。
 */
const multiCaseDWCoinsReq = aggregateObservable<SupportDWCoinInfo[]>(() => {
    return getDataWithCache({
        dataKey: LocalStorageKey.KEY_BALANCE_DW_COINS,
        expireTime: EXPIRED_TYPE.NEVER,
        refresh: () =>
            ExchangeDataProvider.getPublicExchangeDataProvider(ExchangeID.PIONEXV2)
                .api.requestAllBrokerCoins()
                .pipe(
                    map((result) => {
                        const sortMap = {
                            BTC: 100,
                            USDT: 90,
                            ETH: 80,
                        };
                        return result.sort((a, b) => {
                            const aWeight = sortMap[a.coinName];
                            const bWeight = sortMap[b.coinName];
                            if (aWeight && bWeight) {
                                return bWeight - aWeight;
                            } else if (aWeight) {
                                return -1;
                            } else if (bWeight) {
                                return 1;
                            } else {
                                return a.coinName.localeCompare(b.coinName);
                            }
                        });
                    }),
                ),
    });
}, []);

// 获取充提币币种列表
export function useDWCoins() {
    const [loading, changeLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const [coinList, changeDataResult] = useState<SupportDWCoinInfo[]>([]);
    useEffect(() => {
        const subscription = multiCaseDWCoinsReq().subscribe(
            (result) => {
                changeLoading(false);
                changeDataResult(result);
            },
            (err) => {
                changeLoading(false);
                setError(err);
            },
        );
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    return [coinList, loading, error] as const;
}

// 获取单个可重提币种
export function useDWCoin(coin: string): [undefined | SupportDWCoinInfo, boolean] {
    const [onLoading, changeLoading] = useState(false);
    const [coinList, listLoading] = useDWCoins();
    const [targetCoin, updateCoin] = useState(undefined as undefined | SupportDWCoinInfo);
    useEffect(() => {
        if (coinList !== undefined) {
            updateCoin(coinList.find((item) => item.currency === coin));
            changeLoading(listLoading);
        }
    }, [coinList, listLoading, coin]);
    return [targetCoin, onLoading];
}

export interface IDWCoinBalance extends CoinBalance {
    canWithdraw?: boolean;
    canDeposit?: boolean;
}

export function useBalanceWithDW(): [Array<IDWCoinBalance> | undefined, boolean] {
    const accountInfo = useAccountInfo();
    const [onLoading, changeLoading] = useState(true);
    const [coinListBalance, updateCoinList] = useState(undefined as Array<IDWCoinBalance> | undefined);
    const [dwCoins, wdCoinsOnLoading] = useDWCoins();
    const fullBalance = useBalanceAll();
    useEffect(() => {
        let subscription;
        if (dwCoins !== undefined) {
            const apiKey = accountInfo.firstApiKey;
            if (!apiKey || dwCoins.length === 0) {
                return;
            }
            changeLoading(true);
            // 获取可充提币种列表
            subscription = BalanceProvider.instance
                .getBalanceWithApiKey(apiKey2ExchangeApiKey(apiKey))
                .pipe(
                    map((balanceInfo: ExchangeFullBalanceV2) => {
                        return BalanceHelper.getBalanceCurrencyOb(balanceInfo.trade).trade;
                    }),
                )
                .pipe(
                    map((data) => {
                        const balances: IDWCoinBalance[] = data.balances;
                        let result: IDWCoinBalance[] = [];
                        dwCoins.forEach((dwCoinInfo) => {
                            const findResult = balances.find((coinBalance, idx) => {
                                const has = coinBalance.base === dwCoinInfo.currency;
                                if (has) {
                                    balances.splice(idx, 1);
                                }
                                return has;
                            });
                            if (findResult) {
                                findResult.canWithdraw = true;
                                findResult.canDeposit = true;
                                result.push(findResult);
                            }
                        });
                        result = result.concat(balances);
                        return result;
                    }),
                )
                .subscribe(
                    (data) => {
                        updateCoinList(data);
                        changeLoading(false);
                    },
                    (err) => {
                        console.log(err);
                        changeLoading(false);
                    },
                );
        }
        return () => {
            subscription?.unsubscribe();
        };
    }, [dwCoins, wdCoinsOnLoading, fullBalance, accountInfo.firstApiKey]);
    return [coinListBalance, onLoading];
}

// 获取单个coin的balance数据
export function useDWCoinBalance(apiKey: APIKeyInfo | undefined, coin: string, type: DWType): [CoinBalance, boolean] {
    const [coinListBalance, onLoading] = useBalanceWithDW();
    const [coinBalance, updateBalance] = useState({} as CoinBalance);
    const { details, loading: remainQuotaLoading } = useRemainQuota(QuotaChannel.COIN, QuotaDirection.WITHDRAW);
    // T+N限制可用额度
    const tnQuota = useMemo(() => details.find((item) => item.checkType === QuotaCheckType.COINOUT_TPLUSN_QUOTA_CHECK), [details]);
    useEffect(() => {
        if (coinListBalance === undefined) {
            return;
        }
        if (remainQuotaLoading || !tnQuota) {
            return;
        }
        const target = coinListBalance.find((item) => item.base === coin) || ({} as CoinBalance);
        if (type === DWType.withdraw && !isNaN(target.USDPrice)) {
            // 出金需要和T+N可用额度对比取min
            const quotaDecimal = new SafeDecimal(tnQuota.quota).div(target.USDPrice); // 可用额度
            const deviationDecimal = quotaDecimal.sub(target.available).abs(); // 误差，因为后端计算可用额度时的汇率和前端拿到再换算回对应币的汇率可能有误差
            if (deviationDecimal.div(target.available).greaterThan(0.005)) {
                // 差值超过误差允许范围才做对比
                target.available = Math.min(target.available, quotaDecimal.toNumber());
            }
        }
        updateBalance(target);
    }, [coinListBalance, coin, apiKey, remainQuotaLoading, tnQuota, type]);
    return [coinBalance, onLoading];
}
