import { Card } from "landings/FeeAnnounce/Card";
import { Alert, Button, Spin } from "@pionex-web-kit/components";
import { IconSuccess20pxBoldS } from "@pionex-web-kit/icons";
import { history } from "utils/History";
import { useEffect, useState } from "react";
import useAccountInfo from "commonUse/useAccountInfo";
import { useDataProvider } from "commonUse";
import { ManualOrderStatus } from "TradeAPILib";
import { useTranslators } from "commonUse/Locale";
import { getAccountPathname } from ".";
import { botOrderDataRequest, OrderListStatus } from "landings/PionexHistoricalOrderDetails/borderOrderHelper";
import { timer } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { report } from "utils/GoogleTagManager";

export default function CloseOrder() {
    const { $st } = useTranslators();
    const accountInfo = useAccountInfo();
    const dataProvider = useDataProvider(accountInfo.firstApiKey);
    const [botLoading, setBotLoading] = useState(true);
    const [botOrdersCount, setBotOrdersCount] = useState(0);
    const [manualOrdersCount, setManualOrdersCount] = useState(0);
    const [manualLoading, setManualLoading] = useState(true);
    useEffect(() => {
        if (!dataProvider?.api) return;
        setManualLoading(true);
        const subscription = timer(0, 3000)
            .pipe(
                mergeMap(() => {
                    return dataProvider?.api.queryManualOrders({
                        limit: 20,
                        includeStrategy: false,
                        state: ManualOrderStatus.running,
                    });
                }),
            )
            .subscribe({
                next: (result) => {
                    setManualOrdersCount(result.length);
                    setManualLoading(false);
                    if (!result?.length) {
                        subscription.unsubscribe();
                    }
                },
                error: () => {},
                complete: () => {
                    setManualLoading(false);
                },
            });
        return () => subscription?.unsubscribe();
    }, [dataProvider?.api]);

    useEffect(() => {
        if (!dataProvider?.api) return;
        setBotLoading(true);
        const subscription = timer(0, 3000)
            .pipe(
                mergeMap(() => {
                    return botOrderDataRequest(dataProvider.api, false, OrderListStatus.running, undefined, undefined, "all");
                }),
            )
            .subscribe({
                next: (result) => {
                    // setBotOrdersCount(result?.orders?.filter((item) => item.state !== ExchangeOrderStatus.canceling).length || 0);
                    setBotOrdersCount(result?.orders?.length || 0);
                    setBotLoading(false);
                    if (!result?.orders?.length) {
                        subscription.unsubscribe();
                    }
                },
                error: () => {},
                complete: () => {
                    setBotLoading(false);
                },
            });
        return () => subscription?.unsubscribe();
    }, [dataProvider?.api]);

    useEffect(() => {
        if (!botLoading && !manualLoading && !manualOrdersCount && !botOrdersCount) {
            history.push(getAccountPathname("/account_update/upgrade"));
        }
    }, [botLoading, manualLoading, manualOrdersCount, botOrdersCount]);
    return (
        <div className=" w-full max-w-[448px] flex flex-col items-center">
            <Spin spinning={botLoading || manualLoading}>
                <div className=" text-center text-[28px] text-text-100 font-semibold mt-[50px] mb-40px">{$st("account_update")}</div>
                <div className=" text-base text-text-200 mb-24px">{$st("due_to_an_account_system_upgrade")}</div>
                <Alert
                    type="warning"
                    message={
                        <>
                            <li className="list-disc"> {$st("note_recreate")}</li>
                            <li className="list-disc">
                                Here is the upgrade guide{" "}
                                <a
                                    onClick={() => {
                                        report("account_update_link");
                                    }}
                                    style={{ color: "inherit", textDecoration: "underline" }}
                                    target="_blank"
                                    href="https://www.pionex.us/blog/guide-for-account-upgrade/"
                                >
                                    link
                                </a>
                                .
                            </li>
                            <li className="list-disc">
                                If you have any questions, feel free to contact 24/7{" "}
                                <a
                                    onClick={() => {
                                        report("account_update_support");
                                    }}
                                    style={{ color: "inherit", textDecoration: "underline" }}
                                    target="_blank"
                                    href="https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=pionexus.zendesk.com"
                                >
                                    customer support
                                </a>{" "}
                                or email service@pionex.us.
                            </li>
                        </>
                    }
                />

                <Card className="w-full mt-24px">
                    <div className=" flex flex-col gap-24px">
                        <div className="flex items-center justify-between">
                            <div className="flex flex-col flex-1 gap-4px">
                                <div className="text-base font-m text-text-100">Close manual orders</div>
                                <div className=" text-sm text-text-300">{manualOrdersCount > 10 ? `More than 10` : manualOrdersCount} orders are running</div>
                            </div>
                            {manualOrdersCount > 0 ? (
                                <Button
                                    onClick={() => {
                                        history.push(getAccountPathname("/account_update/close_manual_order"));
                                    }}
                                    type="primary"
                                    shape="round"
                                    className="ml-16px w-[90px]"
                                >
                                    Close
                                </Button>
                            ) : (
                                <div className="flex justify-center items-center gap-2px">
                                    <IconSuccess20pxBoldS /> Finished
                                </div>
                            )}
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex flex-col flex-1 gap-4px">
                                <div className="text-base font-m text-text-100">Close bot orders</div>
                                <div className=" text-sm text-text-300">{botOrdersCount > 10 ? `More than 10` : botOrdersCount} orders are running</div>
                            </div>
                            {botOrdersCount > 0 ? (
                                <Button
                                    onClick={() => {
                                        history.push(getAccountPathname("/account_update/close_bot_order"));
                                    }}
                                    type="primary"
                                    shape="round"
                                    className="ml-16px w-[90px]"
                                >
                                    Close
                                </Button>
                            ) : (
                                <div className="flex justify-center items-center gap-2px">
                                    <IconSuccess20pxBoldS /> Finished
                                </div>
                            )}
                        </div>
                    </div>
                </Card>

                <Button
                    onClick={() => {
                        history.push(getAccountPathname("/account_update/upgrade"));
                    }}
                    disabled={!!botOrdersCount || !!manualOrdersCount}
                    type="primary"
                    shape="round"
                    size="large"
                    className="w-full mt-24px"
                >
                    Next
                </Button>
            </Spin>
        </div>
    );
}
