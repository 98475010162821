import queryString from "query-string";

/**
 * @deprecated Use {@link queryString.parse()} instead.
 * @param key
 * @param defaultValue
 * @returns
 */
export function getQueryParam(key: string, defaultValue: string = ""): string {
    const search = window.location.search;
    if (!search) {
        return defaultValue;
    }
    const qo = queryString.parse(window.location.search, { decode: true });
    return (qo[key] as string) || defaultValue;
}

export function getMeta(name: string) {
    const meta = document.head.querySelector(`meta[name=${name}]`);
    return meta?.getAttribute("content") ?? "";
}

export function openUrlInNewTab(url: string) {
    const win = window.open(url, "_blank");
    if (win) {
        win.focus();
    }
}

export function getPathname(href) {
    const a = window.document.createElement("a");
    a.href = href;
    return a.pathname;
}

const UA = navigator.userAgent;
const sUserAgent = UA.toLowerCase();
/**
 * @deprecated Use {@link useMedia} instead.
 */
export const isMobile = /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent);
/**
 * @deprecated Use {@link useMedia} instead.
 */
export const isAndroid = UA.indexOf("Android") > -1 || sUserAgent.indexOf("Adr") > -1;
/**
 * @deprecated Use {@link useMedia} instead.
 */
export const isIOS = !!UA.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

/**
 * 正则集合
 * @notice 请使用getter创建，避免初始化创建该字段
 */
export const RegPatterns = {
    // 国际化电话号码验证
    internationalPhoneNumber: {
        get prefix() {
            return /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)/;
        },
        get content() {
            return /^\d{1,14}$/;
        },
        get full() {
            return new RegExp(`${RegPatterns.internationalPhoneNumber.prefix}${RegPatterns.internationalPhoneNumber.content.toString().replace("^", "")}`);
        },
    },
};

export namespace NestedRoute {
    interface NRR<T extends string> {
        /**有子节点时，用于标记当前节点路径名 */
        readonly _: T;
        /**其他子节点 */
        readonly [t: string]: T | NRR<T>;
    }
    /**
     * 基于嵌套节点模式，定义并补全嵌套对象路由的文本路径
     * @param nr
     * @param pa
     * @returns
     */
    export function CompleteTree<V extends string, T extends NRR<V>>(nr: T, pa: string = ""): Readonly<T> {
        const parent = `${pa}/${nr._}`;
        return Object.entries(nr).reduce((obj: any, [k, v]) => {
            if (typeof v === "string") {
                obj[k] = parent;
                if (k === "_") {
                    // 用于字符串拼接对象时使用
                    obj.toString = function () {
                        return this._;
                    };
                } else {
                    obj[k] = `${parent}/${v}`;
                }
            } else {
                obj[k] = CompleteTree(v, parent);
            }
            return obj;
        }, {} as T);
    }
}
