import React, { useMemo } from "react";
import { useRxRequest } from "commonUse/useRequest";
import { useCircleDebitAPI } from "landings/V2/CircleDebit/context";
import useDateFormatter from "commonUse/useDateFormatter";
import { useTranslators } from "commonUse/Locale";
import useTheme from "commonUse/useTheme";
import { TSpin, TTable, TTooltip } from "components/v2/TinyComps";
import { ThemeName } from "state/ThemeData";
import { DebitDepositRecord, DebitDepositStatus } from "landings/V2/CircleDebit/types";
import { getCircleFailReasonText } from "landings/V2/USDDW/utils";
import cs from "./index.m.less";
import USDTSwapModal from "src/components-v2/USDTSwapModal";
import { useToggle } from "commonUse/tools";
import { catchError } from "rxjs/operators";
import { message, Spin, Table } from "@pionex-web-kit/components";
import { parseError } from "utils/Utils";
import { ColumnsType } from "antd/lib/table";

interface Props {}

function useColumns(showSwapUSDT: () => void): ColumnsType<DebitDepositRecord> {
    const dateFormatter = useDateFormatter();
    const { $st } = useTranslators();
    const theme = useTheme();

    return useMemo(() => {
        const columns: ColumnsType<DebitDepositRecord> = [
            {
                title: $st("signature_dw_history_order_id"),
                dataIndex: "id",
                key: "id",
            },
            {
                title: $st("debit_record_deposit_from"),
                dataIndex: "depositFrom",
                key: "depositFrom",
                render: (_, record, index) => {
                    return record.paymentInfo.accountNo;
                },
            },
            {
                title: $st("signature_dw_history_status"),
                dataIndex: "status",
                key: "status",
                render: (value, record, index) => {
                    return (
                        <div>
                            {value}
                            {record.status === DebitDepositStatus.FAILED && (
                                <TTooltip overlayClassName={cs.tips} title={<span>{getCircleFailReasonText($st, record.failReason, record.failDetail)}</span>}>
                                    <img
                                        src={
                                            theme.name === ThemeName.dark
                                                ? require("images/trading/icon_advance_setting_info_dark.png")
                                                : require("images/trading/icon_advance_setting_info.png")
                                        }
                                        className={cs.itemTips}
                                    />
                                </TTooltip>
                            )}
                        </div>
                    );
                },
            },
            {
                title: $st("signature_dw_history_deposit_amount"),
                dataIndex: "amount",
                key: "amount",
                render: (value, record, index) => {
                    return `${record.paymentInfo.amount} ${record.paymentInfo.currency}`;
                },
            },
            {
                title: $st("signature_dw_history_fee"),
                dataIndex: "feeAmount",
                key: "feeAmount",
                render: (value, record, index) => {
                    return `${record.paymentInfo.feeAmount} ${record.paymentInfo.feeCurrency}`;
                },
            },
            {
                title: $st("signature_dw_history_time_New"),
                dataIndex: "updateTime",
                key: "updateTime",
                render: (value, record, index) => {
                    return value ? <div>{dateFormatter.year_second(value)}</div> : null;
                },
            },
            {
                title: $st("withdrawable_Time"),
                dataIndex: "withdrawableTime",
                key: "withdrawableTime",
                width: "100px",
                render: (value, record, index) => {
                    return value ? <div>{dateFormatter.year_second(value)}</div> : "-";
                },
            },
            {
                title: " ",
                dataIndex: "operation",
                key: "operation",
                render: () => {
                    return (
                        <div className={cs.optWrapper}>
                            <div className={cs.operation} onClick={showSwapUSDT}>
                                {$st("circledw_history_swap_usdt")}
                            </div>
                        </div>
                    );
                },
            },
        ];

        columns.forEach((item) => (item.width = item.width || `${100 / columns.length}%`));
        return columns;
    }, [$st, dateFormatter, showSwapUSDT, theme.name]);
}

interface Props {}

/**
 * DebitCard入金记录
 */
const History: React.FC<Props> = ({}) => {
    const circleDebitAPI = useCircleDebitAPI();
    const { loading, data } = useRxRequest(() =>
        circleDebitAPI?.depositRecords().pipe(
            catchError((err) => {
                message.error(parseError(err));
                throw err;
            }),
        ),
    );

    const [swapVisible, { setTrue: setSwapShow, setFalse: setSwapHide }] = useToggle(false);

    const columns = useColumns(setSwapShow);

    return (
        <Spin spinning={loading}>
            <Table scroll={{ x: "100%" }} dataSource={data} columns={columns} pagination={false} />
            <USDTSwapModal opposite={"USD"} visible={swapVisible} onSuccess={setSwapHide} onCancel={setSwapHide} isGetUSDT />
        </Spin>
    );
};
export default History;
