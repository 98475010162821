import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TRadio, TSpin } from "src/components/v2/TinyComps";
import { useTranslators } from "src/commonUse/Locale";
import cs from "./cs.m.less";
import useExchangeRate from "src/commonUse/useExchangeRate";
import useTheme from "src/commonUse/useTheme";
import { NumberCommonUtils, SafeDecimal } from "trade_utils_lib";
import SeprateChart from "./SeparateChart";
import Chart from "./chart";
import { TPeriod, useBalanceHistory } from "src/commonUse/useBalance";
import EmptyChart from "../EmptyChart";

const colors = ["#809CFF", "#FFA776"];

const Overview = ({
    balanceTotalUSD,
    balanceTotalBTC,
    tradeTotalUSD,
    financeTotalUSD,
    totalBalanceDisplay,
    tradeTotalDisplay,
    financeTotalDisplay,
    balanceStatTotalUSD,
    balanceStatDepositInUSD,
    balanceStatWithdrawInUSD,
    balanceStatLoading,
    financeTodayProfitDisplay,
    tradeTodayProfitDisplay,
    financeTodayProfitColor,
    tradeTodayProfitColor,
}: {
    balanceTotalUSD: number;
    balanceTotalBTC: number;
    tradeTotalUSD: number;
    financeTotalUSD: number;
    totalBalanceDisplay: string;
    tradeTotalDisplay: string;
    financeTotalDisplay: string;
    balanceStatTotalUSD: number;
    balanceStatDepositInUSD: number;
    balanceStatWithdrawInUSD: number;
    balanceStatLoading: boolean;
    financeTodayProfitDisplay: string;
    tradeTodayProfitDisplay: string;
    financeTodayProfitColor: string;
    tradeTodayProfitColor: string;
}) => {
    const { $st } = useTranslators();
    const exchangeRate = useExchangeRate();
    const theme = useTheme();
    const [period, setPeriod] = useState(30);
    const [_originBalanceHistoryData, balanceHistoryLoading] = useBalanceHistory(period as TPeriod);
    const [balanceHistoryData, setBalanceHistoryData] = useState(_originBalanceHistoryData);
    useEffect(() => {
        if (!_originBalanceHistoryData.length && balanceTotalUSD > 0) {
            // 当没有数据时，显示当前balance数据
            setBalanceHistoryData([generateBalanceData(balanceTotalUSD, Date.now() - 1e3), generateBalanceData(balanceTotalUSD, Date.now())]);
        } else {
            setBalanceHistoryData([..._originBalanceHistoryData, generateBalanceData(balanceTotalUSD, Date.now())]);
        }
    }, [_originBalanceHistoryData, balanceTotalUSD]);
    const { totalBTC, hour24ProfitColor, todayPnl, todayPnlRatio, periodTotalPnl, periodPnlColor, periodPnlRatio, periodMap, periodOptions } = useMemo(() => {
        const totalBTC = `=${NumberCommonUtils.toNormalPrice(balanceTotalBTC)} BTC`;
        const totalTodayPnl = balanceTotalUSD - balanceStatTotalUSD - balanceStatDepositInUSD + balanceStatWithdrawInUSD;
        const todayPnl = new SafeDecimal(NumberCommonUtils.toNormalPrice(new SafeDecimal(totalTodayPnl).mul(exchangeRate.exchangeRate).toNumber())).toNumber().toLocaleString();
        const hour24ProfitColor = totalTodayPnl >= 0 ? theme.colorIncrease : theme.colorDecrease;
        const todayPnlRatio = new SafeDecimal(balanceStatTotalUSD).eq(0) ? "" : `(${NumberCommonUtils.toPercent((totalTodayPnl * 100) / balanceStatTotalUSD)})`;
        let periodTotalPnl;
        let periodPnlColor;
        let periodPnlRatio;
        if (balanceHistoryData.length) {
            const periodPnl = new SafeDecimal(balanceHistoryData[balanceHistoryData.length - 1].total_in_usd).minus(balanceHistoryData[0].total_in_usd).toNumber();
            periodTotalPnl = new SafeDecimal(NumberCommonUtils.toNormalPrice(new SafeDecimal(periodPnl).mul(exchangeRate.exchangeRate).toNumber())).toNumber().toLocaleString();
            periodPnlColor = periodPnl >= 0 ? theme.colorIncrease : theme.colorDecrease;
            periodPnlRatio = new SafeDecimal(balanceHistoryData[0].total_in_usd).eq(0)
                ? ""
                : `(${NumberCommonUtils.toPercent((periodPnl * 100) / new SafeDecimal(balanceHistoryData[0].total_in_usd).toNumber())})`;
        } else {
            periodTotalPnl = 0;
            periodPnlColor = theme.colorIncrease;
        }
        const periodMap = {
            30: $st("price_line_type_30d"),
            90: $st("price_line_type_90d"),
            180: $st("price_line_type_180d"),
            365: $st("price_line_type_1y"),
        };
        const periodOptions = [30, 90, 180, 365].map((p) => (
            <TRadio.Button value={p} key={p}>
                {periodMap[p]}
            </TRadio.Button>
        ));
        return {
            totalBTC,
            hour24ProfitColor,
            todayPnl,
            todayPnlRatio,
            periodTotalPnl,
            periodPnlColor,
            periodPnlRatio,
            periodMap,
            periodOptions,
        };
    }, [balanceTotalBTC, balanceTotalUSD, balanceStatTotalUSD, exchangeRate.exchangeRate, theme.colorIncrease, theme.colorDecrease, balanceHistoryData, $st]);
    const onPeriodChange = useCallback((e) => {
        setPeriod(e.target.value);
    }, []);
    return (
        <div className={cs.overview}>
            <div className={cs.overviewTotal}>
                <div className={cs.overviewTotalInfo}>
                    <div className={cs.overviewTotalInfoTitle}>{$st("balance_summary_title")}</div>
                    <div className={cs.overviewTotalInfoSum}>
                        <span className={cs.totalBalance}>{totalBalanceDisplay}</span>
                        <span className={cs.totalBTC}>{totalBTC}</span>
                    </div>
                    <div className={cs.overviewTotalInfoToday} style={{ color: hour24ProfitColor, visibility: balanceStatLoading ? "hidden" : "visible" }}>
                        {$st("balance_bot_right_title")}: {todayPnl} {todayPnlRatio}
                    </div>
                </div>
                <div className={cs.overviewChart}>
                    {balanceHistoryLoading ? (
                        <div style={{ height: 128, display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <TSpin />
                        </div>
                    ) : balanceHistoryData.length ? (
                        <Chart balanceHistoryData={balanceHistoryData} periodPnlColor={periodPnlColor} />
                    ) : (
                        <EmptyChart style={{ height: 128 }} />
                    )}
                    <div className={cs.periodBtn}>
                        <TRadio.Group value={period} onChange={onPeriodChange}>
                            {periodOptions}
                        </TRadio.Group>
                        {/* <div className={cs.periodProfit}>
                            <span>
                                {periodMap[period]}
                                {$st("balance_account_overview_period")}:
                            </span>
                            <span style={{ color: periodPnlColor }}>
                                {periodTotalPnl} {periodPnlRatio}
                            </span>
                        </div> */}
                    </div>
                </div>
            </div>
            {PLATFORM.PIONEX_US_LIKE || (
                <div className={cs.overviewSeparate}>
                    <div className={cs.overviewSeparateTitle}>{$st("balance_seprate_title")}</div>
                    <div className={cs.overviewSeparateDetail}>
                        <div className={cs.overviewSeparateChart}>
                            {tradeTotalUSD + financeTotalUSD === 0 ? (
                                <EmptyChart style={{ width: 360, height: 220 }} />
                            ) : (
                                <SeprateChart tradeTotalUSD={tradeTotalUSD} financeTotalUSD={financeTotalUSD} />
                            )}
                        </div>
                        <div className={cs.overviewSeparateData}>
                            <div className={cs.overviewSeparateDataItem} style={{ borderColor: colors[1] }}>
                                <div className={cs.title}>{$st("balance_seprate_trade_title")}</div>
                                <div className={cs.sum}>{tradeTotalDisplay}</div>
                                <div className={cs.profit} style={{ color: tradeTodayProfitColor }}>
                                    {$st("balance_seprate_profit_title")}:{!balanceStatLoading && tradeTodayProfitDisplay}
                                </div>
                            </div>
                            <div className={cs.overviewSeparateDataItem} style={{ borderColor: colors[0] }}>
                                <div className={cs.title}>{$st("balance_seprate_finance_title")}</div>
                                <div className={cs.sum}>{financeTotalDisplay}</div>
                                <div className={cs.profit} style={{ color: financeTodayProfitColor }}>
                                    {$st("balance_seprate_profit_title")}:{!balanceStatLoading && financeTodayProfitDisplay}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Overview;

function generateBalanceData(balanceTotalUSD: number, timestampMS: number) {
    return {
        finance_total_in_usd: `${balanceTotalUSD}`,
        total_in_usd: `${balanceTotalUSD}`,
        trade_total_in_usd: `${balanceTotalUSD}`,
        timestamp: Number(`${timestampMS}`.slice(0, 10)),
    };
}
