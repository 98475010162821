import React, { useCallback, useMemo } from "react";
import css from "./cs.m.less";
import csn from "classnames";

const Filter = (props: { activeKey: string; className?: string; onChange: (key: string) => void; tabs: { key: string; label: string }[] }) => {
    const { tabs, onChange, className } = props;
    const getClassName = useCallback(
        (itemKey: string) => {
            let cls = css["filter-item"];
            if (itemKey === props.activeKey) {
                cls += ` ${css["filter-item-active"]}`;
            }
            return cls;
        },
        [props.activeKey],
    );
    const tabView = useMemo(() => {
        return tabs.map((item) => {
            return (
                <div className={getClassName(item.key)} key={item.key}>
                    <span onClick={() => onChange(item.key)}>{item.label}</span>
                </div>
            );
        });
    }, [tabs, getClassName, onChange]);
    return <div className={csn(css["filter-box"], className)}>{tabView}</div>;
};

export default Filter;
