import { CoinDetail } from "landings/DepositWithdraw/components/CoinDetail";
import * as React from "react";
import { useMemo } from "react";
import { DWType, useDWPageContext } from "../..";
import cs from "../../withdraw.module.less";
import { CoinSelectView } from "./components/coinList/SelectView";
import useTheme from "commonUse/useTheme";
import { SupportDWCoinInfo } from "TradeAPILib";
import { Button } from "antd";
import { Radio } from "@pionex-web-kit/components";

interface ICoinSelectProps {
    type: DWType;
    onSelect(coin: SupportDWCoinInfo): void;
}

export function CoinSelect(props: ICoinSelectProps) {
    const { type, onSelect } = props;
    const { coinList, currentCoin } = useDWPageContext();
    const theme = useTheme();
    const currentCoins = useMemo(() => {
        return coinList.filter((item) => item.coinName === currentCoin.coinName && (type === DWType.deposit ? item.supportDeposit : item.supportWithdraw));
    }, [coinList, currentCoin.coinName, type]);

    return (
        <div className={cs.cardContent} style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", padding: 0 }}>
            <div>
                <CoinSelectView type={props.type} changeCurrentSelect={onSelect} />
                <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                    <CoinDetail type={type} />
                </div>
            </div>
            <Radio.Group
                className={"mt-[-8px] px-20px"}
                bgStyle="separate-border"
                value={currentCoin.protocol}
                onChange={(e) => {
                    const protocol = e.target.value;
                    const coin = currentCoins.find((item) => item.protocol === protocol);
                    if (coin) {
                        onSelect(coin);
                    }
                }}
            >
                {currentCoins.map((item) => (
                    <Radio.Button key={item.protocol} value={item.protocol} className={"mt-8px"}>
                        {item.protocolDisplay}
                    </Radio.Button>
                ))}
            </Radio.Group>
        </div>
    );
}
