import React, { Dispatch, useCallback, useEffect } from "react";
import { Iconfont, TRadio } from "components/v2/TinyComps";
import cs from "./index.module.less";
import csn from "classnames";
import { useTranslators } from "commonUse/Locale";
import { BankCardInfo, BankStatus } from "compliance_lib";
import { formatCardNumber } from "landings/V2/CircleDW/common";
import { history } from "utils/History";
import { MAX_CARD_COUNT } from "landings/V2/CircleDW/BankCard/common";

const toCard = () => {
    history.push("/circle_dw/bankcard/add");
};

interface Props {
    title?: string;
    disableAdd?: boolean;
    bankCards: BankCardInfo[];
    selectedCardId?: string;
    onSelectCardChange?: Dispatch<BankCardInfo>;
}

/**
 * 出入金的银行卡选择
 */
const CardSelector: React.FC<Props> = ({ title, disableAdd, bankCards, selectedCardId, onSelectCardChange }) => {
    const { $st } = useTranslators();

    useEffect(() => {
        // 默认选中第一张有效卡片
        const firstValidCard = bankCards.find((item) => item.bankStatus === BankStatus.approved);
        firstValidCard && onSelectCardChange?.(firstValidCard);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!!title && <div className={cs.title}>{title}</div>}
            {bankCards.map((item) => (
                <div
                    key={item.bankId}
                    className={csn(cs.cardItem, { [cs.cardItem_disable]: item.bankStatus !== BankStatus.approved })}
                    onClick={() => {
                        if (item.bankStatus !== BankStatus.approved) {
                            return;
                        }
                        onSelectCardChange?.(item);
                    }}
                >
                    <Iconfont icon="icon_Account" />
                    <span className={cs.label}>{`${item.bankName}(${formatCardNumber(item.bankNumber)})`}</span>
                    {(() => {
                        switch (item.bankStatus) {
                            case BankStatus.logged:
                                return $st("circledw_bank_logged");
                            case BankStatus.rejected:
                                return $st("circledw_bank_rejected");
                            case BankStatus.approved:
                            default:
                                return <TRadio checked={item.bankId === selectedCardId} />;
                        }
                    })()}
                </div>
            ))}
            {!disableAdd && bankCards.length < MAX_CARD_COUNT && (
                <div className={cs.add} onClick={toCard}>
                    {$st("circledw_card_selector_add")}
                </div>
            )}
        </>
    );
};

export default CardSelector;
