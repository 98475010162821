import Cookies from "js-cookie";
import { GoogleTag } from "./GoogleTag";
import { EventParams, UserProperties } from "./types";
import LocalStorageUtils from "utils/LocalStorageUtils";
import Constants from "utils/Constants";
import { useEffect } from "react";
import { useLocation } from "react-use";

const KEY_USER_ID = "key_user_id";

export function getUserId() {
    return Cookies.get(KEY_USER_ID) || null;
}

export function getCommonProperties(): UserProperties {
    const searchParams = new URLSearchParams(window.location.search);
    const source = searchParams.get("source") || window.localStorage.getItem("source") || "";
    const referral = LocalStorageUtils.getReferralCode();
    const commonProperties: UserProperties = {
        device_id: Constants.deviceId,
        locale: "en",
    };
    if (process.env.VERSION) {
        commonProperties.version = process.env.VERSION;
    }
    if (source) {
        commonProperties.source = source;
    }
    if (referral) {
        commonProperties.referral = referral;
    }
    return commonProperties;
}

const containerId = "GTM-P6H7RF9V";
const dataLayerName = "gtmDataLayer";

/**
 * 参考文档
 * dataLayer api: https://developers.google.com/tag-platform/tag-manager/datalayer
 * config google analytics: https://support.google.com/tagmanager/topic/6333310
 */
export class GoogleTagManager {
    public static shared = new GoogleTagManager();

    private readonly dataLayer: any[];

    private constructor() {
        window[dataLayerName] = window[dataLayerName] || [];
        this.dataLayer = window[dataLayerName];
    }

    private loadScript() {
        const scriptId = `gtm-${containerId}`;
        if (document.getElementById(scriptId)) {
            return;
        }
        const script = document.createElement("script");
        script.id = scriptId;
        script.type = "text/javascript";
        script.async = true;
        script.onload = () => {
            console.log("gtm onload");
        };
        script.onerror = () => {
            console.log("gtm onerror");
            const scr = document.getElementById(scriptId);
            if (scr) {
                scr.parentNode?.removeChild(scr);
            }
        };
        script.src = `https://www.googletagmanager.com/gtm.js?id=${containerId}&l=${dataLayerName}`;
        const head = document.getElementsByTagName("head")[0];
        head.appendChild(script);
    }

    public init() {
        this.dataLayer.push({
            event: "gtm.js",
            "gtm.start": new Date().getTime(),
        });
        this.loadScript();
        GoogleTag.shared.init();

        this.setUserId(getUserId());
        this.setUserProperties(getCommonProperties());
    }

    public setUserId(user_id?: string | null) {
        if (user_id) {
            this.dataLayer.push({ user_id });
            Cookies.set(KEY_USER_ID, user_id, { secure: true, domain: Constants.currentSubdomain, expires: 365 });
        }
        GoogleTag.shared.setUserId(user_id);
    }

    public setUserProperties(user_properties: UserProperties) {
        this.dataLayer.push({ user_properties });
        GoogleTag.shared.setUserProperties(user_properties);
    }

    public report(name: string, params?: EventParams) {
        this.dataLayer.push({ event: name, event_params: params });
        GoogleTag.shared.report(name, params);
    }
}

export function initGoogleTagManager() {
    GoogleTagManager.shared.init();
}

export function report(name: string, data?: EventParams) {
    GoogleTagManager.shared.report(name, data);
}

export function usePageViewReport() {
    const { pathname } = useLocation();

    useEffect(() => {
        report("page_view");
    }, [pathname]);
}
