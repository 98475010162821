/**
 * 卡类型
 */
export enum FundingType {
    CREDIT = "CREDIT",
    DEBIT = "DEBIT",
    PREPAID = "PREPAID",
    UNKNOWN = "UNKNOWN",
}

/**
 * 卡的网络
 */
export enum Network {
    VISA = "VISA",
    MASTER = "MASTER",
}

/**
 * 卡状态
 */
export enum DebitCardStatus {
    PENDING = "PENDING",
    VERIFY_REQUIRED = "VERIFY_REQUIRED",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
}

/**
 * 绑定的卡的信息
 */
export interface DebitCardInfo {
    last4: string; // 卡号最后4位
    bin: string; // 银行码，卡的前面6位
    issuerCountry: string; // 发卡国家
    network: Network; // 卡的网络, VISA, MASTER
    fundingType: FundingType; // 卡类型 CREDIT DEBIT PREPAID UNKNOWN
    expYear: number; // 卡的过期年份
    expMonth: number; // 卡的过期月份
}

/**
 * 卡片的用户信息
 */
export interface DebitCardUserInfo {
    userId: string; // 用户id
    email: string; // 用户邮箱
    name: string; // 持卡人姓名
    city: string; // 城市
    country: string; // 国家，ISO 3166-1 alpha-3
    addressLine1: string; // 地址1
    addressLine2: string; // 地址2
    district: string; // 地区
    postalCode: string; // 邮编
}

/**
 * sumsub认证状态
 */
export enum ESumsubVerifyStatus {
    NOT_STARTED = "NOT_STARTED", // 没有上传
    PENDING = "PENDING", // 已经上传了文件，认证中
    VERIFIED = "VERIFIED", // 认证成功
    REJECTED = "REJECTED", // 拒绝
    RESUBMISSION = "RESUBMISSION", // 需要重新上传
}

export interface APIDebitCard {
    id: string;
    channel: DebitCardChannel;
    status: DebitCardStatus; // 卡状态
    failReason: string; // 失败原因
    failDetail: string; // 失败详细信息，用作前端展示
    createTime: number;
    updateTime: number;
    cardInfo: DebitCardInfo; // 卡信息
    userInfo: DebitCardUserInfo; // 用户信息
    verifyStatus: ESumsubVerifyStatus; //sumsub认证状态
}

/**
 * 绑定的卡片,带前端字段
 */
export interface DebitCard extends APIDebitCard {
    channelInvalid?: boolean; // 对应通道未开发
}

/**
 * 公钥信息
 */
export interface PublicKeyInfo {
    keyId: string;
    publicKey: string;
}

/**
 * 入金状态
 */
export enum DebitDepositStatus {
    PENDING = "PENDING",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
}

/**
 * 账单信息
 */
export interface DebitPayment {
    cardId: string;
    accountNo: string;
    amount: string;
    currency: string;
    feeAmount: string;
    feeCurrency: string;
    requiredAction?: { type?: DepositActionType; redirectUrl?: string };
}

/**
 * 入金记录
 */
export interface DebitDepositRecord {
    id: string;
    status: DebitDepositStatus;
    failReason: string; // 失败原因
    failDetail: string; // 失败详细信息，用作前端展示
    createTime: number;
    updateTime: number;
    paymentInfo: DebitPayment;
}

/**
 * 入金失败原因枚举
 */
export enum DepositFailReason {
    /**
     * AML检测未通过
     */
    AML_FAILED = "AML_FAILED",
}

/**
 * 入金校验类型
 */
export enum DepositActionType {
    CVV_REQUIRED = "cvv_required",
    THREE_D_SECURE_REQUIRED = "three_d_secure_required",
}

/**
 * 入金请求结果
 */
export interface DepositRes {
    id: string;
    status: DebitDepositStatus;
    actionType: DepositActionType;
    failReason: string; // 失败原因
    failDetail: string; // 失败详细信息，用作前端展示
}

/**
 * 需要Sumsub认证的卡信息
 */
export interface CardVerifyInfo {
    cardId: string;
    isRequired?: boolean;
}

/**
 * 卡片通道类型
 */
export enum DebitCardChannel {
    CIRCLE = "CIRCLE",
    CHECKOUT = "CHECKOUT",
}

export type AddCardRes = Pick<DebitCard, "id" | "status" | "failReason" | "failDetail"> & { verifyRequired: boolean };
