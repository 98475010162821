import { useMemo } from "react";
import useAccountInfo from "commonUse/useAccountInfo";
import { acquireKeyResolver } from "trade_token_lib";
import { Observable } from "rxjs";
import { ExchangeID } from "TradeAPILib";

const stringifyBody = (b) => {
    return typeof b !== "string" ? JSON.stringify(b) : b;
};
/**
 * 获取签名请求函数
 * 请确保调用组件只能在登录之后访问！！！！
 */
export default function useSignRequest() {
    const firstApiKey = useAccountInfo().firstApiKey;
    return useMemo(() => {
        return <T = any>(url: string, { method, body }: { method?: "GET" | "POST" | "DELETE"; body?: any } = {}) => {
            if (!firstApiKey) throw Error("The user account info is required,when calling useSignRequest() function.");
            return acquireKeyResolver().requestSigner.getSignRequestV2(method || "GET", url, stringifyBody(body)) as Observable<{
                code: number;
                message?: string;
                data: T;
            }>;
        };
    }, [firstApiKey]);
}
