export interface CountryInfoV2 {
    code: string;
    name: string;
    cname: string;
    twName: string;
    codeAlpha3: string;
    koName: string;
}
export const CountryListForLite = [
    { code: "US", name: "United States of America", codeAlpha3: "USA", twName: "美國", cname: "美国", koName: "미국" },
    { code: "CN", name: "China", codeAlpha3: "CHN", twName: "中國", cname: "中国", koName: "중국" },
    { code: "HK", name: "Hong Kong", codeAlpha3: "HKG", twName: "中國香港", cname: "中国香港", koName: "홍콩" },
    { code: "TW", name: "Taiwan", codeAlpha3: "TWN", twName: "臺灣", cname: "中国台湾", koName: "대만" },
    { code: "AF", name: "Afghanistan", codeAlpha3: "AFG", twName: "阿富汗", cname: "阿富汗", koName: "아프가니스탄" },
    { code: "AX", name: "Åland Islands", codeAlpha3: "ALA", twName: "奧蘭", cname: "奥兰", koName: "올란드 제도" },
    { code: "AL", name: "Albania", codeAlpha3: "ALB", twName: "阿爾巴尼亞", cname: "阿尔巴尼亚", koName: "알바니아" },
    { code: "DZ", name: "Algeria", codeAlpha3: "DZA", twName: "阿爾及利亞", cname: "阿尔及利亚", koName: "알제리" },
    { code: "AS", name: "American Samoa", codeAlpha3: "ASM", twName: "美屬薩摩亞", cname: "美属萨摩亚", koName: "아메리칸 사모아" },
    { code: "AD", name: "Andorra", codeAlpha3: "AND", twName: "安道爾", cname: "安道尔", koName: "안도라" },
    { code: "AO", name: "Angola", codeAlpha3: "AGO", twName: "安哥拉", cname: "安哥拉", koName: "앙골라" },
    { code: "AI", name: "Anguilla", codeAlpha3: "AIA", twName: "安圭拉", cname: "安圭拉", koName: "앵 귈라" },
    { code: "AQ", name: "Antarctica", codeAlpha3: "ATA", twName: "南極洲", cname: "南极洲", koName: "남극 대륙" },
    { code: "AG", name: "Antigua and Barbuda", codeAlpha3: "ATG", twName: "安提瓜和巴布達", cname: "安提瓜和巴布达", koName: "앤티가 바부 다" },
    { code: "AR", name: "Argentina", codeAlpha3: "ARG", twName: "阿根廷", cname: "阿根廷", koName: "아르헨티나" },
    { code: "AM", name: "Armenia", codeAlpha3: "ARM", twName: "亞美尼亞", cname: "亚美尼亚", koName: "아르메니아" },
    { code: "AW", name: "Aruba", codeAlpha3: "ABW", twName: "阿魯巴", cname: "阿鲁巴", koName: "아루바" },
    { code: "AU", name: "Australia", codeAlpha3: "AUS", twName: "澳大利亞", cname: "澳大利亚", koName: "호주" },
    { code: "AT", name: "Austria", codeAlpha3: "AUT", twName: "奧地利", cname: "奥地利", koName: "오스트리아" },
    { code: "AZ", name: "Azerbaijan", codeAlpha3: "AZE", twName: "阿塞拜疆", cname: "阿塞拜疆", koName: "아제르바이잔" },
    { code: "BS", name: "Bahamas", codeAlpha3: "BHS", twName: "巴哈馬", cname: "巴哈马", koName: "바하마" },
    { code: "BH", name: "Bahrain", codeAlpha3: "BHR", twName: "巴林", cname: "巴林", koName: "바레인" },
    { code: "BD", name: "Bangladesh", codeAlpha3: "BGD", twName: "孟加拉國", cname: "孟加拉国", koName: "방글라데시" },
    { code: "BB", name: "Barbados", codeAlpha3: "BRB", twName: "巴巴多斯", cname: "巴巴多斯", koName: "바베이도스" },
    { code: "BY", name: "Belarus", codeAlpha3: "BLR", twName: "白俄羅斯", cname: "白俄罗斯", koName: "벨라루스" },
    { code: "BE", name: "Belgium", codeAlpha3: "BEL", twName: "比利時", cname: "比利时", koName: "벨기에" },
    { code: "BZ", name: "Belize", codeAlpha3: "BLZ", twName: "伯利茲", cname: "伯利兹", koName: "벨리즈" },
    { code: "BJ", name: "Benin", codeAlpha3: "BEN", twName: "貝寧", cname: "贝宁", koName: "베냉" },
    { code: "BM", name: "Bermuda", codeAlpha3: "BMU", twName: "百慕大", cname: "百慕大", koName: "버뮤다" },
    { code: "BT", name: "Bhutan", codeAlpha3: "BTN", twName: "不丹", cname: "不丹", koName: "부탄" },
    { code: "BO", name: "Bolivia", codeAlpha3: "BOL", twName: "玻利維亞", cname: "玻利维亚", koName: "볼리비아" },
    { code: "BQ", name: "Bonaire, Sint Eustatius and Saba", codeAlpha3: "BES", twName: "荷蘭加勒比區", cname: "荷兰加勒比区", koName: "보네르, 신트 유스 타티 우스, 사바" },
    { code: "BA", name: "Bosnia and Herzegovina", codeAlpha3: "BIH", twName: "波黑", cname: "波黑", koName: "보스니아 헤르체고비나" },
    { code: "BW", name: "Botswana", codeAlpha3: "BWA", twName: "博茨瓦納", cname: "博茨瓦纳", koName: "보츠와나" },
    { code: "BV", name: "Bouvet Island", codeAlpha3: "BVT", twName: "布韋島", cname: "布韦岛", koName: "부베 섬" },
    { code: "BR", name: "Brazil", codeAlpha3: "BRA", twName: "巴西", cname: "巴西", koName: "브라질" },
    { code: "IO", name: "British Indian Ocean Territory", codeAlpha3: "IOT", twName: "英屬印度洋領地", cname: "英属印度洋领地", koName: "영국령 인도양 지역" },
    { code: "BN", name: "Brunei Darussalam", codeAlpha3: "BRN", twName: "文萊", cname: "文莱", koName: "브루나이 다루 살람" },
    { code: "BG", name: "Bulgaria", codeAlpha3: "BGR", twName: "保加利亞", cname: "保加利亚", koName: "불가리아" },
    { code: "BF", name: "Burkina Faso", codeAlpha3: "BFA", twName: "布基納法索", cname: "布基纳法索", koName: "부키 나 파소" },
    { code: "BI", name: "Burundi", codeAlpha3: "BDI", twName: "布隆迪", cname: "布隆迪", koName: "부룬디" },
    { code: "KH", name: "Cambodia", codeAlpha3: "KHM", twName: "柬埔寨", cname: "柬埔寨", koName: "캄보디아" },
    { code: "CM", name: "Cameroon", codeAlpha3: "CMR", twName: "喀麥隆", cname: "喀麦隆", koName: "카메룬" },
    { code: "CA", name: "Canada", codeAlpha3: "CAN", twName: "加拿大", cname: "加拿大", koName: "캐나다" },
    { code: "CV", name: "Cape Verde", codeAlpha3: "CPV", twName: "佛得角", cname: "佛得角", koName: "카보 베르데" },
    { code: "KY", name: "Cayman Islands", codeAlpha3: "CYM", twName: "開曼群島", cname: "开曼群岛", koName: "케이맨 제도" },
    { code: "CF", name: "Central African Republic", codeAlpha3: "CAF", twName: "中非", cname: "中非", koName: "중앙 아프리카 공화국" },
    { code: "TD", name: "Chad", codeAlpha3: "TCD", twName: "乍得", cname: "乍得", koName: "차드" },
    { code: "CL", name: "Chile", codeAlpha3: "CHL", twName: "智利", cname: "智利", koName: "칠레" },
    { code: "CX", name: "Christmas Island", codeAlpha3: "CXR", twName: "聖誕島", cname: "圣诞岛", koName: "크리스마스 섬" },
    { code: "CC", name: "Cocos (Keeling) Islands", codeAlpha3: "CCK", twName: "科科斯（基林）群島", cname: "科科斯（基林）群岛", koName: "코코스 군도" },
    { code: "CO", name: "Colombia", codeAlpha3: "COL", twName: "哥倫比亞", cname: "哥伦比亚", koName: "콜롬비아" },
    { code: "KM", name: "Comoros", codeAlpha3: "COM", twName: "科摩羅", cname: "科摩罗", koName: "코모로" },
    { code: "CG", name: "Congo", codeAlpha3: "COG", twName: "剛果（布）", cname: "刚果（布）", koName: "콩고" },
    { code: "CD", name: "Congo, the Democratic Republic of the", codeAlpha3: "COD", twName: "剛果（金）", cname: "刚果（金）", koName: "콩고 민주 공화국" },
    { code: "CK", name: "Cook Islands", codeAlpha3: "COK", twName: "庫克群島", cname: "库克群岛", koName: "쿡 제도" },
    { code: "CR", name: "Costa Rica", codeAlpha3: "CRI", twName: "哥斯達黎加", cname: "哥斯达黎加", koName: "코스타리카" },
    { code: "CI", name: "Cote D'Ivoire", codeAlpha3: "CIV", twName: "科特迪瓦", cname: "科特迪瓦", koName: "코트 디부 아르" },
    { code: "HR", name: "Croatia", codeAlpha3: "HRV", twName: "克羅地亞", cname: "克罗地亚", koName: "크로아티아" },
    { code: "CU", name: "Cuba", codeAlpha3: "CUB", twName: "古巴", cname: "古巴", koName: "쿠바" },
    { code: "CW", name: "Curaçao", codeAlpha3: "CUW", twName: "庫拉索", cname: "库拉索", koName: "쿠라 사오" },
    { code: "CY", name: "Cyprus", codeAlpha3: "CYP", twName: "塞浦路斯", cname: "塞浦路斯", koName: "키프로스" },
    { code: "CZ", name: "Czech Republic", codeAlpha3: "CZE", twName: "捷克", cname: "捷克", koName: "체코 공화국" },
    { code: "DK", name: "Denmark", codeAlpha3: "DNK", twName: "丹麥", cname: "丹麦", koName: "덴마크" },
    { code: "DJ", name: "Djibouti", codeAlpha3: "DJI", twName: "吉布提", cname: "吉布提", koName: "지부티" },
    { code: "DM", name: "Dominica", codeAlpha3: "DMA", twName: "多米尼克", cname: "多米尼克", koName: "도미니카 공화국" },
    { code: "DO", name: "Dominican Republic", codeAlpha3: "DOM", twName: "多米尼加", cname: "多米尼加", koName: "도미니카 공화국" },
    { code: "EC", name: "Ecuador", codeAlpha3: "ECU", twName: "厄瓜多爾", cname: "厄瓜多尔", koName: "에콰도르" },
    { code: "EG", name: "Egypt", codeAlpha3: "EGY", twName: "埃及", cname: "埃及", koName: "이집트" },
    { code: "SV", name: "El Salvador", codeAlpha3: "SLV", twName: "薩爾瓦多", cname: "萨尔瓦多", koName: "엘살바도르" },
    { code: "GQ", name: "Equatorial Guinea", codeAlpha3: "GNQ", twName: "赤道幾內亞", cname: "赤道几内亚", koName: "적도 기니" },
    { code: "ER", name: "Eritrea", codeAlpha3: "ERI", twName: "厄立特裏亞", cname: "厄立特里亚", koName: "에리트레아" },
    { code: "EE", name: "Estonia", codeAlpha3: "EST", twName: "愛沙尼亞", cname: "爱沙尼亚", koName: "에스토니아" },
    { code: "ET", name: "Ethiopia", codeAlpha3: "ETH", twName: "埃塞俄比亞", cname: "埃塞俄比亚", koName: "에티오피아" },
    { code: "FK", name: "Falkland Islands (Malvinas)", codeAlpha3: "FLK", twName: "福克蘭群島", cname: "福克兰群岛", koName: "포클랜드 제도 (말 비나 스)" },
    { code: "FO", name: "Faroe Islands", codeAlpha3: "FRO", twName: "法羅群島", cname: "法罗群岛", koName: "페로 제도" },
    { code: "FJ", name: "Fiji", codeAlpha3: "FJI", twName: "斐濟", cname: "斐济", koName: "피지" },
    { code: "FI", name: "Finland", codeAlpha3: "FIN", twName: "芬蘭", cname: "芬兰", koName: "핀란드" },
    { code: "FR", name: "France", codeAlpha3: "FRA", twName: "法國", cname: "法国", koName: "프랑스" },
    { code: "GF", name: "French Guiana", codeAlpha3: "GUF", twName: "法屬圭亞那", cname: "法属圭亚那", koName: "프랑스 령 기아나" },
    { code: "PF", name: "French Polynesia", codeAlpha3: "PYF", twName: "法屬波利尼西亞", cname: "法属波利尼西亚", koName: "프랑스 령 폴리네시아의" },
    { code: "TF", name: "French Southern Territories", codeAlpha3: "ATF", twName: "法屬南部領地", cname: "法属南部领地", koName: "프랑스 남부 지역" },
    { code: "GA", name: "Gabon", codeAlpha3: "GAB", twName: "加蓬", cname: "加蓬", koName: "가봉" },
    { code: "GM", name: "Gambia", codeAlpha3: "GMB", twName: "岡比亞", cname: "冈比亚", koName: "감비아" },
    { code: "GE", name: "Georgia", codeAlpha3: "GEO", twName: "格魯吉亞", cname: "格鲁吉亚", koName: "그루지야" },
    { code: "DE", name: "Germany", codeAlpha3: "DEU", twName: "德國", cname: "德国", koName: "독일" },
    { code: "GH", name: "Ghana", codeAlpha3: "GHA", twName: "加納", cname: "加纳", koName: "가나" },
    { code: "GI", name: "Gibraltar", codeAlpha3: "GIB", twName: "直布羅陀", cname: "直布罗陀", koName: "지브롤터" },
    { code: "GR", name: "Greece", codeAlpha3: "GRC", twName: "希臘", cname: "希腊", koName: "그리스" },
    { code: "GL", name: "Greenland", codeAlpha3: "GRL", twName: "格陵蘭", cname: "格陵兰", koName: "그린란드" },
    { code: "GD", name: "Grenada", codeAlpha3: "GRD", twName: "格林納達", cname: "格林纳达", koName: "그레나다" },
    { code: "GP", name: "Guadeloupe", codeAlpha3: "GLP", twName: "瓜德羅普", cname: "瓜德罗普", koName: "과들루프" },
    { code: "GU", name: "Guam", codeAlpha3: "GUM", twName: "關島", cname: "关岛", koName: "괌" },
    { code: "GT", name: "Guatemala", codeAlpha3: "GTM", twName: "危地馬拉", cname: "危地马拉", koName: "과테말라" },
    { code: "GG", name: "Guernsey", codeAlpha3: "GGY", twName: "根西", cname: "根西", koName: "건지 섬" },
    { code: "GN", name: "Guinea", codeAlpha3: "GIN", twName: "幾內亞", cname: "几内亚", koName: "기니" },
    { code: "GW", name: "Guinea-Bissau", codeAlpha3: "GNB", twName: "幾內亞比紹", cname: "几内亚比绍", koName: "기니 비사우" },
    { code: "GY", name: "Guyana", codeAlpha3: "GUY", twName: "圭亞那", cname: "圭亚那", koName: "가이아나" },
    { code: "HT", name: "Haiti", codeAlpha3: "HTI", twName: "海地", cname: "海地", koName: "아이티" },
    { code: "HM", name: "Heard Island and McDonald Islands", codeAlpha3: "HMD", twName: "赫德島和麥克唐納群島", cname: "赫德岛和麦克唐纳群岛", koName: "허드 섬 및 맥도널드 제도" },
    { code: "VA", name: "Holy See (Vatican City State)", codeAlpha3: "VAT", twName: "梵蒂岡", cname: "梵蒂冈", koName: "성좌 (바티칸 시국)" },
    { code: "HN", name: "Honduras", codeAlpha3: "HND", twName: "洪都拉斯", cname: "洪都拉斯", koName: "온두라스" },
    { code: "HU", name: "Hungary", codeAlpha3: "HUN", twName: "匈牙利", cname: "匈牙利", koName: "헝가리" },
    { code: "IS", name: "Iceland", codeAlpha3: "ISL", twName: "冰島", cname: "冰岛", koName: "아이슬란드" },
    { code: "IN", name: "India", codeAlpha3: "IND", twName: "印度", cname: "印度", koName: "인도" },
    { code: "ID", name: "Indonesia", codeAlpha3: "IDN", twName: "印尼", cname: "印尼", koName: "인도네시아 공화국" },
    { code: "IR", name: "Iran, Islamic Republic of", codeAlpha3: "IRN", twName: "伊朗", cname: "伊朗", koName: "이란, 이슬람 공화국" },
    { code: "IQ", name: "Iraq", codeAlpha3: "IRQ", twName: "伊拉克", cname: "伊拉克", koName: "이라크" },
    { code: "IE", name: "Ireland", codeAlpha3: "IRL", twName: "愛爾蘭", cname: "爱尔兰", koName: "아일랜드" },
    { code: "IM", name: "Isle of Man", codeAlpha3: "IMN", twName: "馬恩島", cname: "马恩岛", koName: "아일 오브 맨" },
    { code: "IL", name: "Israel", codeAlpha3: "ISR", twName: "以色列", cname: "以色列", koName: "이스라엘" },
    { code: "IT", name: "Italy", codeAlpha3: "ITA", twName: "意大利", cname: "意大利", koName: "이탈리아" },
    { code: "JM", name: "Jamaica", codeAlpha3: "JAM", twName: "牙買加", cname: "牙买加", koName: "자메이카" },
    { code: "JP", name: "Japan", codeAlpha3: "JPN", twName: "日本", cname: "日本", koName: "일본" },
    { code: "JE", name: "Jersey", codeAlpha3: "JEY", twName: "澤西", cname: "泽西", koName: "저지" },
    { code: "JO", name: "Jordan", codeAlpha3: "JOR", twName: "約旦", cname: "约旦", koName: "요르단" },
    { code: "KZ", name: "Kazakhstan", codeAlpha3: "KAZ", twName: "哈薩克斯坦", cname: "哈萨克斯坦", koName: "카자흐스탄" },
    { code: "KE", name: "Kenya", codeAlpha3: "KEN", twName: "肯尼亞", cname: "肯尼亚", koName: "케냐" },
    { code: "KI", name: "Kiribati", codeAlpha3: "KIR", twName: "基裏巴斯", cname: "基里巴斯", koName: "키리바시" },
    { code: "KP", name: "North Korea", codeAlpha3: "PRK", twName: "朝鮮", cname: "朝鲜", koName: "조선 민주주의 인민 공화국" },
    { code: "KR", name: "South Korea", codeAlpha3: "KOR", twName: "韓國", cname: "韩国", koName: "대한민국" },
    { code: "KW", name: "Kuwait", codeAlpha3: "KWT", twName: "科威特", cname: "科威特", koName: "쿠웨이트" },
    { code: "KG", name: "Kyrgyzstan", codeAlpha3: "KGZ", twName: "吉爾吉斯斯坦", cname: "吉尔吉斯斯坦", koName: "키르기즈스탄" },
    { code: "LA", name: "Lao People's Democratic Republic", codeAlpha3: "LAO", twName: "老撾", cname: "老挝", koName: "라오스 인민 민주주의 공화국" },
    { code: "LV", name: "Latvia", codeAlpha3: "LVA", twName: "拉脫維亞", cname: "拉脱维亚", koName: "라트비아" },
    { code: "LB", name: "Lebanon", codeAlpha3: "LBN", twName: "黎巴嫩", cname: "黎巴嫩", koName: "레바논" },
    { code: "LS", name: "Lesotho", codeAlpha3: "LSO", twName: "萊索托", cname: "莱索托", koName: "레소토" },
    { code: "LR", name: "Liberia", codeAlpha3: "LBR", twName: "利比裏亞", cname: "利比里亚", koName: "라이베리아" },
    { code: "LY", name: "Libya", codeAlpha3: "LBY", twName: "利比亞", cname: "利比亚", koName: "리비아 아랍 자 마히리 야" },
    { code: "LI", name: "Liechtenstein", codeAlpha3: "LIE", twName: "列支敦士登", cname: "列支敦士登", koName: "리히텐슈타인" },
    { code: "LT", name: "Lithuania", codeAlpha3: "LTU", twName: "立陶宛", cname: "立陶宛", koName: "리투아니아" },
    { code: "LU", name: "Luxembourg", codeAlpha3: "LUX", twName: "盧森堡", cname: "卢森堡", koName: "룩셈부르크" },
    { code: "MO", name: "Macao", codeAlpha3: "MAC", twName: "中國澳門", cname: "中国澳门", koName: "마카오" },
    { code: "MK", name: "North Macedonia, Republic of", codeAlpha3: "MKD", twName: "北馬其頓", cname: "北马其顿", koName: "북마케도니아 공화국" },
    { code: "MG", name: "Madagascar", codeAlpha3: "MDG", twName: "馬達加斯加", cname: "马达加斯加", koName: "마다가스카르" },
    { code: "MW", name: "Malawi", codeAlpha3: "MWI", twName: "馬拉維", cname: "马拉维", koName: "말라위" },
    { code: "MY", name: "Malaysia", codeAlpha3: "MYS", twName: "馬來西亞", cname: "马来西亚", koName: "말레이시아" },
    { code: "MV", name: "Maldives", codeAlpha3: "MDV", twName: "馬爾代夫", cname: "马尔代夫", koName: "몰디브" },
    { code: "ML", name: "Mali", codeAlpha3: "MLI", twName: "馬裏", cname: "马里", koName: "말리" },
    { code: "MT", name: "Malta", codeAlpha3: "MLT", twName: "馬耳他", cname: "马耳他", koName: "몰타" },
    { code: "MH", name: "Marshall Islands", codeAlpha3: "MHL", twName: "馬紹爾群島", cname: "马绍尔群岛", koName: "마샬 군도" },
    { code: "MQ", name: "Martinique", codeAlpha3: "MTQ", twName: "馬提尼克", cname: "马提尼克", koName: "마르티니크" },
    { code: "MR", name: "Mauritania", codeAlpha3: "MRT", twName: "毛裏塔尼亞", cname: "毛里塔尼亚", koName: "모리타니" },
    { code: "MU", name: "Mauritius", codeAlpha3: "MUS", twName: "毛裏求斯", cname: "毛里求斯", koName: "모리셔스" },
    { code: "YT", name: "Mayotte", codeAlpha3: "MYT", twName: "馬約特", cname: "马约特", koName: "마 요트" },
    { code: "MX", name: "Mexico", codeAlpha3: "MEX", twName: "墨西哥", cname: "墨西哥", koName: "멕시코" },
    { code: "FM", name: "Micronesia, Federated States of", codeAlpha3: "FSM", twName: "密克羅尼西亞聯邦", cname: "密克罗尼西亚联邦", koName: "미크로네시아," },
    { code: "MD", name: "Moldova, Republic of", codeAlpha3: "MDA", twName: "摩爾多瓦", cname: "摩尔多瓦", koName: "몰도바, 공화국" },
    { code: "MC", name: "Monaco", codeAlpha3: "MCO", twName: "摩納哥", cname: "摩纳哥", koName: "모나코" },
    { code: "MN", name: "Mongolia", codeAlpha3: "MNG", twName: "蒙古", cname: "蒙古", koName: "몽골리아" },
    { code: "ME", name: "Montenegro", codeAlpha3: "MNE", twName: "黑山", cname: "黑山", koName: "몬테네그로" },
    { code: "MS", name: "Montserrat", codeAlpha3: "MSR", twName: "蒙特塞拉特", cname: "蒙特塞拉特", koName: "몬세 라트" },
    { code: "MA", name: "Morocco", codeAlpha3: "MAR", twName: "摩洛哥", cname: "摩洛哥", koName: "모로코" },
    { code: "MZ", name: "Mozambique", codeAlpha3: "MOZ", twName: "莫桑比克", cname: "莫桑比克", koName: "모잠비크" },
    { code: "MM", name: "Myanmar", codeAlpha3: "MMR", twName: "緬甸", cname: "缅甸", koName: "미얀마" },
    { code: "NA", name: "Namibia", codeAlpha3: "NAM", twName: "納米比亞", cname: "纳米比亚", koName: "나미비아" },
    { code: "NR", name: "Nauru", codeAlpha3: "NRU", twName: "瑙魯", cname: "瑙鲁", koName: "나우루" },
    { code: "NP", name: "Nepal", codeAlpha3: "NPL", twName: "尼泊爾", cname: "尼泊尔", koName: "네팔" },
    { code: "NL", name: "Netherlands", codeAlpha3: "NLD", twName: "荷蘭", cname: "荷兰", koName: "네덜란드" },
    { code: "NC", name: "New Caledonia", codeAlpha3: "NCL", twName: "新喀裏多尼亞", cname: "新喀里多尼亚", koName: "뉴 칼레도니아" },
    { code: "NZ", name: "New Zealand", codeAlpha3: "NZL", twName: "新西蘭", cname: "新西兰", koName: "뉴질랜드" },
    { code: "NI", name: "Nicaragua", codeAlpha3: "NIC", twName: "尼加拉瓜", cname: "尼加拉瓜", koName: "니카라과" },
    { code: "NE", name: "Niger", codeAlpha3: "NER", twName: "尼日爾", cname: "尼日尔", koName: "니제르" },
    { code: "NG", name: "Nigeria", codeAlpha3: "NGA", twName: "尼日利亞", cname: "尼日利亚", koName: "나이지리아" },
    { code: "NU", name: "Niue", codeAlpha3: "NIU", twName: "紐埃", cname: "纽埃", koName: "니우에" },
    { code: "NF", name: "Norfolk Island", codeAlpha3: "NFK", twName: "諾福克島", cname: "诺福克岛", koName: "노퍽 섬" },
    { code: "MP", name: "Northern Mariana Islands", codeAlpha3: "MNP", twName: "北馬裏亞納群島", cname: "北马里亚纳群岛", koName: "북 마리아나 제도" },
    { code: "NO", name: "Norway", codeAlpha3: "NOR", twName: "挪威", cname: "挪威", koName: "노르웨이" },
    { code: "OM", name: "Oman", codeAlpha3: "OMN", twName: "阿曼", cname: "阿曼", koName: "오만" },
    { code: "PK", name: "Pakistan", codeAlpha3: "PAK", twName: "巴基斯坦", cname: "巴基斯坦", koName: "파키스탄" },
    { code: "PW", name: "Palau", codeAlpha3: "PLW", twName: "帕勞", cname: "帕劳", koName: "팔라우" },
    { code: "PS", name: "Palestinian Territory, Occupied", codeAlpha3: "PSE", twName: "巴勒斯坦", cname: "巴勒斯坦", koName: "팔레스타인 자치구, 점령 자" },
    { code: "PA", name: "Panama", codeAlpha3: "PAN", twName: "巴拿馬", cname: "巴拿马", koName: "파나마" },
    { code: "PG", name: "Papua New Guinea", codeAlpha3: "PNG", twName: "巴布亞新幾內亞", cname: "巴布亚新几内亚", koName: "파푸아 뉴기니" },
    { code: "PY", name: "Paraguay", codeAlpha3: "PRY", twName: "巴拉圭", cname: "巴拉圭", koName: "파라과이" },
    { code: "PE", name: "Peru", codeAlpha3: "PER", twName: "秘魯", cname: "秘鲁", koName: "페루" },
    { code: "PH", name: "Philippines", codeAlpha3: "PHL", twName: "菲律賓", cname: "菲律宾", koName: "필리핀 제도" },
    { code: "PN", name: "Pitcairn", codeAlpha3: "PCN", twName: "皮特凱恩群島", cname: "皮特凯恩群岛", koName: "핏 케언" },
    { code: "PL", name: "Poland", codeAlpha3: "POL", twName: "波蘭", cname: "波兰", koName: "폴란드" },
    { code: "PT", name: "Portugal", codeAlpha3: "PRT", twName: "葡萄牙", cname: "葡萄牙", koName: "포르투갈" },
    { code: "PR", name: "Puerto Rico", codeAlpha3: "PRI", twName: "波多黎各", cname: "波多黎各", koName: "푸에르토 리코" },
    { code: "QA", name: "Qatar", codeAlpha3: "QAT", twName: "卡塔爾", cname: "卡塔尔", koName: "카타르" },
    { code: "RE", name: "Reunion", codeAlpha3: "REU", twName: "留尼汪", cname: "留尼汪", koName: "재결합" },
    { code: "RO", name: "Romania", codeAlpha3: "ROU", twName: "羅馬尼亞", cname: "罗马尼亚", koName: "루마니아" },
    { code: "RU", name: "Russia", codeAlpha3: "RUS", twName: "俄羅斯", cname: "俄罗斯", koName: "러시아 연방" },
    { code: "RW", name: "Rwanda", codeAlpha3: "RWA", twName: "盧旺達", cname: "卢旺达", koName: "르완다" },
    { code: "BL", name: "Saint Barthélemy", codeAlpha3: "BLM", twName: "聖巴泰勒米", cname: "圣巴泰勒米", koName: "생 바르 텔레 미" },
    { code: "SH", name: "Saint Helena", codeAlpha3: "SHN", twName: "聖赫勒拿、阿森松和特裏斯坦-達庫尼亞", cname: "圣赫勒拿、阿森松和特里斯坦-达库尼亚", koName: "세인트 헬레나" },
    { code: "KN", name: "Saint Kitts and Nevis", codeAlpha3: "KNA", twName: "聖基茨和尼維斯", cname: "圣基茨和尼维斯", koName: "세인트 키츠 네비스" },
    { code: "LC", name: "Saint Lucia", codeAlpha3: "LCA", twName: "聖盧西亞", cname: "圣卢西亚", koName: "세인트 루시아" },
    { code: "MF", name: "Saint Martin (French part)", codeAlpha3: "MAF", twName: "法屬聖馬丁", cname: "法属圣马丁", koName: "세인트 마틴 (프랑스어 부분)" },
    { code: "PM", name: "Saint Pierre and Miquelon", codeAlpha3: "SPM", twName: "聖皮埃爾和密克隆", cname: "圣皮埃尔和密克隆", koName: "생 피에르 미 클롱" },
    { code: "VC", name: "Saint Vincent and the Grenadines", codeAlpha3: "VCT", twName: "聖文森特和格林納丁斯", cname: "圣文森特和格林纳丁斯", koName: "세인트 빈센트 그레나딘" },
    { code: "WS", name: "Samoa", codeAlpha3: "WSM", twName: "薩摩亞", cname: "萨摩亚", koName: "사모아" },
    { code: "SM", name: "San Marino", codeAlpha3: "SMR", twName: "聖馬力諾", cname: "圣马力诺", koName: "산 마리노" },
    { code: "ST", name: "Sao Tome and Principe", codeAlpha3: "STP", twName: "聖多美和普林西比", cname: "圣多美和普林西比", koName: "상투 메 프린시 페" },
    { code: "SA", name: "Saudi Arabia", codeAlpha3: "SAU", twName: "沙特阿拉伯", cname: "沙特阿拉伯", koName: "사우디 아라비아" },
    { code: "SN", name: "Senegal", codeAlpha3: "SEN", twName: "塞內加爾", cname: "塞内加尔", koName: "세네갈" },
    { code: "RS", name: "Serbia", codeAlpha3: "SRB", twName: "塞爾維亞", cname: "塞尔维亚", koName: "세르비아" },
    { code: "SC", name: "Seychelles", codeAlpha3: "SYC", twName: "塞舌爾", cname: "塞舌尔", koName: "세이셸" },
    { code: "SL", name: "Sierra Leone", codeAlpha3: "SLE", twName: "塞拉利昂", cname: "塞拉利昂", koName: "시에라 리온" },
    { code: "SG", name: "Singapore", codeAlpha3: "SGP", twName: "新加坡", cname: "新加坡", koName: "싱가포르" },
    { code: "SX", name: "Sint Maarten (Dutch part)", codeAlpha3: "SXM", twName: "荷屬聖馬丁", cname: "荷属圣马丁", koName: "신트 마틴 (네덜란드어 부분)" },
    { code: "SK", name: "Slovakia", codeAlpha3: "SVK", twName: "斯洛伐克", cname: "斯洛伐克", koName: "슬로바키아" },
    { code: "SI", name: "Slovenia", codeAlpha3: "SVN", twName: "斯洛文尼亞", cname: "斯洛文尼亚", koName: "슬로베니아" },
    { code: "SB", name: "Solomon Islands", codeAlpha3: "SLB", twName: "所羅門群島", cname: "所罗门群岛", koName: "솔로몬 제도" },
    { code: "SO", name: "Somalia", codeAlpha3: "SOM", twName: "索馬裏", cname: "索马里", koName: "소말리아" },
    { code: "ZA", name: "South Africa", codeAlpha3: "ZAF", twName: "南非", cname: "南非", koName: "남아프리카" },
    {
        code: "GS",
        name: "South Georgia and the South Sandwich Islands",
        codeAlpha3: "SGS",
        twName: "南喬治亞和南桑威奇群島",
        cname: "南乔治亚和南桑威奇群岛",
        koName: "사우스 조지아 및 사우스 샌드위치 제도",
    },
    { code: "SS", name: "South Sudan", codeAlpha3: "SSD", twName: "南蘇丹", cname: "南苏丹", koName: "남 수단" },
    { code: "ES", name: "Spain", codeAlpha3: "ESP", twName: "西班牙", cname: "西班牙", koName: "스페인" },
    { code: "LK", name: "Sri Lanka", codeAlpha3: "LKA", twName: "斯裏蘭卡", cname: "斯里兰卡", koName: "스리랑카" },
    { code: "SD", name: "Sudan", codeAlpha3: "SDN", twName: "蘇丹", cname: "苏丹", koName: "수단" },
    { code: "SR", name: "Suriname", codeAlpha3: "SUR", twName: "蘇裏南", cname: "苏里南", koName: "수리남" },
    { code: "SJ", name: "Svalbard and Jan Mayen", codeAlpha3: "SJM", twName: "斯瓦爾巴和揚馬延", cname: "斯瓦尔巴和扬马延", koName: "스발 바르와 얀 메이 엔" },
    { code: "SZ", name: "Eswatini", codeAlpha3: "SWZ", twName: "斯威士蘭", cname: "斯威士兰", koName: "스와질란드" },
    { code: "SE", name: "Sweden", codeAlpha3: "SWE", twName: "瑞典", cname: "瑞典", koName: "스웨덴" },
    { code: "CH", name: "Switzerland", codeAlpha3: "CHE", twName: "瑞士", cname: "瑞士", koName: "스위스" },
    { code: "SY", name: "Syrian Arab Republic", codeAlpha3: "SYR", twName: "敘利亞", cname: "叙利亚", koName: "시리아" },
    { code: "TJ", name: "Tajikistan", codeAlpha3: "TJK", twName: "塔吉克斯坦", cname: "塔吉克斯坦", koName: "타지키스탄" },
    { code: "TZ", name: "Tanzania, United Republic of", codeAlpha3: "TZA", twName: "坦桑尼亞", cname: "坦桑尼亚", koName: "탄자니아, 유엔" },
    { code: "TH", name: "Thailand", codeAlpha3: "THA", twName: "泰國", cname: "泰国", koName: "태국" },
    { code: "TL", name: "Timor-Leste", codeAlpha3: "TLS", twName: "東帝汶", cname: "东帝汶", koName: "동 티모르" },
    { code: "TG", name: "Togo", codeAlpha3: "TGO", twName: "多哥", cname: "多哥", koName: "가다" },
    { code: "TK", name: "Tokelau", codeAlpha3: "TKL", twName: "托克勞", cname: "托克劳", koName: "토켈 라우" },
    { code: "TO", name: "Tonga", codeAlpha3: "TON", twName: "湯加", cname: "汤加", koName: "통가" },
    { code: "TT", name: "Trinidad and Tobago", codeAlpha3: "TTO", twName: "特立尼達和多巴哥", cname: "特立尼达和多巴哥", koName: "트리니다드 토바고" },
    { code: "TN", name: "Tunisia", codeAlpha3: "TUN", twName: "突尼斯", cname: "突尼斯", koName: "튀니지" },
    { code: "TR", name: "Turkey", codeAlpha3: "TUR", twName: "土耳其", cname: "土耳其", koName: "터키" },
    { code: "TM", name: "Turkmenistan", codeAlpha3: "TKM", twName: "土庫曼斯坦", cname: "土库曼斯坦", koName: "투르크 메니스탄" },
    { code: "TC", name: "Turks and Caicos Islands", codeAlpha3: "TCA", twName: "特克斯和凱科斯群島", cname: "特克斯和凯科斯群岛", koName: "터크 스 케이 커스 제도" },
    { code: "TV", name: "Tuvalu", codeAlpha3: "TUV", twName: "圖瓦盧", cname: "图瓦卢", koName: "투발루" },
    { code: "UG", name: "Uganda", codeAlpha3: "UGA", twName: "烏幹達", cname: "乌干达", koName: "우간다" },
    { code: "UA", name: "Ukraine", codeAlpha3: "UKR", twName: "烏克蘭", cname: "乌克兰", koName: "우크라이나" },
    { code: "AE", name: "United Arab Emirates", codeAlpha3: "ARE", twName: "阿聯酋", cname: "阿联酋", koName: "아랍 에미리트" },
    { code: "GB", name: "UK", codeAlpha3: "GBR", twName: "英國", cname: "英国", koName: "영국" },
    { code: "UM", name: "United States Minor Outlying Islands", codeAlpha3: "UMI", twName: "美國本土外小島嶼", cname: "美国本土外小岛屿", koName: "미국령 군소 제도" },
    { code: "UY", name: "Uruguay", codeAlpha3: "URY", twName: "烏拉圭", cname: "乌拉圭", koName: "우루과이" },
    { code: "UZ", name: "Uzbekistan", codeAlpha3: "UZB", twName: "烏茲別克斯坦", cname: "乌兹别克斯坦", koName: "우즈베키스탄" },
    { code: "VU", name: "Vanuatu", codeAlpha3: "VUT", twName: "瓦努阿圖", cname: "瓦努阿图", koName: "바누아투" },
    { code: "VE", name: "Venezuela", codeAlpha3: "VEN", twName: "委內瑞拉", cname: "委内瑞拉", koName: "베네수엘라" },
    { code: "VN", name: "Vietnam", codeAlpha3: "VNM", twName: "越南", cname: "越南", koName: "베트남" },
    { code: "VG", name: "Virgin Islands, British", codeAlpha3: "VGB", twName: "英屬維爾京群島", cname: "英属维尔京群岛", koName: "영국령 버진 아일랜드" },
    { code: "VI", name: "Virgin Islands, U.S.", codeAlpha3: "VIR", twName: "美屬維爾京群島", cname: "美属维尔京群岛", koName: "미국령 버진 아일랜드" },
    { code: "WF", name: "Wallis and Futuna", codeAlpha3: "WLF", twName: "瓦利斯和富圖納", cname: "瓦利斯和富图纳", koName: "월리스 푸 투나" },
    { code: "EH", name: "Western Sahara", codeAlpha3: "ESH", twName: "阿拉伯撒哈拉民主共和國", cname: "阿拉伯撒哈拉民主共和国", koName: "서사하라" },
    { code: "YE", name: "Yemen", codeAlpha3: "YEM", twName: "也門", cname: "也门", koName: "예멘 아랍 공화국" },
    { code: "ZM", name: "Zambia", codeAlpha3: "ZMB", twName: "贊比亞", cname: "赞比亚", koName: "잠비아" },
    { code: "ZW", name: "Zimbabwe", codeAlpha3: "ZWE", twName: "津巴布韋", cname: "津巴布韦", koName: "짐바브웨" },
];

// 支持circle的国家
export const CIRCEL_SUPPORT_WIRE_COUNTRY_CODE = [
    "AD",
    "AO",
    "AI",
    "AR",
    "AU",
    "AT",
    "AZ",
    "BE",
    "BJ",
    "BM",
    "BR",
    "BG",
    "BF",
    "KH",
    "CM",
    "CA",
    "TD",
    "CL",
    "HR",
    "DK",
    "EE",
    "FI",
    "FR",
    "DE",
    "GI",
    "GR",
    "GU",
    "GT",
    "GG",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IE",
    "IM",
    "IL",
    "IT",
    "JP",
    "JE",
    "JO",
    "KE",
    "LV",
    "LI",
    "LT",
    "LU",
    "MG",
    "MO",
    "MW",
    "MY",
    "MT",
    "MX",
    "MC",
    "MZ",
    "NZ",
    "NO",
    "PE",
    "PL",
    "PT",
    "PR",
    "RO",
    "SM",
    "SN",
    "SG",
    "SK",
    "SI",
    "ZA",
    "ES",
    "SE",
    "CH",
    "TH",
    "TR",
    "US",
    "UY",
    "VA",
    "KR",
    "NL",
    "NE",
    "PH",
    "TW",
    "TZ",
    "AE",
    "UM",
    "GB",
];
// 不支持支持circle的国家
export const FORBIN_COUNTRY_CODE = ["BI", "CF", "CU", "IR", "IQ", "LB", "LY", "KP", "SO", "SS", "SD", "SY", "VE", "YE", "AF", "CD", "ZW", "CN"];
// 支持支持iban的国家
export const SUPPORT_IBAN_COUNTRY_CODE = [
    "AL",
    "AD",
    "AT",
    "AZ",
    "BH",
    "BY",
    "BE",
    "BA",
    "BR",
    "BG",
    "CR",
    "HR",
    "CY",
    "CZ",
    "DK",
    "DO",
    "EG",
    "SV",
    "EE",
    "FO",
    "FI",
    "FR",
    "GE",
    "DE",
    "GI",
    "GR",
    "GL",
    "GT",
    "VA",
    "HU",
    "IS",
    "IQ",
    "IE",
    "IL",
    "IT",
    "JO",
    "KZ",
    "XK",
    "KW",
    "LV",
    "LB",
    "LI",
    "LT",
    "LU",
    "MT",
    "MR",
    "MU",
    "MD",
    "MC",
    "ME",
    "NL",
    "MK",
    "NO",
    "PK",
    "PS",
    "PL",
    "PT",
    "QA",
    "RO",
    "LC",
    "SM",
    "ST",
    "SA",
    "RS",
    "SC",
    "SK",
    "SI",
    "ES",
    "SE",
    "CH",
    "TL",
    "TN",
    "TR",
    "UA",
    "AE",
    "GB",
    "VG",
];

export interface AbaInfo {
    aba: string;
    name: string;
}

export const abasInfo: AbaInfo[] = [
    { aba: "101000019", name: "Commerce Bank" },
    { aba: "101000035", name: "Bank Of America, N.a." },
    { aba: "101000048", name: "Federal Reserve Bank" },
    { aba: "101000077", name: "Umb, Na" },
    { aba: "101000158", name: "Bokf Na" },
    { aba: "101000187", name: "Us Bank Na" },
    { aba: "101000695", name: "Umb, Na" },
    { aba: "101000789", name: "Nbh Bank" },
    { aba: "101000925", name: "Security Bank Of Kansas City" },
    { aba: "101000967", name: "Security Bank Of Kansas City" },
    { aba: "101001005", name: "Community First Bank" },
    { aba: "101001018", name: "Security Bank Of Kansas City" },
    { aba: "101001047", name: "Bank Of Labor" },
    { aba: "101001089", name: "Liberty Bank & Trust Co." },
    { aba: "101001173", name: "Bank Of America, N.a." },
    { aba: "101001283", name: "Central Bank Kansas City" },
    { aba: "101001306", name: "Country Club Bank" },
    { aba: "101001351", name: "Security Bank Of Kansas City" },
    { aba: "101001364", name: "Blue Ridge Bank & Trust Co" },
    { aba: "101001720", name: "Enterprise Bank And Trust" },
    { aba: "101001911", name: "Bank Of America N.a." },
    { aba: "101002716", name: "Nbh Bank" },
    { aba: "101002855", name: "Great American Bank" },
    { aba: "101002910", name: "Brotherhood Bank & Trust" },
    { aba: "101002949", name: "Nbh Bank" },
    { aba: "101003045", name: "Anb Bank" },
    { aba: "101003317", name: "First National Bank Of Omaha" },
    { aba: "101003773", name: "Community Bank Of Raymore" },
    { aba: "101004280", name: "Security Bank Of Kansas City" },
    { aba: "101005027", name: "Bank Of Blue Valley" },
    { aba: "101005360", name: "Central Bank Of The Midwest" },
    { aba: "101005483", name: "Central Bank Of The Midwest" },
    { aba: "101005690", name: "Nbh Bank" },
    { aba: "101006039", name: "Bmo Harris Bank Na" },
    { aba: "101006699", name: "Nbh Bank" },
    { aba: "101006725", name: "Security Bank Of Kansas City" },
    { aba: "101007041", name: "Nbh Bank" },
    { aba: "101010672", name: "Central Bank Of The Midwest" },
    { aba: "101011244", name: "Country Club Bank" },
    { aba: "101011998", name: "Security Bank Of Kansas City" },
    { aba: "101012939", name: "Central Bank Of The Midwest" },
    { aba: "101014733", name: "Central Bank Of The Midwest" },
    { aba: "101014869", name: "Nbkc Bank" },
    { aba: "101014924", name: "First Business Bank" },
    { aba: "101014953", name: "Cornerstone Bank" },
    { aba: "101014966", name: "Crossfirst Bank" },
    { aba: "101015020", name: "Central Bank Of The Midwest" },
    { aba: "101015046", name: "Bank Of Prairie Village" },
    { aba: "101015088", name: "Freedom Bank" },
    { aba: "101015101", name: "Bokf, N.a." },
    { aba: "101015282", name: "Crossfirst Bank" },
    { aba: "101019068", name: "Central Bank Of The Midwest" },
    { aba: "101019084", name: "Central Bank Of Kansas City" },
    { aba: "101019194", name: "Citizens Bank And Trust Company" },
    { aba: "101019482", name: "Academy Bank Na" },
    { aba: "101019521", name: "Central Bank Of Kansas City" },
    { aba: "101050001", name: "The Vault Federal Reserve Bank Kc" },
    { aba: "101050014", name: "Network Service Center" },
    { aba: "101059950", name: "District Facilities - Frb Kc" },
    { aba: "101059992", name: "Public Affairs-federal Reserve Kc" },
    { aba: "101089292", name: "Wells Fargo Bank" },
    { aba: "101089496", name: "The Federal Savings Bank" },
    { aba: "101089700", name: "Axos Bank" },
    { aba: "101089742", name: "Metabank, Na" },
    { aba: "101089807", name: "Cibc Bank Usa" },
    { aba: "101089810", name: "First Citizens Bank & Trust Company" },
    { aba: "101089823", name: "First Citizens Bank & Trust Company" },
    { aba: "101089865", name: "Equity Bank" },
    { aba: "101100016", name: "Exchange Bank & Trust" },
    { aba: "101100029", name: "Intrust Bank, Na" },
    { aba: "101100045", name: "Bank Of America N.a." },
    { aba: "101100100", name: "Country Club Bank" },
    { aba: "101100113", name: "Exchange Bank & Trust" },
    { aba: "101100142", name: "Kaw Valley Bank" },
    { aba: "101100171", name: "The First National Bank Hutchinson" },
    { aba: "101100223", name: "Rcb Bank" },
    { aba: "101100265", name: "Bmo Harris Bank Na" },
    { aba: "101100317", name: "Community State Bank" },
    { aba: "101100375", name: "Commercial Bank" },
    { aba: "101100540", name: "Commerce Bank" },
    { aba: "101100566", name: "Landmark National Bank" },
    { aba: "101100579", name: "Emprise Bank" },
    { aba: "101100605", name: "The City State Bank" },
    { aba: "101100618", name: "Southwest National Bank" },
    { aba: "101100621", name: "Sunflower Bank, N.a." },
    { aba: "101100634", name: "Umb, Na" },
    { aba: "101100676", name: "Fidelity State Bank Topeka" },
    { aba: "101100692", name: "Bank Of Commerce" },
    { aba: "101100728", name: "Corefirst Bank & Trust" },
    { aba: "101100760", name: "Esb Financial" },
    { aba: "101100786", name: "Lyon County State Bank" },
    { aba: "101100854", name: "Intrust Bank, Na" },
    { aba: "101100906", name: "Union State Bank" },
    { aba: "101100922", name: "Nbh Bank" },
    { aba: "101100964", name: "Kansas State Bank" },
    { aba: "101100993", name: "Union State Bank" },
    { aba: "101101015", name: "Sunflower Bank, Na" },
    { aba: "101101031", name: "Impact Bank" },
    { aba: "101101057", name: "Security State Bank" },
    { aba: "101101060", name: "The Bank Of Commerce & Trust Co" },
    { aba: "101101109", name: "Rcb Bank" },
    { aba: "101101141", name: "Capitol Federal Savings" },
    { aba: "101101154", name: "Treasurer Of The State Of Kansas" },
    { aba: "101101219", name: "Southwest National Bank" },
    { aba: "101101222", name: "Armed Forces Bank" },
    { aba: "101101248", name: "Landmark National Bank" },
    { aba: "101101293", name: "Central National Bank" },
    { aba: "101101303", name: "Exchange Bank" },
    { aba: "101101413", name: "Garden Plain State Bank" },
    { aba: "101101439", name: "Peoples State Bank" },
    { aba: "101101471", name: "Community State Bank" },
    { aba: "101101536", name: "Ks Statebank" },
    { aba: "101101552", name: "Security Bank Of Kansas City" },
    { aba: "101101581", name: "Peoples Bank & Trust Co" },
    { aba: "101101594", name: "The Citizens State Bank" },
    { aba: "101101620", name: "Union State Bank" },
    { aba: "101101659", name: "The Peoples Bank" },
    { aba: "101101662", name: "Legacy Bank" },
    { aba: "101101730", name: "Fidelity State Bank" },
    { aba: "101101769", name: "Great Southern Bank" },
    { aba: "101101785", name: "Security Bank Of Kansas City" },
    { aba: "101101866", name: "First Bank Of Beloit" },
    { aba: "101101879", name: "Guaranty State Bank & Tr Co Beloit" },
    { aba: "101101921", name: "First National Bank In Fredonia" },
    { aba: "101101934", name: "Firstoak Bank" },
    { aba: "101101947", name: "Federal Home Loan Bank-topeka" },
    { aba: "101101950", name: "Bank Of Blue Valley" },
    { aba: "101101976", name: "Citizens State Bank & Trust Co" },
    { aba: "101101989", name: "Farmers Bank & Trust" },
    { aba: "101101992", name: "First State Bank & Trust" },
    { aba: "101102014", name: "Bank Of Commerce" },
    { aba: "101102069", name: "Girard National Bank-holton" },
    { aba: "101102072", name: "Simmons Bank" },
    { aba: "101102085", name: "Kanza Bank" },
    { aba: "101102108", name: "Citizens Bank Of Kansas" },
    { aba: "101102124", name: "Kanza Bank" },
    { aba: "101102137", name: "Enterprise Bank And Trust" },
    { aba: "101102166", name: "Farmers & Drovers Bank" },
    { aba: "101102182", name: "Gnbank, National Association" },
    { aba: "101102221", name: "Goppert State Service Bank" },
    { aba: "101102289", name: "Home Bank & Trust Co" },
    { aba: "101102315", name: "Bmo Harris Bank Na" },
    { aba: "101102331", name: "Citizens State Bank" },
    { aba: "101102344", name: "Astra Bank" },
    { aba: "101102360", name: "American State Bank & Trust Co." },
    { aba: "101102373", name: "Stock Exchange Bank" },
    { aba: "101102386", name: "Impact Bank" },
    { aba: "101102438", name: "First Bank" },
    { aba: "101102496", name: "Citizens State Bank & Trust" },
    { aba: "101102522", name: "Girard National Bank" },
    { aba: "101102548", name: "Fnb Bank" },
    { aba: "101102551", name: "Bankwest Of Kansas" },
    { aba: "101102564", name: "First Kansas Bank" },
    { aba: "101102645", name: "Prairie Bank Of Kansas" },
    { aba: "101102700", name: "Marion National Bank" },
    { aba: "101102768", name: "First State Bank" },
    { aba: "101102784", name: "Sjn Bank Of Kansas" },
    { aba: "101102836", name: "Equity Bank" },
    { aba: "101102852", name: "Bank Of The Flint Hills" },
    { aba: "101102865", name: "Western State Bank" },
    { aba: "101102878", name: "Citizens Bank Of Kansas" },
    { aba: "101102920", name: "Chisholm Trail State Bank" },
    { aba: "101102933", name: "Baxter State Bank" },
    { aba: "101102959", name: "First Option Bank" },
    { aba: "101102962", name: "The Peoples Bank" },
    { aba: "101102988", name: "Bank Of Commerce" },
    { aba: "101103026", name: "Fnb Washington" },
    { aba: "101103068", name: "Alva State Bank And Trust Company" },
    { aba: "101103084", name: "The Bank Of Protection" },
    { aba: "101103149", name: "State Bank Of Downs" },
    { aba: "101103152", name: "State Bank Of Downs" },
    { aba: "101103220", name: "Vintage Bank Kansas" },
    { aba: "101103233", name: "Equity Bank" },
    { aba: "101103275", name: "Baldwin State Bank" },
    { aba: "101103327", name: "Solutions North Bank" },
    { aba: "101103356", name: "First National Bank & Trust" },
    { aba: "101103385", name: "Conway Bank" },
    { aba: "101103398", name: "Vintage Bank Kansas" },
    { aba: "101103408", name: "Guaranty State Bank & Tr Co Beloit" },
    { aba: "101103466", name: "First National Bank Of Sedan" },
    { aba: "101103521", name: "American State Bank & Trust Co" },
    { aba: "101103547", name: "Howard State Bank" },
    { aba: "101103550", name: "Bank Of Commerce" },
    { aba: "101103592", name: "State Exchange Bank Of Mankato" },
    { aba: "101103660", name: "Kendall Bank" },
    { aba: "101103686", name: "First National Bank Of Syracuse" },
    { aba: "101103699", name: "Valley State Bank Of Syracuse" },
    { aba: "101103725", name: "Carson Bank" },
    { aba: "101103754", name: "Elk State Bank" },
    { aba: "101103835", name: "Halstead Bank" },
    { aba: "101103880", name: "Wilson State Bank" },
    { aba: "101103974", name: "Solomon State Bank" },
    { aba: "101104009", name: "First Option Bank" },
    { aba: "101104012", name: "Exchange Bank & Trust" },
    { aba: "101104041", name: "First National Bank Of Scott City" },
    { aba: "101104067", name: "Stockgrowers State Bank" },
    { aba: "101104070", name: "The Bank Of Protection" },
    { aba: "101104096", name: "Citizens State Bank" },
    { aba: "101104122", name: "Farmers & Merchants Bank" },
    { aba: "101104164", name: "Valley State Bank" },
    { aba: "101104287", name: "Flint Hills Bank" },
    { aba: "101104342", name: "Farmers State Bank Of Wathena" },
    { aba: "101104384", name: "Bendena State Bank" },
    { aba: "101104407", name: "Lyndon State Bank" },
    { aba: "101104436", name: "First National Bank Of Kansas" },
    { aba: "101104494", name: "Citizens State Bank" },
    { aba: "101104504", name: "Community National Bank" },
    { aba: "101104562", name: "Cobank Acb" },
    { aba: "101104588", name: "Marquette Farmers State Bank" },
    { aba: "101104630", name: "Dickinson County Bank" },
    { aba: "101104685", name: "Almena State Bank" },
    { aba: "101104708", name: "Girard National Bank" },
    { aba: "101104711", name: "Farmers State Bank Of Bucklin" },
    { aba: "101104740", name: "State Bank Of Canton" },
    { aba: "101104766", name: "The Bank Of Protection" },
    { aba: "101104779", name: "Peoples Bank" },
    { aba: "101104795", name: "Farmers State Bank Of Oakley" },
    { aba: "101104805", name: "Bankers' Bank Of Kansas" },
    { aba: "101104863", name: "First Heritage Bank" },
    { aba: "101104928", name: "Bennington State Bank" },
    { aba: "101104944", name: "Landmark National Bank" },
    { aba: "101104973", name: "Kaw Valley State Bank" },
    { aba: "101105079", name: "The Citizens State Bank" },
    { aba: "101105082", name: "Legacy Bank" },
    { aba: "101105118", name: "Small Business Bank" },
    { aba: "101105244", name: "Heartland Tri-state Bank" },
    { aba: "101105257", name: "First National Bank In Cimarron" },
    { aba: "101105286", name: "Ford County State Bank" },
    { aba: "101105299", name: "First National Bank Of Spearville" },
    { aba: "101105354", name: "Equity Bank" },
    { aba: "101105419", name: "Plains State Bank" },
    { aba: "101105558", name: "Bank Of Commerce" },
    { aba: "101105590", name: "Equity Bank" },
    { aba: "101105600", name: "First National Bank Of Hutchinson" },
    { aba: "101105626", name: "The First National Bank Hutchinson" },
    { aba: "101105668", name: "Vintage Bank Kansas" },
    { aba: "101105684", name: "Central Bank Of The Midwest" },
    { aba: "101105765", name: "Kansas State Bank Of Overbrook" },
    { aba: "101105778", name: "First Security Bank Of Overbrook" },
    { aba: "101105781", name: "Farmers & Drovers Bank" },
    { aba: "101105817", name: "Community National Bank & Trust" },
    { aba: "101105927", name: "Farmers State Bank" },
    { aba: "101105969", name: "The Bank" },
    { aba: "101105985", name: "Fowler State Bank" },
    { aba: "101106023", name: "Guaranty State Bank & Tr Co Belot" },
    { aba: "101106214", name: "Union State Bank" },
    { aba: "101106227", name: "Bank Of Blue Valley" },
    { aba: "101106269", name: "Acedemy Bank" },
    { aba: "101106285", name: "The Bank Of Tescott" },
    { aba: "101106324", name: "Equity Bank" },
    { aba: "101106353", name: "Southwind Bank" },
    { aba: "101106379", name: "Citizens State Bank" },
    { aba: "101106405", name: "Patriots Bank" },
    { aba: "101106492", name: "Bank Of Commerce" },
    { aba: "101106528", name: "Peoples State Bank" },
    { aba: "101106560", name: "First National Bank" },
    { aba: "101106609", name: "Riley State Bank" },
    { aba: "101106625", name: "Kearny County Bank" },
    { aba: "101106751", name: "Community Bank" },
    { aba: "101106861", name: "Piqua State Bank" },
    { aba: "101106913", name: "Peoples State Bank" },
    { aba: "101106939", name: "Union State Bank" },
    { aba: "101106942", name: "Tri Century Bank" },
    { aba: "101107080", name: "Farmers Bank & Trust" },
    { aba: "101107093", name: "Alden State Bank, Sterling Branch" },
    { aba: "101107116", name: "Farmers State Bank Of Aliceville" },
    { aba: "101107158", name: "Kansasland Bank" },
    { aba: "101107200", name: "Conway Bank" },
    { aba: "101107226", name: "Umb, Na" },
    { aba: "101107297", name: "Elk State Bank" },
    { aba: "101107404", name: "Bendena State Bank" },
    { aba: "101107446", name: "State Bank Of Bern" },
    { aba: "101107475", name: "Bison State Bank" },
    { aba: "101107624", name: "Emerald Bank" },
    { aba: "101107747", name: "Cottonwood Valley Bank" },
    { aba: "101107802", name: "Farmers State Bank" },
    { aba: "101107860", name: "Community Bank Of The Midwest" },
    { aba: "101107886", name: "Legacy Bank" },
    { aba: "101108034", name: "Denison State Bank" },
    { aba: "101108050", name: "The Bank Of Denton" },
    { aba: "101108209", name: "Firstoak Bank" },
    { aba: "101108319", name: "Armed Forces Bank" },
    { aba: "101108348", name: "Bank Of Commerce And Trust Co" },
    { aba: "101108377", name: "Bank Of Commerce" },
    { aba: "101108380", name: "Farmers State Bank" },
    { aba: "101108490", name: "Gorham State Bank" },
    { aba: "101108513", name: "The Bank" },
    { aba: "101108526", name: "Bank Of Greeley" },
    { aba: "101108571", name: "Bank Of Hays" },
    { aba: "101108636", name: "Haviland State Bank" },
    { aba: "101108649", name: "First State Bank Of Healy" },
    { aba: "101108762", name: "Bank Of Holyrood" },
    { aba: "101109130", name: "Vintage Bank Kansas" },
    { aba: "101109156", name: "Citizens State Bank" },
    { aba: "101109237", name: "Lorraine State Bank" },
    { aba: "101109321", name: "Stockgrowers State Bank" },
    { aba: "101109512", name: "Farmers & Merchants Bank" },
    { aba: "101109538", name: "New Century Bank" },
    { aba: "101109648", name: "Grant County Bank" },
    { aba: "101109693", name: "Girard National Bank" },
    { aba: "101109745", name: "Olpe State Bank" },
    { aba: "101109800", name: "Astra Bank" },
    { aba: "101109813", name: "Bank Of Palmer" },
    { aba: "101109842", name: "Simmons Bank" },
    { aba: "101109923", name: "Plains State Bank" },
    { aba: "101109994", name: "Prescott State Bank" },
    { aba: "101110064", name: "Hillsboro State Bank" },
    { aba: "101110080", name: "First State Bank Of Ransom" },
    { aba: "101110213", name: "American State Bank & Trust Co" },
    { aba: "101110226", name: "Citizens State Bank & Trust Co" },
    { aba: "101110271", name: "Centera Bank" },
    { aba: "101110404", name: "Silver Lake Bank" },
    { aba: "101110475", name: "State Bank Of Spring Hill" },
    { aba: "101110488", name: "Stanley Bank" },
    { aba: "101110543", name: "Farmers State Bank" },
    { aba: "101110572", name: "Tampa State Bank" },
    { aba: "101110608", name: "Towanda State Bank" },
    { aba: "101110640", name: "Union State Bank" },
    { aba: "101110763", name: "Walton State Bank" },
    { aba: "101110802", name: "Cbw Bank" },
    { aba: "101111115", name: "Astra Bank" },
    { aba: "101111319", name: "Bank 7" },
    { aba: "101111335", name: "Swedish-american State Bank" },
    { aba: "101111348", name: "Citizens State Bank" },
    { aba: "101111351", name: "Citizens State Bank" },
    { aba: "101111380", name: "Kaw Valley State Bank & Trust Co" },
    { aba: "101111458", name: "Johnson State Bank" },
    { aba: "101111490", name: "Farmers State Bank" },
    { aba: "101111623", name: "Country Club Bank" },
    { aba: "101111678", name: "Farmers Bank & Trust" },
    { aba: "101111681", name: "Exchange State Bank" },
    { aba: "101111704", name: "Labette Bank" },
    { aba: "101111953", name: "Citizens National Bank" },
    { aba: "101112046", name: "Andover State Bank" },
    { aba: "101112172", name: "Bank Of Hays" },
    { aba: "101112224", name: "Sjn Bank Of Kansas" },
    { aba: "101112318", name: "First State Bank" },
    { aba: "101112473", name: "Western National Bank" },
    { aba: "101112635", name: "American Bank" },
    { aba: "101112716", name: "Farmers State Bank" },
    { aba: "101112842", name: "Gnbank Na" },
    { aba: "101112936", name: "Emprise Bank" },
    { aba: "101113074", name: "Labette Bank" },
    { aba: "101113427", name: "Almena State Bank" },
    { aba: "101113676", name: "Farmers State Bank" },
    { aba: "101113799", name: "Citizens State Bank And Trust Co" },
    { aba: "101113812", name: "First National Bank" },
    { aba: "101113841", name: "First National Bank" },
    { aba: "101113867", name: "Farmers National Bank" },
    { aba: "101113935", name: "First National Bank In Frankfort" },
    { aba: "101114031", name: "First National Bank" },
    { aba: "101114044", name: "Labette Bank" },
    { aba: "101114109", name: "First Option Bank" },
    { aba: "101114219", name: "Sjn Bank Of Kansas" },
    { aba: "101114277", name: "First National Bank Of Cunningham" },
    { aba: "101114303", name: "First State Bank & Trust" },
    { aba: "101114390", name: "Bank Of The Flint Hills" },
    { aba: "101114400", name: "Astra Bank" },
    { aba: "101114442", name: "Peoples State Bank" },
    { aba: "101114507", name: "Peoples Bank And Trust Company" },
    { aba: "101114552", name: "First National Bank Hope" },
    { aba: "101114565", name: "State Bank Of Burrton" },
    { aba: "101114581", name: "Community National Bank & Trust" },
    { aba: "101114646", name: "Central Bank Of The Midwest" },
    { aba: "101114659", name: "First Bank Kansas" },
    { aba: "101114691", name: "Security State Bank" },
    { aba: "101114769", name: "The Community Bank" },
    { aba: "101114772", name: "Farmers And Merchants" },
    { aba: "101114798", name: "Alliance Bank" },
    { aba: "101114840", name: "Girard National Bank" },
    { aba: "101114853", name: "Girard National Bank" },
    { aba: "101114879", name: "Community Bank Of Wichita" },
    { aba: "101114895", name: "Great American Bank" },
    { aba: "101114905", name: "Kearny County Bank" },
    { aba: "101114918", name: "Community First National Bank" },
    { aba: "101114934", name: "Mid America Bank" },
    { aba: "101114947", name: "American State Bank & Trust Co" },
    { aba: "101114976", name: "First Commerce Bank" },
    { aba: "101114992", name: "Commerce Bank" },
    { aba: "101115001", name: "Heritage Bank" },
    { aba: "101115030", name: "United Bank & Trust" },
    { aba: "101115056", name: "Visionbank" },
    { aba: "101115072", name: "Central National Bank" },
    { aba: "101115098", name: "Commercial Bank" },
    { aba: "101115111", name: "First National Bank" },
    { aba: "101115140", name: "Intrust Bank National Association" },
    { aba: "101115179", name: "Kansas State Bank" },
    { aba: "101115218", name: "Exchange Bank & Trust" },
    { aba: "101115292", name: "Brilliant Bank" },
    { aba: "101115302", name: "Cbw Bank" },
    { aba: "101115328", name: "Corefirst Bank & Trust" },
    { aba: "101189121", name: "University National Bank Of Lawrence" },
    { aba: "101189590", name: "Simmons Bank" },
    { aba: "101200453", name: "Us Bank Na" },
    { aba: "101200518", name: "Umb, Na" },
    { aba: "101200958", name: "Umb, Na" },
    { aba: "101201164", name: "First National Bank" },
    { aba: "101201601", name: "1st Cameron State Bank" },
    { aba: "101201863", name: "Nodaway Valley Bank" },
    { aba: "101201892", name: "Central Bank Of The Midwest" },
    { aba: "101202257", name: "Community Bank & Trust" },
    { aba: "101202503", name: "Farmers State Bank" },
    { aba: "101202875", name: "Lamar Bank & Trust Company" },
    { aba: "101203515", name: "Country Club Bank" },
    { aba: "101203641", name: "Southwest Missouri Bank" },
    { aba: "101203971", name: "Bank Of Versailles" },
    { aba: "101204022", name: "Community Bank" },
    { aba: "101204792", name: "St. Clair County State Bank" },
    { aba: "101204886", name: "Citizens Bank & Trust" },
    { aba: "101204954", name: "Goppert Financial Bank" },
    { aba: "101205050", name: "Bank Of Weston" },
    { aba: "101205063", name: "Community First Bank" },
    { aba: "101205128", name: "Home Exchange Bank" },
    { aba: "101205131", name: "The Wells Bank Of Platte City" },
    { aba: "101205445", name: "Citizens-farmers Bank Of Cole Camp" },
    { aba: "101205678", name: "Hawthorn Bank" },
    { aba: "101205681", name: "Umb, Na" },
    { aba: "101205940", name: "Wells Bank" },
    { aba: "101206101", name: "Lead Bank" },
    { aba: "101206279", name: "Farmers State Bank" },
    { aba: "101206295", name: "Southwest Missouri Bank" },
    { aba: "101206389", name: "Commercial Bank Of Oak Grove" },
    { aba: "101206457", name: "Kearney Trust Company" },
    { aba: "101206460", name: "Academy Bank Na" },
    { aba: "101206800", name: "Limebank" },
    { aba: "101206994", name: "Peoples Bank Of Seneca" },
    { aba: "101207087", name: "Hawthorn Bank" },
    { aba: "101207210", name: "Corner Stone Bank" },
    { aba: "101207773", name: "First Security Bank" },
    { aba: "101207919", name: "Bank Northwest" },
    { aba: "101208031", name: "Community National Bank & Trust" },
    { aba: "101209412", name: "Sherwood Community Bank" },
    { aba: "101209768", name: "Btc Bank" },
    { aba: "101209797", name: "Central Bank Of The Midwest" },
    { aba: "101210090", name: "Bank Of Grain Valley" },
    { aba: "101211031", name: "Metz Banking Company" },
    { aba: "101211989", name: "Exchange Bank & Trust" },
    { aba: "101212182", name: "Great Southern Bank" },
    { aba: "101213000", name: "Community National Bank & Trust" },
    { aba: "101213673", name: "Citizens Bank" },
    { aba: "101216683", name: "Independent Farmers Bank" },
    { aba: "101217433", name: "Hnb National Bank" },
    { aba: "101217514", name: "Citizens Bank" },
    { aba: "101217750", name: "Citizens Bank" },
    { aba: "101217857", name: "Adrian Bank" },
    { aba: "101217886", name: "Central Bank Of The Midwest" },
    { aba: "101218801", name: "Guaranty Bank" },
    { aba: "101218856", name: "Umb, Na" },
    { aba: "101219017", name: "Umb, Na" },
    { aba: "101219059", name: "Nbh Bank" },
    { aba: "101219279", name: "Community Bank Of Pleasant Hill" },
    { aba: "101219389", name: "Central Bank Of The Midwest" },
    { aba: "101219415", name: "Wells Bank Of Platte City" },
    { aba: "101289432", name: "Arvest Bank" },
    { aba: "101900588", name: "Central Bank Of Sedalia" },
    { aba: "101901396", name: "Citizens Bank And Trust Company" },
    { aba: "101901419", name: "Chillicothe State Bank" },
    { aba: "101901820", name: "Bank Of America N.a." },
    { aba: "101901833", name: "Wood & Huston Bank" },
    { aba: "101902052", name: "Central Bank Of Sedalia" },
    { aba: "101902094", name: "Simmons First Natl Bank" },
    { aba: "101902117", name: "Central Bank Of Warrensburg" },
    { aba: "101902146", name: "F & C Bank" },
    { aba: "101902175", name: "Equity Bank" },
    { aba: "101902201", name: "Bank Of Orrick" },
    { aba: "101902269", name: "Merchants & Farmers Bank" },
    { aba: "101902366", name: "Bank 21" },
    { aba: "101902405", name: "Exchange Bank Of Missouri" },
    { aba: "101902735", name: "Commercial Trust Company" },
    { aba: "101903336", name: "Farmers Bank Of Northern Missouri" },
    { aba: "101903637", name: "Community Bank Of Marshall" },
    { aba: "101903679", name: "Central Bank Of Warrensburg" },
    { aba: "101903763", name: "Putnam County State Bank" },
    { aba: "101903938", name: "County Bank" },
    { aba: "101904186", name: "Bank Of Odessa" },
    { aba: "101904225", name: "Tri-county Trust Company" },
    { aba: "101904911", name: "Central Bank Of Warrensburg" },
    { aba: "101905004", name: "Pony Express Bank" },
    { aba: "101905512", name: "Farmers Bank Of Northern Missouri" },
    { aba: "101906171", name: "Hawthorn Bank" },
    { aba: "101906249", name: "Montrose Savings Bank" },
    { aba: "101906605", name: "Central Bank Of The Midwest" },
    { aba: "101907196", name: "America's Community Bank" },
    { aba: "101908014", name: "Bank Of Brookfield-purdin,n.a." },
    { aba: "101908577", name: "State Bank Of Missouri" },
    { aba: "101908988", name: "Bank 21" },
    { aba: "101909097", name: "Regional Missouri Bank" },
    { aba: "101909398", name: "Corder Bank" },
    { aba: "101910617", name: "Sunflower Bank, N.a." },
    { aba: "101911519", name: "Midwest Regional Bank" },
    { aba: "101911962", name: "Preferred Bank" },
    { aba: "101912055", name: "The Missouri Bank" },
    { aba: "101913177", name: "Home Exchange Bank" },
    { aba: "101914286", name: "Citizens Community Bank" },
    { aba: "101915764", name: "Carroll County Trust Company" },
    { aba: "101916307", name: "Btc Bank" },
    { aba: "101917623", name: "Exchange Bank Of Missouri" },
    { aba: "101917827", name: "Hawthorn Bank" },
    { aba: "101917843", name: "Concordia Bank" },
    { aba: "101918075", name: "The Hamilton Bank" },
    { aba: "101918101", name: "The State Bank" },
    { aba: "101918169", name: "Farmer Bank" },
    { aba: "101918240", name: "Legacy Bank And Trust" },
    { aba: "101918279", name: "Lamonte Community Bank" },
    { aba: "101918321", name: "Bank Northwest" },
    { aba: "101918965", name: "Community Bank Of Missouri" },
    { aba: "102000021", name: "Us Bank Na" },
    { aba: "102000076", name: "Wells Fargo Bank Na" },
    { aba: "102000199", name: "Denver - Frb Kansas City" },
    { aba: "102000607", name: "Bokf, N.a." },
    { aba: "102000924", name: "Bank Of Denver" },
    { aba: "102000937", name: "Umb, Na" },
    { aba: "102000953", name: "Anb Bank" },
    { aba: "102000966", name: "Independent Bank" },
    { aba: "102001017", name: "Jpmorgan Chase" },
    { aba: "102003154", name: "Zions Bancorporation, Na Dba Vectra" },
    { aba: "102003206", name: "Bokf, Na" },
    { aba: "102003743", name: "The Bankers Bank Of The West" },
    { aba: "102004331", name: "Nbh Bank" },
    { aba: "102004519", name: "Firstbank Of Tech Center" },
    { aba: "102005916", name: "Firstbank" },
    { aba: "102006012", name: "Young Americans Bank" },
    { aba: "102006025", name: "Firstbank" },
    { aba: "102006067", name: "Firstbank Of Tech Center Na" },
    { aba: "102006119", name: "First Bank" },
    { aba: "102006326", name: "First American State Bank" },
    { aba: "102006339", name: "United Fidelity Bank, Fsb" },
    { aba: "102006371", name: "The Northern Trust Company" },
    { aba: "102007008", name: "Midfirst Bank" },
    { aba: "102007011", name: "First Western Trust Bank" },
    { aba: "102007299", name: "Monifi" },
    { aba: "102007383", name: "Bank Of Denver" },
    { aba: "102036558", name: "Travel Dssn 3801" },
    { aba: "102089259", name: "Legal Community C.u. Of Colorado" },
    { aba: "102089644", name: "First Citizens Bank & Trust Company" },
    { aba: "102089929", name: "Midfirst Bank" },
    { aba: "102100138", name: "First National Bank Of Trinidad" },
    { aba: "102100552", name: "Tbk Bank, Ssb" },
    { aba: "102100662", name: "The State Bank - La Junta" },
    { aba: "102100675", name: "Colorado Bank And Trust Company" },
    { aba: "102100772", name: "Nbh Bank" },
    { aba: "102100853", name: "Alamosa State Bank" },
    { aba: "102100879", name: "Frontier Bank" },
    { aba: "102100918", name: "Wells Fargo Bank Na" },
    { aba: "102100992", name: "Nbh Bank" },
    { aba: "102101111", name: "First National Bank Of Las Animas" },
    { aba: "102101218", name: "Dolores State Bank" },
    { aba: "102101250", name: "Park State Bank & Trust" },
    { aba: "102101276", name: "Gunnison Bank And Trust" },
    { aba: "102101292", name: "First Colorado National Bank" },
    { aba: "102101315", name: "First Pioneer National Bank" },
    { aba: "102101360", name: "Points West Community Bank" },
    { aba: "102101412", name: "Fowler State Bank" },
    { aba: "102101425", name: "Sunflower Bank, Na" },
    { aba: "102101441", name: "Transact Bank, National Association" },
    { aba: "102101496", name: "Glacier Bank" },
    { aba: "102101519", name: "Firstbank" },
    { aba: "102101548", name: "Glacier Bank" },
    { aba: "102101577", name: "Tbk Bank, Ssb" },
    { aba: "102101616", name: "First National Bank Of Las Animas" },
    { aba: "102101645", name: "Us Bank Na" },
    { aba: "102101700", name: "United Business Bank" },
    { aba: "102101823", name: "Nbh Bank" },
    { aba: "102102000", name: "High Plains Bank" },
    { aba: "102102013", name: "Nbh Bank" },
    { aba: "102102576", name: "First National Bank Hugo" },
    { aba: "102102864", name: "Tbk Bank, Ssb" },
    { aba: "102102916", name: "Nbh Bank" },
    { aba: "102102932", name: "Wray State Bank" },
    { aba: "102103009", name: "United Business Bank" },
    { aba: "102103106", name: "Legacy Bank" },
    { aba: "102103258", name: "Citizens State Bank Of Ouray" },
    { aba: "102103287", name: "United Business Bank" },
    { aba: "102103407", name: "Alpine Bank" },
    { aba: "102103562", name: "Farmers State Bank Of Calhan" },
    { aba: "102103588", name: "Farmers State Bank" },
    { aba: "102103591", name: "Frontier Bank" },
    { aba: "102103630", name: "First National Bank Of Hugo" },
    { aba: "102103708", name: "Sunflower Bank National Association" },
    { aba: "102103999", name: "Firstbank" },
    { aba: "102104273", name: "Bank Of Burlington" },
    { aba: "102104341", name: "Mcclave State Bank" },
    { aba: "102104419", name: "Independent Bank" },
    { aba: "102104587", name: "Four Corners Community Bank" },
    { aba: "102104590", name: "Sunflower Bank, N.a." },
    { aba: "102104600", name: "Nbh Bank" },
    { aba: "102104642", name: "Nbh Bank" },
    { aba: "102104684", name: "Eastern Colorado Bank" },
    { aba: "102104723", name: "Tbk Bank, Ssb" },
    { aba: "102104749", name: "Nbh Bank" },
    { aba: "102104846", name: "Mountain Valley Bank" },
    { aba: "102104888", name: "First National Bank Cortez" },
    { aba: "102104927", name: "The Dolores State Bank" },
    { aba: "102105146", name: "Nbh Bank" },
    { aba: "102105269", name: "Firstbank" },
    { aba: "102105353", name: "Grand Valley Bank" },
    { aba: "102105816", name: "Firstbank Of Summit County" },
    { aba: "102105997", name: "Glacier Bank" },
    { aba: "102106569", name: "Glacier Bank- Bank San Juans Div" },
    { aba: "102106705", name: "Yampa Valley Bank" },
    { aba: "102106844", name: "Inbank" },
    { aba: "102106857", name: "Citywide Banks" },
    { aba: "102107034", name: "First Southwest Bank" },
    { aba: "102107063", name: "Timberline Bank" },
    { aba: "102107144", name: "Alpine Bank" },
    { aba: "102107364", name: "Community State Bank" },
    { aba: "102107474", name: "Glacier Bank" },
    { aba: "102189285", name: "Wells Fargo Bank" },
    { aba: "102189324", name: "Aventa Credit Union" },
    { aba: "102189612", name: "United Business Bank" },
    { aba: "102200245", name: "Inbank" },
    { aba: "102201040", name: "Aimbank" },
    { aba: "102201244", name: "Bank Of America N.a." },
    { aba: "102201710", name: "Farmers & Stockmens Bank" },
    { aba: "102201930", name: "Tbk Bank, Ssb" },
    { aba: "102202078", name: "Citizens Bank Of Farmington" },
    { aba: "102206728", name: "Four Corners Community Bank" },
    { aba: "102207439", name: "Four Corners Community Bank" },
    { aba: "102300129", name: "First Interstate Bank Of Montana" },
    { aba: "102300242", name: "Wells Fargo Bank Na" },
    { aba: "102300255", name: "Rsnb Bank" },
    { aba: "102300268", name: "Glacier Bank-1st Bank Div" },
    { aba: "102300297", name: "Rnb State Bank" },
    { aba: "102300336", name: "Glacier Bank-1st Bank Div" },
    { aba: "102300404", name: "Central Bank And Trust" },
    { aba: "102300459", name: "First Northern Bank Of Wyoming" },
    { aba: "102300475", name: "Anb Bank" },
    { aba: "102300666", name: "Anb Bank" },
    { aba: "102300873", name: "Bank Of Lovell, Br Of Bk Of Bridger" },
    { aba: "102300970", name: "Sundance State Bank" },
    { aba: "102300996", name: "Firstier Bank" },
    { aba: "102301050", name: "First National Bank Gillette" },
    { aba: "102301063", name: "Cowboy State Bank" },
    { aba: "102301089", name: "Glacier Bank-first Bank Wyoming Div" },
    { aba: "102301092", name: "Wells Fargo Bank Na" },
    { aba: "102301115", name: "Banner Capital Bank" },
    { aba: "102301199", name: "Hilltop National Bank" },
    { aba: "102301241", name: "Farmers State Bank Of Pine Bluffs" },
    { aba: "102301513", name: "Pinnacle Bank" },
    { aba: "102301542", name: "Converse County Bank" },
    { aba: "102301636", name: "Wyoming Bank & Trust" },
    { aba: "102301717", name: "Uinta Bank" },
    { aba: "102301762", name: "Points West Community Bank" },
    { aba: "102301775", name: "First State Bk Of Newcastle" },
    { aba: "102301872", name: "Lusk State Bank" },
    { aba: "102301911", name: "Security State Bank Of Basin" },
    { aba: "102302680", name: "Central Bank & Trust Co." },
    { aba: "102302761", name: "Glacier Bank-1st Bank Div" },
    { aba: "102303171", name: "Bank Of Commerce" },
    { aba: "102303841", name: "Glacier Bank Division First State Bk" },
    { aba: "102304099", name: "Bank Of Jackson Hole" },
    { aba: "102305098", name: "Rocky Mountain Bank" },
    { aba: "102305373", name: "Summit National Bank" },
    { aba: "102306356", name: "Bank Of Star Valley" },
    { aba: "102306479", name: "State Bank" },
    { aba: "102306644", name: "Wyoming Community Bank" },
    { aba: "102306699", name: "Platte Valley Bank" },
    { aba: "102307119", name: "Jonah Bank Of Wyoming" },
    { aba: "102307164", name: "Wells Fargo Bank" },
    { aba: "103000017", name: "Bank Of America, N.a." },
    { aba: "103000130", name: "Bank Of Oklahoma,okc" },
    { aba: "103000619", name: "Umb, N.a." },
    { aba: "103000648", name: "Jpmorgan Chase" },
    { aba: "103000677", name: "First Security Bank & Trust Co" },
    { aba: "103000680", name: "First Enterprise Bank" },
    { aba: "103000703", name: "Interbank" },
    { aba: "103000800", name: "Prosperity Bank" },
    { aba: "103000868", name: "First United Bank & Trust Co" },
    { aba: "103001456", name: "All America Bank" },
    { aba: "103001469", name: "Kirkpatrick Bank" },
    { aba: "103001809", name: "Quail Creek Bank, N.a." },
    { aba: "103001977", name: "Frontier State Bank" },
    { aba: "103002099", name: "Farmers And Merchants Bank" },
    { aba: "103002251", name: "Citizens Bank Of Edmond" },
    { aba: "103002617", name: "Bancfirst" },
    { aba: "103002691", name: "First Fidelity Bank" },
    { aba: "103002772", name: "Nbc Oklahoma" },
    { aba: "103003467", name: "Armstrong Bank" },
    { aba: "103003616", name: "The Bankers Bank" },
    { aba: "103003632", name: "Bancfirst" },
    { aba: "103003687", name: "Simmons Bank" },
    { aba: "103005164", name: "Legacy Bank, Blanchard Branch" },
    { aba: "103006406", name: "Bancfirst" },
    { aba: "103008404", name: "First State Bank" },
    { aba: "103008815", name: "Sooner State Bank" },
    { aba: "103009160", name: "First Bethany Bank & Trust" },
    { aba: "103009571", name: "Ynb" },
    { aba: "103012843", name: "Fnb Community Bank" },
    { aba: "103012869", name: "International Bank Of Commerce" },
    { aba: "103012908", name: "Oklahoma State Bank" },
    { aba: "103012966", name: "Bank 2-okc" },
    { aba: "103013017", name: "Valliance Bank" },
    { aba: "103013062", name: "Rose Rock Bank" },
    { aba: "103013101", name: "First Fidelity Bank" },
    { aba: "103013130", name: "First Liberty Bank" },
    { aba: "103013211", name: "Prosperity Bank" },
    { aba: "103013266", name: "Prime Bank" },
    { aba: "103013295", name: "First Liberty Bank" },
    { aba: "103089834", name: "First Citizens Bank & Trust Company" },
    { aba: "103100014", name: "Bank Of Oklahoma Natl. Asn." },
    { aba: "103100179", name: "Security National Bank Of Enid" },
    { aba: "103100195", name: "Stride Bank, National Association" },
    { aba: "103100218", name: "First National Bank & Trust Company" },
    { aba: "103100250", name: "The Bank, National Association" },
    { aba: "103100409", name: "F & M Bank" },
    { aba: "103100467", name: "First National Bank & Trust Co" },
    { aba: "103100522", name: "First National Bank" },
    { aba: "103100616", name: "American Heritage Bank" },
    { aba: "103100739", name: "City National Bank & Trust" },
    { aba: "103100878", name: "First Texoma National Bank" },
    { aba: "103100881", name: "First United Bank & Trust Company" },
    { aba: "103100959", name: "Frazer Bank" },
    { aba: "103101013", name: "Oklahoma Capital Bank" },
    { aba: "103101026", name: "Vision Bank Na" },
    { aba: "103101084", name: "Mabrey Bank" },
    { aba: "103101107", name: "First National Bank & Trust" },
    { aba: "103101110", name: "First National Bank & Trust" },
    { aba: "103101152", name: "First National Bank & Trust" },
    { aba: "103101165", name: "Bancfirst" },
    { aba: "103101204", name: "First Natl Bk & Tr Co Of Weatherford" },
    { aba: "103101262", name: "Firstar Bank" },
    { aba: "103101275", name: "Alva State Bank And Trust Company" },
    { aba: "103101291", name: "Banccentral, National Association" },
    { aba: "103101330", name: "Citizens Bank Of Ada" },
    { aba: "103101356", name: "Regent Bank" },
    { aba: "103101372", name: "Bank Of Commerce" },
    { aba: "103101411", name: "First National Bank & Trust Co." },
    { aba: "103101424", name: "First National Bank" },
    { aba: "103101437", name: "Simmons Bank" },
    { aba: "103101482", name: "Bank Of Commerce" },
    { aba: "103101505", name: "First State Bank" },
    { aba: "103101518", name: "The Community State Bank" },
    { aba: "103101547", name: "Shamrock Bank, Na" },
    { aba: "103101576", name: "First National Bank & Trust,elk City" },
    { aba: "103101589", name: "Community State Bank" },
    { aba: "103101602", name: "Bank Of Western Oklahoma" },
    { aba: "103101628", name: "Exchange Bk&tr Co" },
    { aba: "103101631", name: "First Bank & Trust Company" },
    { aba: "103101660", name: "Firstar Bank" },
    { aba: "103101699", name: "Liberty National Bank" },
    { aba: "103101738", name: "First Farmers National Bank" },
    { aba: "103101754", name: "The First Natl Bk & Tr Co Of Miami" },
    { aba: "103101767", name: "Lakeside Bank Of Salina" },
    { aba: "103101822", name: "First Bank And Trust Company" },
    { aba: "103101848", name: "Oklahoma Bank & Trust" },
    { aba: "103101851", name: "Vision Bank Na" },
    { aba: "103101877", name: "Blue Sky Bank" },
    { aba: "103101880", name: "American Heritage Bank" },
    { aba: "103101929", name: "First State Bank" },
    { aba: "103101987", name: "Pauls Valley Natl Bk" },
    { aba: "103102070", name: "Lakeside State Bank" },
    { aba: "103102106", name: "First Bank & Trust Co" },
    { aba: "103102122", name: "Vast Bank, National Association" },
    { aba: "103102164", name: "Bank Of The Panhandle" },
    { aba: "103102193", name: "First State Bank" },
    { aba: "103102203", name: "First Bank & Trust Company" },
    { aba: "103102216", name: "Security Bank," },
    { aba: "103102274", name: "Citizens Bank & Trust Company" },
    { aba: "103102290", name: "Bancfirst" },
    { aba: "103102300", name: "Latimer State Bank" },
    { aba: "103102326", name: "Oklahoma Heritage Bank" },
    { aba: "103102342", name: "Farmers Merchants Natl Bk" },
    { aba: "103102368", name: "Acb Bank" },
    { aba: "103102384", name: "Simmons Bank" },
    { aba: "103102407", name: "State Bank Of Wynnewood" },
    { aba: "103102410", name: "Equity Bank" },
    { aba: "103102452", name: "Ameristate Bank" },
    { aba: "103102481", name: "Bank Of Commerce, Catoosa, Adair" },
    { aba: "103102494", name: "First National Bank" },
    { aba: "103102546", name: "Interbank" },
    { aba: "103102575", name: "Firststar Bank" },
    { aba: "103102591", name: "Central National Bank" },
    { aba: "103102627", name: "First Pryority Bank" },
    { aba: "103102737", name: "Mccurtain County Natl Bank" },
    { aba: "103102795", name: "Rose Rock Bank" },
    { aba: "103102821", name: "Yorktown Bank" },
    { aba: "103102847", name: "First Natl Bk-stigler" },
    { aba: "103102889", name: "First National Bank" },
    { aba: "103102892", name: "Avb Bank" },
    { aba: "103102960", name: "American National Bank" },
    { aba: "103103024", name: "Pauls Valley Natl Bk" },
    { aba: "103103105", name: "Bancfirst" },
    { aba: "103103121", name: "Walters Bank And Trust" },
    { aba: "103103147", name: "First National Bank" },
    { aba: "103103189", name: "Lakeside State Bank" },
    { aba: "103103215", name: "Community Bank" },
    { aba: "103103244", name: "Equity Bank" },
    { aba: "103103309", name: "American Heritage Bank" },
    { aba: "103103338", name: "Bank Of Eufaula" },
    { aba: "103103354", name: "Community National Bank" },
    { aba: "103103396", name: "Firstbank" },
    { aba: "103103406", name: "First Community" },
    { aba: "103103435", name: "Snb Bank, National Association" },
    { aba: "103103451", name: "First National Bank & Trust Co" },
    { aba: "103103503", name: "First Summit Bank" },
    { aba: "103103516", name: "Peoples Bk & Tr Co" },
    { aba: "103103561", name: "Mabrey Bank" },
    { aba: "103103574", name: "First National Bank & Trust" },
    { aba: "103103600", name: "Fnb Coweta" },
    { aba: "103103642", name: "Corebank" },
    { aba: "103103655", name: "First Natl Bank" },
    { aba: "103103668", name: "American Exchange Bank" },
    { aba: "103103671", name: "First National Bank, Grove Branch" },
    { aba: "103103749", name: "First State Bank" },
    { aba: "103103765", name: "Grant County Bank" },
    { aba: "103103778", name: "Bank 7" },
    { aba: "103103817", name: "Blue Sky Bank" },
    { aba: "103103985", name: "Great Plains National Bank" },
    { aba: "103104104", name: "High Plains Bank" },
    { aba: "103104120", name: "First Bank" },
    { aba: "103104133", name: "F & M Bank" },
    { aba: "103104201", name: "Mabrey Bank" },
    { aba: "103104227", name: "All America Bank" },
    { aba: "103104298", name: "Southwest National Bank" },
    { aba: "103104353", name: "Farmers Bank" },
    { aba: "103104489", name: "Carson Community Bank" },
    { aba: "103104528", name: "Armstrong Bank" },
    { aba: "103104544", name: "First National Bank" },
    { aba: "103104641", name: "1st Bank In Hominy" },
    { aba: "103104764", name: "Community State Bank Of Canton" },
    { aba: "103104816", name: "Farmers And Merchants Bk" },
    { aba: "103104829", name: "Mabrey Bank" },
    { aba: "103104874", name: "Farmers State Bank" },
    { aba: "103104887", name: "Legacy Bank, Hinton Branch" },
    { aba: "103104942", name: "Welch State Bank" },
    { aba: "103104968", name: "American Bank Of Oklahoma" },
    { aba: "103105116", name: "First Bank" },
    { aba: "103105190", name: "Exchange Bank" },
    { aba: "103105213", name: "Payne County Bank" },
    { aba: "103105394", name: "Bank Of Hydro" },
    { aba: "103105446", name: "Washita State Bank" },
    { aba: "103105488", name: "The Bank Of Verden" },
    { aba: "103105530", name: "Farmers & Merchants Bank" },
    { aba: "103105637", name: "First American Bk" },
    { aba: "103105640", name: "Talihina Branch" },
    { aba: "103105695", name: "Farmers And Merchants Bank" },
    { aba: "103105831", name: "Oklahoma State Bk" },
    { aba: "103105886", name: "Cleo State Bank" },
    { aba: "103106092", name: "Washita Valley Bank" },
    { aba: "103106160", name: "Bank Of Cordell" },
    { aba: "103106186", name: "Anchor D Bank" },
    { aba: "103106283", name: "The Seiling State Bank" },
    { aba: "103106377", name: "First Natl Bk & Tr Co Of Weatherford" },
    { aba: "103106542", name: "First State Bank" },
    { aba: "103106775", name: "Bank Of Beaver City" },
    { aba: "103106827", name: "Legacy Bank,acb" },
    { aba: "103106843", name: "Interbank" },
    { aba: "103107004", name: "All Nations Bank" },
    { aba: "103107046", name: "Bancfirst" },
    { aba: "103107392", name: "Liberty National Bank" },
    { aba: "103107402", name: "First State Bank" },
    { aba: "103107415", name: "The First Natl Bk & Tr Co Of Miami" },
    { aba: "103107444", name: "Stock Exchange Bank" },
    { aba: "103107486", name: "The First National Bank Of Fletcher" },
    { aba: "103107622", name: "Stockmans Bank" },
    { aba: "103107635", name: "First St Bk Anadarko, Gracemont Br" },
    { aba: "103107839", name: "Bank Of Cherokee County" },
    { aba: "103108045", name: "The Bank Of Kremlin" },
    { aba: "103108249", name: "First State Bank Of Altus" },
    { aba: "103108443", name: "First Bank Of Okarche" },
    { aba: "103108605", name: "Security State Bank Of Oklahoma" },
    { aba: "103108757", name: "State Exchange Bank" },
    { aba: "103109015", name: "Sooner State Bank" },
    { aba: "103109125", name: "First United Bank And Trust Company" },
    { aba: "103109206", name: "Bank Of Wyandotte" },
    { aba: "103109264", name: "Peoples National Bank" },
    { aba: "103109345", name: "Bank 7" },
    { aba: "103109374", name: "Security State Bank Of Oklahoma" },
    { aba: "103109390", name: "1st State Bank" },
    { aba: "103109507", name: "Security State Bank" },
    { aba: "103109523", name: "Bank Of Vici" },
    { aba: "103109581", name: "Security State Bk" },
    { aba: "103109714", name: "All America Bank" },
    { aba: "103109772", name: "Spiro State Bank" },
    { aba: "103109840", name: "Bancfirst" },
    { aba: "103109882", name: "Liberty National Bank" },
    { aba: "103110046", name: "First National Bank" },
    { aba: "103110237", name: "First State Bank" },
    { aba: "103110305", name: "First Security Bk-beaver" },
    { aba: "103110376", name: "Spiritbank" },
    { aba: "103110392", name: "Stroud Natl Bank" },
    { aba: "103110428", name: "First National Bank" },
    { aba: "103110813", name: "Security Bank & Trust Co" },
    { aba: "103110907", name: "Security Bank And Trust Co" },
    { aba: "103111058", name: "Alva State Bank And Trust Company" },
    { aba: "103111155", name: "First State Bank In Temple" },
    { aba: "103111197", name: "Hopeton State Bk" },
    { aba: "103111252", name: "First National Bank Of Oklahoma" },
    { aba: "103111362", name: "Osage Federal Bank" },
    { aba: "103111618", name: "Mcclain Bank" },
    { aba: "103111650", name: "American National Bank-comanche" },
    { aba: "103111744", name: "Idabel National Bank" },
    { aba: "103111977", name: "Farmers State Bk" },
    { aba: "103112015", name: "First Oklahoma Bank" },
    { aba: "103112031", name: "The American Bank" },
    { aba: "103112073", name: "The Bank Of The West" },
    { aba: "103112112", name: "Mabrey Bank" },
    { aba: "103112125", name: "Community Bank" },
    { aba: "103112248", name: "American Exchange Bank" },
    { aba: "103112329", name: "Cornerstone Bank" },
    { aba: "103112345", name: "First National Bank Of Fort Smith" },
    { aba: "103112358", name: "First National Bank And Trust Co" },
    { aba: "103112426", name: "Security First Natl Bank - Hugo" },
    { aba: "103112439", name: "Oklahoma State Bank" },
    { aba: "103112455", name: "High Plains Bank" },
    { aba: "103112507", name: "Carson Community Bank" },
    { aba: "103112510", name: "Valor Bank" },
    { aba: "103112523", name: "Nbc Oklahoma" },
    { aba: "103112552", name: "Gateway First Bank" },
    { aba: "103112594", name: "Rcb Bank" },
    { aba: "103112620", name: "Oklahoma State Bank" },
    { aba: "103112659", name: "Firstbank" },
    { aba: "103112675", name: "Fsnb, National Association" },
    { aba: "103112730", name: "Bank Of Locust Grove" },
    { aba: "103112743", name: "Peoples State Bk Of Blair-lawton Br" },
    { aba: "103112853", name: "First Bank Of Owasso" },
    { aba: "103112947", name: "Firstar Bank" },
    { aba: "103112976", name: "Arvest Bank" },
    { aba: "103112992", name: "Bank Of Commerce" },
    { aba: "103113001", name: "Bancfirst" },
    { aba: "103113056", name: "First United Bank And Trust Company" },
    { aba: "103113085", name: "Rcb Bank" },
    { aba: "103113111", name: "Armstrong Bank" },
    { aba: "103113124", name: "Grand Savings Bank" },
    { aba: "103113153", name: "Simmons Bank" },
    { aba: "103113166", name: "All America Bank" },
    { aba: "103113179", name: "All America Bank ( Secondary Rtn)" },
    { aba: "103113182", name: "All America Bank" },
    { aba: "103113195", name: "Armstrong Bank" },
    { aba: "103113276", name: "Great Nations Bank" },
    { aba: "103113302", name: "The Community State Bank" },
    { aba: "103113315", name: "Stride Bank, Natl Assoc Tr Of Enid" },
    { aba: "103189103", name: "Central Oklahoma Fcu" },
    { aba: "103189307", name: "Equity Bank" },
    { aba: "103189394", name: "American Bank Of Oklahoma" },
    { aba: "103189682", name: "Bank Of Grand Lake" },
    { aba: "103900036", name: "Bokf, N.a." },
    { aba: "103901491", name: "American Heritage Bank" },
    { aba: "103901569", name: "American Bank & Trust" },
    { aba: "103902717", name: "Vast Bank, National Association" },
    { aba: "103903046", name: "Triad Bank N.a." },
    { aba: "103903486", name: "Crossfirst Bank" },
    { aba: "103908669", name: "American Heritage Bank" },
    { aba: "103912668", name: "Prosperity Bank" },
    { aba: "103912723", name: "Central Bank Of Oklahoma" },
    { aba: "103912875", name: "Grand Bank" },
    { aba: "103912914", name: "Onb Bank And Trust Company" },
    { aba: "103912956", name: "Commerce Bank" },
    { aba: "103912985", name: "Tib-the Independent Bankers Bank" },
    { aba: "103913337", name: "First Oklahoma Bank" },
    { aba: "104000016", name: "First National Bank Of Omaha" },
    { aba: "104000029", name: "Us Bank Na" },
    { aba: "104000058", name: "Wells Fargo Bank, N.a." },
    { aba: "104000469", name: "Enterprise Bank" },
    { aba: "104000702", name: "Great Western Bank" },
    { aba: "104000841", name: "Security National Bank" },
    { aba: "104000854", name: "American National Bank" },
    { aba: "104000870", name: "Premier Bank" },
    { aba: "104001138", name: "Access Bank" },
    { aba: "104001497", name: "First Westroads Bank" },
    { aba: "104001808", name: "Core Bank" },
    { aba: "104002894", name: "Cit Bank Na" },
    { aba: "104014109", name: "United Republic Bank" },
    { aba: "104014125", name: "Cit Bank Na" },
    { aba: "104014138", name: "Access Bank" },
    { aba: "104022751", name: "Glenwood State Bank" },
    { aba: "104089419", name: "Northwest Bank" },
    { aba: "104089574", name: "Gallup Federal Credit Union" },
    { aba: "104100783", name: "Mnb Bank" },
    { aba: "104100945", name: "First National Bank" },
    { aba: "104101258", name: "Banner Capital Bank" },
    { aba: "104101465", name: "First National Bank" },
    { aba: "104101478", name: "Thayer County Bank" },
    { aba: "104101559", name: "Hershey State Bank" },
    { aba: "104101575", name: "U.s. Bank" },
    { aba: "104101627", name: "Points West Community Bank" },
    { aba: "104101630", name: "Cedar Security Bank" },
    { aba: "104101876", name: "Western States Bank" },
    { aba: "104102095", name: "Farmers & Merchants State Bank" },
    { aba: "104102105", name: "Western National Bank" },
    { aba: "104102192", name: "Midwest Bank, National Association" },
    { aba: "104102202", name: "Platte Valley Bank" },
    { aba: "104102257", name: "First State Bank" },
    { aba: "104102309", name: "Platte Valley Bank" },
    { aba: "104102354", name: "Security First Bank" },
    { aba: "104102574", name: "Waypoint Bank" },
    { aba: "104102587", name: "First Central Bank" },
    { aba: "104102613", name: "Bank Of Dixon County" },
    { aba: "104102642", name: "Commercial Bank" },
    { aba: "104102655", name: "Banner Capital Bank" },
    { aba: "104102707", name: "Cornerstone Bank" },
    { aba: "104102781", name: "First National Bank Of Gordon" },
    { aba: "104102875", name: "Tilden Bank" },
    { aba: "104102985", name: "Bank Of Newman Grove" },
    { aba: "104103683", name: "Citizens State Bank" },
    { aba: "104103829", name: "Western Nebraska Bank" },
    { aba: "104103900", name: "Commercial State Bank" },
    { aba: "104104983", name: "Ashton State Bk" },
    { aba: "104105283", name: "Sandhills State Bank" },
    { aba: "104105733", name: "Community First Bank" },
    { aba: "104106088", name: "Nebraska State Bank & Trust Company" },
    { aba: "104106473", name: "Stockmens Bank" },
    { aba: "104106509", name: "Citizens State Bank" },
    { aba: "104106583", name: "Community First Bank" },
    { aba: "104106693", name: "Bruning Bank" },
    { aba: "104106716", name: "Brunswick State Bank" },
    { aba: "104106761", name: "Citizens State Bk" },
    { aba: "104106800", name: "Chambers State Bank" },
    { aba: "104106965", name: "Culbertson Bank" },
    { aba: "104107456", name: "Pinnacle Bank" },
    { aba: "104107731", name: "Sandhills State Bank" },
    { aba: "104107786", name: "Bank Of Lewellen" },
    { aba: "104107825", name: "Points West Community Bank" },
    { aba: "104108316", name: "Nebraska State Bank" },
    { aba: "104108439", name: "Potter State Bank" },
    { aba: "104108507", name: "Commercial State Bank" },
    { aba: "104108691", name: "First State Bank" },
    { aba: "104109739", name: "West Plains Bank" },
    { aba: "104109975", name: "Western Nebraska Bank" },
    { aba: "104110113", name: "Dundee Bank" },
    { aba: "104110919", name: "Nebraska State Bank & Trust Company" },
    { aba: "104110922", name: "Waypoint Bank" },
    { aba: "104110977", name: "Bank Of Hartington" },
    { aba: "104111565", name: "Points West Community Bank" },
    { aba: "104111769", name: "First State Bank" },
    { aba: "104112409", name: "Bank Of The West" },
    { aba: "104112771", name: "Great Plains State Bank" },
    { aba: "104112849", name: "Farmers State Bank" },
    { aba: "104112917", name: "Nebraska State Bk" },
    { aba: "104112988", name: "Chambers State Bank" },
    { aba: "104113039", name: "Security Bank" },
    { aba: "104113097", name: "Western Nebraska Bank" },
    { aba: "104113217", name: "First National Bank" },
    { aba: "104113262", name: "State Bank Of Scotia" },
    { aba: "104113327", name: "Bank Of Elgin" },
    { aba: "104113343", name: "First State Bank" },
    { aba: "104113372", name: "Cedar Rapids State Bank" },
    { aba: "104113408", name: "Community First Bank" },
    { aba: "104113521", name: "Battle Creek State Bank" },
    { aba: "104113550", name: "Butte State Bank" },
    { aba: "104113628", name: "Farmers State Bank" },
    { aba: "104113660", name: "Tri-county Bank" },
    { aba: "104113712", name: "Nebraska State Bank" },
    { aba: "104113738", name: "South Central State Bank, Campbell" },
    { aba: "104113741", name: "Western National Bank" },
    { aba: "104113767", name: "Bank Of Orchard" },
    { aba: "104113819", name: "Security First Bank" },
    { aba: "104113835", name: "Bank Of Lindsay" },
    { aba: "104113880", name: "Firstier Bank" },
    { aba: "104113945", name: "Farmers And Merchants Bank" },
    { aba: "104113958", name: "Adams Bank And Trust Company" },
    { aba: "104113990", name: "First Central Bank" },
    { aba: "104189461", name: "Holyoke Community Fcu" },
    { aba: "104189568", name: "Pioneer Community Fcu" },
    { aba: "104189607", name: "Nebraska Rural Community Federal Cu" },
    { aba: "104900048", name: "First National Bank Of Omaha" },
    { aba: "104900116", name: "First State Bank" },
    { aba: "104900200", name: "Security First Bank" },
    { aba: "104900323", name: "Wells Fargo Bank Na" },
    { aba: "104900349", name: "Cornerstone Bank" },
    { aba: "104900459", name: "First National Bank Of Omaha" },
    { aba: "104900527", name: "Arbor Bank" },
    { aba: "104900543", name: "Premier Bank" },
    { aba: "104900585", name: "Heartland Bank" },
    { aba: "104900679", name: "First National Bank Of Omaha" },
    { aba: "104900721", name: "First National Bank Of Omaha" },
    { aba: "104900750", name: "First State Bank Nebraska" },
    { aba: "104900792", name: "Frontier Bank" },
    { aba: "104900802", name: "F&m Bank" },
    { aba: "104900912", name: "Auburn State Bank" },
    { aba: "104900938", name: "Auburn State Bank" },
    { aba: "104901063", name: "F & M Bank" },
    { aba: "104901115", name: "Eagle State Bank" },
    { aba: "104901144", name: "City Bank & Trust Company" },
    { aba: "104901160", name: "Farmers And Merchants Bank" },
    { aba: "104901238", name: "Firstbank Of Nebraska" },
    { aba: "104901241", name: "Siouxland Bank" },
    { aba: "104901270", name: "Bank Of Doniphan" },
    { aba: "104901319", name: "Elkhorn Valley Bank" },
    { aba: "104901335", name: "State Nebraska Bank And Trust" },
    { aba: "104901348", name: "Cattle National Bank" },
    { aba: "104901364", name: "Jones Bank" },
    { aba: "104901458", name: "Citizens State Bank" },
    { aba: "104901513", name: "F & M Bank" },
    { aba: "104901568", name: "Bank Of The Valley" },
    { aba: "104901584", name: "West Gate Bank" },
    { aba: "104901597", name: "Heartland Bank" },
    { aba: "104901607", name: "Gothenburg State Bank & Trust Co" },
    { aba: "104901610", name: "First State Bank" },
    { aba: "104901652", name: "Bankfirst" },
    { aba: "104901665", name: "Charter West Bank" },
    { aba: "104901678", name: "Five Points Bank" },
    { aba: "104901717", name: "First Bank & Trust Of Fullerton" },
    { aba: "104901720", name: "Points West Community Bank" },
    { aba: "104901814", name: "Minden Exchange Bank & Trust Co" },
    { aba: "104901827", name: "First Bank And Trust Company" },
    { aba: "104901911", name: "Farmers & Merchants Bank Of Ashland" },
    { aba: "104901937", name: "F & M Bank" },
    { aba: "104901940", name: "Genoa Community Bank" },
    { aba: "104901995", name: "Houghton State Bank" },
    { aba: "104902033", name: "Stanton State Bank" },
    { aba: "104902062", name: "Citizens Bank & Trust Co." },
    { aba: "104902114", name: "Core Bank" },
    { aba: "104902127", name: "Citizens State Bank" },
    { aba: "104902172", name: "Elkhorn Valley Bank & Trust" },
    { aba: "104902295", name: "Exchange Bank" },
    { aba: "104902347", name: "Glenwood State Bank" },
    { aba: "104902363", name: "Waypoint Bank" },
    { aba: "104902376", name: "Homestead Bank" },
    { aba: "104902392", name: "Citizens State Bank" },
    { aba: "104902800", name: "Generations Bank" },
    { aba: "104902949", name: "First Northeast Bank Of Nebraska" },
    { aba: "104903032", name: "Firstbank Of Nebraska" },
    { aba: "104903139", name: "Frontier Bank" },
    { aba: "104903294", name: "First Bank Of Bancroft" },
    { aba: "104903333", name: "Exchange Bank" },
    { aba: "104903362", name: "Farmers & Merchants Bank" },
    { aba: "104903388", name: "Midstates Bank" },
    { aba: "104903391", name: "Shelby County State Bank" },
    { aba: "104903498", name: "Farmers State Bank" },
    { aba: "104903579", name: "Clarkson Bank" },
    { aba: "104903595", name: "Two Rivers State Bank" },
    { aba: "104903692", name: "American Exchange Bank" },
    { aba: "104904099", name: "Jones Bank" },
    { aba: "104904277", name: "First Bank Of Utica" },
    { aba: "104904442", name: "First Community Bank" },
    { aba: "104904646", name: "First State Bank" },
    { aba: "104904882", name: "First National Bank" },
    { aba: "104904905", name: "State Bank Of Odell" },
    { aba: "104905140", name: "Farmers & Merchants Bank" },
    { aba: "104905166", name: "Bank Of Prague" },
    { aba: "104905250", name: "Mainstreet Bank" },
    { aba: "104905616", name: "Five Points Bank Of Hastings" },
    { aba: "104905742", name: "Community Bank" },
    { aba: "104905784", name: "Horizon Bank" },
    { aba: "104905807", name: "Malvern Trust & Savings Bank" },
    { aba: "104905836", name: "First State Bank" },
    { aba: "104905881", name: "First National Bank" },
    { aba: "104906602", name: "Boelus State Bank" },
    { aba: "104906725", name: "Midstates Bank, National Association" },
    { aba: "104906880", name: "Community State Bank" },
    { aba: "104907025", name: "Cornhusker Bank" },
    { aba: "104907041", name: "Heartland Bank" },
    { aba: "104907562", name: "First State Bank" },
    { aba: "104907708", name: "Adams County Bank" },
    { aba: "104907779", name: "Foundation One Bank" },
    { aba: "104907818", name: "Homestead Bank" },
    { aba: "104907931", name: "Cornerstone Bank" },
    { aba: "104908040", name: "Bank Of Mead" },
    { aba: "104908082", name: "Farmers & Merchants Bank" },
    { aba: "104908134", name: "Platte Valley Bank" },
    { aba: "104908163", name: "Cornerstone Bank" },
    { aba: "104908189", name: "First State Bank Nebraska" },
    { aba: "104908257", name: "First State Bank" },
    { aba: "104908383", name: "Heritage Bank" },
    { aba: "104908422", name: "Town And Country Bank" },
    { aba: "104908532", name: "Heartland Bank" },
    { aba: "104908723", name: "Bank Of Steinauer" },
    { aba: "104908817", name: "First Tri-county Bank" },
    { aba: "104908846", name: "State Bank Of Table Rock" },
    { aba: "104908859", name: "Tri-valley Bank" },
    { aba: "104908930", name: "Countryside Bank" },
    { aba: "104908956", name: "First Nebraska Bank" },
    { aba: "104909201", name: "Cerescobank" },
    { aba: "104909531", name: "First State Bank Nebraska" },
    { aba: "104909793", name: "Astra Bank" },
    { aba: "104909942", name: "Bank Of Clarks" },
    { aba: "104910135", name: "American Interstate Bank" },
    { aba: "104910232", name: "First Ntl Bk Of Farragut/shenandoah" },
    { aba: "104910258", name: "First Nebraska Bank" },
    { aba: "104910274", name: "Pinnacle Bank" },
    { aba: "104910339", name: "Bank Of The Valley" },
    { aba: "104910342", name: "First National Bank Of Omaha" },
    { aba: "104910795", name: "Union Bank & Trust Company" },
    { aba: "104911422", name: "Scribner Bank" },
    { aba: "104911671", name: "Adams State Bank" },
    { aba: "104912670", name: "Bankfirst" },
    { aba: "104913019", name: "Bank Of Bennington" },
    { aba: "104913048", name: "First National Bank Of Omaha" },
    { aba: "104913064", name: "Farmers Trust & Savings Bank" },
    { aba: "104913161", name: "Washington County Bank" },
    { aba: "104913190", name: "Pathway Bank" },
    { aba: "104913255", name: "Wahoo State Bank" },
    { aba: "104913284", name: "Columbus Bank & Trust Company" },
    { aba: "104913310", name: "Citizens State Bank" },
    { aba: "104913336", name: "State Bank Of Colon" },
    { aba: "104913365", name: "Madison County Bank Fsb" },
    { aba: "104913381", name: "Frontier Bank" },
    { aba: "104913491", name: "York State Bank" },
    { aba: "104913530", name: "Henderson State Bank" },
    { aba: "104913682", name: "Springfield State Bank" },
    { aba: "104913750", name: "Corn Growers State Bank" },
    { aba: "104913912", name: "Pinnacle Bank" },
    { aba: "104913970", name: "Nebraskaland National Bank" },
    { aba: "104914076", name: "Midwest Independent Bank" },
    { aba: "104914089", name: "Washington County Bank" },
    { aba: "104914157", name: "Union Bank And Trust" },
    { aba: "104914160", name: "Union Bank & Trust Company" },
    { aba: "104919275", name: "Tri-valley Bank" },
    { aba: "104920442", name: "Ts Bank" },
    { aba: "104922217", name: "Defiance State Bank" },
    { aba: "104922987", name: "Midstates Bank, Na" },
    { aba: "104989852", name: "Nebraska Bank Of Commerce" },
    { aba: "107000068", name: "Pueblo Bank And Trust" },
    { aba: "107000071", name: "Anb Bank" },
    { aba: "107000097", name: "Pikes Peak National Bank" },
    { aba: "107000152", name: "Bank Of The West" },
    { aba: "107000233", name: "Anb Bank" },
    { aba: "107000262", name: "First National Bank Of Omaha" },
    { aba: "107000275", name: "Wells Fargo Bank Bank Na" },
    { aba: "107000327", name: "Bank Of America, N.a." },
    { aba: "107000372", name: "Southwest Capital Bank" },
    { aba: "107000440", name: "Sunflower Bank National Association" },
    { aba: "107000466", name: "Century Bank" },
    { aba: "107000521", name: "Sunflower Bank" },
    { aba: "107000628", name: "Independent Bank" },
    { aba: "107000783", name: "Bbva Usa" },
    { aba: "107000796", name: "Bank Of America N.a." },
    { aba: "107000929", name: "Rocky Mountain Bank & Trust Florence" },
    { aba: "107001012", name: "Enterprise Bank & Trust" },
    { aba: "107001067", name: "Umb, Na" },
    { aba: "107001070", name: "Citywide Banks" },
    { aba: "107001135", name: "Herring Bank" },
    { aba: "107001232", name: "Anb Bank" },
    { aba: "107001261", name: "Centinel Bank Of Taos" },
    { aba: "107001326", name: "Bank Of Estes Park" },
    { aba: "107001452", name: "U.s. Bank National Association" },
    { aba: "107001481", name: "Academy Bank" },
    { aba: "107001520", name: "United Business Bank" },
    { aba: "107001601", name: "New Mexico Bank & Trust" },
    { aba: "107001672", name: "Firstbank" },
    { aba: "107001724", name: "Nbh Bank" },
    { aba: "107001805", name: "Firstbank" },
    { aba: "107001818", name: "Umb, Na" },
    { aba: "107001960", name: "First National Bank" },
    { aba: "107002008", name: "Community 1st Bank Las Vegas" },
    { aba: "107002118", name: "Nbh Bank" },
    { aba: "107002147", name: "Bank Of The West" },
    { aba: "107002163", name: "Independent Bank" },
    { aba: "107002192", name: "Wells Fargo Bank Na ( New Mexico)" },
    { aba: "107002312", name: "U.s. Bank National Association" },
    { aba: "107002406", name: "Firstbank Of Longmont" },
    { aba: "107002448", name: "Bank Of Colorado" },
    { aba: "107002503", name: "Firstbank" },
    { aba: "107002516", name: "Glacier Bank Bk Of The San Juans Div" },
    { aba: "107002532", name: "Firstbank" },
    { aba: "107002723", name: "Main Bank" },
    { aba: "107002794", name: "Colorado State Bank And Trust, Na." },
    { aba: "107002846", name: "Commerce Bank" },
    { aba: "107002969", name: "Colorado State Bank & Trust, Na." },
    { aba: "107003010", name: "Firstbank" },
    { aba: "107003023", name: "Firstbank Of Longmont, Na" },
    { aba: "107003298", name: "Firstbank" },
    { aba: "107003353", name: "First National Bank" },
    { aba: "107003418", name: "Farmers State Bank Of Brush" },
    { aba: "107003463", name: "Independent Bank" },
    { aba: "107003609", name: "Firstbank Of South Jeffco" },
    { aba: "107003612", name: "Firstbank" },
    { aba: "107003641", name: "Anb Bank" },
    { aba: "107003667", name: "Independent Bank" },
    { aba: "107003829", name: "First National Bank Of Fleming" },
    { aba: "107003861", name: "Evergreen National Bank" },
    { aba: "107003942", name: "Fms Bank" },
    { aba: "107004352", name: "Firstbank" },
    { aba: "107004381", name: "Firstbank" },
    { aba: "107004459", name: "Firstbank Of Lakewood, N.a." },
    { aba: "107004491", name: "Nbh Bank" },
    { aba: "107004611", name: "Independent Bank" },
    { aba: "107004637", name: "Firstbank Of Douglas County Na" },
    { aba: "107004695", name: "Firstbank Of Colorado, N.a." },
    { aba: "107004776", name: "Independent Bank" },
    { aba: "107005047", name: "Firstbank" },
    { aba: "107005076", name: "North Valley Bank" },
    { aba: "107005102", name: "Colorado State Bank & Trust, Na" },
    { aba: "107005254", name: "Firstbank Of South Jeffco" },
    { aba: "107005296", name: "Firstbank Of Arapahoe County" },
    { aba: "107005319", name: "Bbva Usa" },
    { aba: "107005432", name: "Wells Fargo And Company" },
    { aba: "107005445", name: "Tbk Bank, Ssb" },
    { aba: "107005490", name: "Firstbank" },
    { aba: "107005526", name: "Western States Bank" },
    { aba: "107005607", name: "Bank Of America N.a." },
    { aba: "107005694", name: "Legacy Bank" },
    { aba: "107005801", name: "Firstbank" },
    { aba: "107005924", name: "Firstbank" },
    { aba: "107005953", name: "Citywide Banks" },
    { aba: "107005979", name: "Cit Bank Na" },
    { aba: "107006004", name: "Great Western Bank" },
    { aba: "107006046", name: "Firstbank Of Lakewood, N.a." },
    { aba: "107006059", name: "Firstbank Of Arapahoe County, N.a." },
    { aba: "107006075", name: "Firstbank Of Aurora, N.a." },
    { aba: "107006088", name: "Firstbank Of Aurora, N.a." },
    { aba: "107006091", name: "First Bank Of Boulder, N.a." },
    { aba: "107006101", name: "Firstbank Of Boulder, N.a." },
    { aba: "107006127", name: "Firstbank Of Wheatridge, N.a." },
    { aba: "107006143", name: "Firstbank North, Na." },
    { aba: "107006156", name: "Firstbank Of Boulder, N.a." },
    { aba: "107006253", name: "United Business Bank" },
    { aba: "107006305", name: "Firstbank" },
    { aba: "107006347", name: "Firstbank" },
    { aba: "107006363", name: "Independent Bank" },
    { aba: "107006389", name: "Cache Bank And Trust" },
    { aba: "107006392", name: "Firstbank Of Northern Colorado" },
    { aba: "107006428", name: "Fortis Private Bank" },
    { aba: "107006431", name: "Firstbank" },
    { aba: "107006444", name: "Tcf National Bank - Co Region" },
    { aba: "107006460", name: "Nbh Bank" },
    { aba: "107006486", name: "Main Bank" },
    { aba: "107006512", name: "Firstbank" },
    { aba: "107006541", name: "New Mexico Bank & Trust" },
    { aba: "107006554", name: "Firstbank" },
    { aba: "107006606", name: "Bokf, N.a." },
    { aba: "107006651", name: "Firstbank" },
    { aba: "107006664", name: "Firstbank" },
    { aba: "107006677", name: "First National Bank" },
    { aba: "107006732", name: "Tbk Bank, Ssb" },
    { aba: "107006787", name: "First National Bank" },
    { aba: "107006813", name: "Bank Of The West" },
    { aba: "107006839", name: "Flatirons Bank" },
    { aba: "107006871", name: "Legacy Bank" },
    { aba: "107006884", name: "Kirkpatrick Bank" },
    { aba: "107006897", name: "Champion Bank" },
    { aba: "107006923", name: "Cheyenne State Bank" },
    { aba: "107006936", name: "Bbva Usa" },
    { aba: "107006949", name: "Bbva Usa" },
    { aba: "107006965", name: "Nbh Bank" },
    { aba: "107006978", name: "Bank Of Colorado" },
    { aba: "107006981", name: "Citywide Banks" },
    { aba: "107006994", name: "Integrity Bank And Trust" },
    { aba: "107007058", name: "Points West Community Bank" },
    { aba: "107007074", name: "Verus Bank Of Commerce" },
    { aba: "107007087", name: "Citywide Banks" },
    { aba: "107007139", name: "Western States Bank" },
    { aba: "107007197", name: "Citywide Banks" },
    { aba: "107007210", name: "First Farmbank" },
    { aba: "107007223", name: "Verus Bank Of Commerce" },
    { aba: "107007281", name: "Solera National Bank" },
    { aba: "107007317", name: "First American State Bank" },
    { aba: "107007320", name: "Verus Bank Of Commerce" },
    { aba: "107007346", name: "Amg National Trust Bank" },
    { aba: "107007359", name: "North Valley Bank" },
    { aba: "107007375", name: "Mountain View Bank Of Commerce" },
    { aba: "107007391", name: "Redstone Bank" },
    { aba: "107007443", name: "Tbk Bank, Ssb" },
    { aba: "107007469", name: "Farmers Bank" },
    { aba: "107007498", name: "Fortis Private Bank" },
    { aba: "107007508", name: "Cobank" },
    { aba: "107089199", name: "Shambhala Credit Union" },
    { aba: "107089335", name: "Colorado Federal Savings Bank" },
    { aba: "107089555", name: "Jpmorgan Chase" },
    { aba: "107089652", name: "First Citizens Bank & Trust Company" },
    { aba: "107089717", name: "Midland States Bank" },
    { aba: "107089720", name: "Sandia Labs Fcu ( Secondary Rtn)" },
    { aba: "107089843", name: "Century Bank" },
    { aba: "107089885", name: "Colorado Federal Savings Bank" },
    { aba: "111000012", name: "Bank Of America, N.a." },
    { aba: "111000025", name: "Bank Of America, N.a." },
    { aba: "111000038", name: "Federal Reserve Bank" },
    { aba: "111000614", name: "Jpmorgan Chase" },
    { aba: "111000711", name: "Bank Of Texas" },
    { aba: "111000753", name: "Comerica Bank - Texas" },
    { aba: "111000805", name: "Prosperity Bank" },
    { aba: "111000960", name: "North Dallas Bank & Trust" },
    { aba: "111001040", name: "Inwood National Bank" },
    { aba: "111001053", name: "Inwood National Bank" },
    { aba: "111001150", name: "Jpmorgan Chase" },
    { aba: "111001189", name: "Bk Sw Dallas" },
    { aba: "111005774", name: "American Bank N A" },
    { aba: "111006207", name: "Bank Of Texas" },
    { aba: "111006841", name: "Bank Of Texas" },
    { aba: "111010060", name: "Prosperity Bank" },
    { aba: "111010170", name: "Tib-the Independent Bankers Bank" },
    { aba: "111010837", name: "Federal Home Loan Bank Of Dallas" },
    { aba: "111011878", name: "Pavillion Bank" },
    { aba: "111012673", name: "Inwood National Bank" },
    { aba: "111012783", name: "Inwood Natioanl Bank" },
    { aba: "111013672", name: "Prosperity Bank" },
    { aba: "111014325", name: "Bokf, N.a." },
    { aba: "111014383", name: "Frost National Bank" },
    { aba: "111015159", name: "Wells Fargo Bank" },
    { aba: "111015544", name: "Accessbank Texas" },
    { aba: "111015748", name: "Umb Bank, N.a." },
    { aba: "111016064", name: "The Northern Trust Company" },
    { aba: "111016501", name: "United Texas Bank" },
    { aba: "111017458", name: "State Bk Of Texas" },
    { aba: "111017694", name: "Truist Bank" },
    { aba: "111017791", name: "Happy State Bank" },
    { aba: "111017827", name: "Hancock Whitney Bank" },
    { aba: "111017911", name: "American National Bank" },
    { aba: "111017979", name: "Texas Capital Bank" },
    { aba: "111024331", name: "Bankdirect" },
    { aba: "111024386", name: "Interbank" },
    { aba: "111024467", name: "Prosperity Bank" },
    { aba: "111024645", name: "Tolleson Private Bank" },
    { aba: "111024849", name: "Prosperity Bank" },
    { aba: "111024865", name: "Veritex Community Bank" },
    { aba: "111024975", name: "T Bank" },
    { aba: "111025013", name: "Wells Fargo Bank" },
    { aba: "111025084", name: "Bank Of Hope" },
    { aba: "111025123", name: "One World Bank" },
    { aba: "111025453", name: "Pacific Premier Bank" },
    { aba: "111025466", name: "Pegasus Bank" },
    { aba: "111025518", name: "Iberiabank" },
    { aba: "111025521", name: "Pegasus Bank" },
    { aba: "111025534", name: "Prosperity Bank" },
    { aba: "111025877", name: "Texas Security Bank" },
    { aba: "111025903", name: "Community Trust Bank" },
    { aba: "111026038", name: "Shinhan Bank America" },
    { aba: "111026041", name: "Mufg Union Bank, N.a." },
    { aba: "111040195", name: "Federal Home Loan Bk Of Dallas" },
    { aba: "111050156", name: "Public Affairs- Frb Dallas" },
    { aba: "111050295", name: "Frb Dallas Customer Accounting" },
    { aba: "111093650", name: "Bluebonnet Savings Bank, Fbs" },
    { aba: "111093715", name: "Prosperity Bank" },
    { aba: "111094235", name: "Oak Cliff Christian Fcu" },
    { aba: "111100844", name: "Bank Of Winnfield" },
    { aba: "111100909", name: "Exchange Bank & Trust Co" },
    { aba: "111101128", name: "Hodge Bank & Trust Company" },
    { aba: "111101144", name: "Delta Bank" },
    { aba: "111101157", name: "Capital One, N.a." },
    { aba: "111101173", name: "American Bk & Trust Co Inc" },
    { aba: "111101209", name: "B1bank" },
    { aba: "111101225", name: "First National Bank" },
    { aba: "111101306", name: "Winnsboro State Bank & Trust Co" },
    { aba: "111101319", name: "Franklin State Bank & Trust Co" },
    { aba: "111101458", name: "Guaranty Bank & Trust Co" },
    { aba: "111101461", name: "Capital One, N.a." },
    { aba: "111101597", name: "Peoples Bank" },
    { aba: "111101636", name: "Colfax Banking Company" },
    { aba: "111101652", name: "Caldwell Bank & Trust" },
    { aba: "111101681", name: "Bank Of Coushatta" },
    { aba: "111101694", name: "Citizens Bank & Trust Co" },
    { aba: "111101775", name: "Community Trust Bank" },
    { aba: "111101898", name: "Clb The Community Bank" },
    { aba: "111101908", name: "Jackson Parish Bank" },
    { aba: "111101911", name: "Clb The Community Bank" },
    { aba: "111101982", name: "Community Bank Of La." },
    { aba: "111101995", name: "Capital One, N.a." },
    { aba: "111102046", name: "Southern Heritage Bank" },
    { aba: "111102059", name: "Sabine State Bk & Trust" },
    { aba: "111102075", name: "Marion State Bank" },
    { aba: "111102091", name: "Mer Rouge State Bank" },
    { aba: "111102114", name: "Bom Bank" },
    { aba: "111102208", name: "Bank Of Oak Ridge" },
    { aba: "111102240", name: "Delta Bank" },
    { aba: "111102321", name: "Bom Bank" },
    { aba: "111102389", name: "Cross Keys Bank" },
    { aba: "111102402", name: "Cross Keys Bank" },
    { aba: "111102431", name: "Concordia Bank & Trust" },
    { aba: "111102596", name: "Progressive Bank" },
    { aba: "111102732", name: "Bom Bank" },
    { aba: "111102758", name: "Origin Bank" },
    { aba: "111102813", name: "Jonesboro State Bk" },
    { aba: "111102842", name: "Hancock Whitney Bank" },
    { aba: "111102949", name: "City Bank & Trust" },
    { aba: "111103058", name: "Bancorp South" },
    { aba: "111103168", name: "Progressive National Bank" },
    { aba: "111103197", name: "Iberiabank" },
    { aba: "111103210", name: "Capital One, N.a." },
    { aba: "111103281", name: "Sicily Island State Bk" },
    { aba: "111103333", name: "First Natl Bank Of Benton" },
    { aba: "111103362", name: "Citizens Progressive Bank" },
    { aba: "111103524", name: "Citizens National Bank, N.a." },
    { aba: "111103634", name: "Capital One, N.a." },
    { aba: "111103650", name: "Bancorpsouth Bank" },
    { aba: "111103948", name: "Tensas State Bank" },
    { aba: "111104507", name: "Capital One, N.a." },
    { aba: "111104581", name: "Community Bank Of La." },
    { aba: "111104617", name: "Gibsland Bank & Trust Co" },
    { aba: "111104743", name: "Bancorp South" },
    { aba: "111104785", name: "Capital One, N.a." },
    { aba: "111104798", name: "Capital One, N.a." },
    { aba: "111104853", name: "Capital One, N.a." },
    { aba: "111104879", name: "Capital One, N.a." },
    { aba: "111104921", name: "Citizens National Bank, N.a." },
    { aba: "111104947", name: "Capital One, N.a." },
    { aba: "111105234", name: "Iberiabank" },
    { aba: "111105302", name: "Commercial Capital Bank" },
    { aba: "111105315", name: "Community Trust Bank" },
    { aba: "111105331", name: "Commerce Community Bank" },
    { aba: "111105441", name: "Community Trust Bank" },
    { aba: "111193550", name: "Centric Federal Credit Union" },
    { aba: "111193673", name: "Red River Mill Employees Federal Cu" },
    { aba: "111193932", name: "Ubc Southern Cnl. Of Ind. Wrks Fcu" },
    { aba: "111300880", name: "Jpmorgan Chase" },
    { aba: "111300945", name: "Bank Of America" },
    { aba: "111300958", name: "Amarillo National Bank" },
    { aba: "111301122", name: "First Financial Bank, N.a." },
    { aba: "111301436", name: "Sundown State Bank" },
    { aba: "111301614", name: "Kress National Bank" },
    { aba: "111301737", name: "City Bank N A" },
    { aba: "111302095", name: "Western Nb--firstbank Southwest" },
    { aba: "111302370", name: "State National Bank" },
    { aba: "111302532", name: "Citizens National Bank Of Childress" },
    { aba: "111302545", name: "First Bank & Trust" },
    { aba: "111302587", name: "First National Bank" },
    { aba: "111302600", name: "Keystone Bank, National Association" },
    { aba: "111302833", name: "Waggoner National Bank" },
    { aba: "111302846", name: "Herring Bank" },
    { aba: "111303007", name: "First Capital Bank" },
    { aba: "111303010", name: "Texas Republic Bank, N.a." },
    { aba: "111303117", name: "Interbank" },
    { aba: "111303489", name: "Commercial National Bank" },
    { aba: "111303492", name: "Brady National Bank" },
    { aba: "111303667", name: "Oakwood Bank" },
    { aba: "111303748", name: "Haskell National Bank" },
    { aba: "111304051", name: "First Bank & Trust" },
    { aba: "111304064", name: "Interbank" },
    { aba: "111304093", name: "Prosperity Bank" },
    { aba: "111304307", name: "Vista Bank" },
    { aba: "111304349", name: "Donley County State Bank" },
    { aba: "111304381", name: "First Bank And Trust" },
    { aba: "111304608", name: "First National Bank" },
    { aba: "111304637", name: "City National Bank" },
    { aba: "111304912", name: "First Bank Texas, Ssb" },
    { aba: "111305115", name: "Happy State Bank" },
    { aba: "111305571", name: "Spur Security Bank" },
    { aba: "111305746", name: "Santa Anna National Bank" },
    { aba: "111305856", name: "Bank Of The West" },
    { aba: "111306059", name: "Wellington State Bank" },
    { aba: "111306088", name: "Crowell State Bank" },
    { aba: "111306198", name: "First Natl Bk Of Albany/breckenridge" },
    { aba: "111306758", name: "First National Bank" },
    { aba: "111306871", name: "First Financial Bank Na" },
    { aba: "111307252", name: "First National Bank" },
    { aba: "111307265", name: "First National Bank" },
    { aba: "111307317", name: "Prosperity Bank" },
    { aba: "111307430", name: "First Bank Texas, Ssb" },
    { aba: "111307469", name: "Happy State Bank" },
    { aba: "111307485", name: "Roscoe State Bank" },
    { aba: "111307582", name: "Aimbank" },
    { aba: "111307663", name: "Amarillo National Bank" },
    { aba: "111307841", name: "First National Bank" },
    { aba: "111308031", name: "Prosperity Bank" },
    { aba: "111308057", name: "Firstbank Southwest" },
    { aba: "111308277", name: "Citizens Bank" },
    { aba: "111308358", name: "First National Bank" },
    { aba: "111308442", name: "Bank Of Commerce" },
    { aba: "111308552", name: "Wellington State Bank" },
    { aba: "111308578", name: "Pilgrim Bank" },
    { aba: "111308633", name: "First State Bank" },
    { aba: "111308727", name: "First United Bank" },
    { aba: "111308730", name: "Lamesa National Bank" },
    { aba: "111309030", name: "First State Bank" },
    { aba: "111309043", name: "Prosperity Bank" },
    { aba: "111309234", name: "First National Bank" },
    { aba: "111309742", name: "Prosperity Bank" },
    { aba: "111310294", name: "First United Bank" },
    { aba: "111310391", name: "Texas Fncl Bk, Dba The Eden State Bk" },
    { aba: "111310809", name: "State National Bank" },
    { aba: "111310841", name: "Vista Bank" },
    { aba: "111310870", name: "Happy State Bank" },
    { aba: "111311413", name: "First Bank & Trust" },
    { aba: "111311743", name: "First State Bank" },
    { aba: "111311785", name: "First National Bank" },
    { aba: "111311798", name: "First United Bank" },
    { aba: "111311840", name: "Happy State Bank" },
    { aba: "111312182", name: "Ozona National Bank" },
    { aba: "111312205", name: "First State Bank" },
    { aba: "111312535", name: "Crockett National Bank" },
    { aba: "111312739", name: "First Bank Texas, Ssb" },
    { aba: "111312894", name: "First State Bank" },
    { aba: "111313000", name: "First National Bank Of Sonora, The" },
    { aba: "111313204", name: "Interbank" },
    { aba: "111313356", name: "Interbank" },
    { aba: "111313479", name: "First National Bank" },
    { aba: "111313631", name: "Citizens National Bank" },
    { aba: "111314504", name: "Herring National Bank" },
    { aba: "111314575", name: "Vista Bank" },
    { aba: "111315011", name: "Texas National Bank" },
    { aba: "111315794", name: "First National Bank" },
    { aba: "111316612", name: "Interbank" },
    { aba: "111316793", name: "Aimbank" },
    { aba: "111316829", name: "Amistad Bank" },
    { aba: "111316887", name: "Peoples Bank Of Lubbock" },
    { aba: "111317527", name: "Vista Bank" },
    { aba: "111317569", name: "Texas National Bank" },
    { aba: "111317585", name: "Crockett National Bank" },
    { aba: "111317637", name: "Security State Bank" },
    { aba: "111317789", name: "Western Bank" },
    { aba: "111317844", name: "First State Bank" },
    { aba: "111317857", name: "Bbva Usa" },
    { aba: "111317860", name: "First United Bank" },
    { aba: "111318063", name: "Interbank" },
    { aba: "111318267", name: "American National Bank & Trust" },
    { aba: "111318584", name: "Perryton National Bank" },
    { aba: "111318610", name: "Menard Bank" },
    { aba: "111319004", name: "First Bank & Trust, Wilson Branch" },
    { aba: "111319347", name: "First Bank & Trust" },
    { aba: "111319538", name: "First National Bank" },
    { aba: "111320006", name: "Citizens Bank" },
    { aba: "111320679", name: "First United Bank-suda" },
    { aba: "111320899", name: "Security State Bank" },
    { aba: "111320925", name: "First State Bank" },
    { aba: "111321063", name: "Aimbank" },
    { aba: "111321144", name: "Happy State Bank" },
    { aba: "111321270", name: "American Momentum Bank" },
    { aba: "111321649", name: "Happy State Bank" },
    { aba: "111321717", name: "Happy State Bank" },
    { aba: "111321720", name: "Wellington State Bank" },
    { aba: "111321814", name: "Coleman County State Bank" },
    { aba: "111321827", name: "Robert Lee State Bank" },
    { aba: "111321856", name: "Citizens State Bank" },
    { aba: "111322318", name: "Western Bank" },
    { aba: "111322402", name: "Citizens State Bank" },
    { aba: "111322457", name: "First Financial Bank" },
    { aba: "111322538", name: "Wellington State Bank" },
    { aba: "111322570", name: "First Financial Bank, N.a." },
    { aba: "111322583", name: "Prosperity Bank" },
    { aba: "111322664", name: "West Texas State Bank" },
    { aba: "111322729", name: "Happy State Bank" },
    { aba: "111322745", name: "First United Bank" },
    { aba: "111322813", name: "Interbank" },
    { aba: "111322910", name: "Firstbank Southwest" },
    { aba: "111322965", name: "First Bank Muleshoe" },
    { aba: "111322994", name: "Plainscapital Bank" },
    { aba: "111323016", name: "Prosperity Bank" },
    { aba: "111323032", name: "Vista Bank" },
    { aba: "111323207", name: "Plains State Bank" },
    { aba: "111323689", name: "First State Bank" },
    { aba: "111323731", name: "Western Bank" },
    { aba: "111323809", name: "Bank Of Texas" },
    { aba: "111323906", name: "Texas State Bank" },
    { aba: "111323922", name: "American Bank Of Commerce" },
    { aba: "111323951", name: "Interbank" },
    { aba: "111324196", name: "Herring Bank" },
    { aba: "111324219", name: "Amarillo National Bank" },
    { aba: "111324293", name: "First National Bank Of Hereford" },
    { aba: "111324921", name: "Bank Of San Angelo" },
    { aba: "111325713", name: "Aimbank" },
    { aba: "111325797", name: "Lone Star State Bank Of West Texas" },
    { aba: "111325823", name: "Peoples Bank" },
    { aba: "111326068", name: "American Bank Of Commerce" },
    { aba: "111326071", name: "Amarillo National Bank" },
    { aba: "111736713", name: "Frb Dallas Treasury Services" },
    { aba: "111736726", name: "Frb Dallas Treasury Services" },
    { aba: "111736739", name: "Frb Dallas Treasury Services" },
    { aba: "111736991", name: "Dept Of Treasury, Fms, Kc Fncl Ctr" },
    { aba: "111900251", name: "Frost Bank" },
    { aba: "111900455", name: "Capital One, N.a." },
    { aba: "111900510", name: "Capital One, N.a." },
    { aba: "111900549", name: "National Bank Of Texas" },
    { aba: "111900578", name: "Liberty National Bank" },
    { aba: "111900581", name: "Extraco Banks N.a." },
    { aba: "111900594", name: "Community Bank And Trust" },
    { aba: "111900604", name: "American Bank" },
    { aba: "111900659", name: "Wells Fargo Bank" },
    { aba: "111900756", name: "Simmons Bank" },
    { aba: "111900785", name: "Regions Bank" },
    { aba: "111901014", name: "Capital One, N.a." },
    { aba: "111901056", name: "Bancorp South" },
    { aba: "111901234", name: "Prosperity Bank" },
    { aba: "111901302", name: "Bank Of America" },
    { aba: "111901454", name: "Prosperity Bank" },
    { aba: "111901467", name: "First State Bank" },
    { aba: "111901519", name: "The American National Bank Of Texas" },
    { aba: "111901564", name: "Interbank" },
    { aba: "111901580", name: "Citizens National Bank" },
    { aba: "111901629", name: "Citizens National Bank Of Texas" },
    { aba: "111901632", name: "Prosperity Bank" },
    { aba: "111901645", name: "First United Bank And Trust Company" },
    { aba: "111901690", name: "Citizens National Bank" },
    { aba: "111901946", name: "City National Bank" },
    { aba: "111901959", name: "Community Bank" },
    { aba: "111901962", name: "Citibank Texas, National Association" },
    { aba: "111901975", name: "Alliance Bank" },
    { aba: "111901988", name: "First National Bank" },
    { aba: "111902000", name: "Bbva Usa" },
    { aba: "111902055", name: "Benchmark Bank" },
    { aba: "111902385", name: "Austin Bank Texas, N.a." },
    { aba: "111902398", name: "First National Bank Texas" },
    { aba: "111902424", name: "Texasbank Brownwood" },
    { aba: "111902806", name: "Classic Bank, National Association" },
    { aba: "111902819", name: "Citizens National Bank" },
    { aba: "111903151", name: "Verabank, N.a." },
    { aba: "111903232", name: "Texas Exchange Bank, Ssb." },
    { aba: "111903245", name: "First National Bank Of Central Tx" },
    { aba: "111903258", name: "Allegiance Bank" },
    { aba: "111903290", name: "Legend Bank, National Association" },
    { aba: "111903407", name: "Spirit Of Texas Bank, Ssb" },
    { aba: "111903465", name: "Incommons Bank, N.a." },
    { aba: "111903517", name: "Pinnacle Bank" },
    { aba: "111903546", name: "Southstar Bank, S.s.b." },
    { aba: "111903559", name: "Independent Bank" },
    { aba: "111903575", name: "First Financial Bank, Na" },
    { aba: "111903591", name: "Bank Of Houston, Na" },
    { aba: "111903821", name: "Green Bank, N. A." },
    { aba: "111903850", name: "Prosperity Bank" },
    { aba: "111903931", name: "First Texas Bank" },
    { aba: "111903999", name: "Citizens National Bank" },
    { aba: "111904150", name: "First State Bank" },
    { aba: "111904192", name: "Alliance Bank" },
    { aba: "111904215", name: "Wells Fargo Bank" },
    { aba: "111904231", name: "City National Bank" },
    { aba: "111904338", name: "Verabank, N.a." },
    { aba: "111904419", name: "National United" },
    { aba: "111904480", name: "First National Bank" },
    { aba: "111904503", name: "Dallas Capital Bank, N.a." },
    { aba: "111904817", name: "Independent Bank" },
    { aba: "111904859", name: "First National Bank" },
    { aba: "111904943", name: "Texas Bank" },
    { aba: "111904998", name: "City National Bank" },
    { aba: "111905133", name: "Pointwest Bank" },
    { aba: "111905159", name: "Citizens State Bank" },
    { aba: "111905227", name: "Ciera Bank" },
    { aba: "111905230", name: "Interbank" },
    { aba: "111905324", name: "First National Bank" },
    { aba: "111905337", name: "Delta Bank" },
    { aba: "111905340", name: "Fidelity Bank Of Texas" },
    { aba: "111905434", name: "First National Bank" },
    { aba: "111905609", name: "First National Bank Of East Texas" },
    { aba: "111905612", name: "Gilmer National Bank" },
    { aba: "111905638", name: "Prosperity Bank" },
    { aba: "111905641", name: "Jacksboro National Bank" },
    { aba: "111905719", name: "Inwood N Bk, Duncanville Branch" },
    { aba: "111905764", name: "Bancorpsouth Bank" },
    { aba: "111905968", name: "Extraco Banks" },
    { aba: "111906006", name: "Pointbank" },
    { aba: "111906048", name: "Lakeside Bank" },
    { aba: "111906161", name: "First National Bank" },
    { aba: "111906242", name: "Green Bank, N. A." },
    { aba: "111906271", name: "First National Bank Texas" },
    { aba: "111906323", name: "Frost National Bank - Summit" },
    { aba: "111906349", name: "Security Bank Of Crawford" },
    { aba: "111906394", name: "Benchmark Bank" },
    { aba: "111906462", name: "First State Bank" },
    { aba: "111906501", name: "Texas Heritage National Bank" },
    { aba: "111906611", name: "Morris County National Bank" },
    { aba: "111906624", name: "Bancorpsouth Bank" },
    { aba: "111906747", name: "Mcbank" },
    { aba: "111906857", name: "Bank Of Texas, N.a." },
    { aba: "111906860", name: "Interbank" },
    { aba: "111906996", name: "Grandview Bank" },
    { aba: "111907021", name: "Farmers & Merchants Bank" },
    { aba: "111907089", name: "State Bank Of De Kalb" },
    { aba: "111907102", name: "Commercial Bank Of Texas, N.a." },
    { aba: "111907144", name: "American National Bank & Trust" },
    { aba: "111907199", name: "Central National Bank" },
    { aba: "111907348", name: "First National Bank" },
    { aba: "111907429", name: "Spring Hill State Bank" },
    { aba: "111907445", name: "Bbva Usa" },
    { aba: "111907490", name: "Oakwood Bank" },
    { aba: "111907555", name: "Interbank" },
    { aba: "111907568", name: "Bank Of Texas, N.a." },
    { aba: "111907607", name: "Frost Bank Overton" },
    { aba: "111907652", name: "Citizens National Bank Of Texas" },
    { aba: "111907694", name: "First State Bank" },
    { aba: "111907717", name: "First Bank Of Celeste" },
    { aba: "111907775", name: "Citizens State Bank" },
    { aba: "111907788", name: "First Bank" },
    { aba: "111907827", name: "Mineola Community Bank, Ssb" },
    { aba: "111907940", name: "Horizon Bank, Ssb" },
    { aba: "111907979", name: "Texas Bank Financial" },
    { aba: "111908004", name: "First Bank & Trust" },
    { aba: "111908091", name: "Independent Bank" },
    { aba: "111908127", name: "Pioneer Bank, Ssb" },
    { aba: "111908172", name: "City National Bank-sulphur Springs" },
    { aba: "111908208", name: "American National Bank" },
    { aba: "111908499", name: "National Bank" },
    { aba: "111908509", name: "First National Bank" },
    { aba: "111908512", name: "Commercial State Bank" },
    { aba: "111908567", name: "First Financial Bank, N.a." },
    { aba: "111908583", name: "Captex Bank, N.a." },
    { aba: "111908758", name: "First National Bank" },
    { aba: "111908787", name: "The Cowboy Bank Of Texas" },
    { aba: "111908868", name: "Frost National Bank - Summit" },
    { aba: "111908949", name: "Bancorpsouth Bank" },
    { aba: "111908952", name: "Austin Bank Texas N.a." },
    { aba: "111908965", name: "Citizens State Bank" },
    { aba: "111909029", name: "The Northern Trust Company" },
    { aba: "111909210", name: "American State Bank" },
    { aba: "111909265", name: "Community Bank" },
    { aba: "111909281", name: "First National Bank" },
    { aba: "111909362", name: "City National Bk Of Sulphur Springs" },
    { aba: "111909391", name: "Cendera Bank, N.a." },
    { aba: "111909443", name: "First State Bank" },
    { aba: "111909511", name: "First Nat Bk Of Hughes Springs, The" },
    { aba: "111909524", name: "Extraco Banks, N.a." },
    { aba: "111909579", name: "Tbk Bank, Ssb" },
    { aba: "111909605", name: "Pinnacle Bank" },
    { aba: "111909634", name: "Wells Fargo Bank" },
    { aba: "111909663", name: "First Natl Bk Albany/breckenridge" },
    { aba: "111909676", name: "Southstar Bank, S.s.b." },
    { aba: "111909760", name: "Ciera Bank" },
    { aba: "111909773", name: "Buckholts State Bank" },
    { aba: "111909825", name: "First National Bank Of East Texas" },
    { aba: "111909841", name: "Firstcapital Bank Of Texas, N.a." },
    { aba: "111909867", name: "Citibank Texas, National Association" },
    { aba: "111909870", name: "Lamar National Bank" },
    { aba: "111909993", name: "Spirit Of Texas Bank, Ssb" },
    { aba: "111910005", name: "Bancorpsouth Bank" },
    { aba: "111910092", name: "Citizens State Bank" },
    { aba: "111910128", name: "Frost Bank-mansfield" },
    { aba: "111910160", name: "First Security State Bank" },
    { aba: "111910267", name: "First Bank Texas, Ssb" },
    { aba: "111910445", name: "Citizens Bank" },
    { aba: "111910607", name: "Prosperity Bank" },
    { aba: "111910681", name: "Prosperity Bank" },
    { aba: "111910762", name: "Titan Bank Na" },
    { aba: "111911033", name: "First National Bank" },
    { aba: "111911091", name: "Pilgrim Bank" },
    { aba: "111911101", name: "Iredell State Bank" },
    { aba: "111911156", name: "Alliance Bank Central Texas" },
    { aba: "111911224", name: "Justin State Bank" },
    { aba: "111911321", name: "First United Bank & Trust" },
    { aba: "111911826", name: "Extrac0 Banks, N.a." },
    { aba: "111911965", name: "Farmers State Bank Of Newcastle" },
    { aba: "111912090", name: "First National Bank" },
    { aba: "111912197", name: "City National Bank" },
    { aba: "111912252", name: "First State Bank" },
    { aba: "111912388", name: "Powell State Bank" },
    { aba: "111912401", name: "First Bank & Trust" },
    { aba: "111912427", name: "Texas Bank" },
    { aba: "111912498", name: "Bth Bank Na" },
    { aba: "111912605", name: "First State Bank" },
    { aba: "111912744", name: "Pinnacle Bank" },
    { aba: "111912854", name: "Spirit Of Texas Bank, Ssb" },
    { aba: "111913060", name: "1st Natl Bk Of Albany Strawn Branch" },
    { aba: "111913073", name: "Community National Bk & Tr Of Texas" },
    { aba: "111913170", name: "Panola National Bank" },
    { aba: "111913248", name: "First National Bank" },
    { aba: "111913329", name: "First National Bank Of Bosque County" },
    { aba: "111913426", name: "Frost Bank Fossil Creek" },
    { aba: "111913510", name: "Inwood National Bank" },
    { aba: "111913688", name: "Pilgrim Bank" },
    { aba: "111913756", name: "Prosperity Bank" },
    { aba: "111913879", name: "Texas Heritage Bank" },
    { aba: "111914027", name: "Frost National Bank - Summit" },
    { aba: "111914218", name: "First Financial Bank Na" },
    { aba: "111914302", name: "First National Bank Of East Texas" },
    { aba: "111914687", name: "Bancorp South" },
    { aba: "111914849", name: "First Financial Bank, N.a." },
    { aba: "111914852", name: "Hanmi Bank" },
    { aba: "111914878", name: "First National Bank" },
    { aba: "111914881", name: "Normangee State Bank" },
    { aba: "111914917", name: "Community Bank" },
    { aba: "111915039", name: "Farmers State Bank" },
    { aba: "111915042", name: "Legend Bank, N.a." },
    { aba: "111915259", name: "American First National Bank" },
    { aba: "111915327", name: "Guaranty Bank & Trust, N.a." },
    { aba: "111915398", name: "Prosperity Bank" },
    { aba: "111915576", name: "Extraco Banks" },
    { aba: "111915686", name: "Citibank Texas, National Association" },
    { aba: "111915709", name: "Capital One, N.a." },
    { aba: "111915770", name: "First National Bank" },
    { aba: "111916180", name: "Third Coast Bank Ssb" },
    { aba: "111916287", name: "Extraco Banks, N.a." },
    { aba: "111916326", name: "Independent Bank" },
    { aba: "111916410", name: "Extraco Banks, N.a." },
    { aba: "111916423", name: "Prosperity Bank" },
    { aba: "111916436", name: "Veritex Community Bank" },
    { aba: "111916452", name: "First National Bank" },
    { aba: "111916588", name: "Texana Bank Na" },
    { aba: "111916591", name: "The American National Bank Of Texas" },
    { aba: "111916627", name: "Extraco Banks N.a." },
    { aba: "111916656", name: "Peoples Bank" },
    { aba: "111916698", name: "Bank Of Desoto" },
    { aba: "111916724", name: "Bank Of The West" },
    { aba: "111916915", name: "Spectra Bank" },
    { aba: "111916999", name: "Green Bank, N. A." },
    { aba: "111917215", name: "Frost National Bank - Summit" },
    { aba: "111917309", name: "Sanger Bank" },
    { aba: "111917367", name: "Community National Bk & Trust Of Tx" },
    { aba: "111917383", name: "Prosperity Bank" },
    { aba: "111917435", name: "Prosperity Bank" },
    { aba: "111917574", name: "Ciera Bank" },
    { aba: "111917590", name: "First State Bank" },
    { aba: "111917943", name: "Simmons Bank" },
    { aba: "111918230", name: "Citizens State Bank" },
    { aba: "111918845", name: "Alliance Bank Central Texas" },
    { aba: "111919433", name: "First Financial Bank, N. A." },
    { aba: "111920312", name: "Ennis State Bank" },
    { aba: "111920325", name: "Fannin Bank" },
    { aba: "111920684", name: "Muenster State Bank" },
    { aba: "111920765", name: "Community National Bk & Trust Of Tx" },
    { aba: "111921230", name: "First Financial Bank, Natl Assoc." },
    { aba: "111921751", name: "First Financial Bank, N.a." },
    { aba: "111921777", name: "First State Bank" },
    { aba: "111922132", name: "Southstar Bank, S.s.b." },
    { aba: "111922488", name: "Austin Bank, N.a." },
    { aba: "111922527", name: "City National Bank" },
    { aba: "111922624", name: "Citizens Bank" },
    { aba: "111922776", name: "Homebank Texas" },
    { aba: "111923238", name: "Texas Bank And Trust" },
    { aba: "111923490", name: "National Bank" },
    { aba: "111923607", name: "Southside Bank" },
    { aba: "111923898", name: "American National Bank Of Texas" },
    { aba: "111923937", name: "First Texas Bank" },
    { aba: "111924075", name: "Community National Bk & Tr Of Texas" },
    { aba: "111924143", name: "American National Bank" },
    { aba: "111924237", name: "Southside Bank" },
    { aba: "111924305", name: "Pioneer Bank, Ssb" },
    { aba: "111924350", name: "Verabank, N.a." },
    { aba: "111924363", name: "Interbank" },
    { aba: "111924392", name: "First National Bank Frisco" },
    { aba: "111924428", name: "Simmons Bank" },
    { aba: "111924473", name: "Prosperity Bank" },
    { aba: "111924538", name: "Texas Republic Bank" },
    { aba: "111924554", name: "Spirit Bank Of Texas, Ssb" },
    { aba: "111924606", name: "Tib-the Independent Bankers Bank" },
    { aba: "111924619", name: "Simmons Bank" },
    { aba: "111924622", name: "Worthington National Bank" },
    { aba: "111924651", name: "Veritex Community Bank" },
    { aba: "111924680", name: "The First National Bk Of Central Tx" },
    { aba: "111924716", name: "Trinity Bank" },
    { aba: "111924787", name: "Firstcapital Bank Of Texas, N.a." },
    { aba: "111924800", name: "Interbank" },
    { aba: "111924994", name: "Veritex Community Bank" },
    { aba: "111925032", name: "Bank Ozk" },
    { aba: "111925045", name: "First State Bank" },
    { aba: "111925061", name: "First United Bank And Trust Company" },
    { aba: "111925113", name: "Texas Brand Bank" },
    { aba: "111925281", name: "Premier Bank" },
    { aba: "111925317", name: "Prosperity Bank" },
    { aba: "111925320", name: "Prosperity Bank" },
    { aba: "111925359", name: "Banco Popular North America" },
    { aba: "111925430", name: "Farmers Bank And Trust Company" },
    { aba: "111925508", name: "Umb Bank, N.a." },
    { aba: "111925579", name: "The Community Bank" },
    { aba: "111925582", name: "Valliance Bank" },
    { aba: "111925621", name: "The Bank" },
    { aba: "111925634", name: "Opportunity Bank, N.a." },
    { aba: "111925676", name: "Veritex Community Bank" },
    { aba: "111925744", name: "Access Bank Texas" },
    { aba: "111925757", name: "The National Bank Of Texas Ft. Worth" },
    { aba: "111925812", name: "Central National Bank" },
    { aba: "111925935", name: "Liberty Capital Bank" },
    { aba: "111926057", name: "First National Bank Texas" },
    { aba: "111926086", name: "First National Bank Of Central Tx" },
    { aba: "111993572", name: "Covenant Savings Federal Cr Un" },
    { aba: "111993695", name: "First Command Bank" },
    { aba: "111993747", name: "First Guaranty Bank" },
    { aba: "111993763", name: "Mt. Olive Baptist Church Fed Cr Un" },
    { aba: "111993776", name: "Jpmorgan Chase" },
    { aba: "111993873", name: "Superior Community Credit Union" },
    { aba: "111994063", name: "Texans Credit Union" },
    { aba: "111994115", name: "Providence Bank Of Texas" },
    { aba: "111994241", name: "Eecu" },
    { aba: "111994296", name: "Redeemer Federal Credit Union" },
    { aba: "112000011", name: "Federal Reserve Bank El Paso" },
    { aba: "112000066", name: "Wells Fargo And Company" },
    { aba: "112000150", name: "Jpmorgan Chase" },
    { aba: "112017619", name: "Weststar Bank" },
    { aba: "112024437", name: "United Bank Of El Paso Del Norte" },
    { aba: "112025342", name: "Bank Of The West" },
    { aba: "112093808", name: "Sunflower Bank National Association" },
    { aba: "112200439", name: "First American Bank" },
    { aba: "112200620", name: "The James Polk Stone Community Bank" },
    { aba: "112200824", name: "Western Bank" },
    { aba: "112201085", name: "Western Commerce Bank" },
    { aba: "112201218", name: "Citizens Bank" },
    { aba: "112201289", name: "Citizens Bank Of Las Cruces" },
    { aba: "112201797", name: "Carlsbad National Bank" },
    { aba: "112201836", name: "Lea County State Bank" },
    { aba: "112201959", name: "First State Bank" },
    { aba: "112201975", name: "Citizens Bk, Div. Citizens Bk Claude" },
    { aba: "112201988", name: "Western Bank" },
    { aba: "112202123", name: "First National Bank" },
    { aba: "112202372", name: "First New Mexico Bank" },
    { aba: "112202660", name: "Western Bank Of Clovis" },
    { aba: "112203038", name: "First Savings Bank" },
    { aba: "112203122", name: "Valley Bank Of Commerce" },
    { aba: "112204286", name: "First National Bank" },
    { aba: "112205340", name: "First New Mexico Bank Of Silver City" },
    { aba: "112205764", name: "Sunflower Bank National Association" },
    { aba: "112206297", name: "Bank Of The Southwest" },
    { aba: "112206459", name: "First American Bank" },
    { aba: "112206763", name: "Western Heritage Bank" },
    { aba: "112206776", name: "The Bank Of Clovis" },
    { aba: "112206792", name: "American Heritage Bank" },
    { aba: "112207209", name: "Citizens Bank Of Las Cruces" },
    { aba: "112207335", name: "First New Mexico Bank, Las Cruces" },
    { aba: "112207403", name: "Sunflower Bank National Association" },
    { aba: "112304678", name: "West Texas National Bank" },
    { aba: "112304733", name: "Big Bend Banks N.a." },
    { aba: "112306427", name: "Big Bend Banks N.a." },
    { aba: "112307222", name: "First National Bank" },
    { aba: "112311713", name: "National Bank Of Andrews" },
    { aba: "112313342", name: "Western Bank" },
    { aba: "112314066", name: "Fort Davis State Bank" },
    { aba: "112319524", name: "Weststar Bank" },
    { aba: "112320788", name: "Transpecos Banks, Ssb" },
    { aba: "112321318", name: "Pecos County State Bank" },
    { aba: "112321839", name: "City Bank, Na" },
    { aba: "112322508", name: "Southwest Bank" },
    { aba: "112322634", name: "American Momentum Bank" },
    { aba: "112323086", name: "American Momentum Bank" },
    { aba: "112323387", name: "First National Bank" },
    { aba: "112323594", name: "Tejas Bank" },
    { aba: "113000023", name: "Bank Of America, N.a." },
    { aba: "113000049", name: "Fed Reserve Bk" },
    { aba: "113000609", name: "Jpmorgan Chase" },
    { aba: "113000845", name: "Frost Bank Harrisburg" },
    { aba: "113000861", name: "International Bank Of Commerce" },
    { aba: "113000939", name: "Prosperity Bank" },
    { aba: "113000968", name: "Hancock Whitney Bank" },
    { aba: "113001035", name: "Bank Ozk" },
    { aba: "113001077", name: "Central Bank" },
    { aba: "113001242", name: "Unity National Bank" },
    { aba: "113001255", name: "Prosperity Bank" },
    { aba: "113001394", name: "Bank Of Woodforest" },
    { aba: "113002296", name: "Capital Bank" },
    { aba: "113002940", name: "Frost Bank-houston" },
    { aba: "113003389", name: "International Bank Of Commerce" },
    { aba: "113007835", name: "Banco Popular, National Association" },
    { aba: "113008083", name: "Prosperity Bank" },
    { aba: "113008465", name: "Woodforest National Bank" },
    { aba: "113008766", name: "Prosperity Bank" },
    { aba: "113009464", name: "Prosperity Bank" },
    { aba: "113010217", name: "First National Bank Houston" },
    { aba: "113010547", name: "Bbva Usa" },
    { aba: "113011119", name: "Allegiance Bank" },
    { aba: "113011258", name: "Zions Bancorporation, Na Dba Amegy B" },
    { aba: "113011931", name: "The Northern Trust Company" },
    { aba: "113012163", name: "Chasewood Bank" },
    { aba: "113012503", name: "East West Bank" },
    { aba: "113012956", name: "Bank Of Texas, Na" },
    { aba: "113013117", name: "Texas Coastal Bank" },
    { aba: "113014077", name: "International Bank Of Commerce" },
    { aba: "113014336", name: "Frost Bank Park" },
    { aba: "113014909", name: "Prosperity Bank" },
    { aba: "113015267", name: "Prosperity Bank" },
    { aba: "113015429", name: "Communitybank Of Texas Na" },
    { aba: "113015500", name: "Golden Bank, N.a." },
    { aba: "113015584", name: "Comerica Bank" },
    { aba: "113015940", name: "First Prosperity Bank" },
    { aba: "113017346", name: "East West Bank" },
    { aba: "113017870", name: "Wells Fargo Bank" },
    { aba: "113017883", name: "Bank Of Texas, Na" },
    { aba: "113023136", name: "Woodforest Natl Bank" },
    { aba: "113023945", name: "Frost National Bank - Horizon" },
    { aba: "113023990", name: "Pioneer Bank, Ssb" },
    { aba: "113024009", name: "Southwestern National Bank" },
    { aba: "113024096", name: "Allegiance Bank" },
    { aba: "113024106", name: "American First National Bank" },
    { aba: "113024164", name: "Veritex Community Bank" },
    { aba: "113024177", name: "Trustmark National Bank" },
    { aba: "113024274", name: "Prosperity Bank" },
    { aba: "113024407", name: "Cathay Bank" },
    { aba: "113024410", name: "Allegiance Bank" },
    { aba: "113024504", name: "Prosperity Bank" },
    { aba: "113024517", name: "Allegiance Bank" },
    { aba: "113024520", name: "Wells Fargo Bank" },
    { aba: "113024562", name: "First Financial Bank, N.a." },
    { aba: "113024588", name: "Customers Bank" },
    { aba: "113024672", name: "Bbva Bancomer, S.a." },
    { aba: "113024915", name: "Capital One, N.a." },
    { aba: "113024957", name: "Allegiance Bank" },
    { aba: "113025147", name: "Independent Bank" },
    { aba: "113025150", name: "Green Bank, N. A." },
    { aba: "113025231", name: "Plainscapital Bank" },
    { aba: "113025260", name: "Texas Citizens Bank, N.a." },
    { aba: "113025299", name: "Lone Star Bank" },
    { aba: "113025396", name: "Green Bank, N. A." },
    { aba: "113025422", name: "Bancorpsouth Bank" },
    { aba: "113025480", name: "Guaranty Bank And Trust, Na" },
    { aba: "113025723", name: "Allegiance Bank" },
    { aba: "113025765", name: "Bank Of Nova Scotia-houston" },
    { aba: "113025804", name: "Communitybank Of Texas, N.a." },
    { aba: "113025972", name: "The Mint National Bank" },
    { aba: "113026023", name: "Texas First Bank" },
    { aba: "113093519", name: "Team Financial Fcu" },
    { aba: "113093661", name: "Brentwood Baptist Church Fcu" },
    { aba: "113093849", name: "Cadence Bank, Na" },
    { aba: "113093865", name: "Prosperity Bank" },
    { aba: "113093881", name: "Nizari Progressive Federal Credit Un" },
    { aba: "113093946", name: "Light Commerce Federal Credit Union" },
    { aba: "113094071", name: "Empowerment Community Developmnt Fcu" },
    { aba: "113094136", name: "Independent Bank" },
    { aba: "113094149", name: "Third Coast Bank, Ssb" },
    { aba: "113094220", name: "Houston Metropolitan Fcu" },
    { aba: "113094301", name: "Jafarino-interestcu" },
    { aba: "113100046", name: "Frost National Bank" },
    { aba: "113100091", name: "Moody National Bank" },
    { aba: "113100567", name: "Home Town Bank" },
    { aba: "113100570", name: "Hometown Bank, N.a." },
    { aba: "113100745", name: "Verabank, N.a." },
    { aba: "113101317", name: "First National Bank" },
    { aba: "113101524", name: "Bank Of San Jacinto County" },
    { aba: "113101773", name: "Bancorp South" },
    { aba: "113101809", name: "Capital One, N.a." },
    { aba: "113102073", name: "Investar Bank, National Association" },
    { aba: "113102138", name: "Wells Fargo Bank" },
    { aba: "113102303", name: "Prosperity Bank" },
    { aba: "113102329", name: "Citibank Texas, National Association" },
    { aba: "113102442", name: "Pioneer Bank, Ssb" },
    { aba: "113102552", name: "Prosperity Bank" },
    { aba: "113102714", name: "Commercial Bank Of Texas, N.a." },
    { aba: "113103276", name: "Austin Bank, Texas National Assn." },
    { aba: "113103687", name: "First National Bank" },
    { aba: "113103797", name: "Communitybank Of Texas, Na" },
    { aba: "113104000", name: "First National Bank" },
    { aba: "113104178", name: "Prosperity Bank" },
    { aba: "113104275", name: "Citizens State Bank" },
    { aba: "113104521", name: "Prosperity Bank" },
    { aba: "113104534", name: "National Bank & Trust" },
    { aba: "113104712", name: "First National Bank" },
    { aba: "113104796", name: "Newfirst National Bank" },
    { aba: "113104806", name: "First Financial Bank, N.a." },
    { aba: "113104880", name: "First National Bank" },
    { aba: "113105025", name: "International Bank Of Commerce" },
    { aba: "113105070", name: "Farmers State Bank" },
    { aba: "113105274", name: "Austin Bank,n.a. Jacksonville" },
    { aba: "113105368", name: "Prosperity Bank" },
    { aba: "113105449", name: "Wells Fargo Bank" },
    { aba: "113105452", name: "First National Bank" },
    { aba: "113105465", name: "First State Bank" },
    { aba: "113105478", name: "Prosperity Bank" },
    { aba: "113106309", name: "Texas First Bank" },
    { aba: "113106532", name: "Lovelady State Bank" },
    { aba: "113106833", name: "First National Bank" },
    { aba: "113106956", name: "Capital One, N.a." },
    { aba: "113106985", name: "Spirit Of Texas Bank, Ssb" },
    { aba: "113107010", name: "Texas First Bank" },
    { aba: "113107162", name: "Anahuac National Bank" },
    { aba: "113107515", name: "Hill Bank And Trust Co" },
    { aba: "113107612", name: "Prosperity Bank" },
    { aba: "113108802", name: "First National Bank" },
    { aba: "113109128", name: "Allegiance Bank" },
    { aba: "113109131", name: "First National Bank" },
    { aba: "113109377", name: "First State Bank" },
    { aba: "113109720", name: "Prosperity Bank" },
    { aba: "113109830", name: "Burton State Bank" },
    { aba: "113109898", name: "Carmine State Bank" },
    { aba: "113109982", name: "Chappell Hill Bank" },
    { aba: "113110256", name: "Texas First Bank Texas City" },
    { aba: "113110379", name: "Prosperity Bank" },
    { aba: "113110586", name: "Zions Bancorporation, Na Dba Amegy B" },
    { aba: "113110612", name: "Austin Bank, Texas N.a." },
    { aba: "113110641", name: "Citizens State Bank" },
    { aba: "113110654", name: "Brazos National Bank" },
    { aba: "113110667", name: "Bancorp South" },
    { aba: "113110780", name: "Prosperity Bank" },
    { aba: "113110816", name: "Citizens State Bank" },
    { aba: "113110984", name: "Citizens National Bank" },
    { aba: "113111077", name: "Industry State Bank" },
    { aba: "113111378", name: "Prosperity Bank" },
    { aba: "113111909", name: "Woodforest Natl Bank" },
    { aba: "113111983", name: "Communitybank Of Texas, N.a." },
    { aba: "113112995", name: "Citizens State Bank" },
    { aba: "113113237", name: "Main Street National Bank" },
    { aba: "113113363", name: "Texas Gulf Bank N.a." },
    { aba: "113113392", name: "Wallis State Bank" },
    { aba: "113113402", name: "Allegiance Bank" },
    { aba: "113113868", name: "First National Bank" },
    { aba: "113113923", name: "Dominion Bank" },
    { aba: "113114294", name: "Commercial Bank Of Texas, N.a." },
    { aba: "113114595", name: "Prosperity Bank" },
    { aba: "113114647", name: "First Financial Bank, N.a." },
    { aba: "113114896", name: "Round Top State Bank" },
    { aba: "113115235", name: "The First State Bank" },
    { aba: "113115484", name: "Texas Gulf Bank N A" },
    { aba: "113115617", name: "Texas National Bank" },
    { aba: "113116056", name: "First Liberty National Bank" },
    { aba: "113116218", name: "Pioneer Bank, Ssb" },
    { aba: "113116292", name: "Pioneer Bank, Ssb" },
    { aba: "113116386", name: "Prosperity Bank" },
    { aba: "113117194", name: "Brenham National Bank" },
    { aba: "113117204", name: "1st National Bank" },
    { aba: "113117411", name: "Fayetteville Bank" },
    { aba: "113117440", name: "Prosperity Bank" },
    { aba: "113117767", name: "Wells Fargo Bank" },
    { aba: "113118012", name: "Prosperity Bank" },
    { aba: "113118326", name: "Texas First Bank Texas City" },
    { aba: "113118342", name: "Citizens State Bank" },
    { aba: "113118630", name: "Columbus State Bank" },
    { aba: "113119105", name: "Citizens State Bank" },
    { aba: "113119396", name: "Citizens 1st Bank" },
    { aba: "113119985", name: "Southstar Bank, S.s.b.." },
    { aba: "113120291", name: "Peoples State Bank" },
    { aba: "113121135", name: "American State Bank" },
    { aba: "113121384", name: "Peoples State Bank" },
    { aba: "113121902", name: "First Financial Bank, N.a." },
    { aba: "113122082", name: "Bom Bank" },
    { aba: "113122325", name: "Bbva Usa" },
    { aba: "113122655", name: "Prosperity Bank" },
    { aba: "113122804", name: "Southside Bank" },
    { aba: "113122901", name: "Frost Bank-sugarland" },
    { aba: "113123065", name: "First State Bank" },
    { aba: "113123162", name: "First National Bank" },
    { aba: "113123366", name: "First State Bank" },
    { aba: "113123405", name: "Texas First Bank" },
    { aba: "113123447", name: "Hancock Whitney Bank" },
    { aba: "113123528", name: "Bridge City State Bank" },
    { aba: "113123560", name: "First National Bank" },
    { aba: "113123573", name: "Pearland State Bank" },
    { aba: "113123625", name: "First National Bank" },
    { aba: "113123654", name: "First National Bank" },
    { aba: "113123667", name: "Ubank" },
    { aba: "113123719", name: "Texas First Bank Santa Fe" },
    { aba: "113124035", name: "Prosperity Bank" },
    { aba: "113124446", name: "Bank Of Brenham" },
    { aba: "113124598", name: "Spirit Of Texas Bank, Ssb" },
    { aba: "113124637", name: "First Financial Bank" },
    { aba: "113124873", name: "Spirit Of Texas Bank Ssb" },
    { aba: "113124941", name: "American Momentum Bank" },
    { aba: "113125131", name: "Moody National Bank" },
    { aba: "113125254", name: "First Financial Bank, N.a." },
    { aba: "113125380", name: "Pioneer Bank, Ssb" },
    { aba: "113125403", name: "Hancock Whitney Bank" },
    { aba: "113125445", name: "Texas Advantage Community Bank, N.a." },
    { aba: "113125555", name: "Austin County State Bank" },
    { aba: "113125898", name: "Brenham National Bank" },
    { aba: "113125953", name: "First National Bank Of Eagle Lake" },
    { aba: "113125995", name: "American Momentum Bank" },
    { aba: "113126091", name: "Communitybank Of Texas, Na" },
    { aba: "113193532", name: "Citibank Texas, National Association" },
    { aba: "113194162", name: "Texan Bank" },
    { aba: "113194256", name: "First Financial Bank, N.a." },
    { aba: "114000019", name: "Bank Of America" },
    { aba: "114000093", name: "Frost Bank" },
    { aba: "114000491", name: "Schertz Bank & Trust,kirby Branch" },
    { aba: "114000556", name: "Eisenhower Nb, A Div Of Broadway Nb" },
    { aba: "114000653", name: "Bank Of America" },
    { aba: "114000695", name: "Frost Bank-summit" },
    { aba: "114000721", name: "Federal Reserve Bank San Antonio" },
    { aba: "114000763", name: "Jefferson Bank" },
    { aba: "114000776", name: "Jpmorgan Chase" },
    { aba: "114000857", name: "Frost Bank-citizens" },
    { aba: "114002648", name: "Frost Bank-central Park" },
    { aba: "114011011", name: "Texas Community Bank" },
    { aba: "114012641", name: "International Bank Of Commerce" },
    { aba: "114013284", name: "International Bank Of Commerce" },
    { aba: "114014377", name: "Jefferson State Bank" },
    { aba: "114014542", name: "Simmons Bank" },
    { aba: "114015606", name: "Broadway National Bank" },
    { aba: "114016524", name: "First Commercial Bank, N.a." },
    { aba: "114017112", name: "Texstar Bank" },
    { aba: "114017714", name: "Bank Of America" },
    { aba: "114021933", name: "Broadway National Bank" },
    { aba: "114024260", name: "Lone Star Capital Bank, N.a." },
    { aba: "114024969", name: "Pioneer Bank, Ssb" },
    { aba: "114025599", name: "First National Bank Of Sonora, The" },
    { aba: "114025641", name: "Texas Partners Bank" },
    { aba: "114093628", name: "Firstmark Credit Union" },
    { aba: "114094041", name: "Usaa Federal Savings Bank" },
    { aba: "114900313", name: "Bbva Usa" },
    { aba: "114900685", name: "Wells Fargo" },
    { aba: "114901147", name: "Prosperity Bank" },
    { aba: "114901370", name: "The Bank And Trust, S.s.b." },
    { aba: "114901859", name: "City National Bank" },
    { aba: "114902010", name: "Frost Bank-gulfway" },
    { aba: "114902227", name: "Yoakum National Bank" },
    { aba: "114902230", name: "Spirit Of Texas Bank, Ssb" },
    { aba: "114902340", name: "Frost Bank" },
    { aba: "114902405", name: "First State Bank" },
    { aba: "114902528", name: "International Bank Of Commerce" },
    { aba: "114902560", name: "Valuebank Texas" },
    { aba: "114902612", name: "International Bk Of Commerce" },
    { aba: "114902654", name: "Bank Of South Texas" },
    { aba: "114902683", name: "First State Bank Of San Diego" },
    { aba: "114902777", name: "Spirit Of Texas Bank, Ssb" },
    { aba: "114902780", name: "Prosperity Bank" },
    { aba: "114902793", name: "International Bank Of Commerce" },
    { aba: "114902874", name: "Prosperity Bank" },
    { aba: "114903051", name: "First United Bank & Trust Company" },
    { aba: "114903080", name: "Prosperity Bank" },
    { aba: "114903103", name: "First Texas Bank" },
    { aba: "114903174", name: "Hondo National Bank" },
    { aba: "114903213", name: "First Lockhart National Bank" },
    { aba: "114903284", name: "American Bank, N.a." },
    { aba: "114904115", name: "Frost Bank" },
    { aba: "114904461", name: "Stockmens National Bank" },
    { aba: "114904652", name: "International Bank Of Commerce" },
    { aba: "114904704", name: "Frost Bank-north Austin" },
    { aba: "114904746", name: "First Texas Bank" },
    { aba: "114904775", name: "Security State Bank" },
    { aba: "114904953", name: "First National Bank" },
    { aba: "114904979", name: "Prosperity Bank" },
    { aba: "114905499", name: "Commercial Bank" },
    { aba: "114905509", name: "Mason Bank" },
    { aba: "114905567", name: "International Bank Of Commerce" },
    { aba: "114906524", name: "American Bank, N.a." },
    { aba: "114906689", name: "Texas Champion Bank - Kenedy" },
    { aba: "114907387", name: "First State Bank" },
    { aba: "114907633", name: "Citizens State Bank" },
    { aba: "114908182", name: "Frost Bank-boerne" },
    { aba: "114908289", name: "First Community Bank" },
    { aba: "114909013", name: "Bbva Usa" },
    { aba: "114909165", name: "Community National Bank" },
    { aba: "114909220", name: "First United Bank & Trust Company" },
    { aba: "114909482", name: "Texas Regional Bank" },
    { aba: "114909505", name: "International Bank Of Commerce" },
    { aba: "114909903", name: "Commerce Bank" },
    { aba: "114909929", name: "Prosperity Bank" },
    { aba: "114910222", name: "Zavala County Bank" },
    { aba: "114910280", name: "Dilley State Bank" },
    { aba: "114910523", name: "Falls City National Bank" },
    { aba: "114910565", name: "Verabank, N.a." },
    { aba: "114910918", name: "Atascosa Bank" },
    { aba: "114911234", name: "Karnes County National Bank" },
    { aba: "114911302", name: "First United Bank & Trust Company" },
    { aba: "114911506", name: "Llano National Bank" },
    { aba: "114911519", name: "Lytle State Bank" },
    { aba: "114911577", name: "Marion State Bank" },
    { aba: "114911580", name: "International Bank Of Commerce" },
    { aba: "114911616", name: "Texas Regional Bank" },
    { aba: "114911687", name: "Lone Star National Bank" },
    { aba: "114911807", name: "First Community Bank" },
    { aba: "114912013", name: "Third Coast Bank Ssb" },
    { aba: "114912220", name: "First Commercial Bank Na" },
    { aba: "114912233", name: "Hondo National Bank" },
    { aba: "114912275", name: "International Bank Of Commerce" },
    { aba: "114912479", name: "First National Bank" },
    { aba: "114912589", name: "Vantage Bank" },
    { aba: "114912686", name: "Kleberg Bank, N.a." },
    { aba: "114912709", name: "International Bank Of Commerce" },
    { aba: "114912796", name: "Frost Bank" },
    { aba: "114912848", name: "Sanderson State Bank" },
    { aba: "114913711", name: "Sage Capital Bank Na" },
    { aba: "114914040", name: "Round Top State Bank" },
    { aba: "114914516", name: "Community National Bank" },
    { aba: "114914710", name: "First State Bank" },
    { aba: "114914723", name: "Texas Champion Bank" },
    { aba: "114914817", name: "R Bank" },
    { aba: "114915081", name: "International Bank Of Commerce" },
    { aba: "114915272", name: "Vantage Bank Texas" },
    { aba: "114915447", name: "Rio Bank" },
    { aba: "114915803", name: "Falcon International Bank" },
    { aba: "114916019", name: "First National Bank" },
    { aba: "114916103", name: "Schertz Bank & Trust" },
    { aba: "114916488", name: "Citizens State Bank" },
    { aba: "114916705", name: "First State Bank" },
    { aba: "114917092", name: "Lone Star Capital Bank, Na" },
    { aba: "114917157", name: "Bank Of South Texas" },
    { aba: "114917267", name: "Vantage Bank" },
    { aba: "114917319", name: "American Momentum Bank" },
    { aba: "114917335", name: "Texas Regional Bank" },
    { aba: "114917623", name: "International Bank Of Commerce" },
    { aba: "114917814", name: "American Bank Of Commerce Austin Br" },
    { aba: "114917908", name: "Southtrust Bank, N.a." },
    { aba: "114917924", name: "Crossroads Bank" },
    { aba: "114918143", name: "R Bank" },
    { aba: "114918305", name: "Bandera Bank" },
    { aba: "114919184", name: "Frost National Bank - Alamo" },
    { aba: "114919281", name: "Granger National Bank" },
    { aba: "114919676", name: "First National Bank" },
    { aba: "114920128", name: "Texas National Bank" },
    { aba: "114920526", name: "Arrowhead Bank" },
    { aba: "114921415", name: "Plainscapital Bank" },
    { aba: "114921499", name: "Frost Bank-corpus Christi" },
    { aba: "114921622", name: "Commerce Bank Texas" },
    { aba: "114921761", name: "Junction Natl Bank" },
    { aba: "114921800", name: "Frost Bank-austin" },
    { aba: "114921842", name: "Security State Bank" },
    { aba: "114921949", name: "Security State Bank & Trust" },
    { aba: "114921981", name: "First Commercial Bank, N.a." },
    { aba: "114922003", name: "Peoples State Bank" },
    { aba: "114922090", name: "Johnson City Bank" },
    { aba: "114922142", name: "Rio Bank" },
    { aba: "114922265", name: "First State Bank" },
    { aba: "114922430", name: "First State Bank" },
    { aba: "114922443", name: "Pioneer Bank, Ssb" },
    { aba: "114922896", name: "Kleberg First National Bank" },
    { aba: "114922980", name: "Prosperity Bank" },
    { aba: "114923222", name: "Frost Bank-parkdale" },
    { aba: "114923329", name: "Brush Country Bank" },
    { aba: "114923358", name: "Frost Bank-portland" },
    { aba: "114923439", name: "Castroville State Bank" },
    { aba: "114923756", name: "Zapata National Bank" },
    { aba: "114923976", name: "Charter Bank" },
    { aba: "114924014", name: "Prosperity Bank" },
    { aba: "114924182", name: "First Community Bank, Na" },
    { aba: "114924690", name: "Capital Bank Of Texas" },
    { aba: "114924739", name: "Prosperity Bank" },
    { aba: "114924742", name: "The Bancorp Bank" },
    { aba: "114924810", name: "Texas Community Bank" },
    { aba: "114925181", name: "First Community Bank" },
    { aba: "114925194", name: "Anthem Bank & Trust" },
    { aba: "114925275", name: "Charter Bank" },
    { aba: "114925547", name: "Frontier Bank Of Texas" },
    { aba: "114925615", name: "Business Bank Of Texas, N.a." },
    { aba: "114925864", name: "Guadalupe National Bank" },
    { aba: "114925945", name: "Charter Bank" },
    { aba: "114925987", name: "Texas Partners Bank" },
    { aba: "114926009", name: "Community National Bank" },
    { aba: "114926012", name: "R Bank" },
    { aba: "114993540", name: "Valley Federal Credit Union" },
    { aba: "114993731", name: "Prosperity Bank" },
    { aba: "114993786", name: "Prosperity Bank" },
    { aba: "114993906", name: "First Citizens Bank & Trust Company" },
    { aba: "114994031", name: "Austin Capital Bank Ssb" },
    { aba: "114994109", name: "Pioneer Bank, Ssb" },
    { aba: "114994196", name: "Metropolitan Commercial Bank" },
    { aba: "116307370", name: "Frost Bank" },
    { aba: "116312873", name: "Community National Bank" },
    { aba: "116324201", name: "Firstcapital Bank Of Texas Na" },
    { aba: "116325666", name: "American Momentum Bank" },
    { aba: "121000044", name: "Bank Of America, N.a." },
    { aba: "121000248", name: "Wells Fargo Bank, Na" },
    { aba: "121000345", name: "Bank Of America, N.a." },
    { aba: "121000358", name: "Bank Of America, N.a." },
    { aba: "121000374", name: "Federal Reserve Bank" },
    { aba: "121000497", name: "Mufg Union Bank, N.a." },
    { aba: "121001865", name: "East West Bank" },
    { aba: "121002042", name: "Zions Bancorporation, Na Dba Califo" },
    { aba: "121029672", name: "Bank Of The Orient" },
    { aba: "121031673", name: "Tri Counties Bank" },
    { aba: "121032148", name: "Bank Of The Orient" },
    { aba: "121032232", name: "East West Bank" },
    { aba: "121032274", name: "Shanghai Commercial Bank Limited" },
    { aba: "121034531", name: "Bank Of The Orient" },
    { aba: "121036953", name: "Manufacturers Bank" },
    { aba: "121037240", name: "Hanmi Bank" },
    { aba: "121038265", name: "California Pacific Bank" },
    { aba: "121038634", name: "Tri Counties Bank" },
    { aba: "121038773", name: "Mission National Bank" },
    { aba: "121040169", name: "Bank Of Guam Sf Branch" },
    { aba: "121040350", name: "Ctbc Bank Corp. (usa)" },
    { aba: "121040554", name: "Industrial & Comm. Bnk Of China, Na" },
    { aba: "121040651", name: "Beacon Business Bank, Na" },
    { aba: "121042222", name: "The Northern Trust Company" },
    { aba: "121042484", name: "Pacific Coast Bankers Bank" },
    { aba: "121042882", name: "Wells Fargo Bank Na" },
    { aba: "121044055", name: "Bank Of San Francisco" },
    { aba: "121044356", name: "Heritage Bank Of Commerce" },
    { aba: "121044369", name: "Amalgamated Bank" },
    { aba: "121058232", name: "Federal Reserve Bk Sf-public Affairs" },
    { aba: "121058313", name: "Federal Reserve Bk Sf- B, S & R" },
    { aba: "121081872", name: "Sterling Bank & Trust Fsb" },
    { aba: "121100782", name: "Bank Of The West" },
    { aba: "121101037", name: "Bank Of Stockton" },
    { aba: "121101189", name: "Tri Counties Bank" },
    { aba: "121101985", name: "Exchange Bank" },
    { aba: "121102036", name: "Mechanics Bank" },
    { aba: "121103886", name: "Bank Of America, N.a." },
    { aba: "121104063", name: "Savings Bank Of Mendocino County" },
    { aba: "121105156", name: "First Northern Bank Of Dixon" },
    { aba: "121106252", name: "Mechanics Bank" },
    { aba: "121106540", name: "Farmers & Merchants Bk Of Central Ca" },
    { aba: "121107882", name: "Fremont Bank" },
    { aba: "121108250", name: "Bank Of America, N.a." },
    { aba: "121108441", name: "Farmers & Merchants Bk Of Central Ca" },
    { aba: "121109518", name: "Merchants Bank Of Commrce" },
    { aba: "121122676", name: "Us Bank Na" },
    { aba: "121125660", name: "Bac Community Bank" },
    { aba: "121128997", name: "First Northern Bk Of Dixon" },
    { aba: "121132394", name: "Tri Counties Bank" },
    { aba: "121132682", name: "East West Bank" },
    { aba: "121132983", name: "Farmers & Merchants Bk Of Central Ca" },
    { aba: "121133416", name: "River City Bank" },
    { aba: "121135045", name: "Tri Counties Bank" },
    { aba: "121135058", name: "River City Bank" },
    { aba: "121135087", name: "Banner Bank" },
    { aba: "121135773", name: "Heritage Bank Of Comm-los Altos Div" },
    { aba: "121135896", name: "River City Bank" },
    { aba: "121136358", name: "First Northern Bk Of Dixon" },
    { aba: "121136675", name: "Central Valley Community Bank" },
    { aba: "121136785", name: "Umpqua Bank" },
    { aba: "121136808", name: "Monterey County Bank" },
    { aba: "121137001", name: "County Bank" },
    { aba: "121137027", name: "Bank Of The Sierra" },
    { aba: "121137292", name: "United Security Bank" },
    { aba: "121137506", name: "First Bank" },
    { aba: "121137522", name: "Comerica Bank California" },
    { aba: "121137726", name: "Central Valley Community Bank" },
    { aba: "121137797", name: "Boston Private Borel" },
    { aba: "121137807", name: "Banner Bank" },
    { aba: "121138013", name: "First Republic Bank" },
    { aba: "121138288", name: "Plumas Bank" },
    { aba: "121138741", name: "Umpqua Bank" },
    { aba: "121138958", name: "Summit Bank" },
    { aba: "121138990", name: "Bank Of Hope" },
    { aba: "121139122", name: "Liberty Bank" },
    { aba: "121139287", name: "Merchants Bank Of Commerce" },
    { aba: "121139313", name: "Us Bank Na (lamorinda Nb)" },
    { aba: "121139685", name: "Mufg Union Bank, N.a." },
    { aba: "121139711", name: "Mechanics Bank" },
    { aba: "121140218", name: "Westamerica Bank" },
    { aba: "121140263", name: "American River Bank" },
    { aba: "121140276", name: "Umpqua Bank" },
    { aba: "121140331", name: "American River Bank" },
    { aba: "121140399", name: "Silicon Valley Bank" },
    { aba: "121140713", name: "Mufg Union Bank, N.a." },
    { aba: "121140742", name: "Mufg Union Bank, N.a." },
    { aba: "121140823", name: "Umpqua Bank" },
    { aba: "121140933", name: "Umpqua Bank" },
    { aba: "121141000", name: "Westamerica Bank" },
    { aba: "121141042", name: "Umpqua Bank" },
    { aba: "121141107", name: "Umpqua Bank" },
    { aba: "121141246", name: "Manufacturers Bank" },
    { aba: "121141288", name: "Wells Fargo Bank" },
    { aba: "121141343", name: "Metropolitan Bank" },
    { aba: "121141398", name: "Golden Pacific Bank Na" },
    { aba: "121141482", name: "Mechanics Bank" },
    { aba: "121141495", name: "United Security Bank" },
    { aba: "121141534", name: "Wells Fargo Bank" },
    { aba: "121141615", name: "Westamerica Bank" },
    { aba: "121141754", name: "Mega International Comm Bank Co Ltd" },
    { aba: "121141819", name: "Umpqua Bank" },
    { aba: "121141822", name: "Bank Of America Na" },
    { aba: "121141864", name: "Mufg Union Bank, N.a." },
    { aba: "121141877", name: "Bank Of Marin" },
    { aba: "121141903", name: "Banner Bank" },
    { aba: "121141974", name: "American River Bank" },
    { aba: "121142025", name: "Mechanics Bank" },
    { aba: "121142119", name: "Oak Valley Community Bank" },
    { aba: "121142148", name: "Citizens Business Bank" },
    { aba: "121142258", name: "Gbc International Bank" },
    { aba: "121142287", name: "Heritage Bank Of Commerce" },
    { aba: "121142313", name: "Tri Counties Bank" },
    { aba: "121142397", name: "Citizens Business Bank" },
    { aba: "121142407", name: "Community Bank Of The Bay" },
    { aba: "121142423", name: "State Bank Of India California" },
    { aba: "121142517", name: "Umpqua Bank" },
    { aba: "121142627", name: "Umpqua Bank" },
    { aba: "121142669", name: "Bank Of Marin" },
    { aba: "121142698", name: "Modesto Comm Bk-a Div Of Bk Stockton" },
    { aba: "121142737", name: "United Business Bank" },
    { aba: "121142779", name: "Banner Bank" },
    { aba: "121142818", name: "Bank Of Hope" },
    { aba: "121142834", name: "Banner Bank" },
    { aba: "121142850", name: "Heritage Bank Of Commerce-e Bay Div" },
    { aba: "121142902", name: "Umpqua Bank" },
    { aba: "121142999", name: "Central Valley Community Bank" },
    { aba: "121143008", name: "United Business Bank" },
    { aba: "121143037", name: "Five Star Bank" },
    { aba: "121143118", name: "Umpqua Bank" },
    { aba: "121143260", name: "Western Alliance Bank" },
    { aba: "121143273", name: "Premier Valley Bank" },
    { aba: "121143312", name: "Hanmi Bank" },
    { aba: "121143383", name: "Mechanics Bank" },
    { aba: "121143422", name: "Tri Counties Bank" },
    { aba: "121143529", name: "United Security Bank" },
    { aba: "121143532", name: "Hanmi Bank" },
    { aba: "121143558", name: "Heritage Bank Of Commerce" },
    { aba: "121143626", name: "Avidbank" },
    { aba: "121143697", name: "Heritage Bank Of Commerce" },
    { aba: "121143736", name: "Santa Cruz County Bank" },
    { aba: "121143752", name: "Redwood Capital Bank" },
    { aba: "121143781", name: "United Business Bank" },
    { aba: "121143794", name: "Oak Valley Community Bank" },
    { aba: "121143833", name: "Pacific Valley Bank" },
    { aba: "121143846", name: "Bank Of Marin" },
    { aba: "121143891", name: "Poppy Bank" },
    { aba: "121143985", name: "California Bank Of Commerce" },
    { aba: "121144078", name: "Heritage Bank Of Commerce" },
    { aba: "121144146", name: "Fresno First Bank" },
    { aba: "121144191", name: "Suncrest Bank" },
    { aba: "121144201", name: "First Foundation Bank" },
    { aba: "121144214", name: "Merchants Bank Of Commerce" },
    { aba: "121144256", name: "Suncrest Bank" },
    { aba: "121144269", name: "Northern California National Bank" },
    { aba: "121144272", name: "Golden Valley Bank" },
    { aba: "121144285", name: "Bank Of Marin" },
    { aba: "121144311", name: "Banner Bank" },
    { aba: "121144324", name: "River Valley Community Bank" },
    { aba: "121144340", name: "Pinnacle Bank" },
    { aba: "121144418", name: "Suncrest Bank" },
    { aba: "121144463", name: "Western Alliance Bank" },
    { aba: "121144476", name: "Cornerstone Community Bank" },
    { aba: "121144528", name: "Heritage Bank Of Commerce" },
    { aba: "121144557", name: "Central Valley Community Bank" },
    { aba: "121144612", name: "1st Capital Bank" },
    { aba: "121144625", name: "Central Valley Community Bank" },
    { aba: "121144696", name: "California Bank Of Commerce" },
    { aba: "121144803", name: "Mechanics Bank" },
    { aba: "121144845", name: "Bank Of Feather River" },
    { aba: "121144861", name: "Santa Cruz County Bank" },
    { aba: "121144939", name: "Suncrest Bank" },
    { aba: "121145145", name: "Silicon Valley Bank" },
    { aba: "121181730", name: "Umpqua Bank" },
    { aba: "121181743", name: "Pacific Service Credit Union" },
    { aba: "121181798", name: "Mufg Union Bank, N.a." },
    { aba: "121181866", name: "Wells Fargo Bank" },
    { aba: "121181976", name: "Technology Credit Union" },
    { aba: "121182014", name: "First Citizens Bank & Trust Company" },
    { aba: "121182030", name: "Travis Credit Union" },
    { aba: "121182056", name: "Beneficial State Bank" },
    { aba: "121182357", name: "Vision One Credit Union" },
    { aba: "121201694", name: "Us Bank Na" },
    { aba: "121201814", name: "Heritage Bank Ado Glacier Bank" },
    { aba: "121202062", name: "Western Alliance Bank" },
    { aba: "121202185", name: "Umpqua Bank" },
    { aba: "121202211", name: "Charles Schwab Bank" },
    { aba: "121281892", name: "Farm Bureau Bank" },
    { aba: "121282370", name: "Charles Schwab Bank" },
    { aba: "121301015", name: "First Hawaiian Bank" },
    { aba: "121301028", name: "Bank Of Hawaii" },
    { aba: "121301057", name: "American Savings Bank Fsb" },
    { aba: "121301578", name: "Central Pacific Bank" },
    { aba: "121301772", name: "Hawaii National Bank" },
    { aba: "121302247", name: "Bank Of The Orient (bk Of Honolulu)" },
    { aba: "121302292", name: "Bank Of Hawaii Trust" },
    { aba: "121302357", name: "First Foundation Bank" },
    { aba: "121302360", name: "Ohana Pacific Bank" },
    { aba: "121302373", name: "Bank Of The Fsm" },
    { aba: "121403049", name: "City Trust Bank" },
    { aba: "121403065", name: "First Hawaiian Bank Saipan" },
    { aba: "121403078", name: "Bank Of Saipan" },
    { aba: "121404006", name: "Bank Of Hawaii American Samoa" },
    { aba: "121404022", name: "Anz Guam Inc" },
    { aba: "121405018", name: "Bank Of Hawaii Guam" },
    { aba: "121405115", name: "Bank Of Guam" },
    { aba: "121405173", name: "Citizens Security Bank (century Bk)" },
    { aba: "121405199", name: "Philippine National Bank (pnb) Guam" },
    { aba: "121405209", name: "Tri Counties Bank" },
    { aba: "121405212", name: "Anz Guam Inc" },
    { aba: "121405238", name: "First Hawaiian Bank Guam" },
    { aba: "122000030", name: "Bank Of America, N.a." },
    { aba: "122000166", name: "Frb-los Angeles Ach" },
    { aba: "122000247", name: "Wells Fargo Bank Na" },
    { aba: "122000496", name: "Mufg Union Bank, N.a." },
    { aba: "122000616", name: "Bank Of America, N.a." },
    { aba: "122000661", name: "Bank Of America, N.a." },
    { aba: "122003396", name: "Zions Bancorporation, Na Dba Califo" },
    { aba: "122004162", name: "Mufg Bank, Ltd. Los Angeles Branch" },
    { aba: "122016066", name: "City National Bank" },
    { aba: "122033612", name: "Royal Business Bank" },
    { aba: "122034103", name: "Cathay Bank" },
    { aba: "122035199", name: "Banc Of California" },
    { aba: "122037087", name: "East West Bank (american Int'l Bank)" },
    { aba: "122037760", name: "First Citizens Bank" },
    { aba: "122037841", name: "Cathay Bank" },
    { aba: "122038251", name: "Bank Of Hope" },
    { aba: "122038277", name: "East West Bank" },
    { aba: "122038442", name: "Us Bank, Na" },
    { aba: "122038756", name: "Philippine National Bank" },
    { aba: "122038837", name: "Mizrahi Tefahot Bank Limited" },
    { aba: "122039179", name: "United Pacific Bank" },
    { aba: "122039344", name: "State Bank Of India California" },
    { aba: "122039360", name: "Commercial Bank Of California" },
    { aba: "122039399", name: "Hanmi Bank" },
    { aba: "122040090", name: "Citizens Business Bank" },
    { aba: "122040799", name: "Wing Lung Bank, Ltd." },
    { aba: "122041183", name: "Eastwest Bank (first Central Bk)" },
    { aba: "122041235", name: "Bank Of Hope" },
    { aba: "122041594", name: "Sumitomo Mitsui Banking Corporation" },
    { aba: "122041646", name: "Shinhan Bank America" },
    { aba: "122041662", name: "Bank Of China, Los Angeles Branch" },
    { aba: "122041727", name: "Bank Of Hope" },
    { aba: "122041989", name: "Habib American Bank" },
    { aba: "122042069", name: "Bank Of Hope" },
    { aba: "122042153", name: "Shanghai Commercial Bank Limited" },
    { aba: "122042205", name: "Preferred Bank" },
    { aba: "122042807", name: "American Business Bank" },
    { aba: "122043107", name: "Royal Business Bank" },
    { aba: "122043301", name: "First Choice Bank" },
    { aba: "122043440", name: "Bank Of Hope" },
    { aba: "122043482", name: "Commercial Bank Of California" },
    { aba: "122043518", name: "Mufg Union Bank, N.a." },
    { aba: "122043602", name: "Pacific City Bank" },
    { aba: "122043864", name: "Commonwealth Business Bank" },
    { aba: "122043932", name: "Philippine National Bank" },
    { aba: "122043958", name: "Open Bank" },
    { aba: "122044119", name: "California Business Bank" },
    { aba: "122044229", name: "Woori America Bank" },
    { aba: "122044300", name: "Pacific Western Bank" },
    { aba: "122044371", name: "First Foundation Bank" },
    { aba: "122044449", name: "First Choice Bank" },
    { aba: "122045037", name: "Royal Business Bank" },
    { aba: "122086944", name: "Asian Pacific National Bank" },
    { aba: "122087040", name: "Episcopal Community F.c.u." },
    { aba: "122087095", name: "Hanin Federal Credit Union" },
    { aba: "122087590", name: "Pacific Premier Bank" },
    { aba: "122100024", name: "Jpmorgan Chase" },
    { aba: "122101010", name: "Bbva Usa" },
    { aba: "122101191", name: "Wells Fargo Bank Na" },
    { aba: "122101706", name: "Bank Of America, N.a." },
    { aba: "122104046", name: "Bmo Harris Bank Na" },
    { aba: "122104981", name: "Arizona Bank & Trust" },
    { aba: "122104994", name: "Umb Bank, N.a." },
    { aba: "122105045", name: "Zions Bancorporation, Na Dba Nationa" },
    { aba: "122105155", name: "Us Bank Na" },
    { aba: "122105171", name: "Zions Bancorporation, Na Dba Nationa" },
    { aba: "122105184", name: "First Fidelity Bank" },
    { aba: "122105210", name: "The Northern Trust Company" },
    { aba: "122105249", name: "Bmo Harris Bank Na" },
    { aba: "122105278", name: "Wells Fargo Bank Na (arizona)" },
    { aba: "122105320", name: "Zions Bancorporation, Na Dba Nationa" },
    { aba: "122105469", name: "Cit Bank Na" },
    { aba: "122105472", name: "The Foothiills Bank,ado Glacier Bank" },
    { aba: "122105498", name: "Bokf, N.a." },
    { aba: "122105524", name: "Wells Fargo Bank Na" },
    { aba: "122105540", name: "Bokf, Na" },
    { aba: "122105553", name: "Umb Bank, N.a." },
    { aba: "122105566", name: "Pacific Premier Bank" },
    { aba: "122105582", name: "Western State Bank" },
    { aba: "122105595", name: "Bmo Harris Bank Na" },
    { aba: "122105605", name: "Stearns Bank Arizona Na" },
    { aba: "122105634", name: "Glacier Bank - Foothills Bank Div" },
    { aba: "122105647", name: "Bank Of The West" },
    { aba: "122105676", name: "Pacific Premier Bank" },
    { aba: "122105731", name: "Arizona Bank And Trust" },
    { aba: "122105744", name: "Bbva Usa" },
    { aba: "122105757", name: "Cit Bank Na" },
    { aba: "122105773", name: "Charles Schwab Premier Bank" },
    { aba: "122105799", name: "Stearns Bank, Na" },
    { aba: "122105812", name: "New York Community Bank" },
    { aba: "122105825", name: "Canyon Community Bank" },
    { aba: "122105841", name: "Parkway Bank Arizona" },
    { aba: "122105854", name: "Bmo Harris Bank Na" },
    { aba: "122105867", name: "Glacier Bank - Foothills Bank Div" },
    { aba: "122105870", name: "Mission Bank" },
    { aba: "122105896", name: "Bnc National Bank" },
    { aba: "122105906", name: "1st Bank Yuma" },
    { aba: "122105922", name: "Commerce Bank Of Arizona" },
    { aba: "122105935", name: "Horizon Community Bank" },
    { aba: "122105948", name: "Bbva Usa" },
    { aba: "122105964", name: "Glacier Bank - Foothills Bank Div" },
    { aba: "122105980", name: "Western Alliance Bank" },
    { aba: "122105993", name: "The Foothills Bank,ado Glacier Bank" },
    { aba: "122106002", name: "Pacific Premier Bank" },
    { aba: "122106015", name: "Arizona Bank & Trust" },
    { aba: "122106031", name: "Commerce Bank Of Arizona" },
    { aba: "122106073", name: "Washington Federal Bank, Na" },
    { aba: "122106086", name: "Arizona Bank And Trust" },
    { aba: "122106109", name: "Pinnacle Bank" },
    { aba: "122106125", name: "Umb, Na" },
    { aba: "122106170", name: "Republic Bank Of Arizona" },
    { aba: "122106183", name: "Tcf National Bank" },
    { aba: "122106219", name: "Firstbank" },
    { aba: "122106222", name: "West Valley National Bank" },
    { aba: "122106235", name: "Metro Phoenix Bank" },
    { aba: "122106251", name: "Bank '34" },
    { aba: "122106277", name: "Goldwater Bank Na" },
    { aba: "122106280", name: "West Valley National Bank" },
    { aba: "122106293", name: "Academy Bank Na" },
    { aba: "122106316", name: "Gateway Commercial Bank" },
    { aba: "122106332", name: "Arizona Bank And Trust" },
    { aba: "122106358", name: "Metro Phoenix Bank" },
    { aba: "122106390", name: "Gateway Commercial Bank" },
    { aba: "122106413", name: "First Western Trust Bank" },
    { aba: "122106442", name: "Canyon Community Bank" },
    { aba: "122106455", name: "First National Bank Texas" },
    { aba: "122187076", name: "Wells Fargo Bank" },
    { aba: "122187212", name: "New York Community Bank" },
    { aba: "122187238", name: "Desert Financial Credit Union" },
    { aba: "122187335", name: "First Citizens Bank & Trust Company" },
    { aba: "122187445", name: "Midfirst Bank" },
    { aba: "122200759", name: "Citizens Business Bank" },
    { aba: "122201198", name: "Farmers & Merchants Bank Long Beach" },
    { aba: "122203471", name: "Citizens Business Bank" },
    { aba: "122203507", name: "Pacific Premier Bank" },
    { aba: "122203950", name: "Cathay Bank" },
    { aba: "122210406", name: "Ctbc Bank Corp. (usa)" },
    { aba: "122215090", name: "Banc Of California" },
    { aba: "122217056", name: "Citibank West" },
    { aba: "122220593", name: "Mufg Union Bank, N.a." },
    { aba: "122220849", name: "Citibank West" },
    { aba: "122221686", name: "Mechanics Bank" },
    { aba: "122222876", name: "Banc Of California" },
    { aba: "122226063", name: "Beneficial State Bank" },
    { aba: "122226076", name: "Manufacturers Bank" },
    { aba: "122228003", name: "Sunwest Bank" },
    { aba: "122231935", name: "Citibank West" },
    { aba: "122232109", name: "Zions Bancorporation, Na Dba Califo" },
    { aba: "122232196", name: "Mechanics Bank" },
    { aba: "122232222", name: "East West Bank" },
    { aba: "122232439", name: "Pacific Western Bnak" },
    { aba: "122233218", name: "Mechanics Bank" },
    { aba: "122234149", name: "Citizens Business Bank" },
    { aba: "122234194", name: "Bank Of Hemet" },
    { aba: "122234482", name: "Hanmi Bank" },
    { aba: "122234783", name: "Montecito Bank & Trust" },
    { aba: "122235821", name: "Us Bank Na" },
    { aba: "122235902", name: "Gbc International Bank" },
    { aba: "122236244", name: "Bank Leumi Usa" },
    { aba: "122236998", name: "Mechanics Bank" },
    { aba: "122237159", name: "Mechanics Bank" },
    { aba: "122237308", name: "Pacific Western Bank" },
    { aba: "122237515", name: "East West Bank" },
    { aba: "122237544", name: "Citizens Business Bank" },
    { aba: "122237599", name: "First Credit Bank" },
    { aba: "122237612", name: "Citizens Business Bank" },
    { aba: "122237625", name: "Zions Bancorporation, Na Dba Califo" },
    { aba: "122237654", name: "Citizens Business Bank (orange Nb)" },
    { aba: "122237706", name: "Desert Cmty, Div Of Flagstar Bank" },
    { aba: "122237748", name: "Banner Bank" },
    { aba: "122237858", name: "New Omni Bank Na" },
    { aba: "122237955", name: "Wells Fargo Bank" },
    { aba: "122237997", name: "Zions Bancorporation, Na Dba Califo" },
    { aba: "122238048", name: "Citizens Business Bank" },
    { aba: "122238077", name: "Mufg Union Bank, N.a." },
    { aba: "122238200", name: "Pacific Western Bank" },
    { aba: "122238242", name: "Pacific Western Bank" },
    { aba: "122238420", name: "Mechanics Bank" },
    { aba: "122238543", name: "Citizens Business Bank" },
    { aba: "122238572", name: "Zions Bancorporation, Na Dba Califo" },
    { aba: "122238585", name: "Pacific Premier Bank" },
    { aba: "122238682", name: "Us Bank" },
    { aba: "122238721", name: "First Foundation Bank" },
    { aba: "122238912", name: "Citizens Business Bank (orange Nb)" },
    { aba: "122238938", name: "Pacific Western Bank" },
    { aba: "122239021", name: "C3bank, National Association" },
    { aba: "122239131", name: "First Bank" },
    { aba: "122239270", name: "Pacific Premier Bank" },
    { aba: "122239322", name: "Pacific Western Bank" },
    { aba: "122239335", name: "Pacific Western Bank" },
    { aba: "122239542", name: "Bank Of Whittier Na" },
    { aba: "122239571", name: "Eastwest Bank" },
    { aba: "122239584", name: "Umpqua Bank" },
    { aba: "122239678", name: "United Security Bank" },
    { aba: "122239775", name: "Pacific Western Bank" },
    { aba: "122239869", name: "Banc Of California" },
    { aba: "122239872", name: "Industrial & Comm. Bnk Of China, Na" },
    { aba: "122239937", name: "Citizens Business Bank" },
    { aba: "122239982", name: "Pacific Premier Bank" },
    { aba: "122240010", name: "Citizens Business Bank" },
    { aba: "122240104", name: "Citizens Business Bank" },
    { aba: "122240418", name: "East West Bank" },
    { aba: "122240450", name: "Citizens Business Bank" },
    { aba: "122240489", name: "Pacific Western Bank" },
    { aba: "122240492", name: "Boston Private Bank & Trust" },
    { aba: "122240667", name: "Citizens Business Bank" },
    { aba: "122240670", name: "Citizens Business Bank" },
    { aba: "122240683", name: "Mufg Union Bank, N.a." },
    { aba: "122240696", name: "Citizens Business Bank" },
    { aba: "122240706", name: "International City Bank" },
    { aba: "122240751", name: "C3bank, National Association" },
    { aba: "122240764", name: "Citizens Business Bank" },
    { aba: "122240861", name: "Hsbc Bank Usa - California" },
    { aba: "122240890", name: "Citizens Business Bank" },
    { aba: "122241132", name: "Pacific Western Bank" },
    { aba: "122241200", name: "Firstbank (1st Bank Of Palm Desert)" },
    { aba: "122241213", name: "Pacific Premier Bank" },
    { aba: "122241255", name: "First American Trust, Fsb" },
    { aba: "122241446", name: "Mechanics Bank" },
    { aba: "122241501", name: "Mufg Union Bank, N.a." },
    { aba: "122241572", name: "Pacific Premier Bank" },
    { aba: "122241802", name: "Community West Bank" },
    { aba: "122241831", name: "Banner Bank" },
    { aba: "122241912", name: "Citizens Business Bank" },
    { aba: "122241941", name: "Mufg Union Bank, N.a." },
    { aba: "122242018", name: "East West Bank" },
    { aba: "122242034", name: "Mufg Union Bank, N.a." },
    { aba: "122242050", name: "Mission Bank" },
    { aba: "122242092", name: "Pacific Western Bank" },
    { aba: "122242102", name: "East West Bank" },
    { aba: "122242173", name: "The Northern Trust Company" },
    { aba: "122242296", name: "Evertrust Bank" },
    { aba: "122242377", name: "First Commercial Bank Usa" },
    { aba: "122242490", name: "Pacific Trust Bank" },
    { aba: "122242526", name: "Bank Of The Sierra" },
    { aba: "122242555", name: "Boston Private Bank & Trust" },
    { aba: "122242571", name: "Neighborhood National Bank" },
    { aba: "122242597", name: "First Citizens Bank" },
    { aba: "122242607", name: "Wells Fargo Bank" },
    { aba: "122242649", name: "Pacific Premier Bank" },
    { aba: "122242652", name: "Eastwest Bank (prime Bank)" },
    { aba: "122242681", name: "Pacific Western Bank" },
    { aba: "122242704", name: "Wells Fargo Bank Na" },
    { aba: "122242759", name: "Citizens Business Bank" },
    { aba: "122242788", name: "Pacific Premier Bank" },
    { aba: "122242791", name: "Mission Bank" },
    { aba: "122242827", name: "Bank Of The Sierra" },
    { aba: "122242843", name: "Bank Of The West" },
    { aba: "122242869", name: "Pacific Mercantile Bank" },
    { aba: "122242924", name: "Pacific Western Bank" },
    { aba: "122242982", name: "Bank Of Southern California, Na" },
    { aba: "122243017", name: "Armed Forces Bank, Na" },
    { aba: "122243062", name: "Chino Commercial Bank" },
    { aba: "122243130", name: "California First National Bank" },
    { aba: "122243156", name: "Israel Discount Bank Of New York-la" },
    { aba: "122243169", name: "Banner Bank" },
    { aba: "122243172", name: "Pacific Premier Bank" },
    { aba: "122243208", name: "Banner Bank" },
    { aba: "122243224", name: "Mission Valley Bank" },
    { aba: "122243237", name: "Community Bank Of Santa Maria" },
    { aba: "122243240", name: "Pacific Premier Bank" },
    { aba: "122243295", name: "Pacific Premier Bank" },
    { aba: "122243321", name: "Pacific Premier Bank" },
    { aba: "122243334", name: "Commercewest Bank" },
    { aba: "122243347", name: "Commercewest Bank Na" },
    { aba: "122243350", name: "Pacific Western Bank" },
    { aba: "122243376", name: "Bank Of The Orient" },
    { aba: "122243402", name: "Bank Of Southern California Na" },
    { aba: "122243415", name: "United Business Bank" },
    { aba: "122243431", name: "Amalgamated Bank California" },
    { aba: "122243457", name: "Banner Bank" },
    { aba: "122243460", name: "Homestreet Bank" },
    { aba: "122243541", name: "Citizens Business Bank" },
    { aba: "122243567", name: "Royal Business Bank" },
    { aba: "122243583", name: "American Continental Bank" },
    { aba: "122243596", name: "Commercial Bank Of California" },
    { aba: "122243619", name: "Seacoast Commerce Bank" },
    { aba: "122243635", name: "Western Alliance Bank" },
    { aba: "122243648", name: "Pacific Premier Bank" },
    { aba: "122243664", name: "Banner Bank" },
    { aba: "122243680", name: "Pacific Premier Bank" },
    { aba: "122243703", name: "Umpqua Bank" },
    { aba: "122243716", name: "Golden State Bank" },
    { aba: "122243761", name: "1st Century Bk, Div Of Midfirst Bk" },
    { aba: "122243774", name: "Banc Of California" },
    { aba: "122243800", name: "Banner Bank" },
    { aba: "122243813", name: "Bank Of Santa Clarita" },
    { aba: "122243871", name: "Eh National Bank" },
    { aba: "122243884", name: "Pacific Western Bank" },
    { aba: "122243910", name: "Bank Of The Sierra" },
    { aba: "122243923", name: "Cit Bank Na" },
    { aba: "122243965", name: "Premier Valley Bank" },
    { aba: "122243978", name: "Pacific Premier Bank" },
    { aba: "122243994", name: "First Choice Bank" },
    { aba: "122244003", name: "First Foundation Bank" },
    { aba: "122244016", name: "Pacific Premier Bank" },
    { aba: "122244029", name: "Calprivate Bank" },
    { aba: "122244032", name: "California International Bank, N.a." },
    { aba: "122244061", name: "First General Bank" },
    { aba: "122244087", name: "Pacific Premier Bank" },
    { aba: "122244090", name: "Golden State Business Bank" },
    { aba: "122244139", name: "Banc Of California" },
    { aba: "122244155", name: "American Riviera Bank" },
    { aba: "122244184", name: "Fifth Third Bank" },
    { aba: "122244236", name: "Commercewest Bank Na" },
    { aba: "122244249", name: "Friendly Hills Bank" },
    { aba: "122244294", name: "Calprivate Bank" },
    { aba: "122244333", name: "American Riviera Bank" },
    { aba: "122244401", name: "Calprivate Bank" },
    { aba: "122244427", name: "Us Metro Bank" },
    { aba: "122244498", name: "Bank Of Southern California, Na" },
    { aba: "122244511", name: "Pacific Alliance Bank" },
    { aba: "122244537", name: "Banner Bank" },
    { aba: "122244566", name: "Pacific Premier Bank" },
    { aba: "122244595", name: "Pacific Enterprise Bank" },
    { aba: "122244663", name: "American Plus Bank Na" },
    { aba: "122244676", name: "Community Valley Bank" },
    { aba: "122244702", name: "Pacific Premier Bank" },
    { aba: "122244715", name: "Seacoast Commerce Bank" },
    { aba: "122244731", name: "Nano Banc" },
    { aba: "122244744", name: "Pacific Western Bank" },
    { aba: "122244773", name: "Partners Bank Of California" },
    { aba: "122244854", name: "Mechanics Bank" },
    { aba: "122244870", name: "Mega Bank" },
    { aba: "122244883", name: "Pacific Premier Bank" },
    { aba: "122244922", name: "First Choice Bank" },
    { aba: "122245044", name: "Valley Republic Bank" },
    { aba: "122245167", name: "Banc Of California" },
    { aba: "122287170", name: "Wells Fargo Bank" },
    { aba: "122287183", name: "Pacific Western Bank" },
    { aba: "122287251", name: "Axos Bank" },
    { aba: "122287280", name: "Bank Of Southern California, Na" },
    { aba: "122287329", name: "Usc Credit Union" },
    { aba: "122287361", name: "First Citizens Bank & Trust Company" },
    { aba: "122287374", name: "Pacific Marine Credit Union" },
    { aba: "122287413", name: "Arrowhead Credit Union" },
    { aba: "122287426", name: "Pacific Premier Bank" },
    { aba: "122287471", name: "Gain Federal Credit Union" },
    { aba: "122287510", name: "Comunidad Latina Fcu" },
    { aba: "122287549", name: "Valley Strong Credit Union" },
    { aba: "122287581", name: "First Foundation Bank" },
    { aba: "122287604", name: "Coasthills Credit Union" },
    { aba: "122287659", name: "Honda Federal Credit Union" },
    { aba: "122287662", name: "Certified Federal Credit Union" },
    { aba: "122287675", name: "Axos Bank" },
    { aba: "122400724", name: "Bank Of America, N.a." },
    { aba: "122400779", name: "Zions Bancorporation, Na Dba Nevada" },
    { aba: "122401710", name: "Citibank Na" },
    { aba: "122401723", name: "Cit Bank Na" },
    { aba: "122401778", name: "Western Alliance Bank" },
    { aba: "122401781", name: "Us Bank" },
    { aba: "122402049", name: "Wells Fargo Bank Na" },
    { aba: "122402133", name: "Credit One Bank Na" },
    { aba: "122402146", name: "Pacific Premier Bank" },
    { aba: "122402159", name: "Citibank Na" },
    { aba: "122402191", name: "Town And Country Bank" },
    { aba: "122402243", name: "Valley Bank Of Nevada" },
    { aba: "122402285", name: "Pacific Premier Bank" },
    { aba: "122402298", name: "Western Alliance Bank" },
    { aba: "122402311", name: "First Security Bank Of Nevada" },
    { aba: "122402324", name: "Cathay Bank" },
    { aba: "122402337", name: "Royal Business Bank" },
    { aba: "122402366", name: "Bank Of George" },
    { aba: "122402382", name: "Meadows Bank" },
    { aba: "122402395", name: "Amalgamated Bank Nevada" },
    { aba: "122402405", name: "Kirkwood Bank Of Nevada" },
    { aba: "122487022", name: "First Republic Bank" },
    { aba: "122487129", name: "Usaa Savings Bank" },
    { aba: "122487200", name: "Bank Of Nevada, A Div Of W Alliance" },
    { aba: "122487226", name: "Bmo Harris Bank Na" },
    { aba: "122487307", name: "Wells Fargo Bank" },
    { aba: "122487404", name: "Toyota Financial Savings Bank" },
    { aba: "122487459", name: "Beal Bank Usa" },
    { aba: "123000013", name: "Frb - Portland" },
    { aba: "123000068", name: "Mufg Union Bank, N.a." },
    { aba: "123000220", name: "Us Bank Na" },
    { aba: "123000848", name: "Us Bank Na" },
    { aba: "123002011", name: "Key Bank" },
    { aba: "123006389", name: "Hsbc Bank Usa-portland" },
    { aba: "123006651", name: "Beneficial State Bank" },
    { aba: "123006729", name: "Columbia State Bank" },
    { aba: "123006800", name: "Wells Fargo Bank Na" },
    { aba: "123006897", name: "Columbia State Bank" },
    { aba: "123006965", name: "Zions Bancorporation, Na Dba The Com" },
    { aba: "123084958", name: "First Citizens Bank & Trust Company" },
    { aba: "123103596", name: "Twin River Bank" },
    { aba: "123103606", name: "Columbia State Bank" },
    { aba: "123103716", name: "Bank Of America Nw N.a.- Idaho" },
    { aba: "123103729", name: "Us Bank Na" },
    { aba: "123103826", name: "Bankcda" },
    { aba: "123103868", name: "Columbia State Bank" },
    { aba: "123171955", name: "Glacier Bank-mountain West Div" },
    { aba: "123200059", name: "Pioneer Trust Bank Na" },
    { aba: "123200088", name: "Columbia State Bank" },
    { aba: "123202125", name: "Umpqua Bank (valley Of The Rogue Bk)" },
    { aba: "123202183", name: "Clackamas County Bank" },
    { aba: "123202280", name: "Columbia State Bank" },
    { aba: "123202293", name: "Banner Bank" },
    { aba: "123203218", name: "Columbia State Bank" },
    { aba: "123203535", name: "Bank Of Eastern Oregon" },
    { aba: "123203878", name: "Community Bank" },
    { aba: "123204013", name: "Citizens Bank" },
    { aba: "123204071", name: "Banner Bank" },
    { aba: "123204110", name: "Banner Bank" },
    { aba: "123204835", name: "Umpqua Bank" },
    { aba: "123205054", name: "Umpqua Bank" },
    { aba: "123205135", name: "Columbia State Bank" },
    { aba: "123205973", name: "Washington Federal Bank, Na" },
    { aba: "123206011", name: "Columbia State Bank" },
    { aba: "123206053", name: "Umpqua Bank" },
    { aba: "123206338", name: "Oregon Pacific Banking Co" },
    { aba: "123206367", name: "Washington Federal Bank, Na" },
    { aba: "123206406", name: "Columbia State Bank" },
    { aba: "123206516", name: "Us Bank Na" },
    { aba: "123206545", name: "Columbia State Bank" },
    { aba: "123206590", name: "Banner Bank" },
    { aba: "123206613", name: "Mufg Union Bank, N.a." },
    { aba: "123206639", name: "Riverview Community Bank" },
    { aba: "123206707", name: "Us Bank Na" },
    { aba: "123206710", name: "Us Bank" },
    { aba: "123206736", name: "People's Bank Of Commerce" },
    { aba: "123206778", name: "Columbia State Bank" },
    { aba: "123206781", name: "Umpqua Bank (oregon State Bank)" },
    { aba: "123206794", name: "Heritage Bank" },
    { aba: "123206817", name: "Banner Bank" },
    { aba: "123206859", name: "Willamette Valley Bank" },
    { aba: "123206875", name: "Oregon Coast Bank" },
    { aba: "123206888", name: "Willamette Community Bank" },
    { aba: "123206914", name: "Summit Bank" },
    { aba: "123206927", name: "Columbia State Bank" },
    { aba: "123206930", name: "Homestreet Bank" },
    { aba: "123206943", name: "Pacific West Bank" },
    { aba: "123206956", name: "Northwest Bank" },
    { aba: "123206972", name: "Lewis & Clark Bank" },
    { aba: "123206998", name: "Lewis & Clark Bank" },
    { aba: "123271978", name: "Jpmorgan Chase" },
    { aba: "123285090", name: "Mid Oregon Federal Credit Union" },
    { aba: "123300153", name: "Baker Boyer National Bank" },
    { aba: "123305378", name: "Umpqua Bank" },
    { aba: "123306160", name: "Us Bank Na" },
    { aba: "123306429", name: "Bank Of The Pacific" },
    { aba: "123307583", name: "Heritage Bank" },
    { aba: "123308090", name: "Columbia State Bank" },
    { aba: "123308430", name: "Beneficial State Bank" },
    { aba: "123308553", name: "Umpqua Bank" },
    { aba: "123308582", name: "Twin City Bank" },
    { aba: "123308618", name: "Twin City Bank" },
    { aba: "123308786", name: "Banner Bank" },
    { aba: "123308825", name: "Bank Of America, Na" },
    { aba: "124000012", name: "Wells Fargo Bank Na (northwest)" },
    { aba: "124000025", name: "Wells Fargo Bank, Na" },
    { aba: "124000054", name: "Zions Bancorporation, Na Dba Zions B" },
    { aba: "124000313", name: "Frb - Salt Lake City" },
    { aba: "124000737", name: "Key Bank" },
    { aba: "124001545", name: "Jpmorgan Chase" },
    { aba: "124002735", name: "Bank Of The West" },
    { aba: "124002887", name: "Home Savings Bank" },
    { aba: "124002942", name: "Liberty Bank" },
    { aba: "124002971", name: "Wells Fargo Bank Na" },
    { aba: "124003116", name: "Ally Bank" },
    { aba: "124071889", name: "American Express National Bank" },
    { aba: "124084672", name: "Ameriprise Bank, Fsb" },
    { aba: "124084708", name: "Bmw Bank Of North America" },
    { aba: "124084779", name: "Cache Valley Bank" },
    { aba: "124084805", name: "Celtic Bank Corporation" },
    { aba: "124084821", name: "Utah First Federal Credit Union" },
    { aba: "124084834", name: "Cit Bank Na" },
    { aba: "124085024", name: "Green Dot Bank" },
    { aba: "124085066", name: "American Express National Bank" },
    { aba: "124085244", name: "Metabank, National Association" },
    { aba: "124085260", name: "Goldman Sachs Bank Usa" },
    { aba: "124085286", name: "Wex Bank" },
    { aba: "124085299", name: "Sallie Mae Bank" },
    { aba: "124100064", name: "Wells Fargo Bank Na" },
    { aba: "124100080", name: "Wells Fargo Bank Na" },
    { aba: "124100417", name: "The Bank Of Commerce" },
    { aba: "124100857", name: "Ireland Bank" },
    { aba: "124101128", name: "Zions Bancorporation, Na Dba Zions B" },
    { aba: "124101555", name: "Key Bank" },
    { aba: "124102509", name: "Farmers Bank" },
    { aba: "124103582", name: "D L Evans Bank" },
    { aba: "124103676", name: "Bank Of Idaho" },
    { aba: "124103744", name: "D L Evans Bank" },
    { aba: "124103757", name: "Sunwest Bank" },
    { aba: "124103760", name: "Us Bank, Na" },
    { aba: "124103773", name: "Glacier Bank-citizens Community Div" },
    { aba: "124103786", name: "Columbia State Bank" },
    { aba: "124103799", name: "Wells Fargo Bank Na" },
    { aba: "124103838", name: "Idaho First Bank" },
    { aba: "124103841", name: "Idaho Trust National Bank" },
    { aba: "124103896", name: "Glacier Bank-citizens Community Div" },
    { aba: "124103922", name: "Northwest Bank" },
    { aba: "124200249", name: "The First National Bank Of Ely" },
    { aba: "124201565", name: "Nevada Bank And Trust" },
    { aba: "124201581", name: "Nevada Bank And Trust" },
    { aba: "124201594", name: "Nevada Bank And Trust" },
    { aba: "124201688", name: "Nevada Bank And Trust" },
    { aba: "124201701", name: "Nevada Bank And Trust" },
    { aba: "124201756", name: "Nevada Bank And Trust" },
    { aba: "124300107", name: "Bank Of Utah" },
    { aba: "124300327", name: "Central Bank" },
    { aba: "124300699", name: "State Bank Of Southern Utah" },
    { aba: "124300754", name: "First Cmnty Bk Ut, Div Of Glacier Bk" },
    { aba: "124300767", name: "Altabank" },
    { aba: "124300806", name: "Glacier Bank-1st Bank Div" },
    { aba: "124301025", name: "Altabank" },
    { aba: "124301779", name: "State Bank Of Southern Utah" },
    { aba: "124301850", name: "Zions Bancorporation, Na Dba Zions B" },
    { aba: "124302118", name: "Holladay Bank & Trust" },
    { aba: "124302150", name: "Us Bank Na" },
    { aba: "124302257", name: "Banner Bank" },
    { aba: "124302325", name: "Cache Valley Bank" },
    { aba: "124302464", name: "Utah Independent Bank" },
    { aba: "124302503", name: "Brighton Bank" },
    { aba: "124302529", name: "Green Dot Bank" },
    { aba: "124302613", name: "First Utah Bank" },
    { aba: "124302914", name: "Rock Canyon Bank" },
    { aba: "124302927", name: "Capital Community Bank" },
    { aba: "124302930", name: "Cache Valley Bank" },
    { aba: "124302969", name: "Bank Of Utah" },
    { aba: "124303007", name: "Key Bank" },
    { aba: "124303010", name: "Grand Valley Bank" },
    { aba: "124303023", name: "Cache Valley Bank" },
    { aba: "124303036", name: "Finwise Bank" },
    { aba: "124303049", name: "Cache Valley Bank" },
    { aba: "124303065", name: "Bank Of America Na" },
    { aba: "124303081", name: "Prime Alliance Bank" },
    { aba: "124303094", name: "Altabank" },
    { aba: "124303104", name: "Altabank" },
    { aba: "124303120", name: "Green Dot Bank" },
    { aba: "124303162", name: "Go2bank, Division Of Green Dot Bank" },
    { aba: "124384521", name: "America First Federal Credit Union" },
    { aba: "124384589", name: "Webbank" },
    { aba: "124384602", name: "Merrick Bank Corporation" },
    { aba: "124384657", name: "Transportation Alliance Bank" },
    { aba: "124384877", name: "Optum Bank, Inc." },
    { aba: "124384974", name: "First Electronic Bank" },
    { aba: "124385119", name: "Sallie Mae Bank" },
    { aba: "124385274", name: "Merrick Bank Corporation" },
    { aba: "125000011", name: "Frb - Seattle" },
    { aba: "125000024", name: "Bank Of America, N.a. (sfnb)" },
    { aba: "125000105", name: "Us Bank Na" },
    { aba: "125000118", name: "Mufg Union Bank, N.a." },
    { aba: "125000574", name: "Key Bank" },
    { aba: "125006390", name: "Umpqua Bank" },
    { aba: "125007098", name: "Hsbc Bank Usa-seattle" },
    { aba: "125008013", name: "Zions Bancorporation, Na Dba The Com" },
    { aba: "125008165", name: "East West Bank" },
    { aba: "125008220", name: "Banner Bank" },
    { aba: "125008288", name: "Mufg Union Bank, N.a." },
    { aba: "125008547", name: "Wells Fargo Bank Na" },
    { aba: "125008644", name: "Northwest Bank" },
    { aba: "125008657", name: "Bank Of Hope" },
    { aba: "125008712", name: "First Sound Bank" },
    { aba: "125008806", name: "United Business Bank" },
    { aba: "125008848", name: "First Sound Bank" },
    { aba: "125084697", name: "Seattle Bank" },
    { aba: "125100089", name: "Washington Trust Bank" },
    { aba: "125100513", name: "Heritage Savings Bank (npb)" },
    { aba: "125100607", name: "Security State Bank" },
    { aba: "125101651", name: "State Bank Northwest" },
    { aba: "125102278", name: "Columbia State Bank" },
    { aba: "125102676", name: "Lamont Bank Of St John" },
    { aba: "125102906", name: "Kitsap Bank" },
    { aba: "125103882", name: "Farmers State Bank" },
    { aba: "125104315", name: "Columbia State Bank" },
    { aba: "125104425", name: "Peoples Bank" },
    { aba: "125104564", name: "Farmington State Bank" },
    { aba: "125104603", name: "Cashmere Valley Bank" },
    { aba: "125104865", name: "Columbia State Bank" },
    { aba: "125105550", name: "Heritage Bank" },
    { aba: "125105631", name: "Banner Bank" },
    { aba: "125105741", name: "Banner Bank" },
    { aba: "125105822", name: "Central Valley Div Of Heritage Bank" },
    { aba: "125106708", name: "Heritage Bank" },
    { aba: "125106986", name: "Heritage Bank" },
    { aba: "125107008", name: "Security State Bank" },
    { aba: "125107037", name: "Banner Bank" },
    { aba: "125107079", name: "Columbia State Bank" },
    { aba: "125107260", name: "Mufg Union Bank, N.a." },
    { aba: "125107532", name: "Columbia State Bank" },
    { aba: "125107626", name: "Mufg Union Bank, N.a." },
    { aba: "125107642", name: "Glacier Bank" },
    { aba: "125107671", name: "First Citizens Bank" },
    { aba: "125107697", name: "Wheatland Bk" },
    { aba: "125107707", name: "Bank Of The Pacific" },
    { aba: "125107723", name: "Kitsap Bank (northwestern Bank)" },
    { aba: "125107736", name: "Mufg Union Bank, N.a." },
    { aba: "125107765", name: "Banner Bank" },
    { aba: "125107778", name: "Columbia State Bank" },
    { aba: "125107820", name: "Islanders Bank" },
    { aba: "125107862", name: "Glacier Bank-mountain West Div" },
    { aba: "125107875", name: "Columbia State Bank" },
    { aba: "125107998", name: "Glacier Bank" },
    { aba: "125108065", name: "Banner Bank" },
    { aba: "125108159", name: "Columbia State Bk Dba Mt Rainier Bk" },
    { aba: "125108175", name: "Sound Banking Co" },
    { aba: "125108191", name: "Banner Bank" },
    { aba: "125108256", name: "Heritage Bank" },
    { aba: "125108272", name: "Columbia State Bank" },
    { aba: "125108298", name: "Agribank, Fcb-omaha Office" },
    { aba: "125108353", name: "Bank Of The Pacific" },
    { aba: "125108366", name: "Sound Credit Union" },
    { aba: "125108382", name: "Banner Bank" },
    { aba: "125108405", name: "Coastal Community Bank" },
    { aba: "125108447", name: "Heritage Bank" },
    { aba: "125108450", name: "Umpqua Bank" },
    { aba: "125108463", name: "Community First Bank" },
    { aba: "125108476", name: "Kitsap Bank" },
    { aba: "125108489", name: "Zions Bancorporation, Na Dba The Com" },
    { aba: "125108492", name: "East West Bank" },
    { aba: "125108502", name: "Kitsap Bank" },
    { aba: "125108560", name: "Heritage Bank" },
    { aba: "125108573", name: "Gbc International Bank" },
    { aba: "125108599", name: "Banner Bank" },
    { aba: "125108609", name: "Timberland Bank" },
    { aba: "125108625", name: "Columbia State Bank" },
    { aba: "125108667", name: "First Sound Bank" },
    { aba: "125108670", name: "Washington Business Bank" },
    { aba: "125108683", name: "Heritage Bank" },
    { aba: "125108696", name: "Ctbc Bank Corp. (usa)" },
    { aba: "125108706", name: "Kitsap Bank" },
    { aba: "125108735", name: "Commencement Bank" },
    { aba: "125108748", name: "Savibank" },
    { aba: "125108751", name: "Heritage Bank" },
    { aba: "125108764", name: "Banner Bank" },
    { aba: "125108816", name: "River Bank" },
    { aba: "125108832", name: "Mountain Pacific Bank" },
    { aba: "125108858", name: "Banner Bank" },
    { aba: "125108861", name: "Unibank" },
    { aba: "125108887", name: "Commencement Bank" },
    { aba: "125108890", name: "Banner Bank" },
    { aba: "125108913", name: "Columbia State Bank" },
    { aba: "125108939", name: "Banner Bank" },
    { aba: "125108942", name: "Bank Of Hope" },
    { aba: "125108968", name: "Heritage Bank" },
    { aba: "125108984", name: "Liberty Bank" },
    { aba: "125184568", name: "W C L A Credit Union" },
    { aba: "125184610", name: "Pacific Crest Savings Bank" },
    { aba: "125184746", name: "Umpqua Bank" },
    { aba: "125184937", name: "Harborstone Credit Union" },
    { aba: "125185143", name: "Gesa Credit Union" },
    { aba: "125200044", name: "First Bank" },
    { aba: "125200057", name: "Wells Fargo Bank N.a." },
    { aba: "125200060", name: "First National Bank Alaska" },
    { aba: "125200879", name: "Key Bank" },
    { aba: "125200905", name: "Northrim Bank" },
    { aba: "125200921", name: "Denali State Bank" },
    { aba: "125200934", name: "Northrim Bank" },
    { aba: "125200947", name: "Key Bank" },
    { aba: "125200950", name: "Denali State Bank" },
    { aba: "125200963", name: "Wells Fargo Bank, Na" },
    { aba: "211070023", name: "East Boston Savings Bank" },
    { aba: "211070120", name: "East Boston Savings Bank" },
    { aba: "211070146", name: "Eastern Bank" },
    { aba: "211070175", name: "Citizens Bank Na" },
    { aba: "211070227", name: "East Boston Savings Bank" },
    { aba: "211070230", name: "The Cooperative Bank" },
    { aba: "211070243", name: "East Boston Savings Bank" },
    { aba: "211070256", name: "Commonwealth Co-operative Bank" },
    { aba: "211072885", name: "Commonwealth Co-operative Bank" },
    { aba: "211072911", name: "People's United Bank, N.a." },
    { aba: "211073237", name: "Rockland Trust Company" },
    { aba: "211073473", name: "Envision Bank" },
    { aba: "211073499", name: "Santander Bank, N.a." },
    { aba: "211073981", name: "Rockland Trust Company" },
    { aba: "211075086", name: "Radius Bank" },
    { aba: "211080602", name: "Alpha Credit Union" },
    { aba: "211080660", name: "Rtn Federal Credit Union" },
    { aba: "211080699", name: "Boston Customs Fcu" },
    { aba: "211080709", name: "Members Plus Credit Union" },
    { aba: "211080712", name: "Boston Firefighters C.u." },
    { aba: "211080725", name: "First Priorty Cu" },
    { aba: "211080741", name: "Symphony Federal Credit Union" },
    { aba: "211080754", name: "Tremont Credit Union" },
    { aba: "211080783", name: "Cabot Boston Credit Union" },
    { aba: "211080822", name: "City Of Boston Credit Un" },
    { aba: "211080851", name: "Dora Usalliance" },
    { aba: "211080877", name: "River Works Credit Union" },
    { aba: "211080880", name: "600 Atlantic Federal Credit Union" },
    { aba: "211081067", name: "Nemch Credit Union" },
    { aba: "211081096", name: "City Of Boston Credit Un" },
    { aba: "211081148", name: "Union Fed Credit Union" },
    { aba: "211081216", name: "Liberty Bay Credit Union" },
    { aba: "211081892", name: "New England Teamsters Fcu" },
    { aba: "211082354", name: "Members Plus Credit Union" },
    { aba: "211083528", name: "Energy Credit Union" },
    { aba: "211083557", name: "Mass Bay Credit Union" },
    { aba: "211091934", name: "Taupa Lithuanian Fcu" },
    { aba: "211092182", name: "M.o.s.e.s. Federal Credit Union" },
    { aba: "211170046", name: "Liberty Bank" },
    { aba: "211170101", name: "Webster Bank, National Association" },
    { aba: "211170114", name: "Citizens Bank Na" },
    { aba: "211170130", name: "Keybank National Association" },
    { aba: "211170156", name: "Webster Bank, National Association" },
    { aba: "211170169", name: "Peoples United Bank, Na" },
    { aba: "211170185", name: "Keybank National Association" },
    { aba: "211170208", name: "Guilford Savings" },
    { aba: "211170211", name: "The Milford Bank" },
    { aba: "211170237", name: "Windsor Fed Svgs & Ln Assn" },
    { aba: "211170253", name: "Eastern Connecticut Savings Bank" },
    { aba: "211170279", name: "Webster Bank" },
    { aba: "211170282", name: "Liberty Bank" },
    { aba: "211170305", name: "Webster Bank" },
    { aba: "211170318", name: "Peoples United Bank, Na" },
    { aba: "211170334", name: "People's United Bank, N.a." },
    { aba: "211170347", name: "Peoples United Bank, Na" },
    { aba: "211170363", name: "Capital One, N.a." },
    { aba: "211170376", name: "Essex Savings Bank" },
    { aba: "211172785", name: "Webster Bank" },
    { aba: "211173357", name: "Chelsea Groton Bank" },
    { aba: "211173373", name: "Dime Bank" },
    { aba: "211174136", name: "Webster Bank" },
    { aba: "211174165", name: "Torrington Savings Bank" },
    { aba: "211174178", name: "Ion Bank" },
    { aba: "211174181", name: "Berkshire Bank" },
    { aba: "211174194", name: "People's United Bank, N.a." },
    { aba: "211174204", name: "Northwest Community Bk" },
    { aba: "211174217", name: "Northwest Community Bank" },
    { aba: "211174220", name: "Centreville Bank" },
    { aba: "211174233", name: "Td Bank, Na" },
    { aba: "211174259", name: "Thomaston Savings Bank" },
    { aba: "211174262", name: "Stafford Savings Bank" },
    { aba: "211174275", name: "Litchfield Bancorp" },
    { aba: "211174288", name: "Collinsville Bank" },
    { aba: "211174301", name: "Berkshire Bank" },
    { aba: "211174330", name: "Jewett City Savings Bank" },
    { aba: "211174343", name: "Webster Bank" },
    { aba: "211174356", name: "Liberty Bank" },
    { aba: "211174369", name: "Keybank National Association" },
    { aba: "211176574", name: "Asa Fed C U" },
    { aba: "211176590", name: "Franklin Trust Fcu" },
    { aba: "211176613", name: "American Eagle Fcu" },
    { aba: "211176655", name: "Crosspoint Federal Credit Union" },
    { aba: "211176684", name: "First Bristol F.c.u." },
    { aba: "211176707", name: "United Business & Industry Fcu" },
    { aba: "211176859", name: "Crosspoint Federal Credit Union" },
    { aba: "211176888", name: "Americas First Network Credit Union" },
    { aba: "211176891", name: "American Eagle Financial Cu, Inc." },
    { aba: "211176901", name: "Finex Credit Union" },
    { aba: "211176956", name: "Enfield Community Fcu" },
    { aba: "211176969", name: "Tobacco Valley Teachers Fcu" },
    { aba: "211176998", name: "Charter Oak Comm Fed Cu" },
    { aba: "211177007", name: "Groton Mun Empl Fcu" },
    { aba: "211177010", name: "Scient Federal Credit Union" },
    { aba: "211177052", name: "Crosspoint Federal Credit Union" },
    { aba: "211177133", name: "Crosspoint Federal Credit Union" },
    { aba: "211177612", name: "First Connecticut C U Inc" },
    { aba: "211177654", name: "Community Healthcare C.u." },
    { aba: "211177670", name: "Manchester Municipal Federal C.u." },
    { aba: "211177683", name: "Northeast Family Fcu" },
    { aba: "211177748", name: "Membersfirst Ct Federal Credit Union" },
    { aba: "211177777", name: "Meriden Postal Empl Fcu" },
    { aba: "211177816", name: "Dutch Point Credit Union" },
    { aba: "211177829", name: "Seasons Fcu" },
    { aba: "211177832", name: "Seasons Fcu" },
    { aba: "211177942", name: "Connecticut Postal F C U" },
    { aba: "211178006", name: "Achieve Financial Credit Union" },
    { aba: "211178103", name: "New Haven County Credit Union, Inc." },
    { aba: "211178190", name: "Scient Federal Credit Union" },
    { aba: "211178200", name: "Connex Credit Union" },
    { aba: "211178242", name: "Science Park Fcu" },
    { aba: "211178271", name: "Connex Credit Union, Inc" },
    { aba: "211178349", name: "Healthcare Financial Federal Cu" },
    { aba: "211178365", name: "Lawrence Mem Hosp Emp C.u." },
    { aba: "211178378", name: "New London Municipal Cu" },
    { aba: "211178404", name: "Coreplus Federal Credit Union" },
    { aba: "211178433", name: "Community C.u. Of New Milford, Inc." },
    { aba: "211178572", name: "America's First Network Credit Union" },
    { aba: "211178598", name: "Coreplus Federal Credit Union" },
    { aba: "211178608", name: "Coreplus Federal Credit Union" },
    { aba: "211178640", name: "Usalliance Federal Credit Union" },
    { aba: "211178666", name: "Coreplus Federal Credit Union" },
    { aba: "211178705", name: "United Business & Industry F.c.u." },
    { aba: "211178844", name: "America's First Network Credit Union" },
    { aba: "211178941", name: "C S P Empl F.c.u." },
    { aba: "211179131", name: "Northwest Hills C.u." },
    { aba: "211179144", name: "Torrington Mun & Teachers F.c.u." },
    { aba: "211179283", name: "Cornerstone Community Credit Union" },
    { aba: "211179539", name: "Greater Waterbury Healthcare Fcu" },
    { aba: "211179568", name: "Fd Community Fcu" },
    { aba: "211179571", name: "Waterbury Telephone Fcu" },
    { aba: "211179584", name: "Waterbury Police Fcu" },
    { aba: "211179607", name: "Waterbury Conn Teachers Fed Cr Union" },
    { aba: "211179610", name: "Waterbury Postal Emp" },
    { aba: "211179652", name: "Greater Watertown Fed Cu" },
    { aba: "211179937", name: "Dutch Point Credit Union" },
    { aba: "211179982", name: "Usalliance Federal Credit Union" },
    { aba: "211179995", name: "Usalliance Federal Credit Union" },
    { aba: "211180010", name: "Conn. Labor Dept. Fcu" },
    { aba: "211180023", name: "Dutch Point Credit Union" },
    { aba: "211180133", name: "360 Federal Credit Union" },
    { aba: "211180159", name: "Windsor Locks Fcu" },
    { aba: "211189956", name: "G E Employees Fcu" },
    { aba: "211191944", name: "Alloya Corporate Fcu" },
    { aba: "211192309", name: "America's First Network Credit Union" },
    { aba: "211272465", name: "Camden National Bank" },
    { aba: "211272504", name: "Camden National Bank" },
    { aba: "211272517", name: "Northeast Bank, Fsb" },
    { aba: "211272520", name: "Androscoggin Savings Bank" },
    { aba: "211272546", name: "Camden National Bank" },
    { aba: "211272588", name: "Norway Savings Bank" },
    { aba: "211272614", name: "Kennebec Fed S&l" },
    { aba: "211272630", name: "Saco & Biddeford Svg Inst" },
    { aba: "211272766", name: "Mechanics Savings, A Div Of Maine Cm" },
    { aba: "211273309", name: "Camden National Bank" },
    { aba: "211273749", name: "Auburn Savings Bank, Fsb." },
    { aba: "211273752", name: "Bar Harbor Savings & Loan" },
    { aba: "211273765", name: "First Fed. S&l Assn." },
    { aba: "211273781", name: "Camden National Bank" },
    { aba: "211273794", name: "Aroostook County Fed S + L" },
    { aba: "211273846", name: "Rockland Savings Bank Fsb" },
    { aba: "211274382", name: "Bangor Savings Bank" },
    { aba: "211274395", name: "Maine Community Bank" },
    { aba: "211274421", name: "Kennebec Savings Bank" },
    { aba: "211274447", name: "Bath Savings Institution" },
    { aba: "211274450", name: "Td Bank, N.a." },
    { aba: "211274476", name: "Skowhegan Savings Bank" },
    { aba: "211274492", name: "Franklin Savings Bank" },
    { aba: "211274502", name: "Kennebunk Savings Bank" },
    { aba: "211274515", name: "Norway Savings Bank" },
    { aba: "211274531", name: "Machias Savings Bank" },
    { aba: "211274557", name: "Northeast Bank" },
    { aba: "211274573", name: "Gorham Savings Bank" },
    { aba: "211274586", name: "Camden National Bank" },
    { aba: "211274599", name: "Partners Bank" },
    { aba: "211287256", name: "Great Falls Reg. F.c.u." },
    { aba: "211287298", name: "Trademark Federal Credit Union" },
    { aba: "211287324", name: "Capital Area Federal C U" },
    { aba: "211287340", name: "Maine State Cu" },
    { aba: "211287353", name: "Connected Credit Union" },
    { aba: "211287366", name: "Kv Federal Credit Union" },
    { aba: "211287405", name: "Changing Seasons Federal Cu" },
    { aba: "211287447", name: "Bangor Federal Cr Union" },
    { aba: "211287463", name: "Five County C.u." },
    { aba: "211287476", name: "Midcoast Federal C. U." },
    { aba: "211287502", name: "Northeast Credit Union" },
    { aba: "211287515", name: "Peoples Choice Credit Union" },
    { aba: "211287560", name: "Atlantic Regional Fcu" },
    { aba: "211287586", name: "Seaboard Fcu" },
    { aba: "211287609", name: "Otis Fcu" },
    { aba: "211287612", name: "Evergreen Credit Union" },
    { aba: "211287638", name: "Maine Highlands Federal Credit Union" },
    { aba: "211287667", name: "Eastmill Fcu" },
    { aba: "211287696", name: "Cumberland County Federal C.u." },
    { aba: "211287748", name: "Acadia Federal Credit Union" },
    { aba: "211287780", name: "Gardiner Fcu" },
    { aba: "211287793", name: "Gardiner Fcu" },
    { aba: "211287816", name: "Casco Federal Credit Union" },
    { aba: "211287845", name: "The County Federal Credit Union" },
    { aba: "211287858", name: "Penobscot County Fcu" },
    { aba: "211287887", name: "Dirigo Federal Cu" },
    { aba: "211287900", name: "Lewiston Mun Fcu" },
    { aba: "211287913", name: "Maine Family F.c.u." },
    { aba: "211287926", name: "Central Maine Federal Credit Union" },
    { aba: "211287942", name: "Community Credit Union" },
    { aba: "211287971", name: "Lincoln Maine Fcu" },
    { aba: "211287984", name: "Lisbon Community Fcu" },
    { aba: "211288006", name: "County Federal Credit Unio" },
    { aba: "211288019", name: "Norstate Federal Credit Union" },
    { aba: "211288051", name: "Oxford Fcu" },
    { aba: "211288064", name: "Maine Savings F.c.u" },
    { aba: "211288077", name: "Katahdin Federal Credit Un" },
    { aba: "211288080", name: "Monmouth Federal Credit Un" },
    { aba: "211288158", name: "Penobscot County Fcu" },
    { aba: "211288161", name: "University Credit Union" },
    { aba: "211288174", name: "Sebasticook Valley Fcu" },
    { aba: "211288187", name: "Blue Cross & Blue Shield Of Me Efcu" },
    { aba: "211288226", name: "Cumberland County Fcu" },
    { aba: "211288239", name: "Cport Credit Union" },
    { aba: "211288307", name: "Truchoice Federal Credit Union" },
    { aba: "211288404", name: "Maine Media Federal Cr. Union" },
    { aba: "211288417", name: "Coast Line Credit Union" },
    { aba: "211288433", name: "Trugrocer Federal Credit Union" },
    { aba: "211288491", name: "Infinity Federal Credit Union" },
    { aba: "211288585", name: "Semiconductor Of Maine F.c.u." },
    { aba: "211288598", name: "Trademark Federal Credit Union" },
    { aba: "211288637", name: "Evergreen Credit Union" },
    { aba: "211288640", name: "Town & Country Federal C.u." },
    { aba: "211288653", name: "Sabattus Regional C U" },
    { aba: "211288666", name: "Saco Valley Credit Union" },
    { aba: "211288718", name: "Atlantic Regional Fed Credit Union" },
    { aba: "211288747", name: "Brewer Federal C U" },
    { aba: "211288844", name: "Ksw Federal Credit Union" },
    { aba: "211288860", name: "New Dimensions Fcu" },
    { aba: "211288912", name: "Kv Federal Credit Union" },
    { aba: "211288941", name: "Franklin-somerset Fcu" },
    { aba: "211288970", name: "Winslow Community Fed Cu" },
    { aba: "211288983", name: "Winthrop Area Fcu" },
    { aba: "211288996", name: "Down East Cu" },
    { aba: "211291666", name: "Tricorp F.c.u." },
    { aba: "211291802", name: "Five County Cu" },
    { aba: "211370011", name: "Chelsea Bank" },
    { aba: "211370024", name: "Mechanics Cooperative Bank" },
    { aba: "211370037", name: "Fidelity Co-operative Bank" },
    { aba: "211370053", name: "Haverhill Bank" },
    { aba: "211370066", name: "Pentucket Bank" },
    { aba: "211370082", name: "Inst For Svgs Newburyport" },
    { aba: "211370095", name: "Td Bank Na" },
    { aba: "211370118", name: "Coastal Heritage Bank" },
    { aba: "211370134", name: "Rockland Trust Company" },
    { aba: "211370150", name: "Middlesex Fed. Savings, F.a." },
    { aba: "211370176", name: "Watertown Savings Bank" },
    { aba: "211370228", name: "Bankgloucester" },
    { aba: "211370231", name: "Webster Bank, Nat'l Assoc." },
    { aba: "211370257", name: "Eastern Bank" },
    { aba: "211370273", name: "Everett Co-operative Bank" },
    { aba: "211370299", name: "Santander" },
    { aba: "211370338", name: "North Easton Savings Bank" },
    { aba: "211370354", name: "Eastern Bank" },
    { aba: "211370370", name: "Hingham Inst For Svg" },
    { aba: "211370396", name: "Avidia Bank" },
    { aba: "211370406", name: "Winter Hill Bank" },
    { aba: "211370419", name: "East Cambridge Svgs Bank" },
    { aba: "211370435", name: "Cambridge Savings Bank" },
    { aba: "211370448", name: "Eastern Bank" },
    { aba: "211370451", name: "Canton Co-op Bank" },
    { aba: "211370480", name: "Salem Five Cents Svgs Bk" },
    { aba: "211370493", name: "Td Bank, Na" },
    { aba: "211370503", name: "North Easton Savings Bank" },
    { aba: "211370529", name: "Avidia Bank" },
    { aba: "211370545", name: "Td Bank, Na" },
    { aba: "211370558", name: "Salem Five Cents Svgs Bk" },
    { aba: "211370626", name: "Colonial Federal Savings Bank" },
    { aba: "211370639", name: "Hometown Bank" },
    { aba: "211370642", name: "Bankfive" },
    { aba: "211370668", name: "Fidelity Co-operative Bank" },
    { aba: "211370697", name: "North Shore Bank" },
    { aba: "211370707", name: "Fidelity Co-op Bank" },
    { aba: "211370736", name: "Rollstone Bank & Trust" },
    { aba: "211370752", name: "Main Street Bank" },
    { aba: "211370833", name: "Cambridge Savings Bank" },
    { aba: "211370846", name: "Eastern Bank" },
    { aba: "211370859", name: "Bristol County Svgs Bank" },
    { aba: "211370862", name: "Cape Ann Savings Bank" },
    { aba: "211370888", name: "Winchester Savings Bank" },
    { aba: "211370927", name: "Eastern Bank" },
    { aba: "211370943", name: "Inst For Svgs Newburyport" },
    { aba: "211370998", name: "Athol Savings Bank" },
    { aba: "211371023", name: "Bay State Savings Bank" },
    { aba: "211371052", name: "Rockland Trust Company" },
    { aba: "211371065", name: "Mountainone Bank" },
    { aba: "211371078", name: "Cape Cod Five Cents Svg Bk" },
    { aba: "211371120", name: "Cambridge Savings Bank" },
    { aba: "211371133", name: "Eagle Bank" },
    { aba: "211371162", name: "People's United Bank, N.a." },
    { aba: "211371191", name: "Santander Bank" },
    { aba: "211371201", name: "Eastern Bank" },
    { aba: "211371227", name: "Middlesex Savings" },
    { aba: "211371243", name: "Envision Bank" },
    { aba: "211371285", name: "Norwood Co-op Bank" },
    { aba: "211371298", name: "North Shore Bank" },
    { aba: "211371308", name: "Santander Bank" },
    { aba: "211371311", name: "Savers Coop Bank" },
    { aba: "211371324", name: "Milford Federal Bank" },
    { aba: "211371337", name: "Lowell Five Cents Svgs Bk" },
    { aba: "211371340", name: "Methuen Cooperative Bank" },
    { aba: "211371353", name: "Salem Five Cents Savings Bk" },
    { aba: "211371366", name: "The Savings Bank" },
    { aba: "211371379", name: "Main Street Bank" },
    { aba: "211371421", name: "Eastern Bank" },
    { aba: "211371434", name: "People's United Bank, N.a." },
    { aba: "211371447", name: "South Shore Bank" },
    { aba: "211371450", name: "Santander Bank, N.a." },
    { aba: "211371463", name: "North Easton Savings Bank" },
    { aba: "211371476", name: "Eastern Bank" },
    { aba: "211371489", name: "Brookline Bank" },
    { aba: "211371492", name: "Coastal Heritage Bank" },
    { aba: "211371502", name: "Newburyport Five Cents Savings Bk" },
    { aba: "211371573", name: "Winchester Co-op Bank" },
    { aba: "211371586", name: "Stoneham Bank - A Co-op Bank" },
    { aba: "211371599", name: "Southbridge Savings Bank" },
    { aba: "211371625", name: "South Shore Bank" },
    { aba: "211371641", name: "Cape Cod Co-op Bank" },
    { aba: "211371670", name: "N Cambridge Cooperative" },
    { aba: "211371722", name: "Dedham Inst For Savings" },
    { aba: "211371735", name: "Cornerstone Bank" },
    { aba: "211371764", name: "Peoples United Bank, Na" },
    { aba: "211371793", name: "Stoughton Co-op Bank" },
    { aba: "211371816", name: "Mutualone Bank" },
    { aba: "211371829", name: "Rockland Trust Company" },
    { aba: "211371845", name: "Abington Bank" },
    { aba: "211371858", name: "The Village Bank" },
    { aba: "211371887", name: "Citizens Union Savings" },
    { aba: "211371913", name: "Mansfield Co-op Bank" },
    { aba: "211371926", name: "Hometown Bank" },
    { aba: "211371939", name: "Wrentham Co-op Bank" },
    { aba: "211371968", name: "Hometown Bank, A Co-op Bank" },
    { aba: "211371997", name: "Wakefield Coop Bank" },
    { aba: "211372035", name: "Dean Co-operative Bank" },
    { aba: "211372048", name: "Mechanics' Co-op Bank" },
    { aba: "211372051", name: "Foxboro Federal Savings" },
    { aba: "211372064", name: "Salem Five Cents Savings Bk" },
    { aba: "211372103", name: "Coastal Heritage Bank" },
    { aba: "211372129", name: "People's United Bank, N.a." },
    { aba: "211372145", name: "North Shore Bank" },
    { aba: "211372161", name: "Needham Bank" },
    { aba: "211372226", name: "Brookline Bank" },
    { aba: "211372239", name: "Baycoast Bank" },
    { aba: "211372271", name: "Abington Bank" },
    { aba: "211372284", name: "Salem Five Cents Savings Bank" },
    { aba: "211372310", name: "Cambridge Trust Company" },
    { aba: "211372323", name: "The Village Bank" },
    { aba: "211372352", name: "Eastern Bank" },
    { aba: "211372378", name: "Unibank For Savings" },
    { aba: "211372404", name: "Reading Co-op Bank" },
    { aba: "211372417", name: "Rockland Trust Company" },
    { aba: "211372776", name: "Rockland Trust Company" },
    { aba: "211372828", name: "Bridgewater Savings Bank" },
    { aba: "211372844", name: "Middlesex Savings" },
    { aba: "211372857", name: "Webster Five Cents Savings" },
    { aba: "211372909", name: "Framingham Co-op Bank" },
    { aba: "211372925", name: "Martha's Vineyard Savings Bank" },
    { aba: "211372996", name: "Marblehead Bank" },
    { aba: "211373018", name: "Charles River Bank" },
    { aba: "211373063", name: "Walpole Cooperative Bank" },
    { aba: "211373089", name: "Bridgewater Savings Bank" },
    { aba: "211373102", name: "The Bank Of Canton" },
    { aba: "211373115", name: "Clinton Savings Bank" },
    { aba: "211373128", name: "Coastal Heritage Bank" },
    { aba: "211373267", name: "Abington Bank" },
    { aba: "211373348", name: "Rockland Credit Union" },
    { aba: "211373429", name: "Cape Ann Savings Bank" },
    { aba: "211373461", name: "Seaman's Bank" },
    { aba: "211373526", name: "Brookline Bank" },
    { aba: "211373539", name: "Needham Bank" },
    { aba: "211373542", name: "People's United Bank, N.a." },
    { aba: "211373571", name: "Mechanics' Co-op Bank" },
    { aba: "211373584", name: "Abington Bank" },
    { aba: "211373597", name: "Bank Of Easton" },
    { aba: "211373623", name: "People's United Bank, N.a." },
    { aba: "211374004", name: "Washington Savings Bank" },
    { aba: "211374020", name: "The Provident Bank" },
    { aba: "211374046", name: "Fidelity Co-operative Bank" },
    { aba: "211374062", name: "North Shore Bank" },
    { aba: "211374091", name: "North Brookfield Savings B" },
    { aba: "211380179", name: "Acushnet Fcu" },
    { aba: "211380289", name: "Andover Federal Credit Un" },
    { aba: "211380302", name: "Nesc Federal Cr Union" },
    { aba: "211380315", name: "Arlington Municipal F.c.u." },
    { aba: "211380344", name: "Athol Credit Union" },
    { aba: "211380357", name: "Attleboro Mun Emp Fcu" },
    { aba: "211380373", name: "Goldmark Federal C. U." },
    { aba: "211380409", name: "Rockland Federal Credit Union" },
    { aba: "211380425", name: "Harborone Bank" },
    { aba: "211380470", name: "Bedford Va Federal Cr. Union" },
    { aba: "211380483", name: "Hanscom Federal C U" },
    { aba: "211380551", name: "Beverly Mun Fed Cr Union" },
    { aba: "211380580", name: "Billerica Mun Cu" },
    { aba: "211381314", name: "Merrimack Valley Credit Union" },
    { aba: "211381327", name: "Plymouth Cty Tchrs Fed Cu" },
    { aba: "211381369", name: "Sharon & Crescent United Cu" },
    { aba: "211381372", name: "Harborone Bank" },
    { aba: "211381398", name: "Sharon & Crescent United Cu" },
    { aba: "211381437", name: "Sharon & Crescent United Cu" },
    { aba: "211381440", name: "Merrimack Valley Credit Union" },
    { aba: "211381518", name: "Brookline Municipal Credit Union" },
    { aba: "211381563", name: "Burlington Mun Efcu" },
    { aba: "211381592", name: "Mills42 Federal Credit Union" },
    { aba: "211381631", name: "Cambridge Ter Fed C U" },
    { aba: "211381660", name: "Cambridge Firefighters Fcu" },
    { aba: "211381673", name: "Naveo Credit Union" },
    { aba: "211381712", name: "Rtn Federal Credit Union" },
    { aba: "211381738", name: "Harvard Univ Empl Cu" },
    { aba: "211381754", name: "Mass. Inst. Of Tech. Fed. C. U." },
    { aba: "211381783", name: "Direct Federal Credit Union" },
    { aba: "211381958", name: "Chelsea Empl Fcu" },
    { aba: "211381990", name: "Metro Credit Union" },
    { aba: "211382203", name: "Danvers Mun Fcu" },
    { aba: "211382232", name: "Dedham Town Empl Fcu" },
    { aba: "211382481", name: "Members Plus Credit Union" },
    { aba: "211382591", name: "Fall River Municipal Credit Union" },
    { aba: "211382627", name: "St Anthony Of Padua Fed Cu" },
    { aba: "211382630", name: "Southcoast Fcu" },
    { aba: "211382669", name: "Notre Dame Community Fcu" },
    { aba: "211382672", name: "Fall River Municipal Credit Union" },
    { aba: "211382685", name: "Westport Fed Credit Union" },
    { aba: "211382724", name: "Santo Christo Federal C.u." },
    { aba: "211382737", name: "St Anne's Credit Union" },
    { aba: "211382740", name: "St Anthony Of Padua Fed Cu" },
    { aba: "211382766", name: "Southern Mass Credit Union" },
    { aba: "211382779", name: "St Michael's (fall River) F.c.u." },
    { aba: "211382850", name: "I. C. Federal Credit Union" },
    { aba: "211382863", name: "Workers' Credit Union" },
    { aba: "211382902", name: "Gfa Fcu" },
    { aba: "211382931", name: "Workers Credit Union" },
    { aba: "211382986", name: "Metrowest Community Federal Cu" },
    { aba: "211383066", name: "Gfa Fcu" },
    { aba: "211383286", name: "Merrimack Valley Fcu" },
    { aba: "211383325", name: "Haverhill Fire Dept.c.u." },
    { aba: "211383341", name: "Htm Credit Union" },
    { aba: "211383383", name: "Liberty Bay Credit Union" },
    { aba: "211383480", name: "Rtn Federal Credit Union" },
    { aba: "211383590", name: "Nesc Federal Cr Union" },
    { aba: "211383723", name: "Leominster Employees Fcu" },
    { aba: "211383736", name: "Leominster Credit Union" },
    { aba: "211383752", name: "Workers Credit Union" },
    { aba: "211383778", name: "Rtn Federal Credit Union" },
    { aba: "211383781", name: "Lexington Town Emp Fcu" },
    { aba: "211383846", name: "Jeanne D Arc Credit Union" },
    { aba: "211383862", name: "Lowell Fire Fighters Cu" },
    { aba: "211383875", name: "Lowell Municipal Employees Fcu" },
    { aba: "211383901", name: "Align Credit Union" },
    { aba: "211383956", name: "Brotherhood Credit Union" },
    { aba: "211383972", name: "Community Cr Un Of Lynn" },
    { aba: "211383985", name: "River Works Credit Union" },
    { aba: "211384010", name: "Lynn Firemens Fed Cr Union" },
    { aba: "211384065", name: "Lynn Police Credit Union" },
    { aba: "211384078", name: "Massachusetts Postal Employees Cu" },
    { aba: "211384117", name: "St. Jean Credit Union" },
    { aba: "211384146", name: "Malden Federal Credit Union" },
    { aba: "211384162", name: "Usalliance Federal Credit Union" },
    { aba: "211384175", name: "Manchester Fcu" },
    { aba: "211384214", name: "St. Mary's C U" },
    { aba: "211384382", name: "Stoneham Muni Emp Credit Union" },
    { aba: "211384395", name: "Align Credit Union" },
    { aba: "211384405", name: "Methuen Fcu" },
    { aba: "211384434", name: "Millbury Federal Credit Union" },
    { aba: "211384586", name: "First Citizens Federal Credit Union" },
    { aba: "211384641", name: "Commonwealth" },
    { aba: "211384667", name: "New Bedford Cu" },
    { aba: "211384706", name: "Southern Mass Credit Union" },
    { aba: "211384719", name: "Coastal New England Fcu" },
    { aba: "211384722", name: "St Anthony New Bedford F. C. U." },
    { aba: "211384861", name: "Norfolk Comm Fed C U" },
    { aba: "211384913", name: "Align Credit Union" },
    { aba: "211384926", name: "Merrimack Valley Fcu" },
    { aba: "211384939", name: "Merrimack Valley Fcu" },
    { aba: "211384942", name: "Andover Federal Credit Un" },
    { aba: "211384955", name: "Homefield Credit Union" },
    { aba: "211385035", name: "City Of Boston Credit Un" },
    { aba: "211385132", name: "Luso American Cu" },
    { aba: "211385161", name: "Peabody Mun Fed Cr Union" },
    { aba: "211385297", name: "Quincy Credit Union" },
    { aba: "211385336", name: "Rah Fed C U" },
    { aba: "211385349", name: "Harborone Bank" },
    { aba: "211385378", name: "Revere Municipal E.f.c.u." },
    { aba: "211385381", name: "St. Jeans Credit Union" },
    { aba: "211385488", name: "Greater Salem Emp Fed Cu" },
    { aba: "211385514", name: "St. Jeans Credit Union" },
    { aba: "211385527", name: "Rtn Federal Credit Union" },
    { aba: "211385556", name: "Sharon & Crescent United Cu" },
    { aba: "211385569", name: "Shrewsbury Federal Credit Union" },
    { aba: "211385572", name: "Somerset Federal Credit Union" },
    { aba: "211385640", name: "Health Alliance Federal Credit Union" },
    { aba: "211385653", name: "Somerville Mun Fed Cr Un" },
    { aba: "211385705", name: "Southbridge Cu" },
    { aba: "211386005", name: "Stoneham Mun Empl Fc" },
    { aba: "211386034", name: "Lincoln Sudbury Town Efcu" },
    { aba: "211386047", name: "St Dominics Fed C U" },
    { aba: "211386115", name: "Taunton Federal Cr Union" },
    { aba: "211386144", name: "Tewksbury Federal Credit Union" },
    { aba: "211386238", name: "Somerville School E.f.c.u." },
    { aba: "211386445", name: "Rtn Federal Credit Union" },
    { aba: "211386597", name: "Webster First Federal Credit Union" },
    { aba: "211386610", name: "Wellesley Mun Emp Fed C U" },
    { aba: "211386652", name: "Webster First Federal Cu" },
    { aba: "211386759", name: "Westport Fed Credit Union" },
    { aba: "211386801", name: "Hanscom Federal Cu" },
    { aba: "211386953", name: "Common Trust Federal C U" },
    { aba: "211386966", name: "Middlesex Essex Postal Emp" },
    { aba: "211387017", name: "Allcom Credit Union" },
    { aba: "211387114", name: "St. Vincents Hosp C.u." },
    { aba: "211387127", name: "Worcester Fire Dept C.u." },
    { aba: "211387130", name: "Worcester Police Dept. Cr. Union" },
    { aba: "211387143", name: "Worcester Credit Union" },
    { aba: "211387169", name: "Central One Federal Credit Union" },
    { aba: "211387185", name: "Allcom Credit Union" },
    { aba: "211391650", name: "Alloya Corporate Fcu" },
    { aba: "211391689", name: "Usalliance Federal Credit Union" },
    { aba: "211391773", name: "Eastern Corp Fcu" },
    { aba: "211391825", name: "Digital Federal Credit Union" },
    { aba: "211391922", name: "Marblehead Municipal Fcu" },
    { aba: "211392031", name: "Rtn Federal Credit Union" },
    { aba: "211392060", name: "Southcoast Federal Credit Union" },
    { aba: "211392099", name: "Reading Ma. Town Emp. F. C .u." },
    { aba: "211392109", name: "New England Teamsters Fcu" },
    { aba: "211470018", name: "First Seacoast Bank" },
    { aba: "211470115", name: "People's United Bank, N.a." },
    { aba: "211470186", name: "People's United Bank, N.a." },
    { aba: "211470225", name: "Merrimack County Svgs Bank" },
    { aba: "211470238", name: "Profile Bank" },
    { aba: "211470319", name: "Bank Of America N.a." },
    { aba: "211472977", name: "Salem Co-op Bk" },
    { aba: "211474687", name: "Piscataqua Savings Bank" },
    { aba: "211475000", name: "First Republic Bank" },
    { aba: "211489083", name: "New Hampshire Federal C U" },
    { aba: "211489229", name: "Service Federal Credit Union" },
    { aba: "211489407", name: "Members First Credit Union" },
    { aba: "211489449", name: "New Hampshire Postal Cu" },
    { aba: "211489478", name: "Bellwether Community Credit Union" },
    { aba: "211489494", name: "Granite State C U" },
    { aba: "211489591", name: "Triangle Credit Union" },
    { aba: "211489601", name: "Triangle C.u." },
    { aba: "211489630", name: "Northeast Credit Union" },
    { aba: "211489656", name: "Service Federal Credit Union" },
    { aba: "211489669", name: "Holy Rosary Cu" },
    { aba: "211489698", name: "Service Federal Credit Union" },
    { aba: "211492478", name: "Gfa Fcu" },
    { aba: "211572615", name: "Berkshire Bank" },
    { aba: "211573151", name: "The Peoples Credit Union" },
    { aba: "211573177", name: "Navigant Credit Union" },
    { aba: "211573180", name: "Woodlawn Fcu" },
    { aba: "211573229", name: "Harborone Bank" },
    { aba: "211573290", name: "Navigant Credit Union" },
    { aba: "211573313", name: "Westerly Community Cu" },
    { aba: "211573326", name: "Ocean State Credit Union" },
    { aba: "211573407", name: "Wave Federal Credit Union" },
    { aba: "211574613", name: "Citizens Bank Na" },
    { aba: "211574642", name: "Banknewport" },
    { aba: "211574833", name: "Ethic" },
    { aba: "211574862", name: "Home Loan Investment Bank,f.s.b." },
    { aba: "211589828", name: "Navigant Credit Union" },
    { aba: "211589899", name: "Cranston Mun Empl Cu" },
    { aba: "211589996", name: "Community & Teachers Fcu" },
    { aba: "211590150", name: "The Peoples Credit Union" },
    { aba: "211590176", name: "Rhode Island Credit Union" },
    { aba: "211590192", name: "Navigant Credit Union" },
    { aba: "211590257", name: "Postal Employees Regional F.c.u." },
    { aba: "211590260", name: "Pawtucket Cu" },
    { aba: "211590273", name: "Pawtucket Municipal Employees F.c.u." },
    { aba: "211590383", name: "Navigant Credit Union" },
    { aba: "211590464", name: "Postal Government Employees Fcu" },
    { aba: "211590493", name: "Rhode Island Credit Union" },
    { aba: "211590655", name: "Navigant Credit Union" },
    { aba: "211590671", name: "Greenwood Credit Union" },
    { aba: "211590684", name: "Navigant Credit Union" },
    { aba: "211590736", name: "Harborone Bank" },
    { aba: "211590749", name: "Wave Federal Credit Union" },
    { aba: "211590817", name: "Wave Federal Credit Union" },
    { aba: "211590833", name: "Blackstone River Fcu" },
    { aba: "211672476", name: "Brattleboro Savings & Loan" },
    { aba: "211672531", name: "Key Bank" },
    { aba: "211672609", name: "The Bank Of Bennington" },
    { aba: "211672683", name: "Northfield Savings Bank" },
    { aba: "211672722", name: "People's United Bank, N.a." },
    { aba: "211674775", name: "Passumpsic Savings Bank" },
    { aba: "211674788", name: "Wells River Savings Bank" },
    { aba: "211690908", name: "Central Vt. Med. Ctr." },
    { aba: "211690911", name: "Members Advantage Community Cu" },
    { aba: "211690924", name: "United Comunity Credit Union" },
    { aba: "211690953", name: "Members 1st Credit Union" },
    { aba: "211691004", name: "North Country Fed C.u." },
    { aba: "211691017", name: "Green Mountain Credit Union" },
    { aba: "211691062", name: "Vermont Federal Credit Union" },
    { aba: "211691114", name: "One Credit Union" },
    { aba: "211691127", name: "New England Federal Credit Union" },
    { aba: "211691172", name: "Granite Hills Credit Union" },
    { aba: "211691185", name: "Vermont State Emp Cu" },
    { aba: "211691224", name: "Northeast Schools & Hospital Cu" },
    { aba: "211691237", name: "Orlex Govt Emp Cu" },
    { aba: "211691266", name: "River Valley Credit Union" },
    { aba: "211691279", name: "White River Cu" },
    { aba: "211691318", name: "Heritage Family Cr. Union" },
    { aba: "211691321", name: "Green Mountain Credit Union" },
    { aba: "211691334", name: "Credit Union Of Vermont" },
    { aba: "211691428", name: "Bryant Federal Credit Union" },
    { aba: "211691444", name: "Members Advantage Community Cr Un" },
    { aba: "211691457", name: "Northern Lights Fcu" },
    { aba: "211691541", name: "Vermont Va Federal Credit Union" },
    { aba: "211691583", name: "Members Advantage Community Cr. Un." },
    { aba: "211770093", name: "Savings Bank Of Walpole" },
    { aba: "211770132", name: "Woodsville Guaranty" },
    { aba: "211770145", name: "Sugar River Bank" },
    { aba: "211770161", name: "People's United Bank, N.a." },
    { aba: "211770174", name: "Franklin Savings Bank" },
    { aba: "211770190", name: "Claremont Savings Bank" },
    { aba: "211770200", name: "Bar Harbor Bank & Trust" },
    { aba: "211770213", name: "Mascoma Savings Bank" },
    { aba: "211770271", name: "Bank Of New Hampshire" },
    { aba: "211772936", name: "Meredith Village Savings Bank" },
    { aba: "211789000", name: "Service Federal Credit Union" },
    { aba: "211789013", name: "Northeast Credit Union" },
    { aba: "211789026", name: "Freudenberg-nok Emp. C. U." },
    { aba: "211789055", name: "N.h. Community Fe Cr Union" },
    { aba: "211789288", name: "Service Federal Credit Union" },
    { aba: "211789291", name: "Gfa Federal Credit Union" },
    { aba: "211789301", name: "Precision Federal Credit Union" },
    { aba: "211789314", name: "Ngm Employees Federal Credit Union" },
    { aba: "211870142", name: "Pittsfield Co-op Bank" },
    { aba: "211870281", name: "Greenfield Co-op Bank" },
    { aba: "211870799", name: "Greenfield Savings Bank" },
    { aba: "211870812", name: "Berkshire Bank" },
    { aba: "211870870", name: "Lee Bank" },
    { aba: "211870935", name: "Easthampton Savings Bank" },
    { aba: "211870977", name: "Berkshire Bank" },
    { aba: "211870980", name: "Country Bank For Savings" },
    { aba: "211871015", name: "Mountainone Bank" },
    { aba: "211871154", name: "People's United Bank, N.a." },
    { aba: "211871219", name: "Monson Savings Bank" },
    { aba: "211871523", name: "Adams Community Bank" },
    { aba: "211871604", name: "Westfield Bank" },
    { aba: "211871688", name: "Florence Bank" },
    { aba: "211871691", name: "Berkshire Bank" },
    { aba: "211871714", name: "North Brookfield Savings Bank" },
    { aba: "211871772", name: "Peoplesbank" },
    { aba: "211871837", name: "Florence Savings Bank" },
    { aba: "211871866", name: "Westfield Bank" },
    { aba: "211872027", name: "Peoples United Bank, Na" },
    { aba: "211872331", name: "Greenfield Cooperative Bank" },
    { aba: "211872946", name: "Hoosac Bank" },
    { aba: "211872959", name: "Berkshire Bank" },
    { aba: "211873686", name: "Adams Community Bank" },
    { aba: "211880271", name: "Umassfive College Fcu" },
    { aba: "211882059", name: "Polish National Credit Union" },
    { aba: "211882091", name: "Polish National C U" },
    { aba: "211882143", name: "Aldenville Cu" },
    { aba: "211883223", name: "Franklin 1st Fcu" },
    { aba: "211883414", name: "Holyoke Cu" },
    { aba: "211883427", name: "Holyoke Postal Cu" },
    { aba: "211883922", name: "Luso Fed Cr Un" },
    { aba: "211885001", name: "Umassfive College Federal Cu" },
    { aba: "211885234", name: "Mycom Federal Credit Union" },
    { aba: "211885250", name: "Greylock Federal Credit Union" },
    { aba: "211885263", name: "Credit Union Of The Berkshires" },
    { aba: "211885810", name: "Premier Source Federal Credit Union" },
    { aba: "211885836", name: "Massmutual Federal C. U." },
    { aba: "211885852", name: "Polish National C U" },
    { aba: "211885917", name: "Arrha Credit Union" },
    { aba: "211885920", name: "Greater Springfield Cu" },
    { aba: "211885946", name: "Pioneer Valley Fcu" },
    { aba: "211885988", name: "Freedom Credit Union" },
    { aba: "211886275", name: "Premier Source Federal Credit Union" },
    { aba: "211886288", name: "Freedon Credti Union" },
    { aba: "211886712", name: "Pioneer Valley Fcu" },
    { aba: "211970055", name: "Webster Bank" },
    { aba: "211970194", name: "Webster Bank" },
    { aba: "211973049", name: "People's United Bank, N.a." },
    { aba: "211977171", name: "Cencap Federal Credit Union" },
    { aba: "211977197", name: "Conn St Empl Cu Inc" },
    { aba: "211977207", name: "State Police Cu Inc" },
    { aba: "211977278", name: "America's First Network Credit Union" },
    { aba: "211977281", name: "Hartford Federal Credit Union" },
    { aba: "211977317", name: "Hartford Firefighters Fcu" },
    { aba: "211977320", name: "Wellness Federal Credit Union" },
    { aba: "211977333", name: "Cencap Federal Credit Union" },
    { aba: "211977346", name: "Greater Hartford Police Fcu" },
    { aba: "211977359", name: "American Eagle Fcu" },
    { aba: "211977362", name: "Nutmeg State Financial Credit Union" },
    { aba: "211977414", name: "Metropolitan Dist Emp C U" },
    { aba: "211977498", name: "Ukrainian Selfreliance Fcu" },
    { aba: "211977524", name: "Hartford Federal Credit Union" },
    { aba: "221172186", name: "People's United Bank, N.a." },
    { aba: "221172212", name: "First County Bank" },
    { aba: "221172238", name: "Savings Bank Of Danbury" },
    { aba: "221172241", name: "Union Savings Bank" },
    { aba: "221172267", name: "Fairfield County Svgs Bank" },
    { aba: "221172270", name: "Fairfield County Bank" },
    { aba: "221172296", name: "Newtown Savings Bank" },
    { aba: "221172513", name: "Bank Of America, N.a." },
    { aba: "221172610", name: "Citibank Na" },
    { aba: "221173541", name: "Bridgeport P O Fcu" },
    { aba: "221173570", name: "Bridgeport City Emp. F.c.u." },
    { aba: "221173622", name: "Bridgeport Police Fcu" },
    { aba: "221173635", name: "Norwalk Telephone Emp Fcu" },
    { aba: "221173648", name: "Healthcare Financial Federal Cu" },
    { aba: "221173651", name: "G E Employees Fcu" },
    { aba: "221173774", name: "St. Vincents Med Fed C.u." },
    { aba: "221173868", name: "Cornerstone Community Cu" },
    { aba: "221173884", name: "West. Conn. Fed. C.u." },
    { aba: "221173981", name: "General Electric Employees Fcu" },
    { aba: "221174045", name: "Gha Fcu" },
    { aba: "221174058", name: "Greenwich Mun Empl" },
    { aba: "221174074", name: "Members Credit Union" },
    { aba: "221174375", name: "Pitney Bowes Empl. F.c.u." },
    { aba: "221174391", name: "Sound Federal Credit Union" },
    { aba: "221174401", name: "Stamford Postal Emp. F.c.u." },
    { aba: "221174414", name: "Cornerstone Community Credit Union" },
    { aba: "221174427", name: "Stamford Telephone Emp. F.c.u." },
    { aba: "221174498", name: "Tri-town Teachers Fed Cu" },
    { aba: "221174508", name: "Mutual Security C.u." },
    { aba: "221180806", name: "Sikorsky Financial Credit Union" },
    { aba: "221180822", name: "Sikorsky Financial Cr. Union, Inc." },
    { aba: "221182781", name: "G E Employees Fcu" },
    { aba: "221182804", name: "Mc Kesson Federal Credit Union" },
    { aba: "221182888", name: "Merritt Fcu" },
    { aba: "221183159", name: "Soundview Fcu" },
    { aba: "221183227", name: "Consolidated Controls Corp. Fcu" },
    { aba: "221183256", name: "Norwalk Hosp Cu Inc" },
    { aba: "221183308", name: "Stamford Healthcare Credit Union" },
    { aba: "221183311", name: "First New England Fcu" },
    { aba: "221270075", name: "Investors Bank" },
    { aba: "221270101", name: "New York Community Bank" },
    { aba: "221270114", name: "Bayonne Community Bank" },
    { aba: "221270198", name: "Bogota Savings Bank" },
    { aba: "221270211", name: "Somerset Savings Bank Sla" },
    { aba: "221270240", name: "United Roosevelt Savings Bank" },
    { aba: "221270266", name: "Peapack-gladstone Bank" },
    { aba: "221270282", name: "Kearny Bank" },
    { aba: "221270318", name: "Valley National Bank" },
    { aba: "221270415", name: "Nve Bank" },
    { aba: "221270525", name: "Gsl Savings Bank" },
    { aba: "221270541", name: "Valley National Bank" },
    { aba: "221270651", name: "Kearny Bank" },
    { aba: "221270677", name: "Schuyler Savings Bank" },
    { aba: "221270680", name: "Lincoln 1st Bank" },
    { aba: "221270703", name: "Valley National Bank" },
    { aba: "221270758", name: "Santander Bank" },
    { aba: "221270790", name: "Gibraltar Savings Bank" },
    { aba: "221270868", name: "New York Community Bank" },
    { aba: "221270910", name: "Magyar Bank" },
    { aba: "221271029", name: "New York Community Bank" },
    { aba: "221271058", name: "Haven Savings Bank" },
    { aba: "221271090", name: "Valley National Bank" },
    { aba: "221271113", name: "Ocwen Federal Bank Fsb" },
    { aba: "221271197", name: "The Provident Bank" },
    { aba: "221271265", name: "Santander Bank, N.a." },
    { aba: "221271333", name: "Columbia Bank" },
    { aba: "221271359", name: "Blue Foundry Bank" },
    { aba: "221271414", name: "Investors Bank" },
    { aba: "221271456", name: "Santander Bank" },
    { aba: "221271469", name: "Valley National Bank" },
    { aba: "221271485", name: "Glen Rock Savings Bank" },
    { aba: "221271935", name: "Columbia Bank" },
    { aba: "221271948", name: "Freehold Savings Bank" },
    { aba: "221271951", name: "Spencer Savings Bank, Sla" },
    { aba: "221271964", name: "Glen Rock Savings Bank" },
    { aba: "221271993", name: "The Provident Bank" },
    { aba: "221272028", name: "Manasquan Bank" },
    { aba: "221272031", name: "Investors Bank" },
    { aba: "221272044", name: "Kearny Bank" },
    { aba: "221272125", name: "Spencer Savings Bank, Sla" },
    { aba: "221272154", name: "The Provident Bank" },
    { aba: "221272167", name: "Manasquan Bank" },
    { aba: "221272303", name: "Provident Bank" },
    { aba: "221272316", name: "Manufacturers & Traders Trust Co" },
    { aba: "221272329", name: "Union County Savings Bank" },
    { aba: "221272332", name: "Santander Bank" },
    { aba: "221272361", name: "Santander Bank" },
    { aba: "221272374", name: "Manufacturers & Traders Trust Co" },
    { aba: "221272439", name: "Rsi Bank" },
    { aba: "221272565", name: "Valley National Bank" },
    { aba: "221272578", name: "Connectone Bank" },
    { aba: "221273056", name: "Harrison Police & Firemen F.c.u." },
    { aba: "221273140", name: "Hoboken School Empl F.c.u." },
    { aba: "221274521", name: "Monmouth County Postal Empl C.u." },
    { aba: "221274725", name: "Essex County Teachers Fcu" },
    { aba: "221274783", name: "Pinnacle Federal Credit Union" },
    { aba: "221274916", name: "Mid State Fcu" },
    { aba: "221274932", name: "L'oreal Usa Federal Credit Union" },
    { aba: "221274958", name: "Clifton Nj Postal Employees F.c.u." },
    { aba: "221275025", name: "North Jersey Federal C.u." },
    { aba: "221275070", name: "Visions Federal Credit Union" },
    { aba: "221275177", name: "Jersey Central F C U" },
    { aba: "221275180", name: "Nj Gateway Federal Credit Union" },
    { aba: "221275216", name: "Picatinny Federal Credit Union" },
    { aba: "221275326", name: "E Orange V A H Fcu" },
    { aba: "221275410", name: "M&c Menlo Park Fcu" },
    { aba: "221275481", name: "Elizabeth, Nj Firemen's F.c.u." },
    { aba: "221275533", name: "Seaport Federal Credit Union" },
    { aba: "221275643", name: "Wakefern Federal Credit Union" },
    { aba: "221275708", name: "Visions Federal Credit Union" },
    { aba: "221275711", name: "Unilever Fcu" },
    { aba: "221275779", name: "First Atlantic Fcu" },
    { aba: "221275876", name: "Greater Alliance Fcu" },
    { aba: "221276011", name: "United Teletech Financial Fcu" },
    { aba: "221276037", name: "Faa Eastern Region Fcu" },
    { aba: "221276118", name: "Liberty Svgs Fed Cu" },
    { aba: "221276121", name: "Path Fcu" },
    { aba: "221276176", name: "Jersey City Firemen F.c.u." },
    { aba: "221276244", name: "New Brunswick Postal F.c.u." },
    { aba: "221276257", name: "Ocnac No. 1. Federal Credit Union" },
    { aba: "221276370", name: "Atlantic Federal Credit Union" },
    { aba: "221276480", name: "Motion Federal Credit Union" },
    { aba: "221276503", name: "Gaf Linden Emp Fcu" },
    { aba: "221276545", name: "Community Financial Services Fcu" },
    { aba: "221276613", name: "Fairleigh Dickinson Univ. Fcu" },
    { aba: "221276639", name: "Manville Area F.c.u." },
    { aba: "221276697", name: "Riegel Federal Credit Union" },
    { aba: "221276710", name: "Financial Resources Fcu" },
    { aba: "221276723", name: "Proponent Fcu" },
    { aba: "221276781", name: "Garden Savings Federal Credit Union" },
    { aba: "221276817", name: "Advanced Financial Fcu" },
    { aba: "221276888", name: "Garden Savings Federal Credit Union" },
    { aba: "221276901", name: "Middlesex County Nj Employees F.c.u." },
    { aba: "221276927", name: "New Brunswick Postal Fcu" },
    { aba: "221276956", name: "Public Service Fcu" },
    { aba: "221276998", name: "Rutgers Federal Credit Union" },
    { aba: "221277007", name: "B-m S Federal Credit Union" },
    { aba: "221277133", name: "Garden Savings Federal Credit Union" },
    { aba: "221277159", name: "Federal Employees Newark F.c.u." },
    { aba: "221277308", name: "Newark Firemen Fcu" },
    { aba: "221277324", name: "Newark Police Federal C.u." },
    { aba: "221277337", name: "Newark Post Office Employee C.u." },
    { aba: "221277379", name: "Newark Board Of Education Empl C.u." },
    { aba: "221277599", name: "Proponent Federal Credit Union" },
    { aba: "221277735", name: "Parlin Dupont Employees F.c.u." },
    { aba: "221277748", name: "Proponent Federal Credit Union" },
    { aba: "221277764", name: "Nova Va Fcu" },
    { aba: "221277777", name: "Usalliance Federal Credit Union" },
    { aba: "221277816", name: "Union Building Trades Fcu" },
    { aba: "221277971", name: "United Poles Perth Amboy F.c.u." },
    { aba: "221277997", name: "Baker Federal Credit Union" },
    { aba: "221278035", name: "Irco Community Fcu" },
    { aba: "221278129", name: "Central Jersey Federal Credit Union" },
    { aba: "221278158", name: "Plainfield Cu" },
    { aba: "221278174", name: "Southern Middlesex Cty Teachers Fcu" },
    { aba: "221278213", name: "Merck Employees F.c.u." },
    { aba: "221278271", name: "H E Telephone Federal Cu" },
    { aba: "221278307", name: "County Educators Fcu" },
    { aba: "221278323", name: "Rutherford Postal Empl F.c.u." },
    { aba: "221278420", name: "Financial Resources F.c.u." },
    { aba: "221278446", name: "Prime Federal Credit Union" },
    { aba: "221278488", name: "Raritan Bay Fcu" },
    { aba: "221278556", name: "Novartis F.c.u." },
    { aba: "221278608", name: "Teaneck Federal Credit Union" },
    { aba: "221278721", name: "New York Community Bank" },
    { aba: "221278747", name: "United Teletech F.c.u." },
    { aba: "221278938", name: "Advanced Financial Fcu" },
    { aba: "221278954", name: "Garden Savings Federal Credit Union" },
    { aba: "221279021", name: "Pinnacle Federal Credit Union" },
    { aba: "221283130", name: "Unify Financial Federal Credit Union" },
    { aba: "221283347", name: "Lusitania Savings Bank,fsb" },
    { aba: "221283402", name: "United Investors Fcu" },
    { aba: "221283428", name: "New Community F.c.u." },
    { aba: "221283444", name: "Picatinny Federal Credit Union" },
    { aba: "221283457", name: "Dow Jones Employees F.c.u." },
    { aba: "221283512", name: "Affinity Federal Credit Union" },
    { aba: "221370030", name: "Citizens Bank Na" },
    { aba: "221370108", name: "M & T Bank" },
    { aba: "221370399", name: "Elmira Savings Bank, Fsb" },
    { aba: "221370467", name: "The Bank Of Greene County" },
    { aba: "221370496", name: "Fulton Savings Bank" },
    { aba: "221370616", name: "Keybank National Association" },
    { aba: "221370632", name: "Manufacturers & Traders Trust Co." },
    { aba: "221370878", name: "Community Bank, Na" },
    { aba: "221370894", name: "Pathfinder Bank" },
    { aba: "221370904", name: "Nbt Bank Na" },
    { aba: "221371071", name: "Berkshire Bank" },
    { aba: "221371123", name: "The North Country Savings Bank" },
    { aba: "221371165", name: "M & T Bank" },
    { aba: "221371194", name: "Generations Bank" },
    { aba: "221371356", name: "Watertown Savings Bank" },
    { aba: "221371372", name: "Pioneer Savings Bank" },
    { aba: "221371424", name: "Trustco Bank" },
    { aba: "221371534", name: "Trustco Bank" },
    { aba: "221371550", name: "North Country Savings Bank" },
    { aba: "221371563", name: "Carthage Federal S L Assoc" },
    { aba: "221371589", name: "Nbt Bank" },
    { aba: "221371592", name: "Gouverneur Savings & Loan Assoc." },
    { aba: "221371628", name: "Massena Savings & Loan" },
    { aba: "221371644", name: "Community Bank, Na" },
    { aba: "221371709", name: "Adirondack Bank" },
    { aba: "221371741", name: "Geddes Federal Savings & Loan" },
    { aba: "221371770", name: "Seneca Savings" },
    { aba: "221371822", name: "Hsbc Bank, Usa" },
    { aba: "221372274", name: "Community Bank, N.a." },
    { aba: "221373202", name: "Albany Firemen's Fcu" },
    { aba: "221373273", name: "Capital Communications F.c.u." },
    { aba: "221373338", name: "Community Resource Fcu" },
    { aba: "221373383", name: "State Employees Federal Credit Union" },
    { aba: "221373422", name: "Mct Federal Credit Union" },
    { aba: "221373451", name: "Auburn Community Federal Cr Un" },
    { aba: "221373707", name: "Ghs Federal Credit Union" },
    { aba: "221373710", name: "Horizons Federal Credit Union" },
    { aba: "221373736", name: "Visions Federal Credit Union" },
    { aba: "221374175", name: "Capital Communications Fcu" },
    { aba: "221374984", name: "Tct Federal Credit Union" },
    { aba: "221375093", name: "Capital Communications Fcu" },
    { aba: "221375116", name: "Great Meadow F.c.u." },
    { aba: "221375132", name: "Hudson River Community Cu" },
    { aba: "221375174", name: "Dannemora Fcu" },
    { aba: "221375200", name: "United Neighbors Fcu" },
    { aba: "221375226", name: "United Neighbors Fcu" },
    { aba: "221375378", name: "Visions Federal Credit Union" },
    { aba: "221375543", name: "Chen-del-o Federal Credit Union" },
    { aba: "221375682", name: "Coopers Cave Fcu" },
    { aba: "221375695", name: "Parks Heritage Federal Credit Union" },
    { aba: "221375721", name: "Nmp Northern Area Fcu" },
    { aba: "221375802", name: "Americu" },
    { aba: "221375925", name: "Hoosick Fcu" },
    { aba: "221375941", name: "Hoosick Federal Credit Union" },
    { aba: "221376005", name: "Remington Fcu" },
    { aba: "221376186", name: "First Choice Financial Fcu" },
    { aba: "221376199", name: "Upstate Telco Fcu" },
    { aba: "221376296", name: "Edge Fcu" },
    { aba: "221376429", name: "North Franklin Fcu" },
    { aba: "221376539", name: "Seacomm Fed C U" },
    { aba: "221376542", name: "Twin Rivers Federal Credit Union" },
    { aba: "221376872", name: "Syracuse Fire Dep Employee Fcu" },
    { aba: "221376911", name: "Special Metals Federal Credit Union" },
    { aba: "221376924", name: "Utica Dist Telephone Employees Fcu" },
    { aba: "221376937", name: "Utica Gas & Electric Empl. Fcu" },
    { aba: "221378838", name: "Norwich Telops F.c.u." },
    { aba: "221378896", name: "Ed-med Federal Credit Union" },
    { aba: "221378906", name: "St Lawrence F.c.u." },
    { aba: "221378993", name: "Compass Federal Credit Union" },
    { aba: "221379060", name: "Oswego Teachers Emp Fcu" },
    { aba: "221379125", name: "Peru Federal Credit Union" },
    { aba: "221379235", name: "Plattsburgh City School District Fcu" },
    { aba: "221379251", name: "Ufirst Fcu" },
    { aba: "221379536", name: "Rome Federal Credit Union" },
    { aba: "221379581", name: "Rome Teachers Fcu" },
    { aba: "221379659", name: "Saratoga's Community Fcu" },
    { aba: "221379688", name: "Capital Communications Fcu" },
    { aba: "221379730", name: "Stratton Ang F.c.u." },
    { aba: "221379756", name: "Mohawk Progressive F.c.u." },
    { aba: "221379769", name: "Sunmark Federal Credit Union" },
    { aba: "221379785", name: "First New York Fcu" },
    { aba: "221379808", name: "Schenectady County Employees Fcu" },
    { aba: "221379824", name: "Sunmark Credit Union" },
    { aba: "221379895", name: "Access Federal Credit Union" },
    { aba: "221379905", name: "Sidney Fcu" },
    { aba: "221379918", name: "Acmg Federal Credit Union" },
    { aba: "221380075", name: "Countryside Fed. Credit Union" },
    { aba: "221380114", name: "Berkshire Bank" },
    { aba: "221380127", name: "Empower Fcu" },
    { aba: "221380143", name: "Crouse Hinds Employees F.c.u." },
    { aba: "221380172", name: "Core Federal Credit Union" },
    { aba: "221380347", name: "Empower Federal Credit Union" },
    { aba: "221380363", name: "The Summit Federal C.u." },
    { aba: "221380376", name: "Syracuse Postal Cu" },
    { aba: "221380392", name: "The Summit Federal C.u." },
    { aba: "221380457", name: "Trailnorth Federal Credit Union" },
    { aba: "221380460", name: "Capital Communications Fcu" },
    { aba: "221380473", name: "Norton Troy Employees Credit Union" },
    { aba: "221380570", name: "Adirondack Regional Fcu" },
    { aba: "221380651", name: "Mohawk Valley Fcu" },
    { aba: "221380677", name: "Oneida County Federal Credit Union" },
    { aba: "221380716", name: "Umico Federal Credit Union" },
    { aba: "221380761", name: "Gpo Federal Credit Union" },
    { aba: "221380787", name: "Gpo Federal Credit Union" },
    { aba: "221380790", name: "First Source Federal Credit Union" },
    { aba: "221380826", name: "Coxsackie Corr Emp Fcu" },
    { aba: "221380842", name: "Westar Federal Credit Union" },
    { aba: "221380868", name: "Capital Communications Fcu" },
    { aba: "221380936", name: "Northern Credit Union" },
    { aba: "221380949", name: "State Cs Employees Fcu" },
    { aba: "221381003", name: "Finger Lakes Health Care F.c.u." },
    { aba: "221381304", name: "Secny Fcu" },
    { aba: "221381456", name: "Solutions Fcu" },
    { aba: "221381498", name: "First Heritage Federal Credit Union" },
    { aba: "221381540", name: "Cfcu Community Credit Union" },
    { aba: "221381715", name: "Alloya Corporate Fcu" },
    { aba: "221381867", name: "Alternative Fed Cred Union" },
    { aba: "221381919", name: "Tompkins Empl Fcu" },
    { aba: "221381935", name: "Syracuse Cooperative F.c.u." },
    { aba: "221382031", name: "Money Fcu" },
    { aba: "221382167", name: "Leatherstocking Region Fcu" },
    { aba: "221382358", name: "School Systems Federal Credit Union" },
    { aba: "221382361", name: "Tri-lakes Federal Credit Union" },
    { aba: "221382468", name: "Columbia-greene Fcu" },
    { aba: "221471094", name: "New York Community Bank" },
    { aba: "221471104", name: "New York Community Bank" },
    { aba: "221471227", name: "Capital One, N.a." },
    { aba: "221471858", name: "Capital One, N.a." },
    { aba: "221471861", name: "Sterling National Bank" },
    { aba: "221472776", name: "Capital One, N.a." },
    { aba: "221472792", name: "Maspeth Fed. Sav & Ln. Assoc." },
    { aba: "221472815", name: "Sterling National Bank" },
    { aba: "221473652", name: "Bethpage Federal Credit Union" },
    { aba: "221475320", name: "Nassau Financial Federal Cu" },
    { aba: "221475605", name: "Greater Metro Fcu" },
    { aba: "221475634", name: "U.s. Weather Bureau F.c.u." },
    { aba: "221475773", name: "Pentagon Federal Credit Union" },
    { aba: "221475786", name: "Teachers Fcu" },
    { aba: "221475867", name: "Town Of Hempstead E.f.c.u." },
    { aba: "221475870", name: "Pentagon Federal Credit Union" },
    { aba: "221475896", name: "Island Federal Credit Union" },
    { aba: "221476413", name: "Qside Fcu" },
    { aba: "221476442", name: "Bethpage Federal Credit Union" },
    { aba: "221476594", name: "Long Island Realtors Fcu" },
    { aba: "221476688", name: "Nassau Financial Federal Cu" },
    { aba: "221476691", name: "Winthrop-university Hospital Efcu" },
    { aba: "221476701", name: "Ncpd Federal Credit Union" },
    { aba: "221478877", name: "Ocean Financial Fcu" },
    { aba: "221479096", name: "Nassau Financial Federal C.u." },
    { aba: "221479397", name: "Suffolk Federal Credit Union" },
    { aba: "221479407", name: "Port Washington Federal Credit Union" },
    { aba: "221480807", name: "Jovia Financial Federal Credit Unio" },
    { aba: "221481181", name: "Suffolk Federal Credit Un" },
    { aba: "221482148", name: "Island Federal Credit Union" },
    { aba: "221482245", name: "Msba Efcu" },
    { aba: "221482481", name: "Branch 6000 Credit Union" },
    { aba: "221492002", name: "Long Island Alliance Fcu" },
    { aba: "221571363", name: "Banco Santander De Pr" },
    { aba: "221571376", name: "Banco Santander,de Pr" },
    { aba: "221571415", name: "Oriental Bank" },
    { aba: "221571473", name: "Firstbank Puerto Rico" },
    { aba: "221572676", name: "Banco Santander De Pr" },
    { aba: "221572702", name: "Banco Popular" },
    { aba: "221572838", name: "Banco Popular De Puerto Rico" },
    { aba: "221572867", name: "Oriental Bank" },
    { aba: "221581638", name: "Coop Dr=manuel Zeno Gandia" },
    { aba: "221581641", name: "Coopaca" },
    { aba: "221581667", name: "Cooperativa A/c Yabucoena" },
    { aba: "221581670", name: "Cooperativa De A/c Roosevelt Roads" },
    { aba: "221581696", name: "Caribe Fcu" },
    { aba: "221581706", name: "Coop De Ahorro Y Credito De Moca" },
    { aba: "221581722", name: "Cooperativa A/c Maunabo" },
    { aba: "221581748", name: "Coop De Ahorro Y Credito Oriental" },
    { aba: "221581751", name: "Coop. A/c Del Valenciano" },
    { aba: "221581764", name: "Vapr Federal Credit Union" },
    { aba: "221581997", name: "Cooperativa A/c Hermanos Unidos" },
    { aba: "221582051", name: "Coop De Ahorro Y Cr De Lajas" },
    { aba: "221582077", name: "Cooperativa De A/c De Jayuya, Inc." },
    { aba: "221582080", name: "Coop. Vegabajena Inc." },
    { aba: "221582174", name: "Cooperativa A/c De Yauco" },
    { aba: "221582213", name: "Coop Padre Mcdonald" },
    { aba: "221582226", name: "Coop De Ahorra Y Credito De Camuy" },
    { aba: "221582239", name: "Coop De Ahorro Y Credito Juana Diaz" },
    { aba: "221582284", name: "Cooperativa A/c Manati Inc" },
    { aba: "221582310", name: "Cooperativa De A/c De Cabo Rojo" },
    { aba: "221582323", name: "Coop. De Ahorro Y Credito Hatillo" },
    { aba: "221582336", name: "Borinquen Federal Credit Union" },
    { aba: "221582378", name: "Coop De Ahorro Y Credito San Rafael" },
    { aba: "221582417", name: "Coop. De Ahorro Credito Barranquitas" },
    { aba: "221582459", name: "Coop. A/c La Puertorriquena" },
    { aba: "221582501", name: "Cooperativa De A/c Pepiniana" },
    { aba: "221582527", name: "Coop. De A/c De Adjuntas" },
    { aba: "221582569", name: "Coop. A/c La Sagrada Familia" },
    { aba: "221582585", name: "Coop. De A/c Lomas Verdes" },
    { aba: "221672851", name: "Firstbank" },
    { aba: "221682540", name: "St. Thomas Federal Credit Union" },
    { aba: "221970346", name: "Capital One, N.a." },
    { aba: "221970443", name: "Sterling National Bank" },
    { aba: "221970825", name: "Pcsb Bank" },
    { aba: "221970980", name: "Pcsb Bank" },
    { aba: "221971015", name: "Rhinebeck Bank" },
    { aba: "221971086", name: "Rondout Savings Bank" },
    { aba: "221971138", name: "Sawyer Savings Bank" },
    { aba: "221971264", name: "Ulster Savings Bank" },
    { aba: "221971293", name: "Jpmorgan Chase" },
    { aba: "221971316", name: "Walden Savings Bank" },
    { aba: "221971332", name: "Sterling National Bank" },
    { aba: "221972014", name: "Wallkill Valley Fed." },
    { aba: "221972027", name: "Sunnyside Fed.s&l Assoc Of Irvington" },
    { aba: "221972085", name: "Manufacturers & Traders Trust Co" },
    { aba: "221972098", name: "First Federal Savings Of Middletown" },
    { aba: "221972111", name: "Hsbc Bank, Usa" },
    { aba: "221972153", name: "Manufacturers & Traders Trust Co" },
    { aba: "221972221", name: "Wallkill Valley Federal S/l Assoc" },
    { aba: "221972234", name: "Wallkill Valley Fed." },
    { aba: "221972250", name: "New York Commercial Bank" },
    { aba: "221973563", name: "Usalliance Federal Credit Union" },
    { aba: "221973589", name: "Hudson Valley Credit Union" },
    { aba: "221975024", name: "Hudson Valley Credit Union" },
    { aba: "221975846", name: "Educational & Government Efcu" },
    { aba: "221975956", name: "Teg Federal Credit Union" },
    { aba: "221976227", name: "Ulster F.c.u." },
    { aba: "221976243", name: "Mid-hudson Valley Fcu" },
    { aba: "221976654", name: "Heritage Financial C.u." },
    { aba: "221976667", name: "Heritage Financial C.u." },
    { aba: "221976722", name: "Heritage Financial C.u." },
    { aba: "221976793", name: "Mt Vernon Ny Postal E.f.c.u." },
    { aba: "221976803", name: "Scf Westchester Ny Employee Fcu" },
    { aba: "221976861", name: "Usalliance Federal Credit Union" },
    { aba: "221977006", name: "Stamford Postal Emp. F.c.u." },
    { aba: "221977035", name: "Educational & Gov. Employees Fcu" },
    { aba: "221978791", name: "Heritage Financial C.u." },
    { aba: "221978966", name: "Rockland Employees F.c.u." },
    { aba: "221978982", name: "Sing Sing Efcu" },
    { aba: "221979101", name: "Palisades Federal Credit Union" },
    { aba: "221979114", name: "Hudson Rivers Teachers Fcu" },
    { aba: "221979321", name: "Mid-hudson Valley Federal C.u." },
    { aba: "221979334", name: "Mid-hudson Valley Federal C.u." },
    { aba: "221979363", name: "Hudson Valley Credit Union" },
    { aba: "221979936", name: "Rockland Employees F.c.u." },
    { aba: "221981063", name: "Usalliance Federal Credit Union" },
    { aba: "221981102", name: "White Plains Post Office E.f.c.u." },
    { aba: "221981254", name: "Suma (yonkers) F.c.u." },
    { aba: "221981335", name: "Quorum Federal Credit Union" },
    { aba: "221981652", name: "Port Chester Teachers F.c.u." },
    { aba: "221982091", name: "Hudson Valley Credit Union" },
    { aba: "221982130", name: "Affinity Federal Credit Union" },
    { aba: "221982156", name: "Usalliance Federal Credit Union" },
    { aba: "221982389", name: "(afcu) Academic Federal Credit Union" },
    { aba: "222079291", name: "Becco Buffalo Fcu" },
    { aba: "222079385", name: "Crossroads Community Fcu" },
    { aba: "222079424", name: "Buffalo Metropolitan F.c.u." },
    { aba: "222079440", name: "Buffalo Conrail Fcu" },
    { aba: "222079453", name: "Buffalo Police Fcu" },
    { aba: "222079466", name: "Buffalo Community Fcu" },
    { aba: "222079495", name: "Buffalo Service Cu" },
    { aba: "222079518", name: "The Summit Federal Cu" },
    { aba: "222079550", name: "Cheektowaga Centrl Federal Credit Un" },
    { aba: "222079602", name: "Dunlop Employees Fcu" },
    { aba: "222079631", name: "Erie County Employees C.u." },
    { aba: "222079754", name: "Irs Buffalo Federal Credit Union" },
    { aba: "222079806", name: "The Summit Federal C.u." },
    { aba: "222079822", name: "Afgm Enterprise Federal Credit Union" },
    { aba: "222079835", name: "Financial Trust Federal Credit Un" },
    { aba: "222079848", name: "Maryvale Schools Fcu" },
    { aba: "222079974", name: "Cadets Federal Credit Union" },
    { aba: "222080060", name: "Sisters Hospital Employees Fcu" },
    { aba: "222080112", name: "St. John's Buffalo Fcu" },
    { aba: "222080125", name: "St. John United Federal Credit Union" },
    { aba: "222080138", name: "St. Joseph's Parish Buffalo Fcu" },
    { aba: "222080141", name: "Sweet Home Federal Credit Union" },
    { aba: "222080183", name: "Ukrainian Fcu" },
    { aba: "222080222", name: "Western Division Fcu" },
    { aba: "222080264", name: "Clarence Community & Schools Fcu" },
    { aba: "222080277", name: "Riverside Credit Union" },
    { aba: "222082741", name: "Crossroads Community Fcu" },
    { aba: "222083070", name: "Morton R. Lane State University" },
    { aba: "222083355", name: "Tonawanda Community Fcu" },
    { aba: "222370440", name: "Keybank National Association" },
    { aba: "222371054", name: "Citizens Bank Na" },
    { aba: "222371656", name: "Lake Shore Savings Bank" },
    { aba: "222371698", name: "Evans Bank, National Association" },
    { aba: "222371740", name: "Maple City Savings Bank, Fsb" },
    { aba: "222371805", name: "Generations Bank" },
    { aba: "222371863", name: "Esl Federal Credit Union" },
    { aba: "222371876", name: "Hsbc Bank, Usa" },
    { aba: "222379069", name: "Clarence Community & Schools Fcu" },
    { aba: "222379072", name: "Albion School Employees Fcu" },
    { aba: "222379085", name: "Cobblestone Country Federal Cr Un" },
    { aba: "222379098", name: "Olean Area Federal Credit Union" },
    { aba: "222379108", name: "Olean Teachers & Postal Fcu" },
    { aba: "222379124", name: "Lake Shore Fcu" },
    { aba: "222379137", name: "Att-wyo Correctional Emp. Fed. C.u." },
    { aba: "222379179", name: "V A C Employees Fcu" },
    { aba: "222379195", name: "Greater Woodlawn Fcu" },
    { aba: "222379205", name: "Brockport Federal C.u." },
    { aba: "222380281", name: "Canandaigua Fcu" },
    { aba: "222380320", name: "Westside Community Federal Cu" },
    { aba: "222380359", name: "Corning Federal Credit Union" },
    { aba: "222380388", name: "Servu Fcu" },
    { aba: "222380391", name: "Alco Federal Credit Union" },
    { aba: "222380427", name: "Good Neighbors Fcu" },
    { aba: "222380443", name: "Moog Employee F.c.u." },
    { aba: "222380508", name: "Niagara Regional Fcu" },
    { aba: "222380524", name: "Olean Teachers & Postal Fcu" },
    { aba: "222380579", name: "St. Pius X Church Fcu" },
    { aba: "222380595", name: "Greater Chautauqua Fcu" },
    { aba: "222380634", name: "Finger Lakes F.c.u." },
    { aba: "222380676", name: "Suny Geneseo F.c.u." },
    { aba: "222380692", name: "Finger Lakes Fcu" },
    { aba: "222380702", name: "Financial Trust Federal Credit Union" },
    { aba: "222380731", name: "Meridia Community Fcu" },
    { aba: "222380757", name: "Gowanda Area F.c.u." },
    { aba: "222380760", name: "District #6 Fcu" },
    { aba: "222380773", name: "First Heritage Fcu" },
    { aba: "222380964", name: "Jamestown Area Community Fcu" },
    { aba: "222381057", name: "Affinity One Fcu" },
    { aba: "222381073", name: "Southern Chautauqua F.c.u." },
    { aba: "222381112", name: "Kenmore Ny Teachers Fcu" },
    { aba: "222381138", name: "Financial Trust Fcu" },
    { aba: "222381141", name: "South Towns Community Fcu" },
    { aba: "222381183", name: "Leroy Fcu" },
    { aba: "222381196", name: "Cornerstone Community Fcu" },
    { aba: "222381219", name: "Cattaraugus County Efcu" },
    { aba: "222381235", name: "Lockport Schools Fcu" },
    { aba: "222381248", name: "Niagaras Choice Fcu" },
    { aba: "222381293", name: "Cornerstone Community Fcu" },
    { aba: "222381387", name: "Twin City P.o. Federal Credit Union" },
    { aba: "222381400", name: "Ontario Shores Fcu" },
    { aba: "222381439", name: "Greater Niagara Fcu" },
    { aba: "222381442", name: "Greater Niagra Fcu" },
    { aba: "222381510", name: "Gilman Employees Federal Cu" },
    { aba: "222381523", name: "Greater Niagara Federal Credit Union" },
    { aba: "222381549", name: "Niagara's Choice Fcu" },
    { aba: "222381578", name: "Niagara Falls Air Force Fcu" },
    { aba: "222381581", name: "Encompass Niagara Fcu" },
    { aba: "222381594", name: "Niagara Falls Air Force Fcu" },
    { aba: "222381604", name: "Niagaras Choice Fcu" },
    { aba: "222381620", name: "Niagara Falls Teachers Fcu" },
    { aba: "222381646", name: "Boulevard Federal Credit Union" },
    { aba: "222381659", name: "Niagara Frontier Fed. Employees Cu" },
    { aba: "222381730", name: "Rediform Federal Credit Union" },
    { aba: "222381824", name: "Olean Area Federal Credit Union" },
    { aba: "222381879", name: "Great Erie Federal Credit Union" },
    { aba: "222381882", name: "First Heritage Federal Credit Union" },
    { aba: "222381918", name: "Pittsford Fcu" },
    { aba: "222381934", name: "Genesee Valley Federal Credit Union" },
    { aba: "222381992", name: "Wit Federal Credit Union" },
    { aba: "222382027", name: "Gates Chili Fcu" },
    { aba: "222382069", name: "Greece Community Fcu" },
    { aba: "222382072", name: "St. Pius X Church Fed Credit Union" },
    { aba: "222382111", name: "Lexington Ave Fcu" },
    { aba: "222382137", name: "Focus First Federal Credit Union" },
    { aba: "222382140", name: "Wit Federal Credit Union" },
    { aba: "222382153", name: "Rochester Area State Empl. F.c.u." },
    { aba: "222382205", name: "Ever $ Green F.c.u." },
    { aba: "222382221", name: "Ukrainian Federal Credit Union" },
    { aba: "222382234", name: "Rochester & Monroe Cnty Fcu" },
    { aba: "222382292", name: "Advantage Federal Credit Union" },
    { aba: "222382315", name: "The Summit Federal C.u." },
    { aba: "222382386", name: "Alliance Niagara Fcu" },
    { aba: "222382399", name: "Niagara Wheatfield Fcu" },
    { aba: "222382425", name: "Amherst Federal Credit Union" },
    { aba: "222382438", name: "Reliant Community Fcu" },
    { aba: "222382441", name: "Spencerport Federal Credit Union" },
    { aba: "222382496", name: "Linton Federal Credit Union" },
    { aba: "222382548", name: "Tonawanda Community Fcu" },
    { aba: "222382551", name: "Boulevard Federal Credit Union" },
    { aba: "222382593", name: "Family First Of Ny Fcu" },
    { aba: "222382603", name: "Wit Federal Credit Union" },
    { aba: "222382616", name: "Webster Federal Credit Union" },
    { aba: "222382661", name: "Southern Chatauga Fcu" },
    { aba: "222382674", name: "North East Welch Federal Credit Unio" },
    { aba: "222382713", name: "Williamsville F.c.u." },
    { aba: "222382726", name: "Lewiston Porter Fcu" },
    { aba: "222382739", name: "Western New York Fcu" },
    { aba: "222383039", name: "Rochester Polish Fcu" },
    { aba: "222383055", name: "Inner Lakes Federal Credit Union" },
    { aba: "222383068", name: "Suny Fredonia Fcu" },
    { aba: "222383110", name: "C.c.s.e. F.c.u." },
    { aba: "222383149", name: "Financial Trust Fcu" },
    { aba: "222383385", name: "Genesee Co-op Federal Credit Union" },
    { aba: "222383466", name: "Tri County Fed Credit Union" },
    { aba: "222383479", name: "Tonawanda Valley Fcu" },
    { aba: "226070115", name: "Capital One, N.a." },
    { aba: "226070128", name: "Hsbc Bank Usa" },
    { aba: "226070131", name: "Ponce Bank" },
    { aba: "226070173", name: "Kearny Federal Savings Bank" },
    { aba: "226070238", name: "Citibank, West" },
    { aba: "226070306", name: "Dime Community Bank" },
    { aba: "226070319", name: "Emigrant Savings Bank" },
    { aba: "226070364", name: "Hsbc Bank, Usa" },
    { aba: "226070403", name: "Emigrant Bank" },
    { aba: "226070474", name: "Flushing Bank" },
    { aba: "226070555", name: "Capital One, N.a." },
    { aba: "226070584", name: "Apple Bank For Savings" },
    { aba: "226070652", name: "Capital One, N.a." },
    { aba: "226070665", name: "Maspeth Federal S&l Assn" },
    { aba: "226070717", name: "Hsbc Bank Usa" },
    { aba: "226070762", name: "Flushing Savings Bank, F.s.b." },
    { aba: "226070856", name: "Capital One, N.a." },
    { aba: "226071004", name: "New York Community Bank" },
    { aba: "226071020", name: "New York Community Bank" },
    { aba: "226071033", name: "Ridgewood Savings Bank" },
    { aba: "226071091", name: "New York Community Bank" },
    { aba: "226071211", name: "Santnader Bank" },
    { aba: "226071237", name: "Santander Bank, N.a." },
    { aba: "226071389", name: "Hsbc Bank Usa" },
    { aba: "226071457", name: "Northfield Bank" },
    { aba: "226072304", name: "Capital One, N.a." },
    { aba: "226072317", name: "Ridgewood Savings Bank" },
    { aba: "226072333", name: "Flushing Savings Bank" },
    { aba: "226072346", name: "Investors Bank" },
    { aba: "226072362", name: "Northfield Bank" },
    { aba: "226072375", name: "Capital One, N.a." },
    { aba: "226072472", name: "Cross County Savings Bank" },
    { aba: "226072498", name: "Carver Federal Savings Bank" },
    { aba: "226072511", name: "North East Community Bank" },
    { aba: "226072647", name: "New York Community Bank" },
    { aba: "226072841", name: "Hanover Community Bank" },
    { aba: "226072870", name: "Abacus Federal Savings Bank" },
    { aba: "226073895", name: "Usalliance Federal Credit Union" },
    { aba: "226074001", name: "Teachers Federal Credit Union" },
    { aba: "226074111", name: "Van Cortlandt Coop Fcu" },
    { aba: "226074195", name: "Island Federal Credit Union" },
    { aba: "226074218", name: "Brooklyn Postal Employees Cu" },
    { aba: "226074645", name: "Nym Federal Credit Union" },
    { aba: "226074658", name: "Bethpage Federal C.u." },
    { aba: "226074881", name: "Transfiguration Federal C.u." },
    { aba: "226074946", name: "Consumers Fcu" },
    { aba: "226075291", name: "Triboro Postal Federal Credit Union" },
    { aba: "226075385", name: "Far Rockaway Postal F.c.u." },
    { aba: "226075482", name: "Teachers Federal Credit Union" },
    { aba: "226076038", name: "Bethpage Federal Credit Union" },
    { aba: "226076083", name: "Aspire Federal Credit Union" },
    { aba: "226076096", name: "Medisys Employees Federal Cr Un" },
    { aba: "226076122", name: "Ny Team Fed Credit Union" },
    { aba: "226076135", name: "People's Alliance F.c.u." },
    { aba: "226076339", name: "Bakery Salesmen Cu" },
    { aba: "226076371", name: "Ny Team Federal Credit Union" },
    { aba: "226077079", name: "Actors Federal Credit Union" },
    { aba: "226077231", name: "Pentagon Federal Credit Union" },
    { aba: "226077370", name: "Entertainment Industries F.c.u." },
    { aba: "226077451", name: "Elektra Fcu" },
    { aba: "226077464", name: "Empire Branch # 36 Credit Union" },
    { aba: "226077516", name: "Financial Resources Fcu" },
    { aba: "226077532", name: "Fidelis Federal Credit Union" },
    { aba: "226077613", name: "Affintiy Fcu" },
    { aba: "226077642", name: "Grand Central Term E.f.c.u" },
    { aba: "226077862", name: "1199 Seiu Federal Credit Union" },
    { aba: "226077875", name: "Palisades Federal Credit Union" },
    { aba: "226078036", name: "Municipal Credit Union" },
    { aba: "226078049", name: "Usalliance Federal Credit Union" },
    { aba: "226078065", name: "Usalliance Federal Credit Union" },
    { aba: "226078104", name: "Palisades Federal Credit Union" },
    { aba: "226078117", name: "New York Times F.c.u." },
    { aba: "226078230", name: "Xcel Federal Credit Union" },
    { aba: "226078256", name: "Entertainment Industries Fcu" },
    { aba: "226078379", name: "Self Reliance New York F.c.u." },
    { aba: "226078476", name: "Northeastern Operating Engineers Fcu" },
    { aba: "226078544", name: "Ukrainian National F.c.u." },
    { aba: "226078573", name: "Lower Eastside People's Fcu" },
    { aba: "226078609", name: "United Nations Fcu" },
    { aba: "226078612", name: "University Settlement Fcu" },
    { aba: "226078625", name: "U S Courthouse F.c.u." },
    { aba: "226078670", name: "Greater Metro Fcu" },
    { aba: "226079941", name: "Medical Empl Of S.i. Fcu" },
    { aba: "226079967", name: "Division 726 Federal Credit Union" },
    { aba: "226082022", name: "Polish & Slavic Fcu" },
    { aba: "226082116", name: "Entertainment Industries F.c.u." },
    { aba: "226082129", name: "New York University F.c.u." },
    { aba: "226082491", name: "People's Alliance Fcu" },
    { aba: "226082514", name: "Triboro Postal Federal Credit Union" },
    { aba: "226082598", name: "Lower East Side People's Fcu" },
    { aba: "226092009", name: "Xcel Federal Credit Union" },
    { aba: "231082826", name: "Atlantic Empl Fcu" },
    { aba: "231170181", name: "Santander Bank, N.a." },
    { aba: "231175759", name: "Artisans Bank" },
    { aba: "231176554", name: "Del-one Federal Credit Union" },
    { aba: "231176567", name: "Delaware State Police Fcu" },
    { aba: "231176570", name: "First State Federal Credit Union" },
    { aba: "231176596", name: "Provident Federal Credit Union" },
    { aba: "231176648", name: "Dover Federal Credit Union" },
    { aba: "231176664", name: "Tidemark Federal Credit Union" },
    { aba: "231176680", name: "Tidemark Federal Credit Union" },
    { aba: "231176787", name: "American Spirit Fcu" },
    { aba: "231176826", name: "New Castle Cty Del Em Fcu" },
    { aba: "231176855", name: "Delaware Alliance Fcu" },
    { aba: "231176884", name: "Tidemark Federal Credit Union" },
    { aba: "231176952", name: "Louviers Fcu" },
    { aba: "231176994", name: "Dexsta Fcu" },
    { aba: "231177016", name: "Community Powered Fcu" },
    { aba: "231177100", name: "Louviers Fed Cr Union" },
    { aba: "231177126", name: "New Castle County School Emp Fcu" },
    { aba: "231177281", name: "Priority Plus Federal Credit Union" },
    { aba: "231177294", name: "Wilimington Police & Fire Fcu" },
    { aba: "231270256", name: "William Penn Bank" },
    { aba: "231270340", name: "Investors Bank" },
    { aba: "231270353", name: "Oceanfirst Bank" },
    { aba: "231270366", name: "Century Savings Bank" },
    { aba: "231270405", name: "Oceanfirst Bank" },
    { aba: "231270434", name: "Wilmington Savings Fund Society, Fsb" },
    { aba: "231270654", name: "Oceanfirst Bank" },
    { aba: "231270751", name: "New York Community Bank" },
    { aba: "231270777", name: "First Bank" },
    { aba: "231270858", name: "Haddon Savings Bank" },
    { aba: "231271006", name: "Millville Savings Bank" },
    { aba: "231271080", name: "Oceanfirst Bank" },
    { aba: "231271145", name: "Investors Bank" },
    { aba: "231271161", name: "Santander Bank" },
    { aba: "231271226", name: "Truist Bank" },
    { aba: "231271239", name: "Franklin Bank" },
    { aba: "231271242", name: "1st Bank Of Sea Isle City" },
    { aba: "231271284", name: "Sturdy Savings Bank" },
    { aba: "231271365", name: "Cenlar Fsb" },
    { aba: "231271378", name: "Investors Bank" },
    { aba: "231271404", name: "Santander Bank" },
    { aba: "231271501", name: "Crest Savings Bank" },
    { aba: "231271527", name: "Monroe Savings Bank" },
    { aba: "231275882", name: "College Savings Bank, A Div Nexbank" },
    { aba: "231277411", name: "Jersey Shore Federal Credit Union" },
    { aba: "231277424", name: "Atlantic City Electric Empl Fcu" },
    { aba: "231277440", name: "Jersey Shore Federal Credit Union" },
    { aba: "231277518", name: "Bridgeton Onized Fcu" },
    { aba: "231277819", name: "Peoples Transport Fcu" },
    { aba: "231277822", name: "Deepwater Industries" },
    { aba: "231277864", name: "Fort Dix Fcu" },
    { aba: "231277945", name: "Lakehurst Naval Fcu" },
    { aba: "231277990", name: "Andrews Federal Credit Union" },
    { aba: "231278012", name: "Thunderbolt Area Fcu" },
    { aba: "231278054", name: "New Jersey Community Fcu" },
    { aba: "231278083", name: "Garden State Federal Credit Union" },
    { aba: "231278096", name: "Cumco Fcu" },
    { aba: "231278119", name: "Fort Billings Fcu" },
    { aba: "231278122", name: "Research 1166 Fcu" },
    { aba: "231278177", name: "First Point Federal Credit Union" },
    { aba: "231278203", name: "Credit Union Of New Jersey" },
    { aba: "231278229", name: "Princeton Federal Credit Union" },
    { aba: "231278274", name: "Abco Federal Credit Union" },
    { aba: "231278339", name: "First Financial Fcu" },
    { aba: "231278465", name: "Hamilton Horizon Fcu" },
    { aba: "231278575", name: "Mercer County Nj Teachers Fcu" },
    { aba: "231278614", name: "Credit Union Of New Jersey" },
    { aba: "231278627", name: "Njl & Ps Credit Union" },
    { aba: "231278643", name: "Postal Empl Cu" },
    { aba: "231278711", name: "Trenton Nj Firemen Fcu" },
    { aba: "231278724", name: "Trenton Nj Police Fcu" },
    { aba: "231278821", name: "Bay Atlantic Fcu" },
    { aba: "231288808", name: "Pse&g Nuclear Employees Fcu" },
    { aba: "231288811", name: "Healthcare Employees Fcu" },
    { aba: "231371579", name: "Pnc Bank, Na" },
    { aba: "231371595", name: "Altoona First Savings Bank" },
    { aba: "231371605", name: "Penncrest Bank" },
    { aba: "231371618", name: "Investment Saving Bank" },
    { aba: "231371634", name: "Ambler Savings Bank" },
    { aba: "231371663", name: "Santander Bank" },
    { aba: "231371692", name: "Iron Workers Bank" },
    { aba: "231371799", name: "Truist Bank" },
    { aba: "231371841", name: "Bank Of America N.a., Pa" },
    { aba: "231371854", name: "William Penn Bank" },
    { aba: "231371867", name: "Penn Community Bank" },
    { aba: "231371935", name: "S & T Bank" },
    { aba: "231371964", name: "Bryn Mawr Trust Company" },
    { aba: "231372028", name: "Prosper Bank" },
    { aba: "231372073", name: "Slovenian S&l Asn" },
    { aba: "231372112", name: "Penncrest Bank" },
    { aba: "231372141", name: "Sharon Bank" },
    { aba: "231372170", name: "Keybank National Association" },
    { aba: "231372183", name: "Truist Bank" },
    { aba: "231372248", name: "Essa Bank & Trust" },
    { aba: "231372264", name: "Santander" },
    { aba: "231372329", name: "County Savgs Assn" },
    { aba: "231372361", name: "Harleysville Bank" },
    { aba: "231372387", name: "Santander Bank" },
    { aba: "231372400", name: "Hatboro Fed Savgs Loan" },
    { aba: "231372439", name: "Truist Bank" },
    { aba: "231372523", name: "Huntingdon Valley Bank" },
    { aba: "231372691", name: "Santander" },
    { aba: "231372798", name: "William Penn Bank" },
    { aba: "231372811", name: "Mcs Bank" },
    { aba: "231372921", name: "Milton Savings Bank" },
    { aba: "231372963", name: "Sharon Savings Bank" },
    { aba: "231373030", name: "Jim Thorpe Neighborhood Bank" },
    { aba: "231373069", name: "Citizens Bank, National Association" },
    { aba: "231373179", name: "Malvern Federal Savings Bank" },
    { aba: "231373182", name: "Penn Community Bank" },
    { aba: "231374916", name: "Phoenixville Federal Bank & Trust" },
    { aba: "231374945", name: "Santander Bank" },
    { aba: "231374961", name: "Truist Bank" },
    { aba: "231375009", name: "The Gratz Bank" },
    { aba: "231375038", name: "Fulton Bank N.a." },
    { aba: "231375096", name: "Bryn Mawr Trust Company, The" },
    { aba: "231375151", name: "Citizens Savings Bank" },
    { aba: "231375177", name: "Wayne Bank" },
    { aba: "231375216", name: "Univest National Bank" },
    { aba: "231375290", name: "First National Bank Of Pennsylvania" },
    { aba: "231375397", name: "Reliance Savings Bank" },
    { aba: "231375436", name: "Wilmington Savings Fund Society,fsb" },
    { aba: "231375520", name: "M & T Bank" },
    { aba: "231375575", name: "Keybank National Association" },
    { aba: "231375630", name: "Santander Bank" },
    { aba: "231378996", name: "Lehigh Valley Educators Credit Union" },
    { aba: "231379005", name: "Allentown Muncpl Emp Fcu" },
    { aba: "231379034", name: "Apci Federal Credit Union" },
    { aba: "231379076", name: "Lehigh Cty Emp Fcu" },
    { aba: "231379115", name: "People First Fcu" },
    { aba: "231379131", name: "Gold Credit Union" },
    { aba: "231379157", name: "Sarco Federal C.u." },
    { aba: "231379199", name: "First Commonwealth Fcu" },
    { aba: "231379225", name: "Your Choice Fcu" },
    { aba: "231379238", name: "Altoona Butterick Fcu" },
    { aba: "231379254", name: "Healthcare First Credit Union" },
    { aba: "231379270", name: "American Pride Credit Union" },
    { aba: "231379296", name: "Healthcare First Credit Union" },
    { aba: "231379306", name: "Arc Fed Cr Union" },
    { aba: "231379322", name: "Blair County Fcu" },
    { aba: "231379335", name: "Healthcare First Credit Union" },
    { aba: "231379393", name: "Ingersoll-rand Fcu" },
    { aba: "231379445", name: "Cbw Schools Fed Cr Union" },
    { aba: "231379458", name: "Kennaford Fcu" },
    { aba: "231379461", name: "Titan Fcu" },
    { aba: "231379487", name: "Bethlehem Teachers Fcu" },
    { aba: "231379490", name: "Bethlehem 1st Federal Credit Union" },
    { aba: "231379513", name: "People First Federal Credit Union" },
    { aba: "231379539", name: "First Class Fcu" },
    { aba: "231379636", name: "Bradford Area Fcu" },
    { aba: "231379775", name: "Inspire Federal Credit Union" },
    { aba: "231379788", name: "Access Credit Union" },
    { aba: "231379791", name: "Dow Bucks County Fcu" },
    { aba: "231379830", name: "Blue Chip Federal Credit Union" },
    { aba: "231379966", name: "1st Ed Credit Union" },
    { aba: "231379979", name: "Patriot Federal Credit Union" },
    { aba: "231380104", name: "Citadel Fed C U" },
    { aba: "231380133", name: "West Branch Valley Fcu" },
    { aba: "231380159", name: "Superior Credit Union" },
    { aba: "231380298", name: "Central Susquehanna Community Fcu" },
    { aba: "231380324", name: "Trumark Financial Credit Union" },
    { aba: "231380337", name: "Citadel Fcu" },
    { aba: "231380405", name: "Timberland Fed Cr Union" },
    { aba: "231380463", name: "Knoll Employees Credit Union" },
    { aba: "231380544", name: "Crayola Llc Employees Credit Union" },
    { aba: "231380764", name: "Bedco Hospital Federal Credit Union" },
    { aba: "231380777", name: "Wyoming Area Fcu" },
    { aba: "231380780", name: "Horizon Federal Credit Union" },
    { aba: "231380829", name: "Atlantic Credit Union" },
    { aba: "231380997", name: "Belco Community Credit Union" },
    { aba: "231381103", name: "Pennsylvania Central Fcu" },
    { aba: "231381116", name: "Pennsylvania State Employees Cu" },
    { aba: "231381161", name: "Blue Chip Federal Credit Union" },
    { aba: "231381307", name: "Hazleton Sch Emp Cu" },
    { aba: "231381310", name: "Choice One Fcu" },
    { aba: "231381349", name: "Hershey Fed Cu" },
    { aba: "231381417", name: "Huntingdon County, Fcu" },
    { aba: "231381459", name: "Franklin Johnstown Fcu" },
    { aba: "231381488", name: "G.a.p. Fcu" },
    { aba: "231381514", name: "Merho Fed Cr Union" },
    { aba: "231381569", name: "U S S C O Johnstown" },
    { aba: "231381585", name: "Ufcw Local 72 Fed Cr Union" },
    { aba: "231381637", name: "Citymark Federal Credit Union" },
    { aba: "231381815", name: "Lancaster Red Rose Credit Union" },
    { aba: "231381857", name: "Wheatland Fed Cr Un" },
    { aba: "231381860", name: "Lanco Fcu" },
    { aba: "231381938", name: "Lebanon Federal Credit Union" },
    { aba: "231382092", name: "1st Area Fcu" },
    { aba: "231382115", name: "Local 520 U.a. Fcu" },
    { aba: "231382186", name: "Atlantic Empl Fcu" },
    { aba: "231382209", name: "Sun East Federal Credit Union" },
    { aba: "231382241", name: "Members 1st Fcu" },
    { aba: "231382267", name: "Americhoice Federal Credit Union" },
    { aba: "231382306", name: "Franklin Mint Fcu" },
    { aba: "231382351", name: "Pinpoint Federal Credit Union" },
    { aba: "231382403", name: "Lakes To Sea Fcu" },
    { aba: "231382416", name: "Palco Fcu" },
    { aba: "231382458", name: "Spirit Financial Credit Union" },
    { aba: "231382555", name: "New Cumberland Fcu" },
    { aba: "231382568", name: "Susquehanna Valley Fcu" },
    { aba: "231382597", name: "Local 380 Ibew Credit Union" },
    { aba: "231382607", name: "Norristown Bell Credit Union" },
    { aba: "231382665", name: "Northampton Area School District Ecu" },
    { aba: "231382678", name: "St. Elizabeth Cu" },
    { aba: "231385073", name: "Highway Federal Credit Union" },
    { aba: "231385112", name: "P C Fcu" },
    { aba: "231385154", name: "Diamond Credit Union" },
    { aba: "231385183", name: "Tri County Area Fcu" },
    { aba: "231385235", name: "Cacl Fcu" },
    { aba: "231385264", name: "Schuylkill Fed Cu" },
    { aba: "231385280", name: "Hidden River Credit Union" },
    { aba: "231385345", name: "Bellco Fcu" },
    { aba: "231385400", name: "Visions Federal Credit Union" },
    { aba: "231385413", name: "Bellco Fcu" },
    { aba: "231385507", name: "Riverfront Fcu" },
    { aba: "231385536", name: "Riverfront Fcu" },
    { aba: "231385604", name: "Pagoda Federal Credit Union" },
    { aba: "231385633", name: "Utilities Employees Cu" },
    { aba: "231385646", name: "Discovery Federal Credit Union" },
    { aba: "231385701", name: "Boeing Helicopters Credit Union" },
    { aba: "231385714", name: "Members Choice Financial Cu" },
    { aba: "231385730", name: "Morrisons Cove 1st Fcu" },
    { aba: "231385769", name: "Superior Cu" },
    { aba: "231385785", name: "Forge Federal Credit Union" },
    { aba: "231385798", name: "Mountain Laurel Fcu" },
    { aba: "231385808", name: "Team First Federal Credit Union" },
    { aba: "231385879", name: "Harper & Row Keystone Empl Fcu" },
    { aba: "231385918", name: "Net Fed Cr Union" },
    { aba: "231385950", name: "Penn East Federal Credit Union" },
    { aba: "231385976", name: "First Credit Union Of Scranton" },
    { aba: "231385989", name: "Scranton Times Down Town Fcu" },
    { aba: "231385992", name: "Spojnia Credit Union" },
    { aba: "231386043", name: "Steamfitters Phila Fcu" },
    { aba: "231386069", name: "Utilities Employees Credit Union" },
    { aba: "231386108", name: "Hidden River Credit Union" },
    { aba: "231386137", name: "P R R South Fork Fcu" },
    { aba: "231386182", name: "State College Fcu" },
    { aba: "231386221", name: "Ne Pa Community Fcu" },
    { aba: "231386263", name: "Central Keystone Fcu" },
    { aba: "231386344", name: "Craftmaster Federal Credit Union" },
    { aba: "231386360", name: "Ufcw Fed Cu" },
    { aba: "231386373", name: "P G Mehoopany Emp Fcu" },
    { aba: "231386386", name: "Penn East Federal Credit Union" },
    { aba: "231386399", name: "Penn State Federal C U" },
    { aba: "231386441", name: "Delco Postal Cu" },
    { aba: "231386467", name: "Upper Darby Belltelco Fcu" },
    { aba: "231386519", name: "Freedom Credit Union" },
    { aba: "231386580", name: "First Heritage Fcu" },
    { aba: "231386616", name: "Benchmark Fcu" },
    { aba: "231386629", name: "Benchmark Fcu" },
    { aba: "231386645", name: "Merck Sharp + Dohme Fcu" },
    { aba: "231386771", name: "Community Regional Credit Union" },
    { aba: "231386784", name: "Luzerne Cty Fcu" },
    { aba: "231386807", name: "Penn Wilco Fcu" },
    { aba: "231386852", name: "Corner Post Federal Credit Union" },
    { aba: "231386865", name: "W-bee Federal Credit Union" },
    { aba: "231386878", name: "Choice One Fcu" },
    { aba: "231386881", name: "Cross Valley Fed Cu" },
    { aba: "231386894", name: "Citymark Federal Credit Union" },
    { aba: "231386904", name: "Vantage Trust Fcu" },
    { aba: "231386975", name: "Horizon Federal Credit Union" },
    { aba: "231387026", name: "Horizon Federal Credit Union" },
    { aba: "231387039", name: "Williamsport Teachers Cr Un" },
    { aba: "231387042", name: "Wyrope Williamsport Fed Ca" },
    { aba: "231387136", name: "Ufcw Fed Cu" },
    { aba: "231387165", name: "First Capital Fcu" },
    { aba: "231387217", name: "White Rose Credit Union" },
    { aba: "231387275", name: "Pace Resources Fcu" },
    { aba: "231387343", name: "First Capital Fcu" },
    { aba: "231387356", name: "White Rose Credit Union" },
    { aba: "231387385", name: "Heritage Valley Fed Cu" },
    { aba: "231387466", name: "York Educational Fcu" },
    { aba: "231387518", name: "Penntech Employees Fcu" },
    { aba: "231387550", name: "Vizo Financial Corporate Credit Un" },
    { aba: "231387576", name: "The United Fcu" },
    { aba: "231387589", name: "Wilmington Savings Fund Society, Fsb" },
    { aba: "231387602", name: "Service 1st Federal Credit Union" },
    { aba: "231387628", name: "Belco Community Credit Union" },
    { aba: "231387631", name: "Valley Pride Fcu" },
    { aba: "231387644", name: "Spe Federal Credit Union" },
    { aba: "231387932", name: "Corner Post Federal Credit Union" },
    { aba: "231387974", name: "Glatco Credit Union" },
    { aba: "231388038", name: "Lancaster Red Rose Credit Union" },
    { aba: "231388164", name: "Wawa Employees Credit Union" },
    { aba: "231388216", name: "Citymark Federal Credit Union" },
    { aba: "231388261", name: "Healthcare First Credit Union" },
    { aba: "231388274", name: "Cmc-fcpi Emply Fcu" },
    { aba: "231388494", name: "Guthrie Fed Credit Union" },
    { aba: "231388520", name: "First Heritage Federal Credit Union" },
    { aba: "231388656", name: "Altoona Area Employees Fcu" },
    { aba: "231388711", name: "Wilmac Employees Credit Union" },
    { aba: "231971568", name: "Truist Bank" },
    { aba: "231975836", name: "Capital Bank, Na" },
    { aba: "231980834", name: "Honeywell, Phila. Division F.c.u." },
    { aba: "231980944", name: "North Penn Fed Cr Un" },
    { aba: "231981697", name: "Reliance Federal Credit Union" },
    { aba: "231982612", name: "Trumark Financial Credit Union" },
    { aba: "231988470", name: "Trouvaille Federal Credit Union" },
    { aba: "236070545", name: "Wilmington Savings Fund Society, Fsb" },
    { aba: "236073225", name: "United Savings Bank" },
    { aba: "236073377", name: "Wilmington Savings Fund Society, Fsb" },
    { aba: "236073474", name: "Bank Of America N.a." },
    { aba: "236073801", name: "Firstrust Svgs Bank" },
    { aba: "236073830", name: "Univest Bank & Trust Co" },
    { aba: "236073872", name: "Tioga-franklin Savings Bank" },
    { aba: "236074130", name: "William Penn Bank" },
    { aba: "236074156", name: "Wilmington Savings Fund Society, Fsb" },
    { aba: "236074444", name: "Prudential Savings Bank" },
    { aba: "236074509", name: "Prudential Bank" },
    { aba: "236074525", name: "Quaint Oak Bank" },
    { aba: "236074619", name: "Citizens Bank, National Association" },
    { aba: "236074677", name: "Wilmington Savings Fund Society, Fsb" },
    { aba: "236074732", name: "Fifth Third Bank" },
    { aba: "236074855", name: "Port Richmond Savings" },
    { aba: "236075689", name: "Wilmington Savings Fund Society, Fsb" },
    { aba: "236077658", name: "Campbell Federal Credit Union" },
    { aba: "236077755", name: "South Jersey Federal Credit Union" },
    { aba: "236082782", name: "Trumark Financial Credit Union" },
    { aba: "236082821", name: "Citadel Fcu" },
    { aba: "236082944", name: "American Heritage Fcu" },
    { aba: "236083095", name: "Sun Federal Credit Union" },
    { aba: "236083215", name: "Errl Fcu/usda" },
    { aba: "236083228", name: "Esb Employees Fcu" },
    { aba: "236083260", name: "Eagle One Federal Credit Union" },
    { aba: "236083312", name: "Mayfair Fcu" },
    { aba: "236083655", name: "Viriva Community Credit Union" },
    { aba: "236083671", name: "Philadelphia Letter Carriers Fcu" },
    { aba: "236083817", name: "Amer. Heritage Fcu" },
    { aba: "236084162", name: "Trumark Financial Credit Union" },
    { aba: "236084175", name: "Media Members Federal Credit Union" },
    { aba: "236084243", name: "Trumark Financial Cu" },
    { aba: "236084285", name: "Phila Police & Fire Fed Cu" },
    { aba: "236084298", name: "Phila Fed C U" },
    { aba: "236084353", name: "Eagle One Federal Credit Union" },
    { aba: "236084476", name: "Ufcw Local 1776 Fcu" },
    { aba: "236084515", name: "Trumark Financial Credit Union" },
    { aba: "236084560", name: "Freedom Credit Union" },
    { aba: "236084751", name: "Freedom Credit Union" },
    { aba: "236084803", name: "Transit Workers Fed Cu" },
    { aba: "236084829", name: "Media Members Fcu" },
    { aba: "236084832", name: "Ukrainian Selfreliance Fcu" },
    { aba: "236087664", name: "Ardent Credit Union." },
    { aba: "236087680", name: "Global 1 Federal Credit Union" },
    { aba: "236087965", name: "Eagle One Federal Credit Union" },
    { aba: "241070394", name: "Dollar Bank" },
    { aba: "241070417", name: "Citizens Bank Na" },
    { aba: "241070420", name: "Third Federal Svgs & Loan" },
    { aba: "241070433", name: "New York Community Bank" },
    { aba: "241070446", name: "First Federal S & L Assc Of Lakewood" },
    { aba: "241070459", name: "First National Bank Of Pennsylvania" },
    { aba: "241070475", name: "The Pioneer Savings Bank" },
    { aba: "241070530", name: "Third Fed S & L Assn" },
    { aba: "241071212", name: "First Federal S & L Assc Of Lakewood" },
    { aba: "241071762", name: "New York Community Bank" },
    { aba: "241073951", name: "Best Reward Cu." },
    { aba: "241074824", name: "Acme Federal Credit Union" },
    { aba: "241074837", name: "Cleve Ne- Ohio Edcu Cr Un" },
    { aba: "241074840", name: "Cardnal Credit Union, Inc" },
    { aba: "241074905", name: "Antioch Baptist Church Cu" },
    { aba: "241075056", name: "Century Fed Credit Union" },
    { aba: "241075085", name: "Greater Cleveland Community Cu, Inc" },
    { aba: "241075124", name: "The Ohio Educational Credit Union" },
    { aba: "241075153", name: "Ohio's First Class Credit Union" },
    { aba: "241075221", name: "Cleveland Police Cr Un Inc" },
    { aba: "241075386", name: "Plain Dealer Federal Credit Union" },
    { aba: "241075467", name: "Community United Credit Union" },
    { aba: "241075470", name: "Eaton Family Credit Union, Inc." },
    { aba: "241075577", name: "Western Region Fcu" },
    { aba: "241075658", name: "Emerald Group Credit Union" },
    { aba: "241075726", name: "Firefighters Community Credit Un" },
    { aba: "241075920", name: "Noteworthy Federal Credit Union" },
    { aba: "241076000", name: "Faith Community United C.u." },
    { aba: "241076097", name: "Ohio Teamsters Credit Union Inc." },
    { aba: "241076110", name: "Best Reward Credit Union" },
    { aba: "241076152", name: "Ukranian Fcu" },
    { aba: "241076233", name: "Plain Dealer Federal Credit Union" },
    { aba: "241076327", name: "Firefighters Community Credit Un" },
    { aba: "241076466", name: "St. Colmans Federal Credit Union" },
    { aba: "241076534", name: "St Margaret & Gregory F.c.u." },
    { aba: "241076657", name: "Taleris Credit Union, Inc." },
    { aba: "241076767", name: "Steel Valley Fcu" },
    { aba: "241076770", name: "North Coast Credit Union" },
    { aba: "241078875", name: "Ohio Catholic Federal Credit Union" },
    { aba: "241078888", name: "St Therese/s.s. Peter & Paul Fcu" },
    { aba: "241079052", name: "Best Reward Cu." },
    { aba: "241079353", name: "Latvian Cleveland Credit Union" },
    { aba: "241080753", name: "Lake Cnty Educational Federal C.u." },
    { aba: "241081024", name: "Cleve Self Reliance Cu" },
    { aba: "241081040", name: "Holy Family Parma Fcu" },
    { aba: "241081066", name: "Pse Credit Union - Inc." },
    { aba: "241081079", name: "Best Reward Cu" },
    { aba: "241081105", name: "Unity Catholic Fcu" },
    { aba: "241081118", name: "All Saints Federal Credit Union" },
    { aba: "241081493", name: "Wiremen's Cu" },
    { aba: "241081642", name: "Solon School Efcu" },
    { aba: "241270039", name: "Peoples Bank" },
    { aba: "241270181", name: "Premier Bank" },
    { aba: "241270233", name: "First Federal Community Bank" },
    { aba: "241270246", name: "Peoples Svgs & Loan Co" },
    { aba: "241270796", name: "The Conneaut Savings Bank" },
    { aba: "241270819", name: "Home Loan Savings Bank" },
    { aba: "241270848", name: "Westfield Bank" },
    { aba: "241270851", name: "Premier Bank" },
    { aba: "241270903", name: "First Fed S & L Assn" },
    { aba: "241270916", name: "First Federal Community Bank" },
    { aba: "241270958", name: "Premier Bank" },
    { aba: "241271041", name: "1st Federal Bank Of Ohio" },
    { aba: "241271054", name: "Galion Bldg & Loan Bank" },
    { aba: "241271135", name: "Commercial & Savings Bank" },
    { aba: "241271193", name: "Hometown Bank" },
    { aba: "241271203", name: "Home Savings & Loan Co" },
    { aba: "241271342", name: "First Fed S & L Of Lorain" },
    { aba: "241271371", name: "Mechanics Bank" },
    { aba: "241271614", name: "Home Fed Svgs & Loan Assn" },
    { aba: "241271669", name: "Dollar Bank" },
    { aba: "241271795", name: "Ssb Community Bank" },
    { aba: "241271928", name: "First Fed S & L Assn" },
    { aba: "241271931", name: "Van Wert Federal Savings Bank" },
    { aba: "241271944", name: "Equitable Svgs & Loan Co" },
    { aba: "241271957", name: "Tcf National Bank" },
    { aba: "241272040", name: "Wayne Savings Community Bank" },
    { aba: "241272066", name: "Premier Bank" },
    { aba: "241272079", name: "First National Bank Of Pennsylvania" },
    { aba: "241272118", name: "Cf Bank" },
    { aba: "241272202", name: "Farmers National Bank Of Canfield" },
    { aba: "241273078", name: "Bfg Federal Credit Union" },
    { aba: "241273081", name: "Akron Firefighters Cu" },
    { aba: "241273094", name: "Akron Municipal Emp Cr Un" },
    { aba: "241273104", name: "Firstenergy Family Credit Union,inc." },
    { aba: "241273117", name: "Akron Fire Department Credit Union" },
    { aba: "241273120", name: "Towpath Credit Union" },
    { aba: "241273133", name: "Telecommunity Credit Union" },
    { aba: "241273188", name: "Ohiohealth Federal Credit Union" },
    { aba: "241273243", name: "Firestone Fcu" },
    { aba: "241273269", name: "Genfed Financial Credit Union" },
    { aba: "241273285", name: "Goodyear Employees Credit Union" },
    { aba: "241273308", name: "Equitable Federal Credit Union" },
    { aba: "241273366", name: "Buckeye State Cr Union" },
    { aba: "241273463", name: "Summit Federal Cr Union" },
    { aba: "241273489", name: "Golden Circle Cu" },
    { aba: "241273612", name: "Ashland Comm Fcu" },
    { aba: "241273706", name: "Community First Credit Union" },
    { aba: "241273780", name: "Lakeview Federal Credit Union" },
    { aba: "241273845", name: "The Catholic Credit Union" },
    { aba: "241273858", name: "Lormet Community Fcu" },
    { aba: "241273861", name: "New Horizon Federal Credit Union" },
    { aba: "241273887", name: "Integrity Federal Credit Union" },
    { aba: "241274077", name: "Firelands Federal Credit Union" },
    { aba: "241274116", name: "Bse Credit Union, Inc." },
    { aba: "241274200", name: "Brewster Federal Credit Union" },
    { aba: "241274268", name: "Northwestern Federal Credit Union" },
    { aba: "241274336", name: "Geauga Credit Union, Inc." },
    { aba: "241274446", name: "Community One Credit Union Of Ohio" },
    { aba: "241274459", name: "Cse Federal Credit Union" },
    { aba: "241274462", name: "Community One Credit Union Of Ohio" },
    { aba: "241274501", name: "Dn Community Federal Credit Union" },
    { aba: "241274514", name: "Golden Circle Cr Union" },
    { aba: "241274569", name: "St Jos Canton Parish Fcu" },
    { aba: "241274598", name: "Stark Federal Cr Union" },
    { aba: "241274608", name: "Superior Savings Credit Union" },
    { aba: "241274624", name: "Teamsters Local 92 Fcu" },
    { aba: "241274653", name: "Carey Poverello Fcu" },
    { aba: "241276910", name: "Impact Credit Union, Inc" },
    { aba: "241278099", name: "Port Conneaut Federal C.u." },
    { aba: "241278109", name: "Falls Catholic C U" },
    { aba: "241278167", name: "Midwest Community Fcu" },
    { aba: "241278293", name: "Kennametal-orwell Employees Fcu" },
    { aba: "241278345", name: "Materion Federal Credit Union" },
    { aba: "241278361", name: "Community Star Credit Union Inc" },
    { aba: "241278387", name: "Community Star Credit Union Inc" },
    { aba: "241278507", name: "Achieve Credit Union, Inc" },
    { aba: "241278659", name: "Hancock Fcu" },
    { aba: "241278662", name: "Millstream Area Cu Inc." },
    { aba: "241278785", name: "Fremont Federal Credit Union" },
    { aba: "241278811", name: "Coshocton Federal Credit Union" },
    { aba: "241279014", name: "Lake Community F.c.u." },
    { aba: "241279111", name: "Credit Union Of Ohio" },
    { aba: "241279292", name: "Quest Federal Credit Union" },
    { aba: "241279548", name: "Topmark Federal Credit Union" },
    { aba: "241279564", name: "Topmark Fcu" },
    { aba: "241279603", name: "Nickel Steel Federal Credit Union" },
    { aba: "241279616", name: "Superior Credit Union, Llc" },
    { aba: "241279687", name: "Gen Fed Financial Credit Union, Inc" },
    { aba: "241279810", name: "Lormet Community Federal Cr Union" },
    { aba: "241279881", name: "Advantage Credit Union, Inc." },
    { aba: "241279920", name: "Gorman Rupp & Assoc Credit Union Inc" },
    { aba: "241279959", name: "Auto Workers Credit Union" },
    { aba: "241280058", name: "Tappan Credit Union, Inc." },
    { aba: "241280252", name: "Friends And Family Credit Union" },
    { aba: "241280281", name: "Friends And Family Credit Union" },
    { aba: "241280294", name: "Superiors Empl Cu" },
    { aba: "241280362", name: "Mcdonald Community Fcu" },
    { aba: "241280430", name: "Cardinal Credit Union, Inc" },
    { aba: "241280498", name: "Sun Federal Credit Union" },
    { aba: "241280511", name: "Minerva Area F.c.u." },
    { aba: "241280582", name: "Christian Family Credit Union, Inc." },
    { aba: "241280634", name: "Golden Circle Credit Union" },
    { aba: "241280647", name: "Dover Phila F.c.u." },
    { aba: "241280650", name: "Dover Phila F.c.u." },
    { aba: "241280715", name: "Credit Union Of Ohio" },
    { aba: "241280731", name: "First Ohio Community Fcu" },
    { aba: "241280883", name: "Bay Area C. U. Inc." },
    { aba: "241280935", name: "Painesville Credit Union" },
    { aba: "241280951", name: "Fasson Employees Fcu" },
    { aba: "241280977", name: "Lake County Educational Federal C.u." },
    { aba: "241280980", name: "Lce Federal Credit Union" },
    { aba: "241281251", name: "Pca Federal Credit Union" },
    { aba: "241281442", name: "Erie Community Federal Credit Union" },
    { aba: "241281471", name: "Vacationland Fed Cr Union" },
    { aba: "241281594", name: "Wayne County Community Fcu" },
    { aba: "241281714", name: "Struthers Fcu" },
    { aba: "241281743", name: "Sylvania Area Federal Credit Union" },
    { aba: "241281772", name: "Your Legacy Federal Credit Union" },
    { aba: "241281824", name: "Sun Federal Cu" },
    { aba: "241281853", name: "Focus Fcu" },
    { aba: "241281882", name: "Champion Credit Union Inc." },
    { aba: "241281976", name: "Eight Fcu" },
    { aba: "241282072", name: "Jeep Country Federal Credit Union" },
    { aba: "241282140", name: "Local 50 Plumbers & Steamfitters Fcu" },
    { aba: "241282153", name: "Glass City Federal C U" },
    { aba: "241282205", name: "Maumee Valley Credit Union" },
    { aba: "241282276", name: "Parish Federal Credit Union" },
    { aba: "241282373", name: "Promedica Federal Credit Union" },
    { aba: "241282412", name: "Sun Federal Cu" },
    { aba: "241282483", name: "Toledo Fire Fighters Fcu" },
    { aba: "241282506", name: "Toledo Metro Fed Cr Union" },
    { aba: "241282522", name: "Toledo Police Fcu" },
    { aba: "241282535", name: "Champion Credit Union" },
    { aba: "241282564", name: "Sun Federal Credit Union" },
    { aba: "241282577", name: "Directions Credit Union" },
    { aba: "241282603", name: "Educational Community Alliance Cu" },
    { aba: "241282658", name: "Ut-muo Federal Credit Union" },
    { aba: "241282713", name: "Superior Credit Union, Inc." },
    { aba: "241282739", name: "Medina County Fcu" },
    { aba: "241282849", name: "Seven Seventeen C.u." },
    { aba: "241282991", name: "Best Reward Cu" },
    { aba: "241283071", name: "Wes Credit Union, Inc." },
    { aba: "241283181", name: "Associated School Employees Cu" },
    { aba: "241283194", name: "Cardinal Credit Union, Inc" },
    { aba: "241283204", name: "Doy Federal Credit Union" },
    { aba: "241283372", name: "Rsc Yo/cl Offices Fcu" },
    { aba: "241283408", name: "Cardinal Credit Union, Inc" },
    { aba: "241283479", name: "Yha South Unit Fcu" },
    { aba: "241283495", name: "Cardinal Credit Union, Inc" },
    { aba: "241283521", name: "Cardinal Credit Union, Inc" },
    { aba: "241283880", name: "Firefighters Community Credit Union" },
    { aba: "241283945", name: "Commodore Perry Cr Union" },
    { aba: "242070791", name: "First Financial Bank" },
    { aba: "242070885", name: "Wesbanco Bank Inc" },
    { aba: "242070966", name: "Pnc Bank, Na" },
    { aba: "242071004", name: "First Financial Bank" },
    { aba: "242071017", name: "Cincinnati Federal" },
    { aba: "242071033", name: "Cincinnatus S&l Co" },
    { aba: "242071046", name: "Cincinnati Federal" },
    { aba: "242071237", name: "Pnc Bank, Na" },
    { aba: "242071282", name: "First Commenwealth Bank" },
    { aba: "242071295", name: "First Financial Bank" },
    { aba: "242071305", name: "Pnc Bank, Na" },
    { aba: "242071473", name: "First Financial Bank" },
    { aba: "242071509", name: "Guardian Savings Bank" },
    { aba: "242071541", name: "Forcht Bank, National Association" },
    { aba: "242071583", name: "Pnc Bank, Na" },
    { aba: "242071664", name: "Eagle Savings Bank" },
    { aba: "242071758", name: "Fifth Third Bank" },
    { aba: "242071855", name: "Warsaw Federal S&l" },
    { aba: "242071897", name: "Wesbanco Bank, Inc" },
    { aba: "242072168", name: "New Foundation Savings Bank" },
    { aba: "242072391", name: "The Miami S&l Co" },
    { aba: "242072663", name: "Valley Central Bank" },
    { aba: "242072715", name: "Pnc Bank, Na" },
    { aba: "242074425", name: "First Financial Bank" },
    { aba: "242076355", name: "Presidents Federal Credit Union" },
    { aba: "242076465", name: "Emery Emp Federal Credit Union" },
    { aba: "242076559", name: "Children's Medical Center Fcu" },
    { aba: "242076562", name: "Health Alliance Fcu" },
    { aba: "242076588", name: "Kemba Credit Union, Inc." },
    { aba: "242076591", name: "Cincinnati Police Fed Cu" },
    { aba: "242076601", name: "Trupartner Credit Union" },
    { aba: "242076643", name: "Cincinnati Interagency Fcu" },
    { aba: "242076656", name: "Cinfed Federal Credit Union" },
    { aba: "242076669", name: "Postal Family Credit Union, Inc." },
    { aba: "242076672", name: "Greater Cincinnati Credit Union, Inc" },
    { aba: "242076711", name: "Trupartner Credit Union" },
    { aba: "242076753", name: "Emery Emp Fed Cr Union" },
    { aba: "242076779", name: "Trupartner Credit Union" },
    { aba: "242076805", name: "Whitewater Community Credit Union" },
    { aba: "242076821", name: "General Electric Credit Union" },
    { aba: "242076889", name: "Kemba Credit Union" },
    { aba: "242076973", name: "Kemba Credit Union" },
    { aba: "242077082", name: "Cinfed Emp. Federal Credit Union" },
    { aba: "242077121", name: "Aurgroup Financial Credit Union" },
    { aba: "242077312", name: "Sharefax Credit Union Inc" },
    { aba: "242077338", name: "New Horizons Credit Union Inc." },
    { aba: "242077396", name: "St. James Parish Credit Union" },
    { aba: "242077422", name: "Greater Cincinnati School Empl. Cu" },
    { aba: "242077503", name: "Ups Credit Union" },
    { aba: "242077529", name: "Members Trust Federal Credit Union" },
    { aba: "242077574", name: "Christian Family Credit Union, Inc." },
    { aba: "242077626", name: "W T Community Federal Credit Union" },
    { aba: "242086031", name: "Kemba Credit Union, Inc" },
    { aba: "242086361", name: "Ohio Valley Federal Credit Union" },
    { aba: "242086484", name: "Local Union 392 Federal Credit Union" },
    { aba: "242170002", name: "City National Bank" },
    { aba: "242170028", name: "City National Bank Of West Virginia" },
    { aba: "242170031", name: "Pnc Bank, Na" },
    { aba: "242170086", name: "Citizens Fed Ky S&l" },
    { aba: "242170125", name: "Cincinnati Federal" },
    { aba: "242170170", name: "Kentucky Bank" },
    { aba: "242170248", name: "Truist Bank" },
    { aba: "242170280", name: "First Federal Savings And Loan" },
    { aba: "242170316", name: "Wesbanco Bank Wheeling" },
    { aba: "242170374", name: "Home Savings Bank, Fsb" },
    { aba: "242170426", name: "Home Federal Bank" },
    { aba: "242170442", name: "First Federal Savings And Loan Asso" },
    { aba: "242170455", name: "City National Bank Of West Virginia" },
    { aba: "242170523", name: "Kentucky Bank" },
    { aba: "242170536", name: "Peoples Bank" },
    { aba: "242170549", name: "Blue Grass Fed. S&l" },
    { aba: "242170565", name: "First State Bk Of The Southeast,inc" },
    { aba: "242170646", name: "Winchester Federal Savings Bank" },
    { aba: "242175528", name: "Members Choice Credit Union" },
    { aba: "242175557", name: "Ashland Inc. Empl Cu" },
    { aba: "242175612", name: "Bluegrass Community Fcu" },
    { aba: "242175735", name: "C & O United Credit Union" },
    { aba: "242175751", name: "L&n Federal Credit Union" },
    { aba: "242175803", name: "Cove Federal Credit Union" },
    { aba: "242175874", name: "Northern Kentucky Educators Fcu" },
    { aba: "242175955", name: "Health Education Federal Cu" },
    { aba: "242175984", name: "Greater Kentucky Credit Union" },
    { aba: "242176006", name: "Members Heritage Credit Union" },
    { aba: "242176048", name: "Lexington Postal Community Cu" },
    { aba: "242176051", name: "Metro Employees Credit Union" },
    { aba: "242176129", name: "University Of Ky Fcu" },
    { aba: "242176187", name: "A R H Empl Cu" },
    { aba: "242176255", name: "Eastern Kentucky Federal Cu" },
    { aba: "242186180", name: "Applachian Federal Credit Union" },
    { aba: "242186258", name: "Kue Federal Credit Union" },
    { aba: "242186326", name: "Morehead Community Federal Credit Un" },
    { aba: "242270698", name: "Community Savings Bank" },
    { aba: "242270737", name: "Brookville Bldg. & Svgs. A" },
    { aba: "242271969", name: "Covington Savings & Loan" },
    { aba: "242272146", name: "Community Savings Bank" },
    { aba: "242272159", name: "Greenville Federal" },
    { aba: "242272188", name: "Fifth Third Bank" },
    { aba: "242272191", name: "First Financial Bank" },
    { aba: "242272214", name: "Harrison B&l Assn" },
    { aba: "242272227", name: "National Cooperative Bank, N.a." },
    { aba: "242272269", name: "Liberty Bank" },
    { aba: "242272272", name: "First Financial Bank" },
    { aba: "242272285", name: "Southern Hills Community Bank" },
    { aba: "242272311", name: "Union Savings Bank" },
    { aba: "242272324", name: "Liberty Savings Bank" },
    { aba: "242272337", name: "Southern Hills Community Bank" },
    { aba: "242272366", name: "Peoples First Savings Bank" },
    { aba: "242272405", name: "Valley Central Bank" },
    { aba: "242272447", name: "Fifth Third Bank" },
    { aba: "242272463", name: "New Carlisle Federal Savings Bank" },
    { aba: "242272489", name: "Park National Bank" },
    { aba: "242272612", name: "Park National Bank" },
    { aba: "242272625", name: "Peoples Bank" },
    { aba: "242272670", name: "Southern Hills Community Bank" },
    { aba: "242272793", name: "The Richwood Banking Company" },
    { aba: "242272816", name: "Wesbanco Bank Inc" },
    { aba: "242272861", name: "Monroe Fed. S&l Assn." },
    { aba: "242272874", name: "First Financial Bank" },
    { aba: "242272887", name: "Versailles S&l Co." },
    { aba: "242272968", name: "Fifth Third Bank" },
    { aba: "242274403", name: "Wilmington Savings Bank" },
    { aba: "242276362", name: "Day Air Credit Union, Inc." },
    { aba: "242277675", name: "Abbey Credit Union Inc" },
    { aba: "242277769", name: "Code Fcu" },
    { aba: "242277808", name: "Day Air Credit Union, Inc." },
    { aba: "242277824", name: "Firefighters & Company Fcu" },
    { aba: "242277853", name: "River Valley Credit Union Inc" },
    { aba: "242277882", name: "Heartland Federal Credit Union" },
    { aba: "242277895", name: "Freedom First Credit Union" },
    { aba: "242277905", name: "Pathways Financial Credit Union, Inc" },
    { aba: "242277950", name: "Firefighters & Company Fcu" },
    { aba: "242278043", name: "Firefighters & Company Fcu" },
    { aba: "242278056", name: "Bridge Credit Union, Inc." },
    { aba: "242278072", name: "Universal 1 Credit Union, Inc." },
    { aba: "242278124", name: "Heartland Federal Credit Union" },
    { aba: "242278153", name: "Firefighters & Company Fcu" },
    { aba: "242278179", name: "Pathways Financial Credit Union, Inc" },
    { aba: "242278205", name: "Day Air Credit Union, Inc" },
    { aba: "242278247", name: "Day-met Credit Union" },
    { aba: "242278331", name: "Members Choice Credit Union" },
    { aba: "242278360", name: "Telhio Credit Union" },
    { aba: "242278409", name: "Allwealth Federal Credit Union" },
    { aba: "242278616", name: "K H Network Credit Union" },
    { aba: "242278713", name: "River Valley Credit Union Inc" },
    { aba: "242278742", name: "Mid Usa Credit Union" },
    { aba: "242278755", name: "Atrium Credit Union, Inc." },
    { aba: "242278768", name: "Middletown City Empl Fcu" },
    { aba: "242278771", name: "Middletown Area Schools Credit Union" },
    { aba: "242278797", name: "Sorg Bay West Federal Cr Un" },
    { aba: "242278823", name: "Desco Fcu" },
    { aba: "242278878", name: "Miami University Community Fcu" },
    { aba: "242278920", name: "S C F E Cr Union" },
    { aba: "242279068", name: "Internatl Harvester Em Cu" },
    { aba: "242279097", name: "Ih Credit Union, Inc" },
    { aba: "242279110", name: "Springfield Postal" },
    { aba: "242279136", name: "Edison Credit Union, Inc." },
    { aba: "242279149", name: "Internatl Harvester Em Cu" },
    { aba: "242279181", name: "Internatl Harvester Em Cu" },
    { aba: "242279275", name: "Htm Area Credit Union" },
    { aba: "242279408", name: "Wright Patt Credit Union Inc" },
    { aba: "242279437", name: "Yellow Springs Community Fcu" },
    { aba: "242370776", name: "Mercer Savings Bank" },
    { aba: "242372745", name: "First Bank Richmond" },
    { aba: "242372758", name: "Peoples Federal S&l Assn" },
    { aba: "242374361", name: "Home Savings Bank Of Wapakoneta" },
    { aba: "242376411", name: "Dynamic Federal Credit Union" },
    { aba: "242386414", name: "The Way Credit Union, Inc." },
    { aba: "243073616", name: "Nextier Bank, N.a." },
    { aba: "243073632", name: "Wesbanco Bank Inc" },
    { aba: "243073852", name: "First National Bank Of Pennsylvania" },
    { aba: "243073904", name: "Dollar Bank" },
    { aba: "243073959", name: "Slovak Savings Bank" },
    { aba: "243074042", name: "Farmers National Bank Of Emlenton" },
    { aba: "243074071", name: "West View Savings Bank" },
    { aba: "243074385", name: "Dollar Bank" },
    { aba: "243080573", name: "Allegent Community Fcu" },
    { aba: "243083198", name: "Alcoa Credit Union" },
    { aba: "243083208", name: "Acba Federal Credit Union" },
    { aba: "243083211", name: "Alcose Cu" },
    { aba: "243083224", name: "City Co Federal Credit Union" },
    { aba: "243083237", name: "Clearview Federal Credit Union" },
    { aba: "243083240", name: "Allegent Community Fcu" },
    { aba: "243083266", name: "Allegheny Health Services E.f.c.u." },
    { aba: "243083305", name: "At&t Empl Pittsburghpa Fcu" },
    { aba: "243083347", name: "Omega Federal Credit Union" },
    { aba: "243083363", name: "B.i. Federal" },
    { aba: "243083428", name: "Carnegie Mellon University Fcu" },
    { aba: "243083512", name: "Omega Federal Credit Union" },
    { aba: "243083538", name: "Financial Advantage Fcu" },
    { aba: "243083583", name: "Eqt Federal Credit Union" },
    { aba: "243083664", name: "Allegheny Valley Fcu" },
    { aba: "243083677", name: "Clearview Federal Credit Union" },
    { aba: "243083745", name: "Hill District Fcu" },
    { aba: "243083774", name: "Iron Workers Federal Credit Union" },
    { aba: "243083981", name: "Elliott Emp No 1 Fed Cr Union" },
    { aba: "243084032", name: "Mt Lebannon Fcu" },
    { aba: "243084074", name: "North Districts Community Cu" },
    { aba: "243084100", name: "P.a.a.c. Transit Division Fcu" },
    { aba: "243084207", name: "Usx Federal Credit Union" },
    { aba: "243084249", name: "Greater Pittsburgh Police Fcu" },
    { aba: "243084252", name: "Pittsburgh City Hall Efcu" },
    { aba: "243084281", name: "Chrome Federal Credit Union" },
    { aba: "243084294", name: "Riverset Credit Union" },
    { aba: "243084333", name: "Omega Federal Credit Union" },
    { aba: "243084359", name: "Pgh Firefighters Fcu" },
    { aba: "243084469", name: "Pittsburgh Fcu" },
    { aba: "243084595", name: "Swindell Dressler Credit Union" },
    { aba: "243084634", name: "Ukrainian Selfreliance Of W. Pa Fcu" },
    { aba: "243084650", name: "Greater Pittsburgh Fcu" },
    { aba: "243084663", name: "Va Pittsburgh Empl Fcu" },
    { aba: "243084676", name: "M E M Federal Credit Union" },
    { aba: "243084689", name: "Usx Federal Credit Union" },
    { aba: "243084760", name: "Pennsylvania-american Water Fcu" },
    { aba: "243085950", name: "Omega Federal Credit Union" },
    { aba: "243086111", name: "Va Pittsburgh Empl Fcu" },
    { aba: "243086166", name: "Tri-valley Serv Fcu" },
    { aba: "243086221", name: "Century Heritage Fcu" },
    { aba: "243086496", name: "I.b.e.w. Local No. 5 Fcu" },
    { aba: "243086580", name: "Usx Federal Credit Union" },
    { aba: "243086661", name: "Avh Federal Credit Union" },
    { aba: "243086784", name: "Greater Pittsburgh Fed. Cr. Union" },
    { aba: "243086807", name: "Fisher Scientific Employees Fcu" },
    { aba: "243270671", name: "Belmont Savings Bank" },
    { aba: "243272941", name: "Woodsfield Savings Bank" },
    { aba: "243278534", name: "Ohio Valley Community Fcu" },
    { aba: "243278709", name: "Softite Community Fcu" },
    { aba: "243279203", name: "S & J School Efcu" },
    { aba: "243279229", name: "Valley One Community Federal Cu" },
    { aba: "243279449", name: "Yorkville Community Fcu" },
    { aba: "243373112", name: "Brentwood Bank" },
    { aba: "243373141", name: "Brentwood Bank" },
    { aba: "243373170", name: "Cfsbank" },
    { aba: "243373206", name: "S & T Bank" },
    { aba: "243373222", name: "Wesbanco Bank Inc" },
    { aba: "243373248", name: "Marquette Savings Bank" },
    { aba: "243373280", name: "Armstrong Cty B&l" },
    { aba: "243373332", name: "Greenville Savings Bank" },
    { aba: "243373358", name: "Indiana First Savings Bank" },
    { aba: "243373387", name: "Westmoreland Fed S & L" },
    { aba: "243373442", name: "Community Bank" },
    { aba: "243373471", name: "First National Bank Of Pennsylvania" },
    { aba: "243374108", name: "Farmers Building And Savings Bank" },
    { aba: "243374124", name: "Sewickley Savings Bank" },
    { aba: "243374153", name: "Slovenian S&l Asn" },
    { aba: "243374218", name: "Northwest Bank" },
    { aba: "243374221", name: "Washington Financial Bank" },
    { aba: "243374234", name: "First Federal Of Greene County" },
    { aba: "243374250", name: "Standard Bank, Pasb" },
    { aba: "243374263", name: "Compass Federal Savings Bank" },
    { aba: "243374467", name: "Dollar Bank" },
    { aba: "243379462", name: "Clearview Federal Credit Union" },
    { aba: "243379475", name: "A & S Fcu" },
    { aba: "243379514", name: "Friendly Fed Cu" },
    { aba: "243379556", name: "Mon Valley Comm. F.c.u." },
    { aba: "243379585", name: "New Alliance Fcu" },
    { aba: "243379598", name: "New Alliance Fcu" },
    { aba: "243379624", name: "Png Northern Fcu" },
    { aba: "243379640", name: "West Penn P & P Fcu" },
    { aba: "243379653", name: "Friendly Fed Cu" },
    { aba: "243379682", name: "West Penn P & P Fcu" },
    { aba: "243379695", name: "Clearview Federal Credit Union" },
    { aba: "243379705", name: "West-aircomm Fcu" },
    { aba: "243379718", name: "Beaver Valley Fcu" },
    { aba: "243379734", name: "Beaver Falls Teachers Fcu" },
    { aba: "243379831", name: "Allegheny-ludlum Brackenridge Fcu" },
    { aba: "243379860", name: "Visionary Federal Credit Union" },
    { aba: "243379873", name: "Priority First Federal Credit Union" },
    { aba: "243379899", name: "Frick Tri- County Fcu" },
    { aba: "243379925", name: "Armco Credit Union" },
    { aba: "243379983", name: "Cal Ed Fcu" },
    { aba: "243380008", name: "United Community Fcu" },
    { aba: "243380040", name: "Southwest Communities Fcu" },
    { aba: "243380082", name: "Aps Federal Credit Union" },
    { aba: "243380105", name: "Colfax Power Cu" },
    { aba: "243380150", name: "Clairton Works Federal Credit Union" },
    { aba: "243380192", name: "Clarion Federal Credit Union" },
    { aba: "243380260", name: "Glass Cap Fcu" },
    { aba: "243380273", name: "Allegheny Central Employees Fcu" },
    { aba: "243380341", name: "Corry Area Schools Fcu" },
    { aba: "243380354", name: "Corry - Jamestown C U" },
    { aba: "243380370", name: "Corry Federal Credit Union" },
    { aba: "243380396", name: "Ppg & Associates Fcu" },
    { aba: "243380422", name: "Derry Area F.c.u." },
    { aba: "243380448", name: "Frick Tri-county Fcu" },
    { aba: "243380451", name: "United Community Fcu" },
    { aba: "243380477", name: "Irvin Works Fcu" },
    { aba: "243380516", name: "Century Heritage Federal C.u." },
    { aba: "243380529", name: "Ure Federal Credit Union" },
    { aba: "243380833", name: "Americo Federal Credit Union" },
    { aba: "243380846", name: "Erie Firefighters Fcu" },
    { aba: "243380875", name: "Erie Community Credit Union" },
    { aba: "243380927", name: "Erie Federal Credit Union" },
    { aba: "243380930", name: "Erie Times Federal Credit Union" },
    { aba: "243381065", name: "I.b.e.w. Local #56 Federal Cdt Union" },
    { aba: "243381117", name: "Widget Federal Credit Union" },
    { aba: "243381133", name: "Locomotive & Control Employees Fcu" },
    { aba: "243381146", name: "Erie Federal Credit Union" },
    { aba: "243381337", name: "St Vincent Erie Fcu" },
    { aba: "243381421", name: "Armco Credit Union" },
    { aba: "243381447", name: "Phb Employees Federel Credit Union" },
    { aba: "243381476", name: "Armstrong Associates F.c.u." },
    { aba: "243381502", name: "Franklin-oil Region Credit Union" },
    { aba: "243381515", name: "Galaxy Federal Credit Union" },
    { aba: "243381531", name: "Freedom United Fcu" },
    { aba: "243381586", name: "Lake Erie Community Federal C. U." },
    { aba: "243381599", name: "Copper & Glass Federal Credit Union" },
    { aba: "243381641", name: "Pheple Federal Credit Union" },
    { aba: "243381696", name: "Hempfield Area Fcu" },
    { aba: "243381735", name: "Stanwood Area Federal Credit Union" },
    { aba: "243381764", name: "Firstenergy Choice Federal Cu" },
    { aba: "243381777", name: "Pheple Federal Credit Union" },
    { aba: "243381793", name: "Pheple Federal Credit Union" },
    { aba: "243381803", name: "Westmoreland Water Fcu" },
    { aba: "243381816", name: "Bessemer System Fcu" },
    { aba: "243381845", name: "Grove City Area Fcu" },
    { aba: "243381939", name: "Norwin Teachers Fcu" },
    { aba: "243381984", name: "Elliott Empl No 1" },
    { aba: "243381997", name: "Jdmh Federal Credit Union" },
    { aba: "243382080", name: "Armstrong County Fed" },
    { aba: "243382093", name: "Armstrong Cnty Fed Emp Fed Credit Un" },
    { aba: "243382103", name: "K G C Fcu" },
    { aba: "243382132", name: "Latrobe Federal Credit Union" },
    { aba: "243382161", name: "Latrobe Area Hospital Fcu" },
    { aba: "243382187", name: "Lesco Emplys Fcu" },
    { aba: "243382226", name: "Allegheny Metal Federal Credit Union" },
    { aba: "243382268", name: "Vasco Fcu" },
    { aba: "243382365", name: "Mckeepsport Bell Fcu" },
    { aba: "243382394", name: "Parkview Community Federal Cu" },
    { aba: "243382527", name: "Meadville Area Fcu" },
    { aba: "243382556", name: "Clearview Federal Credit Union" },
    { aba: "243382572", name: "Usx Federal Credit Union" },
    { aba: "243382653", name: "Valley 1st Community Fed C.u." },
    { aba: "243382747", name: "Tri - Boro Fcu" },
    { aba: "243382763", name: "Franklin Regional Schools Fcu" },
    { aba: "243382802", name: "R S Bellco Fcu" },
    { aba: "243382815", name: "First Choice Federal Credit Union" },
    { aba: "243382831", name: "Gnc Community Fcu" },
    { aba: "243382873", name: "New Castle Bellco Fcu" },
    { aba: "243382909", name: "Family 1st Federal Credit Union" },
    { aba: "243382912", name: "Shenango China Area Fcu" },
    { aba: "243382925", name: "Allegheny Kiski Postal Fed Credit Un" },
    { aba: "243382938", name: "Allegheny Kiski Postal Cu" },
    { aba: "243382954", name: "Allegheny Kiski Postal Cu" },
    { aba: "243383021", name: "Bva Federal Credit Union" },
    { aba: "243383047", name: "Newell Federal Credit Union" },
    { aba: "243383063", name: "North East Welch Federal C.u." },
    { aba: "243383186", name: "Usx Federal Credit Union" },
    { aba: "243384910", name: "Pa Healthcare Credit Union" },
    { aba: "243384965", name: "Mercer County Community Fcu" },
    { aba: "243384978", name: "Pennstar Fcu" },
    { aba: "243384981", name: "Sru Federal Credit Union" },
    { aba: "243385058", name: "Community Connect Fcu" },
    { aba: "243385139", name: "Fayette County School Employees Cu" },
    { aba: "243385142", name: "Fayette Fed Efcu" },
    { aba: "243385155", name: "Frick Tri-county Fcu" },
    { aba: "243385249", name: "N F G Number 2 Fcu" },
    { aba: "243385252", name: "Kinzua Federal Credit Union" },
    { aba: "243385362", name: "Jessop Community Fcu" },
    { aba: "243385427", name: "Wabellco Fcu" },
    { aba: "243385443", name: "Washington Area Teachers Fcu" },
    { aba: "243385456", name: "Chrome Federal Credit Union" },
    { aba: "243385485", name: "Moonlight Credit Union" },
    { aba: "243385508", name: "United Community Fcu" },
    { aba: "243385919", name: "Ambridge Area Fcu" },
    { aba: "243385935", name: "Keystone United Methodist F.c.u." },
    { aba: "243386523", name: "Blackhawk Federal Credit Union" },
    { aba: "243386620", name: "U.a.l.u. 354 Fed. C.u." },
    { aba: "243386633", name: "Tri State Rail Fcu" },
    { aba: "243470183", name: "First Mutual Bank, Fsb" },
    { aba: "243470196", name: "Peoples Bank" },
    { aba: "243474273", name: "Hancock County Savings Bank, Fsb" },
    { aba: "243474312", name: "Parkvale Bank" },
    { aba: "243474325", name: "United Bank" },
    { aba: "243475829", name: "Bayer Heritage Fcu" },
    { aba: "243485631", name: "Marshall County Fcu" },
    { aba: "243485660", name: "Hancock School Emplys. Fcu" },
    { aba: "243485673", name: "First Choice America Community Fcu" },
    { aba: "243485686", name: "Strip Steel Community Fcu" },
    { aba: "243485699", name: "Tin Mill Empl Fcu" },
    { aba: "243485767", name: "O C P S Fcu" },
    { aba: "243485806", name: "Teamster Local 697 Fcu" },
    { aba: "243485880", name: "Bayer Heritage Federal Credit Union" },
    { aba: "243486067", name: "Long Reach Empl Fcu" },
    { aba: "244072166", name: "New York Community Bank" },
    { aba: "244077022", name: "Pathways Financial Credit Union, Inc" },
    { aba: "244077035", name: "B M I Federal Credit Union" },
    { aba: "244077077", name: "Pathways Financial Credit Union, Inc" },
    { aba: "244077093", name: "Pathways Financial Credit Union, Inc" },
    { aba: "244077129", name: "Ohio Teamsters Credit Union" },
    { aba: "244077132", name: "First Service Federal Credit Union" },
    { aba: "244077226", name: "Columbus Metro F C U" },
    { aba: "244077255", name: "Cme Federal Credit Union" },
    { aba: "244077271", name: "Education First Credit Union" },
    { aba: "244077323", name: "Telhio Credit Union" },
    { aba: "244077446", name: "Franklin Co School Emp Cu" },
    { aba: "244077475", name: "Telhio Credit Union, Inc" },
    { aba: "244077556", name: "Kemba Financial Credit Union, Inc." },
    { aba: "244077637", name: "Axos Bank" },
    { aba: "244077682", name: "First Service Fed Cr Un" },
    { aba: "244077695", name: "Odjfs Federal Credit Union" },
    { aba: "244077776", name: "First Service Fcu" },
    { aba: "244077815", name: "Ohiohealth Federal Credit Union" },
    { aba: "244077886", name: "Credit Union Of Ohio" },
    { aba: "244077899", name: "State Highway Patrol Fcu" },
    { aba: "244077909", name: "Bridge Credit Union" },
    { aba: "244078018", name: "Pathways Financial Credit Union, Inc" },
    { aba: "244078021", name: "Pathways Financial Credit Union, Inc" },
    { aba: "244084264", name: "Corporate One Federal Cu" },
    { aba: "244170165", name: "Citizens Fed S & L" },
    { aba: "244170259", name: "Community Savings" },
    { aba: "244170275", name: "Peoples Bank" },
    { aba: "244170877", name: "United Midwest Savings Bank" },
    { aba: "244170880", name: "Fidelity Fed Savings & Loa" },
    { aba: "244171245", name: "Fairfield Federal Savings" },
    { aba: "244171575", name: "First Federal Savings Of Newark" },
    { aba: "244171902", name: "Peoples Savings Bank" },
    { aba: "244171915", name: "Perpetual Federal Savings Bank" },
    { aba: "244172008", name: "Peoples Saving & Loan Co" },
    { aba: "244172082", name: "Park National Bank" },
    { aba: "244172095", name: "Park National Bank" },
    { aba: "244174352", name: "Southeastern Ohio Credit Union, Inc." },
    { aba: "244174365", name: "Champion Federal Credit Union" },
    { aba: "244179027", name: "Harvest Federal Credit Union" },
    { aba: "244179043", name: "Fiberglas Federal Cr Un" },
    { aba: "244179409", name: "Lan-fair Federal Credit Union" },
    { aba: "244180139", name: "Pillar Credit Union" },
    { aba: "244180223", name: "Scott Associates Cu, Inc." },
    { aba: "244180236", name: "Pathways Financial Credit Union, Inc" },
    { aba: "244180537", name: "Ces Credit Union Inc" },
    { aba: "244180566", name: "Morrow County Federal Credit Union" },
    { aba: "244180663", name: "Truecore Federal Credit Union" },
    { aba: "244180689", name: "Hopewell F.c.u." },
    { aba: "244183615", name: "Genesis Employees Credit Union" },
    { aba: "244183631", name: "Ohio Educational Credit Uniion" },
    { aba: "244270191", name: "First Mutual Bank, Fsb" },
    { aba: "244271543", name: "Nelsonville Home & Savings" },
    { aba: "244273826", name: "Ohio University Credit Union" },
    { aba: "244274100", name: "Kraton Belpre Fcu" },
    { aba: "244274731", name: "Mead Employees Cu, Inc" },
    { aba: "244274744", name: "Chivaho Fcu" },
    { aba: "244274786", name: "Homeland Credit Union, Inc." },
    { aba: "244278863", name: "Kyger Creek Credit Union" },
    { aba: "244280107", name: "Riverview Credit Union, Inc" },
    { aba: "244281193", name: "Atomic Cu Inc." },
    { aba: "251072968", name: "Townebank" },
    { aba: "251082136", name: "Call Federal Credit Union" },
    { aba: "251082149", name: "Connects Federal Credit Union" },
    { aba: "251082194", name: "Henrico Federal Credit Union" },
    { aba: "251082233", name: "Argent Federal Credit Union" },
    { aba: "251082288", name: "Martinsville Du Pont Ecu, Inc.dba V" },
    { aba: "251082327", name: "Peoples Advantage Fcu" },
    { aba: "251082398", name: "Call Fcu" },
    { aba: "251082411", name: "Richmond Virginia Fire Police Cu Inc" },
    { aba: "251082424", name: "Partners Financial Fcu" },
    { aba: "251082466", name: "Credit Union Of Richmond Inc." },
    { aba: "251082479", name: "Richmond Heritage Fcu" },
    { aba: "251082495", name: "Rva Financial Federal Credit Union" },
    { aba: "251082589", name: "Baylands Family Credit Union, Inc" },
    { aba: "251082615", name: "Virginia Credit Union, Inc." },
    { aba: "251082644", name: "Dominion Energy Credit Union" },
    { aba: "251082673", name: "Va. United Methodist C.u." },
    { aba: "251082712", name: "Chartway Federal Credit Union" },
    { aba: "251472542", name: "Atlantic Union Bank" },
    { aba: "251472555", name: "Atlantic Union Bank" },
    { aba: "251472636", name: "M & T Bank" },
    { aba: "251472759", name: "Martinsville First Savings Bank" },
    { aba: "251472762", name: "Blue Ridge Bank, Na" },
    { aba: "251472885", name: "Virginia Commonwealth Bank" },
    { aba: "251473075", name: "National Bank Of Blacksburg" },
    { aba: "251473088", name: "Atlantic Union Bank" },
    { aba: "251473091", name: "City National Bank Of West Virginia" },
    { aba: "251473512", name: "First Sentinel Bank" },
    { aba: "251473758", name: "City National Bank Of West Virginia" },
    { aba: "251473787", name: "Atlantic Union Bank" },
    { aba: "251479927", name: "Midway Federal Credit Union" },
    { aba: "251479930", name: "Beacon Credit Union" },
    { aba: "251480165", name: "Virginia Credit Union, Inc" },
    { aba: "251480178", name: "Virginia Credit Union, Inc" },
    { aba: "251480181", name: "Uva Community Credit Union" },
    { aba: "251480288", name: "Nae Federal Credit Union" },
    { aba: "251480291", name: "Nae Federal Credit Union" },
    { aba: "251480301", name: "St Matthews Federal Credit Union" },
    { aba: "251480327", name: "Virginia Credit Union, Inc." },
    { aba: "251480372", name: "Jackson River Community Credit Union" },
    { aba: "251480398", name: "Topside Federal Credit Union" },
    { aba: "251480453", name: "Martinsville Du Pont Ecu, Inc. Dba V" },
    { aba: "251480466", name: "Urw Community Fcu" },
    { aba: "251480482", name: "Urw Community Federal Credit Union" },
    { aba: "251480563", name: "1st Advantage Federal Credit Union" },
    { aba: "251480576", name: "Fort Lee Federal Credit Union" },
    { aba: "251480592", name: "Bronco Federal Credit Union" },
    { aba: "251480631", name: "Front Royal Federal Credit Union" },
    { aba: "251480712", name: "Hampton Road Educators' Credit Union" },
    { aba: "251480725", name: "Hampton Va Federal Credit Union" },
    { aba: "251480738", name: "Langley Federal Credit Union" },
    { aba: "251480806", name: "Park View Federal Credit Union" },
    { aba: "251480848", name: "Hopewell Chemical Fcu" },
    { aba: "251480851", name: "Peoples Advantage Fcu" },
    { aba: "251480877", name: "Kraftsman Federal Credit Union" },
    { aba: "251480880", name: "Vantage Point Fcu" },
    { aba: "251481041", name: "Lynchburg Municipal Employees Fcu" },
    { aba: "251481067", name: "Beacon Credit Union" },
    { aba: "251481122", name: "Beacon Credit Union" },
    { aba: "251481148", name: "Mountain Empire Federal Credit Union" },
    { aba: "251481180", name: "Martinsville Du Pont Ecu, Inc. Dba V" },
    { aba: "251481216", name: "Augusta County Federal Credit Union" },
    { aba: "251481229", name: "Celco Federal Credit Union" },
    { aba: "251481342", name: "Newport News Municipal Employees Cu" },
    { aba: "251481355", name: "Virginia Educators' Credit Union" },
    { aba: "251481368", name: "Bayport Credit Union" },
    { aba: "251481384", name: "Riverside Health System Employees Cu" },
    { aba: "251481423", name: "Dollar Bank, Federal Savings Bank" },
    { aba: "251481478", name: "Summit Hampton Roads Fcu" },
    { aba: "251481614", name: "Chartway Federal Credit Union" },
    { aba: "251481627", name: "Abnb Federal Credit Union" },
    { aba: "251481643", name: "Norfolk Fire Department Fcu" },
    { aba: "251481656", name: "Prime Care Credit Union" },
    { aba: "251481669", name: "N A E Fcu" },
    { aba: "251481672", name: "Chartway Federal Credit Union" },
    { aba: "251481711", name: "Nma Federal Credit Union" },
    { aba: "251481740", name: "N A E Fcu" },
    { aba: "251481766", name: "Langley Federal Credit Union" },
    { aba: "251481779", name: "Nma Federal Credit Union" },
    { aba: "251481902", name: "Petersburg Fed Reformatory Cu Inc" },
    { aba: "251481915", name: "Virginia State University Fcu" },
    { aba: "251481928", name: "Abnb Federal Credit Union" },
    { aba: "251481957", name: "Northern Star Credit Union, Inc." },
    { aba: "251481986", name: "Portsmouth Teachers Fcu" },
    { aba: "251481999", name: "P F D Firefighters Cu Inc" },
    { aba: "251482066", name: "First Nrv Federal Credit Union" },
    { aba: "251482095", name: "Henrico Federal Creidt Union" },
    { aba: "251482833", name: "Member One Federal Credit Union" },
    { aba: "251482859", name: "Roanoke Valley Community Fcu" },
    { aba: "251482891", name: "Blue Eagle Credit Union" },
    { aba: "251482914", name: "Fedstar Federal Credit Union" },
    { aba: "251482927", name: "Roanoke Valley Community Fcu" },
    { aba: "251482998", name: "Kemba Roanoke Federal Credit Union" },
    { aba: "251483010", name: "Roanoke Cty School Emp Fcu" },
    { aba: "251483023", name: "Freedom First Federal Credit Union" },
    { aba: "251483078", name: "Salem Vamc Federal Credit Union" },
    { aba: "251483159", name: "Metropolitan Church Fcu" },
    { aba: "251483175", name: "Planters Federal Credit Union" },
    { aba: "251483214", name: "Virginia Beach Schools Fcu" },
    { aba: "251483230", name: "Abnb Federal Credit Union" },
    { aba: "251483243", name: "Virginia Beach Postal Fcu" },
    { aba: "251483256", name: "Beach Municipal Federal Cu." },
    { aba: "251483311", name: "Dupont Community Credit Union" },
    { aba: "251483324", name: "Augusta Health Care Credit Un, Inc." },
    { aba: "251483340", name: "Baylands Family Credit Union, Inc." },
    { aba: "251484459", name: "Ila Federal Credit Union" },
    { aba: "251484514", name: "Central Virginia Fcu" },
    { aba: "251484941", name: "Martinsville Dupont Empl Cu Dba Vsc" },
    { aba: "251573182", name: "Citizens Bank Of Morgantown" },
    { aba: "251573234", name: "Huntington Federal Savings Bank" },
    { aba: "251578705", name: "Home Federal Credit Union" },
    { aba: "251578721", name: "Clarksburg Area Postal Employees Fcu" },
    { aba: "251578763", name: "Harrison County Federal Credit Union" },
    { aba: "251578776", name: "Hope Fcu" },
    { aba: "251578815", name: "United Hospital Center Fcu" },
    { aba: "251578844", name: "Fairmont Federal Credit Union" },
    { aba: "251579050", name: "Morgantown Aes Fcu" },
    { aba: "251579089", name: "The United Fcu" },
    { aba: "251579092", name: "Tri-ag(wv) Federal Credit Union" },
    { aba: "251579102", name: "Wvu Employees Federal Credit Union" },
    { aba: "251579186", name: "Ccmh Fcu" },
    { aba: "251579254", name: "Mountain Heritage Federal C.u." },
    { aba: "251579319", name: "One Community Federal Credit Union" },
    { aba: "251579377", name: "Wee Fcu" },
    { aba: "251579403", name: "Ravenswood Fcu" },
    { aba: "251579458", name: "Union Trades Federal Credit Union" },
    { aba: "251579474", name: "Willow Island Fcu" },
    { aba: "251579694", name: "Marion County School Employees Fcu" },
    { aba: "251579830", name: "Preston Federal Credit Union" },
    { aba: "251579908", name: "Waycose Federal Credit Union" },
    { aba: "251579911", name: "Raleigh Co Educators" },
    { aba: "251583457", name: "Raleigh County Fcu" },
    { aba: "251583486", name: "Telbec Federal Credit Union" },
    { aba: "251583538", name: "Mercer Co (wv) Teachers Fcu" },
    { aba: "251583567", name: "National Employees Fcu" },
    { aba: "251583839", name: "West Virginia Central Fcu" },
    { aba: "251583868", name: "Alloy Federal Credit Union" },
    { aba: "251583907", name: "Appalachian Power Employees Fcu" },
    { aba: "251583910", name: "Universal Federal Credit Union" },
    { aba: "251583936", name: "Cabway Telco Federal Credit Union" },
    { aba: "251583978", name: "First Priority Federal Credit Union" },
    { aba: "251584003", name: "Huntington C&o Railway Employees Cu" },
    { aba: "251584016", name: "Huntingtonized Fcu" },
    { aba: "251584029", name: "Star Usa Federal Credit Union" },
    { aba: "251584032", name: "Ibew #317 F.c.u" },
    { aba: "251584045", name: "Pioneer West Virginia Fcu" },
    { aba: "251584061", name: "Pace Federal Cu" },
    { aba: "251584100", name: "Metro Community Fcu" },
    { aba: "251584126", name: "Of Toalston Federal Credit Union" },
    { aba: "251584142", name: "Fayette Federal Credit Union" },
    { aba: "251584184", name: "South Charleston Employees Fcu" },
    { aba: "251584197", name: "West Virginia Federal Cu" },
    { aba: "251584207", name: "Pioneer West Virginia Fcu" },
    { aba: "251983617", name: "Star Usa Federal Credit Union" },
    { aba: "251983633", name: "Cha-tel Federal Credit Union" },
    { aba: "251983662", name: "Charleston Fcu" },
    { aba: "251983675", name: "Charleston Postal Fcu" },
    { aba: "251983691", name: "Star Usa Federal Credit Union" },
    { aba: "251983730", name: "Pioneer West Virginia Fcu" },
    { aba: "251983756", name: "Kemba Charleston Fcu" },
    { aba: "251983798", name: "Members Choice Wv Federal Cu" },
    { aba: "251983811", name: "Peoples Federal Credit Union" },
    { aba: "251983853", name: "Wv National Guard Fcu" },
    { aba: "251984386", name: "Element Federal Credit Union" },
    { aba: "251984409", name: "Camc Federal Credit Union" },
    { aba: "251984467", name: "The State Credit Union" },
    { aba: "252070299", name: "Arundel Federal Savings Bank" },
    { aba: "252070309", name: "Truist Bank" },
    { aba: "252070435", name: "M & T Bank" },
    { aba: "252070545", name: "Chesapeake Bank Of Maryland" },
    { aba: "252070561", name: "Fvcbank" },
    { aba: "252070639", name: "Eastern Savings Bank, F.s.b." },
    { aba: "252070684", name: "Orrstown Bank" },
    { aba: "252070723", name: "Orrstown Bank" },
    { aba: "252070752", name: "Howard Bank" },
    { aba: "252070817", name: "M & T Bank" },
    { aba: "252070891", name: "Orrstown Bank" },
    { aba: "252070972", name: "Homewood Federal Savings Bank" },
    { aba: "252070985", name: "Wesbanco Bank, Inc." },
    { aba: "252071065", name: "Bayvanguard Bank" },
    { aba: "252071078", name: "Bayvanguard Bank" },
    { aba: "252071146", name: "Bayvanguard Bank" },
    { aba: "252071159", name: "Bayvanguard Bank" },
    { aba: "252071214", name: "Bayvanguard Bank" },
    { aba: "252071227", name: "Peoplesbank" },
    { aba: "252071379", name: "Howard Bank" },
    { aba: "252071405", name: "Howard Bank" },
    { aba: "252071557", name: "Rosedale Federal Savings & Loan Assn" },
    { aba: "252071654", name: "Wesbanco Bank, Inc." },
    { aba: "252071706", name: "Bayvanguard Bank" },
    { aba: "252071861", name: "Suburban Federal Savings Bank" },
    { aba: "252073018", name: "M & T" },
    { aba: "252075663", name: "Five Star Of Maryland Federal Cu" },
    { aba: "252075744", name: "Atlantic Financial Fcu" },
    { aba: "252075757", name: "Baltimore County Employees Fcu" },
    { aba: "252075870", name: "Central Credit Union Of Maryland" },
    { aba: "252075977", name: "First Eagle Federal Credit Union" },
    { aba: "252075980", name: "St Agnes Federal Credit Union" },
    { aba: "252076044", name: "Baltimore County Employees Fcu" },
    { aba: "252076235", name: "Johns Hopkins Fcu" },
    { aba: "252076248", name: "St Agnes Federal Credit Union" },
    { aba: "252076390", name: "Lm Federal Credit Union" },
    { aba: "252076442", name: "Destinations Credit Union" },
    { aba: "252076468", name: "Municipal Ecu Of Baltimore, Inc." },
    { aba: "252076565", name: "Point Breeze Credit Union" },
    { aba: "252076578", name: "Post Office Credit Union Of Maryland" },
    { aba: "252076646", name: "Self Reliance Baltimore Fcu" },
    { aba: "252076701", name: "Members First Of Maryland Fcu" },
    { aba: "252076714", name: "Securityplus Federal Credit Union" },
    { aba: "252076727", name: "Five Star Of Maryland Federal Cu" },
    { aba: "252076730", name: "St Joseph Medical Ctr Md Fcu" },
    { aba: "252076785", name: "Lm Federal Credit Union" },
    { aba: "252076866", name: "Five Star Of Maryland Federal Cu" },
    { aba: "252172485", name: "First Shore Federal Savings & Loan" },
    { aba: "252176889", name: "Act 1st Federal Credit Union" },
    { aba: "252177118", name: "Chessie Federal Cu" },
    { aba: "252177121", name: "First Peoples Community Fcu" },
    { aba: "252177147", name: "Potomac Federal Credit Union" },
    { aba: "252177150", name: "Chessie Federal Cu" },
    { aba: "252177163", name: "Potomac Federal Credit Union" },
    { aba: "252177176", name: "Kelco Federal Credit Union" },
    { aba: "252177927", name: "Dor Wic Federal Credit Union" },
    { aba: "252178201", name: "Wepco Federal Credit Union" },
    { aba: "252279414", name: "Romney Fcu" },
    { aba: "253070201", name: "First Charter Bank" },
    { aba: "253074951", name: "Blue Flame Credit Union" },
    { aba: "253074977", name: "Carolinas Telco Fcu" },
    { aba: "253075015", name: "Carolina Cooperative Credit Union" },
    { aba: "253075028", name: "Charlotte Metro Fcu" },
    { aba: "253075031", name: "Nova Credit Union" },
    { aba: "253075044", name: "Charlotte Fire Department" },
    { aba: "253075112", name: "Carolina Cooperative Credit Union" },
    { aba: "253075251", name: "Carolina Cooperative Fcu" },
    { aba: "253075293", name: "Self-help Credit Union" },
    { aba: "253075303", name: "Sharonview Federal Credit Union" },
    { aba: "253170020", name: "Pinnacle Bank" },
    { aba: "253170062", name: "First Bank" },
    { aba: "253170088", name: "Belmont Federal S&l" },
    { aba: "253170143", name: "First Horizon Bank" },
    { aba: "253170240", name: "Hometrust Bank" },
    { aba: "253170253", name: "First Horizon Bank" },
    { aba: "253170279", name: "Hometrust Bank, Na" },
    { aba: "253170282", name: "First Charter Bank" },
    { aba: "253170305", name: "Wells Fargo Bank" },
    { aba: "253170334", name: "First Federal Bank" },
    { aba: "253170376", name: "Mechanics & Farmers Bank" },
    { aba: "253170486", name: "First Citizens Bank & Trust Company" },
    { aba: "253170512", name: "South State Bank" },
    { aba: "253170525", name: "Select Bank & Trust" },
    { aba: "253170677", name: "Hertford Savings Bank, Ssb" },
    { aba: "253170758", name: "Ks Bank Inc." },
    { aba: "253170842", name: "Hometrust Bank" },
    { aba: "253170884", name: "First Federal Svgs Bk Of Lincolnton" },
    { aba: "253170936", name: "1st S&l Asn" },
    { aba: "253170981", name: "First Community Bank, Na" },
    { aba: "253171003", name: "Morganton Savings Bank, S.s.b." },
    { aba: "253171197", name: "Roanoke Rapids Savings Bank, Ssb" },
    { aba: "253171252", name: "Southern Bank & Tr Co" },
    { aba: "253171294", name: "Roxboro Savings Bank, Ssb" },
    { aba: "253171430", name: "First National Bank Of Pennsylvania" },
    { aba: "253171498", name: "Jackson Savings Bank Ssb" },
    { aba: "253171524", name: "Tarboro Savings Bank,ssb" },
    { aba: "253171537", name: "Taylorsville Savings Bank Ssb" },
    { aba: "253171595", name: "Uwharrie Bank" },
    { aba: "253171605", name: "Wake Forest Federal Savings & Loan" },
    { aba: "253171621", name: "United Bank" },
    { aba: "253171676", name: "Lifestore Bank" },
    { aba: "253171728", name: "First Bank" },
    { aba: "253171773", name: "Piedmont Federal Savings Bank" },
    { aba: "253173496", name: "Pinnacle Bank" },
    { aba: "253173661", name: "Atlantic Union Bank" },
    { aba: "253174576", name: "Mountain Credit Union" },
    { aba: "253174589", name: "Arcade Credit Union" },
    { aba: "253174592", name: "Atlanta Postal Credit Union" },
    { aba: "253174738", name: "Telco Community Credit Union" },
    { aba: "253174822", name: "Lithium Federal Credit Union" },
    { aba: "253174851", name: "Self-help Credit Union" },
    { aba: "253174893", name: "Marine Federal Credit Union" },
    { aba: "253174903", name: "Champion Credit Union" },
    { aba: "253174929", name: "State Employees Cu" },
    { aba: "253175384", name: "First Flight Federal Credit Union" },
    { aba: "253175397", name: "Carolina Federal Credit Union" },
    { aba: "253175410", name: "Carolina Postal Credit Union" },
    { aba: "253175449", name: "Duke University Federal Credit Union" },
    { aba: "253175481", name: "Vision Financial Fcu" },
    { aba: "253175494", name: "Coastal Federal Credit Union" },
    { aba: "253175517", name: "Vision Financial Federal Cu" },
    { aba: "253175546", name: "Carolina Postal Credit Union" },
    { aba: "253175643", name: "Members Credit Union" },
    { aba: "253175656", name: "Piedmont Advantage Cu" },
    { aba: "253175685", name: "Vision Financial Fcu" },
    { aba: "253175708", name: "Vision Financial Federal Cu" },
    { aba: "253175724", name: "Bragg Mutual Federal Credit Union" },
    { aba: "253175737", name: "Fort Bragg Federal Credit Union" },
    { aba: "253175834", name: "First Carolina People's Credit Union" },
    { aba: "253175889", name: "Premier Federal Credit Union" },
    { aba: "253175931", name: "Greensboro Municipal Fcu" },
    { aba: "253175957", name: "Greensboro Postal Cu" },
    { aba: "253175960", name: "Piedmont Aviation Cu" },
    { aba: "253175973", name: "Internal Revenue Employees Fcu" },
    { aba: "253175986", name: "Summit Credit Union" },
    { aba: "253176037", name: "Healthshare Credit Union" },
    { aba: "253176066", name: "Summit Credit Union" },
    { aba: "253176118", name: "Summit Credit Union" },
    { aba: "253176150", name: "Guco Credit Union" },
    { aba: "253176163", name: "Hamlet Federal Credit Union" },
    { aba: "253176273", name: "Shuford Federal Credit Union" },
    { aba: "253176448", name: "Greater Kinston Credit Union" },
    { aba: "253176574", name: "Mcdowell Cornerstone Cu" },
    { aba: "253176613", name: "Allegacy Federal Credit Union" },
    { aba: "253176626", name: "Allvac Savings And Credit Union" },
    { aba: "253176794", name: "Oteen V A Fed Credit Union" },
    { aba: "253176846", name: "Weyco Community Credit Union" },
    { aba: "253176875", name: "First Carolina People's Credit Union" },
    { aba: "253176901", name: "Local Government Fcu" },
    { aba: "253176930", name: "Electel Cooperative Fcu" },
    { aba: "253177049", name: "State Employees Credit Union" },
    { aba: "253177117", name: "Rtp Federal Credit Union" },
    { aba: "253177120", name: "Welcome Federal Credit Union" },
    { aba: "253177133", name: "Riegelwood F C U" },
    { aba: "253177201", name: "Coastal Federal Credit Union" },
    { aba: "253177230", name: "Dill Fcu" },
    { aba: "253177308", name: "North Carolina Community Fcu" },
    { aba: "253177447", name: "Telco Credit Union" },
    { aba: "253177502", name: "Wnc Community Credit Union" },
    { aba: "253177586", name: "Piedmont Advantage Credit Union" },
    { aba: "253177609", name: "Piedmont Advantage Credit Union" },
    { aba: "253177748", name: "Members Credit Union" },
    { aba: "253177793", name: "Members Credit Union" },
    { aba: "253177832", name: "Truliant Federal Credit Union" },
    { aba: "253177861", name: "Piedmont Advantage Credit Union" },
    { aba: "253177887", name: "Allegacy Federal Credit Union" },
    { aba: "253177968", name: "Winston-salem Federal Credit Union" },
    { aba: "253177984", name: "Emergency Responders Credit Union" },
    { aba: "253177997", name: "Carolina Postal Cu" },
    { aba: "253178022", name: "Team & Wheel Fed C U" },
    { aba: "253184317", name: "Vizo Financial Corporate Cu" },
    { aba: "253184359", name: "Self-help Credit Union" },
    { aba: "253184430", name: "American Partners Fcu" },
    { aba: "253184472", name: "Self-help Credit Union" },
    { aba: "253184537", name: "Local Government Fcu" },
    { aba: "253184692", name: "Acclaim Fcu" },
    { aba: "253184809", name: "Piedmont Cu" },
    { aba: "253184825", name: "Tcp Credit Union" },
    { aba: "253184841", name: "Self-help Credit Union" },
    { aba: "253184854", name: "Ecusta Credit Union" },
    { aba: "253184870", name: "Lion's Share Federal Credit Union" },
    { aba: "253184922", name: "North Carolina Press Assocation Fcu" },
    { aba: "253184977", name: "Piedmont Advantage Cu" },
    { aba: "253185002", name: "Self-help Credit Union" },
    { aba: "253271806", name: "Abbeville First Bank, Ssb" },
    { aba: "253271822", name: "Security Federal Bank" },
    { aba: "253271916", name: "First Palmetto Bank" },
    { aba: "253271945", name: "South State Bank" },
    { aba: "253271974", name: "First- Citizens Bank & Trust Co" },
    { aba: "253272009", name: "Spratt Savings Bank" },
    { aba: "253272070", name: "South State Bank" },
    { aba: "253272148", name: "First Piedmont Federal S&l" },
    { aba: "253272229", name: "Citizens Building & Loan, Ssb" },
    { aba: "253272261", name: "Mutual Savings Bank" },
    { aba: "253272287", name: "Kingstree Federal S&l Assn" },
    { aba: "253272342", name: "Pee Dee Federal Savings Bank" },
    { aba: "253272368", name: "First Community Bank Na" },
    { aba: "253272384", name: "Pickens Savings & Loan Association" },
    { aba: "253272410", name: "Oconee Federal S&l Assn." },
    { aba: "253272481", name: "South State Bank" },
    { aba: "253272494", name: "1st Federal Savings Bank Of Sc, Inc" },
    { aba: "253272533", name: "Woodruff Federal S&l Association" },
    { aba: "253273901", name: "South State Bank" },
    { aba: "253278058", name: "Abbeville Community Fcu" },
    { aba: "253278061", name: "Hope South Federal Credit Union" },
    { aba: "253278090", name: "Srp Federal Credit Union" },
    { aba: "253278126", name: "Anmed Fcu" },
    { aba: "253278139", name: "Anderson Federal Credit Union" },
    { aba: "253278142", name: "Sc State Credit Union" },
    { aba: "253278184", name: "South Carolina State Credit Union" },
    { aba: "253278197", name: "Upstate Federal Credit Union" },
    { aba: "253278236", name: "Peach State Federal Cridit Union" },
    { aba: "253278304", name: "Arrow Pointe Federal Credit Union" },
    { aba: "253278333", name: "1st Co-op Federal Credit Union" },
    { aba: "253278362", name: "Rev Federal Credit Union" },
    { aba: "253278391", name: "South Carolina Federal Credit Union" },
    { aba: "253278401", name: "South Carolina Federal Credit Union" },
    { aba: "253278414", name: "Latitude 32 Federal Credit Union" },
    { aba: "253278430", name: "C. O. Federal Credit Union" },
    { aba: "253278498", name: "Pinnacle Bank" },
    { aba: "253278870", name: "Dixies Federal Credit Union" },
    { aba: "253278906", name: "South Carolina Natl Guard Fcu" },
    { aba: "253278935", name: "Pee Dee Federal Cu" },
    { aba: "253278964", name: "Palmetto First Fcu" },
    { aba: "253278980", name: "Nucor Employees Credit Union" },
    { aba: "253279002", name: "Sci Federal Credit Union" },
    { aba: "253279028", name: "South Carolina Federal Credit Union" },
    { aba: "253279031", name: "Allsouth Federal Credit Union" },
    { aba: "253279099", name: "Georgetown Kraft Credit Union" },
    { aba: "253279109", name: "Arrowpointe Fcu" },
    { aba: "253279167", name: "Ghs Federal Credit Union" },
    { aba: "253279183", name: "Greenville Heritage Federal Cu" },
    { aba: "253279196", name: "Greenville Federal Credit Union" },
    { aba: "253279206", name: "Carolina Foothills Fcu" },
    { aba: "253279277", name: "Spero Financial Federal Credit Union" },
    { aba: "253279332", name: "Greenwood Municipal Cu" },
    { aba: "253279345", name: "Neighbors United Fcu" },
    { aba: "253279358", name: "Emerald Credit Association Fcu" },
    { aba: "253279361", name: "Self Memorial Hospital Credit Union" },
    { aba: "253279390", name: "Spc Credit Union" },
    { aba: "253279439", name: "Founders Federal Credit Union" },
    { aba: "253279471", name: "Mid Carolina Credit Union" },
    { aba: "253279497", name: "Berkeley Community Fcu" },
    { aba: "253279507", name: "Santee Cooper Cu" },
    { aba: "253279510", name: "Carolina Trust Federal Credit Union" },
    { aba: "253279536", name: "Cpm Federal Credit Union" },
    { aba: "253279565", name: "Edisto Federal Credit Union" },
    { aba: "253279578", name: "Sc State Federal Credit Union" },
    { aba: "253279581", name: "Sc Highway Dept District #7 Fcu" },
    { aba: "253279617", name: "Pickens Fcu" },
    { aba: "253279620", name: "Arrow Pointe Federal Credit Union" },
    { aba: "253279659", name: "Family Trust Fcu" },
    { aba: "253279691", name: "Safe Fcu" },
    { aba: "253279714", name: "Secured Advantage Fcu" },
    { aba: "253279785", name: "Spartanburg Regional Fcu" },
    { aba: "253279808", name: "Carolina Foothills Fcu" },
    { aba: "253279811", name: "South Carolina Federal Credit Union" },
    { aba: "253279918", name: "Sc State Federal Credit Union" },
    { aba: "253284563", name: "Turbine Federal Credit Union" },
    { aba: "253284990", name: "South Carolina Federal Credit Union" },
    { aba: "253285012", name: "Peach State Federal Credit Union" },
    { aba: "253285041", name: "South Carolina Federal Credit Union" },
    { aba: "253978581", name: "Columbia Post Office Credit Union" },
    { aba: "253978604", name: "Sc State Federal Credit Union" },
    { aba: "253978617", name: "Palmetto Citizens Fcu" },
    { aba: "253978620", name: "Self-help Credit Union" },
    { aba: "253978691", name: "Palmetto Health Credit Union" },
    { aba: "253978730", name: "Sc State Federal Credit Union" },
    { aba: "253978743", name: "South Carolina Natl Guard Fcu" },
    { aba: "253978756", name: "Palmetto Health Credit Union" },
    { aba: "253978769", name: "Palmetto Citizens Fcu" },
    { aba: "253978785", name: "Sc Methodist Conference Cu" },
    { aba: "253978824", name: "Caro Smart Financial Solutions" },
    { aba: "254070019", name: "Wells Fargo Bank" },
    { aba: "254070077", name: "Fvcbank" },
    { aba: "254070116", name: "Citibank" },
    { aba: "254070132", name: "First National Bank Of Pennsylvania" },
    { aba: "254074031", name: "Afl-cio Employees Fcu" },
    { aba: "254074057", name: "Agriculture Federal Credit Union" },
    { aba: "254074112", name: "Andrews Federal Credit Union" },
    { aba: "254074170", name: "Bank-fund Staff Fcu" },
    { aba: "254074183", name: "Idb Global Federal Credit Union" },
    { aba: "254074222", name: "Ep Federal Credit Union" },
    { aba: "254074235", name: "Treasury Department Fcu" },
    { aba: "254074277", name: "Census Federal Credit Union" },
    { aba: "254074345", name: "Wright Patman Congressional Fcu" },
    { aba: "254074374", name: "Dc Fire Dept Federal Credit Union" },
    { aba: "254074400", name: "Northwest Fcu" },
    { aba: "254074413", name: "Justice Federal Credit Union" },
    { aba: "254074426", name: "Department Of Labor Fcu" },
    { aba: "254074439", name: "Department Of Commerce Fcu" },
    { aba: "254074442", name: "Department Of Interior Fcu" },
    { aba: "254074455", name: "Dc Federal Credit Union" },
    { aba: "254074468", name: "D.c. Teachers Fcu" },
    { aba: "254074536", name: "Nasa Federal Credit Union" },
    { aba: "254074581", name: "Frb Federal Credit Union" },
    { aba: "254074620", name: "Gsa Federal Credit Union" },
    { aba: "254074646", name: "Gpo Fcu" },
    { aba: "254074662", name: "Gsa Federal Credit Union" },
    { aba: "254074675", name: "Georgetown Federal Credit Union" },
    { aba: "254074688", name: "Democracy Federal Credit Union" },
    { aba: "254074727", name: "Howard University Employees Fcu" },
    { aba: "254074730", name: "Hud Federal Credit Union" },
    { aba: "254074756", name: "I.b.e.w. 26 Federal Credit Union" },
    { aba: "254074785", name: "Fedchoice Federal Credit Union" },
    { aba: "254074811", name: "Lafayette Federal Credit Union" },
    { aba: "254074837", name: "Library Of Congress Fcu" },
    { aba: "254074934", name: "Signature Federal Credit Union" },
    { aba: "254075014", name: "Departmentof Labor Fcu" },
    { aba: "254075027", name: "Northwest Federal Credit Union" },
    { aba: "254075043", name: "Nrl Federal Credit Union" },
    { aba: "254075069", name: "Oasstaff Federal Credit Union" },
    { aba: "254075072", name: "Paho/who Federal Credit Union" },
    { aba: "254075111", name: "Pepco Federal Credit Union" },
    { aba: "254075137", name: "Police Federal Credit Union" },
    { aba: "254075250", name: "U.s. Senate Federal Credit Union" },
    { aba: "254075344", name: "State Dept Federal Credit Union" },
    { aba: "254075386", name: "Transit Employees Fcu" },
    { aba: "254075399", name: "Transportation Fcu" },
    { aba: "254075409", name: "Treasury Department Fcu" },
    { aba: "254075438", name: "Infirst Federal Credit Union" },
    { aba: "254075441", name: "U.s. Postal Service Fcu" },
    { aba: "254075470", name: "Advantage Financial Federal Cu" },
    { aba: "254075483", name: "Washington Typographic Fcu" },
    { aba: "254075506", name: "Fedchoice Federal Credit Union" },
    { aba: "254075551", name: "Department Of Commerce Fcu" },
    { aba: "254079874", name: "Napfe Federal Credit Union" },
    { aba: "255070351", name: "First National Bank Of Pennsylvania" },
    { aba: "255071444", name: "Severn Savings Bank, Fsb" },
    { aba: "255071981", name: "Capital One, Na" },
    { aba: "255072029", name: "Howard Bank" },
    { aba: "255072045", name: "Cecil Bank" },
    { aba: "255072126", name: "Bank Of New York Mellon" },
    { aba: "255072142", name: "Glen Burnie Mutual Savings Bank" },
    { aba: "255072207", name: "Pnc Bank Na - Baltimore" },
    { aba: "255072236", name: "Cecil Federal Bank" },
    { aba: "255072252", name: "Jarrettsville Federal S&l Assn" },
    { aba: "255072278", name: "Bank Of Essex" },
    { aba: "255072317", name: "M & T Bank" },
    { aba: "255072362", name: "Wesbanco Bank, Inc." },
    { aba: "255072388", name: "North Arundel Savings Bank, Fsb" },
    { aba: "255072427", name: "Manufacturers And Traders Trust Co." },
    { aba: "255072537", name: "Farmers & Merchants Bank" },
    { aba: "255072582", name: "Sonabank" },
    { aba: "255072595", name: "Community Bank Of The Chesapeake" },
    { aba: "255072935", name: "Wells Fargo Bank" },
    { aba: "255073219", name: "Wesbanco Bank, Inc." },
    { aba: "255073251", name: "Congressional Bank" },
    { aba: "255073345", name: "Presidential Bank, Fsb" },
    { aba: "255074111", name: "Andrews Federal Credit Union" },
    { aba: "255074988", name: "Nymeo Federal Credit Union" },
    { aba: "255075495", name: "Signal Financial Fcu" },
    { aba: "255075576", name: "Aberdeen Proving Grnd Fcu" },
    { aba: "255076753", name: "State Employees Credit Union" },
    { aba: "255076892", name: "Har-co Credit Union" },
    { aba: "255076902", name: "Agriculture Federal Credit Union" },
    { aba: "255076928", name: "Baxter Credit Union" },
    { aba: "255076944", name: "National Institutes Of Health Fcu" },
    { aba: "255076973", name: "Northwest Fcu" },
    { aba: "255077008", name: "Educational Systems Fcu" },
    { aba: "255077024", name: "Market Usa Federal Credit Union" },
    { aba: "255077066", name: "Money One Federal Credit Union" },
    { aba: "255077079", name: "Howard County Education Fcu" },
    { aba: "255077299", name: "Freedom Of Maryland Federal Cu" },
    { aba: "255077312", name: "Cecil County School Employees' Fcu" },
    { aba: "255077325", name: "Thiokol-elkton Federal Credit Union" },
    { aba: "255077370", name: "Tower Federal Credit Union" },
    { aba: "255077419", name: "Ferko Maryland Fcu" },
    { aba: "255077477", name: "Mid-atlantic Federal Credit Union" },
    { aba: "255077480", name: "Baltimore Washington Fcu" },
    { aba: "255077493", name: "Market Usa Federal Credit Union" },
    { aba: "255077503", name: "Greenbelt Federal Credit Union" },
    { aba: "255077516", name: "Bulldog Federal Credit Union" },
    { aba: "255077561", name: "Educational Systems Fcu" },
    { aba: "255077600", name: "Money One Federal Credit Union" },
    { aba: "255077613", name: "Wssc Federal Credit Union" },
    { aba: "255077639", name: "Capital Area Realtors Federal Cu" },
    { aba: "255077671", name: "First Financial Fcu Of Md" },
    { aba: "255077736", name: "Cedar Point Federal Credit Union" },
    { aba: "255077749", name: "Central Cu Of Md" },
    { aba: "255077833", name: "Nasa Federal Credit Union" },
    { aba: "255077888", name: "Educational Systems Fcu" },
    { aba: "255077891", name: "Skypoint Federal Credit Union" },
    { aba: "255077901", name: "Fedfinancial Federal Credit Union" },
    { aba: "255077985", name: "Mncppc Federal Credit Union" },
    { aba: "255077998", name: "Apl Federal Credit Union" },
    { aba: "255078052", name: "Maryland Postal Federal Credit Union" },
    { aba: "255078188", name: "Prince George's Community Fcu" },
    { aba: "255079527", name: "Washington Co Teachers Fcu" },
    { aba: "255080011", name: "O A S Staff Fcu" },
    { aba: "255081586", name: "Masters,mates & Pilots Federal Cu" },
    { aba: "255083597", name: "Marriot Employees Fcu" },
    { aba: "256072691", name: "E Trade Bank" },
    { aba: "256072701", name: "Wells Fargo Bank" },
    { aba: "256073302", name: "M & T Bank" },
    { aba: "256073328", name: "Stifel Bank And Trust" },
    { aba: "256073360", name: "Fulton Bank" },
    { aba: "256073373", name: "M & T Bank" },
    { aba: "256074961", name: "Market Usa Federal Credit Union" },
    { aba: "256074974", name: "Navy Federal Credit Union" },
    { aba: "256075025", name: "Northwest Federal Credit Union" },
    { aba: "256075342", name: "State Dept Federal Credit Union" },
    { aba: "256075520", name: "Truenergy Federal Credit Union" },
    { aba: "256078255", name: "Commonwealth One Fcu" },
    { aba: "256078365", name: "Commonwealth One Fcu" },
    { aba: "256078404", name: "Arlington Community Fcu" },
    { aba: "256078433", name: "Patent & Trademark Office Fcu" },
    { aba: "256078446", name: "Pentagon Federal Credit Union" },
    { aba: "256078459", name: "Arlington Community Fcu" },
    { aba: "256078462", name: "St. Anns Arlington Fcu" },
    { aba: "256078501", name: "Nextmark Federal Credit Union" },
    { aba: "256078514", name: "Apple Federal Credit Union" },
    { aba: "256078543", name: "Healthcare Systems Fcu" },
    { aba: "256078556", name: "Strategic Federal Credit Union" },
    { aba: "256078598", name: "P.w.c. Employees Credit Union" },
    { aba: "256080528", name: "Northwest Federal Credit Union" },
    { aba: "257078966", name: "Berkeley City Public Schools Fcu" },
    { aba: "257078995", name: "Eastern Panhandle Federal Cu" },
    { aba: "257079033", name: "167th Tfr Federal Credit Union" },
    { aba: "257079716", name: "Martinsburg V. A. Center Fcu" },
    { aba: "261070141", name: "Citizen Trust Bank" },
    { aba: "261071140", name: "Credit Union Of Atlanta" },
    { aba: "261071166", name: "Atlanta Postal Credit Un" },
    { aba: "261071182", name: "Pinnacle Credit Union" },
    { aba: "261071195", name: "Credit Union Of Atlanta" },
    { aba: "261071315", name: "Delta Community Cu" },
    { aba: "261071328", name: "The Southern Credit Union" },
    { aba: "261071357", name: "Fort Mcpherson C U" },
    { aba: "261071373", name: "Family First Credit Union" },
    { aba: "261071412", name: "On The Grid Financial Fcu" },
    { aba: "261071438", name: "Georgia's Own Credit Union" },
    { aba: "261071522", name: "Mutual Svg Cu" },
    { aba: "261071548", name: "Excel Federal Credit Union" },
    { aba: "261071551", name: "Powerco Fed Cr Union" },
    { aba: "261071564", name: "Coca-cola Federal Credit Union" },
    { aba: "261071577", name: "Georgia's Own Credit Union" },
    { aba: "261071661", name: "Georgia's Own Credit Union" },
    { aba: "261072259", name: "Ibm Se Employees Cr Union" },
    { aba: "261072479", name: "Genuine Parts Credit Union" },
    { aba: "261072495", name: "Atlanta Postal Credit Union" },
    { aba: "261072547", name: "United Methodist Connectional Fcu" },
    { aba: "261072770", name: "1st Choice Credit Union" },
    { aba: "261072974", name: "Lge Community Credti Union" },
    { aba: "261073326", name: "Energy One Federal Credit Union" },
    { aba: "261073410", name: "First Florida Credit Union" },
    { aba: "261073478", name: "Atlanta City Employees Cu" },
    { aba: "261073591", name: "Bond Community Fed Cr Un" },
    { aba: "261073737", name: "Georgia's Own Credit Union" },
    { aba: "261073782", name: "Fort Mcpherson Cu" },
    { aba: "261073834", name: "Membersfirst Cu" },
    { aba: "261074671", name: "Georgia 's Own Credit Union" },
    { aba: "261084988", name: "Georgia United Credit Union" },
    { aba: "261170290", name: "Synovus Bank" },
    { aba: "261170371", name: "Newton Federal Bank" },
    { aba: "261170465", name: "Elberton Federal S&l" },
    { aba: "261170494", name: "United Bank" },
    { aba: "261170533", name: "Heritage Southeast Bank" },
    { aba: "261170546", name: "Pnc Bank, National Association" },
    { aba: "261170708", name: "Synovus Bank" },
    { aba: "261170740", name: "Regions Bank" },
    { aba: "261170818", name: "Oconee Federal S&l Assn." },
    { aba: "261170876", name: "South State Bank, Na" },
    { aba: "261170931", name: "Synovus Bank" },
    { aba: "261171163", name: "Atlanta Postal Cu" },
    { aba: "261171215", name: "Srp Fed Credit Union" },
    { aba: "261171228", name: "Midsouth Federal Credit Union" },
    { aba: "261171244", name: "Cdc Federal Credit Union" },
    { aba: "261171299", name: "Atlanta Postal Cu" },
    { aba: "261171309", name: "Georgia United Credit Union" },
    { aba: "261171338", name: "Associated Credit Union" },
    { aba: "261171383", name: "Coosa Valley Credit Union" },
    { aba: "261171480", name: "Lge Community Credit Union" },
    { aba: "261171493", name: "Credit Union Of Georgia" },
    { aba: "261171587", name: "Robins Financial Credit Union" },
    { aba: "261171671", name: "Kinetic Credit Union" },
    { aba: "261171781", name: "Family First Credit Union" },
    { aba: "261171972", name: "Ithink Financial Credit Union" },
    { aba: "261172007", name: "Coosa Valley Credit Union" },
    { aba: "261172078", name: "Macon-bibb Employees Credit Union" },
    { aba: "261172175", name: "First Reliance Fcu" },
    { aba: "261172227", name: "Gwinnett Federal Credit Union" },
    { aba: "261172308", name: "Emory Alliance Cu" },
    { aba: "261172528", name: "Rig Employees Credit Union" },
    { aba: "261172560", name: "Regional Members Fcu" },
    { aba: "261172573", name: "Georgia Power Northwest Fcu" },
    { aba: "261172696", name: "Peach State Federal Credit Union" },
    { aba: "261172748", name: "H.e.a. Federal Credit Union" },
    { aba: "261172764", name: "Georgia United Credit Union" },
    { aba: "261172780", name: "Coosa Valley Credit Union" },
    { aba: "261172968", name: "Kinetic Credit Union" },
    { aba: "261173352", name: "Lanier Federal Credit Union" },
    { aba: "261173433", name: "Combined Employees Cu" },
    { aba: "261173514", name: "The Wright Credit Union" },
    { aba: "261173585", name: "Beka Federal Credit Union" },
    { aba: "261173653", name: "Coweta Cities & Co Efcu" },
    { aba: "261173941", name: "North Georgia Credit Union" },
    { aba: "261174296", name: "Gwinnett Federal Credit Union" },
    { aba: "261174432", name: "Members First Cu" },
    { aba: "261174759", name: "Peach State Federal Credit Union" },
    { aba: "261174775", name: "Hallco Community Credit Union" },
    { aba: "261174995", name: "Cgr Credit Union" },
    { aba: "261175156", name: "First Reliance Fcu" },
    { aba: "261175169", name: "Peach State Federal Credit Union" },
    { aba: "261175415", name: "Tabernacle Fcu" },
    { aba: "261175512", name: "Georgia United Credit Union" },
    { aba: "261175541", name: "Walker County Educators Fcu" },
    { aba: "261187173", name: "Coosa Valley Federal Credit Union" },
    { aba: "261187843", name: "Peach State Federal Credit Union" },
    { aba: "261189061", name: "Health Center Cr Un" },
    { aba: "261190490", name: "South State Bank" },
    { aba: "261190649", name: "United Community Bank, Inc" },
    { aba: "261191169", name: "Go Energy Financial Credit Union" },
    { aba: "261191334", name: "North Main Credit Union" },
    { aba: "261270417", name: "First State Bank Of Blakely" },
    { aba: "261270679", name: "Family Bank" },
    { aba: "261270695", name: "Colony Bank" },
    { aba: "261270789", name: "Tc Federal Bank" },
    { aba: "261270831", name: "First Fed S&l Valdosta" },
    { aba: "261270857", name: "Vidalia Federal Savings" },
    { aba: "261270912", name: "Sb&t" },
    { aba: "261271115", name: "Renasant Bank" },
    { aba: "261271267", name: "Savannah Schools Fcu" },
    { aba: "261271364", name: "Geovista Credit Union" },
    { aba: "261271403", name: "Five Star Credit Union" },
    { aba: "261271500", name: "Southeastern Credit Union" },
    { aba: "261271649", name: "United 1st Federal Credit Union" },
    { aba: "261271694", name: "Georgia Heritage Fcu" },
    { aba: "261271704", name: "Southern Pine Credit Union" },
    { aba: "261271717", name: "Ameris Bank" },
    { aba: "261271759", name: "Members United Credit Union" },
    { aba: "261271791", name: "Artesian City Fed" },
    { aba: "261271982", name: "Members First Credit Union" },
    { aba: "261272046", name: "Five Star Credit Union" },
    { aba: "261272101", name: "Colquitt County Teachers Fcu" },
    { aba: "261272266", name: "Georgia's Own Credit Union" },
    { aba: "261272460", name: "Go Energy Financial Credit Union" },
    { aba: "261272583", name: "Georgia Power Valdosta Fcu" },
    { aba: "261272648", name: "Glynn Cty Fed Emp Cu" },
    { aba: "261272651", name: "Marshland Community Federal Cu" },
    { aba: "261272680", name: "Gpa Fcu" },
    { aba: "261272826", name: "Interstate Unlimited Federal C/u" },
    { aba: "261272839", name: "Altamaha Federal Credit Union" },
    { aba: "261272897", name: "Locoga Federal Credit Union" },
    { aba: "261273142", name: "Core Credit Union" },
    { aba: "261273155", name: "Savannah Postal Credit Union" },
    { aba: "261273207", name: "Five Star Credit Union" },
    { aba: "261273401", name: "Valdosta Teachers Credit Union" },
    { aba: "261273456", name: "United 1st Federal Credit Union" },
    { aba: "261273485", name: "Workmen's Circle Credit Union , Inc." },
    { aba: "261275001", name: "Memorial Health Cu" },
    { aba: "261287633", name: "Core Credit Union" },
    { aba: "261289880", name: "Colony Bank" },
    { aba: "261290361", name: "First Ga Bk (tm) Of United Comm Bank" },
    { aba: "261291043", name: "American Banking Co Dba Ameris" },
    { aba: "261291247", name: "South Georgia Bank" },
    { aba: "261375684", name: "Tennessee Valley Fcu" },
    { aba: "261378652", name: "Unify Financial Federal Credit Union" },
    { aba: "261388273", name: "Chattanooga Federal Empl C/u" },
    { aba: "261388286", name: "Memorial Credit Union" },
    { aba: "261388312", name: "Chattanooga Area Schools Fcu" },
    { aba: "261388325", name: "Scenic Community Credit Union" },
    { aba: "261388406", name: "Comtrust Federal Credit Union" },
    { aba: "261388503", name: "Epb Employee Credit Union" },
    { aba: "261388516", name: "Healthcare Services Credit Union" },
    { aba: "261388532", name: "Basf Chattanooga Fcu" },
    { aba: "261388587", name: "Ibew Local 175 Fed. Cu" },
    { aba: "261388723", name: "Trust Federal Credit Union" },
    { aba: "261388765", name: "Trust Federal Credit Union" },
    { aba: "261388888", name: "Southern Credit Union" },
    { aba: "261388930", name: "Bcbst Employees Cu" },
    { aba: "261388998", name: "Chattanooga First Fcu" },
    { aba: "261389036", name: "Collegedale Credit Union" },
    { aba: "262000932", name: "Andalusia Mills Ecu" },
    { aba: "262073260", name: "Alabama Law Enforcement Credit Union" },
    { aba: "262075475", name: "Landmark Credit Union" },
    { aba: "262083881", name: "America's First Federal Credit Un" },
    { aba: "262083959", name: "Jefferson Credit Union" },
    { aba: "262083991", name: "Eco Credit Union" },
    { aba: "262084356", name: "Synovus Bank" },
    { aba: "262084385", name: "Citizens Trust Bank" },
    { aba: "262084893", name: "Ang Federal Credit Union" },
    { aba: "262084916", name: "Alatrust Credit Union" },
    { aba: "262085070", name: "1st Resource Credit Union" },
    { aba: "262085096", name: "Health Cu" },
    { aba: "262085261", name: "Legacy Community Fcu" },
    { aba: "262085863", name: "Social Security Credit Union" },
    { aba: "262086338", name: "New Prilgrim Fcu" },
    { aba: "262086477", name: "Sixth Avenue Baptist Fcu" },
    { aba: "262086561", name: "Legacy Community Fcu" },
    { aba: "262086671", name: "Alabama One Credit Union" },
    { aba: "262086749", name: "Mutual Savings Credit Union" },
    { aba: "262087133", name: "America's First Federal Credit Union" },
    { aba: "262087159", name: "L & N Credit Union" },
    { aba: "262087447", name: "Acipco Fed Cr Union" },
    { aba: "262087502", name: "Alabama Central Cu" },
    { aba: "262087515", name: "Alabama Postal Cu" },
    { aba: "262087528", name: "Avadian Credit Union" },
    { aba: "262087609", name: "Apco Employees Cu" },
    { aba: "262088019", name: "Birmingham City Cu" },
    { aba: "262088051", name: "Avadian Cu" },
    { aba: "262088116", name: "Federal Employees Cu" },
    { aba: "262090120", name: "Corporate America Credit Union" },
    { aba: "262090780", name: "Beal Bank Ssb" },
    { aba: "262175867", name: "New Horizons Credit Union" },
    { aba: "262175870", name: "Mcintosh Chemical Fed Credit Union" },
    { aba: "262176358", name: "Gulf Winds Credit Union" },
    { aba: "262176743", name: "Opp Micolas Credit Union" },
    { aba: "262183626", name: "All In Federal Credit Union" },
    { aba: "262183749", name: "All In Federal Credit Union" },
    { aba: "262183985", name: "Brewton Mill Fcu" },
    { aba: "262184078", name: "Solutions First Federal Credit Union" },
    { aba: "262184515", name: "First Federal Bank" },
    { aba: "262185116", name: "Naheola Credit Union" },
    { aba: "262187716", name: "Covington Schools Federal C/u" },
    { aba: "262275783", name: "North Alabama Educators Cu" },
    { aba: "262275835", name: "Redstone Federal Cu-huntsville" },
    { aba: "262275848", name: "Rocket City Federal C U" },
    { aba: "262275958", name: "Max Credit Union" },
    { aba: "262276368", name: "Fedmont Federal Credit Union" },
    { aba: "262276384", name: "Alabama One Credit Union" },
    { aba: "262276397", name: "Alabama State Employess Cu" },
    { aba: "262276410", name: "Guardian Credit Union" },
    { aba: "262276452", name: "Guardian Credit Union" },
    { aba: "262276533", name: "Guardian Credit Union" },
    { aba: "262276562", name: "Montgomery Va Fcu" },
    { aba: "262276575", name: "Hope Federal Credit Union" },
    { aba: "262276630", name: "Guardian Credit Union" },
    { aba: "262276656", name: "Tva Community Credit Union" },
    { aba: "262276698", name: "Council Federal Credit Union" },
    { aba: "262276724", name: "East Alabama Community Fcu" },
    { aba: "262276737", name: "Four Seasons Fcu" },
    { aba: "262276805", name: "Phenix Pride Federal Credit Union" },
    { aba: "262276944", name: "Jefferson Financial Federal Credit U" },
    { aba: "262277008", name: "Electrical Workers 558 Fcu" },
    { aba: "262277011", name: "Listerhill Credit Union" },
    { aba: "262277024", name: "Railway Credit Union" },
    { aba: "262277105", name: "Sycamore Federal Credit Union" },
    { aba: "262277118", name: "Heritage South Credit Union" },
    { aba: "262277189", name: "Alabama One Credit Union" },
    { aba: "262277192", name: "Alabama One Credit Union" },
    { aba: "262277202", name: "City Credit Union" },
    { aba: "262277215", name: "Red Oak Credit Union" },
    { aba: "262277260", name: "Alabama Credit Union" },
    { aba: "262277273", name: "Tuscaloosa Va Federal Crun" },
    { aba: "262277286", name: "Riverfall Credit Union" },
    { aba: "262277309", name: "Tuscaloosa Cty Cu" },
    { aba: "262277354", name: "Valley Credit Union" },
    { aba: "262277370", name: "Tuskegee Fcu" },
    { aba: "262277419", name: "Alabama Credit Union" },
    { aba: "262283665", name: "First Southern Bank" },
    { aba: "262283843", name: "Railroad Community Credit Union" },
    { aba: "262283908", name: "Auburn University Credit Union" },
    { aba: "262284253", name: "Champion Alabama Employees C/u" },
    { aba: "262284279", name: "Family Security Cr Un" },
    { aba: "262284415", name: "Winsouth Credit Union" },
    { aba: "262284431", name: "Ala Teachers Cu" },
    { aba: "262284460", name: "Southfirst Bank" },
    { aba: "262284693", name: "Smartbank" },
    { aba: "262285184", name: "Pnc Bank, National Association" },
    { aba: "262285223", name: "First Fidelity Bank" },
    { aba: "262285511", name: "Mutual Savings Cu" },
    { aba: "262285605", name: "Florence Federal Credit Union" },
    { aba: "262285621", name: "Cullman Savings Bank" },
    { aba: "262285663", name: "The Southern Bank Company" },
    { aba: "262285838", name: "Lauderdale County Teachers C/u" },
    { aba: "262285951", name: "Chem Family Credit Union" },
    { aba: "262286109", name: "Liberty Bank & Trust Company" },
    { aba: "262286170", name: "Aod Fcu" },
    { aba: "262286358", name: "Wcu Credit Union" },
    { aba: "262286455", name: "Security Bank" },
    { aba: "262286688", name: "Northeast Al Postal" },
    { aba: "262286950", name: "First Financial Bank" },
    { aba: "262287195", name: "Family Savings Credit Union" },
    { aba: "262287218", name: "First Community Bank Of Cullman" },
    { aba: "262287360", name: "Fort Mcclellan Credit Union" },
    { aba: "262287386", name: "Pinnacle Bank" },
    { aba: "262287425", name: "Marvel City Fcu" },
    { aba: "262287551", name: "Coosa Pines Fcu-childersburg" },
    { aba: "262287894", name: "Aod Fcu" },
    { aba: "262289326", name: "Family Security Cu" },
    { aba: "262289355", name: "North Alabama Papermakers Fcu" },
    { aba: "262291424", name: "South State Bank, Na" },
    { aba: "263078837", name: "Alive Credit Union" },
    { aba: "263078918", name: "First Florida Credit Union" },
    { aba: "263078921", name: "Jax Federal Credit Union" },
    { aba: "263078934", name: "Community First Cu Of Florida" },
    { aba: "263078947", name: "Farmers Federal Cr Union" },
    { aba: "263078950", name: "121 Financial Credit Union" },
    { aba: "263078963", name: "Metro North Federal C/u" },
    { aba: "263079014", name: "First Florida Credit Union" },
    { aba: "263079027", name: "Jax Federal Credit Union" },
    { aba: "263079043", name: "121 Financial Credit Union" },
    { aba: "263079056", name: "Alive Credit Union" },
    { aba: "263079085", name: "Gulf Life Employees Fcu" },
    { aba: "263079108", name: "Jax Federal Credit Union" },
    { aba: "263079124", name: "Jax Firemens C U" },
    { aba: "263079166", name: "Jax Postal + Prof Credit U" },
    { aba: "263079234", name: "Jax Federal Credit Union" },
    { aba: "263079276", name: "Vystar Credit Union" },
    { aba: "263079289", name: "City & Police Federal Credit Union" },
    { aba: "263079373", name: "Space Coast Credit Union" },
    { aba: "263079409", name: "Coastline Fed C U" },
    { aba: "263079412", name: "First Florida Credit Union" },
    { aba: "263079425", name: "First Coast Fed C U" },
    { aba: "263089800", name: "Jm Associates Federal Credit Union" },
    { aba: "263090938", name: "First Florida Credit Union" },
    { aba: "263091128", name: "Iberiabank" },
    { aba: "263170175", name: "Td Bank Na" },
    { aba: "263177547", name: "Midflorida Fcu" },
    { aba: "263177563", name: "Midflorida Fed C U" },
    { aba: "263177660", name: "Financial Access Fcu" },
    { aba: "263177686", name: "Envision Credit Union" },
    { aba: "263177712", name: "City County Emp Credit Uniion" },
    { aba: "263177725", name: "Ecu Credit Union" },
    { aba: "263177741", name: "Pinellas Federal Credit Union" },
    { aba: "263177754", name: "Space Coast Credit Union" },
    { aba: "263177783", name: "Ficare Fcu" },
    { aba: "263177903", name: "Space Coast Credit Union" },
    { aba: "263177916", name: "Financial Educators Fcu" },
    { aba: "263177932", name: "Launch Credit Union" },
    { aba: "263178070", name: "Eglin Federal C U" },
    { aba: "263178216", name: "Container Mutual Cu" },
    { aba: "263178410", name: "Florida Credit Union" },
    { aba: "263178423", name: "First Credit Union Of Gainesville" },
    { aba: "263178452", name: "Sunstate Federal Credit Union" },
    { aba: "263178478", name: "Campus Usa Credit Union" },
    { aba: "263178481", name: "First Credit Union Of Gainesville" },
    { aba: "263178494", name: "My Healthcare Federal Credit Union" },
    { aba: "263179532", name: "Launch Credit Union" },
    { aba: "263179574", name: "Florida Rural Electric Credit Union" },
    { aba: "263179781", name: "Priority Cu" },
    { aba: "263179804", name: "Midflorida Cu" },
    { aba: "263179817", name: "Publix Employees Fed Cu" },
    { aba: "263179859", name: "Florida Credit Union" },
    { aba: "263179891", name: "Suwanne River Federal Credit Union" },
    { aba: "263179914", name: "Country Financial Credit Union" },
    { aba: "263179956", name: "Mccoy Federal Credit Union" },
    { aba: "263180958", name: "Jefferson Cty Teachers Cu" },
    { aba: "263181151", name: "Ocala Community Credit Union" },
    { aba: "263181229", name: "Insight Credit Union" },
    { aba: "263181287", name: "Advent Health Credit Union" },
    { aba: "263181342", name: "Local 606 Electrical Workers Fcu" },
    { aba: "263181368", name: "Fairwinds Credit Union" },
    { aba: "263181384", name: "Addition Financial Cu" },
    { aba: "263181407", name: "Orlando Credit Union" },
    { aba: "263181423", name: "Priority Cu" },
    { aba: "263181449", name: "Usf Federal Credit Union" },
    { aba: "263181478", name: "First Coast Community Cu" },
    { aba: "263181481", name: "First Coast Community Cr Union" },
    { aba: "263181494", name: "First Coast Community Cu" },
    { aba: "263181575", name: "Space Coast Credit Union" },
    { aba: "263181779", name: "Buckeye Community Fed Cr Union" },
    { aba: "263181805", name: "Florida State University Cu" },
    { aba: "263182037", name: "Community Credit Union Of Florida" },
    { aba: "263182040", name: "San Antonio Citizens Fcu" },
    { aba: "263182150", name: "1st Street Credit Union" },
    { aba: "263182163", name: "Usf Federal Credit Union" },
    { aba: "263182312", name: "Achieva Credit Union" },
    { aba: "263182480", name: "Dept Of Transportation C U" },
    { aba: "263182503", name: "Florida A&m Univ Federal C/u" },
    { aba: "263182516", name: "First Commerce Credit Union" },
    { aba: "263182529", name: "Flag Credit Union" },
    { aba: "263182545", name: "Florida State University Cu" },
    { aba: "263182558", name: "Envision Credit Union" },
    { aba: "263182587", name: "Gulf Winds Credit Union" },
    { aba: "263182600", name: "The First, A National Banking Assoc" },
    { aba: "263182626", name: "Tallahassee Leon Fed C/u" },
    { aba: "263182639", name: "Tallahassee Community Fed Cr Un" },
    { aba: "263182642", name: "Tmh Federal Cu" },
    { aba: "263182671", name: "Midflorida Fed Cu" },
    { aba: "263182723", name: "Powernet Credit Union" },
    { aba: "263182749", name: "Florida Customs Federal Credit Union" },
    { aba: "263182794", name: "G T E Federal Credit Union" },
    { aba: "263182817", name: "Suncoast Credit Union" },
    { aba: "263182833", name: "Florida West Coast Credit Union" },
    { aba: "263182901", name: "Tropical Financial Credit Union" },
    { aba: "263182914", name: "Grow Financial Fcu" },
    { aba: "263182969", name: "Ficare Fcu" },
    { aba: "263183007", name: "Florida Central C/u" },
    { aba: "263183010", name: "Tampa Bay Fed Credit Union" },
    { aba: "263183036", name: "Tampa Postal Federal Credit Union" },
    { aba: "263183049", name: "Railroad & Industrial Fed C/u" },
    { aba: "263183117", name: "Coast 2 Coast Financial Credit Union" },
    { aba: "263183159", name: "Usf Federal Credit Union" },
    { aba: "263183175", name: "Tyndall Federal Credit Union" },
    { aba: "263183492", name: "Gulf States Credit Union" },
    { aba: "263184488", name: "First Federal Bank Of Florida" },
    { aba: "263184815", name: "Axiom Bank" },
    { aba: "263184996", name: "Capital City Bank" },
    { aba: "263185018", name: "South State Bank, Na" },
    { aba: "263187320", name: "Surety Bank" },
    { aba: "263189069", name: "Corporate One Federal Cu" },
    { aba: "263189218", name: "Midflorida Cu" },
    { aba: "263189263", name: "Florida Rural Electric Credit Union" },
    { aba: "263189289", name: "F.r.s.a. Credit Union" },
    { aba: "263189616", name: "Hca Federal Credit Union" },
    { aba: "263189661", name: "Truist Bank" },
    { aba: "263189865", name: "Jpmorgan Chase" },
    { aba: "263190634", name: "Centennial Bank" },
    { aba: "263190757", name: "Iberiabank" },
    { aba: "263190812", name: "Fifth Third Bank" },
    { aba: "263191387", name: "Truist Bank" },
    { aba: "263191390", name: "South State Bank, Na" },
    { aba: "263191484", name: "First Florida Credit Union" },
    { aba: "263277599", name: "Calhoun Liberty Emp C U" },
    { aba: "263277670", name: "Harvesters Fed Credit Union" },
    { aba: "263277696", name: "Community South Cu" },
    { aba: "263277887", name: "Okaloosa Cty Teachers Fcu" },
    { aba: "263277984", name: "Walton Cty Teachers Fed Cu" },
    { aba: "263279937", name: "Jackson Cty Teachers C U" },
    { aba: "263280939", name: "Santa Rosa County Fcu" },
    { aba: "263281527", name: "Panhandle Credit Union" },
    { aba: "263281543", name: "Bay Credit Union" },
    { aba: "263281556", name: "Innovations Federal Credit Union" },
    { aba: "263281585", name: "Harvesters Fcu" },
    { aba: "263281608", name: "Ecco Credit Union" },
    { aba: "263281624", name: "Members First Cr Un Of Florida" },
    { aba: "263281640", name: "Fla State Emp Fed Cr Union" },
    { aba: "263281653", name: "Gpce Credit Union" },
    { aba: "263281679", name: "Gulf Winds Credit Union" },
    { aba: "263281695", name: "Pen Air Federal Credit Union" },
    { aba: "263281705", name: "My Pensacola Federal Credit Union" },
    { aba: "263281718", name: "Central Credit Union Of Florida" },
    { aba: "263281721", name: "My Pensacola Federal Credit Union" },
    { aba: "263281750", name: "Central Cr Union Of Fla" },
    { aba: "263281967", name: "Emerald Coast Federal Credit Union" },
    { aba: "263289095", name: "Harvesters Fed Credit Union" },
    { aba: "263290220", name: "Hancock Whitney Bank" },
    { aba: "263290576", name: "Bbva Usa" },
    { aba: "264071590", name: "Regions Bank" },
    { aba: "264071752", name: "First Horizon Bank" },
    { aba: "264080390", name: "Lifeway Credit Union" },
    { aba: "264080400", name: "Life Credit Union" },
    { aba: "264080497", name: "Dixie Line Cu" },
    { aba: "264080523", name: "Tennessee Employees Credit Union" },
    { aba: "264080549", name: "Enbright Credit Union" },
    { aba: "264080808", name: "Metropolitan Teachers" },
    { aba: "264080811", name: "Cornerstone Financial Credit Union" },
    { aba: "264080837", name: "Mpd Community Credit Union" },
    { aba: "264080853", name: "The Tennessee Cu" },
    { aba: "264080866", name: "Nashville Post Office Cu" },
    { aba: "264080895", name: "Nashville Firemen's Cu" },
    { aba: "264080989", name: "Parthenon Fcu" },
    { aba: "264080992", name: "Pias Credit Union" },
    { aba: "264081111", name: "St Thomas Credit Union" },
    { aba: "264081124", name: "Your Best Credit Union" },
    { aba: "264081153", name: "Tenn. Dept. Of Safety Credit Union" },
    { aba: "264081179", name: "Southeast Financial Cu" },
    { aba: "264081205", name: "Us Community Credit Union" },
    { aba: "264081221", name: "Vanderbilt University Employees Cu" },
    { aba: "264081250", name: "Outreach Community Fcu" },
    { aba: "264171209", name: "Reliant Bank" },
    { aba: "264171241", name: "Republic Bank & Trust Company" },
    { aba: "264171267", name: "Highland Federal S&l" },
    { aba: "264171270", name: "First Federal Bank" },
    { aba: "264171432", name: "Lawrenceburg Federal Bank" },
    { aba: "264171500", name: "Security Fed. S&l" },
    { aba: "264171542", name: "Pinnacle National Bank" },
    { aba: "264172156", name: "First Horizon Bank" },
    { aba: "264172169", name: "Smartbank" },
    { aba: "264174303", name: "American Bk & Tr Of The Cumberlands" },
    { aba: "264178668", name: "Listerhill Credit Union" },
    { aba: "264178671", name: "Listerhill Credit Union" },
    { aba: "264178710", name: "Middle Tennessee Fcu" },
    { aba: "264178998", name: "Gallatin Steam Plant Credit Union" },
    { aba: "264179900", name: "Employee Resources Credit Union" },
    { aba: "264180070", name: "Skyline Credit Union" },
    { aba: "264181286", name: "Johnsonville Tva Empl Cu" },
    { aba: "264181299", name: "Lakeside Credit Union" },
    { aba: "264181448", name: "Old Hickory Credit Union" },
    { aba: "264181574", name: "Heritage South Community Cu" },
    { aba: "264181590", name: "The Credit Union For Robertson Co." },
    { aba: "264181626", name: "Ascend Federal Credit Union" },
    { aba: "264181671", name: "Gateway Credit Union" },
    { aba: "264182120", name: "Fortera Federal Credit Union" },
    { aba: "264182272", name: "Jack Daniel Employee Credit Union" },
    { aba: "264182337", name: "Upper Cumberland Fcu" },
    { aba: "264182395", name: "Volunteer Corp Cu" },
    { aba: "264271183", name: "Capstar Bank" },
    { aba: "264271196", name: "Citizens Bank" },
    { aba: "264271280", name: "Elizabethton Federal Savings Bank" },
    { aba: "264271293", name: "Security Federal Bank" },
    { aba: "264271303", name: "First Horizon Bank" },
    { aba: "264271345", name: "Greeneville Federal Bank, Fsb." },
    { aba: "264271361", name: "First Horizon Bank" },
    { aba: "264271390", name: "Home Federal Savings Bk Of Tennessee" },
    { aba: "264271468", name: "Volunteer Fed. S & L Assn." },
    { aba: "264271510", name: "Hometrust Bank" },
    { aba: "264271617", name: "Newport Federal Bank" },
    { aba: "264272027", name: "First Horizon Bank" },
    { aba: "264278283", name: "Alcoa Tennessee Fcu" },
    { aba: "264278296", name: "Holston Methodist Cu" },
    { aba: "264278445", name: "United Southeast Federal C/u" },
    { aba: "264278461", name: "Bowater Employees Credit Union" },
    { aba: "264278474", name: "O M C Empl Cu" },
    { aba: "264278513", name: "Bowater Employees Credit Union" },
    { aba: "264278568", name: "Cleveland Bradley Teachers" },
    { aba: "264278584", name: "Appliance Credit Union" },
    { aba: "264278597", name: "Pathway Credit Union" },
    { aba: "264278733", name: "Copper Basin Federal Credit Union" },
    { aba: "264278827", name: "Northeast Community Credit Union" },
    { aba: "264278843", name: "Happy Valley Cu" },
    { aba: "264278872", name: "Clinchfield Federal Credit Union" },
    { aba: "264279091", name: "Consumer Credit Union" },
    { aba: "264279237", name: "Beacon Financial Credit Union" },
    { aba: "264279253", name: "Select Seven Federal Credit Union" },
    { aba: "264279295", name: "Kingsport Press Cr Union" },
    { aba: "264279321", name: "Appalachian Federal Credit Union" },
    { aba: "264279334", name: "Horizon Credit Union" },
    { aba: "264279350", name: "Eastman Credit Union" },
    { aba: "264279363", name: "Kingston Tva Employees Cu" },
    { aba: "264279415", name: "City Employees Cu" },
    { aba: "264279444", name: "Etma Fed Cr Un" },
    { aba: "264279460", name: "Consumer Credit Union" },
    { aba: "264279473", name: "First Choice Community Cu" },
    { aba: "264279538", name: "Knox Co Teachers Fcu" },
    { aba: "264279541", name: "Knox Cty Empl Cu" },
    { aba: "264279554", name: "Tnconnect Credit Union" },
    { aba: "264279567", name: "Knoxville Tva Empl. Cu" },
    { aba: "264279570", name: "Knoxville Firefighters Fed C/u" },
    { aba: "264279606", name: "Knoxville Teachers Credit Union" },
    { aba: "264279622", name: "Knoxville Law Enforcement Fcu" },
    { aba: "264279745", name: "New South Credit Union" },
    { aba: "264279758", name: "Health Systems Credit Union" },
    { aba: "264279800", name: "Ut Federal Credit Union" },
    { aba: "264280022", name: "Foothills Federal Credit Union" },
    { aba: "264280035", name: "Lowland Credit Union" },
    { aba: "264280129", name: "Maryville Mun Cu" },
    { aba: "264280187", name: "Comsumer Credit Union" },
    { aba: "264280255", name: "Greater Eastern Credit Union" },
    { aba: "264281364", name: "Enrichment Federal Credit Union" },
    { aba: "264281380", name: "Knoxville Teachers Credit Union" },
    { aba: "264281393", name: "Tenn Members 1st Federal Cu" },
    { aba: "264281416", name: "Ornl Federal Credit Union" },
    { aba: "264281432", name: "Y-12 Federal Credit Union" },
    { aba: "264282428", name: "Bmh Federal Credit Union" },
    { aba: "265070435", name: "Gulf Coast Bank & Trust Company" },
    { aba: "265070516", name: "Hancock Whitney Bank" },
    { aba: "265070529", name: "Eureka Homestead" },
    { aba: "265070532", name: "Fidelity Bank" },
    { aba: "265070545", name: "Fifth District Svgs" },
    { aba: "265070574", name: "Home Bank, National Association" },
    { aba: "265070587", name: "Home Bank, National Association" },
    { aba: "265070590", name: "Hibernia Bank" },
    { aba: "265070671", name: "Mutual Savings And Loan" },
    { aba: "265070736", name: "Hancock Whitney Bank" },
    { aba: "265070749", name: "Hibernia Bank" },
    { aba: "265070752", name: "Liberty Bank & Trust Company" },
    { aba: "265073238", name: "St Bernard School Board Efcu" },
    { aba: "265075087", name: "Onpath Federal Credit Union" },
    { aba: "265075207", name: "Coast Guard Employees Cu" },
    { aba: "265075210", name: "Jefferson Financial Cu" },
    { aba: "265075304", name: "Jefferson Financial Cu" },
    { aba: "265075346", name: "Jefferson Financial Credit Union" },
    { aba: "265075401", name: "Cusa Federal Credit Union" },
    { aba: "265075511", name: "Internal Revenue Fcu" },
    { aba: "265075540", name: "Judicial & Justice Fcu" },
    { aba: "265075634", name: "La Central Cr Union" },
    { aba: "265075663", name: "Louisiana Public" },
    { aba: "265075786", name: "Post Office Employees Credit Union" },
    { aba: "265075812", name: "New Orleans Fireman's Fcu" },
    { aba: "265075825", name: "Greater New Orleans Fcu" },
    { aba: "265075838", name: "New Orleans Clerks & Checkers Fcu" },
    { aba: "265075867", name: "New Orleans Port Comm Ecu" },
    { aba: "265075870", name: "Noda Federal Cr Union" },
    { aba: "265075883", name: "Pelican State Cu" },
    { aba: "265075980", name: "Pan Amoco Fcu" },
    { aba: "265076028", name: "Post Office Empl Cu" },
    { aba: "265076112", name: "Sewerage & Water Bd E.f.c.u." },
    { aba: "265076125", name: "Xplore Federal Credit Union" },
    { aba: "265076329", name: "Uno Federal Credit Union" },
    { aba: "265081864", name: "Tulane / Loyola Fcu" },
    { aba: "265082449", name: "Riverland Federal Credit Union" },
    { aba: "265175961", name: "Chemco Credit Union" },
    { aba: "265176009", name: "Azalea City Fcu" },
    { aba: "265176012", name: "Blue Flame Cu" },
    { aba: "265176038", name: "All In Federal Credit Union" },
    { aba: "265176083", name: "Navigator Credit Union" },
    { aba: "265176151", name: "Gulf Coast Federal Credit Union" },
    { aba: "265176193", name: "Mobile Educators Cu" },
    { aba: "265176203", name: "University Of South Alabama Fcu" },
    { aba: "265176232", name: "The Infirmary Federal Credit Union" },
    { aba: "265176245", name: "Singing River Fcu" },
    { aba: "265176258", name: "Mobile Postal Empl" },
    { aba: "265176274", name: "All In Federal Credit Union" },
    { aba: "265176313", name: "Progressive Fcu" },
    { aba: "265176339", name: "New Horizons Cu" },
    { aba: "265186594", name: "Baldwin County Fcu" },
    { aba: "265190483", name: "New Horizons Cu" },
    { aba: "265270002", name: "Abbeville B&l Asn" },
    { aba: "265270138", name: "Citizens Savings Bank" },
    { aba: "265270196", name: "Beauregard Federal Savings Bank" },
    { aba: "265270222", name: "Iberiabank" },
    { aba: "265270303", name: "Home Bank, National Association" },
    { aba: "265270316", name: "Iberiabank" },
    { aba: "265270345", name: "First Federal Bank Of Louisiana" },
    { aba: "265270413", name: "Iberiabank" },
    { aba: "265270769", name: "Iberiabank" },
    { aba: "265270772", name: "St Landry Homestead" },
    { aba: "265270811", name: "Rayne Building & Loan Association" },
    { aba: "265273025", name: "Vermilion School Employees Cu" },
    { aba: "265273041", name: "Valex Federal Credit Union" },
    { aba: "265273054", name: "Heart Of Louisiana Fcu" },
    { aba: "265273067", name: "Alexandria T&p Federal Credit Union" },
    { aba: "265273070", name: "Alexandria Municipal Employees Cu" },
    { aba: "265273096", name: "Legacy Financial Fcu" },
    { aba: "265273106", name: "Rapides Federal Credit Union" },
    { aba: "265273119", name: "Rapides Gen. Hosp. Employees Fed Cu" },
    { aba: "265273122", name: "Cenla Fcu" },
    { aba: "265273164", name: "Valex Federal Credit Union" },
    { aba: "265273892", name: "Beauregard School Employees Fcu" },
    { aba: "265273902", name: "First Street Federal Credit Union" },
    { aba: "265274325", name: "Cse Federal Credit Union" },
    { aba: "265274367", name: "Acadian Fed Cu" },
    { aba: "265274370", name: "First Pioneers Federal Cu" },
    { aba: "265274422", name: "Maple Federal Credit Union" },
    { aba: "265274435", name: "Meritus Federal Credit Union" },
    { aba: "265274451", name: "First Pioneers Fcu" },
    { aba: "265274477", name: "Acadiana Medical Federal C U" },
    { aba: "265274503", name: "Section 705 Fcu" },
    { aba: "265274516", name: "Pelican State C U" },
    { aba: "265274545", name: "University Of Louisiana Fcu" },
    { aba: "265274558", name: "3rd District Highway Federal Cu" },
    { aba: "265274574", name: "Calcasieu Teachers & Employees Cu" },
    { aba: "265274590", name: "Southwest Louisiana Credit Union" },
    { aba: "265274642", name: "Firestone Lake Charles Fcu" },
    { aba: "265274655", name: "Gca Federal Credit" },
    { aba: "265274671", name: "Pelican State C U" },
    { aba: "265274723", name: "Mcneese Federal Credit Union" },
    { aba: "265274736", name: "Access Of Louisiana Fcu" },
    { aba: "265274749", name: "Southwest Louisiana Credit Union" },
    { aba: "265274765", name: "Spelc Federal Credit Union" },
    { aba: "265274820", name: "Morton-weeks Federal Credit Union" },
    { aba: "265274859", name: "Cse Federal Credit Union" },
    { aba: "265276491", name: "People's Credit Union" },
    { aba: "265276637", name: "Old Spanish Trail Credit Union" },
    { aba: "265281729", name: "District 08 Federal Credit Union" },
    { aba: "265281897", name: "Bel Federal Cr Un" },
    { aba: "265281965", name: "Phi Federal Credit Union" },
    { aba: "265370915", name: "First Southern Bank" },
    { aba: "265371053", name: "Bancorpsouth" },
    { aba: "265371066", name: "Grand Bank For Savings,fsb" },
    { aba: "265371082", name: "Bankplus Ssb" },
    { aba: "265371121", name: "First Federal Savings & Loan" },
    { aba: "265376906", name: "Gulf Coast Community Fcu" },
    { aba: "265376948", name: "Camp Shelby Fcu" },
    { aba: "265376993", name: "Pine Belt Federal Credit Union" },
    { aba: "265377002", name: "Keesler Federal Credit Union" },
    { aba: "265377028", name: "Centuryfirst Federal Credit Union" },
    { aba: "265377073", name: "Jackson Area Fcu" },
    { aba: "265377099", name: "Ecm Federal Credit Union" },
    { aba: "265377167", name: "Armstrong Federal Credit Union" },
    { aba: "265377196", name: "Mississippi National Guard Fcu" },
    { aba: "265377222", name: "Statewide Federal Credit Union" },
    { aba: "265377235", name: "Magnolia Federal Credit Union" },
    { aba: "265377251", name: "Jpfce Credit Union" },
    { aba: "265377277", name: "Comm First Federal Credit Union" },
    { aba: "265377280", name: "Jackson Area Fcu" },
    { aba: "265377293", name: "Brightview Federal Credit Union" },
    { aba: "265377332", name: "Healthplus Federal Credit Union" },
    { aba: "265377345", name: "Rivertrust Fcu" },
    { aba: "265377387", name: "Eagle Express Federal Credit Union" },
    { aba: "265377390", name: "Ms Dhs Federal Credit Union" },
    { aba: "265377400", name: "Keesler Federal Credit Union" },
    { aba: "265377426", name: "Mississippi Hwy" },
    { aba: "265377484", name: "Members Exchange Credit Union" },
    { aba: "265377510", name: "Mississippi Public Employees Cu" },
    { aba: "265377549", name: "Mississippi Federal Credit Union" },
    { aba: "265377565", name: "Magnolia Federal Credit Union" },
    { aba: "265377633", name: "Sunbelt Federal Credit Union" },
    { aba: "265377675", name: "Mccomb Federal Credit Union" },
    { aba: "265377714", name: "1st Mississippi Federal C/u" },
    { aba: "265377730", name: "Meridian Mutual Fcu" },
    { aba: "265377743", name: "Meridan Mississippi" },
    { aba: "265377756", name: "Muna Federal Credit Union" },
    { aba: "265377811", name: "Ferguson Federal Credit Un" },
    { aba: "265377840", name: "Singing River Fcu" },
    { aba: "265377895", name: "Citizens Choice Fcu" },
    { aba: "265377905", name: "Old South Fcu" },
    { aba: "265377921", name: "Community Partners Fcu" },
    { aba: "265377950", name: "Navigator Credit Union" },
    { aba: "265377963", name: "Navigator Credit Union" },
    { aba: "265377976", name: "Gulf Trust Federal Credit Union" },
    { aba: "265378043", name: "First Neshoba Fcu" },
    { aba: "265378098", name: "Vickswood Federal Credit Union" },
    { aba: "265378166", name: "Mutual Credit Union" },
    { aba: "265378182", name: "Vicksburg Railroad Fcu" },
    { aba: "265378247", name: "Magnolia Federal Credit Union" },
    { aba: "265381991", name: "Choctau Fcu" },
    { aba: "265470158", name: "Heritage Bank Of St. Tammany" },
    { aba: "265470242", name: "The First, A National Banking Assoc" },
    { aba: "265470381", name: "Iberiabank" },
    { aba: "265470792", name: "Anthem Bank & Trust" },
    { aba: "265472062", name: "Home Bank, National Association" },
    { aba: "265472208", name: "Capital One, National Association" },
    { aba: "265472415", name: "Red River Bank" },
    { aba: "265473207", name: "Tangipahoa Par. Teachers Cu" },
    { aba: "265473252", name: "Terminal Cu" },
    { aba: "265473278", name: "Alec Federal Cu" },
    { aba: "265473281", name: "Allied Plastics Federal Credit Union" },
    { aba: "265473317", name: "Neighbors Fcu" },
    { aba: "265473320", name: "Baton Rouge Telco Fcu" },
    { aba: "265473333", name: "Baton Rouge City Parish Efcu" },
    { aba: "265473346", name: "Baton Rouge Fire Department Fcu" },
    { aba: "265473375", name: "Breco Federal Credit Union" },
    { aba: "265473388", name: "Bayou Fed C U" },
    { aba: "265473401", name: "Campus Fcu" },
    { aba: "265473414", name: "La Usa Federal Credit Union" },
    { aba: "265473472", name: "Dept Of Corrections Cu" },
    { aba: "265473485", name: "Pelican State C U" },
    { aba: "265473498", name: "East Baton Rouge Teachers Fcu" },
    { aba: "265473508", name: "Louisiana Usa Federal C/u" },
    { aba: "265473511", name: "Efcu Financial Federal Credit Union" },
    { aba: "265473524", name: "Efcu Financial Federal Credit Union" },
    { aba: "265473540", name: "Jefferson Financial Credit Union" },
    { aba: "265473582", name: "La Capitol Fcu" },
    { aba: "265473618", name: "Les Federal Credit Union" },
    { aba: "265473663", name: "Postal Cu" },
    { aba: "265473702", name: "Saif Fcu" },
    { aba: "265473715", name: "Southern Teachers & Parents Fcu" },
    { aba: "265473731", name: "Eagle Louisana Federal Credit Union" },
    { aba: "265473760", name: "Nas Jrb Credit Union" },
    { aba: "265473773", name: "Nas Jrb Cr Union" },
    { aba: "265473812", name: "Zellco Fcu" },
    { aba: "265473825", name: "Efcu Financial Federal Credit Union" },
    { aba: "265473838", name: "Bayou Community Federal Credit Union" },
    { aba: "265473922", name: "Lpea Federal Credit Union" },
    { aba: "265474044", name: "Wymar Federal Cr Union" },
    { aba: "265474057", name: "Ascension Cu" },
    { aba: "265474086", name: "River Region Federal Cu" },
    { aba: "265474109", name: "Jefferson Financial Federal Cu" },
    { aba: "265474125", name: "South Louisiana Highway Fcu" },
    { aba: "265474154", name: "District 62 Highway Fcu" },
    { aba: "265474196", name: "La Terre Federal Credit Union" },
    { aba: "265474222", name: "T E A Federal Credit Union" },
    { aba: "265474251", name: "La Terre Federal Credit Union" },
    { aba: "265474808", name: "Barton Plant Employees Fcu" },
    { aba: "265474840", name: "S E L H Fcu" },
    { aba: "265474918", name: "Jefferson Parish Employees Fcu" },
    { aba: "265475292", name: "Electrical Workers Local 130 Fcu" },
    { aba: "265476411", name: "Lousiana Federal Credit Union" },
    { aba: "265476440", name: "Essential Federal Credit Union" },
    { aba: "265476453", name: "Iberville Federal Credit Union" },
    { aba: "265476482", name: "Wbrt Federal Credit Union" },
    { aba: "265476534", name: "St. Tammany Federal Credit Union" },
    { aba: "265476547", name: "Feliciana Federal Credit Union" },
    { aba: "265476657", name: "G P Louisiana Fcu" },
    { aba: "265476660", name: "G P Louisiana Fcu" },
    { aba: "265481888", name: "Total Choice Federal Credit Union" },
    { aba: "265482382", name: "La Corporate Credit Union" },
    { aba: "265482450", name: "Geismar Complex Fed Cr Un" },
    { aba: "265576696", name: "Southern Coastal Federal Cu" },
    { aba: "265576706", name: "Biloxi Teachers Fcu" },
    { aba: "265576719", name: "Sunbelt Federal Credit Union" },
    { aba: "265576722", name: "Harrison Cty Poe Fcu" },
    { aba: "265577585", name: "Keesler Federal Credit Union" },
    { aba: "266080026", name: "Tropical Financial Credit Union" },
    { aba: "266080071", name: "Tropical Financial Credit Union" },
    { aba: "266080107", name: "Dade County Federal Credit Union" },
    { aba: "266080181", name: "Electricians No349 C U" },
    { aba: "266080204", name: "Power Financial Credit Union" },
    { aba: "266080369", name: "Miami Federal Cr Un" },
    { aba: "266080372", name: "Miami Firefighters Fed Cr Union" },
    { aba: "266080398", name: "Tropical Financial Credit Union" },
    { aba: "266080437", name: "Miami Police Fcu" },
    { aba: "266080440", name: "Miami Postal Serv Credit" },
    { aba: "266080686", name: "Guardians Credit Union" },
    { aba: "266086554", name: "Citibank" },
    { aba: "267077601", name: "South Atlantic Federal Cu" },
    { aba: "267077627", name: "Ithink Financial Credit Union" },
    { aba: "267077795", name: "Everglades Cu" },
    { aba: "267077821", name: "South Florida Educational Fcu" },
    { aba: "267077847", name: "Tropical Financial Credit Union" },
    { aba: "267077850", name: "University C U" },
    { aba: "267078150", name: "Tropical Federal Cu" },
    { aba: "267078286", name: "Priority One Credit Union Of Florida" },
    { aba: "267078299", name: "Brightstar Credit Union" },
    { aba: "267078325", name: "We Florida Financial" },
    { aba: "267078626", name: "Hialeah Municipal Employees Fcu" },
    { aba: "267078707", name: "Sun Credit Union" },
    { aba: "267078736", name: "Memorial Employees Fcu" },
    { aba: "267078765", name: "Connect Credit Union" },
    { aba: "267078781", name: "Power Financial Credit Union" },
    { aba: "267079544", name: "Monroe Cty Teachers Fed Cu" },
    { aba: "267079557", name: "Keys Federal Credit Union" },
    { aba: "267079560", name: "Southernmost Federal Credit Union" },
    { aba: "267079984", name: "Jetstream Fcu" },
    { aba: "267080135", name: "Space Coast Credit Union" },
    { aba: "267080177", name: "Space Coast Credit Union" },
    { aba: "267080326", name: "Coral Community Fcu" },
    { aba: "267080355", name: "Jetstream Fcu" },
    { aba: "267080520", name: "Power Financial Credit Union" },
    { aba: "267080614", name: "Power Financial Credit Union" },
    { aba: "267080821", name: "Dade County Federal Credit Union" },
    { aba: "267080834", name: "We Florida Financial" },
    { aba: "267080876", name: "Compass Financial Fcu" },
    { aba: "267081118", name: "We Florida Financial" },
    { aba: "267081927", name: "Broward Healthcare Fcu" },
    { aba: "267081943", name: "Pompano Beach Employee Credit Union" },
    { aba: "267082201", name: "Baptist Health South Florida Fcu" },
    { aba: "267083239", name: "Midflorida Fed C U" },
    { aba: "267083242", name: "First Choice Credit Union" },
    { aba: "267083271", name: "Velocity Community Fed Credit Union" },
    { aba: "267083323", name: "Guardians Credit Union" },
    { aba: "267083336", name: "Gold Coast Fed Credit Un" },
    { aba: "267083349", name: "First Choice Credit Union" },
    { aba: "267083763", name: "Truist Bank" },
    { aba: "267084131", name: "Jpmorgan Chase" },
    { aba: "267084144", name: "South State Bank, Na" },
    { aba: "267084199", name: "Pnc Bank, Na" },
    { aba: "267087358", name: "Pnc Bank, Na" },
    { aba: "267087769", name: "Interamerican Bank" },
    { aba: "267089136", name: "Coral Community Fcu" },
    { aba: "267089712", name: "Firstbank Florida" },
    { aba: "267090060", name: "Desjardins Bank, Na" },
    { aba: "267090455", name: "Iberiabank" },
    { aba: "267090536", name: "Bank Of America N.a." },
    { aba: "267090594", name: "Bankunited, National Association" },
    { aba: "267090691", name: "First Horizon Bank" },
    { aba: "267090769", name: "Third Federal Savings And Loan" },
    { aba: "267090772", name: "Bank Ozk" },
    { aba: "267091027", name: "Ithink Financial Credit Union" },
    { aba: "267091221", name: "New York Community Bank" },
    { aba: "267091250", name: "Iberiabank" },
    { aba: "271070513", name: "Marquette Bank" },
    { aba: "271070584", name: "Central Savings F.s.b." },
    { aba: "271070607", name: "Pnc Bank, Na" },
    { aba: "271070681", name: "Community Savings Bank" },
    { aba: "271070791", name: "Pnc Bank, Na" },
    { aba: "271070801", name: "Citibank Federal Savings Bank" },
    { aba: "271070814", name: "First Svgs Bank Of Hegewisch" },
    { aba: "271070843", name: "Royal Savings Bank" },
    { aba: "271070908", name: "Hoyne Savings Bank" },
    { aba: "271070924", name: "Gn Bank" },
    { aba: "271070979", name: "Marquette Bank" },
    { aba: "271071004", name: "Liberty Bank For Savings" },
    { aba: "271071033", name: "Hoyne Savings Bank" },
    { aba: "271071062", name: "Mutual Federal Bank" },
    { aba: "271071114", name: "N Side Fed. S&l Chicago" },
    { aba: "271071156", name: "Tcf National Bank Illinois" },
    { aba: "271071198", name: "Hoyne Savings Bank" },
    { aba: "271071224", name: "Pulaski S&l Asn" },
    { aba: "271071253", name: "Royal Savings Bank" },
    { aba: "271071279", name: "Self-help Fcu Chicago Division" },
    { aba: "271071295", name: "Fifth Third Bank" },
    { aba: "271071321", name: "Citizens Bank Na" },
    { aba: "271071334", name: "Tcf National Bank" },
    { aba: "271071402", name: "Citibank West" },
    { aba: "271071415", name: "Royal Savings Bank" },
    { aba: "271076986", name: "Acme Continental Cred Unio" },
    { aba: "271077244", name: "77th St Depot Fcu" },
    { aba: "271077257", name: "Archer Heights Credit Union" },
    { aba: "271077888", name: "Chicago Area Office F C U" },
    { aba: "271077891", name: "Heights Auto Workers Credit Union" },
    { aba: "271077985", name: "Chicago Fire Fighters C U" },
    { aba: "271078094", name: "Chicago Municipal Emp C U" },
    { aba: "271078146", name: "The Chicago Patrolmen's Fed Cu" },
    { aba: "271078162", name: "Sourceone Credit Union" },
    { aba: "271078175", name: "Chicago Post Office Empl Cr Un" },
    { aba: "271078557", name: "C T A F C Federal Credit Union" },
    { aba: "271078638", name: "Credit Union 1" },
    { aba: "271078719", name: "First Northern Credit Union" },
    { aba: "271078997", name: "Illiana Financial Credit Union" },
    { aba: "271079103", name: "Us Employees Credit Union" },
    { aba: "271079271", name: "United Credit Union" },
    { aba: "271079352", name: "United Credit Union" },
    { aba: "271079404", name: "Israel Methcomm Fcu" },
    { aba: "271079640", name: "United C U Local 73" },
    { aba: "271079747", name: "Northstar Credit Union" },
    { aba: "271080095", name: "Northside L Federal Credit Union" },
    { aba: "271080309", name: "Peoples Energy Credit Union" },
    { aba: "271080325", name: "Great Lakes Credit Union" },
    { aba: "271080354", name: "Planites Credit Union" },
    { aba: "271080545", name: "Synergy Partners Credit Union" },
    { aba: "271080684", name: "First Financial Credit Union" },
    { aba: "271080710", name: "Mwrd Employees Credit Union" },
    { aba: "271080817", name: "Selfreliance Fed C U" },
    { aba: "271080862", name: "Sherwin Williams C U" },
    { aba: "271080891", name: "74th St Dfcu" },
    { aba: "271080956", name: "South Division Cr Un" },
    { aba: "271081502", name: "United Credit Union" },
    { aba: "271081528", name: "Alliant Credit Union" },
    { aba: "271081560", name: "Maroon Financial Credit Union" },
    { aba: "271081599", name: "Great Lakes Credit Union" },
    { aba: "271081939", name: "Access Credit Union" },
    { aba: "271082022", name: "77th St Depot Fcu" },
    { aba: "271092122", name: "Trinity Ucc Federal Credit Union" },
    { aba: "271092892", name: "Fellowship Baptist Church Cu" },
    { aba: "271170154", name: "Beardstown Savings S.b." },
    { aba: "271170264", name: "Busey Bank" },
    { aba: "271170390", name: "First Federal S & L/champaign-urbana" },
    { aba: "271170413", name: "First Federal S&l Assn Of Central Il" },
    { aba: "271171616", name: "Dewitt Savings Bank" },
    { aba: "271171674", name: "First Savings Bank" },
    { aba: "271171988", name: "Union Savings Bank" },
    { aba: "271172262", name: "Union Federal S & L Assn." },
    { aba: "271172275", name: "Union Fed Svg & Ln" },
    { aba: "271172521", name: "Washington Savings Bank" },
    { aba: "271172644", name: "Security Savings Bank" },
    { aba: "271172754", name: "Busey Bank" },
    { aba: "271172987", name: "First Mid Illinois Bank & Trust" },
    { aba: "271173038", name: "Marion County Savings Bank" },
    { aba: "271173339", name: "First Savanna Savings Bank" },
    { aba: "271173368", name: "First Fed S&l Asn Of Central Il, S.b" },
    { aba: "271173452", name: "Security Bank S B" },
    { aba: "271173494", name: "Sterling Federal Bk-f S B" },
    { aba: "271173627", name: "Morton Community Bank" },
    { aba: "271173724", name: "Iroquois Fed Svg & Ln Assn" },
    { aba: "271176035", name: "Bloomington Postal Empl Cu" },
    { aba: "271176048", name: "Bloomington Municipal C U" },
    { aba: "271176080", name: "Prairieland Federal Credit Union" },
    { aba: "271176103", name: "Mid-illini Credit Union" },
    { aba: "271176116", name: "I A A Credit Union" },
    { aba: "271176200", name: "State Farm Fed Credit Union" },
    { aba: "271176226", name: "Land Of Lincoln Cr Un" },
    { aba: "271176653", name: "University Of Illinois Community Cu" },
    { aba: "271176679", name: "Champaign County Sch Ecu" },
    { aba: "271176828", name: "Central Illinois Cr Un" },
    { aba: "271176899", name: "University Of Il Empl C U" },
    { aba: "271176909", name: "United Equity Credit Union" },
    { aba: "271182430", name: "Credit Union 1" },
    { aba: "271182524", name: "Education Personnel Fcu" },
    { aba: "271182566", name: "Landmark Credit Union" },
    { aba: "271182579", name: "New Horizon Cu" },
    { aba: "271182618", name: "Vibrant Credit Union" },
    { aba: "271182634", name: "Landmark Credit Union" },
    { aba: "271182692", name: "Tee Pak Credit Union" },
    { aba: "271182702", name: "Landmark Credit Union" },
    { aba: "271182715", name: "Vibrant Credit Union" },
    { aba: "271182883", name: "Adm Credit Union" },
    { aba: "271182919", name: "Decatur Earthmover C U" },
    { aba: "271182993", name: "Decatur Postal Cu" },
    { aba: "271183015", name: "Land Of Lincoln Cr Un" },
    { aba: "271183028", name: "Staley Credit Union" },
    { aba: "271183109", name: "I.b.e.w Local #146 Credit Union" },
    { aba: "271183125", name: "1st Mid America Credit Union" },
    { aba: "271183219", name: "Staley Credit Union" },
    { aba: "271183646", name: "Vibrant Credit Union" },
    { aba: "271183662", name: "I H Mississippi Valley Credit Union" },
    { aba: "271183691", name: "Service Plus Credit Union" },
    { aba: "271183701", name: "Citizens Equity First Cu" },
    { aba: "271183727", name: "Kemba Peoria C U" },
    { aba: "271184425", name: "Cornerstone Credit Union" },
    { aba: "271184441", name: "First Northern Credit Union" },
    { aba: "271184522", name: "Gale Credit Union" },
    { aba: "271184564", name: "Galesburg Burlington C U" },
    { aba: "271186070", name: "Blaw Knox Credit Union" },
    { aba: "271186122", name: "Area Educational Credit Union" },
    { aba: "271186368", name: "Cooperative Choice Network Cu" },
    { aba: "271186423", name: "Deere Employees Credit Union" },
    { aba: "271186449", name: "Kone Employees Credit Union" },
    { aba: "271186517", name: "Moline Municipal C U" },
    { aba: "271186766", name: "Advantage One Credit Union" },
    { aba: "271186931", name: "Illinois State Credit Union" },
    { aba: "271187574", name: "Tazewell County Sch E C U" },
    { aba: "271187587", name: "Kemba Peoria Credit Union" },
    { aba: "271187642", name: "Cooperative Choice Network Cu" },
    { aba: "271187749", name: "Peoria Bell Credit Union" },
    { aba: "271187765", name: "Peoria City Emply C U" },
    { aba: "271187833", name: "Peoria Postal Empl C U" },
    { aba: "271187875", name: "Redbrand Credit Union" },
    { aba: "271188078", name: "Community Plus Federal Cu" },
    { aba: "271188081", name: "Credit Union 1" },
    { aba: "271188230", name: "Gas + Electric C U" },
    { aba: "271188285", name: "Gas And Electric Credit Union" },
    { aba: "271188311", name: "Quad Cities Postal Credit Union" },
    { aba: "271188337", name: "Ria Federal Credit Union" },
    { aba: "271188968", name: "Blackhawk Area Cr Un" },
    { aba: "271189310", name: "Cooperative Choice Network Cu" },
    { aba: "271189336", name: "Illinois State Police Fcu" },
    { aba: "271189352", name: "Cooperative Choice Network Cu" },
    { aba: "271189381", name: "Land Of Lincoln Credit Union" },
    { aba: "271189417", name: "Land Of Lincoln Credit Union" },
    { aba: "271189433", name: "Illinois Educators Credit Union" },
    { aba: "271189446", name: "Heartland Credit Union" },
    { aba: "271189488", name: "Springfield City Employees Cr Union" },
    { aba: "271189491", name: "Financial Partners Credit Union" },
    { aba: "271189608", name: "Select Employees Credit Union" },
    { aba: "271189734", name: "Taylorville Community Credit Union" },
    { aba: "271189792", name: "University Of Illinois Community Cre" },
    { aba: "271189844", name: "Community Healthcare Credit Union" },
    { aba: "271190150", name: "Landmark Credit Union" },
    { aba: "271192310", name: "Western Illinois C U" },
    { aba: "271192802", name: "Illinois Educators Credit Union" },
    { aba: "271192967", name: "Funeral Service Credit Union" },
    { aba: "271250647", name: "Bmo Harris Bank,n.a." },
    { aba: "271274115", name: "Horizon Bk, An Indiana Bank" },
    { aba: "271274241", name: "Horizon Bk, An Indiana Bank" },
    { aba: "271276537", name: "Laporte Comm Fed Cr Un" },
    { aba: "271290351", name: "Allegius Fcu" },
    { aba: "271290597", name: "Chiphone Federal Cr Un" },
    { aba: "271290623", name: "Chiphone Federal Cr Un" },
    { aba: "271290681", name: "Inova Federal Credit Union" },
    { aba: "271291017", name: "Interra Credit Union" },
    { aba: "271291389", name: "Grand Heritage Fcu" },
    { aba: "271291457", name: "First Trust Credit Union" },
    { aba: "271291460", name: "Members Advantage Credit Union" },
    { aba: "271291486", name: "Laporte Community Fcu(alis-chalmers)" },
    { aba: "271291525", name: "Tlcu Financial" },
    { aba: "271291596", name: "Notre Dame Fcu" },
    { aba: "271291619", name: "Midwest Family Fcu" },
    { aba: "271291648", name: "Aaa Federal Credit Union" },
    { aba: "271291680", name: "Imeco Fed C U" },
    { aba: "271291729", name: "River Bend Federal Credit Union" },
    { aba: "271291761", name: "South Bend Firefighters" },
    { aba: "271291774", name: "South Bend Post Office Cr Un" },
    { aba: "271291790", name: "Policemen's F C U" },
    { aba: "271291813", name: "So Bend Transit Federal Cr Un" },
    { aba: "271291826", name: "Teachers Credit Union" },
    { aba: "271291855", name: "Communitywide Fed Cr Un" },
    { aba: "271291884", name: "First Trust Federal Credit Union" },
    { aba: "271292838", name: "Generations Federal Credit Union" },
    { aba: "271970066", name: "Citibank F S B" },
    { aba: "271970079", name: "Corporate America Family C U" },
    { aba: "271970147", name: "Fifth Third Bank" },
    { aba: "271970299", name: "Midland Svgs & Loan Assn" },
    { aba: "271970312", name: "Citibank Na" },
    { aba: "271970448", name: "Peoples Bank" },
    { aba: "271971502", name: "Central Fed S&l Asn-cicero" },
    { aba: "271971560", name: "Pnc Bank, Na" },
    { aba: "271971599", name: "West Town Bank And Trust" },
    { aba: "271971735", name: "First Midwest Bank" },
    { aba: "271971777", name: "Citibank A Fed Sav Bk" },
    { aba: "271971816", name: "Pnc Bank, Na" },
    { aba: "271971829", name: "Fifth Third Bank" },
    { aba: "271971887", name: "Tcf National Bank Illinois" },
    { aba: "271972064", name: "Citibank F S B(glen Ellyn Savings)" },
    { aba: "271972103", name: "State Bank" },
    { aba: "271972116", name: "First Merchants Bank" },
    { aba: "271972161", name: "Frist Savings Bank Of Hegewisch" },
    { aba: "271972239", name: "Midland States Bank" },
    { aba: "271972310", name: "Eureka Savings Bank" },
    { aba: "271972381", name: "Bmo Harris Bank,n.a." },
    { aba: "271972404", name: "Lisle Savings Bank" },
    { aba: "271972501", name: "Ottawa Savings Bank" },
    { aba: "271972569", name: "Mchenry Savings Bank" },
    { aba: "271972572", name: "Tcf National Bank Illinois" },
    { aba: "271972653", name: "State Bank" },
    { aba: "271972718", name: "Us Bank, Na" },
    { aba: "271972828", name: "Marquette Bank" },
    { aba: "271972899", name: "Bankfinancial, National Association" },
    { aba: "271972912", name: "First Fed Savings Bank" },
    { aba: "271972925", name: "Ottawa Savings Bank" },
    { aba: "271973128", name: "Peru Federal Savings Bank" },
    { aba: "271973487", name: "Fifth Third Bank" },
    { aba: "271973542", name: "Streator Home Building & Ln Asn" },
    { aba: "271973568", name: "Marquette Bank" },
    { aba: "271973762", name: "North Shore Trust & Svgs" },
    { aba: "271973775", name: "First Midwest Bank" },
    { aba: "271973924", name: "Peoples Bank" },
    { aba: "271973937", name: "First Midwest Bank" },
    { aba: "271974017", name: "American Community Bank Of Indiana" },
    { aba: "271974033", name: "First Merchants Bank" },
    { aba: "271974046", name: "Peoples Bank" },
    { aba: "271974059", name: "First Savings Bank Of Hegewisch" },
    { aba: "271974062", name: "First Financial Bank" },
    { aba: "271974224", name: "Peoples Bank" },
    { aba: "271974295", name: "Bmo Harris Bank Na" },
    { aba: "271975388", name: "Abri Credit Union" },
    { aba: "271975391", name: "Aaec Credit Union" },
    { aba: "271975401", name: "Metro Fed C U" },
    { aba: "271975443", name: "Fox Valley Credit Union" },
    { aba: "271975456", name: "Fox Valley Credit Union" },
    { aba: "271975485", name: "Aurora Firefighters Cu" },
    { aba: "271975498", name: "Aurora Policeman Credit Union" },
    { aba: "271975508", name: "Aurora Postal Emply C U" },
    { aba: "271975566", name: "Earthmover Cu" },
    { aba: "271975715", name: "Partnership Financial Cr Un" },
    { aba: "271975951", name: "Great Lakes Credit Union" },
    { aba: "271976329", name: "School District 218 Employees Fcu" },
    { aba: "271976510", name: "Illiana Finanicial Credit Union" },
    { aba: "271976523", name: "Illiana Financial Credit Union" },
    { aba: "271977616", name: "Burlington Northern Santa Fe Cr Un" },
    { aba: "271979193", name: "Great Lakes Credit Union" },
    { aba: "271980111", name: "Northwest Community Credit Union" },
    { aba: "271981657", name: "Partnership Financial Cr Un" },
    { aba: "271982083", name: "Hts Auto Workers Cu" },
    { aba: "271982119", name: "Illiana Financial Credit Union" },
    { aba: "271982290", name: "Midwest Operating Engineers C U" },
    { aba: "271982737", name: "Vibrant Credit Union" },
    { aba: "271982779", name: "Northern Illinois Federal Cu" },
    { aba: "271983341", name: "Northwest Municipal Fcu" },
    { aba: "271983422", name: "Consumers Cooperative Credit Union" },
    { aba: "271983804", name: "Kane County Teachers Credit Union" },
    { aba: "271983833", name: "Kane County Teachers C U" },
    { aba: "271984133", name: "Evergreen Park Schools Fcu" },
    { aba: "271984162", name: "Great Lakes Credit Union" },
    { aba: "271984285", name: "Leyden Credit Union" },
    { aba: "271984311", name: "Andigo Credit Union" },
    { aba: "271984780", name: "Partnership Financial Cr Un" },
    { aba: "271984832", name: "Great Lakes Credit Union" },
    { aba: "271984861", name: "Community Trust Credit Union" },
    { aba: "271984890", name: "Harvard Community Credit Union" },
    { aba: "271985093", name: "Vibrant Credit Union" },
    { aba: "271985213", name: "Numark Credit Union" },
    { aba: "271985226", name: "Argonne Credit Union" },
    { aba: "271985352", name: "New Century Federal Credit Union" },
    { aba: "271985420", name: "Fieldstone Credit Union" },
    { aba: "271985433", name: "Illiana Financial Credit Union" },
    { aba: "271985462", name: "Commonwealth Credit Union" },
    { aba: "271985491", name: "Illiana Financial Credit Union" },
    { aba: "271985556", name: "Elite Community Credit Union" },
    { aba: "271985598", name: "Kankakee Terminal Belt C U" },
    { aba: "271985624", name: "2 Rivers Area Credit Union" },
    { aba: "271985721", name: "American Nickeloid Employees Cr Un" },
    { aba: "271985938", name: "Canals & Trails Cu" },
    { aba: "271986678", name: "Fox Valley Credit Union" },
    { aba: "271986746", name: "Streator Onized Credit Union" },
    { aba: "271986801", name: "Consumers Cooperative Credit Union" },
    { aba: "271986869", name: "Northstar Credit Union" },
    { aba: "271987075", name: "Corporate America Family Cu" },
    { aba: "271987088", name: "First Financial Cr Un" },
    { aba: "271987130", name: "Cbi Federal Credit Union" },
    { aba: "271987143", name: "Central Credit Union Of Il" },
    { aba: "271987224", name: "Metropolitan L Fed Cr Un" },
    { aba: "271987305", name: "Illinois Valley Cr Un" },
    { aba: "271987334", name: "Bakelite Ottawa Plastics C U" },
    { aba: "271987363", name: "Financial Plus C U" },
    { aba: "271987392", name: "Ottawa Hiway Cu" },
    { aba: "271987635", name: "Alloya Corporate Fcu" },
    { aba: "271988113", name: "Acme Continental Credit Union" },
    { aba: "271988388", name: "Rock Valley Fed Credit Union" },
    { aba: "271988456", name: "Generations Credit Union" },
    { aba: "271988647", name: "Rockford Bell Credit Union" },
    { aba: "271988689", name: "Rockford Municipal Employees C U" },
    { aba: "271988702", name: "Linxus Credit Union" },
    { aba: "271988760", name: "Membersalliance Credit Union" },
    { aba: "271988773", name: "Swedish American Federal Cr Un" },
    { aba: "271988854", name: "Romeoville Community C U" },
    { aba: "271988948", name: "N I C E Fed Cu" },
    { aba: "271988980", name: "Consumers Cooperative Credit Union" },
    { aba: "271988993", name: "Northstar Credit Union" },
    { aba: "271989060", name: "Partnership Financial Cr Un" },
    { aba: "271989112", name: "First Financial Cr Un" },
    { aba: "271989222", name: "Great Lakes Credit Union" },
    { aba: "271989675", name: "Streator Community Credit Union" },
    { aba: "271989688", name: "Streator Onized Credit Union" },
    { aba: "271989714", name: "Illinois Community Credit Union" },
    { aba: "271989756", name: "Great Lakes Credit Union" },
    { aba: "271989950", name: "Consumers Cooperative Cu" },
    { aba: "271990169", name: "Dupage County Employees Credit Union" },
    { aba: "271990198", name: "Dupage Credit Union" },
    { aba: "271990253", name: "Partnership Financial Cr Un" },
    { aba: "271990295", name: "Great Lakes Credit Union" },
    { aba: "271990473", name: "Advance Financial Fcu" },
    { aba: "271990761", name: "Base Federal Credit Union" },
    { aba: "271990787", name: "Profinance Fcu" },
    { aba: "271990871", name: "Us 1364 Federal Credit Union" },
    { aba: "271990923", name: "Gesb Sheet Metal Workers Fcu" },
    { aba: "271990952", name: "Northern Indiana Fed Cr Un" },
    { aba: "271990994", name: "Tech Cr Un" },
    { aba: "271991139", name: "Regional Fed C U" },
    { aba: "271991155", name: "Midwest Carpenters&millwrights Fcu" },
    { aba: "271991168", name: "Finans Federal Credit Union" },
    { aba: "271991207", name: "Local 697 F C U" },
    { aba: "271991210", name: "Members Source Credit Union" },
    { aba: "271991951", name: "Whiting Refinery Fcu" },
    { aba: "271992141", name: "Loyola University Empl Fcu" },
    { aba: "271992183", name: "Healthcare Associates Cr Un" },
    { aba: "271992219", name: "Great Lakes Credit Union" },
    { aba: "271992400", name: "Baxter Credit Union" },
    { aba: "271992468", name: "Great Lakes Credit Union" },
    { aba: "271992581", name: "Riverside Community Credit Union" },
    { aba: "271992989", name: "Great Horizons Federal Credit Un" },
    { aba: "272071223", name: "Liberty Bank & Trust Company" },
    { aba: "272077984", name: "New England Federal Credit Union" },
    { aba: "272078048", name: "Genisys Credit Union" },
    { aba: "272078116", name: "One Detroit Credit Union" },
    { aba: "272078268", name: "Lake Trust Credit Union" },
    { aba: "272078297", name: "Diversified Members Credit Union" },
    { aba: "272078323", name: "Zeal Credit Union" },
    { aba: "272078352", name: "Southeast Michigan St Empl Fed. C.u." },
    { aba: "272078365", name: "Michigan First Cu" },
    { aba: "272078394", name: "Electrical Workers Credit Union" },
    { aba: "272078417", name: "Zeal Credit Union" },
    { aba: "272078446", name: "Community Choice Cu" },
    { aba: "272078488", name: "Self Reliance Federal Credit Union" },
    { aba: "272078653", name: "International Uaw Fcu" },
    { aba: "272078679", name: "Oakland County Credit Union" },
    { aba: "272078828", name: "Chiropractic Federal Credit Union" },
    { aba: "272078844", name: "Michigan Columbus Fed Credit Union" },
    { aba: "272078857", name: "Christian Financial Credit Union" },
    { aba: "272078925", name: "Parkway Federal Credit Union" },
    { aba: "272079021", name: "Community Choice Cu" },
    { aba: "272079377", name: "Teamsters Credit Union" },
    { aba: "272079429", name: "Unity Credit Union" },
    { aba: "272079432", name: "Ukrainian Selfreliance Mi Federal Cu" },
    { aba: "272079487", name: "Public Service Credit Union" },
    { aba: "272471072", name: "Homestead Savings Bank" },
    { aba: "272471085", name: "Mbank" },
    { aba: "272471098", name: "Tcf National Bank" },
    { aba: "272471124", name: "Independent Bank" },
    { aba: "272471153", name: "Edgewater Bank" },
    { aba: "272471166", name: "Eaton Community Bank" },
    { aba: "272471179", name: "Tcf National Bank" },
    { aba: "272471182", name: "Dearborn Federal Savings Bank" },
    { aba: "272471357", name: "Commercial Bank" },
    { aba: "272471399", name: "Flagstar Bank" },
    { aba: "272471438", name: "Fifth Third Bank" },
    { aba: "272471483", name: "Horizon Bank, An Indiana Bank" },
    { aba: "272471519", name: "Teachers Credit Union" },
    { aba: "272471548", name: "Tcf National Bank" },
    { aba: "272471551", name: "Tcf National Bank" },
    { aba: "272471632", name: "Sturgis Bank And Trust Co" },
    { aba: "272471658", name: "Horizon Bk, An Indiana Bank" },
    { aba: "272471661", name: "Tcf National Bank" },
    { aba: "272471674", name: "Bank Of America, Na" },
    { aba: "272471836", name: "Tcf National Bank" },
    { aba: "272471849", name: "Sterling Bank & Trust Fsb" },
    { aba: "272471852", name: "Flagstar Bank, Fsb" },
    { aba: "272476239", name: "River Valley Cu" },
    { aba: "272476268", name: "Lenco Ed Credit Union" },
    { aba: "272476349", name: "Allegan Community Federal Cu" },
    { aba: "272476378", name: "Zeal Credit Union" },
    { aba: "272476381", name: "Graco Federal Credit Union" },
    { aba: "272476394", name: "Gratiot Community Credit Union" },
    { aba: "272476404", name: "Besser Credit Union" },
    { aba: "272476420", name: "Alpena Community Credit Union" },
    { aba: "272476433", name: "Alpena Alcona Area Credit Union" },
    { aba: "272476446", name: "Besser Credit Union" },
    { aba: "272476462", name: "H.p.c. Credit Union" },
    { aba: "272476527", name: "Lake Trust Credit Union" },
    { aba: "272476543", name: "The University Of Mich Cu" },
    { aba: "272476653", name: "Omni Community Credit Union" },
    { aba: "272476666", name: "Advia Credit Union" },
    { aba: "272476705", name: "Grand Trunk Employees Federal Cu" },
    { aba: "272476734", name: "Kellogg Community Credit Union." },
    { aba: "272476763", name: "Honor Credit Union" },
    { aba: "272476776", name: "Blue Ox Credit Union" },
    { aba: "272476802", name: "Honor Credit Union" },
    { aba: "272476844", name: "Financial Edge Community Cu" },
    { aba: "272476912", name: "Great Lakes Federal Credit Union" },
    { aba: "272476925", name: "Copoco Community Credit Union" },
    { aba: "272476983", name: "Sunrise Family Credit Union" },
    { aba: "272476996", name: "United Bay Community Credit Union" },
    { aba: "272477034", name: "Unified Communities Fcu" },
    { aba: "272477199", name: "Birmingham Bloomfield Credit Union" },
    { aba: "272477241", name: "United Financial Credit Union" },
    { aba: "272477267", name: "Country Heritage Credit Union" },
    { aba: "272477306", name: "West Michigan Credit Union" },
    { aba: "272477322", name: "Wexford Community Credit Union" },
    { aba: "272477393", name: "Christian Financial Credit Union" },
    { aba: "272477429", name: "Cornerstone Community Fin Fcu" },
    { aba: "272477432", name: "Extra Credit Union" },
    { aba: "272477445", name: "Lake Trust Credit Union" },
    { aba: "272477461", name: "4front Credit Union" },
    { aba: "272477500", name: "Straits Area Federal Credit Union" },
    { aba: "272477539", name: "Members First Credit Union" },
    { aba: "272477568", name: "Detroit Metropolitain Credit Union" },
    { aba: "272477571", name: "Alliance Catholic Credit Union" },
    { aba: "272477681", name: "United Churches Credit Union" },
    { aba: "272477694", name: "Zeal Credit Union" },
    { aba: "272477720", name: "Parkside Credit Union" },
    { aba: "272477746", name: "Membersfocus Community Credit Union" },
    { aba: "272477885", name: "Community Alliance Credit Union" },
    { aba: "272478075", name: "Alloya Corporate Fcu" },
    { aba: "272478130", name: "Construction Federal Credit Union" },
    { aba: "272478350", name: "Southeast Michigan State Empl Fcu" },
    { aba: "272478842", name: "Michigan Columbus Fed Credit Union" },
    { aba: "272478910", name: "Parda Federal Credit Union" },
    { aba: "272479388", name: "Vibe Credit Union" },
    { aba: "272479582", name: "Dowagiac Area Federal Credit Union" },
    { aba: "272479618", name: "Eastpointe Community Cu" },
    { aba: "272479663", name: "Msu Federal Credit Union" },
    { aba: "272479715", name: "Downriver Community Federal Cu" },
    { aba: "272479728", name: "Community Focus Fcu" },
    { aba: "272479744", name: "Montcalm Public Empls Credit Union" },
    { aba: "272479812", name: "Loc Federal Credit Union" },
    { aba: "272479841", name: "Credit Union One" },
    { aba: "272479867", name: "Filer Credit Union" },
    { aba: "272479870", name: "Genisys Credit Union" },
    { aba: "272479919", name: "Dort Financial Credit Union" },
    { aba: "272479922", name: "Genisys Credit Union" },
    { aba: "272479935", name: "Elga Credit Union" },
    { aba: "272479980", name: "Pfcu" },
    { aba: "272479993", name: "Sovita Credit Union" },
    { aba: "272480047", name: "Genisys Credit Union" },
    { aba: "272480063", name: "The University Of Mich Cu" },
    { aba: "272480115", name: "Security Credit Union" },
    { aba: "272480128", name: "Financial Plus Credit Union" },
    { aba: "272480131", name: "Frankenmuth Credit Union" },
    { aba: "272480157", name: "Frankfort Community Federal Cr Union" },
    { aba: "272480173", name: "Michigan Schools & Government Cu" },
    { aba: "272480199", name: "Gerber Federal Credit Union" },
    { aba: "272480212", name: "Rogue River Community Credit Union" },
    { aba: "272480306", name: "Lake Michigan Credit Union" },
    { aba: "272480335", name: "Tri-cities Credit Union" },
    { aba: "272480348", name: "Lake Trust Credit Union" },
    { aba: "272480393", name: "Aac Credit Union" },
    { aba: "272480429", name: "Atl Federal Credit Union" },
    { aba: "272480432", name: "Adventure Credit Union" },
    { aba: "272480461", name: "River Valley Credit Union" },
    { aba: "272480500", name: "Lake Michigan Credit Union" },
    { aba: "272480571", name: "Lake Michigan Credit Union" },
    { aba: "272480636", name: "Community West Credit Union" },
    { aba: "272480649", name: "Michigan First Credit Union" },
    { aba: "272480665", name: "Members First Credit Union" },
    { aba: "272480678", name: "Lake Michigan Credit Union" },
    { aba: "272480694", name: "Preferred Credit Union" },
    { aba: "272480775", name: "First United Federal Credit Union" },
    { aba: "272480791", name: "Kent County Credit Union" },
    { aba: "272480872", name: "Meijer Credit Union" },
    { aba: "272480979", name: "Lake Michigan Credit Union" },
    { aba: "272480995", name: "Community West Credit Union" },
    { aba: "272481004", name: "West Michigan Credit Union" },
    { aba: "272481017", name: "Western Districts Members Cu" },
    { aba: "272481114", name: "Preferred Credit Union" },
    { aba: "272481185", name: "Harbor Beach Community Fcu" },
    { aba: "272481266", name: "Thornapple Valley Credit Union" },
    { aba: "272481363", name: "A A C Credit Union" },
    { aba: "272481389", name: "Aac Credit Union" },
    { aba: "272481499", name: "Michigan One Community Credit Union" },
    { aba: "272481512", name: "Aeroquip Credit Union" },
    { aba: "272481554", name: "South Central Credit Union" },
    { aba: "272481567", name: "American 1 Credit Union" },
    { aba: "272481583", name: "Cp Financial Credit Union" },
    { aba: "272481619", name: "Consumers Professional Cu" },
    { aba: "272481651", name: "Community Choice Credit Union" },
    { aba: "272481680", name: "South Central Credit Union" },
    { aba: "272481729", name: "Omni Community Credit Union" },
    { aba: "272481761", name: "Advia Credit Union" },
    { aba: "272481826", name: "Lake Michigan Credit Union" },
    { aba: "272481839", name: "Consumers Credit Union" },
    { aba: "272481855", name: "Kalsee Credit Union" },
    { aba: "272481871", name: "Arbor Financial Credit Union" },
    { aba: "272481884", name: "Honor Credit Union" },
    { aba: "272481952", name: "Astera Credit Union" },
    { aba: "272481965", name: "Auto-owners Associates Credit Union" },
    { aba: "272481981", name: "Capital Area School Employees Cu" },
    { aba: "272482003", name: "Consumers Professional Credit Union" },
    { aba: "272482032", name: "Farm Bureau Family Credit Union" },
    { aba: "272482061", name: "Lafcu" },
    { aba: "272482223", name: "Gabriels Catholic Credit Union" },
    { aba: "272482265", name: "Lake Trust Credit Union" },
    { aba: "272482346", name: "Credit Union One" },
    { aba: "272482375", name: "Lincoln Park Community Cu" },
    { aba: "272482414", name: "Michigan Columbus Fed Credit Union" },
    { aba: "272482430", name: "Catholic Vantage Financial Fcu" },
    { aba: "272482456", name: "Parkside Livonia Credit Union" },
    { aba: "272482508", name: "Michigan Educational Cu" },
    { aba: "272482511", name: "Safe Harbor Credit Union" },
    { aba: "272482582", name: "Michigan Schools And Government Cu" },
    { aba: "272482676", name: "West Michigan Credit Union" },
    { aba: "272482715", name: "Marshall Community Credit Union" },
    { aba: "272482773", name: "Zeal Credit Union" },
    { aba: "272482825", name: "Community West Credit Union" },
    { aba: "272482838", name: "Dow Chemical Employees Credit Union" },
    { aba: "272482841", name: "Members First Credit Union" },
    { aba: "272482883", name: "Monroe County Community Cu" },
    { aba: "272482906", name: "Monroe County Community Cu" },
    { aba: "272483015", name: "Isabella Community Credit Union" },
    { aba: "272483060", name: "Port City Federal Credit Union" },
    { aba: "272483073", name: "Lakeshore Federal Credit Union" },
    { aba: "272483099", name: "First General Credit Union" },
    { aba: "272483109", name: "West Michigan Postal Service Fcu" },
    { aba: "272483125", name: "Community Choice Cu" },
    { aba: "272483170", name: "Muskegon Co-op Federal Credit Union" },
    { aba: "272483196", name: "Muskegon Federal Credit Union" },
    { aba: "272483251", name: "Family Financial Credit Union" },
    { aba: "272483264", name: "Service 1 Federal Credit Union" },
    { aba: "272483277", name: "Michigan Coastal Credit Un" },
    { aba: "272483316", name: "Best Financial Credit Union" },
    { aba: "272483332", name: "Muskegon Patternmakers Fcu" },
    { aba: "272483345", name: "Service 1 Federal Credit Union" },
    { aba: "272483387", name: "Circle Federal Credit Union" },
    { aba: "272483426", name: "Four Flags Area Credit Union" },
    { aba: "272483484", name: "Awakon Federal Credit Union" },
    { aba: "272483536", name: "Honor Credit Union" },
    { aba: "272483594", name: "Owosso Wbc Fed Credit Union" },
    { aba: "272483633", name: "Advia Credit Union" },
    { aba: "272483675", name: "St Francis X. Fed Credit Union" },
    { aba: "272483743", name: "Community Financial Credit Union" },
    { aba: "272483785", name: "Chief Pontiac Fed Credit Union" },
    { aba: "272483808", name: "Oakland County Credit Union" },
    { aba: "272483840", name: "Michigan Legacy Credit Union" },
    { aba: "272483853", name: "Oakland County Credit Union" },
    { aba: "272483879", name: "Birmingham Bloomfield Credit Union" },
    { aba: "272483882", name: "Credit Union One" },
    { aba: "272483905", name: "Genisys Credit Union" },
    { aba: "272483918", name: "Birmingham-bloomfield Credit Union" },
    { aba: "272483947", name: "Blue Water Federal Credit Union" },
    { aba: "272483963", name: "Advia Credit Union" },
    { aba: "272484014", name: "Flagship Federal Cu" },
    { aba: "272484056", name: "Pfcu" },
    { aba: "272484098", name: "Frankenmuth Credit Union" },
    { aba: "272484108", name: "Belle River Community Credit Union" },
    { aba: "272484137", name: "Monroe County Community Cu" },
    { aba: "272484166", name: "Lake Michigan Credit Union" },
    { aba: "272484179", name: "Community West Credit Union" },
    { aba: "272484195", name: "Calcite Credit Union" },
    { aba: "272484218", name: "Zeal Credit Union" },
    { aba: "272484247", name: "North Central Area Credit Union" },
    { aba: "272484289", name: "Christian Financial Credit Union" },
    { aba: "272484373", name: "Our Credit Union" },
    { aba: "272484425", name: "Financial Plus Credit Union" },
    { aba: "272484441", name: "Jolt Credit Union" },
    { aba: "272484470", name: "Team One Credit Union" },
    { aba: "272484535", name: "First Area Credit Union" },
    { aba: "272484551", name: "Lake Huron Credit Union" },
    { aba: "272484603", name: "Saginaw County Employees Cu" },
    { aba: "272484629", name: "Copoco Community Credit Union" },
    { aba: "272484645", name: "Frankenmuth Credit Union" },
    { aba: "272484658", name: "Health Advantage Federal Cu" },
    { aba: "272484674", name: "Family First Credit Union" },
    { aba: "272484713", name: "Wildfire Credit Union" },
    { aba: "272484797", name: "Elga Credit Union" },
    { aba: "272484807", name: "Wanigas Credit Union" },
    { aba: "272484823", name: "Advia Credit Union" },
    { aba: "272484836", name: "Journey Federal Credit Union" },
    { aba: "272484852", name: "Honor Credit Union" },
    { aba: "272484894", name: "United Federal Credit Union" },
    { aba: "272484917", name: "Freestar Financial Credit Union" },
    { aba: "272484988", name: "People Driven Credit Union" },
    { aba: "272485039", name: "Credit Union Advantage" },
    { aba: "272485107", name: "Advantage One Credit Union" },
    { aba: "272485136", name: "Rogue River Community Credit Union" },
    { aba: "272485194", name: "The Local Credit Union" },
    { aba: "272485259", name: "Alliance Catholic Credit Union" },
    { aba: "272485275", name: "Total Community Credit Union" },
    { aba: "272485291", name: "Tlc Community Credit Union" },
    { aba: "272485314", name: "Kellogg Community Credit Union." },
    { aba: "272485372", name: "Traverse Catholic Fcu" },
    { aba: "272485385", name: "4front Credit Union" },
    { aba: "272485408", name: "Northwest Consumers Federal Cu" },
    { aba: "272485424", name: "T.b.a. Credit Union" },
    { aba: "272485482", name: "Shore To Shore Community Fcu" },
    { aba: "272485547", name: "T & I Credit Union" },
    { aba: "272485602", name: "Birmingham Bloomfield Credit Union" },
    { aba: "272485657", name: "Walled Lake School Emp Federal Cu" },
    { aba: "272485673", name: "Abd Federal Credit Union" },
    { aba: "272485725", name: "Blue Ox Credit Union" },
    { aba: "272485767", name: "Community Choice Cu" },
    { aba: "272485835", name: "Tandem Federal Credit Union" },
    { aba: "272485851", name: "Live Life Federal Credit Union" },
    { aba: "272485877", name: "Warren Municipal Federal Cu" },
    { aba: "272485932", name: "Wayne-westland Federal Cu" },
    { aba: "272485945", name: "Northland Area Federal Credit Union" },
    { aba: "272485990", name: "Harborlight Credit Union" },
    { aba: "272486025", name: "Northland Area Federal Credit Union" },
    { aba: "272486041", name: "Advia Credit Union" },
    { aba: "272486096", name: "Community Choice Credit Union" },
    { aba: "272486164", name: "Gr Consumers Credit Union" },
    { aba: "272486177", name: "Kenowa Municipal Fed Credit Union" },
    { aba: "272486193", name: "Bloom Credit Union" },
    { aba: "272486203", name: "Rivertown Community Federal Cu" },
    { aba: "272486232", name: "The University Of Michigan Cu" },
    { aba: "272486245", name: "Ypsilanti Area Federal Cu" },
    { aba: "272486258", name: "People Driven Credit Union" },
    { aba: "272486407", name: "P.a.c. Federal Credit Union" },
    { aba: "272486449", name: "River Valley Cu" },
    { aba: "272486452", name: "State Employees Cr Un" },
    { aba: "272486627", name: "Forest Area Federal Cu" },
    { aba: "272486724", name: "Community First Fcu" },
    { aba: "272486740", name: "Usa Federal Credit Union" },
    { aba: "273070278", name: "Metabank, National Association" },
    { aba: "273073806", name: "Financial Plus Credit Union" },
    { aba: "273073848", name: "Premier Credit Union" },
    { aba: "273073851", name: "Collins Community Credit Union" },
    { aba: "273073916", name: "Financial Plus Credit Union" },
    { aba: "273073932", name: "Community Choice Credit Union" },
    { aba: "273073945", name: "Des Moines Metro Cu" },
    { aba: "273073958", name: "Serve Credit Union" },
    { aba: "273073974", name: "First Class Community Credit Union" },
    { aba: "273074012", name: "Community Choice Credit Union" },
    { aba: "273074041", name: "Collins Community Credit Union" },
    { aba: "273074054", name: "Affinity Credit Union" },
    { aba: "273074067", name: "First Class Credit Union" },
    { aba: "273074135", name: "Capitol View Credit Union" },
    { aba: "273074148", name: "Financial Plus Credit Union" },
    { aba: "273074229", name: "Journey Credit Union" },
    { aba: "273074261", name: "Financial Plus Credit Union" },
    { aba: "273074339", name: "Polk County Credit Union" },
    { aba: "273074342", name: "Serve Credit Union" },
    { aba: "273074407", name: "Community Choice Credit Union" },
    { aba: "273074452", name: "Serve Credit Union" },
    { aba: "273074494", name: "Vibrant Credit Union" },
    { aba: "273074504", name: "Affinity Credit Union" },
    { aba: "273970116", name: "Metabank, National Association" },
    { aba: "273970174", name: "Pcsb Bank" },
    { aba: "273970365", name: "Great Western Bank" },
    { aba: "273970446", name: "Dubuque Bank And Trust Co" },
    { aba: "273970514", name: "Us Bank Na" },
    { aba: "273970585", name: "Great Southern Bank" },
    { aba: "273970640", name: "Great Southern Bank" },
    { aba: "273970682", name: "Northwest Bank" },
    { aba: "273970776", name: "Wcf Financial Bank" },
    { aba: "273970941", name: "Cornerstone Bank" },
    { aba: "273972583", name: "Greater Iowa Credit Union" },
    { aba: "273972596", name: "River Valley Credit Union" },
    { aba: "273972619", name: "Marine Credit Union" },
    { aba: "273972648", name: "Nishna Valley Credit Union" },
    { aba: "273972761", name: "Casebine Community Cu" },
    { aba: "273972813", name: "5 Star Community Credit Union" },
    { aba: "273972839", name: "Cedar Falls Community Cu" },
    { aba: "273972842", name: "Unite Credit Union" },
    { aba: "273972871", name: "First Federal Credit Union" },
    { aba: "273972897", name: "Linn Area Credit Union" },
    { aba: "273972936", name: "Dupaco Community Credit Union" },
    { aba: "273972949", name: "Collins Community Credit Union" },
    { aba: "273973074", name: "University Of Ia C U" },
    { aba: "273973100", name: "Metco Credit Union" },
    { aba: "273973168", name: "Quaker Oats C U" },
    { aba: "273973210", name: "Teamsters Credit Union" },
    { aba: "273973252", name: "Industrial Employees Cu" },
    { aba: "273973265", name: "Family Community Credit Union" },
    { aba: "273973281", name: "North Star Community Credit Union" },
    { aba: "273973320", name: "1st Gateway Credit Union" },
    { aba: "273973456", name: "Ascentra Credit Union" },
    { aba: "273973540", name: "Davenport Police Dept C U" },
    { aba: "273973553", name: "Marine Credit Union" },
    { aba: "273973663", name: "Family Credit Union" },
    { aba: "273974549", name: "Du Trac Community Credit Union" },
    { aba: "273974565", name: "Dubuque Postal Employees Cu" },
    { aba: "273974578", name: "Vibrant Credit Union" },
    { aba: "273974581", name: "Dupaco Community Credit Union" },
    { aba: "273974633", name: "Alliant Credit Union" },
    { aba: "273974730", name: "Employees Credit Union" },
    { aba: "273974882", name: "Fort Dodge Family Credit Union" },
    { aba: "273974905", name: "Peoples Credit Union" },
    { aba: "273974947", name: "Citizens Community Credit Union" },
    { aba: "273974963", name: "Peoples Credit Union" },
    { aba: "273975069", name: "Power Co-op Employees Credit Union" },
    { aba: "273975098", name: "Greenstate Credit Union" },
    { aba: "273975153", name: "The Hub - Co C U" },
    { aba: "273975182", name: "Lee Co Cu" },
    { aba: "273975221", name: "Secu Credit Union" },
    { aba: "273975263", name: "N W Iowa Credit Union" },
    { aba: "273975276", name: "Peoples Credit Union" },
    { aba: "273975292", name: "Northwest Ia Credit Union" },
    { aba: "273975331", name: "Members1st Community Cr Un" },
    { aba: "273975357", name: "Lennox Employees Credit Union" },
    { aba: "273975399", name: "Ace Credit Union" },
    { aba: "273975412", name: "Iowa Heartland Credit Union" },
    { aba: "273975483", name: "Mercy-city-leigh Family Cu" },
    { aba: "273975522", name: "Cent Credit Union" },
    { aba: "273975548", name: "Cent Credit Union" },
    { aba: "273975564", name: "North Iowa Community C U" },
    { aba: "273975593", name: "5 Star Community Credit Union" },
    { aba: "273975629", name: "Members Community Credit Union" },
    { aba: "273975674", name: "Advantage Credit Union" },
    { aba: "273975726", name: "Community 1st Credit Union" },
    { aba: "273975742", name: "Community 1st Credit Union" },
    { aba: "273975755", name: "River Community Credit Union" },
    { aba: "273975771", name: "Meridian Credit Union" },
    { aba: "273975823", name: "Sioux Valley Credit Union" },
    { aba: "273975917", name: "Midwest Comm. Cu" },
    { aba: "273975946", name: "The Municipal Credit Union" },
    { aba: "273975991", name: "Telco-triad Community Credit Union" },
    { aba: "273976181", name: "Midland Credit Union" },
    { aba: "273976262", name: "Allen Hospital Personnelcu" },
    { aba: "273976356", name: "Chicago Central And Commerce Cu" },
    { aba: "273976369", name: "Veridian Credit Union" },
    { aba: "273976408", name: "Public Employees Credit Union" },
    { aba: "273976437", name: "Dupaco Community Credit Union" },
    { aba: "273976482", name: "Dupaco Community Credit Union" },
    { aba: "273976592", name: "Peoples Credit Union" },
    { aba: "273982816", name: "Hometown Credit Union" },
    { aba: "274070439", name: "Bmo Harris Bank Na" },
    { aba: "274070484", name: "The Huntington National Bank" },
    { aba: "274073711", name: "Indiana Members Credit Union" },
    { aba: "274073766", name: "Energy Plus Credit Union" },
    { aba: "274073805", name: "Northpark Community Credit Union" },
    { aba: "274073818", name: "Financial Center Fcu" },
    { aba: "274073834", name: "Elements Financial Federal Credit Un" },
    { aba: "274073863", name: "Hoosier United Credit Union" },
    { aba: "274073876", name: "Financial Center First Credit Union" },
    { aba: "274073928", name: "Firefighters Credit Union" },
    { aba: "274073957", name: "Hale Fcu" },
    { aba: "274074037", name: "Forum Credit Union" },
    { aba: "274074040", name: "Indiana Members Credit Union" },
    { aba: "274074066", name: "Professional Police Officers Cu" },
    { aba: "274074105", name: "Indianapolis Post Office Cr Un" },
    { aba: "274074121", name: "Indianapolis Newspapers Fcu" },
    { aba: "274074134", name: "Harvester Fcu" },
    { aba: "274074163", name: "Kemba Indianapolis Cu" },
    { aba: "274074192", name: "Link Federal Credit Union" },
    { aba: "274074202", name: "Local 41 Sheet Metal Workers Fcu" },
    { aba: "274074257", name: "Clarian Federal Credit Union" },
    { aba: "274074325", name: "Family Horizons Credit Union" },
    { aba: "274074600", name: "Indiana Members Credit Union" },
    { aba: "274086339", name: "Alloya Corporate Fcu" },
    { aba: "274086533", name: "Indiana Members Credit Union" },
    { aba: "274970021", name: "First Federal Savings Bank Angola" },
    { aba: "274970050", name: "Horizon Bk, An Indiana Bank" },
    { aba: "274970063", name: "First Financial Bank" },
    { aba: "274970076", name: "First Financial Bank" },
    { aba: "274970144", name: "First Financial Bank" },
    { aba: "274970173", name: "First Merchants Bk Of Central Ind Na" },
    { aba: "274970186", name: "First Financial Bank" },
    { aba: "274970199", name: "First Financial Bank" },
    { aba: "274970267", name: "Mutual Savings Bank" },
    { aba: "274970351", name: "First Federal Savings & Loan" },
    { aba: "274970380", name: "First Federal Savings Bank" },
    { aba: "274970568", name: "Fcn Bank, National Association" },
    { aba: "274970584", name: "Civista Bank" },
    { aba: "274970607", name: "Security Fed Svgs Bk" },
    { aba: "274970610", name: "Logansport Savings Bank Fsb" },
    { aba: "274970649", name: "Home Bank Sb" },
    { aba: "274970652", name: "Peoples Community Bank Sb Of Montice" },
    { aba: "274970665", name: "Bmo Harris Bank Na" },
    { aba: "274970681", name: "Northwest Bank" },
    { aba: "274970717", name: "First Merchants Bank, N.a" },
    { aba: "274970775", name: "First Bank Of Berne" },
    { aba: "274970791", name: "First Bank Richmond" },
    { aba: "274970801", name: "Bmo Harris Bank,n.a." },
    { aba: "274970814", name: "Three Rivers Federal Credit Union" },
    { aba: "274970827", name: "First Fed Savings Bank" },
    { aba: "274970830", name: "Bmo Harris Bank Na" },
    { aba: "274970843", name: "First Merchants Bank" },
    { aba: "274970872", name: "Our Community Bank" },
    { aba: "274970937", name: "Fifth Third Bank" },
    { aba: "274970995", name: "Crossroads Bank" },
    { aba: "274971101", name: "Union Savings And Loan Association" },
    { aba: "274971729", name: "Terre Haute Savings Bank" },
    { aba: "274971800", name: "Farm & Mech Fed S&l Assn" },
    { aba: "274972579", name: "Independent Federal Credit Union" },
    { aba: "274972582", name: "Lampco Fcu" },
    { aba: "274972605", name: "Madison County Fcu" },
    { aba: "274972689", name: "Beacon Credit Union" },
    { aba: "274972744", name: "Indiana University Credit Union" },
    { aba: "274972760", name: "Members Choice Fcu" },
    { aba: "274972838", name: "Weatherhead C C Fcu" },
    { aba: "274972854", name: "Bar-cons Fed Credit Union" },
    { aba: "274972883", name: "Centra Credit Union" },
    { aba: "274972919", name: "Business & Industrial Fcu" },
    { aba: "274972948", name: "Whitewater Regional Fcu" },
    { aba: "274972980", name: "Central Soya Fcu" },
    { aba: "274973057", name: "Public Service Credit Union" },
    { aba: "274973073", name: "East Allen Fcu" },
    { aba: "274973141", name: "Midwest America Fcu" },
    { aba: "274973167", name: "City Trust Fcu" },
    { aba: "274973183", name: "Profed Federal Credit Union" },
    { aba: "274973196", name: "Fire Police City County Fcu" },
    { aba: "274973206", name: "Partners 1st Federal Credit Union" },
    { aba: "274973219", name: "Power One Federal Credit Union" },
    { aba: "274973222", name: "Three Rivers F C U" },
    { aba: "274973316", name: "Partners 1st Federal Credit Union" },
    { aba: "274973358", name: "Public Service Credit Union" },
    { aba: "274973374", name: "Fort Financial Credit Union" },
    { aba: "274973390", name: "Philips Electronics Federal Cu" },
    { aba: "274973549", name: "Perfect Cir Cu" },
    { aba: "274973578", name: "Community Credit Union" },
    { aba: "274973581", name: "Partners 1st Federal Credit Union" },
    { aba: "274973594", name: "Huntington County Fcu" },
    { aba: "274973620", name: "Our Sunday Visitor Efcu" },
    { aba: "274974645", name: "Financial Center First Credit Union" },
    { aba: "274974658", name: "Indiana Heartland Federal C U" },
    { aba: "274974661", name: "Howard Co. Sch Efcu" },
    { aba: "274974700", name: "City Of Firsts Community Fcu" },
    { aba: "274974726", name: "Solidarity Federal Credit Union" },
    { aba: "274974755", name: "Haynes International Fcu" },
    { aba: "274974768", name: "Financial Builders Fcu" },
    { aba: "274974852", name: "Christian Family Credit Union, Inc." },
    { aba: "274974865", name: "Tippecanoe Federal Cu" },
    { aba: "274974878", name: "Industrial Federal Credit Union" },
    { aba: "274974917", name: "Dearborn Co Hospital Federal Cu" },
    { aba: "274974946", name: "Community Spirit Cu" },
    { aba: "274975042", name: "Diversified General Fcu" },
    { aba: "274975107", name: "Riverside Community Fcu" },
    { aba: "274975152", name: "Via Credit Union" },
    { aba: "274975165", name: "Afena Federal Credit Union" },
    { aba: "274975220", name: "Fortress Federal Credit Union" },
    { aba: "274975259", name: "Lakes Federal Credit Union" },
    { aba: "274975262", name: "First County Federal Credit Union" },
    { aba: "274975291", name: "Financial Center First Credit Union" },
    { aba: "274975301", name: "Del Met Federal Credit Union" },
    { aba: "274975356", name: "Munseetown Comm Fcu" },
    { aba: "274975385", name: "Muncie Federal Credit Union" },
    { aba: "274975408", name: "Muncie Post Office Credit Union" },
    { aba: "274975424", name: "Primetrust Financial Fcu" },
    { aba: "274975437", name: "Thrive Fcu" },
    { aba: "274975466", name: "Eastern Indiana Fcu" },
    { aba: "274975592", name: "Hometown Federal Credit Union" },
    { aba: "274975602", name: "Crossroads Financial Fcu" },
    { aba: "274975660", name: "Natco Credit Union" },
    { aba: "274975709", name: "Richmond City Employees Federal C.u." },
    { aba: "274975754", name: "Wufface Federal Credit Union" },
    { aba: "274975770", name: "Wayne County Federal Credit Union" },
    { aba: "274975848", name: "Crane Credit Union" },
    { aba: "274975880", name: "Advantageplus Of Indiana Fcu" },
    { aba: "274975929", name: "Hulman Field Technicians F C U" },
    { aba: "274975932", name: "Indiana State Univ Fcu" },
    { aba: "274975958", name: "Crane Credit Union" },
    { aba: "274976009", name: "T H P Federal Credit Union" },
    { aba: "274976025", name: "Vigo County Fcu" },
    { aba: "274976038", name: "Encompass Federal Credit Union" },
    { aba: "274976067", name: "Purdue Federal Credit Union" },
    { aba: "274976151", name: "Beacon Credit Union" },
    { aba: "274976193", name: "A H E Plant No 3 Fcu" },
    { aba: "274976203", name: "Financial Partners Fed Cr Un" },
    { aba: "274976216", name: "Indiana Members Credit Union" },
    { aba: "274986413", name: "Health Care Professionals Fcu" },
    { aba: "274986594", name: "Indiana Lakes Federal Credit Union" },
    { aba: "274986659", name: "Vulcraft Efcu" },
    { aba: "275071246", name: "Empower Credit Union" },
    { aba: "275071259", name: "The Equitable Bank S S B" },
    { aba: "275071288", name: "First Citizens Bank & Trust Co." },
    { aba: "275071314", name: "Bmo Harris Bank,n.a." },
    { aba: "275071327", name: "Pyramax Bank" },
    { aba: "275071330", name: "Associated Bank, Na" },
    { aba: "275071356", name: "North Shore Bank Fsb" },
    { aba: "275071385", name: "T C F Bank Wisconsin Fsb" },
    { aba: "275071398", name: "Bmo Harris Bank Na" },
    { aba: "275071408", name: "Pnc Bank, Na" },
    { aba: "275071767", name: "Bmo Harris Bank Na" },
    { aba: "275071770", name: "Waterstone Bank" },
    { aba: "275077279", name: "Trustone Financial Federal Cu" },
    { aba: "275077282", name: "Trustone Financial Federal Cu" },
    { aba: "275079578", name: "Air Tech Cu" },
    { aba: "275079581", name: "Heritage Credit Union- Ab Branch" },
    { aba: "275079662", name: "Brewery Credit Union" },
    { aba: "275079714", name: "Landmark Credit Union" },
    { aba: "275079879", name: "Marine Credit Union" },
    { aba: "275079882", name: "Great Lakes Credit Union" },
    { aba: "275079976", name: "Guardian Credit Union" },
    { aba: "275080253", name: "Summit Credit Union" },
    { aba: "275080282", name: "Prime Financial Credit Union" },
    { aba: "275080334", name: "Northwestern Mutual Credit Union" },
    { aba: "275080567", name: "Summit Credit Union" },
    { aba: "275080570", name: "Enterprise Credit Union" },
    { aba: "275081582", name: "Trustone Financial Federal Cu" },
    { aba: "275082439", name: "Focus Credit Union" },
    { aba: "275082468", name: "Prime Financial Credit Union" },
    { aba: "275082471", name: "Appletree Credit Union" },
    { aba: "275082646", name: "Summit Credit Union" },
    { aba: "275082866", name: "Corporate Central Cr Union" },
    { aba: "275970020", name: "Marathon Bank" },
    { aba: "275970130", name: "Fox Valley Savings Bank" },
    { aba: "275970813", name: "Tcf National Bank" },
    { aba: "275970826", name: "Bmo Harris Bank,n.a." },
    { aba: "275971029", name: "East Wisconsin Savings Bank S A" },
    { aba: "275971087", name: "Old National Bank" },
    { aba: "275971113", name: "Home Savings Bank" },
    { aba: "275971139", name: "Forward Bank" },
    { aba: "275971142", name: "Mayville Savings Bank" },
    { aba: "275971498", name: "Paper City Savings Assoc." },
    { aba: "275971524", name: "Tcf National Bk Wi" },
    { aba: "275971582", name: "Bmo Harris Bank Na" },
    { aba: "275971634", name: "Associated Bank Green Bay" },
    { aba: "275971692", name: "First Fed Sb Of Wisconsin" },
    { aba: "275971825", name: "Westbury Bank" },
    { aba: "275971838", name: "Keysavings Bank" },
    { aba: "275971854", name: "Blackhawk Bank" },
    { aba: "275971906", name: "Great Midwest Bank S S B" },
    { aba: "275976655", name: "Co Vantage Credit Union" },
    { aba: "275976833", name: "Community First Credit Union" },
    { aba: "275976891", name: "Community First Credit Union" },
    { aba: "275976943", name: "Athens Area Credit Union" },
    { aba: "275977049", name: "Advia Credit Union" },
    { aba: "275977052", name: "First Community Credit Union" },
    { aba: "275977094", name: "Teachers Credit Union" },
    { aba: "275977120", name: "Co Op Credit Union" },
    { aba: "275977146", name: "Citizensfirst Credit Union" },
    { aba: "275977159", name: "Brokaw Credit Union" },
    { aba: "275977256", name: "Fox Communities Credit Union" },
    { aba: "275977450", name: "Fond Du Lac Credit Union" },
    { aba: "275977489", name: "Marine Credit Union" },
    { aba: "275977515", name: "Compassionate Care Credit Union" },
    { aba: "275977560", name: "Fort Community Credit Union" },
    { aba: "275977641", name: "Capital Credit Union" },
    { aba: "275977748", name: "Capital Credit Union" },
    { aba: "275977777", name: "Capital Credit Union" },
    { aba: "275977803", name: "Fox Communites Credit Union" },
    { aba: "275977858", name: "Pcm Credit Union" },
    { aba: "275977890", name: "Schneider Community Cu" },
    { aba: "275977900", name: "Service Credit Union" },
    { aba: "275977926", name: "Wisconsin Medical Credit Union" },
    { aba: "275978048", name: "Blackhawk Credit Union" },
    { aba: "275978080", name: "Summit Credit Union" },
    { aba: "275978093", name: "First Community Credit Union" },
    { aba: "275978145", name: "Heartland Credit Union" },
    { aba: "275978161", name: "County-city Credit Union" },
    { aba: "275978213", name: "Unison Credit Union" },
    { aba: "275978226", name: "Trustone Financial Federal Cu" },
    { aba: "275978310", name: "Southern Lakes Credit Union" },
    { aba: "275978394", name: "Capital Credit Union" },
    { aba: "275978417", name: "Kohler Credit Union" },
    { aba: "275978721", name: "Madison Credit Union" },
    { aba: "275978734", name: "Summit Credit Union" },
    { aba: "275978750", name: "Dane County Credit Union" },
    { aba: "275978828", name: "Heartland Credit Union" },
    { aba: "275978886", name: "Heartland Credit Union" },
    { aba: "275978983", name: "Heritage Credit Un" },
    { aba: "275978996", name: "Post Office Cu" },
    { aba: "275979021", name: "St Marys & Affiliates Credit Union" },
    { aba: "275979034", name: "Summit Credit Union" },
    { aba: "275979050", name: "Members First Credit Union" },
    { aba: "275979063", name: "Heritage Credit Union" },
    { aba: "275979076", name: "University Of Wisconsin C U" },
    { aba: "275979144", name: "Wea Cu" },
    { aba: "275979173", name: "Unitedone Credit Union" },
    { aba: "275979212", name: "Holy Family Memorial Cr Un" },
    { aba: "275979267", name: "Shipbuilders Cu" },
    { aba: "275979306", name: "Bay Shore Credit Union" },
    { aba: "275979351", name: "Tri-county Credit Union" },
    { aba: "275979364", name: "Simplicity Credit Union" },
    { aba: "275979377", name: "Marshfield Medical Center Cr Un" },
    { aba: "275979393", name: "First Choice Cr Un" },
    { aba: "275979429", name: "Prospera Credit Union" },
    { aba: "275979474", name: "Community First Credit Union" },
    { aba: "275979490", name: "Evergreen Credit Union" },
    { aba: "275980735", name: "Valley Communities Credit Union" },
    { aba: "275980751", name: "Badger Globe Credit Union" },
    { aba: "275980777", name: "Cone Cu" },
    { aba: "275980793", name: "Co Vantage Credit Union" },
    { aba: "275980816", name: "Citizensfirst Credit Union" },
    { aba: "275980832", name: "Community First Credit Union" },
    { aba: "275980861", name: "Nekoosa Cu" },
    { aba: "275980887", name: "Premier Financial Credit Union" },
    { aba: "275980900", name: "Forward Financial Credit Union" },
    { aba: "275980926", name: "Oakdale Cu" },
    { aba: "275980968", name: "New Credit Union" },
    { aba: "275981064", name: "Health Care Credit Union" },
    { aba: "275981080", name: "Community First Credit Union" },
    { aba: "275981093", name: "Commuinity First Credit Union" },
    { aba: "275981116", name: "Oshkosh Postal Emp Cre Uni" },
    { aba: "275981132", name: "Healthcare Credit Union" },
    { aba: "275981145", name: "Oshkosh Community C U" },
    { aba: "275981158", name: "Prospera Credit Union" },
    { aba: "275981174", name: "Winnebago Community Credit Union" },
    { aba: "275981187", name: "Verve, A Credit Union" },
    { aba: "275981242", name: "Dupaco Community Credit Union" },
    { aba: "275981378", name: "Educators Credit Union" },
    { aba: "275981394", name: "Mcu Financial Center Cu" },
    { aba: "275981420", name: "Racine Muncipal Empl Cr Union" },
    { aba: "275981543", name: "Golden Rule Community Credit Union" },
    { aba: "275981792", name: "Sheboygan Area Credit Union" },
    { aba: "275981828", name: "Sheboygan Area Credit Union" },
    { aba: "275981873", name: "Meadowland Credit Union" },
    { aba: "275981909", name: "1st Community Credit Union" },
    { aba: "275981954", name: "Central City Credit Union" },
    { aba: "275981967", name: "Sentry Credit Union" },
    { aba: "275982005", name: "Central Wisconsin Credit Union" },
    { aba: "275982050", name: "Tomah Area Credit Union" },
    { aba: "275982102", name: "Shoreline Credit Union" },
    { aba: "275982115", name: "Verve, A Credit Union" },
    { aba: "275982144", name: "Avestar Credit Union" },
    { aba: "275982238", name: "1st Class Express Credit Union" },
    { aba: "275982283", name: "Cloverbelt Credit Union" },
    { aba: "275982296", name: "Connexus Credit Union" },
    { aba: "275982322", name: "Marathon County Empl Cr Un" },
    { aba: "275982335", name: "M.e. Empl Cu" },
    { aba: "275982351", name: "Public Service Cu" },
    { aba: "275982526", name: "Glacier Hills Credit Union" },
    { aba: "275982539", name: "Westby Co-op Credit Union" },
    { aba: "275982584", name: "Members' Advantage Credit Union" },
    { aba: "275982597", name: "Connexus Credit Union" },
    { aba: "275982801", name: "Community First Credit Union" },
    { aba: "275983017", name: "Badger Globe Credit Union" },
    { aba: "281070901", name: "Collinsville B&l Asn" },
    { aba: "281070914", name: "First National Bank" },
    { aba: "281070985", name: "First Mid-illinois Bank & Trust" },
    { aba: "281071081", name: "Guardian Savings Bank" },
    { aba: "281071227", name: "First Fedl Savings Bank Of Mascoutah" },
    { aba: "281073445", name: "Midland States Bank" },
    { aba: "281073458", name: "Pnc Bank, Na" },
    { aba: "281073555", name: "Busey Bank" },
    { aba: "281073568", name: "Pnc Bank, Na" },
    { aba: "281076167", name: "State Employees Credit Union" },
    { aba: "281076170", name: "Altonized Community Fed Credit Union" },
    { aba: "281076235", name: "1st Mid America Credit Union" },
    { aba: "281076277", name: "Catholic & Community Credit Union" },
    { aba: "281076675", name: "Catholic & Community Credit Union" },
    { aba: "281076688", name: "Fairmont Village Credit Union" },
    { aba: "281076730", name: "1st Mid America Credit Union" },
    { aba: "281076743", name: "Siue Credit Union" },
    { aba: "281076853", name: "Gcs Credit Union" },
    { aba: "281076866", name: "1st Midamerica Credit Union" },
    { aba: "281076882", name: "1st Midamerica Credit Union" },
    { aba: "281076895", name: "Processors Industrial Cu" },
    { aba: "281076905", name: "1st Midamerica Credit Union" },
    { aba: "281077522", name: "Scott Credit Union" },
    { aba: "281077603", name: "Midwest Members Credit Union" },
    { aba: "281080315", name: "West Community C U" },
    { aba: "281080360", name: "County Cu" },
    { aba: "281080739", name: "Alltru Federal Credit Union" },
    { aba: "281080768", name: "De Soto Mo Pac Credit Union" },
    { aba: "281080988", name: "Division #6 Highway Credit Union" },
    { aba: "281080991", name: "West Community Credit Union" },
    { aba: "281081000", name: "Century Credit Union" },
    { aba: "281081013", name: "First Missouri Credit Union" },
    { aba: "281081233", name: "Arsenal Credit Union" },
    { aba: "281081246", name: "Members 1st Credit Union" },
    { aba: "281081466", name: "St. Louis Community Cu" },
    { aba: "281081479", name: "Vantage Credit Union" },
    { aba: "281081495", name: "Electro Svgs Cu" },
    { aba: "281081505", name: "Alliance Credit Union" },
    { aba: "281081534", name: "F R B Fcu" },
    { aba: "281081686", name: "Allince Credit Union" },
    { aba: "281081738", name: "West Community Credit Union" },
    { aba: "281081877", name: "First Community Cr Union" },
    { aba: "281081961", name: "Procter & Gamble St. Louis Empl Cu" },
    { aba: "281082148", name: "Alliance Credit Union" },
    { aba: "281082203", name: "Alliance Credit Union" },
    { aba: "281082216", name: "Patriot Credit Union" },
    { aba: "281082258", name: "St Louis Community Credit Union" },
    { aba: "281082339", name: "Gateway Metro Credit Union" },
    { aba: "281082384", name: "Neighbors Credit Union" },
    { aba: "281082397", name: "St. Louis Policemens Cu" },
    { aba: "281082407", name: "Ubc Credit Union" },
    { aba: "281082423", name: "St. Louis Community Credit Union" },
    { aba: "281082436", name: "St Louis Firefighters & Comm Cu" },
    { aba: "281082504", name: "Health Care Family C U" },
    { aba: "281082915", name: "Together Credit Union" },
    { aba: "281082931", name: "St. Louis Community Credit Union" },
    { aba: "281082944", name: "Health Care Family Cu" },
    { aba: "281085585", name: "Millennium Corporate Credit Union" },
    { aba: "281085705", name: "Missouri Valley Fcu" },
    { aba: "281271014", name: "Washington Savings Bank" },
    { aba: "281271056", name: "Marion County Savings Bank" },
    { aba: "281271137", name: "Cnb Bank & Trust N.a." },
    { aba: "281271179", name: "Heritage State Bank" },
    { aba: "281271263", name: "Wabash Savings Bank" },
    { aba: "281271438", name: "First Robinson Savings Bk, N.a." },
    { aba: "281271454", name: "Marion County Savings Bank" },
    { aba: "281276242", name: "A.s.h. Employees Credit Union" },
    { aba: "281276378", name: "Carbondale Highway Credit Union" },
    { aba: "281276420", name: "Siu Credit Union" },
    { aba: "281277005", name: "Southern Illinois Schools Cu" },
    { aba: "281277063", name: "Ambraw Federal Cu" },
    { aba: "281277173", name: "Siu Credit Union" },
    { aba: "281277199", name: "Vibrant Credit Union" },
    { aba: "281277241", name: "Jeff-co Schools Fed C U" },
    { aba: "281277319", name: "Western Il School Employees Cr Un." },
    { aba: "281277351", name: "United Community Credit Union" },
    { aba: "281277364", name: "United Community Credit Union" },
    { aba: "281277377", name: "Hometown Credit Union" },
    { aba: "281277403", name: "Members First Credit Union" },
    { aba: "281277445", name: "Quincy Postal Employees Cu" },
    { aba: "281277555", name: "River To River Credit Union" },
    { aba: "281285631", name: "Siu Credit Union" },
    { aba: "281371613", name: "Farmers And Mechanics Fed Savings Bk" },
    { aba: "281371626", name: "Boonville Federal Savings Bank" },
    { aba: "281371972", name: "German American Bank" },
    { aba: "281372007", name: "First Federal Savings Bank" },
    { aba: "281377646", name: "Hoosier Hills Credit Union" },
    { aba: "281377691", name: "Psepefcu" },
    { aba: "281378108", name: "Heritage Federal Credit Union" },
    { aba: "281378179", name: "Western Indiana Credit Union" },
    { aba: "281572988", name: "F & M Bank And Trust Co" },
    { aba: "281573013", name: "F & M Bank And Trust Co" },
    { aba: "281573204", name: "Stockmens Bank" },
    { aba: "281573259", name: "Southern Bank" },
    { aba: "281573275", name: "Southern Bank" },
    { aba: "281573288", name: "Progressive Ozark Bank" },
    { aba: "281573686", name: "West Plains Saving Loan" },
    { aba: "281580381", name: "Academic Employees Credit Union" },
    { aba: "281580417", name: "Missouri Credit Union" },
    { aba: "281580420", name: "Columbia Credit Union" },
    { aba: "281580462", name: "Shelter Insurance Fcu" },
    { aba: "281580501", name: "Cape Regional Credit Union" },
    { aba: "281580572", name: "Our Lady Of Snows C.u." },
    { aba: "281580679", name: "Infuze Credit Union" },
    { aba: "281580695", name: "Community First Credit Union" },
    { aba: "281580705", name: "Northeast Regional Credit Union" },
    { aba: "281581047", name: "Horizon Credit Union" },
    { aba: "281581063", name: "United Credit Union" },
    { aba: "281581076", name: "Show-me Credit Union" },
    { aba: "281581144", name: "Ozark Federal Credit Union" },
    { aba: "281581160", name: "Riverways Federal Credit Union" },
    { aba: "281582693", name: "Division 10 Highway Empl C.u." },
    { aba: "281586398", name: "West Community Credit Union" },
    { aba: "281970881", name: "Chester National Bank" },
    { aba: "281971301", name: "Nashville Savings Bank" },
    { aba: "281971356", name: "Nokomis Savings Bank" },
    { aba: "281971424", name: "North County Savings Bank" },
    { aba: "281971505", name: "Tempo Bank" },
    { aba: "281972931", name: "Ozarks Fed Svgs And Loan Assn." },
    { aba: "281980619", name: "Leadco Cu" },
    { aba: "281980622", name: "Semo Community Credit Union" },
    { aba: "281985973", name: "Kaskaskia Valley Community Cu" },
    { aba: "282074566", name: "Alcoa Community Fcu" },
    { aba: "282074582", name: "Hurricane Creek Fcu" },
    { aba: "282075028", name: "Arkansas Fcu" },
    { aba: "282075086", name: "Cooperative Extension Service Fcu" },
    { aba: "282075138", name: "Arkansas Teachers Federal Cu" },
    { aba: "282075141", name: "Southern Gas Federal Credit Union" },
    { aba: "282075170", name: "Baptist Health Federal Credit Union" },
    { aba: "282075219", name: "Dillard's Federal Credit Union" },
    { aba: "282075235", name: "Orion Federal Credit Union" },
    { aba: "282075251", name: "Electric Cooperatives Fcu" },
    { aba: "282075264", name: "Engineers Federal Credit Union" },
    { aba: "282075303", name: "United Arkansas Federal Credit Union" },
    { aba: "282075390", name: "Truservice Community Federal Cu" },
    { aba: "282075413", name: "Little Rock Fire Department Fcu" },
    { aba: "282075471", name: "United Arkansas Fcu" },
    { aba: "282075523", name: "Telcoe Cu" },
    { aba: "282075552", name: "Va Hospital Federal Credit Union" },
    { aba: "282075662", name: "Arkansas Federal Credit Union" },
    { aba: "282075675", name: "Ft Roots Cu" },
    { aba: "282075730", name: "Up Arkansas Fcu" },
    { aba: "282075743", name: "U.p. Employees Federal Credit Union" },
    { aba: "282076001", name: "Arkansas Health Center Fcu" },
    { aba: "282970068", name: "Relyance Bank, National Association" },
    { aba: "282970110", name: "First Financial Bank" },
    { aba: "282970437", name: "Iberiabank" },
    { aba: "282974006", name: "Bancorpsouth" },
    { aba: "282974161", name: "United Bank" },
    { aba: "282974527", name: "Henderson State University Fcu" },
    { aba: "282974530", name: "Patterson Federal Credit Union" },
    { aba: "282974637", name: "River Valley Fcu" },
    { aba: "282974679", name: "Timberline Federal Credit Union" },
    { aba: "282974695", name: "Lion Federal Credit Union" },
    { aba: "282974750", name: "Uark Fcu" },
    { aba: "282974763", name: "Northwest Arkansas Fcu" },
    { aba: "282974831", name: "Arkansas Best Fcu" },
    { aba: "282974860", name: "Ft Smith Dixie Cup F.c.u." },
    { aba: "282974873", name: "Fort Smith Teachers Fcu" },
    { aba: "282974899", name: "Rheem Arkansas Federal Credit Union" },
    { aba: "282974925", name: "River Town Federal Credit Union" },
    { aba: "282974938", name: "Mercy Credit Union" },
    { aba: "282974970", name: "Garland County Educators Federal Cu" },
    { aba: "282974996", name: "Diamond Lakes Federal Credit Union" },
    { aba: "282975018", name: "Combined Federal Credit Union" },
    { aba: "282975034", name: "Diamond Lakes Federal Credit Union" },
    { aba: "282975788", name: "Fairfield Fcu" },
    { aba: "282975827", name: "Pba Federal C.u." },
    { aba: "282975843", name: "Pine Bluff Postal Credit Union" },
    { aba: "282975856", name: "Pine Bluff Cotton Belt Fcu" },
    { aba: "282975869", name: "Pine Federal Credit Union" },
    { aba: "282975908", name: "Priority Federal Credit Union" },
    { aba: "282975940", name: "White County Govt Fcu" },
    { aba: "282975953", name: "Natural State Fcu" },
    { aba: "282976020", name: "Subiaco Fcu" },
    { aba: "282976059", name: "Arkansas Superior Federal Cu" },
    { aba: "282985558", name: "Mil-way Federal Credit Union" },
    { aba: "283071788", name: "First Savings Bank" },
    { aba: "283071827", name: "Wesbanco Bank Inc" },
    { aba: "283071830", name: "First Financial Bank" },
    { aba: "283077669", name: "One Vision Federal Credit Union" },
    { aba: "283078684", name: "Autotruck Fcu" },
    { aba: "283078710", name: "Autotruck Fcu" },
    { aba: "283078778", name: "Brown-forman Employees Credit Union" },
    { aba: "283078846", name: "Chemco Federal Credit Un" },
    { aba: "283078888", name: "Class Act Federal Credit Union" },
    { aba: "283078914", name: "L&n Federal Credit Union" },
    { aba: "283079094", name: "Advanz Federal Credit Union" },
    { aba: "283079117", name: "L&n Federal Credit Union" },
    { aba: "283079120", name: "Kemba Louisville Credit Union" },
    { aba: "283079159", name: "Transcend Credit Union" },
    { aba: "283079175", name: "Kit Federal Credit Union" },
    { aba: "283079227", name: "L & N Employees Fcu" },
    { aba: "283079272", name: "Commonwealth Credit Union" },
    { aba: "283079298", name: "Beacon Credit Union" },
    { aba: "283079311", name: "Louisville Fed Cu" },
    { aba: "283079324", name: "Commonwealth Credit Union" },
    { aba: "283079353", name: "Louisville Gas & Electric Co. Cu" },
    { aba: "283079450", name: "Msd Federal Credit Union" },
    { aba: "283079476", name: "Park Community Credit Union, Inc" },
    { aba: "283079609", name: "Rural Cooperatives Cr Un" },
    { aba: "283079722", name: "1st United Labor Federal Cu" },
    { aba: "283085868", name: "Sheet Metal Workers Local 110 Fcu" },
    { aba: "283970850", name: "First Federal Svgs Bank Of Frankfort" },
    { aba: "283971600", name: "Bedford Federal Savings Bank" },
    { aba: "283971901", name: "Mid Southern Savings Bk,fsb" },
    { aba: "283971914", name: "Scottsburg B&l Asn" },
    { aba: "283972036", name: "Republic Bank" },
    { aba: "283972078", name: "Carrollton Fed. Bank" },
    { aba: "283972094", name: "Wesbanco Bank Wheeling" },
    { aba: "283972120", name: "First Federal Svgs Bank Of Kentucky" },
    { aba: "283972162", name: "First Financial Bank" },
    { aba: "283972230", name: "First Financial Bank, N.a." },
    { aba: "283972434", name: "Hancock Bank And Trust Co" },
    { aba: "283972560", name: "Wesbanco Bank, Inc" },
    { aba: "283977633", name: "Hoosier Hills Credit Union" },
    { aba: "283977688", name: "Crane Credit Union" },
    { aba: "283977905", name: "Dubois-pike Federal Credit Union" },
    { aba: "283977989", name: "Martin County Cooperative C.u." },
    { aba: "283977992", name: "Clifty Creek Employees Fcu" },
    { aba: "283978001", name: "Jefferson Community Federal Cu" },
    { aba: "283978137", name: "Jackson Co Coop Cu" },
    { aba: "283978357", name: "Service One Credit Union, Inc." },
    { aba: "283978360", name: "Lake Chem Fcu" },
    { aba: "283978425", name: "Abound Federal Credit Union" },
    { aba: "283978441", name: "Commonwealth Credit Union" },
    { aba: "283978467", name: "Kentucky Employees Credit Union" },
    { aba: "283978522", name: "Autotruck Financial Credit Union" },
    { aba: "283978564", name: "Kraftcor Federal Credit Union" },
    { aba: "283978593", name: "Gibbs Aluminum Federal Credit Union" },
    { aba: "283979822", name: "Hopkins Co. Teachers F.c.u." },
    { aba: "283979835", name: "Regional Medical Center Employee Fcu" },
    { aba: "283979877", name: "Your Hometown Federal Credit Union" },
    { aba: "283979929", name: "Daviess Co Teachers Fcu" },
    { aba: "283979945", name: "Green River Area Federal C.u." },
    { aba: "283979961", name: "Modern Empl Fcu" },
    { aba: "283980002", name: "Owensboro Federal Credit Union" },
    { aba: "283980015", name: "Audubon Federal Credit Union" },
    { aba: "283980031", name: "C-plant Fcu" },
    { aba: "283980044", name: "C-plant Fcu" },
    { aba: "283980099", name: "Signet Federal Credit Union" },
    { aba: "283980109", name: "Paducah Teachers Federal Credit Un." },
    { aba: "283980125", name: "Shawnee Tva Employees Fcu" },
    { aba: "283980138", name: "W B H Empl Fcu" },
    { aba: "283980222", name: "Whitesville Community Credit Union" },
    { aba: "283985599", name: "Msu Federal Credit Union" },
    { aba: "283985829", name: "Labor Management Federal Credit Un." },
    { aba: "283985913", name: "Doe Run Federal Credit Union" },
    { aba: "284071949", name: "Financial Federal Bank" },
    { aba: "284073785", name: "Banktennessee" },
    { aba: "284084101", name: "Binsy Federal Credit Union" },
    { aba: "284084114", name: "Healthnet Federal Credit Union" },
    { aba: "284084130", name: "Bulab Employees Federal Credit Union" },
    { aba: "284084208", name: "Memphis City Employees Credit Union" },
    { aba: "284084266", name: "Securtrust Federal Credit Union" },
    { aba: "284084350", name: "Fedex Employees Credit Assn Fcu" },
    { aba: "284084363", name: "F A A Federal Credit Union #9639" },
    { aba: "284084376", name: "Federal Building Federal Cu" },
    { aba: "284084460", name: "Hope Federal Credit Union" },
    { aba: "284084512", name: "Cn/ic Employees Credit Union" },
    { aba: "284084541", name: "Kellogg Mphs Emp Cu" },
    { aba: "284084554", name: "Kemba Delta Federal Credit Union" },
    { aba: "284084567", name: "Kennedy V.a. Empl Fcu" },
    { aba: "284084635", name: "L. G. & W. Federal Credit Union" },
    { aba: "284084813", name: "Kimberly Clark Credit Union" },
    { aba: "284084842", name: "Memphis Municipal Efcu" },
    { aba: "284084871", name: "Memphis City Employees Credit Union" },
    { aba: "284084907", name: "Orion Federal Credit Union" },
    { aba: "284084936", name: "The West Tennessee Credit Union" },
    { aba: "284084949", name: "Methodist Healthcare Fcu" },
    { aba: "284085003", name: "Memphis City Employees Credit Union" },
    { aba: "284085139", name: "Memphis City Employees Credit Union" },
    { aba: "284085168", name: "Southern Security Federal" },
    { aba: "284085197", name: "Shelby County Federal Credit Union" },
    { aba: "284085317", name: "Tva Allen Steam Plant Fcu" },
    { aba: "284085375", name: "First South Financial Credit Union" },
    { aba: "284086471", name: "Ups Employees Credit Union" },
    { aba: "284170109", name: "Riverbank" },
    { aba: "284170141", name: "Armor Bank" },
    { aba: "284170468", name: "Centennial Bank" },
    { aba: "284170510", name: "Iberiabank" },
    { aba: "284174134", name: "Unico Bank" },
    { aba: "284174613", name: "Success Federal Credit Union" },
    { aba: "284272625", name: "Renasant Bank" },
    { aba: "284272641", name: "Planters Bk & Tr Co" },
    { aba: "284272654", name: "Cadence Bank" },
    { aba: "284272670", name: "Trustmark National Bank" },
    { aba: "284272780", name: "Renasant Bank" },
    { aba: "284273912", name: "Southern Bancorp Bank" },
    { aba: "284273970", name: "First Horizon Bank" },
    { aba: "284274076", name: "First Bank And Trust Of Mississippi" },
    { aba: "284283067", name: "Tombigbee Fcu" },
    { aba: "284283106", name: "Red River Employees Fcu" },
    { aba: "284283164", name: "Statewide Federal Credit Union" },
    { aba: "284283203", name: "Cm Members Federal Credit Union" },
    { aba: "284283216", name: "Twin States Fed Cu" },
    { aba: "284283232", name: "Muw Employees Fcu" },
    { aba: "284283261", name: "Triangle Federal Credit Union" },
    { aba: "284283342", name: "Delta Credit Union" },
    { aba: "284283397", name: "Ampot Fcu" },
    { aba: "284283407", name: "Elevator Credit Union" },
    { aba: "284283423", name: "Mid Delta Credit Union" },
    { aba: "284283504", name: "Shelby/bolivar County Fcu" },
    { aba: "284283630", name: "New Horizons Credit Union" },
    { aba: "284286048", name: "Red River Employees Fcu" },
    { aba: "284286051", name: "Hope Federal Credit Union" },
    { aba: "284373760", name: "Community Bank" },
    { aba: "284373838", name: "Central Bank" },
    { aba: "284383789", name: "First South Financial Credit Union" },
    { aba: "284383886", name: "Leaders Credit Union" },
    { aba: "284383941", name: "P + G Jackson Empl Fcu" },
    { aba: "284383954", name: "Patriot Equity Credit Union" },
    { aba: "284383983", name: "Resource Federal Credit Union" },
    { aba: "284383996", name: "Employee Resources Credit Union" },
    { aba: "284384241", name: "Delta Refining Company Efcu" },
    { aba: "284385415", name: "Holley Credit Union" },
    { aba: "286371663", name: "United Fidelity Bank, Fsb" },
    { aba: "286371676", name: "First Federal Savings Bank" },
    { aba: "286377764", name: "Evansville Fcu" },
    { aba: "286377777", name: "Evansville Teachers Federal C.u." },
    { aba: "286377780", name: "Crane Credit Union" },
    { aba: "286377793", name: "Evansville Firefighters Fcu" },
    { aba: "286377874", name: "Sterling United Fed Cr Un" },
    { aba: "286377890", name: "Diamond Valley Federal Credit Union" },
    { aba: "286573089", name: "Legends Bank" },
    { aba: "286573322", name: "Great Southern Bank" },
    { aba: "286573335", name: "Guaranty Bank" },
    { aba: "286573351", name: "Systematic Savings Bank" },
    { aba: "286580807", name: "Conservation Employees C U" },
    { aba: "286580810", name: "District 5 Hwy C U" },
    { aba: "286580823", name: "Employment Security Cu" },
    { aba: "286580852", name: "Jc Federal Employee Credit Union" },
    { aba: "286580865", name: "River Region Credit Union" },
    { aba: "286580894", name: "Missouri Baptist Cu" },
    { aba: "286580917", name: "River Region Credit Union" },
    { aba: "286582708", name: "Multipli Credit Union" },
    { aba: "286582711", name: "Metro Credit Union" },
    { aba: "286582737", name: "Metro Credit Union" },
    { aba: "286582740", name: "Assemblies Of God Cu" },
    { aba: "286582779", name: "Community Financial Credit Union" },
    { aba: "286582782", name: "Cu Community Credit Union" },
    { aba: "286582805", name: "Blucurrent Credit Union" },
    { aba: "286582834", name: "Educational Community Credit Union" },
    { aba: "286582850", name: "Foundation Credit Union" },
    { aba: "286582876", name: "Telcomm Credit Union" },
    { aba: "286586254", name: "Mo Elec Coop Employee C.u." },
    { aba: "286586416", name: "Mercy Credit Union" },
    { aba: "291070001", name: "Tcf National Bank Mn" },
    { aba: "291073231", name: "Anoka Hennepin Credit Union" },
    { aba: "291073503", name: "Financial One Credit Union" },
    { aba: "291073833", name: "Eaton Employees Credit Union" },
    { aba: "291074065", name: "West Metro Schools Credit Union" },
    { aba: "291074308", name: "Wings Financial Cu" },
    { aba: "291074418", name: "Electric Machinery Empl Credit Union" },
    { aba: "291074502", name: "City & County Credit Union" },
    { aba: "291074612", name: "Latvian Credit Union" },
    { aba: "291074638", name: "Spire Credit Union" },
    { aba: "291074654", name: "Sharepoint Credit Union" },
    { aba: "291074670", name: "Midland Coop Credit Union" },
    { aba: "291074696", name: "Topline Federal Credit Union" },
    { aba: "291074719", name: "Building Trades Federal Cr Union" },
    { aba: "291074722", name: "Firefly Federal Credit Union" },
    { aba: "291074748", name: "Diversified Cr Un" },
    { aba: "291074751", name: "Teamsters Credit Union" },
    { aba: "291074793", name: "North Memorial Federal Credit Union" },
    { aba: "291074971", name: "Royal Credit Union" },
    { aba: "291074984", name: "Sharepoint Credit Union" },
    { aba: "291075022", name: "Central Minnesota Credit Union" },
    { aba: "291075048", name: "Star Choice Credit Union" },
    { aba: "291075080", name: "Trustone Financial Federal Cu" },
    { aba: "291075103", name: "Toro Employees Fed Cr Un" },
    { aba: "291075116", name: "Spire Credit Union" },
    { aba: "291075129", name: "Trustone Financial Fcu" },
    { aba: "291075459", name: "My Credit Union" },
    { aba: "291172530", name: "Gogebic County Fcu" },
    { aba: "291172543", name: "Settlers Federal Credit Union" },
    { aba: "291172556", name: "Northern Lights Community Fcu" },
    { aba: "291172569", name: "U.p. State Credit Union" },
    { aba: "291172598", name: "Embers Credit Union" },
    { aba: "291172624", name: "Northern United Federal Cu" },
    { aba: "291172637", name: "Peninsula Federal Credit Union" },
    { aba: "291172640", name: "Great Lakes First Fcu" },
    { aba: "291172682", name: "Michigan Tech Empl Fcu" },
    { aba: "291172721", name: "Trunorth Federal Credit Union" },
    { aba: "291172747", name: "Iron Mt-kingsford Community Fcu" },
    { aba: "291172763", name: "Baraga County Federal Credit Union" },
    { aba: "291172776", name: "Torch Lake Federal Cu" },
    { aba: "291172789", name: "Limestone Federal Cred Un" },
    { aba: "291172792", name: "Manistique Federal Cu" },
    { aba: "291172844", name: "Embers Credit Union" },
    { aba: "291172860", name: "Marquette Community Federal Cr Un" },
    { aba: "291172954", name: "Embers Credit Union" },
    { aba: "291172967", name: "Honor Credit Union" },
    { aba: "291172970", name: "Tahquamenon Area Credit Union" },
    { aba: "291172996", name: "U.p. State Credit Union" },
    { aba: "291173018", name: "Rock Community Federal Cu" },
    { aba: "291173047", name: "Chippewa County Credit Union" },
    { aba: "291173050", name: "Federal Employees Chippewa Co Cr Un" },
    { aba: "291173076", name: "Soo Coop Credit Union" },
    { aba: "291173089", name: "Integra First Federal" },
    { aba: "291173102", name: "Embers Credit Union" },
    { aba: "291173128", name: "Lake Superior Cr Un" },
    { aba: "291181013", name: "Detour Drummond Comm Cu" },
    { aba: "291270050", name: "Home Federal Savings Bank" },
    { aba: "291270270", name: "Jackson Federal Saving And Loan Assn" },
    { aba: "291270416", name: "Riverwood Bank" },
    { aba: "291270461", name: "Hometown Bank" },
    { aba: "291270571", name: "1st Security Bk-detroit Lakes Off" },
    { aba: "291270649", name: "Mid-central National Bank" },
    { aba: "291270665", name: "Citizens Community Federal Na" },
    { aba: "291270717", name: "Worthington Fed Savings Bank, Fsb" },
    { aba: "291271004", name: "American Federal Bank" },
    { aba: "291271240", name: "Riverwood Bank" },
    { aba: "291271305", name: "First Farmers And Merchants Natl Bk" },
    { aba: "291273316", name: "Ely Area Credit Union" },
    { aba: "291273442", name: "First Community Credit Union" },
    { aba: "291273510", name: "North Star Credit Union" },
    { aba: "291273549", name: "Dawson Co Op Credit Union" },
    { aba: "291273552", name: "Deer River Coop Cr Union" },
    { aba: "291273882", name: "Ely Area Credit Union" },
    { aba: "291273905", name: "Embarrass Vermillion Fed Cr Union" },
    { aba: "291273947", name: "Fergus Falls Community Fed Cu" },
    { aba: "291273950", name: "Otter Tail Credit Union" },
    { aba: "291274014", name: "Members Cooperative Credit Union" },
    { aba: "291274030", name: "Hibbing Cooperative Cu" },
    { aba: "291274056", name: "Northridge Community Credit Union" },
    { aba: "291274085", name: "Northridge Community Credit Union" },
    { aba: "291274108", name: "Trustar Federal Cr Un" },
    { aba: "291274111", name: "Central Hanna Employees Credit Union" },
    { aba: "291275194", name: "Co-op Credit Union" },
    { aba: "291275408", name: "Red Lake Co-op Fcu" },
    { aba: "291276481", name: "North Shore Federal Credit Union" },
    { aba: "291276591", name: "Two Harbors Fcu" },
    { aba: "291276601", name: "Virginia Coop Credit Union" },
    { aba: "291276614", name: "Wadena Fcu" },
    { aba: "291276630", name: "White Earth Reservation Fcu" },
    { aba: "291281560", name: "Fulda Area Credit Union" },
    { aba: "291370918", name: "Gate City Bank" },
    { aba: "291378130", name: "Capital Credit Union" },
    { aba: "291378198", name: "Vue Community Credit Union" },
    { aba: "291378266", name: "First Community Credit Union" },
    { aba: "291378295", name: "Dakota Plains Credit Union" },
    { aba: "291378321", name: "City & County Employees Cu" },
    { aba: "291378334", name: "Dakota Telco Fed Cr Union" },
    { aba: "291378376", name: "Postal Family Federal Credit Union" },
    { aba: "291378389", name: "Fargo Public Schools Fcu" },
    { aba: "291378392", name: "Fargo Va Federal Cu" },
    { aba: "291378402", name: "Ffe Federal Cr Un" },
    { aba: "291378460", name: "Freedom Community Credit Union" },
    { aba: "291378486", name: "United Savings Cu" },
    { aba: "291378512", name: "Flasher Community Credit Union" },
    { aba: "291378538", name: "Elm River Credit Union" },
    { aba: "291378554", name: "Northern Valley Federal Cu" },
    { aba: "291378583", name: "Riverfork Federal Cr Un" },
    { aba: "291378606", name: "Area Community Credit Union" },
    { aba: "291378635", name: "Med Park C U" },
    { aba: "291378648", name: "University Fed Cr Un" },
    { aba: "291378693", name: "First Community Credit Union" },
    { aba: "291378745", name: "Hometown Credit Union" },
    { aba: "291378758", name: "Lamoure Credit Union" },
    { aba: "291378774", name: "North Star Community Credit Union" },
    { aba: "291378790", name: "Railway Credit Union" },
    { aba: "291378813", name: "Gem Federal Credit Union" },
    { aba: "291378826", name: "North Star Community Credit Union" },
    { aba: "291378839", name: "Minot Area Schools Federal Cu" },
    { aba: "291378868", name: "North Star Community Credit Union" },
    { aba: "291378871", name: "Town & Country Credit Union" },
    { aba: "291378884", name: "Aspire Community Federal Cu" },
    { aba: "291378897", name: "Affinity First Federal Credit Union" },
    { aba: "291378936", name: "Community Credit Union" },
    { aba: "291378949", name: "Capital Credit Union" },
    { aba: "291379126", name: "Dakota West Credit Union" },
    { aba: "291379142", name: "Western Cooperative Cr Un" },
    { aba: "291381570", name: "Capital Credit Union" },
    { aba: "291471011", name: "First Dakota National Bank" },
    { aba: "291471024", name: "Metabank, National Association" },
    { aba: "291471066", name: "Great Western Bank - Madison" },
    { aba: "291471134", name: "Great Western Bank" },
    { aba: "291471419", name: "First Western Federal Savings Bank" },
    { aba: "291479178", name: "Area Federal Credit Union" },
    { aba: "291479220", name: "Highmark Federal Credit Union" },
    { aba: "291479233", name: "Norstar Federal Credit Union" },
    { aba: "291479291", name: "Sentinel Federal Credit Union" },
    { aba: "291479301", name: "Northern Hills Federal Credit Union" },
    { aba: "291479356", name: "Dakotaland Federal Cr Un" },
    { aba: "291479372", name: "Huron Cnw Fcu" },
    { aba: "291479385", name: "Huron Area Ed Fcu" },
    { aba: "291479408", name: "Hb Telco Fed Cr Un" },
    { aba: "291479424", name: "M-o Federal Credit Union" },
    { aba: "291479440", name: "Dakota Plains Federal Cu" },
    { aba: "291479453", name: "East River Federal Credit Union" },
    { aba: "291479518", name: "Palace City Fcu" },
    { aba: "291479550", name: "Ft Randall Fcu" },
    { aba: "291479563", name: "Oahe Federal Credit Union" },
    { aba: "291479592", name: "Black Hills Federal Credit Union" },
    { aba: "291479602", name: "Dacotah Fcu" },
    { aba: "291479615", name: "Minuteman Fcu" },
    { aba: "291479657", name: "Rapid City Medical Fcu" },
    { aba: "291479660", name: "Med5 Federal Credit Union" },
    { aba: "291479673", name: "Sentinel Federal Credit Union" },
    { aba: "291479686", name: "Highmark Federal Credit Union" },
    { aba: "291479699", name: "Rushmore Electric Federal Cr Un" },
    { aba: "291479738", name: "Dakota Star Federal Credit Union" },
    { aba: "291479770", name: "Sioux Falls Federal Credit Union" },
    { aba: "291479783", name: "First Century Federal Cr Un" },
    { aba: "291479806", name: "Sioux Empire Federal Credit Union" },
    { aba: "291479835", name: "Good Samaritan Fed C U" },
    { aba: "291479877", name: "Voyage Federal Credit Uinon" },
    { aba: "291479903", name: "Service First Federal Credit Union" },
    { aba: "291479929", name: "Sioux Empire Federal Cu" },
    { aba: "291479974", name: "Sioux Falls Federal Credit Union" },
    { aba: "291479987", name: "Voyage Federal Credit Union" },
    { aba: "291480015", name: "Coteau Valley Federal Credit Union" },
    { aba: "291480028", name: "Interfaith Federal Credit Union" },
    { aba: "291480044", name: "Vermillion Federal Credit Union" },
    { aba: "291480060", name: "Sioux Valley Coop Cr Un" },
    { aba: "291480073", name: "Avanti Federal Credit Union" },
    { aba: "291480141", name: "Explorers Federal Credit Union" },
    { aba: "291481441", name: "Consumers Federal Credit Union" },
    { aba: "291481496", name: "Healthcare Plus Fcu" },
    { aba: "291481548", name: "Sisseton-wahpeton Federal Credit Un" },
    { aba: "291571267", name: "Ladysmith Federal Savings & Loan" },
    { aba: "291571270", name: "Prevail Bank" },
    { aba: "291571283", name: "Tomahawk Community Bank, Ssb" },
    { aba: "291571348", name: "Tomahawk Community Bank" },
    { aba: "291580151", name: "Arcadia Credit Union" },
    { aba: "291580313", name: "Ripco Credit Union" },
    { aba: "291580504", name: "Iron County Community Credit Union" },
    { aba: "291580559", name: "Peoples Choice Credit Union" },
    { aba: "291580562", name: "Taylor Credit Union" },
    { aba: "291580614", name: "Park City Credit Union" },
    { aba: "291580698", name: "Northwoods Community Credit Union" },
    { aba: "291580708", name: "Heritage Credit Un" },
    { aba: "291580711", name: "Ripco Credit Union" },
    { aba: "291580724", name: "Lakewood Credit Union" },
    { aba: "291580782", name: "Co-op Credit Union" },
    { aba: "291871226", name: "Cumberland Federal Bank Fsb" },
    { aba: "291871336", name: "Superior Savings Bank" },
    { aba: "291871352", name: "Associated Bank N.a." },
    { aba: "291878575", name: "Governmental Employees Cr Un" },
    { aba: "291880288", name: "Westconsin Credit Union" },
    { aba: "291880330", name: "Citizens Community Federal" },
    { aba: "291880369", name: "Marine Credit Union" },
    { aba: "291880411", name: "Royal Credit Union" },
    { aba: "291880424", name: "Royal Credit Union" },
    { aba: "291880437", name: "Utilities Credit Union" },
    { aba: "291880495", name: "Hayward Community Cu" },
    { aba: "291880589", name: "Westconsin Credit Union" },
    { aba: "291880767", name: "Indianhead Credit Union" },
    { aba: "291880903", name: "Centricity Credit Union" },
    { aba: "291880916", name: "Superior Choice Credit Union" },
    { aba: "291881041", name: "Marine Credit Union" },
    { aba: "291881054", name: "Dairyland Power Cr Un" },
    { aba: "291881083", name: "Government Emply C U" },
    { aba: "291881096", name: "Co-op Credit Union" },
    { aba: "291881119", name: "Lacrosse Burlington Cu" },
    { aba: "291881122", name: "Firefighters Credit Union" },
    { aba: "291881151", name: "Marine Credit Union" },
    { aba: "291881164", name: "Verve, A Credit Union" },
    { aba: "291881177", name: "Gundersen Credit Union" },
    { aba: "291881203", name: "Mayo Employees Fcu" },
    { aba: "291881216", name: "Altra Federal Credit Union" },
    { aba: "291970033", name: "Midcountry Bank" },
    { aba: "291970253", name: "Brainerd Savings & Loan" },
    { aba: "291970282", name: "Lake City Federal Bank" },
    { aba: "291970541", name: "Citizens Community Federal Na" },
    { aba: "291971320", name: "1st United Bank" },
    { aba: "291971391", name: "Liberty Savings Bank Fsb" },
    { aba: "291971430", name: "Viking Bank, National Association" },
    { aba: "291971469", name: "Old National Bank" },
    { aba: "291973153", name: "Trades And Labor Federal Cu" },
    { aba: "291973166", name: "City & County Empl Federal Cr Un" },
    { aba: "291973205", name: "United Employees Cr Un" },
    { aba: "291973289", name: "Austin City Empl Cu" },
    { aba: "291973292", name: "Accentra Credit Union" },
    { aba: "291973302", name: "Mower County Employees Credit Union" },
    { aba: "291973357", name: "Brainerd B N Cu" },
    { aba: "291973360", name: "Mid Minnesota Fed Cr Union" },
    { aba: "291973386", name: "Minnco Credit Union" },
    { aba: "291973454", name: "Members Cooperative Credit Union" },
    { aba: "291973470", name: "Northwoods Credit Union" },
    { aba: "291973629", name: "Northern Communities Credit Uion" },
    { aba: "291973645", name: "Share Advantage Credit Union" },
    { aba: "291973674", name: "Duluth Police Department Emply Cu" },
    { aba: "291973687", name: "Harbor Pointe Credit Union" },
    { aba: "291973739", name: "Centricity Credit Union" },
    { aba: "291973755", name: "Minnesota Power Emp Cr Union" },
    { aba: "291974123", name: "Ae Goetze Employees Credit Union" },
    { aba: "291974178", name: "Minnesota Valley Federal Cu" },
    { aba: "291974194", name: "Northern Energy Fcu" },
    { aba: "291974204", name: "Central Minnesota Credit Union" },
    { aba: "291975245", name: "Members Cooperative Credit Union" },
    { aba: "291975258", name: "Spire Credit Union" },
    { aba: "291975326", name: "Federated E C U" },
    { aba: "291975355", name: "Home Town Federal Credit Union" },
    { aba: "291975384", name: "Proctor Federal Cu" },
    { aba: "291975423", name: "Red Wing Credit Union" },
    { aba: "291975465", name: "Think Mutual Bank" },
    { aba: "291975478", name: "Mayo Employees Fcu" },
    { aba: "291975481", name: "First Alliance Credit Union" },
    { aba: "291975614", name: "St Cloud Financial Credit Union" },
    { aba: "291975630", name: "Catholic United Financial Cu" },
    { aba: "291975643", name: "St Cloud Financial Credit Union" },
    { aba: "291975656", name: "St Cloud Financial Credit Union" },
    { aba: "291975672", name: "Great River Federal Credit Union" },
    { aba: "291976493", name: "Southpoint Financial Credit Union" },
    { aba: "291981255", name: "Minnesota Valley Fed Cu" },
    { aba: "292070806", name: "Opportunity Bank Of Montana" },
    { aba: "292077511", name: "Intrepid Credit Union" },
    { aba: "292077566", name: "Vocal Credit Union" },
    { aba: "292077618", name: "Rocky Mountain Credit Union" },
    { aba: "292081248", name: "Millennium Corporate Credit Union" },
    { aba: "292176661", name: "Southwest Mt Community Fed Cu" },
    { aba: "292176674", name: "Deer Lodge Cty School Efcu" },
    { aba: "292176690", name: "Grasslands Federal Credit Union" },
    { aba: "292176700", name: "Billings Federal Credit Union" },
    { aba: "292177071", name: "Grasslands Federal Credit Union" },
    { aba: "292177107", name: "Bitterroot Community Fed Credit Un" },
    { aba: "292177136", name: "Community 1st Fcu" },
    { aba: "292177165", name: "Fort Peck Comnty Fed C U" },
    { aba: "292177217", name: "Glendive Bn Federal Cr Un" },
    { aba: "292177233", name: "Mdu Employees Fcu" },
    { aba: "292177453", name: "Tricounty Federal Credit Union" },
    { aba: "292177709", name: "Mountain West Federal Credit Union" },
    { aba: "292177725", name: "Lincoln County Cu" },
    { aba: "292177770", name: "Miles City Fed C U" },
    { aba: "292177851", name: "Mm Employees Federal Credit Union" },
    { aba: "292177864", name: "Park Side Credit Union" },
    { aba: "292177877", name: "St Pats Empl Fcu" },
    { aba: "292177932", name: "Daniels Sheridan Fcu" },
    { aba: "292177945", name: "1st Liberty Federal Credit Union" },
    { aba: "292178012", name: "Whitefish Cu" },
    { aba: "292970744", name: "Glacier Bank-western Security Div" },
    { aba: "292970757", name: "Pioneer Federal S&l" },
    { aba: "292970825", name: "Glacier Bank" },
    { aba: "292970841", name: "Umpqua Bank" },
    { aba: "292970854", name: "Glacier Bank-western Security Div" },
    { aba: "292976751", name: "Montana Health Fcu" },
    { aba: "292976764", name: "Billings Federal Credit Union" },
    { aba: "292976845", name: "Rimrock Credit Union" },
    { aba: "292976861", name: "Valley Credit Union" },
    { aba: "292976874", name: "Altana Federal Credit Union" },
    { aba: "292976968", name: "Butte Community Fcu" },
    { aba: "292976971", name: "Mountain West Federal Cr Un" },
    { aba: "292976984", name: "Northwestern Energy Employees Fcu" },
    { aba: "292977006", name: "Rocky Mountain Credit Union" },
    { aba: "292977019", name: "Mile High Federal Cr Un" },
    { aba: "292977022", name: "Horizon Credit Union" },
    { aba: "292977116", name: "High Peaks Federal Credit Union" },
    { aba: "292977158", name: "Intrepid Credit Union" },
    { aba: "292977200", name: "Badlands Federal Credit Union" },
    { aba: "292977226", name: "Badlands Federal Credit Union" },
    { aba: "292977242", name: "Montana Federal Cu" },
    { aba: "292977271", name: "The Family First Federal Cr Un" },
    { aba: "292977297", name: "Russell Country Federal Credit Union" },
    { aba: "292977307", name: "Embark Federal Credit Union" },
    { aba: "292977336", name: "Montana Federal Cu" },
    { aba: "292977349", name: "1st Liberty Federal Cu" },
    { aba: "292977420", name: "Ravalli County Federal Credit Union" },
    { aba: "292977462", name: "Bear Paw Credit Union" },
    { aba: "292977640", name: "Park Side Federal Credit Union" },
    { aba: "292977653", name: "Seg Federal Credit Union" },
    { aba: "292977666", name: "Altana Federal Credit Union" },
    { aba: "292977679", name: "Fergus Federal Credit Union" },
    { aba: "292977747", name: "Sky Federal Credit Union" },
    { aba: "292977789", name: "Community 1st Federal Credit Union" },
    { aba: "292977831", name: "Horizon Credit Union" },
    { aba: "292977899", name: "Clearwater Fcu" },
    { aba: "292977954", name: "Richland Federal Cu" },
    { aba: "292978005", name: "Park Side Credit Union" },
    { aba: "292978034", name: "Wolf Point Federal Credit Union" },
    { aba: "296075522", name: "Wakota Federal Credit Union" },
    { aba: "296075535", name: "Heartland Credit Union" },
    { aba: "296075564", name: "First Pace Credit Union" },
    { aba: "296075726", name: "Riverview Credit Union" },
    { aba: "296075807", name: "Carpenters Credit Union" },
    { aba: "296075810", name: "City & County Credit Union" },
    { aba: "296075823", name: "Topline Federal Credit Union" },
    { aba: "296075852", name: "Spire Credit Union" },
    { aba: "296075878", name: "Farm Credit Employees Fcu" },
    { aba: "296075933", name: "Hiway Credit Union" },
    { aba: "296075991", name: "Hbi Employees Credit Union" },
    { aba: "296076013", name: "St Paul Federal Credit Union" },
    { aba: "296076042", name: "Metropolitan Services Credit Union" },
    { aba: "296076068", name: "Novation Credit Union" },
    { aba: "296076084", name: "United Educators Credit Union" },
    { aba: "296076123", name: "Nsp St Paul Credit Union" },
    { aba: "296076152", name: "Wings Financial Credit Union" },
    { aba: "296076165", name: "Pipefitter-steamfitter Cu" },
    { aba: "296076204", name: "Smw Financial Credit Union" },
    { aba: "296076233", name: "Minnesota Catholic Credit Union" },
    { aba: "296076262", name: "Ideal Credit Union" },
    { aba: "296076275", name: "Cities Credit Union" },
    { aba: "296076301", name: "Affinity Plus Federal Cu" },
    { aba: "296076330", name: "Wings Financial Credit Union" },
    { aba: "296076343", name: "Transit Operations Federal Cu" },
    { aba: "296076369", name: "Unity One Credit Union" },
    { aba: "296076385", name: "Associated Healthcare Cu" },
    { aba: "296076408", name: "St Paul Federal Credit Union" },
    { aba: "296081516", name: "Alloya Corporate Fcu" },
    { aba: "301070852", name: "Argentine Federal Savings" },
    { aba: "301071592", name: "First Federal Bank" },
    { aba: "301071631", name: "North American Savings Bank" },
    { aba: "301071709", name: "Clay County Savings Bank" },
    { aba: "301071712", name: "Central Bank Of The Midwest" },
    { aba: "301077839", name: "Mainstreet Credit Union" },
    { aba: "301078540", name: "K C Area Credit Union" },
    { aba: "301078553", name: "Credit Union Of Johnson County" },
    { aba: "301078579", name: "Christ The King Federal Credit Union" },
    { aba: "301078582", name: "Credit Union Of Johnson County" },
    { aba: "301078663", name: "Kck Fireman & Police Credit Union" },
    { aba: "301078702", name: "Kc Fairfax Federal Credit Union" },
    { aba: "301078731", name: "Kumc Credit Union" },
    { aba: "301078744", name: "Azura Credit Union" },
    { aba: "301078760", name: "Health Care Employees Credit Union" },
    { aba: "301078773", name: "Midwest Regional Credit Union" },
    { aba: "301078854", name: "Uaw Mo-kan Federal Credit Union" },
    { aba: "301078870", name: "Reliance Credit Union" },
    { aba: "301078896", name: "Mainstreet Cu" },
    { aba: "301079183", name: "Mainstreet Federal Credit Union" },
    { aba: "301079617", name: "S M Federal Credit Union" },
    { aba: "301080392", name: "R-g Federal Credit Union" },
    { aba: "301080428", name: "City Credit Union" },
    { aba: "301080431", name: "Independence Teachers Credit Union" },
    { aba: "301080583", name: "B & V Credit Union" },
    { aba: "301080622", name: "United Consumers Credit Union" },
    { aba: "301080664", name: "Burns & Mcdonnell C.u." },
    { aba: "301080693", name: "Csd Credit Union" },
    { aba: "301080703", name: "Central Communications Credit Union" },
    { aba: "301080774", name: "Csd Credit Union" },
    { aba: "301080790", name: "Summit Ridge Credit Union" },
    { aba: "301080813", name: "Edison Credit Union" },
    { aba: "301080855", name: "Mazuma Credit Union" },
    { aba: "301080923", name: "R-g Federal Credit Union" },
    { aba: "301080936", name: "Holy Rosary Credit Union" },
    { aba: "301081016", name: "Kansas City Credit Union" },
    { aba: "301081045", name: "Kc Area Credit Union" },
    { aba: "301081061", name: "Greater Kc Public Safety Cu" },
    { aba: "301081087", name: "Kc Term Empl/guad Cntr Fed Cu" },
    { aba: "301081113", name: "United Consumers Credit Union" },
    { aba: "301081155", name: "Missouri Central Credit Union" },
    { aba: "301081197", name: "Mazuma Credit Union" },
    { aba: "301081281", name: "Community America Credit Union" },
    { aba: "301081294", name: "Community America Credit Union" },
    { aba: "301081333", name: "Catholic Family Credit Union" },
    { aba: "301081362", name: "Community America Credit Union" },
    { aba: "301081414", name: "United Consumers Credit Union" },
    { aba: "301081485", name: "United Consumers Cu" },
    { aba: "301081508", name: "Community America Credit Union" },
    { aba: "301081618", name: "Community America Credit Union" },
    { aba: "301081650", name: "Burlington Northtown Community Cu" },
    { aba: "301081676", name: "Northland Teachers Community Cu" },
    { aba: "301081773", name: "Holy Rosary Cu" },
    { aba: "301081786", name: "Raytown-lee's Summit Community Cu" },
    { aba: "301088556", name: "Bayer Credit Union" },
    { aba: "301089047", name: "United Labor Credit Union" },
    { aba: "301170587", name: "Home Savings Bank" },
    { aba: "301170668", name: "Golden Belt Bank, Fsa" },
    { aba: "301170707", name: "The Liberty Savings Association, Fsa" },
    { aba: "301170817", name: "Equity Bank" },
    { aba: "301170943", name: "Citizens Federal Savings Bank" },
    { aba: "301170956", name: "Mutual Savings Association" },
    { aba: "301170985", name: "Lyons Federal Bank" },
    { aba: "301171007", name: "Landmark National Bank" },
    { aba: "301171081", name: "Bank Of The West" },
    { aba: "301171120", name: "Landmark National Bank" },
    { aba: "301171230", name: "Simmons First Natl Bk" },
    { aba: "301171285", name: "Capitol Federal Savings Bank" },
    { aba: "301171337", name: "Lyons Federal Bank" },
    { aba: "301171353", name: "Fidelity Bank" },
    { aba: "301177713", name: "Ark Valley Credit Union" },
    { aba: "301177755", name: "Frontier Community Credit Union" },
    { aba: "301177784", name: "White Eagle Credit Union" },
    { aba: "301177823", name: "Farmway Credit Union" },
    { aba: "301177865", name: "C & R Credit Union" },
    { aba: "301177904", name: "Co-operative Credit Union" },
    { aba: "301177920", name: "Peoples Choice Credit Union" },
    { aba: "301178013", name: "Credit Union Of Dodge City" },
    { aba: "301178026", name: "Credit Union Of Dodge City" },
    { aba: "301178055", name: "Bluestem Community Credit Union" },
    { aba: "301178097", name: "Credit Union Of Emporia" },
    { aba: "301178107", name: "Emporia State Federal Credit Union" },
    { aba: "301178123", name: "Enterprise Credit Union" },
    { aba: "301178165", name: "Frontier Community Credit Union" },
    { aba: "301178178", name: "Garden City Teachers Fed Credit Un" },
    { aba: "301178181", name: "Golden Plains Credit Union" },
    { aba: "301178204", name: "Crossroads Credit Union" },
    { aba: "301178259", name: "Farmers Credit Union" },
    { aba: "301178288", name: "Great Plains Federal Credit Union" },
    { aba: "301178301", name: "Bell Credit Union" },
    { aba: "301178330", name: "Dillon Credit Union" },
    { aba: "301178369", name: "Hutchinson Postal Community Cu" },
    { aba: "301178372", name: "Heartland Credit Union" },
    { aba: "301178398", name: "Hutchinson Government Employees C U" },
    { aba: "301178424", name: "Morton Credit Union" },
    { aba: "301178932", name: "Wakarusa Valley Employees Cu" },
    { aba: "301179025", name: "Credit Union Of Johnson County" },
    { aba: "301179038", name: "U S P L K Empl Fcu" },
    { aba: "301179041", name: "Community America Credit Union" },
    { aba: "301179106", name: "Kansas State University Fcu" },
    { aba: "301179122", name: "Sunflower Up Federal Credit Union" },
    { aba: "301179135", name: "Mcpherson Co-op Credit Union" },
    { aba: "301179216", name: "Mid Kansas Credit Union" },
    { aba: "301179258", name: "United Credit Union" },
    { aba: "301179261", name: "White Eagle Credit Union" },
    { aba: "301179274", name: "United Northwest Fed Credit Union" },
    { aba: "301179410", name: "Kansas Teachers Community Cu" },
    { aba: "301179737", name: "Azura Credit Union" },
    { aba: "301179753", name: "Kansas Air Guard Credit Union" },
    { aba: "301179766", name: "Kansas Blue Cross Blue Shield Cu" },
    { aba: "301179805", name: "Azura Credit Union" },
    { aba: "301179834", name: "Envista Credit Union" },
    { aba: "301179850", name: "North East Kansas Credit Union" },
    { aba: "301179863", name: "Topeka Firemen's Credit Union" },
    { aba: "301179876", name: "Topeka Police Credit Union" },
    { aba: "301179889", name: "Topeka Post Office Credit Union" },
    { aba: "301179892", name: "Azura Credit Union" },
    { aba: "301179902", name: "Envista Credit Union" },
    { aba: "301179960", name: "Panhandle Federal Credit Union" },
    { aba: "301179986", name: "Golden Plains Credit Union" },
    { aba: "301179999", name: "Campus Credit Union" },
    { aba: "301180027", name: "Skyward Credit Union" },
    { aba: "301180056", name: "Credit Union Of America" },
    { aba: "301180098", name: "Equishare Credit Union" },
    { aba: "301180111", name: "Millennium Corporate Credit Union" },
    { aba: "301180124", name: "Mid American Credit Union" },
    { aba: "301180140", name: "Freedom 1st Federal Credit Union" },
    { aba: "301180153", name: "Credit Union Of America" },
    { aba: "301180182", name: "Catholic Family Fed Cr Un" },
    { aba: "301180195", name: "Wheat State Credit Union" },
    { aba: "301180221", name: "Sunflower Federal Cu" },
    { aba: "301180234", name: "Tecu Credit Union" },
    { aba: "301180292", name: "Meritrust Federal Credit Union" },
    { aba: "301180328", name: "Wichita Federal Credit Union" },
    { aba: "301180344", name: "Panhandle Federal Credit Union" },
    { aba: "301189170", name: "Truity Federal Credit Union" },
    { aba: "301271460", name: "First Missouri Bank" },
    { aba: "301271512", name: "Investors Community Bank" },
    { aba: "301271693", name: "North Amer Svgs Bk Fsb" },
    { aba: "301271758", name: "Home S&l Assn Of Carroll County, Fa" },
    { aba: "301271787", name: "Farmers State Bank" },
    { aba: "301271790", name: "Bmo Harris Bank Na" },
    { aba: "301271800", name: "Quarry City Savings And Loan Assc" },
    { aba: "301280503", name: "Legacy Credit Union" },
    { aba: "301280532", name: "Joplin Metro Credit Union" },
    { aba: "301281641", name: "Northwest Mo Regional Cr Un" },
    { aba: "301281832", name: "District One Highway Credit Union" },
    { aba: "301281890", name: "Lovers Lane Credit Union" },
    { aba: "301281913", name: "Goetz Credit Union" },
    { aba: "301281968", name: "Postal & Community Credit Union" },
    { aba: "301281984", name: "St Joseph Teachers Cr Un" },
    { aba: "301282019", name: "Stationery Credit Union" },
    { aba: "301282035", name: "Postal & Community Credit Union" },
    { aba: "301282064", name: "Central Missouri Community Cu" },
    { aba: "301287030", name: "Bothwell Hospital Employees Cu" },
    { aba: "301288369", name: "Postal & Community Cu" },
    { aba: "301980447", name: "Independence Federal Credit Union" },
    { aba: "302070013", name: "Westerra Credit Union" },
    { aba: "302074145", name: "Blue Federal Credit Union" },
    { aba: "302075018", name: "Bellco Credit Union" },
    { aba: "302075115", name: "Burlington Colorado Southern C.u." },
    { aba: "302075128", name: "Credit Union Of Colorado" },
    { aba: "302075131", name: "Columbine Federal Credit Union" },
    { aba: "302075238", name: "Denver Fire Dept Federal Cu" },
    { aba: "302075267", name: "Colorado Credit Union" },
    { aba: "302075283", name: "Rocky Mountain Law Enforcement Fcu" },
    { aba: "302075306", name: "Partner Colorado Credit Union" },
    { aba: "302075319", name: "Westerra Credit Union" },
    { aba: "302075351", name: "Star Tech Fcu" },
    { aba: "302075364", name: "Eagle Legacy Credit Union" },
    { aba: "302075416", name: "Electrical Federal Credit Union" },
    { aba: "302075458", name: "Fitzsimons Federal Credit Union" },
    { aba: "302075555", name: "Metrum Community Credit Union" },
    { aba: "302075694", name: "Denver Community Credit Union" },
    { aba: "302075788", name: "Porter Federal Credit Union" },
    { aba: "302075814", name: "Denver Community Credit Union" },
    { aba: "302075830", name: "Canvas Credit Union" },
    { aba: "302075937", name: "Partner Colorado Credit Union" },
    { aba: "302075982", name: "Horizons North Credit Union" },
    { aba: "302076004", name: "Westerra Credit Union" },
    { aba: "302076017", name: "Sooper Credit Union" },
    { aba: "302076033", name: "Space Age Federal Credit Union" },
    { aba: "302076266", name: "Foothills Credit Union" },
    { aba: "302076295", name: "White Crown Fcu" },
    { aba: "302088092", name: "Red Rocks Credit Union" },
    { aba: "302170162", name: "Del Norte Bank" },
    { aba: "302170298", name: "Anb Bank" },
    { aba: "302170340", name: "Gunnison Savings & Loan Assoc." },
    { aba: "302170405", name: "Rio Grande Svgs & Loan Assn" },
    { aba: "302170463", name: "High Country Bank" },
    { aba: "302170476", name: "Century Savings & Loan Association" },
    { aba: "302173732", name: "Bank Of Colorado" },
    { aba: "302174317", name: "Valley Educators Credit Union" },
    { aba: "302174320", name: "Mountain River Credit Union" },
    { aba: "302174362", name: "Guadalude Parish C U" },
    { aba: "302174728", name: "San Luis Valley Federal Bank" },
    { aba: "302174935", name: "Moffat County Schools Fcu" },
    { aba: "302174977", name: "Delta County Federal Credit Union" },
    { aba: "302176315", name: "Credit Union Of Colorado A Fcu" },
    { aba: "302176467", name: "Fellowship Credit Union" },
    { aba: "302176580", name: "Coloramo Federal Credit Union" },
    { aba: "302176603", name: "Grand Junction Federal Credit Union" },
    { aba: "302176616", name: "Mt. Garfield Federal Credit Union" },
    { aba: "302176629", name: "Harmony Federal Credit Union" },
    { aba: "302176632", name: "Western Rockies Federal Credit Union" },
    { aba: "302176674", name: "Rio Grande Fcu" },
    { aba: "302176823", name: "Holyoke Community Federal C.u." },
    { aba: "302176849", name: "Co-ne Federal Credit Union" },
    { aba: "302176865", name: "Otero County Teachers Fcu" },
    { aba: "302176904", name: "Fellowship Credit Union" },
    { aba: "302177107", name: "San Juan Mountains Credit Union" },
    { aba: "302177110", name: "Nuvista Federal Credit Union" },
    { aba: "302177398", name: "Western Rockies Federal Credit Union" },
    { aba: "302177440", name: "Mountain River Credit Union" },
    { aba: "302177479", name: "Peoples Credit Union" },
    { aba: "302177576", name: "Yuma County Federal Credit Union" },
    { aba: "302188675", name: "Home Loan State Bank" },
    { aba: "302283994", name: "High Desert Community Credit Union" },
    { aba: "302284032", name: "Cuba Credit Union" },
    { aba: "302284058", name: "Animas Credit Union" },
    { aba: "302284090", name: "Four Corners Federal Cu" },
    { aba: "302284171", name: "Jemez Valley Cu" },
    { aba: "302284281", name: "Questa Credit Union" },
    { aba: "302284469", name: "Rincones Presbyterian Credit Union" },
    { aba: "302373011", name: "Buffalo Federal Bank" },
    { aba: "302373079", name: "Big Horn Federal Savings Bank" },
    { aba: "302373118", name: "First Federal Bank & Trust" },
    { aba: "302386477", name: "Pathfinder Federal Credit Union" },
    { aba: "302386529", name: "Reliant Federal Credit Union" },
    { aba: "302386558", name: "Pathfinder Federal Credit Union" },
    { aba: "302386574", name: "Greater Wyoming Federal Credit Union" },
    { aba: "302386587", name: "Wyo Central Federal Credit Union" },
    { aba: "302386723", name: "Valley Financial Credit Union" },
    { aba: "302386736", name: "Sunlight Federal Credit Union" },
    { aba: "302386749", name: "Campco Federal Credit Union" },
    { aba: "302386752", name: "Wychem Federal Credit Union" },
    { aba: "302386765", name: "Trona Valley Community Federal Cu" },
    { aba: "302386778", name: "Green River Basin Fcu" },
    { aba: "302386794", name: "Guernsey Community Fcu" },
    { aba: "302386817", name: "Atlantic City Federal Credit Union" },
    { aba: "302386930", name: "Sweetwater Federal Credit Union" },
    { aba: "302386943", name: "Sheridan Community Fed Credit Union" },
    { aba: "302386956", name: "Cit-co Fcu" },
    { aba: "302387023", name: "Meridian Trust Federal Credit Union" },
    { aba: "303072780", name: "Home Savings & Loan Assoc" },
    { aba: "303072793", name: "International Bank Of Commerce" },
    { aba: "303085007", name: "Oklahoma Federal Credit Union" },
    { aba: "303085078", name: "Friends Federal Credit Union" },
    { aba: "303085094", name: "O.u. Federal Credit Union" },
    { aba: "303085133", name: "Integris Federal Credit Union" },
    { aba: "303085188", name: "Dps Federal Credit Union" },
    { aba: "303085191", name: "Credit Union One Of Oklahoma" },
    { aba: "303085227", name: "True Sky Credit Union" },
    { aba: "303085230", name: "Allegiance Credit Union" },
    { aba: "303085337", name: "Municipal Empl. Cr.un. Of Okc" },
    { aba: "303085353", name: "Oklahoma Educators Credit Union" },
    { aba: "303085418", name: "Oklahoma's Credit Union" },
    { aba: "303085421", name: "The Focus Federal Credit Union" },
    { aba: "303085434", name: "Energize Credit Union" },
    { aba: "303085476", name: "Communications Federal Credit Union" },
    { aba: "303085528", name: "Oklahoma Federal Credit Union" },
    { aba: "303085531", name: "Oklahoma Federal Credit Union" },
    { aba: "303085573", name: "Ssmok Employees Federal Credit Union" },
    { aba: "303085609", name: "U.s. Employees O.c. Federal Cr Union" },
    { aba: "303085638", name: "Weokie Federal Credit Union" },
    { aba: "303085654", name: "Credit Union One Of Oklahoma" },
    { aba: "303085829", name: "Tinker Federal Credit Union" },
    { aba: "303087995", name: "Midfirst Bank" },
    { aba: "303172651", name: "Great Plains National Bank" },
    { aba: "303172664", name: "Fairview Savings And Loan" },
    { aba: "303172855", name: "American Heritage Bank" },
    { aba: "303184500", name: "Arbuckle Federal Credit Union" },
    { aba: "303184513", name: "Communication Federal Credit Union" },
    { aba: "303184542", name: "Red River Federal C U" },
    { aba: "303184568", name: "Cooperative Employees' Cu" },
    { aba: "303184571", name: "Communications Federal Cu" },
    { aba: "303184610", name: "Ok Federal Credit Union" },
    { aba: "303184652", name: "Truity Federal Credit Union" },
    { aba: "303184762", name: "Endurance Federal Credit Union" },
    { aba: "303184788", name: "Correctional Workers Fcu" },
    { aba: "303184791", name: "El Reno R I L Cu" },
    { aba: "303184856", name: "Fort Sill Federal Cu" },
    { aba: "303184872", name: "First Family Federal Cr Un" },
    { aba: "303184898", name: "Comanche County Fcu" },
    { aba: "303184924", name: "Southwest Oklahoma Federal Cu" },
    { aba: "303184979", name: "Mcalester Aap Federal Credit Union" },
    { aba: "303185059", name: "Muskogee Federal Credit Union" },
    { aba: "303185703", name: "Cherokee Strip Credit Union" },
    { aba: "303185758", name: "Red Crown Federal Credit Union" },
    { aba: "303185761", name: "Green Country Federal Credit Union" },
    { aba: "303185787", name: "Bison Federal Credit Union" },
    { aba: "303185790", name: "Communication Federal Credit Union" },
    { aba: "303185813", name: "University & Community Federal Cu" },
    { aba: "303187675", name: "Grand Savings Bank" },
    { aba: "303188111", name: "Bank Of The West" },
    { aba: "303985932", name: "Dowell Federal Credit Union" },
    { aba: "303985961", name: "Fire Fighters Credit Union" },
    { aba: "303985974", name: "Fraternal Order Of Police Cr. Union" },
    { aba: "303985987", name: "Western Sun Federal Credit Union" },
    { aba: "303986012", name: "Lifetime Federal Credit Union" },
    { aba: "303986038", name: "Pearl District Federal Credit Union" },
    { aba: "303986054", name: "Employees Federal Credit Union" },
    { aba: "303986096", name: "Oklahoma Central Credit Union" },
    { aba: "303986148", name: "Red Crown Federal Cr. Un." },
    { aba: "303986151", name: "Ok Members First Fed Credit Union" },
    { aba: "303986180", name: "First Tulsa Federal Credit Union" },
    { aba: "303986193", name: "Space Age Tulsa Federal Credit Union" },
    { aba: "303986203", name: "United Members Fed. Credit Union" },
    { aba: "303986216", name: "St. Francis Employees Fcu" },
    { aba: "303986229", name: "Encentus Federal Credit Union" },
    { aba: "303986258", name: "Energy One Federal Credit Union" },
    { aba: "303986261", name: "Tulsa Federal Employees Credit Union" },
    { aba: "303986274", name: "Employees Federal Credit Union" },
    { aba: "303986290", name: "First Oklahoma Federal Cu" },
    { aba: "303986313", name: "Ttcu Federal Credit Union" },
    { aba: "303986384", name: "Western Sun Federal Credit Union" },
    { aba: "303986397", name: "Oklahoma Central Credit Union" },
    { aba: "303986423", name: "Tinker Federal Credit Union" },
    { aba: "304077616", name: "First Class Community Credit Union" },
    { aba: "304077645", name: "North Western Employees Credit Union" },
    { aba: "304077658", name: "United Credit Union" },
    { aba: "304082876", name: "Centris Federal Credit Union" },
    { aba: "304082915", name: "Creighton Federal Cr Union" },
    { aba: "304082928", name: "First Nebraska Credit Union" },
    { aba: "304082960", name: "Electrical Workers #22 Fcu" },
    { aba: "304082999", name: "Four Points Federal Credit Union" },
    { aba: "304083008", name: "First Nebraska Credit Union" },
    { aba: "304083079", name: "Kellogg Midwest Federal Credit Union" },
    { aba: "304083134", name: "Woodmen Federal Credit Union" },
    { aba: "304083147", name: "Mutual First Federal Credit Union" },
    { aba: "304083231", name: "Omaha Douglas Federal Credit Union" },
    { aba: "304083244", name: "Omaha Firefighters Credit Un" },
    { aba: "304083257", name: "Omaha Police Federal Credit Union" },
    { aba: "304083260", name: "Oppd Employees Federal Credit Union" },
    { aba: "304083299", name: "U.p. Connection Federal Cu" },
    { aba: "304083309", name: "Omaha Federal Credit Union" },
    { aba: "304083312", name: "Our Family Social Cu" },
    { aba: "304083367", name: "Neighborhood Community Fed Cr Union" },
    { aba: "304083370", name: "Roberts Dairy Emp Fc" },
    { aba: "304083396", name: "Metro Cu Federal Credit Union" },
    { aba: "304083448", name: "Cobalt Credit Union" },
    { aba: "304083451", name: "Union Pacific Streamliner F C U" },
    { aba: "304083480", name: "Family Focus Federal Credit Union" },
    { aba: "304083493", name: "Heartland Area Federal Credit Union" },
    { aba: "304087237", name: "Alloya Corporate Federal Credit Un" },
    { aba: "304087567", name: "Bakers Federal Credit Union" },
    { aba: "304171839", name: "Custer Federal State Bank" },
    { aba: "304172210", name: "Sidney Federal Sav Loan" },
    { aba: "304182093", name: "Western Heritage Federal Cu" },
    { aba: "304182103", name: "Box Butte Public Empl Fcu" },
    { aba: "304182116", name: "Consumers Cooperative Federal Cr Un" },
    { aba: "304182187", name: "Chadron Federal Credit Union" },
    { aba: "304182365", name: "Hemingford Community Fcu" },
    { aba: "304182734", name: "K.r.d. Federal Credit Union" },
    { aba: "304183584", name: "Meridian Trust Fcu" },
    { aba: "304183597", name: "Sugar Valley Federal Credit Union" },
    { aba: "304187331", name: "Meadow Grove Fcu" },
    { aba: "304971932", name: "Equitable Bank" },
    { aba: "304971945", name: "Home Federal Savings & Loan Assn" },
    { aba: "304971974", name: "Home Federal Savings & Loan Assn." },
    { aba: "304971987", name: "Great Western Bank" },
    { aba: "304971990", name: "Lincoln Fed Sav Bank Of Nebraska" },
    { aba: "304972038", name: "Madison County Bank" },
    { aba: "304972232", name: "Tecumseh Federal Bank" },
    { aba: "304973985", name: "Cit Bank Na" },
    { aba: "304977677", name: "Town And Country Credit Union" },
    { aba: "304982138", name: "Membersown Credit Union" },
    { aba: "304982170", name: "Boys Town Federal Credit Union" },
    { aba: "304982196", name: "Columbus United Federal C.u." },
    { aba: "304982206", name: "Dale Employees Federal Credit Union" },
    { aba: "304982222", name: "Nebraska Energy Federal Credit Union" },
    { aba: "304982235", name: "Siouxland Federal Credit Union" },
    { aba: "304982316", name: "Central Nebraska Fedl Credit Union" },
    { aba: "304982358", name: "Hastings Federal Credit Union" },
    { aba: "304982400", name: "Trius Federal Credit Union" },
    { aba: "304982439", name: "First Lincoln Federal Credit Union" },
    { aba: "304982442", name: "Membersown Credit Union" },
    { aba: "304982468", name: "Liberty First Credit Union" },
    { aba: "304982497", name: "Spirit Of America Federal Cu" },
    { aba: "304982523", name: "Nebr. State Employee Credit Union" },
    { aba: "304982536", name: "Lincone Federal Credit Union" },
    { aba: "304982552", name: "Lincone Federal Credit Union" },
    { aba: "304982578", name: "Lincoln Sda Credit Union" },
    { aba: "304982581", name: "Lps Employees Federal Credit Union" },
    { aba: "304982594", name: "Lincoln Usda Federal Credit Union" },
    { aba: "304982633", name: "Nebr. State Employee Credit Union" },
    { aba: "304982688", name: "U. Of N. Federal Credit Union" },
    { aba: "304982772", name: "Northeast Nebraska Fcu" },
    { aba: "304982808", name: "North Platte U.p. Emp. Cu." },
    { aba: "304987191", name: "Archer Cooperative Credit Union" },
    { aba: "304988682", name: "Membersown Credit Union" },
    { aba: "304988734", name: "Eddyville Cooperative Credit Union" },
    { aba: "304989050", name: "Kearney Federal Credit Union" },
    { aba: "307070005", name: "Ent Credit Union" },
    { aba: "307070021", name: "Air Academy Federal Credit Union" },
    { aba: "307070034", name: "Blue Federal Credit Union" },
    { aba: "307070047", name: "Sandia Area Federal Credit Union" },
    { aba: "307070050", name: "Kirtland Federal Credit Union" },
    { aba: "307070115", name: "Us Bank Na" },
    { aba: "307070267", name: "Key Bank" },
    { aba: "307070270", name: "Golden Belt Bank Fsa" },
    { aba: "307072278", name: "Bank Of America N.a." },
    { aba: "307072427", name: "Washington Federal Bank, Na" },
    { aba: "307072443", name: "Century Bank" },
    { aba: "307074098", name: "Farmers Bank" },
    { aba: "307074124", name: "Independent Bank" },
    { aba: "307074276", name: "Community Choice Credit Union" },
    { aba: "307074399", name: "Sooper Credit Union" },
    { aba: "307074441", name: "Fidelis Catholic Fcu" },
    { aba: "307074454", name: "Aurora Federal Credit Union" },
    { aba: "307074467", name: "The District Federal Credit Union" },
    { aba: "307074519", name: "Blue Federal Credit Union" },
    { aba: "307074535", name: "Premier Members Credit Union" },
    { aba: "307074551", name: "Premier Members Federal Cu" },
    { aba: "307074580", name: "Elevations Credit Union" },
    { aba: "307074690", name: "Aventa Credit Union" },
    { aba: "307074768", name: "Harrison District 2 Fcu" },
    { aba: "307074810", name: "One Thirteen Credit Union" },
    { aba: "307074836", name: "Pikes Peak Credit Union" },
    { aba: "307075259", name: "Credit Union Of Denver" },
    { aba: "307076232", name: "Credit Union Of The Rockies" },
    { aba: "307076342", name: "Arapahoe Credit Union" },
    { aba: "307076397", name: "Fci Federal Credit Union" },
    { aba: "307076533", name: "On Tap Credit Union" },
    { aba: "307076562", name: "Space Age Federal Credit Union" },
    { aba: "307076575", name: "Blue Federal Credit Union" },
    { aba: "307076711", name: "Northern Colorado Credit Union" },
    { aba: "307076724", name: "Weld Community Credit Union" },
    { aba: "307076805", name: "Haxtun Community Fcu" },
    { aba: "307076889", name: "Westerra Credit Union" },
    { aba: "307077079", name: "Foothills Credit Union" },
    { aba: "307077202", name: "Power Credit Union" },
    { aba: "307077231", name: "Minnequa Works Credit Union" },
    { aba: "307077273", name: "Aventa Credit Union" },
    { aba: "307077309", name: "Pueblo Government Agencies Fcu" },
    { aba: "307077325", name: "Sunwest Educational Credit Union" },
    { aba: "307077367", name: "Premier Members Credit Union" },
    { aba: "307077464", name: "School Dist #3 Federal C.u." },
    { aba: "307077480", name: "Sterling Federal Credit Union" },
    { aba: "307077529", name: "Premier Members Federal Cu" },
    { aba: "307077545", name: "Westminster Federal Credit Union" },
    { aba: "307083652", name: "U.s. Eagle Fcu" },
    { aba: "307083665", name: "Nusenda Federal Credit Union" },
    { aba: "307083678", name: "Rio Grande Credit Union" },
    { aba: "307083694", name: "First Financial Credit Union" },
    { aba: "307083843", name: "Rio Grande Credit Union" },
    { aba: "307083872", name: "Southwest Federal Credit Union" },
    { aba: "307083911", name: "Sandia Lab Fcu" },
    { aba: "307084004", name: "Belen Railway Employees Credit Union" },
    { aba: "307084211", name: "Del Norte Credit Union" },
    { aba: "307084237", name: "Los Alamos Schools Credit Union" },
    { aba: "307084240", name: "Zia Credit Union" },
    { aba: "307084347", name: "Guadalupe Credit Un" },
    { aba: "307084415", name: "Northern New Mexico" },
    { aba: "307084431", name: "State Employees Credit Union" },
    { aba: "307086604", name: "Cheyenne-laramie County Emp Fcu" },
    { aba: "307086617", name: "First Education Fcu" },
    { aba: "307086620", name: "Nuvision Federal Credit Union" },
    { aba: "307086633", name: "Nuvision Federal Credit Union" },
    { aba: "307086662", name: "Nuvision Federal Credit Union" },
    { aba: "307086691", name: "Wy Hy Federal Credit Union" },
    { aba: "307086701", name: "Meridian Trust Federal Credit Union" },
    { aba: "307086714", name: "Western Vista Fcu" },
    { aba: "307086837", name: "A.c.p.e. Fcu" },
    { aba: "307086879", name: "Laramie Plains Community Fcu" },
    { aba: "307086882", name: "Uniwyo Federal Credit Union" },
    { aba: "307087001", name: "Equitable Savings & Loan Assoc." },
    { aba: "307087399", name: "Alloya Corporate Federal Credit Un" },
    { aba: "307087616", name: "Nusenda Federal Credit Union" },
    { aba: "307087661", name: "Security First Bank" },
    { aba: "307087713", name: "5 Star Bank" },
    { aba: "307088754", name: "Cobank,acb" },
    { aba: "307088770", name: "United Business Bank" },
    { aba: "311078857", name: "Advancial Federal Credit Union" },
    { aba: "311078873", name: "Everman Parkway Credit Union" },
    { aba: "311078886", name: "Baylor Health Care System Cu" },
    { aba: "311078938", name: "Lifetime Federal Credit Union" },
    { aba: "311079050", name: "City Credit Union" },
    { aba: "311079186", name: "Metro Medical Credit Union" },
    { aba: "311079270", name: "Neighborhood Credit Union" },
    { aba: "311079296", name: "Dallas U.p. Employees Credit Union" },
    { aba: "311079306", name: "Credit Union Of Texas" },
    { aba: "311079319", name: "Go Federal Credit Union" },
    { aba: "311079335", name: "City Credit Union" },
    { aba: "311079348", name: "Libertyone Credit Union" },
    { aba: "311079380", name: "America's Credit Union" },
    { aba: "311079474", name: "Intouch Credit Union" },
    { aba: "311079500", name: "Employees Credit Union" },
    { aba: "311079539", name: "City Credit Union" },
    { aba: "311079571", name: "Green Bank, N.a." },
    { aba: "311079694", name: "Texas Federal Credit Union" },
    { aba: "311079827", name: "Southwest Financial Federal Cu" },
    { aba: "311079843", name: "Local 20 Ibew Federal Credit Union" },
    { aba: "311079869", name: "Lone Star Credit Union" },
    { aba: "311079898", name: "Methodist Hosp Empl F C U" },
    { aba: "311079937", name: "Neiman Marcus Group E.f.c.u" },
    { aba: "311080049", name: "Pollock Employees Credit Union" },
    { aba: "311080052", name: "Texas Health Resources Credit Union" },
    { aba: "311080162", name: "Resource One Credit Union" },
    { aba: "311080285", name: "Faith Cooperative Fcu" },
    { aba: "311080311", name: "Prestige Community Credit Union" },
    { aba: "311080395", name: "Union Pacific & Transportation E Fcu" },
    { aba: "311080531", name: "Texas Telcom Credit Union" },
    { aba: "311080573", name: "Las Colinas Federal Credit Union" },
    { aba: "311090673", name: "Southwest Airlines Federal Credit Un" },
    { aba: "311090738", name: "The Local Federal Credit Union" },
    { aba: "311093120", name: "Bank Of America" },
    { aba: "311170166", name: "B1bank" },
    { aba: "311170250", name: "Century Next Bank" },
    { aba: "311170276", name: "Home Federal Bank" },
    { aba: "311175093", name: "Barksdale Federal Credit Union" },
    { aba: "311175103", name: "Kraftman Federal Credit Union" },
    { aba: "311175129", name: "Bossier Federal Credit Union" },
    { aba: "311175158", name: "District 58 Federal Credit Union" },
    { aba: "311175271", name: "Carter Fcu" },
    { aba: "311175307", name: "Centric Federal Credit Union" },
    { aba: "311175310", name: "Federal Emp Cr Union" },
    { aba: "311175352", name: "Monroe Telco F C U" },
    { aba: "311175462", name: "Piney Hills Federal Credit Union" },
    { aba: "311175514", name: "Aneca Fcu" },
    { aba: "311175527", name: "Bossier Federal Credit Union" },
    { aba: "311175569", name: "Aneca Federal Credit Union" },
    { aba: "311175608", name: "Caddo Par Teachers Fcu" },
    { aba: "311175750", name: "Post Office Employees Fed Cu" },
    { aba: "311175828", name: "Shreveport Police Fed Credit Union" },
    { aba: "311175844", name: "Prof Fire Fighters Cu" },
    { aba: "311175860", name: "Red River Employees Fcu" },
    { aba: "311175873", name: "Louisiana Catholic Federal Credit Un" },
    { aba: "311175899", name: "State Agencies Fcu" },
    { aba: "311175938", name: "T.e.s. Regional Healthcare Fed Cr Un" },
    { aba: "311175941", name: "Bossier Federal Credit Union" },
    { aba: "311175954", name: "Wesla Federal Credit Union" },
    { aba: "311175970", name: "Willis Knighton Fcu" },
    { aba: "311175983", name: "Carter Fcu" },
    { aba: "311176021", name: "Ouachita Valley Fcu" },
    { aba: "311192610", name: "Northwest Louisiana Federal Cu" },
    { aba: "311193059", name: "Homeland Federal Savings Bank" },
    { aba: "311371172", name: "Dalhart Federal S L Assn" },
    { aba: "311372252", name: "First Federal Bk Littlefield, Tx,ssb" },
    { aba: "311372744", name: "Interstate Bank,ssb" },
    { aba: "311373125", name: "First Bank & Trust" },
    { aba: "311376494", name: "Abilene Federal Credit Union" },
    { aba: "311376504", name: "First Priority Credit Union" },
    { aba: "311376533", name: "Abilene Teachers Fcu" },
    { aba: "311376591", name: "First Abilene Fcu" },
    { aba: "311376614", name: "Txdot Credit Union" },
    { aba: "311376643", name: "Alba - Golden Fcu" },
    { aba: "311376737", name: "City Federal Credit Union" },
    { aba: "311376740", name: "Amarillo Community Federal Cr Un" },
    { aba: "311376753", name: "Peoples Federal Credit Union" },
    { aba: "311376766", name: "Amarillo Postal Empl C U" },
    { aba: "311376782", name: "The Education Credit Union" },
    { aba: "311376818", name: "Amarillo Community Federal Cr Un" },
    { aba: "311376850", name: "Santa Fe Fcu" },
    { aba: "311376876", name: "Texas Plains Federal Credit Union" },
    { aba: "311376902", name: "Access Community Credit Union" },
    { aba: "311377901", name: "Big Spring Ed Emp Cu" },
    { aba: "311377927", name: "The Electric Utilities Credit Union" },
    { aba: "311377943", name: "Cosden Federal Credit Union" },
    { aba: "311378023", name: "Borger Federal Credit Union" },
    { aba: "311378052", name: "Pantex Federal Cr Un" },
    { aba: "311378065", name: "Brownfield Fed C U" },
    { aba: "311380846", name: "Texan Sky Federal Credit Union" },
    { aba: "311380859", name: "Communities Of Abilene Fcu" },
    { aba: "311382093", name: "Friona Texas Fcu" },
    { aba: "311382116", name: "Texoma Federal Credit Union" },
    { aba: "311382527", name: "1st Community Federal Cu" },
    { aba: "311382776", name: "Hereford Tex Fcu" },
    { aba: "311385524", name: "Caprock Federal Credit Union" },
    { aba: "311385676", name: "Lefors Federal Credit Union" },
    { aba: "311385689", name: "Hockley County School Employees Cu" },
    { aba: "311385948", name: "Westex Federal Credit Union" },
    { aba: "311385964", name: "Alliance Credit Union" },
    { aba: "311386031", name: "Texas Tech Fcu" },
    { aba: "311386044", name: "Lubbock Telco Fcu" },
    { aba: "311386073", name: "Texas Tech Fed C U" },
    { aba: "311386277", name: "Bivins Federal Credit Union" },
    { aba: "311386620", name: "Cochran County Schools Fcu" },
    { aba: "311387069", name: "Cabot & Noi Employee Credit Union" },
    { aba: "311387111", name: "Pamcel Fcu" },
    { aba: "311387137", name: "Pampa Municipal Credit Union" },
    { aba: "311387140", name: "Pampa Teachers Federal Credit Union" },
    { aba: "311387357", name: "Northeast Panhandle Teachers Fcu" },
    { aba: "311387409", name: "Hale Cnty Teachers Fcu" },
    { aba: "311387412", name: "Plains Federal Cr Union" },
    { aba: "311387865", name: "Concho Educators Fcu" },
    { aba: "311387933", name: "Concho Valley Credit Union" },
    { aba: "311387946", name: "San Angelo Fed Cu" },
    { aba: "311387991", name: "Trans Texas Southwest Credit Union" },
    { aba: "311389106", name: "Skel Tex Credit Union" },
    { aba: "311389119", name: "Caprock Santa Fe Cu" },
    { aba: "311389151", name: "H&h Federal Credit Union" },
    { aba: "311389203", name: "Sweetwater Regional Fcu" },
    { aba: "311390140", name: "Citizens Fcu" },
    { aba: "311390438", name: "Windthorst Federal Cr Un" },
    { aba: "311390467", name: "Yantis Federal Credit Union" },
    { aba: "311971705", name: "Graham Savings & Loan, Ssb" },
    { aba: "311971776", name: "Henderson Federal Savings Bank" },
    { aba: "311972238", name: "Colonial Savings F.a." },
    { aba: "311972526", name: "Mineola Community Bank Ssb" },
    { aba: "311972652", name: "Citibank Texas, National Association" },
    { aba: "311972704", name: "First Federal Community Bank" },
    { aba: "311973208", name: "Nexbank, Ssb" },
    { aba: "311973279", name: "Prosperity Bank" },
    { aba: "311973677", name: "Citizens 1st Bank" },
    { aba: "311974032", name: "Cypress Bank, Ssb" },
    { aba: "311977013", name: "Allied Federal Credit Union" },
    { aba: "311977026", name: "Texas Trust Credit Union" },
    { aba: "311977055", name: "Texas Trust Credit Union" },
    { aba: "311977084", name: "Highway District 19 Emp Cr Un" },
    { aba: "311977877", name: "Belton Federal Credit Union" },
    { aba: "311977990", name: "Fannin Federal Cu" },
    { aba: "311978164", name: "Mid-tex Federal Credit Union" },
    { aba: "311978232", name: "Valwood Park Federal Credit Union" },
    { aba: "311978287", name: "Ne Texas Teachers Fcu" },
    { aba: "311978290", name: "Members Credit Union" },
    { aba: "311978708", name: "Central Texas Teachers Credit Union" },
    { aba: "311978818", name: "Affiliated Bank" },
    { aba: "311979752", name: "Mobility Credit Union" },
    { aba: "311979875", name: "Texas Trust Credit Union" },
    { aba: "311980699", name: "Texas Telcom Credit Union" },
    { aba: "311980725", name: "Datcu Credit Union" },
    { aba: "311980754", name: "Life Federal Credit Union" },
    { aba: "311981342", name: "Farmers Branch City Employees Cu" },
    { aba: "311981410", name: "Unity One Credit Union" },
    { aba: "311981436", name: "Alcon Employees Federal Credit Union" },
    { aba: "311981494", name: "Ben E Keith Employees Fcu" },
    { aba: "311981533", name: "Southside Bank" },
    { aba: "311981562", name: "Pentagon Federal Credit Union" },
    { aba: "311981614", name: "Eecu" },
    { aba: "311981669", name: "Fort Worth City Cu" },
    { aba: "311981672", name: "Fort Worth Community Credit Union" },
    { aba: "311981698", name: "First Class American C U" },
    { aba: "311981724", name: "Eecu" },
    { aba: "311981737", name: "Texas People Fcu" },
    { aba: "311981740", name: "Eecu" },
    { aba: "311981805", name: "Ibew 116 Federal Credit" },
    { aba: "311981902", name: "Family 1st Of Texas Federal Cu" },
    { aba: "311981931", name: "Natural Resources Conervation Ser Fc" },
    { aba: "311981960", name: "Tarrant County's Credit Union" },
    { aba: "311981999", name: "Member Preferred F.c.u." },
    { aba: "311982134", name: "4u Federal Credit Union" },
    { aba: "311982147", name: "City Credit Union" },
    { aba: "311982435", name: "America's Credit Union" },
    { aba: "311982532", name: "Grand Prairie Credit Union" },
    { aba: "311982561", name: "Service 1st Credit Union" },
    { aba: "311985238", name: "Irving City Emp Fed Credit Union" },
    { aba: "311985429", name: "Greater Central Texas Fcu" },
    { aba: "311985542", name: "Corner Stone Credit Union" },
    { aba: "311985746", name: "North East Texas Credit Union" },
    { aba: "311985759", name: "Barksdale Federal Credit Union" },
    { aba: "311985788", name: "E Tx Professional Cu" },
    { aba: "311985791", name: "Telco Plus Credit Union" },
    { aba: "311985814", name: "Longview Federal Credit Union" },
    { aba: "311985830", name: "East Texas Professional Cu" },
    { aba: "311985869", name: "Sweetex Credit Union" },
    { aba: "311985872", name: "T & P Longview Federal Credit Union" },
    { aba: "311986253", name: "Marshall T & P Empl" },
    { aba: "311986318", name: "Rocket Federal Credit Un" },
    { aba: "311986334", name: "Mesquite Credit Union" },
    { aba: "311986376", name: "Centex Citizens Credit Union" },
    { aba: "311987207", name: "Paris District Credit Union" },
    { aba: "311987773", name: "Prosperity Bank" },
    { aba: "311987786", name: "Texans Credit Union" },
    { aba: "311987809", name: "Rockdale Federal Credit Union" },
    { aba: "311987870", name: "Texas Trust Credit Union" },
    { aba: "311988976", name: "Seagoville Federal Credit Union" },
    { aba: "311989001", name: "Union Square Credit Union" },
    { aba: "311989030", name: "Texoma Educators" },
    { aba: "311989072", name: "City Credit Union" },
    { aba: "311989234", name: "Freestone Credit Union" },
    { aba: "311989247", name: "Scott & White Employ Cr" },
    { aba: "311989250", name: "Texell Federal Credit Union" },
    { aba: "311989263", name: "Temple Santa Fe Community Cr Un" },
    { aba: "311989302", name: "Domino Fcu" },
    { aba: "311989315", name: "Fed Emply C U" },
    { aba: "311989328", name: "Morris Sheppard Texarkana Fcu" },
    { aba: "311989331", name: "Red River Emp Fcu" },
    { aba: "311989344", name: "Texar Federal Credit Union" },
    { aba: "311989357", name: "Texarkana Terminal Emp Fcu" },
    { aba: "311989360", name: "Fci Federal Credit Union" },
    { aba: "311989522", name: "Case Federal Credit Union" },
    { aba: "311989548", name: "Cooperative Teachers Cu" },
    { aba: "311989603", name: "Kelly Community Federal Credit Union" },
    { aba: "311989632", name: "Tyler City Employees Credit Union" },
    { aba: "311989645", name: "Keystone Credit Union" },
    { aba: "311989658", name: "Keystone Credit Union" },
    { aba: "311989661", name: "United Credit Union" },
    { aba: "311989674", name: "Mcmurrey Fcu" },
    { aba: "311989807", name: "1st University Cu" },
    { aba: "311989836", name: "C-t Waco Federal Credit Union" },
    { aba: "311989852", name: "Educators Credit Union" },
    { aba: "311989878", name: "Heart O Texas Fed Cr Union" },
    { aba: "311989917", name: "Mc Lennan County Empl Fcu" },
    { aba: "311989933", name: "Linkage Credit Union" },
    { aba: "311990016", name: "Waco Federal Credit Union" },
    { aba: "311990029", name: "Genco Federal Credit Union" },
    { aba: "311990045", name: "First Central Credit Union" },
    { aba: "311990058", name: "Members Choice Of Cenral Tx Fcu" },
    { aba: "311990061", name: "Waconized Federal Credit Union" },
    { aba: "311990265", name: "Midwestern State University Cr Un" },
    { aba: "311990294", name: "Texoma Community Cred Un" },
    { aba: "311990375", name: "Wichita Falls Fcu" },
    { aba: "311990388", name: "Wichita Falls Teachers Fcu" },
    { aba: "311990391", name: "Postel Family Credit Union" },
    { aba: "311990511", name: "Catalyst Corporate Fcu" },
    { aba: "311990809", name: "Employees United Fed. Credit Union" },
    { aba: "311991484", name: "My Credit Union" },
    { aba: "311991675", name: "Barksdale Federal Credit Union" },
    { aba: "311991866", name: "Cen Tex Manufacturing Credit Union" },
    { aba: "311992250", name: "Swemp Fcu" },
    { aba: "311992904", name: "American Airlines Fcu" },
    { aba: "311993149", name: "Plainscapital Bank" },
    { aba: "311993330", name: "Beal Bank Ssb" },
    { aba: "312080941", name: "Firstlight Federal Credit Union" },
    { aba: "312080970", name: "One Source Federal Credit Union" },
    { aba: "312080996", name: "Mountain Star Federal Credit Union" },
    { aba: "312081005", name: "Evolve Federal Credit Union" },
    { aba: "312081034", name: "El Paso Teachers Fcu" },
    { aba: "312081089", name: "Gecu" },
    { aba: "312081209", name: "One Source Federal Credit Union" },
    { aba: "312081212", name: "Chevron Federal Credit Union" },
    { aba: "312270010", name: "Otero Fcu" },
    { aba: "312270023", name: "Cannon Fcu" },
    { aba: "312270324", name: "Bank'34" },
    { aba: "312270379", name: "U.s. Bank National Association" },
    { aba: "312270450", name: "Washington Federal Bank, Na" },
    { aba: "312270463", name: "Pioneer Bank" },
    { aba: "312270476", name: "Tucumcari Fed. S&l" },
    { aba: "312276030", name: "Loco Credit Union" },
    { aba: "312276072", name: "Artesia Credit Union" },
    { aba: "312276085", name: "First Financial Credit Union" },
    { aba: "312276108", name: "Financial Security Credit Union" },
    { aba: "312276111", name: "U-1st Community Federal Credit Union" },
    { aba: "312276124", name: "Eddy Fcu" },
    { aba: "312276140", name: "Internationalites Federal Credit Uni" },
    { aba: "312276247", name: "Fort Bayard Federal Credit Union" },
    { aba: "312276289", name: "Estacado Federal Credit Union" },
    { aba: "312276360", name: "Chaves County School Employee C U" },
    { aba: "312276409", name: "Roswell Community Federal Cr Union" },
    { aba: "312276412", name: "Telco Roswell Nm Fcu" },
    { aba: "312276454", name: "Everyones's Fcu" },
    { aba: "312276470", name: "White Sands Fcu" },
    { aba: "312293505", name: "The Florist Fcu" },
    { aba: "312376684", name: "Alpine Community Credit Union" },
    { aba: "312385303", name: "Westex Community Credit Union" },
    { aba: "312386616", name: "Ward County Credit Union" },
    { aba: "313071920", name: "Prosperity Bank" },
    { aba: "313072819", name: "Capital One, N.a." },
    { aba: "313074370", name: "Texas First Bank" },
    { aba: "313074493", name: "Trustmark National Bank" },
    { aba: "313077513", name: "Community Resource Credit Union" },
    { aba: "313080584", name: "Space City Credit Union" },
    { aba: "313080597", name: "City Of Deer Park Fcu" },
    { aba: "313080610", name: "Lubrizol Emp C U" },
    { aba: "313080636", name: "Shell Federal Credit Union" },
    { aba: "313080649", name: "U S I Fcu" },
    { aba: "313082171", name: "United Community Credit Union" },
    { aba: "313082838", name: "Investex Credit Union" },
    { aba: "313082906", name: "Bp Federal Credit Union" },
    { aba: "313082935", name: "Texas Bay Area Credit Union" },
    { aba: "313082964", name: "Baker Hughes Fcu" },
    { aba: "313082980", name: "Bcm Federal Credit Union" },
    { aba: "313083125", name: "Oak Farms Employees Credit Union" },
    { aba: "313083167", name: "Ctecu" },
    { aba: "313083196", name: "Members Choice Credit Union" },
    { aba: "313083219", name: "C-e Federal Credit Union" },
    { aba: "313083222", name: "United Savers Trust Credit Union" },
    { aba: "313083235", name: "Cy Fair Fcu" },
    { aba: "313083251", name: "Houston Highway Credit Union" },
    { aba: "313083264", name: "Southern Star Credit Union" },
    { aba: "313083303", name: "Energy Capital Credit Union" },
    { aba: "313083426", name: "Spco Credit Union" },
    { aba: "313083471", name: "Harris County Fcu" },
    { aba: "313083578", name: "Smart Financial Credit Union" },
    { aba: "313083581", name: "Houston Belt & Term" },
    { aba: "313083620", name: "Members Trust Of The Southwest Fcu" },
    { aba: "313083633", name: "Space City Credit Union" },
    { aba: "313083646", name: "Houston Metropolitan Fcu" },
    { aba: "313083659", name: "Houston Police Fed C U" },
    { aba: "313083675", name: "Plus4 Credit Union" },
    { aba: "313083714", name: "Houston Tx Fire Fighters Fcu" },
    { aba: "313083727", name: "Primeway Federal Credit Union" },
    { aba: "313083772", name: "First Service Cu" },
    { aba: "313083824", name: "Union Fidelity Federal Credit Union" },
    { aba: "313083837", name: "Ibew Lu 66 Federal Credit Union" },
    { aba: "313083866", name: "Port Of Houston Warehouse Federal Cu" },
    { aba: "313083879", name: "Ila 1351 Federal Credit Union" },
    { aba: "313083992", name: "Jsc Federal Credit Union" },
    { aba: "313084001", name: "Kbr Heritage Federal Credit Union" },
    { aba: "313084124", name: "Memorial Credit Union" },
    { aba: "313084315", name: "Our Mother Mercy Federal Cr Un" },
    { aba: "313084357", name: "Pilgrim Cucc Federal Credit Union" },
    { aba: "313084409", name: "Port Of Houston Cu" },
    { aba: "313084412", name: "Port Terminal Federal Credit Union" },
    { aba: "313084467", name: "Met Tran Federal Credit Un" },
    { aba: "313084470", name: "Reed Credit Union" },
    { aba: "313084564", name: "Schlumberger Employees Cr Un" },
    { aba: "313084593", name: "People's Trust Federal Credit Union" },
    { aba: "313084632", name: "Southern Fed Credit Union" },
    { aba: "313084658", name: "Sp Trainmen Fcu" },
    { aba: "313084674", name: "First Community Credit Union" },
    { aba: "313084807", name: "Space City Credit Union" },
    { aba: "313084881", name: "First Service Credit Union" },
    { aba: "313084904", name: "Membersource Credit Union" },
    { aba: "313084917", name: "Chevron Fedral Credit Union" },
    { aba: "313084988", name: "Transtar Federal Credit Union" },
    { aba: "313085039", name: "United Energy Credit Union" },
    { aba: "313085071", name: "U S Employees Credit Union" },
    { aba: "313085084", name: "Pie Credit Union" },
    { aba: "313085181", name: "Local 24 Employees Federal C.u." },
    { aba: "313085275", name: "Right Choice Federal Credit Union" },
    { aba: "313085288", name: "Brazos Valley Schools Credit Union" },
    { aba: "313085495", name: "Beacon Federal Credit Union" },
    { aba: "313087228", name: "American Baptist Assoc. Credit Union" },
    { aba: "313087257", name: "Pasadena Municipal Federal Cu" },
    { aba: "313087273", name: "Pasadena Postal C U" },
    { aba: "313087286", name: "Gulf Coast Educators F.c.u" },
    { aba: "313087299", name: "Shared Resources Credit Union" },
    { aba: "313090561", name: "First Service Credit Union" },
    { aba: "313090914", name: "First Educators C.u." },
    { aba: "313092093", name: "Bayou City Federal Credit Union" },
    { aba: "313092530", name: "Pioneer Mutual Federal Cu" },
    { aba: "313170957", name: "Prosperity Bank" },
    { aba: "313172052", name: "Prosperity Bank" },
    { aba: "313172670", name: "First Financial Bank, N.a." },
    { aba: "313172913", name: "Rusk Fed S&l Asn" },
    { aba: "313173336", name: "Prosperity Bank" },
    { aba: "313173349", name: "Capital One, N.a." },
    { aba: "313173776", name: "Citizens 1st Bank" },
    { aba: "313173792", name: "Fayette Savings Bank, Ssb" },
    { aba: "313173938", name: "Angelina Savings Bank" },
    { aba: "313176692", name: "Brazos Community Credit Union" },
    { aba: "313176715", name: "Chocolate Bayou Community Fed Cu" },
    { aba: "313177468", name: "Baycel Federal Credit Union" },
    { aba: "313177484", name: "Matagorda County Credit Union" },
    { aba: "313177581", name: "Beaumont Community Credit Union" },
    { aba: "313177604", name: "Neches Federal Credit Union" },
    { aba: "313177662", name: "Commoncents Credit Union" },
    { aba: "313177688", name: "Ibew Community Federal Credit Union" },
    { aba: "313177727", name: "Mobiloil Federal Cr Union" },
    { aba: "313177756", name: "Union Pacific Employees Cu" },
    { aba: "313177785", name: "Education First Federal Cu" },
    { aba: "313178182", name: "Fedstar Credit Union" },
    { aba: "313180808", name: "Temple-inland Federal Credit Un" },
    { aba: "313180918", name: "J C T Fcu" },
    { aba: "313182068", name: "Brazosport Teachers Federal Cr Un" },
    { aba: "313182181", name: "Coastal Community Fcu" },
    { aba: "313182217", name: "Galveston Govt Empl" },
    { aba: "313182220", name: "Galveston School Employee Cr Un" },
    { aba: "313182602", name: "Golden Triangle Fcu" },
    { aba: "313182615", name: "Gulf Credit Union" },
    { aba: "313182631", name: "Sacred Heart Parish Fed Cr Un" },
    { aba: "313183368", name: "Houston Federal Credit Union" },
    { aba: "313185191", name: "Community Service Credit Union" },
    { aba: "313185201", name: "Walker County Fed C U" },
    { aba: "313185515", name: "Tx Dow Emply Credit" },
    { aba: "313185722", name: "Liberty County Teachers Fcu" },
    { aba: "313186093", name: "Angelina Cty Teachers Credit Union" },
    { aba: "313186103", name: "Angelina Federal Employees Cr Un" },
    { aba: "313186129", name: "Lufkin Federal Credit Union" },
    { aba: "313186158", name: "Southland Fcu" },
    { aba: "313186640", name: "Doches Credit Union" },
    { aba: "313186679", name: "Dugood Federal Credit Union" },
    { aba: "313186718", name: "Texasgulf Federal Credit Union" },
    { aba: "313186909", name: "Old Ocean Fed C U" },
    { aba: "313186938", name: "Sabine Federal Credit Union" },
    { aba: "313186983", name: "Wellspring Federal Credit Union" },
    { aba: "313186996", name: "Fivepoint Credit Union" },
    { aba: "313187021", name: "Anderson County Federalcu" },
    { aba: "313187047", name: "Mopac Employees Fcu" },
    { aba: "313187050", name: "Trinity Valley Teachers Cu" },
    { aba: "313187458", name: "Cal-com Federal Cu" },
    { aba: "313187474", name: "Port Arthur Community Federal Cu" },
    { aba: "313187500", name: "Port Arthur Teachers Fcu" },
    { aba: "313187571", name: "Fivepoint Credit Union" },
    { aba: "313187597", name: "Coastal Teachers Federal Credit Unio" },
    { aba: "313187636", name: "Neches Federal Credit Union" },
    { aba: "313187649", name: "Mct Credit Union" },
    { aba: "313187652", name: "Neches Federal Credit Union" },
    { aba: "313187704", name: "Prairie View Fcu" },
    { aba: "313187830", name: "Cherokee County Teachers Fed Cr Un" },
    { aba: "313187843", name: "Cherokee County Fcu" },
    { aba: "313189087", name: "Eastex Federal Credit Union" },
    { aba: "313189171", name: "Houston Federal Credit Union" },
    { aba: "313189391", name: "Amoco Federal Credit Union" },
    { aba: "313189401", name: "Associated Credit Union Of Texas" },
    { aba: "313189430", name: "Marathon Republic" },
    { aba: "313189472", name: "T C Teachers Federal Credit Union" },
    { aba: "313189498", name: "Gulf Shore Federal Credit Union" },
    { aba: "313189715", name: "Jackson County Federal Credit Union" },
    { aba: "313189731", name: "Victoria City County Emp Fcu" },
    { aba: "313189744", name: "Coastal Bend Po Fcu" },
    { aba: "313189760", name: "Victoria Federal Credit Union" },
    { aba: "313189773", name: "Victoria Teachers Fcu" },
    { aba: "313192647", name: "Shelby Savings Bank, Ssb" },
    { aba: "313193222", name: "Germania Credit Union" },
    { aba: "314074269", name: "Usaa Federal Savings Bank" },
    { aba: "314078469", name: "H.e.b. Fed. Cr. Union" },
    { aba: "314085504", name: "Air Force Federal Credit Union" },
    { aba: "314088190", name: "Texas Workforce Credit Union" },
    { aba: "314088239", name: "Randolph-brooks Fcu" },
    { aba: "314088284", name: "Credit Human Federal Credit Union" },
    { aba: "314088352", name: "City Public Service/ibew Fcu" },
    { aba: "314088420", name: "Air Force Federal Credit Union" },
    { aba: "314088446", name: "Air Force Fcu" },
    { aba: "314088530", name: "River City Federal Credit Union" },
    { aba: "314088556", name: "Firstmark Credit Union" },
    { aba: "314088572", name: "Generations Federal Credit Union" },
    { aba: "314088585", name: "Generations Federal Credit Union" },
    { aba: "314088637", name: "Security Service Fcu" },
    { aba: "314088666", name: "Select Federal Credit Union" },
    { aba: "314088705", name: "Randolph-brooks Fcu" },
    { aba: "314088747", name: "Firstmark Credit Union" },
    { aba: "314088828", name: "Southwest Research Ctr Fcu" },
    { aba: "314088860", name: "United Texas Credit Union" },
    { aba: "314089681", name: "Randolph - Brooks Fcu" },
    { aba: "314091035", name: "Tx Assoc Of Professionals" },
    { aba: "314092128", name: "Synergy Federal Credit Union" },
    { aba: "314092717", name: "Baptist Credit Union" },
    { aba: "314970664", name: "Bbva Usa" },
    { aba: "314971168", name: "Trusttexas Bank,ssb" },
    { aba: "314972853", name: "Woodforest National Bank" },
    { aba: "314973412", name: "International Bank Of Commerce" },
    { aba: "314973548", name: "Greater South Texas Bank" },
    { aba: "314973593", name: "Prosperity Bank" },
    { aba: "314974000", name: "First Community Bank" },
    { aba: "314974071", name: "Broadway National Bank" },
    { aba: "314974356", name: "Prosperity Bank" },
    { aba: "314977104", name: "A+ Federal Credit Union" },
    { aba: "314977133", name: "Velocity Credit Union" },
    { aba: "314977162", name: "Star Of Texas Credit Union" },
    { aba: "314977175", name: "Austin Telco Federal C U" },
    { aba: "314977188", name: "United Heritage Cu" },
    { aba: "314977191", name: "Capitol Credit Union" },
    { aba: "314977214", name: "Gefcu" },
    { aba: "314977227", name: "Amplify Credit Union" },
    { aba: "314977243", name: "Lcra Cu" },
    { aba: "314977298", name: "Public Employees Credit Union" },
    { aba: "314977324", name: "Texas Dps Credit Union" },
    { aba: "314977337", name: "Greater Texas Federal Cr U" },
    { aba: "314977340", name: "Texas Health Credit Union" },
    { aba: "314977382", name: "Travis County Credit Union" },
    { aba: "314977405", name: "University Federal Credit Union" },
    { aba: "314977418", name: "Austin Federal C/u" },
    { aba: "314977421", name: "Vatat Credit Union" },
    { aba: "314977971", name: "Chemcel Federal Credit Union" },
    { aba: "314978093", name: "First Financial Community Fcu" },
    { aba: "314978132", name: "Valley Federal Credit Un" },
    { aba: "314978352", name: "Gulf Coast Fcu" },
    { aba: "314978381", name: "Corpus Christi Sp Cu" },
    { aba: "314978394", name: "Members First Credit Union" },
    { aba: "314978404", name: "Texas Bridge Credit Union" },
    { aba: "314978417", name: "Coastal Community And Teachers Cu" },
    { aba: "314978446", name: "Gulf Coast Fcu" },
    { aba: "314978543", name: "Navy Army Community Credit Union" },
    { aba: "314978556", name: "Nce Credit Union" },
    { aba: "314978598", name: "Star Financial Credit Uion" },
    { aba: "314978608", name: "C.c. Postal Employees Credit Union" },
    { aba: "314978637", name: "Third Coast Federal Credit Union" },
    { aba: "314980667", name: "Del Rio Sp Cu" },
    { aba: "314980887", name: "Edinburg Teachers Credit Union" },
    { aba: "314982694", name: "Rio Grande Valley Credit Union" },
    { aba: "314985293", name: "Texstar Federal Credit Union" },
    { aba: "314985329", name: "Kerr County Fcu" },
    { aba: "314985332", name: "Texhillco School Emp Fcu" },
    { aba: "314985345", name: "Kerr County Federal Credit Union" },
    { aba: "314985442", name: "Kingsville Community Fcu" },
    { aba: "314985468", name: "La Joya Area Federal Credit Union" },
    { aba: "314985552", name: "South Texas Regional Fcu" },
    { aba: "314985578", name: "Laredo Federal Credit Union" },
    { aba: "314985581", name: "Laredo Fire Department Fcu" },
    { aba: "314985617", name: "Laredo Federal Credit Union" },
    { aba: "314985659", name: "Tex-mex Credit Union" },
    { aba: "314985662", name: "Border Federal Credit Union" },
    { aba: "314986292", name: "Security First Federal Credit Union" },
    { aba: "314986302", name: "South Texas Federal Credit Union" },
    { aba: "314987369", name: "Hwy Dist 21 Fcu" },
    { aba: "314987372", name: "Naft Federal Credit Union" },
    { aba: "314987699", name: "Sherwin Federal Credit Union" },
    { aba: "314987712", name: "Cowboy Country Federal Credit Union" },
    { aba: "314987754", name: "Refugio County Federal Credit Union" },
    { aba: "314987796", name: "Starr County Teacher" },
    { aba: "314989095", name: "San Patricio County Teachers Fcu" },
    { aba: "314990165", name: "La Joya Area Federal Credit Union" },
    { aba: "316386434", name: "My Community Credit Union" },
    { aba: "316386476", name: "Members Financial Fcu" },
    { aba: "316386489", name: "Mtcu" },
    { aba: "316386492", name: "Heritage Usa Federal Credit Union" },
    { aba: "316386735", name: "Southwest Heritage Credit Union" },
    { aba: "316386777", name: "West Tx Educators C U" },
    { aba: "316386803", name: "First Basin Credit Union" },
    { aba: "316386829", name: "Odessa Employees' Credit Union" },
    { aba: "316386832", name: "Complex Community Federal Credit Un." },
    { aba: "316386858", name: "Southwest 66 Credit Union" },
    { aba: "321070007", name: "Citibank West" },
    { aba: "321070104", name: "Citibank Fsb" },
    { aba: "321070227", name: "Us Bank, Na" },
    { aba: "321070450", name: "East West Bank" },
    { aba: "321070829", name: "United Business Bank" },
    { aba: "321071077", name: "East West Bank" },
    { aba: "321071129", name: "Gbc International Bank" },
    { aba: "321071161", name: "Gateway Bank Fsb" },
    { aba: "321075866", name: "Cfcu Dba Spectrum Credit Union" },
    { aba: "321075934", name: "Community Trust-div Of Self Help Fcu" },
    { aba: "321075947", name: "Chevron Federal Credit Union" },
    { aba: "321075989", name: "Ilwu Federal Credit Union" },
    { aba: "321076056", name: "Mckesson Employees' Fcu" },
    { aba: "321076098", name: "Uncle Credit Union" },
    { aba: "321076140", name: "Skyone Federal Credit Union" },
    { aba: "321076218", name: "Matson Employees F.c.u." },
    { aba: "321076292", name: "Polam F C U" },
    { aba: "321076328", name: "Financial Partners Credit Union" },
    { aba: "321076412", name: "Unify Financial Federal Credit Union" },
    { aba: "321076441", name: "San Francisco Federal Credit Union" },
    { aba: "321076467", name: "Sf Bay Area Educators Cu" },
    { aba: "321076470", name: "Patelco Credit Union" },
    { aba: "321076496", name: "The Police Credit Union Of Ca" },
    { aba: "321076506", name: "San Francisco Fire Credit Union" },
    { aba: "321076522", name: "San Francisco Fire Credit Union" },
    { aba: "321081041", name: "Northeast Community Fcu" },
    { aba: "321081669", name: "First Republic Bank" },
    { aba: "321170282", name: "Citibank West" },
    { aba: "321170318", name: "Mufg Union Bank, N.a." },
    { aba: "321170444", name: "Citibank West" },
    { aba: "321170538", name: "Bbva Usa" },
    { aba: "321170596", name: "First Federal Sla Of San Rafael" },
    { aba: "321170839", name: "Travis Credit Union" },
    { aba: "321170842", name: "Heritage Community Credit Union" },
    { aba: "321170978", name: "El Dorado Savings Bank" },
    { aba: "321171184", name: "Citibank Fsb" },
    { aba: "321171265", name: "Sea West Coast Guard Fcu" },
    { aba: "321171304", name: "First United Services Credit Union" },
    { aba: "321171317", name: "Smw 104 Fed Cr Un" },
    { aba: "321171391", name: "Delta Schools Federal Credit Union" },
    { aba: "321171427", name: "Antioch Community Fcu" },
    { aba: "321171621", name: "Cooperative Center F C U" },
    { aba: "321171731", name: "Provident Credit Union" },
    { aba: "321171744", name: "Upward Credit Union" },
    { aba: "321171757", name: "Safeamerica Credit Union" },
    { aba: "321171773", name: "Citizens Equity First Credit Union" },
    { aba: "321171841", name: "Members 1st Credit Union" },
    { aba: "321171964", name: "Unify Financial Federal Credit Union" },
    { aba: "321171980", name: "Western Healthcare F.c.u" },
    { aba: "321172044", name: "1st Northern California Cu" },
    { aba: "321172073", name: "San Mateo Credit Union" },
    { aba: "321172125", name: "Silverado Credit Union" },
    { aba: "321172141", name: "Chabot Federal Credit Union" },
    { aba: "321172167", name: "Redwood Credit Union" },
    { aba: "321172183", name: "Moore West Federal Cr Un" },
    { aba: "321172219", name: "Compass Community Credit Union" },
    { aba: "321172248", name: "Coast Central Credit Union" },
    { aba: "321172293", name: "Northern Redwood Federal Cu" },
    { aba: "321172316", name: "Solano First Federal Credit Union" },
    { aba: "321172358", name: "Community First Credit Union" },
    { aba: "321172374", name: "Central Coast Federal Credit Union" },
    { aba: "321172442", name: "Greater Valley Credit Union" },
    { aba: "321172510", name: "Noble Federal Credit Union" },
    { aba: "321172536", name: "Central Valley Firefighters Cu" },
    { aba: "321172578", name: "Fresno Police Dept Credit Union" },
    { aba: "321172594", name: "Educational Employees Credit Union" },
    { aba: "321172688", name: "United Local Credit Union" },
    { aba: "321172691", name: "San Joaquin Power Employees Cu" },
    { aba: "321172756", name: "Valley First Credit Union" },
    { aba: "321172769", name: "First California Federal Cu" },
    { aba: "321172808", name: "Community First Credit Union" },
    { aba: "321172824", name: "Kings Federal Credit Union" },
    { aba: "321172866", name: "Bay Cities Fcu" },
    { aba: "321172992", name: "Golden Valley Federal Credit Union" },
    { aba: "321173001", name: "Families And Schools Together Fcu" },
    { aba: "321173072", name: "Uncle Credit Union" },
    { aba: "321173085", name: "Mokelumne Federal Credit Union" },
    { aba: "321173182", name: "1st Northern California Credit Union" },
    { aba: "321173205", name: "Unify Financial Federal Credit Union" },
    { aba: "321173218", name: "Shell Western States Fcu" },
    { aba: "321173289", name: "Menlo Survey Fcu" },
    { aba: "321173328", name: "Sri Federal Credit Union" },
    { aba: "321173373", name: "Merced School Efcu" },
    { aba: "321173386", name: "Merco Credit Union" },
    { aba: "321173470", name: "Mocse Federal Credit Union" },
    { aba: "321173483", name: "Organized Labor Credit Union" },
    { aba: "321173506", name: "Modesto's First Federal Credit Union" },
    { aba: "321173519", name: "Rolling F Credit Union" },
    { aba: "321173522", name: "Valley First Credit Union" },
    { aba: "321173603", name: "Monterey Credit Union" },
    { aba: "321173674", name: "Technology Credit Union" },
    { aba: "321173742", name: "Safe Credit Union" },
    { aba: "321174000", name: "1st United Services Credit Union" },
    { aba: "321174149", name: "Alliant Credit Union" },
    { aba: "321174152", name: "Safeamerica Credit Union" },
    { aba: "321174262", name: "Unify Financial Federal Credit Union" },
    { aba: "321174372", name: "United Association Credit Union" },
    { aba: "321174385", name: "Utility District Credit Union" },
    { aba: "321174440", name: "San Mateo Credit Union" },
    { aba: "321174521", name: "Operating Engineers Union #3 Fcu" },
    { aba: "321174550", name: "Dow Great Western Cu" },
    { aba: "321174602", name: "Sierra Central Credit Union" },
    { aba: "321174738", name: "Members 1st Credit Union" },
    { aba: "321174770", name: "Sierra Central Credit Union" },
    { aba: "321174819", name: "My Credit Union" },
    { aba: "321174848", name: "Sequoia Federal Credit Union" },
    { aba: "321174851", name: "San Mateo Credit Union" },
    { aba: "321174864", name: "Upward Credit Union" },
    { aba: "321174880", name: "Atchison Village Credit Union" },
    { aba: "321174903", name: "Travis Credit Union" },
    { aba: "321175180", name: "Financial Partners Credit Union" },
    { aba: "321175232", name: "C.a.h.p. Cu" },
    { aba: "321175258", name: "Vision One Credit Union" },
    { aba: "321175261", name: "The Golden 1 Credit Union" },
    { aba: "321175465", name: "First Us Community Credit Union" },
    { aba: "321175481", name: "First Us Community Credit Union" },
    { aba: "321175520", name: "Sacramento Credit Union" },
    { aba: "321175533", name: "Sacramento Postal Ecu" },
    { aba: "321175588", name: "First Us Community Credit Union" },
    { aba: "321175614", name: "California Community Credit Union" },
    { aba: "321175627", name: "Schoolsfirst Federal Credit Union" },
    { aba: "321175643", name: "California Community Credit Union" },
    { aba: "321175711", name: "Allu.s. Credit Union" },
    { aba: "321175737", name: "Central Coast Federal Credit Union" },
    { aba: "321175766", name: "Bay Federal Credit Union" },
    { aba: "321176260", name: "Operating Engineers Local Un #3 Fcu" },
    { aba: "321176574", name: "Sun Pacific Federal Credit Union" },
    { aba: "321176752", name: "University & State Employees Cu" },
    { aba: "321176794", name: "Commonwealth Central Credit Union" },
    { aba: "321176804", name: "Excite Credit Union" },
    { aba: "321176833", name: "Meriwest Credit Union" },
    { aba: "321176862", name: "Star One Credit Union" },
    { aba: "321176875", name: "Premierone Credit Union" },
    { aba: "321176914", name: "Pacific Postal Credit Union" },
    { aba: "321176972", name: "Santa Clara County Federal Cu" },
    { aba: "321177256", name: "San Mateo City Efcu" },
    { aba: "321177340", name: "Marin County Federal Credit Union" },
    { aba: "321177434", name: "Premierone Credit Union" },
    { aba: "321177447", name: "Mission City Fcu" },
    { aba: "321177560", name: "Sonoma Federal Credit Union" },
    { aba: "321177573", name: "North Bay Credit Union" },
    { aba: "321177586", name: "Redwood Credit Union" },
    { aba: "321177599", name: "Community First Credit Union" },
    { aba: "321177670", name: "Corrections Federal Credit Union" },
    { aba: "321177706", name: "Bay Federal Credit Union" },
    { aba: "321177722", name: "Stanford Federal Credit Union" },
    { aba: "321177735", name: "Central State Credit Union" },
    { aba: "321177780", name: "Uncle Credit Union" },
    { aba: "321177803", name: "Financial Center Credit Union" },
    { aba: "321177861", name: "Unify Financial Federal Credit Union" },
    { aba: "321177887", name: "Premier Community Credit Union" },
    { aba: "321177968", name: "Star One Credit Union" },
    { aba: "321178019", name: "Technology Credit Union" },
    { aba: "321178048", name: "Lassen County Federal Credit Union" },
    { aba: "321178051", name: "Valley Oak Credit Union" },
    { aba: "321178093", name: "Valley First Credit Union" },
    { aba: "321178158", name: "Tulare County Federal Credit Union" },
    { aba: "321178190", name: "Redwood Credit Union" },
    { aba: "321178226", name: "Community First Credit Union" },
    { aba: "321178336", name: "Befit Financial Federal Credit Union" },
    { aba: "321178349", name: "Self-help Federal Credit Union" },
    { aba: "321178420", name: "Tucoemas Fcu" },
    { aba: "321178446", name: "Kaiperm Federal Credit Union" },
    { aba: "321178459", name: "Diablo Valley Fcu" },
    { aba: "321178475", name: "Watsonville Hospital Fcu" },
    { aba: "321178514", name: "Yolo Federal Credit Union" },
    { aba: "321178543", name: "Siskiyou Central Credit Union" },
    { aba: "321180379", name: "First Technology Fcu" },
    { aba: "321180515", name: "Keypoint Credit Union" },
    { aba: "321180874", name: "Vocality Community Credit Union" },
    { aba: "321180939", name: "Santa Cruz Comm Cu" },
    { aba: "321181200", name: "Nbh Bank" },
    { aba: "321181271", name: "Mufg Union Bank, N.a." },
    { aba: "321181297", name: "Luther Burbank Savings" },
    { aba: "321181307", name: "Summit State Bank" },
    { aba: "321181491", name: "Beneficial State Bank" },
    { aba: "321181501", name: "Murphy Bank" },
    { aba: "321270742", name: "Wells Fargo Bank Na (nevada)" },
    { aba: "321280130", name: "Financial Horizons Credit Union" },
    { aba: "321280143", name: "Greater Nevada Credit Union" },
    { aba: "321280156", name: "Churchill Cty Fcu" },
    { aba: "321280198", name: "Great Basin Fcu" },
    { aba: "321280208", name: "Reno City Employees Fcu" },
    { aba: "321280224", name: "United Federal Credit Union" },
    { aba: "321280237", name: "Sierra Pacific Fcu" },
    { aba: "321280253", name: "Greater Nevada Credit Union" },
    { aba: "321280266", name: "Nevada Federal Cu" },
    { aba: "321280282", name: "Great Basin Federal Credit Union" },
    { aba: "321280606", name: "Operating Engineers Union #3 Fcu" },
    { aba: "321370707", name: "Territorial Savings Bank" },
    { aba: "321370765", name: "American Savings Bank Fsb" },
    { aba: "321371528", name: "Pearl Hawaii Federal Credit Union" },
    { aba: "321378592", name: "Mc Bryde Fcu" },
    { aba: "321378602", name: "Ewa Federal Credit Union" },
    { aba: "321378644", name: "Big Island Federal Credit Union" },
    { aba: "321378657", name: "Hawaii County Employees Fcu" },
    { aba: "321378660", name: "Hfs Federal Credit Union" },
    { aba: "321378673", name: "Helco Fed Cu" },
    { aba: "321378709", name: "Cu Hawaii Federal Credit Union" },
    { aba: "321378738", name: "Hfs Federal Credit Union" },
    { aba: "321378741", name: "Hotel And Travel Industry Fcu" },
    { aba: "321378990", name: "Hawaii Central Federal Credit Union" },
    { aba: "321379012", name: "Hawaii Federal Credit Union" },
    { aba: "321379041", name: "Hawaii State Federal Credit Union" },
    { aba: "321379067", name: "Hawaiian Electric Efcu" },
    { aba: "321379070", name: "Hawaiian Financial Fcu" },
    { aba: "321379083", name: "Hawaii Federal Credit Union" },
    { aba: "321379106", name: "Hickam Federal Cu" },
    { aba: "321379119", name: "Hmsa Employees Federal Credit Union" },
    { aba: "321379122", name: "Honea Federal Credit Union" },
    { aba: "321379148", name: "Aloha Pacific Federal Credit Union" },
    { aba: "321379151", name: "Honolulu Fire Dept Fcu" },
    { aba: "321379164", name: "Honolulu Federal Credit Union" },
    { aba: "321379180", name: "Hawaii Law Enforcement Fcu" },
    { aba: "321379261", name: "Kamehameha Federal Cr Un" },
    { aba: "321379290", name: "Kuakini Medical Center Fcu" },
    { aba: "321379313", name: "University Of Hawaii Fcu" },
    { aba: "321379339", name: "Local Union 1186 Ibew Fcu" },
    { aba: "321379355", name: "Aloha Pacific Federal Credit Union" },
    { aba: "321379397", name: "Aloha Pacific Federal Credit Union" },
    { aba: "321379410", name: "Hawaiiusa Federal Credit Union" },
    { aba: "321379436", name: "Oahu Federal Credit Union" },
    { aba: "321379449", name: "Hawaii Schools Fcu" },
    { aba: "321379546", name: "Aloha Pacific Federal Credit Union" },
    { aba: "321379559", name: "Chevron Federal Credit Union" },
    { aba: "321379588", name: "St. Francis Medical Center Fcu" },
    { aba: "321379656", name: "University Of Hawaii Fcu" },
    { aba: "321379685", name: "Molokai Community Fcu" },
    { aba: "321379698", name: "Kahuku Fcu" },
    { aba: "321379708", name: "Valley Isle Community Fcu" },
    { aba: "321379711", name: "Kahului Federal Credit Union" },
    { aba: "321379724", name: "Maui Federal Credit Union" },
    { aba: "321379737", name: "Maui Teachers Federal Credit Union" },
    { aba: "321379753", name: "Hawaii First Federal Credit Union" },
    { aba: "321379795", name: "Molokai Community Fcu" },
    { aba: "321379805", name: "Hfs Federal Credit Union (puna Fcu)" },
    { aba: "321379818", name: "Hawaii Community Fcu" },
    { aba: "321379821", name: "Aloha Pacific Federal Credit Union" },
    { aba: "321379834", name: "Garden Island Federal Credit Union" },
    { aba: "321379847", name: "Kula Community Fcu" },
    { aba: "321379863", name: "Lahaina Fcu" },
    { aba: "321379876", name: "Maui County Federal Credit Union" },
    { aba: "321379902", name: "Gather Federal Credit Union" },
    { aba: "321379915", name: "Kauai Government Efcu" },
    { aba: "321379928", name: "Kauai Teachers Fcu" },
    { aba: "321379931", name: "Garden Island Federal Credit Union" },
    { aba: "321379944", name: "Cu Hawaii Federal Credit Union" },
    { aba: "321379999", name: "Onomea Federal Credit Union" },
    { aba: "321380001", name: "Hamakua Fcu" },
    { aba: "321380014", name: "Hawaii State Federal Credit Union" },
    { aba: "321380027", name: "Schofield Fcu" },
    { aba: "321380043", name: "Waialua Federal Credit Union" },
    { aba: "321380072", name: "Maui County Efcu" },
    { aba: "321380085", name: "Wailuku Federal Credit Union" },
    { aba: "321380098", name: "Kulia Ohana Federal Credit Union" },
    { aba: "321380315", name: "Ko'olau Federal Credit Union" },
    { aba: "321380328", name: "Pearl Hawaii Federal Credit Union" },
    { aba: "321381233", name: "Finance Factors, Ltd." },
    { aba: "321381644", name: "Plumbers & Fitters Local 675 Fcu" },
    { aba: "321470364", name: "Bankpacific Ltd" },
    { aba: "321480406", name: "Community First Guam Fcu" },
    { aba: "321481612", name: "Coast360 Fcu" },
    { aba: "322070019", name: "Citibank West" },
    { aba: "322070145", name: "Broadway Federal Bank, Fsb" },
    { aba: "322070239", name: "Oneunited Bank" },
    { aba: "322070381", name: "East West Bank" },
    { aba: "322070598", name: "One United Bank" },
    { aba: "322070789", name: "Aerospace Fcu" },
    { aba: "322071872", name: "Hanmi Bank (first Global Bank)" },
    { aba: "322071982", name: "Eastwest Bank" },
    { aba: "322074277", name: "Certified Federal Credit Union" },
    { aba: "322076136", name: "Gain Federal Credit Union" },
    { aba: "322076152", name: "Federal Employees West Credit Union" },
    { aba: "322076181", name: "Sag-aftra Federal Credit Union" },
    { aba: "322076233", name: "Sag-aftra Federal Credit Union" },
    { aba: "322077119", name: "Certified Federal Credit Union" },
    { aba: "322077135", name: "Nuvision Federal Credit Union" },
    { aba: "322077274", name: "Cal State La Federal Credit Union" },
    { aba: "322077287", name: "First Financial Credit Union" },
    { aba: "322077313", name: "California Bear Credit Union" },
    { aba: "322077449", name: "University Credit Union" },
    { aba: "322077478", name: "Credit Union Of Southern California" },
    { aba: "322077494", name: "California Agribusiness Cu" },
    { aba: "322077559", name: "Alliant Credit Union" },
    { aba: "322077562", name: "La Financial Credit Union" },
    { aba: "322077766", name: "Pacific Transportation Fcu" },
    { aba: "322077779", name: "Skyone Federal Credit Union" },
    { aba: "322077795", name: "Farmers Insurance Group Fcu" },
    { aba: "322077818", name: "Unify Financial Federal Credit Union" },
    { aba: "322077902", name: "Nuvision Federal Credit Union" },
    { aba: "322078011", name: "La Healthcare Federal Credit Union" },
    { aba: "322078079", name: "Kinecta Federal Credit Union" },
    { aba: "322078257", name: "Schools Federal Credit Union" },
    { aba: "322078325", name: "Southland Credit Union" },
    { aba: "322078341", name: "Firefighters First Credit Union" },
    { aba: "322078370", name: "Los Angeles Federal Cu" },
    { aba: "322078383", name: "F & A Federal Credit Union" },
    { aba: "322078396", name: "L A Electrical Workers Credit Union" },
    { aba: "322078419", name: "First City Credit Union" },
    { aba: "322078422", name: "Water & Power Community Credit Union" },
    { aba: "322078448", name: "Cityside Federal Credit Union" },
    { aba: "322078464", name: "The California Credit Union" },
    { aba: "322078477", name: "Jacom Credit Union" },
    { aba: "322078493", name: "Los Angeles Police F.c.u." },
    { aba: "322078590", name: "Printing Industries Credit Union" },
    { aba: "322078668", name: "Certified Federal Credit Union" },
    { aba: "322078804", name: "Financial Partners Credit Union" },
    { aba: "322078833", name: "Ups Employees Federal Credit Union" },
    { aba: "322078927", name: "Polam Fcu" },
    { aba: "322078930", name: "Priority One Credit Union" },
    { aba: "322078972", name: "First City Credit Union" },
    { aba: "322078998", name: "Clearpath Federal Credit Union" },
    { aba: "322079094", name: "E-central Credit Union" },
    { aba: "322079133", name: "Credit Union Of Southern California" },
    { aba: "322079227", name: "E-central Credit Union" },
    { aba: "322079324", name: "Technicolor Federal Credit Union" },
    { aba: "322079353", name: "Wescom Credit Union" },
    { aba: "322079450", name: "Wescom Credit Union" },
    { aba: "322079463", name: "Union Pacific Caefcu" },
    { aba: "322079489", name: "California Bear Credit Union" },
    { aba: "322079502", name: "University Credit Union" },
    { aba: "322079531", name: "Los Angeles Federal Cu" },
    { aba: "322079557", name: "Usc Credit Union" },
    { aba: "322079719", name: "Unify Financial Federal Credit Union" },
    { aba: "322084597", name: "Pasadena Service Fcu" },
    { aba: "322084827", name: "Certified Federal Credit Union" },
    { aba: "322085318", name: "Popa Federal Credit Union" },
    { aba: "322086142", name: "East West Bank" },
    { aba: "322170692", name: "Bank Of America, N.a. - Arizona" },
    { aba: "322172069", name: "San Tan Credit Union" },
    { aba: "322172108", name: "Coconino Federal Credit Union" },
    { aba: "322172111", name: "Pinal County Fed C U" },
    { aba: "322172153", name: "Credit Union West" },
    { aba: "322172205", name: "Ray Federal Credit Union" },
    { aba: "322172221", name: "Mohave Community Fcu" },
    { aba: "322172425", name: "Alhambra Credit Union" },
    { aba: "322172438", name: "U-haul Federal Credit Union" },
    { aba: "322172441", name: "Arizona Central Credit Union" },
    { aba: "322172496", name: "Oneaz Credit Union" },
    { aba: "322172506", name: "Sunwest Fed Cu" },
    { aba: "322172739", name: "Marisol Federal Credit Union" },
    { aba: "322172742", name: "First Credit Union" },
    { aba: "322172797", name: "Arizona Federal Credit Union" },
    { aba: "322172807", name: "Copper State Credit Union" },
    { aba: "322172849", name: "Copper State Credit Union" },
    { aba: "322172852", name: "America First Federal Credit Union" },
    { aba: "322172881", name: "Banner Federal Credit Union" },
    { aba: "322172904", name: "Aero Federal Credit Union" },
    { aba: "322172917", name: "Southwest Health Care C U" },
    { aba: "322173039", name: "Bashas Associates Fcu" },
    { aba: "322173055", name: "Truwest Credit Union" },
    { aba: "322173084", name: "Rim Country Federal Credit Union" },
    { aba: "322173149", name: "Landings Credit Union" },
    { aba: "322173165", name: "Prescott Fcu" },
    { aba: "322173181", name: "First American Credit Union" },
    { aba: "322173194", name: "Winslow Santa Fe C U" },
    { aba: "322173204", name: "Winslow School Efcu" },
    { aba: "322173217", name: "Aea Federal Credit Union" },
    { aba: "322174575", name: "Southeastern Arizona Fedcu" },
    { aba: "322174588", name: "American Southwest Credit Union" },
    { aba: "322174708", name: "Vantage West Credit Union" },
    { aba: "322174795", name: "Pima Federal Credit Union" },
    { aba: "322174821", name: "Pyramid Credit Union" },
    { aba: "322174850", name: "Shamrock Foods Fcu" },
    { aba: "322174944", name: "Hughes Federal Credit Union" },
    { aba: "322174957", name: "Tucson Old Pueblo Credit Union" },
    { aba: "322174986", name: "Tucson Federal Credit Union" },
    { aba: "322175011", name: "Cochise Credit Union" },
    { aba: "322186288", name: "Bmo Harris Bank Na" },
    { aba: "322270055", name: "Citibank West" },
    { aba: "322270262", name: "Citibank West" },
    { aba: "322270275", name: "Universal Bank" },
    { aba: "322270288", name: "Cit Bank N.a." },
    { aba: "322270356", name: "Us Bank" },
    { aba: "322270408", name: "Bbva Usa" },
    { aba: "322270453", name: "Banc Of California" },
    { aba: "322270495", name: "Us Bank" },
    { aba: "322270518", name: "Provident Savings Bank" },
    { aba: "322270521", name: "Jpmorgan Chase" },
    { aba: "322270770", name: "Edwards Fed Credit Union" },
    { aba: "322270822", name: "Coasthills Credit Union" },
    { aba: "322271038", name: "Altura Credit Union" },
    { aba: "322271096", name: "Citibank West" },
    { aba: "322271106", name: "Cit Bank N.a." },
    { aba: "322271326", name: "Union Bank Of California" },
    { aba: "322271407", name: "Community Commerce Bank" },
    { aba: "322271627", name: "Jpmorgan Chase" },
    { aba: "322271724", name: "Citibank Fsb" },
    { aba: "322271766", name: "Eastwest Bank" },
    { aba: "322271779", name: "Citibank West" },
    { aba: "322271850", name: "Bbva Usa" },
    { aba: "322273272", name: "Credit Union Of Southern California" },
    { aba: "322273379", name: "Eccu" },
    { aba: "322273489", name: "Foothill Federal Credit Union" },
    { aba: "322273609", name: "Safe 1 Credit Union" },
    { aba: "322273625", name: "Bakersfield City Emp Fcu" },
    { aba: "322273667", name: "Espeeco Fcu" },
    { aba: "322273696", name: "Self-help Fcu" },
    { aba: "322273706", name: "Strata Federal Credit Union" },
    { aba: "322273722", name: "Valley Strong Credit Union" },
    { aba: "322273829", name: "Barstow Railway Employees Cu" },
    { aba: "322273890", name: "Beverly Hills City Efcu" },
    { aba: "322273900", name: "20th Century Fox Fcu" },
    { aba: "322273913", name: "High Sierra Credit Union" },
    { aba: "322273997", name: "Orange County's Credit Union" },
    { aba: "322274048", name: "California Agribusiness Credit Union" },
    { aba: "322274116", name: "Gain Federal Credit Union" },
    { aba: "322274132", name: "Ume Federal Credit Union" },
    { aba: "322274158", name: "First Entertainment Credit Union" },
    { aba: "322274187", name: "Logix Federal Credit Union" },
    { aba: "322274190", name: "Premier America Credit Union" },
    { aba: "322274239", name: "Priority One Credit Union" },
    { aba: "322274242", name: "Partners Federal Credit Union" },
    { aba: "322274462", name: "Altaone Federal Credit Union" },
    { aba: "322274488", name: "Cabrillo Credit Union" },
    { aba: "322274527", name: "Banc Of California" },
    { aba: "322274572", name: "Bourns Employees Fcu" },
    { aba: "322274653", name: "Mid-cities Schools Credit Union" },
    { aba: "322274734", name: "Novo Federal Credit Union" },
    { aba: "322274789", name: "Nuvision Financial Fcu" },
    { aba: "322274792", name: "Credit Union Of Southern California" },
    { aba: "322274831", name: "Christian Community Credit Union" },
    { aba: "322274925", name: "Frontwave Credit Union" },
    { aba: "322274941", name: "Glendale Federal Credit Union" },
    { aba: "322275089", name: "Trugrocer Federal Credit Union" },
    { aba: "322275115", name: "Downey Fcu" },
    { aba: "322275144", name: "Rancho Fcu" },
    { aba: "322275157", name: "Financial Partners Credit Union" },
    { aba: "322275212", name: "Employees Choice Fcu" },
    { aba: "322275241", name: "Inland Federal Credit Union" },
    { aba: "322275254", name: "California Coast Credit Union" },
    { aba: "322275296", name: "Sun Community Federal Credit Union" },
    { aba: "322275348", name: "El Monte Community Credit Union" },
    { aba: "322275429", name: "Certified Federal Credit Union" },
    { aba: "322275432", name: "Lafayette Fcu" },
    { aba: "322275445", name: "Chevron Federal Credit Union" },
    { aba: "322275487", name: "Financial Partners Credit Union" },
    { aba: "322275490", name: "Xceed Financial Credit Union" },
    { aba: "322275526", name: "Escondido Federal Credit Union" },
    { aba: "322275555", name: "Fontana Federal Credit Union" },
    { aba: "322275568", name: "Inland Valley Federal Credit Union" },
    { aba: "322275607", name: "American First Credit Union" },
    { aba: "322275610", name: "Credit Union Of Southern California" },
    { aba: "322275649", name: "Schoolsfirst Federal Credit Union" },
    { aba: "322275746", name: "Honda Federal Credit Union" },
    { aba: "322275788", name: "Kinecta Federal Credit Union" },
    { aba: "322275791", name: "Nikkei Credit Union" },
    { aba: "322275856", name: "Media City Credit Union" },
    { aba: "322275869", name: "Airco Federal Credit Union" },
    { aba: "322275885", name: "Glendale Federal Credit Union" },
    { aba: "322275908", name: "California Adventist Fcu" },
    { aba: "322276062", name: "Kinecta Federal Credit Union" },
    { aba: "322276075", name: "Mattel Fcu" },
    { aba: "322276088", name: "Northrop Grumman Federal Cu" },
    { aba: "322276240", name: "Huntington Beach City Employees Cu" },
    { aba: "322276305", name: "First Imperial Credit Union" },
    { aba: "322276509", name: "East County Schools Fcu" },
    { aba: "322276583", name: "Schoolsfirst Federal Credit Union" },
    { aba: "322276596", name: "Schoolsfirst Federal Credit Union" },
    { aba: "322276622", name: "La Loma Federal Credit Union" },
    { aba: "322276774", name: "Va Desert Pacific Fcu" },
    { aba: "322276800", name: "Postcity Financial Credit Union" },
    { aba: "322276813", name: "Arrowhead Central Credit Union" },
    { aba: "322276839", name: "Long Beach City Empl Fcu" },
    { aba: "322276842", name: "Long Beach Firemens Credit Union" },
    { aba: "322276855", name: "Lbs Financial Credit Union" },
    { aba: "322276868", name: "Allied Healthcare Fcu" },
    { aba: "322276871", name: "Financial Partners Credit Union" },
    { aba: "322276949", name: "Financial Partners Credit Union" },
    { aba: "322277281", name: "The First Financial Fed Credit Union" },
    { aba: "322277841", name: "Capstone Federal Credit Union" },
    { aba: "322278073", name: "Kinecta Federal Credit Union" },
    { aba: "322278183", name: "Homestreet Bank" },
    { aba: "322279920", name: "Financial Partners Credit Union" },
    { aba: "322279975", name: "Camino Federal Credit Union" },
    { aba: "322279988", name: "Bourns Employees Fcu" },
    { aba: "322280032", name: "Southern California Edison Co Fcu" },
    { aba: "322280058", name: "Glendale Area Schools Credit Union" },
    { aba: "322280090", name: "Nuvision Federal Credit Union" },
    { aba: "322280139", name: "Southern California Postal Cu" },
    { aba: "322280184", name: "Paradise Valley Fcu" },
    { aba: "322280207", name: "Arrowhead Central Credit Union" },
    { aba: "322280223", name: "Financial Partners Cu (cal West Cu)" },
    { aba: "322280265", name: "Lbs Financial Credit Union" },
    { aba: "322280278", name: "Matadors Community Credit Union" },
    { aba: "322280304", name: "Premier America Credit Union" },
    { aba: "322280375", name: "Chaffey Fcu" },
    { aba: "322280414", name: "Ontario Public Employees F.c.u." },
    { aba: "322280427", name: "Ontario-montclair School Emp Fcu" },
    { aba: "322280485", name: "Credit Union Of Southern California" },
    { aba: "322280540", name: "Unify Financial Federal Credit Union" },
    { aba: "322280621", name: "Sun Community Federal Credit Union" },
    { aba: "322280692", name: "Caltech Employees Fcu" },
    { aba: "322280728", name: "Pasadena Fcu" },
    { aba: "322280731", name: "Pasadena Service Fcu" },
    { aba: "322280838", name: "Premier America Credit Union" },
    { aba: "322280854", name: "Cal Poly Federal Credit Union" },
    { aba: "322280906", name: "Interfaith Federal Credit Union" },
    { aba: "322280919", name: "Pacific Federal C U" },
    { aba: "322280935", name: "Pomona Postal Fcu" },
    { aba: "322280977", name: "Printing Ofc Ecu Div Pasadena Sv Fcu" },
    { aba: "322280980", name: "Pvhmc Federal Credit Union" },
    { aba: "322280993", name: "C B C Federal Credit Union" },
    { aba: "322281028", name: "South Bay Credit Union" },
    { aba: "322281031", name: "Parishioners Federal Credit Union" },
    { aba: "322281057", name: "Unify Financial Federal Credit Union" },
    { aba: "322281109", name: "Bourns Employees Fcu" },
    { aba: "322281196", name: "Printing & Publishing Ecu" },
    { aba: "322281206", name: "Financial Partners Credit Union" },
    { aba: "322281235", name: "Altura Credit Union" },
    { aba: "322281248", name: "Schoolsfirst Federal Credit Union" },
    { aba: "322281264", name: "Priority One Credit Union" },
    { aba: "322281329", name: "California Credit Union" },
    { aba: "322281374", name: "California Credit Union" },
    { aba: "322281439", name: "Financial Partners Credit Union" },
    { aba: "322281455", name: "North County Credit Union" },
    { aba: "322281468", name: "California Credit Union" },
    { aba: "322281484", name: "Mypoint Credit Union" },
    { aba: "322281507", name: "Mission Federal Credit Union" },
    { aba: "322281549", name: "Wheelhouse Credit Union" },
    { aba: "322281578", name: "California Coast Credit Union" },
    { aba: "322281604", name: "California Coast Credit Union" },
    { aba: "322281617", name: "San Diego County Credit Union" },
    { aba: "322281633", name: "California Coast Credit Union" },
    { aba: "322281659", name: "California Coast Credit Union" },
    { aba: "322281688", name: "California Coast Credit Union" },
    { aba: "322281691", name: "University & State Employees Cu" },
    { aba: "322281824", name: "Bopti Fcu" },
    { aba: "322281840", name: "Credit Union Of Southern California" },
    { aba: "322281866", name: "California Agribusiness Credit Union" },
    { aba: "322281882", name: "Financial Partners Credit Union" },
    { aba: "322281905", name: "Orange Countys Credit Union" },
    { aba: "322281963", name: "Southland Credit Union" },
    { aba: "322281989", name: "Orange County's Credit Union" },
    { aba: "322281992", name: "Eagle Community Credit Union" },
    { aba: "322282001", name: "Schoolsfirst Federal Credit Union" },
    { aba: "322282027", name: "Santa Ana Federal Credit Union" },
    { aba: "322282098", name: "Schools First Federal Credit Union" },
    { aba: "322282137", name: "Santa Barbara County Fcu" },
    { aba: "322282153", name: "Santa Barbara Teachers Fcu" },
    { aba: "322282292", name: "Financial Partners Credit Union" },
    { aba: "322282386", name: "California Lithuanian Credit Union" },
    { aba: "322282399", name: "Nuvision Federal Credit Union" },
    { aba: "322282454", name: "Southland Credit Union" },
    { aba: "322282483", name: "Southland Credit Union" },
    { aba: "322282519", name: "Sea Air Federal Credit Union" },
    { aba: "322282551", name: "Sunkist Employees Fcu" },
    { aba: "322282580", name: "Alta Vista Credit Union" },
    { aba: "322282603", name: "Arrowhead Credit Union" },
    { aba: "322282616", name: "Thinkwise Federal Credit Union" },
    { aba: "322282632", name: "1st Valley Credit Union" },
    { aba: "322282674", name: "Slo Credit Union" },
    { aba: "322282713", name: "Sesloc Fcu" },
    { aba: "322282894", name: "Olive View Fcu" },
    { aba: "322282920", name: "Safe 1 Credit Union" },
    { aba: "322282962", name: "Treasury Department Fcu" },
    { aba: "322282975", name: "Financial Partners Credit Union" },
    { aba: "322283042", name: "Schoolsfirst Federal Credit Union" },
    { aba: "322283071", name: "Arrowhead Central Credit Union" },
    { aba: "322283084", name: "Southland Credit Union Aka Harbor Cu" },
    { aba: "322283107", name: "Calcom Federal Credit Union" },
    { aba: "322283152", name: "Torrance Community Credit Union" },
    { aba: "322283178", name: "Unify Financial Federal Credit Union" },
    { aba: "322283181", name: "Schoolsfirst Federal Credit Union" },
    { aba: "322283204", name: "Desert Valleys Fcu" },
    { aba: "322283220", name: "Universal City Studios Cu" },
    { aba: "322283369", name: "Gain Federal Credit Union" },
    { aba: "322283505", name: "Ventura County Credit Union" },
    { aba: "322283518", name: "County Schools Federal Credit Union" },
    { aba: "322283628", name: "Bourns Employees Fcu" },
    { aba: "322283709", name: "United Catholics Fcu" },
    { aba: "322283712", name: "Prospectors Federal Credit Union" },
    { aba: "322283767", name: "America's Christian Credit Union" },
    { aba: "322283796", name: "Credit Union Of Southern California" },
    { aba: "322283822", name: "Prospectors Federal Credit Union" },
    { aba: "322283835", name: "Credit Union Of Southern California" },
    { aba: "322283893", name: "Ilwu Federal Credit Union" },
    { aba: "322283990", name: "Premier America Credit Union" },
    { aba: "322284698", name: "Parsons Fcu" },
    { aba: "322284779", name: "America's Christian Credit Union" },
    { aba: "322284892", name: "Us Bank" },
    { aba: "322284928", name: "Balboa Thrift & Loan Association" },
    { aba: "322284960", name: "Nuvision Federal Credit Union" },
    { aba: "322284986", name: "North County Credit Union" },
    { aba: "322285024", name: "Union Yes Federal Credit Union" },
    { aba: "322285480", name: "Home Bank Of California" },
    { aba: "322285668", name: "Pacific Western Bank" },
    { aba: "322285781", name: "Pacific Premier Bank" },
    { aba: "322285833", name: "Pacific Premier Bank" },
    { aba: "322285846", name: "Us Bank" },
    { aba: "322286120", name: "Malaga Bank Ssb" },
    { aba: "322286256", name: "Eastern International Bank" },
    { aba: "322286395", name: "San Diego Firefighters Fcu" },
    { aba: "322286447", name: "Pacific Western Bank" },
    { aba: "322286489", name: "Tustin Community Bank" },
    { aba: "322286492", name: "Cit Bank N.a." },
    { aba: "322286803", name: "Silvergate Bank" },
    { aba: "322484016", name: "Pahranagat Valley Fcu" },
    { aba: "322484029", name: "Boulder Dam Credit Union" },
    { aba: "322484113", name: "Clark County Credit Union" },
    { aba: "322484142", name: "America First Federal Credit Union" },
    { aba: "322484197", name: "Las Vegas Up Empoyees Fcu271987635" },
    { aba: "322484207", name: "I B E W Plus Credit Union" },
    { aba: "322484265", name: "Silver State Schools Credit Union" },
    { aba: "322484278", name: "Sce Federal Credit Union" },
    { aba: "322484304", name: "America First Federal Credit Union" },
    { aba: "322484320", name: "Sierra Pacific Fcu" },
    { aba: "322484401", name: "One Nevada Credit Union" },
    { aba: "322484414", name: "America First Federal Credit Union" },
    { aba: "322484634", name: "Weststar Credit Union" },
    { aba: "323070380", name: "Bank Of America, N.a." },
    { aba: "323074959", name: "United Trades Federal Credit Union" },
    { aba: "323074991", name: "United Trades Federal Credit Union" },
    { aba: "323075071", name: "Castparts Employees Fcu" },
    { aba: "323075097", name: "Advantis Credit Union" },
    { aba: "323075136", name: "Consolidated Federal Credit Union" },
    { aba: "323075181", name: "Advantis Credit Union" },
    { aba: "323075194", name: "Ibew & United Workers Fcu" },
    { aba: "323075220", name: "Sunset Science Park Fcu" },
    { aba: "323075259", name: "Northwest Community Credit Union" },
    { aba: "323075275", name: "Legacy Federal Credit Union" },
    { aba: "323075327", name: "Ironworkers Usa Federal Credit Union" },
    { aba: "323075356", name: "Kai Perm Nw Federal Credit Union" },
    { aba: "323075372", name: "Northwest Adventist Fcu" },
    { aba: "323075408", name: "Machinists-boilermaker Fcu" },
    { aba: "323075437", name: "Northwest Community Credit Union" },
    { aba: "323075495", name: "Point West Credit Union" },
    { aba: "323075547", name: "Hapo Community Credit Union" },
    { aba: "323075563", name: "Trailhead Credit Union" },
    { aba: "323075686", name: "Nw Priority Credit Union" },
    { aba: "323075699", name: "Unitus Community Credit Union" },
    { aba: "323075709", name: "Oregonians Credit Union" },
    { aba: "323075754", name: "Pacific Nw Fed Cred Union" },
    { aba: "323075806", name: "Usagencies Credit Union" },
    { aba: "323075822", name: "Portland Local 8 Fcu" },
    { aba: "323075864", name: "Northwest Priority Credit Union" },
    { aba: "323075880", name: "Onpoint Community Credit Union" },
    { aba: "323075945", name: "Providence Federal Credit Union" },
    { aba: "323076009", name: "United Trades Federal Credit Union" },
    { aba: "323076012", name: "Rivermark Community Credit Union" },
    { aba: "323076135", name: "United Trades Federal Credit Union" },
    { aba: "323076151", name: "Teamsters Council 37 Fcu" },
    { aba: "323076575", name: "Nw Preferred Federal Credit Union" },
    { aba: "323170112", name: "Umpqua Bank" },
    { aba: "323172958", name: "Cottonwood Community Fcu" },
    { aba: "323173041", name: "Pine Tree Cu" },
    { aba: "323173193", name: "Freedom Northwest Credit Union" },
    { aba: "323173274", name: "Lewis Clark Credit Union" },
    { aba: "323173313", name: "Potlatch No 1 Financial Credit Union" },
    { aba: "323173368", name: "Latah Credit Union" },
    { aba: "323173834", name: "St Joe Valley Credit Union" },
    { aba: "323173863", name: "Potlatch No 1 Financial Credit Union" },
    { aba: "323270216", name: "Umpqua Bank" },
    { aba: "323270274", name: "Evergreen Federal Savings & Loan" },
    { aba: "323270300", name: "Umpqua Bank" },
    { aba: "323270313", name: "First Federal Savings & Loan" },
    { aba: "323270436", name: "Wells Fargo Bank Na" },
    { aba: "323271493", name: "Columbia State Bank" },
    { aba: "323274076", name: "Central Willamette Credit Union" },
    { aba: "323274092", name: "Red Canoe Credit Union" },
    { aba: "323274160", name: "First Technology Fcu" },
    { aba: "323274186", name: "Mid Oregon Federal Credit Union" },
    { aba: "323274199", name: "Rogue Credit Union" },
    { aba: "323274209", name: "Wauna Federal Credit Union" },
    { aba: "323274225", name: "Northwest Community Cu" },
    { aba: "323274238", name: "First Community Credit Union" },
    { aba: "323274254", name: "Benton County Schools Cu" },
    { aba: "323274270", name: "Oregon State Credit Union" },
    { aba: "323274351", name: "Pacific Cascade Federal Cu" },
    { aba: "323274377", name: "Eweb Employees Federal Credit Union" },
    { aba: "323274393", name: "Laneco Federal Credit Union" },
    { aba: "323274432", name: "Emerald Empire Federal Credit Union" },
    { aba: "323274445", name: "Selco Community Credit Union" },
    { aba: "323274458", name: "First Technology Fcu" },
    { aba: "323274461", name: "Oregon Community Credit Union" },
    { aba: "323274513", name: "First Community Credit Union" },
    { aba: "323274526", name: "Hapo Community Credit Union" },
    { aba: "323274584", name: "Cascade Central Credit Union" },
    { aba: "323274597", name: "Opc Federal Credit Union" },
    { aba: "323274607", name: "Old West Federal Credit Union" },
    { aba: "323274678", name: "Klamath Public Employees Fcu" },
    { aba: "323274704", name: "Pacific Crest Federal Credit Union" },
    { aba: "323274733", name: "Old West Federal Credit Union" },
    { aba: "323274762", name: "Linn Co Federal Cred Un" },
    { aba: "323274775", name: "Rogue Credit Union" },
    { aba: "323274788", name: "Cutting Edge Federal Credit Union" },
    { aba: "323274843", name: "South Coast Ilwu Federal Cu" },
    { aba: "323274869", name: "Malheur Federal Credit Union" },
    { aba: "323274885", name: "Clackamas Federal Credit Union" },
    { aba: "323274937", name: "Umatilla County Fed C U" },
    { aba: "323274940", name: "First Community Credit Union" },
    { aba: "323276304", name: "Tlc Fcu A Division Of Fibre Fcu" },
    { aba: "323276320", name: "Cascade Community Credit Union" },
    { aba: "323276346", name: "Inroads Federal Credit Union" },
    { aba: "323276362", name: "Ibew/sj Cascade Fcu" },
    { aba: "323276388", name: "Marion & Polk Schools Credit Union" },
    { aba: "323276391", name: "Heritage Grove Federal Credit Union" },
    { aba: "323276401", name: "Valley Credit Union" },
    { aba: "323276430", name: "First Technology Fcu" },
    { aba: "323276469", name: "Ibew / Sj Cascade Fcu" },
    { aba: "323276485", name: "Emerald Empire Federal Credit Union" },
    { aba: "323276498", name: "Northwest Community Credit Union" },
    { aba: "323276524", name: "Northwest Community Credit Union" },
    { aba: "323276647", name: "Hapo Community Credit Union" },
    { aba: "323283944", name: "Gateway Credit Union" },
    { aba: "323370666", name: "Riverview Community Bank" },
    { aba: "323371076", name: "Banner Bank" },
    { aba: "323380261", name: "Iq Credit Union" },
    { aba: "323380326", name: "Blue Mountain Credit Union" },
    { aba: "323380740", name: "Red Canoe Credit Union" },
    { aba: "323380766", name: "Fibre Federal Credit Union" },
    { aba: "323380782", name: "Lower Columbia Longshoremen's Fcu" },
    { aba: "323380795", name: "Pud Federal Credit Union" },
    { aba: "323380821", name: "Red Canoe Credit Union" },
    { aba: "323380834", name: "Mint Valley Fcu" },
    { aba: "323383349", name: "Columbia Credit Union" },
    { aba: "323383352", name: "Lighthouse Community Credit Union" },
    { aba: "323383378", name: "Iq Credit Union" },
    { aba: "323383394", name: "People's Community Federal Cr Un" },
    { aba: "323383404", name: "Columbia Credit Union" },
    { aba: "323383530", name: "Longshoremens Local 4 Fcu" },
    { aba: "324077379", name: "Hi Land Credit Union" },
    { aba: "324078187", name: "Hercules First Federal Credit Union" },
    { aba: "324078297", name: "American Utd Family Of Credit Unions" },
    { aba: "324078365", name: "American United Family Of Cu's Fcu" },
    { aba: "324078381", name: "Chevron Federal Credit Union" },
    { aba: "324078417", name: "Utah Federal Credit Union" },
    { aba: "324078475", name: "Deseret New Employees Credit Union" },
    { aba: "324078527", name: "Varex Federal Credit Union" },
    { aba: "324078585", name: "Cyprus Federal Credit Union" },
    { aba: "324078640", name: "American United Federal Credit Union" },
    { aba: "324078682", name: "Gibbons & Reed Employees Fcu" },
    { aba: "324078721", name: "Granite Cu" },
    { aba: "324078776", name: "Hercules First Federal Credit Union" },
    { aba: "324078909", name: "Deseret First Credit Union" },
    { aba: "324078938", name: "Lu #354 Ibew Federal Credit Union" },
    { aba: "324078941", name: "Meadow Gold Employee's C.u." },
    { aba: "324078996", name: "National Jacl Credit Union" },
    { aba: "324079005", name: "Newspaper Employees Credit Union" },
    { aba: "324079063", name: "P & S Credit Union" },
    { aba: "324079115", name: "Transwest Credit Union" },
    { aba: "324079131", name: "American United Family Of Cu's Fcu" },
    { aba: "324079160", name: "Slc Vaf Federal Credit Union" },
    { aba: "324079225", name: "Firefighters Credit Union" },
    { aba: "324079296", name: "Cyprus Federal Credit Union" },
    { aba: "324079348", name: "Tanner Employees Credit Union" },
    { aba: "324079377", name: "Hercules First Federal Credit Union" },
    { aba: "324079393", name: "Cyprus Federal Credit Union" },
    { aba: "324079416", name: "University First Fcu" },
    { aba: "324079474", name: "Chartway Federal Credit Union" },
    { aba: "324079500", name: "Utah First Federal Credit Union" },
    { aba: "324079539", name: "Utah Power & Light Credit Union" },
    { aba: "324079542", name: "America First Federal Credit Union" },
    { aba: "324079555", name: "Mountain America Fcu" },
    { aba: "324084003", name: "Alloya Corporate Federal Credit Un" },
    { aba: "324170085", name: "Washington Federal Bank, Na" },
    { aba: "324170179", name: "First Federal Savings Bank-twin Fall" },
    { aba: "324172465", name: "Trugrocer Federal Credit Union" },
    { aba: "324172575", name: "Capital Educators Fcu" },
    { aba: "324172588", name: "E S A Credit Union" },
    { aba: "324172630", name: "Horizon Credit Union" },
    { aba: "324172698", name: "Idaho Central Credit Union" },
    { aba: "324172708", name: "Health Care Idaho Credit Union" },
    { aba: "324172766", name: "Idaho State University Fcu" },
    { aba: "324172795", name: "Pioneer Federal Credit Union" },
    { aba: "324172850", name: "Simplot Employees Credit Union" },
    { aba: "324172863", name: "Desert Sage Fcu" },
    { aba: "324173079", name: "Westmark Credit Union" },
    { aba: "324173082", name: "East Idaho Credit Union" },
    { aba: "324173121", name: "Members Preferred Cu" },
    { aba: "324173150", name: "Idaho State University Fcu" },
    { aba: "324173383", name: "Pioneer Federal Credit Union" },
    { aba: "324173422", name: "Clarity Credit Union" },
    { aba: "324173448", name: "Northwest Christian Credit Union" },
    { aba: "324173480", name: "Mountain Gem Credit Union" },
    { aba: "324173587", name: "Advantage Plus Federal Credit Union" },
    { aba: "324173626", name: "Idaho Central Credit Union" },
    { aba: "324173639", name: "Idaho State University Fcu" },
    { aba: "324173655", name: "Pocatello Simplot Credit Union" },
    { aba: "324173697", name: "Idaho State University Fcu" },
    { aba: "324173707", name: "Rails West Federal Credit Union" },
    { aba: "324173710", name: "Connections Credit Union" },
    { aba: "324173736", name: "Sei Us Employees Fcu" },
    { aba: "324173817", name: "Beehive Federal Credit Union" },
    { aba: "324173943", name: "Snake River Federal Credit Union" },
    { aba: "324184440", name: "Idaho United Credit Union" },
    { aba: "324274033", name: "Elko Federal Credit Union" },
    { aba: "324376711", name: "San Juan Credit Union" },
    { aba: "324376740", name: "Phillips-wasatch Credit Union" },
    { aba: "324376805", name: "Members First Credit Union" },
    { aba: "324376818", name: "Elevate Federal Credit Union" },
    { aba: "324376944", name: "Utah Prison Employees Credit Union" },
    { aba: "324377011", name: "Horizon Credit Union" },
    { aba: "324377024", name: "Millard County Credit Union" },
    { aba: "324377066", name: "American United Family Of Cu's Fcu" },
    { aba: "324377134", name: "Desertview Federal Credit Union" },
    { aba: "324377189", name: "Logan Medical Federal Credit Union" },
    { aba: "324377202", name: "Cyprus Federal Credit Union" },
    { aba: "324377286", name: "Grand County Credit Union" },
    { aba: "324377325", name: "Devil's Slide Federal Credit Union" },
    { aba: "324377338", name: "Moroni Feed Cu" },
    { aba: "324377448", name: "Nephi Western Credit Union" },
    { aba: "324377516", name: "America First Federal Credit Union" },
    { aba: "324377558", name: "Summitone Credit Union" },
    { aba: "324377590", name: "Wasatch Peaks Federal Credit Union" },
    { aba: "324377600", name: "Wasatch Peaks Federal Credit Union" },
    { aba: "324377613", name: "Goldenwest Federal Credit Union" },
    { aba: "324377626", name: "Education First Credit Union" },
    { aba: "324377707", name: "Wasatch Peaks Federal Credit Union" },
    { aba: "324377710", name: "Ascent Federal Credit Union" },
    { aba: "324377765", name: "Alpine Credit Union" },
    { aba: "324377817", name: "Eastern Utah Community Credit Union" },
    { aba: "324377820", name: "Utah Community Credit Union" },
    { aba: "324377846", name: "C.u.p. Federal Credit Union" },
    { aba: "324377914", name: "America First Federal Credit Union" },
    { aba: "324377927", name: "Freedom Credit Union" },
    { aba: "324377998", name: "Kings Peak Credit Union" },
    { aba: "324378036", name: "Chartway Federal Credit Union" },
    { aba: "324379705", name: "Jordan Credit Union" },
    { aba: "324379750", name: "Logan Cache Rich Fcu" },
    { aba: "324379763", name: "Nebo Credit Un" },
    { aba: "324379789", name: "Pacific Horizon Credit Union" },
    { aba: "324379792", name: "American United Family Of Cu Fcu" },
    { aba: "324379828", name: "Chartway Federal Credit Union" },
    { aba: "324379844", name: "Uintah Credit Union" },
    { aba: "324383843", name: "American United Family Of Cu Fcu" },
    { aba: "325070760", name: "Jpmorgan Chase" },
    { aba: "325070951", name: "Jpmorgan Chase" },
    { aba: "325070980", name: "Washington Federal Bank, Na" },
    { aba: "325072166", name: "Alaska Air Group Fcu" },
    { aba: "325081306", name: "Sound Community Bank" },
    { aba: "325081403", name: "Boeing Employees Credit Union" },
    { aba: "325081474", name: "Cascade Federal Credit Union" },
    { aba: "325081500", name: "Seattle Metropolitan Credit Union" },
    { aba: "325081610", name: "Salal Credit Union" },
    { aba: "325081665", name: "Harborstone Credit Union" },
    { aba: "325081704", name: "Express Credit Union" },
    { aba: "325081791", name: "Seattle Metropolitan Credit Union" },
    { aba: "325081827", name: "Nordstrom Federal Credit Union" },
    { aba: "325081885", name: "Verity Credit Union" },
    { aba: "325081924", name: "Sound Credit Union" },
    { aba: "325081966", name: "Qualstar Credit Union" },
    { aba: "325082017", name: "Community First Credit Union" },
    { aba: "325082033", name: "Sound Credit Union" },
    { aba: "325082062", name: "Waterfront Federal Credit Union" },
    { aba: "325082127", name: "Seattle Metropolitan Credit Union" },
    { aba: "325082130", name: "Qualstar Credit Union" },
    { aba: "325082198", name: "Qualstar Credit Union" },
    { aba: "325082253", name: "Qualstar Credit Union" },
    { aba: "325082266", name: "Gesa Credit Union" },
    { aba: "325084138", name: "Seattle Metropolitan Credit Union" },
    { aba: "325084426", name: "Homestreet Bank" },
    { aba: "325170628", name: "1st Security Bank Of Washington" },
    { aba: "325170631", name: "Washington Federal Bank, Na" },
    { aba: "325170754", name: "Timberland Bank" },
    { aba: "325170822", name: "Olympia Federal Savings & Loan Assn" },
    { aba: "325170835", name: "Heritage Bank" },
    { aba: "325170848", name: "First Federal Sla Of Port Angeles" },
    { aba: "325170864", name: "Raymond Federal Bank" },
    { aba: "325170877", name: "First Financial Northwest Bank" },
    { aba: "325171122", name: "Yakima Federal Savings & Loan Assn" },
    { aba: "325171232", name: "Washington Federal Bank, Na" },
    { aba: "325171740", name: "Umpqua Bank" },
    { aba: "325179946", name: "Longshore Federal Credit Union" },
    { aba: "325179959", name: "Newrizons Federal Credit Union" },
    { aba: "325179991", name: "Tesoro Northwest Fcu" },
    { aba: "325180061", name: "Qualstar Credit Union" },
    { aba: "325180087", name: "Qualstar Credit Union" },
    { aba: "325180113", name: "Whatcom Educational C U" },
    { aba: "325180142", name: "Westedge Federal Credit Union" },
    { aba: "325180155", name: "Industrial Credit Union" },
    { aba: "325180184", name: "North Coast Credit Union" },
    { aba: "325180207", name: "Connection Credit Union" },
    { aba: "325180223", name: "Kitsap Credit Union" },
    { aba: "325180304", name: "Cheney Fcu" },
    { aba: "325180333", name: "Great Northwest Federal Credit Union" },
    { aba: "325180346", name: "Coulee Dam Fcu" },
    { aba: "325180401", name: "White River Credit Union" },
    { aba: "325180414", name: "Horizon Credit Union" },
    { aba: "325180427", name: "Granco Federal Credit Union" },
    { aba: "325180508", name: "Community Healthcare Fcu" },
    { aba: "325180524", name: "Northwest Plus Credit Union" },
    { aba: "325180540", name: "Mill Town Credit Union" },
    { aba: "325180553", name: "Snocope Fcu" },
    { aba: "325180566", name: "Mountaincrest Credit Union" },
    { aba: "325180579", name: "Mountaincrest Credit Union" },
    { aba: "325180595", name: "Global Credit Union" },
    { aba: "325180618", name: "North Coast Credit Union" },
    { aba: "325180634", name: "America's Credit Union, A Federal Cu" },
    { aba: "325180676", name: "Tri-cities Community Fcu" },
    { aba: "325180870", name: "Harborstone Credit Union" },
    { aba: "325180935", name: "Community First Credit Union" },
    { aba: "325180977", name: "O Bee Credit Union" },
    { aba: "325180980", name: "Olympia Credit Union" },
    { aba: "325181002", name: "Evergreendirect Credit Union" },
    { aba: "325181015", name: "Twinstar Credit Union" },
    { aba: "325181028", name: "Washington State Employees Cu" },
    { aba: "325181099", name: "Strait-view Credit Union" },
    { aba: "325181154", name: "Mt Rainier Federal Credit Union" },
    { aba: "325181248", name: "Gesa Credit Union" },
    { aba: "325181264", name: "Hapo Community Credit Union" },
    { aba: "325182289", name: "1st Security Bank Of Washington" },
    { aba: "325182344", name: "Peninsula Community Fcu" },
    { aba: "325182357", name: "Our Community Credit Union" },
    { aba: "325182360", name: "Sno Falls Credit Union" },
    { aba: "325182373", name: "Spokane City Credit Union" },
    { aba: "325182441", name: "Spokane Media Fcu" },
    { aba: "325182496", name: "Gesa Credit Union" },
    { aba: "325182506", name: "Horizon C U" },
    { aba: "325182551", name: "Horizon Credit Union" },
    { aba: "325182564", name: "Horizon Credit Union" },
    { aba: "325182577", name: "Safeway Federal Credit Union" },
    { aba: "325182580", name: "Primesource Credit Union" },
    { aba: "325182603", name: "Horizon Credit Union" },
    { aba: "325182629", name: "Canopy Federal Credit Union" },
    { aba: "325182645", name: "Spokane Firefighters C U" },
    { aba: "325182674", name: "Responders Emergency Services Cu" },
    { aba: "325182687", name: "Progressions Credit Union" },
    { aba: "325182690", name: "Numerica Credit Union" },
    { aba: "325182700", name: "Spokane Teachers Credit Union" },
    { aba: "325182726", name: "Primesource Credit Union" },
    { aba: "325182755", name: "Horizon Credit Union" },
    { aba: "325182771", name: "Avista Corp Credit Union" },
    { aba: "325182797", name: "Horizon Credit Union" },
    { aba: "325182836", name: "Lower Valley Cu" },
    { aba: "325182849", name: "American Lake Credit Union" },
    { aba: "325182946", name: "Umpqua Bank" },
    { aba: "325182991", name: "Ibew 76 Fcu" },
    { aba: "325183039", name: "American Lake Credit Union" },
    { aba: "325183152", name: "Tacoma Narrows Federal Credit Union" },
    { aba: "325183165", name: "American Lake Credit Union" },
    { aba: "325183194", name: "Tacoma Longshoremen Cu" },
    { aba: "325183220", name: "Sound Credit Union" },
    { aba: "325183233", name: "Tapco Credit Union" },
    { aba: "325183259", name: "Puget Sound Cooperative Credit Union" },
    { aba: "325183291", name: "Qualstar Credit Union" },
    { aba: "325183657", name: "Lower Valley Cu" },
    { aba: "325183725", name: "Calcoe Federal Credit Union" },
    { aba: "325183738", name: "Solarity Credit Union" },
    { aba: "325183754", name: "State Highway Credit Union" },
    { aba: "325183796", name: "Solarity Credit Union" },
    { aba: "325183819", name: "Gesa Credit Union" },
    { aba: "325270049", name: "Northrim Bank" },
    { aba: "325271268", name: "Mt Mckinley Bank" },
    { aba: "325272021", name: "Alaska Usa Fcu" },
    { aba: "325272034", name: "Alaska District Engineers Fcu" },
    { aba: "325272047", name: "Nuvision Federal Credit Union" },
    { aba: "325272050", name: "Nuvision Federal Credit Union" },
    { aba: "325272063", name: "Credit Union 1" },
    { aba: "325272115", name: "Credit Union 1" },
    { aba: "325272157", name: "Northern Skies Federal Credit Union" },
    { aba: "325272209", name: "Mac Federal Cu" },
    { aba: "325272212", name: "Spirit Of Alaska Fcu" },
    { aba: "325272225", name: "Credit Union One" },
    { aba: "325272270", name: "True North Federal Credit Union" },
    { aba: "325272306", name: "Tongass Fcu" },
    { aba: "325272335", name: "Matanuska Valley Fcu" },
    { aba: "325272351", name: "Alps Federal Credit Union" },
    { aba: "325272377", name: "Credit Union One (ward Cove Fcu)" },
    { aba: "325280039", name: "Mac Federal Credit Union" },
    { aba: "655060042", name: "Social Security Administration" },
    { aba: "074986820", name: "Profed Federal Credit Union" },
    { aba: "021204403", name: "Valley National Bank" },
    { aba: "041000153", name: "Huntington National Bank" },
    { aba: "063112605", name: "Banco Popular, Florida" },
    { aba: "083905449", name: "Fifth Third Bank" },
    { aba: "071908144", name: "Cibc Bank Usa" },
    { aba: "071911788", name: "First Midwest Bank Na" },
    { aba: "075009188", name: "Bmo Harris Bank, N.a" },
    { aba: "042108122", name: "Citizens Bank Of Jackson" },
    { aba: "092900613", name: "Glacier Bank" },
    { aba: "081507425", name: "Oakstar Bank, Na" },
    { aba: "061121025", name: "Atlantic Capital Bank, Na" },
    { aba: "091310424", name: "Farmers Security Bank" },
    { aba: "081015535", name: "Meramec Valley Bank" },
    { aba: "062205034", name: "Union State Bank" },
    { aba: "056009288", name: "Main Street Bank" },
    { aba: "073900739", name: "Peoples Bank" },
    { aba: "071922421", name: "Fifth Third Bank" },
    { aba: "091008299", name: "Lake Community Bank" },
    { aba: "074986804", name: "Forum Credit Union" },
    { aba: "063100646", name: "Millennium Bank" },
    { aba: "071127093", name: "The Farmers National Bank" },
    { aba: "062003605", name: "Synovus Bank" },
    { aba: "071923747", name: "Bmo Harris Bank,n.a." },
    { aba: "061121009", name: "Georgia's Own Credit Union" },
    { aba: "011775285", name: "Mascoma Savings Bank" },
    { aba: "062206347", name: "Southern States Bank" },
    { aba: "073900742", name: "Cbi Bank & Trust" },
    { aba: "075010193", name: "Bmo Harris Bank, N.a" },
    { aba: "011305260", name: "Inst For Svgs Newburyport" },
    { aba: "061111653", name: "Pinnacle Bank" },
    { aba: "081518113", name: "Peoples Bank Of Moniteau County" },
    { aba: "061207839", name: "Heritage Bank" },
    { aba: "073900755", name: "First National Bank Of Muscatine" },
    { aba: "071127035", name: "State Bank Of Bement" },
    { aba: "041204166", name: "Pnc Bank, Na" },
    { aba: "062206392", name: "First Community Bank" },
    { aba: "071922476", name: "Fifth Third Bank" },
    { aba: "081507470", name: "Bank Of Iberia" },
    { aba: "071116376", name: "Hickory Point Bank & Trust Fsb" },
    { aba: "071127022", name: "State Bank Of Lincoln" },
    { aba: "056009246", name: "Mainstreet Bank" },
    { aba: "071908160", name: "Parkway Bank & Trust" },
    { aba: "062206376", name: "Cheaha Bank" },
    { aba: "061112966", name: "Southcrest Bank" },
    { aba: "056009233", name: "M & T Bank" },
    { aba: "073912756", name: "Danville State Savings Ban" },
    { aba: "083906707", name: "German American Bank" },
    { aba: "091071585", name: "American Investors Bank & Mortgage" },
    { aba: "026004802", name: "Brown Brothers Harriman" },
    { aba: "043316942", name: "United Bank" },
    { aba: "061121096", name: "Colony Bank" },
    { aba: "051501671", name: "Mountain Valley Bank, N.a." },
    { aba: "084304243", name: "Simmons First National Bank" },
    { aba: "065400140", name: "Capital One, N.a." },
    { aba: "067006762", name: "Oneunited Bank" },
    { aba: "065400137", name: "Jpmorgan Chase" },
    { aba: "073910114", name: "Community State Bank" },
    { aba: "031307659", name: "Truist Bank" },
    { aba: "011102502", name: "Union Savings Bank" },
    { aba: "074913851", name: "Horizon Bank, An Indiana Bank" },
    { aba: "011401135", name: "Santander Bank, N.a." },
    { aba: "053285160", name: "St Francis Federal Credit Union" },
    { aba: "026004828", name: "Hsbc Bank Usa" },
    { aba: "071921176", name: "Bmo Harris Bank,n.a." },
    { aba: "063109058", name: "Bank Of America, N.a." },
    { aba: "041002711", name: "Fifth Third Bank" },
    { aba: "061121070", name: "American Pride Bank" },
    { aba: "073922115", name: "Bank Plus" },
    { aba: "074911219", name: "Centrebank" },
    { aba: "061219898", name: "United National Bank" },
    { aba: "084304272", name: "Carroll Bank & Trust" },
    { aba: "011500913", name: "Centreville Bank" },
    { aba: "092105227", name: "Fairview Off-fst Security Bk-west" },
    { aba: "053285115", name: "Peach State Federal Credit Union" },
    { aba: "031307604", name: "Pnc Bank, Na" },
    { aba: "061121054", name: "Renasant Bank" },
    { aba: "061205255", name: "Ameris Bank" },
    { aba: "062206318", name: "Southern States Bank" },
    { aba: "073909183", name: "Elgin State Bank" },
    { aba: "061219830", name: "Ameris Bank" },
    { aba: "067016118", name: "Centennial Bank" },
    { aba: "041000124", name: "Pnc Bank, Ohio" },
    { aba: "026004860", name: "Banco Bradesco S.a." },
    { aba: "061110382", name: "Pinnacle Bank" },
    { aba: "067016105", name: "Centennial Bank" },
    { aba: "074912577", name: "Pnc Bank, Na" },
    { aba: "091909217", name: "First State Bank Of Rosemount" },
    { aba: "091215927", name: "Us Bank Na" },
    { aba: "063100688", name: "Capital City Bank" },
    { aba: "067006775", name: "South State Bank, Na" },
    { aba: "021204416", name: "Amboy Bank" },
    { aba: "063208823", name: "Bank Of Pensacola" },
    { aba: "061121041", name: "Bank Ozk" },
    { aba: "062206305", name: "Trustmark National Bank" },
    { aba: "084304230", name: "The Bank Of Fayette County" },
    { aba: "051501642", name: "United Bank" },
    { aba: "091203942", name: "First Security Bank , Canby" },
    { aba: "026003557", name: "Banco Do Brasil - New York Branch" },
    { aba: "065400153", name: "Hancock Whitney Bank" },
    { aba: "061003787", name: "United Community Bank, Inc" },
    { aba: "026014216", name: "New Bank" },
    { aba: "072407987", name: "Tcf National Bank" },
    { aba: "021300336", name: "M & T Bank" },
    { aba: "051502913", name: "Harrison County Bank" },
    { aba: "065401505", name: "Hancock Whitney Bank" },
    { aba: "031101017", name: "Td Bank, Na" },
    { aba: "091215817", name: "Northern State Bank" },
    { aba: "075900465", name: "Us Bank Na" },
    { aba: "073983125", name: "Aegis Credit Union" },
    { aba: "071105756", name: "1st Trust & Sav Bk" },
    { aba: "082904289", name: "Gateway Bank" },
    { aba: "071104427", name: "Heartland Bank And Trust Co" },
    { aba: "026014245", name: "Global Bank" },
    { aba: "043315561", name: "S & T Bank" },
    { aba: "063206207", name: "Fnbt Bank" },
    { aba: "081518090", name: "Seymour Bank" },
    { aba: "021301678", name: "Hsbc Bank, Usa" },
    { aba: "044208936", name: "Citizens Bank Co." },
    { aba: "042000013", name: "U.s. Bank National Association" },
    { aba: "065402889", name: "South Louisiana Bank" },
    { aba: "022300186", name: "Hsbc Bank, Usa" },
    { aba: "064282615", name: "Ornl Federal Credit Union" },
    { aba: "031307688", name: "Fulton Bank, Na" },
    { aba: "021110966", name: "Bankwell Bank" },
    { aba: "031307691", name: "Jim Thorpe Neighborhood Bank" },
    { aba: "031101059", name: "Bryn Mawr Trust Co" },
    { aba: "074912603", name: "First Financial Bank" },
    { aba: "067016192", name: "Lake Michigan Credit Union" },
    { aba: "091404000", name: "Western Dakota Bank" },
    { aba: "091215888", name: "Unity Bank" },
    { aba: "081905768", name: "The Missouri Bank" },
    { aba: "091201232", name: "First National Bank" },
    { aba: "021302978", name: "M & T Bank" },
    { aba: "042206574", name: "Riverhills Bank, N.a." },
    { aba: "031317021", name: "First National Bank Of Pennsylvania" },
    { aba: "075901723", name: "Associated Bank Na" },
    { aba: "091909178", name: "Deerwood Bank" },
    { aba: "053285173", name: "Mtc Federal Credit Union" },
    { aba: "051404545", name: "Farmers And Miners Bank" },
    { aba: "065402850", name: "Mississippi River Bank" },
    { aba: "041206782", name: "Croghan Colonial Bank" },
    { aba: "071911704", name: "Horizon Bk, An Indiana Bank" },
    { aba: "061110405", name: "Bank Ozk" },
    { aba: "081518045", name: "Security Bank Of Pulaski County" },
    { aba: "091201258", name: "Security Bank Usa" },
    { aba: "091407942", name: "Farmers State Bank Hosmer" },
    { aba: "081220508", name: "1st State Bk" },
    { aba: "091910196", name: "Lake Elmo Bank" },
    { aba: "051404529", name: "Atlantic Union Bank" },
    { aba: "075901710", name: "Bmo Harris Bank, N.a" },
    { aba: "021000322", name: "Bank Of America, N.a." },
    { aba: "081519361", name: "Bank Of Advance" },
    { aba: "031200730", name: "Wells Fargo Bank" },
    { aba: "022300160", name: "Bank Of America, N.a." },
    { aba: "091071491", name: "Great Southern Bank" },
    { aba: "081518016", name: "Senath State Bk" },
    { aba: "082906863", name: "Logan County Bank" },
    { aba: "063015537", name: "Ameris Bank" },
    { aba: "062206295", name: "Cadence Bank, N.a." },
    { aba: "022300173", name: "Jpmorgan Chase" },
    { aba: "061292433", name: "Ameris Bank" },
    { aba: "084101417", name: "Fnb Of Eastern Ark" },
    { aba: "081904468", name: "Jersey State Bank" },
    { aba: "064206031", name: "Peoples Bank Of The South" },
    { aba: "091201287", name: "The Lake Bank" },
    { aba: "053202871", name: "Enterprise Bank Of S C" },
    { aba: "091310369", name: "Harwood State Bank" },
    { aba: "081220537", name: "First Community Bank, Xenia-flora" },
    { aba: "081304615", name: "German American Bank" },
    { aba: "082906892", name: "Bancorpsouth" },
    { aba: "075000721", name: "North Shore Bank, F.s.b." },
    { aba: "073900807", name: "Greenstate Credit Union" },
    { aba: "071104430", name: "Bmo Harris Bank,n.a." },
    { aba: "075000734", name: "Bmo Harris Bank,n.a." },
    { aba: "072404058", name: "Eastern Michigan Bank" },
    { aba: "081518058", name: "American Bank Of Missouri" },
    { aba: "042108151", name: "Forcht Bank, National Association" },
    { aba: "083906684", name: "First Savings Bank" },
    { aba: "071105798", name: "Tnb Bank" },
    { aba: "053908188", name: "South State Bank" },
    { aba: "073912808", name: "Heritage Bank" },
    { aba: "051405803", name: "First Us Bank" },
    { aba: "022302786", name: "Community Bank, Na" },
    { aba: "081220524", name: "First State Bank Of Campbell Hill" },
    { aba: "081219535", name: "Central State Bank" },
    { aba: "073983170", name: "Dupaco Community Credit Union" },
    { aba: "083905342", name: "Lewisburg Banking Co." },
    { aba: "021300381", name: "Key Bank" },
    { aba: "083906668", name: "West Point Bank" },
    { aba: "071117760", name: "First National Bank Of Pana" },
    { aba: "042206503", name: "First National Bank" },
    { aba: "073901929", name: "Peoples State Bank" },
    { aba: "021206948", name: "Brunswick Bk & Tr. Co." },
    { aba: "063113811", name: "South State Bank, Na" },
    { aba: "053010665", name: "Mechanics & Farmers Bank" },
    { aba: "062206473", name: "Peoplestrust Bank" },
    { aba: "081519510", name: "First Midwest Bank Of Poplar Bluff" },
    { aba: "071922557", name: "Republic Bank Of Chicago" },
    { aba: "063113824", name: "Centennial Bank" },
    { aba: "083906888", name: "Magnolia Bank" },
    { aba: "071116295", name: "Morton Community Bank" },
    { aba: "071202756", name: "First Source Bank South Bend" },
    { aba: "062206486", name: "South State Bank, Na" },
    { aba: "031307701", name: "Mauch Chunk Trust Co" },
    { aba: "081519507", name: "First Midwest Bank Of Dexter" },
    { aba: "083905546", name: "Farmers Bank" },
    { aba: "062206457", name: "Traditions Bank" },
    { aba: "074910087", name: "Ossian State Bank" },
    { aba: "092901803", name: "Glacier Bank" },
    { aba: "062206460", name: "Bank Of Walker County" },
    { aba: "062205131", name: "Cadence Bank, N.a." },
    { aba: "011392626", name: "Morton Federal Credit Union" },
    { aba: "073913959", name: "Iowa State Bank" },
    { aba: "073901961", name: "State Savings Bank" },
    { aba: "081518223", name: "Town & Country Bank Midwest" },
    { aba: "011392684", name: "Leominster Credit Union" },
    { aba: "064208518", name: "Millennium Bank" },
    { aba: "073901974", name: "Cherokee State Bank" },
    { aba: "066015084", name: "Apollo Bank" },
    { aba: "084003159", name: "Bank Of Bartlett" },
    { aba: "083905504", name: "Farmers National Bank Of Danville" },
    { aba: "075012683", name: "Bmo Harris Bank, N.a" },
    { aba: "061112843", name: "United Community Bank,inc" },
    { aba: "021408597", name: "People's United Bank, N.a." },
    { aba: "063113808", name: "Seacoast National Bank" },
    { aba: "071921244", name: "Fifth Third Bank" },
    { aba: "056009356", name: "John Marshall Bank" },
    { aba: "082905505", name: "Anstaff Bank." },
    { aba: "074900657", name: "First Merchants Bank" },
    { aba: "081518210", name: "Neighbors Bank" },
    { aba: "073909015", name: "New Albin Savings Bank" },
    { aba: "063111237", name: "Drummond Community Bank" },
    { aba: "061206429", name: "Synovus Bank" },
    { aba: "091900944", name: "Sterling State Bank" },
    { aba: "075912275", name: "Oregon Community Bank & Trust" },
    { aba: "074912645", name: "Community State Bank" },
    { aba: "084306791", name: "Peoples Bk" },
    { aba: "061220751", name: "Synovus Bank" },
    { aba: "073922005", name: "Peoples Bank" },
    { aba: "073909031", name: "The Exchange State Bank" },
    { aba: "073900690", name: "South Ottumwa Savings Bank" },
    { aba: "051500481", name: "United Bank" },
    { aba: "072407929", name: "Thumb Bank & Trust" },
    { aba: "056009314", name: "Eagle Bank" },
    { aba: "071212128", name: "1st Source Bank" },
    { aba: "081001073", name: "Lindell Bank Trust Company" },
    { aba: "026004721", name: "National Bank Of Pakistan" },
    { aba: "021206993", name: "Valley National Bank" },
    { aba: "091501204", name: "Bmo Harris Bank Na" },
    { aba: "074912674", name: "Alliance Bank" },
    { aba: "073910046", name: "Hiawatha Bank & Trust Company" },
    { aba: "053285238", name: "South Carolina Federal Credit Union" },
    { aba: "073922021", name: "Mfrs Bk & Tr Co" },
    { aba: "043314449", name: "S & T Bank" },
    { aba: "062206431", name: "Citizens Bank & Trust" },
    { aba: "021205648", name: "Connectone Bank" },
    { aba: "071093295", name: "South Side Community Fcu" },
    { aba: "063100552", name: "Bank Of America, N.a." },
    { aba: "075912233", name: "Bmo Harris Bank,n.a." },
    { aba: "091071608", name: "Bmo Harris Bank Na" },
    { aba: "067016231", name: "Finemark National Bank & Trust" },
    { aba: "062206444", name: "Hometown Bank" },
    { aba: "075901590", name: "Baraboo State Bank" },
    { aba: "061206458", name: "Bank Of Lenox" },
    { aba: "053285241", name: "Market Usa Fcu" },
    { aba: "074912690", name: "Community State Bk" },
    { aba: "063113879", name: "Drummond Community Bank" },
    { aba: "062206415", name: "Iberiabank" },
    { aba: "091071611", name: "Ameriprise Bank, Fsb" },
    { aba: "075901561", name: "Woodtrust Bank" },
    { aba: "051501778", name: "Union Bank" },
    { aba: "092105337", name: "Garfield County Bank" },
    { aba: "084304117", name: "The Bank Of Milan" },
    { aba: "061206432", name: "Ameris Bank" },
    { aba: "066004367", name: "City National Bank Of Florida" },
    { aba: "051501765", name: "The Pleasants County Bank" },
    { aba: "031305185", name: "1st Liberty B & T Div Of Comm Bk N.a" },
    { aba: "072405206", name: "The Port Austin State Bank" },
    { aba: "073922063", name: "Peoples Savings Bank" },
    { aba: "073910091", name: "First Central State Bank" },
    { aba: "051402071", name: "Atlantic Union Bank" },
    { aba: "031318499", name: "First Keystone Community Bank" },
    { aba: "091911548", name: "First State Bank" },
    { aba: "072406522", name: "Freeland State Bank" },
    { aba: "053106799", name: "First National Bank Of Pennsylvania" },
    { aba: "053201607", name: "Truist Bank" },
    { aba: "082904166", name: "Simmons First Natl Bk" },
    { aba: "091911551", name: "First State Bank And Trust" },
    { aba: "051008828", name: "M & T Bank" },
    { aba: "011500858", name: "Washington Trust Company" },
    { aba: "073922089", name: "Walker State Bank" },
    { aba: "073922092", name: "Nxt Bank" },
    { aba: "075901642", name: "Nicolet National Bank" },
    { aba: "064282563", name: "Bradley Initiative Credit Union" },
    { aba: "065303085", name: "Renasant Bank" },
    { aba: "091215710", name: "United Valley Bank" },
    { aba: "067016257", name: "Achieva Credit Union" },
    { aba: "021300462", name: "Jpmorgan Chase" },
    { aba: "091911577", name: "Citizens St Bk Norwood Young America" },
    { aba: "051501723", name: "Mcnb Bank And Trust Co" },
    { aba: "026014135", name: "Spring Bank" },
    { aba: "091215749", name: "State Bank Of Lake Park" },
    { aba: "065303056", name: "Jefferson Bank" },
    { aba: "065304385", name: "Citizens Bank" },
    { aba: "051404697", name: "Carter Bank & Trust" },
    { aba: "091909275", name: "Citizens Alliance Bank" },
    { aba: "061220654", name: "Flint Community Bank" },
    { aba: "044110147", name: "Vinton County National Bank" },
    { aba: "063111169", name: "Iberiabank" },
    { aba: "065303069", name: "Bankplus" },
    { aba: "075902968", name: "Jackson County Bank" },
    { aba: "051404684", name: "Miners Exchange Bank" },
    { aba: "091405452", name: "Gayville Br Cortrust Bank" },
    { aba: "065304369", name: "Bankfirst Financial Services" },
    { aba: "053201610", name: "Bank Of America, N.a." },
    { aba: "065401411", name: "Mcbank & Trust Co" },
    { aba: "065401408", name: "Plaquemine Bank And Trust" },
    { aba: "021313734", name: "Pathfinder Bank" },
    { aba: "091404136", name: "Richland State Bank" },
    { aba: "061219694", name: "Ab & T" },
    { aba: "072406506", name: "State Savings Bank" },
    { aba: "021300420", name: "Hsbc Bank, Usa" },
    { aba: "043001024", name: "First National Bank Of Pa" },
    { aba: "071923815", name: "Bank Of Montgomery" },
    { aba: "022302689", name: "Community Bank, N.a." },
    { aba: "091071598", name: "First Citizens Bank & Trust Co." },
    { aba: "084101514", name: "Cross County Bank" },
    { aba: "051404642", name: "Bank Of Southside Virginia" },
    { aba: "082906737", name: "Southern Bank" },
    { aba: "072405280", name: "Exchange State Bank" },
    { aba: "061111624", name: "Cb&t Of Middle Ga. A Div Of Syn Bank" },
    { aba: "081518139", name: "Citizens Bk Of Newburg" },
    { aba: "064208589", name: "Firstbank" },
    { aba: "083904181", name: "Edmonton State Bank" },
    { aba: "091214478", name: "F&m Bank Minnesota" },
    { aba: "065304327", name: "Bank Of Franklin" },
    { aba: "091202480", name: "First National Bk Gilbert" },
    { aba: "075000666", name: "First Midwest Bank N A" },
    { aba: "084003191", name: "Renasant Bank" },
    { aba: "026014193", name: "Td Bank Usa, Na" },
    { aba: "053908081", name: "First Horizon Bank" },
    { aba: "071923857", name: "Northwest Bank Of Rockford" },
    { aba: "051404600", name: "First Community Bank, Na" },
    { aba: "081518197", name: "Sterling Bank" },
    { aba: "042108290", name: "Hometown Bank Of Corbin, Inc." },
    { aba: "042108274", name: "City National Bank Of West Virginia" },
    { aba: "064207292", name: "First Horizon Bank" },
    { aba: "021973019", name: "Webster Bank" },
    { aba: "091803818", name: "Union State Bank" },
    { aba: "081518184", name: "Saints Avenue Bank" },
    { aba: "091300887", name: "First State Bk Of Cando" },
    { aba: "042108261", name: "Peoples Bank" },
    { aba: "071923828", name: "Northern Trust Bank/dupage" },
    { aba: "092900862", name: "State Bk & Tr-div Of Bk Of Commerce" },
    { aba: "091802547", name: "Associated Bank, Na" },
    { aba: "083905685", name: "United Citizens Bk & Tr Co" },
    { aba: "061100790", name: "Suntrust Bank, Northwest Ga" },
    { aba: "041001246", name: "Pnc Bank, Na" },
    { aba: "081208685", name: "Kinmundy Bank" },
    { aba: "062206596", name: "River Bank & Trust" },
    { aba: "061112788", name: "Bank Of America, N.a." },
    { aba: "071922670", name: "First Midwest Bank N A" },
    { aba: "042206804", name: "Park National Bank" },
    { aba: "091803889", name: "Citizens State Bank" },
    { aba: "081221646", name: "Farmers Natl Bk" },
    { aba: "064101589", name: "First National Bank Of Middle Tennes" },
    { aba: "063100882", name: "Capital City Bank" },
    { aba: "091310631", name: "Heartland State Bank" },
    { aba: "056073612", name: "Capital One, Na" },
    { aba: "064101576", name: "Us Bank, Na" },
    { aba: "042108342", name: "Forcht Bank, National Association" },
    { aba: "056009039", name: "Atlantic Union Bank" },
    { aba: "092900833", name: "Glacier Bank-first Security Div" },
    { aba: "074909153", name: "Merchants Bank Of Indiana" },
    { aba: "083905656", name: "Hart County Bank And Trust Co." },
    { aba: "062206583", name: "First Southern State Bank" },
    { aba: "071921396", name: "Bmo Harris Bank,n.a." },
    { aba: "063112809", name: "Td Bank Na" },
    { aba: "092900820", name: "Trailwest Bank" },
    { aba: "091310615", name: "American Bank Center" },
    { aba: "073913836", name: "Legacy Bank" },
    { aba: "083904314", name: "Bedford Loan & Deposit Bank" },
    { aba: "066015181", name: "Plus International Bank" },
    { aba: "041205259", name: "Premier Bank" },
    { aba: "081004601", name: "Central Bank Of St. Louis" },
    { aba: "091308300", name: "Grant County State Bank" },
    { aba: "083905630", name: "The Peoples Bank" },
    { aba: "073901851", name: "First National Bank/creston" },
    { aba: "083904301", name: "Limestone Bank, Inc" },
    { aba: "041205246", name: "First Citizens Natl Bank" },
    { aba: "073900522", name: "Midwestone Bk" },
    { aba: "022302935", name: "Five Star Bank" },
    { aba: "081518333", name: "Community Bank Of Memphis" },
    { aba: "063112825", name: "Iberiabank" },
    { aba: "091801234", name: "Northwestern Bank" },
    { aba: "073900535", name: "Dubuque Bank And Trust Co." },
    { aba: "074900783", name: "Us Bank Na" },
    { aba: "074909111", name: "Star Financial Bank" },
    { aba: "071922696", name: "Bmo Harris Bank,n.a." },
    { aba: "081506358", name: "The Bank Of Houston" },
    { aba: "091310602", name: "Farmers & Merchants St Bk Langdon" },
    { aba: "073901877", name: "First National Bank In Fairfield" },
    { aba: "074912755", name: "Fowler State Bank" },
    { aba: "073901880", name: "Ia St Bk & Tr Co" },
    { aba: "062206512", name: "Bryant Bank" },
    { aba: "084304010", name: "Security Bank & Trust Company" },
    { aba: "031307426", name: "Pncbank, Na" },
    { aba: "073000914", name: "Federal Home Loan Bank Des Moines" },
    { aba: "051501451", name: "Citizens Bank Of Weston" },
    { aba: "073900577", name: "Two Rivers Bank & Trust" },
    { aba: "031306100", name: "Pnc Bank, Na" },
    { aba: "074912784", name: "Kentland Bank" },
    { aba: "071093350", name: "Elca Fcu" },
    { aba: "073912552", name: "Farmers Savings Bank" },
    { aba: "074910126", name: "Grant County State Bank" },
    { aba: "026014407", name: "Savoy Bank" },
    { aba: "073900580", name: "Farmers & Merchants Bank & Trust" },
    { aba: "084006729", name: "Trustmark National Bank" },
    { aba: "021902475", name: "Orange Bank & Trust Company" },
    { aba: "084307965", name: "Greenfield Banking Co" },
    { aba: "091909437", name: "St. Clair State Bank" },
    { aba: "062206567", name: "River Bank & Trust" },
    { aba: "021050466", name: "Disbursing Division - New York" },
    { aba: "044111191", name: "Civista Bank" },
    { aba: "064101550", name: "First Horizon Bank" },
    { aba: "051502748", name: "Premier Bank ,inc" },
    { aba: "043303447", name: "Bank Of New York Mellon" },
    { aba: "073911278", name: "Crawford County Trust & Savings Bank" },
    { aba: "026001122", name: "Northern Trust Int'l Banking Corp." },
    { aba: "061207651", name: "Bank Of Dawson" },
    { aba: "011100106", name: "Wells Fargo Bank" },
    { aba: "061220609", name: "Ameris Bank" },
    { aba: "091909466", name: "Sentry Bank" },
    { aba: "062206541", name: "Noblebank & Trust" },
    { aba: "051404260", name: "Truist Bank" },
    { aba: "072406412", name: "Isabella Bank" },
    { aba: "021313886", name: "Greene County Commercial Bank" },
    { aba: "072407741", name: "Bank Michigan" },
    { aba: "061219584", name: "Primesouth Bank" },
    { aba: "064207195", name: "First Horizon Bank" },
    { aba: "082904027", name: "First Security Bank" },
    { aba: "071104647", name: "Tbk Bank, Ssb" },
    { aba: "031101240", name: "First Citizens Community Bank" },
    { aba: "071093305", name: "St. Helena Parish Cu" },
    { aba: "082904030", name: "Centennial Bank" },
    { aba: "021001486", name: "Citibank N.a." },
    { aba: "091908027", name: "Flagship Bank Minnesota" },
    { aba: "051403041", name: "First National Bank" },
    { aba: "026013136", name: "Northfield Bank" },
    { aba: "075912673", name: "Cornerstone Community Bank" },
    { aba: "031306168", name: "First National Bank Of Pennsylvania" },
    { aba: "044112488", name: "The Peoples Bank Of Gambier" },
    { aba: "074912807", name: "Bmo Harris Bank,n.a." },
    { aba: "091407793", name: "American Bank And Trust" },
    { aba: "031054287", name: "Frb Philadelphia-registrations" },
    { aba: "044111188", name: "Wesbanco Bank Inc" },
    { aba: "091214753", name: "Northwoods Bank Of Minn, Fsb" },
    { aba: "082904056", name: "Diamond Bank" },
    { aba: "075000941", name: "Associated Bank N A" },
    { aba: "091202765", name: "Bremer Bank, Na" },
    { aba: "031101279", name: "The Bancorp Bank" },
    { aba: "051405670", name: "Farmers Bank" },
    { aba: "051008996", name: "Atlantic Union Bank" },
    { aba: "026013165", name: "Bank Of Hope" },
    { aba: "075912644", name: "Bmo Harris Bank,n.a." },
    { aba: "021301869", name: "Hsbc Bank, Usa" },
    { aba: "021205499", name: "The Provident Bank" },
    { aba: "053107963", name: "Wells Fargo Bank" },
    { aba: "031308784", name: "Truist Bank" },
    { aba: "075972202", name: "Starion Bank" },
    { aba: "021000128", name: "Jpmorgan Chase" },
    { aba: "082904085", name: "Centennial Bank" },
    { aba: "074912849", name: "First Financial Bank" },
    { aba: "051404325", name: "Pioneer Bank" },
    { aba: "091407751", name: "Cortrust Bank" },
    { aba: "021300556", name: "Key Bank" },
    { aba: "083002177", name: "Commonwealth Bank & Trust Co." },
    { aba: "075912628", name: "Bmo Harris Bank Na" },
    { aba: "042205481", name: "Lcnb National Bank" },
    { aba: "091407764", name: "First Fidelity Bk Burke-platte Off" },
    { aba: "051005012", name: "First Community Bank N A" },
    { aba: "075912615", name: "Wisconsin Bank And Trust" },
    { aba: "061219571", name: "Renasant Bank" },
    { aba: "053201746", name: "Arthur State Bank" },
    { aba: "074912865", name: "Owen County State Bank" },
    { aba: "075901972", name: "The Bank Of Kaukauna" },
    { aba: "053107989", name: "Bank Of America, N.a." },
    { aba: "072405167", name: "Isabella Bank" },
    { aba: "071922609", name: "First Bank Highland Park" },
    { aba: "074909218", name: "Grant County State Bank" },
    { aba: "061112830", name: "F & M Bank" },
    { aba: "064208437", name: "First Horizon Bank" },
    { aba: "071103389", name: "West Central Bank" },
    { aba: "067092556", name: "Ithink Financial Credit Union" },
    { aba: "075901956", name: "Oregon Community Bank & Trust" },
    { aba: "053200446", name: "Bank Of America, N.a." },
    { aba: "091212179", name: "First National Bank" },
    { aba: "042204110", name: "Greenville National Bank" },
    { aba: "091310592", name: "Kirkwood Bank And Trust Company" },
    { aba: "071102076", name: "First Mid Bank & Trust, Na" },
    { aba: "065303124", name: "Pike National Bank" },
    { aba: "064208453", name: "Southern Bank Of Tennessee" },
    { aba: "071923909", name: "Fifth Third Bank" },
    { aba: "042204123", name: "Park National Bank" },
    { aba: "065401712", name: "Capital One, National Association" },
    { aba: "065304424", name: "United Miss Bank" },
    { aba: "031101208", name: "Hsbc Trust Company Delaware N.a." },
    { aba: "091310576", name: "Border Bank" },
    { aba: "081905593", name: "Citizens Bank" },
    { aba: "091803957", name: "First National Bank" },
    { aba: "043000096", name: "Pnc Bank, National Association" },
    { aba: "064208479", name: "First Citizens National Bank" },
    { aba: "071115322", name: "Town & Country Bank" },
    { aba: "051406909", name: "Essex Bank" },
    { aba: "071922638", name: "Bankfinancial, National Association" },
    { aba: "091803960", name: "River Bank" },
    { aba: "031101211", name: "Community Bank Delaware" },
    { aba: "042108397", name: "Forcht Bank, National Association" },
    { aba: "071103376", name: "First Natl Bk-beardstown" },
    { aba: "071923954", name: "Bankfinancial, National Association" },
    { aba: "082905343", name: "Commercial Bk & Tr" },
    { aba: "044101745", name: "The Fahey Banking Co." },
    { aba: "073901903", name: "First Iowa State Bank" },
    { aba: "091310550", name: "American Bank Center" },
    { aba: "071104692", name: "Shelby County State Bank" },
    { aba: "042074544", name: "Peoples Bank" },
    { aba: "064207166", name: "United Cumberland Bank" },
    { aba: "044002051", name: "United Bankers Bank" },
    { aba: "071116677", name: "Williamsville State Bank" },
    { aba: "081209985", name: "Clay County State Bank" },
    { aba: "063112728", name: "First Natl Bk Of Pasco Cty" },
    { aba: "081221549", name: "Citizens National Bank" },
    { aba: "081915075", name: "Old Exchange Natl Bank" },
    { aba: "044002161", name: "Fifth Third Bank" },
    { aba: "091310534", name: "The Union Bank" },
    { aba: "092105159", name: "The State Bank Of Townsend A Div Of" },
    { aba: "092900736", name: "Glacier Bank-first Bank Montana Div" },
    { aba: "071920135", name: "Midland States Bank" },
    { aba: "042108481", name: "Cumberland Valley National Bank" },
    { aba: "083903140", name: "Hancock Bank And Trust Com" },
    { aba: "091800015", name: "Bmo Harris Bank Na" },
    { aba: "011306492", name: "Eastern Bank" },
    { aba: "011100012", name: "Bank Of America N.a." },
    { aba: "091310518", name: "Bank Of Turtle Lake" },
    { aba: "052103065", name: "The Bank Of Delmarva" },
    { aba: "065005435", name: "Hancock Whitney Bank" },
    { aba: "053010445", name: "First Citizens Bank & Trust Company" },
    { aba: "041000014", name: "Federal Reserve Bank" },
    { aba: "062206693", name: "Progress Bank & Trust" },
    { aba: "044207283", name: "Wesbanco Bank Inc" },
    { aba: "071201647", name: "Pnc Bank, Na" },
    { aba: "071922777", name: "1st Amer Bk" },
    { aba: "091310521", name: "Bell Bank" },
    { aba: "031308807", name: "Mid Penn Bank" },
    { aba: "056073502", name: "Capital One, Na" },
    { aba: "091481793", name: "Sioux Empire Federal Credit Union" },
    { aba: "071908490", name: "Heartland Bank & Trust Company" },
    { aba: "081505155", name: "First State Bank & Trust Co" },
    { aba: "091481780", name: "Lakota Fcu" },
    { aba: "083903108", name: "First & Farmers National Bank, Inc" },
    { aba: "073901725", name: "Grinnell State Bank" },
    { aba: "081518469", name: "First Midwest Bank Of Poplar Bluff" },
    { aba: "042108449", name: "1st Trust Bank Inc" },
    { aba: "063100714", name: "Bank Of America, N.a." },
    { aba: "071920164", name: "Marquette Bank" },
    { aba: "064208301", name: "First Horizon Bank" },
    { aba: "056009123", name: "The Freedom Bank Of Virginia" },
    { aba: "084200981", name: "Bank Of Commerce" },
    { aba: "061101980", name: "Bk Of Coweta Div Synovus Bk" },
    { aba: "091800028", name: "National Bank Of Commerce" },
    { aba: "063015566", name: "Synovus Bank" },
    { aba: "065305902", name: "Regions Bank" },
    { aba: "021313925", name: "Pioneer Commercial Bank" },
    { aba: "021384200", name: "Cvph Employees Fcu" },
    { aba: "073913755", name: "Hills Bank And Trust Co" },
    { aba: "011102612", name: "Salisbury Bank & Trust Co" },
    { aba: "075911195", name: "Bmo Harris Bank Na" },
    { aba: "043205079", name: "Wesbanco Bank Wheeling" },
    { aba: "073900438", name: "Regions Bank" },
    { aba: "051501558", name: "Premier Bank, Inc" },
    { aba: "075911182", name: "Tri City National Bank" },
    { aba: "091909518", name: "Concorde Bank" },
    { aba: "043401967", name: "Farmers National Bank Of Emlenton" },
    { aba: "062206648", name: "First Cahawba Bank" },
    { aba: "042108407", name: "Citizens National Bk Of Paintsville" },
    { aba: "073900441", name: "Clinton National Bank" },
    { aba: "051501545", name: "United Bank" },
    { aba: "031101091", name: "Cross Country Bank" },
    { aba: "075083243", name: "Holy Redeemer Community Cu" },
    { aba: "061111394", name: "United Security Bank" },
    { aba: "083905708", name: "Citizens Union Bank" },
    { aba: "056073573", name: "E*trade Securities" },
    { aba: "064102944", name: "First Farmers And Commercial Bank" },
    { aba: "067007949", name: "Bank Of America, N.a." },
    { aba: "074911578", name: "First Farmers Bank & Trust Co." },
    { aba: "021384239", name: "Directors Choice Cu" },
    { aba: "062206622", name: "Southern States Bank" },
    { aba: "065402892", name: "Regions Bank" },
    { aba: "092105104", name: "The Bank Of Baker" },
    { aba: "074911581", name: "Hoosier Heartland State Bank" },
    { aba: "031308810", name: "Truist Bank" },
    { aba: "056073599", name: "Sonabank, N.a." },
    { aba: "062204019", name: "Metro Bank" },
    { aba: "026004970", name: "East West Bank" },
    { aba: "061050057", name: "Fedglobal Dz Bank Ag" },
    { aba: "053285487", name: "Brookland Federal Credit Union" },
    { aba: "075912479", name: "Bankers Bank" },
    { aba: "011102667", name: "Salisbury Bank & Trust Co." },
    { aba: "042000314", name: "Fifth Third Bank" },
    { aba: "062204022", name: "North Ala Bank" },
    { aba: "064101673", name: "Bank Of America" },
    { aba: "011102638", name: "National Iron Bank" },
    { aba: "064102999", name: "Volunteer State Bank" },
    { aba: "063112786", name: "Hancock Whitney Bank" },
    { aba: "064101660", name: "Tower Community Bank" },
    { aba: "062206651", name: "Progress Bank & Trust" },
    { aba: "051502858", name: "Putnam County Bank" },
    { aba: "021206715", name: "Valley National Bank" },
    { aba: "062204006", name: "Cadence Bank, N.a." },
    { aba: "021914544", name: "The Westchester Bank" },
    { aba: "091202736", name: "First Security Bank , Canby" },
    { aba: "051404383", name: "Sonabank" },
    { aba: "061003664", name: "East West Bank" },
    { aba: "031306278", name: "Cnb Bank" },
    { aba: "091910455", name: "Stearns Bank National Assn" },
    { aba: "075900575", name: "Associated Bank Green Bay" },
    { aba: "067016040", name: "Centennial Bank" },
    { aba: "051403164", name: "Atlantic Union Bank" },
    { aba: "091215930", name: "First Independent Bank" },
    { aba: "067016037", name: "Centennial Bank" },
    { aba: "064208398", name: "Peoples Bank Of East Tennessee" },
    { aba: "091911797", name: "Citizens Bank Minnesota" },
    { aba: "051005106", name: "Community Bankers' Bank" },
    { aba: "031306294", name: "Riverview Bank" },
    { aba: "091405258", name: "Dacotah Bank" },
    { aba: "053201814", name: "Regions Bank" },
    { aba: "031101143", name: "Citizens Bank" },
    { aba: "075911205", name: "Bmo Harris Bank Na" },
    { aba: "026014368", name: "Valley National Bank" },
    { aba: "091214627", name: "United Prairie Bank" },
    { aba: "071105866", name: "Rushville State Bank" },
    { aba: "091908179", name: "Harvest Bank" },
    { aba: "043315448", name: "S & T Bank" },
    { aba: "051404464", name: "The First Bank & Trust Company" },
    { aba: "061220489", name: "Ameris Bank" },
    { aba: "065303292", name: "Bank Of Leakesville" },
    { aba: "043314164", name: "First National Bank Of Pennsylanvia" },
    { aba: "031101169", name: "The Bancorp.com" },
    { aba: "091215985", name: "Currie State Bank" },
    { aba: "063206139", name: "Hancock Whitney Bank" },
    { aba: "053201872", name: "The Bank Of South Carolina" },
    { aba: "061220418", name: "First National Bk Of Decatur Cnty" },
    { aba: "065401673", name: "S Lafourche Bk & Tr" },
    { aba: "051403122", name: "National Bank Of Blacksburg" },
    { aba: "021205376", name: "Lakeland Bank" },
    { aba: "084307790", name: "Security Bank" },
    { aba: "026014384", name: "Alma Bank" },
    { aba: "021300640", name: "Hsbc Bank Usa" },
    { aba: "053201885", name: "Td Bank Na" },
    { aba: "091213314", name: "Grand Marais State Bank" },
    { aba: "021408704", name: "Capital One, N.a." },
    { aba: "042000398", name: "Pnc Bank Ach Services" },
    { aba: "073972110", name: "Charter Bank" },
    { aba: "053201856", name: "Dedicated Community Bank" },
    { aba: "031101185", name: "Hsbc Bank, Usa, N.a." },
    { aba: "064102902", name: "Coffee County Bank" },
    { aba: "053201869", name: "South State Bank" },
    { aba: "074912988", name: "Pnc Bank, Na" },
    { aba: "081918946", name: "Bank Of Franklin County" },
    { aba: "061220447", name: "Five Star Credit Union" },
    { aba: "021206676", name: "Santander Bank" },
    { aba: "063110092", name: "Bank Of America, N.a." },
    { aba: "064208327", name: "Commercial Bk" },
    { aba: "051404419", name: "Farmers & Merchants Bank" },
    { aba: "067092679", name: "Simmons Bank" },
    { aba: "084102610", name: "First Financial Bank" },
    { aba: "091310495", name: "Unison Bank" },
    { aba: "051405735", name: "National Bank Of Blacksburg" },
    { aba: "011305202", name: "Millbury National Bank" },
    { aba: "083903098", name: "Bank Of Columbia" },
    { aba: "081506390", name: "Alliant Bank" },
    { aba: "091308164", name: "First State Bank Of Harvey" },
    { aba: "086500634", name: "Central Bank" },
    { aba: "081917662", name: "Farmers State Bank" },
    { aba: "083903085", name: "Citizens Union Bank" },
    { aba: "075972105", name: "Wolf River Community Bank" },
    { aba: "082906517", name: "Bancorp South" },
    { aba: "081518375", name: "Central Bank Of Branson" },
    { aba: "071910721", name: "St Bk Herscher" },
    { aba: "091310482", name: "Dakota Heritage Bank Of North Dakota" },
    { aba: "041206436", name: "Park National Bank" },
    { aba: "071922764", name: "Durand State Bank" },
    { aba: "086500605", name: "Hawthorn Bank" },
    { aba: "072407660", name: "Independent Bk East Michigan" },
    { aba: "082907862", name: "Centennial Bank" },
    { aba: "067092640", name: "Centennial Bank" },
    { aba: "083903069", name: "Citizens Union Bank" },
    { aba: "082907859", name: "Centennial Bank" },
    { aba: "021311383", name: "Bank Of Utica" },
    { aba: "071910776", name: "1st Natl Manhattan" },
    { aba: "084101323", name: "Iberiabank" },
    { aba: "084004284", name: "Iberiabank" },
    { aba: "071105882", name: "Bank Of Farmington" },
    { aba: "083903056", name: "Cecilian Bank" },
    { aba: "071919117", name: "First Midwest Bank Na" },
    { aba: "075972134", name: "Sunset Bank & Savings" },
    { aba: "082907875", name: "First Service Bank" },
    { aba: "082906546", name: "Smackover State Bk" },
    { aba: "091309451", name: "First Security Bank-west" },
    { aba: "073972181", name: "Metabank, National Association" },
    { aba: "091310437", name: "American Bank Center" },
    { aba: "031101114", name: "Thebancorp.com Bank" },
    { aba: "091407803", name: "Cortrust Bank-webster Office" },
    { aba: "075972147", name: "Thrivent Federal Credit Union" },
    { aba: "065305863", name: "First State Bank" },
    { aba: "082907888", name: "Simmons First National Bank" },
    { aba: "091310440", name: "First Western Bank & Trust" },
    { aba: "064206426", name: "Citizens Bk&tr Of Grainger County" },
    { aba: "081219108", name: "Anna State Bank" },
    { aba: "053201034", name: "Arthur State Bank" },
    { aba: "051404930", name: "Carter Bank & Trust" },
    { aba: "011401533", name: "Citizens Bank Na" },
    { aba: "041001013", name: "Peoples Bank" },
    { aba: "031176110", name: "Capital One N.a." },
    { aba: "081017711", name: "Simmons First Natl Bk" },
    { aba: "073922526", name: "Hills Bank Kalona" },
    { aba: "073911870", name: "Ohnward Bank & Trust" },
    { aba: "051404914", name: "First Community Bank" },
    { aba: "064207771", name: "First Horizon Bank" },
    { aba: "062204158", name: "Citizens Bank Of Fayette" },
    { aba: "081518524", name: "Citizens Bank" },
    { aba: "073922513", name: "Decorah Bank & Trust Company" },
    { aba: "063114810", name: "Seacoast National Bank" },
    { aba: "061120152", name: "First Horizon Bank" },
    { aba: "051404901", name: "Citizens & Farmers Bank" },
    { aba: "081506552", name: "Peoples Bank" },
    { aba: "053202350", name: "United Bank" },
    { aba: "067005873", name: "1st Natl Bank South Miami" },
    { aba: "011303084", name: "Ivy Bk,a Div. Of Cambridge Sav Bk" },
    { aba: "073921213", name: "First Trust & Savings Bank" },
    { aba: "061206982", name: "Renasant Bank" },
    { aba: "061120123", name: "Douglas Nat'l Bank" },
    { aba: "081222797", name: "First Southern Bank" },
    { aba: "073922539", name: "Availa Bank" },
    { aba: "073921200", name: "First Iowa State Bank" },
    { aba: "011303097", name: "Northern Bank & Trust Co" },
    { aba: "073908252", name: "State Bank Of Bussey" },
    { aba: "091310864", name: "Bank Forward" },
    { aba: "073909578", name: "Farmers State Bank" },
    { aba: "091058964", name: "Nacs Billing - Ach Receivable" },
    { aba: "043000261", name: "Bank Of New York Mellon" },
    { aba: "091309846", name: "Farmers & Merchants Bank Of Nd" },
    { aba: "061120107", name: "Bank Ozk" },
    { aba: "042101925", name: "Peoples Bank" },
    { aba: "074909357", name: "First Financial Bank" },
    { aba: "071106593", name: "1st Natl Bk Assumption" },
    { aba: "073922555", name: "Premier Bank" },
    { aba: "061107146", name: "Bank Of Hancock County" },
    { aba: "031176136", name: "Ally Bank" },
    { aba: "041001039", name: "Keybank" },
    { aba: "084307761", name: "Apex Bank" },
    { aba: "063114865", name: "Bank Ozk" },
    { aba: "091310819", name: "Security First Bank Of Nd" },
    { aba: "067015232", name: "Israel Discount Bank Of New York,mi" },
    { aba: "055073587", name: "Rosedale Fed. Savings & Loan Assn." },
    { aba: "082001179", name: "Eagle Bank And Trust Co." },
    { aba: "063114878", name: "American Momentum Bank" },
    { aba: "067016558", name: "Seacoast National Bank" },
    { aba: "073922571", name: "Northstar Bank" },
    { aba: "072404508", name: "G.w. Jones Exchange Bk" },
    { aba: "021214273", name: "Cross River Bank" },
    { aba: "055001986", name: "Howard Bank" },
    { aba: "053102117", name: "First National Bank Of Pennsylvania" },
    { aba: "055000657", name: "Pnc Bank - Baltimore" },
    { aba: "075901273", name: "Bmo Harris Bank, N.a" },
    { aba: "062206732", name: "Peoples Bank Of Alabama" },
    { aba: "063113552", name: "South State Bank, Na" },
    { aba: "075902573", name: "Farmers State Bank Of Waupaca" },
    { aba: "073922597", name: "Cedar Rapids Bank & Trust Co." },
    { aba: "081005794", name: "First Bank" },
    { aba: "067015203", name: "First National Bk Of The Gulf Coast" },
    { aba: "031309851", name: "Orrstown Bank" },
    { aba: "091804558", name: "Waumandee State Bank" },
    { aba: "084305129", name: "Renasant Bank" },
    { aba: "063114894", name: "Community Bank Of The South" },
    { aba: "062206745", name: "Premier Bank Of The South" },
    { aba: "091512251", name: "Abby Bank" },
    { aba: "081506523", name: "Bank Of Advance" },
    { aba: "067015274", name: "Sunstate Bank" },
    { aba: "062204132", name: "Heritage Bank" },
    { aba: "091803290", name: "Bank Of The West" },
    { aba: "071993214", name: "Allied First Bank" },
    { aba: "063114836", name: "Cadence Bank, N.a." },
    { aba: "067016590", name: "Centennial Bank" },
    { aba: "071214472", name: "First State Bank Of Middlebury" },
    { aba: "067015258", name: "Centennial Bank" },
    { aba: "064109264", name: "First Community Bank Of Bedford Cnty" },
    { aba: "061204382", name: "Bank Of Edison" },
    { aba: "091201753", name: "Minnwest Bank" },
    { aba: "091803274", name: "Security Financial Bank" },
    { aba: "063113523", name: "First State Bank" },
    { aba: "063015045", name: "South State Bank, Na" },
    { aba: "067016574", name: "Professional Bank" },
    { aba: "083002342", name: "Fifth Third Bank" },
    { aba: "062204129", name: "The Exchange Bk Of Alabama" },
    { aba: "061291531", name: "Flint River Employees Fcu" },
    { aba: "082907781", name: "Stone Bank" },
    { aba: "081903524", name: "First National Bank" },
    { aba: "011305749", name: "Td Bank Na" },
    { aba: "091214960", name: "The American Bank Of The North" },
    { aba: "031319236", name: "First Northern Bank And Trust Co." },
    { aba: "082905136", name: "Community State Bank" },
    { aba: "072404485", name: "Comerica Bank" },
    { aba: "053274090", name: "South State Bank" },
    { aba: "011736020", name: "U.s. Treasury (stored Value Card)" },
    { aba: "081903537", name: "Bank And Trust Company" },
    { aba: "067015287", name: "Sanibel Captiva Community Bank" },
    { aba: "075902625", name: "Bmo Harris Bank,n.a." },
    { aba: "081903540", name: "Litchfield National Bank" },
    { aba: "051500753", name: "Wesbanco Bank, Inc" },
    { aba: "071993162", name: "Abbott Laboratories Empl C.u." },
    { aba: "091404466", name: "1st Financial Bank Usa" },
    { aba: "053274061", name: "First Capital Bank" },
    { aba: "082906494", name: "Simmons First Natl Bk" },
    { aba: "031308535", name: "Honesdale National Bank" },
    { aba: "063114784", name: "Pnc Bank, National Association" },
    { aba: "091403140", name: "First National Bank" },
    { aba: "091213673", name: "Midwest Bank" },
    { aba: "091912330", name: "First Farmers & Merchants Bank" },
    { aba: "063113468", name: "South State Bank, Na" },
    { aba: "044208512", name: "Home National Bank" },
    { aba: "031308548", name: "Wayne Bank" },
    { aba: "075902609", name: "Richland County Bank" },
    { aba: "063114797", name: "Heartland National Bank" },
    { aba: "055073532", name: "M & T Bank" },
    { aba: "064100852", name: "Bank Of America" },
    { aba: "031308551", name: "The Dime Bank" },
    { aba: "065400687", name: "Capital One, N.a." },
    { aba: "091901684", name: "Wnb Financial, N.a." },
    { aba: "075903912", name: "Bank Of New Glarus" },
    { aba: "021214189", name: "Connectone Bank" },
    { aba: "063112142", name: "Florida Capital Bank, N.a." },
    { aba: "091911001", name: "First Community Bank" },
    { aba: "055001876", name: "Pnc Bank, Na" },
    { aba: "051402372", name: "Blue Ridge Bank, Na" },
    { aba: "082905181", name: "Farmers Bank And Trust Company" },
    { aba: "051401043", name: "Movement Bank" },
    { aba: "043002900", name: "Pnc Bank, Na" },
    { aba: "071993159", name: "Services Credit Union" },
    { aba: "021202191", name: "Pnc Bank" },
    { aba: "084101051", name: "Farmers Bank And Trust Co." },
    { aba: "063112155", name: "Synovus Bank" },
    { aba: "021484524", name: "American Broadcast Employees Fcu" },
    { aba: "096000700", name: "Sunrise Banks, National Association" },
    { aba: "091405753", name: "American Bank And Trust" },
    { aba: "065302044", name: "Bankplus" },
    { aba: "071909871", name: "State Bank Of The Lakes, Na" },
    { aba: "051402356", name: "Citizens & Farmers Bank" },
    { aba: "091913685", name: "21st Century Bank" },
    { aba: "051401027", name: "American National Bank & Trust Co" },
    { aba: "071119975", name: "1st State Bank Of Van Orin" },
    { aba: "071107987", name: "State Bank Of Toulon" },
    { aba: "065303386", name: "First Bank" },
    { aba: "064003768", name: "Wells Fargo Bank" },
    { aba: "061120217", name: "Renasant Bank" },
    { aba: "051403672", name: "Trupoint Bank" },
    { aba: "074908112", name: "Hoosier Heartland State Bank" },
    { aba: "061303348", name: "First Volunteer Bank" },
    { aba: "081505184", name: "Northeast Missouri State Bank" },
    { aba: "071106687", name: "Better Banks" },
    { aba: "011304478", name: "Rockland Trust Company" },
    { aba: "082907710", name: "Diamond State Bank" },
    { aba: "031907790", name: "Qnb Bank" },
    { aba: "065303360", name: "The First, A Natl Banking Assn" },
    { aba: "083907560", name: "Bank Of Edmonson County" },
    { aba: "083906228", name: "State Bank Of Medora" },
    { aba: "051404985", name: "Wells Fargo Bank" },
    { aba: "061000227", name: "Wells Fargo Bank" },
    { aba: "071118659", name: "Goodfield State Bank" },
    { aba: "021483282", name: "People's Alliance Fcu" },
    { aba: "021302554", name: "Glens Falls Natl Bk&tr Co" },
    { aba: "071922887", name: "Old 2nd Natl Bk Aurora" },
    { aba: "082907736", name: "Citizens Bank" },
    { aba: "051403643", name: "Carter Bank & Trust" },
    { aba: "071922874", name: "Byline Bank" },
    { aba: "061107201", name: "Synovus Bank" },
    { aba: "073909620", name: "Citizens Savings Bank" },
    { aba: "065304673", name: "Bank Of Wiggins" },
    { aba: "074000515", name: "Pnc Bank, Na" },
    { aba: "082907752", name: "Grand Savings Bank" },
    { aba: "051403630", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "065400603", name: "The First, A National Banking Assn" },
    { aba: "071921532", name: "First State Bank" },
    { aba: "061000256", name: "Wells Fargo Bank" },
    { aba: "073911951", name: "Farmers Savings Bank" },
    { aba: "091307149", name: "Western State Bank" },
    { aba: "065303315", name: "Liberty Bank & Trust Company" },
    { aba: "091308481", name: "First State Bank Golva" },
    { aba: "073000794", name: "Iowa State Bank" },
    { aba: "073922607", name: "Hiawatha Bank & Trust Co" },
    { aba: "075011888", name: "Bmo Harris Bank, N.a" },
    { aba: "011303110", name: "Eastern Bank" },
    { aba: "071921529", name: "Pioneer State Bank" },
    { aba: "065303328", name: "Century Bank" },
    { aba: "021302567", name: "Td Bank Na" },
    { aba: "011701314", name: "Bangor Savings Bank" },
    { aba: "092900985", name: "Citizens Bank & Trust Big Timber" },
    { aba: "081004517", name: "Pnc Bank, Na" },
    { aba: "091310770", name: "First State Bank & Trust" },
    { aba: "043000106", name: "Bank Of New York Mellon" },
    { aba: "043001435", name: "Federal Home Loan Bank Pittsburgh" },
    { aba: "051403711", name: "Sonabank" },
    { aba: "091310783", name: "United Community Bk Of Nd Minot" },
    { aba: "071118471", name: "Farmers-merchants National Bank Of I" },
    { aba: "061206814", name: "First Southern Bank" },
    { aba: "071921697", name: "Bmo Harris Bank,n.a." },
    { aba: "073922403", name: "American State Bank" },
    { aba: "043301504", name: "Mid Penn Bank" },
    { aba: "083907722", name: "Citizens Bank" },
    { aba: "091310754", name: "Bnc National Bank" },
    { aba: "054080160", name: "Phi Beta Sigma Federal Credit Union" },
    { aba: "053105936", name: "Southern Bank & Trust Co" },
    { aba: "043000122", name: "Pnc Bank, Pa" },
    { aba: "092900956", name: "First Community Bank" },
    { aba: "065303522", name: "Bank Of Morton" },
    { aba: "091310767", name: "Starion Bank" },
    { aba: "073922429", name: "Cental Bank" },
    { aba: "067015313", name: "Synovus Bank" },
    { aba: "043314847", name: "S & T Bank" },
    { aba: "061120000", name: "Green Dot Bank" },
    { aba: "071107783", name: "Federated Bank" },
    { aba: "091310741", name: "The Union Bank" },
    { aba: "061121342", name: "First Century Bank, N.a." },
    { aba: "081222658", name: "Southern Bank" },
    { aba: "061107010", name: "Firstbank" },
    { aba: "071105154", name: "The Frederick Community Bank" },
    { aba: "073909468", name: "State Savings Bank" },
    { aba: "061206843", name: "First Bank Of Coastal Georgia" },
    { aba: "074909470", name: "First Financial Bank" },
    { aba: "091310725", name: "Cornerstone Bank" },
    { aba: "091309736", name: "Kodabank" },
    { aba: "073922432", name: "Iowa State Bank" },
    { aba: "073908155", name: "West Iowa Bank" },
    { aba: "091803300", name: "Sterling Bank" },
    { aba: "081505388", name: "Peoples Savings Bank Of Rhineland" },
    { aba: "053201186", name: "United Community Bank" },
    { aba: "074908138", name: "State Bank Of Lizton" },
    { aba: "031309945", name: "Acnb Bank" },
    { aba: "073921132", name: "Community State Bank" },
    { aba: "072478660", name: "Iron Workers Local 25 Cu" },
    { aba: "071214333", name: "Tcf National Bank" },
    { aba: "067014026", name: "Bank Of America, N.a." },
    { aba: "067015355", name: "Banco De Credito Del Peru" },
    { aba: "081004591", name: "Commerce Bank" },
    { aba: "091803355", name: "Alliance Bank - Mondovi Office" },
    { aba: "063114742", name: "Centennial Bank" },
    { aba: "021214163", name: "First Bank" },
    { aba: "073921129", name: "South Ottumwa Savings Bank" },
    { aba: "073922458", name: "Community Bank" },
    { aba: "081003275", name: "Bank Star" },
    { aba: "053274155", name: "Bank Ozk" },
    { aba: "081207068", name: "First National Bank Of Sandoval" },
    { aba: "064109358", name: "Banktennessee" },
    { aba: "071993340", name: "Services Credit Union" },
    { aba: "075903792", name: "Bank First, N.a." },
    { aba: "063101454", name: "Capital City Bank" },
    { aba: "073921158", name: "Earlham Savings Bank" },
    { aba: "063102783", name: "Drummond Community Bank" },
    { aba: "021202162", name: "Bank Of America N.a" },
    { aba: "072405743", name: "State Savings Bank" },
    { aba: "091214915", name: "Northern State Bank" },
    { aba: "075901121", name: "Associated Bank Na" },
    { aba: "065400483", name: "M C Bank & Trust" },
    { aba: "081207097", name: "Peoples Bank & Trust" },
    { aba: "075903789", name: "Badger State Bank" },
    { aba: "067015326", name: "Tiaa Bank" },
    { aba: "073922474", name: "Viking State Bank & Trust" },
    { aba: "067016655", name: "Centennial Bank" },
    { aba: "053274139", name: "South State Bank" },
    { aba: "075901134", name: "Bank First, N.a." },
    { aba: "075902463", name: "Forte Bank" },
    { aba: "081518634", name: "Central Bank Of The Ozarks" },
    { aba: "021902352", name: "Bank Of New York Mellon" },
    { aba: "071993324", name: "Tech Credit Union" },
    { aba: "063114700", name: "Centennial Bank" },
    { aba: "081506646", name: "First Community Bank Of The Ozarks" },
    { aba: "067015397", name: "U.s. Century Bank" },
    { aba: "061120084", name: "First Century Bank, N.a." },
    { aba: "091202943", name: "State Bank Of Wheaton" },
    { aba: "081903715", name: "Duquoin State Bank" },
    { aba: "091201627", name: "Profinium Financial Inc-fairmont Off" },
    { aba: "075903763", name: "Ergo Bank" },
    { aba: "073921161", name: "Security State Bank" },
    { aba: "071117184", name: "Town & Country Bank" },
    { aba: "053274113", name: "South State Bank" },
    { aba: "062205597", name: "Merit Bank" },
    { aba: "081015218", name: "Cnb St. Louis Bank" },
    { aba: "075903750", name: "Horicon Bank" },
    { aba: "031310918", name: "Truist Bank" },
    { aba: "081220074", name: "United Community Bank" },
    { aba: "091103181", name: "Peoples State Bk" },
    { aba: "073921187", name: "Security State Bank" },
    { aba: "081518605", name: "Security Bank Of The Ozarks" },
    { aba: "073921190", name: "Citizens State Bank" },
    { aba: "081220087", name: "First National Bank" },
    { aba: "075902421", name: "Bank Five Nine" },
    { aba: "091201643", name: "Bank Midwest" },
    { aba: "084101129", name: "Cross County Bank" },
    { aba: "063101399", name: "Capital City Bank" },
    { aba: "072405688", name: "Bay Port State Bk" },
    { aba: "011701288", name: "Northway Bank" },
    { aba: "026004307", name: "Mizuho Bank, Ltd.,new York Branch" },
    { aba: "072403046", name: "Choiceone Bank" },
    { aba: "075903831", name: "Premier Community Bank" },
    { aba: "061204191", name: "American Banking Co Dba Ameris Bank" },
    { aba: "011304517", name: "Citizens Bank Na" },
    { aba: "021302648", name: "Tompkins Trust Company" },
    { aba: "026005652", name: "Banco De Chile-n.y. Branch" },
    { aba: "067014071", name: "Amerasia Bank" },
    { aba: "021214095", name: "Lakeland Bank" },
    { aba: "072406975", name: "Choiceone Bank" },
    { aba: "063114661", name: "Cogent Bank" },
    { aba: "062005690", name: "Regions Bank" },
    { aba: "064109329", name: "Firstbank" },
    { aba: "021214082", name: "First Commerce Bank" },
    { aba: "022000842", name: "Jpmorgan Chase" },
    { aba: "026003007", name: "Philippine Nat'l Bk, Ny Branch" },
    { aba: "091212221", name: "Northwestern Bank, N.a." },
    { aba: "091900465", name: "Wells Fargo Bank Na" },
    { aba: "083905083", name: "Peoples Bank" },
    { aba: "011101613", name: "Webster Bank" },
    { aba: "022000839", name: "Key Bank" },
    { aba: "031319317", name: "The Honesdale National Bank" },
    { aba: "072405662", name: "Fifth Third Bank" },
    { aba: "072404333", name: "Oxford Bank" },
    { aba: "065302183", name: "Bank Of Forest" },
    { aba: "072403004", name: "Isabella Bank" },
    { aba: "082905068", name: "Chambers Bank" },
    { aba: "031319320", name: "Bank Of Bird-in-hand" },
    { aba: "021214066", name: "Bcb Community Bank" },
    { aba: "026003023", name: "Safra National Bank Of New York" },
    { aba: "055073448", name: "Sonabank" },
    { aba: "072404320", name: "Union Bank" },
    { aba: "065302196", name: "Community Bank Of Ms" },
    { aba: "031306029", name: "Truist Bank" },
    { aba: "091502973", name: "Nicolet National Bank" },
    { aba: "021214053", name: "The Provident Bank" },
    { aba: "055001766", name: "Pnc Bank Na Inc. - Baltimore" },
    { aba: "051402482", name: "Benchmark Community Bank" },
    { aba: "063114690", name: "Cadence Bank, N.a." },
    { aba: "091403292", name: "Premier Bank" },
    { aba: "051401153", name: "Legacy Bank" },
    { aba: "071920300", name: "Glenview State Bank" },
    { aba: "091911153", name: "Lake Region Bank" },
    { aba: "091912482", name: "Citizens Alliance Bank" },
    { aba: "083906383", name: "First Southern National Bank" },
    { aba: "071922968", name: "Bmo Harris Bank,n.a." },
    { aba: "082908900", name: "Farmers Bnk And Trust" },
    { aba: "044102977", name: "Park National Bank" },
    { aba: "065205031", name: "American Bank And Trust Co" },
    { aba: "064003881", name: "Synovus Bank" },
    { aba: "026004394", name: "Capital One, N.a." },
    { aba: "021300077", name: "Key Bank" },
    { aba: "022000868", name: "Citibank Na" },
    { aba: "065302154", name: "Citizens Bank Of Philadelphia" },
    { aba: "051403779", name: "Chesapeake Bank" },
    { aba: "031918569", name: "Linkbank" },
    { aba: "071119865", name: "United Community Bank" },
    { aba: "061291450", name: "Mercy Fcu" },
    { aba: "022311117", name: "Savannah Bank, N.a." },
    { aba: "083905012", name: "South Central Bank, Inc." },
    { aba: "073911812", name: "Ashton State Bank" },
    { aba: "051403766", name: "Skyline National Bank" },
    { aba: "083907667", name: "Commonwealth Community Bank, Inc." },
    { aba: "091310699", name: "First Security Bank-west" },
    { aba: "021300019", name: "Bank Of America N.a" },
    { aba: "041208803", name: "Baltic State Bank" },
    { aba: "071119807", name: "Villa Grove State Bank" },
    { aba: "064205087", name: "First Horizon Bank" },
    { aba: "073000642", name: "Bankers Trust Company" },
    { aba: "071921668", name: "Forest Park National Bk & Tr Company" },
    { aba: "065303467", name: "Peoples Bank" },
    { aba: "071922984", name: "Byline Bank" },
    { aba: "073909507", name: "Exchange State Bank" },
    { aba: "091310673", name: "Security First Bank Of Nd" },
    { aba: "081211928", name: "First National Bank" },
    { aba: "091308371", name: "Cornerstone Bank" },
    { aba: "031918527", name: "Keybank National Association" },
    { aba: "021203310", name: "Lakeland Bank" },
    { aba: "091804723", name: "Community Bank Of Cameron" },
    { aba: "064109086", name: "First Freedom Bank" },
    { aba: "083905203", name: "Farmers Bank & Trust Company" },
    { aba: "064208848", name: "Heritage Community Bank" },
    { aba: "081518786", name: "Southern Bank" },
    { aba: "061121261", name: "Synovus Bank" },
    { aba: "083002520", name: "First Financial Bank" },
    { aba: "064109073", name: "Fnb Bank" },
    { aba: "041206135", name: "Commercial & Savings Bank" },
    { aba: "091512031", name: "Bmo Harris Bank Na" },
    { aba: "081518773", name: "Alliance Bank" },
    { aba: "091306137", name: "Stock Growers Bank" },
    { aba: "091308795", name: "Citizens State Bank Mohall" },
    { aba: "065302303", name: "Priorityone Bank" },
    { aba: "021373059", name: "Community Bank, Na" },
    { aba: "083002533", name: "Central Bank And Trust Company" },
    { aba: "073900975", name: "Great Western Bank" },
    { aba: "053900377", name: "Bank Of America, N.a." },
    { aba: "022310121", name: "Evans Bank, National Association" },
    { aba: "096000580", name: "Bankcherokee" },
    { aba: "063113727", name: "Bmo Harris Bank, N.a" },
    { aba: "064109057", name: "Heritage Bank & Trust" },
    { aba: "062203078", name: "Merchants Bank Of Alabama" },
    { aba: "042100861", name: "First State Bk Of The Southeast,inc." },
    { aba: "053202596", name: "Palmetto State Bank" },
    { aba: "061121245", name: "First Citizens Bank & Trust Company" },
    { aba: "084201977", name: "Renasant Bank" },
    { aba: "091114112", name: "Gogebic Range Bank" },
    { aba: "071118798", name: "Community Bank" },
    { aba: "081221235", name: "Central State Bank" },
    { aba: "091201915", name: "First Bank Blue Earth" },
    { aba: "081504172", name: "Citizens Bank" },
    { aba: "073922322", name: "Citizens State Bank" },
    { aba: "073911676", name: "Farmers State Bank" },
    { aba: "056009482", name: "Capital One, N.a." },
    { aba: "021409567", name: "Capital One, N.a." },
    { aba: "091306108", name: "Bank Of Hamilton" },
    { aba: "056009479", name: "Chain Bridge Bank, N.a." },
    { aba: "061121203", name: "Renasant Bank" },
    { aba: "073909361", name: "Sloan State Bank" },
    { aba: "064208893", name: "Citizens Bank" },
    { aba: "084008947", name: "Paragon Bank" },
    { aba: "063101700", name: "Capital City Bank" },
    { aba: "071201320", name: "Horizon Bk, An Indiana Bank" },
    { aba: "061110557", name: "Farmers & Merchants Bank" },
    { aba: "041208777", name: "Sutton Bank" },
    { aba: "067005679", name: "Bank Of America, N.a." },
    { aba: "075912165", name: "Bmo Harris Bank Na" },
    { aba: "073908045", name: "Farmers State Bank" },
    { aba: "072405604", name: "Lake-osceola State Bk" },
    { aba: "067016325", name: "First Florida Integrity Bank" },
    { aba: "062205665", name: "Pnc Bank, National Association" },
    { aba: "081222593", name: "Banterra Bank" },
    { aba: "063113772", name: "Td Bank Na" },
    { aba: "073921006", name: "Farmers State Bank" },
    { aba: "067016312", name: "Professional Bank" },
    { aba: "062204349", name: "First Us Bank" },
    { aba: "081018888", name: "Simmons Bank" },
    { aba: "062203010", name: "Peoples Bank Of Alabama" },
    { aba: "026005911", name: "Shanghai Commercial Bank" },
    { aba: "072405620", name: "Sturgis Bank & Trust Company" },
    { aba: "043303887", name: "First Commonwealth Bank" },
    { aba: "073910389", name: "Cbi Bank & Trust" },
    { aba: "073922364", name: "Iowa Trust & Savings Bank" },
    { aba: "063112469", name: "South State Bank, Na" },
    { aba: "053104568", name: "First Bank" },
    { aba: "075901480", name: "Bmo Harris Bank, N.a" },
    { aba: "073922351", name: "Maquoketa State Bank" },
    { aba: "065506332", name: "The Citizens Bank Of Philadelphia" },
    { aba: "067016367", name: "Bankflorida" },
    { aba: "064109044", name: "Reliant Bank" },
    { aba: "062204378", name: "Farmers & Merchants" },
    { aba: "053274223", name: "Pinnacle Bank" },
    { aba: "081018833", name: "Royal Banks Of Missouri" },
    { aba: "064208806", name: "Hometrust Bank" },
    { aba: "051501875", name: "Clear Mountain Bank" },
    { aba: "073921048", name: "Citizens Bank" },
    { aba: "021905977", name: "Key Bank" },
    { aba: "073922377", name: "1st Security State Bank" },
    { aba: "067016354", name: "Valley National Bank" },
    { aba: "075900148", name: "Bmo Harris Bank, Na." },
    { aba: "064109031", name: "Putnam 1st Mercantile Bank" },
    { aba: "026004624", name: "Modern Bank, Na" },
    { aba: "091901969", name: "Americana Community Bank" },
    { aba: "091900630", name: "Bremer Bank, Na" },
    { aba: "067015012", name: "Midwestone Bk" },
    { aba: "011400071", name: "Td Bank Na" },
    { aba: "067016341", name: "Cadence Bank, Na" },
    { aba: "061205475", name: "Capital City Bank" },
    { aba: "051500562", name: "Wesbanco Bank, Inc" },
    { aba: "064109028", name: "First Vision Bank Of Tennessee" },
    { aba: "091901972", name: "First Security Bank Sleepy Eye" },
    { aba: "031308331", name: "Citizens & Northern Bank" },
    { aba: "051006778", name: "Wells Fargo Bank" },
    { aba: "091901862", name: "Citizens Bank & Trust Co" },
    { aba: "091900533", name: "Wells Fargo Natl Bank S Central Na" },
    { aba: "082908874", name: "Iberiabank" },
    { aba: "064002280", name: "Regions Bank" },
    { aba: "043302493", name: "Bank Of New York Mellon" },
    { aba: "091213877", name: "Citizens State Bank Tyler, Inc." },
    { aba: "075900229", name: "Bmo Harris Bank, N.a" },
    { aba: "073921093", name: "Iowa Trust And Savings Bank" },
    { aba: "067016396", name: "Bac Florida Bank" },
    { aba: "091912521", name: "Lake Area Bank" },
    { aba: "042205038", name: "Us Bank Na" },
    { aba: "065400894", name: "Capital One, N.a." },
    { aba: "091900559", name: "The State Bank Of Faribault" },
    { aba: "061000052", name: "Bank Of America, N.a." },
    { aba: "082908890", name: "Anstaff Bank" },
    { aba: "064101903", name: "First Horizon Bank" },
    { aba: "082907561", name: "Merchants & Planters Bk" },
    { aba: "031309686", name: "Mifflinburg Bk & Tr Co" },
    { aba: "082908887", name: "Relyance Bank, National Association" },
    { aba: "082907558", name: "Security Bank" },
    { aba: "067016383", name: "First National Bk Of The Gulf Coast" },
    { aba: "091901891", name: "Center National Bank" },
    { aba: "051500520", name: "Optum Bank, Inc." },
    { aba: "061036000", name: "Treas/irs/eftps Recv" },
    { aba: "051501846", name: "Premier Bank Inc" },
    { aba: "091912547", name: "The First State Bank Of Red Wing" },
    { aba: "071105507", name: "First Community Bank Moultrie County" },
    { aba: "091200592", name: "Security State Bank" },
    { aba: "061204052", name: "Waycross Bank & Trust" },
    { aba: "011692588", name: "Opportunities Credit Union" },
    { aba: "026003353", name: "Sterling National Bank" },
    { aba: "061000078", name: "Bank Of America, N.a." },
    { aba: "067092912", name: "Bankunited" },
    { aba: "063112362", name: "South State Bank, Na" },
    { aba: "075902832", name: "Citizens First Bank" },
    { aba: "081904662", name: "Cnb Bank And Trust Na" },
    { aba: "081018794", name: "Bmo Harris Bank Na" },
    { aba: "091212580", name: "Prairie Sun Bank" },
    { aba: "031308302", name: "Citizens & Northern Bank" },
    { aba: "082906258", name: "First Security Bank" },
    { aba: "051402149", name: "Southern Bank & Trust Company" },
    { aba: "075901516", name: "American Bank Of Beaver Dam" },
    { aba: "026003379", name: "Amalgamated Bank" },
    { aba: "081017478", name: "First National Bank" },
    { aba: "062205636", name: "West Alabama Bank & Trust" },
    { aba: "044102647", name: "Standing Stone National Bank" },
    { aba: "044103976", name: "The Savings Bank" },
    { aba: "061036013", name: "Treas/irs/eftps Orig" },
    { aba: "065205125", name: "Hancock Whitney Bank" },
    { aba: "051501817", name: "Bank Of Mingo" },
    { aba: "044211910", name: "Waterford Commercial & Sav" },
    { aba: "063111059", name: "The Northern Trust Company" },
    { aba: "042205009", name: "Merchants National Bank" },
    { aba: "075902816", name: "State Bank Of Cross Plains" },
    { aba: "063209505", name: "Hancock Whitney Bank" },
    { aba: "021483428", name: "The Heart Center Fcu" },
    { aba: "067015096", name: "Optimumbank" },
    { aba: "051403465", name: "Atlantic Union Bank" },
    { aba: "091912576", name: "Commerce Bank" },
    { aba: "072406865", name: "Honor Bank" },
    { aba: "062205610", name: "Hancock Whitney Bank" },
    { aba: "061204094", name: "1st St Bk Randolph Cty" },
    { aba: "026014041", name: "Valley National Bank" },
    { aba: "031307086", name: "Fidelity Dep Discount Bk" },
    { aba: "011075150", name: "Santander" },
    { aba: "071116224", name: "Savanna-thomson State Bank" },
    { aba: "041208719", name: "The Andover Bank" },
    { aba: "071921749", name: "Busey Bank" },
    { aba: "031319074", name: "Peoples Security Bank And Trust Comp" },
    { aba: "061110654", name: "The Commercial Bank" },
    { aba: "051404752", name: "Bank Of Charlotte County" },
    { aba: "044101305", name: "The Park National Bank" },
    { aba: "041208748", name: "Apple Creek Banking Co" },
    { aba: "071105581", name: "Alliance Community Bank" },
    { aba: "054080212", name: "Georgetown Univ Alumni & Student Fcu" },
    { aba: "083905135", name: "Wesbanco Bank Wheeling" },
    { aba: "082908816", name: "Arvest Bank" },
    { aba: "041208735", name: "Antwerp Exchange Bank Co" },
    { aba: "074909661", name: "The Bippus State Bank" },
    { aba: "071921794", name: "Bmo Harris Bank,n.a." },
    { aba: "065205183", name: "Bk Of Erath Delcambre Br" },
    { aba: "071105536", name: "Farmers & Merchants St Bk" },
    { aba: "082908829", name: "Central Bank" },
    { aba: "071918150", name: "Bmo Harris Bank,n.a." },
    { aba: "091103505", name: "Citizens St Bk Ontonagon" },
    { aba: "082908832", name: "Signature Bank Of Arkansas" },
    { aba: "052202225", name: "Summit Community Bank" },
    { aba: "061000010", name: "Wells Fargo Bank" },
    { aba: "091406820", name: "Minnwest Bank" },
    { aba: "031319045", name: "Hometown Bank Of Pennsyvania" },
    { aba: "074908303", name: "First Merchants Bank" },
    { aba: "071919463", name: "Bmo Harris Bank,n.a." },
    { aba: "091406833", name: "First Savings Bank" },
    { aba: "073000545", name: "Us Bank Na" },
    { aba: "053200019", name: "Wells Fargo Bank" },
    { aba: "031319058", name: "Woodforest National Bk" },
    { aba: "082908858", name: "Legacy National Bank" },
    { aba: "084201896", name: "Bank Of Commerce" },
    { aba: "064208725", name: "Apex Bank" },
    { aba: "065303771", name: "Hancock Whitney Bank" },
    { aba: "031176246", name: "Artisans' Bank" },
    { aba: "083905326", name: "Peoples Bank" },
    { aba: "061121135", name: "Bank Ozk" },
    { aba: "083907984", name: "Citizens Deposit Bk" },
    { aba: "081504266", name: "Tpnb Bank" },
    { aba: "063114917", name: "Florida Business Bank" },
    { aba: "065303784", name: "Great Southern Bank" },
    { aba: "041207341", name: "Farmers & Merch State Bk" },
    { aba: "096000687", name: "Old National Bank" },
    { aba: "073911511", name: "Bridge Community Bank" },
    { aba: "063114920", name: "Cadence Bank, N.a." },
    { aba: "071918286", name: "First National Bank Of Omaha" },
    { aba: "061121119", name: "South State Bank, Na" },
    { aba: "091310990", name: "The Bank Of Tioga" },
    { aba: "061109487", name: "Capital City Bank" },
    { aba: "073912837", name: "Denver Savings Bank" },
    { aba: "021410637", name: "Jpmorgan Chase" },
    { aba: "091804833", name: "Citizens State Bank Of La Crosse" },
    { aba: "083907955", name: "The Peoples Bank" },
    { aba: "061121122", name: "Servisfirst Bank" },
    { aba: "061108161", name: "Farmers State Bank" },
    { aba: "081222438", name: "First Southern Bank" },
    { aba: "064208767", name: "Southeast Bank" },
    { aba: "091803562", name: "Bank Of Alma" },
    { aba: "091310974", name: "Liberty State Bank" },
    { aba: "073900878", name: "Connection Bank" },
    { aba: "091017060", name: "Crown Bank" },
    { aba: "084201841", name: "Renasant Bank" },
    { aba: "061121106", name: "Touchmark National Bank" },
    { aba: "021410695", name: "Capital One, N.a." },
    { aba: "081211795", name: "Bank Of Bluffs" },
    { aba: "073909222", name: "Northeast Security Bank" },
    { aba: "091310987", name: "Cornerstone Bank" },
    { aba: "011775379", name: "Sugar River Bank" },
    { aba: "052203703", name: "Capon Valley Bank" },
    { aba: "041210985", name: "First Financial Bank" },
    { aba: "073900894", name: "State Central Bank" },
    { aba: "084201838", name: "Renesant Bank" },
    { aba: "083907926", name: "The Casey County Bank" },
    { aba: "083907913", name: "Citizens Bank & Trust Co." },
    { aba: "091310961", name: "Bank Of Glen Ullin" },
    { aba: "084201825", name: "Fnb Oxford" },
    { aba: "074910692", name: "Farmers & Merchants Bank" },
    { aba: "084308731", name: "Patriot Bank" },
    { aba: "044210403", name: "Vinton County National Bank" },
    { aba: "071214579", name: "Bank Of America" },
    { aba: "067015119", name: "Iberiabank" },
    { aba: "067016448", name: "American National Bank" },
    { aba: "091213806", name: "State Bank Of Fairmont" },
    { aba: "081018998", name: "Stifel Bank" },
    { aba: "073910237", name: "First National Bank" },
    { aba: "055000770", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "021204597", name: "First Hope Bank" },
    { aba: "096000603", name: "Deerwood Bank" },
    { aba: "062205791", name: "Bank Independent" },
    { aba: "081518812", name: "The Bank Of Missouri" },
    { aba: "073910266", name: "Citizens Savings Bank" },
    { aba: "091310916", name: "State Bank & Trust Of Kenmare" },
    { aba: "062203104", name: "Renasant Bank" },
    { aba: "081018972", name: "Stifel Bank And Trust" },
    { aba: "073911582", name: "Iowa Prairie Bank" },
    { aba: "011400149", name: "St Mary's Bank" },
    { aba: "073922238", name: "South Story Bank & Trust" },
    { aba: "091803591", name: "Bluff View Bank" },
    { aba: "091310929", name: "Citizens State Bank" },
    { aba: "081506837", name: "Simmons Bank" },
    { aba: "063114946", name: "First Bank" },
    { aba: "081018956", name: "New Frontier Bank" },
    { aba: "064109167", name: "Reliant Bank" },
    { aba: "056009505", name: "Fvcbank" },
    { aba: "022072692", name: "Keybank National Association" },
    { aba: "075901341", name: "Waukesha State Bank" },
    { aba: "075902670", name: "Farmers & Merchants Union" },
    { aba: "071908908", name: "Byron Bank" },
    { aba: "081904808", name: "Bank Of America, N.a." },
    { aba: "091213835", name: "Wadena State Bank" },
    { aba: "073922254", name: "Rolling Hills Bank & Trust" },
    { aba: "075901354", name: "Bmo Harris Bank, N.a" },
    { aba: "061121180", name: "Promiseone Bank" },
    { aba: "053020202", name: "Frb Charlotte Cash Department" },
    { aba: "063112304", name: "South State Bank, Na" },
    { aba: "061121151", name: "Bank Ozk" },
    { aba: "061206681", name: "Mount Vernon Bank" },
    { aba: "091911409", name: "Cambridge State Bank" },
    { aba: "021205871", name: "Td Bank Na" },
    { aba: "096000661", name: "Western Bank, Div Of American Ntl Bk" },
    { aba: "075903983", name: "Citizens State Bank Loyal" },
    { aba: "063114975", name: "Fairwinds Credit Union" },
    { aba: "065506222", name: "First Bank And Trust" },
    { aba: "061121164", name: "Pinnacle Bank" },
    { aba: "044112187", name: "Farmers & Merchants Bank" },
    { aba: "072405442", name: "Alden State Bk" },
    { aba: "072406771", name: "First Community Bank" },
    { aba: "075011930", name: "First-citizens Bank & Trust Co" },
    { aba: "062205704", name: "West Alabama Bank & Trust" },
    { aba: "082908751", name: "Southern Bancorp Bank" },
    { aba: "031307125", name: "First Keystone Community Bank" },
    { aba: "091911302", name: "Perennial Bank" },
    { aba: "051500614", name: "First Community Bank, N.a." },
    { aba: "073983235", name: "Community Choice Credit Unioin" },
    { aba: "042015846", name: "Cbank" },
    { aba: "072406768", name: "Tcf National Bank" },
    { aba: "073922296", name: "Community State Bank" },
    { aba: "071908827", name: "First Midwest Bank" },
    { aba: "011304300", name: "Natl Grand Bk Marblehead" },
    { aba: "091911315", name: "Citizens Alliance Bank" },
    { aba: "063112294", name: "Bank Of America, N.a." },
    { aba: "043313259", name: "Mercer County State Bank" },
    { aba: "075902735", name: "Bmo Harris Bank, N.a" },
    { aba: "021914078", name: "Customers Bank" },
    { aba: "061205271", name: "Bank Of Alapaha" },
    { aba: "081916731", name: "Security National Bank" },
    { aba: "072405455", name: "Fifth Third Bank" },
    { aba: "067091719", name: "Fifth Third Bank" },
    { aba: "065400755", name: "Capital One, N.a." },
    { aba: "082908780", name: "Peoples Bank" },
    { aba: "021302884", name: "The Adirondack Trust Company" },
    { aba: "091211112", name: "United Farmers State Bank" },
    { aba: "082908777", name: "Simmons First Natl Bk" },
    { aba: "067015164", name: "Synovus Bank" },
    { aba: "091910002", name: "Perennial Bank" },
    { aba: "062006796", name: "Servisfirst Bank" },
    { aba: "065205219", name: "Cottonport Bank" },
    { aba: "031319139", name: "Centric Bank" },
    { aba: "084104883", name: "Southern Bancorp Bank" },
    { aba: "021314814", name: "Cayuga Lake National Bank" },
    { aba: "063112249", name: "Iberiabank" },
    { aba: "062106036", name: "Bankplus" },
    { aba: "026003243", name: "Td Bank, Na" },
    { aba: "063100277", name: "Bank Of America, N.a." },
    { aba: "091404356", name: "Dakota Prairie Bank" },
    { aba: "071908869", name: "Bmo Harris Bank,n.a." },
    { aba: "082907477", name: "The Farmers & Merchants Bk" },
    { aba: "091910028", name: "Bank Of Zumbrota" },
    { aba: "091911357", name: "Stearns Bank Upsala, Na" },
    { aba: "075902722", name: "Jpmorgan Chase" },
    { aba: "091404369", name: "First National Bank In Philip" },
    { aba: "074910799", name: "First Financial Bank" },
    { aba: "081904798", name: "Bank Of Hillsboro" },
    { aba: "051402259", name: "The Fauquier Bank" },
    { aba: "091501411", name: "Mbank" },
    { aba: "026003269", name: "Bank Of China, New York" },
    { aba: "051403588", name: "The Bank Of Fincastle" },
    { aba: "091909055", name: "Old National Bank" },
    { aba: "051500601", name: "Summit Community Bank" },
    { aba: "082907493", name: "Bank Of Bearden" },
    { aba: "026003272", name: "Keb Hana Bank Usa, N.a." },
    { aba: "091909068", name: "First National Bank" },
    { aba: "062205733", name: "Renasant Bank" },
    { aba: "081017575", name: "St. John's Bank And Trust Company" },
    { aba: "091501408", name: "Bmo Harris Bank Na" },
    { aba: "065205264", name: "Red River Bank" },
    { aba: "044114716", name: "Merchants National Bank" },
    { aba: "072404197", name: "First State Bk Decatur" },
    { aba: "021000678", name: "Bank Of New York Mellon" },
    { aba: "051402233", name: "Citizens Bank And Trust Company" },
    { aba: "091406972", name: "Community Bank" },
    { aba: "091211170", name: "Grand Rapids State Bank" },
    { aba: "036001808", name: "Td Bank, Na" },
    { aba: "081914131", name: "Farmers And Merchants Bk" },
    { aba: "042207735", name: "Fifth Third Bank" },
    { aba: "031319197", name: "First Citizens Community Bank" },
    { aba: "051402220", name: "Atlantic Union Bank" },
    { aba: "091909084", name: "Randall State Bank" },
    { aba: "063209660", name: "Peoples Bank Of Graceville" },
    { aba: "071105471", name: "Tbk Bank, Ssb" },
    { aba: "074908471", name: "First Merchants Bk Of Central Ind Na" },
    { aba: "041210943", name: "Ottoville Bank Company" },
    { aba: "011305684", name: "Rockland Trust Company" },
    { aba: "051005504", name: "Dept Of Defense-overseas Military Bk" },
    { aba: "065301061", name: "Bankplus" },
    { aba: "051402204", name: "Atlantic Union Bank" },
    { aba: "042206422", name: "First National Bank" },
    { aba: "083905258", name: "First Community Bk Of Western Ky" },
    { aba: "074907126", name: "First National Bank" },
    { aba: "061000104", name: "Suntrust" },
    { aba: "021313569", name: "Keybank National Association" },
    { aba: "062006709", name: "Oakworth Capital Bank" },
    { aba: "031319142", name: "The Dime Bank" },
    { aba: "082908706", name: "Petit Jean State Bank" },
    { aba: "031275998", name: "New York Community Bank" },
    { aba: "091406930", name: "Cortrust Bank" },
    { aba: "051403517", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "053201487", name: "First -citizens Bank & Trust Co" },
    { aba: "051404846", name: "Citizens & Farmers Bank" },
    { aba: "067091780", name: "Wells Fargo Bank" },
    { aba: "081902198", name: "Bank Of Washington" },
    { aba: "091018302", name: "Minnesota Bank & Trust" },
    { aba: "071921891", name: "Pnc Bank, Na" },
    { aba: "011306971", name: "Rockland Trust Company" },
    { aba: "091307275", name: "Mcintosh County Bank" },
    { aba: "083907887", name: "Taylor County Bank" },
    { aba: "074908426", name: "The 1st Natl Bk - Monterey" },
    { aba: "061000146", name: "Federal Reserve Bank" },
    { aba: "071920559", name: "Us Bank" },
    { aba: "083905216", name: "Bank Of Jamestown" },
    { aba: "065302358", name: "Citizens Bank" },
    { aba: "067091764", name: "Natbank, N.a." },
    { aba: "086519421", name: "Springfield First Community Bank" },
    { aba: "071006774", name: "Royal Business Bank" },
    { aba: "073921653", name: "First National Bank, Ames" },
    { aba: "056080126", name: "Loudoun Credit Union" },
    { aba: "022309352", name: "Five Star Bank" },
    { aba: "071926809", name: "Woodforest National Bank" },
    { aba: "053203210", name: "First National Bank Of S.c." },
    { aba: "082007791", name: "Iberiabank" },
    { aba: "091205694", name: "Butterfield Off-triumph St Bk" },
    { aba: "073922979", name: "Clear Lake Bank & Trust Co" },
    { aba: "091216340", name: "State Bank Of Bellingham" },
    { aba: "073906034", name: "First Security Bank" },
    { aba: "021984532", name: "Hudson Valley Credit Union" },
    { aba: "081224944", name: "North Adams St Bk Of Ursa" },
    { aba: "073921679", name: "Greenbelt Bank And Trust" },
    { aba: "042202219", name: "Wesbanco Bank Inc" },
    { aba: "021213339", name: "Valley National Bank" },
    { aba: "054001699", name: "Sandy Spring Bank" },
    { aba: "081517693", name: "Us Bank Na" },
    { aba: "075902175", name: "Premier Bank" },
    { aba: "021200025", name: "Wells Fargo Bank" },
    { aba: "021200012", name: "Pnc Bank Newark" },
    { aba: "081517677", name: "United State Bank" },
    { aba: "073921666", name: "Laurens State Bank" },
    { aba: "061101414", name: "Southeast First National Bank" },
    { aba: "064204253", name: "Tnbank" },
    { aba: "053901473", name: "Wells Fargo Bank" },
    { aba: "073906047", name: "Security National Bank" },
    { aba: "075902188", name: "Badger Bank" },
    { aba: "073921695", name: "Decorah Bank & Trust Company" },
    { aba: "054001673", name: "Amalgamated Bank Of New York" },
    { aba: "062102593", name: "Peoples Exchange Bank" },
    { aba: "021213313", name: "First Bank" },
    { aba: "062101264", name: "First Us Bank" },
    { aba: "071902878", name: "Centier Bank" },
    { aba: "081211669", name: "First State Bank Of Beecher City" },
    { aba: "073907389", name: "Leighton State Bank" },
    { aba: "091408394", name: "Sunrise Bank Dakota" },
    { aba: "071925509", name: "United Fidelity Bank, Fsb" },
    { aba: "054001686", name: "City First Bank Of D.c.,n.a" },
    { aba: "073921682", name: "Keystone Savings Bank" },
    { aba: "062101251", name: "Troy Bank & Trust Co." },
    { aba: "084301107", name: "Commercial Bank & Trust Co" },
    { aba: "021213300", name: "Connectone Bank" },
    { aba: "011104322", name: "Webster Bank N.a" },
    { aba: "082007746", name: "The Union Bank" },
    { aba: "081210314", name: "United Community Bank" },
    { aba: "065302507", name: "Bankplus" },
    { aba: "073918077", name: "Security Savings Bank" },
    { aba: "073906092", name: "Iowa Savings Bank" },
    { aba: "091014924", name: "Fidelity Bank Edina" },
    { aba: "061102701", name: "United Community Bank, Inc" },
    { aba: "084302452", name: "Bank Of Halls" },
    { aba: "081503089", name: "Freedom Bank Of Southern Missouri" },
    { aba: "072404993", name: "Mayville State Bank" },
    { aba: "011201490", name: "Camden National Bank" },
    { aba: "074036068", name: "Dssn 5570 Srd1 Weswt1" },
    { aba: "071925554", name: "Illinois Bank & Trust" },
    { aba: "082907383", name: "Simmons First Natl Bk" },
    { aba: "072401006", name: "Pnc Bank, Na" },
    { aba: "092902404", name: "Glacier Bank" },
    { aba: "054001631", name: "Industrial Bank Of Washington" },
    { aba: "071103952", name: "First National Bk & Tr" },
    { aba: "081505634", name: "1st Bank Of The Lake" },
    { aba: "071913566", name: "Holcomb Bank" },
    { aba: "065303810", name: "Richton Bk + Tr Co" },
    { aba: "072414310", name: "Level One Bank" },
    { aba: "091409681", name: "Citibank Na" },
    { aba: "075904775", name: "Royal Bank" },
    { aba: "071924212", name: "Hinsdale Bank & Trust Company" },
    { aba: "075903446", name: "Bank Of Sun Prairie" },
    { aba: "073920395", name: "Bellevue State Bank" },
    { aba: "042107673", name: "The Citizens Bank" },
    { aba: "062101219", name: "Hancock Whitney Bank" },
    { aba: "071103949", name: "State Bank Of Lincoln" },
    { aba: "064108388", name: "Bancorp South" },
    { aba: "075902120", name: "River Bank" },
    { aba: "043000630", name: "First National Bank Of Pa" },
    { aba: "071926867", name: "Old Second Natl Bank" },
    { aba: "071115953", name: "Midamerica National Bank" },
    { aba: "075904762", name: "Bank Of Cashton" },
    { aba: "071974505", name: "United Trust Bank" },
    { aba: "044212922", name: "Citizens Bank Co." },
    { aba: "071126609", name: "Town & Country Bank Of Springfield" },
    { aba: "071925538", name: "Byline Bank" },
    { aba: "042106331", name: "Commercial Bank" },
    { aba: "071101307", name: "Wells Fargo Bk Na Illinois" },
    { aba: "072414336", name: "Choiceone Bank" },
    { aba: "071926870", name: "Byline Bank" },
    { aba: "064107046", name: "Bank Of America" },
    { aba: "074036055", name: "Dssn 5570 Srd1 East 1" },
    { aba: "071926854", name: "Advia Credit Union" },
    { aba: "054001628", name: "United Bank" },
    { aba: "071925525", name: "Park Ridge Community Bank" },
    { aba: "011307077", name: "Eastern Bank" },
    { aba: "026013796", name: "East West Bank" },
    { aba: "082906083", name: "Eagle Bank And Trust Co." },
    { aba: "072402348", name: "Fifth Third Bank" },
    { aba: "042107657", name: "Security Bank & Trust Co." },
    { aba: "071004161", name: "Fifth Third Bank" },
    { aba: "072403677", name: "Commercial Bank" },
    { aba: "071101310", name: "The Farmers And Mechanics Bank" },
    { aba: "061211935", name: "Glennville Bank" },
    { aba: "075902104", name: "Nicolet National Bank" },
    { aba: "091407052", name: "Bryant State Bank" },
    { aba: "065303807", name: "Merchants & Planters Bank" },
    { aba: "072414323", name: "Macatawa Bank" },
    { aba: "074036026", name: "Dssn 5570" },
    { aba: "071925596", name: "Inland Bank And Trust" },
    { aba: "071004158", name: "Byline Bank" },
    { aba: "074000065", name: "Pnc Bank, Indiana" },
    { aba: "021213397", name: "Sterling National Bank" },
    { aba: "061209617", name: "Bank Of Newington" },
    { aba: "026012483", name: "Carver Federal Savings Bank" },
    { aba: "054001602", name: "Sandy Spring Bank" },
    { aba: "074000078", name: "Huntington National Bank" },
    { aba: "072404948", name: "Tcf National Bank" },
    { aba: "091409652", name: "Minnwest Bank" },
    { aba: "074036039", name: "Dssn 5570" },
    { aba: "081210398", name: "Farmers State Bank Of Alto Pass" },
    { aba: "072404977", name: "Tri-county Bank" },
    { aba: "062103864", name: "Midsouth Bank" },
    { aba: "064107091", name: "First Horizon Bank" },
    { aba: "021213371", name: "New Millennium Bank" },
    { aba: "061113415", name: "Truist Bank" },
    { aba: "071925567", name: "Libertyville Bank And Trust Comp, Na" },
    { aba: "021201383", name: "Valley National Bank" },
    { aba: "072403635", name: "Chelsea State Bank" },
    { aba: "071924241", name: "Pan American Bank" },
    { aba: "081913417", name: "Bank Of Calhoun County" },
    { aba: "031310413", name: "Truist Bank" },
    { aba: "074910854", name: "Farmers State Bank" },
    { aba: "041215663", name: "Sutton Bank" },
    { aba: "091409717", name: "Citibank Na" },
    { aba: "044115760", name: "First Financial Bank" },
    { aba: "082908625", name: "Centennial Bank" },
    { aba: "072414271", name: "Old National Bank" },
    { aba: "072402283", name: "Southern Michigan Bank & Trust" },
    { aba: "021383528", name: "Crouse Fcu" },
    { aba: "061211870", name: "South Georgia Banking Comp" },
    { aba: "061210541", name: "Georgia Community Bank" },
    { aba: "071100049", name: "Pnc Bank, Na" },
    { aba: "074908510", name: "The Napoleon State Bank" },
    { aba: "052000773", name: "Wesbanco Bank, Inc." },
    { aba: "091300159", name: "Alerus Financial" },
    { aba: "071974466", name: "Royal Savings Bank" },
    { aba: "091409733", name: "Black Hills Community Bank" },
    { aba: "055080295", name: "Market Usa Federal Credit Union" },
    { aba: "071112040", name: "Campus State Bank" },
    { aba: "031309440", name: "Truist Bank" },
    { aba: "092902352", name: "Glacier Bank-first Security Div" },
    { aba: "081914678", name: "Warren-boynton State Bank" },
    { aba: "091804147", name: "First Bank Of Baldwin" },
    { aba: "074000162", name: "Bmo Harris Bank, N.a" },
    { aba: "072414297", name: "First Financial Bank" },
    { aba: "031918831", name: "First Bank" },
    { aba: "065205329", name: "Community First Bank" },
    { aba: "011201432", name: "People's United Bank, N.a." },
    { aba: "091409746", name: "Republic Bank Of Chicago" },
    { aba: "053107303", name: "Townebank" },
    { aba: "071112037", name: "Camp Grove St Bk" },
    { aba: "091903831", name: "Bank Of Elk River" },
    { aba: "084003900", name: "Cadence Bank, N.a." },
    { aba: "091108539", name: "First National Bank Of St Ignace" },
    { aba: "031918844", name: "Wilmington Savings Fund Society, Fsb" },
    { aba: "021407912", name: "Capital One, N.a." },
    { aba: "071924160", name: "Bank Of Bourbonnais" },
    { aba: "031311771", name: "The Gratz Bank" },
    { aba: "011103093", name: "Td Bank Na" },
    { aba: "081518919", name: "Branson Bank" },
    { aba: "041215621", name: "Signature Bank" },
    { aba: "091903844", name: "First Bank Elk River" },
    { aba: "011201458", name: "Camden National Bank" },
    { aba: "071974408", name: "First Citizens Bank & Trust Co." },
    { aba: "067008582", name: "Bank Of America, N.a." },
    { aba: "072414239", name: "First National Bank Of Michigan" },
    { aba: "042202196", name: "Fifth Third Bank" },
    { aba: "071115966", name: "Morton Community Bank" },
    { aba: "067008579", name: "Bank Of America, N.a." },
    { aba: "081518906", name: "Freedom Bank Of Southern Missouri" },
    { aba: "091903860", name: "Farmers & Merchants State Bank" },
    { aba: "072414226", name: "Bank Of Ann Arbor" },
    { aba: "091915832", name: "Lake Country Community Bank" },
    { aba: "064108346", name: "One Bank Of Tennessee" },
    { aba: "031918828", name: "Meridian Bank" },
    { aba: "071115995", name: "Spring Valley City Bk" },
    { aba: "091902531", name: "First Security Bank" },
    { aba: "091901202", name: "Frandsen Bank & Trust" },
    { aba: "062103903", name: "Trustmark National Bank" },
    { aba: "072414255", name: "Tcf National Bank" },
    { aba: "061209552", name: "Citizens Community Bank" },
    { aba: "071100010", name: "Commerce Bank" },
    { aba: "021310591", name: "Community Bank, Na" },
    { aba: "091915845", name: "Minnwest Bank" },
    { aba: "036002810", name: "United Bank Of Philadelphia" },
    { aba: "091409704", name: "Citibank Na" },
    { aba: "091302788", name: "Union State Bank" },
    { aba: "091901215", name: "Alliance Bank" },
    { aba: "091205607", name: "First Security Bank Sleepy Eye" },
    { aba: "065301197", name: "Bankplus" },
    { aba: "011104351", name: "Liberty Bank" },
    { aba: "083908433", name: "United Citizens Bank Of Southern Ky" },
    { aba: "042215468", name: "Spring Valley Bank" },
    { aba: "091302762", name: "Horizon Financial Bank" },
    { aba: "071211734", name: "1st St Bank Porter" },
    { aba: "073908728", name: "United Bank Of Iowa" },
    { aba: "074908594", name: "Fifth Third Bank" },
    { aba: "011500120", name: "Citizens Bank, National Association" },
    { aba: "091915890", name: "Community Bank Mankato" },
    { aba: "091914561", name: "Security State Bk Of Marine" },
    { aba: "011104335", name: "Patriot Bank, National Association" },
    { aba: "083908420", name: "Wesbanco Bank, Inc." },
    { aba: "053012582", name: "The Park National Bank" },
    { aba: "091206965", name: "First National Bank Coleraine" },
    { aba: "021202609", name: "Bank Of America, N.a." },
    { aba: "073908731", name: "Heritage Bank N A" },
    { aba: "073921718", name: "First State Bank" },
    { aba: "084303749", name: "First Horizon Bank" },
    { aba: "021406667", name: "Bnb Bank" },
    { aba: "073920405", name: "Templeton Savings Bank" },
    { aba: "073919416", name: "Peoples Bank" },
    { aba: "071112066", name: "Heartland Bank And Trust Co" },
    { aba: "073908760", name: "Fairbank State Bank" },
    { aba: "065302484", name: "Covington County Bank" },
    { aba: "065301155", name: "Regions Bank" },
    { aba: "092901078", name: "Glacier Bank- First Bank Montana Div" },
    { aba: "031918899", name: "Mid Penn Bank" },
    { aba: "091302717", name: "Rolette State Bank" },
    { aba: "071974479", name: "Beal Bank" },
    { aba: "031311797", name: "Riverview National Bank" },
    { aba: "091302720", name: "Garrison State Bank And Trust" },
    { aba: "073921721", name: "State Bank Of Wapello" },
    { aba: "091216308", name: "First Security Bank" },
    { aba: "043086968", name: "East End Food Cooperative Fcu" },
    { aba: "073918103", name: "Farmers State Bank" },
    { aba: "091205678", name: "Midwest Bank" },
    { aba: "065302468", name: "Magnolia State Bank" },
    { aba: "031918873", name: "First Resource Bank" },
    { aba: "092902394", name: "Farmers State Bank Victor" },
    { aba: "091216324", name: "Prairie Sun Bank" },
    { aba: "086519311", name: "Simmons First National Bank" },
    { aba: "041215692", name: "Cnb Bank" },
    { aba: "021033205", name: "Freddie Mac" },
    { aba: "073906131", name: "American Bank Na" },
    { aba: "031309495", name: "Muncy Bk & Tr Co" },
    { aba: "011201403", name: "Northeast Bank" },
    { aba: "053900225", name: "Wells Fargo Bank" },
    { aba: "073920418", name: "Bank Iowa" },
    { aba: "073922869", name: "Liberty National Bank" },
    { aba: "022309239", name: "Citibank - New York State" },
    { aba: "073921530", name: "Nsb Bank" },
    { aba: "065300020", name: "Bancorpsouth Bank" },
    { aba: "061102617", name: "United Bank" },
    { aba: "011307129", name: "Leader Bank , N.a." },
    { aba: "021213232", name: "Lakeland Bank" },
    { aba: "084302342", name: "Simmons Bank" },
    { aba: "091205571", name: "Farmers St Bk" },
    { aba: "073000176", name: "Bank Of America N A" },
    { aba: "071004226", name: "Providence Bank And Trust" },
    { aba: "073921527", name: "Iowa Falls State Bank" },
    { aba: "073922856", name: "Bankers Trust Company" },
    { aba: "071926919", name: "Evergreen Bank Group" },
    { aba: "065301362", name: "Merchants & Marine Bank" },
    { aba: "026014902", name: "Metropolitan Commercial Bank" },
    { aba: "042000916", name: "Federal Home Loan Bank" },
    { aba: "063110791", name: "Pnc Bank, National Association" },
    { aba: "073919212", name: "Peoples Bank" },
    { aba: "091204255", name: "First National Bank" },
    { aba: "075903394", name: "Bank Of Wisconsin Dells" },
    { aba: "026002927", name: "Cathay Bank" },
    { aba: "073921556", name: "Manson State Bank" },
    { aba: "073922885", name: "First State Bank" },
    { aba: "071901604", name: "First Midwest Bank N A" },
    { aba: "021213216", name: "Oceanfirst Bank, National Associatio" },
    { aba: "084000084", name: "Regions Bank" },
    { aba: "075902052", name: "Clare Bank" },
    { aba: "042105196", name: "Inez Deposit Bank" },
    { aba: "071006868", name: "Pan American Bank" },
    { aba: "071004200", name: "Us Bank" },
    { aba: "091216243", name: "First Farmers & Merchants State Bank" },
    { aba: "073921543", name: "First State Bank" },
    { aba: "011307116", name: "Peoples United Bank, Na" },
    { aba: "065302675", name: "Bank Of Anguilla" },
    { aba: "061102620", name: "Persons Banking Company" },
    { aba: "084301042", name: "First Citizens National Bk" },
    { aba: "075902065", name: "Bmo Harris Bank,n.a." },
    { aba: "071925635", name: "Byline Bank" },
    { aba: "042204932", name: "Peoples Bank" },
    { aba: "021214529", name: "1st Constitution Bank" },
    { aba: "061103904", name: "Synovus Bank" },
    { aba: "084303639", name: "Decatur Cnty Bk" },
    { aba: "071924306", name: "First Nations Bank" },
    { aba: "051402835", name: "Skyline National Bank" },
    { aba: "084304968", name: "Bank 3" },
    { aba: "026014928", name: "Metropolitan Commercial Bank" },
    { aba: "084302300", name: "Hardin County Bk" },
    { aba: "026002956", name: "Commercebank Ny" },
    { aba: "042105170", name: "Hyden Citizens Bank" },
    { aba: "011104209", name: "Webster Bank" },
    { aba: "084302313", name: "Firstbank" },
    { aba: "051402822", name: "Atlantic Union Bank" },
    { aba: "071913650", name: "Bmo Harris Bank,n.a." },
    { aba: "075902049", name: "Wisconsin Community Bank" },
    { aba: "061092947", name: "Delta Community Credit Union" },
    { aba: "081211520", name: "First Natl Bank" },
    { aba: "061103920", name: "The Peoples Bank Of Georgia" },
    { aba: "022309226", name: "Five Star Bank" },
    { aba: "073921585", name: "First State Bank" },
    { aba: "042105154", name: "Bank Hindman" },
    { aba: "065302633", name: "Renasant Bank" },
    { aba: "082007649", name: "First Arkansas Bank And Trust" },
    { aba: "075905994", name: "The Pineries Bank" },
    { aba: "061112021", name: "Synovus Bank" },
    { aba: "073920285", name: "Bank Iowa" },
    { aba: "072402212", name: "Citizens National Bank" },
    { aba: "071102500", name: "Midwest Bank Of Western Illinois" },
    { aba: "084000026", name: "First Horizon Bank" },
    { aba: "074036181", name: "Ddsn 5570 Dds 2" },
    { aba: "082908599", name: "Equity Bank" },
    { aba: "071925680", name: "Northside Community Bank" },
    { aba: "082008923", name: "Central Bank" },
    { aba: "075917979", name: "Greenwoods State Bank" },
    { aba: "081914814", name: "Bloomsdale Bank" },
    { aba: "081517732", name: "Focus Bank" },
    { aba: "071115801", name: "First State Bank Shannon-polo" },
    { aba: "011201380", name: "Camden National Bank" },
    { aba: "061112034", name: "The Four County Bank" },
    { aba: "082907273", name: "Bank Ozk" },
    { aba: "072404867", name: "Choiceone Bank" },
    { aba: "074036194", name: "Dssn 5570" },
    { aba: "081503102", name: "Central Bank" },
    { aba: "075902007", name: "Fortifi Bank" },
    { aba: "063216543", name: "Bancorpsouth Bank" },
    { aba: "053202091", name: "South State Bank" },
    { aba: "011307187", name: "Northmark Bank" },
    { aba: "071925651", name: "Barrington Bank And Trust" },
    { aba: "031311807", name: "Peoples Security Bank And Trust Comp" },
    { aba: "081517729", name: "United Security Bank" },
    { aba: "075001199", name: "Tri City Natl Bank" },
    { aba: "084003997", name: "Metabank, National Association" },
    { aba: "081505731", name: "Ozark Bank" },
    { aba: "053202062", name: "United Bank" },
    { aba: "061211809", name: "Synovus Bank" },
    { aba: "072404883", name: "Century Bank And Trust" },
    { aba: "026013673", name: "Td Bank Na" },
    { aba: "071004284", name: "Marquette Bank" },
    { aba: "075905965", name: "Peoples State Bank" },
    { aba: "054001741", name: "State Bank Of India (california)" },
    { aba: "072403554", name: "Commercial Bank" },
    { aba: "071924319", name: "Banco Popular" },
    { aba: "065303920", name: "Bankplus" },
    { aba: "064108485", name: "Reliant Bank" },
    { aba: "061101375", name: "Regions Bank" },
    { aba: "072414200", name: "Tcf National Bank" },
    { aba: "071926980", name: "Busey Bank" },
    { aba: "081504415", name: "Town & Country Bank Midwest" },
    { aba: "091407175", name: "American Bank And Trust" },
    { aba: "011307161", name: "Century Bank And Trust Co" },
    { aba: "054001712", name: "United Bank" },
    { aba: "071004271", name: "Devon Bank" },
    { aba: "081914856", name: "Fcb Banks" },
    { aba: "075917937", name: "Nicolet National Bank" },
    { aba: "056008881", name: "Sandy Spring Bank" },
    { aba: "011307174", name: "Rockland Trust Company" },
    { aba: "054001725", name: "Td Bank, Na" },
    { aba: "071923048", name: "First American Bank" },
    { aba: "042107754", name: "Heritage Bank" },
    { aba: "061103975", name: "Queensborough National Bk & Tr" },
    { aba: "036000362", name: "Brown Brothers Harriman" },
    { aba: "075917924", name: "Oak Bank" },
    { aba: "091408446", name: "First Bank & Trust" },
    { aba: "071923035", name: "Com Bank Of Utica/a Part/lasalle Bk" },
    { aba: "081517761", name: "Montgomery Bank" },
    { aba: "075905936", name: "Oregon Community Bank & Trust" },
    { aba: "075917911", name: "North Shore Bank, F.s.b." },
    { aba: "043386914", name: "Consumer 1st Fcu" },
    { aba: "075917908", name: "First Business Bank" },
    { aba: "054001709", name: "Hsbc Bank Usa, Na" },
    { aba: "091409788", name: "Security Savings Bank" },
    { aba: "091050700", name: "Banco De Mexico" },
    { aba: "021309638", name: "Nbt Bank, N.a." },
    { aba: "072404841", name: "Fifth Third Bank" },
    { aba: "011307158", name: "State Street Bank & Trust" },
    { aba: "061101333", name: "United Community Bank, Inc" },
    { aba: "021214574", name: "Regal Bank" },
    { aba: "061102662", name: "Rabun County Bank" },
    { aba: "061103991", name: "Synovus Bank" },
    { aba: "042107738", name: "Farmers State Bank" },
    { aba: "071925693", name: "1st Equity Bank" },
    { aba: "091407133", name: "Farmers State Bank" },
    { aba: "091409791", name: "1st Financial Bank Usa" },
    { aba: "091408462", name: "Dnb National Bank" },
    { aba: "091408501", name: "Us Bank Na" },
    { aba: "056008852", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "043386891", name: "P.a.t.a. F.c.u." },
    { aba: "071974563", name: "Bankfinancial, National Association" },
    { aba: "062102713", name: "Friend Bank" },
    { aba: "072414158", name: "1st State Bank" },
    { aba: "074907317", name: "First Financial Bank" },
    { aba: "021912915", name: "Td Bank Usa, N.a." },
    { aba: "031311878", name: "Gratz National Bank" },
    { aba: "091408514", name: "Bankstar Financial" },
    { aba: "041215553", name: "Buckeye Community Bank" },
    { aba: "031309550", name: "Truist Bank" },
    { aba: "044115870", name: "Union Bank Company" },
    { aba: "071126531", name: "United Community Bank" },
    { aba: "011201306", name: "Camden National Bank" },
    { aba: "056008849", name: "United Bank" },
    { aba: "072402199", name: "Pnc Bank, Na" },
    { aba: "091300036", name: "Bank Of The West" },
    { aba: "091408527", name: "Rivers Edge Bank" },
    { aba: "082907202", name: "Simmons First National Bank" },
    { aba: "061136052", name: "Dssn 5570 Ads" },
    { aba: "072414174", name: "Main Street Bank" },
    { aba: "031310552", name: "Kish Bank" },
    { aba: "021307096", name: "Delaware Natl Bk Of Delhi" },
    { aba: "031201085", name: "The Pennsville Natl Bk" },
    { aba: "061112092", name: "The Peoples Bank" },
    { aba: "092903542", name: "Bank Of Bridger, Na" },
    { aba: "041215537", name: "Wells Fargo Bank, Na" },
    { aba: "062102726", name: "The First, A National Banking Assoc" },
    { aba: "081004070", name: "Busey Bank" },
    { aba: "072414161", name: "Level One Bank" },
    { aba: "065205468", name: "Hancock Whitney Bank" },
    { aba: "031309505", name: "Hamlin Bank & Trust Co" },
    { aba: "091903967", name: "Pine Island Bank" },
    { aba: "091300010", name: "Wells Fargo Bank Na (north Dakota)" },
    { aba: "061208126", name: "Ameris Bank" },
    { aba: "042107806", name: "Monticello Banking Company" },
    { aba: "041215508", name: "Westfield Bank" },
    { aba: "081017342", name: "Simmons First Natl Bk" },
    { aba: "031918938", name: "Truist Bank" },
    { aba: "031310507", name: "Fulton County N/b & Tr Co" },
    { aba: "092101030", name: "Glacier Bank" },
    { aba: "091300023", name: "Us Bank Na" },
    { aba: "072404786", name: "Comerica Bank" },
    { aba: "031918941", name: "Wilmington Savings Fund Society, Fsb" },
    { aba: "044102524", name: "Union Bank Company" },
    { aba: "031310510", name: "Truist Bank" },
    { aba: "021308396", name: "Bank Of Richmondville" },
    { aba: "011200022", name: "Key Bank" },
    { aba: "065205484", name: "First National Bank Of Louisiana" },
    { aba: "082908573", name: "First Community Bank Of Batesville" },
    { aba: "072402157", name: "First Merchants Bank" },
    { aba: "071102568", name: "Busey Bank" },
    { aba: "072414132", name: "Horizon Bk, An Indiana Bank" },
    { aba: "061211728", name: "Georgia Community Bank" },
    { aba: "072403473", name: "Huntington National Bank" },
    { aba: "061113389", name: "Northeast Georgia Bank" },
    { aba: "021307054", name: "National Bank Of Coxsackie" },
    { aba: "091901338", name: "First National Bank Minnesota" },
    { aba: "091901341", name: "Pioneer Bank" },
    { aba: "031918925", name: "Radnor Trust Co" },
    { aba: "064108443", name: "Legends Bank" },
    { aba: "081505702", name: "O Bannon Banking Co" },
    { aba: "041204852", name: "Civista Bank" },
    { aba: "072414129", name: "Northstar Bank" },
    { aba: "044115809", name: "Woodforest National Bank" },
    { aba: "073910923", name: "White State Bank" },
    { aba: "053108580", name: "Wells Fargo Bank" },
    { aba: "021405464", name: "Peoples United Bank, Na" },
    { aba: "091206826", name: "Western Nb Dba Westernbank Cass Lake" },
    { aba: "073922908", name: "Two Rivers Bank & Trust" },
    { aba: "065204184", name: "B1bank" },
    { aba: "052204728", name: "Cnb Bank, Inc." },
    { aba: "061208155", name: "Five Star Credit Union" },
    { aba: "074906059", name: "The Fountain Trust Company" },
    { aba: "021909342", name: "Jeff Bank" },
    { aba: "021983258", name: "O&r Utilities Employees Fcu" },
    { aba: "031904395", name: "Truist Bank" },
    { aba: "065204197", name: "B1bank" },
    { aba: "091901370", name: "Bremer Bank, Na" },
    { aba: "091206842", name: "State Bank Of Chandler" },
    { aba: "073000228", name: "Wells Fargo Bk Na Iowa" },
    { aba: "073922911", name: "Central State Bank" },
    { aba: "083908530", name: "Peoples Bank & Tr Co Of Clinton Cnty" },
    { aba: "084074395", name: "Pinnacle Bank" },
    { aba: "073908621", name: "Keystone Savings Bank" },
    { aba: "031309589", name: "First Citizens Community Bank" },
    { aba: "053109877", name: "First National Bank Of Pennsylvania" },
    { aba: "021909300", name: "Sterling National Bank" },
    { aba: "073921611", name: "United Bank Of Iowa" },
    { aba: "041215582", name: "Premier Bank" },
    { aba: "073922940", name: "First Bank" },
    { aba: "031308250", name: "The Ephrata National Bank" },
    { aba: "021372940", name: "Avidia Bank" },
    { aba: "092902297", name: "Valley Bank Of Kalispell" },
    { aba: "073908634", name: "Chelsea Savings Bank" },
    { aba: "096016794", name: "Associated Bank Minnesota" },
    { aba: "041203594", name: "State Bank & Trust Co." },
    { aba: "074908688", name: "North Salem State Bank" },
    { aba: "011500010", name: "Bank Of America, N.a." },
    { aba: "091900067", name: "Western National Bank" },
    { aba: "072414190", name: "Huron Valley State Bank" },
    { aba: "011104283", name: "Savings Institute" },
    { aba: "091302607", name: "American Bank Center" },
    { aba: "073922966", name: "Farmers Trust And Savings Bank" },
    { aba: "071113298", name: "The Granville Natl Bk" },
    { aba: "042215578", name: "Centerbank" },
    { aba: "073909992", name: "Central State Bank" },
    { aba: "092902271", name: "First State Bank" },
    { aba: "031918996", name: "Oceanfirst Bank" },
    { aba: "091206884", name: "Border Bank" },
    { aba: "063109702", name: "Centennial Bank" },
    { aba: "073921624", name: "Midwestone Bank" },
    { aba: "073922953", name: "City State Bank" },
    { aba: "042215565", name: "Civista Bank" },
    { aba: "065301265", name: "Renasant Bank" },
    { aba: "091900096", name: "Park State Bank" },
    { aba: "073907334", name: "Bridge Community Bank" },
    { aba: "074909988", name: "Friendship State Bank" },
    { aba: "021912928", name: "Catskill Hudson Bank" },
    { aba: "042202264", name: "First Financial Bank" },
    { aba: "043301601", name: "Bank Of New York Mellon" },
    { aba: "073922746", name: "Two Rivers Bank & Trust" },
    { aba: "021203792", name: "Lakeland Bank" },
    { aba: "022083665", name: "Good Neighbors Federal Credit Union" },
    { aba: "071925716", name: "Fifth Third Bank" },
    { aba: "051402518", name: "Bank Of Clarke County" },
    { aba: "026013916", name: "Emigrant Bank" },
    { aba: "071004349", name: "Byline Bank" },
    { aba: "073921404", name: "Guthrie County State Bank" },
    { aba: "061304363", name: "Smartbank" },
    { aba: "021214435", name: "Regal Bank" },
    { aba: "091050807", name: "Banco Nacional De Panama" },
    { aba: "071913731", name: "First Financial Bank" },
    { aba: "091203285", name: "Adrian State Bank" },
    { aba: "084302232", name: "Farmers Merchants Bk" },
    { aba: "053202130", name: "Community First Bank, Inc." },
    { aba: "021911369", name: "Country Bank" },
    { aba: "071003007", name: "First Midwest Bank" },
    { aba: "021310711", name: "First Natl Bank Of Scotia" },
    { aba: "073921433", name: "United Bank Of Iowa" },
    { aba: "073922762", name: "Citizens First Bk" },
    { aba: "061103849", name: "First Peoples Bank" },
    { aba: "021202447", name: "Pnc Bank N.a." },
    { aba: "021502794", name: "Oriental Bank" },
    { aba: "051403818", name: "Powell Valley National Bank" },
    { aba: "073921420", name: "Frontier Bank" },
    { aba: "061102507", name: "Ameris Bank" },
    { aba: "026013958", name: "City National Bank" },
    { aba: "053202114", name: "Bank Of Walterboro" },
    { aba: "022083649", name: "Kaleida Health Fcu" },
    { aba: "071900456", name: "Bmo Harris Bank,n.a." },
    { aba: "063106129", name: "Bank Of America, N.a." },
    { aba: "021911343", name: "Salisbury Bank & Trust Co" },
    { aba: "071925758", name: "Prairie Community Bank" },
    { aba: "091409487", name: "First National Bank Of Omaha" },
    { aba: "075901082", name: "Bmo Harris Bank, N.a" },
    { aba: "073921446", name: "Solon State Bank" },
    { aba: "091408161", name: "Security State Bank" },
    { aba: "063106145", name: "Bank Of America, N.a." },
    { aba: "074906091", name: "Hendricks Cty Bk&tr Co" },
    { aba: "075000103", name: "Us Bank Na" },
    { aba: "063192104", name: "South State Bank, Na" },
    { aba: "091408174", name: "Farmers & Merchants St Bk" },
    { aba: "026013990", name: "Preferred Bank" },
    { aba: "042202426", name: "Pnc Bank, Na" },
    { aba: "064205388", name: "Commercial Bank" },
    { aba: "084303545", name: "Security Bank & Trust Company" },
    { aba: "081224724", name: "Flora Bank & Trust" },
    { aba: "091805609", name: "Park Bank" },
    { aba: "072404757", name: "Mercantile Bank Of Michigan" },
    { aba: "073920162", name: "Security Savings Bank" },
    { aba: "043312344", name: "Nextier Bank, N.a." },
    { aba: "084002325", name: "Bankcorp South" },
    { aba: "084003654", name: "Bancorp South" },
    { aba: "071925787", name: "Crystal Lake Bank And Trust" },
    { aba: "042106580", name: "Peoples Exchange Bank" },
    { aba: "071924458", name: "Banco Popular" },
    { aba: "065082584", name: "Total Community Action Fcu" },
    { aba: "026011358", name: "Valley National Bank" },
    { aba: "062101455", name: "Bank Of Brewton" },
    { aba: "061019975", name: "Georgia Banking Company" },
    { aba: "063013924", name: "Bbva Usa" },
    { aba: "061103894", name: "East West Bank" },
    { aba: "091806996", name: "Dairy State Bank" },
    { aba: "075901037", name: "Farm & Merch Bk & Tr" },
    { aba: "021213180", name: "Kearny Federal Savings Bank" },
    { aba: "071112202", name: "Bank Of Chestnut" },
    { aba: "091409458", name: "Andes State Bank" },
    { aba: "072402115", name: "Fifth Third Bank" },
    { aba: "074971945", name: "First Merchants Bank" },
    { aba: "021213177", name: "New York Community Bank" },
    { aba: "065302701", name: "Bankplus" },
    { aba: "075902337", name: "First Citizens State Bank" },
    { aba: "061102594", name: "First National Community Bank" },
    { aba: "061020977", name: "Iberiabank" },
    { aba: "021214493", name: "Freedom Bank" },
    { aba: "075901011", name: "Stephenson Natl Bank & Trust" },
    { aba: "075902340", name: "Bmo Harris Bank, N.a" },
    { aba: "026010058", name: "Santander Bank" },
    { aba: "043312386", name: "Mars Bank" },
    { aba: "071102827", name: "Prospect Bank" },
    { aba: "081517897", name: "Bank Of America" },
    { aba: "021309735", name: "Solvay Bank" },
    { aba: "061102523", name: "West Central Georgia Bank" },
    { aba: "061103852", name: "South State Bank" },
    { aba: "064207946", name: "Citizens Bank" },
    { aba: "031201467", name: "Wells Fargo Bank" },
    { aba: "071004378", name: "First Midwest Bank" },
    { aba: "091805683", name: "Independence State Bank" },
    { aba: "075903637", name: "Midwestone Bk" },
    { aba: "091108746", name: "First National Bank" },
    { aba: "081903867", name: "The Bank Of Missouri" },
    { aba: "026009085", name: "Mega International Commercial Bk" },
    { aba: "061102552", name: "First Century Bank, Na" },
    { aba: "071004365", name: "Tcf National Bank Illinois" },
    { aba: "081517871", name: "Bank New Cambria" },
    { aba: "021911398", name: "Mahopac Bank" },
    { aba: "075904953", name: "State Bank Of Cross Plains" },
    { aba: "043312373", name: "Marion Center Bank" },
    { aba: "022083694", name: "Town Of Cheektowaga Fcu" },
    { aba: "061102549", name: "South State Bank" },
    { aba: "061103878", name: "Bank Ozk" },
    { aba: "042107851", name: "Cumberland Valley National Bank" },
    { aba: "075902308", name: "National Exchange Bank & Trust" },
    { aba: "091311096", name: "Security First Bank Of Nd" },
    { aba: "091106146", name: "Northern Interstate Bank" },
    { aba: "021383751", name: "Albany Firemens Fcu" },
    { aba: "031312835", name: "Northumberland Natl Bank" },
    { aba: "071112260", name: "The Frederick Community Bank" },
    { aba: "071174512", name: "Axos Bank" },
    { aba: "065205549", name: "Lakeside Bank" },
    { aba: "044115511", name: "Umb, Na" },
    { aba: "072414022", name: "Mercantile Bank Of Michigan" },
    { aba: "044103536", name: "Civista Bank" },
    { aba: "072402076", name: "Citizens Bank Na" },
    { aba: "092901256", name: "First Security Bank Of Roundup" },
    { aba: "072414051", name: "Mbank" },
    { aba: "061211647", name: "Capital City Bank" },
    { aba: "042311650", name: "St. Henry Bank" },
    { aba: "091914710", name: "Heritage Bank N A" },
    { aba: "091311041", name: "Strasburg State Bank" },
    { aba: "041203455", name: "Civista Bank" },
    { aba: "074907414", name: "Peoples State Bank" },
    { aba: "091409500", name: "Winner Br-first Fidelity, Burke" },
    { aba: "031310206", name: "Pennian Bank" },
    { aba: "061212989", name: "Wilcox County State Bank" },
    { aba: "062102849", name: "First National Bank Of Dozier" },
    { aba: "071125587", name: "Busey Bank" },
    { aba: "065204249", name: "Merchants & Farmers Bank" },
    { aba: "041204726", name: "First National Bank" },
    { aba: "061113279", name: "Wells Fargo Bank" },
    { aba: "021307164", name: "First Natl Bk Of Dryden" },
    { aba: "067011993", name: "Citibank /florida/ Br" },
    { aba: "061209332", name: "Synovus Bank" },
    { aba: "064108113", name: "First Farmers & Merchants Bk" },
    { aba: "011200365", name: "Bank Of America, N.a." },
    { aba: "092901227", name: "American Bank Of Montana" },
    { aba: "064000059", name: "U.s. Bank National Association" },
    { aba: "061113282", name: "Oconee State Bank" },
    { aba: "081006340", name: "Lindell Bank & Trust" },
    { aba: "022383598", name: "Local #41 Ibew Federal Credit Union" },
    { aba: "091900106", name: "North Shore Bank Of Commerce" },
    { aba: "083907382", name: "First United Bank Of Hopkins Co, Inc" },
    { aba: "091311038", name: "Bremer Bank, Na" },
    { aba: "031300821", name: "Bank Of New York Mellon" },
    { aba: "091900119", name: "Park State Bank" },
    { aba: "072402034", name: "Fifth Third Bank" },
    { aba: "067011977", name: "American National Bank" },
    { aba: "092901214", name: "Rocky Mountain Bank" },
    { aba: "091300353", name: "Ramsey National Bank ." },
    { aba: "022383585", name: "Greater Chautauqua Fcu" },
    { aba: "031300834", name: "M & T Bank" },
    { aba: "021213096", name: "Cathay Bank" },
    { aba: "086507174", name: "Office Of The Missouri State Treasur" },
    { aba: "091311009", name: "Peoples State Bank" },
    { aba: "044103523", name: "Park National Bank" },
    { aba: "072414019", name: "Credit Union One" },
    { aba: "061020948", name: "Atlantic Capital Bank, Na" },
    { aba: "067009646", name: "Iberiabank" },
    { aba: "061736009", name: "Treas/irs/insolvency" },
    { aba: "086519159", name: "Oakstar Bank, Na" },
    { aba: "041204739", name: "The Croghan Colonial Bank" },
    { aba: "072403350", name: "The State Bank" },
    { aba: "082907134", name: "The Malvern Natl Bank" },
    { aba: "071100269", name: "Jpmorgan Chase" },
    { aba: "086507187", name: "The Bank Of Missouri" },
    { aba: "091902793", name: "Granite Falls Bank" },
    { aba: "061101294", name: "Pinnacle Bank" },
    { aba: "072414006", name: "Tcf National Bank" },
    { aba: "041203400", name: "Cnb Bank" },
    { aba: "091913452", name: "Minnesota National Bank" },
    { aba: "091505242", name: "Great North Bank" },
    { aba: "055080431", name: "Northwest Federal Credit Union" },
    { aba: "043205875", name: "Citizens Natl Bk Of Woodsfield" },
    { aba: "011202907", name: "Camden National Bank" },
    { aba: "091204527", name: "Citizens St Bk Roseau" },
    { aba: "064000020", name: "Bank Of America, N.a." },
    { aba: "021502804", name: "Oriental Bank" },
    { aba: "084100793", name: "First Natl Bank" },
    { aba: "064204075", name: "First Community Bank Of East Tn" },
    { aba: "091901477", name: "Merchants Bank, Na" },
    { aba: "096017719", name: "Frandsen Bank & Trust" },
    { aba: "064000017", name: "Regions Bank" },
    { aba: "051402589", name: "First Bank" },
    { aba: "091205869", name: "State Bank Of Ceylon" },
    { aba: "081913158", name: "Germantown Trust & Savings" },
    { aba: "031311593", name: "Fleetwood Bank" },
    { aba: "011202910", name: "Td Bank Usa, Na" },
    { aba: "091302979", name: "Choice Financial Group" },
    { aba: "073922801", name: "Cedar Rapids Bank & Trust Company" },
    { aba: "064000046", name: "Suntrust" },
    { aba: "091301640", name: "United Valley Bank" },
    { aba: "083907324", name: "German American Bank" },
    { aba: "011202923", name: "Damariscotta Bank & Trust Company" },
    { aba: "041215498", name: "Portage Community Bank" },
    { aba: "011202936", name: "The First, National Association" },
    { aba: "031312877", name: "Peoples Sercurity Bank And Trust Com" },
    { aba: "031310219", name: "The Juniata Valley Bank" },
    { aba: "053202208", name: "First Community Bank" },
    { aba: "091204569", name: "First National Bank" },
    { aba: "073908511", name: "State Bank Of Schaller" },
    { aba: "051402550", name: "Bank Of Botetourt" },
    { aba: "091302966", name: "Choice Financial Group" },
    { aba: "051401218", name: "Atlantic Union Bank" },
    { aba: "064003962", name: "Regions Bank" },
    { aba: "072414064", name: "Northstar Bank" },
    { aba: "091900193", name: "Merchants Bank Na" },
    { aba: "073921514", name: "Us Bank Na" },
    { aba: "073922843", name: "United Bank & Trust Na" },
    { aba: "081006395", name: "State Bank Of Waterloo" },
    { aba: "092901298", name: "First Security Bank Deer Lodge" },
    { aba: "073907208", name: "Farmers & Traders Savings" },
    { aba: "096017780", name: "Gateway Bank" },
    { aba: "031309259", name: "Orrstown Bank" },
    { aba: "063108732", name: "Crews Bank & Trust" },
    { aba: "063292855", name: "One Florida Bank" },
    { aba: "052073519", name: "Cfg Community Bank" },
    { aba: "072414093", name: "Bank Of Ann Arbor" },
    { aba: "051403850", name: "Virginia Commonwealth Bank" },
    { aba: "083908608", name: "Monticello Banking Company" },
    { aba: "011501035", name: "Bank Of America N.a." },
    { aba: "021201011", name: "Wells Fargo Bank" },
    { aba: "091216476", name: "Prinsbank" },
    { aba: "073922623", name: "Axos Bank" },
    { aba: "021214325", name: "Bank Of Hope" },
    { aba: "073908317", name: "Fidelity Bank And Trust" },
    { aba: "081225901", name: "Southern Illinois Bank" },
    { aba: "081212600", name: "Community First Bk Of The Heartland" },
    { aba: "054073630", name: "Urban Trust Bank" },
    { aba: "051403957", name: "Carter Bank & Trust" },
    { aba: "084302119", name: "Bank Of Camden" },
    { aba: "071925826", name: "First Trust Bank Of Illinois" },
    { aba: "065300279", name: "Trustmark National Bank" },
    { aba: "021214312", name: "Peapack-gladstone Bank" },
    { aba: "075000019", name: "Jpmorgan Chase" },
    { aba: "071208297", name: "First State Bk Middlebury" },
    { aba: "021202337", name: "Jpmorgan Chase" },
    { aba: "091215163", name: "Woodland Bank" },
    { aba: "075000022", name: "Us Bank Na" },
    { aba: "063107393", name: "Bank Of America, N.a." },
    { aba: "084302122", name: "Trustmark National Bank" },
    { aba: "026001847", name: "Banco Bilbao Vizcaya Argentaria Sa" },
    { aba: "071004446", name: "Albany Bank & Tr Co N. A." },
    { aba: "091216489", name: "The Gary State Bank" },
    { aba: "021202311", name: "Pnc Bank N.a." },
    { aba: "091203191", name: "F & M Community Bank, N.a." },
    { aba: "051403931", name: "Touchstone Bank" },
    { aba: "084304793", name: "Centennial Bank" },
    { aba: "081514052", name: "Peoples Community Bank" },
    { aba: "063106006", name: "Bank Of America, N.a." },
    { aba: "091408268", name: "Dakota Prairie Bank" },
    { aba: "066007681", name: "Bank Of America N.a." },
    { aba: "071925871", name: "Byline Bank" },
    { aba: "022310422", name: "Citibank-new York State" },
    { aba: "071004433", name: "Cibc Bank Usa" },
    { aba: "031203915", name: "First Natl Bank" },
    { aba: "071923213", name: "Cibc Bank Usa" },
    { aba: "081211300", name: "First National Bank" },
    { aba: "091804406", name: "State Bank Of Arcadia" },
    { aba: "073908359", name: "Security State Bank" },
    { aba: "053202282", name: "First-citizens Bank & Trust Co" },
    { aba: "075902272", name: "Bank First, N.a." },
    { aba: "073919005", name: "Farmers Savings Bank" },
    { aba: "071004420", name: "Banco Popular" },
    { aba: "026013851", name: "Bank Of East Asia, Limited" },
    { aba: "073921323", name: "United Community Bank" },
    { aba: "073922652", name: "Premier Bank" },
    { aba: "051403915", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "065302895", name: "Bankplus" },
    { aba: "065301566", name: "Community Bank Of Ms" },
    { aba: "071925855", name: "Associated Bank Illinois" },
    { aba: "081212613", name: "1st State Bk" },
    { aba: "063108680", name: "The Bank Of Tampa" },
    { aba: "073922681", name: "Peoples Savings Bank" },
    { aba: "073922678", name: "Community Bank Of Oelwein" },
    { aba: "065301540", name: "Community Bank Of Ms" },
    { aba: "065300211", name: "Citizens National Bank" },
    { aba: "064206594", name: "Regions Bank" },
    { aba: "053202279", name: "South State Bank" },
    { aba: "075001322", name: "Bmo Harris Bank, N.a" },
    { aba: "082008732", name: "Centennial Bank" },
    { aba: "071923255", name: "Fifth Third Bank" },
    { aba: "071924584", name: "First National Bank Of Omaha" },
    { aba: "081517981", name: "Great Southern Bank" },
    { aba: "071911270", name: "Oxford Bank & Trust" },
    { aba: "071126706", name: "Bankchampaign Na" },
    { aba: "062101581", name: "First Bank Of Linden" },
    { aba: "053000219", name: "Wells Fargo Bank" },
    { aba: "061101126", name: "Ameris Bank" },
    { aba: "092976799", name: "Altana Federal Credit Union" },
    { aba: "081502006", name: "Mercantile Bk Of Louisiana" },
    { aba: "084302180", name: "Mckenzie Banking Co" },
    { aba: "063216307", name: "Community Bank Of Ms" },
    { aba: "053000206", name: "Federal Reserve Bank" },
    { aba: "091408239", name: "American Bank And Trust" },
    { aba: "061101113", name: "First State Bank" },
    { aba: "021214396", name: "Provident Bank" },
    { aba: "061102442", name: "Bank Of Madison" },
    { aba: "091409571", name: "Citibank Na" },
    { aba: "081502019", name: "Bank Of Louisiana" },
    { aba: "091408242", name: "Montrose Off-security Bk Alexandria" },
    { aba: "072404650", name: "Mercantile Bank Of Michigan" },
    { aba: "081517965", name: "Bk Of New Madrid" },
    { aba: "071900595", name: "Bmo Harris Bank,n.a." },
    { aba: "056007387", name: "Bank Of America, N.a." },
    { aba: "021213054", name: "Kearny Federal Savings Bank" },
    { aba: "075904872", name: "Royal Bank Cobb" },
    { aba: "071923226", name: "Jpmorgan Chase" },
    { aba: "071925884", name: "Peoples Bank" },
    { aba: "061113101", name: "Bank Of Dade" },
    { aba: "031201360", name: "Td Bank, Na" },
    { aba: "081503322", name: "Great Southern Bank" },
    { aba: "053901198", name: "Nbsc A Div Synovus Bk" },
    { aba: "075902227", name: "Port Washington State Bank" },
    { aba: "021213041", name: "Lakeland Bank" },
    { aba: "081504651", name: "Simmons First National Bank" },
    { aba: "064108249", name: "First Horizon Bank" },
    { aba: "066006349", name: "Iberiabank" },
    { aba: "081225972", name: "Homebank" },
    { aba: "031203999", name: "Pnc Bank" },
    { aba: "065082490", name: "Hope Federal Credit Union" },
    { aba: "075903530", name: "Associated Bank Na" },
    { aba: "091409513", name: "Cortrust Bank-letcher Branch" },
    { aba: "081503380", name: "Citizens Bank Of Eldon" },
    { aba: "031976268", name: "Univest Bank & Trust Co" },
    { aba: "091409526", name: "Western Dakota Bank" },
    { aba: "044102362", name: "Park National Bank" },
    { aba: "075000051", name: "Bmo Harris Bank, N.a" },
    { aba: "075904856", name: "Cleveland State Bank" },
    { aba: "061103739", name: "Citizens Bk Tr Inc" },
    { aba: "075903527", name: "Bmo Harris Bank,n.a." },
    { aba: "084001148", name: "Fidelity National Bank" },
    { aba: "061102400", name: "Ameris Bank" },
    { aba: "052000618", name: "Pnc Bank Na, Baltimore" },
    { aba: "091408200", name: "First Fidelity Bk Gregory" },
    { aba: "081514010", name: "Legends Bank" },
    { aba: "063205664", name: "First City Bank" },
    { aba: "061102439", name: "Ameris Bank" },
    { aba: "061101100", name: "Fnb Bank" },
    { aba: "071923284", name: "Bank Of America, N.a." },
    { aba: "065204443", name: "Rayne State Bank & Trust" },
    { aba: "071027106", name: "Bank Of China, Chicago Branch" },
    { aba: "021203682", name: "Valley Natl Bank" },
    { aba: "071911296", name: "Algonquin State Bank N A" },
    { aba: "081504693", name: "First Midwest Bank Of The Ozarks" },
    { aba: "052000634", name: "M & T Bank" },
    { aba: "031201328", name: "Td Bank, Na" },
    { aba: "052001963", name: "Wells Fargo Bank" },
    { aba: "011910697", name: "Berkshire Bank" },
    { aba: "065203004", name: "Hancock Whitney Bank" },
    { aba: "071113476", name: "North Central Bank" },
    { aba: "081517907", name: "First State Bank And Trust" },
    { aba: "092902488", name: "Stockmens Bank" },
    { aba: "011201539", name: "Bank Of America N.a." },
    { aba: "031309314", name: "First National Bank Of Pennsylvania" },
    { aba: "067008414", name: "Regions Bank" },
    { aba: "031311632", name: "First Citizens Community Bank" },
    { aba: "075904937", name: "Bmo Harris Bank,n.a." },
    { aba: "061101197", name: "Citizens Bank Of The South" },
    { aba: "091914820", name: "First National Bank Of Moose Lake" },
    { aba: "074906211", name: "The Farmers And Merchants Bank" },
    { aba: "091409610", name: "Security Natl Bank Of South Dakota" },
    { aba: "091109994", name: "First Bank Upper Michigan" },
    { aba: "061212853", name: "The Security State Bank" },
    { aba: "081308705", name: "First Savings Bank" },
    { aba: "042309222", name: "Peoples Bank Co." },
    { aba: "053111920", name: "First National Bank Of Pennsylvania" },
    { aba: "091300285", name: "Bank Of North Dakota" },
    { aba: "061212866", name: "Fnb South" },
    { aba: "071923190", name: "Fifth Third Bank" },
    { aba: "081516610", name: "The Bank Of Missouri" },
    { aba: "071112105", name: "Catlin Bank" },
    { aba: "071113434", name: "Hartsburg St Bnk" },
    { aba: "091300256", name: "Bremer Bank, Na" },
    { aba: "071114763", name: "Prairie State Bank And Trust" },
    { aba: "092901117", name: "First Madison Valley Bank" },
    { aba: "026084246", name: "First Entertainment Credit Union" },
    { aba: "092902446", name: "Stockman Bank Of Montana" },
    { aba: "091301585", name: "Aspire Financial" },
    { aba: "071174431", name: "Us Bank, Na" },
    { aba: "091900216", name: "Wnb Financial, N.a." },
    { aba: "071100117", name: "Busey Bank" },
    { aba: "064000185", name: "Citizens Bank" },
    { aba: "067007130", name: "Bank Of America, N.a." },
    { aba: "064108236", name: "Bank Of Dickson" },
    { aba: "072403211", name: "Mercantile Bank Of Michigan" },
    { aba: "041205929", name: "Liberty Natl Bank Of Ada" },
    { aba: "031312929", name: "First Northern Bank And Trust Co" },
    { aba: "092901104", name: "The State Bank Of Townsend A Div Of" },
    { aba: "091901558", name: "Roundbank" },
    { aba: "081517936", name: "Homebank" },
    { aba: "081505964", name: "Bank Of Billings" },
    { aba: "061101155", name: "Wells Fargo Bank" },
    { aba: "091901561", name: "First National Bank" },
    { aba: "067009785", name: "Centennial Bank" },
    { aba: "041205958", name: "The Hicksville Bank" },
    { aba: "081006434", name: "Rockwood Bank" },
    { aba: "065204391", name: "Washington State" },
    { aba: "031976200", name: "Bryn Mawr Trust Co" },
    { aba: "061212811", name: "Sunmark Community Bank" },
    { aba: "071126764", name: "Peoples Bank & Trust" },
    { aba: "062102933", name: "Bancorpsouth Bank" },
    { aba: "061101184", name: "1st Natl Bk Of Griffin" },
    { aba: "081517910", name: "First Security State Bk" },
    { aba: "043000847", name: "Standard Bank, Pasb" },
    { aba: "061212824", name: "Pineland Bank" },
    { aba: "082907008", name: "Chambers Bank" },
    { aba: "061101171", name: "Ameris Bank" },
    { aba: "091302872", name: "Dakota Heritage Bank" },
    { aba: "031309385", name: "First National Bank Of Pennsylvania" },
    { aba: "091205733", name: "Produce State Bank" },
    { aba: "091204404", name: "First State Bank Minnesota" },
    { aba: "053111991", name: "First Charter Bank" },
    { aba: "022083830", name: "Kaleida Health Fcu" },
    { aba: "053111988", name: "First National Bank Of Pennsylvania" },
    { aba: "011600868", name: "Community National Bank" },
    { aba: "026084262", name: "Brooklyn Cooperative Fcu" },
    { aba: "011103129", name: "Jewett City Savings Bank" },
    { aba: "067007156", name: "Citibank /florida/br" },
    { aba: "044210063", name: "Kingston National Bank" },
    { aba: "091900287", name: "Bremer Bank, Na" },
    { aba: "091216405", name: "First Southeast Bank" },
    { aba: "073909714", name: "Libertyville Savings Bank" },
    { aba: "055080318", name: "Korean Catholic Fcu" },
    { aba: "091203104", name: "Root River State Bank" },
    { aba: "073922704", name: "Freedom Financial Bank" },
    { aba: "064000101", name: "Federal Reserve Bank" },
    { aba: "031309343", name: "The Juniata Valley Bank" },
    { aba: "053202321", name: "Pinnacle Bank" },
    { aba: "021502723", name: "Banco Santander De Pr" },
    { aba: "063109922", name: "Synovus Bank" },
    { aba: "071112176", name: "State Bank Of Cherry" },
    { aba: "071901837", name: "Midland States Bank" },
    { aba: "091205788", name: "Farmers Trust And Savings Bank" },
    { aba: "051401331", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "092901191", name: "Glacier Bank-western Security Div" },
    { aba: "031311674", name: "S & T Bank" },
    { aba: "021502736", name: "Oriental Bank And Trust" },
    { aba: "011201500", name: "People's United Bank, N.a." },
    { aba: "063109935", name: "Fifth Third Bank" },
    { aba: "055080376", name: "None Suffer Lack Fcu" },
    { aba: "073910745", name: "Union State Bank" },
    { aba: "053202305", name: "First National Bank Of Pennsylvania" },
    { aba: "053012265", name: "Pinnacle Bank" },
    { aba: "061210237", name: "State Bank Of Cochran" },
    { aba: "041203374", name: "Park National Bank" },
    { aba: "091204462", name: "First Southeast Bank" },
    { aba: "021502752", name: "Oriental Bank And Trust" },
    { aba: "053110633", name: "First Horizon Bank" },
    { aba: "053202318", name: "Conway National Bank" },
    { aba: "092901175", name: "Glacier Bank" },
    { aba: "096015232", name: "Old National Bank" },
    { aba: "011201526", name: "People's United Bank, N.a." },
    { aba: "072401404", name: "Fifth Third Bank" },
    { aba: "063109430", name: "Wells Fargo Bank" },
    { aba: "042205708", name: "Lcnb National Bank" },
    { aba: "053206738", name: "The Bank Of Clarendon" },
    { aba: "071900663", name: "First Midwest Bank Illinois" },
    { aba: "075904377", name: "The Portage County Bank" },
    { aba: "073905187", name: "Visionbank Of Iowa" },
    { aba: "043311662", name: "1st United Natl Bk" },
    { aba: "075904380", name: "International Bank Of Amherst" },
    { aba: "062000116", name: "Bancorpsouth Bank" },
    { aba: "084303338", name: "Brighton Bank" },
    { aba: "051400549", name: "Wells Fargo Bank" },
    { aba: "063111787", name: "South State Bank, Na" },
    { aba: "071925923", name: "First Midwest Bank" },
    { aba: "042108588", name: "Peoples Bank" },
    { aba: "061112539", name: "Synovus Bank" },
    { aba: "073917175", name: "Melvin Savings Bank" },
    { aba: "084100638", name: "Southern Bancorp Bank" },
    { aba: "071923336", name: "First Bank And Trust Company Of Il" },
    { aba: "081200528", name: "State Street Bank & Trust Co" },
    { aba: "075903019", name: "First National Bk At Darlington" },
    { aba: "011102133", name: "Hometown Bank" },
    { aba: "071925981", name: "Evergreen Bank Group" },
    { aba: "075905664", name: "Hustisford State Bank" },
    { aba: "071924652", name: "Inland Bank And Trust" },
    { aba: "071923323", name: "Fifth Third Bank" },
    { aba: "084206176", name: "Planters Bank And Trust Company" },
    { aba: "071909046", name: "Blackhawk Bank" },
    { aba: "065301689", name: "First State Bank" },
    { aba: "071923310", name: "Fifth Third Bank" },
    { aba: "084100654", name: "Partners Bank" },
    { aba: "021502105", name: "Banco Popular National Association" },
    { aba: "021912410", name: "Sterling National Bank" },
    { aba: "042108559", name: "First National Bank Of Lexington" },
    { aba: "065301650", name: "Bankplus" },
    { aba: "051401849", name: "Bank Of Marion" },
    { aba: "071925965", name: "First Midwest Bank" },
    { aba: "081213832", name: "Anna-jonesboro National Bank" },
    { aba: "075906977", name: "First Natl Bank" },
    { aba: "071923307", name: "Midland States Bank" },
    { aba: "051401836", name: "First Citizens Bank & Trust Company" },
    { aba: "084206150", name: "Oxford University Bank" },
    { aba: "053206741", name: "Anderson Brothers Bank" },
    { aba: "084303309", name: "Carroll Bank & Trust" },
    { aba: "051400507", name: "Bank Of America, N.a." },
    { aba: "081307382", name: "Elberfeld State Bank" },
    { aba: "072402704", name: "Fifth Third Bank" },
    { aba: "026002626", name: "Hsbc Bank, Usa" },
    { aba: "081200531", name: "United Community Bank" },
    { aba: "071923378", name: "First Midwest Bank" },
    { aba: "092901560", name: "Trailwest Bank" },
    { aba: "065302963", name: "Commercial Bank" },
    { aba: "074908921", name: "Lafayette Bank & Trust" },
    { aba: "075906951", name: "Bmo Harris Bank, N.a" },
    { aba: "075905622", name: "Horicon Bank" },
    { aba: "092901544", name: "American Bank Center" },
    { aba: "042108520", name: "Kentucky Farmers Bank Corporation" },
    { aba: "026014627", name: "Itau Corpbanca, New York Branch" },
    { aba: "065301647", name: "Riverhills Bank" },
    { aba: "072413450", name: "Capitol National Bank" },
    { aba: "071923352", name: "First Natl Bank Brookfield" },
    { aba: "065204511", name: "Cottonport Bank" },
    { aba: "075906948", name: "Tsb Bank" },
    { aba: "081925816", name: "First Community Bank" },
    { aba: "042108517", name: "Bank Of Lexington" },
    { aba: "081913860", name: "Bank Of Kampsville" },
    { aba: "071911364", name: "The First National Bank In Amboy" },
    { aba: "042000424", name: "Pnc Bank, Na" },
    { aba: "081200586", name: "First Bankers Trust Co, Natl Assn" },
    { aba: "071922010", name: "Midland States Bank" },
    { aba: "053206783", name: "Arthur State Bank" },
    { aba: "021502150", name: "Bnc International Banking Corp" },
    { aba: "071923349", name: "West Suburban Bank" },
    { aba: "071924681", name: "First Secure Bank And Trust Co" },
    { aba: "081200573", name: "Town & Country Bank Of Quincy" },
    { aba: "071909062", name: "Fifth Third Bank" },
    { aba: "061111255", name: "First American Bank & Trust Co" },
    { aba: "021384365", name: "Fasny Fcu" },
    { aba: "072413421", name: "West Shore Bank" },
    { aba: "091408792", name: "Ipswich State Bk" },
    { aba: "071922094", name: "Solutions Bank" },
    { aba: "061112597", name: "F&m Bk And Trust Company" },
    { aba: "056005253", name: "United Bank" },
    { aba: "063206663", name: "Regions Bank" },
    { aba: "083908077", name: "Armed Forces Bank" },
    { aba: "065301605", name: "Bankplus" },
    { aba: "011402024", name: "Eastern Bank" },
    { aba: "036002425", name: "Hsbc Bank Usa-phila" },
    { aba: "065203224", name: "Hancock Whitney Bank" },
    { aba: "081515462", name: "Southern Bank" },
    { aba: "081212574", name: "Dieterich Bank, National Association" },
    { aba: "071909091", name: "Merchants And Manufacturers Bank" },
    { aba: "051485551", name: "Crch Employees Federal Credit Union" },
    { aba: "026013356", name: "Metropolitan Commercial Bank" },
    { aba: "011402008", name: "Key Bank" },
    { aba: "072402788", name: "Fifth Third Bank" },
    { aba: "081503490", name: "Umb, Na" },
    { aba: "031204710", name: "Truist Bank" },
    { aba: "083908048", name: "Franklin Bank & Trust Comp" },
    { aba: "031902038", name: "Santander Bank" },
    { aba: "064108799", name: "First Commerce Bank" },
    { aba: "071115526", name: "Rochester State Bank" },
    { aba: "091106379", name: "Gogebic Range Bank" },
    { aba: "091408873", name: "Bonesteel Off-1st Fidelity Bk Burke" },
    { aba: "042215060", name: "Huntington National Bank" },
    { aba: "064108786", name: "Bank Of Lincoln County" },
    { aba: "061220353", name: "Community Bank Of Georgia" },
    { aba: "054000807", name: "Wells Fargo Bank" },
    { aba: "065204472", name: "B1bank" },
    { aba: "065203143", name: "Hancock Whitney Bank" },
    { aba: "074001019", name: "Federal Home Loan Bk" },
    { aba: "064204703", name: "Simply Bank" },
    { aba: "052001633", name: "Bank Of America, N.a." },
    { aba: "011200585", name: "Damariscotta Bk&tr Co" },
    { aba: "091408899", name: "Cortrust Bank National Association" },
    { aba: "092901489", name: "First Citizens Bank" },
    { aba: "071102238", name: "Midamerica National Bank" },
    { aba: "092901492", name: "Bank Of Bridger, Na" },
    { aba: "067008993", name: "South State Bank, Na" },
    { aba: "092901476", name: "Glacier Bank" },
    { aba: "062091895", name: "Nrs Community Development Cr Un" },
    { aba: "081019405", name: "Parkside Financial Bank And Trust" },
    { aba: "074907634", name: "Star Financial Bank" },
    { aba: "071006282", name: "Bank Of Hope" },
    { aba: "061210981", name: "Colony Bank" },
    { aba: "071103509", name: "First Natl Bank Pana" },
    { aba: "071116813", name: "Bank Of Yates City" },
    { aba: "026012098", name: "Industrial Bank Of Korea Ny Branch" },
    { aba: "091806378", name: "Midwestone Bk" },
    { aba: "091806381", name: "Citizens Community Federal, Na" },
    { aba: "073907855", name: "First National Bank" },
    { aba: "074908934", name: "Tri-county Bk & Tr Co" },
    { aba: "091204750", name: "1st Natl Bk" },
    { aba: "073920845", name: "Great Western Bank" },
    { aba: "064108715", name: "Pinnacle National Bank" },
    { aba: "062000080", name: "Wells Fargo Bank" },
    { aba: "061101922", name: "Southcrest Bank" },
    { aba: "091903404", name: "Minnstar Bank Na" },
    { aba: "042203001", name: "Park National Bank" },
    { aba: "073907868", name: "Savings Bank" },
    { aba: "071126269", name: "Great Southern Bank" },
    { aba: "066010490", name: "Banco Do Brasil - Miami" },
    { aba: "091204763", name: "First National Bank" },
    { aba: "073920832", name: "County Bank" },
    { aba: "067092310", name: "Busey Bank, N.a." },
    { aba: "091916721", name: "Minnwest Bank" },
    { aba: "026073008", name: "Community Federal Savings Bank" },
    { aba: "011201830", name: "First National Bank" },
    { aba: "063000047", name: "Bank Of America, N.a." },
    { aba: "091301899", name: "Peoples State Bank" },
    { aba: "061100606", name: "Synovus Bank" },
    { aba: "091903433", name: "Perennial Bank" },
    { aba: "091215435", name: "Marshall County State Bank" },
    { aba: "073907897", name: "First Community Bank" },
    { aba: "011900571", name: "Bank Of America, N.a." },
    { aba: "073918569", name: "Farmers State Bank" },
    { aba: "073920887", name: "Raccoon Valley Bank" },
    { aba: "071901947", name: "First American Bank" },
    { aba: "091903446", name: "Cortrust Bank National Association" },
    { aba: "051580184", name: "Chhe Fcu" },
    { aba: "021213711", name: "Provident Bank" },
    { aba: "021200407", name: "Capital One, N.a." },
    { aba: "053206819", name: "Countybank" },
    { aba: "064108757", name: "Fifth Third Bank" },
    { aba: "026073079", name: "Ameriprise Bank, Fsb" },
    { aba: "073920874", name: "First State Bank" },
    { aba: "091216780", name: "Grand Timber Bank" },
    { aba: "022383750", name: "Greater Chautauqua Fcu" },
    { aba: "091903462", name: "Old National Bank" },
    { aba: "031301998", name: "Truist Bank" },
    { aba: "064108744", name: "Pinnacle National Bank" },
    { aba: "063000021", name: "Wells Fargo Bank" },
    { aba: "026073066", name: "Esquire Bank, National Association" },
    { aba: "086518477", name: "Central Bank Of The Ozarks" },
    { aba: "091914121", name: "Pine River State Bank" },
    { aba: "091300515", name: "American State Bank & Trust Co" },
    { aba: "073905268", name: "Union Bank, N.a." },
    { aba: "031202084", name: "Bank Of America N.a." },
    { aba: "071114255", name: "Longview Bank" },
    { aba: "073920890", name: "Northstar Bank" },
    { aba: "081006162", name: "Enterprise Bank And Trust" },
    { aba: "062000019", name: "Regions Bank" },
    { aba: "073920683", name: "Pocahontas State Bank" },
    { aba: "072402610", name: "Southern Michigan Bank & Trust" },
    { aba: "063110306", name: "Capital City Bank" },
    { aba: "091203395", name: "Union St Bk Browns Valley" },
    { aba: "091914257", name: "Secur St Bk Wanamingo Inc" },
    { aba: "051409029", name: "Village Bank" },
    { aba: "071900786", name: "Banco Popular North America" },
    { aba: "091914273", name: "1st Natl Bk At St James" },
    { aba: "075904254", name: "Farmers Merchants Bank Of Kendall" },
    { aba: "073905064", name: "Gnb Bank" },
    { aba: "071923404", name: "Peoples Bank Of Kankakee County" },
    { aba: "021031207", name: "Jpmorgan Chase" },
    { aba: "051409016", name: "Bank Of The James" },
    { aba: "062003977", name: "Bancorpsouth Bank" },
    { aba: "091914286", name: "First Western Bank & Trust" },
    { aba: "021213698", name: "Lakeland Bank" },
    { aba: "066010678", name: "Banco De La Nacion Argentina" },
    { aba: "065300486", name: "Bancorpsouth" },
    { aba: "061101786", name: "Exchange Bank" },
    { aba: "062001319", name: "Truist Bank" },
    { aba: "073918394", name: "Atkins Savings Bank & Trust" },
    { aba: "073918378", name: "Home State Bank" },
    { aba: "071900760", name: "Old Second Natl Bk Aurora" },
    { aba: "075906896", name: "Partners Bank Of Wisconsin" },
    { aba: "061101773", name: "Century Bank And Trust" },
    { aba: "084206082", name: "Commerce Bank" },
    { aba: "063112993", name: "Community Natl Bk Of Sarasota County" },
    { aba: "091301815", name: "Commercial Bank Of Mott" },
    { aba: "071911416", name: "Bmo Harris Bank,n.a." },
    { aba: "081225707", name: "Inb National Association" },
    { aba: "075905554", name: "Highland State Bank" },
    { aba: "053206893", name: "The Citizens Bank" },
    { aba: "031309068", name: "Northwest Bank" },
    { aba: "072403907", name: "Fifth Third Bank" },
    { aba: "071924775", name: "Heartland Bank & Trust Co" },
    { aba: "022309682", name: "Five Star Bank" },
    { aba: "063014499", name: "Ameris Bank" },
    { aba: "071114080", name: "Middletown State Bk" },
    { aba: "021684133", name: "Mid-island Federal Credit Union" },
    { aba: "086300012", name: "Old National Bank" },
    { aba: "021913862", name: "Empire State Bank" },
    { aba: "084206040", name: "The Jefferson Bank" },
    { aba: "071900799", name: "Fifth Third Bank" },
    { aba: "053206877", name: "Palmetto State Bank" },
    { aba: "066009650", name: "The Northern Trust Co" },
    { aba: "075906854", name: "Hometown Bank" },
    { aba: "071114093", name: "Bank Of Farmington" },
    { aba: "084305844", name: "Home Banking Company" },
    { aba: "031310086", name: "Peoplesbank" },
    { aba: "021200339", name: "Bank Of America N.a." },
    { aba: "072402694", name: "County National Bank" },
    { aba: "092901434", name: "First Bank" },
    { aba: "081211106", name: "1st State Bk" },
    { aba: "071909198", name: "Bmo Harris Bank,n.a." },
    { aba: "021502011", name: "Banco Popular" },
    { aba: "056004089", name: "Wells Fargo Bank" },
    { aba: "011402105", name: "Primary Bank" },
    { aba: "071006486", name: "Cibc Bank Usa" },
    { aba: "075906838", name: "Partners Bank Of Wisconsin" },
    { aba: "071910187", name: "Old Second Natl Bk Aurora" },
    { aba: "072412008", name: "Century Bank & Trust" },
    { aba: "031205955", name: "Truist Bank" },
    { aba: "021309201", name: "Citizens National Bank Of Hammond" },
    { aba: "092901418", name: "Three Rivers Bank Of Montana" },
    { aba: "081516872", name: "Southern Bank" },
    { aba: "072400052", name: "Fifth Third Bank" },
    { aba: "021311529", name: "Saratoga Natl Bank & Trust Company" },
    { aba: "081307227", name: "Crane Credit Union" },
    { aba: "026014517", name: "Alma Bank" },
    { aba: "065300402", name: "Home Bank, National Association" },
    { aba: "026002532", name: "The Bank Of Nova Scotia" },
    { aba: "072412037", name: "State Bank" },
    { aba: "086300041", name: "Fifth Third Bank" },
    { aba: "065301744", name: "Copiah Bank" },
    { aba: "061210965", name: "Plantersfirst" },
    { aba: "081909007", name: "Bank Of Springfield" },
    { aba: "021502040", name: "Citibank, Puerto Rico" },
    { aba: "063111677", name: "Ameris Bank" },
    { aba: "072402652", name: "Independent Bank" },
    { aba: "044115443", name: "Jpmorgan Chase" },
    { aba: "084303231", name: "Bank Of Crockett" },
    { aba: "065204663", name: "Jeff Davis Bank & Trust Company" },
    { aba: "042000550", name: "North Side Bank & Trust Co" },
    { aba: "051485690", name: "Star City Federal Credit Union" },
    { aba: "061112474", name: "Bank Ozk" },
    { aba: "056005376", name: "United Bank" },
    { aba: "072403978", name: "Blissfield State Bank" },
    { aba: "074001048", name: "Key Bank" },
    { aba: "063205457", name: "Peoplessouth Bank" },
    { aba: "061101799", name: "United Bank" },
    { aba: "091406260", name: "Heartland State Bank" },
    { aba: "084304573", name: "Trustmark National Bank" },
    { aba: "067006432", name: "Wells Fargo Bank" },
    { aba: "081213780", name: "The Iuka State Bank" },
    { aba: "026013220", name: "First Republic Bank" },
    { aba: "031205913", name: "Pnc Bank, Na" },
    { aba: "092902705", name: "Opportunity Bank Of Montana" },
    { aba: "022309611", name: "Alden State Bk" },
    { aba: "083908187", name: "South Central Bank, Inc" },
    { aba: "026002574", name: "Barclays Bank Plc" },
    { aba: "081503597", name: "Peoples Community Bank" },
    { aba: "053204280", name: "Farmers & Merchants Bank Of Sc" },
    { aba: "071922175", name: "Itasca Bank And Trust Co" },
    { aba: "072403994", name: "Huron Community Bank" },
    { aba: "072402665", name: "Mercantile Bank Of Michigan" },
    { aba: "083908174", name: "German American Bank" },
    { aba: "053012838", name: "South State Bank, Na" },
    { aba: "061112458", name: "Bank Ozk" },
    { aba: "067005158", name: "Seacoast National Bank" },
    { aba: "071115416", name: "Raritan State Bk" },
    { aba: "091408983", name: "Citibank, N.a." },
    { aba: "074014433", name: "Merchants Bank Of Indiana" },
    { aba: "026013246", name: "Bank Of Hope" },
    { aba: "063015838", name: "Valley National Bank" },
    { aba: "011501718", name: "Independence Bank" },
    { aba: "011402079", name: "Cambridge Trust Company" },
    { aba: "065204579", name: "Jd Bank" },
    { aba: "091408996", name: "First Fidelity Bank" },
    { aba: "074014420", name: "State Bank Of Lizton" },
    { aba: "053107646", name: "Mechanics & Farmers Bank" },
    { aba: "026014588", name: "Bankunited, National Association" },
    { aba: "054000959", name: "Industrial Bank, N.a." },
    { aba: "091407667", name: "Minnwest Bank" },
    { aba: "021502095", name: "Banco Popular De Puerto Rico" },
    { aba: "092901395", name: "Unity Bank" },
    { aba: "081503500", name: "Jack Henry Co/ Regions Bank" },
    { aba: "072413298", name: "Fifth Third Bank" },
    { aba: "011501705", name: "Bristol County Svgs Bank" },
    { aba: "081504839", name: "Old Missouri Bank" },
    { aba: "011402066", name: "Bar Harbor Bank & Trust" },
    { aba: "067005145", name: "South State Bank, Na" },
    { aba: "056005363", name: "M & T Bank" },
    { aba: "031312738", name: "Pncbank, Na" },
    { aba: "021309285", name: "Wsb Municipal Bank" },
    { aba: "062101882", name: "First National Bank Of Hartford" },
    { aba: "092901382", name: "Bank Of Rockies" },
    { aba: "091311164", name: "Ramsey National Bank & Trust" },
    { aba: "011402053", name: "Nbt Bank Na" },
    { aba: "091406354", name: "Banknorth" },
    { aba: "083908132", name: "Lincoln National Bank" },
    { aba: "064108883", name: "Cedarstone Bank" },
    { aba: "091311177", name: "First International Bank & Trust" },
    { aba: "052001772", name: "Harbor Bank Of Maryland" },
    { aba: "011501750", name: "The Washington Trust Company" },
    { aba: "091407612", name: "American Bank And Trust" },
    { aba: "091311148", name: "Turtle Mountain State Bank" },
    { aba: "074906428", name: "Horizon Bk, An Indiana Bank" },
    { aba: "052203046", name: "The Grant County Bank" },
    { aba: "091904827", name: "Granite Community Bank" },
    { aba: "083909445", name: "Springs Valley Bank & Trust Co." },
    { aba: "071104715", name: "Busey Bank" },
    { aba: "063216187", name: "The First, A Natl Banking Assn" },
    { aba: "064106283", name: "Macon Bank And Trust Compa" },
    { aba: "011501747", name: "U.s. Bank Na" },
    { aba: "091311151", name: "American Bank Center" },
    { aba: "072413256", name: "Comerica Bank" },
    { aba: "026013291", name: "Bank Ozk" },
    { aba: "053107633", name: "Wells Fargo Bank" },
    { aba: "092901337", name: "Glacier Bank-first Security Div" },
    { aba: "091904830", name: "State Bank Of Cold Spring" },
    { aba: "092901340", name: "Independence Bank" },
    { aba: "072413285", name: "Huron State Bank" },
    { aba: "052204359", name: "Cnb Bank, Inc." },
    { aba: "073920706", name: "Carroll County State Bank" },
    { aba: "091408970", name: "Liberty National Bank" },
    { aba: "091916815", name: "Frandsen Bank & Trust" },
    { aba: "073907729", name: "Farmers Trust And Savings Bank" },
    { aba: "011501763", name: "Bank Rhode Island" },
    { aba: "081006201", name: "Commercial Bank" },
    { aba: "071104731", name: "Havana National Bank" },
    { aba: "091311135", name: "Union State Bank" },
    { aba: "052204346", name: "M & T Bank" },
    { aba: "081504855", name: "First State Bank And Trust" },
    { aba: "056005318", name: "Truist Bank" },
    { aba: "064108838", name: "Homeland Community Bank" },
    { aba: "091408909", name: "The Security State Bank" },
    { aba: "091904856", name: "Citizens Alliance Bank" },
    { aba: "091311106", name: "Visionbank" },
    { aba: "091903530", name: "First National Bank" },
    { aba: "031312796", name: "New Tripoli Bank" },
    { aba: "071102173", name: "State Bank Of Lincoln" },
    { aba: "091904872", name: "Citizens State Bank" },
    { aba: "091916844", name: "Roundbank" },
    { aba: "063112906", name: "Ameris Bank" },
    { aba: "021201639", name: "Industrial Bank" },
    { aba: "073920735", name: "Washington St Bk" },
    { aba: "051409087", name: "Bank Of Powhatan, N.a" },
    { aba: "063112919", name: "Academy Bank Na" },
    { aba: "071102199", name: "Town & Country Bk Springfield" },
    { aba: "073905116", name: "Sibley State Bank" },
    { aba: "073907774", name: "St Ansgar State Bank" },
    { aba: "073906445", name: "Midstates Bank, National Association" },
    { aba: "091914202", name: "Neighborhood National Bank" },
    { aba: "084206105", name: "Community Spirit Bank" },
    { aba: "051409074", name: "Blue Ridge Bank, Na" },
    { aba: "073920764", name: "Peoples Savings Bank" },
    { aba: "071103473", name: "First Financial Bank Na" },
    { aba: "064108870", name: "Firstbank" },
    { aba: "091204682", name: "Red River State Bank" },
    { aba: "051409061", name: "Touchstone Bank" },
    { aba: "074905173", name: "Huntington National Bank" },
    { aba: "071200538", name: "Key Bank" },
    { aba: "091301750", name: "Stock Growers Bank" },
    { aba: "064009144", name: "Capital Bank & Trust" },
    { aba: "064108867", name: "Capstar Bank" },
    { aba: "021201600", name: "Valley National Bank" },
    { aba: "066010597", name: "Banco Santander International" },
    { aba: "091216696", name: "Security State Bank Fergus Falls" },
    { aba: "053105059", name: "United Community Bank" },
    { aba: "091902256", name: "Northview Bank" },
    { aba: "073920780", name: "State Bank Of Toledo" },
    { aba: "031309123", name: "Truist Bank" },
    { aba: "091881717", name: "Lco Federal Credit Union" },
    { aba: "011201759", name: "Bar Harbor Bank & Trust" },
    { aba: "086505273", name: "Midwest Independent Bank" },
    { aba: "091916899", name: "First Resource Bank" },
    { aba: "073918459", name: "Farmers Savings Bank And Trust" },
    { aba: "073920777", name: "Pinnacle Bank" },
    { aba: "061220285", name: "Bank Ozk" },
    { aba: "011900652", name: "Bank Of America N.a." },
    { aba: "011201762", name: "The First, N.a." },
    { aba: "091213084", name: "United Southwest Bank" },
    { aba: "062206114", name: "Cb&s Bank" },
    { aba: "021213591", name: "Synchrony Bank" },
    { aba: "053012728", name: "South State Bank" },
    { aba: "031312398", name: "Riverview Bank" },
    { aba: "092901706", name: "Glacier Bank" },
    { aba: "053206961", name: "Commercial Bank" },
    { aba: "051402987", name: "Citizens & Farmers Bank" },
    { aba: "067092035", name: "Centennial Bank" },
    { aba: "062001209", name: "Pnc Bank, National Association" },
    { aba: "071909240", name: "Fifth Third Bank" },
    { aba: "073919571", name: "Commercial Savings Bank" },
    { aba: "071909211", name: "Fifth Third Bank" },
    { aba: "051402974", name: "Atlantic Union Bank" },
    { aba: "096016930", name: "North American Banking Company" },
    { aba: "021300912", name: "Trustco Bank" },
    { aba: "084304421", name: "Firstbank" },
    { aba: "067092022", name: "First Citizens Bank & Trust Company" },
    { aba: "071900883", name: "First Midwest Bank Na" },
    { aba: "075904597", name: "Bonduel State Bank" },
    { aba: "084304434", name: "The Bank Of Jackson" },
    { aba: "051402961", name: "Wells Fargo Bank" },
    { aba: "062206101", name: "Renasant Bank" },
    { aba: "021213559", name: "Mariner's Bank" },
    { aba: "091913096", name: "Pioneer Bank" },
    { aba: "053012760", name: "Select Bank & Trust Company" },
    { aba: "051402958", name: "Summit Community Bank" },
    { aba: "075000569", name: "Mitchell Bank" },
    { aba: "075917898", name: "Advia Credit Union" },
    { aba: "071911584", name: "Bmo Harris Bank,n.a." },
    { aba: "075903242", name: "Bank Of Brodhead" },
    { aba: "071924898", name: "Bmo Harris Bank,n.a." },
    { aba: "071923569", name: "First Midwest Bank" },
    { aba: "021214875", name: "Industrial Bank" },
    { aba: "011307213", name: "Brookline Bank" },
    { aba: "026002846", name: "Metropolitan Bank And Trust Co." },
    { aba: "021502341", name: "Banco Santander De Puerto Rico" },
    { aba: "071922227", name: "Bmo Harris Bank,n.a." },
    { aba: "043310139", name: "Farm Natl Bk Emlenton" },
    { aba: "096016972", name: "Agribank, Fcb" },
    { aba: "021214862", name: "Cross River Bank" },
    { aba: "071909253", name: "Fifth Third Bank" },
    { aba: "051402932", name: "Carter Bank & Trust" },
    { aba: "011102353", name: "Peoplesbank" },
    { aba: "071924872", name: "Wonder Lake State Bank" },
    { aba: "071923543", name: "Bmo Harris Bank,n.a." },
    { aba: "021502354", name: "Firstbank Puerto Rico" },
    { aba: "075903226", name: "Community First Bank" },
    { aba: "036002247", name: "Republic First Bank" },
    { aba: "021213520", name: "Bcb Community Bank" },
    { aba: "091407256", name: "Spencer Br-security St Bk Alexandria" },
    { aba: "075904542", name: "State Bank Of Cross Plains" },
    { aba: "082900982", name: "The Union Bk Of Mena" },
    { aba: "064204402", name: "One Bank Of Tennessee" },
    { aba: "044115126", name: "Huntington National Bank" },
    { aba: "043311497", name: "Nextier Bank N.a." },
    { aba: "081503694", name: "Bank Of Salem" },
    { aba: "072413227", name: "Mercantile Bank Of Michigan" },
    { aba: "091408598", name: "First Premier Bank" },
    { aba: "075903200", name: "State Bank Of Chilton" },
    { aba: "075905868", name: "Blc Community Bank" },
    { aba: "061112393", name: "Morris Bank" },
    { aba: "065301883", name: "United Miss Bank" },
    { aba: "075917843", name: "Town Bank, Na" },
    { aba: "064182524", name: "Family Advantage Federal Cu" },
    { aba: "081501049", name: "Phelps County Bank" },
    { aba: "061112364", name: "Banksouth" },
    { aba: "042107424", name: "Peoples Bank" },
    { aba: "075904526", name: "Banner Bank" },
    { aba: "026014876", name: "Amalgamated Bank" },
    { aba: "021502383", name: "Banco Cooperativo De Puerto Rico" },
    { aba: "041002957", name: "Key Bank" },
    { aba: "065301854", name: "Omni Bank" },
    { aba: "066010720", name: "Banco De Bogota, S.a." },
    { aba: "065203431", name: "Hancock Whitney Bank" },
    { aba: "056005075", name: "Atlantic Union Bank" },
    { aba: "071922256", name: "Byline Bank" },
    { aba: "065204760", name: "Capital One, N.a." },
    { aba: "075904513", name: "The Benton State Bank" },
    { aba: "071005254", name: "Burling Bank" },
    { aba: "043019003", name: "Tristate Capital Bank" },
    { aba: "081501023", name: "Kennett Trust Bank" },
    { aba: "084303189", name: "Citizens Bk & Tr Co" },
    { aba: "026014892", name: "Metropolitan Commercial Bank" },
    { aba: "064204444", name: "First National Bank" },
    { aba: "084303118", name: "Lauderdale Cnty Bk" },
    { aba: "081201695", name: "Farmers St Bk & Tr Co" },
    { aba: "051074030", name: "Atlantic Union Bank" },
    { aba: "026013576", name: "Signature Bank" },
    { aba: "026001591", name: "Standard Chartered Bank" },
    { aba: "053902197", name: "Td Bank Na" },
    { aba: "081914995", name: "Oakdale State Bank" },
    { aba: "067092064", name: "Home Federal Bank Of Hollywood" },
    { aba: "071922298", name: "Fifth Third Bank" },
    { aba: "086314367", name: "German American Bank" },
    { aba: "051485315", name: "Halifax County Community Fcu" },
    { aba: "091408585", name: "Lake Norden Office-first Premier Bk" },
    { aba: "081212273", name: "Clay City Banking Co" },
    { aba: "051736158", name: "Bureau Of The Public Debt" },
    { aba: "075905910", name: "Bank Of Luxemburg" },
    { aba: "083908284", name: "Banktrust Financial Corp" },
    { aba: "064108553", name: "Tristar Bank" },
    { aba: "056008962", name: "Sandy Spring Bank" },
    { aba: "091904908", name: "Falcon National Bank" },
    { aba: "082900911", name: "Bancorpsouth" },
    { aba: "063000225", name: "Tiaa Bank" },
    { aba: "021309379", name: "Jpmorgan Chase" },
    { aba: "081913556", name: "Farmers State Bk" },
    { aba: "051050009", name: "The Federal Reserve Board" },
    { aba: "072413175", name: "Fifth Third Bank" },
    { aba: "091408640", name: "Cortrust Bank, Na" },
    { aba: "071102487", name: "Peoples Natl Bank-kewanee" },
    { aba: "075072199", name: "Cibc Bank Usa" },
    { aba: "042287027", name: "Republic Bank & Trust Company" },
    { aba: "041205631", name: "First Financial Bank" },
    { aba: "061209756", name: "Wells Fargo Bank" },
    { aba: "082900937", name: "Simmons First National Bank" },
    { aba: "065202063", name: "Guaranty Bank And Trust Company" },
    { aba: "083908255", name: "German American Bank" },
    { aba: "091904924", name: "Bonanza Valley St Bk" },
    { aba: "081010860", name: "Pnc Bank, Na" },
    { aba: "064108524", name: "Reliant Bank" },
    { aba: "081503607", name: "Community State Bank Of Shelbina" },
    { aba: "074905225", name: "Citizens Bank" },
    { aba: "091903608", name: "Unity Bank" },
    { aba: "091215626", name: "Peoples State Bank Of Wells" },
    { aba: "011175351", name: "Dime Bank" },
    { aba: "074906541", name: "Horizon Bank, An Indiana Bank" },
    { aba: "056007604", name: "Wells Fargo Bank" },
    { aba: "065202089", name: "United Miss Bank" },
    { aba: "071115720", name: "Riverside Community Bank" },
    { aba: "021310326", name: "Community Bank, N.a." },
    { aba: "065505870", name: "First National Bank" },
    { aba: "092900383", name: "Us Bank Na" },
    { aba: "062101934", name: "American Banking Co Dba Ameris" },
    { aba: "072413133", name: "Comerica Bank" },
    { aba: "072414462", name: "West Shore Bank" },
    { aba: "084303082", name: "Centennial Bank" },
    { aba: "073921912", name: "Farmers Saving Bank-vinton" },
    { aba: "082900966", name: "Bancorp South" },
    { aba: "075072157", name: "Bmo Harris Bank, N.a" },
    { aba: "091202325", name: "Citizens Natl Bank Park Rapids" },
    { aba: "081516940", name: "Jonesburg State Bk" },
    { aba: "062001186", name: "Bbva Usa" },
    { aba: "092901683", name: "First Interstate Bank" },
    { aba: "043386985", name: "I.c. Fcu" },
    { aba: "091916941", name: "Prime Security Bank-shakopee Off" },
    { aba: "073921909", name: "Northeast Security Bank" },
    { aba: "091915612", name: "Peoples State Bank" },
    { aba: "091202338", name: "Northview Bank" },
    { aba: "052000113", name: "M & T Bank" },
    { aba: "084102979", name: "Farmers & Merchants Bank" },
    { aba: "011175319", name: "Peoples United Bank, Na" },
    { aba: "091214339", name: "Farmers & Merchants State Bank" },
    { aba: "083908200", name: "First Financial Bank" },
    { aba: "073921938", name: "Maxwell State Bank" },
    { aba: "071113188", name: "Glasford State Bank" },
    { aba: "041215032", name: "Huntington National Bank" },
    { aba: "021213504", name: "1st Constitution Bank" },
    { aba: "071900825", name: "Bmo Harris Bank,n.a." },
    { aba: "091916967", name: "Bankvista" },
    { aba: "081019298", name: "Midwest Bankcentre" },
    { aba: "073921954", name: "State Bank" },
    { aba: "063013597", name: "Ameris Bank" },
    { aba: "061101702", name: "Synovus Bank" },
    { aba: "075012531", name: "Bmo Harris Bank,n.a." },
    { aba: "073920612", name: "Freedombank" },
    { aba: "063109621", name: "Capital City Bank" },
    { aba: "011501682", name: "Bank Rhode Island" },
    { aba: "096016888", name: "Key Community Bank" },
    { aba: "041215016", name: "Huntington National Bank" },
    { aba: "091902353", name: "Cortrust Bank National Association" },
    { aba: "091915654", name: "Old National Bank" },
    { aba: "021201503", name: "Td Bank Na" },
    { aba: "091915670", name: "Home State Bank" },
    { aba: "061220133", name: "First Chatham Bank" },
    { aba: "073918323", name: "Farmers State Bank" },
    { aba: "043300712", name: "First National Bank Of Pennsylvania" },
    { aba: "073921970", name: "Gnb Bank" },
    { aba: "073905006", name: "Northwestern Bank" },
    { aba: "073907664", name: "American Savings Bank" },
    { aba: "073906335", name: "Libertyville Savings Bank" },
    { aba: "073921967", name: "First State Bank" },
    { aba: "061220146", name: "Renasant Bank" },
    { aba: "031301752", name: "Atlantic Community Bankers Bank" },
    { aba: "091213039", name: "First State Bank Of Bigfork" },
    { aba: "081019256", name: "Central Bank Of The Midwest" },
    { aba: "051400361", name: "Bank Of America, N.a." },
    { aba: "073920638", name: "Home State Bank" },
    { aba: "071113175", name: "The Gifford State Bank" },
    { aba: "043300738", name: "Pnc Bank" },
    { aba: "022383983", name: "Seneca Nation Of Indians Fcu" },
    { aba: "071126489", name: "Fortress Bank" },
    { aba: "081019227", name: "Simmons Bank" },
    { aba: "071912813", name: "Bmo Harris Bank,n.a." },
    { aba: "091914370", name: "Vermillion State Bank" },
    { aba: "073920654", name: "Iowa State Bank" },
    { aba: "071113162", name: "German American State Bank" },
    { aba: "071114491", name: "First State Bank" },
    { aba: "086506256", name: "Empire Bank" },
    { aba: "081019230", name: "Midwest Bankcentre" },
    { aba: "031313753", name: "Turbotville Natl Bank" },
    { aba: "075903174", name: "Bank Of Lake Mills" },
    { aba: "022383844", name: "Greater Niagara Fcu" },
    { aba: "073921776", name: "Chelsea Savings Bank" },
    { aba: "031204804", name: "1st Natl Bk Absecon" },
    { aba: "091913177", name: "First National Bank" },
    { aba: "071006651", name: "International Bank Of Chicago" },
    { aba: "091901192", name: "Citizens Bank Minnesota" },
    { aba: "071908021", name: "Bmo Harris Bank,n.a." },
    { aba: "091913180", name: "Prinsbank" },
    { aba: "071924979", name: "Municipal Trust & Svg Bank" },
    { aba: "064203021", name: "First Horizon Bank" },
    { aba: "071909363", name: "First Midwest Bank" },
    { aba: "021213465", name: "Oceanfirst Bank, National Associatio" },
    { aba: "084306953", name: "Regions Bank" },
    { aba: "071900993", name: "Union National Bank" },
    { aba: "075903158", name: "Bank Of Mauston" },
    { aba: "073920463", name: "Iowa Prairie Bank" },
    { aba: "073921792", name: "Fidelity Bank And Trust" },
    { aba: "073906160", name: "Farmers Trust & Savings Bank" },
    { aba: "065106321", name: "The First, A Natl Banking Assn" },
    { aba: "075903161", name: "Greenwoods State Bank" },
    { aba: "091300719", name: "Cornerstone Bank" },
    { aba: "022383815", name: "Allegany County Schools Fcu" },
    { aba: "021913655", name: "Sterling National Bank" },
    { aba: "063292499", name: "Centennial Bank" },
    { aba: "021213449", name: "Investors Bank" },
    { aba: "075917791", name: "Prevail Bank" },
    { aba: "071911652", name: "First Community Bank & Trust" },
    { aba: "026073082", name: "Banco Popular North America" },
    { aba: "071923695", name: "Bmo Harris Bank,n.a." },
    { aba: "071922366", name: "First Midwest Bank" },
    { aba: "021913642", name: "Pcsb Bank" },
    { aba: "062206279", name: "Millennial Bank" },
    { aba: "053012647", name: "Hometrust Bank, Na" },
    { aba: "042107592", name: "Kentucky Bank" },
    { aba: "075905787", name: "First Business Bank" },
    { aba: "091107705", name: "Northern Interstate Bank" },
    { aba: "066010869", name: "Banco Itau Europa International" },
    { aba: "021913639", name: "Connectione Bank" },
    { aba: "071922340", name: "Fifth Third Bank" },
    { aba: "075904445", name: "First State Bank" },
    { aba: "075903116", name: "Royal Bank" },
    { aba: "026014711", name: "Federal Home Loan Bank Of Ny" },
    { aba: "063110526", name: "Synovus Bk" },
    { aba: "065106363", name: "Centennial Bank" },
    { aba: "021213410", name: "Haven Savings Bank" },
    { aba: "021502228", name: "Oriental Bank" },
    { aba: "065204841", name: "Home Bank, National Association" },
    { aba: "071923666", name: "Byline Bank" },
    { aba: "071922337", name: "National Bank Of St Anne" },
    { aba: "091807908", name: "Citizens First Bank" },
    { aba: "081202869", name: "First Bk & Tr Co" },
    { aba: "026013408", name: "Royal Business Bank" },
    { aba: "026002752", name: "United Orient Bank" },
    { aba: "072413104", name: "West Michigan Community Bank" },
    { aba: "072414433", name: "Union Bank" },
    { aba: "026001423", name: "Cathay Bank" },
    { aba: "082007830", name: "Fiirst Natl Bank" },
    { aba: "082900872", name: "Arvest Bank-fayetteville" },
    { aba: "021310407", name: "Champlain National Bank" },
    { aba: "086314464", name: "Indiana Members Credit Union" },
    { aba: "065482595", name: "West Jefferson Federal Cu" },
    { aba: "081514450", name: "Bk St.elizabeth" },
    { aba: "092901654", name: "Glacier Bank-big Sky Western Div" },
    { aba: "071103619", name: "Bank Of America N A" },
    { aba: "064204305", name: "First Community Bank, Na" },
    { aba: "072414459", name: "Southern Michigan Bank & Trust" },
    { aba: "071922379", name: "First Midwest Bank" },
    { aba: "081226829", name: "Woodforest National Bank" },
    { aba: "084004666", name: "Bankcorp South" },
    { aba: "043310281", name: "First National Bank Of Pa" },
    { aba: "072414446", name: "Isabella Bank" },
    { aba: "026002794", name: "Bank Leumi Usa" },
    { aba: "063205208", name: "Trustmark National Bank" },
    { aba: "026001465", name: "Eastbank, N.a." },
    { aba: "065203567", name: "Evangeline Bank And Trust" },
    { aba: "092901625", name: "Native American Bank" },
    { aba: "091408679", name: "Plains Commerce Bank" },
    { aba: "026013453", name: "Landesbank Baden Wurttemberg" },
    { aba: "063205211", name: "First Federal Bank Of Florida" },
    { aba: "084303008", name: "Farmers Bank" },
    { aba: "084304337", name: "The Bank Of Fayette County" },
    { aba: "064182605", name: "Veritas Federal Credit Union" },
    { aba: "064204347", name: "First Horizon Bank" },
    { aba: "081501175", name: "First Midwest Bank Of Dexter" },
    { aba: "084303011", name: "Bk Of Gleason" },
    { aba: "056005169", name: "United Bank" },
    { aba: "091408695", name: "Security State Bank" },
    { aba: "026013479", name: "Alpine Capital Bank" },
    { aba: "092104244", name: "Bank Of Bridger, Na" },
    { aba: "021213481", name: "Bcb Community Bank" },
    { aba: "065301948", name: "Bankplus" },
    { aba: "051485438", name: "Mosaic Federal Credit Union" },
    { aba: "074905380", name: "First Merchants Bank, N.a." },
    { aba: "021308176", name: "Citibank Nys" },
    { aba: "042107602", name: "Huntington National Bank" },
    { aba: "072414394", name: "Level One Bank" },
    { aba: "067006238", name: "Bank Of America, N.a." },
    { aba: "065203499", name: "Farmers State Bank" },
    { aba: "061221310", name: "South Coast Bank & Trust" },
    { aba: "026012166", name: "Investors Bank" },
    { aba: "061211993", name: "Sb&t" },
    { aba: "064108663", name: "First Federal Bank" },
    { aba: "041205518", name: "Farmers National Bank Of Canfield" },
    { aba: "083908381", name: "United Community Bank Of West Ky Inc" },
    { aba: "091408763", name: "Pioneer Bank And Trust" },
    { aba: "083907052", name: "Stock Yards Bank & Trust Company" },
    { aba: "031301846", name: "First National Bank Of Pennsylvania" },
    { aba: "081912379", name: "First State Bank" },
    { aba: "064108650", name: "Traditions First Bank" },
    { aba: "071101022", name: "Colchester State Bank" },
    { aba: "071115665", name: "State Bank Of Saunemin" },
    { aba: "065202186", name: "Feliciana Bk And Tr Co." },
    { aba: "081224122", name: "Crossroads Bank" },
    { aba: "081913695", name: "Jefferson Bank And Trust Company" },
    { aba: "064108647", name: "Simmons First National Bank" },
    { aba: "092901599", name: "Valley Bank Of Ronan" },
    { aba: "091408705", name: "Cortrust Bank Bowdle Office" },
    { aba: "072401048", name: "Key Bank" },
    { aba: "071103635", name: "Morton Community Bank" },
    { aba: "072402364", name: "Fifth Third Bank" },
    { aba: "064107376", name: "First Horizon Bank" },
    { aba: "072401064", name: "Comerica Bank" },
    { aba: "091215529", name: "Peoples State Bank" },
    { aba: "071102322", name: "Busey Bank" },
    { aba: "074905319", name: "Home National Bk Of Thorntown" },
    { aba: "061211964", name: "Pineland Bank" },
    { aba: "061210635", name: "American Banking Co Dba Ameris" },
    { aba: "091915722", name: "Produce State Bank" },
    { aba: "072414378", name: "Grand River Bank" },
    { aba: "091202202", name: "Minnwest Bank" },
    { aba: "021310465", name: "The Upstate National Bank" },
    { aba: "091408734", name: "Great Western Bank" },
    { aba: "021383463", name: "Uhs Employees Fcu" },
    { aba: "044105819", name: "Peoples National Bank" },
    { aba: "074000010", name: "Jpmorgan Chase" },
    { aba: "064108689", name: "Southeast Bank & Trust" },
    { aba: "091914406", name: "St Martin National Bank" },
    { aba: "091203544", name: "Rushford State Bank" },
    { aba: "091915751", name: "Pine Country Bank" },
    { aba: "083908323", name: "American Bank & Trust Co., Inc." },
    { aba: "031300575", name: "Bank Of New York Mellon" },
    { aba: "011200608", name: "Key Bank" },
    { aba: "026073150", name: "Community Federal Savings Bank" },
    { aba: "067092200", name: "Iberiabank" },
    { aba: "044212265", name: "Lcnb National Bank" },
    { aba: "073907509", name: "Fnb Bank" },
    { aba: "096001013", name: "Sunrise Banks, National Association" },
    { aba: "083908310", name: "Century Bank" },
    { aba: "091204899", name: "First National Bank Of Henning, The" },
    { aba: "091202231", name: "Wadena State Bank" },
    { aba: "083908307", name: "Citizens Bank Of Cumberland County" },
    { aba: "011802488", name: "People's United Bank, N.a." },
    { aba: "073921831", name: "State Bank & Trust Company" },
    { aba: "011175212", name: "Insurbanc" },
    { aba: "026073134", name: "M.y. Safra Bank, Fsb" },
    { aba: "063292538", name: "Centennial Bank" },
    { aba: "073907541", name: "Farm & Merch Svg Bk" },
    { aba: "021202719", name: "Jpmorgan Chase" },
    { aba: "052000278", name: "Federal Reserve Bank" },
    { aba: "091215587", name: "Prime Security Bank" },
    { aba: "011501598", name: "Berkshire Bank" },
    { aba: "091915793", name: "Minnwest Bank" },
    { aba: "091914464", name: "Bremer Bank, Na" },
    { aba: "071909305", name: "Hanmi Bank" },
    { aba: "081006052", name: "Simmons First Natl Bk" },
    { aba: "081503704", name: "Town & Country Bank" },
    { aba: "064108621", name: "Pinnacle National Bank" },
    { aba: "073921873", name: "Mediapolis Savings Bank" },
    { aba: "091913148", name: "Castle Rock Bank" },
    { aba: "065106402", name: "The First, A National Banking Assn" },
    { aba: "011201995", name: "Androscoggin Savings Bank" },
    { aba: "071113049", name: "1st State Bk-forrest" },
    { aba: "091300641", name: "Merchants Bank" },
    { aba: "031901097", name: "Bryn Mawr Trust Company, The" },
    { aba: "064108605", name: "Peoples Bank Of Middle Tennessee" },
    { aba: "073920531", name: "Peoples Savings Bank" },
    { aba: "031300562", name: "Pnc Bank" },
    { aba: "073921860", name: "Security State Bank" },
    { aba: "026073163", name: "Carver Federal Savings Bank" },
    { aba: "061220049", name: "Guardian Bank" },
    { aba: "041206876", name: "Corn City State Bank" },
    { aba: "071124805", name: "Hickory Point Bank & Trust Fsb" },
    { aba: "062103000", name: "1st Progressive Bk" },
    { aba: "071026385", name: "Republic Bank Of Chicago" },
    { aba: "011001881", name: "Fiduciary Trust Co" },
    { aba: "052100932", name: "Shore United Bank" },
    { aba: "091904348", name: "Kensington Bank" },
    { aba: "053112796", name: "The Park National Bank" },
    { aba: "026009674", name: "Sumitomo Mitsui Banking Corp" },
    { aba: "036002069", name: "Wilmington Savings Fund Society, Fsb" },
    { aba: "091905680", name: "Premier Bank Minnesota" },
    { aba: "061020317", name: "Iberiabank" },
    { aba: "071112817", name: "Farmers State Bank" },
    { aba: "091400486", name: "First Dakota National Bank" },
    { aba: "071100858", name: "First Financial Bank Na" },
    { aba: "055003298", name: "Eagle Bank" },
    { aba: "071124821", name: "Bank Of Gibson City" },
    { aba: "091917681", name: "First National Bank Of Waseca" },
    { aba: "075907866", name: "Portage County Bank" },
    { aba: "052100929", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "075906524", name: "Hometown Bank" },
    { aba: "072413829", name: "Mercantile Bank Of Michigan" },
    { aba: "026072931", name: "Saks Fifth Avenue Efcu" },
    { aba: "064009445", name: "City National Bank" },
    { aba: "031300135", name: "Peoples Sercurity Bank And Trust Com" },
    { aba: "071001041", name: "Us Bank" },
    { aba: "091903051", name: "American Heritage National Bank" },
    { aba: "062105626", name: "First Us Bank" },
    { aba: "031312110", name: "Nbt Bank, N.a." },
    { aba: "091206101", name: "First State Bank Southwest" },
    { aba: "063116164", name: "First Federal Bank" },
    { aba: "072413816", name: "Mercantile Bank Of Michigan" },
    { aba: "091916378", name: "Highland Bank" },
    { aba: "072401831", name: "Fifth Third Bank" },
    { aba: "092904185", name: "Opportunity Bank Of Montana" },
    { aba: "053112754", name: "First Citizens Bank & Trust Company" },
    { aba: "021307711", name: "Community Bank, Na" },
    { aba: "053207614", name: "Sandhills Bank" },
    { aba: "026072928", name: "Igo Bank, A Div Of Flushing Svgs Bk" },
    { aba: "063116177", name: "Valley National Bank" },
    { aba: "051409333", name: "The First Bank And Trust Company" },
    { aba: "031312123", name: "Bank Of Landisburg" },
    { aba: "065204951", name: "Simmesport State Bk" },
    { aba: "021307740", name: "Nbt Bank Na" },
    { aba: "021101108", name: "Wells Fargo Bank" },
    { aba: "067011294", name: "Metabank Na" },
    { aba: "053112783", name: "Blueharbor Bank" },
    { aba: "071112804", name: "Farmers State Bank Elmwood" },
    { aba: "051000017", name: "Bank Of America, N.a." },
    { aba: "011000536", name: "Federal Home Loan Bk Bost" },
    { aba: "072413845", name: "Macatawa Bank" },
    { aba: "051409320", name: "American Natl Bk & Tr Co" },
    { aba: "071002341", name: "Mufg Bank, Ltd,chicago Branch" },
    { aba: "053110112", name: "Wells Fargo Bank" },
    { aba: "064009432", name: "Capstar Bank" },
    { aba: "061212387", name: "Heritage Bank" },
    { aba: "061020375", name: "South State Bank, Na" },
    { aba: "092905498", name: "Glacier Bank" },
    { aba: "053112770", name: "United Community Bank" },
    { aba: "072400528", name: "Huntington National Bank" },
    { aba: "072486830", name: "Advia Credit Union" },
    { aba: "053905877", name: "South Carolina Community Bank" },
    { aba: "065204964", name: "Basile State Bk" },
    { aba: "084107628", name: "Simmons First Natl Bk" },
    { aba: "071905943", name: "Fnbc Bank & Trust" },
    { aba: "061104518", name: "United Community Bank, Inc" },
    { aba: "053207669", name: "Blue Ridge Bank" },
    { aba: "031289214", name: "Healthcare Employees Fcu" },
    { aba: "084108957", name: "Evolve Bank & Trust" },
    { aba: "065203648", name: "The Bank" },
    { aba: "051409304", name: "Sonabank" },
    { aba: "062191627", name: "All In Federal Credit Union" },
    { aba: "096081729", name: "Catholic United Financial Cu" },
    { aba: "022306258", name: "Five Star Bank" },
    { aba: "065204980", name: "Guaranty Bank & Trust" },
    { aba: "065300790", name: "Bank Of Yazoo City" },
    { aba: "075907808", name: "Farmers State Bank" },
    { aba: "064201120", name: "Community National Bank" },
    { aba: "021583111", name: "Coop A/c San Blas Illescas" },
    { aba: "071904627", name: "Bmo Harris Bank,n.a." },
    { aba: "011301798", name: "Eastern Bank" },
    { aba: "026072973", name: "First Central Savings Bank" },
    { aba: "084202219", name: "Bankplus" },
    { aba: "074905403", name: "First Financial Bank" },
    { aba: "065204993", name: "Vermilion Bk & Trust Co" },
    { aba: "071110262", name: "Anderson State Bank" },
    { aba: "021583108", name: "Coop A/c Nuestra Sra Dela Candelaria" },
    { aba: "071122247", name: "Apple River State Bk" },
    { aba: "074906729", name: "1st Merchants Bk Of Central Ind Na" },
    { aba: "081202759", name: "Us Bank Na" },
    { aba: "071905930", name: "Republic Bk Of Chicago" },
    { aba: "021584424", name: "Cooperative A/c Empls. Subsidiarias" },
    { aba: "083913949", name: "Fifth Third Bank" },
    { aba: "061308592", name: "Firstbank" },
    { aba: "065201019", name: "Merchants & Farmers Bank" },
    { aba: "044105576", name: "The Community Bank" },
    { aba: "071123518", name: "Citizens Bank Of Edinburg" },
    { aba: "071905985", name: "Fifth Third Bank" },
    { aba: "021584495", name: "Coop A/c Centra Gub Minillas" },
    { aba: "031312194", name: "Vist Bank" },
    { aba: "071026356", name: "Beverly Bank Trust Co. N.a." },
    { aba: "071109202", name: "Tompkins State Bk" },
    { aba: "086506955", name: "Simmons First National Bank" },
    { aba: "043202946", name: "Unified Bank" },
    { aba: "021584479", name: "V.a.p.r Federal Credit Union" },
    { aba: "071122205", name: "Vermont State Bk" },
    { aba: "021583140", name: "Coop De Ahorro Y Credito Naguabena" },
    { aba: "065201048", name: "Capital One, National Association" },
    { aba: "026072986", name: "New York Commercial Bank" },
    { aba: "042103253", name: "Citizens Bank Of Kentucky, Inc" },
    { aba: "021583137", name: "Coop. A/c De Anasco" },
    { aba: "071109228", name: "Scott State Bank" },
    { aba: "041212637", name: "Old Fort Banking Company" },
    { aba: "083901896", name: "Bankers' Bank Of Kentucky" },
    { aba: "021113196", name: "Fairfield County Bank Corp" },
    { aba: "042103363", name: "Fifth Third Bank,hsa" },
    { aba: "084300603", name: "Bancorp South" },
    { aba: "041211298", name: "Pnc Bank, Na" },
    { aba: "065300703", name: "Trustmark Natl Bank" },
    { aba: "064202501", name: "Citizens Bk Of Blount Co" },
    { aba: "073905608", name: "Central State Bank" },
    { aba: "061104592", name: "Synovus Bank" },
    { aba: "063106734", name: "Ameris Bank" },
    { aba: "042103350", name: "Forcht Bank,national Association" },
    { aba: "084202293", name: "First Horizon Bank" },
    { aba: "081502530", name: "West Plains Bank And Trust Company" },
    { aba: "031302803", name: "Keybank National Association" },
    { aba: "021583195", name: "Coop. De A/c Abraham Rosa" },
    { aba: "063105405", name: "Capital City Bank" },
    { aba: "074904158", name: "Horizon Bk, An Indiana Bank" },
    { aba: "084202280", name: "Cleveland State Bank" },
    { aba: "062202778", name: "Renasant Bank" },
    { aba: "062201449", name: "Bankfirst Financial Services" },
    { aba: "042102018", name: "Wesbanco Bank, Inc." },
    { aba: "042103347", name: "Forcht Bank, National Association" },
    { aba: "074905474", name: "First Bank Of Berne" },
    { aba: "063106750", name: "Republic Bank & Trust Company" },
    { aba: "071916550", name: "H F Gehant Banking Co" },
    { aba: "021583179", name: "Coop. A/c Hogar Cristiano De Ciales" },
    { aba: "071915218", name: "Pioneer State Bank" },
    { aba: "064202514", name: "First Horizon Bank" },
    { aba: "061103276", name: "Renasant Bank" },
    { aba: "083900525", name: "Field And Main Bank" },
    { aba: "074904132", name: "First Financial" },
    { aba: "083901854", name: "Citizens Union Bank Of Shelbyville" },
    { aba: "073905637", name: "Sanborn Savings Bank" },
    { aba: "084202264", name: "Bankplus" },
    { aba: "066011389", name: "Banco Davivienda Sa Miami Intl Br" },
    { aba: "061202944", name: "Bank Of Camilla" },
    { aba: "061191848", name: "First Citizens Bank & Trust Company" },
    { aba: "074905461", name: "First Merchants Bank" },
    { aba: "084203593", name: "Bank Of Commerce" },
    { aba: "071122263", name: "Bank Of Pontiac" },
    { aba: "091807254", name: "Citizens State Bank" },
    { aba: "084202251", name: "Guaranty Bank & Trust" },
    { aba: "061103218", name: "Community Bank & Trust - West Ga" },
    { aba: "041211243", name: "Republic Banking Company" },
    { aba: "073905653", name: "Montezuma State Bank" },
    { aba: "066011392", name: "Ocean Bank" },
    { aba: "061202931", name: "First State Bk Of Blakely" },
    { aba: "041272279", name: "Westfield Bank" },
    { aba: "084202248", name: "Renasant Bank" },
    { aba: "091101950", name: "Range Bank, N.a." },
    { aba: "061103247", name: "Synovus Bank" },
    { aba: "083901825", name: "Wesbanco Bank, Inc." },
    { aba: "031914437", name: "Truist Bank" },
    { aba: "063106705", name: "First Natl Bank" },
    { aba: "022307600", name: "Bank Of Holland" },
    { aba: "073917641", name: "American State Bank" },
    { aba: "084202235", name: "Community Bank Of Ms" },
    { aba: "071122276", name: "Washington State Bank" },
    { aba: "081501227", name: "First Midwest Bank" },
    { aba: "066009029", name: "Banesco Usa" },
    { aba: "053185668", name: "First Carolina Corporate Cu" },
    { aba: "083901809", name: "Republic Bank" },
    { aba: "092905485", name: "Glacier Bank-big Sky Western Div" },
    { aba: "042105947", name: "Salyersville National Bank" },
    { aba: "063116122", name: "Sabal Palm Bank" },
    { aba: "064105200", name: "First Horizon Bank" },
    { aba: "084000819", name: "Bank Of America, N.a." },
    { aba: "084309015", name: "Simmons First National Bank" },
    { aba: "044212139", name: "Peoples Savings Bank" },
    { aba: "092905472", name: "Eagle Bank" },
    { aba: "053112712", name: "Aquesta Bank" },
    { aba: "053100737", name: "Fifth Third Bank Na" },
    { aba: "092905469", name: "Beartooth Bank American Bank Center" },
    { aba: "091113980", name: "Old Mission Bank" },
    { aba: "066011350", name: "Pacific National Bank" },
    { aba: "021113125", name: "The Greenwich Bank & Trust Co." },
    { aba: "043309713", name: "The Andover Bank" },
    { aba: "073916367", name: "Central State Bank" },
    { aba: "021414280", name: "Bnb Bank" },
    { aba: "073903053", name: "Citizens Savings Bank" },
    { aba: "092905456", name: "Freedom Bank" },
    { aba: "055003214", name: "Wesbanco Bank, Inc." },
    { aba: "042105921", name: "Peoples Bank Of Kentucky, Inc." },
    { aba: "063116148", name: "Centennial Bank" },
    { aba: "064107842", name: "First Bank" },
    { aba: "053112738", name: "Pinnacle Bank" },
    { aba: "061202957", name: "Planters & Citizens Bank" },
    { aba: "061212345", name: "Farmers & Merchants Bank" },
    { aba: "026009739", name: "Federal Home Loan Bank Of N.y." },
    { aba: "021114483", name: "Bankwell Bank" },
    { aba: "067012688", name: "Banco Do Brasil Americas" },
    { aba: "081007022", name: "Lindell Bank & Trust Company" },
    { aba: "092905443", name: "Bank Of Bozeman" },
    { aba: "026008413", name: "Safra National Bank Of New York" },
    { aba: "081503814", name: "Exchange Bank Of Northeast Missouri" },
    { aba: "075906595", name: "Bmo Harris Bank,n.a." },
    { aba: "092905430", name: "Trailwest Bank" },
    { aba: "055003201", name: "Wells Fargo Bank" },
    { aba: "092905427", name: "American Bank Center" },
    { aba: "052100987", name: "First United Bank & Trust" },
    { aba: "043308426", name: "Apollo Trust Co." },
    { aba: "081503827", name: "Peoples Community Bank" },
    { aba: "075905253", name: "Advia Credit Union" },
    { aba: "074904190", name: "First Financial Bank" },
    { aba: "044212168", name: "Peoples Bank" },
    { aba: "053112709", name: "First National Bank Of Pennsylvania" },
    { aba: "072486788", name: "Frankenmuth Credit Union" },
    { aba: "091905648", name: "First National Bank" },
    { aba: "062104397", name: "Peoples Bank" },
    { aba: "066013989", name: "Valley National Bank" },
    { aba: "026009768", name: "Israel Discount Bk Of Ny" },
    { aba: "091904322", name: "Arlington State Bank" },
    { aba: "061201673", name: "Ameris Bank" },
    { aba: "074904187", name: "First Financial Bank" },
    { aba: "031302861", name: "Community Bank N.a." },
    { aba: "031301532", name: "M & T Bank" },
    { aba: "062202749", name: "Iberiabank" },
    { aba: "091905664", name: "Premier Bank Rochester" },
    { aba: "072486791", name: "Dfcu Financial" },
    { aba: "041201813", name: "Independence Bank" },
    { aba: "071100719", name: "Busey Bank" },
    { aba: "053112686", name: "First National Bank Of Pennsylvania" },
    { aba: "061212277", name: "Bank Of Lumber City" },
    { aba: "063201435", name: "The Bank Of Bonifay, A Div Ffbf" },
    { aba: "075918428", name: "First Business Bank" },
    { aba: "063116083", name: "Seaside National Bank & Trust" },
    { aba: "091209933", name: "Ultima Bank Minnesota" },
    { aba: "062105778", name: "Ccb Community Bank" },
    { aba: "026009551", name: "Banco Popular, North America" },
    { aba: "021306547", name: "Cayuga Lake Natl Bk" },
    { aba: "072400421", name: "Pnc Bank, Na" },
    { aba: "091210922", name: "First Financial Bank In Winnebago" },
    { aba: "041213814", name: "Pnc Bank, Na" },
    { aba: "053112673", name: "Union Bank" },
    { aba: "072413735", name: "Bank Of Ann Arbor" },
    { aba: "031312288", name: "Luzerne Bank" },
    { aba: "091915133", name: "Heritage Bank Minnesota" },
    { aba: "026011882", name: "Citibank N.a." },
    { aba: "071001180", name: "Republic Bk Of Chicago" },
    { aba: "072401789", name: "Tcf National Bank" },
    { aba: "061201592", name: "Synovus Bank" },
    { aba: "081308161", name: "Lnb Community Bank" },
    { aba: "072413764", name: "Metabank, National Association" },
    { aba: "072412435", name: "First National Bank Of America" },
    { aba: "053185613", name: "Self-help Federal Credit Union" },
    { aba: "075907743", name: "Bank Of Ontario" },
    { aba: "072400447", name: "Fifth Third Bank" },
    { aba: "066012867", name: "Banco Del Pichincha Miami Agency" },
    { aba: "091903161", name: "First National Bank Minnesota" },
    { aba: "026008248", name: "Kbc Bank, N.v." },
    { aba: "067009044", name: "Bac Florida Bank" },
    { aba: "051001414", name: "Wells Fargo Bank" },
    { aba: "072413751", name: "Tcf National Bank" },
    { aba: "044115090", name: "Huntington National Bank" },
    { aba: "072413706", name: "Tcf National Bank" },
    { aba: "061212235", name: "Southern Bank" },
    { aba: "062202914", name: "The Citizens Bank" },
    { aba: "091207317", name: "Esb Bank" },
    { aba: "065203729", name: "Bank Of Abbeville + Trust Company" },
    { aba: "042212568", name: "Osgood State Bank" },
    { aba: "011001962", name: "Cathay Bank" },
    { aba: "031313562", name: "Pnc Bank, Na" },
    { aba: "031312233", name: "Somerset Trust Company" },
    { aba: "031301590", name: "Fulton Bank" },
    { aba: "051000101", name: "Bank Of America, N.a." },
    { aba: "091400554", name: "Plains Commerce Bank" },
    { aba: "053112631", name: "First National Bank Of Pennsylvania" },
    { aba: "026009593", name: "Bank Of America N.a." },
    { aba: "051409456", name: "Select Bank" },
    { aba: "044103086", name: "First Commonwealth Bank" },
    { aba: "061212251", name: "American Banking Co Dba Ameris" },
    { aba: "053110002", name: "The Fidelity Bank" },
    { aba: "055003133", name: "The Bank Of Glen Burnie" },
    { aba: "091210993", name: "Welcome State Bank" },
    { aba: "053112660", name: "First National Bank Of Pennsylvania" },
    { aba: "053100685", name: "Pinnacle Bank" },
    { aba: "081910436", name: "Unico Bank" },
    { aba: "051409443", name: "Pioneer Bank" },
    { aba: "072413722", name: "University Bank" },
    { aba: "072413719", name: "First Community Bank" },
    { aba: "071100706", name: "Pnc Bank, Na" },
    { aba: "053112657", name: "Providence Bank" },
    { aba: "036076215", name: "S & T Bank" },
    { aba: "011900254", name: "Bank Of America N.a." },
    { aba: "051000127", name: "Premier Bank, Inc." },
    { aba: "063116070", name: "South State Bank, Na" },
    { aba: "031312262", name: "The Juniata Valley Bank" },
    { aba: "082901952", name: "First Western Bank" },
    { aba: "041213885", name: "The Sherwood State Bank" },
    { aba: "021583001", name: "Merci Coop De Puerto Rico" },
    { aba: "074905539", name: "First Merchants Bk Of Central Ind Na" },
    { aba: "021584330", name: "Glamour Community Fcu" },
    { aba: "051409427", name: "Chesapeake Bank" },
    { aba: "041200555", name: "Huntington National Bank" },
    { aba: "081009813", name: "Umb, Na" },
    { aba: "053904483", name: "Bank Of America, N.a." },
    { aba: "042213842", name: "Lcnb National Bank" },
    { aba: "051409414", name: "Old Dominion National Bank" },
    { aba: "065202445", name: "Evangeline Bank And Trust" },
    { aba: "065201116", name: "Capital One, National Association" },
    { aba: "062105723", name: "Brantley Bank & Trust Co." },
    { aba: "021584314", name: "Coop Miembros Union De Tronquistas" },
    { aba: "091400525", name: "First National Bank Pierre" },
    { aba: "053207766", name: "Wells Fargo Bank" },
    { aba: "071110149", name: "Morton Community Bank" },
    { aba: "071109163", name: "United Community Bank" },
    { aba: "091206062", name: "State Bank Of Lismore" },
    { aba: "065202461", name: "Washington State Bank" },
    { aba: "062105710", name: "The Citizens Bank" },
    { aba: "041213872", name: "Metamora State Bank" },
    { aba: "061104408", name: "Synovus Bank" },
    { aba: "091400538", name: "Bankwest" },
    { aba: "071904779", name: "Us Bank Na" },
    { aba: "021583043", name: "Coop. A/c Trib Gen De Justicia" },
    { aba: "074906826", name: "Fifth Third Bank" },
    { aba: "021414426", name: "Flushing Bank" },
    { aba: "061008766", name: "Federal Home Loan Bk Of At" },
    { aba: "091303130", name: "Citizens State Bk" },
    { aba: "021583030", name: "Cooperativa De A/c Jesus Obrero" },
    { aba: "071111407", name: "The Gerber State Bk" },
    { aba: "042102050", name: "Forcht Bank, National Association" },
    { aba: "021583027", name: "Coop Ahorro Y Credito Vega Alta" },
    { aba: "074906800", name: "Community State Bank" },
    { aba: "084204709", name: "Citizens Bank And Trust Company" },
    { aba: "071110107", name: "Blackhawk Bank And Trust" },
    { aba: "042201854", name: "Peoples Bank" },
    { aba: "083900680", name: "Truist Bank" },
    { aba: "073906801", name: "Exchange State Bank" },
    { aba: "065202393", name: "Bk Of Sunset & Tr Co" },
    { aba: "065201064", name: "Capital One, N.a." },
    { aba: "021414345", name: "Flushing Bank" },
    { aba: "091810979", name: "River Falls State Bank" },
    { aba: "091809980", name: "First National Community Bank" },
    { aba: "075005496", name: "Associated Bank Green Bay" },
    { aba: "071902043", name: "Lasalle St Bank" },
    { aba: "061103140", name: "Synovus Bank" },
    { aba: "061092057", name: "Stearns Bank National Assn" },
    { aba: "042103473", name: "First National Bank" },
    { aba: "056003158", name: "United Bank" },
    { aba: "062201591", name: "Synovus Bank" },
    { aba: "042103460", name: "Commercial Bank" },
    { aba: "021583069", name: "Cooperativa De A/c Civico Poncena" },
    { aba: "064104052", name: "Union Bank" },
    { aba: "061103166", name: "Queensborough National Bk & Tr" },
    { aba: "081503953", name: "Northeast Missouri State Bank" },
    { aba: "044106944", name: "Peoples Bank" },
    { aba: "056003174", name: "United Bank" },
    { aba: "083000920", name: "Pnc Bank, Na" },
    { aba: "021583056", name: "Coop De A/c Ciales" },
    { aba: "021584385", name: "Coop. A/c Emp Dept Hacienda" },
    { aba: "081502637", name: "New Era Bank" },
    { aba: "061103153", name: "Peoples Bank & Trust" },
    { aba: "071902030", name: "Hometown National Bank" },
    { aba: "082901907", name: "Simmons First Natl Bk" },
    { aba: "081213285", name: "First Southern Bank" },
    { aba: "073918844", name: "Titonka Savings Bank" },
    { aba: "084107709", name: "Armor Bank" },
    { aba: "031902591", name: "Fulton Bank" },
    { aba: "082901910", name: "Signature Bank Of Arkansas" },
    { aba: "065503348", name: "Community Bank Of Ms" },
    { aba: "081501353", name: "Maries County Bank" },
    { aba: "042102115", name: "Truist Bank" },
    { aba: "073906856", name: "Central State Bank" },
    { aba: "073905527", name: "Lincoln Savings Bank" },
    { aba: "061092015", name: "Georgia Guard Credit Union" },
    { aba: "071111481", name: "Alliance Community Bank" },
    { aba: "071926045", name: "American Community Bank & Trust" },
    { aba: "096017230", name: "Drake Bank" },
    { aba: "056004445", name: "United Bank" },
    { aba: "083900619", name: "Wesbanco Bank, Inc." },
    { aba: "081213269", name: "Grand Rivers Community Bank" },
    { aba: "011300595", name: "Cambridge Trust Company" },
    { aba: "073906872", name: "Bank" },
    { aba: "071026408", name: "Metropolitan Capital Bank" },
    { aba: "071109189", name: "State Bank Of Cerro Gordo" },
    { aba: "084202112", name: "Renasant Bank" },
    { aba: "073918857", name: "Farmers Trust And Savings Bank" },
    { aba: "071926016", name: "First American Bank" },
    { aba: "084008028", name: "Trustmark National Bank" },
    { aba: "021583098", name: "Coop. Ahorro Y Credito Adjuntas" },
    { aba: "081501340", name: "Bank Of Kirksville" },
    { aba: "065000731", name: "Bank Of Louisiana" },
    { aba: "083901935", name: "First Southern National Bank" },
    { aba: "071001122", name: "Old Second Bank/aurora" },
    { aba: "051000033", name: "Federal Reserve Bank" },
    { aba: "092905362", name: "The State Bank Of Townsend A Div Of" },
    { aba: "063116009", name: "South State Bank, Na" },
    { aba: "053112602", name: "United Bank" },
    { aba: "071926090", name: "Grand Ridge National Bank" },
    { aba: "031302997", name: "American Bank" },
    { aba: "051000020", name: "Suntrust" },
    { aba: "066009155", name: "City National Bank Of Florida" },
    { aba: "042307224", name: "Park National Bank" },
    { aba: "091905716", name: "Stearns Bk-holdingford, Natl Assn" },
    { aba: "021210112", name: "Crown Bank" },
    { aba: "071926087", name: "International Bank Of Chicago" },
    { aba: "052000016", name: "Wells Fargo Bank" },
    { aba: "081019175", name: "Fortunebank" },
    { aba: "084000929", name: "Tri-state Bank" },
    { aba: "026011921", name: "The Berkshire Bank" },
    { aba: "063116025", name: "American Momentum Bank" },
    { aba: "091101879", name: "Superior Natl Bk & Tr Co" },
    { aba: "091818049", name: "Security Financial Bank" },
    { aba: "081019162", name: "Triad Bank" },
    { aba: "071926058", name: "First Secure Community Bank" },
    { aba: "071002422", name: "Brickyard Bank" },
    { aba: "053185574", name: "Self-help Credit Union" },
    { aba: "053112615", name: "Square 1 Bank Div Pacific Western Bk" },
    { aba: "065106619", name: "Hancock Whitney Bank" },
    { aba: "071926061", name: "The Leaders Bank" },
    { aba: "043211160", name: "Unified Bank" },
    { aba: "091905745", name: "Village Bank" },
    { aba: "053185587", name: "Allegacy Fcu" },
    { aba: "062103165", name: "Smartbank" },
    { aba: "026010605", name: "Sterling National Bank" },
    { aba: "031302955", name: "M & T Bank" },
    { aba: "081019120", name: "1st Advantage Bank" },
    { aba: "061103182", name: "The Bank Of Lafayette" },
    { aba: "064104036", name: "Citizens Community Bank" },
    { aba: "071002419", name: "Fifth Third Bank" },
    { aba: "062201559", name: "Cb&s Bank" },
    { aba: "026009632", name: "Mufg Bank, Ltd. New York Branch" },
    { aba: "091917733", name: "National Bank Od Commerce" },
    { aba: "081019133", name: "St. Louis Bank" },
    { aba: "061202889", name: "Guardian Bank" },
    { aba: "075906469", name: "The Peshtigo National Bank" },
    { aba: "031313627", name: "Fulton Bank, Na" },
    { aba: "026011963", name: "Shinhan Bank America" },
    { aba: "031302971", name: "Customers Bank" },
    { aba: "067011456", name: "Helm Bank" },
    { aba: "062202859", name: "First Natl Bk Hamilton" },
    { aba: "026009645", name: "Citibank N.a." },
    { aba: "081019104", name: "Fifth Third Bank" },
    { aba: "081503911", name: "Southern Bank" },
    { aba: "021907577", name: "M & T Bank" },
    { aba: "081019117", name: "Paramount Bank" },
    { aba: "062201533", name: "Peoples Bank-greensboro" },
    { aba: "064105336", name: "The Farmers Bank" },
    { aba: "064107994", name: "First National Bank" },
    { aba: "064009241", name: "Pinnacle Bank" },
    { aba: "091401553", name: "One American Bank" },
    { aba: "084108795", name: "Centennial Bank" },
    { aba: "044202505", name: "Peoples Bank" },
    { aba: "053112563", name: "Select Bank & Trust" },
    { aba: "072413625", name: "Horizon Bk, An Indiana Bank" },
    { aba: "051409142", name: "First Community Bank" },
    { aba: "071109406", name: "Marine Bank" },
    { aba: "053112550", name: "Alliance Bank & Trust" },
    { aba: "021210073", name: "Woori America Bank" },
    { aba: "051409139", name: "Old Point National Bank Of Phoebus" },
    { aba: "072413612", name: "Tcf National Bank" },
    { aba: "061212170", name: "Wheeler County State Bank" },
    { aba: "071109435", name: "First National Bk Tremont" },
    { aba: "075905457", name: "1st National Bank/darlington" },
    { aba: "071108106", name: "Citizens State Bank" },
    { aba: "031313261", name: "First National Bank Of Pennsylvania" },
    { aba: "067001699", name: "Valley National Bank" },
    { aba: "071110424", name: "Table Grove State Bank" },
    { aba: "065106680", name: "Community Bank Of Ms" },
    { aba: "072413638", name: "Old National Bank" },
    { aba: "041201703", name: "Key Bank" },
    { aba: "075904115", name: "Bmo Harris Bank,n.a." },
    { aba: "062103259", name: "Trustmark National Bank" },
    { aba: "021210057", name: "Unity Bank" },
    { aba: "081309364", name: "Fifth Third Bank" },
    { aba: "063203734", name: "Synovus Bank" },
    { aba: "062201708", name: "Cadence Bank, N.a." },
    { aba: "075905431", name: "Bmo Harris Bank,n.a." },
    { aba: "053185503", name: "Latino Community Credit Union" },
    { aba: "021583328", name: "Cooperativa A/c Caparra" },
    { aba: "061308770", name: "Wesbanco Bank, Inc." },
    { aba: "091218335", name: "Mills Resolute Bank" },
    { aba: "072401608", name: "Fifth Third Bank" },
    { aba: "021210044", name: "Fulton Bank, Na" },
    { aba: "043308277", name: "Farmers National Bank Of Emlenton" },
    { aba: "081311666", name: "First Financial Bank" },
    { aba: "051409100", name: "Sonabank" },
    { aba: "071001274", name: "Tcf National Bank Illinois" },
    { aba: "081308006", name: "Spencer County Bank" },
    { aba: "062201711", name: "Farmers Merchants Bank" },
    { aba: "021584644", name: "Cooperativa De Ahorro Y Credito Sol" },
    { aba: "086518765", name: "Community First Banking Company" },
    { aba: "062104520", name: "First National Bank And Trust" },
    { aba: "086506793", name: "Providence Bank" },
    { aba: "044203863", name: "The Ohio Valley Bank Company" },
    { aba: "061020113", name: "Cibc Bank Usa" },
    { aba: "065405226", name: "United Community Bank" },
    { aba: "051000253", name: "Wells Fargo Bank" },
    { aba: "072413609", name: "Bank Of America" },
    { aba: "055003492", name: "Hsbc National Bank Usa" },
    { aba: "044203850", name: "Peoples Bank" },
    { aba: "021583302", name: "Coop.a/c De La Casa Del Trabajador" },
    { aba: "053112547", name: "Nantahala Bank & Trust" },
    { aba: "086518749", name: "Bancorp South" },
    { aba: "062103217", name: "First Citizens Bk" },
    { aba: "053100559", name: "Bank Of America, N.a." },
    { aba: "074014035", name: "Horizon Bank, An Indiana Bank" },
    { aba: "082901839", name: "Farmers Bank & Trust" },
    { aba: "091205063", name: "United Valley Bank" },
    { aba: "086518736", name: "Bancorp South" },
    { aba: "071122467", name: "Midland States Bank" },
    { aba: "021583360", name: "Cooperativa De A/c Mayaguez" },
    { aba: "065202555", name: "Investar Bank, National Association" },
    { aba: "091206389", name: "Viking Bank" },
    { aba: "011600062", name: "People's United Bank, N.a." },
    { aba: "063192926", name: "Republic Bank & Trust Co." },
    { aba: "021583357", name: "Coop. Fed. De Empleados Telefonicos" },
    { aba: "082901842", name: "The First National Bank" },
    { aba: "086518723", name: "Simmons First National Bank" },
    { aba: "031276120", name: "Oceanfirst Bank" },
    { aba: "036076150", name: "Citizens Bank, National Association" },
    { aba: "065202568", name: "Iberiabank" },
    { aba: "082901855", name: "Simmons First Natl Bk" },
    { aba: "075906702", name: "Community First Bank" },
    { aba: "021583344", name: "Coop. A/c Pueblocoop" },
    { aba: "081501492", name: "First Missouri Bank Of Semo" },
    { aba: "091016993", name: "Republic Bank & Trust Co." },
    { aba: "084204628", name: "First State Bank" },
    { aba: "071108193", name: "Peoples Bk Of Macon" },
    { aba: "065201255", name: "First Guaranty Bank" },
    { aba: "071026576", name: "Belmont Bank & Trust Company" },
    { aba: "082901868", name: "Simmons First Natl Bk" },
    { aba: "021583331", name: "Coop A/c Eli Lilly And Co, Inc." },
    { aba: "041212433", name: "Croghan Colonial Bank" },
    { aba: "071916822", name: "Old Second Natl Bk Aurora" },
    { aba: "041213759", name: "Union Bank Company" },
    { aba: "011301536", name: "Eastern Bank" },
    { aba: "091205089", name: "Security Savings Bank" },
    { aba: "031913438", name: "Univest Bank And Trust Co" },
    { aba: "072401682", name: "Fifth Third Bank" },
    { aba: "081923452", name: "Community Bank Trenton" },
    { aba: "084205931", name: "Renasant Bank" },
    { aba: "064202268", name: "Mountain Commerce Bank" },
    { aba: "011600033", name: "Td Bank Na" },
    { aba: "072400353", name: "Pnc Bank, Na" },
    { aba: "021305386", name: "Community Bank, Na" },
    { aba: "082901871", name: "Bank Ozk" },
    { aba: "042201948", name: "Us Bank Na" },
    { aba: "091302160", name: "Choice Financial Group" },
    { aba: "074906949", name: "The Fountain Trust Company" },
    { aba: "071926210", name: "Inland Bank & Trust" },
    { aba: "031902766", name: "Pnc Bank" },
    { aba: "011600020", name: "Community Bank, Na" },
    { aba: "064202255", name: "Citizens National Bank" },
    { aba: "081909146", name: "First National Bank" },
    { aba: "053112592", name: "Woodforest National Bank" },
    { aba: "072413654", name: "Tcf National Bank" },
    { aba: "063192971", name: "Republic Bank & Trust Co." },
    { aba: "082901897", name: "First Security Bank" },
    { aba: "021583386", name: "Coop De A/c De Quebradillas" },
    { aba: "041213704", name: "The Mt. Victory State Bank" },
    { aba: "072413683", name: "Charlevoix State Bank" },
    { aba: "084205915", name: "First Securtiy Bank" },
    { aba: "072411025", name: "Farmers State Bank Of Munith" },
    { aba: "072001079", name: "First Independence Bank" },
    { aba: "071904889", name: "Northern Trust/lake Forest" },
    { aba: "042102160", name: "Heritage Bank" },
    { aba: "021583373", name: "Coop. A/c Bo. Quebrada" },
    { aba: "091302115", name: "Hometown Community Bank" },
    { aba: "042102270", name: "Commercial Bank" },
    { aba: "021414468", name: "Hanover Community Bank" },
    { aba: "084202060", name: "Renasant Bank" },
    { aba: "011601443", name: "Citizens Access, Div. Of Citizens Bk" },
    { aba: "071914138", name: "Lemont National Bank And Trust Co." },
    { aba: "063104312", name: "First National Bank" },
    { aba: "021414455", name: "Investors Bank" },
    { aba: "042102267", name: "Truist Bank" },
    { aba: "081514748", name: "Mid America Bank" },
    { aba: "091014267", name: "Old National Bank" },
    { aba: "055003405", name: "Eagle Bank" },
    { aba: "056006786", name: "Atlantic Union Bank" },
    { aba: "063105654", name: "Capital City Bank" },
    { aba: "021114263", name: "Hsbc National Bank Usa" },
    { aba: "074904394", name: "First Financial Bank" },
    { aba: "061105672", name: "Southcrest Bank" },
    { aba: "064203610", name: "First Horizon Bank" },
    { aba: "065503681", name: "Hancock Whitney Bank" },
    { aba: "042102254", name: "Truist Bank" },
    { aba: "061105685", name: "South State Bank" },
    { aba: "081226421", name: "Southerntrust Bank" },
    { aba: "062201698", name: "Valley National Bank" },
    { aba: "061103030", name: "Northeast Georgia Bank" },
    { aba: "061104314", name: "South State Bank" },
    { aba: "074904365", name: "Fairmount State Bank" },
    { aba: "022307820", name: "Cattaraugus County Bank" },
    { aba: "061104301", name: "Bank Of Wadley" },
    { aba: "081501489", name: "Central Bank Of Audrain County" },
    { aba: "073905417", name: "First State Bank" },
    { aba: "091818117", name: "Frandsen Bank & Trust" },
    { aba: "082901813", name: "Southern Bancorp Bank" },
    { aba: "021413155", name: "Bnb Bank" },
    { aba: "061103001", name: "Bank Ozk" },
    { aba: "071926155", name: "Cornerstone National Bk & Tr Co" },
    { aba: "083900732", name: "Us Bank Na" },
    { aba: "082901826", name: "Iberiabank" },
    { aba: "084204657", name: "First Horizon Bank" },
    { aba: "081512106", name: "The Bank Of Missouri" },
    { aba: "091905813", name: "First State Bank Of Grove City" },
    { aba: "084001915", name: "Bancorpsouth" },
    { aba: "051903761", name: "Huntington National Bank" },
    { aba: "062202956", name: "Bancorpsouth Bank" },
    { aba: "091905826", name: "Onb Bank" },
    { aba: "031302638", name: "First National Bank Of Pennsylvania" },
    { aba: "073904117", name: "Hardin County Savings Bank" },
    { aba: "042104854", name: "Cumberland Security Bank" },
    { aba: "063115039", name: "Century Bank Of Florida" },
    { aba: "064107622", name: "First Horizon Bank" },
    { aba: "021114205", name: "Jpmorgan Chase" },
    { aba: "064108951", name: "Sumner Bank & Trust" },
    { aba: "044215893", name: "Hocking Valley Bank" },
    { aba: "071001232", name: "South Central Bk & Tr Co" },
    { aba: "021606580", name: "Banco Popular De Pr" },
    { aba: "055002150", name: "Woodsboro Bank" },
    { aba: "021213986", name: "Lakeland Bank" },
    { aba: "055003489", name: "Woori America Bank" },
    { aba: "073906788", name: "Dysart State Bank" },
    { aba: "062201630", name: "Marion Bk And Trust Co" },
    { aba: "091109253", name: "The Miners State Bank" },
    { aba: "063116371", name: "Seacoast National Bank" },
    { aba: "062201601", name: "Bbva Usa" },
    { aba: "055003450", name: "Sandy Spring Bank" },
    { aba: "053112505", name: "Axos Bank" },
    { aba: "063115055", name: "Bank Ozk" },
    { aba: "091818162", name: "Coulee Bank" },
    { aba: "026010948", name: "Industrial & Comm. Bnk Of China, Na" },
    { aba: "091208905", name: "Ormsby State Bank" },
    { aba: "071926197", name: "Schaumburg Bank & Trust Company, Na" },
    { aba: "065405255", name: "Bancorp South" },
    { aba: "067013700", name: "Fifth Third Bank" },
    { aba: "055003463", name: "First Citizens Bank" },
    { aba: "063115068", name: "Centennial Bank" },
    { aba: "065405242", name: "Resource Bank" },
    { aba: "042104825", name: "Cumberland Valley National Bank" },
    { aba: "083008362", name: "Republic Bank & Trust Co" },
    { aba: "071001216", name: "Self-help Fcu" },
    { aba: "064108922", name: "Citizens Tri-county Bank" },
    { aba: "071926184", name: "Northbrook Bank & Trust Comp, Na" },
    { aba: "051409197", name: "The Fidelity Bank" },
    { aba: "062202998", name: "Citizens State Bank" },
    { aba: "082986728", name: "Priority Bank, Fsb" },
    { aba: "073904159", name: "Audubon State Bank" },
    { aba: "055003434", name: "Howard Bank" },
    { aba: "053207902", name: "South State Bank" },
    { aba: "064108993", name: "Smartbank" },
    { aba: "051409184", name: "Atlantic Union Bank" },
    { aba: "063105683", name: "Bank Of America, N.a." },
    { aba: "091915201", name: "Sherburne State Bank" },
    { aba: "021213944", name: "Connectone Bank" },
    { aba: "063116339", name: "South State Bank, Na" },
    { aba: "052100741", name: "The Peoples Bank" },
    { aba: "061103056", name: "Wells Fargo Bank" },
    { aba: "091916527", name: "Security State Bank Of Aitkin" },
    { aba: "066011567", name: "Banco Internacional De Costa Rica" },
    { aba: "092102851", name: "First Montana Bank, Inc" },
    { aba: "081009428", name: "First Bank" },
    { aba: "051409171", name: "Highlands Community Bank" },
    { aba: "091916543", name: "Bankwest" },
    { aba: "084202086", name: "Sycamore Bank" },
    { aba: "065405297", name: "Synergy Bank" },
    { aba: "055003418", name: "Congressional Bank" },
    { aba: "051409168", name: "Bank Of Essex" },
    { aba: "075905486", name: "Abby Bank" },
    { aba: "065104116", name: "Hancock Whitney Bank" },
    { aba: "067012413", name: "Centennial Bank" },
    { aba: "084202073", name: "First Choice Bank" },
    { aba: "091903239", name: "Foresight Bank" },
    { aba: "084301767", name: "Bank Of America" },
    { aba: "055003421", name: "Sonabank" },
    { aba: "065405284", name: "B1bank" },
    { aba: "067000438", name: "First State Bk Of The Florida Keys" },
    { aba: "062202985", name: "Metropolitan Commercial Bank" },
    { aba: "091903242", name: "Janesville State Bank" },
    { aba: "052100725", name: "Pnc Bank, Inc. - Baltimore" },
    { aba: "063115026", name: "Synovus Bank" },
    { aba: "064108964", name: "Fnb Bank" },
    { aba: "021201943", name: "Pnc Bank" },
    { aba: "071002503", name: "Fifth Third Bank" },
    { aba: "091508317", name: "Forward Bank" },
    { aba: "096008825", name: "Old National Bank" },
    { aba: "061202753", name: "Plantersfirst" },
    { aba: "051409155", name: "American National Bank & Trust Co" },
    { aba: "062102030", name: "Hnb First Bank" },
    { aba: "091902036", name: "Eagle Bank" },
    { aba: "026010773", name: "Delta National Bank & Trust Company" },
    { aba: "072402856", name: "First Financial Bank" },
    { aba: "067012484", name: "Synovus Bank" },
    { aba: "053207944", name: "Pinnacle Bank" },
    { aba: "053100465", name: "Suntrust" },
    { aba: "073917285", name: "Peoples Savings Bank" },
    { aba: "063215845", name: "First Federal Bank Of Florida" },
    { aba: "053100452", name: "Mechanics & Farmers Bank" },
    { aba: "072401514", name: "Premier Bank" },
    { aba: "091902049", name: "First State Bank" },
    { aba: "026010786", name: "Nordea Bk Abp New York Branch" },
    { aba: "053112437", name: "First National Bank Forest City" },
    { aba: "067011142", name: "Td Bank Na" },
    { aba: "053207931", name: "South State Bank" },
    { aba: "011302920", name: "Bank Of New York Mellon" },
    { aba: "067012471", name: "Bank Leumi Usa" },
    { aba: "065202607", name: "Bankplus" },
    { aba: "091208853", name: "Odin State Bank" },
    { aba: "041202922", name: "Peoples Bank" },
    { aba: "053112466", name: "Dogwood State Bank" },
    { aba: "061212057", name: "First Commerce Credit Union" },
    { aba: "091207537", name: "First State Bank Fountain" },
    { aba: "091902065", name: "Minnesota National Bank" },
    { aba: "061202685", name: "South Georgia Banking Comp" },
    { aba: "031312055", name: "Jonestown Bk & Tr Co" },
    { aba: "042286853", name: "First Miami Student Fcu" },
    { aba: "066010445", name: "Hsbc Private Bank International" },
    { aba: "066009456", name: "First American Bank" },
    { aba: "061191495", name: "Chattahoochee Federal Cu" },
    { aba: "091208879", name: "Currie State Bank, Okabena Office" },
    { aba: "071124931", name: "Community State Bk Galva" },
    { aba: "072402869", name: "Highpoint Community Bank" },
    { aba: "053112453", name: "First National Bank Of Pennsylvania" },
    { aba: "061202672", name: "Colony Bank" },
    { aba: "073917298", name: "Central State Bank Elkader" },
    { aba: "053112408", name: "First Carolina Bank" },
    { aba: "086518891", name: "Old Missouri Bank" },
    { aba: "062104656", name: "22nd State Bank" },
    { aba: "031300012", name: "Pncbank, N.a." },
    { aba: "062105985", name: "Sunsouth Bank" },
    { aba: "055003395", name: "Acnb Bank" },
    { aba: "053206657", name: "Palmetto State Bank" },
    { aba: "063116287", name: "Seacoast National Bank" },
    { aba: "071112901", name: "Fairview State Banking Co" },
    { aba: "091206224", name: "Community Development Bank Fsb" },
    { aba: "051409223", name: "First Citizens Bank & Trust Company" },
    { aba: "074014187", name: "First Internet Bank Of Indiana" },
    { aba: "075906634", name: "State Bank Of Reeseville" },
    { aba: "036089309", name: "Wayland Temple Baptist Fcu" },
    { aba: "061212002", name: "Onesouth Bank" },
    { aba: "053207973", name: "The Citizens Bank" },
    { aba: "091401647", name: "Commercial State Bank" },
    { aba: "063116290", name: "South State Bank, Na" },
    { aba: "051409210", name: "Atlantic Union Bank" },
    { aba: "053185406", name: "Self-help Credit Union" },
    { aba: "091207582", name: "Frost State Bank" },
    { aba: "053207960", name: "South State Bank" },
    { aba: "031302696", name: "Fulton Bank, Na" },
    { aba: "053112424", name: "United Bank" },
    { aba: "061212015", name: "The Claxton Bank" },
    { aba: "071124902", name: "Midwest Bank" },
    { aba: "021213892", name: "Connectone Bank" },
    { aba: "051409207", name: "Bank Of Louisa" },
    { aba: "091401663", name: "Great Plains Bank, Eureka" },
    { aba: "075907947", name: "Webster Bank,national Association" },
    { aba: "053112411", name: "Peoples National Bank Fayetteville" },
    { aba: "091110093", name: "State Savings Bank Of Manistique" },
    { aba: "053207957", name: "Grandsouth Bank" },
    { aba: "031312026", name: "1st Liberty B & T Div Of Comm Bk N.a" },
    { aba: "021200575", name: "Pnc Bank N.a." },
    { aba: "053206699", name: "Palmetto State Bank" },
    { aba: "065201349", name: "Iberiabank" },
    { aba: "072413586", name: "Cibc Bank Usa" },
    { aba: "041202980", name: "Credit First Na" },
    { aba: "071110369", name: "Philo Exchange Bank" },
    { aba: "082901729", name: "First Western Bank" },
    { aba: "071108038", name: "Longview Bank And Trust" },
    { aba: "086505545", name: "State Bank Of Southwest Mo" },
    { aba: "065202694", name: "Bank Of St Francisville" },
    { aba: "071026686", name: "Signature Bank" },
    { aba: "091001144", name: "Sunrise Banks, National Association" },
    { aba: "011601265", name: "Peoples United Bank, N.a." },
    { aba: "061192753", name: "First Community Bank" },
    { aba: "091402947", name: "Rivers Edge Bank" },
    { aba: "036089312", name: "Philadelphia Federal Credit Union" },
    { aba: "091014458", name: "First Minnetonka City Bank" },
    { aba: "081910232", name: "Peoples B & T Co." },
    { aba: "082901745", name: "Today's Bank" },
    { aba: "053206660", name: "First- Citizens Bank & Trust Co" },
    { aba: "021583218", name: "Cooperativa A/c Lares Region Central" },
    { aba: "061192740", name: "Peoples Bank Of East Tennessee" },
    { aba: "064204774", name: "First Century Bank" },
    { aba: "053207999", name: "First Community Bank" },
    { aba: "026083713", name: "Neighborhood Trust Fcu" },
    { aba: "082900429", name: "Bank Of America" },
    { aba: "065201381", name: "First Guaranty Bank" },
    { aba: "091302034", name: "American Bank Center" },
    { aba: "071109338", name: "Inb National Association" },
    { aba: "053112482", name: "Uwharrie Bank" },
    { aba: "053100494", name: "Wells Fargo Bank" },
    { aba: "031276049", name: "Spencer Savings Bank, Sla" },
    { aba: "061212086", name: "Cadence Bank, Na" },
    { aba: "061191437", name: "Hallco Community Credit Union" },
    { aba: "082900432", name: "Simmons Bank" },
    { aba: "053112479", name: "First National Bank Of Pennsylvania" },
    { aba: "096005093", name: "Premier Bank" },
    { aba: "021583276", name: "Cooperativa De A/c Caribe Coop" },
    { aba: "011601236", name: "Bar Harbor Bank & Trust" },
    { aba: "071926320", name: "Bmo Harris Bank,n.a." },
    { aba: "041201635", name: "Civista Bank" },
    { aba: "082901774", name: "First Security Bank" },
    { aba: "072401585", name: "Pnc Bank, Na" },
    { aba: "021584592", name: "Coop. Empleados De Cervecera De Pr" },
    { aba: "011301604", name: "Eastern Bank" },
    { aba: "072413560", name: "Independent Bank" },
    { aba: "063192874", name: "Axiom Bank" },
    { aba: "082901790", name: "Priority Bank" },
    { aba: "053112495", name: "New Century Bank" },
    { aba: "072413557", name: "Tcf National Bank" },
    { aba: "071926249", name: "Senb Bank" },
    { aba: "084203276", name: "Renasant Bank" },
    { aba: "081514874", name: "First Security State Bk" },
    { aba: "091304650", name: "American Bank Center" },
    { aba: "071926252", name: "Fifth Third Bank" },
    { aba: "063105515", name: "Lafayette State Bank" },
    { aba: "021907315", name: "Sterling National Bank" },
    { aba: "084302915", name: "Cb&s Bank" },
    { aba: "091806242", name: "Security Bank" },
    { aba: "091807571", name: "Security Financial Bank" },
    { aba: "092905508", name: "Glacier Bank -first Security Bank Di" },
    { aba: "061104220", name: "First Peoples Bank" },
    { aba: "073920900", name: "Fidelity Bank" },
    { aba: "073919911", name: "Security National Bank" },
    { aba: "074014213", name: "Regions Bank" },
    { aba: "091304634", name: "First National Bank & Trust Co" },
    { aba: "084302928", name: "Centennial Bank" },
    { aba: "081215649", name: "Sainte Marie State Bank" },
    { aba: "084203250", name: "Bank Of Kilmichael" },
    { aba: "063105544", name: "Citizens Bank Of Florida" },
    { aba: "021114153", name: "The First Bank Of Greenwich" },
    { aba: "071915580", name: "Bmo Harris Bank N.a" },
    { aba: "062200482", name: "Trustmark National Bank" },
    { aba: "021583292", name: "Coop A/c Empl Dep Educacion" },
    { aba: "042102351", name: "First Southern National Bank" },
    { aba: "061105533", name: "Bank Ozk" },
    { aba: "073918608", name: "United Bank & Trust Co" },
    { aba: "072412176", name: "Pnc Bank, Na" },
    { aba: "064203513", name: "Bank Of Cleveland" },
    { aba: "091818269", name: "National Bank Of Commerce" },
    { aba: "071026628", name: "Signature Bank" },
    { aba: "073920926", name: "Reliance State Bank" },
    { aba: "071108096", name: "Lena State Bank" },
    { aba: "084205889", name: "Renasant Bank" },
    { aba: "081513574", name: "First Missouri State Bank" },
    { aba: "073907952", name: "Citizens State Bank" },
    { aba: "091818256", name: "Hiawatha National Bank" },
    { aba: "071926281", name: "American Eagle Bank" },
    { aba: "071108083", name: "Exchange State Bk" },
    { aba: "073920942", name: "Peoples Trust & Savings Bank" },
    { aba: "031901482", name: "Td Bank, N.a." },
    { aba: "081512232", name: "Security Bank Of The Ozarks" },
    { aba: "042101006", name: "Bank Of Maysville" },
    { aba: "042102335", name: "Wesbanco Bank, Inc." },
    { aba: "096018352", name: "Key Community Bank" },
    { aba: "091818243", name: "State Bank Financial" },
    { aba: "084009111", name: "Triumph Bank" },
    { aba: "061104204", name: "United Bank" },
    { aba: "073920939", name: "Iowa State Bank" },
    { aba: "091904610", name: "Lake Central Bank" },
    { aba: "067013852", name: "Valley National Bank" },
    { aba: "031301422", name: "Fulton Bank, Na" },
    { aba: "055003353", name: "Wesbanco Bank, Inc." },
    { aba: "052100893", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "044202466", name: "First Bank Of Ohio" },
    { aba: "071000039", name: "Bank Of America, N.a." },
    { aba: "026590808", name: "Fannie Mae" },
    { aba: "021306822", name: "Hsbc Bank, Usa" },
    { aba: "055003366", name: "Sandy Spring Bank" },
    { aba: "073906665", name: "Wayland State Bank" },
    { aba: "021200559", name: "Wells Fargo Bank" },
    { aba: "081502815", name: "First State Community Bank" },
    { aba: "083008223", name: "Forcht Bank, National Association" },
    { aba: "065405145", name: "Capital One, N.a." },
    { aba: "064107729", name: "Citizens Bank" },
    { aba: "067013836", name: "Valley National Bank" },
    { aba: "071000013", name: "Jpmorgan Chase" },
    { aba: "073905352", name: "Gnb Bank" },
    { aba: "062201724", name: "Fnb Bank" },
    { aba: "065405132", name: "Capital One, N.a." },
    { aba: "026010825", name: "Cathay Bank" },
    { aba: "031302777", name: "Wells Fargo Bank, N.a." },
    { aba: "055003337", name: "Cathay Bank" },
    { aba: "091903310", name: "First National Bank" },
    { aba: "063116261", name: "Bank Of Central Florida" },
    { aba: "031312107", name: "Community Bank, N.a." },
    { aba: "073918653", name: "American State Bank" },
    { aba: "031302780", name: "Fulton Bank" },
    { aba: "055003340", name: "Capital Bank, National Association" },
    { aba: "042286905", name: "Cincinnati Employees C.u., Inc." },
    { aba: "031314765", name: "First Columbia Bk & Tr Co" },
    { aba: "063116274", name: "First-citizens Bank & Trust Co" },
    { aba: "061308932", name: "Synovus Bank" },
    { aba: "091915311", name: "First State Bank Of St. Joesph" },
    { aba: "067012565", name: "Seacoast National Bank" },
    { aba: "011900445", name: "Bank Of America N.a" },
    { aba: "026008536", name: "Unicredit S.p.a." },
    { aba: "073904036", name: "Clear Lake Bank & Trust Co" },
    { aba: "063116203", name: "Central Bank" },
    { aba: "061104275", name: "Renasant Bank" },
    { aba: "073920997", name: "The State Bank" },
    { aba: "031314707", name: "Farmers & Merchants Bank (mt Alto)" },
    { aba: "061020197", name: "Newton Federal Bank" },
    { aba: "081514829", name: "Peoples Bank Of Wyaconda" },
    { aba: "031000503", name: "Wells Fargo Bank" },
    { aba: "061104262", name: "Bank Ozk" },
    { aba: "043309632", name: "S & T Bank" },
    { aba: "064009380", name: "Capstar Bank" },
    { aba: "063105573", name: "Brannen Bank" },
    { aba: "066010351", name: "Valley National Bank" },
    { aba: "063116229", name: "American Momentum Bank" },
    { aba: "062102098", name: "United Bank-atmore" },
    { aba: "092905537", name: "Farmers State Bank" },
    { aba: "091902023", name: "Glenwood State Bank" },
    { aba: "044215796", name: "The Ohio Valley Bank" },
    { aba: "063215900", name: "Smartbank" },
    { aba: "021114111", name: "Dr Bank" },
    { aba: "062201779", name: "Renasant Bank" },
    { aba: "042286947", name: "Sharefax Credit Union, Inc" },
    { aba: "092905524", name: "Bank Of Montana" },
    { aba: "062200440", name: "Office Of State Treasury" },
    { aba: "055003308", name: "Truist Bank" },
    { aba: "051409278", name: "Sonabank" },
    { aba: "062103482", name: "First Citizens Bk" },
    { aba: "075907413", name: "First Community Bank" },
    { aba: "083974467", name: "First Financial Bank" },
    { aba: "091206541", name: "Starbank" },
    { aba: "036018888", name: "Univest Bank And Trust Co" },
    { aba: "031100966", name: "Applied Bank" },
    { aba: "061020799", name: "Bank Of North Ga, A Div Of Syn Bank" },
    { aba: "026011507", name: "Capital One, N.a." },
    { aba: "091409296", name: "Farmers State Bk" },
    { aba: "065405420", name: "B1bank" },
    { aba: "091217226", name: "Kodabank" },
    { aba: "065405417", name: "Iberiabank" },
    { aba: "011275303", name: "Gorham Savings Bank" },
    { aba: "031902931", name: "Univest National Bank" },
    { aba: "084107246", name: "Centennial Bank" },
    { aba: "061104929", name: "The Citizens Bank Of Georgia" },
    { aba: "031001175", name: "Pnc Bank" },
    { aba: "031301053", name: "First National Bank Of Pennsylvania" },
    { aba: "091907882", name: "Flagship Bank Minnesota" },
    { aba: "082900380", name: "Bancorpsouth" },
    { aba: "054001220", name: "Wells Fargo Bank" },
    { aba: "051504319", name: "Pioneer Community Bank, Inc." },
    { aba: "064103105", name: "Peoples Bank & Trust Of Pickett Cty." },
    { aba: "072412972", name: "Independent Bank" },
    { aba: "072411643", name: "Fifth Third Bank" },
    { aba: "044107778", name: "The Union Banking Co" },
    { aba: "031316967", name: "Pncbank, Na" },
    { aba: "071004543", name: "Banco Popular" },
    { aba: "026012881", name: "Wells Fargo Bank" },
    { aba: "091905237", name: "National Bank Of Commerce" },
    { aba: "062201957", name: "River Bank & Trust" },
    { aba: "054001204", name: "Bank Of America, N.a." },
    { aba: "031902928", name: "Univest National Bank" },
    { aba: "057004680", name: "United Bank" },
    { aba: "071004530", name: "Byline Bank" },
    { aba: "091205283", name: "Boundary Waters Bank" },
    { aba: "091905253", name: "Minnwest" },
    { aba: "065405459", name: "Investar Bank, National Association" },
    { aba: "065201446", name: "Home Bank, National Association" },
    { aba: "057004693", name: "Bank Of Charles Town" },
    { aba: "064105734", name: "Wayne County Bank" },
    { aba: "062103453", name: "First National Bank Of Atmore" },
    { aba: "071004527", name: "First Midwest Bank" },
    { aba: "091917254", name: "Community Bank Owatonna" },
    { aba: "071113751", name: "Iroquois Farmers State Bk" },
    { aba: "065202791", name: "Capital One, N.a." },
    { aba: "061102332", name: "The Geo. D. Warthen Bank" },
    { aba: "084205737", name: "Farmers & Merchants Bk" },
    { aba: "082901606", name: "Warren Bk & Tr" },
    { aba: "011575236", name: "Axos Bank" },
    { aba: "084205724", name: "Southern Bancorp Bank" },
    { aba: "091981808", name: "Embarrass Vermillion Cr Union" },
    { aba: "042104168", name: "Whitaker Bank" },
    { aba: "061104987", name: "Ameris Bank" },
    { aba: "071113748", name: "Ipava State Bk" },
    { aba: "031207801", name: "Td Bank, Na" },
    { aba: "092101289", name: "Bank Of Bridger, Na" },
    { aba: "082901619", name: "Farmers & Merchants Bank" },
    { aba: "031901686", name: "Wells Fargo Bank" },
    { aba: "042105484", name: "Jackson County Bank" },
    { aba: "091917270", name: "Falcon National Bank" },
    { aba: "061101029", name: "Bk Of North Ga Div Synovus Bk" },
    { aba: "064008569", name: "Citizens Savings Bank & Trust Co" },
    { aba: "091905295", name: "Citizens State Bank" },
    { aba: "083902798", name: "The Peoples Bank Of Kentucky" },
    { aba: "031912332", name: "National Bank Of Malvern" },
    { aba: "074905872", name: "The Farmers & Merchants State Bank" },
    { aba: "021283615", name: "Hmc (nj) Federal Credit Union" },
    { aba: "031100908", name: "Citibank (south Dakota) Na" },
    { aba: "082901622", name: "Bancorpsouth" },
    { aba: "074006674", name: "The National Bank Of Indianapolis" },
    { aba: "081586637", name: "Table Rock Federal Credit Union" },
    { aba: "042215921", name: "Greenville National Bank" },
    { aba: "074904530", name: "Wayne Bank & Trust Co" },
    { aba: "084205708", name: "Planters Bk & Tr Co" },
    { aba: "071926443", name: "International Bank Of Chicago" },
    { aba: "082901635", name: "Farmers Bank & Trust" },
    { aba: "044107707", name: "Vinton County National Bank" },
    { aba: "091301064", name: "State Bank Of Lakota" },
    { aba: "061104958", name: "United Community Bank" },
    { aba: "031301066", name: "Ameriserv Financial Bank" },
    { aba: "036089257", name: "New Life Federal Credit Union" },
    { aba: "071113719", name: "State Bk Of Industry" },
    { aba: "082900319", name: "First Natl Bk" },
    { aba: "031100924", name: "Wilmington Savings Fund Society Fsb" },
    { aba: "065200188", name: "Capital One, N.a." },
    { aba: "011202392", name: "Katahdin Trust Company" },
    { aba: "082901648", name: "Merchants & Planters Bk" },
    { aba: "053207216", name: "Carolina Bank & Trust Company" },
    { aba: "042104126", name: "Citizens Deposit B & T" },
    { aba: "022304030", name: "Five Star Bank" },
    { aba: "021308642", name: "Tioga State Bank" },
    { aba: "061104945", name: "First Volunteer Bank" },
    { aba: "061019771", name: "Bank Ozk" },
    { aba: "041201512", name: "Park National Bank" },
    { aba: "091301048", name: "The Goose River Bank" },
    { aba: "042105442", name: "First National Bank" },
    { aba: "061020786", name: "Ameris Bank" },
    { aba: "071926430", name: "Illinois Bank & Trust" },
    { aba: "083902756", name: "Independence Bank Of Kentucky" },
    { aba: "021309997", name: "Citizens Bank Of Cape Vincent" },
    { aba: "086503424", name: "Mid-missouri Bank" },
    { aba: "083901414", name: "First Harrison Bank, Corydon" },
    { aba: "073903419", name: "Bank Iowa" },
    { aba: "071902399", name: "1st Natl Bank Of Ottawa" },
    { aba: "064202983", name: "Commercial Bk" },
    { aba: "041212145", name: "Farmers National Bank Of Canfield" },
    { aba: "073904751", name: "Mount Vernon Bank & Trust Co" },
    { aba: "071926375", name: "Providence Bank & Trust" },
    { aba: "031206420", name: "Oceanfirst Bank, National Associatio" },
    { aba: "083008508", name: "Eclipse Bank" },
    { aba: "071123149", name: "Senb Bank" },
    { aba: "064103095", name: "Liberty State Bk Brch Of Citizens Bk" },
    { aba: "061212727", name: "The Peoples Bank" },
    { aba: "071913058", name: "Solutions Bank" },
    { aba: "083902727", name: "Elkton Bank & Trust Co." },
    { aba: "071926362", name: "Busey Bank" },
    { aba: "071925033", name: "Inland Bank & Trust" },
    { aba: "071925017", name: "First Eagle Bank" },
    { aba: "053111810", name: "First Horizon Bank" },
    { aba: "022306782", name: "Citizens & Northern Bank" },
    { aba: "084203137", name: "Renesant Bank" },
    { aba: "091303664", name: "Dakota Community Bank & Trust, Na" },
    { aba: "071914358", name: "Resource Bank N A" },
    { aba: "071926333", name: "1st Equity Bank" },
    { aba: "091505093", name: "Mbank" },
    { aba: "053012197", name: "South State Bank" },
    { aba: "026590798", name: "Fannie Mae" },
    { aba: "083902701", name: "Citizens Deposit B & T" },
    { aba: "064103079", name: "Regions Bank" },
    { aba: "081512371", name: "First Midwest Bank Of The Ozarks" },
    { aba: "071113793", name: "State Bank Of Bement" },
    { aba: "043307508", name: "Nextier Bank N.a." },
    { aba: "075918198", name: "Blackhawk Bank" },
    { aba: "091281773", name: "Trustar Federal Credit Union" },
    { aba: "073916778", name: "Leighton State Bank" },
    { aba: "084205766", name: "Renasant Bank" },
    { aba: "091001157", name: "Bmo Harris Bank Na" },
    { aba: "073915449", name: "State Savings Bank" },
    { aba: "075918185", name: "Incrediblebank" },
    { aba: "073902151", name: "First Whitney Bank & Trust" },
    { aba: "021404465", name: "Bank Of America, N.a." },
    { aba: "061101032", name: "Community National Bank" },
    { aba: "061103690", name: "Synovus Bank" },
    { aba: "061092387", name: "Jpmorgan Chase" },
    { aba: "061113017", name: "United Community Bank, Inc" },
    { aba: "081512355", name: "Oakstar Bank" },
    { aba: "026084589", name: "East River Development Alliance Fcu" },
    { aba: "071112480", name: "First Trust Bank Of Illinois" },
    { aba: "092101360", name: "First St Bk" },
    { aba: "073903493", name: "Home Trust & Savings Bank" },
    { aba: "011600774", name: "Bar Harbor Bank & Trust" },
    { aba: "081501696", name: "Callaway Bank" },
    { aba: "043307524", name: "Nextier Bank N.a." },
    { aba: "071926391", name: "Illinois Bank & Trust" },
    { aba: "041200144", name: "Pnc Bank, Na" },
    { aba: "071925062", name: "Bmo Harris Bank,n.a." },
    { aba: "075906197", name: "American Bank Of Beaver Dam" },
    { aba: "071925046", name: "Fifth Third Bank" },
    { aba: "071913087", name: "The First National Bank In Amboy" },
    { aba: "091014487", name: "Bridgewater Bank" },
    { aba: "071123123", name: "Blackhawk Bank & Trust" },
    { aba: "075907497", name: "First State Bank" },
    { aba: "073902180", name: "Great Southern Bank" },
    { aba: "075906171", name: "Citizens Bank Of Mukwonago" },
    { aba: "061092332", name: "Cornerstone Bank" },
    { aba: "071004501", name: "Federal Home Loan Bank" },
    { aba: "091809472", name: "Shell Lake State Bank" },
    { aba: "072412927", name: "Jpmorgan Chase, Dearborn" },
    { aba: "084107181", name: "Riverwindbank" },
    { aba: "061211472", name: "Georgia Community Bank" },
    { aba: "052173464", name: "M & T Bank" },
    { aba: "051504254", name: "Pendleton Community Bank, Inc." },
    { aba: "052100547", name: "Pnc Bank Na - Baltimore" },
    { aba: "073915494", name: "Readlyn Savings Bank" },
    { aba: "021683794", name: "Frederiksted Fcu" },
    { aba: "075918130", name: "Bank First N.a." },
    { aba: "062201847", name: "Liberty Bank Of Geraldine" },
    { aba: "063192269", name: "Republic Bank & Trust Co." },
    { aba: "065405491", name: "Citizens Bank & Trust Company" },
    { aba: "064105666", name: "Union Bank & Trust Co." },
    { aba: "091811758", name: "Bmo Harris Bank Na" },
    { aba: "063106352", name: "Community State Bank" },
    { aba: "067011760", name: "First Horizon Bank" },
    { aba: "092903270", name: "First State Bank Of Forsyth" },
    { aba: "021207044", name: "Valley National Bank" },
    { aba: "065404159", name: "The Bank Of Commerce" },
    { aba: "021683781", name: "Christiansted Federal Cr Union" },
    { aba: "075906142", name: "Bmo Harris Bank, N.a" },
    { aba: "075906126", name: "Greenwoods State Bank" },
    { aba: "063107610", name: "Bank Of America, N.a." },
    { aba: "053111836", name: "First Community Bank, Na" },
    { aba: "061211427", name: "Barwick Banking Company" },
    { aba: "064201612", name: "Simmons Bank" },
    { aba: "043404647", name: "Main Street Bank Corp" },
    { aba: "063107623", name: "Centennial Bank" },
    { aba: "075907442", name: "Citizens Community Federal Na" },
    { aba: "061211430", name: "Great Oaks Bank" },
    { aba: "056004720", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "021207028", name: "Santander Bank" },
    { aba: "021382642", name: "Stewart's Federal Credit Union" },
    { aba: "084301518", name: "Centennial Bank" },
    { aba: "044201030", name: "Hocking Valley Bank" },
    { aba: "092904570", name: "Stockman Bank Of Montana" },
    { aba: "061211443", name: "Durden Banking Co., Inc." },
    { aba: "042310525", name: "Minster Bank" },
    { aba: "081226298", name: "Town & Country Bank" },
    { aba: "064202967", name: "Civis Bank" },
    { aba: "082008583", name: "Encore Bank" },
    { aba: "053000196", name: "Bank Of America, N.a." },
    { aba: "064104379", name: "First Horizon Bank" },
    { aba: "021382655", name: "Oswego County Public F.c.u." },
    { aba: "091906508", name: "Kensington Bank" },
    { aba: "053111849", name: "First Charter Bank" },
    { aba: "092904554", name: "U.s. Bank" },
    { aba: "062201892", name: "First State Bank" },
    { aba: "081909722", name: "1st Natl Bk Steeleville" },
    { aba: "067008155", name: "City National Bank Florida" },
    { aba: "021207002", name: "Santander Bank" },
    { aba: "053000183", name: "Wells Fargo Bank" },
    { aba: "071002053", name: "Hsbc Bank Usa" },
    { aba: "026012713", name: "Turkiye Vakiflar T.a.o." },
    { aba: "084107343", name: "Southern Bancorp Bank" },
    { aba: "065202830", name: "Peoples Bk&tr Of Pointe Coupee Prsh" },
    { aba: "051504429", name: "Whitesville State Bank" },
    { aba: "062103592", name: "First Us Bank" },
    { aba: "075909945", name: "Park Bank" },
    { aba: "075908616", name: "Oregon Community Bank & Trust" },
    { aba: "082901567", name: "Union Bk & Tr Co" },
    { aba: "071002040", name: "Tcf National Bank Illinois" },
    { aba: "082901570", name: "Bancorpsouth" },
    { aba: "054000030", name: "Pnc Bank, Na" },
    { aba: "091205102", name: "Community Development Bank Fsb" },
    { aba: "062102292", name: "First National Bank Of Brundidge" },
    { aba: "075910921", name: "Farmers And Merchants Bk" },
    { aba: "052002166", name: "Citibank Maryland, N.a." },
    { aba: "021101425", name: "Td Bank Na" },
    { aba: "075017947", name: "Bmo Harris Bank,n.a." },
    { aba: "071002024", name: "Bank Leumi Usa" },
    { aba: "065201530", name: "Farmers-merchants Bank And" },
    { aba: "031100869", name: "Wells Fargo Bank" },
    { aba: "036018985", name: "Noah Bank" },
    { aba: "064105815", name: "Citizens Tri-county Bank" },
    { aba: "091205128", name: "American St Bk Grygla" },
    { aba: "051504432", name: "First Community Bank N A" },
    { aba: "043305092", name: "Commercial Bank And Trust" },
    { aba: "051503103", name: "Miners & Merchants Bank" },
    { aba: "051407296", name: "Virginia Bank & Trust Co." },
    { aba: "084106027", name: "Fnb Of Lawrence County" },
    { aba: "064103228", name: "First National Bank Of Pulaski" },
    { aba: "081921551", name: "First National Bank Of Nokomis" },
    { aba: "063214312", name: "Pnc Bank, National Association" },
    { aba: "091904018", name: "First National Bank" },
    { aba: "031303763", name: "Truist Bank" },
    { aba: "091905347", name: "Stearns Bank N.a." },
    { aba: "031100872", name: "County Bank" },
    { aba: "082901596", name: "First State Bank" },
    { aba: "054001314", name: "Premier Bank, Inc." },
    { aba: "091408035", name: "Reliabank Dakota" },
    { aba: "021114771", name: "Bankwell Bank" },
    { aba: "036018969", name: "Hyperion Bank" },
    { aba: "062102221", name: "Friend Bank" },
    { aba: "021101438", name: "Webster Bank" },
    { aba: "091905363", name: "Farmers & Merchants Savings Bank" },
    { aba: "063192214", name: "Tampa Bay Federal Credit Union" },
    { aba: "031302447", name: "Pnc Bank, Na" },
    { aba: "065405349", name: "Iberiabank" },
    { aba: "081501803", name: "Sullivan Bank" },
    { aba: "091917351", name: "Lakeview Bank" },
    { aba: "063106268", name: "Bank Of America, N.a." },
    { aba: "091409380", name: "Campbell County Bank-pollock Office" },
    { aba: "084301479", name: "Centennial Bank" },
    { aba: "091917364", name: "Western National Bank" },
    { aba: "065200243", name: "Capital One, N.a." },
    { aba: "031205081", name: "Fulton Bank, Na" },
    { aba: "091904050", name: "Pioneer Bank - Mapleton Office" },
    { aba: "083974373", name: "South Central Bank, Inc." },
    { aba: "071005979", name: "Cathay Bank" },
    { aba: "081908590", name: "Peoples Bank Of Altenburg" },
    { aba: "044106588", name: "Richwood Banking Co." },
    { aba: "041202744", name: "Croghan Colonial Bank" },
    { aba: "065201585", name: "Hancock Whitney Bank" },
    { aba: "084205614", name: "Holmes County Bank" },
    { aba: "091916048", name: "Security Bank And Trust Company" },
    { aba: "026009140", name: "State Bank Of India" },
    { aba: "061104877", name: "First Bank" },
    { aba: "074903353", name: "First Merchants Bank" },
    { aba: "091000022", name: "Us Bank Na" },
    { aba: "071926511", name: "Leaders Bank" },
    { aba: "091916051", name: "Harvest Bank" },
    { aba: "081919262", name: "The Bank Of Missouri" },
    { aba: "061103535", name: "Synovus Bank" },
    { aba: "026011484", name: "Amerasia Bank" },
    { aba: "071926508", name: "Byline Bank" },
    { aba: "036018914", name: "Morebank" },
    { aba: "074903337", name: "First Financial Bank" },
    { aba: "061020867", name: "South State Bank, Na" },
    { aba: "061103564", name: "Synovus Bank" },
    { aba: "062102218", name: "Bank Ozk" },
    { aba: "061104893", name: "Quantum National Bank" },
    { aba: "091205199", name: "Red River State Bank" },
    { aba: "041202760", name: "Middlefield Banking Company" },
    { aba: "091916077", name: "First Farmers & Merchants Bank" },
    { aba: "053003931", name: "Truist Bank" },
    { aba: "071102995", name: "Bmo Harris Bank,n.a." },
    { aba: "071126955", name: "The First National Bank" },
    { aba: "061104880", name: "Greater Community Bank" },
    { aba: "073905912", name: "Rolling Hills Bank & Trust" },
    { aba: "091409351", name: "State Bank Of Eagle Butte" },
    { aba: "041202702", name: "Northwest Savings Bank" },
    { aba: "091001322", name: "United Banker's Bank" },
    { aba: "071002095", name: "Liberty Bank & Trust Company" },
    { aba: "084108630", name: "First Security State Bk" },
    { aba: "074904640", name: "Fcn Bank National Association" },
    { aba: "071925240", name: "Associated Bank Green Bay" },
    { aba: "031301192", name: "M & T Bank" },
    { aba: "071926553", name: "Centrust Bank" },
    { aba: "071925224", name: "Bmo Harris Bank,n.a." },
    { aba: "091916093", name: "Security State Bank" },
    { aba: "053207339", name: "The Citizens Bank" },
    { aba: "061104822", name: "Bank Ozk" },
    { aba: "064008611", name: "Insbank" },
    { aba: "042104249", name: "Pnc Bank, Na" },
    { aba: "074903308", name: "First Financial Bank" },
    { aba: "021283547", name: "Affinity Federal Credit Union" },
    { aba: "091001319", name: "Union Bank And Trust Co" },
    { aba: "082901538", name: "First Security Bk" },
    { aba: "091014652", name: "Farmers St Bk Of Hamel" },
    { aba: "081204867", name: "Legence Bank" },
    { aba: "091000019", name: "Wells Fargo Bank Na (minnesota)" },
    { aba: "021308781", name: "Community Bank, N.a." },
    { aba: "061104848", name: "Metro City Bank" },
    { aba: "064008637", name: "Pinnacle Bank" },
    { aba: "071114938", name: "Scott State Bank" },
    { aba: "081204854", name: "First Southern Bank" },
    { aba: "083901537", name: "Monticello Banking Co., Albany" },
    { aba: "071925156", name: "Fifth Third Bank" },
    { aba: "081216758", name: "Community First Bk Of The Heartland" },
    { aba: "064202860", name: "Andrew Johnson Bank" },
    { aba: "081501793", name: "Great Southern Bank" },
    { aba: "031207636", name: "Fulton Bank, Na" },
    { aba: "073904638", name: "Dewitt Bank & Tr Co" },
    { aba: "067011825", name: "Iberiabank" },
    { aba: "031207649", name: "Fulton Bank, Na" },
    { aba: "053207371", name: "Bank Of Travelers Rest" },
    { aba: "081511110", name: "Bank Of Monticello" },
    { aba: "011805388", name: "Adams Community Bank" },
    { aba: "036089189", name: "Paperconverters Local286/1034 Fcu" },
    { aba: "073903312", name: "First Security Bank & Trust Co" },
    { aba: "096017670", name: "Platinum Bank" },
    { aba: "081500477", name: "F & M Bank And Trust Co" },
    { aba: "091050234", name: "The Bank Of Nova Scotia" },
    { aba: "067011812", name: "Intercredit Bank, N.a." },
    { aba: "082974471", name: "United Bank" },
    { aba: "073903325", name: "First Bank Hampton" },
    { aba: "071926472", name: "The Lincoln Way Community Bank" },
    { aba: "074902082", name: "Citizens State Bank" },
    { aba: "071926456", name: "Republic Bk Of Chicago" },
    { aba: "064202873", name: "Johnson County Bank" },
    { aba: "081501780", name: "Kahoka State Bank" },
    { aba: "041200050", name: "Fifth Third Bank" },
    { aba: "071925130", name: "Fifth Third Bank" },
    { aba: "071124371", name: "1st State Bank Of Bloomington" },
    { aba: "026084482", name: "New Covenant Dominion Fcu" },
    { aba: "073916668", name: "Pilot Grove Savings Bank" },
    { aba: "084205656", name: "Renasant Bank" },
    { aba: "075918075", name: "Nicolet National Bank" },
    { aba: "053012087", name: "First Horizon Bank" },
    { aba: "052100699", name: "Shore United Bank" },
    { aba: "083902811", name: "First Financial Bank" },
    { aba: "061201974", name: "Plantersfirst" },
    { aba: "084205643", name: "Renasant Bank" },
    { aba: "031207607", name: "Pnc Bank, New Jersey" },
    { aba: "064201560", name: "First Horizon Bank" },
    { aba: "091001270", name: "Associated Bk Mn" },
    { aba: "073903354", name: "West Bank" },
    { aba: "041201321", name: "Henry County Bank" },
    { aba: "075906087", name: "Bank Of Milton" },
    { aba: "084204301", name: "Community Bank Of Ms" },
    { aba: "091809524", name: "Oakwood Bank" },
    { aba: "026084466", name: "Actors Federal Credit Union" },
    { aba: "081500480", name: "Hnb National Bank" },
    { aba: "073904696", name: "Kerndt Bros Savings Bank" },
    { aba: "073903367", name: "First National Bank, Ames" },
    { aba: "073915342", name: "Grinnell State Bank" },
    { aba: "071112354", name: "Peoples State Bank Colfax" },
    { aba: "067010512", name: "Banesco Usa" },
    { aba: "071925169", name: "Fifth Third Bank" },
    { aba: "073903383", name: "Mid States Bank" },
    { aba: "071124339", name: "Community St Bk Rock Falls" },
    { aba: "081500493", name: "Commerce Bank" },
    { aba: "021502914", name: "Oriental Bank And Trust" },
    { aba: "075906061", name: "Bmo Harris Bank, N.a" },
    { aba: "067010509", name: "Amerant Bank, N.a." },
    { aba: "071925172", name: "First Midwest Bank" },
    { aba: "081214174", name: "Town & Country Bank Midwest" },
    { aba: "075918033", name: "Incrediblebank" },
    { aba: "067010583", name: "Regions Bank" },
    { aba: "061211346", name: "Citizens Bk Of Cochran" },
    { aba: "051503051", name: "First Nat Bk Of Peterstown" },
    { aba: "031302515", name: "Riverview Bank" },
    { aba: "063192120", name: "Synovus Bank" },
    { aba: "021502930", name: "Oriental Bank And Trust" },
    { aba: "031314503", name: "Ps Bank" },
    { aba: "062201999", name: "Bankfirst Financial Services" },
    { aba: "061212688", name: "Morris Bank" },
    { aba: "011600622", name: "People's United Bank, N.a." },
    { aba: "083008469", name: "River City Bank" },
    { aba: "031302528", name: "Woodlands Bank" },
    { aba: "021101470", name: "Bank Of New York Mellon" },
    { aba: "075918017", name: "Commerce State Bank" },
    { aba: "051504377", name: "Freedom Bank, Inc." },
    { aba: "075907374", name: "River Bank" },
    { aba: "044215521", name: "Settlers Bank" },
    { aba: "075908687", name: "Mound City Bank" },
    { aba: "066012333", name: "Terrabank N A" },
    { aba: "021114784", name: "Bankwell Bank" },
    { aba: "043487046", name: "Natrium Employees Federal Cu" },
    { aba: "031207694", name: "Crown Bank" },
    { aba: "065405378", name: "Hancock Whitney Bank" },
    { aba: "021502943", name: "Oriental Bank And Trust" },
    { aba: "075906032", name: "Black River Country Bank" },
    { aba: "075907345", name: "First Citizens State Bank" },
    { aba: "061200658", name: "The Carver State Bank" },
    { aba: "063107568", name: "Brannen Bank" },
    { aba: "051904524", name: "City National Bank Of West Virginia" },
    { aba: "051085427", name: "Henrico Fcu" },
    { aba: "081214161", name: "Liberty Bank" },
    { aba: "075910992", name: "Intercity State Bank" },
    { aba: "075906016", name: "The Peoples Community Bank" },
    { aba: "073902096", name: "Fnnb Bank" },
    { aba: "065405365", name: "Investar Bank, National Association" },
    { aba: "075906003", name: "Bmo Harris Bank, N.a" },
    { aba: "081000993", name: "First State Bk St Charles" },
    { aba: "061211304", name: "Guardian Bank" },
    { aba: "041200089", name: "Key Bank" },
    { aba: "064202828", name: "First Peoples Bank Of Tennessee" },
    { aba: "031207678", name: "1st Constitution Bank" },
    { aba: "075908658", name: "Peoples State Bank" },
    { aba: "074914067", name: "Horizon Bk, An Indiana Bank" },
    { aba: "081512407", name: "The Maries County Bank" },
    { aba: "061211317", name: "Peo Bk Lyons Ga" },
    { aba: "052101957", name: "The Queenstown Bank Of Maryland" },
    { aba: "063107513", name: "Wells Fargo Bank" },
    { aba: "066014959", name: "Safra National Bank N.y." },
    { aba: "053110400", name: "Wells Fargo Bank" },
    { aba: "081012952", name: "Enterprise Bank & Trust" },
    { aba: "064202844", name: "Farmers State Bank" },
    { aba: "053012029", name: "Regions Bank" },
    { aba: "081501735", name: "Central Bank" },
    { aba: "031207652", name: "Investors Bank" },
    { aba: "021052053", name: "Joint Upic Account" },
    { aba: "091206402", name: "Northern State Bank" },
    { aba: "081000980", name: "Bmo Harris Bank Na" },
    { aba: "042212050", name: "First State Bank Adams Co." },
    { aba: "063192191", name: "Third Federal" },
    { aba: "042213389", name: "Farmers And Merchants Bank" },
    { aba: "084301408", name: "Insouth Bank" },
    { aba: "054000056", name: "The National Capital Bank Of Wash" },
    { aba: "031975984", name: "Firstrust Bank" },
    { aba: "081010307", name: "Bank Star" },
    { aba: "084203043", name: "Renasant Bank" },
    { aba: "053111726", name: "First Charter Bank" },
    { aba: "052101931", name: "Provident State Bank, Inc." },
    { aba: "083974412", name: "The Murray Bank" },
    { aba: "071903929", name: "Fnbc Bank & Trust" },
    { aba: "062102399", name: "Town Country Nat'l Bank" },
    { aba: "021307520", name: "Nbt Bank Na" },
    { aba: "091906760", name: "First Security Bank" },
    { aba: "065404340", name: "First Guaranty Bank" },
    { aba: "063205923", name: "The Warrington Bank" },
    { aba: "031302117", name: "Santander Bank" },
    { aba: "065201624", name: "Landmark Bank" },
    { aba: "084301372", name: "First Horizon Bank" },
    { aba: "091216104", name: "Integrity Bank" },
    { aba: "075907646", name: "State Bk Of Cross Plains" },
    { aba: "082901444", name: "Us Bank Na" },
    { aba: "062102386", name: "Community Neighbor Bank" },
    { aba: "091905444", name: "Minnesota Lakes Bank" },
    { aba: "053110374", name: "First Horizon Bank" },
    { aba: "091216133", name: "Border Bank" },
    { aba: "021272794", name: "Investors Bank" },
    { aba: "042200279", name: "Pnc Bank, Na" },
    { aba: "075906304", name: "Bristol Morgan Bank" },
    { aba: "067010169", name: "Iberiabank" },
    { aba: "071125914", name: "State Bank" },
    { aba: "071002150", name: "State Bk Of India" },
    { aba: "031302133", name: "Fulton Bank, Na" },
    { aba: "082901460", name: "First State Bank Of De Queen" },
    { aba: "083986662", name: "German American Bank" },
    { aba: "071002147", name: "Associated Bank Chicago" },
    { aba: "065191721", name: "Gulf Coast Federal Credit Union" },
    { aba: "011300605", name: "Bank Of America N.a." },
    { aba: "044084354", name: "Pathways Financial Credit Union,inc" },
    { aba: "051409595", name: "Cornerstone Bank, N.a." },
    { aba: "071002134", name: "Bank Of Hope" },
    { aba: "053111674", name: "United Community Bank, Inc" },
    { aba: "026011785", name: "Chinatrust Bank (u.s.a.)" },
    { aba: "021308817", name: "Community Bank, Na" },
    { aba: "043318092", name: "First National Bank Of Pennsylvania" },
    { aba: "065201666", name: "Cottonport Bank" },
    { aba: "051409582", name: "Oak View National Bank" },
    { aba: "091216146", name: "Northern State Bank" },
    { aba: "091409005", name: "Leola Office Cortrust Bank" },
    { aba: "063105285", name: "United Southern Bank" },
    { aba: "051409579", name: "Virginia Partners Bank" },
    { aba: "051904676", name: "Premier Bank, Inc" },
    { aba: "091400680", name: "Bank Of The West" },
    { aba: "061211281", name: "Rochelle State Bank" },
    { aba: "091409018", name: "Campbell County Bank, Inc." },
    { aba: "075908920", name: "Woodford State Bank" },
    { aba: "021308833", name: "Wayne Bank" },
    { aba: "042202895", name: "Citizens Deposit B & T" },
    { aba: "051409566", name: "Frontier Community Bank" },
    { aba: "053111690", name: "Uwharrie Bank" },
    { aba: "063216608", name: "Rbc Bank (ga)" },
    { aba: "071902629", name: "First National Bank Of Omaha" },
    { aba: "062101031", name: "Troy Bank & Trust Co." },
    { aba: "084302698", name: "Centennial Bank" },
    { aba: "065404353", name: "First Guaranty Bank" },
    { aba: "071110000", name: "Vermilion Valley Bank" },
    { aba: "021584217", name: "Cooperativa De A/c Los Hermanos" },
    { aba: "091917490", name: "Lakeview Bank" },
    { aba: "091916161", name: "Security Bank & Trust Glencoe" },
    { aba: "031000037", name: "Bank Of New York Mellon" },
    { aba: "071926650", name: "St. Charles Bank & Trust Co. N. A." },
    { aba: "071112668", name: "Better Banks" },
    { aba: "071111339", name: "Farmers State Bank Of Western Ill" },
    { aba: "051409540", name: "The Bank Of Southside Va" },
    { aba: "071926634", name: "Town Center Bank" },
    { aba: "071113984", name: "1st Natl Bk Lacon" },
    { aba: "021272749", name: "Connectone Bank" },
    { aba: "051409537", name: "First Sentinel Bank" },
    { aba: "071109037", name: "1st Community Bank" },
    { aba: "031000011", name: "Wells Fargo Bank" },
    { aba: "071124669", name: "First St Bank Of Mason Cty" },
    { aba: "084002781", name: "First Horizon Bank" },
    { aba: "051409524", name: "Vcc Bank" },
    { aba: "021272723", name: "Jpmorgan Chase" },
    { aba: "071926618", name: "Byline Bank" },
    { aba: "083900363", name: "Us Bank Na" },
    { aba: "081910588", name: "First State Community Bank" },
    { aba: "083901692", name: "First Southern National Bank" },
    { aba: "081908273", name: "Silex Banking Co" },
    { aba: "071101967", name: "Busey Bank" },
    { aba: "061104712", name: "Regions Bank" },
    { aba: "091401919", name: "Citizens State Bank" },
    { aba: "041215906", name: "Cortland Savings And Banking Company" },
    { aba: "071926692", name: "Peoplefirst Bank" },
    { aba: "071926676", name: "Busey Bank" },
    { aba: "021307559", name: "Community Bank N.a." },
    { aba: "082901402", name: "Farmers Bank & Trust" },
    { aba: "091401922", name: "Valley Exchange Bank" },
    { aba: "011301992", name: "Bankfive" },
    { aba: "071925350", name: "Fifth Third Bank" },
    { aba: "071026123", name: "The Federal Savings Bank" },
    { aba: "071111313", name: "Philo Exchange Bank" },
    { aba: "091000132", name: "Northeast Bank" },
    { aba: "071925334", name: "Lake Forest Bank & Tr Co" },
    { aba: "071924005", name: "Bmo Harris Bank,n.a." },
    { aba: "031000053", name: "Pncbank, National Association" },
    { aba: "061103409", name: "Persons Banking Company" },
    { aba: "092101085", name: "Citizens Bank & Trust Big Timber" },
    { aba: "091400606", name: "First Bank & Trust" },
    { aba: "083902992", name: "Jackson County Bank" },
    { aba: "082902757", name: "Centennial Bank" },
    { aba: "082901428", name: "Simmons First National Bank" },
    { aba: "011600486", name: "People's United Bank, N.a." },
    { aba: "071124614", name: "Bank Champaign" },
    { aba: "031000040", name: "Federal Reserve Bank" },
    { aba: "071101996", name: "Busey Bank" },
    { aba: "073904502", name: "Corydon State Bank" },
    { aba: "075001047", name: "First-citizens Bank & Trust Co" },
    { aba: "071125943", name: "Preferred Bank" },
    { aba: "083901650", name: "Wesbanco Bank, Inc." },
    { aba: "072410165", name: "First State Bk Of East Detroit" },
    { aba: "026083470", name: "Local 804 F.c.u." },
    { aba: "042105785", name: "United Cumberland Bank" },
    { aba: "036089053", name: "University Of Penna Student Fcu" },
    { aba: "091302597", name: "Security State Bank" },
    { aba: "092902132", name: "Manhattan Bank" },
    { aba: "011104199", name: "People's United Bank, N.a." },
    { aba: "071123398", name: "Community Bank Of Easton" },
    { aba: "021584291", name: "Coop. A/c Dept. Agricultura Pr" },
    { aba: "081203318", name: "Bank Of Herrin" },
    { aba: "041202582", name: "U.s. Bank National Association" },
    { aba: "092904774", name: "Glacier Bank" },
    { aba: "071123385", name: "First Financial Bank" },
    { aba: "071122056", name: "Princeville State Bank" },
    { aba: "031205340", name: "Newfield National Bank" },
    { aba: "081000605", name: "Cass Commercial Bank" },
    { aba: "063106501", name: "Vystar Credit Union" },
    { aba: "031207979", name: "Lakeland Bank" },
    { aba: "084205559", name: "Bankplus" },
    { aba: "064202763", name: "Sevier County Bank" },
    { aba: "011600570", name: "Td Bank Na" },
    { aba: "041215883", name: "Liberty National Bank" },
    { aba: "084204220", name: "Bank Of Mississippi" },
    { aba: "021584275", name: "Cooperativa De A/c Uti De Pr" },
    { aba: "083901621", name: "Citizens Union Bank" },
    { aba: "073904531", name: "South Ottumwa Savings Bank" },
    { aba: "061102196", name: "Synovus Bank" },
    { aba: "092904761", name: "Little Horn State Bank" },
    { aba: "011600567", name: "Peo Tr Co St Albans" },
    { aba: "096017337", name: "Gateway Bank" },
    { aba: "064104162", name: "Bank Of Perry County" },
    { aba: "073905873", name: "Security National Bank" },
    { aba: "071913278", name: "Grand Ridge National Bank" },
    { aba: "071926582", name: "Old Plank Trail Community Bank Na" },
    { aba: "083901618", name: "Commonwealth Bank And Trust Company" },
    { aba: "071110042", name: "Bankorion" },
    { aba: "022306960", name: "Community Bank, N.a" },
    { aba: "073916545", name: "First Trust & Savgs Bk" },
    { aba: "073904560", name: "Bank 1st" },
    { aba: "071111371", name: "Anchor St Bk" },
    { aba: "064201450", name: "First Horizon Bank" },
    { aba: "061102138", name: "United Bank" },
    { aba: "052101669", name: "Hebron Savings Bank" },
    { aba: "041202540", name: "First Bank Of Ohio" },
    { aba: "061201851", name: "American Banking Company Dba Ameris" },
    { aba: "073916532", name: "Farmers Savings Bank" },
    { aba: "091107022", name: "Range Bank - Copper Country N.a." },
    { aba: "091808347", name: "Waumandee State Bank" },
    { aba: "028000325", name: "Bank Of America, N.a." },
    { aba: "042104401", name: "Citizens National Bank" },
    { aba: "061103454", name: "United Community Bank,inc" },
    { aba: "031207966", name: "First Bank" },
    { aba: "031207937", name: "Oceanfirst Bank, National Associatio" },
    { aba: "073903244", name: "Farmers & Merchants Savings Bank" },
    { aba: "096017382", name: "Cortrust Bank National Association" },
    { aba: "071109082", name: "First Neighbor Bank N.a." },
    { aba: "041203895", name: "Pnc Bank, Na" },
    { aba: "067012811", name: "Valley National Bank" },
    { aba: "073917887", name: "Community State Bank" },
    { aba: "091303871", name: "State Bank Of Bottineau" },
    { aba: "091810623", name: "Frandsen Bank & Trust" },
    { aba: "064201463", name: "Community Trust Bank, Inc" },
    { aba: "091000080", name: "Federal Reserve Bank Of Minneapolis" },
    { aba: "041215854", name: "Waterford Bank, N.a." },
    { aba: "011600525", name: "Td Bank Na" },
    { aba: "031207940", name: "The Bank Of Princeton" },
    { aba: "071122030", name: "Bank Of Stronghurst" },
    { aba: "043404443", name: "Union Bank Inc" },
    { aba: "053110303", name: "Wells Fargo Bank" },
    { aba: "081000676", name: "Midwest Bankcentre" },
    { aba: "053111632", name: "Truist Bank" },
    { aba: "044072311", name: "The Union Bank Company" },
    { aba: "065382514", name: "Hope Federal Credit Union" },
    { aba: "067012882", name: "Pnc Bank, National Association" },
    { aba: "091206703", name: "First Farmers & Merchants State Bank" },
    { aba: "044072308", name: "Middlefield Banking Co" },
    { aba: "064106775", name: "Bank Of Frankewing" },
    { aba: "091303855", name: "Lincoln State Bank" },
    { aba: "044072324", name: "Axos Bank" },
    { aba: "065103243", name: "Citizens' Bank Inc" },
    { aba: "043308620", name: "First National Bank Of Pa" },
    { aba: "053111658", name: "First Charter Bank" },
    { aba: "051904634", name: "West Virginia State Treasurer" },
    { aba: "075905059", name: "Nicolet National Bank" },
    { aba: "031316815", name: "1st Summit Bank" },
    { aba: "084301314", name: "Bankcorp South" },
    { aba: "073903286", name: "Cusb Bank" },
    { aba: "071003405", name: "Amalgamated Bank Chgo" },
    { aba: "091808363", name: "Unity Bank" },
    { aba: "043309975", name: "First National Bank Of Pennsylvania" },
    { aba: "061201864", name: "Citizens National Bank" },
    { aba: "075905033", name: "Denmark State Bank" },
    { aba: "061212510", name: "Synovus Bank" },
    { aba: "064202705", name: "Bank Of Tennessee" },
    { aba: "091206745", name: "First National Bank Of Buhl" },
    { aba: "075918334", name: "Spring Bank" },
    { aba: "091906731", name: "Cenbank" },
    { aba: "075906362", name: "The Bank Of Brodhead" },
    { aba: "044072337", name: "First Financial Bank" },
    { aba: "075906346", name: "Oostburg State Bank" },
    { aba: "084205591", name: "Bank Of Okolona" },
    { aba: "091905402", name: "Financial Security Bank" },
    { aba: "043310980", name: "Community Bank" },
    { aba: "011104131", name: "People's United Bank, N.a." },
    { aba: "043308691", name: "Somerset Trust Co." },
    { aba: "091204103", name: "Valley Premier Bank" },
    { aba: "075905004", name: "Bank Of Deerfield" },
    { aba: "067012895", name: "South State Bank, Na" },
    { aba: "084302614", name: "Regions Bank" },
    { aba: "052102901", name: "The Farmers Bank" },
    { aba: "026008206", name: "Mizuho Corporate Bank, Ltd" },
    { aba: "075918318", name: "Byline Bank" },
    { aba: "065201611", name: "Hancock Whitney Bank" },
    { aba: "072400670", name: "Pnc Bank, Na" },
    { aba: "091916200", name: "Franklin State Bank" },
    { aba: "082903976", name: "Bankcorpsouth" },
    { aba: "031315544", name: "Susquehanna Community Bank" },
    { aba: "065405556", name: "Metaire Bank & Trust" },
    { aba: "065201750", name: "Bank Of Erath" },
    { aba: "061211197", name: "Farmers State Bank" },
    { aba: "072413971", name: "Cibc Bank Usa" },
    { aba: "082901334", name: "Southern Bancorp Bank" },
    { aba: "082902663", name: "Peoples Bank" },
    { aba: "072410013", name: "Tcf National Bank" },
    { aba: "044106397", name: "Citizens National Bank" },
    { aba: "065405527", name: "Investar Bank, National Association" },
    { aba: "083986549", name: "Jefferson Community Fcu" },
    { aba: "091216007", name: "First Farmers & Merchants Natl Bk" },
    { aba: "067009280", name: "First Horizon Bank" },
    { aba: "044106384", name: "First National Bank" },
    { aba: "072413997", name: "Independent Bank" },
    { aba: "071002257", name: "Banco Popular" },
    { aba: "082902689", name: "Simmons Fnb" },
    { aba: "082902692", name: "Bank Of Gravett" },
    { aba: "091904267", name: "First State Bank Of Le Center" },
    { aba: "053112880", name: "Coastal Bank & Trust" },
    { aba: "054001550", name: "United Bank" },
    { aba: "065201789", name: "B1bank" },
    { aba: "064104311", name: "One Bank Of Tennessee" },
    { aba: "072413942", name: "Choiceone Bank" },
    { aba: "021908288", name: "Bank Of New York Mellon" },
    { aba: "091916255", name: "Equity Bank" },
    { aba: "091409128", name: "Cortrust Bank-yankton-tabor Branch" },
    { aba: "072413939", name: "Waterford Bank, N.a." },
    { aba: "063192450", name: "Trustco Bank" },
    { aba: "091904270", name: "First National Bank" },
    { aba: "065404256", name: "Capital One, N.a." },
    { aba: "072413968", name: "Isabella Bank" },
    { aba: "092005518", name: "Glacier Bank- Valley Bk Helena Div" },
    { aba: "053111577", name: "Pinnacle Bank" },
    { aba: "061211168", name: "Ameris Bank" },
    { aba: "082901376", name: "First Naturalstate Bank" },
    { aba: "054001534", name: "United Bank" },
    { aba: "091904283", name: "Farmers & Merchants St Bk Of Pierz" },
    { aba: "081011461", name: "Bank Of Old Monroe" },
    { aba: "031901929", name: "M & T Bank" },
    { aba: "091204080", name: "First National Bank" },
    { aba: "061211171", name: "Commercial Banking Co." },
    { aba: "065000171", name: "Hancock Whitney Bank" },
    { aba: "026008044", name: "Commerzbank Aktiengesellschaft" },
    { aba: "054001547", name: "Truist Bank" },
    { aba: "082901392", name: "Bokf, N.a." },
    { aba: "063204746", name: "Centennial Bank" },
    { aba: "062200961", name: "First Bank Of Alabama" },
    { aba: "021908275", name: "Bank Of Millbrook" },
    { aba: "054001518", name: "Nbad Americas N.v." },
    { aba: "071902797", name: "Bmo Harris Bank,n.a." },
    { aba: "041215825", name: "The Commercial & Savings Bank" },
    { aba: "031207911", name: "Berkshire Bank" },
    { aba: "091216081", name: "St Bk Of Easton" },
    { aba: "041203824", name: "Wells Fargo Bank, N.a." },
    { aba: "044107613", name: "The Savings Bank" },
    { aba: "031207924", name: "First Bank" },
    { aba: "064201243", name: "United Community Bank" },
    { aba: "053207533", name: "The Peoples Bank" },
    { aba: "021272626", name: "Banco Popular, North America" },
    { aba: "091400758", name: "Farmers State Bank" },
    { aba: "071112561", name: "Dewey State Bk" },
    { aba: "071925402", name: "Hinsdale Bank & Trust Company N. A." },
    { aba: "031207908", name: "Interstate Net Bank" },
    { aba: "071926731", name: "Millennium Bank" },
    { aba: "062102441", name: "Synovus Bank" },
    { aba: "061103315", name: "Bank Of Monticello" },
    { aba: "041215812", name: "Buckeye State Bank" },
    { aba: "071123204", name: "Morton Community Bank" },
    { aba: "061104644", name: "Persons Banking Company" },
    { aba: "041202511", name: "First National Bank Of Pennsylvania" },
    { aba: "063215489", name: "The First, A National Banking Assoc" },
    { aba: "051036706", name: "Credit Gateway- Ach Receiver" },
    { aba: "021272655", name: "Citibank Na" },
    { aba: "028001489", name: "Citibank N.a." },
    { aba: "074902228", name: "Riddell National Bank" },
    { aba: "071112503", name: "State Bank Of Davis" },
    { aba: "074902215", name: "Bmo Harris Bank,n.a." },
    { aba: "026084628", name: "The Finest Federal Credit Union" },
    { aba: "074903544", name: "Campbell & Fetter Bank" },
    { aba: "041203811", name: "First Financial Bank" },
    { aba: "091014898", name: "Bmo Harris Bank Na" },
    { aba: "051409605", name: "New Horizon Bank, Na" },
    { aba: "074904860", name: "Fountain Trust Company" },
    { aba: "071925460", name: "Village Bank & Trust, N.a." },
    { aba: "071924131", name: "Heritage Bank Of Schaumburg" },
    { aba: "044107600", name: "Vinton County Natl Bank Of Mcarthur" },
    { aba: "071925444", name: "Wintrust Bank, Na" },
    { aba: "083901773", name: "Farmers Bank And Trust Com" },
    { aba: "042104469", name: "Citizens Bank" },
    { aba: "081217919", name: "Farmers Merchants Bank" },
    { aba: "026084602", name: "Empire Financial Fcu" },
    { aba: "073905718", name: "Community Bank" },
    { aba: "061019674", name: "United Community Bank, Inc" },
    { aba: "021606056", name: "Oriental Bank" },
    { aba: "067012921", name: "Valley National Bank" },
    { aba: "084205452", name: "The Peoples Bank" },
    { aba: "041215786", name: "Premier Bank" },
    { aba: "021584178", name: "Coop De A/c De La Universdad De Pr" },
    { aba: "042104579", name: "Farmers & Traders Bank" },
    { aba: "083901760", name: "First Southern National Bank" },
    { aba: "031207872", name: "New Century Bank" },
    { aba: "065501353", name: "The First, A Natl Banking Assn" },
    { aba: "021606069", name: "Oriental Bank" },
    { aba: "071925389", name: "Wheaton Bank & Trust, Na" },
    { aba: "021584165", name: "Coop Empleados Agencias Agricolas Pr" },
    { aba: "042103237", name: "Owingsville Banking Co" },
    { aba: "021200957", name: "Td Bank, Na" },
    { aba: "072000096", name: "Comerica Bank" },
    { aba: "031207885", name: "First Bank" },
    { aba: "071123262", name: "Town & Country Bk Springfield" },
    { aba: "044011453", name: "First Commonwealth Bank" },
    { aba: "081000728", name: "Pnc Bank, Na" },
    { aba: "071924063", name: "Heartland Bank & Trust Company" },
    { aba: "071925376", name: "Community Bank Of Elmhurst" },
    { aba: "073916406", name: "Watkins Savings Bank" },
    { aba: "042105882", name: "First & Peoples Bank And Trust Co." },
    { aba: "081204540", name: "Midland States Bank" },
    { aba: "064203979", name: "United Community Bank, Inc" },
    { aba: "083901744", name: "Regions Bank" },
    { aba: "031207856", name: "First-citizens Bank & Trust Co" },
    { aba: "011104050", name: "Webster Bank" },
    { aba: "031207869", name: "1st Colonial National Bank" },
    { aba: "061191932", name: "Gwinnett Federal Credit Union" },
    { aba: "091809731", name: "Bremer Bank, Na" },
    { aba: "041215773", name: "Peoples Bank" },
    { aba: "021584149", name: "Coop A/c Fed Maestros Pr" },
    { aba: "043403282", name: "Wesbanco Bank Inc" },
    { aba: "091301132", name: "First United Bank" },
    { aba: "091809728", name: "Citizens Community Federal Na" },
    { aba: "061103399", name: "Synovus Bank" },
    { aba: "081203208", name: "First Southern Bank" },
    { aba: "081000702", name: "Pnc Bank, Na" },
    { aba: "083900402", name: "The Paducah Bank And Trust Company" },
    { aba: "041201143", name: "Consumers National Bank" },
    { aba: "073916422", name: "Wayland State Bank" },
    { aba: "031207830", name: "Cornerstone Bank" },
    { aba: "083901728", name: "Farmers National Bank" },
    { aba: "071125891", name: "Sauk Valley Bank" },
    { aba: "022306847", name: "Bank Of Cattaraugus" },
    { aba: "091810762", name: "Superior Choice Credit Union" },
    { aba: "073917748", name: "American Trust & Savings Bk" },
    { aba: "041215757", name: "Buckeye Community Bank" },
    { aba: "052100466", name: "1880 Bank" },
    { aba: "064201324", name: "First Horizon Bank" },
    { aba: "031207843", name: "Oceanfirst Bank" },
    { aba: "031207814", name: "Parke Bank" },
    { aba: "071924089", name: "Bmo Harris Bank,n.a." },
    { aba: "021414125", name: "Radius Bank" },
    { aba: "075918295", name: "Community First Bank" },
    { aba: "064202682", name: "Simply Bank" },
    { aba: "061105999", name: "United Community Bank, Inc" },
    { aba: "061102031", name: "Cohutta Banking Co" },
    { aba: "061103360", name: "Pnc Bank, National Association" },
    { aba: "041202443", name: "Farmers National Bank Of Canfield" },
    { aba: "083901702", name: "Citizens Natl Bank" },
    { aba: "041201114", name: "Northwest Savings Bank" },
    { aba: "011201115", name: "Bar Harbor Bank & Trust" },
    { aba: "031207827", name: "Northfield Bank" },
    { aba: "044202071", name: "Lcnb National Bank" },
    { aba: "081509070", name: "Central Bank Lake Of The Ozarks" },
    { aba: "075918282", name: "Settlers Bank" },
    { aba: "091036135", name: "Frb Internal Acct-fed As Receiver" },
    { aba: "021584181", name: "Coop A/c Emp Mun Guaynabo" },
    { aba: "053112848", name: "Skyline National Bank" },
    { aba: "082901279", name: "Fbt Bank & Mortgage" },
    { aba: "073903150", name: "Union State Bank" },
    { aba: "061212439", name: "Altamaha Bk & Trust Co" },
    { aba: "044084422", name: "Pathways Financial Credit Union, Inc" },
    { aba: "073903147", name: "Farmers & Merchants St Bk" },
    { aba: "083912393", name: "First Savings Bank" },
    { aba: "072413900", name: "Tcf National Bank" },
    { aba: "043403224", name: "Community Bank" },
    { aba: "072486856", name: "Community Promise Fcu" },
    { aba: "081513930", name: "Simmons Bank" },
    { aba: "084107055", name: "Iberiabank" },
    { aba: "053100850", name: "Pnc Bank, National Association" },
    { aba: "022306818", name: "Bank Of Castile" },
    { aba: "065502608", name: "Hancock Whitney Bank" },
    { aba: "072413926", name: "Northpointe Bank" },
    { aba: "044000804", name: "Umb, Na" },
    { aba: "074914274", name: "German American Bank" },
    { aba: "053112864", name: "First National Bank Of Pennsylvania" },
    { aba: "084303859", name: "Gates Banking & Trust Co" },
    { aba: "021307708", name: "Td Bank Na" },
    { aba: "061201754", name: "Ameris Bank" },
    { aba: "073916477", name: "Farmers & Merchants Bank & Trust" },
    { aba: "053112851", name: "Farmers And Merchants Bank" },
    { aba: "062200880", name: "Bbva Usa" },
    { aba: "075918237", name: "Baraboo State Bank" },
    { aba: "073916480", name: "West Chester Savings Bank" },
    { aba: "052100408", name: "M & T Bank" },
    { aba: "075907594", name: "Wisconsin Bank And Trust" },
    { aba: "064104201", name: "Firstbank" },
    { aba: "053112806", name: "Capital Bank, N.a" },
    { aba: "091906838", name: "Midwestone Bk" },
    { aba: "026011701", name: "Investors Bank" },
    { aba: "067009390", name: "Hsbc Bank Usa" },
    { aba: "042200295", name: "Key Bank" },
    { aba: "075907581", name: "Greenleaf Bank" },
    { aba: "083008650", name: "New Washington State Bank" },
    { aba: "075906236", name: "Hometown Bank" },
    { aba: "091905512", name: "Security Bank & Trust Glencoe" },
    { aba: "084303804", name: "Firstbank" },
    { aba: "011104047", name: "Bank Of America N.a." },
    { aba: "075907552", name: "State Bank Of Newburg" },
    { aba: "021113251", name: "Westport National Bank" },
    { aba: "091905525", name: "First State Bank" },
    { aba: "053112822", name: "Live Oak Banking Company" },
    { aba: "081513969", name: "Bank Of Grandin" },
    { aba: "067011692", name: "International Finance Bank" },
    { aba: "091807186", name: "State Bank Of Arcadia" },
    { aba: "031207898", name: "The Bank Of Delmarva" },
    { aba: "071974372", name: "Wachovia Bank, N.a." },
    { aba: "096017418", name: "Green Dot Bank" },
    { aba: "091906870", name: "Produce State Bank" },
    { aba: "061212426", name: "Colony Bank" },
    { aba: "065001426", name: "Capital One, N.a." },
    { aba: "073915193", name: "Palo Savings Bank" },
    { aba: "091917513", name: "Riverland Bank" },
    { aba: "056004623", name: "United Bank" },
    { aba: "065201860", name: "Gulf Coast Bank" },
    { aba: "067014301", name: "Edison National Bank" },
    { aba: "091908700", name: "Alerus Financial, Na" },
    { aba: "083901087", name: "Fnb Bank Incorporated" },
    { aba: "071121772", name: "Mason City Natl Bk" },
    { aba: "065200531", name: "Evangeline Bank & Trust Co" },
    { aba: "091811253", name: "Bmo Harris Bank Na" },
    { aba: "082901208", name: "Stone Bank" },
    { aba: "011375164", name: "Brookline Bank" },
    { aba: "083000108", name: "Pnc Bank, Kentucky, Inc." },
    { aba: "021582662", name: "Cooperativa A/c Caguas" },
    { aba: "074904954", name: "Farmers & Merch State Bk" },
    { aba: "064103532", name: "Citizens Bank & Savings Co" },
    { aba: "064103529", name: "Wilson Bank And Trust" },
    { aba: "091908713", name: "United Minnesota Bank" },
    { aba: "091305044", name: "Banknorth" },
    { aba: "074914313", name: "Horizon Bank, An Indiana Bank" },
    { aba: "081086742", name: "Lutheran Federal Credit Union" },
    { aba: "071905095", name: "First National Bank Of Omaha" },
    { aba: "065404560", name: "Metairie Bank And Trust Co" },
    { aba: "084201757", name: "Bna Bank" },
    { aba: "065201886", name: "Investar Bank, National Association" },
    { aba: "065000090", name: "Capital One, National Association" },
    { aba: "067015601", name: "Bank Ozk" },
    { aba: "091908726", name: "New Market Bank" },
    { aba: "083902387", name: "German American Bank" },
    { aba: "061204735", name: "1st Natl Bk Of Coffee Cty" },
    { aba: "071108766", name: "Tompkins State Bk" },
    { aba: "067014343", name: "First Horizon Bank" },
    { aba: "091908742", name: "State Bank New Richland" },
    { aba: "091305031", name: "First International Bank & Trust" },
    { aba: "083902374", name: "Fifth Third Bank" },
    { aba: "061203477", name: "Bk Of Wrightsville" },
    { aba: "081025761", name: "Simmons Bank" },
    { aba: "082902579", name: "Todays Bank" },
    { aba: "072409794", name: "Comerica Bank" },
    { aba: "071107424", name: "Wenona State Bank" },
    { aba: "071121727", name: "Better Banks" },
    { aba: "067014330", name: "Bmo Harris Bank N.a" },
    { aba: "081906275", name: "Farmers + Merchants Natl" },
    { aba: "071108782", name: "Midland Community Bank" },
    { aba: "071107453", name: "Bank Of Rantoul" },
    { aba: "061120589", name: "Synovus Bank" },
    { aba: "083903690", name: "The Peoples Bank" },
    { aba: "067015656", name: "Anchor Bank" },
    { aba: "061192274", name: "Platinum Fcu" },
    { aba: "021582691", name: "Coop De Ahorra Y Credito Aibonitena" },
    { aba: "091200822", name: "Northern Sky Bank" },
    { aba: "067003671", name: "Pnc Bank, Na" },
    { aba: "064104887", name: "Capstar Bank" },
    { aba: "091908768", name: "Progrowth Bank" },
    { aba: "067015643", name: "Tiaa Bank" },
    { aba: "051009296", name: "Regions Bank" },
    { aba: "044108641", name: "Huntington National Bank" },
    { aba: "091017196", name: "Choice Financial Group" },
    { aba: "053101273", name: "Wells Fargo Bank" },
    { aba: "091200848", name: "Miners Natl Bk" },
    { aba: "091306315", name: "Kindred State Bank" },
    { aba: "073915818", name: "State Savings Bank" },
    { aba: "083903661", name: "Auburn Banking Co." },
    { aba: "031315269", name: "Centric Bank" },
    { aba: "051504704", name: "Pendleton Community Bank" },
    { aba: "028000082", name: "Citibank, Na" },
    { aba: "067014372", name: "Busey Bank, N.a." },
    { aba: "065306066", name: "United Miss Bank" },
    { aba: "063115738", name: "Fifth Third Bank" },
    { aba: "083904974", name: "Fredonia Valley Bk" },
    { aba: "083903645", name: "South Central Bank, Inc" },
    { aba: "083902316", name: "Farmers Bank & Trust Co." },
    { aba: "091907471", name: "Northview Bank" },
    { aba: "065306079", name: "First State Bank" },
    { aba: "083902303", name: "Stock Yards Bk & Tr Co" },
    { aba: "063114412", name: "Hillsboro Bank" },
    { aba: "062204569", name: "Central State Bank" },
    { aba: "063115741", name: "Seacoast National Bank" },
    { aba: "062004099", name: "Iberiabank" },
    { aba: "062203230", name: "First Jackson Bank" },
    { aba: "091510075", name: "Security State Bk" },
    { aba: "083000137", name: "Jpmorgan Chase" },
    { aba: "064008983", name: "Firstbank" },
    { aba: "051408897", name: "New Peoples Bank" },
    { aba: "074903670", name: "Greenfield Banking Company" },
    { aba: "074902341", name: "First Financial Bank" },
    { aba: "065404544", name: "Citizens Bank & Trust Co" },
    { aba: "062203285", name: "Bank Of Moundville" },
    { aba: "042174525", name: "Commonwealth Bank & Trust Co." },
    { aba: "073915847", name: "Northwest Bank" },
    { aba: "091212865", name: "Profinium, Inc" },
    { aba: "062203298", name: "Pnc Bank, National Association" },
    { aba: "074901009", name: "Lafayette Bk & Tr Co" },
    { aba: "064008970", name: "First Citizens Bank" },
    { aba: "073902546", name: "Availa Bank" },
    { aba: "082903837", name: "Farmers Bank And Trust Company" },
    { aba: "053102586", name: "Southern Bank & Trust Company" },
    { aba: "073913218", name: "Freedombank" },
    { aba: "081086700", name: "Tiaa Bank" },
    { aba: "062203269", name: "Trustmark National Bank" },
    { aba: "042174509", name: "Central Bank & Trust Co." },
    { aba: "071106195", name: "Casey State Bk" },
    { aba: "084201786", name: "Bankfirst Financial Services" },
    { aba: "028000024", name: "Jpmorgan Chase" },
    { aba: "052100042", name: "First United Bank & Trust" },
    { aba: "082902511", name: "First Nat'l Bk Of North Arkansas" },
    { aba: "042174512", name: "First State Bk Of The Southeast,inc" },
    { aba: "073903891", name: "County Bank" },
    { aba: "051408868", name: "Virginia National Bank" },
    { aba: "073901233", name: "Midwestone Bank" },
    { aba: "091209247", name: "First Independent Bank" },
    { aba: "031912785", name: "First Natl Bk & Tr Newtown" },
    { aba: "053208118", name: "Bank Ozk" },
    { aba: "064209135", name: "First Community Bk Of East Tenn" },
    { aba: "063115673", name: "Drummond Community Bank" },
    { aba: "026007922", name: "Ftc" },
    { aba: "063113015", name: "Capital City Bank" },
    { aba: "073914550", name: "Maynard Savings Bank" },
    { aba: "063113028", name: "Pilot Bank" },
    { aba: "073915889", name: "Heartland Bank" },
    { aba: "053208105", name: "Coastalstates Bank" },
    { aba: "061120615", name: "Signature Bank Of Georgia" },
    { aba: "062205830", name: "West Alabama Bank & Trust" },
    { aba: "063115686", name: "Renasant Bank" },
    { aba: "091801904", name: "Dairy State Bank" },
    { aba: "081205727", name: "Fairfield National Bank" },
    { aba: "075907099", name: "Farmers And Merchants State Bank" },
    { aba: "063200928", name: "Centennial Bank" },
    { aba: "063114360", name: "Midflorida Cu" },
    { aba: "091211471", name: "Security State Bank" },
    { aba: "071000521", name: "Us Bank Na" },
    { aba: "055000110", name: "M & T Bank" },
    { aba: "051408949", name: "Townebank" },
    { aba: "083074451", name: "First Financial Bank" },
    { aba: "075907073", name: "First National Bank & Trust Company" },
    { aba: "061119600", name: "Bank Ozk" },
    { aba: "053208150", name: "The Park National Bank" },
    { aba: "051901705", name: "Wesbanco Bank, Inc" },
    { aba: "064209177", name: "Citizens Bank" },
    { aba: "071921956", name: "Marseilles Bank Na" },
    { aba: "062204530", name: "Farmers & Merchants Bank" },
    { aba: "051408936", name: "Atlantic Union Bank" },
    { aba: "042174486", name: "Truist Bank" },
    { aba: "064209180", name: "Smartbank" },
    { aba: "073901288", name: "Trubank" },
    { aba: "063115631", name: "Td Bank, Na" },
    { aba: "053208147", name: "Hometrust Bank" },
    { aba: "071918309", name: "Bmo Harris Bank,n.a." },
    { aba: "042174499", name: "Traditional Bank Of Kentucky, Inc." },
    { aba: "051408923", name: "United Bank" },
    { aba: "071000505", name: "Bank Of America, N.a." },
    { aba: "026005319", name: "Intesa Sanpaolo S.p.a." },
    { aba: "043208296", name: "Consumers National Bank" },
    { aba: "043401190", name: "Wesbanco Bank Inc" },
    { aba: "053208134", name: "First Reliance Bank" },
    { aba: "063114328", name: "South State Bank, Na" },
    { aba: "042100272", name: "Fifth Third Bank" },
    { aba: "066015767", name: "First Citizens Bank & Trust Co" },
    { aba: "055002723", name: "Farmers & Merchants Bank" },
    { aba: "051408910", name: "Townebank" },
    { aba: "026005322", name: "Bank Of Baroda" },
    { aba: "021207413", name: "Td Bank Na" },
    { aba: "081509193", name: "Heritage Community Bank" },
    { aba: "053208121", name: "First Citizens Bank & Trust Company" },
    { aba: "051408907", name: "South State Bank" },
    { aba: "063115660", name: "South State Bank, Na" },
    { aba: "042174473", name: "Kentucky Bank" },
    { aba: "026007993", name: "Ubs Ag" },
    { aba: "075907031", name: "State Bank Of Cross Plains" },
    { aba: "072408436", name: "Choiceone Bank" },
    { aba: "081500749", name: "Central Bank Of Moberly" },
    { aba: "021582714", name: "Cooperativa A/c La Comeriena" },
    { aba: "091403577", name: "First Savings Bank" },
    { aba: "031100555", name: "Cnb" },
    { aba: "061120686", name: "Metro City Bank" },
    { aba: "082902485", name: "Home Bank Of Arkansas" },
    { aba: "063113099", name: "Synovus Bank" },
    { aba: "081227129", name: "United Community Bank" },
    { aba: "071121808", name: "First State Bank Of Forrest" },
    { aba: "075907002", name: "Community State Bank" },
    { aba: "075911988", name: "Wells Fargo Bk Na Wisconsin" },
    { aba: "021582701", name: "Coop. A/c Saulo D. Rodriguez" },
    { aba: "071920656", name: "Bmo Harris Bank,n.a." },
    { aba: "071108834", name: "American Bank & Trust Co" },
    { aba: "071921985", name: "1st Secur Tr & Svg Bk" },
    { aba: "042101572", name: "Kentucky Farmers Bank" },
    { aba: "071905040", name: "Bmo Harris Bank,n.a." },
    { aba: "091017138", name: "Sun Rise Banks, National Association" },
    { aba: "063212987", name: "Trustmark National Bank" },
    { aba: "075910659", name: "Bmo Harris Bank, N.a" },
    { aba: "053208192", name: "United Bank" },
    { aba: "091402264", name: "Campbell County Bank Inc Dba Menno" },
    { aba: "072407123", name: "Independent Bank" },
    { aba: "053208176", name: "Pinnacle Bank" },
    { aba: "081908833", name: "Belgrade State Bank" },
    { aba: "021302130", name: "Nbt Bank Na" },
    { aba: "042100230", name: "Fifth Third Bank" },
    { aba: "011401928", name: "Merrimack County Svgs Bank" },
    { aba: "042102898", name: "Citizens Deposit B & T" },
    { aba: "063211629", name: "South State Bank, Na" },
    { aba: "061120660", name: "Bank Ozk" },
    { aba: "042101569", name: "The Bank Of Henderson" },
    { aba: "065201802", name: "Hancock Whitney Bank" },
    { aba: "071119519", name: "Ayars Bk-a Facility Of Fnb Nokomis" },
    { aba: "062106120", name: "The Commercial Bank Of Ozark" },
    { aba: "053208163", name: "South Atlantic Bank" },
    { aba: "091402277", name: "Merchants State Bank" },
    { aba: "042101556", name: "City National Bank Of West Virginia" },
    { aba: "061120673", name: "Southcrest Bank" },
    { aba: "031305936", name: "First Columbia Bk & Tr Co" },
    { aba: "091402280", name: "Cortrust Bank, Na - Freeman Office" },
    { aba: "071119506", name: "Bank Of Pontiac" },
    { aba: "072000326", name: "Jpmorgan Chase" },
    { aba: "021582756", name: "Cooperativa A/c Aguas Buenas" },
    { aba: "061120644", name: "Ameris Bank" },
    { aba: "071918383", name: "Illini State Bank" },
    { aba: "063114386", name: "Pnc Bank, National Association" },
    { aba: "055000165", name: "Fulton Bank, Na" },
    { aba: "063113057", name: "Fifth Third Bank" },
    { aba: "064200765", name: "Regions Bank" },
    { aba: "043018868", name: "Fifth Third Bank Of Western Pa" },
    { aba: "081001727", name: "Pnc Bank, Na" },
    { aba: "055036100", name: "Us Army-6944-fin Mang Center" },
    { aba: "072000339", name: "Comerica Bank" },
    { aba: "011100805", name: "Bank Of America N.a" },
    { aba: "021582743", name: "Coop. A/c San Jose" },
    { aba: "091403548", name: "First Fidelity Bank" },
    { aba: "053174103", name: "Pinnacle Bank" },
    { aba: "061119668", name: "Nanb, Div. Of Shinhan Bank America" },
    { aba: "071905082", name: "Harvard State Bank" },
    { aba: "065201831", name: "Bank Of Gueydan" },
    { aba: "061120628", name: "Pinnacle Bank" },
    { aba: "061204706", name: "American Banking Company Dba Ameris" },
    { aba: "021582730", name: "Coop. De A/c Cidrena" },
    { aba: "051503394", name: "Truist Bank" },
    { aba: "011401960", name: "People's United Bank, N.a." },
    { aba: "065200515", name: "First National Bank Of Louisiana" },
    { aba: "061119639", name: "Pnc Bank, National Association" },
    { aba: "075911920", name: "Bmo Harris Bank Na" },
    { aba: "021582727", name: "Coop.sagrada Familia Santa Juanita" },
    { aba: "061120631", name: "Bank Ozk" },
    { aba: "065200528", name: "Bank Of Commerce & Trust" },
    { aba: "081509106", name: "Regional Missouri Bank" },
    { aba: "061119642", name: "North Georgia National Bank" },
    { aba: "042101514", name: "Traditional Bank" },
    { aba: "071025849", name: "Fifth Third Bank" },
    { aba: "081001714", name: "Bmo Harris Bank Na" },
    { aba: "067003778", name: "First Bank" },
    { aba: "051009364", name: "Atlantic Union Bank" },
    { aba: "091403616", name: "First State Bank Roscoe" },
    { aba: "031305981", name: "Mid Penn Bank" },
    { aba: "021413935", name: "Bnb Bank" },
    { aba: "071120320", name: "Central State Bank" },
    { aba: "083001314", name: "Republic Bank & Trust Co." },
    { aba: "084008840", name: "Partners Bank" },
    { aba: "042102940", name: "Peoples Bank" },
    { aba: "067002436", name: "Bank Of America, N.a." },
    { aba: "091810050", name: "First Resource Bank" },
    { aba: "036018516", name: "Asian Bank" },
    { aba: "071106043", name: "Longview Bank And Trust" },
    { aba: "084008853", name: "Iberiabank" },
    { aba: "041210781", name: "Civista Bank" },
    { aba: "074914449", name: "The Friendship State Bank" },
    { aba: "071108698", name: "Illinois Bank & Trust" },
    { aba: "042305420", name: "First Financial Bank" },
    { aba: "011700425", name: "Northway Bank" },
    { aba: "084201621", name: "Renasant Bank" },
    { aba: "067015724", name: "Centennial Bank" },
    { aba: "083901184", name: "Wilson & Muir Bank & Trust Bardstown" },
    { aba: "082902430", name: "First State Bk" },
    { aba: "082901101", name: "Bancorpsouth" },
    { aba: "081024571", name: "Fcb Bank" },
    { aba: "081227077", name: "The First National Bank Of Allendale" },
    { aba: "061204612", name: "Ameris Bank" },
    { aba: "072407084", name: "First State Bk Of East Detroit" },
    { aba: "041209789", name: "Hamler State Bank" },
    { aba: "071108643", name: "State Bk Of Nauvoo" },
    { aba: "074914452", name: "First Financial Bank" },
    { aba: "074903719", name: "Lake City Bank" },
    { aba: "067014466", name: "Seacoast National Bank" },
    { aba: "067015795", name: "Paradise Bank" },
    { aba: "061119493", name: "United Community Bank, Inc." },
    { aba: "061204683", name: "Glennville Bank" },
    { aba: "064102371", name: "Capstar Bank" },
    { aba: "061202025", name: "The First, Anba" },
    { aba: "051502201", name: "Fayette County National Bank" },
    { aba: "031316608", name: "The Neffs Natl Bk" },
    { aba: "071108630", name: "Midwest Bank Of Freeport" },
    { aba: "021302143", name: "Nbt Bank" },
    { aba: "082902456", name: "Stone Bank" },
    { aba: "067013124", name: "First-citizens Bank & Trust Company" },
    { aba: "021303472", name: "Community Bank, Na" },
    { aba: "071122933", name: "Cibm Bank" },
    { aba: "067014453", name: "Centennial Bank" },
    { aba: "071121604", name: "United Community Bk Chatham" },
    { aba: "081512928", name: "Bank Of Crocker" },
    { aba: "061120495", name: "Century Bank Of Georgia" },
    { aba: "081905069", name: "First National Bank" },
    { aba: "064103697", name: "Mountain Valley Bank" },
    { aba: "051405094", name: "The Blue Grass Valley Bank" },
    { aba: "082902469", name: "Arkansas County Bank" },
    { aba: "071108669", name: "First Community Bank Moultrie County" },
    { aba: "031305965", name: "The Gratz Bank" },
    { aba: "067015779", name: "Banesco Usa" },
    { aba: "061120466", name: "Ameris Bank" },
    { aba: "044072298", name: "First Financial Bank" },
    { aba: "084008811", name: "First Capital Bank" },
    { aba: "082902472", name: "Citizens Bank" },
    { aba: "065403150", name: "Citizens Bank And Trust Co" },
    { aba: "041208421", name: "Farmers State Bk W Salem" },
    { aba: "091908881", name: "Woodlands National Bank" },
    { aba: "074914481", name: "State Bank Of Lizton" },
    { aba: "061119477", name: "United Bank" },
    { aba: "061203338", name: "First National Bank Of Decatur Co" },
    { aba: "051503556", name: "Summit Community Bank" },
    { aba: "067014437", name: "Tiaa Bank" },
    { aba: "061120479", name: "Ameris Bank" },
    { aba: "084008824", name: "Patriot Bank" },
    { aba: "081905085", name: "Providence Bank" },
    { aba: "083902468", name: "First Cmnty Bk Of The Heartland, Inc" },
    { aba: "071121620", name: "Milledgeville State Bank" },
    { aba: "091918208", name: "Park State Bank" },
    { aba: "064103671", name: "First Community Bank Of Tennessee" },
    { aba: "061203325", name: "Ameris Bank" },
    { aba: "061010220", name: "Citizens Trust Bank" },
    { aba: "061204654", name: "South State Bank" },
    { aba: "091918224", name: "Reliance Bank" },
    { aba: "067013179", name: "Charlotte State Bank And Trust" },
    { aba: "083902455", name: "Clinton Bank" },
    { aba: "071205850", name: "First Merchants Bank" },
    { aba: "083000056", name: "Pnc Bank, Kentucky" },
    { aba: "091907578", name: "Freeport State Bank" },
    { aba: "086500087", name: "Bank Of America, N.a." },
    { aba: "055002707", name: "Suntrust Bank" },
    { aba: "091200738", name: "First National Bank" },
    { aba: "083901113", name: "First Kentucky Bank" },
    { aba: "083902442", name: "South Central Bank, Inc." },
    { aba: "063115615", name: "South State Bank, Na" },
    { aba: "065306189", name: "First Commercial Bank" },
    { aba: "083901100", name: "Wesbanco Bank, Inc." },
    { aba: "021411335", name: "First National Bank Of Li" },
    { aba: "083902439", name: "Sebree Deposit Bank" },
    { aba: "063104972", name: "Bank Of America, N.a." },
    { aba: "063115628", name: "Tiaa Bank" },
    { aba: "091212726", name: "Farmers And Merchants State" },
    { aba: "084201692", name: "Renasant Bank" },
    { aba: "042306720", name: "First National Bank" },
    { aba: "071106069", name: "Hill-dodge Banking Company" },
    { aba: "083903742", name: "Morgantown Bank & Trust" },
    { aba: "041207150", name: "Community First Bank Na" },
    { aba: "073903749", name: "Rivers Edge Bank" },
    { aba: "065404418", name: "Capital One, N.a." },
    { aba: "043318814", name: "S & T Bank" },
    { aba: "011375245", name: "Santander Bank, N.a." },
    { aba: "062202082", name: "Pnc Bank, National Association" },
    { aba: "084201676", name: "Merchants & Farmers Bk" },
    { aba: "011375274", name: "Patriot Community Bank" },
    { aba: "065306147", name: "Bankplus" },
    { aba: "063104927", name: "Wauchula State Bank" },
    { aba: "073902436", name: "Midwest Heritage Bank Fsb" },
    { aba: "083903726", name: "Wilson & Muir Bank" },
    { aba: "084201663", name: "Bank Of Holly Springs" },
    { aba: "074914407", name: "Community First Bank Of Howard Cnty" },
    { aba: "091308805", name: "Peoples State Bank" },
    { aba: "053101121", name: "Truist Bank" },
    { aba: "073914411", name: "First State Bank" },
    { aba: "043306826", name: "First Commonwealth Bank" },
    { aba: "074903764", name: "United Communities National Bank" },
    { aba: "062203395", name: "Union State Bank" },
    { aba: "053208231", name: "Coastal Carolina Nb" },
    { aba: "061107515", name: "United Bank" },
    { aba: "063114221", name: "Seacoast National Bank" },
    { aba: "042174567", name: "Heartland Bank" },
    { aba: "011302357", name: "Bank Of America N.a." },
    { aba: "052101588", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "081206946", name: "The First National Bank Of Barry" },
    { aba: "053174019", name: "First Bank" },
    { aba: "021207358", name: "Santander Bank" },
    { aba: "065306134", name: "Bank Of Brookhaven" },
    { aba: "062204624", name: "First Southern State Bank" },
    { aba: "084104621", name: "Bancorpsouth Bank" },
    { aba: "063102259", name: "Capital City Bank" },
    { aba: "011075202", name: "Santander Bank, N.a." },
    { aba: "021039513", name: "Freddie Mac" },
    { aba: "044000642", name: "Key Bank" },
    { aba: "021209990", name: "Fulton Bank, Na" },
    { aba: "081206962", name: "1st Natl Bank/beardstown" },
    { aba: "073915782", name: "Pioneer Bank" },
    { aba: "062205937", name: "Bancorpsouth Bank" },
    { aba: "053208202", name: "The Park National Bank" },
    { aba: "065306118", name: "Community Bank Of Ms" },
    { aba: "091212687", name: "Farmers State Bank" },
    { aba: "064005203", name: "First Horizon Bank" },
    { aba: "053174048", name: "Union Bank" },
    { aba: "021039500", name: "Fannie Mae" },
    { aba: "053174051", name: "New Republic Bank" },
    { aba: "053208273", name: "South Atlantic Bank" },
    { aba: "073902494", name: "Bank Of The West" },
    { aba: "063211726", name: "First National Bank Nw Florida" },
    { aba: "063102204", name: "Farmers & Merchants Bank" },
    { aba: "075908276", name: "Collins State Bank" },
    { aba: "021283958", name: "Polish & Slavic Federal Credit Union" },
    { aba: "075909589", name: "Mbank" },
    { aba: "053208260", name: "South Atlantic Bank" },
    { aba: "084308401", name: "Banktennessee" },
    { aba: "062203337", name: "First State Bank Of Dekalb" },
    { aba: "063115521", name: "Iberiabank" },
    { aba: "062006534", name: "Southpoint Bank" },
    { aba: "091210074", name: "United Community Bank" },
    { aba: "021283929", name: "Bayonne Hospital Employees Fcu" },
    { aba: "075909576", name: "Bmo Harris Bank, N.a" },
    { aba: "022305770", name: "Cnb Bank" },
    { aba: "082000109", name: "Regions Bank" },
    { aba: "062203308", name: "First St Bank Of Dekalb Co" },
    { aba: "042210722", name: "The Farmers State Bank" },
    { aba: "021283932", name: "Ila Local 1235 Fcu" },
    { aba: "021283916", name: "1st Bergen Federal Credit Union" },
    { aba: "053208244", name: "The Conway National Bank" },
    { aba: "062006505", name: "Servisfirst Bank" },
    { aba: "081000553", name: "Midwest Bankcentre" },
    { aba: "082901033", name: "Farmers & Merchants Bank" },
    { aba: "061120563", name: "Lge Community Credit Union" },
    { aba: "065002548", name: "Capital One, N.a." },
    { aba: "026007883", name: "Capital One, N.a." },
    { aba: "081500862", name: "Simmons Bank" },
    { aba: "075908218", name: "Bmo Harris Bank, N.a" },
    { aba: "081000566", name: "Jefferson Bank & Trust Company" },
    { aba: "065382624", name: "First Unity Fcu" },
    { aba: "061120576", name: "First National Community Bank" },
    { aba: "082901046", name: "Commercial Natl Bk" },
    { aba: "081500875", name: "Bank Of America" },
    { aba: "073913195", name: "Fairfax State Savings Bank" },
    { aba: "075910523", name: "Bmo Harris Bank, N.a" },
    { aba: "075908205", name: "Bank First, N.a." },
    { aba: "071108740", name: "Marine Bank" },
    { aba: "075911852", name: "Johnson Bank" },
    { aba: "081512834", name: "Bank Of Macks Creek" },
    { aba: "061120547", name: "Peach State Bank & Trust" },
    { aba: "062106256", name: "Bancorpsouth Bank" },
    { aba: "082901062", name: "Simmons First Natl Bk Of Pine Bluff" },
    { aba: "074913194", name: "Bank Of Wolcott" },
    { aba: "061119558", name: "Bank Ozk" },
    { aba: "081500859", name: "Central Bank Of Boone County" },
    { aba: "043318791", name: "Clarion County Community Bank" },
    { aba: "061106257", name: "South State Bank" },
    { aba: "031304720", name: "M & T Bank" },
    { aba: "071193331", name: "Dhcu Community Credit Union" },
    { aba: "021004823", name: "Hsbc Bank, Usa" },
    { aba: "082901075", name: "Bancorp South Bank" },
    { aba: "021206074", name: "Santander Bank" },
    { aba: "011002343", name: "Boston Private Bk + Tr Co" },
    { aba: "061120550", name: "Bank Ozk" },
    { aba: "042101679", name: "Tri County National Bank" },
    { aba: "031100461", name: "Fulton Bank, Na" },
    { aba: "083913033", name: "First Harrison Bank" },
    { aba: "084308498", name: "Firstbank" },
    { aba: "061205938", name: "Bank Of Dudley" },
    { aba: "061120521", name: "Bank Ozk" },
    { aba: "062205908", name: "Bbva Usa" },
    { aba: "051503491", name: "Pendleton Community Bank, Inc." },
    { aba: "063114263", name: "Seacoast National Bank" },
    { aba: "063115592", name: "South State Bank, Na" },
    { aba: "065200612", name: "St Landry Bank And Trust Co" },
    { aba: "061120534", name: "United Community Bank" },
    { aba: "021303511", name: "Nbt Bank Na" },
    { aba: "044072243", name: "First Merchants Bank" },
    { aba: "082901088", name: "Relyance Bank, National Association" },
    { aba: "055001384", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "064201968", name: "Tennessee State Bank" },
    { aba: "063114276", name: "Ameris Bank" },
    { aba: "082901091", name: "Farmers Bank And Trust Company" },
    { aba: "061119545", name: "Bank Ozk" },
    { aba: "081000524", name: "Midwest Bankcentre" },
    { aba: "051502159", name: "West Union Bank" },
    { aba: "084006237", name: "First Commercial Bank" },
    { aba: "061120505", name: "American United Bank" },
    { aba: "031100490", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "091017248", name: "Eagle Community Bank" },
    { aba: "063114289", name: "Valley National Bank" },
    { aba: "081905014", name: "Bradford National Bank" },
    { aba: "011701660", name: "Bar Harbor Bank & Trust" },
    { aba: "081216389", name: "The Bank Of Carbondale" },
    { aba: "063208140", name: "Synovus Bank" },
    { aba: "061120518", name: "Legacy State Bank" },
    { aba: "051502175", name: "Summit Community Bank, Inc" },
    { aba: "081906000", name: "Cnb Bank And Trust Na" },
    { aba: "051504542", name: "Wesbanco Bank, Inc" },
    { aba: "051503213", name: "Pioneer Community Bank" },
    { aba: "031304306", name: "Farmers & Merchants Tr Co" },
    { aba: "084201524", name: "Bank Of Winona" },
    { aba: "072408274", name: "Kalamazoo County State Bank" },
    { aba: "061205844", name: "Southeastern Bank" },
    { aba: "084201511", name: "Renasant Bank" },
    { aba: "051504568", name: "Premier Bank Inc" },
    { aba: "065403477", name: "First Bank And Trust" },
    { aba: "063103915", name: "Fifth Third Bank" },
    { aba: "053103637", name: "First Horizon Bank" },
    { aba: "074913233", name: "Bmo Harris Bank,n.a." },
    { aba: "084008507", name: "Renasant Bank" },
    { aba: "081906013", name: "Carrollton Bank" },
    { aba: "031317636", name: "S & T Bank" },
    { aba: "084202837", name: "Renasant Bank" },
    { aba: "072408290", name: "West Shore Bank" },
    { aba: "081919356", name: "Mrv Banks" },
    { aba: "072408232", name: "Eastern Michigan Bank" },
    { aba: "071106344", name: "Arcola First Bank" },
    { aba: "061120369", name: "Bank Ozk" },
    { aba: "082902320", name: "Generations Bank" },
    { aba: "071120647", name: "Solutions Bank" },
    { aba: "082904991", name: "Fnbc Bank" },
    { aba: "074902503", name: "The Farmers Bank" },
    { aba: "072410550", name: "First Nat'l Bank Howell" },
    { aba: "051504500", name: "United Bank" },
    { aba: "011400495", name: "Bank Of America, N.a." },
    { aba: "091101280", name: "Central Savings Bk Sault Ste Marie" },
    { aba: "044110707", name: "The Ohio Valley Bank Company" },
    { aba: "031318907", name: "Truist Bank" },
    { aba: "071105002", name: "Central Bank Illinois" },
    { aba: "071121963", name: "First State Bank" },
    { aba: "091908988", name: "Farmers & Merchants State Bank" },
    { aba: "091305280", name: "Dakota Western Bank" },
    { aba: "081000210", name: "Us Bank Na" },
    { aba: "067004764", name: "Citibank" },
    { aba: "071107699", name: "Citizens State Bank" },
    { aba: "082901017", name: "Citizens Bk & Tr Co" },
    { aba: "082901020", name: "Commercial Capital Bank" },
    { aba: "071121992", name: "State Bk-pearl City" },
    { aba: "074913275", name: "First Merchants Bank" },
    { aba: "074903803", name: "Garrett State Bank" },
    { aba: "041209624", name: "Genoa Banking Company" },
    { aba: "083902581", name: "Monticello Banking Company" },
    { aba: "091906359", name: "Peoples State Bank Of Plainview" },
    { aba: "067015478", name: "Cit Bank Na" },
    { aba: "063115958", name: "Seacoast National Bank" },
    { aba: "062202150", name: "Regions Bank" },
    { aba: "053103682", name: "Lumbee Guaranty Bank" },
    { aba: "074901290", name: "Horizon Bank, An Indiana Bank" },
    { aba: "065404735", name: "State Bank + Trust Co." },
    { aba: "083901236", name: "Farmers National Bank" },
    { aba: "063114632", name: "Pnc Bank, National Association" },
    { aba: "063115961", name: "Centennial Bank" },
    { aba: "083903894", name: "Limestone Bank, Inc" },
    { aba: "082000073", name: "Bank Of America N.a." },
    { aba: "091917018", name: "Lake Region Bank" },
    { aba: "062202163", name: "First Bank Of Boaz" },
    { aba: "031315036", name: "Orrstown Bank" },
    { aba: "091209441", name: "Security Savings Bank" },
    { aba: "063114645", name: "Madison County Community Bank" },
    { aba: "067014123", name: "Iberiabank" },
    { aba: "083974289", name: "Truist Bank" },
    { aba: "091905059", name: "Cornerstone State Bank" },
    { aba: "083903881", name: "Forcht Bank, National Association" },
    { aba: "021283851", name: "Pinnacle Fcu" },
    { aba: "055001711", name: "United Bank" },
    { aba: "073904939", name: "First State Bank" },
    { aba: "061204586", name: "American Banking Co Dba Ameris Bank" },
    { aba: "061203257", name: "Pnc Bank, National Association" },
    { aba: "091917047", name: "Charter Bank Eau Claire" },
    { aba: "063114658", name: "First Federal Bank" },
    { aba: "067014110", name: "Centennial Bank" },
    { aba: "062203476", name: "Community Spirit Bank" },
    { aba: "071000806", name: "Northern /o'hare" },
    { aba: "083901210", name: "First State Bank" },
    { aba: "062202147", name: "Fnb Bank" },
    { aba: "096000988", name: "Royal Credit Union" },
    { aba: "061309229", name: "Pinnacle Bank" },
    { aba: "053208312", name: "Coastalstates Bank" },
    { aba: "063115916", name: "South State Bank, Na" },
    { aba: "091917050", name: "Bankvista" },
    { aba: "031306993", name: "Jersey Shore State Bank" },
    { aba: "067014181", name: "Key Bank" },
    { aba: "062202192", name: "Merch & Farm Bk Greene Cty" },
    { aba: "083901207", name: "Bank Of Cadiz" },
    { aba: "091208141", name: "Vantage Bank" },
    { aba: "053103640", name: "Farmers & Merchants Bank" },
    { aba: "091307851", name: "United Community Bank Of No Dakota" },
    { aba: "063016154", name: "Iberiabank" },
    { aba: "053208309", name: "Coastal Carolina National Bank" },
    { aba: "091208138", name: "Kensington Bank" },
    { aba: "031908074", name: "S & T Bank" },
    { aba: "091208154", name: "First State Bank Of Kiester" },
    { aba: "091209483", name: "First Security Bank Sleepy Eye" },
    { aba: "063114603", name: "South State Bank, Na" },
    { aba: "073902313", name: "Primebank" },
    { aba: "074903890", name: "Bmo Harris Bank,n.a." },
    { aba: "021409169", name: "Jpmorgan Chase" },
    { aba: "021906808", name: "Putnam County National Bank" },
    { aba: "041207040", name: "Key Bank" },
    { aba: "083902507", name: "First United Bank Of Hopkins County" },
    { aba: "084202879", name: "Renasant Bank" },
    { aba: "073915656", name: "Rowley Savings Bank" },
    { aba: "091210375", name: "Farmers State Bk" },
    { aba: "011303327", name: "Rockland Trust Company" },
    { aba: "055001698", name: "Pnc Bank Na - Baltimore" },
    { aba: "091017303", name: "Maple Bank" },
    { aba: "021207235", name: "Valley National Bank" },
    { aba: "063114580", name: "Bmo Harris Bank, N.a" },
    { aba: "055000372", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "031376017", name: "Truist Bank" },
    { aba: "021410080", name: "Hsbc Bank, Usa" },
    { aba: "073915672", name: "Federation Bank" },
    { aba: "042208556", name: "Merchants National Bank" },
    { aba: "057001418", name: "Bank Of Charles Town" },
    { aba: "091017329", name: "Minnesota Bank & Trust" },
    { aba: "065103625", name: "Pnc Bank, National Association" },
    { aba: "021303618", name: "Nbt Bank Na" },
    { aba: "091017316", name: "Border Bank" },
    { aba: "067014194", name: "Pnc Bank, National Association" },
    { aba: "021207206", name: "Td Bank Na" },
    { aba: "073914369", name: "Trubank" },
    { aba: "063114548", name: "Pnc Bank, National Association" },
    { aba: "061291735", name: "Georgia's Own Credit Union" },
    { aba: "065306202", name: "Community Trust Bank" },
    { aba: "062202118", name: "Peoples Independent Bank" },
    { aba: "063113219", name: "South State Bank, Na" },
    { aba: "052102516", name: "1880 Bank" },
    { aba: "056001066", name: "Burke & Herbert Bk & Tr Co" },
    { aba: "021582905", name: "Medicoop" },
    { aba: "053207054", name: "Anderson Brothers Bank" },
    { aba: "073914356", name: "Luana Savings Bank" },
    { aba: "031303093", name: "Unb Bank" },
    { aba: "063114551", name: "First Home Bank" },
    { aba: "063115880", name: "South State Bank, Na" },
    { aba: "026004226", name: "Societe Generale" },
    { aba: "071906573", name: "Bmo Harris Bank,n.a." },
    { aba: "063113222", name: "Truist Bank" },
    { aba: "071918545", name: "Banco Popular" },
    { aba: "055002998", name: "Acnb Bank" },
    { aba: "044002909", name: "First City Bank" },
    { aba: "091005302", name: "Highland Bank" },
    { aba: "065103654", name: "Trustmark National Bank" },
    { aba: "063115893", name: "Flagship Bank" },
    { aba: "073902397", name: "Pcsb Bank" },
    { aba: "096000959", name: "North Star Bank" },
    { aba: "063114564", name: "Republic Bank & Trust Company" },
    { aba: "063102592", name: "Pilot Bank" },
    { aba: "044204370", name: "Ohio Valley Bank Co." },
    { aba: "063114577", name: "First Citrus Bank" },
    { aba: "084106988", name: "Piggott State Bk" },
    { aba: "091501767", name: "Bmo Harris Bank Na" },
    { aba: "061107476", name: "Talbot State Bank" },
    { aba: "021582950", name: "Coop. Ahorro Y Credito De Florida" },
    { aba: "051503174", name: "First Citizens Bank & Trust Company" },
    { aba: "073914398", name: "Fidelity Bank And Trust" },
    { aba: "061106150", name: "Hanmi Bank" },
    { aba: "021582947", name: "Coop A/c Cristobal Rodriguez Hidalgo" },
    { aba: "081216240", name: "Farmers State Bank And Trust Company" },
    { aba: "075907248", name: "Associated Bank Na" },
    { aba: "091402015", name: "Farmers & Merchants St Bk" },
    { aba: "081511631", name: "Legacy Bank & Trust" },
    { aba: "051503161", name: "United Bank" },
    { aba: "073901097", name: "Boone Bank And Trust" },
    { aba: "061120453", name: "American Commerce Bank, Na" },
    { aba: "061119464", name: "United Community Bank, Inc" },
    { aba: "021582934", name: "Cooperativa A/c Aguadilla" },
    { aba: "075910882", name: "Bmo Harris Bank N.a." },
    { aba: "061120424", name: "Mvcb, A Division Of Piedmont Bank" },
    { aba: "071917232", name: "Fifth Third Bank" },
    { aba: "081218879", name: "State Bk Whittington" },
    { aba: "071918561", name: "Midland States Bank" },
    { aba: "082902265", name: "Farmers Bk" },
    { aba: "081204265", name: "Legence Bank" },
    { aba: "064201832", name: "First Horizon Bank" },
    { aba: "064105899", name: "Atlantic Capital Bank, Na" },
    { aba: "021582921", name: "Cooperativa A/c Electro-coop." },
    { aba: "061120437", name: "United Bank" },
    { aba: "042101792", name: "Farmers Bank" },
    { aba: "021207280", name: "Valley National Bank" },
    { aba: "043305131", name: "Pnc Bank, Na" },
    { aba: "021582918", name: "Cooperativa De Ahorro Cr La Sagrada" },
    { aba: "065200722", name: "Capital One, N.a." },
    { aba: "084308375", name: "Firstbank" },
    { aba: "061120408", name: "South State Bank" },
    { aba: "063210112", name: "Regions Bank" },
    { aba: "083974302", name: "South Central Bank" },
    { aba: "026004297", name: "National Bank Of New York City" },
    { aba: "084307033", name: "Firstbank" },
    { aba: "021582989", name: "Cooperativa De A/c Ciales" },
    { aba: "081204281", name: "City National Bank" },
    { aba: "064201845", name: "Ubank" },
    { aba: "061119422", name: "First Charter Bank" },
    { aba: "081074495", name: "Midland States Bank" },
    { aba: "063210125", name: "Wells Fargo Bank" },
    { aba: "063092770", name: "South State Bank, Na" },
    { aba: "021582976", name: "Coop De Ahorro Y Credito De Aguada" },
    { aba: "091101316", name: "Mbank" },
    { aba: "084008536", name: "Guaranty Bank And Trust Company" },
    { aba: "021314306", name: "Tioga State Bank" },
    { aba: "021582963", name: "Cooperativa Ahorro Y Cred Cupey Alto" },
    { aba: "042101750", name: "Bank Of Mountains" },
    { aba: "051503145", name: "The Poca Valley Bank" },
    { aba: "091810296", name: "Security Financial Bank" },
    { aba: "081302633", name: "German American Bank" },
    { aba: "084202743", name: "Bank Of Benoit" },
    { aba: "031305745", name: "Fulton Bank, Na" },
    { aba: "021582798", name: "Cooperativa A/c San Rafael" },
    { aba: "091101138", name: "Upper Pennisula State Bank" },
    { aba: "026004093", name: "Royal Bank Of Canada" },
    { aba: "065403370", name: "First National Bankers Bank" },
    { aba: "051504665", name: "Fifth Third Bank" },
    { aba: "071106250", name: "Bmo Harris Bank,n.a." },
    { aba: "064103419", name: "Citizens Bank" },
    { aba: "021582785", name: "Cooperativa A/c De Isabela" },
    { aba: "051504652", name: "Premier Bank Inc" },
    { aba: "051503323", name: "First Neighborhood Bank, Inc." },
    { aba: "061120291", name: "United Community Bank, Inc" },
    { aba: "071118264", name: "Flanagan State Bank" },
    { aba: "081023019", name: "Bank Of Ofallon" },
    { aba: "053104843", name: "First Charter National Bank" },
    { aba: "021582769", name: "Coop A/c La Sagrada Familia" },
    { aba: "011401876", name: "People's United Bank, N.a." },
    { aba: "091906427", name: "American Heritage Natl Bank" },
    { aba: "083900091", name: "Springs Valley Bank And Trust Co." },
    { aba: "081013692", name: "Jefferson Bk St Louis Co" },
    { aba: "081303920", name: "Peoples Tr & Svgs Bk" },
    { aba: "082903536", name: "First Bank" },
    { aba: "081036307", name: "Defense Megacenter St Louis (6102)" },
    { aba: "061120246", name: "Cadence Bank, N.a." },
    { aba: "031100636", name: "Wilmington Savs Fund Soc" },
    { aba: "074903955", name: "First Financial Bank" },
    { aba: "091905114", name: "Midwestone Bk" },
    { aba: "051504623", name: "United Bank" },
    { aba: "064103464", name: "Peoples Bank" },
    { aba: "028001081", name: "Hsbc Bank Usa-poughkeepsie" },
    { aba: "031100649", name: "Discover Bank" },
    { aba: "067015546", name: "Centennial Bank" },
    { aba: "071106218", name: "Peoples Natl Bank / Kewanee" },
    { aba: "091907785", name: "Security Bank & Trust Company" },
    { aba: "071119522", name: "Morton Communtiy Bank" },
    { aba: "091917115", name: "Falcon National Bank" },
    { aba: "072409464", name: "Tcf National Bank" },
    { aba: "021301089", name: "Hsbc Bank, Usa" },
    { aba: "071118222", name: "State Bank Of Graymont" },
    { aba: "061120220", name: "South State Bank, Na" },
    { aba: "091101141", name: "First Bank, Upper Michigan" },
    { aba: "071107563", name: "Atlanta National Bank" },
    { aba: "021113662", name: "Bankwell Bank" },
    { aba: "061120233", name: "Bank Ozk" },
    { aba: "021100361", name: "Jpmorgan Chase" },
    { aba: "031303161", name: "Nbt Bank, N.a." },
    { aba: "096010415", name: "Bremer Bank, Na" },
    { aba: "081918182", name: "1st Natl Bk" },
    { aba: "063114506", name: "South State Bank, Na" },
    { aba: "091200961", name: "Bank Of The West" },
    { aba: "063114519", name: "American Banking Co Dba Ameris Bank" },
    { aba: "051504607", name: "Wesbanco Bank, Inc." },
    { aba: "067014246", name: "Marine Bank & Trust Company" },
    { aba: "061204463", name: "South State Bank" },
    { aba: "062202257", name: "First State Bank Of The South" },
    { aba: "092000267", name: "Frb-helena Ach" },
    { aba: "021283738", name: "Nestle (freehold) Fcu" },
    { aba: "053103585", name: "The Fidelity Bank" },
    { aba: "063114535", name: "Synovus Bk" },
    { aba: "091917160", name: "Community Resource Bank" },
    { aba: "083901333", name: "Meade County Bank" },
    { aba: "074914614", name: "Kentland Bank" },
    { aba: "052102590", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "053207106", name: "Bank Of York" },
    { aba: "031303129", name: "Fidelity Dep Discount Bk" },
    { aba: "073903503", name: "Central Bank" },
    { aba: "053104869", name: "Peoples Bank" },
    { aba: "091917186", name: "Neighborhood National Bank" },
    { aba: "084201443", name: "Cadence Bank, N.a." },
    { aba: "074914627", name: "Hoosier Heartland State Bank" },
    { aba: "071214126", name: "Fifth Third Bank" },
    { aba: "063115806", name: "Sunrise Bank" },
    { aba: "031303132", name: "Fncb Bank" },
    { aba: "042207379", name: "National Bank Of Adams Co." },
    { aba: "073903516", name: "Citiz 1st Natl Bk" },
    { aba: "083902646", name: "Firstbank" },
    { aba: "082001247", name: "Simmons First Natl Bk" },
    { aba: "091512594", name: "Community Financial Bank" },
    { aba: "091208031", name: "State Bank Of Jeffers" },
    { aba: "081206807", name: "Peoples National Bank, N.a." },
    { aba: "021906934", name: "Key Bank" },
    { aba: "083902633", name: "Planters Bank" },
    { aba: "021283770", name: "Atlantic Health Fcu" },
    { aba: "031317788", name: "Community State Bank" },
    { aba: "084202769", name: "Renasant Bank" },
    { aba: "073903529", name: "Security Trust & Savings B" },
    { aba: "074913314", name: "First Merchants Bank" },
    { aba: "083902620", name: "Springfield State Bank" },
    { aba: "062006356", name: "Alamerica Bank" },
    { aba: "021283767", name: "Affinity Federal Credit Union" },
    { aba: "071905309", name: "Central Bank Illinois" },
    { aba: "063113138", name: "Ameris Bank" },
    { aba: "022314020", name: "Evans National Bank" },
    { aba: "071906641", name: "Fifth Third Bank" },
    { aba: "021207112", name: "New York Community Bank" },
    { aba: "073902216", name: "Washington State Bank" },
    { aba: "073915520", name: "Security Trust & Savings Bank" },
    { aba: "091210249", name: "Farmers & Merchants St Bk Of Alpha" },
    { aba: "073904890", name: "First National Bank" },
    { aba: "053102191", name: "First Horizon Bank" },
    { aba: "063113141", name: "South State Bank, Na" },
    { aba: "062006330", name: "Cadence Bank, Na" },
    { aba: "062202202", name: "West Alabama Bank & Trust" },
    { aba: "055000259", name: "Truist Bank" },
    { aba: "091981646", name: "South Metro Federal Credit Union" },
    { aba: "073914220", name: "Libertyville Savings Bank" },
    { aba: "053207122", name: "Community First Bank" },
    { aba: "081508178", name: "United State Bank" },
    { aba: "073902232", name: "Quad City Bank & Trust Company" },
    { aba: "051408839", name: "Carter Bank & Trsut" },
    { aba: "092001512", name: "Glacier Bank- Valley Bk Helena Div" },
    { aba: "043307841", name: "Riverview Bank" },
    { aba: "062203515", name: "Synovus Bank" },
    { aba: "053207119", name: "Bank Of Greeleyville" },
    { aba: "063113167", name: "Bank Ozk" },
    { aba: "091209289", name: "Red Rock Bank" },
    { aba: "084105730", name: "First Financial Bank" },
    { aba: "063101111", name: "The First, A National Banking Assoc" },
    { aba: "031376101", name: "Northwest Bank" },
    { aba: "011736114", name: "U.s. Treasury (stored Value Card)" },
    { aba: "084006059", name: "Community Bank Of Ms" },
    { aba: "063114425", name: "Drummond Community Bank" },
    { aba: "055002862", name: "Fulton Bank, Na" },
    { aba: "063115754", name: "Seacoast National Bank" },
    { aba: "071917326", name: "First Midwest Bank-oak Park" },
    { aba: "062203573", name: "Valley State Bk" },
    { aba: "011001276", name: "Oneunited Bank" },
    { aba: "091981688", name: "Crow Wing Power Credit Union" },
    { aba: "071920960", name: "Heartland Bank & Trust Company" },
    { aba: "053207164", name: "The Citizens Bank" },
    { aba: "043318500", name: "Enterprise Bank" },
    { aba: "042207308", name: "Park National Bank" },
    { aba: "053906041", name: "First Citizens Bank" },
    { aba: "073902274", name: "First National Bank Ames" },
    { aba: "011304711", name: "Brookline Bank" },
    { aba: "063113112", name: "Brannen Bank" },
    { aba: "091017400", name: "Sunrise Banks, Na" },
    { aba: "075910798", name: "Bmo Harris Bank, N.a" },
    { aba: "073902290", name: "Northwest Bank" },
    { aba: "071920957", name: "Fifth Third Bank" },
    { aba: "064008912", name: "Truxton Trust Company" },
    { aba: "063115783", name: "American Momentum Bank" },
    { aba: "073902287", name: "United Bank Of Iowa" },
    { aba: "065200803", name: "Patterson State Bank" },
    { aba: "042015422", name: "Huntington National Bank" },
    { aba: "026005458", name: "Bank Of India" },
    { aba: "073914262", name: "Hills Bank" },
    { aba: "073914288", name: "Community Savings Bank" },
    { aba: "021582837", name: "Cooperativa De A Y C De La Aaa" },
    { aba: "026006790", name: "Merchants Bk Of Ny,div. Of Valley Nb" },
    { aba: "071916042", name: "Stillman Banccorp Na" },
    { aba: "061120327", name: "Renasant Bank" },
    { aba: "081205455", name: "First Bank" },
    { aba: "021207196", name: "Provident Bank" },
    { aba: "081204126", name: "First National Bank In Olney" },
    { aba: "021582824", name: "Coop. De A/c Morovena" },
    { aba: "061107366", name: "Citizens Union Bk Branch" },
    { aba: "082902139", name: "Bank Of England" },
    { aba: "081511754", name: "First State Bank" },
    { aba: "011001234", name: "Bank Of New York Mellon" },
    { aba: "081509449", name: "Community State Bank Of Missouri" },
    { aba: "061120330", name: "Northeast Georgia Bank" },
    { aba: "075907112", name: "Stephenson Natl Bank & Trust" },
    { aba: "081204142", name: "Trustbank" },
    { aba: "026005487", name: "National Bank Of Canada, Ny Branch" },
    { aba: "064103367", name: "F&m Bank" },
    { aba: "021582808", name: "Coop. De A/c Familiar Progresista" },
    { aba: "021314416", name: "Champlain National Bank" },
    { aba: "021582879", name: "Coop A/c Cacsi Santa Isabel" },
    { aba: "082903497", name: "First State Bank" },
    { aba: "055000288", name: "Pnc Bank Na - Baltimore" },
    { aba: "021207154", name: "1st Constitution Bank" },
    { aba: "081216169", name: "Teutopolis St Bank" },
    { aba: "021582866", name: "Coop. A/c San Miguel" },
    { aba: "061107324", name: "Wilkinson County Bank" },
    { aba: "084008646", name: "Premier Bank Of Arkansas" },
    { aba: "051504571", name: "Premier Bank, Inc." },
    { aba: "081216172", name: "Texico State Bk" },
    { aba: "011401850", name: "Bank Of New England" },
    { aba: "065200861", name: "Bank Of Abbeville & Trust Company" },
    { aba: "091809362", name: "Hiawatha National Bank" },
    { aba: "072411708", name: "Shelby State Bank" },
    { aba: "021582853", name: "Coop De Ahorro Y Credito Las Piedras" },
    { aba: "011002550", name: "Eastern Bank" },
    { aba: "055000262", name: "Pnc Bank Na - Baltimore" },
    { aba: "042101886", name: "Pinnacle Bank, Inc." },
    { aba: "064102070", name: "Citizens Tri-county Bank" },
    { aba: "044107163", name: "First Central Natl Bank" },
    { aba: "065200874", name: "Capital One, N.a." },
    { aba: "091017484", name: "Visionbank" },
    { aba: "021313103", name: "Citizens Bank Na" },
    { aba: "021207138", name: "Td Bank Na - Paycard Only" },
    { aba: "066014726", name: "Banco De Credito E Inversiones (bci)" },
    { aba: "062204802", name: "Guardian Credit Union" },
    { aba: "021301115", name: "Chemung Canal Trust Co" },
    { aba: "051504597", name: "Mvb Bank, Inc." },
    { aba: "091017471", name: "Tradition Capital Bank" },
    { aba: "022304616", name: "The Lyons National Bank" },
    { aba: "081000032", name: "Bank Of America, N.a." },
    { aba: "071109655", name: "1st Security Bk" },
    { aba: "065003592", name: "Crescent Bank & Trust" },
    { aba: "071108326", name: "West Central Bank" },
    { aba: "053112327", name: "Bank Of Oak Ridge" },
    { aba: "061213247", name: "Pineland Bank" },
    { aba: "091211905", name: "United Farmers State Bank" },
    { aba: "081000045", name: "Federal Reserve Bank" },
    { aba: "071108313", name: "Citizens Bank Of Chatsworth" },
    { aba: "071122616", name: "Fisher National Bank" },
    { aba: "021305577", name: "Nbt Bank, National Association" },
    { aba: "053112314", name: "Providence Bank" },
    { aba: "021606742", name: "Merchants Commercial Bank" },
    { aba: "083904589", name: "Sacramento Deposit Bank" },
    { aba: "061202575", name: "Colony Bank" },
    { aba: "051502599", name: "Clear Mountain Bank" },
    { aba: "011002725", name: "Berkshire Bank" },
    { aba: "021583506", name: "Cooperativa A/c Gerenciales Aee" },
    { aba: "031318716", name: "Embassy Bank For The Lehigh Valley" },
    { aba: "082904726", name: "Cornerstone Bank" },
    { aba: "081303823", name: "Bmo Harris Bank,n.a." },
    { aba: "067014767", name: "Synovus Bank" },
    { aba: "081917390", name: "Community Trust Bank" },
    { aba: "071122645", name: "First Mid Bank & Trust, Na" },
    { aba: "081904086", name: "Chesterfield State" },
    { aba: "065002289", name: "First Bank And Trust" },
    { aba: "053112343", name: "First Horizon Bank" },
    { aba: "051501299", name: "First Community Bank" },
    { aba: "061201233", name: "Pineland Bank" },
    { aba: "053101684", name: "Bank Ozk" },
    { aba: "081916058", name: "State Bank Of St Jacob" },
    { aba: "041209420", name: "First Natl Bk Of Dennison" },
    { aba: "053112330", name: "Select Bank & Trust Company" },
    { aba: "083904563", name: "German American Bank" },
    { aba: "062106528", name: "Banco Del Sol" },
    { aba: "053100355", name: "Wells Fargo Bank" },
    { aba: "071111986", name: "Fortress Bank" },
    { aba: "083905892", name: "First National Bank" },
    { aba: "055001096", name: "Sandy Spring Bank" },
    { aba: "073901602", name: "First Citizens Bank" },
    { aba: "053100300", name: "First Citizens Bank & Trust Company" },
    { aba: "082903426", name: "Horatio State Bk" },
    { aba: "021583551", name: "Coop. A/c La Regla De Oro" },
    { aba: "092905029", name: "Peoples Bk Of Deer Lodge" },
    { aba: "064101398", name: "First Horizon Bank" },
    { aba: "053101626", name: "Wells Fargo Bank" },
    { aba: "082903439", name: "Diamond Bank" },
    { aba: "091211963", name: "Farmers & Merchants State Bank" },
    { aba: "055001070", name: "Harford Bank" },
    { aba: "043401637", name: "Wesbanco Bank Wheeling" },
    { aba: "071111957", name: "Buckley State Bk" },
    { aba: "051501257", name: "First Natl Bk Williamson" },
    { aba: "064101385", name: "Bank Of America" },
    { aba: "011601171", name: "Bar Harbor Bank & Trust" },
    { aba: "021583535", name: "Cooperativa De Ahorro Y Credito Sali" },
    { aba: "044000037", name: "Jpmorgan Chase" },
    { aba: "092981658", name: "Montana Educators Credit Union" },
    { aba: "083000564", name: "Stock Yards Bk & Tr Co" },
    { aba: "051502528", name: "Mvb Bank, Inc" },
    { aba: "051009649", name: "Atlantic Union Bank" },
    { aba: "073914961", name: "Liberty Trust & Svgs Bk" },
    { aba: "072000805", name: "Bank Of America, Na" },
    { aba: "044000024", name: "Huntington National Bank" },
    { aba: "031306799", name: "First National Bank Of Pennsylvania" },
    { aba: "064004181", name: "Renasant Bank" },
    { aba: "064102601", name: "Citizens Bank" },
    { aba: "066014069", name: "Banco De Sabadell,sa-miami Branch" },
    { aba: "044000011", name: "Pnc Bank, Na" },
    { aba: "044002679", name: "Fifth Third Bank" },
    { aba: "021583593", name: "Cooperativa De Ahorro Y Creditovilla" },
    { aba: "091908315", name: "Loretto Office, 21st Century Bank" },
    { aba: "041209488", name: "The State Bank And Trust Company" },
    { aba: "074900194", name: "Pnc Bank, Na" },
    { aba: "065402313", name: "Capital One, N.a." },
    { aba: "091208332", name: "Lowry State Bank" },
    { aba: "084106768", name: "Evolve Bank And Trust" },
    { aba: "031318787", name: "Integrity Bank, A Div Of S & T Bank" },
    { aba: "083904505", name: "Bank Of Buffalo" },
    { aba: "071050432", name: "Frb-financial Services" },
    { aba: "011601142", name: "First Nat Bank Of Orwell" },
    { aba: "061192630", name: "Pnc Bank, National Association" },
    { aba: "042211572", name: "Somerville Bank" },
    { aba: "011302616", name: "People's United Bank, N.a." },
    { aba: "065403626", name: "Regions Bank" },
    { aba: "064103914", name: "First Horizon Bank" },
    { aba: "021583577", name: "Cooperativa A/c De Rincon" },
    { aba: "066014085", name: "Banco De Chile" },
    { aba: "051405395", name: "Farm & Merch Bk Craig Cty" },
    { aba: "073913674", name: "Marion County State Bank" },
    { aba: "041210448", name: "Middlefield Banking Co" },
    { aba: "071123990", name: "First State Bank Of Forrest" },
    { aba: "071122661", name: "Regions Bank" },
    { aba: "071108355", name: "Inb National Association" },
    { aba: "053112356", name: "North State Bank" },
    { aba: "021412114", name: "New York Commercial Bank" },
    { aba: "081025363", name: "Citizens Community Bank" },
    { aba: "011302603", name: "Northmark Bank" },
    { aba: "031318745", name: "York Traditions Bank" },
    { aba: "051404040", name: "Carter Bank & Trust" },
    { aba: "064101301", name: "Citizens Bank Of Lafayette" },
    { aba: "081001387", name: "Regions Bank" },
    { aba: "052103544", name: "Calvin B Taylor Banking Co" },
    { aba: "011601100", name: "The Union Bank" },
    { aba: "071122687", name: "1st Natl Bk In Taylorville" },
    { aba: "053112385", name: "First National Bank Hickory" },
    { aba: "052102215", name: "Pnc Bank, Na" },
    { aba: "051404037", name: "Blue Ridge Bank, National Associatio" },
    { aba: "031318758", name: "Mid Penn Bank" },
    { aba: "091207058", name: "Hometown Community Bank" },
    { aba: "062201083", name: "Bancorpsouth Bank" },
    { aba: "065402339", name: "Capital One, N.a." },
    { aba: "061192669", name: "First Citizens Bank & Trust Co." },
    { aba: "031318761", name: "Customers Bank" },
    { aba: "053112372", name: "Peoples National Bank Fayetteville" },
    { aba: "052102228", name: "Chesapeake Bank & Trust Company" },
    { aba: "091908373", name: "Cornerstone State Bank" },
    { aba: "075909178", name: "Farmers Savings Bank" },
    { aba: "065401000", name: "Capital One, N.a." },
    { aba: "063092110", name: "Tiaa Bank" },
    { aba: "065305151", name: "Trustmark National Bank" },
    { aba: "052102312", name: "Bank Of Ocean City" },
    { aba: "031100157", name: "Bny Mellon-bps" },
    { aba: "053201953", name: "United Bank" },
    { aba: "073923033", name: "Lincoln Savings Bank" },
    { aba: "062203641", name: "First Bank Of Alabama" },
    { aba: "073911061", name: "City State Bank" },
    { aba: "011110646", name: "Peoples United Bank, Na" },
    { aba: "011301390", name: "Century Bank" },
    { aba: "042101174", name: "Kentucky Bank" },
    { aba: "065103887", name: "Pnc Bank, National Association" },
    { aba: "073923020", name: "Hills Bank And Trust Company" },
    { aba: "011110633", name: "Peoples United Bank, Na" },
    { aba: "086586713", name: "River Region Credit Union" },
    { aba: "031100160", name: "Bank Of America, N.a." },
    { aba: "091017536", name: "American National Bank" },
    { aba: "051405450", name: "Pinnacle Bank" },
    { aba: "081207932", name: "United Fidelity Bank, Fsb" },
    { aba: "031100173", name: "M & T Bank" },
    { aba: "051404118", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "011110620", name: "Naugatuck Savings Bank" },
    { aba: "061104136", name: "Cadence Bank, Na" },
    { aba: "051405447", name: "National Bank Of Blacksburg" },
    { aba: "091017565", name: "American National Bank" },
    { aba: "062203638", name: "First Bank- Wadley" },
    { aba: "091510428", name: "Headwaters State Bk Land O Lakes" },
    { aba: "053185299", name: "Hsm Federal Credit Union" },
    { aba: "051405434", name: "Carter Bank & Trust" },
    { aba: "061104123", name: "Cadence Bank, Na" },
    { aba: "091017552", name: "First Western Bank & Trust" },
    { aba: "011110617", name: "Bankers' Bank Northeast" },
    { aba: "026008808", name: "Bayerische Hypo Und Vereinsbank Ag" },
    { aba: "071002707", name: "Byline Bank" },
    { aba: "091971533", name: "American Natl Bk Of Minnesota" },
    { aba: "031304173", name: "S & T Bank" },
    { aba: "071916123", name: "Pioneer State Bank" },
    { aba: "084008358", name: "Trustmark National Bank" },
    { aba: "053200666", name: "Synovus Bank" },
    { aba: "091017507", name: "Northeast Bank" },
    { aba: "011110688", name: "Liberty Bank" },
    { aba: "026008811", name: "Popular Bank" },
    { aba: "021206582", name: "Columbia Bank" },
    { aba: "021205253", name: "Valley National Bank" },
    { aba: "021001208", name: "Federal Reserve Bank" },
    { aba: "075912877", name: "1st American Bank" },
    { aba: "043315147", name: "Elderton St Bk" },
    { aba: "022313021", name: "Genesee Regional Bank" },
    { aba: "011110675", name: "Webster Bank" },
    { aba: "062202367", name: "Renasant Bank" },
    { aba: "091509468", name: "Chippewa Valley Bank" },
    { aba: "075912864", name: "Investors Community Bank" },
    { aba: "063116504", name: "First Colony Bank Of Florida" },
    { aba: "091508171", name: "Laona State Bank" },
    { aba: "051405405", name: "United Bank" },
    { aba: "091102807", name: "Mbank" },
    { aba: "091017523", name: "Bridgewater Bank" },
    { aba: "021206566", name: "Valley National Bank" },
    { aba: "021205237", name: "Peapack-gladstone Bank" },
    { aba: "075909204", name: "Bank Of Prairie Du Sac" },
    { aba: "063116517", name: "South State Bank, Na" },
    { aba: "072408805", name: "United Bk Of Michigan" },
    { aba: "021413388", name: "American Community Bank" },
    { aba: "053201982", name: "United Community Bank" },
    { aba: "065200926", name: "Home Bank, National Association" },
    { aba: "021111800", name: "Webster Bank" },
    { aba: "062202341", name: "Synovus Bank" },
    { aba: "062201012", name: "South State Bank, Na" },
    { aba: "071918765", name: "Home State Bank Natl Assoc" },
    { aba: "062203670", name: "State Bank & Trust" },
    { aba: "011110659", name: "Liberty Bank" },
    { aba: "071000152", name: "The Northern Trust Co" },
    { aba: "067014822", name: "Td Bank, Na" },
    { aba: "081905302", name: "Sullivan Bank" },
    { aba: "082902003", name: "First State Bk" },
    { aba: "055001054", name: "Pnc Bank, Na - Baltimore" },
    { aba: "026008853", name: "New York Commercial Bank" },
    { aba: "021473030", name: "Quontic Bank" },
    { aba: "022303659", name: "Canandaigua Nat'l Bank & Tr. Co." },
    { aba: "075912835", name: "West Pointe Bank" },
    { aba: "082903345", name: "Commerce Community Bank" },
    { aba: "026008866", name: "Bk Hapoalim B M" },
    { aba: "075900863", name: "Bmo Harris Bank, N.a" },
    { aba: "011000028", name: "State Street Bank And Trust Company" },
    { aba: "061104181", name: "Synovus Bank" },
    { aba: "071001465", name: "United Fidelity Bank, Fsb" },
    { aba: "021306974", name: "Nbt Bank Na" },
    { aba: "055002367", name: "Wesbanco Bank, Inc." },
    { aba: "081905331", name: "Murphy Wall State Bank" },
    { aba: "042102416", name: "Somerset Natl Bk, Mccreary Bk Center" },
    { aba: "063115291", name: "South State Bank, Na" },
    { aba: "031911812", name: "Keybank National Association" },
    { aba: "031100102", name: "Wilmington Savs Fund Soc" },
    { aba: "071109707", name: "Community Bank Of Shelby County" },
    { aba: "042102403", name: "First Southern National Bank" },
    { aba: "081508217", name: "First Independent Bank" },
    { aba: "026006224", name: "Bank Of Hope" },
    { aba: "064101291", name: "Citizens Tri-county Bank" },
    { aba: "022314334", name: "Wayne Bank" },
    { aba: "066004600", name: "Grove Bank & Trust" },
    { aba: "075912819", name: "Byline Bank" },
    { aba: "043002023", name: "Bank Of New York Mellon" },
    { aba: "021172768", name: "Newtown Savings Bank" },
    { aba: "091400020", name: "First National Bank Sioux Falls" },
    { aba: "061021170", name: "South State Bank, Na" },
    { aba: "081510593", name: "First Community National Bank" },
    { aba: "021583603", name: "Coop. A/c De La Assn. De Maestros Pr" },
    { aba: "081904015", name: "Associated Bank, N.a." },
    { aba: "083903195", name: "Cecilian Bank" },
    { aba: "062106557", name: "River Bank & Trust" },
    { aba: "063201875", name: "Bank Of The South" },
    { aba: "062204938", name: "Citizens Bank" },
    { aba: "055002341", name: "Bank Of America, N.a." },
    { aba: "026006237", name: "Interaudi Bank" },
    { aba: "063116562", name: "Intracoastal Bank" },
    { aba: "075912806", name: "Capitol Bank" },
    { aba: "091017581", name: "Minnesota Bank & Trust" },
    { aba: "083914016", name: "German American Bank" },
    { aba: "091102894", name: "Baybank" },
    { aba: "067014851", name: "Tiaa Bank" },
    { aba: "091802877", name: "First National Bank" },
    { aba: "081905344", name: "First National Bank" },
    { aba: "065200984", name: "First National Bank Of Jeanerette" },
    { aba: "011592675", name: "Westerly Community Credit Union" },
    { aba: "061202533", name: "Magnolia State Bank" },
    { aba: "062106573", name: "Southern Independent Bank" },
    { aba: "091400046", name: "Wells Fargo Bank Na (south Dakota)" },
    { aba: "072408892", name: "Mercantile Bank Of Michigan" },
    { aba: "026007582", name: "New York Commercial Bank" },
    { aba: "021172784", name: "Fieldpoint Private Bank & Trust" },
    { aba: "062105244", name: "Escambia County Bank" },
    { aba: "082902074", name: "First Natl Bk" },
    { aba: "063116588", name: "Iberiabank" },
    { aba: "011000015", name: "Federal Reserve Bank" },
    { aba: "065200997", name: "First Natl Bk" },
    { aba: "081917345", name: "Simmons First Natl Bk" },
    { aba: "081510564", name: "Tipton Latham Bk,national Assoc" },
    { aba: "011001331", name: "Citizens Bank Na" },
    { aba: "031100144", name: "Jpmorgan Chase Bank, Na" },
    { aba: "055002338", name: "Fulton Bank, Na" },
    { aba: "071110521", name: "1st Nat'l Of Arenzville" },
    { aba: "053112204", name: "South State Bank" },
    { aba: "026007362", name: "Habib American Bank" },
    { aba: "026008691", name: "Bangkok Bank Public Co., Ltd." },
    { aba: "083902060", name: "Town & Country Bank And Trust Co." },
    { aba: "083903386", name: "Lincoln National Bank" },
    { aba: "062106638", name: "Synovus Bank" },
    { aba: "064101482", name: "One Bank Of Tennessee" },
    { aba: "041211609", name: "Farmers Savings Bank" },
    { aba: "081000168", name: "Lindell Bank & Trust Company" },
    { aba: "067013564", name: "Wells Fargo Bank" },
    { aba: "083902044", name: "Wilson & Muir Bank & Trust" },
    { aba: "061202452", name: "First Port City Bank" },
    { aba: "071109558", name: "Citizens 1st St Bank Walnut" },
    { aba: "011002877", name: "East West Bank" },
    { aba: "053112220", name: "First National Bank Of Pennsylvania" },
    { aba: "061203778", name: "Merchants & Citizens Bank" },
    { aba: "071111863", name: "F & M Bank" },
    { aba: "061213153", name: "Bank Ozk" },
    { aba: "053101561", name: "Wells Fargo Bank" },
    { aba: "011675343", name: "Northfield Savings Bank" },
    { aba: "053112217", name: "First Horizon Bank" },
    { aba: "031919021", name: "Citizens & Northern Bank" },
    { aba: "041209307", name: "Cortland Svgs & Banking Co." },
    { aba: "065403587", name: "First Bank And Trust" },
    { aba: "011000206", name: "Bank Of America N.a" },
    { aba: "081025415", name: "United Community Bank" },
    { aba: "065500752", name: "The Peoples Bank Of Biloxi" },
    { aba: "011601087", name: "Natl Bank Of Middlebury" },
    { aba: "026082620", name: "People's Alliance Fcu" },
    { aba: "091800293", name: "Us Bank Na" },
    { aba: "026082617", name: "Korean American Catholics F.c.u." },
    { aba: "065002108", name: "Liberty Bank & Trust Company" },
    { aba: "051501354", name: "First Exchange Bank" },
    { aba: "063115194", name: "Atlantic Capital Bank, Na" },
    { aba: "011601074", name: "Berkshire Bank" },
    { aba: "072407495", name: "State Bank" },
    { aba: "082903303", name: "Merchants & Farmers Bk" },
    { aba: "083902002", name: "South Central Bank, Inc." },
    { aba: "073902821", name: "Marion County State Bank" },
    { aba: "083904660", name: "Bank Of Clarkson" },
    { aba: "091304032", name: "Bank Of Hazelton" },
    { aba: "065003453", name: "Iberiabank" },
    { aba: "055002286", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "081509737", name: "Security Bank Of Southwest Mo" },
    { aba: "081510739", name: "Farmers Bank" },
    { aba: "081074440", name: "Cibc Bank Usa" },
    { aba: "083903328", name: "Community Financial Services Bank" },
    { aba: "071000288", name: "Bmo Harris Bank, N.a." },
    { aba: "021583412", name: "Coop A/c Oficiales De Custodia De Pr" },
    { aba: "082905987", name: "Bk Of Lake Village" },
    { aba: "091908412", name: "Bank Of Maple Plain" },
    { aba: "053101529", name: "Wells Fargo Bank" },
    { aba: "082905990", name: "Chambers Bank" },
    { aba: "065402290", name: "B1bank" },
    { aba: "081001439", name: "Royal Banks Of Missouri" },
    { aba: "064102737", name: "Firstbank" },
    { aba: "061107913", name: "Northeast Georgia Bank" },
    { aba: "021583483", name: "Cooperativa Asociacione Parroquiales" },
    { aba: "053112288", name: "Townebank" },
    { aba: "083904631", name: "The Cecilian Bank" },
    { aba: "071108290", name: "Community National Bk Monmouth" },
    { aba: "051502641", name: "Clay County Bank" },
    { aba: "091209535", name: "State Bank Of Taunton" },
    { aba: "021206537", name: "Valley National Bank" },
    { aba: "011807043", name: "Keybank National Association" },
    { aba: "031305596", name: "Bank Of New York Mellon" },
    { aba: "053112275", name: "First National Bank Of Pennsylvania" },
    { aba: "011302768", name: "People's United Bank, N.a." },
    { aba: "061119901", name: "United Community Bank, Inc" },
    { aba: "061192517", name: "United Neighborhood Fcu" },
    { aba: "073914864", name: "Cedar Valley Bank And Trust" },
    { aba: "031919092", name: "Mid Penn Bank" },
    { aba: "091305329", name: "First State Bank" },
    { aba: "011601029", name: "Community National Bank" },
    { aba: "062201164", name: "Bbva Usa" },
    { aba: "091208222", name: "Vergas State Bank" },
    { aba: "051502667", name: "The Grant County Bank" },
    { aba: "084201223", name: "Renasant Bank" },
    { aba: "091210540", name: "Security State Bank" },
    { aba: "021112935", name: "Patriot Bank, National Association" },
    { aba: "011302742", name: "Enterprise Bank & Trust Co." },
    { aba: "041210370", name: "The Marblehead Bank" },
    { aba: "091907125", name: "Deerwood Bank" },
    { aba: "073902889", name: "Crawford Cty Tr & Svg Bk" },
    { aba: "051501325", name: "Bcbank, Inc" },
    { aba: "053112291", name: "South State Bank" },
    { aba: "051503938", name: "First Community Bank, Na" },
    { aba: "073912222", name: "State Bank Of Brooks" },
    { aba: "053112246", name: "Atlantic Union Bank" },
    { aba: "063191749", name: "Raymond James Bank, Na" },
    { aba: "061213166", name: "American Banking Co Dba Ameris" },
    { aba: "041212983", name: "Citizens Ntl Bk Bluffton" },
    { aba: "091209593", name: "Triumph State Bank" },
    { aba: "071110550", name: "Farmers-merchants Bank Of Illinois" },
    { aba: "091306645", name: "Kodabank" },
    { aba: "071122535", name: "Peoples Bank & Trust" },
    { aba: "021000089", name: "Citibank Na" },
    { aba: "053112233", name: "First National Bank Of Pennsylvania" },
    { aba: "053100258", name: "Bank Of America, N.a." },
    { aba: "074900275", name: "Wells Fargo Bk N.a. Indiana" },
    { aba: "051405492", name: "Carter Bank & Trust" },
    { aba: "051404163", name: "Lee Bank & Trust Company" },
    { aba: "075911373", name: "Banner Bank - Birnamwood Branch" },
    { aba: "066014153", name: "Apollo Bank" },
    { aba: "071074528", name: "Jpmorgan Chase" },
    { aba: "083905902", name: "Sacramento Deposit Bank" },
    { aba: "073913580", name: "Westside State Bank" },
    { aba: "051502625", name: "United Bank" },
    { aba: "042101190", name: "Fifth Third Bank" },
    { aba: "031919063", name: "Berkshire Bank" },
    { aba: "053112259", name: "Bank Ozk" },
    { aba: "041210338", name: "Bank Of Magnolia Company" },
    { aba: "071905613", name: "First National Bank Of Omaha" },
    { aba: "052101012", name: "Calvin B. Taylor Banking C" },
    { aba: "042208006", name: "1st National Bank" },
    { aba: "073901592", name: "First Security Bank & Trust" },
    { aba: "051405476", name: "Carter Bank & Trust" },
    { aba: "064102740", name: "Citizens Bank" },
    { aba: "073912264", name: "Se Natl Bk Moline" },
    { aba: "063116407", name: "American Commerce Bank, Na" },
    { aba: "062201106", name: "Phenix-girard Bank-phenix City" },
    { aba: "064208165", name: "Truist Bank" },
    { aba: "052101106", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "072408708", name: "Independent Bank" },
    { aba: "031100047", name: "Bank Of New York Mellon" },
    { aba: "066016766", name: "Synovus Bank" },
    { aba: "063116410", name: "Centennial Bank" },
    { aba: "091908399", name: "Security Bank Minnesota" },
    { aba: "061119985", name: "Community Bank Of Pickens Cty" },
    { aba: "071026835", name: "American Eagle Bank" },
    { aba: "055003528", name: "Sandy Spring Bank" },
    { aba: "075912783", name: "Monona State Bank" },
    { aba: "066015440", name: "Interaudi Bank" },
    { aba: "011099660", name: "Frb Boston Accounting Dept." },
    { aba: "063116423", name: "Valley National Bank" },
    { aba: "071001504", name: "Lakeside Bank" },
    { aba: "051404231", name: "Atlantic Union Bank" },
    { aba: "071026848", name: "Gold Coast Bank" },
    { aba: "081519073", name: "Sterling Bank" },
    { aba: "061120958", name: "Bank Ozk" },
    { aba: "051503909", name: "Williamstown Bank, Inc." },
    { aba: "075912770", name: "Bay Bank" },
    { aba: "061106655", name: "First Bank Of Pike" },
    { aba: "042101271", name: "Bank Of The Bluegrass" },
    { aba: "062203751", name: "Wells Fargo Bank" },
    { aba: "063116436", name: "South State Bank, Na" },
    { aba: "044002802", name: "Commerce National Bank" },
    { aba: "091510596", name: "Bremer Bk, Na Wisconsin" },
    { aba: "042102597", name: "Citizens Commerce National Bank" },
    { aba: "084201294", name: "Renasant Bank" },
    { aba: "042101268", name: "Peoples Bank & Trust Co." },
    { aba: "062202477", name: "First Southern State Bank" },
    { aba: "031316271", name: "First National Bank Of Pennsylvania" },
    { aba: "091017620", name: "Republic Bank & Trust Co." },
    { aba: "091005632", name: "Bmo Harris Bank Na" },
    { aba: "064208194", name: "Bank Of America" },
    { aba: "022314237", name: "Genesee Regional Bank" },
    { aba: "061300367", name: "First Horizon Bank" },
    { aba: "011300142", name: "Berkshire Bank" },
    { aba: "031100089", name: "Pnc Bank, National Association" },
    { aba: "053185192", name: "Members Credit Union" },
    { aba: "082001881", name: "Bancorpsouth Bank" },
    { aba: "062201151", name: "Merchants & Marine Bank" },
    { aba: "061120903", name: "Embassy National Bank" },
    { aba: "042200910", name: "First Financial Bank" },
    { aba: "084201278", name: "Bancorpsouth Bank" },
    { aba: "075912741", name: "Wisconsin River Bank" },
    { aba: "042102571", name: "Citizens Deposit Bank & Trust, Inc" },
    { aba: "021000018", name: "Bank Of New York Mellon" },
    { aba: "051405528", name: "Farmers Bank" },
    { aba: "031100092", name: "M & T Bank" },
    { aba: "065404913", name: "Regions Bank" },
    { aba: "021907975", name: "Bank Of Millbrook" },
    { aba: "021000021", name: "Jpmorgan Chase" },
    { aba: "042102568", name: "Forcht Bank, National Association" },
    { aba: "051405515", name: "Capital One Bank (usa), N.a." },
    { aba: "075900766", name: "National Exchange Bank & Trust" },
    { aba: "091971627", name: "Minnesota First Credit & Savings,inc" },
    { aba: "075912738", name: "American Natl Bank-fox Cities" },
    { aba: "021606690", name: "United Fidelity Bank, Fsb" },
    { aba: "082903219", name: "Century Next Bank" },
    { aba: "053285717", name: "Self-help Credit Union" },
    { aba: "081218536", name: "Farmers State Bank & Trust Co" },
    { aba: "042102555", name: "Wesbanco Bank, Inc." },
    { aba: "081507014", name: "Ma Bank" },
    { aba: "063115152", name: "Ameris Bank" },
    { aba: "063116481", name: "Prime Meridian Bank" },
    { aba: "075912712", name: "Monona State Bank" },
    { aba: "062104041", name: "Peoplessouth Bank" },
    { aba: "081511990", name: "Community Point Bank" },
    { aba: "081519002", name: "Freedom Bank Of Southern Missouri" },
    { aba: "063116494", name: "Iberiabank" },
    { aba: "021606674", name: "Banco Popular De Pr" },
    { aba: "071001588", name: "Banco Popular" },
    { aba: "082000549", name: "Us Bank Na" },
    { aba: "062104070", name: "Community Bank & Trust Union Springs" },
    { aba: "063103193", name: "Bank Of America, N.a." },
    { aba: "063115178", name: "Iberiabank" },
    { aba: "055002244", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "026008756", name: "Bessemer Trust Co. N.a." },
    { aba: "084008468", name: "Landmark Community Bank" },
    { aba: "071904290", name: "Grundy Bank" },
    { aba: "053102816", name: "First National Bank Of Pennsylvania" },
    { aba: "081205222", name: "First National Bank" },
    { aba: "031389266", name: "Par-del Employees Fcu" },
    { aba: "021001318", name: "Bank Of America" },
    { aba: "061104084", name: "United Community Bank, Inc" },
    { aba: "091101455", name: "Wells Fargo Bank Na (michigan)" },
    { aba: "067014987", name: "Valley National Bank" },
    { aba: "053185105", name: "Cs Credit Union" },
    { aba: "064209452", name: "Millennium Bank" },
    { aba: "064208123", name: "First Horizon Bank" },
    { aba: "082903264", name: "Bank Of Delight" },
    { aba: "042102513", name: "Inez Deposit Bank, Fsb" },
    { aba: "031101321", name: "Barclays Bank Delaware" },
    { aba: "026007443", name: "Woori America Bank" },
    { aba: "091800329", name: "Bmo Harris Bank Na" },
    { aba: "061203752", name: "Bank Of Hazlehurst" },
    { aba: "084308003", name: "Bank Of Ripley" },
    { aba: "062105338", name: "Trustmark National Bank" },
    { aba: "062203735", name: "Regions Bank" },
    { aba: "081507072", name: "Simmons First Natl Bk" },
    { aba: "055003560", name: "Sandy Spring Bank" },
    { aba: "063115123", name: "Bmo Harris Bank, N.a" },
    { aba: "063116452", name: "Centennial Bank" },
    { aba: "061202410", name: "Synovus Bank" },
    { aba: "084308032", name: "Simmons Bank" },
    { aba: "063212631", name: "Pnb Community Bank" },
    { aba: "081519028", name: "Connections Bank" },
    { aba: "021172661", name: "New York Community Bank" },
    { aba: "031389237", name: "Everence Federal Credit Union" },
    { aba: "067014961", name: "Flagler Bank" },
    { aba: "062203706", name: "Trustmark National Bank" },
    { aba: "061120987", name: "Vinings Bank" },
    { aba: "042103826", name: "First National Bank Of Brooksville" },
    { aba: "011000138", name: "Bank Of America, N.a." },
    { aba: "063115136", name: "Beach Bank" },
    { aba: "081519031", name: "Heritage Bank Of The Ozarks" },
    { aba: "091400172", name: "Dacotah Bank" },
    { aba: "071001533", name: "Byline Bank" },
    { aba: "062203719", name: "Evabank" },
    { aba: "084008426", name: "Independent Bank" },
    { aba: "082903293", name: "Simmons First National Bank" },
    { aba: "063115149", name: "South State Bank, Na" },
    { aba: "081205264", name: "Anna-jonesboro National Bank" },
    { aba: "041284539", name: "Park View Federal Savings Bank" },
    { aba: "091800316", name: "State Bank Financial" },
    { aba: "071109891", name: "First State Bank Of Bloomington" },
    { aba: "083902183", name: "Forcht Bank, National Association" },
    { aba: "061213001", name: "Persons Banking Company" },
    { aba: "071120207", name: "Better Banks" },
    { aba: "062106751", name: "Troy Bank & Trust" },
    { aba: "074913699", name: "Citizens State Bank" },
    { aba: "071107220", name: "Farmers Bank-mt Pulaski" },
    { aba: "071108559", name: "Bank Of Springfield" },
    { aba: "041212815", name: "Fort Jennings State Bank" },
    { aba: "074900356", name: "First Financial Bank Na" },
    { aba: "091800374", name: "Coulee Bank" },
    { aba: "051501024", name: "Logan B & T Company" },
    { aba: "081206496", name: "United Community Bank" },
    { aba: "074071926", name: "First Financial Bank" },
    { aba: "082905819", name: "Riverside Bank" },
    { aba: "064102436", name: "Citizens Bank" },
    { aba: "091811004", name: "Dairy State Bank" },
    { aba: "071107259", name: "Better Banks" },
    { aba: "067014521", name: "Bank Ozk" },
    { aba: "083902154", name: "Pnc Bank, Na" },
    { aba: "071119234", name: "Middletown State Bank" },
    { aba: "031919128", name: "The Victory Bank" },
    { aba: "061203655", name: "Primesouth Bank" },
    { aba: "031908485", name: "Bryn Mawr Trust Co" },
    { aba: "074901672", name: "Star Financial Bank" },
    { aba: "065002030", name: "Capital One, N.a." },
    { aba: "067002533", name: "Eastern National Bank" },
    { aba: "061204971", name: "Bank Of Soperton" },
    { aba: "061203642", name: "Farmers & Merchants Bank" },
    { aba: "061202384", name: "Synovus Bank" },
    { aba: "042208174", name: "Twin Valley Bank" },
    { aba: "022000046", name: "M & T Bank" },
    { aba: "067001275", name: "Banco Popular, North America" },
    { aba: "071109846", name: "Warren-boynton State Bank" },
    { aba: "067013247", name: "Englewood Bank & Trust" },
    { aba: "092905278", name: "Wells Fargo Bank Na" },
    { aba: "031919115", name: "First Bank" },
    { aba: "061192481", name: "Heritage First Bank" },
    { aba: "061201042", name: "Citizens Bank Of Americus" },
    { aba: "083903454", name: "Cecilian Bank" },
    { aba: "061202371", name: "Peoplesouth Bank" },
    { aba: "064200914", name: "Regions Bank" },
    { aba: "081905182", name: "Peoples Bank & Trust" },
    { aba: "022000020", name: "Hsbc Bank Usa, N.a" },
    { aba: "092905265", name: "Valley Bank Of Ronan" },
    { aba: "082905851", name: "Mcgehee Bank" },
    { aba: "061202368", name: "Ogeechee Valley Bank" },
    { aba: "021583771", name: "Universal Coop Fcu" },
    { aba: "021401617", name: "Valley National Bank" },
    { aba: "092905249", name: "Stockman Bank Of Montana" },
    { aba: "021303139", name: "Nbt Bank Na" },
    { aba: "064200930", name: "First Horizon Bank" },
    { aba: "067001246", name: "Bank Of Belle Glade" },
    { aba: "083902109", name: "Citizens Union Bank" },
    { aba: "091303046", name: "United Community Bank Of No Dakota" },
    { aba: "021001088", name: "Hsbc Bank Usa, N.a." },
    { aba: "083903425", name: "Dixon Bank" },
    { aba: "084201139", name: "Cadence Bank, N.a." },
    { aba: "051405188", name: "Atlantic Union Bank" },
    { aba: "091005836", name: "Alerus Financial" },
    { aba: "041212899", name: "First Natl Bk Of Sycamore" },
    { aba: "053112152", name: "First Community Bank" },
    { aba: "073901411", name: "Iowa Trust And Savings Ban" },
    { aba: "071119289", name: "Bank Of Rantoul" },
    { aba: "084202442", name: "Renasant Bank" },
    { aba: "091510253", name: "Northern State Bank" },
    { aba: "091303020", name: "Stock Growers Bank" },
    { aba: "091907235", name: "Bremer Bank, National Association" },
    { aba: "065403875", name: "Regions Bank" },
    { aba: "043306910", name: "Mid Penn Bank" },
    { aba: "072471939", name: "First Citizens Bank & Trust Co." },
    { aba: "091910879", name: "First National Bank Minnesota" },
    { aba: "031305253", name: "Keybank National Association" },
    { aba: "063115505", name: "Mainstreet Community Bank Of Fl" },
    { aba: "044205146", name: "Farmers Bank & Savings Co." },
    { aba: "044206475", name: "First National Bank" },
    { aba: "074912302", name: "Bath State Bk" },
    { aba: "067014592", name: "Sunstate Bank" },
    { aba: "053112178", name: "First Bank" },
    { aba: "073902766", name: "First Bank" },
    { aba: "073900108", name: "Cedar Rapids Bank & Trust Co." },
    { aba: "061213043", name: "Morris Bank" },
    { aba: "072471955", name: "Auto Club Trust, Fsb" },
    { aba: "073902779", name: "Fidelity Bank And Trust" },
    { aba: "091207278", name: "Dunnell Off Farmers St Bk" },
    { aba: "091005807", name: "Bnc National Bank" },
    { aba: "021110209", name: "Keybank National Association" },
    { aba: "071107262", name: "Petefish Skiles & Co" },
    { aba: "073913425", name: "First State Bank" },
    { aba: "084107893", name: "Iberiabank" },
    { aba: "011375368", name: "Brookline Bank" },
    { aba: "053112110", name: "First National Bank Of Pennsylvania" },
    { aba: "071916408", name: "Fifth Third Bank" },
    { aba: "081025114", name: "Providence Bank" },
    { aba: "065304084", name: "First State Bank" },
    { aba: "061213069", name: "Ameris Bank" },
    { aba: "074913660", name: "First Merchants Bank" },
    { aba: "053112149", name: "American National Bank & Trust Co" },
    { aba: "031307866", name: "First National Bank Of Pennsylvania" },
    { aba: "071121594", name: "Heartland Bank And Trust Co" },
    { aba: "086500142", name: "Commerce Bank" },
    { aba: "075908098", name: "Farmers Merchants Bank Of Kendall" },
    { aba: "092001208", name: "Ascent Bank" },
    { aba: "074912344", name: "The Morris Plan Co Of Terre Haute In" },
    { aba: "053112136", name: "First Horizon Bank" },
    { aba: "011375384", name: "Avidia Bank" },
    { aba: "061192423", name: "Omega Psi Phi Fraternity Fcu" },
    { aba: "031919186", name: "Citizens & Northern Bank" },
    { aba: "041212873", name: "First Natl Bk Of Pandora" },
    { aba: "051405117", name: "Atlantic Union Bank" },
    { aba: "073901479", name: "1st Natl Bk In Creston" },
    { aba: "065401246", name: "Capital One, N.a." },
    { aba: "063114108", name: "Centennial Bank" },
    { aba: "064209371", name: "Southeast Bank & Trust" },
    { aba: "063115437", name: "Valley National Bank" },
    { aba: "064208042", name: "First Horizon Bank" },
    { aba: "044207872", name: "Rockhold Brown & Co Bk" },
    { aba: "031100393", name: "Jpmorgan Chase Bank, Na" },
    { aba: "075908195", name: "Bmo Harris Bank, N.a" },
    { aba: "043306855", name: "S & T Bank" },
    { aba: "061119859", name: "Banksouth" },
    { aba: "071000301", name: "Federal Reserve Bank" },
    { aba: "011807205", name: "Community Bank, Na" },
    { aba: "072410903", name: "The Dart Bank" },
    { aba: "071001630", name: "Pnc Bank, Na" },
    { aba: "073913467", name: "First Central State Bank" },
    { aba: "061120851", name: "Piedmont Bank" },
    { aba: "073901495", name: "Iowa State Savings Bank" },
    { aba: "055002558", name: "Pnc Bank, Inc. - Baltimore" },
    { aba: "051406543", name: "Old Point National Bank Of Phoebus" },
    { aba: "062203890", name: "Premier Bank Of The South" },
    { aba: "075910484", name: "Dmb Community Bank" },
    { aba: "073900182", name: "Northwest Bank & Trust Co" },
    { aba: "061120822", name: "Westside Bank" },
    { aba: "091015143", name: "Cortrust Bank National Association" },
    { aba: "063102152", name: "Suntrust" },
    { aba: "071025645", name: "American Metro Bank" },
    { aba: "061107861", name: "The Farmers Bank" },
    { aba: "066015576", name: "Synovus Bank" },
    { aba: "072410929", name: "Sidney State Bank" },
    { aba: "021001033", name: "Deutsche Bank Trust Co Americas" },
    { aba: "061120835", name: "First Century Bank, Na" },
    { aba: "084201184", name: "Mechanics Bank" },
    { aba: "063114137", name: "Bank Ozk" },
    { aba: "062203874", name: "Bank Of Vernon" },
    { aba: "071002943", name: "State Bank Of Texas" },
    { aba: "061120806", name: "Liberty First Bank" },
    { aba: "061119817", name: "Ameris Bank" },
    { aba: "043304239", name: "First National Bank Of Pennsylvania" },
    { aba: "091101730", name: "First National Bk & Tr Co Of Iron Mt" },
    { aba: "084201171", name: "Renasant Bank" },
    { aba: "053185066", name: "Members Credit Union" },
    { aba: "011302438", name: "Bank Of America N.a." },
    { aba: "055002503", name: "Community Bank Of The Chesapeake" },
    { aba: "071903055", name: "Midland States Bank" },
    { aba: "071025661", name: "Bmo Harris Bank, N.a." },
    { aba: "061120819", name: "Southern States Bank" },
    { aba: "063116737", name: "South State Bank, Na" },
    { aba: "043317569", name: "Parkvale Bank" },
    { aba: "063115408", name: "Commerce National Bank & Trust" },
    { aba: "053185079", name: "Self-help Credit Union" },
    { aba: "062201274", name: "Synovus Bank" },
    { aba: "042100049", name: "Pnc Bank, Na" },
    { aba: "061119820", name: "South State Bank, Na" },
    { aba: "053112071", name: "Surrey Bank & Trust" },
    { aba: "071904355", name: "Bmo Harris Bank,n.a." },
    { aba: "042102694", name: "Community Trust Bank, Inc" },
    { aba: "062202574", name: "Auburnbank" },
    { aba: "031318596", name: "First National Bank Of Pennsylvania" },
    { aba: "061107816", name: "First- Citizens Bank & Trust Co" },
    { aba: "063115411", name: "Bank Ozk" },
    { aba: "071927002", name: "Busey Bank" },
    { aba: "044015747", name: "First Merchants Bank, N.a." },
    { aba: "071002914", name: "Bankfinancial, National Association" },
    { aba: "073912196", name: "Breda Savings Bank" },
    { aba: "074913712", name: "First Merchants Bank" },
    { aba: "075911768", name: "National Exchange Bank & Tr" },
    { aba: "053112097", name: "First Horizon Bank" },
    { aba: "044109297", name: "North Valley Bank" },
    { aba: "071904371", name: "Bmo Harris Bank,n.a." },
    { aba: "061119804", name: "Century Bank Of Georgia" },
    { aba: "071927060", name: "American Heartland Bank And Trust" },
    { aba: "081508466", name: "Peoples Svg Bk" },
    { aba: "071110929", name: "Reynolds State Bk" },
    { aba: "062105493", name: "Samson Banking Co" },
    { aba: "061204913", name: "Community Banking Co. Of Fitzgerald" },
    { aba: "082903109", name: "Bk Of Cave City" },
    { aba: "075911742", name: "Incrediblebank" },
    { aba: "044111557", name: "Commodore Bank" },
    { aba: "067003927", name: "Synovus Bank" },
    { aba: "065305326", name: "Bank Of Forest" },
    { aba: "042103994", name: "Pbk Bank Inc" },
    { aba: "062203816", name: "Bancorpsouth Bank" },
    { aba: "026007773", name: "Sterling National Bank" },
    { aba: "075910400", name: "State Bank Of Chilton" },
    { aba: "052080256", name: "State Department Fcu" },
    { aba: "042102652", name: "City National Bank Of West Virginia" },
    { aba: "081205112", name: "The Fnb Community Bank" },
    { aba: "061204900", name: "South State Bank" },
    { aba: "064102397", name: "Regions Bank" },
    { aba: "083914236", name: "Freedom Bank" },
    { aba: "043316256", name: "S & T Bank" },
    { aba: "075909408", name: "Ixonia Bank" },
    { aba: "092905210", name: "Granite Mountain Bank, Inc." },
    { aba: "043400052", name: "United Bank" },
    { aba: "092905207", name: "Belt Valley Bank" },
    { aba: "081507124", name: "Great Southern Bank" },
    { aba: "064102384", name: "Peoples Bank & Trust Co" },
    { aba: "091812430", name: "Charter Bank" },
    { aba: "075911713", name: "Abbybank" },
    { aba: "061204968", name: "Bank Ozk" },
    { aba: "071001685", name: "Banco Popular" },
    { aba: "055001258", name: "Middletown Valley Bank" },
    { aba: "041284403", name: "Toledo Urban Federal Credit Union" },
    { aba: "061120880", name: "Peoples Community National Bank" },
    { aba: "031100351", name: "Bank Of New York Mellon" },
    { aba: "091016498", name: "Bmo Harris Bank Na" },
    { aba: "081205138", name: "Bank Of Herrin" },
    { aba: "071000343", name: "Fifth Third Bank" },
    { aba: "067014615", name: "Synovus Bank" },
    { aba: "062203858", name: "Bank Independent" },
    { aba: "043400036", name: "Wesbanco Bank Inc" },
    { aba: "067015944", name: "Seacoast National Bank" },
    { aba: "082904483", name: "Iberiabank" },
    { aba: "061120893", name: "Coastalstates Bank" },
    { aba: "061105232", name: "United Community Banks, Inc" },
    { aba: "081507166", name: "Citizens Bk Of Rogersville" },
    { aba: "063114166", name: "Synovus Bk" },
    { aba: "063115495", name: "Synovus Bank" },
    { aba: "061119875", name: "United Community Bank, Inc" },
    { aba: "091401142", name: "Quoin Financial Bank" },
    { aba: "067015928", name: "First American Bank" },
    { aba: "081304822", name: "Northwest Bank" },
    { aba: "064208013", name: "Carter Co Bk A Div Of Bk Of Tenn" },
    { aba: "055002574", name: "Sandy Spring Bank" },
    { aba: "031100380", name: "Deutsche Bank Trust Company, De" },
    { aba: "071193250", name: "Canaan Credit Union" },
    { aba: "051502298", name: "Community Bank" },
    { aba: "081918425", name: "First State Community Bank" },
    { aba: "061119888", name: "Cadence Bank, Na" },
    { aba: "081519141", name: "Connections Bank" },
    { aba: "063211001", name: "Synovus Bank" },
    { aba: "071108449", name: "Marion County Savings Bank" },
    { aba: "021583645", name: "Coop. De A/c Comunal De Dorado" },
    { aba: "067015999", name: "Legacy Bank Of Florida" },
    { aba: "081026540", name: "Bank Of Belleville" },
    { aba: "081509957", name: "Bank Of St Elizabeth" },
    { aba: "051501150", name: "Citizens Bank Of West Virginia, Inc." },
    { aba: "061203558", name: "The Citizens Bank Of Swainsboro" },
    { aba: "042102717", name: "Kentucky Bank" },
    { aba: "051502489", name: "Wesbanco Bank, Inc." },
    { aba: "022000127", name: "Bank Of America, N.a." },
    { aba: "084204929", name: "Bank Of Holly Springs" },
    { aba: "091403865", name: "First Fidelity Bank" },
    { aba: "062104203", name: "Trustmark National Bank" },
    { aba: "067015986", name: "Tiaa Bank" },
    { aba: "062105532", name: "Bank Of Evergreen" },
    { aba: "075900902", name: "Bmo Harris Bank, N.a" },
    { aba: "072471887", name: "The Northern Trust Co" },
    { aba: "081206373", name: "Farmers State Bank" },
    { aba: "051502476", name: "Wesbanco Bank, Inc." },
    { aba: "081025208", name: "Commerce Bank" },
    { aba: "064101233", name: "First National Bk Of Tennessee" },
    { aba: "011701987", name: "Ledyard National Bank" },
    { aba: "067015973", name: "Centennial Bank" },
    { aba: "081510933", name: "The Bank Of Missouri" },
    { aba: "081508602", name: "Alton Bk" },
    { aba: "081905292", name: "Buena Vista National Bank" },
    { aba: "083009060", name: "Pnc Bank, Indiana" },
    { aba: "071108452", name: "Morton Community Bank" },
    { aba: "044109417", name: "Buckeye State Bank" },
    { aba: "091403881", name: "Rivers Edge Bank" },
    { aba: "067003985", name: "Bank Of America, N.a." },
    { aba: "061204858", name: "Thomasville National Bank" },
    { aba: "091402552", name: "Bankstar Financial" },
    { aba: "071107123", name: "State Bank Of Bement" },
    { aba: "031318606", name: "First National Bank Of Pennsylvania" },
    { aba: "051501163", name: "Community Savings Bank" },
    { aba: "081905289", name: "United Bank Of Union" },
    { aba: "065402494", name: "Capital One, N.a." },
    { aba: "092905168", name: "Wells Fargo Bank Na" },
    { aba: "071108407", name: "United Community Bk Chatham" },
    { aba: "031919225", name: "Oceanfirst Bank" },
    { aba: "011001726", name: "Brookline Bank" },
    { aba: "083903580", name: "United Southern Bank" },
    { aba: "021413728", name: "Valley National Bank" },
    { aba: "083902248", name: "Wesbanco Bank, Inc." },
    { aba: "051502434", name: "First Community Bank, Na" },
    { aba: "071108423", name: "Farmers Natl Bank" },
    { aba: "081519303", name: "First State Bank Of Purdy" },
    { aba: "071109752", name: "Illinois Bank & Trust" },
    { aba: "092905142", name: "Yellowstone Bank" },
    { aba: "067014686", name: "Tiaa Bank" },
    { aba: "091403836", name: "Bankwest, Inc" },
    { aba: "073915915", name: "Citizens Savings Bank" },
    { aba: "091210757", name: "Farmers St Bank Of Trimont" },
    { aba: "062105529", name: "Banksouth" },
    { aba: "083903564", name: "First State Bank" },
    { aba: "081509973", name: "Century Bank Of The Ozarks" },
    { aba: "011001742", name: "Bank Of America, N.a." },
    { aba: "051501134", name: "Davis Trust Company" },
    { aba: "061202245", name: "First National Bank" },
    { aba: "031919209", name: "Citizens & Northern Bank" },
    { aba: "091209755", name: "First National Bank North" },
    { aba: "021583658", name: "Coop A/c Emp. Auto Energia Electrica" },
    { aba: "091210744", name: "First State Bank" },
    { aba: "071122713", name: "Southern Trust Bank" },
    { aba: "067014673", name: "Commercebank, N.a." },
    { aba: "031919212", name: "Meridian Bank" },
    { aba: "062192804", name: "Alabama River Credit Union" },
    { aba: "053101396", name: "Bank Of America, N.a." },
    { aba: "031305347", name: "First National Bank Of Pennsylvania" },
    { aba: "053112042", name: "First Horizon Bank" },
    { aba: "063191956", name: "South State Bank, Na" },
    { aba: "011302519", name: "Eastern Bank" },
    { aba: "073903943", name: "Success Bank" },
    { aba: "065102833", name: "Commonwealth National Bank" },
    { aba: "084201003", name: "Renasant Bank" },
    { aba: "065401107", name: "United Community Bank" },
    { aba: "053112039", name: "Pinnacle Bank" },
    { aba: "041284393", name: "L.e.o. Credit Union, Inc." },
    { aba: "041209129", name: "Civista Bank" },
    { aba: "011701903", name: "Bank Of New Hampshire" },
    { aba: "091208471", name: "Community First Bank" },
    { aba: "063116708", name: "Florida Business Bank" },
    { aba: "073901301", name: "Lee County Bank." },
    { aba: "053112068", name: "First Horizon Bank" },
    { aba: "091209797", name: "Wanda State Bank" },
    { aba: "065402423", name: "First American Bk + Trust" },
    { aba: "051502421", name: "Calhoun County Bank" },
    { aba: "031318677", name: "Landmark Community Bank" },
    { aba: "051405285", name: "Fulton Bank" },
    { aba: "071905817", name: "The State Bank Of Geneva" },
    { aba: "072000915", name: "Pnc Bank, Michigan" },
    { aba: "067012057", name: "Pnc Bank, National Association" },
    { aba: "091403807", name: "Cortrust Bank-pierpont Office" },
    { aba: "053112055", name: "First Horizon Bank" },
    { aba: "073901314", name: "Connection Bank" },
    { aba: "051405272", name: "Atlantic Union Bank" },
    { aba: "063103407", name: "Citizens Bank And Trust" },
    { aba: "064101204", name: "Macon Bank & Trust Co." },
    { aba: "031318619", name: "M & T Bank" },
    { aba: "091907374", name: "Elysian Bank" },
    { aba: "073903985", name: "Waukon State Bank" },
    { aba: "052201938", name: "Fnb Bank, Inc." },
    { aba: "053101341", name: "First National Bank Of Pennslyvania" },
    { aba: "073913302", name: "Farmers Savings Bank" },
    { aba: "064272421", name: "Hometrust Bank" },
    { aba: "071905846", name: "Bmo Harris Bank,n.a." },
    { aba: "052201925", name: "The Bank Of Romney" },
    { aba: "071904517", name: "First Midwest Bank" },
    { aba: "073902672", name: "Farmers & Merchants Savings Bank" },
    { aba: "071121471", name: "Fifth Third Bank" },
    { aba: "064103833", name: "Fifth Third Bank" },
    { aba: "041200775", name: "Key Bank" },
    { aba: "074911138", name: "De Motte State Bank" },
    { aba: "067012099", name: "Comerica Bank" },
    { aba: "081509902", name: "The Bank Of Missouri" },
    { aba: "084203603", name: "Bankplus" },
    { aba: "075910387", name: "Farmers State Bank" },
    { aba: "091207087", name: "State Bank Of Danvers" },
    { aba: "071903123", name: "Fifth Third Bank" },
    { aba: "082903057", name: "First Natl Bk Of Izard Co" },
    { aba: "053200983", name: "South State Bank" },
    { aba: "062201342", name: "Robertson Banking Company-demopolis" },
    { aba: "057000668", name: "Truist Bank" },
    { aba: "063104668", name: "Regions Bank" },
    { aba: "061119736", name: "Ameris Bank" },
    { aba: "086501578", name: "Jefferson Bank Of Missouri" },
    { aba: "041055551", name: "Frb Cleveland - Expense Accounting" },
    { aba: "061120738", name: "Bank Ozk" },
    { aba: "073913344", name: "Security State Bank" },
    { aba: "071903136", name: "Bmo Harris Bank,n.a." },
    { aba: "051404008", name: "Southern Bank & Trust Co" },
    { aba: "091016566", name: "Citizens Independent Bank" },
    { aba: "082001687", name: "Bank Of Little Rock" },
    { aba: "021906471", name: "M & T Bank" },
    { aba: "026008905", name: "Mizuho Bank (usa)" },
    { aba: "011110756", name: "New Haven Bank" },
    { aba: "061120709", name: "Heritage Southeast Bank" },
    { aba: "042100191", name: "Huntington National Bank" },
    { aba: "063114001", name: "Citizens First Bank" },
    { aba: "011110743", name: "Easthampton Savings Bank" },
    { aba: "051405324", name: "United Bank" },
    { aba: "062203984", name: "Truist Bank" },
    { aba: "071903110", name: "Hanmi Bank" },
    { aba: "055002406", name: "Wesbanco Bank, Inc." },
    { aba: "021206249", name: "Provident Bank" },
    { aba: "063104697", name: "Bank Of America, N.a." },
    { aba: "091306823", name: "First State Bank" },
    { aba: "042100188", name: "Pnc Bank Ach Services" },
    { aba: "011110730", name: "Bankwell Bank" },
    { aba: "063116672", name: "Seacoast National Bank" },
    { aba: "062203997", name: "Synovus Bank" },
    { aba: "052103515", name: "The Bank Of Delmarva" },
    { aba: "044015831", name: "Avidia Bank" },
    { aba: "061119723", name: "South State Bank, Na" },
    { aba: "057000697", name: "Huntington National Bank" },
    { aba: "075908043", name: "Nekoosa Prt Edwards St Bk" },
    { aba: "071001737", name: "Fifth Third Bank" },
    { aba: "075908027", name: "Waldo State Bank" },
    { aba: "084201058", name: "First American National Bank" },
    { aba: "031304050", name: "Bank Of America N.a." },
    { aba: "053208037", name: "First Horizon Bank" },
    { aba: "071916482", name: "Waterman State Bank" },
    { aba: "043317679", name: "First National Bank Of Pennsylvania" },
    { aba: "042100175", name: "Us Bank Na" },
    { aba: "065305478", name: "Renasant Bank" },
    { aba: "081208928", name: "First National Bank Of Carmi" },
    { aba: "065402520", name: "Bank Of Zachary" },
    { aba: "044108078", name: "The Pataskala Banking Co." },
    { aba: "063104626", name: "Brannen Bank" },
    { aba: "073912073", name: "United Bank Of Iowa" },
    { aba: "065402517", name: "First National Bank Usa" },
    { aba: "053208024", name: "First Federal Bank" },
    { aba: "063191914", name: "Liberty Savings Bank, Fsb" },
    { aba: "063116614", name: "South State Bank, Na" },
    { aba: "073900085", name: "The Security National Bank" },
    { aba: "062201397", name: "First Financial Bank" },
    { aba: "073912060", name: "Benton County State Bank" },
    { aba: "073912086", name: "Blue Grass Savings Bank" },
    { aba: "071025797", name: "Republic Bank Of Chicago" },
    { aba: "044015860", name: "Lcnb National Bank" },
    { aba: "071904478", name: "Bmo Harris Bank,n.a." },
    { aba: "053208011", name: "Southern First Bank" },
    { aba: "081025198", name: "Fcb Banks" },
    { aba: "041209080", name: "Farmers National Bank Of Canfield" },
    { aba: "091015224", name: "Us Bank Na" },
    { aba: "031317380", name: "First National Bank Of Pennsylvania" },
    { aba: "062202697", name: "Union State Bank" },
    { aba: "057001971", name: "Jefferson Security Bank" },
    { aba: "063105984", name: "Brannen Bank" },
    { aba: "042101475", name: "Citizens Guaranty Bank" },
    { aba: "053208008", name: "First Reliance Bank" },
    { aba: "042100146", name: "Central Bank & Trust Co." },
    { aba: "041284377", name: "S.t.o.u.f.e.e. Federal Credit Union" },
    { aba: "041210053", name: "Killbuck Savings Bank Co" },
    { aba: "084201029", name: "Fnb Of Clarksdale" },
    { aba: "075908001", name: "Premierbank" },
    { aba: "062104290", name: "Sweet Water State Bk" },
    { aba: "081519248", name: "Table Rock Community Bank" },
    { aba: "053208079", name: "United Bank" },
    { aba: "011000390", name: "Bank Of America N.a" },
    { aba: "065305436", name: "Regions Bank" },
    { aba: "082904302", name: "Bank Of Salem" },
    { aba: "081907847", name: "Farmers And Merchants Bk" },
    { aba: "072409888", name: "Fifth Third Bank" },
    { aba: "075900973", name: "First Natl Bk & Tr Co" },
    { aba: "072408546", name: "Eaton Community Bank" },
    { aba: "031100209", name: "Citibank Na" },
    { aba: "053208066", name: "Ameris Bank" },
    { aba: "062203939", name: "Synovus Bank" },
    { aba: "081906505", name: "The First National Bank In Carlyle" },
    { aba: "053273981", name: "South State Bank" },
    { aba: "042102788", name: "Peoples Bank Of Kentucky, Inc." },
    { aba: "075900986", name: "Bmo Harris Bank, N.a" },
    { aba: "042101459", name: "Huntington National Bank" },
    { aba: "064101194", name: "First National Bank" },
    { aba: "051900395", name: "United Bank" },
    { aba: "053208053", name: "First Community Bank, N.a." },
    { aba: "021304675", name: "Ballston Spa National Bank" },
    { aba: "063200407", name: "Bank Of America, N.a." },
    { aba: "055002480", name: "First National Bank Of Pennsylvania" },
    { aba: "042101446", name: "Citizens National Bank" },
    { aba: "081206328", name: "Peoples State Bk" },
    { aba: "061119794", name: "First Ic Bank" },
    { aba: "081507234", name: "Homepride Bank" },
    { aba: "075911616", name: "Peoples State Bank" },
    { aba: "053208040", name: "South State Bank" },
    { aba: "081519219", name: "1st Missouri State Bank Of Cape Cty" },
    { aba: "075911603", name: "Us Bank Na" },
    { aba: "071109820", name: "First Farmers St Bk Minier" },
    { aba: "062203913", name: "Investar Bank, National Association" },
    { aba: "031100225", name: "Wells Fargo Bank" },
    { aba: "021305991", name: "First Natl Bk Of Groton" },
    { aba: "071110819", name: "Longview Bank" },
    { aba: "064101178", name: "Bank Of America" },
    { aba: "064209216", name: "Smartbank" },
    { aba: "021583726", name: "Coop. De A/c Emp. Del Centro Medico" },
    { aba: "062203968", name: "First Us Bank" },
    { aba: "026007689", name: "Bnp Paribas" },
    { aba: "082086589", name: "Arkansas Education Association Fcu" },
    { aba: "053174213", name: "Ks Bank" },
    { aba: "064209203", name: "Hometrust Bank" },
    { aba: "067014738", name: "Power Financial Credit Union" },
    { aba: "091402578", name: "First Dakota National Bank" },
    { aba: "026007692", name: "Banco Santander, S.a." },
    { aba: "072408504", name: "Fifth Third Bank" },
    { aba: "061119781", name: "Ameris Bank" },
    { aba: "081519280", name: "Kennett National Bank" },
    { aba: "063115369", name: "South State Bank, Na" },
    { aba: "082904360", name: "Bodcaw Bank" },
    { aba: "051502395", name: "Bank Of Monroe" },
    { aba: "061120770", name: "River City Bank" },
    { aba: "063114030", name: "South State Bank, Na" },
    { aba: "062104274", name: "Amerifirst Bank" },
    { aba: "053208095", name: "United Community Bank" },
    { aba: "084309125", name: "Commercial Bank & Trust" },
    { aba: "082903044", name: "Centennial Bank" },
    { aba: "082904373", name: "Connect Bank" },
    { aba: "062203942", name: "Peoples Independent Bank" },
    { aba: "042101404", name: "Wesbanco Bank, Inc." },
    { aba: "063115372", name: "First Horizon Bank" },
    { aba: "011110701", name: "Webster Bank N.a." },
    { aba: "081919880", name: "The Village Bank" },
    { aba: "061021060", name: "Georgia Primary Bank" },
    { aba: "051900366", name: "Jpmorgan Chase" },
    { aba: "067014712", name: "South State Bank, Na" },
    { aba: "083902196", name: "First National Bank Of Kentucky" },
    { aba: "062203955", name: "First Metro Bank Muscle Shoals" },
    { aba: "083000726", name: "River City Bank Inc" },
    { aba: "026005050", name: "Mufg Union Bank, N.a." },
    { aba: "055001122", name: "Pnc Bank Inc. - Baltimore" },
    { aba: "061119765", name: "First Citizens Bank & Trust Co" },
    { aba: "021206278", name: "Amboy Bank" },
    { aba: "044109336", name: "The Heartland Bank" },
];
