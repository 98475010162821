import * as Sentry from "@sentry/react";
import { Dispatch } from "redux";
import Constants from "utils/Constants";

const ignoreErrors = [
    "Non-Error",
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
    "chain is not set up", // https://pionex.sentry.io/issues/4193060587/?project=4505148045262848
    "firefoxSample",
    "wallet must has at least one account", // metamask
    'The method "isDefaultWallet" does not exist / is not available.', // metamask
    // @fingerprintjs/fingerprintjs
    // https://pionex.sentry.io/issues/4323766892/?project=4505148045262848
    "Failed to execute 'startRendering' on 'OfflineAudioContext'",
    "FirebaseError",
    "Remote Config",
    "Database deleted by request of the user",
];

function getDsn(): string | undefined {
    return "https://3c4d5304a5ef9bd19e06bdc1b62d42f1@o4505147559706624.ingest.us.sentry.io/4507287987486720";
}

export function initSentry() {
    if (window.__PRERENDER_INJECTED) {
        return;
    }
    const dsn = getDsn();
    if (!dsn) {
        return;
    }
    Sentry.init({
        dsn,
        integrations: [
            Sentry.breadcrumbsIntegration(),
            Sentry.breadcrumbsIntegration({
                console: process.env.NODE_ENV === "production",
                history: false,
            }),
        ],
        sampleRate: 1.0,
        enableTracing: true,
        tracesSampleRate: 0.01,
        sendClientReports: true,
        replaysOnErrorSampleRate: 0.1,
        ignoreErrors,
        release: process.env.VERSION,
        dist: "1",
        environment: Constants.isBeta ? "development" : "production",
        beforeSend: (event, hint) => {
            if (process.env.NODE_ENV === "development") {
                console.log("SentryUtils", event, hint);
                return null;
            }
            const extraMessage = (event.extra?.__serialized__ as any)?.message;
            if (extraMessage && ignoreErrors.includes(extraMessage)) {
                console.log("SentryUtils ignore error by extra message", event, hint);
                return null;
            }
            return event;
        },
    });
    Sentry.setTags({
        device_id: Constants.deviceId,
        locale: "en",
    });
}

export function sentryMiddleware() {
    return (next: Dispatch) => (action) => {
        if (["type_account_init", "type_account_sign_in"].includes(action.type)) {
            if (action.data.userId) {
                Sentry.setUser({
                    id: action.data.userId,
                    username: action.data.nickname,
                });
            }
        }
        return next(action);
    };
}
