import { PieChartListColorData, ListColorTransition } from "landings/Balance/components/BalancePie/useOrderBalance";
import React, { useMemo, useState } from "react";
import cs from "../../balance.module.less";
import { BalancePieView } from "./BalancePieView";
import useTheme from "commonUse/useTheme";
import { useBalanceAll, useArbitrageBalanceTotalUSD } from "commonUse/useBalance";
import { useTranslators } from "src/commonUse/Locale";
import EmptyChart from "../EmptyChart";
import { SafeDecimal } from "trade_utils_lib";

export interface IBalanceProps {
    pieType: "Coins" | "Bots";
}

function FinanceBalancePie(props: IBalanceProps) {
    const { pieType } = props;
    const theme = useTheme();
    const balance = useBalanceAll();
    const { $st } = useTranslators();
    const arbitrageBalanceTotalUSD = useArbitrageBalanceTotalUSD();
    const financeTotalInUSD = balance?.total?.financeTotalInUSD;
    const financeTradeTypePercentData = useMemo(() => {
        const returnListData: any[] = [];
        let totalPercent = 0;
        if (financeTotalInUSD) {
            if (arbitrageBalanceTotalUSD) {
                const arbitragePercent = (arbitrageBalanceTotalUSD / financeTotalInUSD) * 100;
                totalPercent += arbitragePercent;
                const colorData: PieChartListColorData = ListColorTransition("Bots", 0);
                returnListData.push({
                    item: $st("fsa_title_desc"),
                    count: arbitrageBalanceTotalUSD,
                    itemPercent: new SafeDecimal(arbitragePercent).toDecimalPlaces(2).toNumber(),
                    colorStartValue: colorData.colorStartValue,
                    colorEndValue: colorData.colorEndValue,
                });
            }
            if (balance && balance.deposit) {
                const depositPercent = (balance.deposit.totalUSD / financeTotalInUSD) * 100;
                totalPercent += depositPercent;
                const colorData: PieChartListColorData = ListColorTransition("Bots", 1);
                returnListData.push({
                    item: $st("balance_finance_deposit"),
                    count: balance.deposit.totalUSD,
                    itemPercent: new SafeDecimal(depositPercent).toDecimalPlaces(2).toNumber(),
                    colorStartValue: colorData.colorStartValue,
                    colorEndValue: colorData.colorEndValue,
                });
            }
        }
        //处理精确度：99.99的情况
        if (returnListData && returnListData.length > 1) {
            if (totalPercent !== 100 && totalPercent > 99.98) {
                let total = 0;
                for (let i = 1; i < returnListData.length; i++) {
                    total = new SafeDecimal(total).add(returnListData[i].itemPercent).toNumber();
                }
                returnListData[0].itemPercent = new SafeDecimal(100).minus(total).toDecimalPlaces(2).toNumber();
            }
        }

        // 百分比为零就不显示了
        return returnListData.filter((data) => data.itemPercent !== 0);
    }, [$st, arbitrageBalanceTotalUSD, balance, financeTotalInUSD]);
    return (
        <div className={cs.pieBalanceWrapper}>
            <div className={cs.pieView}>
                <div className={cs.pieListItemLiner}>
                    {financeTradeTypePercentData.length > 0 ? (
                        <BalancePieView
                            theme={theme}
                            isLoading={false}
                            dataSource={financeTradeTypePercentData}
                            totalFiat={financeTotalInUSD || 0}
                            pieType={pieType}
                            showLabel={false}
                        />
                    ) : (
                        <EmptyChart />
                    )}
                </div>
            </div>
        </div>
    );
}

export default React.memo(FinanceBalancePie);
