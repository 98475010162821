import { message } from "antd";
import { TModal } from "components/v2/TinyComps";
import React, { createContext, PropsWithChildren, useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useCallbackStatic } from "./tools";
import useAccountInfo from "./useAccountInfo";

export enum BETA_MODULE_IDS {
    circle = "dw-circle",
    credit_card = "credit_card",
    ach = "dw-ach",
    taxes = "taxes",
}

const INTERNAL_TEST_JOIN_KEY = "INTERNAL_TEST_JOIN_KEY";

let timer;
let fastClickTimes = 1;

const _getStoreObj = (): { [k: string]: string[] } => {
    try {
        return JSON.parse(localStorage.getItem(INTERNAL_TEST_JOIN_KEY) || "{}");
    } catch (error) {}
    return {};
};
const _getUserIDsByModuleID = (mId: string) => {
    const _obj = _getStoreObj();
    return _obj[mId] || [];
};
const _setUserIDsByModuleID = (mId: string, uid: string) => {
    const _obj = _getStoreObj();
    _obj[mId] = _obj[mId] || [];
    _obj[mId].push(uid);
    try {
        localStorage.setItem(INTERNAL_TEST_JOIN_KEY, JSON.stringify(_obj));
    } catch (error) {}
    return _obj[mId];
};

export type BetaModuleUserIds = {
    [k in BETA_MODULE_IDS]?: string[];
};
export const CTXJoinBeta = createContext({
    _registerBetaModuleId(_ids: BetaModuleUserIds) {},
    isBetaUserOfModule: {} as { [k: string]: boolean },
    continuouslyClick10TimesToJoinBetaWithModuleId() {},
});

export function JoinBetaTestProvider({ children }: PropsWithChildren<any>) {
    const [onlineConfigInfo, setOnlineConfigInfo] = useState({});
    const [internalIds, setInternalIds] = useState<{ [k: string]: string[] }>(() => {
        const cache = _getStoreObj();
        return Object.entries(BETA_MODULE_IDS).reduce((ob, [_, mId]) => {
            if (!Array.isArray(ob[mId])) {
                ob[mId] = [];
            }
            return ob;
        }, cache);
    });
    const accountInfo = useAccountInfo();

    // 注册更新被监听的模块 useID
    const _registerBetaModuleId = useCallbackStatic((userTestDataInfos: BetaModuleUserIds) => {
        setOnlineConfigInfo(userTestDataInfos);
    });

    // 点击10次，以触发填写内测ID的弹窗。
    const continuouslyClick10TimesToJoinBetaWithModuleId = useCallbackStatic(() => {
        if (accountInfo.userId && fastClickTimes >= 10) {
            // 需要用户输出内测模块编号，已确定进入内测
            const _moduleId = prompt("Please enter a beta module id:");
            const _targetIds = _moduleId && internalIds[_moduleId];
            if (_targetIds) {
                internalIds[_moduleId] = _setUserIDsByModuleID(_moduleId, accountInfo.userId);
                setInternalIds({ ...internalIds });
                TModal.success({
                    content: `You have joined the beta of the module-[${_moduleId}].`,
                    onOk: () => {
                        // window.location.reload();
                    },
                });
            } else message.error("Wrong module id!");
        }
        fastClickTimes++;
        clearTimeout(timer);
        timer = setTimeout(() => {
            fastClickTimes = 1;
        }, 1e3);
    });
    // 是否某个模块的内测用户
    const isBetaUserOfModule = useMemo(() => {
        return Object.entries(internalIds).reduce((obj, [mId, uIds]: [string, string[]]) => {
            if (!!accountInfo?.userId) {
                obj[mId] = uIds.includes(accountInfo.userId) || (Array.isArray(onlineConfigInfo[mId]) && onlineConfigInfo[mId].includes(accountInfo.userId));
            } else {
                obj[mId] = false;
            }
            return obj;
        }, {} as { [k: string]: boolean });
    }, [accountInfo.userId, internalIds, onlineConfigInfo]);
    return <CTXJoinBeta.Provider value={{ _registerBetaModuleId, isBetaUserOfModule, continuouslyClick10TimesToJoinBetaWithModuleId }}>{children}</CTXJoinBeta.Provider>;
}

/**
 * 当传参类型不同时，返回类型也会不同
 */
export default function useUNSAFE_JoinInternalTest(): () => void;
export default function useUNSAFE_JoinInternalTest<I extends string[], K extends I[number]>(...moduleIds: I): { [k in K]: boolean };
export default function useUNSAFE_JoinInternalTest<I extends string[], K extends I[number]>(...moduleIds: I) {
    const { continuouslyClick10TimesToJoinBetaWithModuleId, isBetaUserOfModule, _registerBetaModuleId } = useContext(CTXJoinBeta);
    const [isModuleMode] = useState(() => !!moduleIds.length);
    // useLayoutEffect(() => {
    //     // 注册需要监听的内测模块ID
    //     moduleIds.length && _registerBetaModuleId(moduleIds);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    return isModuleMode ? (isBetaUserOfModule as { [k in K]: boolean }) : continuouslyClick10TimesToJoinBetaWithModuleId;
}
