import { ColumnsType } from "antd/lib/table";
import { ST, useTranslators } from "commonUse/Locale";
import useDateFormatter from "commonUse/useDateFormatter";
import useSignRequest from "commonUse/useSignRequest";
import useTheme from "commonUse/useTheme";
import TReportLink, { Iconfont, TSpin, TTable, TTooltip } from "components/v2/TinyComps";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ThemeName } from "state/ThemeData";
import Constants from "utils/Constants";
import cs from "landings/V2/SignatureDW/cs.m.less";
import CopyToClipboard from "react-copy-to-clipboard";
import { getCircleFailReasonText } from "landings/V2/USDDW/utils";
import USDTSwapModal from "src/components-v2/USDTSwapModal";
import { useToggle } from "commonUse/tools";
import { ACH_DepositStatus } from "landings/V2/CircleACH/utils";
import { parseError } from "utils/Utils";
import { message, Spin, Table } from "@pionex-web-kit/components";

interface PageRecords<T> {
    page_no: number;
    page_size: number;
    records: T;
    total: number;
    total_page_no: number;
}

interface HistoryItem {
    // 入金ID
    id: string;
    // 入金类型
    type: "ACH" | "CARD";
    status: ACH_DepositStatus;
    // 失败原因;
    fail_reason: string;
    // 创建时间;
    create_time: number;
    // 更新时间;
    update_time: number;
    payment_info: {
        account_no: string;
        bank_name: string;
        // ach账户ID;
        ach_account_id: string;
        // 金额;
        amount: string;
        // 币种;
        currency: string;
        // 费用金额;
        fee_amount: string;
        // 费用币种;
        fee_currency: string;
    };
}

/**
 * 获取ACH入金状态提示
 */
export function getDepositStatusTips($st: ST, { status, fail_reason }: HistoryItem): string | undefined {
    switch (status) {
        case ACH_DepositStatus.FAILED:
            return getCircleFailReasonText($st, fail_reason);
        case ACH_DepositStatus.EARLY_CREDIT:
            return $st("ach_deposit_status_tips:EARLY_CREDIT");
        case ACH_DepositStatus.DEDUCTED:
            return $st("ach_deposit_status_tips:DEDUCTED");

        default:
            return undefined;
    }
}

export default function OtherHistory() {
    const [paymentList, setPaymentList] = useState<HistoryItem[]>([]);
    const [listLoading, setListLoading] = useState(false);
    const dateFormatter = useDateFormatter();
    const { $st } = useTranslators();
    const theme = useTheme();
    const [swapVisible, { setTrue: setSwapShow, setFalse: setSwapHide }] = useToggle(false);
    const signRequest = useSignRequest();
    const getBankList = useCallback(async () => {
        if (listLoading) return;
        setListLoading(true);
        try {
            const { code, data } = await signRequest<PageRecords<HistoryItem[] | null>>(`${Constants.walletApiHost}/circle/ach/deposits/?page_no=1&page_size=200`).toPromise();
            if (code === 0 && data.records) {
                setPaymentList(data.records);
            }
        } catch (e) {
            console.error(e);
            message.error(parseError(e));
        }
        setListLoading(false);
    }, [listLoading, signRequest]);
    useEffect(() => {
        getBankList();
    }, []);
    const columns = useMemo(() => {
        const columns: ColumnsType<HistoryItem> = [
            {
                title: $st("signature_dw_history_order_id"),
                dataIndex: "id",
                key: "id",
                render: (id) => {
                    return (
                        <CopyToClipboard text={id} onCopy={() => message.success($st("tip_copy_success"))}>
                            <TReportLink>
                                {id.slice(0, 8)}... <Iconfont icon="icon_copy" className={cs.copy} />
                            </TReportLink>
                        </CopyToClipboard>
                    );
                },
            },
            {
                title: $st("debit_record_deposit_from"),
                dataIndex: "depositFrom",
                key: "depositFrom",
                render: (_, { payment_info: { account_no } }) => {
                    return account_no;
                },
            },
            {
                title: $st("signature_dw_history_status"),
                dataIndex: "status",
                key: "status",
                render: (value, record, index) => {
                    const tips = getDepositStatusTips($st, record);
                    return (
                        <div>
                            {$st(`ach_deposit_status:${value}`)}
                            {!!tips && (
                                <TTooltip title={<span>{tips}</span>}>
                                    <img
                                        src={
                                            theme.name === ThemeName.dark
                                                ? require("images/trading/icon_advance_setting_info_dark.png")
                                                : require("images/trading/icon_advance_setting_info.png")
                                        }
                                        className={cs.itemTips}
                                    />
                                </TTooltip>
                            )}
                        </div>
                    );
                },
            },
            {
                title: $st("ach_history_amount"),
                dataIndex: "amount",
                key: "amount",
                render: (value, { payment_info: { amount, currency } }, index) => {
                    return `${amount} ${currency}`;
                },
            },
            {
                title: $st("signature_dw_history_fee"),
                dataIndex: "feeAmount",
                key: "feeAmount",
                render: (value, { payment_info: { fee_amount, fee_currency } }, index) => {
                    return `${fee_amount} ${fee_currency}`;
                },
            },
            {
                title: $st("signature_dw_history_time"),
                dataIndex: "create_time",
                key: "create_time",
                render: (value, record, index) => {
                    return value ? <div>{dateFormatter.year_second(value)}</div> : null;
                },
            },
            {
                title: $st("withdrawable_Time"),
                dataIndex: "withdrawable_time",
                key: "withdrawable_time",
                render: (value, record, index) => {
                    return value ? <div>{dateFormatter.year_second(value)}</div> : null;
                },
            },
            {
                title: "",
                dataIndex: "operation",
                key: "operation",
                render: () => {
                    return (
                        <div className={cs.optWrapper}>
                            <div className={cs.operation} onClick={setSwapShow}>
                                {$st("circledw_history_swap_usdt")}
                            </div>
                        </div>
                    );
                },
            },
        ];

        columns.forEach((item) => (item.width = `${100 / columns.length}%`));
        return columns;
    }, [$st, dateFormatter, setSwapShow, theme.name]);

    return (
        <Spin spinning={listLoading}>
            <Table className={cs.table} pagination={false} columns={columns} dataSource={paymentList}></Table>
            <USDTSwapModal opposite={"USD"} visible={swapVisible} onSuccess={setSwapHide} onCancel={setSwapHide} isGetUSDT />
        </Spin>
    );
}
