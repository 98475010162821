import { message } from "antd";
import { AccountType, BUUserAccount } from "bu_account_js_sdk";
import useAccountInfo from "commonUse/useAccountInfo";
import { SelfBeautyInput } from "components/SelfInput/SelfInput";
import { CodeInputView } from "components/input/VerifyCodeInput/CodeInputView";
import css from "components/input/index.module.less";
import { TModal } from "components/v2/TinyComps";
import cs from "landings/DepositWithdraw/withdraw.module.less";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ResetGoogleLink from "src/components-v2/ResetGoogleLink";
import { $st } from "src/utils/i18n";
import { VerifyCodeAction, VerifyCodeType } from "trade_asset_lib";
import { IVerificationNams, SendState } from "../../../use";

interface IVerifyModalProps {
    visible: boolean;
    submitState: SendState;
    action?: VerifyCodeAction;
    submitWidthVerifications(data: IVerificationNams);
    onCancel: () => void;
}

export default function VerifyModal(props: IVerifyModalProps) {
    const { visible, submitState, onCancel, submitWidthVerifications, action = VerifyCodeAction.withdraw } = props;

    const accountInfo = useAccountInfo();
    const [smsCode, changeSmsCode] = useState("");
    const [emailCode, changeEmailCode] = useState("");
    const [googleCode, changeGoogleCode] = useState("");
    const [smsCodeError, changeSmsCodeError] = useState(undefined as undefined | string);
    const [emailError, changeEmailCodeError] = useState(undefined as undefined | string);
    const [googleCodeError, changeGoogleCodeError] = useState(undefined as undefined | string);
    useEffect(() => {
        if (visible) {
            changeSmsCode("");
            changeEmailCode("");
            changeGoogleCode("");
        }
    }, [visible]);
    const [mainAccount, otherAccount] = useMemo(() => {
        let main: BUUserAccount | undefined;
        let other: BUUserAccount | undefined;
        if (accountInfo.accounts) {
            if (accountInfo.accounts.length > 0) {
                main = accountInfo.accounts[0];
            }
            if (accountInfo.accounts.length > 1) {
                other = accountInfo.accounts[1];
            }
        }
        return [main, other];
    }, [accountInfo.accounts]);

    const [hasSMS, hasEmail] = useMemo(() => {
        const sms = mainAccount?.account_type === AccountType.phoneNumber || otherAccount?.account_type === AccountType.phoneNumber;
        const email = mainAccount?.account_type === AccountType.email || otherAccount?.account_type === AccountType.email;
        return [sms, email];
    }, [mainAccount, otherAccount]);

    const [smsCodePlaceholder, emailCodePlaceholder, googleCodePlaceholder] = useMemo(() => {
        return [$st("withdraw.confirmation.phone.code"), $st("withdraw.confirmation.email.code"), $st("withdraw.confirmation.google.code")];
    }, []);

    const onSmsCodeChange = useCallback((text: string) => {
        changeSmsCode(text);
        let errorMsg;
        if (text && text.match("^[0-9]{6}$") === null) {
            errorMsg = $st("withdraw.confirmation.phone.code.error");
        }
        changeSmsCodeError(errorMsg);
    }, []);

    const onEmailCodeChange = useCallback((text: string) => {
        changeEmailCode(text);
        let errorMsg;
        if (text && text.match("^[0-9]{6}$") === null) {
            errorMsg = $st("withdraw.confirmation.email.code.error");
        }
        changeEmailCodeError(errorMsg);
    }, []);

    const onGoogleCodeChange = useCallback((text: string) => {
        let errorMsg;
        changeGoogleCode(text);
        if (text && text.match("^[0-9]{6}$") === null) {
            errorMsg = $st("withdraw.confirmation.google.code.error");
        }
        changeGoogleCodeError(errorMsg);
    }, []);

    const onConfirm = () => {
        let needAbort = false;
        if (hasSMS && !smsCodeError && !smsCode) {
            changeSmsCodeError($st("withdraw.confirmation.phone.code.error"));
            needAbort = true;
        }
        if (hasEmail && !emailError && !emailCode) {
            changeEmailCodeError($st("withdraw.confirmation.email.code.error"));
            needAbort = true;
        }
        if (!googleCodeError && !googleCode) {
            changeGoogleCodeError($st("withdraw.confirmation.google.code.error"));
            needAbort = true;
        }

        if (needAbort || smsCodeError || googleCodeError || emailError) {
            message.error($st("deposit_withdraw_submitted_error"));
            return;
        }
        submitWidthVerifications({
            sms_code: smsCode ? smsCode.replace(/(^\s*)|(\s*$)/g, "") : "",
            google_code: googleCode.replace(/(^\s*)|(\s*$)/g, ""),
            email_code: emailCode ? emailCode.replace(/(^\s*)|(\s*$)/g, "") : "",
        });
    };

    const cancelWithdraw = () => {
        onCancel();
        onSmsCodeChange("");
        onGoogleCodeChange("");
    };

    const accountInputView = useMemo(() => {
        return <SelfBeautyInput inPutClassName={css.inputBeauty} value={accountInfo.accounts ? accountInfo.accounts[0].account : "-"} theme={"gray-light"} disabled={true} />;
    }, [accountInfo]);

    const smsCodeInputView = useMemo(() => {
        return (
            <CodeInputView
                inputProps={{ shadowTextClassName: css.shadowTextStyle }}
                code={smsCode}
                action={action}
                codePlaceholder={smsCodePlaceholder}
                onCodeChanged={onSmsCodeChange}
                codeError={smsCodeError}
                verifyCodeType={VerifyCodeType.sms}
            />
        );
    }, [smsCode, smsCodeError, smsCodePlaceholder, onSmsCodeChange, action]);

    const emailCodeInputView = useMemo(() => {
        return (
            <CodeInputView
                code={emailCode}
                inputProps={{ shadowTextClassName: css.shadowTextStyle }}
                action={action}
                codePlaceholder={emailCodePlaceholder}
                onCodeChanged={onEmailCodeChange}
                codeError={emailError}
                verifyCodeType={VerifyCodeType.email}
            />
        );
    }, [emailCode, emailError, emailCodePlaceholder, onEmailCodeChange, action]);

    const googleCodeInputView = useMemo(() => {
        return (
            <CodeInputView
                isOptInput={true}
                code={googleCode}
                inputProps={{ shadowTextClassName: css.shadowTextStyle }}
                action={action}
                codePlaceholder={googleCodePlaceholder}
                onCodeChanged={onGoogleCodeChange}
                codeError={googleCodeError}
            />
        );
    }, [googleCode, googleCodeError, googleCodePlaceholder, onGoogleCodeChange, action]);

    return (
        <TModal
            maskClosable={false}
            centered={true}
            destroyOnClose={true}
            title={$st("withdraw.confirmation.title")}
            onCancel={cancelWithdraw}
            visible={visible}
            cancelText={$st("withdraw.submit.confirm.cancel")}
            okText={$st("withdraw.confirmation.confirm")}
            onOk={onConfirm}
            okButtonProps={{
                loading: submitState === SendState.pending,
            }}
        >
            <div className={cs.withdraw} style={{ marginTop: 15 }}>
                {accountInputView}
                {hasSMS ? <div style={{ marginTop: 20 }}>{smsCodeInputView}</div> : null}

                {hasEmail ? <div style={{ marginTop: 20 }}>{emailCodeInputView}</div> : null}

                <div style={{ marginTop: 20 }}>{googleCodeInputView}</div>
                <ResetGoogleLink account={mainAccount?.account} action={action} />
            </div>
        </TModal>
    );
}
