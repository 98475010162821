import { ObservableUtils } from "trade_utils_lib";
import HostConfig from "utils/HostConfig";
import {
    NotificationListData,
    NotificationUnreadCountData,
    NotificationUnreadData,
    NotificationListParam,
    NotificationReadParams,
    AnnouncementListParams,
    AnnouncementListData,
} from "./interface";

/**
 * @description: 消息列表 https://pionex.atlassian.net/wiki/spaces/backend/pages/138248867/Kol-Home+-+API#%E6%B6%88%E6%81%AF%E4%B8%AD%E5%BF%83
 * @param {string} next_page_token
 * @param {number} limit
 * @return {*}
 */
export const notificationListGet = (params: NotificationListParam) => {
    const host = HostConfig.messageCenter;
    return ObservableUtils.getV2<NotificationListData>(`${host}/tapi/v1/notification_list`, params, undefined, true);
};

/**
 * @description: 消息未读总数 https://pionex.atlassian.net/wiki/spaces/backend/pages/138248867/Kol-Home+-+API#%E6%B6%88%E6%81%AF%E6%9C%AA%E8%AF%BB%E6%80%BB%E6%95%B0
 * @return {*}
 */
export const notificationUnreadCountGet = () => {
    const host = HostConfig.messageCenter;
    return ObservableUtils.getV2<NotificationUnreadCountData>(`${host}/tapi/v1/notification_unread_count`, {}, undefined, true);
};

/**
 * @description: 清除未读消息 https://pionex.atlassian.net/wiki/spaces/backend/pages/138248867/Kol-Home+-+API#%E6%B8%85%E9%99%A4%E6%9C%AA%E8%AF%BB%E6%B6%88%E6%81%AF

 * @param {string} message_id
 * @return {*}
 */
export const notificationReadPost = (params: NotificationReadParams) => {
    const host = HostConfig.messageCenter;
    return ObservableUtils.postV2<NotificationUnreadData>(`${host}/tapi/v1/notification_read`, params, undefined, true);
};

/**
 * @description: 清除全部未读消息 https://pionex.atlassian.net/wiki/spaces/backend/pages/138248867/Kol-Home+-+API#%E6%B8%85%E9%99%A4%E5%85%A8%E9%83%A8%E6%9C%AA%E8%AF%BB%E6%B6%88%E6%81%AF
 * @return {*}
 */
export const notificationAllReadPost = () => {
    const host = HostConfig.messageCenter;
    return ObservableUtils.postV2<any>(`${host}/tapi/v1/notification_all_read`, {}, undefined, true);
};

/**
 * @description: 公告列表： https://pionex.atlassian.net/wiki/spaces/backend/pages/138248867/KOL+Home+API#%E5%85%AC%E5%91%8A%E5%88%97%E8%A1%A8%E6%8E%A5%E5%8F%A3
 * @return {*}
 */
export const announcementListPost = (params: AnnouncementListParams) => {
    const host = HostConfig.messageCenter;
    return ObservableUtils.getV2<AnnouncementListData>(`${host}/tapi/v1/announcement_list`, params);
};
