import { CaretDownOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import classNames from "classnames";
import cs from "components/DropDown/dropdown.module.less";
import React, { forwardRef, ReactNode, Ref, useCallback, useEffect, useImperativeHandle, useState } from "react";

export interface CustomDropDownProps {
    overLayContent: () => React.ReactNode;
    overLayIcon?: () => ReactNode;
    overlayIconClass?: string;
    visibleControl?: boolean;
    overLayTitle?: string;
    headerClass?: string;
    overLayClass?: string;
    popupContainer?: HTMLElement;
    onVisibleChange?: (visible: boolean) => void;

    rightButtonText?: string;
    dropdownClass?: string;
    dropdownOtherChildren?: ReactNode;
}

export interface CustomDropDownAction {
    close: () => void;
}

const CustomDropDown = (props: CustomDropDownProps, ref: Ref<CustomDropDownAction>) => {
    const { overLayContent, overLayIcon, overLayTitle, visibleControl, rightButtonText, headerClass, overLayClass, popupContainer, overlayIconClass, onVisibleChange } = props;
    const [visible, changeVisible] = useState(false);
    const [caretClass, changeCaretClass] = useState(cs.arrowDown);

    useImperativeHandle(
        ref,
        () => ({
            close: () => {
                changeVisible(false);
                changeCaretClass(`${cs.arrowDown}`);
            },
        }),
        [],
    );

    useEffect(() => {
        if (!visible) {
            changeCaretClass(`${cs.arrowDown}`);
        } else {
            changeCaretClass(`${cs.arrowDown} ${cs.open}`);
        }
        if (onVisibleChange) {
            onVisibleChange(visible);
        }
    }, [visible]);

    const onVisibleStateChange = useCallback(
        (visible) => {
            if (!visible) {
                changeCaretClass(`${cs.arrowDown}`);
            } else {
                changeCaretClass(`${cs.arrowDown} ${cs.open}`);
            }
            if (onVisibleChange) {
                onVisibleChange(visible);
            }
        },
        [changeCaretClass],
    );

    if (visibleControl) {
        return (
            <Dropdown
                visible={visible}
                placement="bottomLeft"
                trigger={["click"]}
                getPopupContainer={() => (popupContainer ? popupContainer : document.body)}
                overlay={<div className={overLayClass}>{overLayContent()}</div>}
            >
                <div className={cs.dropDownStyle}>
                    <div className={cs.wrap}>
                        <div
                            className={`${cs.overlay} ${headerClass}`}
                            onClick={() => {
                                changeVisible(!visible);
                            }}
                        >
                            {overLayIcon && <div className={overlayIconClass}>{overLayIcon()}</div>}
                            <div className={cs.text}>{overLayTitle}</div>
                            <CaretDownOutlined className={caretClass} style={{ fontSize: 18 }} />
                        </div>

                        {rightButtonText && visible ? (
                            <Button className={cs.button} onClick={() => changeVisible(false)}>
                                {rightButtonText}
                            </Button>
                        ) : null}
                    </div>
                </div>
            </Dropdown>
        );
    } else {
        return (
            <Dropdown
                placement="bottomLeft"
                trigger={["click"]}
                getPopupContainer={() => (popupContainer ? popupContainer : document.body)}
                onVisibleChange={onVisibleStateChange}
                overlay={<div className={overLayClass}>{overLayContent()}</div>}
            >
                <div className={classNames(cs.dropDownStyle, props.dropdownClass)}>
                    <div className={`${cs.overlay} ${headerClass}`}>
                        {overLayIcon && <div className={overlayIconClass}>{overLayIcon()}</div>}
                        <div className={cs.text}>{overLayTitle}</div>
                        <CaretDownOutlined className={caretClass} style={{ fontSize: 18 }} />
                    </div>
                    {props.dropdownOtherChildren}
                </div>
            </Dropdown>
        );
    }
};

export const CustomDropDownView = forwardRef(CustomDropDown);
