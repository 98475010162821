import { TradingConfig, TradingGlobalProvider } from "@pionex-web-kit/trading-components";
import { useDataProviderOnFirstApiKey } from "commonUse";
import { ConfirmCheckModalProps } from "commonUse/useComp";
import { showLoginDialog } from "components/LoginDialog";
import React, { PropsWithChildren, useCallback } from "react";
import { SHOW_GA_BIND_GUIDE_REF } from "src/components-v2/Layout/GlobalMessages/GABindGuide";
import { StringFormatter } from "trade_lib_wrapper";
import { useTickerMap } from "use/useTicker";
import AccountManager from "utils/AccountManager";
import { useTranslators } from "commonUse/Locale";
import useAccountInfo from "commonUse/useAccountInfo";
import useExchangeRate from "commonUse/useExchangeRate";
import useTheme from "commonUse/useTheme";
import { history } from "utils/History";
import { PlatformInfo } from "@pionex-web-kit/trading-components/es/utils/platform";
import { useKycV2TradeKycModal } from "commonUse/useKycRightsV2";
import { useSelector } from "react-redux";
import AppState, { dispatch } from "./AppState";
import { TYPE_ORDER_SHARE } from "./OrderShare";
import { TYPE_RE_ORDER_DATA } from "./ReOrderData";
import { TYPE_COPY_BOT_DATA } from "./CopyBotData";
import { report } from "utils/GoogleTagManager";

/**
 * 包裹交易组件库的Provider
 * @param children
 * @constructor
 */
export const TradingComProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const { $st, $stn } = useTranslators();
    const accountInfo = useAccountInfo();
    const firstDataProvider = useDataProviderOnFirstApiKey();
    const exchangeRate = useExchangeRate();
    const themeData = useTheme();
    const tickerMap = useTickerMap();
    const orderShare = useSelector((state: AppState) => state.orderShare);
    const reOrderData = useSelector((state: AppState) => state.reOrderData);
    const copyBotData = useSelector((state: AppState) => state.copyBotData);
    const clearShareOrderData = useCallback(() => {
        dispatch(TYPE_ORDER_SHARE, null);
        dispatch(TYPE_RE_ORDER_DATA, null);
        dispatch(TYPE_COPY_BOT_DATA, null);
    }, []);

    return (
        <TradingGlobalProvider
            $st={$st}
            $stn={(value, precision) => $stn(value, { maximumSignificantDigits: precision })}
            accountInfo={accountInfo}
            firstDataProvider={firstDataProvider}
            exchangeRate={exchangeRate}
            themeData={themeData}
            tickerMap={tickerMap}
            showLoginDialog={showLoginDialog}
            KycV2TradeKycModal={useKycV2TradeKycModal}
            SHOW_GA_BIND_GUIDE_REF={SHOW_GA_BIND_GUIDE_REF}
            shareOrderData={orderShare || undefined}
            clearShareOrderData={clearShareOrderData}
            reOrderData={reOrderData}
            copyBotData={copyBotData}
        >
            {children}
        </TradingGlobalProvider>
    );
};

/**
 * 初始化trading-components的静态配置
 */
export function initTradingConfig() {
    TradingConfig.init({
        accountManager: {
            getAccessToken: AccountManager.shared.getAccessToken,
            needEnterLCE: AccountManager.shared.needEnterLCE,
            updateFSARiskPlanStatusObservable: AccountManager.shared.updateFSARiskPlanStatusObservable,
        },
        platformInfo: {
            ...(PLATFORM as PlatformInfo),
        },
        history: {
            push: history.push,
            replace: history.replace,
        },
        gaBindGuideShow: {
            showIfNeed: (force?: boolean, showProps?: ConfirmCheckModalProps) => SHOW_GA_BIND_GUIDE_REF.showIfNeed(),
        },
        track: ({ platform, key, data }) => {
            report(key, data);
        },
        customOrderCreator: {
            forceProcessError: (error: any, $st: StringFormatter) => {
                return false;
            },
        },
        disableMCoin: true,
    });
}
