import { ExchangeID, ExchangeOrder, ExchangeSymbol } from "TradeAPILib";
import { useSelector } from "react-redux";
import AppState, { dispatch } from "./AppState";
import { useEffect, useMemo } from "react";
import useAccountInfo from "commonUse/useAccountInfo";
import { useLocation, useParams } from "react-router";
import { ExchangeDataProvider, TradeSelectedInfo, useSymbolData } from "@pionex-web-kit/common-business";
import AccountManager from "utils/AccountManager";
import { useCallbackStatic } from "commonUse/tools";
import LocalStorageUtils, { KEY_TRADE_SELECTED_SYMBOL } from "utils/LocalStorageUtils";
import { SideTabKey } from "landings/Trade/SideTab";
import { history } from "src/landings";

export const TRADE_SELECTED_INFO = "trade_selected_info";

export function TradeSelectedInfoReducer(state = null, action: { type: string; data: ExchangeOrder }) {
    if (action.type === TRADE_SELECTED_INFO) {
        return action.data;
    }
    return state;
}
interface TradeRouteParams {
    symbol: string;
    /**
     * @deprecated 平台需要再传入改参数，
     */
    exchangeId: string;
}

export function useTradeSelectedInfo() {
    const accountInfo = useAccountInfo();
    const { pathname, state, search } = useLocation();
    const params = useParams<TradeRouteParams>();
    const base_quote = useMemo(
        () => (params?.symbol || pathname.match(/trade\/([^/]+)\//)?.[1] || localStorage.getItem(KEY_TRADE_SELECTED_SYMBOL) || "BTC_USD").toUpperCase(),
        [params?.symbol, pathname],
    );
    const exchange = ExchangeID.PIONEXV2;
    const symbolData = useSymbolData();
    const tradeSelectedInfo = useSelector((state: AppState) => state.tradeSelectedInfo);

    const apiKey = useMemo(() => {
        // if (apiKey.exchange !== exchange) {
        //     //当前选中的ApiKey和地址栏的交易所名字不一样，跳转到ApiKey对应的交易所
        //     history.replace(`/trade/${base_quote}/${apiKey.exchange}`);
        //     return;
        // }
        return AccountManager.selectApiKey(accountInfo, exchange);
    }, [accountInfo, exchange]);

    const changeExchangeSymbol = useCallbackStatic(async (exchangeSymbol: string | ExchangeSymbol, targetPage: boolean | SideTabKey = true) => {
        // if (
        //     `${typeof exchangeSymbol === "string" ? exchangeSymbol : `${exchangeSymbol.base}_${exchangeSymbol.quote}`}` ===
        //     `${tradeSelectedInfo?.symbol.base}_${tradeSelectedInfo?.symbol.quote}`
        // ) {
        //     // 不对通类数据进行重复修改；
        //     return;
        // }
        let symbol = typeof exchangeSymbol === "string" ? symbolData?.symbolMap.get(exchangeSymbol) : exchangeSymbol;
        if (!symbol) {
            //没有base_quote交易对，选择一个存在的交易对
            symbol = symbolData?.symbolMap.get("BTC_USD") || (symbolData?.allSymbols[0] as ExchangeSymbol);
            history.replace(`${getPathBySymbol(symbol.baseDisplay || symbol.base, symbol.quoteDisplay || symbol.quote)}${search}`, state);
        }
        if (symbol) {
            //ok, set tradeSelectedInfo
            const noPerpSymbolData = {
                allSymbols: symbolData?.spotSymbolData.symbols,
                quotes: symbolData?.spotSymbolData.quotes,
                symbolMap: symbolData?.spotSymbolData.map,
            };
            const tradeSelectedInfo = {
                ...noPerpSymbolData,
                apiKey,
                exchange,
                symbol,
            } as TradeSelectedInfo;
            ExchangeDataProvider.getPublicExchangeDataProvider(exchange).setSymbol(symbol);
            LocalStorageUtils.setTradeSelectedInfo(tradeSelectedInfo);
            if (apiKey) {
                try {
                    const data = await ExchangeDataProvider.getExchangeDataProvider(apiKey).toPromise();
                    data.setSymbol(symbol);
                    dispatch(TRADE_SELECTED_INFO, tradeSelectedInfo);
                } catch (e) {
                    console.error(e);
                }
            } else {
                dispatch(TRADE_SELECTED_INFO, tradeSelectedInfo);
            }
        }
        if (targetPage === true) {
            history.replace(`${getPathBySymbol(symbol.baseDisplay || symbol.base, symbol.quoteDisplay || symbol.quote)}${search}`, state);
        } else if (targetPage) {
            history.replace(`/trade/${symbol.baseDisplay || symbol.base}_${symbol.quoteDisplay || symbol.quote}/${targetPage}`);
        }
    });

    return [tradeSelectedInfo, changeExchangeSymbol, base_quote] as [typeof tradeSelectedInfo, typeof changeExchangeSymbol, string];
}

export function useInitTradeSelectedInfo() {
    const [, changeExchangeSymbol, baseQuote] = useTradeSelectedInfo();
    const symbolData = useSymbolData();
    const accountInfo = useAccountInfo();
    useEffect(() => {
        if ((accountInfo.userId && !accountInfo.apiKeys) || !symbolData || symbolData.allSymbols.length === 0) {
            return;
        }
        changeExchangeSymbol(baseQuote, false);
    }, [accountInfo.apiKeys, symbolData, baseQuote]);
}

/**
 * 根据币对获取trade的路径
 * @param base
 * @param quote
 */
function getPathBySymbol(base: string, quote: string) {
    // 优先url上的tab值（可能开了多个浏览器标签，在其他标签下修改了localstorage）
    const currentPathTab = window.location.pathname.split("/").pop();
    const targetTab = Object.values(SideTabKey).includes(currentPathTab as any) ? currentPathTab : LocalStorageUtils.tradeSelectedTab;

    return `/trade/${base}_${quote}/${targetTab}`;
}
