import TReportLink from "components/v2/TinyComps";
import cs from "./index.m.less";
import React from "react";
import { $st } from "utils/i18n/trans.comp";
import { Button } from "@pionex-web-kit/components";
import { AccountsRoute } from "utils/AccountsRoute";

export default function NotLogin() {
    return (
        <div className={cs.btn}>
            <SignInBtn />
            <RegisterBtn />
        </div>
    );
}

export function SignInBtn({ from }: { from?: string }) {
    return (
        <TReportLink to={AccountsRoute.sign({ from })}>
            <Button className={cs.sign}>{$st("sign_in_title")}</Button>
        </TReportLink>
    );
}

export function RegisterBtn({ from }: { from?: string }) {
    return (
        <TReportLink to={AccountsRoute.signUp({ from })}>
            <Button type="primary" className={cs.register}>
                {$st("sign_up_title")}
            </Button>
        </TReportLink>
    );
}

export const LoginGroup = (props: { title: string; from?: string }) => {
    const { title, from } = props;
    return (
        <div className={cs.container}>
            {title ? <div className={cs.title}>{title}</div> : null}
            <div style={{ marginTop: 20 }} className="flex">
                <SignInBtn from={from} />
                <RegisterBtn from={from} />
            </div>
        </div>
    );
};
