import { HelpCenterFAQQuestion } from "@pionex-web-kit/pro-components";
import { useSelector } from "react-redux";
import { ObservableUtils } from "trade_utils_lib";
import Constants from "utils/Constants";
import AppState, { dispatch } from "state/AppState";

/**
 * 配置文件结构
 */
interface OriginHelpConfig {
    summary: string;
    faqGroup: { title?: string; faqs: { question: string; answer: { link: string } }[] }[];
}

/**
 * 转换后的配置结构
 */
export type HelpConfig = { desc?: string; questions: HelpCenterFAQQuestion[] };

const TYPE_REFRESH_HELP_CONFIG = "type_refresh_help_config";

export class HelpConfigRedux {
    static refresh() {
        ObservableUtils.getV2<OriginHelpConfig>(`${Constants.configHost}/statics/web/suport_FAQ.web.json`).subscribe((data) => {
            dispatch(TYPE_REFRESH_HELP_CONFIG, {
                desc: data.summary,
                questions: data.faqGroup.map((item) => ({
                    category: item.title,
                    list: item.faqs.map((f) => ({
                        label: f.question,
                        url: f.answer.link,
                    })),
                })),
            });
        });
    }

    static init() {
        this.refresh();
    }

    static reducer = (state = {}, action) => {
        switch (action.type) {
            case TYPE_REFRESH_HELP_CONFIG:
                return action.data;
        }
        return state;
    };
}

export function useHelpConfig() {
    return useSelector<AppState, HelpConfig>((appState) => appState.helpConfig);
}
