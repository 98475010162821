import React, { useCallback, useEffect, useMemo, useState } from "react";
import Constants from "utils/Constants";
import useSignRequest from "commonUse/useSignRequest";
import { DebitCardStatus } from "landings/V2/CircleDebit/types";
import { $st } from "utils/i18n/trans.comp";
import { ST } from "commonUse/Locale";
import { useOnlineConfig } from "utils/onlineConfig";
import useKycV2Info from "commonUse/useKycInfoV2";
import { parseError } from "utils/Utils";
import { message } from "@pionex-web-kit/components";
import { TModal } from "components/v2/TinyComps";
import { ParseErrorUtils } from "trade_lib_wrapper";
import { QuotaChannel, QuotaCheckType, QuotaDirection, useRemainQuota } from "utils/complianceQuota";
import useAccountInfo from "commonUse/useAccountInfo";
import { useCallbackStatic, useToggle } from "commonUse/tools";

interface LinkToken {
    token: string;
    expire_at: number;
}

/**
 * 入金状态
 */
export enum ACH_DepositStatus {
    PENDING = "PENDING", //进行中
    SUCCESS = "SUCCESS", //成功
    FAILED = "FAILED", //失败
    EARLY_CREDIT = "EARLY_CREDIT", //预上账
    DEDUCTED = "DEDUCTED", //已下账
}

export interface ACH_DepositResult {
    id: string;
    status: ACH_DepositStatus;
    fail_reason?: string;
}

export interface ACH_AccountCreate {
    // plaid PublicToken，在OnSuccess回调中得到
    plaid_public_token: string;
    // plaid登录的机构ID
    plaid_institution_id: string;
    // plaid登录的机构名称
    plaid_institution_name: string;
    // 绑定的账户ID
    plaid_account_id: string;
    // 绑定的账户名
    plaid_account_name: string;
    // 绑定的账户Mask
    plaid_account_mask: string;
}

export interface ACH_AccountItem {
    id: string;
    channel: ACHChannel;
    status: "PENDING" | "SUCCESS" | "FAILED" | "REAUTHENTICATION" | "MANUAL_REVIEWING";
    fail_reason?: string;
    create_time: number;
    update_time: number;
    account_number: string;
    bank_address: {
        bank_name: string;
        city: string;
        country?: string;
        line1?: string;
        line2?: string;
    };
    plaid_info: Omit<ACH_AccountCreate, "plaid_public_token">;
    routing_number: string;
    verify_required: boolean;
}

/**
 * 通道类型
 */
export enum ACHChannel {
    CIRCLE = "CIRCLE",
    CHECKOUT = "CHECKOUT",
}

/**
 * 默认不请求，需要手动调用
 */
export function useGetListToken() {
    const signRequest = useSignRequest();
    const [linkToken, setLinkToken] = useState<LinkToken>();

    // 获取linkToken数据
    const getLinkToken = useCallback(
        /**
         *
         * @param ach_account_id ach账户id，在进行plaid账户重新认证的时候传
         */
        async (ach_account_id?: string) => {
            setLinkToken(undefined); // 开始请求时清空之前的token
            try {
                let url = `${Constants.walletApiHost}/plaid/link_token/`;
                if (ach_account_id) {
                    url += `?ach_account_id=${ach_account_id}`;
                }

                const { code, data } = await signRequest<LinkToken>(url).toPromise();
                if (code === 0) {
                    setLinkToken(data);
                }
            } catch (error) {
                console.error(error);
                if (!!error?.data?.mcode) {
                    const code = error.data.mcode;
                    let msg = "";
                    if (code === "PAYMENT_CHANNEL_MAINTENANCE") {
                        msg = $st("service_maintenance_error_tip");
                    } else {
                        msg = $st({ id: `ach_link_token_error:${code}`, defaultMessage: $st("unknown_error_code", { code }) });
                    }
                    message.error(msg);
                } else if (!!error?.message) {
                    // 后端有返回的提示
                    message.error(error.message);
                }
            }
        },
        [signRequest],
    );

    return { linkToken, getLinkToken };
}

/**
 * 获取绑定的ACH账号列表
 * @param disableAutoRefresh 是否关闭特定情况的自动刷新
 */
export function useBankList(disableAutoRefresh = false) {
    const signRequest = useSignRequest();
    const [dataLoaded, { setTrue: setTrueDataLoaded }] = useToggle(); // 是否加载过数据
    const [listLoading, setListLoading] = useState(false);
    const [bankList, setBankList] = useState<ACH_AccountItem[]>([]);
    const firstApiKey = useAccountInfo().firstApiKey;

    const getBankList = useCallbackStatic(async () => {
        if (!firstApiKey) {
            setTrueDataLoaded();
            return;
        }
        if (listLoading) return;
        setListLoading(true);
        try {
            const { data } = await signRequest<ACH_AccountItem[] | null>(`${Constants.walletApiHost}/circle/ach/accounts/`).toPromise();
            setBankList(data ?? []);
            setListLoading(false);
            // TODO
            // form.setFieldsValue({ ach_account_id: data?.[0]?.id });
        } catch (e) {
            setListLoading(false);
            console.error(e);
            message.error(parseError(e));
        } finally {
            setTrueDataLoaded();
        }
    });

    useEffect(() => {
        getBankList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let timeout;
        // 数据中有pending的卡片轮询刷新
        if (!disableAutoRefresh && !!bankList?.length && !!bankList?.some((item) => item.status === DebitCardStatus.PENDING)) {
            timeout = setTimeout(() => {
                getBankList();
            }, 2000);
        }

        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankList, disableAutoRefresh]);

    return { bankList, getBankList, listLoading, dataLoaded };
}

/*
POST /wallet-api/v1/circle/ach/account/further_verification/

*/

type FurtherVerificationType = {
    id: number;
    is_joint: boolean;
    file: string;
};

export function usePostFurtherVerification() {
    const signRequest = useSignRequest();
    return useCallback(
        (params: FurtherVerificationType) => {
            return signRequest(`${Constants.walletApiHost}/circle/ach/account/further_verification/`, { method: "POST", body: params });
        },
        [signRequest],
    );
}

/**
 * 获取状态描述文案
 * @param $st
 * @param card
 */
export function getStatusTips($st: ST, card: ACH_AccountItem): string {
    if (["SUCCESS", "REAUTHENTICATION"].includes(card.status)) {
        return "";
    }

    if (card.status === "FAILED") {
        return $st({ id: `ach_bind_account_circle_error:${card.fail_reason}`, defaultMessage: `[${card.fail_reason}]: ${$st("ach_bind_failed")}` });
    }

    if (card.status === "PENDING" && !!card.verify_required) {
        return "";
    }

    return $st({ id: `ach_card_status:${card.status}`, defaultMessage: " " });
}

export namespace ACHDefaultCard {
    export function getDefaultCardId(cards?: ACH_AccountItem[]) {
        if (!cards?.length) {
            return undefined;
        }

        return cards[0].id;
    }
}

/**
 * ACHFee相关配置
 */
export function useACHFee(type: "deposit" | "withdraw" = "deposit") {
    const config = useOnlineConfig();

    return config.dw?.[type === "withdraw" ? "w" : "d"]?.cir_ach?.fee || { default_display: "$2.99" };
}

/**
 * ACH活动
 * @param type
 */
export function useACHActivity(type: "deposit" | "withdraw" = "deposit") {
    const config = useOnlineConfig();

    return config.dw?.[type === "withdraw" ? "w" : "d"]?.cir_ach?.activity?.web;
}

/**
 * ACH限制的配置
 */
export function useACHLimit() {
    const config = useOnlineConfig();
    return config.dw?.d?.cir_ach?.limit ?? {};
}

/**
 * 根据用户信用等级获取的限制文案
 */
export function useUserLevelLimit(): string | undefined {
    const [{ originData: kycOriginData }] = useKycV2Info();
    const { dw } = useOnlineConfig();
    if (kycOriginData?.userLevel === undefined) {
        return undefined;
    }
    return dw?.d?.cir_ach?.userLevel?.[kycOriginData?.userLevel]?.r10dLimitTip;
}

export function deleteACHAccount(signRequest: ReturnType<typeof useSignRequest>, item: ACH_AccountItem, onSuccess: () => void) {
    const { id, plaid_info, account_number } = item;

    TModal.confirm({
        content: $st("debit_delete_card_content", {
            br: <br />,
            cardNum: `${plaid_info.plaid_institution_name}(${account_number})`,
        }),
        async onOk() {
            try {
                const { code, message: msg } = await signRequest<never>(`${Constants.walletApiHost}/circle/ach/accounts/${id}/`, {
                    method: "DELETE",
                }).toPromise();
                if (code === 0) {
                    // message.success($st("ach_account_del_success"));
                    // // 删除后，更新列表
                    // getBankList();
                    //
                    // // 删除的当前选中卡，清空选中值
                    // if (form.getFieldValue("ach_account_id") === item.id) {
                    //     form.setFieldsValue({ ach_account_id: undefined });
                    //     setJointAccountCheckVisible(false);
                    // }
                    onSuccess();
                } else {
                    message.error(msg || "Delete failed, please try again later.");
                }
            } catch (e) {
                console.error(e);
                message.error(ParseErrorUtils.formatterToString(e) || "Delete failed, please try again later.");
            }
        },
    });
}

/**
 * ACH金额限制配置
 */
export function useACHAmountLimit() {
    const { min: singleMinAmount = 0, max: singleMaxAmount = 2000 } = useACHLimit();

    // 滚动额度
    const { details: remainDetails, loading: remainLoading } = useRemainQuota(QuotaChannel.CIRCLE_ACH, QuotaDirection.DEPOSIT);
    const rollingRemain = useMemo(() => remainDetails.find((item) => item.checkType === QuotaCheckType.DEPOSIT_ROLLING_QUOTA_CHECK), [remainDetails]);
    const rollingMaxAmount = useMemo(() => Number(rollingRemain?.quota ?? 0), [rollingRemain?.quota]);
    const maxAmount = Math.min(rollingMaxAmount, singleMaxAmount); // 服务端返回的限额和配置的单笔限额取最小值

    return { singleMinAmount, maxAmount, rollingMaxAmount, loading: remainLoading };
}
