import { CountryListForLite } from "landings/V2/CircleDW/BankCard/constants";

export const stateMap = require("src/landings/V2/CircleDW/assets/stateCodes.json");

/**
 * codeAlpha3 转 codeAlpha2
 */
export function alpha3CodeToCode(codeAlpha3: string): string | undefined {
    return CountryListForLite.find((item) => item.codeAlpha3 === codeAlpha3)?.code;
}

/**
 * 州code转名字
 * @param countryCode
 * @param regionCode
 */
export function regionCodeToName(countryCode: string, regionCode: string): string | undefined {
    return stateMap[countryCode]?.find((item) => item.code === regionCode)?.name;
}
