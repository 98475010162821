/**
 * 特殊的错误原因code处理
 * @param $st
 * @param reasons
 */
import { useTranslators } from "commonUse/Locale";
import { TModal } from "components/v2/TinyComps";
import React, { useCallback, useMemo } from "react";
import { useIPLimitOfUSSite } from "commonUse/tools";
import useKycV2Info from "commonUse/useKycInfoV2";
import { alpha3CodeToCode, regionCodeToName } from "utils/region";

/**
 * code和message字符串对应之外的特殊的错误愿意码处理
 */
export function useExtraProcessReasons() {
    const { $st } = useTranslators();
    const [{ originData: kycOriginData }] = useKycV2Info();
    const [_, { serveList }] = useIPLimitOfUSSite();

    const alpha2Code = useMemo(() => alpha3CodeToCode(kycOriginData?.region ?? ""), [kycOriginData?.region]);
    const regionName = useMemo(() => regionCodeToName(alpha2Code ?? "", kycOriginData?.province ?? ""), [alpha2Code, kycOriginData?.province]);

    const serveRegionNames = useMemo(() => {
        const regions = serveList?.find((c) => c.country === alpha2Code)?.serve_regions;
        if (!Array.isArray(regions)) {
            return [];
        }

        return regions
            .map((region) => region.code)
            .sort((a, b) => {
                return a.toUpperCase() < b.toUpperCase() ? -1 : 1;
            });
    }, [alpha2Code, serveList]);

    return useCallback(
        (reasons?: string[]) => {
            if (!reasons?.includes("NOT_SUPPORT_PROVINCE")) {
                return false;
            }
            TModal.error({
                title: $st("kyc_failed"),
                cancelButtonProps: { style: { display: "none" } },
                maskClosable: true,
                content: <p>{$st("kyc2_reason_not_support", { br: <br />, regionName: regionName, serveRegionNames: serveRegionNames.join(", ") })}</p>,
            });
            return true;
        },
        [$st, regionName, serveRegionNames],
    );
}
