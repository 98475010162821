import "animate.css/animate.min.css";
import useTheme from "commonUse/useTheme";
import { DownloadAppPopover } from "landings/HomePage/DownloadApp/DownloadAppPopover";
import React from "react";
import "react-html5video/dist/styles.css";
import { LayoutConfig } from "src/components-v2/Layout";
import DownloadApp from "src/landings/HomePage/DownloadApp";
import ThemeData from "state/ThemeData";
import { $st } from "utils/i18n";
import cs from "./style/download.module.less";
import { isMobile, isAndroid } from "src/utils/commons";

const Download = () => {
    const theme: ThemeData = useTheme();

    if (!isMobile) {
        return (
            <LayoutConfig contentFullWidthOnly whiteBg>
                <DownloadApp showQRCode titleColor={theme.colorText6} />
            </LayoutConfig>
        );
    }

    return (
        <LayoutConfig contentFullWidthOnly whiteBg>
            <div className={cs.download_wrap_mobile}>
                <div className={cs.download_title}>{$st("homepage_downloadapp_title_1")}</div>
                <DownloadAppPopover isAndroid={isAndroid} />

                <div className={cs.download_footer_img} />
            </div>
        </LayoutConfig>
    );
};

export default Download;
