import { getCurrentLocale, LANGUAGE_LOCALS_MAP } from "utils/i18n";
import uuidv4 from "uuid/v4";
import { buildClientId, ClientPlatform, ClientProduct, PRODUCT_TYPE } from "trade_utils_lib";
import Cookies from "js-cookie";
import HostConfig from "utils/HostConfig";
import { ReactNativeWebView } from "utils/ReactNativeWebView";

export const REDIRECT_ROUTER_ARRAY = ["/win-one-btc"];

const KEY_DEVICE_ID = "device_id";

/* 获取当前子域名 */
export function getCurrentSubdomain() {
    try {
        let subdomain = "";
        const key = `mh_${Math.random()}`;
        const { domain } = document;
        const domainList = domain.split(".");
        const ipAddressReg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
        // 若为 IP 地址、localhost，则直接返回
        if (ipAddressReg.test(domain) || domain === "localhost") {
            return domain;
        }
        const urlItems: Array<string | undefined> = [];
        urlItems.unshift(domainList.pop());
        while (domainList.length) {
            urlItems.unshift(domainList.pop());
            subdomain = urlItems.join(".");
            Cookies.set(key, "12345", { domain: subdomain });
            if (Cookies.get(key)) {
                Cookies.remove(key, { domain: subdomain });
                break;
            }
        }

        return subdomain || document.domain;
    } catch (e) {
        return document.domain;
    }
}

export type BusinessVersion = keyof typeof Constants.appDomain;

export default class Constants extends HostConfig {
    static origin = window.location.origin;
    static host = window.location.host;
    public static currentSubdomain = getCurrentSubdomain(); // 当前子域名
    public static accountsDomain = `accounts.${Constants.currentSubdomain}`;

    /**
     * 自动标记调用接口的来源站点
     */
    static get siteFlag(): PRODUCT_TYPE {
        // 开发环境需要特殊判别
        // if (this.isBeta) return ClientProduct.PIONEX_DEV;
        if (PLATFORM.PIONEX_COL) return ClientProduct.PICOL;
        if (PLATFORM.PIONEX_COLOGY) return ClientProduct.PICOL_TW;
        if (PLATFORM.BOT) return ClientProduct.BU;
        if (PLATFORM.PIONEX_CN) return ClientProduct.PIONEX_CN;
        if (PLATFORM.PIONEX_SG) return ClientProduct.PIONEX_SG;
        if (PLATFORM.PIONEX_US) return ClientProduct.PIONEX_US;
        if (PLATFORM.PIONEX_US_V2) return ClientProduct.PIONEX_US_V2;
        return ClientProduct.PIONEX;
    }

    static get isBeta() {
        return process.env.BETA || Constants.host.indexOf("beta") !== -1 || Constants.host.indexOf("pionexdev") !== -1 || Constants.host.indexOf("pionexusdev") !== -1;
    }

    static get isStaging() {
        return Constants.host.indexOf("staging") !== -1;
    }

    static get appName(): string {
        return PLATFORM.BOT ? "BitUniverse" : "Pionex.US";
    }

    /**
     * @deprecated Use {@link useIntl} or {@link useTranslators} instead.
     */
    static get appLocale(): string {
        return getCurrentLocale();
    }

    static setDeviceId(deviceId: string) {
        window.localStorage.setItem(KEY_DEVICE_ID, deviceId);
    }

    static get clientId() {
        return buildClientId(Constants.siteFlag, ClientPlatform.WEB, process.env.VERSION);
    }

    static get deviceId(): string {
        const deviceIdFromCookie = Cookies.get(KEY_DEVICE_ID) || "";
        let deviceId = window.localStorage.getItem(KEY_DEVICE_ID) || "";
        if (!deviceId) {
            deviceId = deviceIdFromCookie;
            if (!deviceId) {
                deviceId = uuidv4() || `device_id_${Date.now()}`;
            }
            window.localStorage.setItem(KEY_DEVICE_ID, deviceId);
        }
        if (deviceIdFromCookie !== deviceId) {
            Cookies.set(KEY_DEVICE_ID, deviceId, { secure: true, domain: Constants.currentSubdomain, expires: 365 });
        }
        return deviceId;
    }

    static get fpDid(): string {
        return window.localStorage.getItem("key_fp_did") || "unknown";
    }

    static get commonRequestParams() {
        return {
            client_id: Constants.clientId,
            device_id: Constants.deviceId,
            fp_did: Constants.fpDid,
            app_ver: process.env.VERSION,
            os: ReactNativeWebView.shared.isWebView() ? "webview" : "web",
            os_api: undefined,
            tz_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
            tz_offset: -new Date().getTimezoneOffset() * 60,
            sys_lang: window.navigator.language || window.navigator["browserLanguage"],
            sys_region: undefined,
            app_lang: Constants.appLocale,
            brand: undefined,
            model: undefined,
        };
    }

    /**
     * @deprecated
     */
    static get isCN() {
        return Constants.appLocale.startsWith(LANGUAGE_LOCALS_MAP.zhCN);
    }

    /**
     * @deprecated
     */
    static get isEN() {
        return Constants.appLocale.startsWith(LANGUAGE_LOCALS_MAP.enUS);
    }

    /**
     * @deprecated
     */
    static get isTW() {
        return Constants.appLocale.startsWith(LANGUAGE_LOCALS_MAP.zhTW);
    }

    /**
     * @deprecated
     */
    static get ko() {
        return Constants.appLocale.startsWith(LANGUAGE_LOCALS_MAP.ko);
    }

    static get appShortName() {
        if (PLATFORM.PIONEX_US_LIKE) {
            return "Pionex.US";
        } else if (PLATFORM.PIONEX_COL) {
            return "Picol";
        } else {
            return "Pionex";
        }
    }

    static get appDomain() {
        if (this.isBeta) {
            return {
                pionex: "www.pionexdev.com",
                "pionex.us": "www.pionexusdev.com",
                "pionex.us.v2": "v2.pionexusdev.com",
            };
        }
        if (this.isStaging) {
            return {
                pionex: "www.staging.pionex.com",
                "pionex.us": "www.pionexstaging.us",
                "pionex.us.v2": "v2.pionexstaging.us",
            };
        }
        return {
            pionex: "www.pionex.com",
            "pionex.us": "v1.pionex.us",
            "pionex.us.v2": "www.pionex.us",
        };
    }

    static get accountsRootPath() {
        if (process.env.NODE_ENV === "development") {
            return Constants.isBeta ? "https://accounts.pionexusdev.com/en" : "https://accounts.pionex.us/en";
        }
        return `https://${this.accountsDomain}/en`;
    }

    static get accountsRoute() {
        return {
            sign: `${this.accountsRootPath}/sign`,
            signUp: `${this.accountsRootPath}/signUp`,
            resetPassword: `${this.accountsRootPath}/ResetPassword`,
            resetSecurityAccount: `${this.accountsRootPath}/ResetSecurityAccount`,
        };
    }

    static get isSiteV2() {
        return !!PLATFORM.PIONEX_US_V2;
    }
}
