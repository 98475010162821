import { Radio } from "@pionex-web-kit/components";
import { CodeInputModal, CodeInputModalRef, SendType } from "@pionex-web-kit/pro-components";
import { SupportDWCoinInfo } from "TradeAPILib";
import { useAvailableVerifyInfo } from "account/hook";
import { Form, message } from "antd";
import useAccountInfo from "commonUse/useAccountInfo";
import { CoinIcon } from "components/CoinIcon";
import { TCheckbox, TInput, TLoading, TModal, TSelect } from "components/v2/TinyComps";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDataProvider } from "src/commonUse";
import { useDWCoins } from "src/commonUse/useDW";
import { SHOW_GA_BIND_GUIDE_REF } from "src/components-v2/Layout/GlobalMessages/GABindGuide";
import { VerifyCodeAction } from "trade_asset_lib";
import { $st } from "utils/i18n";
import { TAddAddressInfoData, addAddressBookItem, commonErrorParser } from "../../api";
import { useVerifyTypes } from "../../hooks";
import cs from "./cs.m.less";

export interface IDefaultValue {
    coin?: string;
    chain?: string;
    address?: string;
    whitelist?: boolean;
    memo?: string;
}

type AddAddressModalProps = {
    coinType?: string;
    visible: boolean;
    onCancel: () => void;
    onAddSuccess?: () => void;
    isFromWithdrawSuccess?: boolean;
    defaultValue?: IDefaultValue;
};

type VerifyInfo = {
    email_code: string;
    otp_code: string;
    sms_code: string;
};

const AddAddressModal = ({ coinType = "TUSDT", visible, onCancel, onAddSuccess, isFromWithdrawSuccess = false, defaultValue = {} }: AddAddressModalProps) => {
    const [addressVisible, setAddressVisible] = useState(visible);
    const [addLoading, setAddLoading] = useState(false);
    const accountInfo = useAccountInfo();
    const verifyInfo = useAvailableVerifyInfo();
    const [form] = Form.useForm();
    const [currentCoin, changeCurrentCoin] = useState<SupportDWCoinInfo>();
    const [defaultCurrentCoin, setDefaultCurrentCoin] = useState<SupportDWCoinInfo>();
    const [coinList, loading] = useDWCoins();
    const [haveMemo, setHaveMemo] = useState(false);
    const [resetData, setResetData] = useState({});
    const modalRef = useRef<HTMLDivElement | null>(null);
    const codeInputModalRef = useRef<CodeInputModalRef>(null);
    const currentCoins: SupportDWCoinInfo[] = useMemo(() => {
        if (!currentCoin) {
            return [];
        }
        return coinList.filter((item) => item.coinName === currentCoin.coinName);
    }, [coinList, currentCoin]);

    const coinOptions = useMemo(() => {
        const optionData: SupportDWCoinInfo[] = [];
        for (const item of coinList) {
            if (!optionData.some((i) => i.coinName === item.coinName)) {
                optionData.push(item);
            }
        }
        return optionData.map((item) => {
            return (
                <TSelect.Option value={item.currency} key={item.currency} coinName={item.coinName} fullName={item.fullName}>
                    <CoinIcon className={cs.coinOptionImg} target={item.coinName} size="small" />
                    <span style={{ marginLeft: 5 }}>{`${item.coinName} - ${item.fullName}`}</span>
                </TSelect.Option>
            );
        });
    }, [coinList]);

    useEffect(() => {
        if (coinType) {
            const findResult = coinList.find((item) => item.currency === coinType);
            if (findResult) {
                changeCurrentCoin(findResult);
                setDefaultCurrentCoin(findResult);
                const vals = {
                    coin: findResult.coinName,
                    chain: findResult.protocol,
                };
                form.setFieldsValue(vals);
                setResetData({ ...defaultValue, ...vals });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coinType, coinList, defaultValue]);

    const handleOk = useCallback(() => {
        form.submit();
    }, [form]);
    const handleCoinChange = useCallback(
        (value: string) => {
            const target = coinList.find((item) => item.currency === value);
            if (!target) {
                return;
            }
            changeCurrentCoin(target);
            form.setFieldsValue({
                coin: target.coinName,
                chain: target.protocol,
            });
        },
        [coinList, form],
    );
    const memoTagName = useMemo(() => {
        if (currentCoin?.currency) {
            return currentCoin.currency === "XRP" ? "Tag" : "Memo";
        }
        return "Memo";
    }, [currentCoin]);
    const dataProvider = useDataProvider(accountInfo.firstApiKey);

    const resetForm = useCallback(() => {
        form.resetFields();
        changeCurrentCoin(defaultCurrentCoin);
        // 这里不能监听form的变化
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultCurrentCoin]);
    useEffect(() => {
        setAddressVisible(visible);
        // 打开弹窗的时候重置一下 form
        if (visible) {
            setHaveMemo(false);
            resetForm();
        }
    }, [visible, resetForm]);
    useEffect(() => {
        //currentCoin
        if (visible && currentCoin?.currency) {
            const findResult = coinList.find((item) => item.currency === currentCoin?.currency);
            if (findResult?.withdraw_memo) {
                setHaveMemo(true);
            } else {
                setHaveMemo(false);
            }
        }
    }, [dataProvider, currentCoin, visible, coinList, coinType]);

    const submitAddAddress = useCallback(
        (verifyInfo?: VerifyInfo) => {
            const values = form.getFieldsValue();
            const coin = currentCoin?.coinName as string;
            let submitData = {
                coin,
                address: values.address,
                tag: values.tag,
                whitelist: values.whitelist,
            } as TAddAddressInfoData;

            if (values.chain) {
                submitData.chain = values.chain;
            }
            if (values.memo) {
                submitData.memo = values.memo;
            }
            if (values.whitelist) {
                submitData = Object.assign({}, submitData, {
                    email_code: verifyInfo?.email_code,
                    otp_code: verifyInfo?.otp_code,
                    sms_code: verifyInfo?.sms_code,
                });
            }
            addAddressBookItem(submitData).subscribe(
                () => {
                    message.success($st("add_address_success"));
                    onAddSuccess && onAddSuccess();
                    codeInputModalRef.current?.dismiss();
                    setAddressVisible(false);
                    setAddLoading(false);
                    resetForm();
                },
                (error) => {
                    codeInputModalRef.current?.retry();
                    if (error.code === 40020337) {
                        if (error.data?.duplicate_address_tag) {
                            message.error($st("duplicate_address_with_name", { dupTag: error.data.duplicate_address_tag }));
                        } else {
                            message.error($st("duplicate_address"));
                        }
                    } else if (error.code === 40020336) {
                        message.error($st("duplicate_tag"));
                    } else {
                        commonErrorParser(error.code, $st, error.message);
                    }
                    setAddLoading(false);
                },
            );
        },
        [currentCoin?.coinName, form, onAddSuccess, resetForm],
    );
    const submitVerify = useCallback(
        (verifyInfo) => {
            submitAddAddress(verifyInfo);
        },
        [submitAddAddress],
    );
    const verifyCancel = useCallback(() => {
        codeInputModalRef.current?.dismiss();
        setAddressVisible(true);
        setAddLoading(false);
    }, []);

    const verifyTypes = useVerifyTypes();

    const onFinish = useCallback(
        (values) => {
            setAddLoading(true);
            if (values.whitelist) {
                if (SHOW_GA_BIND_GUIDE_REF.showIfNeed(true)) {
                    // 二次验证没有绑定或者确认
                    setAddLoading(false);
                    return;
                }
                codeInputModalRef.current?.request({
                    params: {
                        verifyTypes: verifyTypes,
                        action: VerifyCodeAction.add_whitelist_address,
                        sendType: SendType.manualSend,
                        account: accountInfo.masterAccount?.account,
                        maskAccounts: verifyInfo.maskAccounts,
                    },
                    onOk: submitVerify,
                    onCancel: verifyCancel,
                });
                setAddressVisible(false);
            } else {
                submitAddAddress();
            }
        },
        [accountInfo.masterAccount?.account, submitAddAddress, submitVerify, verifyCancel, verifyInfo.maskAccounts, verifyTypes],
    );
    const onNotUseMemoChange = useCallback(
        (e) => {
            form.setFieldsValue({ notUseMemo: e.target.checked });
            // 打开不填写 memo 的时候，需要清空 memo 的 error
            if (e.target.checked) {
                form.setFields([{ name: "memo", value: "", errors: [] }]);
            }
        },
        [form],
    );

    const handleChange = (val: any) => {
        if (val?.[0]?.name?.[0] === "chain") {
            form.validateFields();
        }
    };

    const emptyData = isFromWithdrawSuccess ? {} : { address: "", tag: "", memo: "" };

    return (
        <>
            <TModal
                forceRender
                visible={addressVisible}
                onCancel={onCancel}
                title={$st("address_add_page_title")}
                maskClosable={false}
                onOk={handleOk}
                okButtonProps={{ loading: addLoading }}
            >
                <div className={cs.addAddressModal} ref={modalRef}>
                    {loading || !modalRef.current ? (
                        <TLoading />
                    ) : (
                        <Form form={form} name="addAddressForm" initialValues={{ ...resetData, ...emptyData }} onFieldsChange={handleChange} onFinish={onFinish}>
                            <Form.Item
                                name="coin"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (!value) {
                                                return Promise.reject($st("bot_invite_form_email_require"));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <TSelect
                                    getPopupContainer={() => modalRef.current as HTMLDivElement}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option?.coinName.toUpperCase().indexOf(input.toUpperCase()) >= 0 || option?.fullName.toUpperCase().indexOf(input.toUpperCase()) >= 0
                                    }
                                    size="large"
                                    onChange={handleCoinChange}
                                    disabled={isFromWithdrawSuccess}
                                >
                                    {coinOptions}
                                </TSelect>
                            </Form.Item>
                            <span className={cs.addAddressChainText}>{$st("address_table_header_chain")}</span>
                            <Form.Item
                                name="chain"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (!value) {
                                                return Promise.reject($st("bot_invite_form_email_require"));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Radio.Group disabled={isFromWithdrawSuccess} bgStyle="separate-border">
                                    {currentCoins.map((item) => (
                                        <Radio.Button key={item.protocol} value={item.protocol} className={"mt-8px"}>
                                            {item.protocolDisplay}
                                        </Radio.Button>
                                    ))}
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldError, getFieldValue }) => {
                                    const error = getFieldError("address") && getFieldError("address")[0];
                                    const chain = getFieldValue("chain");

                                    return (
                                        <Form.Item
                                            name="address"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        let errMsg = "";
                                                        if (!value) {
                                                            return Promise.reject($st("address_address_input_placeholder"));
                                                        }
                                                        const _val = value.trim();
                                                        if (!_val) {
                                                            errMsg = $st("address_address_input_placeholder");
                                                        } else if (_val.length < 2) {
                                                            errMsg = $st("withdraw.limit.address_len_error");
                                                        } else if (chain === "ERC20" && !/^(0x|0X)/.test(_val)) {
                                                            errMsg = $st("withdraw.limit.address_ERC20_error");
                                                        } else if (chain === "OMNI" && /^(0x|0X)/.test(_val)) {
                                                            errMsg = $st("withdraw.limit.address_OMINI_error");
                                                        } else if (chain === "TRC20") {
                                                            const addressUpper = _val.toUpperCase();
                                                            if (addressUpper.startsWith("BC")) {
                                                                errMsg = $st("withdraw_btc_lighting_invalid", { coin: currentCoin?.coinName });
                                                            }
                                                        }
                                                        if (errMsg) {
                                                            return Promise.reject(errMsg);
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <TInput
                                                size="large"
                                                placeholder={$st("add_address_form_address")}
                                                error={error}
                                                disabled={isFromWithdrawSuccess}
                                                autoComplete={"off"}
                                            />
                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldError }) => {
                                    const error = getFieldError("tag") && getFieldError("tag")[0];
                                    return (
                                        <Form.Item
                                            name="tag"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        if (!value) {
                                                            return Promise.reject($st("address_tag_input_placeholder"));
                                                        }
                                                        const _val = value.trim();
                                                        if (!_val) {
                                                            return Promise.reject($st("address_tag_input_placeholder"));
                                                        }
                                                        if (_val.length > 28) {
                                                            return Promise.reject($st("address_tag_too_long"));
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <TInput size="large" placeholder={$st("address_tag_name")} error={error} autoComplete={"off"} />
                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>
                            {haveMemo ? (
                                <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue, getFieldError }) => {
                                        const formMemoErr = getFieldError("memo") && getFieldError("memo")[0];
                                        const notUseMemo = getFieldValue("notUseMemo");
                                        return (
                                            <div className={cs.memo}>
                                                <Form.Item
                                                    name="memo"
                                                    rules={[
                                                        () => ({
                                                            validator(_, value) {
                                                                if (!notUseMemo) {
                                                                    if (!value) {
                                                                        return Promise.reject($st("add_address_form_memo_error", { memoName: memoTagName }));
                                                                    }
                                                                    const _val = value.trim();
                                                                    if (!_val) {
                                                                        return Promise.reject($st("add_address_form_memo_error", { memoName: memoTagName }));
                                                                    }
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <TInput size="large" placeholder={$st("add_address_form_memo")} error={formMemoErr} disabled={notUseMemo} />
                                                </Form.Item>
                                                <Form.Item name="notUseMemo" valuePropName="checked">
                                                    <TCheckbox onChange={onNotUseMemoChange} className={cs.notUseMemo}>
                                                        {$st("withdraw_address_no_memo", { memoName: memoTagName })}
                                                    </TCheckbox>
                                                </Form.Item>
                                            </div>
                                        );
                                    }}
                                </Form.Item>
                            ) : null}
                            <Form.Item name="whitelist" valuePropName="checked">
                                <TCheckbox>{$st("join_to_whitelist")}</TCheckbox>
                            </Form.Item>
                        </Form>
                    )}
                </div>
            </TModal>

            <CodeInputModal
                ref={codeInputModalRef}
                changeVerificationDropdownProps={{
                    trigger: ["click", "hover"],
                }}
            />
        </>
    );
};

export default AddAddressModal;
