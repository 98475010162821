import { $st } from "utils/i18n/trans.comp";

/** 解析验证码错误提示...*/
export function parseVerifyCodeErrorMsg(errorMsg: string) {
    if (errorMsg) {
        if (errorMsg.indexOf("type=otp") !== -1) {
            return `${$st("withdraw_code_verify_failed_google")}`;
        }
        if (errorMsg.indexOf("type=sms") !== -1) {
            return `${$st("sms_verify_code_error")}`;
        }
        if (errorMsg.indexOf("type=email") !== -1) {
            return `${$st("email_verify_code_error")}`;
        }
        if (errorMsg.indexOf("type=voice") !== -1) {
            return `${$st("voice_verify_code_error")}`;
        }
    }
    return `${$st("verification_code_error")}`;
}
