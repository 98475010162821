import { Button, Checkbox } from "antd";
import { DepositLayerType } from "landings/DepositWithdraw/components/Address";
import cssExports from "landings/DepositWithdraw/components/DWHistory/index.module.less";
import css from "landings/DepositWithdraw/withdraw.module.less";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import AppState from "state/AppState";
import ThemeData from "state/ThemeData";
import { AddressInfo } from "TradeAPILib";
import { $st } from "utils/i18n/trans.comp";

interface AttentionViewProps {
    theme: ThemeData;
    onNext: () => void;
    coinAddress: AddressInfo;
}

export function queryDWLayerType(coinAddressInfo: AddressInfo): DepositLayerType | undefined {
    if (coinAddressInfo.currency === "ETC") {
        return DepositLayerType.likely;
    } else if (coinAddressInfo.tag !== undefined && coinAddressInfo.tag !== "") {
        return DepositLayerType.memo;
    }
    return undefined;
}

export function getTagDisplay(coinAddressInfo?: AddressInfo): string {
    if (coinAddressInfo) {
        return coinAddressInfo.currency === "XRP" ? "Tag" : "Memo";
    }
    return "Memo";
}

const AttentionView = (props: AttentionViewProps) => {
    const { theme, onNext, coinAddress } = props;
    const [buttonDisable, changeButtonDisable] = React.useState(true);

    const { layerContent, layerCheckText } = useMemo(() => {
        const memoName = coinAddress.currency === "XRP" ? "Tag" : "Memo";
        let layerContent = "";
        let layerCheckText = "";
        const layerType = queryDWLayerType(coinAddress);
        if (coinAddress.currency === "MATIC") {
            layerContent = $st("deposit_matic_layer_tip");
            layerCheckText = $st("deposit_matic_layer_check_content");
        } else if (layerType === DepositLayerType.likely) {
            layerContent = $st("deposit_withdraw_etc_attention_content");
            layerCheckText = $st("deposit_withdraw_etc_attention_check");
        } else {
            layerContent = $st("deposit_withdraw_memo_attention_content", {
                coin: coinAddress.currency,
                memoName,
            });
            layerCheckText = $st("deposit_withdraw_memo_attention_check", {
                memoName,
            });
        }
        return { layerContent, layerCheckText };
    }, [coinAddress]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                // padding: '24px 10px 10px'
            }}
        >
            <span
                style={{
                    fontSize: 16,
                    color: theme.colorTextAccent,
                }}
            >
                {$st("deposit_withdraw_memo_attention")}
            </span>
            <span style={{ marginTop: 10, fontSize: 14, color: theme.colorTextAccent }}>{layerContent}</span>

            <div className={cssExports.checkBoxWrapper}>
                <Checkbox
                    onChange={(e) => {
                        changeButtonDisable(!e.target.checked);
                    }}
                >
                    {layerCheckText}
                </Checkbox>
            </div>

            <div style={{ flexDirection: "column", display: "flex", flex: 1, justifyContent: "flex-end" }}>
                <div
                    className={css.attentionButton}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "flex-end",
                    }}
                >
                    <Button className={css.button} disabled={buttonDisable} onClick={onNext}>
                        {$st("dw_attention_next")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export const AttentionViewPage = connect((state: AppState) => ({ theme: state.theme }))(AttentionView);
