import { Table } from "antd";
import { ColumnProps } from "antd/es/table";
import CustomAntdEmpty from "components/Antd/CustomEmpty";
import * as React from "react";
import { useBalanceAll, useArbitrageBalanceTotalUSD } from "src/commonUse/useBalance";
import { $st } from "src/utils/i18n";
import { CoinBalance } from "trade_asset_lib";
import { NumberCommonUtils, SafeDecimal } from "trade_utils_lib";
import css from "../BalanceBot/BalanceBotView.module.less";
import useExchangeRate from "commonUse/useExchangeRate";
import ListCoinNameItem from "landings/Balance/components/ListTable/ListCoinNameItem";
import ListBasicShowItem from "landings/Balance/components/ListTable/ListBasicShowItem";
import ListProgressItem from "landings/Balance/components/ListTable/ListProgressItem";
import ListButtonItem from "landings/Balance/components/ListTable/ListButtonItem";
import { IDWCoinBalance } from "src/commonUse/useDW";
import { Iconfont, TLoading, TTooltip } from "src/components/v2/TinyComps";
import OccupyStatus from "landings/Balance/components/OccupyStatus";
import { useCallback, useState } from "react";
import useMedia from "src/components-v2/Layout/hooks/useMedia";
import { StakingProduct } from "landings/V2/staking/StakingService";
import Constants from "utils/Constants";

export interface ListTableProps {
    exchange: string;
    coinListData: IDWCoinBalance[];
    onLoading: boolean;
    source: "trade" | "debt" | "arbitrage" | "deposit";
    onItemClick?: (item: CoinBalance) => void;
    isSearchState?: boolean;
    hideOperator?: boolean; // 是否展示充提交易按钮
    hideFrozenAndFree?: boolean; // 是否展示可用、冻结数量
    autoWidth?: boolean; // 列宽自适应
    stakingProduct?: StakingProduct[];
}

export function ListTable(props: ListTableProps) {
    const { exchange, coinListData = [], autoWidth = false, onLoading, source, isSearchState, hideFrozenAndFree = false } = props;

    const exchangeRate = useExchangeRate();
    const { currency } = exchangeRate;
    const fullBalance = useBalanceAll();
    const tradeTotalUSD = fullBalance ? fullBalance.trade.totalUSD : 0;
    const arbitrageTotalUSD = useArbitrageBalanceTotalUSD();
    const depositTotalUSD = fullBalance ? fullBalance.deposit.totalUSD : 0;
    const debtTotalUSD = fullBalance ? fullBalance.debt.totalUSD : 0;
    const financeTotalInUSD = fullBalance ? fullBalance.total?.financeTotalInUSD : 0;

    // 查看使用情况的币种
    const [modalOccupyCoin, setModalOccupyCoin] = useState<IDWCoinBalance>();
    const handleHideOccupyModal = useCallback(() => {
        setModalOccupyCoin(undefined);
    }, []);

    const getColumns = React.useMemo(() => {
        const result: Array<ColumnProps<any>> = [
            {
                title: $st("balance.table.coin"),
                dataIndex: "base",
                render: (coin, item) => <ListCoinNameItem baseDisplay={item.baseDisplay} coin={coin} fullName={item.fullName} />,
                width: 180,
            },
        ];

        result.push({
            title: $st("balance.table.quantity"),
            dataIndex: "quantity",
            sorter: (a, b) => {
                return a.quantity - b.quantity;
            },
            render: (data) => <ListBasicShowItem value={new SafeDecimal(data).toDecimalPlaces(8).toString()} />,
            width: 180,
        });

        result.push({
            title: `${$st("balance.table.balance.usd")}(${currency})`,
            dataIndex: "fiat",
            sorter: (a, b) => a.USD - b.USD,
            render: (data, item) => <ListBasicShowItem value={NumberCommonUtils.toNormalPrice(item.USD * exchangeRate.exchangeRate)} />,
            width: 170,
        });
        result.push({
            title: $st("balance_list_occupation"),
            dataIndex: "USD",
            sorter: (a, b) => a.USD - b.USD,
            render: (data, item) => {
                if (item.base === "BUB") {
                    return <div />;
                }
                if (["arbitrage", "deposit"].includes(source)) {
                    const financeTotal = new SafeDecimal(arbitrageTotalUSD).add(depositTotalUSD).toNumber();
                    return <ListProgressItem percent={new SafeDecimal(data).abs().mul(100).div(financeTotal).toDecimalPlaces(2).toNumber()} />;
                }
                if (source === "debt") {
                    return <ListProgressItem percent={new SafeDecimal(data).abs().mul(100).div(new SafeDecimal(debtTotalUSD).abs().toNumber()).toDecimalPlaces(2).toNumber()} />;
                }

                return (
                    <ListProgressItem
                        percent={new SafeDecimal(data)
                            .abs()
                            .mul(100)
                            .div(new SafeDecimal(tradeTotalUSD).add(Constants.isSiteV2 ? financeTotalInUSD || 0 : 0).toNumber())
                            .toDecimalPlaces(2)
                            .toNumber()}
                    />
                );
            },
            width: 140,
        });
        //
        if (["trade", "debt"].includes(source) && !hideFrozenAndFree) {
            result.push({
                title: (
                    <div className="flex items-center">
                        <span>{`${$st("balance_coin_list_row_available")}/${$st("balance.table.frozen_balance.usd")}`}</span>
                        <TTooltip title={<span>{$st("wallet_overview_trade")}</span>}>
                            <Iconfont icon="icon_header_FAQ" size={18} style={{ marginRight: 6 }} />
                        </TTooltip>
                    </div>
                ),
                dataIndex: ["available", "frozen"],
                render: (item, data) => {
                    return (
                        <span>
                            {`${NumberCommonUtils.toNormalPrice(data.available)}`}
                            <span className={css.frozenValue}>{`/${NumberCommonUtils.toNormalPrice(data.frozen)}`}</span>
                        </span>
                    );
                },
                width: 180,
            });
        }
        if (["arbitrage", "deposit"].includes(source)) {
            result.push({
                title: $st("balance_finance_funds_type"),
                dataIndex: ["balanceType"],
                render: (balanceType) => {
                    return <span>{$st(`balance_finance_funds_type.${balanceType}`)}</span>;
                },
                width: 180,
            });
        }

        result.push({
            title: "",
            colSpan: 2,
            render: (coin, item: IDWCoinBalance) => (
                <ListButtonItem
                    exchange={exchange}
                    source={source}
                    currency={item.currency}
                    base={item.base}
                    baseDisplay={item.baseDisplay}
                    coin={coin}
                    fullName={item.fullName || ""}
                    canDeposit={item.canDeposit}
                    canWithdraw={item.canWithdraw}
                    onClickOccupy={() => {
                        setModalOccupyCoin(item);
                    }}
                />
            ),
        });
        if (autoWidth) {
            result.forEach((item) => {
                if (item.width) {
                    delete item.width;
                }
            });
        }

        return result;
    }, [currency, source, hideFrozenAndFree, autoWidth, exchangeRate.exchangeRate, tradeTotalUSD, financeTotalInUSD, arbitrageTotalUSD, depositTotalUSD, debtTotalUSD, exchange]);

    let emptyText;
    if (source === "trade" || source === "debt") {
        if (isSearchState) {
            emptyText = <CustomAntdEmpty loading={onLoading} dataLength={coinListData.length} no_data_value={$st("account_search_filter_empty")} />;
        } else {
            emptyText = <CustomAntdEmpty loading={onLoading} dataLength={coinListData.length} no_data_value={$st("balance_coin_no_data_title")} />;
        }
    } else {
        emptyText = <CustomAntdEmpty loading={onLoading} dataLength={coinListData.length} no_data_value={$st("account_search_filter_empty")} />;
    }
    const { isTablet } = useMedia();
    return (
        <>
            <Table
                size={isTablet ? "small" : "middle"}
                className={css.table_wrapper}
                columns={getColumns}
                rowClassName={(record, index) => (index % 2 !== 1 ? `${css.table_item_odd} ${css.table_common_item}` : `${css.table_common_item} ${css.table_item_even}`)}
                loading={{ indicator: <TLoading />, spinning: onLoading }}
                pagination={false}
                dataSource={coinListData}
                scroll={{ x: "100%" }}
                rowKey="base"
                locale={{
                    emptyText: emptyText,
                    cancelSort: "",
                    triggerDesc: "",
                    triggerAsc: "",
                }}
            />
            {!!modalOccupyCoin && (
                <OccupyStatus
                    visible={!!modalOccupyCoin}
                    base={modalOccupyCoin.base}
                    baseDisplay={modalOccupyCoin.baseDisplay}
                    fullName={modalOccupyCoin.fullName ?? ""}
                    onCancel={handleHideOccupyModal}
                    onOk={handleHideOccupyModal}
                    filterStrategyTag={[31]}
                    stakingProduct={props.stakingProduct}
                />
            )}
        </>
    );
}
