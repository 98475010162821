import React, { useCallback, useState } from "react";
import HistoryTabs from "landings/V2/CircleDW/History/HistoryTabs";
import Withdraw from "./Withdraw";
import Deposit from "./Deposit";
import cs from "./index.m.less";

type TabKey = "deposit" | "withdraw";

/**
 * Signature出入金历史记录汇总界面
 */
const History = () => {
    const [activeKey, setActiveKey] = useState<TabKey>("deposit");
    const onChange = useCallback((key: TabKey) => {
        setActiveKey(key);
    }, []);
    return (
        <>
            <HistoryTabs className={cs.tabs} activeKey={activeKey} onChange={onChange}></HistoryTabs>
            {activeKey === "deposit" && <Deposit />}
            {activeKey === "withdraw" && <Withdraw />}
        </>
    );
};

export default History;
