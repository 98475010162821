import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { ajaxGet } from "rxjs/internal/observable/dom/AjaxObservable";
import { catchError, exhaustMap, map, tap } from "rxjs/operators";
import AppState from "state/AppState";
import Constants from "utils/Constants";
import { message } from "antd";

export const EXCHANGE_RATE_KEY = "exchange_rate_key";

export type ExchangeRateInfo = {
    base: string;
    quote: string;
    price: number;
    rateMapWithUSD?: Map<string, number>;
};

export default class ExchangeRate {
    currency: string;
    exchangeRate: number;
    // 基于USD的汇率字典
    rateMapWithUSD: Map<string, number>;
    constructor(data?: ExchangeRate) {
        this.currency = data?.currency || "USD";
        this.exchangeRate = data?.exchangeRate || 1;
        this.rateMapWithUSD = data?.rateMapWithUSD || new Map();
    }

    static reducer = (state: ExchangeRate = new ExchangeRate(), action) => {
        switch (action.type) {
            case "refresh_exchange_rate_result":
                if (action.data) {
                    const { quote: currency, price: exchangeRate, rateMapWithUSD = new Map() } = action.data as ExchangeRateInfo;
                    return new ExchangeRate({ currency, exchangeRate, rateMapWithUSD });
                }
        }
        return state;
    };

    static get availableActions() {
        return {
            refreshExchangeRate: (currency) =>
                AppState.store.dispatch({
                    type: "refresh_exchange_rate",
                    // 美国站仅支持USD汇率，不支持切换
                    data: PLATFORM.PIONEX_US_LIKE ? "USD" : currency,
                }),
        };
    }

    static refreshExchangeRateRoutin = (action$, store) => {
        // 服务端渲染时，不获取汇率数据
        if (window.__PRERENDER_INJECTED?.$$isRenderServer) return of({ type: "refresh_exchange_rate_result", data: undefined });
        return action$
            .pipe(ofType("refresh_exchange_rate"))
            .pipe(
                exhaustMap<any, any>((param) => {
                    return ajaxGet(`${Constants.configHost}/fiatmap.json`, undefined)
                        .pipe(
                            map((result) => {
                                let findResult: undefined | ExchangeRateInfo;
                                const rateMapWithUSD = new Map<string, any>();
                                result.response.forEach((item: ExchangeRateInfo) => {
                                    if (item.base.toUpperCase() === "USD") {
                                        // 当前用户汇率
                                        if (item.quote.toUpperCase() === param["data"].toUpperCase()) findResult = item;
                                        // 所有与USD之间的汇率的quote与price的映射
                                        rateMapWithUSD.set(item.quote, item.price);
                                    }
                                });
                                if (!findResult) return;
                                return { ...findResult, rateMapWithUSD } as ExchangeRateInfo;
                            }),
                        )
                        .pipe(
                            tap(() => {
                                window.localStorage.setItem(EXCHANGE_RATE_KEY, param["data"]);
                            }),
                        );
                }),
            )
            .pipe(
                map((data) => {
                    return { type: "refresh_exchange_rate_result", data };
                }),
            )
            .pipe(
                catchError((error) => {
                    message.error(`[fiatmap.json Request Error]: ${error?.message || error.toString()}`);
                    return of({ type: "refresh_exchange_rate_result" });
                }),
            );
    };

    static get epic() {
        return combineEpics(this.refreshExchangeRateRoutin);
    }

    static init() {
        let exchangeRateCache = window.localStorage.getItem(EXCHANGE_RATE_KEY);
        if (exchangeRateCache) {
            this.availableActions.refreshExchangeRate(exchangeRateCache);
        }
        // 没设置过汇率时在语言初始化完成之后再刷新
    }
}
