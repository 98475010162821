import { report } from "utils/GoogleTagManager";

/**
 * Web上报 Google Analytics
 */
export const PionexReportV2 = {
    /**
     * 交易页面点击行为
     * 1.账户设置  2.系统设置  3.Trade  4.My bots  5.资产按钮  6.只显示当前交易对  7.查看历史订单
     * @param type
     */
    reportClickInfo(button: 1 | 2 | 3 | 4 | 5 | 6 | 7) {
        report("pionex_exchange_page", { button });
    },

    /**
     * 选择交易对排序情况
     * 1.PAIR  2.VOL  3.PRICE  4.CHANGE
     * @param type
     */
    reportSymbolSortTyle(type: 1 | 2 | 3 | 4) {
        report("pionex_pair_rank", { type });
    },

    /**
     * 交易页面订单处
     * @param cardType  1.Trading bots  2.Manual orders
     * @param operation  "cancel" | "share" | "detail"
     * @param state 订单状态  同Order.state
     */
    reportBotCardOperate(data: { tab: string; state: "cancel" | "share" | "detail"; operation: string }) {
        report("pionex_order_operate", data);
    },

    /**
     *
     * @param tab 1.登录tab   2.注册tab
     * @param operation 1.点击这个tab   2.点击发送验证码   3.点击登录/注册大按钮   4.点击登录/注册后成功  5.点击登录/注册后失败  6.点击忘记密码  7.点击加入社群
     */
    pionex_log_page(data: { tab: 1 | 2; operation: 1 | 2 | 3 | 4 | 5 | 6 | 7 }) {
        report("pionex_log_page", data);
    },

    /**
     *
     * @param headertab 小按钮登录注册
     *
     */
    header_pionex_log_page(headertab: "header_sing" | "pihodl_sign") {
        report("header_pionex_log_page", { headertab });
    },

    /**
     *
     * @param operation 1What's Pionex 2Market 3CoinCap 4点击iOS 5点击Android 6点击Market的任意交易对 7点击CoinCap任意交易对
     */
    pionex_main_page(operation: 1 | 2 | 3 | 4 | 5 | 6 | 7) {
        report("pionex_main_page", { operation });
    },

    /**
     *
     * @param setting 1成功修改用户名 2成功设置头像 3成功解绑谷歌验证 4成功修改Password
     */
    pionex_personal_set(setting: 1 | 2 | 3 | 4) {
        report("pionex_personal_set", { setting });
    },

    reportNewUserViewing() {
        // 若已经注册，将不再上报访问事件
        if (!window.localStorage.getItem("--us-new-user-ana-registered--")) report("pionex_new_user_viewing");
    },
};
