import React from "react";
import cs from "./index.m.less";
import csn from "classnames";

interface Props {
    text?: string;
    className?: string;
}

/**
 * 一级菜单的徽章
 */
const Badge: React.FC<Props> = ({ text, className }) => {
    return <span className={csn(cs.badge, className)}>{text}</span>;
};

export default Badge;
