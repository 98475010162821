import { PlaidLinkOnSuccessMetadata, usePlaidLink } from "react-plaid-link";
import Constants from "utils/Constants";
import { message } from "antd";
import useSignRequest from "commonUse/useSignRequest";
import { CommonSubmitResult } from "landings/V2/CircleACH/index";
import { useTranslators } from "commonUse/Locale";
import React, { cloneElement, useEffect, useState } from "react";
import { ACH_AccountCreate, ACHChannel, useGetListToken } from "landings/V2/CircleACH/utils";
import { TSpin } from "components/v2/TinyComps";
import { map, mergeMap } from "rxjs/operators";
import { Modal } from "@pionex-web-kit/components";

/**
 * 用来每一次挂载重新出发plaid的sdk hooks
 * @param token
 * @param onACHBindSucceed
 * @constructor
 */
export const LinkPlaidShow: React.FC<{ token: string; onACHBindSucceed: () => void; ach_account_id?: string }> = ({ token, onACHBindSucceed, ach_account_id }) => {
    const { $st } = useTranslators();
    const signRequest = useSignRequest();

    const { open, exit, ready } = usePlaidLink({
        onSuccess: async (plaid_public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
            const {
                accounts: [{ id, mask, name }],
                institution,
            } = metadata;

            const reAuthRequest = signRequest<CommonSubmitResult>(`${Constants.walletApiHost}/circle/ach/account/re_authenticated/`, {
                method: "POST",
                body: { id: ach_account_id },
            });

            const newAuthRequest = signRequest<{ channel: ACHChannel }>(`${Constants.walletApiHost}/circle/cards/channel/?type=ACH`, {
                method: "GET",
            }).pipe(
                map((res) => res.data.channel),
                mergeMap((channel) =>
                    signRequest<CommonSubmitResult>(`${Constants.walletApiHost}/circle/ach/accounts/`, {
                        method: "POST",
                        body: {
                            channel,
                            plaid_public_token,
                            plaid_account_id: id,
                            plaid_account_name: name,
                            plaid_account_mask: mask,
                            plaid_institution_id: institution?.institution_id,
                            plaid_institution_name: institution?.name,
                        } as ACH_AccountCreate,
                    }),
                ),
            );

            // 若传入了选择的accountId则为重新认证逻辑
            const bindPromise = (!!ach_account_id ? reAuthRequest : newAuthRequest).toPromise();

            try {
                const { code, data, message: msg } = await bindPromise;
                if (code === 0) {
                    if (data.status === "FAILED") {
                        // 创建ACH账户失败原因
                        message.error($st({ id: `ach_bind_account_circle_error:${data.fail_reason}`, defaultMessage: `[${data.fail_reason}]: ${$st("ach_bind_failed")}` }));
                    } else {
                        onACHBindSucceed();
                    }
                } else {
                    message.error(msg || $st("ach_bind_account_unknown_error"));
                }
            } catch (error) {
                if (!!error?.data?.mcode) {
                    const code = error.data.mcode;
                    message.error($st({ id: `ach_bind_account_error:${code}`, defaultMessage: $st("unknown_error_code", { code }) }));
                } else {
                    message.error($st("ach_bind_account_unknown_error"));
                    console.error(error);
                }
            }
        },
        onExit: (err, metadata) => {
            console.log(err, metadata);
        },
        onEvent: async (eventName, metadata) => {
            if (eventName === "ERROR" && metadata.error_code === "INVALID_LINK_TOKEN") {
                // await updateToken();
                message.info("Please try again~");
            }
        },
        token,
        //required for OAuth; if not using OAuth, set to null or omit:
        // receivedRedirectUri: window.location.href,
    });

    useEffect(() => {
        // 每一次挂载并ready后打开plaid
        if (!ready) {
            return;
        }

        open();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ready]);

    return null;
};

/**
 * 增加点击事件，获取linkToken的操作逻辑
 */
export const LinkPlaidWrap: React.FC<{ children: JSX.Element; ach_account_id?: string; onACHBindSucceed: () => void; onClick?: () => boolean | undefined }> = ({
    children,
    onACHBindSucceed,
    onClick,
    ach_account_id,
}) => {
    const { $st } = useTranslators();
    const [loading, setLoading] = useState(false);

    const { linkToken, getLinkToken } = useGetListToken();

    return (
        <TSpin spinning={loading}>
            {cloneElement(children, {
                onClick: async () => {
                    if (onClick?.()) {
                        // 外部拦截掉事件
                        return;
                    }

                    if (!ach_account_id) {
                        await new Promise((resolve, reject) => {
                            Modal.confirm({
                                title: $st("ach_link_confirm_title"),
                                content: $st("ach_link_confirm_des", { br: <br /> }),
                                okText: $st("spot_balance_small_asset_transfer_confirm"),
                                onOk: () => {
                                    resolve(true);
                                },
                                onCancel: () => {
                                    reject(true);
                                },
                            });
                        });
                    }
                    setLoading(true);
                    // 获取linkToken数据
                    await getLinkToken(ach_account_id);
                    setLoading(false);
                },
            })}
            {/*根据linkToken不同状态选择是否挂载LinkPlaidShow*/}
            {linkToken && <LinkPlaidShow token={linkToken.token} onACHBindSucceed={onACHBindSucceed} ach_account_id={ach_account_id} />}
        </TSpin>
    );
};
