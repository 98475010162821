import { useState } from "react";
import { useCallbackStatic } from "commonUse/tools";
import TwoStepVerifyModal, { useTwoStepVerifyModalState } from "components/TwoStepVerifyModal";

export const SHOW_GA_BIND_GUIDE_REF = {
    /**
     * 如果需要则弹窗
     * @param ignoreToday 忽略24小时不弹出的判断
     * @return 是否弹窗
     */
    showIfNeed(ignoreToday?: boolean): boolean {
        return false;
    },
};

export default function GuideBindGADialog() {
    const [verifyModalVisible, setVerifyModalVisible] = useState(false);
    const [disableToday, setDisableToday] = useState(false);

    const {
        shouldVerifyModal, // 两步验证弹窗是否启用，每次数据变化会触发render重置showIfNeed，所以不会有闭包老值的问题
        isTodayShowFn, // 今日是否提醒
    } = useTwoStepVerifyModalState();

    SHOW_GA_BIND_GUIDE_REF.showIfNeed = useCallbackStatic((ignoreToday?: boolean) => {
        if (window.location.pathname.includes("trial-fund")) {
            return false;
        }
        if (shouldVerifyModal && (isTodayShowFn() || ignoreToday)) {
            ignoreToday && setDisableToday(true); // 强制展示时,不显示今日不展示勾选
            setVerifyModalVisible(true);
            return true;
        }
        return false;
    });

    return (
        <TwoStepVerifyModal
            scene={"account_sign"}
            visible={verifyModalVisible}
            onCancel={() => {
                setVerifyModalVisible(false);
            }}
            disableToday={disableToday}
            onVisibleChange={(v) => {
                if (!v) {
                    // 隐藏时重置
                    setDisableToday(false);
                }
            }}
        />
    );
}
