import React, { useState } from "react";
import { Upload } from "antd";
import { Iconfont, TButton, TLoading } from "src/components/v2/TinyComps";
import { useTranslators } from "src/commonUse/Locale";
import cs from "./cs.m.less";
import { customUploadRequest } from "./tools";
import csn from "classnames";

interface Props {
    className?: string;
    onFileUploaded: (string) => void;
    setImgErrors: (error: string) => void;
    minSize?: number;
}
const Uploader = ({ onFileUploaded, setImgErrors, minSize = 100, className }: Props) => {
    const { $st } = useTranslators();
    const [imgUrl, setImageUrl] = useState<string>("");
    const [pdf, setPdf] = useState<File | null>(null);
    const [imgError, setImageError] = useState("");
    const [imgLoading, setImageLoading] = useState(false);
    function beforeUpload(file) {
        onFileUploaded("");
        setImgErrors("");
        const isLt10M = file.size / 1024 / 1024 < 10;
        let errorMsg = "";
        if (!isLt10M) {
            errorMsg = $st("deposit_create_order_atm_bind_file_size");
        }
        // 最小值 单位k
        const isLtMin = file.size / 1024 > minSize;
        if (!isLtMin) {
            errorMsg = $st("deposit_create_order_atm_bind_file_size_min", { min: minSize });
        }
        const setIsJpgOrPng = ["image/jpeg", "image/png", "application/pdf"].includes(file.type);
        if (!setIsJpgOrPng) {
            errorMsg = $st("deposit_create_order_atm_bind_file_size_type");
        }
        setImageError(errorMsg);
        setImgErrors(errorMsg);
        return !errorMsg;
    }
    function customRequest(option) {
        setImageError("");
        setImageUrl("");
        setPdf(null);
        setImageLoading(true);
        customUploadRequest({
            uploadProps: option,
        })
            .then(({ fileName, file }: { fileName: string; file: File }) => {
                if (file.type === "application/pdf") {
                    return { dataUrl: "", fileName, file };
                } else {
                    return new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.addEventListener("load", () => resolve({ dataUrl: reader.result, fileName, file }));
                        reader.readAsDataURL(file);
                        setImageUrl(fileName);
                    });
                }
            })
            .then(({ dataUrl, fileName, file }: { dataUrl: string; fileName: string; file: File }) => {
                if (dataUrl) {
                    setImageUrl(dataUrl);
                } else {
                    setPdf(file);
                }
                onFileUploaded(fileName);
            })
            .catch((err) => {
                setImageError($st("kyc_upload_failed"));
                console.error(err);
            })
            .finally(() => {
                setImageLoading(false);
            });
    }
    return (
        <Upload name="idZ" customRequest={customRequest} className={csn(cs.cardUpload, className)} showUploadList={false} disabled={imgLoading} beforeUpload={beforeUpload}>
            <>
                {imgError ? (
                    <div className={cs.cardUploadError}>
                        <div style={{ marginBottom: 8 }}>{imgError}</div>
                        <TButton type="primary">{$st("deposit_create_order_atm_bind_change_photo")}</TButton>
                    </div>
                ) : imgUrl || pdf ? (
                    imgUrl ? (
                        <div className={cs.cardUploadImg} style={{ backgroundImage: `url(${imgUrl})` }}>
                            <div className={cs.cardUploadImgMask}></div>
                            <TButton type="bordered">{$st("deposit_create_order_atm_bind_change_photo")}</TButton>
                        </div>
                    ) : (
                        <div className={cs.cardUploadNormal}>
                            <div className={cs.cardUploadNormalName}>
                                <Iconfont icon="icon_pdf" style={{ fontSize: 30, verticalAlign: -3 }} />
                                {pdf?.name}
                            </div>
                            <TButton type="bordered">{$st("deposit_create_order_atm_bind_change_photo")}</TButton>
                        </div>
                    )
                ) : (
                    <div className={cs.cardUploadNormal}>
                        {imgLoading ? (
                            <div className={cs.cardUploadLoading}>
                                <TLoading />
                            </div>
                        ) : (
                            <>
                                <div style={{ marginBottom: 8 }}>
                                    <img src={require("images/v2/twDeposit/Subtract.png")} alt="" />
                                </div>
                                <div>{$st("deposit_create_order_atm_bind_step2_upload")}</div>
                            </>
                        )}
                    </div>
                )}
            </>
        </Upload>
    );
};
export default Uploader;
