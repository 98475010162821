import { TForm } from "components/v2/TinyComps";
import React, { ReactNode } from "react";
interface InnerProps<V> {
    onChange?(value?: V): void;
    value?: V;
}

interface Props<V> extends Partial<InnerProps<V>> {
    children?(props: InnerProps<V>): ReactNode;
}

/**
 * 用来转换FormItem中组件的value和onChange
 * @param props
 * @constructor
 */
export default function FormItemTransition<V>(props: Props<V>) {
    const { value, onChange } = props;
    return <>{props.children?.({ onChange, value })}</>;
}

// <FormItem field={'key'}>
//   <FormItemTransition<ValueType>>
//     {({ onChange, value }) => (
//       <XCom
//         xValue={value}
//         onXChange={onChange}
//       />
//     )}
//   </FormItemTransition>
// </FormItem>
