import { memo, useLayoutEffect } from "react";
import LocalStorageUtils from "utils/LocalStorageUtils";

export const GlobalHookComponent = memo(() => {
    useLayoutEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const referral = searchParams.get("referral");
        const source = searchParams.get("source");
        if (referral) {
            LocalStorageUtils.set("referral", referral);
        }
        if (source) {
            LocalStorageUtils.set("source", source);
        }
    }, []);
    return <></>;
});
