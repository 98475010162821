import { Tabs } from "@pionex-web-kit/components";
import { useState } from "react";
import OtherHistory from "./OtherHistory";
import AchStripeHistory from "./AchStripeHistory";

export default function Deposit() {
    const [activeKey, setActiveKey] = useState("AchStripeHistory");
    return (
        <div className="px-16px">
            <Tabs
                type="button-group"
                activeKey={activeKey}
                onChange={(e) => {
                    setActiveKey(e);
                }}
                items={[
                    {
                        key: "AchStripeHistory",
                        label: "ACH (Stripe)",
                        children: activeKey === "AchStripeHistory" && <AchStripeHistory />,
                    },
                    {
                        key: "OtherHistory",
                        label: "Other",
                        children: activeKey === "OtherHistory" && <OtherHistory />,
                    },
                ]}
            />
        </div>
    );
}
