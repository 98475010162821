import { Restrict_Reason, RestrictData } from "account/types";
import { useEffect, useMemo, useState } from "react";
import { useAsync } from "react-use";
import useAccountInfo from "commonUse/useAccountInfo";
import { ObservableUtils, SafeDecimal } from "trade_utils_lib";
import Constants from "utils/Constants";
import { OtpState } from "state/AccountInfo";
import { desensitizedEmail, desensitizedPhone } from "utils/Utils";
import { AccountInfoTag, AccountType, MaskAccountData, VerifyType } from "bu_account_js_sdk";

export function parseWithdrawContent(restrictData: RestrictData, str, dateFormatter): string {
    let contentStr = "";
    const deadTime = new SafeDecimal(restrictData.restrict_expired_on).mul(1000) ?? new SafeDecimal(new Date().getTime()).add(86400000);
    const deadTimeStr = dateFormatter.year_minute(deadTime.toNumber());
    const suffixStr = str("withdraw_forbidden_content_suffix", {
        time: deadTimeStr,
    });
    switch (restrictData.restrict_reason) {
        case Restrict_Reason.remote_login:
            contentStr = `${str("withdraw_forbidden_content_remote_login")}${suffixStr}`;
            break;
        case Restrict_Reason.change_password:
            contentStr = `${str("withdraw_forbidden_content_reset_password")}${suffixStr}`;
            break;
        case Restrict_Reason.confirm_otp:
            contentStr = `${str("withdraw_forbidden_content_bind_otp")}${suffixStr}`;
            break;
        case Restrict_Reason.unbind_otp:
            contentStr = `${str("withdraw_forbidden_content_unbind_otp")}${suffixStr}`;
            break;
        case Restrict_Reason.admin_unbind_otp:
            contentStr = `${str("withdraw_forbidden_content_reset_otp")}${suffixStr}`;
            break;
        case Restrict_Reason.admin_unbind_acct:
            contentStr = `${str("withdraw_forbidden_content_reset_account")}${suffixStr}`;
            break;
        default:
            contentStr = str("withdraw_forbidden_content_default", { app_short_name: Constants.appShortName });
            break;
    }
    return contentStr;
}

export function useRestrictData(): [RestrictData | undefined, boolean] {
    const { userId } = useAccountInfo();
    const { value, loading } = useAsync(() => {
        if (!userId) {
            return Promise.resolve(undefined);
        }
        return ObservableUtils.get<RestrictData>(`${Constants.accountServiceHost}/user/has_high_risk`, undefined, undefined, true).toPromise();
    }, [userId]);

    return [value, loading];
}
/**
 * @description: 获取公钥标识和公钥内容
 * @return {*}
 */
export function usePubkey() {
    const [pubkey, setPubkey] = useState<{ key_id: string; key_val: string }>(); // 公钥
    useEffect(() => {
        ObservableUtils.getV2<any>(
            `${Constants.accountServiceHost}/pubkey`,
            {
                fp_dfp: "unknown",
            },
            {},
        )
            .toPromise()
            .then((data) => {
                setPubkey(data);
            });
    }, []);
    return pubkey;
}

export interface AvailableVerifyInfo {
    account: string;
    maskAccounts: MaskAccountData[];
    verifyTypes: VerifyType[];
}

/**
 * 获取当前用户可用的安全项
 */
export function useAvailableVerifyInfo(): AvailableVerifyInfo {
    const accountInfo = useAccountInfo();

    return useMemo(() => {
        const verifyTypes: VerifyType[] = [];
        const maskAccounts: MaskAccountData[] = [];
        let masterAccount = "";
        if (accountInfo.accounts) {
            for (const account of accountInfo.accounts) {
                if (account.account_info_tag === AccountInfoTag.master) {
                    masterAccount = account.account;
                }
                if (account.account_type === AccountType.phoneNumber) {
                    verifyTypes.push(VerifyType.sms_code);
                    maskAccounts.push({
                        account_type: account.account_type,
                        account_value: desensitizedPhone(account.account),
                    });
                } else if (account.account_type === AccountType.email) {
                    verifyTypes.push(VerifyType.email_code);
                    maskAccounts.push({
                        account_type: account.account_type,
                        account_value: desensitizedEmail(account.account),
                    });
                }
            }
            if (!masterAccount && accountInfo.accounts.length > 0) {
                masterAccount = accountInfo.accounts[0].account;
            }
        }
        if (accountInfo.otpState === OtpState.BOUND) {
            verifyTypes.push(VerifyType.verify_otp);
        }
        return { verifyTypes, maskAccounts, account: masterAccount };
    }, [accountInfo.accounts, accountInfo.otpState]);
}
