import { useTranslators } from "commonUse/Locale";
import Filter from "../../../Market/component/Filter";
import React, { useMemo } from "react";

const HistoryTabs = ({ activeKey, onChange, className }: { onChange: (key: string) => void; activeKey: string; className?: string }) => {
    const { $st } = useTranslators();
    const tabs = useMemo(() => {
        return [
            { label: $st("circledw_history_deposit"), key: "deposit" },
            { label: $st("circledw_history_withdraw"), key: "withdraw" },
        ];
    }, []);
    return <Filter className={className} onChange={onChange} tabs={tabs} activeKey={activeKey}></Filter>;
};
export default HistoryTabs;
