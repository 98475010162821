import React, { useMemo, useState } from "react";
import { TModal } from "components/v2/TinyComps";
import { useTranslators } from "commonUse/Locale";
import cs from "./index.module.less";
import TSelfNumberInput from "src/components-v2/TSelfNumberInput";
import { useBalanceTradeByBaseQuote } from "commonUse/useBalance";
import { NumberCommonUtils, SafeDecimal } from "trade_utils_lib";
import { ExchangeOrderSide, ExchangeOrderType } from "TradeAPILib";
import { message } from "antd";
import { CheckMarketTradingCost, useSwapConfig } from "./helpers";
import { useDataProviderOnFirstApiKey } from "commonUse";
import { MarketTradingParams, MarketTradingProcessor, ParseErrorUtils } from "trade_lib_wrapper";
import { flatMap } from "rxjs/operators";
import { OrderCreator } from "@pionex-web-kit/trading-components/es/Trading/Grid/tools/OrderCreator";
import { useGetLimit, useTickerSwap } from "landings/Trade/Swap/tool";
import { useSymbol } from "@pionex-web-kit/common-business";
import { EMPTY } from "rxjs";

const USDT = "USDT";

/**
 * 获取有效的symbol，正向币对没找到，获取反向币对
 * @param opposite
 */
function useValidSymbol(opposite: string) {
    const symbol = useSymbol(opposite, USDT);
    const reverseSymbol = useSymbol(USDT, opposite);

    return symbol ?? reverseSymbol;
}

interface Props {
    opposite: string; // 和USDT互换的币种
    isGetUSDT?: boolean; // 交换方向，默认USDT换base
    onSuccess?: () => void; // 闪兑成功
    visible?: boolean;
    onCancel?: () => void;
}

/**
 * 和USDT互换的闪兑弹窗
 */
const USDTSwapModal: React.FC<Props> = ({ isGetUSDT, opposite, onSuccess, visible, onCancel }) => {
    const { $st } = useTranslators();

    const swapConfig = useSwapConfig();

    const from = isGetUSDT ? opposite : USDT;
    const to = isGetUSDT ? USDT : opposite;

    const symbol = useValidSymbol(opposite);

    // 确定买卖方向
    const isBuy = from === symbol?.quote;
    const ticker = useTickerSwap(symbol, swapConfig);

    const [{ baseAvailable, baseBalance: baseTotalBalance, quoteAvailable }] = useBalanceTradeByBaseQuote(symbol?.base, symbol?.quote);
    const [buyPrecision, sellPrecision, fromPrecision, toPrecision] = useMemo(() => {
        const buyPrecision = symbol?.precisionCost ?? 4;
        const sellPrecision = symbol?.amount ?? 4;
        const fromPrecision = isBuy ? buyPrecision : sellPrecision;
        const toPrecision = Math.max(buyPrecision, sellPrecision);
        return [buyPrecision, sellPrecision, fromPrecision, toPrecision];
    }, [isBuy, symbol?.amount, symbol?.precisionCost]);

    // 兑换的个数
    const [fromAmount, setFromAmount] = useState("");
    // 得到的个数
    const toMount = useMemo(() => {
        if (!fromAmount && fromAmount !== "0") {
            return "--";
        }
        let result;
        if (ticker && fromAmount) {
            if (isBuy) {
                result = NumberCommonUtils.formatterDecimalToStr(new SafeDecimal(fromAmount).div(ticker.latest), toPrecision);
            } else {
                result = NumberCommonUtils.formatterDecimalToStr(new SafeDecimal(fromAmount).mul(ticker.latest), toPrecision);
            }
        }
        return Number(result) || "--";
    }, [fromAmount, isBuy, ticker, toPrecision]);

    const summary = [
        { label: $st("trade_available"), value: `${NumberCommonUtils.formatterDecimalToStr(symbol?.base === opposite ? baseAvailable : quoteAvailable, fromPrecision)} ${from}` },
        { label: $st("swap_usdt_input_placeholder"), value: `${toMount} ${to}` },
    ];

    // const fromSwapConfig = useFromSwapConfig(opposite, isSell);
    const range = useGetLimit(symbol, ticker, isBuy, isBuy ? buyPrecision : sellPrecision);
    const [submitting, setSubmitting] = useState(false);
    const tradeAPI = useDataProviderOnFirstApiKey()?.api;
    const handleOk = () => {
        if (!tradeAPI || !symbol || !ticker) {
            return;
        }

        const _swapAmount = Number(fromAmount);
        const max = range?.max || 0;
        const min = range?.min || 0;
        if (_swapAmount > max) {
            message.error($st("trade_swap_error_high_cost", { cost: max }));
            return;
        } else if (_swapAmount < min) {
            message.error($st("trade_swap_error_low_cost", { cost: min }));
            return;
        }

        setSubmitting(true);
        const params: MarketTradingParams = {
            market: tradeAPI.getInnerApiKeyInfo().market as string,
            symbol,
            side: !isBuy ? ExchangeOrderSide.sell : ExchangeOrderSide.buy,
            baseBalance: baseAvailable.toString(),
            quoteBalance: quoteAvailable.toString(),
            baseTotalBalance: baseTotalBalance,
            curPrice: ticker.latest.toString(),
            quantity: fromAmount as string,
            orderType: ExchangeOrderType.market,
            tradeType: !isBuy ? "sell" : "cost",
        };

        try {
            const request = MarketTradingProcessor.getCreateOrderRequest(params, $st);
            CheckMarketTradingCost(symbol, ticker.latest, params.side, tradeAPI, params.quantity, params.tradeType, $st)
                .pipe(
                    flatMap((result) => {
                        if (!result) {
                            return EMPTY;
                        }
                        return OrderCreator.createOrder(request, symbol.baseDisplay, symbol.quote, params.orderType, tradeAPI, $st, undefined, false);
                    }),
                )
                .subscribe(
                    () => {
                        message.success($st("swap_success"));
                        onSuccess?.();
                    },
                    (e) => {
                        console.error(e);
                        message.error(ParseErrorUtils.formatterToString(e));
                        setSubmitting(false);
                    },
                    () => {
                        setSubmitting(false);
                        setFromAmount("");
                    },
                );
        } catch (e) {
            console.error(e);
            message.error(ParseErrorUtils.formatterToString(e));
            setSubmitting(false);
        }
    };

    return (
        <TModal
            visible={visible}
            width={400}
            title={$st("usdt_swap_title", { from: from, to: to })}
            okText={$st("trade_swap")}
            onOk={handleOk}
            confirmLoading={submitting}
            onCancel={onCancel}
        >
            <TSelfNumberInput
                maxPrecision={fromPrecision}
                size={"large"}
                wrapperClassName={cs.amount}
                placeholder={$st("usdt_swap_amount_placeholder")}
                onChange={setFromAmount}
                value={fromAmount}
            />
            {summary.map((item) => (
                <div key={item.label} className={cs.summaryItem}>
                    <div>{item.label}</div>
                    <div className={cs.summaryValue}>{item.value}</div>
                </div>
            ))}
            <div className={cs.tips}>{$st("swap_usdt_hint")}</div>
        </TModal>
    );
};

export default USDTSwapModal;
