import {
    BUAccount,
    BUAccountAPISessionCallback,
    BUAccountAPISessionParam,
    BUAccountAPISessionResult,
    BUAccountConfig,
    BUAccountToken,
    BUAccountTokenStorage,
    BUAccountTokenUsage,
    BUUserInfo,
} from "bu_account_js_sdk";
import { showEnterLCEDialog } from "components/GuideEnterLCEDialog";
import { forkJoin, from, Observable, of, zip } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { history } from "utils/History";
import { AuthType, getTradeDependencyInstance, Network, ObservableUtils, requestWithRetry } from "trade_utils_lib";
import { ExchangeID, FSARiskPlanStatus } from "TradeAPILib";
import { APIKeyInfo, ExchangeTradePair } from "TradeLib/TradeTypes";
import { $st } from "utils/i18n";
import AccountInfo, {
    RebateLevel,
    TYPE_ACCOUNT_FSA_RISK_PLAN,
    TYPE_ACCOUNT_INIT,
    TYPE_ACCOUNT_PASS_LCE,
    TYPE_ACCOUNT_REBATE_LEVEL,
    TYPE_ACCOUNT_REFRESH_API_KEY,
    TYPE_ACCOUNT_REFRESH_INFO,
    TYPE_ACCOUNT_REFRESH_OTP,
    TYPE_ACCOUNT_REFRESH_REFERRAL_CODE,
    TYPE_ACCOUNT_SIGN_IN,
    TYPE_ACCOUNT_SIGN_OUT,
} from "../state/AccountInfo";
import AppState from "../state/AppState";
import Constants, { BusinessVersion } from "./Constants";
import DataSubject from "./DataSubject";
import LocalStorageUtil from "./LocalStorageUtils";
import LocalStorageUtils, { LocalStorageKey } from "./LocalStorageUtils";
import ExchangeDataProvider from "TradeLib/ExchangeDataProvider";
import { VerifyCodeAction, VerifyCodeType } from "trade_asset_lib";
import { TModal } from "components/v2/TinyComps";
import FrameBridge from "./frameBridge";
import { message } from "@pionex-web-kit/components";
import Cookies from "js-cookie";
import { correctHostByVersion, toBusinessWeb } from "./BusinessWeb";
import DataReporter from "landings/Analytics/DataReporter";
import { AccountService, SendVerifyCodeParams } from "bu_account_js_sdk/src/AccountService";
import { ErrorCode, ErrorCodeUtils } from "utils/errorCode";
import { EventName, FromName } from "landings/Analytics/EventName";
import { AccountsRoute } from "utils/AccountsRoute";
import { ReactNativeWebView } from "utils/ReactNativeWebView";

export interface TokenData {
    expire_at: number;
    token: string;
}

/*
        币安所有在用的正则  作为参考
        cnMobile:/^1[3456789]\d{9}$/
        cpaRefCode: /CPA_[A-Za-z0-9]{10,10}/
        email: /^[\w+&*-]+[-+.'&*\w]*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        emailCode: /^\d{6}$/
        emailForRegister: /^[a-zA-Z0-9_+-]+(?:\.[a-zA-Z0-9_+-]+)*@(?:[a-zA-Z0-9-_]+\.)+[a-zA-Z]+$/
        googleCode: /^\d{6}$/
        growthRefCode: /LIMIT_[A-Za-z0-9]{8,8}/
        ip: /((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.){3}(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)/
        mobile: /^\+?\d{5,13}$/
        password: /(?=.*\d)(?=.*[A-Z])[\s\S]{8,128}/
        punctuation: /[!-#%-*,-/\:;?@\[-\]_\{\}\u00A1\u00A7\u00AB\u00B6\u00B7\u00BB\u00BF\u037E\u0387\u055A-\u055F\u0589\u058A\u05BE\u05C0\u05C3\u05C6\u05F3\u05F4\u0609\u060A\u060C\u060D\u061B\u061E\u061F\u066A-\u066D\u06D4\u0700-\u070D\u07F7-\u07F9\u0830-\u083E\u085E\u0964\u0965\u0970\u0AF0\u0DF4\u0E4F\u0E5A\u0E5B\u0F04-\u0F12\u0F14\u0F3A-\u0F3D\u0F85\u0FD0-\u0FD4\u0FD9\u0FDA\u104A-\u104F\u10FB\u1360-\u1368\u1400\u166D\u166E\u169B\u169C\u16EB-\u16ED\u1735\u1736\u17D4-\u17D6\u17D8-\u17DA\u1800-\u180A\u1944\u1945\u1A1E\u1A1F\u1AA0-\u1AA6\u1AA8-\u1AAD\u1B5A-\u1B60\u1BFC-\u1BFF\u1C3B-\u1C3F\u1C7E\u1C7F\u1CC0-\u1CC7\u1CD3\u2010-\u2027\u2030-\u2043\u2045-\u2051\u2053-\u205E\u207D\u207E\u208D\u208E\u2308-\u230B\u2329\u232A\u2768-\u2775\u27C5\u27C6\u27E6-\u27EF\u2983-\u2998\u29D8-\u29DB\u29FC\u29FD\u2CF9-\u2CFC\u2CFE\u2CFF\u2D70\u2E00-\u2E2E\u2E30-\u2E42\u3001-\u3003\u3008-\u3011\u3014-\u301F\u3030\u303D\u30A0\u30FB\uA4FE\uA4FF\uA60D-\uA60F\uA673\uA67E\uA6F2-\uA6F7\uA874-\uA877\uA8CE\uA8CF\uA8F8-\uA8FA\uA8FC\uA92E\uA92F\uA95F\uA9C1-\uA9CD\uA9DE\uA9DF\uAA5C-\uAA5F\uAADE\uAADF\uAAF0\uAAF1\uABEB\uFD3E\uFD3F\uFE10-\uFE19\uFE30-\uFE52\uFE54-\uFE61\uFE63\uFE68\uFE6A\uFE6B\uFF01-\uFF03\uFF05-\uFF0A\uFF0C-\uFF0F\uFF1A\uFF1B\uFF1F\uFF20\uFF3B-\uFF3D\uFF3F\uFF5B\uFF5D\uFF5F-\uFF65]/g
        refCode: /^([0-9]{8,13}|(?=.*[A-Za-z])[A-Za-z0-9]{4,16})$/
        refCodeV2: /^([0-9]{8,13}|(?=.*[A-Za-z])[A-Za-z0-9]{4,16}|LIMIT_[A-Za-z0-9]{8})|CPA_[A-Za-z0-9]{10,10}$/
        relativePath: /^\/$|^(\/[a-zA-Z]{2}.*)$/
        scopeSplit: /[\s,]+/g
        smsCode: /^\d{6}$/
        specialCode: /[\\[\]`~!#\\$\\^&\\*()=|{}':;,\\.<>\\/\\?~\uff01\uffe5\u2026\uff08\uff09\u2014\u3010\u3011\u2018\uff1b\uff1a\u201d\u201c\u3002\uff0c\u3001\uff1f]/
        trMobile: /^\d{10}$/
        userId: /^\d{8,}$/
*/

export const KEY_ACCOUNT_TOKEN = "key_account_token";
const REFRESH_ACCESS_TOKEN_LOCK = "refresh_access_token_lock";

const phoneReg = /^\+[0-9]{12,}$/;
const usPhoneReg = /^\+[0-9]{11}$/;
const emailReg = /^[a-zA-Z0-9_+-]+(?:\.[a-zA-Z0-9_+-]+)*@(?:[a-zA-Z0-9-_]+\.)+[a-zA-Z]+$/;
const passwordNumberReg = /[0-9]+/;
const passwordLowercaseReg = /[a-z]+/;
const passwordUppercaseReg = /[A-Z]+/;

const passwordCharReg = /[a-zA-Z]+/;
const passwordLengthReg = /^.{8,16}$/;
const verifyCodeReg = /^[0-9]{6}$/;

export const DEFAULT_AVATAR = "pionex_default.png";

const getLockTime = (time: number) => (time ? Math.ceil(time / 60) : "");

export default class AccountManager {
    static shared = new AccountManager();

    buAccount: BUAccount;

    public signExpiredModalShown = false;
    // 限制强刷频率，避免多个窗口来回刷
    public disableForceRefreshExpire = 0;
    private expiredRefreshToken = "";

    private cachedCollectionListMap?: Map<string, ExchangeTradePair[]>;

    constructor() {
        BUAccountConfig.DEBUG = process.env.DEBUG;
        if (Constants.isBeta) {
            BUAccountConfig.RSAPublicKey =
                "-----BEGIN PUBLIC KEY-----\n" +
                "MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAw/wM6xrhhRjgADZoWrOw\n" +
                "PYigzigeIjO4pX5ALiMyIxfBax0ebmNi5Utba3mK6Ezh/Xv8EsJFQu1d8cb/YjR3\n" +
                "dLJ55ynBiGI8tep/rRrHuqpCC1UYgczVqxPRoyRY46B4eBYTRDzIbmzB8vy7UH3M\n" +
                "psUKD9BfF7Zit1AvxnaGb44k3jc49gvoebIosJiSrNZUucLXFhvAKoJj4H3vVncK\n" +
                "6qYozx6QHt874Whm/UK9t9TJijiRDyMZYHoSD0YITw90iCrs4VMHai2/0H1kHERF\n" +
                "6D4SEYxTUysND0QWZSNwzA8WfbbOV9OnUU56QKOGGT8Ij3QVuLGlRNpWqv/P+z+K\n" +
                "fU6fhBl+yvfwR28DSvys2Gy6fqE4nVQ9PJpeLqvIuKYyaJsL1+cCCqK8RhIMEpOr\n" +
                "tzz+myqlmptyZK3q8nMTyorgBtg93SqPlr3r6CslQ5JEuzJ9HftpnqAJP+2W5lhw\n" +
                "hoJ82ELrSvvVZShWhFRgUtU/9uiM0AMXuvgJ78p3+2AzbaiFpELBkCvvRnu6DGsu\n" +
                "qp74VehSWjmj91mqM1P8ot0Ws5vl+GdOYg2EeD1JKpIic8OLZCwr/vzhLIVS2oj5\n" +
                "xKKVG08GjJ5ckcapZZMcM95ZERDgLx1B+S0jzOo5R4KT9pSh1ZVjYoZa7+zUKHXg\n" +
                "sUR5VzY38bKo8K0WWgzOSQcCAwEAAQ==\n" +
                "-----END PUBLIC KEY-----";
        }

        this.buAccount = new BUAccount(Constants.clientId, Constants.deviceId, Constants.accountServiceHost);
    }

    static isPhoneNumber(account) {
        return phoneReg.test(account);
    }

    static isUsPhoneNumber(account) {
        return usPhoneReg.test(account);
    }

    static isEmail(account) {
        return emailReg.test(account);
    }

    static isAccount(account) {
        return this.isEmail(account) || this.isPhoneNumber(account);
    }

    static getStakingPath() {
        return "/staking";
    }

    /**
     * @deprecated
     * @param password
     */
    static isPassword(password) {
        return (
            // passwordNumberReg.test(password) &&
            // passwordLowercaseReg.test(password) &&
            // passwordUppercaseReg.test(password) &&
            passwordLengthReg.test(password)
        );
    }

    /**
     * 2022年01月28日16:59:28
     * 应安全检测机构要求，更改密码校验复杂度逻辑
     * @param password
     */
    public static isValidPassword(password: string) {
        return passwordNumberReg.test(password) && (passwordLowercaseReg.test(password) || passwordUppercaseReg.test(password)) && passwordLengthReg.test(password);
    }

    //  校验数字字母 不包含大小写
    public static isValidPasswordV2(password: string) {
        return passwordNumberReg.test(password) && passwordCharReg.test(password) && passwordLengthReg.test(password);
    }

    static isVerifyCode(verifyCode) {
        return verifyCodeReg.test(verifyCode);
    }

    public static parseErrorTips(error: any, isSignIn = false) {
        if (!error) {
            return "Unknown error. Please try again later or contact the support team.";
        }
        if (error.code === -4209) {
            return $st("verification_code_error");
        } else if (error.code === -3210 || error.code === -3201) {
            if (isSignIn) {
                return $st("account_sms_code_error_limit");
            }
            return $st("verification_code_send_too_quick");
        } else if (error.code === -2003 || error.code === -24004) {
            // -24004 老账号注册，获取验证码时提示账号已存在
            return $st(PLATFORM.PIONEX ? "pionex_account_already_exists" : "account_already_exists");
        } else if (error.code === -3300) {
            return $st("acc_mail_cn_tip");
        } else if (error.code === -1203 || error.code === -1204) {
            return $st("sign_in_account_not_exists");
        } else if (error.code === -8209) {
            const un_used = error.data?.un_used;
            if (un_used === undefined) {
                //账号错误
                return $st("account_verify_error_1");
            } else {
                //账号正确
                const lockTime = Math.ceil((error.data?.incr_ex ?? 1800) / 60).toString();
                if (un_used === 0) {
                    //密码错误超过5次
                    return $st("account_locked_error_signIn", { time: lockTime });
                } else {
                    // 密码错误小于5次
                    return `${$st("account_verify_error_1")}${$st("account_chance_error_signIn", {
                        count: un_used,
                        time: lockTime,
                    })}`;
                }
            }
        } else if (error.code === -109907) {
            // 账号正确，密码输错5次被锁定以后，又输入了正确的账号和密码
            const lockTime = Math.ceil((error.data?.incr_ex ?? 1800) / 60).toString();
            return $st("account_locked_error_signIn", { time: lockTime });
        } else if (error.code === -5203) {
            // Google两步验证失败
            return $st("verification_code_error");
        } else if (error.code === -99999) {
            // 403
            return $st("account_too_many_requests");
        } else if (error.code === -2 || error.code === -3 || error.code === -9206) {
            // 会话超时
            return $st("account_session_timeout");
        } else if (error.code === -99998) {
            // network request time out
            return $st("network_error");
        } else if (error.message === "Network request failed") {
            return `${$st("network_error")}.`;
        } else if (error.code === -99900 && error.message.indexOf("Network Error") !== -1) {
            return `${$st("network_error")}..`;
        } else if (error.code === -4212) {
            const un_used = error.data?.un_used;
            if (un_used === undefined) {
                return $st("verification_code_error");
            } else if (un_used === 0) {
                return $st("account_locked_error_signIn", { time: getLockTime(error.data?.incr_ex ?? 1800) });
            } else {
                return `${$st("verification_code_error")} ${$st("account_chance_error_signIn", {
                    count: un_used,
                    time: getLockTime(error.data?.incr_ex ?? 1800),
                })}`;
            }
        } else if (error.code === -46210) {
            return `${$st("ga_unbind_too_quick_error")}`;
        } else if (error.code === -1009) {
            return `${$st("account_signin_error_stuck_temp")}`;
        } else if (error.code === 40920642) {
            return $st("credit_card_error_too_many_request");
        } else if (error.code === 40320708) {
            return "Sorry! Looks like this service is not available in your current location(IP).";
        } else if (error.code === 50021343) {
            return "The error of Service Provider";
        } else if (error.code === -109902) {
            return $st("withdraw_verify_code_sent_error");
        } else if (error.code === -109903) {
            const un_used = error.data?.un_used; // 剩余重试次数
            if (un_used === undefined) {
                return $st("code_verify_error");
            } else if (un_used === 0) {
                return $st("account_locked_error_signIn", { time: getLockTime(error.data?.incr_ex ?? 1800) });
            } else {
                return `${$st("code_verify_error")} ${$st("account_chance_error_signIn", {
                    count: un_used,
                    time: getLockTime(error.data?.incr_ex ?? 1800),
                })}`;
            }
        } else if (error.code === -100502) {
            return $st("ga_reset_question_had_submitted");
        } else if (error.code === -100501) {
            return $st("ga_reset_question_had_submitted");
        } else if (error.code === -32003) {
            return $st("account_user_not_exists");
        } else if (error.code === -3301) {
            return $st("account_signUp_bind_3301_error");
        } else if (error.code === -109907) {
            return `${$st("account_locked_error", { minutes: getLockTime(error.data?.incr_ex ?? 1800) })}`;
        } else if (error.code === -13201) {
            return $st("account_bind_2fa_before_unbind");
        } else if (error.code === -101030) {
            return $st("hyperlink_expired");
        } else if (error.code === -101110) {
            return $st("account_delete_kyc_error");
        } else if (error.code === -30207) {
            return $st("data_has_been_used", { day: "30" });
        } else if (error.code === -13200) {
            return $st("account_bind_phone_before_unbind_email");
        } else {
            if (!error.code) {
                console.log(error);
                return $st("trade_error_unknown");
            }
            return `${error.code}: ${error.message}`;
        }
    }

    static getApiKeyByExchange(accountInfo: AccountInfo, exchange: string): APIKeyInfo | undefined {
        if (!accountInfo.apiKeys) {
            return undefined;
        }
        for (let apiKey of accountInfo.apiKeys) {
            if (apiKey.exchange === exchange) {
                return apiKey;
            }
        }
        return undefined;
    }

    static getApiKeyByKeyId(accountInfo: AccountInfo, keyId: string): APIKeyInfo | undefined {
        if (!accountInfo.apiKeys) {
            return undefined;
        }
        for (let apiKey of accountInfo.apiKeys) {
            if (apiKey.key_id === keyId) {
                return apiKey;
            }
        }
        return undefined;
    }

    static selectApiKey(accountInfo: AccountInfo, exchange: string): APIKeyInfo | undefined {
        if (!accountInfo.apiKeys || accountInfo.apiKeys.length === 0) {
            return undefined;
        }
        let lastSelectedKeyId = LocalStorageUtil.tradeSelectedApiKeyId;
        if (lastSelectedKeyId) {
            let apiKey = AccountManager.getApiKeyByKeyId(accountInfo, lastSelectedKeyId);
            if (apiKey && apiKey.exchange === exchange) {
                return apiKey;
            }
        }
        let apiKey = AccountManager.getApiKeyByExchange(accountInfo, exchange);
        if (apiKey) {
            return apiKey;
        }
        return accountInfo.apiKeys[0];
    }

    static dispatch(type, data) {
        AppState.store.dispatch({ type: type, data: data });
    }

    static isSignPageNow() {
        return history.location.pathname.toLowerCase() === "/sign";
    }

    static toSign(
        from?: string,
        {
            params,
            ...opt
        }: {
            params?: Record<string, string>;
        } & Parameters<typeof AccountsRoute.sign>[1] = {},
    ) {
        if (["/account_update"].some((e) => window.location.pathname.indexOf(e) > -1)) {
            window.location.replace(AccountsRoute.sign());
        } else {
            window.location.replace(AccountsRoute.sign({ from, ...params }, opt));
        }
    }

    static toSignUp(
        from?: string,
        {
            params,
            ...opt
        }: {
            params?: Record<string, string>;
        } & Parameters<typeof AccountsRoute.signUp>[1] = {},
    ) {
        window.location.replace(AccountsRoute.signUp({ from, ...params }, opt));
    }

    static pionexInvite(referralCode: string) {
        return requestWithRetry(
            () => {
                return this.pionexInviteObservable(referralCode);
            },
            {
                name: "pionexInvite",
                maxRetryCount: 2,
            },
        )
            .pipe(
                catchError((error) => {
                    DataReporter.report(EventName.temp_bind_referral_code_failed, {
                        referral_code: referralCode,
                        error_msg: error?.message,
                        error_code: error?.code,
                    });
                    if ("code" in error) {
                        const code: number = error.code;
                        switch (code) {
                            case -99002: //没找到对应人
                                message.error($st("fill_referrals_error_not_found"));
                                break;
                            case -20002: //绑定过别人了
                                message.error($st("fill_referrals_error_had_bound"));
                                break;
                            case -30009: //已经进行开单了
                                message.error($st("fill_referrals_error_had_order"));
                                break;
                            case -30008: //过期了
                                message.error($st("fill_referrals_error_expired"));
                                break;
                            case -30007: //绑定循环了
                                message.error($st("fill_referrals_error_had_circle"));
                                break;
                            case 40000300:
                                message.error($st("kol_rebate_invite_bind_expired"));
                                break;
                            case -30016: // 不允许跨站
                                message.error($st("invite_rebate_code"));
                                break;
                            default:
                                message.error(JSON.stringify(error));
                                break;
                        }
                    }
                    throw error;
                }),
            )
            .subscribe(
                (data) => {
                    DataReporter.report(EventName.temp_bind_referral_code_success, { referral_code: referralCode });
                    window.localStorage.removeItem("key_referral_info");
                    if (process.env.DEBUG) {
                        console.log(data);
                    }
                },
                (error) => {
                    if (error?.response?.status === 400) {
                        window.localStorage.removeItem("key_referral_info");
                    }
                    if (process.env.DEBUG) {
                        console.log(error);
                    }
                },
            );
    }

    static pionexInviteObservable(referralCode: string): Observable<any> {
        return ObservableUtils.postV2(`${Constants.rebateHost}/api/bind/`, { sharecode: referralCode }, null, true);
    }

    public static queryMyInviter(): Observable<{ share_code: string } | null> {
        return requestWithRetry(
            () => {
                return ObservableUtils.get(`${Constants.tradeServiceHost}/share_grid/my_inviter`, null, null, true);
            },
            { name: `share_grid/my_inviter`, maxRetryCount: 2 },
        );
    }

    /**
     * 从sid中获取用户版本
     */
    public static getBusinessVersion(): Undefinable<string> {
        const sid = Cookies.get("sid");
        const businessVersion = sid?.split("|")[0];

        return businessVersion;
    }

    /**
     * 清理Cookie中的sid
     * @param checkSameHost 是否同域才允许清理
     */
    public static clearSIDCookie(checkSameHost?: boolean): void {
        const businessVersion = AccountManager.getBusinessVersion();
        if (checkSameHost && (!businessVersion || Constants.appDomain[businessVersion] !== window.location.host)) {
            // 需要同域，但是不同域
            return;
        }

        Cookies.remove("sid");
        Cookies.remove("sid", { domain: Constants.currentSubdomain });
    }

    /**
     * 是否空token，兼容历史{}和{access: null,refresh: null}情况
     */
    public static isEmptyToken() {
        let tokenStr = window.localStorage.getItem(KEY_ACCOUNT_TOKEN);

        if (!tokenStr) {
            return true;
        }

        let token;
        try {
            token = JSON.parse(tokenStr);
        } catch (error) {
            return true;
        }

        return !token.refresh;
    }

    /**
     * 同步sid和token信息，如果两者对应不上，需要清理相关脏数据
     */
    public static syncSIDAndToken() {
        if (process.env.NODE_ENV === "development") {
            return;
        }
        let emptyToken = AccountManager.isEmptyToken();

        if (emptyToken) {
            // 没有token，清除对应版本的sid
            AccountManager.clearSIDCookie(true);
        }

        const businessVersion = AccountManager.getBusinessVersion();

        if (!emptyToken && (!businessVersion || Constants.appDomain[businessVersion] !== window.location.host)) {
            // 有token，sid对应不上版本，清除token
            window.localStorage.removeItem(KEY_ACCOUNT_TOKEN);
        }
    }

    public init() {
        if (ReactNativeWebView.shared.isWebView()) {
            this.initFromWebview();
            return;
        }

        this.initFromLocal();
    }

    public initFromLocal() {
        if (ReactNativeWebView.shared.isWebView()) {
            return;
        }
        AccountManager.syncSIDAndToken(); // 需要先执行，可能会修改localStorage

        let tokenStr = window.localStorage.getItem(KEY_ACCOUNT_TOKEN);
        let token;
        if (tokenStr !== null && tokenStr !== "") {
            try {
                token = JSON.parse(tokenStr);
            } catch (error) {}
        } else {
            token = {
                access: null,
                refresh: null,
            };
        }
        this.buAccount.tokenStorage = new BUAccountTokenStorageImpl(token);

        if (token.refresh !== null) {
            this.getAccessToken().subscribe(
                () => {
                    let accountInfo = LocalStorageUtil.accountInfo;
                    if (accountInfo && accountInfo.userId && accountInfo.apiKeys) {
                        AccountManager.dispatch(TYPE_ACCOUNT_INIT, accountInfo);
                        this.refreshAccountInfo(true);
                    } else {
                        this.initFromServer();
                    }
                },
                (error) => {
                    AccountManager.dispatch(TYPE_ACCOUNT_INIT, {});
                },
            );
        } else {
            AccountManager.dispatch(TYPE_ACCOUNT_INIT, {});
        }
    }

    private initFromWebview() {
        if (ReactNativeWebView.shared.isWebView()) {
            const loginAsyncQuest = {
                eventName: "LoginStatusAsync",
                data: window.location.host,
            };
            const str = JSON.stringify(loginAsyncQuest);
            const calledWithAndroid = navigator.userAgent && navigator.userAgent.toLowerCase().indexOf("android") >= 0;
            const listener = (event: MessageEvent) => {
                const accountData = JSON.parse(event.data);
                if (accountData.token !== undefined && accountData.account !== undefined) {
                    //判断是否需要跳转对应站点
                    toBusinessWeb(accountData.account);
                    const { token, account } = accountData;
                    if (token?.refresh && account?.userId) {
                        // app 已登录
                        this.buAccount.tokenStorate = new BUAccountTokenStorageImpl({
                            refresh: token.refresh,
                            access: null,
                        });
                        // app的apiKey存储字段和web不一致，特殊处理一下
                        const apiKey = account.apiKey;
                        AccountManager.dispatch(TYPE_ACCOUNT_INIT, {
                            ...account,
                            apiKeys: [apiKey],
                            firstApiKey: apiKey,
                        });
                        this.refreshAccountInfo(true);
                    } else {
                        AccountManager.dispatch(TYPE_ACCOUNT_INIT, {});
                    }
                }
            };

            if (calledWithAndroid) {
                ///> android要使用document接收webview post过来的数据
                document.addEventListener("message", listener, false);
            } else {
                window.addEventListener("message", listener, false);
            }
            //@ts-ignore
            window.ReactNativeWebView.postMessage(str);
        }
    }

    private static getAccountInfo() {
        return of(window.localStorage.getItem("key_account_info")).pipe(
            map((data) => {
                if (data && data !== "") {
                    try {
                        return { ...JSON.parse(data), isInitialized: true };
                    } catch (error) {
                        return undefined;
                    }
                } else {
                    return undefined;
                }
            }),
        );
    }

    private initFromServer() {
        return this.requestAccountInfo().subscribe(
            (accountInfo) => {
                AccountManager.dispatch(TYPE_ACCOUNT_SIGN_IN, accountInfo);
                this.refreshAccountInfo();
            },
            (error) => {
                console.error(error);
                AccountManager.dispatch(TYPE_ACCOUNT_INIT, {});
                AccountManager.toSign(FromName.AM_init_er);
            },
        );
    }

    refreshAccountInfo(refreshAll = false) {
        if (refreshAll) {
            this.refreshInfo();
            this.refreshApiKey();
        }
        this.refreshOtp(true);
        this.refreshReferralCode();
        this.requestCollectList();
        this.updateFSARiskPlanStatus();
        this.updatePassLCE();
        this.getRebateLevelInfo();
    }

    private refreshInfo() {
        from(this.buAccount.getUserInfo()).subscribe(
            (user: BUUserInfo) => {
                AccountManager.dispatch(TYPE_ACCOUNT_REFRESH_INFO, {
                    userId: user.user_id,
                    uid: user.uid,
                    accounts: user.accounts,
                    nickname: user.nickname,
                    createTime: user.create_time,
                    avatar: user.head_path ? `${Constants.avatarHost}/${user.head_path}` : Constants.defaultAvatar,
                });

                if (ReactNativeWebView.shared.isWebView()) {
                    // 兼容老版本app bug，在刷新用户信息后判断一次用户版本
                    correctHostByVersion(user.from_app as BusinessVersion);
                }
            },
            (error) => {
                console.error(error);
            },
        );
    }

    public refreshApiKey() {
        this.getApiKeys().subscribe(
            (data) => {
                if (data) {
                    AccountManager.dispatch(TYPE_ACCOUNT_REFRESH_API_KEY, { apiKeys: data });
                }
            },
            (error) => {
                console.error(error);
            },
        );
    }

    private getApiKeys() {
        return requestWithRetry(
            () =>
                Network.post<APIKeyInfo>({
                    url: `${Constants.manageApiHost}/api_key/`,
                    data: { market: "spot" },
                    authType: AuthType.Access,
                }).pipe(
                    map((data) => {
                        return [data];
                    }),
                ),
            { maxRetryCount: 10 },
        );
    }

    private refreshOtp(needGuide: boolean) {
        this.queryOTP().subscribe(
            (data) => {
                AccountManager.dispatch(TYPE_ACCOUNT_REFRESH_OTP, { otpState: data.type });
            },
            (error) => {
                console.error(error);
            },
        );
    }

    private refreshReferralCode() {
        Network.get({
            url: `${Constants.tradeServiceHost}/share_grid/pionex_link`,
            authType: AuthType.Access,
        }).subscribe(
            (data) => {
                AccountManager.dispatch(TYPE_ACCOUNT_REFRESH_REFERRAL_CODE, { referralCode: data.share_code });
            },
            (error) => {
                console.error(error);
            },
        );
    }

    register(param: BUAccountAPISessionParam, sessionHandler): Observable<BUAccountAPISessionResult> {
        return from(this.buAccount.register(param, sessionHandler));
    }

    public signIn(param: BUAccountAPISessionParam, sessionHandler): Observable<BUAccountAPISessionResult> {
        return from(this.buAccount.login(param, sessionHandler));
    }

    signOut(toSignPage: boolean = true) {
        return from(this.buAccount.logout())
            .pipe(
                map((data) => {
                    AccountManager.dispatch(TYPE_ACCOUNT_SIGN_OUT, "");
                    this.cachedCollectionListMap = undefined;
                    DataSubject.next(DataSubject.TYPE_COLLECT_LIST_CHANGED, undefined);
                    return data;
                }),
            )
            .subscribe(
                () => {
                    AccountManager.clearSIDCookie();
                    if (toSignPage) {
                        AccountManager.toSign(FromName.AM_sign_out);
                    }
                },
                (error) => {
                    console.error(error);
                },
            );
    }

    queryOTP(): Observable<any> {
        return from(this.buAccount.queryOTP());
    }

    unbindOTP(otpCode: string) {
        return from(this.buAccount.unbindOTP(otpCode));
    }

    public unbindOTPDangerously(param: BUAccountAPISessionParam, sessionHandler: BUAccountAPISessionCallback): Observable<BUAccountAPISessionResult> {
        return from(this.buAccount.resetOtpV2(param, sessionHandler));
    }

    forgetPassword(param: BUAccountAPISessionParam, sessionHandler): Observable<BUAccountAPISessionResult> {
        return from(this.buAccount.forgetPassword(param, sessionHandler));
    }

    public bindAccount(param: BUAccountAPISessionParam, sessionHandler: BUAccountAPISessionCallback): Observable<BUAccountAPISessionResult> {
        return from(this.buAccount.accountBind(param, sessionHandler));
    }

    private getRefreshToken() {
        if (this.buAccount.tokenStorate) {
            const refreshToken = this.buAccount.tokenStorate!.getToken(BUAccountTokenUsage.refresh);
            if (refreshToken !== null) {
                return refreshToken.token;
            }
        }
        return undefined;
    }

    getAccessToken(force?: boolean) {
        if (this.expiredRefreshToken && this.expiredRefreshToken === this.getRefreshToken()) {
            return of(0).pipe(
                map(() => {
                    throw { code: ErrorCode.ACCESS_TOKEN_EXPIRED, message: "sign expired" };
                }),
            );
        }
        const realForce = !!force && Date.now() > this.disableForceRefreshExpire;
        return from(this.buAccount.getAccessToken(realForce))
            .pipe(
                map((token: BUAccountToken) => {
                    if (this.signExpiredModalShown) {
                        this.signExpiredModalShown = false;
                    }
                    if (realForce) {
                        this.disableForceRefreshExpire = Date.now() + 10000;
                    }
                    return token.token;
                }),
            )
            .pipe(
                catchError((err) => {
                    if (ErrorCodeUtils.isSignExpiredCode(err.code)) {
                        this.onSignExpired();
                    }
                    throw err;
                }),
            );
    }

    private onSignExpired() {
        const refreshToken = this.getRefreshToken();
        if (refreshToken) {
            this.expiredRefreshToken = refreshToken;
        }
        if (this.signExpiredModalShown) {
            return;
        }
        this.signExpiredModalShown = true;
        /**
         * Picology 站点被主站引入时，不弹窗提示过期
         */
        if (PLATFORM.PIONEX_COLOGY && FrameBridge.windowParent) {
            return;
        }
        AccountManager.dispatch(TYPE_ACCOUNT_SIGN_OUT, "");
        window.localStorage.removeItem(KEY_ACCOUNT_TOKEN);
        AccountManager.clearSIDCookie();
        TModal.warning({
            centered: true,
            maskClosable: false,
            title: $st("account_sign_expired"),
            okText: $st("button_ok"),
            zIndex: 1200,
            onOk: () => {
                history.replace("/sign");
                // FIXME: 若加入版本升级概念，将可以控制是否需要强制刷新用户浏览器
                // Force reload to update local static assets
                window.location.reload();
            },
        });
    }

    requestAccountInfo() {
        return zip(
            from(
                this.buAccount.getUserInfo() as Promise<
                    BUUserInfo & {
                        migrate_time?: number;
                        has_bound_third: boolean;
                    }
                >,
            ),
            this.getApiKeys(),
        ).pipe(
            map((data) => {
                const [user, apiKeys] = data;
                const accountInfo: AccountInfo = {
                    isInitialized: true,
                    userId: user.user_id,
                    uid: user.uid,
                    accounts: user.accounts,
                    nickname: user.nickname,
                    thirdType: user.has_bound_third,
                    avatar: `${Constants.avatarHost}/${user.head_path ? user.head_path : DEFAULT_AVATAR}`,
                    apiKeys: apiKeys,
                    createTime: user.create_time,
                    migrate_time: user.migrate_time,
                };
                return accountInfo;
            }),
        );
    }

    requestCollectList() {
        Network.post({
            url: `${Constants.tradeServiceHost}/user/collect/list`,
            authType: AuthType.Access,
        })
            .pipe(
                map((data) => {
                    let collectionListMap = new Map();
                    data.forEach((item) => {
                        let list = collectionListMap.get(item.exchange);
                        if (!list) {
                            list = [];
                            collectionListMap.set(item.exchange, list);
                        }
                        list.push(item);
                    });
                    return collectionListMap;
                }),
            )
            .subscribe(
                (data) => {
                    this.cachedCollectionListMap = data;
                    DataSubject.next(DataSubject.TYPE_COLLECT_LIST_CHANGED, data);
                },
                (error) => {
                    console.error(error);
                },
            );
    }
    getLoginStatus() {
        const accountInfo: AccountInfo = AppState.store.getState().accountInfo;
        return !!accountInfo.userId;
    }
    getCollectionList(exchange: string = ExchangeID.PIONEXV2) {
        const isLogin = this.getLoginStatus();
        //未登录时取本地存储
        if (!isLogin) {
            return LocalStorageUtils.getObject(LocalStorageKey.FAVOR_LIST, []) ?? [];
        }

        if (this.cachedCollectionListMap) {
            return this.cachedCollectionListMap.get(exchange) || [];
        } else {
            return [];
        }
    }

    changeCollection(pair: ExchangeTradePair | ExchangeTradePair[], isAdd) {
        const isLogin = this.getLoginStatus();
        let $sub;
        if (!isLogin) {
            const curFavorList = this.getCollectionList();
            const _list = Array.isArray(pair) ? pair : [pair];
            for (let newItem of _list) {
                const findIndex = curFavorList.findIndex((v) => v.base === newItem.base && v.quote === newItem.quote);
                if (isAdd) {
                    findIndex === -1 && curFavorList.unshift(newItem);
                } else {
                    findIndex !== -1 && curFavorList.splice(findIndex, 1);
                }
            }
            LocalStorageUtils.setObject(LocalStorageKey.FAVOR_LIST, curFavorList);
            $sub = of(curFavorList);
        } else {
            $sub = isAdd ? this.addCollection(pair) : this.removeCollection(pair);
        }
        return $sub.subscribe({
            next: () => {
                DataSubject.next(DataSubject.TYPE_COLLECT_LIST_CHANGED, this.cachedCollectionListMap);
            },
            error: () => {},
        });
    }

    addCollection(pair: ExchangeTradePair | ExchangeTradePair[]) {
        const _list = Array.isArray(pair) ? pair : [pair];
        return Network.post({
            url: `${Constants.collectApiHost}/add/`,
            data: { list: _list },
            authType: AuthType.Access,
        }).pipe(
            map((data) => {
                let collectionListMap = this.cachedCollectionListMap;
                if (!collectionListMap) {
                    return;
                }
                const exchange = _list?.[0].exchange || ExchangeID.PIONEXV2;
                let list = collectionListMap.get(exchange);
                if (!list) {
                    list = [];
                    collectionListMap.set(exchange, list);
                }
                list.unshift(..._list);
                return list;
            }),
        );
    }

    removeCollection(pair: ExchangeTradePair | ExchangeTradePair[]) {
        const _list = Array.isArray(pair) ? pair : [pair];
        return Network.post({
            url: `${Constants.collectApiHost}/cancel/`,
            data: { list: _list },
            authType: AuthType.Access,
        }).pipe(
            map((data) => {
                let collectionListMap = this.cachedCollectionListMap;
                if (!collectionListMap) {
                    return;
                }
                const exchange = _list?.[0].exchange || ExchangeID.PIONEXV2;
                let list = collectionListMap.get(exchange);
                if (!list) {
                    return;
                }
                let newList: ExchangeTradePair[] = [];
                for (let item of list) {
                    for (let pair of _list) {
                        if (item.base !== pair.base || item.quote !== pair.quote) {
                            newList.push(item);
                        }
                    }
                }

                collectionListMap.set(exchange, newList);
                return newList;
            }),
        );
    }

    public updateFSARiskPlanStatusObservable(): Observable<FSARiskPlanStatus> {
        const url = `${Constants.tradeServiceHost}/orders/insurance_fund/`;
        return requestWithRetry(
            () => {
                return ObservableUtils.getV2<FSARiskPlanStatus>(url, null, null, true);
            },
            { maxRetryCount: 2, name: "updateFSARiskPlanStatus" },
        ).pipe(
            tap((res) => {
                if (res) {
                    AccountManager.dispatch(TYPE_ACCOUNT_FSA_RISK_PLAN, { fsaRiskPlanStatus: res });
                }
            }),
        );
    }

    private updateFSARiskPlanStatus() {
        this.updateFSARiskPlanStatusObservable().subscribe(
            (res) => {},
            (error) => {
                console.log("getFSARiskPlan:", error);
            },
        );
        // const res: FSARiskPlanStatus = {
        //     is_joined: false,
        //     accumulative_investment: "1000",
        //     auto_join_investment: "10000",
        // }
        // AccountManager.dispatch(TYPE_ACCOUNT_FSA_RISK_PLAN, { fsaRiskPlanStatus: res });
    }

    public updatePassLCEObservable(): Observable<{ passed: boolean }> {
        const url = `${Constants.tradeServiceHost}/orders/risk_knowledge/`;
        return requestWithRetry(
            () => {
                return ObservableUtils.getV2<any>(url, null, null, true);
            },
            { maxRetryCount: 2, name: "get /orders/risk_knowledge/" },
        ).pipe(
            tap((res) => {
                if (res) {
                    AccountManager.dispatch(TYPE_ACCOUNT_PASS_LCE, { passLCExam: res.passed });
                }
            }),
        );
    }

    private updatePassLCE() {
        this.updatePassLCEObservable().subscribe(
            (res) => {},
            (error) => {
                console.log("getFSARiskPlan:", error);
            },
        );
    }

    public passLCEAction(): Observable<{ passed: boolean }> {
        const url = `${Constants.tradeServiceHost}/orders/risk_knowledge/`;
        return requestWithRetry(
            () => {
                return ObservableUtils.postV2<any>(url, null, null, true);
            },
            { maxRetryCount: 2, name: "pose /orders/risk_knowledge/" },
        ).pipe(
            tap((res) => {
                if (res) {
                    AccountManager.dispatch(TYPE_ACCOUNT_PASS_LCE, { passLCExam: res.passed });
                }
            }),
        );
    }

    // 需不需要参加考试
    public needEnterLCE(base: string): boolean {
        const accountInfo: AccountInfo = (AppState.store.getState() as any).accountInfo;
        const marketSymbol = ExchangeDataProvider.getPublicExchangeDataProvider(ExchangeID.PIONEXV2).api.getDefaultSymbols();
        const symbolInfo = marketSymbol.find((symbol) => symbol.baseDisplay === base);
        if (accountInfo.passLCExam) {
            return false;
        }

        if (!base.startsWith("BTC") && !base.startsWith("ETH") && !accountInfo.passLCExam && symbolInfo && symbolInfo.isLeverage) {
            showEnterLCEDialog();
            return true;
        }

        return false;
    }

    /**
     * @deprecated Please use {@link AccountService.sendVerifyCode}
     * @param action
     * @param receiveTypes
     * @param language
     * @param token
     */
    sendVerifyCode(action: VerifyCodeAction, receiveTypes: VerifyCodeType[], language: string, token?: string) {
        let requestTokenObservable: Observable<string>;
        if (token) {
            requestTokenObservable = of(token);
        } else {
            requestTokenObservable = AccountManager.shared.getAccessToken();
        }

        const params: Omit<SendVerifyCodeParams, "types"> = { usage: action, lang: language, is_resend: false };
        return requestTokenObservable.pipe(
            mergeMap((accessToken) => {
                return forkJoin(
                    receiveTypes.map((type) => {
                        return ObservableUtils.post(
                            `${getTradeDependencyInstance().getRunTimeHost().accountHost}/mfa/txn/v1/send_verify_code`,
                            {
                                type,
                                ...params,
                            },
                            {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        ).pipe(
                            map(({ code_id }) => {
                                if (type === VerifyCodeType.sms) {
                                    return {
                                        sms_code_id: code_id,
                                    };
                                } else if (type === VerifyCodeType.voice) {
                                    return {
                                        voice_code_id: code_id,
                                    };
                                } else {
                                    return {
                                        email_code_id: code_id,
                                    };
                                }
                            }),
                        );
                    }),
                ).pipe(
                    map((data) => {
                        return data.reduce((res, item) => {
                            return { ...res, ...item };
                        });
                    }),
                    map((data) => ({ data: { data } })), // 组装成老版本的返回值结构
                );
            }),
        );
    }

    public getRebateLevelInfo() {
        requestWithRetry(() =>
            Network.get({
                url: `${Constants.tradeServiceHost}/rebate/mine/`,
                authType: AuthType.Access,
            }),
        ).subscribe(
            (data) => {
                const result = {
                    currentLevel: {
                        level: data.rebate_level ? data.current_level?.level : RebateLevel.rebate0,
                        inviteNumber: data.current_level?.invite_number ?? 0,
                        icon: data.current_level?.icon ?? "",
                    },
                    nextLevel: data.next_level
                        ? {
                              level: data.next_level.level ?? RebateLevel.rebate20,
                              inviteNumber: data.next_level.invite_number ?? 2,
                              icon: data.next_level.level.icon ?? "",
                          }
                        : undefined,
                    currentInviteNumber: data.current_invite_number ?? 0,
                };
                AccountManager.dispatch(TYPE_ACCOUNT_REBATE_LEVEL, { rebateLevel: result });
            },
            (error) => {
                AccountManager.dispatch(TYPE_ACCOUNT_REBATE_LEVEL, {
                    rebateLevel: {
                        currentLevel: {
                            level: RebateLevel.rebate0,
                            inviteNumber: 0,
                            icon: "",
                        },
                        nextLevel: {
                            level: RebateLevel.rebate20,
                            inviteNumber: 2,
                            icon: "",
                        },
                        currentInviteNumber: 0,
                    },
                });
            },
        );
    }
}

class BUAccountTokenStorageImpl implements BUAccountTokenStorage {
    constructor(private token: { access: BUAccountToken | null; refresh: BUAccountToken | null }) {
        window.localStorage.setItem(KEY_ACCOUNT_TOKEN, JSON.stringify(token));
    }

    setToken(key: string, token: BUAccountToken | null): void {
        if (key === "access") {
            this.token.access = token;
        } else if (key === "refresh") {
            this.token.refresh = token;
        }
        window.localStorage.setItem(KEY_ACCOUNT_TOKEN, JSON.stringify(this.token));
    }

    getToken(key: "access" | "refresh"): BUAccountToken | null {
        if (key === "access") {
            return this.token.access;
        } else if (key === "refresh") {
            return this.token.refresh;
        }
        return null;
    }

    setAccessTokenRefreshLock(isLock: boolean) {
        const lock = {
            value: isLock,
            timestamp: Date.now(),
        };
        if (isLock) {
            window.localStorage.setItem(REFRESH_ACCESS_TOKEN_LOCK, JSON.stringify(lock));
            return;
        }

        setTimeout(() => {
            window.localStorage.setItem(REFRESH_ACCESS_TOKEN_LOCK, JSON.stringify(lock));
        }, 10000);
    }

    isAccessTokenRefreshLocked() {
        const lock = window.localStorage.getItem(REFRESH_ACCESS_TOKEN_LOCK);
        if (!lock) {
            return false;
        }
        try {
            const lockValue = JSON.parse(lock);
            // 设置锁的有效时间 30s
            if (lockValue.timestamp + 30000 > Date.now()) {
                return lockValue.value;
            } else {
                window.localStorage.removeItem(REFRESH_ACCESS_TOKEN_LOCK);
            }
        } catch (err) {
            return false;
        }
        return false;
    }
}
