import { PaymentMethodChannel } from "landings/V2/BuyCrypto/Buy/cards";
import { ST } from "commonUse/Locale";
import { BuyCryptoFailReason } from "landings/V2/BuyCrypto/types";
import { ACHPaymentMethod } from "landings/V2/BuyCrypto/Buy/cards/ACHPaymentMethod";

interface GetFailReason {
    ($st: ST, failReason: BuyCryptoFailReason): Undefinable<string>;
}

export namespace FailReasonUtils {
    const channelGetFailReason = new Map<PaymentMethodChannel, GetFailReason>([[PaymentMethodChannel.ACH, ACHPaymentMethod.getFailReason]]);

    /**
     * 提取错误信息提示文案
     * @param $st
     * @param fail_reason
     */
    export function extractFailReason($st: ST, fail_reason?: string): Undefinable<string> {
        if (!fail_reason) {
            return;
        }
        try {
            const failReason = JSON.parse(fail_reason) as BuyCryptoFailReason;

            let res: Undefinable<string> = undefined;
            for (const [channel, getFailReason] of channelGetFailReason) {
                if (!!failReason[channel] && !!getFailReason) {
                    // 返回了对应渠道的错误信息&&该渠道有对应的处理
                    res = getFailReason($st, failReason);
                }
            }
            return res || failReason.innerReason;
        } catch (e) {
            console.error(e);
        }

        return fail_reason;
    }
}
