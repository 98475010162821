import { Card } from "antd";
import cs from "landings/DepositWithdraw/withdraw.module.less";
import React from "react";
import { $st } from "utils/i18n/trans.comp";
import AddAddressModal from "landings/V2/Address/components/AddAddressModal";
import { useToggle } from "src/commonUse/tools";
import TReportLink, { TModal } from "src/components/v2/TinyComps";

export interface IWithdrawDataProps {
    coin: string;
    chain?: string;
    address: string;
    memo?: string;
    isFromAddressBook?: boolean;
}

const WithdrawSuccessContent = ({ coin, chain = "", address, memo, isFromAddressBook, closeParent }: IWithdrawDataProps & { closeParent() }) => {
    const [addNEwVisible, { setTrue: showAddNew, setFalse: hideAddNew }] = useToggle();
    const [addNewBtn, setAddNewBtn] = React.useState(!isFromAddressBook);
    const onAddNewSuccess = React.useCallback(() => {
        hideAddNew();
        setAddNewBtn(false);
    }, [hideAddNew]);
    const defaultValue = React.useMemo(
        () =>
            coin && address
                ? {
                      coin,
                      chain,
                      address,
                      memo,
                  }
                : undefined,
        [address, chain, coin, memo],
    );
    return (
        <Card className={cs.withdrawSuccess}>
            <div className={cs.successIcon}>
                <img src={require("images/v2/dw/withdraw_success.stc.png")} alt="circlestars" />
            </div>
            <h3 className={cs.successTitle}>{$st("withdraw_success_content")}</h3>
            <p className={cs.successTips}>{$st("withdraw_success_content1")}</p>
            <div style={{ textAlign: "center", marginTop: 30 }}>
                {addNewBtn && defaultValue && (
                    <TReportLink
                        className={cs.addNewBtn}
                        onClick={() => {
                            showAddNew();
                            closeParent();
                        }}
                    >
                        {$st("add_withdraw_address")}
                    </TReportLink>
                )}
            </div>
            {defaultValue && (
                <AddAddressModal coinType={coin} defaultValue={defaultValue} visible={addNEwVisible} onCancel={hideAddNew} onAddSuccess={onAddNewSuccess} isFromWithdrawSuccess />
            )}
        </Card>
    );
};

export const WithdrawSuccessModal = ({ visible, data = {} as any, onClose }: { visible: boolean; data?: IWithdrawDataProps; onClose() }) => {
    return (
        <TModal visible={visible} onCancel={onClose} onOk={onClose} cancelButtonProps={{ style: { display: "none" } }}>
            <WithdrawSuccessContent {...data} closeParent={onClose} />
        </TModal>
    );
};
