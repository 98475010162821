import {
    Action,
    BrowserHistoryBuildOptions,
    createBrowserHistory,
    History,
    Href,
    Location,
    LocationDescriptorObject,
    LocationListener,
    Path,
    TransitionPromptHook,
    UnregisterCallback,
} from "history";
import { getCurrentLocale, LANGUAGE_LOCALS_MAP, onLocaleChange } from "./i18n";
import { getComparedLocale } from "./i18n/cache";

type HistoryBuildOptions = BrowserHistoryBuildOptions & {
    disableLocaleBasename?: boolean; // 路由去掉语言
};

// 根据语言的切换来响应
export class LangHistory<HistoryLocationState = any> implements History<any> {
    private _originHistory: History<any>;
    private _proxyListenersMap: Map<LocationListener, UnregisterCallback> = new Map();
    public language = getComparedLocale().supportLocale;
    public basename?: string;
    /**
     * 基于当前basename和origin拼接当前路由访问的全连接（排除hash和SearchString）
     * @param path
     * @returns
     */
    public getFullUri(path: string) {
        return new URL(`${this.basename || ""}${path}`, window?.location?.origin).href;
    }
    constructor(private options: HistoryBuildOptions = {}) {
        this.basename = !options.disableLocaleBasename ? `/${this.language}` : undefined;
        this._originHistory = createBrowserHistory({
            ...this.options,
            basename: this.basename,
        });
        this._setHistoryMethods(this._originHistory);
        onLocaleChange((lang: LANGUAGE_LOCALS_MAP) => {
            !options.disableLocaleBasename && this._dynamicChangeBasename(this.language, lang);
        });
        // FIXME: 确定是否真的需要调用该方法。当前在SAP-build场景下调用，会导致根路径【/】生成的 [index.html] 页面中无法正确生成节点。
        // 在组件周期外进行路由初始化，避免路由列表导致的重定向问题
        !window.__PRERENDER_INJECTED?.$$isRenderServer && this._initialUrlBasename();
    }

    private _setHistoryMethods(history) {
        this.replace = history.replace;
        this.push = history.push;
        this.go = history.go;
        this.goBack = history.goBack;
        this.goForward = history.goForward;
        this.block = history.block;
    }

    private _dynamicChangeBasename(lastLang: LANGUAGE_LOCALS_MAP, newLang: LANGUAGE_LOCALS_MAP) {
        // 更新 language & basename
        this.language = newLang;
        this.basename = `/${newLang}`;
        const newHistory = createBrowserHistory({
            ...this.options,
            basename: this.basename,
        });
        this._setHistoryMethods(newHistory);
        this._proxyListenersMap.forEach((unregister, listener) => {
            // 取消之前的监听
            unregister();
            // 创建新的监听
            this._proxyListenersMap.set(listener, newHistory.listen(listener));
        });
        const {
            location: { pathname },
        } = this._originHistory;
        this._originHistory = newHistory;
        // 将之前的语言标识移除，重新将路由状态数据装载到新的路由器中
        newHistory.replace({ ...this._originHistory.location, pathname: pathname.replace(`/${lastLang}`, "") });
    }

    private _initialUrlBasename() {
        let {
            location: { pathname },
        } = this._originHistory;
        const { urlLocale } = getComparedLocale();
        if (urlLocale) {
            /**
             * 处理近似语种替换，如系统支持 en-US 时，其他en语种处理流程为： /en/trade/* => /trade/* => /en-US/trade/*
             */
            pathname = pathname.replace(new RegExp(`^/${urlLocale}/`), "/");
        }
        // reset url
        this._originHistory.replace({
            ...this._originHistory.location,
            pathname: pathname.replace(new RegExp(`^/${this.language}/`), "/"),
        });
    }

    get length(): number {
        return this._originHistory.length;
    }

    get action(): Action {
        return this._originHistory.action;
    }

    get location(): Location<HistoryLocationState> {
        return this._originHistory.location;
    }

    push(path: Path | LocationDescriptorObject<HistoryLocationState>, state?: HistoryLocationState): void {
        return this._originHistory.push(path as any, state);
    }

    replace(path: Path | LocationDescriptorObject<HistoryLocationState>, state?: HistoryLocationState): void {
        return this._originHistory.replace(path as any, state);
    }

    go(n: number): void {
        return this._originHistory.go(n);
    }

    goBack(): void {
        return this._originHistory.goBack();
    }

    goForward(): void {
        return this._originHistory.goForward();
    }

    block(prompt?: boolean | string | TransitionPromptHook): UnregisterCallback {
        return this._originHistory.block(prompt);
    }

    listen(listener: LocationListener): UnregisterCallback {
        const unregister = this._originHistory.listen(listener);
        this._proxyListenersMap.set(listener, unregister);
        return () => {
            this._proxyListenersMap.get(listener)!();
            this._proxyListenersMap.delete(listener);
        };
    }

    createHref(location: LocationDescriptorObject<HistoryLocationState>): Href {
        return this._originHistory.createHref(location);
    }

    get isPush() {
        return this._originHistory.action === "PUSH";
    }
}

export const disableLocaleBasename = process.env.DISABLE_LOCALE_BASENAME || PLATFORM.PIONEX_COLOGY;

export const history = new LangHistory({
    // forceRefresh: true
    disableLocaleBasename,
});
