import React from "react";
import cs from "./cs.m.less";
import { useTranslators } from "src/commonUse/Locale";
const AssetDisplay = ({
    title,
    balanceTotal,
    todayProfitDisplay,
    todayProfitColor,
}: {
    title: string;
    balanceTotal: string;
    todayProfitDisplay: string;
    todayProfitColor: string;
}) => {
    const { $st } = useTranslators();
    return (
        <div className={cs.assetDisplay}>
            <div className={cs.assetDisplayTitle}>{title}</div>
            <div className={cs.assetDisplaySum}>
                <div className={cs.assetDisplaySumTitle}>{$st("balance_seprate_sum_title")}</div>
                <div className={cs.assetDisplaySumData}>{balanceTotal}</div>
            </div>
            <div className={cs.assetDisplayToday}>
                <div className={cs.assetDisplaySumTitle}>{$st("balance_seprate_profit_title")}</div>
                <div className={cs.assetDisplaySumData} style={{ color: todayProfitColor }}>
                    {todayProfitDisplay}
                </div>
            </div>
        </div>
    );
};

export default AssetDisplay;
