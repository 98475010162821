import moment from "moment";

export type TimeRangeTypes = "last_year" | "current_year" | "customer";
const lastYear = moment().subtract(1, "year").format("YYYY");
const current_year = moment().format("YYYY");
export const timeRangeTypes = [
    {
        key: "last_year",
        text: "taxes_last_year",
        _text: `1/1/${lastYear} - 12/31/${lastYear}`,
    },
    {
        key: "current_year",
        text: "taxes_current_year",
        _text: `1/1/${current_year} - ${moment().format("MM/DD/YYYY")}`,
    },
    {
        key: "customer",
        text: "taxes_customer",
        _text: "",
    },
];

export const timeRangeValueS = {
    last_year: {
        start_time: moment(lastYear + "/1/1")
            .unix()
            .valueOf(),
        end_time: moment(lastYear + "/12/31")
            .unix()
            .valueOf(),
    },
    current_year: {
        start_time: moment(current_year + "/1/1")
            .unix()
            .valueOf(),
        end_time: moment().unix().valueOf(),
    },
};

export const taxesDateFormat = "MM/DD/YYYY";
