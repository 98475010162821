import { CDN } from "frames-react/config/config";
import CryptoJS from "crypto-js";

const SCRIPT_ID = "CHECKOUT_SDK";

export let CHECKOUT_SDK_INJECTED = false;

function injectScript(integrity: string) {
    if (!!document.getElementById(SCRIPT_ID)) {
        // 已经注入过
        return;
    }

    const script = document.createElement("script");
    script.id = SCRIPT_ID;
    script.type = "text/javascript";
    script.src = CDN;
    script.async = true;
    script.crossOrigin = "anonymous";
    script.integrity = integrity;
    script.onload = () => {
        CHECKOUT_SDK_INJECTED = true;
    };
    script.onerror = () => {
        console.error(`injectCheckoutSDK: load script error`);
    };
    const head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
}

/**
 * 动态注入checkout的SDK，实时计算integrity（SDK路径的资源可能变化）
 */
export async function injectCheckoutSDK() {
    const response = await fetch(CDN);

    if (response.status === 200) {
        const buffer = await response.arrayBuffer();
        const sha384 = `sha384-${CryptoJS.SHA384(CryptoJS.lib.WordArray.create(buffer)).toString(CryptoJS.enc.Base64)}`;
        injectScript(sha384);
    } else {
        console.error(`injectCheckoutSDK: download error ${response.status}`);
    }
}
