import { useOrderBalance } from "landings/Balance/components/BalancePie/useOrderBalance";
import * as React from "react";
import cs from "../../balance.module.less";
import { BalancePieView } from "./BalancePieView";
import useAccountInfo from "commonUse/useAccountInfo";
import useTheme from "commonUse/useTheme";
import { useBalanceAll } from "commonUse/useBalance";
import EmptyChart from "../EmptyChart";
import { NumberCommonUtils, SafeDecimal } from "trade_utils_lib";
import useExchangeRate from "commonUse/useExchangeRate";
import Constants from "utils/Constants";

export interface IBalanceProps {
    pieType: "Coins" | "Bots";
}

function BalancePie(props: IBalanceProps) {
    const { pieType } = props;
    const theme = useTheme();
    const accountInfo = useAccountInfo();
    const balance = useBalanceAll();
    const exchangeRate = useExchangeRate();
    const stakingBalance = React.useMemo(() => {
        if (!Constants.isSiteV2) return 0;
        const financeTotalDisplay = new SafeDecimal(NumberCommonUtils.toNormalPrice((balance?.total?.financeTotalInUSD || 0) * exchangeRate.exchangeRate)).toNumber();
        return financeTotalDisplay || 0;
    }, [balance?.total?.financeTotalInUSD, exchangeRate.exchangeRate]);
    const balanceTotalUSD = (balance?.total?.tradeTotalInUSD || 0) + (stakingBalance || 0);
    const [botTypeBalanceList, isBotsLoading] = useOrderBalance(accountInfo, balanceTotalUSD, stakingBalance);

    return (
        <div className={cs.pieBalanceWrapper}>
            <div className={cs.pieView}>
                <div className={cs.pieListItemLiner}>
                    {botTypeBalanceList.length > 0 || isBotsLoading ? (
                        <BalancePieView theme={theme} isLoading={isBotsLoading} dataSource={botTypeBalanceList} totalFiat={balanceTotalUSD} pieType={pieType} showLabel={false} />
                    ) : (
                        <EmptyChart />
                    )}
                </div>
            </div>
        </div>
    );
}

export default React.memo(BalancePie);
