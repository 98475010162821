import { EventParams, UserProperties } from "./types";
import Constants from "utils/Constants";

const gtagId = Constants.isBeta ? "G-6BKYD931E1" : "G-5Y9J2VG6X7";
const dataLayerName = "gtagDataLayer";

/**
 * 参考文档
 * gtag api: https://developers.google.com/tag-platform/gtagjs/reference
 * 配置项: https://developers.google.com/analytics/devguides/collection/ga4/reference/config
 * 指南: https://developers.google.com/analytics/devguides/collection/ga4
 */
export class GoogleTag {
    public static shared = new GoogleTag();

    private gtag = function (command: "js" | "config" | "set" | "get" | "event" | "consent", ...parameters: any[]) {
        window[dataLayerName].push(arguments);
    };

    private constructor() {
        window[dataLayerName] = window[dataLayerName] || [];
        window["gtag"] = this.gtag;
    }

    private loadScript() {
        const scriptId = `gtag-${gtagId}`;
        if (document.getElementById(scriptId)) {
            return;
        }
        const script = document.createElement("script");
        script.id = scriptId;
        script.type = "text/javascript";
        script.async = true;
        script.onload = () => {
            console.log("gtag onload");
        };
        script.onerror = () => {
            console.log("gtag onerror");
            const scr = document.getElementById(scriptId);
            if (scr) {
                scr.parentNode?.removeChild(scr);
            }
        };
        script.src = `https://www.googletagmanager.com/gtag/js?l=${dataLayerName}`;
        const head = document.getElementsByTagName("head")[0];
        head.appendChild(script);
    }

    public init() {
        this.gtag("js", new Date());
        this.gtag("config", gtagId, {
            send_page_view: false,
        });
        this.loadScript();
    }

    public setUserId(user_id?: string | null) {
        if (user_id) {
            this.gtag("set", "user_id", user_id);
        }
    }

    public setUserProperties(user_properties: UserProperties) {
        this.gtag("set", "user_properties", user_properties);
    }

    public report(name: string, params?: EventParams) {
        this.gtag("event", name, params);
    }
}
