import tradingEnUSLocal from "@pionex-web-kit/trading-components/es/utils/i18n/source/locale.en";
import enUSLocal from "./locale.en-US";
/**
 * Confiuration
 */

// 语言字典
export enum LANGUAGE_LOCALS_MAP {
    // 用于快速调用使用语言local字符串
    zhCN = "zh-CN",
    enUS = "en-US",
    zhTW = "zh-TW",
    ko = "ko",
    ar = "ar",
    fr = "fr",
    tr = "tr",
    id = "id",
    de = "de",
    ja = "ja",
    pt = "pt",
    es = "es",
    th = "th",
    ru = "ru",
    // 越南语
    vi = "vi",
}

export const LANGUAGE_LOCALS_LABEL_LIST: {
    lang: LANGUAGE_LOCALS_MAP;
    reportNum: number;
    name: string;
    antd: string;
    tradingView: string;
    isDefault?: boolean;
    support: boolean;
}[] = [
    {
        lang: LANGUAGE_LOCALS_MAP.enUS,
        reportNum: 2,
        name: "English",
        antd: "en_US",
        tradingView: "en",
        isDefault: true,
        support: true,
    },
    {
        lang: LANGUAGE_LOCALS_MAP.zhCN,
        reportNum: 2,
        name: "简体中文",
        antd: "zh_CN",
        tradingView: "zh",
        support: !PLATFORM.PIONEX_US_LIKE,
    },
    {
        lang: LANGUAGE_LOCALS_MAP.zhTW,
        reportNum: 2,
        name: "繁體中文",
        antd: "zh_TW",
        tradingView: "zh_TW",
        support: !PLATFORM.PIONEX_US_LIKE,
    },
    {
        lang: LANGUAGE_LOCALS_MAP.ko,
        reportNum: 2,
        name: "한국어",
        antd: "ko_KR",
        tradingView: "ko",
        support: !PLATFORM.PIONEX_US_LIKE,
    },
    {
        lang: LANGUAGE_LOCALS_MAP.id,
        reportNum: 2,
        name: "bahasa",
        antd: "en_US",
        tradingView: "en",
        support: !PLATFORM.PIONEX_US_LIKE,
    },
    {
        lang: LANGUAGE_LOCALS_MAP.de,
        reportNum: 2,
        name: "Deutsch",
        // FIXME: 由于Antd组件以下语言均展示为韩语，因此全部降级指定为显示英语，需要修复。
        antd: "en_US", // "de_DE",
        tradingView: "de",
        support: !PLATFORM.PIONEX_US_LIKE,
    },
    {
        lang: LANGUAGE_LOCALS_MAP.ja,
        reportNum: 2,
        name: "日本語",
        antd: "en_US", // "ja_JP",
        tradingView: "ja",
        support: !PLATFORM.PIONEX_US_LIKE,
    },
    {
        lang: LANGUAGE_LOCALS_MAP.pt,
        reportNum: 2,
        name: "Português",
        antd: "en_US", // "pt_PT",
        tradingView: "pt",
        support: !PLATFORM.PIONEX_US_LIKE,
    },
    {
        lang: LANGUAGE_LOCALS_MAP.es,
        reportNum: 2,
        name: "español",
        antd: "en_US", // "es_ES",
        tradingView: "es",
        support: !PLATFORM.PIONEX_US_LIKE,
    },
    {
        lang: LANGUAGE_LOCALS_MAP.th,
        reportNum: 2,
        name: "ภาษาไทย",
        antd: "en_US", //"th_TH",
        tradingView: "th_TH",
        support: !PLATFORM.PIONEX_US_LIKE,
    },
    {
        lang: LANGUAGE_LOCALS_MAP.ru,
        reportNum: 2,
        name: "Pусский",
        antd: "en_US", // "ru_RU",
        tradingView: "ru",
        support: !PLATFORM.PIONEX_US_LIKE,
    },
    {
        lang: LANGUAGE_LOCALS_MAP.vi,
        reportNum: 2,
        name: "Tiếng Việt",
        antd: "en_US", // "vi_VN",
        tradingView: "vi",
        support: !PLATFORM.PIONEX_US_LIKE,
    },
    {
        lang: LANGUAGE_LOCALS_MAP.ar,
        reportNum: 2,
        name: "العربية",
        antd: "ar_EG", // "ar",
        tradingView: "ar",
        support: true,
    },
    {
        lang: LANGUAGE_LOCALS_MAP.fr,
        reportNum: 2,
        name: "Français",
        antd: "fr_FR", // "fr",
        tradingView: "fr",
        support: true,
    },
    {
        lang: LANGUAGE_LOCALS_MAP.tr,
        reportNum: 2,
        name: "Türkçe",
        antd: "tr_TR", // "tr",
        tradingView: "tr",
        support: true,
    },
];

// JS Cache
export const LocalsMessages = {
    [LANGUAGE_LOCALS_MAP.enUS]: { ...tradingEnUSLocal, ...enUSLocal },
};

// 受支持的语言列表，用于快速进行语言支持查询
export const [
    // 当前系统默认的语言
    DEFAULT_LANGUAGE,
    // 当前系统支持的语言
    SUPPORTED_LANGUAGES_LOCAL,
    // 主要用于图表的语言支持，配置对应来源于Tradingview配置文档
    // 中国语（台湾）	zh_TW
    // 中国语	zh
    // 荷兰语（荷兰）	nl_NL
    // 英语	en
    // 法语	fr
    // 德语 (德国)	de_DE
    // 希腊语	el
    // 意大利语	it
    // 阿拉伯语 ar
    // 土耳其语 tr
    // 法语 fr
    // 日语	ja
    // 韩语	ko
    // 波斯语(伊朗)	fa_IR
    // 葡萄牙语	pt
    // 俄语	ru
    // 西班牙语	es
    // 泰语(泰国)	th_TH
    // 土耳其语	tr
    // 越南语	vi
    TRADING_VIEW_LOCALE_MAP,
    // AntD的国际化文件字典
    ANTD_LOCALE_MAP,
] = LANGUAGE_LOCALS_LABEL_LIST.reduce(
    ([def, supported, trading, antdLang], { lang, support, antd, tradingView, isDefault }) => {
        if (support) {
            supported.push(lang);
            trading[lang] = tradingView;
            antdLang[lang] = antd;
            // 默认支持的语言标记
            if (isDefault) {
                def = lang;
            }
        }
        return [def, supported, trading, antdLang];
    },
    [LANGUAGE_LOCALS_MAP.enUS, [] as LANGUAGE_LOCALS_MAP[], {} as { [k in LANGUAGE_LOCALS_MAP]: string }, {} as { [k in LANGUAGE_LOCALS_MAP]: string }],
);

// 获取图表的语言
export const getTradingViewLocale = (locale: string) => {
    return TRADING_VIEW_LOCALE_MAP[locale] || TRADING_VIEW_LOCALE_MAP[LANGUAGE_LOCALS_MAP.enUS];
};
