import { usePersistFn } from "@gcer/react-air";
import useAsyncFn, { AsyncFnReturn } from "commonUse/useAsyncFn";
import { useState, useEffect } from "react";
import { Observable } from "rxjs";
import { concatMap, debounceTime, distinctUntilChanged, flatMap, map, take } from "rxjs/operators";
import { TradeAPI, ExchangeTradePair, ExchangeOrder, ExchangeOrderType, ExchangeOrderListInfo, QueryBotOrdersParamV4 } from "TradeAPILib";
import { getTradeDependencyInstance, PRODUCT_TYPE, requestWithRetry } from "trade_utils_lib";

export enum OrderListStatus {
    running = "running",
    history = "history",
}

export enum OrderPairType {
    all = 0,
    current = 1,
    other = 2,
}

export type OrderTypeForFilter = ExchangeOrderType[] | "all";

export function botOrderDataRequest(
    tradeApi: TradeAPI,
    allExchange: boolean,
    currentOrderStatus: OrderListStatus,
    symbolPair?: ExchangeTradePair,
    nextPageToken?: string,
    orderType?: OrderTypeForFilter,
): Observable<ExchangeOrderListInfo> {
    const params = {} as QueryBotOrdersParamV4;
    if (currentOrderStatus === OrderListStatus.running) {
        params.status = "running";
    } else {
        params.status = "finished";
    }
    if (!allExchange) {
        params.exchange = tradeApi.market;
    }

    if (symbolPair) {
        params.pair = symbolPair;
    }

    if (nextPageToken) {
        params.pageToken = nextPageToken;
    }
    if (orderType !== undefined && orderType !== "all") {
        params.orderTypes = orderType;
    }
    return requestWithRetry(() => tradeApi.queryAllBotOrdersV4(params), { maxRetryCount: 3, name: "queryAllBotOrdersV4" });
}

const arbitrageTypes = [ExchangeOrderType.cm_fsa];

export function getWebSocketMarketBotOrder(OrderWebsocketObserve: (market: string) => Observable<ExchangeOrder>, market: string) {
    return OrderWebsocketObserve(market).pipe(
        distinctUntilChanged((obj1, obj2) => {
            if (obj1.id === obj2.id) {
                if (obj1.orderType === obj2.orderType) {
                    switch (obj2.orderType) {
                        case ExchangeOrderType.grid:
                            return (
                                obj1.state === obj2.state &&
                                obj1.exchangeGridOrder!.totalInvest === obj2.exchangeGridOrder!.totalInvest &&
                                obj1.exchangeGridOrder!.transactionAmount === obj2.exchangeGridOrder!.transactionAmount &&
                                obj1.closeTimestamp === obj2.closeTimestamp &&
                                obj1.exchangeGridOrder!.latestBaseAmount === obj2.exchangeGridOrder!.latestBaseAmount &&
                                obj1.exchangeGridOrder!.latestQuoteAmount === obj2.exchangeGridOrder!.latestQuoteAmount
                            );
                        case ExchangeOrderType.ig:
                            return (
                                obj1.state === obj2.state &&
                                obj1.innerStatus === obj2.innerStatus && //开单loading使用
                                obj1.exchangeIGOrder!.totalInvest === obj2.exchangeIGOrder!.totalInvest &&
                                obj1.exchangeIGOrder!.transactionAmount === obj2.exchangeIGOrder!.transactionAmount &&
                                obj1.closeTimestamp === obj2.closeTimestamp &&
                                obj1.exchangeIGOrder!.latestBaseAmount === obj2.exchangeIGOrder!.latestBaseAmount &&
                                obj1.exchangeIGOrder!.latestQuoteAmount === obj2.exchangeIGOrder!.latestQuoteAmount
                            );
                        case ExchangeOrderType.gridV3:
                            return (
                                obj1.state === obj2.state &&
                                obj1.exchangeGridV3Order!.totalInvest === obj2.exchangeGridV3Order!.totalInvest &&
                                obj1.exchangeGridV3Order!.trx_history === obj2.exchangeGridV3Order!.trx_history &&
                                obj1.closeTimestamp === obj2.closeTimestamp &&
                                obj1.exchangeGridV3Order!.triggerTime === obj2.exchangeGridV3Order!.triggerTime &&
                                obj1.innerStatus === obj2.innerStatus &&
                                obj1.exchangeGridV3Order!.baseAmount === obj2.exchangeGridV3Order!.baseAmount &&
                                obj1.exchangeGridV3Order!.quoteAmount === obj2.exchangeGridV3Order!.quoteAmount &&
                                //下列为提取利润相关
                                obj1.exchangeGridV3Order!.profitWithdrawn === obj2.exchangeGridV3Order!.profitWithdrawn &&
                                obj1.exchangeGridV3Order!.latestQuoteAmount === obj2.exchangeGridV3Order!.latestQuoteAmount &&
                                obj1.exchangeGridV3Order!.baseFeeReserve === obj2.exchangeGridV3Order!.baseFeeReserve &&
                                obj1.exchangeGridV3Order!.quoteFeeReserve === obj2.exchangeGridV3Order!.quoteFeeReserve
                            );
                        case ExchangeOrderType.gridPro:
                            return (
                                obj1.state === obj2.state &&
                                obj1.exchangeGridProOrder!.totalInvest === obj2.exchangeGridProOrder!.totalInvest &&
                                obj1.exchangeGridProOrder!.trx_history === obj2.exchangeGridProOrder!.trx_history &&
                                obj1.closeTimestamp === obj2.closeTimestamp &&
                                obj1.exchangeGridProOrder!.triggerTime === obj2.exchangeGridProOrder!.triggerTime &&
                                obj1.innerStatus === obj2.innerStatus &&
                                obj1.exchangeGridProOrder!.baseAmount === obj2.exchangeGridProOrder!.baseAmount &&
                                obj1.exchangeGridProOrder!.quoteAmount === obj2.exchangeGridProOrder!.quoteAmount &&
                                //下列为提取利润相关
                                obj1.exchangeGridProOrder!.profitWithdrawn === obj2.exchangeGridProOrder!.profitWithdrawn &&
                                obj1.exchangeGridProOrder!.latestQuoteAmount === obj2.exchangeGridProOrder!.latestQuoteAmount &&
                                obj1.exchangeGridProOrder!.baseFeeReserve === obj2.exchangeGridProOrder!.baseFeeReserve &&
                                obj1.exchangeGridProOrder!.quoteFeeReserve === obj2.exchangeGridProOrder!.quoteFeeReserve
                            );
                        case ExchangeOrderType.gridClassic:
                            return (
                                obj1.state === obj2.state &&
                                obj1.exchangeGridClassicOrder!.totalInvest === obj2.exchangeGridClassicOrder!.totalInvest &&
                                obj1.exchangeGridClassicOrder!.trx_history === obj2.exchangeGridClassicOrder!.trx_history &&
                                obj1.closeTimestamp === obj2.closeTimestamp &&
                                obj1.exchangeGridClassicOrder!.triggerTime === obj2.exchangeGridClassicOrder!.triggerTime &&
                                obj1.innerStatus === obj2.innerStatus &&
                                obj1.exchangeGridClassicOrder!.baseAmount === obj2.exchangeGridClassicOrder!.baseAmount &&
                                obj1.exchangeGridClassicOrder!.quoteAmount === obj2.exchangeGridClassicOrder!.quoteAmount
                            );
                        case ExchangeOrderType.gridLeverage:
                            return (
                                obj1.state === obj2.state &&
                                obj1.exchangeLeverageGridOrder!.totalInvest === obj2.exchangeLeverageGridOrder!.totalInvest &&
                                obj1.exchangeLeverageGridOrder!.trx_history === obj2.exchangeLeverageGridOrder!.trx_history &&
                                obj1.closeTimestamp === obj2.closeTimestamp &&
                                obj1.exchangeLeverageGridOrder!.liquidatedTriggered === obj2.exchangeLeverageGridOrder!.liquidatedTriggered &&
                                obj1.exchangeLeverageGridOrder!.triggerTime === obj2.exchangeLeverageGridOrder!.triggerTime &&
                                obj1.exchangeLeverageGridOrder!.liquidatedPrice === obj2.exchangeLeverageGridOrder!.liquidatedPrice &&
                                obj1.innerStatus === obj2.innerStatus &&
                                obj1.exchangeLeverageGridOrder!.baseAmount === obj2.exchangeLeverageGridOrder!.baseAmount &&
                                obj1.exchangeLeverageGridOrder!.quoteAmount === obj2.exchangeLeverageGridOrder!.quoteAmount &&
                                obj1.exchangeLeverageGridOrder!.loanCoin === obj2.exchangeLeverageGridOrder!.loanCoin
                            );
                        case ExchangeOrderType.gridLeverageShort:
                            return (
                                obj1.state === obj2.state &&
                                obj1.exchangeLeverageShortGridOrder!.totalInvest === obj2.exchangeLeverageShortGridOrder!.totalInvest &&
                                obj1.exchangeLeverageShortGridOrder!.trx_history === obj2.exchangeLeverageShortGridOrder!.trx_history &&
                                obj1.closeTimestamp === obj2.closeTimestamp &&
                                obj1.exchangeLeverageShortGridOrder!.liquidatedTriggered === obj2.exchangeLeverageShortGridOrder!.liquidatedTriggered &&
                                obj1.exchangeLeverageShortGridOrder!.triggerTime === obj2.exchangeLeverageShortGridOrder!.triggerTime &&
                                obj1.exchangeLeverageShortGridOrder!.liquidatedPrice === obj2.exchangeLeverageShortGridOrder!.liquidatedPrice &&
                                obj1.innerStatus === obj2.innerStatus &&
                                obj1.exchangeLeverageShortGridOrder!.baseAmount === obj2.exchangeLeverageShortGridOrder!.baseAmount &&
                                obj1.exchangeLeverageShortGridOrder!.quoteAmount === obj2.exchangeLeverageShortGridOrder!.quoteAmount &&
                                obj1.exchangeLeverageShortGridOrder!.loanCoin === obj2.exchangeLeverageShortGridOrder!.loanCoin
                            );
                        case ExchangeOrderType.gridFuture:
                            return (
                                obj1.state === obj2.state &&
                                obj1.exchangeFutureGridOrder!.totalInvest === obj2.exchangeFutureGridOrder!.totalInvest &&
                                obj1.exchangeFutureGridOrder!.trx_history === obj2.exchangeFutureGridOrder!.trx_history &&
                                obj1.closeTimestamp === obj2.closeTimestamp &&
                                obj1.exchangeFutureGridOrder!.liquidatedTriggered === obj2.exchangeFutureGridOrder!.liquidatedTriggered &&
                                obj1.exchangeFutureGridOrder!.triggerTime === obj2.exchangeFutureGridOrder!.triggerTime &&
                                obj1.exchangeFutureGridOrder!.liquidatedPrice === obj2.exchangeFutureGridOrder!.liquidatedPrice &&
                                obj1.innerStatus === obj2.innerStatus &&
                                obj1.exchangeFutureGridOrder!.baseAmount === obj2.exchangeFutureGridOrder!.baseAmount &&
                                obj1.exchangeFutureGridOrder!.quoteAmount === obj2.exchangeFutureGridOrder!.quoteAmount
                            );
                        case ExchangeOrderType.gridLoan:
                            return (
                                obj1.state === obj2.state &&
                                obj1.exchangeLoanGridOrder!.totalInvest === obj2.exchangeLoanGridOrder!.totalInvest &&
                                obj1.exchangeLoanGridOrder!.trx_history === obj2.exchangeLoanGridOrder!.trx_history &&
                                obj1.closeTimestamp === obj2.closeTimestamp &&
                                obj1.exchangeLoanGridOrder!.liquidatedTriggered === obj2.exchangeLoanGridOrder!.liquidatedTriggered &&
                                obj1.exchangeLoanGridOrder!.triggerTime === obj2.exchangeLoanGridOrder!.triggerTime &&
                                obj1.exchangeLoanGridOrder!.liquidatedPrice === obj2.exchangeLoanGridOrder!.liquidatedPrice &&
                                obj1.innerStatus === obj2.innerStatus &&
                                obj1.exchangeLoanGridOrder!.baseAmount === obj2.exchangeLoanGridOrder!.baseAmount &&
                                obj1.exchangeLoanGridOrder!.quoteAmount === obj2.exchangeLoanGridOrder!.quoteAmount &&
                                obj1.exchangeLoanGridOrder!.loanCoin === obj2.exchangeLoanGridOrder!.loanCoin
                            );
                        case ExchangeOrderType.gridShort:
                            return (
                                obj1.state === obj2.state &&
                                obj1.exchangeShortGridOrder!.totalInvest === obj2.exchangeShortGridOrder!.totalInvest &&
                                obj1.exchangeShortGridOrder!.trx_history === obj2.exchangeShortGridOrder!.trx_history &&
                                obj1.closeTimestamp === obj2.closeTimestamp &&
                                obj1.exchangeShortGridOrder!.triggerTime === obj2.exchangeShortGridOrder!.triggerTime &&
                                obj1.innerStatus === obj2.innerStatus &&
                                obj1.exchangeShortGridOrder!.baseAmount === obj2.exchangeShortGridOrder!.baseAmount &&
                                obj1.exchangeShortGridOrder!.quoteAmount === obj2.exchangeShortGridOrder!.quoteAmount &&
                                //下列为提取利润相关
                                obj1.exchangeShortGridOrder!.profitWithdrawn === obj2.exchangeShortGridOrder!.profitWithdrawn &&
                                obj1.exchangeShortGridOrder!.latestQuoteAmount === obj2.exchangeShortGridOrder!.latestQuoteAmount &&
                                obj1.exchangeShortGridOrder!.baseFeeReserve === obj2.exchangeShortGridOrder!.baseFeeReserve &&
                                obj1.exchangeShortGridOrder!.quoteFeeReserve === obj2.exchangeShortGridOrder!.quoteFeeReserve
                            );

                        default:
                            return false;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }),
    );
}
// TODO: 拆分独立不同类型订单的数据处理
export function useBotOrderWebSocket(
    OrderWebsocketObserve: (market: string) => Observable<ExchangeOrder>,
    market: string,
    allExchange: boolean,
    orderType?: OrderTypeForFilter,
): ExchangeOrder | undefined {
    const [order, setOrder] = useState<ExchangeOrder>(undefined as unknown as ExchangeOrder);
    useEffect(() => {
        let tempWebsocket;
        tempWebsocket = OrderWebsocketObserve(market)
            .pipe(
                distinctUntilChanged((obj1, obj2) => {
                    if (obj1.id === obj2.id) {
                        if (obj1.orderType === obj2.orderType) {
                            switch (obj2.orderType) {
                                case ExchangeOrderType.grid:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeGridOrder!.totalInvest === obj2.exchangeGridOrder!.totalInvest &&
                                        obj1.exchangeGridOrder!.transactionAmount === obj2.exchangeGridOrder!.transactionAmount &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeGridOrder!.latestBaseAmount === obj2.exchangeGridOrder!.latestBaseAmount &&
                                        obj1.exchangeGridOrder!.latestQuoteAmount === obj2.exchangeGridOrder!.latestQuoteAmount
                                    );
                                case ExchangeOrderType.ig:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.innerStatus === obj2.innerStatus && //开单loading使用
                                        obj1.exchangeIGOrder!.totalInvest === obj2.exchangeIGOrder!.totalInvest &&
                                        obj1.exchangeIGOrder!.transactionAmount === obj2.exchangeIGOrder!.transactionAmount &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeIGOrder!.latestBaseAmount === obj2.exchangeIGOrder!.latestBaseAmount &&
                                        obj1.exchangeIGOrder!.latestQuoteAmount === obj2.exchangeIGOrder!.latestQuoteAmount
                                    );
                                case ExchangeOrderType.gridV3:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeGridV3Order!.totalInvest === obj2.exchangeGridV3Order!.totalInvest &&
                                        obj1.exchangeGridV3Order!.trx_history === obj2.exchangeGridV3Order!.trx_history &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeGridV3Order!.triggerTime === obj2.exchangeGridV3Order!.triggerTime &&
                                        obj1.innerStatus === obj2.innerStatus &&
                                        obj1.exchangeGridV3Order!.baseAmount === obj2.exchangeGridV3Order!.baseAmount &&
                                        obj1.exchangeGridV3Order!.quoteAmount === obj2.exchangeGridV3Order!.quoteAmount &&
                                        //下列为提取利润相关
                                        obj1.exchangeGridV3Order!.profitWithdrawn === obj2.exchangeGridV3Order!.profitWithdrawn &&
                                        obj1.exchangeGridV3Order!.latestQuoteAmount === obj2.exchangeGridV3Order!.latestQuoteAmount &&
                                        obj1.exchangeGridV3Order!.baseFeeReserve === obj2.exchangeGridV3Order!.baseFeeReserve &&
                                        obj1.exchangeGridV3Order!.quoteFeeReserve === obj2.exchangeGridV3Order!.quoteFeeReserve
                                    );
                                case ExchangeOrderType.gridPro:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeGridProOrder!.totalInvest === obj2.exchangeGridProOrder!.totalInvest &&
                                        obj1.exchangeGridProOrder!.trx_history === obj2.exchangeGridProOrder!.trx_history &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeGridProOrder!.triggerTime === obj2.exchangeGridProOrder!.triggerTime &&
                                        obj1.innerStatus === obj2.innerStatus &&
                                        obj1.exchangeGridProOrder!.baseAmount === obj2.exchangeGridProOrder!.baseAmount &&
                                        obj1.exchangeGridProOrder!.quoteAmount === obj2.exchangeGridProOrder!.quoteAmount &&
                                        //下列为提取利润相关
                                        obj1.exchangeGridProOrder!.profitWithdrawn === obj2.exchangeGridProOrder!.profitWithdrawn &&
                                        obj1.exchangeGridProOrder!.latestQuoteAmount === obj2.exchangeGridProOrder!.latestQuoteAmount &&
                                        obj1.exchangeGridProOrder!.baseFeeReserve === obj2.exchangeGridProOrder!.baseFeeReserve &&
                                        obj1.exchangeGridProOrder!.quoteFeeReserve === obj2.exchangeGridProOrder!.quoteFeeReserve
                                    );
                                case ExchangeOrderType.gridClassic:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeGridClassicOrder!.totalInvest === obj2.exchangeGridClassicOrder!.totalInvest &&
                                        obj1.exchangeGridClassicOrder!.trx_history === obj2.exchangeGridClassicOrder!.trx_history &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeGridClassicOrder!.triggerTime === obj2.exchangeGridClassicOrder!.triggerTime &&
                                        obj1.innerStatus === obj2.innerStatus &&
                                        obj1.exchangeGridClassicOrder!.baseAmount === obj2.exchangeGridClassicOrder!.baseAmount &&
                                        obj1.exchangeGridClassicOrder!.quoteAmount === obj2.exchangeGridClassicOrder!.quoteAmount
                                    );
                                case ExchangeOrderType.gridLeverage:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeLeverageGridOrder!.totalInvest === obj2.exchangeLeverageGridOrder!.totalInvest &&
                                        obj1.exchangeLeverageGridOrder!.trx_history === obj2.exchangeLeverageGridOrder!.trx_history &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeLeverageGridOrder!.liquidatedTriggered === obj2.exchangeLeverageGridOrder!.liquidatedTriggered &&
                                        obj1.exchangeLeverageGridOrder!.triggerTime === obj2.exchangeLeverageGridOrder!.triggerTime &&
                                        obj1.exchangeLeverageGridOrder!.liquidatedPrice === obj2.exchangeLeverageGridOrder!.liquidatedPrice &&
                                        obj1.innerStatus === obj2.innerStatus &&
                                        obj1.exchangeLeverageGridOrder!.baseAmount === obj2.exchangeLeverageGridOrder!.baseAmount &&
                                        obj1.exchangeLeverageGridOrder!.quoteAmount === obj2.exchangeLeverageGridOrder!.quoteAmount &&
                                        obj1.exchangeLeverageGridOrder!.loanCoin === obj2.exchangeLeverageGridOrder!.loanCoin
                                    );
                                case ExchangeOrderType.gridLeverageShort:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeLeverageShortGridOrder!.totalInvest === obj2.exchangeLeverageShortGridOrder!.totalInvest &&
                                        obj1.exchangeLeverageShortGridOrder!.trx_history === obj2.exchangeLeverageShortGridOrder!.trx_history &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeLeverageShortGridOrder!.liquidatedTriggered === obj2.exchangeLeverageShortGridOrder!.liquidatedTriggered &&
                                        obj1.exchangeLeverageShortGridOrder!.triggerTime === obj2.exchangeLeverageShortGridOrder!.triggerTime &&
                                        obj1.exchangeLeverageShortGridOrder!.liquidatedPrice === obj2.exchangeLeverageShortGridOrder!.liquidatedPrice &&
                                        obj1.innerStatus === obj2.innerStatus &&
                                        obj1.exchangeLeverageShortGridOrder!.baseAmount === obj2.exchangeLeverageShortGridOrder!.baseAmount &&
                                        obj1.exchangeLeverageShortGridOrder!.quoteAmount === obj2.exchangeLeverageShortGridOrder!.quoteAmount &&
                                        obj1.exchangeLeverageShortGridOrder!.loanCoin === obj2.exchangeLeverageShortGridOrder!.loanCoin
                                    );
                                case ExchangeOrderType.gridFuture:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeFutureGridOrder!.totalInvest === obj2.exchangeFutureGridOrder!.totalInvest &&
                                        obj1.exchangeFutureGridOrder!.trx_history === obj2.exchangeFutureGridOrder!.trx_history &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeFutureGridOrder!.liquidatedTriggered === obj2.exchangeFutureGridOrder!.liquidatedTriggered &&
                                        obj1.exchangeFutureGridOrder!.triggerTime === obj2.exchangeFutureGridOrder!.triggerTime &&
                                        obj1.exchangeFutureGridOrder!.liquidatedPrice === obj2.exchangeFutureGridOrder!.liquidatedPrice &&
                                        obj1.innerStatus === obj2.innerStatus &&
                                        obj1.exchangeFutureGridOrder!.baseAmount === obj2.exchangeFutureGridOrder!.baseAmount &&
                                        obj1.exchangeFutureGridOrder!.quoteAmount === obj2.exchangeFutureGridOrder!.quoteAmount
                                    );
                                case ExchangeOrderType.gridLoan:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeLoanGridOrder!.totalInvest === obj2.exchangeLoanGridOrder!.totalInvest &&
                                        obj1.exchangeLoanGridOrder!.trx_history === obj2.exchangeLoanGridOrder!.trx_history &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeLoanGridOrder!.liquidatedTriggered === obj2.exchangeLoanGridOrder!.liquidatedTriggered &&
                                        obj1.exchangeLoanGridOrder!.triggerTime === obj2.exchangeLoanGridOrder!.triggerTime &&
                                        obj1.exchangeLoanGridOrder!.liquidatedPrice === obj2.exchangeLoanGridOrder!.liquidatedPrice &&
                                        obj1.innerStatus === obj2.innerStatus &&
                                        obj1.exchangeLoanGridOrder!.baseAmount === obj2.exchangeLoanGridOrder!.baseAmount &&
                                        obj1.exchangeLoanGridOrder!.quoteAmount === obj2.exchangeLoanGridOrder!.quoteAmount &&
                                        obj1.exchangeLoanGridOrder!.loanCoin === obj2.exchangeLoanGridOrder!.loanCoin
                                    );
                                case ExchangeOrderType.gridShort:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeShortGridOrder!.totalInvest === obj2.exchangeShortGridOrder!.totalInvest &&
                                        obj1.exchangeShortGridOrder!.trx_history === obj2.exchangeShortGridOrder!.trx_history &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeShortGridOrder!.triggerTime === obj2.exchangeShortGridOrder!.triggerTime &&
                                        obj1.innerStatus === obj2.innerStatus &&
                                        obj1.exchangeShortGridOrder!.baseAmount === obj2.exchangeShortGridOrder!.baseAmount &&
                                        obj1.exchangeShortGridOrder!.quoteAmount === obj2.exchangeShortGridOrder!.quoteAmount &&
                                        //下列为提取利润相关
                                        obj1.exchangeShortGridOrder!.profitWithdrawn === obj2.exchangeShortGridOrder!.profitWithdrawn &&
                                        obj1.exchangeShortGridOrder!.latestQuoteAmount === obj2.exchangeShortGridOrder!.latestQuoteAmount &&
                                        obj1.exchangeShortGridOrder!.baseFeeReserve === obj2.exchangeShortGridOrder!.baseFeeReserve &&
                                        obj1.exchangeShortGridOrder!.quoteFeeReserve === obj2.exchangeShortGridOrder!.quoteFeeReserve
                                    );

                                default:
                                    return false;
                            }
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }),
            )
            .subscribe(
                (result) => {
                    if (getTradeDependencyInstance().getProductType() === PRODUCT_TYPE.BU) {
                        return;
                    }
                    if (orderType !== undefined && orderType !== "all") {
                        if (orderType.some((type) => type === result.orderType)) {
                            setOrder(result);
                        }
                    } else {
                        if (arbitrageTypes.some((a) => a === result.orderType)) {
                            return;
                        }
                        if (market === result.exchange) {
                            setOrder(result);
                        }
                    }
                },
                (error) => {
                    console.log(error);
                },
            );
        return () => {
            tempWebsocket && tempWebsocket.unsubscribe();
        };
    }, [market, OrderWebsocketObserve, allExchange, orderType]);

    return order;
}
function isObject(obj) {
    return Object.prototype.toString.call(obj) === "[object Object]";
}
function isArray(arr) {
    return Array.isArray(arr);
}
export function mergeObject(target, ...arg) {
    return arg.reduce((acc, cur) => {
        return Object.keys(cur).reduce((subAcc, key) => {
            const srcVal = cur[key];
            if (isObject(srcVal)) {
                subAcc[key] = mergeObject(subAcc[key] ? subAcc[key] : {}, srcVal);
            } else if (isArray(srcVal)) {
                // series: []，下层数组直接赋值
                subAcc[key] = srcVal.map((item, idx) => {
                    if (isObject(item)) {
                        const curAccVal = subAcc[key] ? subAcc[key] : [];
                        return mergeObject(curAccVal[idx] ? curAccVal[idx] : {}, item);
                    } else {
                        return item;
                    }
                });
            } else {
                subAcc[key] = srcVal;
            }
            return subAcc;
        }, acc);
    }, target);
}
