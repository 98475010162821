import useExchangeRate from "commonUse/useExchangeRate";
import useTheme from "commonUse/useTheme";
import { PionexReport } from "landings/Analytics/Report";
import MarketItem from "landings/Trade/MarketLayout/MarketItem";
import { ExchangeSymbolData } from "landings/Trade/MarketLayout/Symbol/SymbolView";
import React, { useCallback, useEffect, useState } from "react";
import { AutoSizer, List } from "react-virtualized";
import { ExchangeSymbol } from "TradeAPILib";
import { $st } from "utils/i18n";
import LocalStorageUtils from "utils/LocalStorageUtils";
import empty from "./bt-empty.png";
import Header from "./header";
import css from "./MarketPage.module.less";
import { SymbolSortMethod } from "./types";
import { SideTabKey } from "landings/Trade/SideTab";

const MARKET_PAGE_SCROLL_VIEW_ID = "pionex_market_page_scroll_view";

export function scrollToTop() {
    try {
        const scrollView = window.document.getElementById(MARKET_PAGE_SCROLL_VIEW_ID);
        if (scrollView && scrollView.firstChild) {
            (scrollView.firstChild as any).scrollTop = 0;
        }
    } catch (e) {
        console.error(e);
    }
}

export interface MarketPageProps {
    symbol: ExchangeSymbol;
    exchange: string;
    symbolList: ExchangeSymbolData[];
    onCollectionChanged(symbol: ExchangeSymbol, collection: boolean);
    hidePercent?: boolean;
    nano?: boolean;
    onSelectSymbol?: () => void;
    isDropdown?: boolean;
    isSort?: boolean;
    targetPage?: SideTabKey;
}

export default function MarketPage(props: MarketPageProps) {
    const { onCollectionChanged, symbolList = [], exchange, hidePercent, isSort = true, targetPage } = props;
    const theme = useTheme();
    const exchangeRate = useExchangeRate();
    const [sortMethod, setSortMethod] = React.useState(LocalStorageUtils.getSymbolSortMethod);
    const [dataList, setDataList] = React.useState(symbolList);
    const [show, setShow] = useState(false);

    const compare = useCallback(
        (a: ExchangeSymbolData, b: ExchangeSymbolData): number => {
            if (sortMethod === SymbolSortMethod.PAIR_ASC) {
                return a.symbol.localeCompare(b.symbol);
            } else if (sortMethod === SymbolSortMethod.PAIR_DESC) {
                return b.symbol.localeCompare(a.symbol);
            } else if (sortMethod === SymbolSortMethod.VOL_ASC) {
                let leftVol = a.ticker ? a.ticker.quoteVol : 0;
                let rightVol = b.ticker ? b.ticker.quoteVol : 0;

                return leftVol - rightVol;
            } else if (sortMethod === SymbolSortMethod.VOL_DESC) {
                let leftVol = a.ticker ? a.ticker.quoteVol : 0;
                let rightVol = b.ticker ? b.ticker.quoteVol : 0;
                return rightVol - leftVol;
            } else if (sortMethod === SymbolSortMethod.PRICE_ASC) {
                let leftPrice = b.ticker ? b.ticker.latest : 0;
                let rightPrice = a.ticker ? a.ticker.latest : 0;

                return rightPrice - leftPrice;
            } else if (sortMethod === SymbolSortMethod.PRICE_DESC) {
                let leftPrice = b.ticker ? b.ticker.latest : 0;
                let rightPrice = a.ticker ? a.ticker.latest : 0;
                return leftPrice - rightPrice;
            } else if (sortMethod === SymbolSortMethod.CHANGE_ASC) {
                let leftChange = b.ticker ? b.ticker.changePercent || 0 : 0;
                let rightChange = a.ticker ? a.ticker.changePercent || 0 : 0;
                return rightChange - leftChange;
            } else if (sortMethod === SymbolSortMethod.CHANGE_DESC) {
                let leftChange = b.ticker ? b.ticker.changePercent || 0 : 0;
                let rightChange = a.ticker ? a.ticker.changePercent || 0 : 0;

                return leftChange - rightChange;
            } else {
                return 0;
            }
        },
        [sortMethod],
    );

    const _setSortMethod = useCallback((sortMethod) => {
        setSortMethod(sortMethod);
        LocalStorageUtils.setSymbolSortMethod(sortMethod);
        PionexReport.reportSymbolSortTyle(sortMethod);
        scrollToTop();
    }, []);

    React.useEffect(() => {
        setDataList(isSort ? symbolList.sort((a, b) => compare(a, b)).slice() : symbolList);
    }, [symbolList, sortMethod, compare, isSort]);

    const renderItem = useCallback(
        ({
            key, // Unique key within array of rows
            index, // Index of row within collection
            isScrolling, // The List is currently being scrolled
            isVisible, // This row is visible within the List (eg it is not an overscanned row)
            style, // Style object to be applied to row (to position it)
        }) => {
            const item = dataList[index];
            return (
                <div style={style} key={key}>
                    <MarketItem
                        onSelectSymbol={props.onSelectSymbol}
                        nano={props.nano}
                        theme={theme}
                        exchange={exchange}
                        symbol={item}
                        exchangeRate={exchangeRate}
                        onCollectionChanged={onCollectionChanged}
                        key={item.symbol}
                        selected={item.base === props.symbol.base && item.quote === props.symbol.quote}
                        hidePercent={hidePercent}
                        isDropdown={props.isDropdown}
                        targetPage={targetPage}
                    />
                </div>
            );
        },
        [dataList, props.nano, props.symbol.base, props.onSelectSymbol, props.symbol.quote, theme, exchange, exchangeRate, onCollectionChanged, hidePercent],
    );

    // const getHW=useCallback(()=>{
    //     document.getElementsByClassName("resize-triggers")
    //     if()
    // },[])

    useEffect(() => {
        // 临时解决 AutoSizer在下拉菜单没展开时 获取不到高度 宽度的问题
        setTimeout(() => {
            setShow(true);
        }, 200);
    }, []);

    return (
        <>
            {!props.nano && <Header theme={theme} hidePercent={hidePercent} sortMethod={sortMethod} setSortMethod={_setSortMethod} isSort={isSort} />}

            {!dataList.length ? (
                <div className={css.noData}>
                    <img src={empty} width={64} height={64} alt={""} />
                    <div className={css.noDataLabel}>{$st("no_data")}</div>
                </div>
            ) : (
                <div style={{ height: "100%" }}>
                    {show && (
                        <AutoSizer>
                            {({ height, width }) => {
                                return <List className={css.list} width={width} height={height} rowHeight={60} rowCount={dataList.length} rowRenderer={renderItem} />;
                            }}
                        </AutoSizer>
                    )}
                </div>
            )}
        </>
    );
}
