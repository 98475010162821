import { CoinIcon } from "components/CoinIcon";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDWCoins } from "commonUse/useDW";
import { ExchangeID, SupportDWCoinInfo } from "TradeAPILib";
import { useTicker } from "@pionex-web-kit/common-business";
import { Subscription } from "rxjs/internal/Subscription";
import { AuthType, Network, NumberCommonUtils, ObservableUtils, requestWithRetry, SafeDecimal } from "trade_utils_lib";
import { map } from "rxjs/operators";
import { MiniLineChart, MiniLineChartEntry } from "landings/V2/BuyCrypto/components/Form/items/MiniLineChart";
import Constants from "utils/Constants";
import useTheme from "commonUse/useTheme";

interface Props {
    coin: string;
    quote: string;
    allCoinsMap: Map<string, SupportDWCoinInfo>;
}

const ONE_DAY_MS = 24 * 60 * 60 * 1000;
const ONE_HOUR_MS = 60 * 60 * 1000;
const MAX_ENTRY_LENGTH = 24;
export const DropdownCoinItem = (props: Props) => {
    const { coin, quote } = props;
    const theme = useTheme();
    const [dwCoins] = useDWCoins();
    const allCoinsMap = useMemo(() => {
        return new Map(dwCoins.map((item) => [item.coinName, item]));
    }, [dwCoins]);
    const ticker = useTicker(coin, quote);
    const [entries24H, setEntries24H] = useState<MiniLineChartEntry[]>([]);
    const updateChartEntries = useCallback((res: MiniLineChartEntry[]) => {
        if (!res || res.length === 0) {
            setEntries24H([]);
        } else if (res.length > MAX_ENTRY_LENGTH) {
            const startPos = res.length - MAX_ENTRY_LENGTH;
            setEntries24H(res.slice(startPos, res.length));
        } else {
            const firstX = res[0].x * 1000;
            const addArr = new Array(MAX_ENTRY_LENGTH - res.length)
                .fill(null)
                .map((v, index) => {
                    return {
                        x: Math.floor((firstX - (index + 1) * ONE_HOUR_MS) / 1000),
                        y: 0,
                    };
                })
                .reverse();
            setEntries24H(addArr.concat(res));
        }
    }, []);

    const request = useCallback((): Subscription => {
        const nowMs = Date.now();
        const start = Math.floor((nowMs - ONE_DAY_MS) / 1000);
        const end = Math.floor(nowMs / 1000);

        return requestWithRetry(() => {
            return Network.get({
                url: `${Constants.kLineHost}/query_unite_candle_data`,
                data: {
                    base: coin,
                    quote: quote,
                    market: ExchangeID.PIONEXV2,
                    interval: "1h",
                    start,
                    end,
                    from: "web",
                },
            });
        })
            .pipe(
                map((v) => {
                    return (
                        Array.isArray(v.history_price)
                            ? v.history_price.map((t) => {
                                  return {
                                      x: new SafeDecimal(t.date).toNumber(),
                                      y: new SafeDecimal(t.close).toNumber(),
                                  };
                              })
                            : []
                    ) as MiniLineChartEntry[];
                }),
            )
            .subscribe({
                next: (res) => updateChartEntries(res),
                error: () => {},
            });
    }, [coin, quote, updateChartEntries]);
    useEffect(() => {
        const subscription = request();
        return () => {
            subscription?.unsubscribe();
        };
    }, [request]);
    const { priceStr, changePercentStr, changePercentColor } = useMemo(() => {
        if (ticker) {
            const changePercent = ticker.changePercent ?? 0;
            const priceStr = NumberCommonUtils.toNormalPrice(ticker.latest);
            const changePercentStr = NumberCommonUtils.toPercent(changePercent * 100, true, true);
            const changePercentColor = changePercent >= 0 ? theme.increase : theme.decrease;
            return { priceStr, changePercentStr, changePercentColor };
        }
        return {};
    }, [theme.decrease, theme.increase, ticker]);
    const entries = useMemo(() => {
        if ((ticker?.latest || ticker?.latest === 0) && entries24H.length > 0) {
            const latestEntry: MiniLineChartEntry | undefined = {
                x: Math.round(Date.now() / 1000),
                y: new SafeDecimal(ticker.latest).toNumber(),
            };
            const newEntries = entries24H.concat(latestEntry);
            return newEntries.length > MAX_ENTRY_LENGTH
                ? newEntries.slice(newEntries.length - MAX_ENTRY_LENGTH, newEntries.length) //只保留最大数量长度
                : newEntries;
        }
        return entries24H;
    }, [entries24H, ticker?.latest]);
    return (
        <div className="flex flex-row items-center">
            <div className="flex-1 flex flex-row items-center">
                <CoinIcon target={coin} className="mr-8px !w-24px !h-24px" />
                <div>
                    <div> {`${coin}`}</div>
                    <div className="text-secondary  text-xs">{allCoinsMap.get(coin)?.fullName || coin}</div>
                </div>
            </div>
            <div className="flex-1 text-right">
                <MiniLineChart priceStr={priceStr} lineColor={changePercentColor} entries={entries} />
            </div>
            <div className="flex-1 text-right">
                <div className="text-accent font-medium text-sm">{priceStr ? `$${priceStr}` : undefined}</div>
                <div style={{ color: changePercentColor }}>{changePercentStr}</div>
            </div>
        </div>
    );
};
