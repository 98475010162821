import React from "react";
import cs from "./index.m.less";
import csn from "classnames";

interface Props {
    className?: string;
    text?: React.ReactNode;
}

/**
 * 手续费标识
 */
const ZeroFee: React.FC<Props> = ({ className, text }) => {
    return <span className={csn(cs.zeroFee, className)}>{text ?? "0 Fee"}</span>;
};

export default ZeroFee;
