import React from "react";
import { H3, H5, WrapperColumn } from "../StyleComponent";

const OrderHorParam = (props) => {
    const { title, value, style } = props;

    return (
        <WrapperColumn style={{ flex: 1, ...style }}>
            <H5>{title}</H5>
            <H3 style={{ marginTop: 4 }}>{value}</H3>
        </WrapperColumn>
    );
};

export default OrderHorParam;
