import React from "react";
import cs from "./index.module.less";
import { Iconfont } from "components/v2/TinyComps";
import csn from "classnames";
import CopyToClipboard from "react-copy-to-clipboard";
import { message } from "antd";
import { useTranslators } from "commonUse/Locale";
import useTheme from "commonUse/useTheme";
import { IconWarning20pxLineS } from "@pionex-web-kit/icons";

interface Props {
    className?: string;
    require?: boolean;
    label?: string;
    value?: string;
    disableCopy?: boolean;
}

/**
 * 单条汇款信息
 */
const TransferInfoItem: React.FC<Props> = ({ require, label, value, disableCopy }) => {
    const { $st } = useTranslators();

    return (
        <>
            <div className={csn(cs.transferInfoItem)}>
                <div>
                    <div className={csn(cs.label, { [cs.label_light]: require })}>{label}</div>
                    <div className={csn(cs.value, { [cs.value_light]: require })}>{value}</div>
                </div>
                {value?.length !== 0 && !disableCopy && (
                    <CopyToClipboard text={value || ""} onCopy={() => message.success($st("tip_copy_success"))}>
                        <Iconfont icon="icon_copy" className={cs.copy} />
                    </CopyToClipboard>
                )}
            </div>
        </>
    );
};

export default TransferInfoItem;
