import React from "react";
import css from "landings/Balance/components/BalanceBot/BalanceBotView.module.less";
import { CoinIcon } from "components/CoinIcon";

export interface ListCoinNameItemProps {
    coin: any;
    baseDisplay: string;
    fullName: string;
}

const ListCoinNameItem = (props: ListCoinNameItemProps) => {
    const { coin, baseDisplay, fullName } = props;

    return (
        <div className={css.coinNameWrap} style={{ width: 180, display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div style={{ marginRight: 8 }}>
                <CoinIcon target={coin} size={25} />
            </div>
            <span className={css.coinName}>
                {baseDisplay}
                <span className={css.fullName}>
                    {fullName !== "" && fullName !== undefined && fullName !== null && !fullName.startsWith("No Full Name") ? ` (${fullName})` : ""}
                </span>
            </span>
        </div>
    );
};

export default React.memo(ListCoinNameItem);
