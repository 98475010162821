import { Observable } from "rxjs";
import Api from "utils/Api";
import Constants from "utils/Constants";
import { message as Message } from "antd";
import { ObservableUtils } from "trade_utils_lib";

export interface AssetAddressItem {
    address_id: string;
    coin: string;
    chain: string;
    address: string;
    tag: string;
    whitelist: boolean;
    memo?: string;
    // 前端赋值，通过chain转换而来，对应SupportDWCoinInfo中的protocolDisplay
    protocolDisplay: string;
}

export enum WhiteListStatus {
    on = "on",
    off = "off",
}

export interface WhiteListStatusResult {
    whitelist_status: WhiteListStatus;
}

export enum VerifyCodeAction {
    login = "login",
    withdraw = "withdraw",
    register = "register",
    forgetPassword = "forget_password",
    accountBind = "account_bind",
    add_whitelist_address = "add_whitelist_address", //"添加白名单地址"
    modify_address_tag = "modify_address_tag", // '修改地址标签',
    move_to_whitelist_address = "move_to_whitelist_address", //移至白名单',
    move_out_whitelist_address = "move_out_whitelist_address", // '移出白名单',
    open_whitelist_withdraw = "open_whitelist_withdraw", // '开启白名单提币',
    close_whitelist_withdraw = "close_whitelist_withdraw", // '关闭白名单提币',
}

export enum VerifyCodeType {
    sms = "sms",
    email = "email",
    voice = "voice",
}
export interface VerifyCodeInfo {
    email_code?: string;
    sms_code?: string;
    otp_code: string;
}

export interface AssetAddressItemResp {
    address: AssetAddressItem[];
}

export type WhiteListAction = "add" | "remove";

export type TAddAddressInfoData = {
    coin: string;
    address: string;
    tag: string;
    memo?: string;
    chain?: string;
    whitelist?: boolean;
    otp_code?: string;
    sms_code?: string;
    email_code?: string;
};

// 获取与设置 是否开启只向白名单提币的状态
export const getWhiteListStatus = (): Observable<WhiteListStatusResult> => {
    const url = `${Constants.walletApiHost}/get_user_whitelist_status/`;
    return ObservableUtils.getV2(url, null, null, true);
};
export const setWhiteListStatus = (status: WhiteListStatus, verifyInfo: VerifyCodeInfo): Observable<WhiteListStatusResult> => {
    const url = `${Constants.walletApiHost}/modify_user_whitelist_status/`;
    const params = {
        modify_status: status,
        ...verifyInfo,
    };
    return ObservableUtils.postV2(url, JSON.stringify(params), null, true);
};

// 获取全部地址簿地址
export const getAddressBookItems = (): Observable<AssetAddressItemResp> => {
    const url = `${Constants.walletApiHost}/get_address/`;
    return ObservableUtils.getV2(url, {}, null, true);
};

// 获取白名单地址簿地址
export const getAddressBookWhiteListItems = (): Observable<AssetAddressItemResp> => {
    const url = `${Constants.walletApiHost}/get_whitelist_address/`;
    return ObservableUtils.getV2(url, {}, null, true);
};

// 获取某个币的地址簿列表
export const getAddressBookWithCoin = (coin: string, chain?: string): Observable<AssetAddressItemResp> => {
    const url = `${Constants.walletApiHost}/get_address_with_coin/`;
    const params = {
        coin: coin,
        chain: chain || null,
    };
    return ObservableUtils.getV2(url, params, null, true);
};

// 增加地址到地址簿
export const addAddressBookItem = (AddAddressInfoData: TAddAddressInfoData): Observable<AssetAddressItem> => {
    const url = `${Constants.walletApiHost}/add_address/`;
    return ObservableUtils.postV2(url, JSON.stringify(AddAddressInfoData), null, true);
};

// 删除地址簿地址
export const deleteAddressBookItem = ({ address_id: addressID }): Observable<null> => {
    const url = `${Constants.walletApiHost}/delete_address/?address_id=${addressID}&action=delete`;
    return Api.deleteBearerData(url);
};

// 修改地址簿地址的备注
export const modifyAddressBookItemTag = (addressID: string, tag: string): Observable<AssetAddressItem> => {
    const url = `${Constants.walletApiHost}/modify_address_tag/`;
    const params = {
        address_id: addressID,
        tag: tag,
    };
    return ObservableUtils.postV2(url, JSON.stringify(params), null, true);
};

// 修改地址簿地址的白名单状态 add为添加 remove为剔除
export const modifyAddressBookItemWhiteListStatus = (requestParam): Observable<AssetAddressItem> => {
    const url = `${Constants.walletApiHost}/modify_address_status/`;
    return ObservableUtils.postV2(url, JSON.stringify(requestParam), null, true);
};

export const commonErrorParser = (errorCode: number | string, str, message?: string) => {
    if (message === "timeout of 10000ms exceeded") {
        Message.error(str("account_too_many_requests"));
        return;
    }

    switch (errorCode) {
        case 40320799:
            Message.error(str("verification_code_error"));
            break;
        case 40300700:
            Message.error(str("not_a_whitelist_address"));
            break;
        case 40300800:
            Message.error(str("address_tag_too_long"));
            break;
        case 40320735:
            Message.error(str("code_verify_error"));
            break;
        case 40020337:
            Message.error(str("duplicate_address"));
            break;
        case 40020336:
            Message.error(str("duplicate_tag"));
            break;
        case 40020338:
            Message.error(str("address_not_exists"));
            break;
        case 50020299:
            Message.error(str("add_address_factor_desc"));
            break;
        default:
            Message.error(str("address_unknown_error", { message, errorCode }));
    }
};
