import { ConvertParams, ConvertRecord } from "landings/Trade/Swap/types";
import { APIKeyInfo, ObservableUtils } from "trade_utils_lib";
import HostConfig from "utils/HostConfig";
import { map } from "rxjs/operators";
export type TCreateSwapOrder = {
    base_amount?: string;
    base_coin: string;
    quote_amount?: string;
    quote_coin: string;
    side: string;
    mode?: "sell" | "convert";
};
export type TSwapOrder = {
    base_amount: string;
    base_coin: string;
    create_time: number;
    fail_reason: string;
    order_id: string;
    price: string;
    quote_amount: string;
    quote_coin: string;
    status: string;
    side: "buy" | "sell";
    update_time: number;
};
export namespace SwapAPI {
    function genKeyInfo(keyInfo: APIKeyInfo) {
        return {
            market: keyInfo.exchange,
            cloudKeyId: keyInfo.key_id,
        };
    }
    /**
     * 提交闪兑
     */
    export function applyConvert(keyInfo: APIKeyInfo, params: ConvertParams) {
        return ObservableUtils.postWithTradeAuthV3<{ order_id: string }>(`${HostConfig.walletApiHost}/buycrypto/convert/orders/`, genKeyInfo(keyInfo), params);
    }

    export function getRecords(keyInfo: APIKeyInfo) {
        return ObservableUtils.getWithTradeAuthV3<{ records?: ConvertRecord[] }>(`${HostConfig.walletApiHost}/buycrypto/convert/orders/`, genKeyInfo(keyInfo));
    }

    export function getSingleRecord(keyInfo: APIKeyInfo, order_id: string) {
        return ObservableUtils.getWithTradeAuthV3<{ records?: ConvertRecord[] }>(`${HostConfig.walletApiHost}/buycrypto/convert/orders/`, genKeyInfo(keyInfo), { order_id }).pipe(
            map((res) => {
                if (!res?.records?.length) {
                    return null;
                }
                return res.records[0];
            }),
        );
    }
    export function checkOrderStatus(apiKeyInfo, id?: string, mode?: TCreateSwapOrder["mode"]) {
        return ObservableUtils.getWithTradeAuthV3<{ records?: TSwapOrder[] }>(`${HostConfig.walletApiHost}/buycrypto/convert/orders/`, apiKeyInfo, {
            order_id: id,
            mode,
        });
    }
}
