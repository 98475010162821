import React, { useEffect, useRef, useState } from "react";
import { Modal, Spin } from "@pionex-web-kit/components";
import { IconCheckMark16pxRegular } from "@pionex-web-kit/icons";
import { EDepositState, THREE_DS_FAQ_URL } from "landings/V2/CircleDebit/Deposit/depositState";
import cs from "landings/V2/CircleDebit/Deposit/index.m.less";
import ThreeDSProgress from "landings/V2/CircleDebit/components/ThreeDSProgress";
import { useTranslators } from "commonUse/Locale";
import { Subscription, timer } from "rxjs";
import { ProgressUtils } from "utils/ProgressUtils";

interface Props {
    state: EDepositState;
    onCancel?: () => void;
}

/**
 * 入金时的等待状态弹窗
 * @param state
 * @param onCancel
 * @constructor
 */
const DepositWaitingModal: React.FC<Props> = ({ state, onCancel }) => {
    const { $st } = useTranslators();
    const [percent, setPercent] = useState(0);
    const [show3DSTips, setShow3DSTips] = useState(false);
    const progressSubscriptionRef = useRef<Subscription>();

    useEffect(() => {
        // 第一步提交订单请求，0-2秒时间进度条走到第一段3分之2处开始等待（等待进行3DS认证通知
        if (state === EDepositState.SUBMITTING) {
            progressSubscriptionRef.current = ProgressUtils.progressTo(2000, percent, 33).subscribe((per) => {
                setPercent(per);
            });
            return;
        }

        if (state === EDepositState.IN_3DS_AUTH) {
            // 到第2秒，弹窗上出现关闭按钮以及3DS FAQ提示说明
            timer(2000).subscribe(() => {
                setShow3DSTips(true);
            });

            // 接收到3DS认证通知，0-1.5秒，时间进度条总第一段3分之2处走到第二段3分之1处
            progressSubscriptionRef.current?.unsubscribe();
            progressSubscriptionRef.current = ProgressUtils.progressTo(1500, percent, 66).subscribe((per) => {
                setPercent(per);
            });

            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        return () => {
            progressSubscriptionRef.current?.unsubscribe();
        };
    }, []);

    return (
        <Modal
            wrapClassName={cs.tdsWaitModal}
            title={$st("deposit_3ds_modal_title")}
            visible
            className={cs.cover}
            centered
            width={400}
            closable={false}
            // closable={depositState.isClosable()}
            footer={
                show3DSTips ? (
                    <div className="flex justify-end">
                        <div className={"text-primary cursor-pointer"} onClick={onCancel}>
                            {$st("dw.history.status.cancel")}
                        </div>
                    </div>
                ) : null
            }
        >
            <ThreeDSProgress percent={percent} className={"pb-20px"} />
            {generateWaitItems(state).map((item, index) => (
                <DepositWaitingItem text={index === 0 ? $st("debit_deposit_cover_submitted") : $st("debit_deposit_cover_3ds")} status={item} />
            ))}
            {show3DSTips && (
                <a className={"text-primary hover:text-primary text-sm mt-10px block"} href={THREE_DS_FAQ_URL} target="_blank">
                    {$st("debit_result_faq")}
                </a>
            )}
        </Modal>
    );
};

export default DepositWaitingModal;

interface DepositWaitingItemProps {
    text: React.ReactNode;
    status: "prepare" | "loading" | "finished";
}

/**
 * 入金时的等待弹窗上的提示文案项
 */
const DepositWaitingItem: React.FC<DepositWaitingItemProps> = ({ text, status }) => {
    if (status === "prepare") {
        return (
            <div className="flex items-center text-secondary">
                <div className="w-12px text-center flex justify-center mr-12px">
                    <div className={"rounded-4px w-4px h-4px bg-secondary"} />
                </div>
                {text}
            </div>
        );
    }

    if (status === "loading") {
        return (
            <div className="flex items-center text-accent">
                <div className="w-12px text-center flex justify-center mr-12px">
                    <Spin size="small" className="scale-[.25]" />
                </div>
                {text}
            </div>
        );
    }

    if (status === "finished") {
        return (
            <div className="flex items-center text-accent">
                <IconCheckMark16pxRegular className="mr-12px w-12px" />
                {text}
            </div>
        );
    }

    return null;
};

export function generateWaitItems(state: EDepositState): Array<DepositWaitingItemProps["status"]> {
    switch (state) {
        case EDepositState.WAIT_3DS_URL:
        case EDepositState.SUBMITTING:
        case EDepositState.APPROVING:
            return ["loading", "prepare"];
        case EDepositState.IN_3DS_AUTH:
            return ["finished", "loading"];
        default:
            return [];
    }
}
