import Constants from "utils/Constants";
import AccountManager from "utils/AccountManager";

/**
 * 判断用户类型，跳转到指定业务站
 */
export function checkToBusinessWeb() {
    if (process.env.NODE_ENV === "development") {
        // 本地调试不做host 验证
        return;
    }
    const businessVersion = AccountManager.getBusinessVersion();

    if (businessVersion) {
        const targetHost = Constants.appDomain[businessVersion];

        if (targetHost === window.location.host) {
            // 已经在当前版本对应的域名下
            return;
        }

        window.location.replace(`https://${targetHost}`);
    }
}
