import React, { useMemo } from "react";
import { WithdrawHistoryInfo } from "landings/V2/SignatureDW/types";
import { ColumnsType } from "antd/lib/table";
import useDateFormatter from "commonUse/useDateFormatter";
import { useTranslators } from "commonUse/Locale";
import useTheme from "commonUse/useTheme";
import { useSignatureDWAPI } from "landings/V2/SignatureDW/common";
import { useRxRequest } from "commonUse/useRequest";
import { getTips, useWithdrawErrorMap } from "landings/V2/SignatureDW/History/Withdraw/utils";
import { IconInformation16pxBoldS } from "@pionex-web-kit/icons";
import { Spin, Table, Tooltip } from "@pionex-web-kit/components";

const USD = "USD";

export function useColumns(): ColumnsType<WithdrawHistoryInfo> {
    const dateFormatter = useDateFormatter();
    const { $st } = useTranslators();
    const theme = useTheme();
    const errorMap = useWithdrawErrorMap();

    return useMemo(() => {
        const columns: ColumnsType<WithdrawHistoryInfo> = [
            {
                title: $st("signature_dw_history_order_id"),
                dataIndex: "orderId",
                key: "orderId",
            },
            {
                title: $st("signature_dw_history_withdraw_amount"),
                dataIndex: "amount",
                key: "amount",
                render: (value, record, index) => {
                    return `${value} ${USD}`;
                },
            },
            {
                title: $st("signature_dw_history_status"),
                dataIndex: "status",
                key: "status",
                render: (value, record, index) => {
                    const tips = getTips($st, errorMap, record);
                    return (
                        <div className="flex items-center">
                            {value}
                            {!!tips && (
                                <Tooltip title={<span>{tips}</span>}>
                                    <IconInformation16pxBoldS size={18} className="ml-4px" />
                                </Tooltip>
                                // <TTooltip title={<span>{tips}</span>}>
                                //     <img
                                //         src={
                                //             theme.name === ThemeName.dark
                                //                 ? require("images/trading/icon_advance_setting_info_dark.png")
                                //                 : require("images/trading/icon_advance_setting_info.png")
                                //         }
                                //         className={cs.itemTips}
                                //     />
                                // </TTooltip>
                            )}
                        </div>
                    );
                },
            },
            // {
            //     title: $st("signature_dw_history_withdraw_realAmount"),
            //     dataIndex: "realAmount",
            //     key: "realAmount",
            //     render: (value, record, index) => {
            //         return `${value} ${USD}`;
            //     },
            // },
            {
                title: $st("signature_dw_history_fee"),
                dataIndex: "fee",
                key: "fee",
                render: (value, record, index) => {
                    return `${value} ${USD}`;
                },
            },
            {
                title: $st("signature_dw_history_time"),
                dataIndex: "createTime",
                key: "createTime",
                render: (value, record, index) => {
                    return value ? <div>{dateFormatter.year_second(value)}</div> : null;
                },
            },
            // {
            //     title: $st("withdrawable_Time"),
            //     dataIndex: "withdrawableTime",
            //     key: "withdrawableTime",
            //     render: (value, record, index) => {
            //         return value ? <div>{dateFormatter.year_second(value)}</div> : null;
            //     },
            // },
            // {
            //     title: $st("signature_dw_history_withdraw_time"),
            //     dataIndex: "remitTime",
            //     key: "remitTime",
            //     render: (value, record, index) => {
            //         return value ? <div>{dateFormatter.year_second(value)}</div> : null;
            //     },
            // },
        ];

        columns.forEach((item) => (item.width = `${100 / columns.length}%`));
        return columns;
    }, [$st, dateFormatter, theme.name]);
}

interface Props {}

/**
 *
 */
const WithdrawHistory: React.FC<Props> = ({}) => {
    const signatureDWAPI = useSignatureDWAPI();
    const { loading, data } = useRxRequest(() => signatureDWAPI?.getWithdrawOrders());

    const columns = useColumns();

    return (
        <Spin spinning={loading}>
            <Table scroll={{ x: "100%" }} dataSource={data} columns={columns} pagination={false} />
        </Spin>
    );
};

export default WithdrawHistory;
