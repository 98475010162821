import { useEffect, useState } from "react";

// 页面滚动时检测元素是否在可视区
function useShow(el: Element | null) {
    const [show, setShow] = useState(false);
    useEffect(() => {
        if (!el) {
            // console.warn("不存在元素");
            setShow(false);
            return;
        } else {
            // console.log('已存在元素')
        }
        const listenElClient = () => {
            const chartClientRect = el.getBoundingClientRect();
            if (chartClientRect.width >= 330) {
                setShow(true);
            } else {
                setShow(false);
            }
        };
        window.addEventListener("resize", listenElClient, false);
        return () => {
            window.removeEventListener("resize", listenElClient);
        };
    }, [el]);
    return show;
}

export default useShow;
