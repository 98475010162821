import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";
import { Form, message } from "antd";
import { TInput, TSelect } from "components/v2/TinyComps";
import { countryText, getAbaInfo, getBankType, getCircleStates, isNeedState, loadBankInfoBySwift } from "landings/V2/CircleDW/BankCard/common";
import { AddBankCardParams, bankCardPureNumberReg, bankInfoReg, BankType, BillingInfoParam, postalReg } from "compliance_lib";
import { extractBIC, extractIBAN, isValidBIC, isValidIBAN } from "ibantools";
import cs from "./CardInfoForm.module.less";
import { useTranslators } from "commonUse/Locale";
import FormItemTransition from "utils/FormItemTransition";
import { Alpha2Code, alpha3ToAlpha2 } from "i18n-iso-countries";
import csn from "classnames";
import { getFBOSupportCountries, useWithdrawConfig } from "landings/V2/SignatureDW/Withdraw/utils";
import { validateCardName } from "landings/V2/USDDW/utils";
import Uploader from "landings/V2/USDDW/components/Uploader";
import { Callbacks } from "rc-field-form/lib/interface";
import { SignatureTransferType } from "landings/V2/SignatureDW/types";

const CITY_MAX_LENGTH = 35;
const STREET_MAX_LENGTH = 70;
const handleFilterOption = (input, { label }: any) => {
    // 通过Option上增加额外的label属性直接过滤
    return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

interface Props {
    className?: string;
    onFinish?: (params: AddBankCardParams & { fileIds?: string }) => void;
    onValuesChange?: Callbacks["onValuesChange"];
    transferType: SignatureTransferType;
}

export interface CardInfoFormRef {
    submit: () => void;
}

/**
 * 银行卡信息表单
 *  新增
 */
const CardInfoForm = forwardRef<CardInfoFormRef, Props>(({ className, onFinish: outFinish, onValuesChange, transferType }, ref) => {
    const { $st } = useTranslators();
    const [file, setFile] = useState<string>("");
    const [form] = Form.useForm();
    const [imgErrors, setImgErrors] = useState<string>("");
    useImperativeHandle(ref, () => ({ submit: () => form.submit() }));

    const onFinish = useCallback(
        async (values) => {
            if (imgErrors) {
                message.warn(imgErrors);
                return;
            }
            if (!file) {
                message.warn($st("mine_bank_add_step_3_uploadDoc"));
                return;
            }
            const params: AddBankCardParams & { transfer_type?: SignatureTransferType } = {
                bank_address: {
                    country: values.country_code,
                    city: values.bankCity,
                },
            };
            const bankType = getBankType(values.country_code);
            const accountNumber = (values.account_number || "").replace(/\s/g, "");
            const swiftCode = values.swift_bic;
            let bankName: string = values.bank_name;
            if (bankType === BankType.ABA) {
                params.transfer_type = values.transferType;
                params.bank_account_us = {
                    routing_number: values.routing_number,
                    account_number: accountNumber,
                };
                params.bank_address.city = "";
            }
            if (bankType === BankType.IBAN) {
                params.bank_account_iban = values.iban_code.replace(/\s/g, "");
                params.bank_address.city = values.bankCity;
            }
            if (bankType === BankType.SWIFT) {
                params.bank_account_other = {
                    swift_bic: values.swift_bic,
                    account_number: accountNumber,
                };
                if (swiftCode.length === 8 || swiftCode.length === 11) {
                    const bankInfo = await loadBankInfoBySwift(swiftCode);
                    if (bankInfo && bankInfo.countryCode === values.country_code) {
                        bankName = bankInfo.bank;
                        params.bank_address.city = bankInfo.city;
                    }
                }
            }
            params.bank_address.bank_name = bankName.substring(0, 35);
            if (bankName.length > 35) {
                params.bank_address.line1 = bankName.substr(35, 35);
            }
            if (bankName.length > 70) {
                params.bank_address.line2 = bankName.substring(70);
            }

            const realAddress = values.street.replace(/\s+/g, " ").trim();
            const billingDetails: BillingInfoParam = {
                name: values.name,
                city: values.city,
                country: (alpha3ToAlpha2(values.countryCode) || values.countryCode) as Alpha2Code,
                line1: realAddress.substring(0, 35),
                postal_code: values.postal_code,
                district: values.stateCode,
            };
            if (realAddress.length > 35) {
                billingDetails.line2 = realAddress.substr(35, 35);
            }
            params.billing_details = billingDetails;
            outFinish?.({ ...params, fileIds: file });
        },
        [$st, file, imgErrors, outFinish],
    );
    const onFinishFailed = useCallback(({ errorFields }) => {
        const countryCode = errorFields.find((err) => err.name[0] === "country_code");
        if (countryCode && countryCode.errors[0]) {
            message.warn(countryCode.errors[0]);
        }
    }, []);

    const countryOptions = useMemo(() => {
        const options = getFBOSupportCountries();
        return options.map((item) => {
            const label = countryText(item);
            return (
                <TSelect.Option key={item.code} value={item.code} label={label}>
                    {label}
                </TSelect.Option>
            );
        });
    }, []);

    const onValuesChangeNew = useCallback(
        (changeValue, _) => {
            if (changeValue.routing_number) {
                const abaInfo = getAbaInfo(changeValue.routing_number);
                if (abaInfo?.name) {
                    form.setFieldsValue({ bank_name: abaInfo.name });
                }
            }
            onValuesChange && onValuesChange(changeValue, _);
        },
        [form, onValuesChange],
    );

    return (
        <Form
            className={csn(cs.formStyle, className)}
            form={form}
            initialValues={{ country_code: "US", transferType }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onValuesChangeNew}
        >
            <div className={cs.step}>{$st("signature_dw_withdraw_bank_info")}</div>
            <Form.Item
                name="country_code"
                rules={[
                    () => ({
                        validator(_, value) {
                            if (!value) {
                                return Promise.reject($st("circledw_addCard_country"));
                            }
                            return Promise.resolve();
                        },
                    }),
                ]}
                className="hidden"
            >
                <TSelect showSearch filterOption={handleFilterOption} size="large" placeholder={$st("circledw_addCard_country")}>
                    {/*{useMemo(() => {*/}
                    {/*    const options = getFBOSupportCountries().filter((v) => v.code === "US");*/}
                    {/*    return options.map((item) => {*/}
                    {/*        const label = countryText(item);*/}
                    {/*        return (*/}
                    {/*            <TSelect.Option key={item.code} value={item.code} label={label}>*/}
                    {/*                {label}*/}
                    {/*            </TSelect.Option>*/}
                    {/*        );*/}
                    {/*    });*/}
                    {/*}, [])}*/}
                </TSelect>
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, getFieldError }) => {
                    const countryCode = getFieldValue("country_code");
                    const bankType = getBankType(countryCode);
                    const [cityErr] = getFieldError("bankCity") || [];
                    const [ibanCodeErr] = getFieldError("iban_code") || [];
                    const [routingNumberErr] = getFieldError("routing_number") || [];
                    const [swiftErr] = getFieldError("swift_bic") || [];
                    const [accountNumberErr] = getFieldError("account_number") || [];
                    const [bankNameErr] = getFieldError("bank_name") || [];
                    if (!countryCode) {
                        return null;
                    }
                    return (
                        <>
                            {[BankType.SWIFT, BankType.IBAN].includes(bankType) && (
                                <Form.Item
                                    name={"bankCity"}
                                    rules={[
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return Promise.reject($st("mine_band_add_bank_city_none"));
                                                }
                                                if (!bankInfoReg.test(value)) {
                                                    return Promise.reject($st("mine_band_add_info_not_english_error"));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <TInput size="large" placeholder={$st("circledw_addCard_located")} autoComplete="off" error={cityErr} />
                                </Form.Item>
                            )}
                            {bankType === BankType.IBAN && (
                                <Form.Item
                                    name={"iban_code"}
                                    normalize={(value) => value.replace(/\s/g, "").toUpperCase()}
                                    rules={[
                                        () => ({
                                            validator(_, value) {
                                                if (value.length === 0) {
                                                    return Promise.reject($st("mine_bank_iban_code_input_none"));
                                                }
                                                if (!isValidIBAN(value)) {
                                                    return Promise.reject($st("mine_bank_iban_code_input_error"));
                                                }
                                                if (extractIBAN(value).countryCode !== countryCode) {
                                                    return Promise.reject($st("mine_bank_iban_country_no_match_country"));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <TInput size="large" placeholder={$st("circledw_addCard_iban_code")} autoComplete="off" error={ibanCodeErr} />
                                </Form.Item>
                            )}
                            {bankType === BankType.ABA && (
                                <>
                                    <Form.Item name={"transferType"} className="hidden" rules={[{ required: true }]}>
                                        <TSelect size="large" placeholder={$st("fbo_addCard_transfer_type")}>
                                            {/*{[*/}
                                            {/*    { value: SignatureTransferType.WIRE, label: $st("fbo_addCard_transfer_type_wire") },*/}
                                            {/*    { value: SignatureTransferType.ACH, label: $st("fbo_addCard_transfer_type_ach") },*/}
                                            {/*].map(({ value, label }) => {*/}
                                            {/*    return (*/}
                                            {/*        <TSelect.Option key={value} value={value} label={label}>*/}
                                            {/*            {value === SignatureTransferType.ACH ? (*/}
                                            {/*                <span>*/}
                                            {/*                    <span>{label} </span>*/}
                                            {/*                    <span className={"line-through"}>${fee}</span>*/}
                                            {/*                    <span>{$st("fbo_ach_0fee_des", { fee: activity_display })}</span>*/}
                                            {/*                </span>*/}
                                            {/*            ) : (*/}
                                            {/*                `${label} $${fee}`*/}
                                            {/*            )}*/}
                                            {/*        </TSelect.Option>*/}
                                            {/*    );*/}
                                            {/*})}*/}
                                        </TSelect>
                                    </Form.Item>
                                    <Form.Item
                                        name={"routing_number"}
                                        normalize={(value) => value.replace(/\s/g, "").toUpperCase()}
                                        rules={[
                                            () => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.reject($st("mine_bank_aba_code_input_none"));
                                                    }
                                                    if (!/^\d{9}$/.test(value)) {
                                                        return Promise.reject($st("mine_bank_aba_code_input_error"));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <TInput size="large" placeholder={$st("fbo_addCard_aba_routing")} autoComplete="off" error={routingNumberErr} />
                                    </Form.Item>
                                    <Form.Item
                                        name={"bank_name"}
                                        extra={<div className={cs.nameTips}>{$st("bank_name_tips")}</div>}
                                        rules={[
                                            () => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.reject($st("mine_bank_name_input_none"));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <TInput size="large" placeholder={$st("fbo_addCard_aba_bank_name")} autoComplete="off" error={bankNameErr} />
                                    </Form.Item>
                                </>
                            )}
                            {bankType === BankType.SWIFT && (
                                <Form.Item
                                    name={"swift_bic"}
                                    normalize={(value) => value.replace(/\s/g, "").toUpperCase()}
                                    rules={[
                                        () => ({
                                            validator: async (_, value) => {
                                                if (!value) {
                                                    return Promise.reject($st("mine_bank_swift_code_input_none"));
                                                }
                                                if (!isValidBIC(value)) {
                                                    return Promise.reject($st("mine_bank_swift_code_input_error"));
                                                }
                                                const swiftInfo = extractBIC(value);
                                                if (swiftInfo.countryCode !== countryCode) {
                                                    return Promise.reject($st("mine_bank_swift_country_no_match_country"));
                                                }
                                                const bankInfo = await loadBankInfoBySwift(value);
                                                if (bankInfo) {
                                                    if (bankInfo.countryCode === countryCode) {
                                                        if (!bankInfo.bank || !bankInfoReg.test(bankInfo.bank)) {
                                                            return Promise.reject($st("mine_bank_swift_code_input_error"));
                                                        }
                                                    }
                                                }
                                                if (!bankInfo) {
                                                    return Promise.reject($st("mine_bank_swift_code_input_error"));
                                                }
                                            },
                                        }),
                                    ]}
                                >
                                    <TInput size="large" placeholder={$st("circledw_addCard_swift_code")} autoComplete="off" error={swiftErr} />
                                </Form.Item>
                            )}
                            {[BankType.ABA, BankType.SWIFT].includes(bankType) && (
                                <Form.Item
                                    name={"account_number"}
                                    normalize={(value) => value.replace(/(\d{4})(?=\d)/g, "$1 ")}
                                    rules={[
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return Promise.reject($st("mine_band_add_bank_number_none"));
                                                }
                                                const val = value.replace(/\s/g, "");
                                                if (val.length < 4 || !bankCardPureNumberReg.test(val)) {
                                                    return Promise.reject($st("mine_band_add_bank_number_aba_input_error"));
                                                }

                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <TInput size="large" placeholder={$st("circledw_addCard_bank_number")} autoComplete="off" error={accountNumberErr} />
                                </Form.Item>
                            )}
                        </>
                    );
                }}
            </Form.Item>
            <div className={cs.step}>{$st("signature_dw_withdraw_profile_info")}</div>
            <Form.Item noStyle shouldUpdate>
                {({ getFieldError, getFieldValue }) => {
                    const countryCode = getFieldValue("countryCode");

                    const [nameErr] = getFieldError("name") || [];
                    const [cityErr] = getFieldError("city") || [];
                    const [streetErr] = getFieldError("street") || [];
                    const [postErr] = getFieldError("postal_code") || [];
                    return (
                        <>
                            <Form.Item
                                name={"name"}
                                normalize={(value) => value.toUpperCase()}
                                rules={[
                                    () => ({
                                        validator(_, originValue = "") {
                                            return validateCardName($st, originValue);
                                        },
                                    }),
                                ]}
                            >
                                <FormItemTransition<string>>
                                    {({ value, onChange }) => (
                                        <>
                                            <TInput
                                                value={value}
                                                onChange={(e) => {
                                                    onChange?.(e.target.value);
                                                }}
                                                size="large"
                                                placeholder={$st("circledw_addCard_step2_name")}
                                                autoComplete="off"
                                                error={nameErr}
                                            />
                                            <div className={cs.nameTips}>{$st("signature_dw_add_card_name_tips")}</div>
                                        </>
                                    )}
                                </FormItemTransition>
                            </Form.Item>
                            <Form.Item
                                name="countryCode"
                                rules={[
                                    () => ({
                                        validator(_, value = "") {
                                            if (!value) {
                                                return Promise.reject($st("kyc_choose_Country"));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <TSelect showSearch filterOption={handleFilterOption} size="large" placeholder={$st("circledw_addCard_step2_country")}>
                                    {countryOptions}
                                </TSelect>
                            </Form.Item>
                            {isNeedState(countryCode) && (
                                <Form.Item
                                    name="stateCode"
                                    rules={[
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return Promise.reject($st("circledw_bank_select_state"));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <TSelect showSearch filterOption={handleFilterOption} size="large" placeholder={$st("circledw_bank_select_state")}>
                                        {getCircleStates(countryCode).map((item) => {
                                            return (
                                                <TSelect.Option key={item.code} value={item.code} label={item.name}>
                                                    {item.name}
                                                </TSelect.Option>
                                            );
                                        })}
                                    </TSelect>
                                </Form.Item>
                            )}
                            <Form.Item
                                name={"city"}
                                rules={[
                                    () => ({
                                        validator(_, value = "") {
                                            const noSpaceValue = value.replace(/\s/g, "");
                                            if (noSpaceValue.length < 1) {
                                                return Promise.reject($st("circledw_addCard_step2_city"));
                                            }
                                            if (noSpaceValue.length > CITY_MAX_LENGTH) {
                                                return Promise.reject($st("common_length_max", { length: CITY_MAX_LENGTH }));
                                            }
                                            if (!bankInfoReg.test(value)) {
                                                return Promise.reject($st("mine_band_add_info_not_english_error"));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <TInput size="large" placeholder={$st("circledw_addCard_step2_city")} autoComplete="off" error={cityErr} />
                            </Form.Item>
                            <Form.Item
                                name={"street"}
                                rules={[
                                    () => ({
                                        validator(_, value = "") {
                                            const realAddress = value.replace(/\s+/g, " ").trim();
                                            const noSpaceValue = realAddress.replace(/\s/g, "");
                                            if (noSpaceValue.length < 1) {
                                                return Promise.reject($st("mine_band_cardholder_address_none"));
                                            }
                                            if (noSpaceValue.length > STREET_MAX_LENGTH) {
                                                return Promise.reject($st("common_length_max", { length: STREET_MAX_LENGTH }));
                                            }
                                            if (!bankInfoReg.test(realAddress)) {
                                                return Promise.reject($st("mine_band_add_info_not_english_error"));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <TInput.TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder={$st("circledw_addCard_step2_street")} autoComplete="off" error={streetErr} />
                            </Form.Item>

                            <Form.Item
                                name={"postal_code"}
                                rules={[
                                    () => ({
                                        validator(_, value = "") {
                                            if (value.replace(/\s/g, "").length < 1) {
                                                return Promise.reject($st("mine_band_cardholder_postal_none"));
                                            }
                                            if (!postalReg.test(value)) {
                                                return Promise.reject($st("mine_band_cardholder_postal_format_error"));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <TInput size="large" placeholder={$st("circledw_addCard_post_code")} autoComplete="off" error={postErr} />
                            </Form.Item>
                        </>
                    );
                }}
            </Form.Item>
            <div className={cs.cardLabel}>{$st("withdraw_upLoadDoc")}</div>
            <div className={cs.cardWarn}>
                {/* <div className={cs.cardDesc}>{$st("withdraw_upLoadDocDesc")}</div>
                <div className={cs.cardRequire}>{$st("withdraw_upLoadDocRequire", { br: <br /> })}</div> */}
                <div>{$st("withdraw_upLoadDocRequireDec", { br: <br />, strong: (t) => <strong>{t}</strong> })}</div>
            </div>
            <div className={cs.addCardUpload}>
                <Uploader onFileUploaded={setFile} setImgErrors={setImgErrors} minSize={10} />
            </div>
            <div className={cs.addCardBtns}></div>
        </Form>
    );
});

export default CardInfoForm;
