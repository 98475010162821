export enum SymbolSortMethod {
    PAIR_ASC,
    PAIR_DESC,
    VOL_ASC,
    VOL_DESC,
    PRICE_ASC,
    PRICE_DESC,
    CHANGE_ASC,
    CHANGE_DESC,
    AI_ASC,
    AI_DESC,
    NONE = "NONE",
}
