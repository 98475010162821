export enum CodeInputType {
    none,
    otp,
    email,
    sms,
}
export enum Restrict_Reason {
    remote_login = "remote_login",
    change_password = "change_password",
    confirm_otp = "confirm_otp",
    unbind_otp = "unbind_otp",
    admin_unbind_otp = "admin_unbind_otp",
    admin_unbind_acct = "admin_unbind_acct",
}

export interface RestrictData {
    restrict_withdraw: boolean;
    restrict_reason: Restrict_Reason;
    restrict_expired_on: number;
}
