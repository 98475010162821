import React from "react";
import { LocaleData } from "commonActions/reducers";
import { AppLangInfoContext } from "state/Contexts";
import { useIntl } from "react-intl";
import { useTranslators } from "./Locale";

/**
 * @deprecated Use {@link useIntl} or {@link useTranslators} instead.
 * @returns
 */
export default function useAppLangInfo(): LocaleData {
    return React.useContext(AppLangInfoContext);
}
