import { Checkbox, Modal, Radio } from "@pionex-web-kit/components";
import { AccountInfoTag, AccountType } from "bu_account_js_sdk";
import { useLocaleIpInfo } from "commonUse/tools";
import { useTrackFn } from "@pionex-web-kit/common-business";
import { useCallback, useEffect, useMemo, useState } from "react";
import { history } from "src/landings";
import { getAccountCountryList } from "trade_lib_wrapper";
import useAccountInfo from "commonUse/useAccountInfo";
import { useTranslators } from "commonUse/Locale";
import { OtpState } from "state/AccountInfo";

/* 验证类型 */
export enum VerifyType {
    google = "google", // 谷歌验证器
    email = "email", // 邮箱验证
    phone = "phone", // 手机号认证
}

/* 验证URL */
export enum VerifyUrl {
    google = "/googleAuthenticator",
    email = "/account/bind",
    phone = "/account/bind",
}

/* 获取用户类型信息，判断用户的手机、邮箱以及谷歌认证情况 */
export function useUserTypeInfo() {
    const { otpState: googleVerify, accounts } = useAccountInfo();
    const { isPhoneAccount, isEmailAccount, isPhoneBind, isEmailBind } = useMemo(() => {
        let isPhoneAccount = false; // 是否手机号注册
        let isEmailAccount = false; // 是否邮箱注册
        let isPhoneBind = false; // 是否绑定手机号
        let isEmailBind = false; // 是否绑定邮箱
        accounts?.forEach(({ account_type, account_info_tag }) => {
            if (account_info_tag === AccountInfoTag.master) {
                if (account_type === AccountType.email) {
                    isEmailAccount = true;
                } else if (account_type === AccountType.phoneNumber) {
                    isPhoneAccount = true;
                }
            } else if (account_type === AccountType.email) {
                isEmailBind = true;
            } else if (account_type === AccountType.phoneNumber) {
                isPhoneBind = true;
            }
        });
        return {
            isPhoneAccount,
            isEmailAccount,
            isPhoneBind,
            isEmailBind,
        };
    }, [accounts]);
    const isGoogleBind = !googleVerify || googleVerify === OtpState.BOUND; // 是否绑定谷歌验证
    return { isPhoneAccount, isEmailAccount, isPhoneBind, isEmailBind, isGoogleBind };
}

/* 两步验证弹窗今日是否提醒 */
export function twoStepVerifyModalIsTodayShow() {
    const disableTimeStr = localStorage.getItem("verifyModalDisableTime"); // 初始化获取失效时间
    if (!disableTimeStr) {
        return true;
    }
    const endTime = new Date(+disableTimeStr).setHours(23, 59, 59); // 失效时间当天23:59:59
    const nowTime = Date.now();
    // 系统当前时间大于失效时间当天23:59:59
    return nowTime > endTime;
}

export function useTwoStepState() {
    const { isPhoneAccount, isEmailAccount, isPhoneBind, isEmailBind, isGoogleBind } = useUserTypeInfo();
    const emailNoPhoneOrGoogle = isEmailAccount && !isGoogleBind && !isPhoneBind; // 邮箱账号未绑定谷歌和手机
    const phoneNoEmailOrGoogle = isPhoneAccount && !isGoogleBind && !isEmailBind; // 手机账号未绑定谷歌和邮箱
    // 是否完成两步验证
    const noTwoFactor = useMemo(() => emailNoPhoneOrGoogle || phoneNoEmailOrGoogle, [emailNoPhoneOrGoogle, phoneNoEmailOrGoogle]);

    return {
        noTwoFactor,
        isPhoneAccount,
        isEmailAccount,
        isPhoneBind,
        isEmailBind,
        isGoogleBind,
    };
}

/* 两步验证弹窗是否启用 */
export function useTwoStepVerifyModalState() {
    const { noTwoFactor: shouldVerifyModal, ...resetState } = useTwoStepState();

    return {
        ...resetState,
        shouldVerifyModal,
        isTodayShowFn: twoStepVerifyModalIsTodayShow,
    };
}

interface Props {
    scene: "account_sign" | "trading";
    visible: boolean;
    onCancel?();
    disableToday?: boolean; // 不展示"今日不显示"
    onVisibleChange?: (visible: boolean) => void;
}

export default function TwoStepVerifyModal(props: Props) {
    const { scene, visible, onCancel, disableToday, onVisibleChange } = props;
    const [verifyMode, setVerifyMode] = useState<VerifyType>(VerifyType.google); // 验证方式
    const [verifyModalDisableTime, setVerifyModalDisableTime] = useState<number>(); // 验证弹窗失效时间
    const { isPhoneAccount, isEmailAccount, isPhoneBind, isEmailBind, isGoogleBind } = useUserTypeInfo();
    const emailNoPhoneOrGoogle = isEmailAccount && !isGoogleBind && !isPhoneBind; // 邮箱账号未绑定谷歌和手机
    const phoneNoEmailOrGoogle = isPhoneAccount && !isGoogleBind && !isEmailBind; // 手机账号未绑定谷歌和邮箱
    const { loading, ipInfo } = useLocaleIpInfo(); // 识别的IP地址信息
    const supportedCountryList = getAccountCountryList(true, false); // 支持手机号注册的国家列表
    const { $st } = useTranslators();
    /* 根据ip地址，判断是否支持手机号注册 */
    const ipIsSupportPhoneAccount = useMemo(() => {
        if (!ipInfo || !supportedCountryList || PLATFORM.PIONEX_COL) {
            return false;
        }
        const { country_code } = ipInfo;
        return !!supportedCountryList.find(({ code }) => code === country_code);
    }, [ipInfo, supportedCountryList]);
    /* 更新localStorage中的失效时间 */
    const updateStorangeDisableTime = useCallback(() => {
        if (verifyModalDisableTime) {
            localStorage.setItem("verifyModalDisableTime", `${verifyModalDisableTime}`);
        } else {
            localStorage.removeItem("verifyModalDisableTime");
        }
    }, [verifyModalDisableTime]);

    const onVisibleTrack = useTrackFn(() => {
        return {
            platform: "ga",
            key: "pionex_account_bind_guide",
            data: {
                value1: scene,
            },
        };
    });

    const onActionTrack = useTrackFn((action: "bind" | "close", value?: string) => {
        return {
            platform: "ga",
            key: "account_bind_perform",
            data: { action, value },
        };
    });

    useEffect(() => {
        if (visible) {
            onVisibleTrack();
        }
    }, [onVisibleTrack, visible]);
    useEffect(() => {
        onVisibleChange?.(visible);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const onOkEvt = useCallback(() => {
        if (verifyModalDisableTime) {
            // 如果勾选了“今日不再提醒”，则更新到localStorage
            updateStorangeDisableTime();
            onCancel?.();
            return;
        }
        onActionTrack("bind", verifyMode);
        setTimeout(() => {
            history.push(VerifyUrl[verifyMode]); // 跳转到对应的验证方式绑定页面
        }, 0);
        onCancel?.();
    }, [onActionTrack, onCancel, updateStorangeDisableTime, verifyModalDisableTime, verifyMode]);
    /* 验证方式change */
    const verifyModeChange = useCallback((e) => {
        setVerifyMode(e.target.value);
    }, []);
    /* 失效时间change */
    const verifyModalDisableTimeChange = useCallback(
        (e) => {
            setVerifyModalDisableTime(e.target.checked ? Date.now() : 0);
        },
        [setVerifyModalDisableTime],
    );
    const tipText = useMemo(() => {
        return $st("email_or_phone_verify_tip", { verifyMode: isPhoneAccount ? $st("account_type_email") : $st("two_step_type_phone") });
    }, [$st, isPhoneAccount]);
    useEffect(() => {
        if (emailNoPhoneOrGoogle) {
            setVerifyMode(ipIsSupportPhoneAccount ? VerifyType.phone : VerifyType.google); // 如果是邮箱账号未绑定谷歌和手机，验证方式默认选中手机号认证; 如果ip不支持手机号，验证方式默认选中谷歌认证
        } else if (phoneNoEmailOrGoogle) {
            setVerifyMode(VerifyType.google); // 如果是手机账号未绑定谷歌和邮箱，验证方式默认选中谷歌认证
        }
    }, [emailNoPhoneOrGoogle, ipIsSupportPhoneAccount, phoneNoEmailOrGoogle]);
    return !loading ? (
        <Modal
            title={ipIsSupportPhoneAccount ? $st("bind_two_step_verify_title") : $st("factor_auth_title")}
            visible={visible}
            onCancel={() => {
                if (verifyModalDisableTime) {
                    // 如果勾选了“今日不再提醒”，则更新到localStorage
                    updateStorangeDisableTime();
                }
                onActionTrack("close");
                onCancel?.();
            }}
            onOk={onOkEvt}
            okText={Number(verifyModalDisableTime) > 0 ? $st("trade_menu_title") : $st("two_step_ok")}
            cancelText={$st("button_cancel")}
        >
            <div className="flex flex-col">
                {!ipIsSupportPhoneAccount && isEmailAccount ? (
                    <div className="text-accent">{$st("factor_auth_content")}</div>
                ) : (
                    <>
                        <div className="text-accent-sub leading-[20px]">{tipText}</div>
                        {phoneNoEmailOrGoogle && (
                            <Radio.Group onChange={verifyModeChange} value={verifyMode} className="flex flex-col gap-[20px] mt-[22px] mb-[12px]">
                                <Radio value={VerifyType.google}>{$st("bind_otp_radio_title")}</Radio>
                                <Radio value={VerifyType.email}>{$st("bind_email_radio_title")}</Radio>
                            </Radio.Group>
                        )}
                        {emailNoPhoneOrGoogle && (
                            <Radio.Group onChange={verifyModeChange} value={verifyMode} className="flex flex-col gap-[20px] mt-[22px] mb-[12px]">
                                <Radio value={VerifyType.phone}>{$st("bind_phone_radio_title")}</Radio>
                                <Radio value={VerifyType.google}>{$st("bind_otp_radio_title")}</Radio>
                            </Radio.Group>
                        )}
                    </>
                )}
                {disableToday || (
                    <Checkbox onChange={verifyModalDisableTimeChange} className="mt-[16px] text-[14px] !text-accent-sub ">
                        {$st("notify_wont_today")}
                    </Checkbox>
                )}
            </div>
        </Modal>
    ) : null;
}
