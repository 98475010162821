import useAccountInfo from "commonUse/useAccountInfo";
import { BankCardInfo, BankStatus, CircleDWAPI } from "compliance_lib";
import { useMemo } from "react";
import { $st as tst } from "utils/i18n";

/**
 * Circle相关API请求的实例
 */
export function useCircleDWAPI() {
    const firstApiKey = useAccountInfo().firstApiKey;

    return useMemo(() => {
        if (!firstApiKey) {
            return;
        }
        return new CircleDWAPI(firstApiKey);
    }, [firstApiKey]);
}

/**
 * 解析银行卡相关流程接口错误码
 * @param code
 */
export function analysisBankCorrelationError(err: { code: number; message: string }, $st: typeof tst): string {
    switch (err.code) {
        case 40090001: {
            return $st("bank_add_error_40090001");
        }
        case 40090002: {
            return $st("bank_add_error_40090002");
        }
        case 40090003: {
            return $st("bank_add_error_40090003");
        }
        case 40091032: {
            return $st("bank_add_error_40091032");
        }
        case 40091051: {
            return $st("bank_add_error_40091051");
        }
        case 40091068: {
            return $st("bank_add_error_40091068");
        }
        case 40091069: {
            return $st("bank_add_error_40091069");
        }
        case 40091070: {
            return $st("bank_add_error_40091070");
        }
        case 40091076: {
            return $st("bank_add_error_40091076");
        }
        case 40091077: {
            return $st("bank_add_error_40091077");
        }
        case 40091078: {
            return $st("bank_add_error_40091078");
        }
        case 40091084: {
            return $st("bank_add_error_40091084");
        }
        case 40091085: {
            return $st("bank_add_error_40091085");
        }
        case 40091086: {
            return $st("bank_add_error_40091086");
        }
        case 40091087: {
            return $st("bank_add_error_40091087");
        }
        case 40091088: {
            return $st("bank_add_error_40091088");
        }
        case 40091089: {
            return $st("bank_add_error_40091089");
        }
        case 40091091: {
            return $st("bank_add_error_40091091");
        }
        case 40091092: {
            return $st("bank_add_error_40091092");
        }
        case 40091093: {
            return $st("bank_add_error_40091093");
        }
        case 40091094: {
            return $st("bank_add_error_40091094");
        }
        case 40091096: {
            return $st("bank_add_error_40091096");
        }
        case 40091097: {
            return $st("bank_add_error_40091097");
        }
        case 40092003: {
            return $st("bank_add_error_40092003");
        }
        case 40092004: {
            return $st("bank_add_error_40092004");
        }
        case 40092005: {
            return $st("bank_add_error_40092005");
        }
        case 40020325: {
            return $st("bank_add_error_40020325");
        }
        case 40020326: {
            return $st("bank_add_error_40020326");
        }
        case 40020327: {
            return $st("bank_add_error_40020327");
        }
        case 40020928: {
            return $st("bank_add_error_40020928");
        }
        case 40020929: {
            return $st("bank_add_error_40020929");
        }
        case 40920630: {
            return $st("bank_add_error_40920630");
        }
        default: {
            return `${$st("common_unknown_error")} \n${err.message}`;
        }
    }
}

export const formatCardNumber = (cardNumber: string, showAll = false) => {
    if (showAll) {
        return cardNumber.replace(/(\d{4})(?=\d)/g, "$1 ");
    }
    if (cardNumber && cardNumber.length > 8) {
        return `${cardNumber.substring(0, 4)} ${"*".repeat(8).replace(/(.{4})/g, `$1 `)}${cardNumber.length % 4 ? " " : ""}${cardNumber.slice(-4)}`;
    } else {
        return `${cardNumber.substring(0, 2)} ${"*".repeat(cardNumber.length - 4 > 0 ? cardNumber.length - 4 : 4).replace(/(.{4})/g, `$1 `)}${
            cardNumber.length % 4 ? " " : ""
        }${cardNumber.slice(-2)}`;
    }
};

/**
 * 银行卡整体的状态
 */
export enum CardsVerifyStatus {
    VERIFIED = "verified", // 有认证通过的卡
    UNVERIFIED = "unverified", // 没有认证通过的卡
    FAILED = "failed", // 没有认证通过的卡，且有拒绝的卡
    DEPOSITED = "deposited", // 有认证通过的且入金过卡
}

/**
 * 银行卡整体的认证情况
 * @param cards
 */
export function useCardsVerifyInfo(cards: BankCardInfo[]): { verifyStatus: CardsVerifyStatus; failedCard?: BankCardInfo; deposited?: BankCardInfo } {
    return useMemo(() => {
        if (cards.some((item) => item.bankStatus === BankStatus.approved)) {
            // 有认证通过的卡
            return { verifyStatus: CardsVerifyStatus.VERIFIED };
        }

        const failedCard = cards.find((item) => item.bankStatus === BankStatus.rejected);
        if (!!failedCard) {
            // 没有认证通过的卡，且有拒绝的卡
            return { verifyStatus: CardsVerifyStatus.FAILED, failedCard: failedCard };
        }

        const depositedCard = cards.find((item) => item.bankActivated);
        if (!!depositedCard) {
            // 有认证通过的且入金过卡
            return { verifyStatus: CardsVerifyStatus.DEPOSITED, deposited: failedCard };
        }

        // 没有认证通过的卡
        return { verifyStatus: CardsVerifyStatus.UNVERIFIED };
    }, [cards]);
}
