import { Alert } from "antd";
import React from "react";
import { $st } from "utils/i18n/index";
import css from "./cs.m.less";
import useMedia from "src/components-v2/Layout/hooks/useMedia";
import { useIntl } from "react-intl";
import { getLocaleDownloadLink } from "../Header.v2/utils";
import { report } from "utils/GoogleTagManager";
import LocalStorageUtils from "utils/LocalStorageUtils";

const Index = ({ onClose }: { onClose?: () => void }) => {
    const { isPlatformBaidu } = useMedia();
    const { locale } = useIntl();
    return (
        <Alert
            className={css.download_alert_box}
            message={
                <div className={css.download_alert}>
                    <div className={css.download_alert_left}>
                        <div className={css.download_alert_logo_box}>
                            {isPlatformBaidu ? null : <img className={css.download_alert_logo} src={require("images/v2/home/img_logo_APP.png")} alt="" width={60} height={60} />}
                        </div>
                        <div className={css.download_alert_txtbox}>
                            <h5>
                                <div className={css.download_alert_name}>{PLATFORM.PIONEX_US_LIKE ? "Pionex.US" : "Pionex"}</div>
                            </h5>
                            <p className={css.download_alert_desc}>{$st("homepage_download_alert_desc")}</p>
                        </div>
                    </div>

                    <div className={css.download_alert_down_btnbox}>
                        <button
                            className={css.download_alert_down_btn}
                            onClick={() => {
                                window.open(getLocaleDownloadLink(locale), "_blank");
                                report("go_download_app", {
                                    position: "phone_header_view",
                                    ref: LocalStorageUtils.getReferralCode(),
                                });
                            }}
                            type="button"
                        >
                            {$st("downloadapp")}
                        </button>
                    </div>
                </div>
            }
            banner
            closable
            showIcon={false}
            onClose={onClose}
        />
    );
};

export default Index;
