import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Tabs } from "@pionex-web-kit/components";
import StripeAmount from "landings/V2/BuyCrypto/StripeACH/StripeAmount";
import ACHWithdraw from "landings/V2/CircleACH/Withdraw";
import { DepositType } from "landings/Balance/components/Root";
import SignatureDeposit from "landings/V2/SignatureDW/Deposit";
import SignatureWithdraw from "landings/V2/SignatureDW/Withdraw";
import DebitDeposit from "landings/V2/CircleDebit/Deposit";
import cs from "./index.module.less";
import CircleDebitWrapper from "landings/V2/CircleDebit";
import TReportLink from "components/v2/TinyComps";
import { IconHistory24px } from "@pionex-web-kit/icons";
import { useTranslators } from "commonUse/Locale";
import { useParams } from "react-router";
import { history } from "utils/History";
import { useRequestAllChannelStatus } from "landings/V2/USDDW/hooks";
import { USDDWContext } from "landings/V2/USDDW/context";
import DataReporter from "landings/Analytics/DataReporter";
import useKycV2Info from "commonUse/useKycInfoV2";
import useAccountInfo from "commonUse/useAccountInfo";
import dayjs from "dayjs";

export const DepositUSD = ({ type }: { type: string }) => {
    const { $st } = useTranslators();
    const { menuType, subType } = useParams<{ menuType: string; subType: string }>();
    const { data: channelStatusInfo } = useRequestAllChannelStatus();
    const [kycV2Info] = useKycV2Info();
    const account = useAccountInfo();
    const ageDate = new Date(kycV2Info.originData?.userInfo?.dob ?? 0);
    const age = ageDate.getFullYear();
    const createDate = new Date(account.createTime ?? 0);
    const createYear = createDate.getFullYear();
    const date = dayjs("2024-07-24 00:00:00"); // 使用本地时间，没有时区信息
    const timestamp = date.valueOf();

    const showWire = useMemo(() => {
        if (type === DepositType.wire) {
            if (timestamp < (account.createTime ?? 0)) {
                if (createYear - age < 65) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    }, [account.createTime, age, createYear, timestamp, type]);

    const [activeKey, setActiveKey] = useState<string>(subType || "deposit");
    useEffect(() => {
        if (!showWire) {
            setActiveKey("withdraw");
            return;
        }
        setActiveKey(subType || "deposit");
    }, [showWire, subType]);
    const depositWithdraw = useMemo(() => {
        switch (type) {
            case DepositType.ach:
                return {
                    deposit: <StripeAmount />,
                    withdraw: <ACHWithdraw />,
                };
            case DepositType.wire:
                return {
                    deposit: <SignatureDeposit />,
                    withdraw: <SignatureWithdraw />,
                };
            case DepositType.debit:
                return {
                    deposit: (
                        <CircleDebitWrapper>
                            <DebitDeposit />
                        </CircleDebitWrapper>
                    ),
                };
            default:
                return {
                    deposit: <StripeAmount />,
                    withdraw: <ACHWithdraw />,
                };
        }
    }, [type]);

    const onChange = useCallback(
        (key: string) => {
            if (type === DepositType.ach && key === "withdraw") {
                DataReporter.report("tab_withdraw_account");
            } else if (type === DepositType.wire && key === "withdraw") {
                DataReporter.report("tab_withdraw_wiretransfer");
            }
            history.push(`/balance/${type}/${key}`);
        },
        [type],
    );

    const tabsOption = useMemo(() => {
        return [
            // { key: "deposit", label: "Deposit", children: depositWithdraw.deposit },
            ...(showWire
                ? [
                      {
                          key: "deposit",
                          label: "Deposit",
                          children: depositWithdraw.deposit,
                      },
                  ]
                : []),
            ...(type !== DepositType.debit
                ? [
                      {
                          key: "withdraw",
                          label: "Withdraw",
                          children: depositWithdraw.withdraw,
                      },
                  ]
                : []),
        ];
    }, [depositWithdraw.deposit, depositWithdraw.withdraw, showWire, type]);

    return (
        <div className="bg-card">
            <USDDWContext.Provider value={{ channelStatusInfo }}>
                <div className={cs.container}>
                    <Tabs
                        items={tabsOption}
                        activeKey={activeKey}
                        onChange={onChange}
                        type="line"
                        tabBarExtraContent={
                            <TReportLink to={`/orders/${activeKey}/${type}`} className="text-accent flex justify-end items-center mt-20px">
                                <IconHistory24px size={16} className="mr-6px" />
                                {$st("buy_crypto_history")}
                            </TReportLink>
                        }
                    />
                </div>
            </USDDWContext.Provider>
        </div>
    );
};
