import { CodeInputModal, CodeInputModalRef, SendType } from "@pionex-web-kit/pro-components";
import { useAvailableVerifyInfo } from "account/hook";
import { message } from "antd";
import useAccountInfo from "commonUse/useAccountInfo";
import { TModal, TSwitch } from "components/v2/TinyComps";
import React, { useCallback, useRef, useState } from "react";
import { SHOW_GA_BIND_GUIDE_REF } from "src/components-v2/Layout/GlobalMessages/GABindGuide";
import { VerifyCodeAction } from "trade_asset_lib";
import { $st } from "utils/i18n";
import { WhiteListStatus, commonErrorParser, setWhiteListStatus } from "../../api";
import { useVerifyTypes } from "../../hooks";
import cs from "./cs.m.less";
import { useCallbackStatic } from "commonUse/tools";

const OnlyWhiteList = ({ onlyWhiteList, setOnlyWhiteList }: { onlyWhiteList: boolean; setOnlyWhiteList: (boolean) => void }) => {
    const [targetStatus, setTargetStatus] = useState(WhiteListStatus.off);
    const verifyInfo = useAvailableVerifyInfo();
    const accountInfo = useAccountInfo();
    const codeInputModalRef = useRef<CodeInputModalRef>(null);
    const verifyTypes = useVerifyTypes();

    const submitVerify = useCallbackStatic((verifyInfo) => {
        setWhiteListStatus(targetStatus, {
            sms_code: verifyInfo?.sms_code,
            email_code: verifyInfo?.email_code,
            otp_code: verifyInfo?.otp_code,
        }).subscribe(
            () => {
                message.success(targetStatus === WhiteListStatus.on ? $st("whitelist_status_turn_on_success") : $st("whitelist_status_turn_off_success"));
                setOnlyWhiteList(targetStatus === WhiteListStatus.on);
                codeInputModalRef.current?.dismiss();
            },
            (error) => {
                codeInputModalRef.current?.retry();
                commonErrorParser(error.code, $st, error.message);
            },
        );
    });

    const onOnlyWhiteListChange = useCallback(
        (checked) => {
            if (SHOW_GA_BIND_GUIDE_REF.showIfNeed(true)) {
                // 二次验证没有绑定或者确认
                return;
            }
            const handleOk = () => {
                codeInputModalRef.current?.request({
                    params: {
                        verifyTypes,
                        action: checked ? VerifyCodeAction.open_whitelist_withdraw : VerifyCodeAction.close_whitelist_withdraw,
                        sendType: SendType.manualSend,
                        account: accountInfo.masterAccount?.account,
                        maskAccounts: verifyInfo.maskAccounts,
                    },
                    onOk: submitVerify,
                    onCancel: codeInputModalRef.current?.dismiss,
                });
            };

            setTargetStatus(checked ? WhiteListStatus.on : WhiteListStatus.off);
            if (checked) {
                TModal.confirm({
                    title: $st("whitelist_status_turn_on_title"),
                    icon: null,
                    content: $st("whitelist_status_turn_on_desc"),
                    onOk: handleOk,
                });
            } else {
                TModal.confirm({
                    title: $st("whitelist_status_turn_off_title"),
                    icon: null,
                    content: $st("whitelist_status_turn_off_desc"),
                    onOk: handleOk,
                });
            }
        },
        [accountInfo.masterAccount?.account, submitVerify, targetStatus, verifyInfo.maskAccounts, verifyTypes],
    );

    return (
        <div className={cs.onlyWhiteList}>
            <div className={cs.onlyWhiteListText}>
                <div className={cs.onlyWhiteListTitle}>{$st("whitelist_withdraw_only")}</div>
                <div className={cs.onlyWhiteListWarn}>{$st("whitelist_withdraw_only_desc")}</div>
            </div>
            <div className={cs.onlyWhiteListSwitch}>
                <TSwitch checked={onlyWhiteList} onChange={onOnlyWhiteListChange} />
            </div>

            <CodeInputModal
                ref={codeInputModalRef}
                changeVerificationDropdownProps={{
                    trigger: ["click", "hover"],
                }}
            />
        </div>
    );
};
export default OnlyWhiteList;
