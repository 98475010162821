import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { announcementListPost, notificationAllReadPost, notificationListGet, notificationReadPost, notificationUnreadCountGet } from "../api/api";
import { AnnouncementListItem, NotificationListItem, NotificationUnreadCountData, NotificationUnreadSplash } from "../api/interface";
import { requestWithRetry, SafeDecimal } from "trade_utils_lib";
import LocalStorageUtils, { LocalStorageKey } from "utils/LocalStorageUtils";
import { flatMap } from "rxjs/operators";
import { of, timer } from "rxjs";
import useAccountInfo from "commonUse/useAccountInfo";
import { message } from "@pionex-web-kit/components";
import { ParseErrorUtils } from "trade_lib_wrapper";
import { BehaviorSubject } from "rxjs";

export const NOTIFICATION_ITEM_STATUS_REFRESH_KEY = "NOTIFICATION_ITEM_STATUS_REFRESH_KEY";
export const NOTIFICATION_ALL_STATUS_REFRESH_KEY = "NOTIFICATION_ALL_STATUS_REFRESH_KEY";
export const NOTIFICATION_UNREAD_COUNT_UPDATE_KEY = "NOTIFICATION_UNREAD_COUNT_UPDATE_KEY";

export const announcementLoadedSubject = new BehaviorSubject<boolean>(false);

export function useAnnouncementLoaded() {
    const refresh = useCallback(() => {
        announcementLoadedSubject.next(true);
    }, []);
    return refresh;
}

export function useAnnouncementReset() {
    const refresh = useCallback(() => {
        announcementLoadedSubject.next(false);
    }, []);
    return refresh;
}

export function useGetNotificationList(limit: number) {
    const [nextPage, setNextPage] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<NotificationListItem[]>([]);
    const nextPageRef = useRef<string>();
    const currentPageRef = useRef<string>();
    const cachedDataRef = useRef<NotificationListItem[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const isRefreshRef = useRef(false);
    const accountInfo = useAccountInfo();

    useEffect(() => {
        let sub;
        if (accountInfo.userId) {
            setLoading(true);
            isRefreshRef.current = true;
            const currentPageToken = nextPage;
            sub = notificationListGet({ next_page_token: currentPageToken, limit }).subscribe(
                ({ next_page_token, list }) => {
                    setLoading(false);
                    isRefreshRef.current = false;
                    currentPageRef.current = currentPageToken;
                    nextPageRef.current = next_page_token;

                    if (!next_page_token) {
                        setHasMore(false);
                    }
                    const newData = [...cachedDataRef.current, ...list];
                    cachedDataRef.current = newData;
                    setData(newData);
                },
                (err) => {
                    setLoading(false);
                    isRefreshRef.current = false;
                },
            );
        }

        return () => {
            sub?.unsubscribe();
        };
    }, [accountInfo.userId, limit, nextPage]);

    const loadMore = useCallback(() => {
        if (currentPageRef.current === nextPage && nextPageRef.current && !isRefreshRef.current) {
            setNextPage(nextPageRef.current);
        }
    }, [nextPage]);

    useEffect(() => {
        const refreshItem = (ev: CustomEvent<string>) => {
            const id = ev.detail;
            if (!id) return;
            const newData = [...cachedDataRef.current].map((item) => {
                if (item.message_id === id) {
                    return {
                        ...item,
                        read_timestamp: 1,
                    };
                }
                return item;
            });
            cachedDataRef.current = newData;
            setData(newData);
        };

        const refreshAll = () => {
            // 处理头部全部已读后没有更新问题
            const newData = [...cachedDataRef.current].map((item) => {
                return {
                    ...item,
                    read_timestamp: 1,
                };
            });
            setData(newData);
            setNextPage(undefined);
            currentPageRef.current = undefined;
            cachedDataRef.current = [];
            setHasMore(true);
        };

        window.addEventListener(NOTIFICATION_ITEM_STATUS_REFRESH_KEY, refreshItem);
        window.addEventListener(NOTIFICATION_ALL_STATUS_REFRESH_KEY, refreshAll);
        return () => {
            window.removeEventListener(NOTIFICATION_ITEM_STATUS_REFRESH_KEY, refreshItem);
            window.removeEventListener(NOTIFICATION_ALL_STATUS_REFRESH_KEY, refreshAll);
        };
    }, []);

    return useMemo(() => {
        return {
            loading,
            data,
            loadMore,
            hasMore,
        };
    }, [data, hasMore, loadMore, loading]);
}

export function notificationReadItem(id: string) {
    return notificationReadPost({ message_id: id });
}

export function notificationReadItemFn(id: string) {
    notificationReadItem(id).subscribe(
        () => {
            // todo @limao 以后要做跳转到对应业务里面，这样就不需要去刷列表了
            window.dispatchEvent(new CustomEvent(NOTIFICATION_ITEM_STATUS_REFRESH_KEY, { detail: id }));
            window.dispatchEvent(new CustomEvent(NOTIFICATION_UNREAD_COUNT_UPDATE_KEY));
        },
        (err) => {
            message.error(ParseErrorUtils.formatterToString(err));
        },
    );
}

export function useNotificationUnreadCount(uid?: string) {
    const [count, setCount] = useState(0);
    const [splash, setSplash] = useState<NotificationUnreadSplash>();

    /**
     * 10分钟刷新一下
     */
    useEffect(() => {
        let sub;
        if (uid) {
            sub = timer(0, 10 * 60 * 1000)
                .pipe(flatMap(() => notificationUnreadCountGet()))
                .subscribe(
                    ({ count, splash }) => {
                        setCount(count);
                        if (splash?.length > 0) {
                            const tmpSplash = splash[0];
                            if (tmpSplash.extra) {
                                try {
                                    const tmpItem = JSON.parse(tmpSplash.extra as any);
                                    tmpSplash.extra = tmpItem;
                                    setSplash(tmpSplash);
                                } catch (error) {}
                            }
                        }
                    },
                    (err) => {
                        console.log(JSON.stringify(err));
                    },
                );
        } else {
            setCount(0);
        }

        return () => {
            sub?.unsubscribe();
        };
    }, [uid]);

    useEffect(() => {
        const update = () => {
            requestWithRetry<NotificationUnreadCountData>(() => notificationUnreadCountGet(), {
                name: "notificationUnreadCount",
                maxRetryCount: 3,
            }).subscribe(
                ({ count, splash }) => {
                    setCount(count);
                    if (splash?.length > 0) {
                        const tmpSplash = splash[0];
                        if (tmpSplash.extra) {
                            try {
                                const tmpItem = JSON.parse(tmpSplash.extra as any);
                                tmpSplash.extra = tmpItem;
                                setSplash(tmpSplash);
                            } catch (error) {}
                        }
                    }
                },
                (err) => {
                    console.log(JSON.stringify(err));
                },
            );
        };

        window.addEventListener(NOTIFICATION_UNREAD_COUNT_UPDATE_KEY, update);

        return () => {
            window.removeEventListener(NOTIFICATION_UNREAD_COUNT_UPDATE_KEY, update);
        };
    }, []);

    return { count, splash };
}

export function notificationReadAll() {
    return requestWithRetry(() => notificationAllReadPost(), {
        name: "notificationUnreadCount",
        maxRetryCount: 3,
    });
}

export function notificationReadAllFn() {
    notificationReadAll().subscribe(
        () => {
            window.dispatchEvent(new CustomEvent(NOTIFICATION_ALL_STATUS_REFRESH_KEY));
            window.dispatchEvent(new CustomEvent(NOTIFICATION_UNREAD_COUNT_UPDATE_KEY));
        },
        (err) => {
            message.error(ParseErrorUtils.formatterToString(err));
        },
    );
}

export function useGetAnnouncementList(limit: number) {
    const [nextPage, setNextPage] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<AnnouncementListItem[]>([]);
    const nextPageRef = useRef<string>();
    const currentPageRef = useRef<string>();
    const cachedDataRef = useRef<AnnouncementListItem[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const isRefreshRef = useRef(false);
    const { userId } = useAccountInfo();
    // const { locale } = useTranslators();

    useEffect(() => {
        setLoading(true);
        isRefreshRef.current = true;
        const currentPageToken = nextPage;
        const sub = announcementListPost({ next_page_token: currentPageToken, limit })
            .pipe(
                flatMap((data) => {
                    let prevTime = LocalStorageUtils.get(`${LocalStorageKey.KEY_ANNOUNCEMENT_READ_TIMESTAMP}`);
                    if (!prevTime) {
                        prevTime = "0";
                    } else {
                        prevTime = JSON.parse(prevTime);
                    }
                    return of({
                        next_page_token: data.next_page_token,
                        list: data.list.map((item) => {
                            if (new SafeDecimal(item.timestamp).lessThan(new SafeDecimal(prevTime))) {
                                return {
                                    ...item,
                                    isRead: true,
                                };
                            } else {
                                return {
                                    ...item,
                                    isRead: false,
                                };
                            }
                        }),
                        has_more: data.has_more,
                    });
                }),
            )
            .subscribe(
                ({ next_page_token, list, has_more }) => {
                    setLoading(false);
                    isRefreshRef.current = false;
                    currentPageRef.current = currentPageToken;
                    nextPageRef.current = next_page_token;
                    setHasMore(has_more);
                    const newData = [...cachedDataRef.current, ...list];
                    cachedDataRef.current = newData;
                    setData(newData);
                },
                (err) => {
                    setLoading(false);
                    isRefreshRef.current = false;
                    console.log(JSON.stringify(err));
                },
            );

        return () => {
            sub?.unsubscribe();
        };
    }, [limit, nextPage, userId]);

    const loadMore = useCallback(() => {
        if (currentPageRef.current === nextPage && nextPageRef.current && !isRefreshRef.current) {
            setNextPage(nextPageRef.current);
        }
    }, [nextPage]);

    return useMemo(() => {
        return {
            loading,
            data,
            loadMore,
            hasMore,
        };
    }, [data, hasMore, loadMore, loading]);
}

export function useGetAnnouncement(limit: number) {
    const [data, setData] = useState<AnnouncementListItem[]>([]);
    const [loading, setLoading] = useState(false);
    const adsLoaded = useAnnouncementLoaded();
    const adsReset = useAnnouncementReset();

    useEffect(() => {
        adsReset();
        setLoading(true);
        const sub = timer(1500)
            .pipe(
                flatMap(() => {
                    return announcementListPost({ next_page_token: undefined, limit });
                }),
            )
            .subscribe(
                ({ list }) => {
                    setLoading(false);
                    adsLoaded();
                    setData(list);
                },
                (err) => {
                    setLoading(false);
                    adsLoaded();
                    console.log(JSON.stringify(err));
                },
            );
        return () => {
            sub?.unsubscribe();
        };
    }, [adsLoaded, adsReset, limit]);

    return useMemo(() => {
        return {
            loading,
            data,
        };
    }, [data, loading]);
}
