import { Button } from "@pionex-web-kit/components";
import { useTranslators } from "commonUse/Locale";
import { history } from "utils/History";
import { getAccountPathname } from ".";

export default function Upgrade() {
    const { $st } = useTranslators();
    return (
        <div className=" w-full max-w-[448px] flex flex-col items-center">
            <div className="flex flex-col items-center mt-40px mb-16px">
                <img style={{ width: 100, height: 100 }} src={require("./assets/icon_top_success.svg")} />
                <div className=" text-center text-[28px] text-text-100 font-semibold"> {$st("account_update")} </div>
            </div>

            <div className=" text-base text-text-200">{$st("ensure_to_upgrade_your_account")}</div>

            <Button
                onClick={() => {
                    history.push(getAccountPathname("/account_update/upgrading"));
                }}
                type="primary"
                shape="round"
                size="large"
                className="w-full mt-24px"
            >
                {$st("upgrade_now")}
            </Button>
        </div>
    );
}
