import { Switch } from "@pionex-web-kit/components";
import { IconSuccessBrand20pxBoldS } from "@pionex-web-kit/icons";
import classNames from "classnames";
import React, { useCallback } from "react";
import css from "./index.module.less";
import search from "./asset/search.png";
import mini from "./asset/mini.png";

import full from "./asset/full.png";
import lsearch from "./asset/lsearch.png";
import lmini from "./asset/lmini.png";

import lfull from "./asset/lfull.png";
import { LayoutTypes, useTradeUiProvider } from "landings/Trade/TradeCtx";
import useTheme from "commonUse/useTheme";
import { ThemeName } from "state/ThemeData";
import { useTranslators } from "commonUse/Locale";
import { $st } from "utils/i18n";

export default function TradeLayoutConfig() {
    const { $st } = useTranslators();
    const { layoutInfo, changeLayout } = useTradeUiProvider();
    const handleClick = useCallback(
        (value: LayoutTypes) => {
            changeLayout({ layout: value });
        },
        [changeLayout],
    );

    const handleSwitchOrderBook = useCallback(
        (value: boolean) => {
            changeLayout({
                Manual: {
                    ...layoutInfo.Manual,
                    orderBook: value,
                },
            });
        },
        [changeLayout, layoutInfo.Manual],
    );
    const handleSwitchMarket = useCallback(
        (value: boolean) => {
            changeLayout({
                Manual: {
                    ...layoutInfo.Manual,
                    marketTrades: value,
                },
            });
        },
        [changeLayout, layoutInfo.Manual],
    );
    return (
        <div className={css.con}>
            <div className={css.top}>
                <p className={css.title}>{$st("layout_set")}</p>
                <CheckLine text={$st("layout_order")} checked={layoutInfo.Manual.orderBook} onChange={handleSwitchOrderBook} />
                <CheckLine classNames={"mt-[23px] mb-[20px]"} text={$st("layout_market")} checked={layoutInfo.Manual.marketTrades} onChange={handleSwitchMarket} />
            </div>
            <p className=" mt-[25px] mb-0  text-accent leading-[115.5%] font-sb">{$st("Search coin bar")}</p>
            <p className={classNames("text-sm text-secondary mt-[5px] ", css.dec)}>{$st("layout_desc")}</p>
            <RadioButton value={layoutInfo.layout} handleChange={handleClick} />
        </div>
    );
}

function CheckLine(props) {
    const { text, onChange, checked } = props;
    return (
        <div className={classNames("flex  justify-between  text-accent leading-[115.5%] items-center", props.classNames)}>
            <span className="text-sm leading-[115.5%] ">{text}</span>
            <Switch onChange={onChange} size="small" checked={checked} />
        </div>
    );
}

function RadioButton(props: { value: LayoutTypes; handleChange: (value: LayoutTypes) => void }) {
    const { value, handleChange } = props;
    const theme = useTheme();
    const { $st } = useTranslators();
    return (
        <div className={css.layout_con}>
            <label className={css.layout}>
                <input type="radio" className={css.radio} name="tab" checked={value === LayoutTypes.AUTO} value={LayoutTypes.AUTO} onClick={() => handleChange(LayoutTypes.AUTO)} />
                <div className={css.auto}>
                    <p className="text-sm text-accent mb-[20px]">{$st("Auto adaptive")}</p>
                    <div className={css.scheme}>
                        <div></div>
                        <div></div>

                        <div></div>
                    </div>
                    <IconSuccessBrand20pxBoldS />
                </div>
            </label>
            <label className={css.layout}>
                <input type="radio" className={css.radio} name="tab" checked={value === LayoutTypes.NANO} value={LayoutTypes.NANO} onClick={() => handleChange(LayoutTypes.NANO)} />
                <div>
                    <p className="text-sm text-accent">{$st("layout_nano")}</p>
                    <img src={theme.name === ThemeName.dark ? search : lsearch} alt="" className="w-[110px]  height-[30px]" />
                    <IconSuccessBrand20pxBoldS />
                </div>
            </label>
            <label className={css.layout}>
                <input type="radio" className={css.radio} name="tab" checked={value === LayoutTypes.MINI} value={LayoutTypes.MINI} onClick={() => handleChange(LayoutTypes.MINI)} />
                <div>
                    <p className="text-sm text-accent">{$st("layout_mini")}</p>
                    <img src={theme.name === ThemeName.dark ? mini : lmini} alt="" className="w-[68px]  height-[90px]" />
                    <IconSuccessBrand20pxBoldS />
                </div>
            </label>
            <label className={css.layout}>
                <input type="radio" className={css.radio} name="tab" checked={value === LayoutTypes.FULL} value={LayoutTypes.FULL} onClick={() => handleChange(LayoutTypes.FULL)} />
                <div>
                    <p className="text-sm text-accent">{$st("layout_full")}</p>
                    <img src={theme.name === ThemeName.dark ? full : lfull} alt="" className="w-[110px]  height-[90px]" />
                    <IconSuccessBrand20pxBoldS />
                </div>
            </label>
        </div>
    );
}
