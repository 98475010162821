import useAccountInfo from "commonUse/useAccountInfo";
import React, { forwardRef, Ref, useCallback, useImperativeHandle } from "react";
import useTheme from "commonUse/useTheme";
import { PionexReport } from "landings/Analytics/Report";
import { EventType, SideBarEvent } from "landings/SidebarEvent";
import { useSelector } from "react-redux";
import ThemeData, { setTheme, ThemeName } from "src/state/ThemeData";
import AppState from "state/AppState";
import css from "./sider.module.less";
import { history } from "utils/History";
import { useTranslators } from "commonUse/Locale";
import useAppLangInfo from "commonUse/useAppLangInfo";
import { useToggle } from "src/commonUse/tools";
import { TPopover } from "src/components/v2/TinyComps";
import { TRADE_PAGE_KLINE_SIZE } from "src/components-v2/Layout";
import { useOpenHelpCenter } from "commonUse";

export enum SideBarItemActionName {
    changeTheme = "changeTheme",
    resizeKline = "resizeKline",
    JumpToMyTradePage = "JumpToMyTradePage",
    openManualService = "OpenManualService",
}
const stopPP = (e) => e.stopPropagation();
export interface SideBarItemInfo {
    actionName: SideBarItemActionName;
    status1Name: string;
    status2Name: string;
    onClick1: () => any;
    onClick2: () => any;
    showInitName: boolean;
    disable: boolean;
    icon1: { [ThemeName: string]: any };
    icon2: { [ThemeName: string]: any };
    disableIcon?: { [ThemeName: string]: any };
    popoverTips1: string;
    popoverTips2: string;
    show: boolean;
}

const useSideBarMenuData = (theme: ThemeData): SideBarItemInfo[] => {
    const route = useSelector((state: AppState) => state.router);
    const [sideBarItemInfo, setSideBarItemInfo] = React.useState<SideBarItemInfo[]>([]);
    const router = useSelector<AppState, AppState["router"]>((state) => state.router);
    const pathname = router.location.pathname;
    const accountInfo = useAccountInfo();
    const { locale } = useAppLangInfo();
    const { $st } = useTranslators();
    const openHelpCenter = useOpenHelpCenter();

    React.useEffect(() => {
        window.localStorage.getItem(TRADE_PAGE_KLINE_SIZE);
        setSideBarItemInfo([
            {
                actionName: SideBarItemActionName.changeTheme,
                status1Name: $st("side_bar_dark_theme"),
                status2Name: $st("side_bar_normal_theme"),
                onClick1: () => {
                    setTheme(ThemeName.light);
                    PionexReport.reportSystemOption(11);
                },
                onClick2: () => {
                    setTheme(ThemeName.dark);
                    PionexReport.reportSystemOption(12);
                },
                icon1: {
                    [ThemeName.light]: require("src/images/sidebar/sidebar_theme_dark_normal.png"),
                    [ThemeName.dark]: require("src/images/sidebar/sidebar_theme_dark_dark.png"),
                },
                icon2: {
                    [ThemeName.light]: require("src/images/sidebar/sidebar_theme_normal_dark.png"),
                    [ThemeName.dark]: require("src/images/sidebar/sidebar_theme_normal_normal.png"),
                },
                popoverTips1: $st("side_bar_switch_to_theme_dark"),
                popoverTips2: $st("side_bar_switch_to_theme_normal"),
                showInitName: theme.name === ThemeName.light,
                disable: false,
                show: true,
            },
            {
                actionName: SideBarItemActionName.resizeKline,
                status1Name: $st("side_bar_big_kline"),
                status2Name: $st("side_bar_small_kline"),
                onClick1: () => {
                    SideBarEvent.instance.sendCommand({ type: EventType.ResizeKline, value: "default" });
                    window.localStorage.setItem(TRADE_PAGE_KLINE_SIZE, "default");
                },
                onClick2: () => {
                    SideBarEvent.instance.sendCommand({ type: EventType.ResizeKline, value: "middle" });
                    window.localStorage.setItem(TRADE_PAGE_KLINE_SIZE, "middle");
                },
                icon1: {
                    [ThemeName.dark]: require("src/images/sidebar/sidebar_kline_big_normal.png"),
                    [ThemeName.light]: require("src/images/sidebar/sidebar_kline_big_dark.png"),
                },
                icon2: {
                    [ThemeName.light]: require("src/images/sidebar/sidebar_kline_small_normal.png"),
                    [ThemeName.dark]: require("src/images/sidebar/sidebar_kline_small_dark.png"),
                },
                disableIcon: {
                    [ThemeName.light]: require("src/images/sidebar/sidebar_kline_disable_normal.png"),
                    [ThemeName.dark]: require("src/images/sidebar/sidebar_kline_disable_dark.png"),
                },
                showInitName: !window.localStorage.getItem(TRADE_PAGE_KLINE_SIZE) || window.localStorage.getItem(TRADE_PAGE_KLINE_SIZE) === "default",
                disable: !route.location.pathname.startsWith("/trade"),
                popoverTips1: $st("side_bar_switch_to_big_kline"),
                popoverTips2: $st("side_bar_switch_to_small_kline"),
                show: true,
            },
            {
                actionName: SideBarItemActionName.JumpToMyTradePage,
                status1Name: $st("side_bar_jump_to_my_trade_page"),
                status2Name: $st("side_bar_jump_to_my_trade_page"),
                onClick1: () => history.replace("/orders/bot/running"),
                onClick2: () => history.replace("/orders/bot/running"),
                icon1: {
                    [ThemeName.light]: require("src/images/sidebar/sidebar_order_details_narmal.png"),
                    [ThemeName.dark]: require("src/images/sidebar/sidebar_order_details_narmal.png"),
                },
                icon2: {
                    [ThemeName.light]: require("src/images/sidebar/sidebar_order_details_narmal.png"),
                    [ThemeName.dark]: require("src/images/sidebar/sidebar_order_details_narmal.png"),
                },
                disableIcon: {
                    [ThemeName.light]: require("src/images/sidebar/sidebar_order_details_narmal.png"),
                    [ThemeName.dark]: require("src/images/sidebar/sidebar_order_details_narmal.png"),
                },
                showInitName: true,
                disable: false,
                popoverTips1: $st("side_bar_switch_to_ump_to_my_trade_page"),
                popoverTips2: $st("side_bar_switch_to_ump_to_my_trade_page"),
                show: Boolean(accountInfo && accountInfo.userId),
            },
            {
                actionName: SideBarItemActionName.openManualService,
                status1Name: $st("manual_service_title"),
                status2Name: $st("manual_service_title"),
                onClick1: () => {
                    openHelpCenter();
                },
                onClick2: () => {
                    openHelpCenter();
                },
                icon1: {
                    [ThemeName.light]: require("src/images/icon_manual_uncheck.png"),
                    [ThemeName.dark]: require("src/images/icon_manual_uncheck.png"),
                },
                icon2: {
                    [ThemeName.light]: require("src/images/icon_manual_uncheck.png"),
                    [ThemeName.dark]: require("src/images/icon_manual_uncheck.png"),
                },
                disableIcon: {
                    [ThemeName.light]: require("src/images/icon_manual_uncheck.png"),
                    [ThemeName.dark]: require("src/images/icon_manual_uncheck.png"),
                },
                showInitName: true,
                disable: false,
                popoverTips1: $st("manual_service_side_bar_desc"),
                popoverTips2: $st("manual_service_side_bar_desc"),
                show: true,
            },
        ]);
    }, [theme.name, route.location.pathname, $st, pathname, accountInfo, locale]);

    return sideBarItemInfo;
};

export const SIDE_BAR_MAX_WIDTH = 180;
export const SIDE_BAR_MIN_WIDTH = 46;

export interface SideBarViewRef {
    resize: () => any;
}

const SideBarView = (props, ref: Ref<SideBarViewRef>) => {
    const [collapsed, { setFalse, toggle: toggleCollapsed }] = useToggle();
    const theme = useTheme();
    const { $st } = useTranslators();

    const sideBarMenuData = useSideBarMenuData(theme);
    const [menuItemStatus, setMenuItemStatus] = React.useState<boolean[]>([]);

    useImperativeHandle(ref, () => ({
        resize: setFalse,
    }));

    React.useEffect(() => {
        if (sideBarMenuData.length) setMenuItemStatus(sideBarMenuData.map((item) => item.showInitName));
    }, [sideBarMenuData, setMenuItemStatus]);

    const headerImgSource =
        theme.name === ThemeName.light
            ? collapsed
                ? require("images/sidebar/sidebar_header_active_normal.png")
                : require("images/sidebar/sidebar_header_dark.png")
            : collapsed
            ? require("images/sidebar/sidebar_header_active_dark.png")
            : require("images/sidebar/sidebar_header_normal.png");
    return (
        <div
            onClick={stopPP}
            id="sider_wrap"
            className={css.sider_wrap}
            style={{
                maxWidth: collapsed ? SIDE_BAR_MAX_WIDTH : SIDE_BAR_MIN_WIDTH,
                boxShadow: collapsed
                    ? theme.name === ThemeName.dark
                        ? "-6px 2px 13px 0px rgba(0,0,0,0.41)"
                        : "-2px 0px 9px 0px rgba(0,0,0,0.08)"
                    : "0px 0px 0px 0px rgba(0,0,0,0)",
            }}
        >
            <div className={css.sider_header} onClick={toggleCollapsed}>
                <img src={headerImgSource} className={css.side_bar_icon} alt={"side_bar_icon"} />
                <div className={css.sider_item_text}>{$st("side_bar_title")}</div>
            </div>
            {sideBarMenuData.map((item, index) => {
                if (menuItemStatus[index] === undefined || !item.show) return null;

                return (
                    <div key={item.actionName} className={css.sider_item_hover}>
                        <TPopover
                            getPopupContainer={() => document.getElementById("sider_popover") as HTMLElement}
                            arrowPointAtCenter
                            placement="left"
                            content={menuItemStatus[index] ? item.popoverTips1 : item.popoverTips2}
                            overlayClassName={css.sider_item_popover}
                        >
                            <div
                                style={item.disable ? { cursor: "not-allowed" } : {}}
                                className={css.sider_item}
                                onClick={() => {
                                    if (item.disable) return;
                                    menuItemStatus[index] ? item.onClick2() : item.onClick1();
                                    const newMenuItemStatus = [...menuItemStatus];
                                    newMenuItemStatus[index] = !newMenuItemStatus[index];
                                    setMenuItemStatus(newMenuItemStatus);
                                }}
                            >
                                {item.disable && item.disableIcon ? (
                                    <img src={item.disableIcon[theme.name]} className={css.side_bar_icon} alt={"side_bar_icon"} />
                                ) : (
                                    <img src={menuItemStatus[index] ? item.icon2[theme.name] : item.icon1[theme.name]} className={css.side_bar_icon} alt={"side_bar_icon"} />
                                )}
                                <div style={item.disable ? { color: theme.name === ThemeName.light ? "#BBBDC3" : "#8A8E97" } : {}} className={css.sider_item_text}>
                                    {menuItemStatus[index] ? item.status2Name : item.status1Name}
                                </div>
                            </div>
                        </TPopover>
                    </div>
                );
            })}
        </div>
    );
};

export default forwardRef(SideBarView);
