import { APIKeyInfo } from "TradeLib/TradeTypes";
import {
    AccountTypeBalanceV2,
    apiKey2ExchangeApiKey,
    AssetAPILib,
    BALANCE_TYPE_V2,
    BalanceHelper,
    BalanceHistoryOriginInfo,
    BalanceHistoryOriginItemInfo,
    BalanceProvider,
    BalanceTotalInfoV2,
    CoinBalance,
    ExchangeBalanceV2,
} from "trade_asset_lib";
import { take } from "rxjs/operators";
import { useEffect, useMemo, useState } from "react";
import useAccountInfo from "./useAccountInfo";
import { SafeDecimal } from "trade_utils_lib";

export interface TradeAvailableBalance {
    baseBalance: number;
    baseAvailable: number;
    baseFrozen: number;
    quoteBalance: number;
    quoteAvailable: number;
    quoteFrozen: number;
    usdtBalance: number;
    usdtAvailable: number;
    usdtFrozen: number;
    base?: ExchangeBalanceV2;
    quote?: ExchangeBalanceV2;
    usdt?: ExchangeBalanceV2;
}

const EMPTY_BALANCE: Pick<ExchangeBalanceV2, "balanceType" | "available" | "frozen" | "balance"> = {
    balanceType: BALANCE_TYPE_V2.TRADE,
    available: 0,
    frozen: 0,
    balance: 0,
};

const balanceInitializer = (base?: string, quote?: string) =>
    ({
        baseBalance: 0,
        baseAvailable: 0,
        baseFrozen: 0,
        quoteBalance: 0,
        quoteAvailable: 0,
        quoteFrozen: 0,
        usdtBalance: 0,
        usdtAvailable: 0,
        usdtFrozen: 0,
        /**
         * 这里需要默认这三个的balance数据对象，因为下单部分调用了一个通用检查配置函数【LimitCreateOrderModel._checkToGetCommon】.
         * 不管是买单还是买单，base和quote的balance对象都进行了一次非空检查。如果其中一个为空，则都抛出【TradeCreateOrderError.Balance_Get_Failed】此错误。
         * 由于用户账户中很多base币种的balance为空，【getTradeBalanceObservable】远端获取的资产数据中没有base的balance数据，就可能导致用户无法下单。
         */
        base: { ...EMPTY_BALANCE, currency: base, currencyAlias: base },
        quote: { ...EMPTY_BALANCE, currency: quote, currencyAlias: quote },
        usdt: { ...EMPTY_BALANCE, currency: "USDT", currencyAlias: "USDT" },
    } as TradeAvailableBalance);
const usdtName = "USDT";

// 获取已选中交易对的持仓数据
export const useBalanceTradeByBaseQuote = (base: undefined | string, quote: undefined | string): [TradeAvailableBalance, boolean, undefined | string] => {
    const [balance, setBalance] = useState<TradeAvailableBalance>(() => balanceInitializer(base, quote));
    const [onLoading, changeLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string>();
    const { firstApiKey, userId } = useAccountInfo();

    useEffect(() => {
        let subscription;
        if (!base || !quote || !firstApiKey) {
            return;
        }
        setBalance(balanceInitializer(base, quote));
        changeLoading(true);
        subscription = BalanceProvider.instance
            .getTradeBalanceObservable({
                key: "",
                secret: "",
                extra: "",
                market: firstApiKey.exchange,
                cloudKeyId: firstApiKey.key_id,
            })
            .subscribe(
                (data) => {
                    const balances = balanceInitializer(base, quote);
                    let counter = 0;
                    for (const item of data) {
                        if (item.currency === base) {
                            balances.baseAvailable = item.available;
                            balances.baseBalance = item.balance;
                            balances.baseFrozen = item.frozen;
                            balances.base = item;
                            counter++;
                        }
                        if (item.currency === quote) {
                            balances.quoteAvailable = item.available;
                            balances.quoteBalance = item.balance;
                            balances.quoteFrozen = item.frozen;
                            balances.quote = item;
                            counter++;
                        }
                        if (item.currency === usdtName) {
                            balances.usdtAvailable = item.available;
                            balances.usdtBalance = item.balance;
                            balances.usdtFrozen = item.frozen;
                            balances.usdt = item;
                            counter++;
                        }
                        if (counter === 3) {
                            break;
                        }
                    }
                    setBalance(balances);
                    changeLoading(false);
                },
                (err) => {
                    const msg = typeof err === "object" ? JSON.stringify(err) : `${err}`;
                    setErrMsg(msg);
                    changeLoading(false);
                },
            );

        return () => {
            subscription && subscription.unsubscribe();
        };
    }, [firstApiKey, base, quote]);

    return [balance, onLoading, errMsg];
};

export interface IBalanceAll extends AccountTypeBalanceV2 {
    total: BalanceTotalInfoV2;
}

export function useBalanceAll() {
    const [balance, changeBalance] = useState<IBalanceAll>();
    const accountInfo = useAccountInfo();
    const { exchange, key_id } = (accountInfo?.apiKeys || [])[0] ?? ({} as APIKeyInfo);
    useEffect(() => {
        if (!(exchange && key_id)) {
            return;
        }
        const subscription = BalanceProvider.instance
            .getAllBalanceObservable({
                key: "",
                secret: "",
                extra: "",
                market: exchange,
                cloudKeyId: key_id,
            })
            .subscribe(
                (res) => {
                    const { total, exchangeBalances } = res;
                    const asset = BalanceHelper.getBalanceCurrencyOb(exchangeBalances);
                    changeBalance({ total, ...asset });
                },
                (err) => {
                    console.error(err);
                },
            );

        return () => subscription.unsubscribe();
    }, [exchange, key_id]);

    return balance;
}

export function useBalanceTotalInfo(apiKey: APIKeyInfo | undefined, takeOne?: boolean, refresh?: boolean): BalanceTotalInfoV2 {
    const [balanceTotalInfo, setBalanceTotalInfo] = useState<BalanceTotalInfoV2>({
        financeTotalInBTC: 0,
        financeTotalInUSD: 0,
        totalInBTC: 0,
        totalInUSD: 0,
        tradeTotalInBTC: 0,
        tradeTotalInUSD: 0,
        manualTotalInBTC: 0,
        manualTotalInUSD: 0,
    });

    useEffect(() => {
        if (!apiKey) {
            return;
        }
        let observable = BalanceProvider.instance.getTotalBalanceOverview(apiKey2ExchangeApiKey(apiKey));
        if (takeOne) {
            observable = BalanceProvider.instance.getTotalBalanceOverview(apiKey2ExchangeApiKey(apiKey)).pipe(take(1));
        }
        let sub = observable.subscribe(
            (res) => {
                if (!res) return;

                const { financeTotalInBTC, financeTotalInUSD, totalInBTC, totalInUSD, tradeTotalInBTC, tradeTotalInUSD, manualTotalInUSD, manualTotalInBTC } = res;
                setBalanceTotalInfo({
                    financeTotalInBTC,
                    financeTotalInUSD,
                    totalInBTC,
                    totalInUSD,
                    tradeTotalInBTC,
                    tradeTotalInUSD,
                    manualTotalInBTC,
                    manualTotalInUSD,
                });
            },
            (error) => console.log("getTotalBalanceOverview", error),
        );

        return () => sub && sub.unsubscribe();
    }, [apiKey, takeOne, refresh]);

    return balanceTotalInfo;
}

interface IBalancesStatInfo {
    depositInUSD: number;
    withdrawInUSD: number;
    financeTotalInUSD: number;
    totalInUSD: number;
    tradeTotalInUSD: number;
}

export function useBalanceStat(apiKey: APIKeyInfo | undefined): [IBalancesStatInfo, boolean] {
    const [loading, setLoading] = useState(false);
    const [balanceStat, setBalanceStat] = useState<IBalancesStatInfo>({
        depositInUSD: 0,
        withdrawInUSD: 0,
        financeTotalInUSD: 0,
        totalInUSD: 0,
        tradeTotalInUSD: 0,
    });
    useEffect(() => {
        if (apiKey === undefined) {
            return;
        }
        setLoading(true);
        const subscription = new AssetAPILib().getBalancesStat$().subscribe(
            (res) => {
                const balanceStatRes: IBalancesStatInfo = {
                    depositInUSD: new SafeDecimal(res.today_deposit_withdraw.deposit_in_usd).toNumber(),
                    withdrawInUSD: new SafeDecimal(res.today_deposit_withdraw.withdraw_in_usd).toNumber(),
                    financeTotalInUSD: new SafeDecimal(res.today_init_balances.finance_total_in_usd).toNumber(),
                    totalInUSD: new SafeDecimal(res.today_init_balances.total_in_usd).toNumber(),
                    tradeTotalInUSD: new SafeDecimal(res.today_init_balances.trade_total_in_usd).toNumber(),
                };
                setBalanceStat(balanceStatRes);
                setLoading(false);
            },
            (error) => {
                console.error(error);
                setLoading(false);
            },
        );
        return () => subscription?.unsubscribe();
    }, [apiKey]);
    return [balanceStat, loading];
}

/**
 * 理财账户有对冲的资产
 */
export const useBalanceArbitrageList = (): CoinBalance[] => {
    const balance = useBalanceAll();
    return useMemo(() => {
        if (balance) {
            const { arbitrageStrategy, swap, cmSwap } = balance;
            const arbitrageStrategyBalances = arbitrageStrategy.balances.filter((item) => {
                return item.quantity > 0;
            });
            const swapBalances = swap.balances.filter((item) => {
                return item.quantity > 0;
            });
            const cmSwapBalances = cmSwap.balances.filter((item) => {
                return item.quantity > 0;
            });
            return [...arbitrageStrategyBalances, ...swapBalances, ...cmSwapBalances];
        } else {
            return [];
        }
    }, [balance]);
};

/**
 * 理财账户除了对冲类的其它币（其实就是除套利外的其它，如存币生息等）
 */
export const useBalanceFinanceOtherList = (): CoinBalance[] => {
    const balance = useBalanceAll();
    return useMemo(() => {
        if (balance) {
            const { deposit } = balance;
            const depositBalances = deposit.balances.filter((item) => {
                return item.quantity > 0;
            });
            return [...depositBalances];
        } else {
            return [];
        }
    }, [balance]);
};
/**
 * 套利
 */
export const useArbitrageBalanceTotalUSD = (): number => {
    const balance = useBalanceAll();
    return useMemo(() => {
        if (balance) {
            const { arbitrageStrategy, swap, cmSwap } = balance;
            return new SafeDecimal(arbitrageStrategy.totalUSD + swap.totalUSD + cmSwap.totalUSD).toNumber();
        } else {
            return 0;
        }
    }, [balance]);
};
export type TPeriod = 30 | 90 | 180 | 365;
export const useBalanceHistory = (period: TPeriod): [BalanceHistoryOriginInfo["items"], boolean] => {
    const accountInfo = useAccountInfo();
    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState<BalanceHistoryOriginItemInfo[]>([]);
    useEffect(() => {
        if (!(accountInfo?.apiKeys || [])[0]) {
            return;
        }
        setLoading(true);
        const subscription = new AssetAPILib().getBalancesHistory$(period).subscribe(
            (res) => {
                if (res?.items) {
                    setHistory(res.items);
                }
                setLoading(false);
            },
            (error) => {
                console.error(error);
                setLoading(false);
            },
        );
        return () => subscription?.unsubscribe();
    }, [accountInfo?.apiKeys, period]);
    return [history, loading];
};
