import { TButton } from "components/v2/TinyComps";
import React, { CSSProperties } from "react";
import { useTranslators } from "src/commonUse/Locale";
interface IEmptyChart {
    str?: string;
    style?: CSSProperties;
}
const EmptyChart = ({ str, style }: IEmptyChart) => {
    const { $st } = useTranslators();
    const styles = Object.assign({}, { display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }, style);
    return (
        <div style={styles}>
            <div style={{ textAlign: "center" }}>
                {str || $st("balance_finance_funds_empty")}
                <p style={{ marginTop: 15 }}>
                    <TButton href="/balance/deposit" type="primary">
                        {$st("header.account.menu.deposit")}
                    </TButton>
                </p>
            </div>
        </div>
    );
};

export default EmptyChart;
