import { createContext, createElement, FC, useCallback, useContext, useMemo } from "react";
import { FormatNumberOptions, IntlShape, useIntl } from "react-intl";
import { $st, $td, $tt, $str, $tn, $stp, $stn } from "utils/i18n";

export type ST = typeof $st;

/**
 * @deprecated Use {@link $tn} or {@link $stn} instead.
 * @returns
 */
export function useStn(): (value: Parameters<Intl.NumberFormat["format"]>[0], precision?: number) => string {
    const intl = useIntl();

    return useCallback(
        (value: Parameters<Intl.NumberFormat["format"]>[0], precision?: number) => {
            const num = Number(value) || 0;
            const option: FormatNumberOptions = {};
            if (precision !== undefined) {
                option.maximumFractionDigits = precision;
                option.minimumFractionDigits = precision;
            } else if (num >= 100) {
                option.maximumFractionDigits = 2;
                option.minimumFractionDigits = 2;
            } else if (num >= 1) {
                option.maximumFractionDigits = 4;
                option.minimumFractionDigits = 4;
            } else {
                option.maximumSignificantDigits = 4;
            }
            return intl.formatNumber(value, option);
        },
        [intl],
    );
}

const staticTranslatorsMap = {
    $st,
    $td,
    $tt,
    $str,
    $tn,
    $stp,
    $stn,
    intl: {} as IntlShape,
};

export const CTXTranslators = createContext({ ...staticTranslatorsMap, intl: {} as IntlShape, locale: staticTranslatorsMap.intl.locale });
export const TranslatorsProvider: FC = ({ children }) => {
    const intl = useIntl();
    const _translators = useMemo(() => {
        return Object.keys(staticTranslatorsMap).reduce((ob, key) => {
            ob[key] = (...arg) => staticTranslatorsMap[key](...arg);
            return ob;
        }, {} as typeof staticTranslatorsMap);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl, intl.locale]);
    return createElement(CTXTranslators.Provider, { value: { ..._translators, intl, locale: intl.locale } }, children);
};

/**
 * 函数组件的翻译钩子，订阅国际化更新
 * @returns
 */
export function useTranslators() {
    return useContext(CTXTranslators);
}
