import { useDataProvider } from "commonUse";
import { OrderListStatus, OrderPairType } from "landings/PionexHistoricalOrderDetails/borderOrderHelper";
import useFetchOrders from "landings/PionexHistoricalOrderDetails/components-v2/BotOrders/hooks/useFetchOrders";
import closeOrderMiddleware from "landings/PionexHistoricalOrderDetails/components/CommonUI/OrderCloseAbout/closeOrderMiddleware";
import { botOrderTransform, useBotOrderInfo } from "landings/Trade/Order/TradingBotTable/botOrderHelper";
import { BotTableOrderInfo, OrderTabType, TradingBotOrderTableData } from "landings/Trade/TradeConstant";
import { useCallback, useEffect, useMemo, useState } from "react";
import { history } from "utils/History";
import { ExchangeOrder, ExchangeOrderStatus } from "TradeAPILib";
import { Alert, Button, Card, Modal, Spin } from "@pionex-web-kit/components";
import { useTranslators } from "commonUse/Locale";
import useExchangeRate from "commonUse/useExchangeRate";
import useAccountInfo from "commonUse/useAccountInfo";
import { useObservable } from "@pionex-web-kit/common-business";
import moment from "moment";
import { getAccountPathname } from ".";

function BotOrderCards() {
    const { $st } = useTranslators();
    const exchangeRate = useExchangeRate();
    const accountInfo = useAccountInfo();
    const apiKey = (accountInfo.apiKeys || [])[0];
    const dataProvider = useDataProvider(accountInfo.firstApiKey);
    const [closeOrderModalVisible, setCloseOrderModalVisible] = useState<boolean>();
    const { getOrdersObserve, loading, fetch } = useFetchOrders({
        status: OrderListStatus.running,
        type: OrderPairType.all,
        orderType: "all",
        symbolPair: undefined,
        isWatchNewOrder: true,
    });

    const { orders, nextPageToken } = useObservable(getOrdersObserve, {
        orders: [],
        nextPageToken: undefined,
        tickerMap: new Map(),
    });

    const orderDetailAction = useCallback((order: ExchangeOrder) => {
        history.push(`/orders/bot/running/${order.orderType}`, { orderType: order.orderType, orderId: order.id });
    }, []);

    const [botOrderData, botOrderCount] = useBotOrderInfo(apiKey, OrderTabType.running, true, false, undefined);
    const botTableOrderInfo = useMemo(() => {
        return {
            orderData: botOrderData,
            botOrderCount: botOrderCount,
        } as unknown as BotTableOrderInfo;
    }, [botOrderData, botOrderCount]);
    const resetList = useCallback(() => {
        if (!dataProvider) return;
        fetch(true);
    }, [fetch, dataProvider]);
    const dataSource = useMemo(
        () => (botTableOrderInfo ? botOrderTransform(orders, botTableOrderInfo?.orderAssets!, exchangeRate) : []),
        [botTableOrderInfo, orders, exchangeRate],
    );

    const closeOrderEvt = useCallback(
        (e: React.MouseEvent<HTMLElement, MouseEvent>, item: TradingBotOrderTableData) => {
            e.stopPropagation();
            closeOrderMiddleware(apiKey, item.originalData, false);
        },
        [resetList],
    );

    const closeAllOrders = useCallback(() => {
        setCloseOrderModalVisible(false);
        if (!dataSource) return;
        Promise.all(dataSource.map((item) => closeOrderMiddleware(apiKey, item.originalData, false)));
    }, [apiKey, dataSource]);

    useEffect(() => {
        resetList();
    }, [resetList]);

    useEffect(() => {
        if (!nextPageToken) return;
        fetch();
    }, [fetch, nextPageToken]);

    useEffect(() => {
        if (loading !== false) return;
        if (!dataSource.length) {
            history.push(getAccountPathname("/account_update"));
        }
    }, [dataSource, loading]);

    return (
        <div className=" mb-20px">
            <Spin spinning={loading}>
                <div className="flex justify-between items-center mb-12px mt-16px">
                    <div className="text-base font-b">Close bot orders</div>
                    <Button
                        loading={!!dataSource.length && dataSource.length === dataSource.filter(({ originalData: { state } }) => state === ExchangeOrderStatus.canceling).length}
                        onClick={() => {
                            setCloseOrderModalVisible(true);
                        }}
                        type="primary"
                        shape="round"
                    >
                        Close all orders
                    </Button>
                </div>
                <Alert className="mb-12px" message={$st("note_recreate")} type="warning" />
                {dataSource?.map((item) => {
                    return (
                        <Card
                            className="w-full mb-8px cursor-pointer"
                            onClick={() => {
                                orderDetailAction(item.originalData);
                            }}
                            key={item.id}
                        >
                            <div className="flex justify-between items-center">
                                <div className="text-base font-m">{item.bot}</div>
                                <Button
                                    loading={dataSource.some(({ id, originalData: { state } }) => id === item.id && state === ExchangeOrderStatus.canceling)}
                                    size="small"
                                    type="default"
                                    shape="round"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        closeOrderEvt(e, item);
                                    }}
                                >
                                    {$st("grid_close_order_btn_text")}
                                </Button>
                            </div>
                            <div className="grid grid-cols-4 mt-12px gap-y-8px mobile:grid-cols-2">
                                <div className="flex flex-col gap-4px">
                                    <div className="text-sm text-text-200">{$st("trade_symbol")}</div>
                                    <div className="text-text-100">{item.symbol}</div>
                                </div>
                                <div className="flex flex-col items-center mobile:items-end gap-4px">
                                    <div className="text-sm text-text-200">{$st("history_time")}</div>
                                    <div className="text-text-100">{moment(item.timestamp).format("YYYY/MM/DD HH:mm:ss")}</div>
                                </div>
                                <div className="flex flex-col items-center mobile:items-start gap-4px">
                                    <div className="text-sm text-text-200">{$st("order_title_investment")}</div>
                                    <div className="text-text-100">{item.investment}</div>
                                </div>
                                <div className="flex flex-col items-end gap-4px">
                                    <div className="text-sm text-text-200">{$st("grid_total_profit", { quote: "USDT" })}</div>
                                    <div className={item.profit >= 0 ? "text-increase" : "text-decrease"}>{item.profitText}</div>
                                </div>
                            </div>
                        </Card>
                    );
                })}
            </Spin>
            {closeOrderModalVisible !== undefined && (
                <Modal
                    visible={closeOrderModalVisible}
                    onOk={closeAllOrders}
                    onCancel={() => {
                        setCloseOrderModalVisible(false);
                    }}
                    title={$st("grid_pro_order_close_model_title")}
                >
                    {$st("sure_close_all_orders")}
                </Modal>
            )}
        </div>
    );
}

export default BotOrderCards;
