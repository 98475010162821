// 是否pionex环境，如果不存在环境标识，则成立。也用于标记非 BU 环境
/**
 * @deprecated Use
 * {@link PLATFORM.PIONEX}
 *  instead.
 */
export function isPionex() {
    return PLATFORM.PIONEX;
}
/**
 * @deprecated Use
 * {@link PLATFORM.PIONEX_CN}
 *  instead.
 */
export function isPionexCN() {
    return PLATFORM.PIONEX_CN;
}
/**
 * @deprecated Use
 * {@link PLATFORM.PIONEX_SG}
 *  instead.
 */
export function isPionexSG() {
    return PLATFORM.PIONEX_SG;
}
/**
 * @deprecated Use
 * {@link PLATFORM.BOT}
 *  instead.
 */
// 是否BU环境，如果不存在环境标识，则成立
export function isBU() {
    return PLATFORM.BOT;
}

/**
 * @deprecated
 * @param First
 */
// 仅在BU版本中渲染某一组件
export function inBU<T extends React.ComponentType>(First: T): T | void;
export function inBU<T extends JSX.Element>(First: T): T | T[] | void;
export function inBU<T extends JSX.Element | React.ComponentType>(First: T, ...Comp: T[]): T | T[] | void {
    return isBU() ? (Comp.length === 0 ? First : [First, ...Comp]) : undefined;
}

/**
 * @deprecated
 * @param First
 */
// 仅在Pionex版本中渲染某一组件
export function inPionex<T extends React.ComponentType>(First: T): T | void;
export function inPionex<T extends JSX.Element>(First: T): T | T[] | void;
export function inPionex<T extends JSX.Element | React.ComponentType>(First: T, ...Comp: T[]): T | T[] | void {
    return isPionex() ? (Comp.length === 0 ? First : [First, ...Comp]) : undefined;
}
/**
 * @deprecated
 * @param First
 */
// PionexSG
export function inPionexSG<T extends React.ComponentType>(First: T): T | void;
export function inPionexSG<T extends JSX.Element>(First: T): T | T[] | void;
export function inPionexSG<T extends JSX.Element | React.ComponentType>(First: T, ...Comp: T[]): T | T[] | void {
    return isPionexSG() ? (Comp.length === 0 ? First : [First, ...Comp]) : undefined;
}

export function isHostCC() {
    return window.location.hostname.endsWith(".cc");
}
export function isHostCom() {
    return window.location.hostname.endsWith(".com");
}

export function getRootHostName() {
    const hn = window.location.hostname.split(".");
    return hn.length >= 2 ? hn.pop() : "com";
}

/**
 * 本地站点标识和服务端的映射关系
 */
const APIPlatformMap: { [k in EnvBuildConfig["PLATFORM"]]?: string } = {
    pionex: "MAIN",
    "pionex.us": "USA",
    "pionex.us.v2": "USA",
    "pionex.sg": "SGP",
};

/**
 * 直接获取当前站点对应的服务端标识
 */
export function platformForAPI(): string {
    return APIPlatformMap[PLATFORM._CODE] ?? "MAIN";
}
