import { bankHolderNameReg } from "compliance_lib";
import { ST } from "commonUse/Locale";
import { DWChannelStatus, DWChannelStatusInfo, DWPayMethod } from "landings/V2/USDDW/types";
import { QuotaChannel, QuotaDirection } from "utils/complianceQuota";
import { DISABLE_CHECKOUT } from "landings/V2/CircleDebit/utils";

const NAME_MAX_LENGTH = 70;

/**
 * 校验持卡人姓名
 * @param $st
 * @param originValue
 */
export function validateCardName($st: ST, originValue = "") {
    const value = originValue.trim();
    if (!value) {
        return Promise.reject($st("circledw_addCard_step2_name"));
    }
    if (!value.includes(" ") || value.length < 3 || !bankHolderNameReg.test(value)) {
        return Promise.reject($st("circledw_addCard_step2_name_err"));
    }
    if (value.length > NAME_MAX_LENGTH) {
        return Promise.reject($st("common_length_max", { length: NAME_MAX_LENGTH }));
    }
    return Promise.resolve();
}

/**
 * Circle入金错误原因文案
 */
export function getCircleFailReasonText($st: ST, failReason?: string, failDetail?: string) {
    return $st({
        id: `circle_deposit_fail_reason:${failReason}`,
        defaultMessage: $st({
            id: `card_reject_reason:${failReason}`, // 入金理由内没有，再去卡拒绝理由里面获取一下
            defaultMessage: failDetail || `[${failReason}]:${$st("debit_deposit_error")}`,
        }),
    });
}

/**
 * Circle绑卡拒绝原因文案
 */
export function getCircleCardRejectText($st: ST, failReason?: string, failDetail?: string) {
    if (!failReason) return $st("card_status_tips_reject2");

    const k = `card_reject_reason:${failReason}`;
    const reasonV = $st(k);

    if (reasonV === k) {
        // 没有对应的翻译
        return failDetail || $st("card_status_tips_reject2");
    }

    return $st("card_status_tips_reject1", { reason: reasonV || failReason });
}

/**
 * PayMethod和Channel的映射
 */
const PAY_METHOD__CHANNEL: Record<DWPayMethod, Array<QuotaChannel>> = {
    [DWPayMethod.ACH]: [QuotaChannel.CIRCLE_ACH, QuotaChannel.CHECKOUT_ACH],
    [DWPayMethod.WIRE]: [QuotaChannel.FBO],
    [DWPayMethod.DEBIT]: [QuotaChannel.CIRCLE_CARD, QuotaChannel.CHECKOUT_CARD],
};
// 整个Debit通道状态展示时，只有Circle关闭时也需要整个通道展示关闭，从映射中剔除掉checkout
DISABLE_CHECKOUT && (PAY_METHOD__CHANNEL[DWPayMethod.DEBIT] = PAY_METHOD__CHANNEL[DWPayMethod.DEBIT].filter((item) => item !== QuotaChannel.CHECKOUT_CARD));

/**
 * 根据全量通道状态数据获取指定payMethod的展示状态
 * @param payMethod
 * @param allChannelStatus
 * @param direction
 */
export function getPayMethodStatus(payMethod: DWPayMethod, allChannelStatus: DWChannelStatusInfo, direction: QuotaDirection): DWChannelStatus {
    // 暂时把维护中和关闭当相同逻辑处理,payMethod下只要有一个开放的通道就算整个payMethod开放
    return PAY_METHOD__CHANNEL[payMethod].some((channel) => allChannelStatus[channel][direction] === DWChannelStatus.OPEN) ? DWChannelStatus.OPEN : DWChannelStatus.CLOSE;
}
