import React, { ReactNode, useMemo } from "react";
import { PaymentMethod, PaymentMethodChannel } from "landings/V2/BuyCrypto/Buy/cards/index";
import { useBalanceTradeByBaseQuote } from "commonUse/useBalance";
import { NumberCommonUtils, SafeDecimal } from "trade_utils_lib";
import { ST, useTranslators } from "commonUse/Locale";

interface Config {
    $st: ST;
    usdAvailable: number;
}

export function useBalancePaymentMethod() {
    const { $st } = useTranslators();
    const [{ baseAvailable: usdAvailable }] = useBalanceTradeByBaseQuote("USD", "USDT"); // 原始的余额

    return useMemo(() => new BalancePaymentMethod(PaymentMethodChannel.BALANCE, {}, { usdAvailable, $st }), [$st, usdAvailable]);
}

/**
 * 余额支付的实现
 */
export class BalancePaymentMethod implements PaymentMethod<{}> {
    channel: PaymentMethodChannel;
    card: {};
    config: Config;

    constructor(channel: PaymentMethodChannel, card: {}, config: Config) {
        this.card = card;
        this.channel = channel;
        this.config = config;
    }

    getKey(): string {
        return this.channel;
    }

    getFee(): string {
        return "0";
    }

    renderItem(): ReactNode {
        const { $st } = this.config;
        return <>{$st("buy_crypto_paymethod_usd_balance")}</>;
    }

    disabled(): boolean {
        return false;
    }

    getAmountLimit(): string {
        return NumberCommonUtils.formatterDecimalToStr(this.config.usdAvailable, 2);
    }

    getAmountLimitText(): ReactNode {
        const { $st } = this.config;
        return (
            <div className="flex justify-between text-accent-sub">
                {$st("buy_crypto_available_balance")}
                <span className="text-accent font-sb">${NumberCommonUtils.formatterDecimalToStr(this.config.usdAvailable, 2)}</span>
            </div>
        );
    }

    amountValidator(amount: number): Promise<void> {
        const { $st, usdAvailable } = this.config;

        if (new SafeDecimal(amount).greaterThan(usdAvailable)) {
            return Promise.reject($st("buy_crypto_amount_exceeded", { max: NumberCommonUtils.formatterDecimalToStr(usdAvailable, 2) }));
        }
        return Promise.resolve();
    }
}
