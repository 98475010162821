import useAccountInfo from "commonUse/useAccountInfo";
import { useEffect, useMemo, useState } from "react";
import { AuthType, Network } from "trade_utils_lib";
import Constants from "utils/Constants";

enum EWhiteListState {
    SUBMITTED = "SUBMITTED", // 已申请
    ACCEPTED = "ACCEPTED", // 白名单用户
    REJECTED = "REJECTED", // 非白名单用户
}



/**
 * 业务白名单状态查询
 * @param moduleTag string。在 crm【运营管理 - 白名单管理 - 业务线】菜单中找到或者新建业务线（moduleTag）。
 */

let IS_WHITE_LIST: boolean;
let IS_WHITE_LIST_PROMISE: Promise<boolean>;


export const getWhiteList = (moduleTag: string) => {
    if (IS_WHITE_LIST) {
        return Promise.resolve(IS_WHITE_LIST);
    }
    if (IS_WHITE_LIST_PROMISE) {
        return IS_WHITE_LIST_PROMISE;
    }
    IS_WHITE_LIST_PROMISE = Network.get<{ status: EWhiteListState }>({
        url: `${Constants.balanceApiHost}/application/`,
        data: { module_tag: moduleTag },
        authType: AuthType.Access,
    }).toPromise().then((result) => result.status === EWhiteListState.ACCEPTED);
    return IS_WHITE_LIST_PROMISE;
};

export const useWhiteList = (moduleTag: string) => {
    const accountInfo = useAccountInfo();
    // NOTE: 默认为 undefined 不要默认写为 false
    const [state, setState] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>();

    useEffect(() => {
        if (!accountInfo.userId) {
            setState(undefined);
            return;
        }
        setLoading(true);
        getWhiteList(moduleTag).then((result) => {
            setLoading(false);
            setState(result);
        }).catch(() => {
            setLoading(false);
            setState(false);
        })

    }, [accountInfo.userId, moduleTag]);

    return useMemo(() => ({ state, loading }), [loading, state]);
};