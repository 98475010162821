import { useTranslators } from "commonUse/Locale";
import { useToggle } from "commonUse/tools";
import TReportLink, { Iconfont, TButton, TSpin } from "components/v2/TinyComps";
import TransferInfoItem from "landings/V2/CircleDW/Deposit/TransferInfoItem";
import { useSignatureDWAPI } from "landings/V2/SignatureDW/common";
import { BeneficiaryRes } from "landings/V2/SignatureDW/types";
import { withMaintenance } from "landings/V2/USDDW/components/Maintenance";
import NoKyc, { useNoKycVisible } from "landings/V2/USDDW/components/NoKyc";
import { DWPayMethod } from "landings/V2/USDDW/types";
import React, { useEffect, useMemo, useState } from "react";
import resultCs from "src/components-v2/ResultInPage/index.module.less";
import { requestWithRetry } from "trade_utils_lib";
import { QuotaDirection } from "utils/complianceQuota";
import cs from "./index.module.less";
import { Button, Tabs } from "@pionex-web-kit/components";
import { IconSafeguard100pxRegular, IconSuccesswaiting100pxBoldS, IconWarning20pxLineS } from "@pionex-web-kit/icons";
import { SignatureStep } from "landings/V2/SignatureDW/components/SignatureStep";
import csn from "classnames";
import ResultInPage from "src/components-v2/ResultInPage";
import { subscribe } from "pubsub-js";

const TUTORIAL_URL = "https://www.pionex.us/blog/how-to-deposit-usd-via-wire-transfer/";

interface Props {
    beneficiaries: BeneficiaryRes;
}

interface Tabs {
    key: string;
    value: string;
    label: string;
}

enum banksName {
    chaseBank = "JPMorgan Chase Bank",
    leadBank = "Lead Bank",
}

/**
 * Signature 入金页面
 */
const Deposit: React.FC<Props> = ({ beneficiaries }) => {
    const { $st } = useTranslators();
    const [isSelect, setIsSelect] = useState<string>(banksName.chaseBank);

    const beforeDWVisible = useNoKycVisible();
    const [completed, { setTrue: setCompleted, setFalse: setUnCompleted }] = useToggle();
    useEffect(() => {
        // 切换到成功状态自动滚动到顶部
        if (beneficiaries.banks?.length === 1) {
            beneficiaries.banks.forEach((item) => {
                if (item.beneficiaryBank.name === banksName.leadBank) {
                    setIsSelect(banksName.leadBank);
                }
            });
        }
        if (!completed) {
            return;
        }
        setTimeout(() => {
            document.documentElement.scroll({
                top: 0,
                behavior: "smooth",
            });
        }, 100);
    }, [beneficiaries.banks, completed]);

    const tabs: Tabs[] = useMemo(() => {
        if (!beneficiaries.banks) {
            return [];
        }
        return beneficiaries.banks.map((item) => {
            return {
                key: item.beneficiaryBank.name,
                value: item.beneficiaryBank.name === "JPMorgan Chase Bank" ? "Chase Bank" : item.beneficiaryBank.name,
                label: item.beneficiaryBank.name === "JPMorgan Chase Bank" ? "Chase Bank" : item.beneficiaryBank.name,
            };
        });
    }, [beneficiaries]);

    const depositMsg = useMemo(() => {
        const bank = beneficiaries.banks?.filter((item) => {
            return item.beneficiaryBank.name === banksName.leadBank;
        });
        if (!bank) {
            return;
        }
        if (bank[0]?.depositMessage) {
            return bank[0]?.depositMessage;
        } else {
            return;
        }
    }, [beneficiaries.banks]);

    const infos = useMemo(() => {
        const bank = beneficiaries.banks?.filter((item) => {
            return item.beneficiaryBank.name === isSelect;
        });
        if (!bank) {
            return [];
        }
        const bank0 = bank[0];
        if (!bank0) {
            return [];
        }

        const { beneficiary, beneficiaryBank } = bank0;

        return [
            { label: $st("signature_dw_transfer_recipient_name"), value: beneficiary.name },
            {
                label: $st("signature_dw_transfer_recipient_address"),
                value: beneficiaryBank.name === banksName.leadBank ? "" : beneficiary.address1,
            },
            ...(beneficiaryBank.name === banksName.leadBank
                ? [
                      {
                          label: $st("signature_dw_transfer_recipient_address_line_one"),
                          value: `${beneficiary.address1}`,
                      },
                  ]
                : []),
            ...(beneficiaryBank.name === banksName.leadBank
                ? [
                      {
                          label: $st("signature_dw_transfer_recipient_address_line_two"),
                          value: `${beneficiary.address2}`,
                      },
                  ]
                : []),
            { label: $st("signature_dw_transfer_account_number"), value: beneficiaryBank.accountNumber },
            { label: $st("signature_dw_bank_name"), value: beneficiaryBank.name },
            { label: $st("signature_dw_transfer_bank_address"), value: beneficiaryBank.address },
            { label: $st("signature_dw_transfer_aba"), value: beneficiaryBank.routingNumber },
            ...(!!beneficiaryBank.swiftCode
                ? [
                      {
                          label: $st("signature_dw_transfer_swift"),
                          value: beneficiaryBank.swiftCode,
                      },
                  ]
                : []),
        ];
    }, [$st, beneficiaries?.banks, isSelect]);

    if (beforeDWVisible) {
        return <NoKyc reportEvent="fiatdeposit" />;
    }

    if (completed) {
        return (
            <>
                <div className={csn(cs.result, resultCs.container)}>
                    <IconSuccesswaiting100pxBoldS size={100} />
                    <div className={csn(resultCs.title, "text-center")}>{$st("signature_dw_deposit_success_title")}</div>
                    <div className={csn(resultCs.title)}>{$st("signature_dw_deposit_success_title2")}</div>
                    <div className={resultCs.des}>{$st("signature_dw_deposit_success")}</div>
                </div>
                <Button type="primary" className={cs.again} onClick={setUnCompleted}>
                    {$st("button_confirm")}
                </Button>
            </>
        );
    }

    return (
        <>
            <div>
                <Tabs
                    className={`${tabs.length > 1 ? "" : "hidden"}`}
                    onChange={(str) => {
                        setIsSelect(str);
                    }}
                    items={tabs}
                    defaultActiveKey={tabs[0].key}
                    type="button-group"
                />
            </div>
            <div className={cs.title}>{$st("signature_deposit_title")}</div>
            <div className={"text-accent text-base"}>{$st("signature_deposit_subtitle")}</div>
            <div className={"text-red text-base"}>{$st("signature_deposit_subtitle2")}</div>
            <div className={cs.hint}>
                <div className={cs.hintContent}>
                    <Iconfont icon="iocn_label_defeated" />
                    {isSelect === banksName.chaseBank
                        ? $st("signature_dw_deposit_hint", {
                              strong: (t) => <strong>{t}</strong>,
                              br: <br />,
                              banksName: isSelect,
                          })
                        : $st("signature_dw_deposit_hint_lead_bank", {
                              strong: (t) => <strong>{t}</strong>,
                              br: <br />,
                              banksName: isSelect,
                              str1: "<",
                              str2: ">",
                          })}
                </div>
            </div>
            <div id="circledw_deposit_transfer_infos">
                <div className={cs.subTitle}>
                    {$st("signature_dw_deposit_transfer_info")}
                    <a href={TUTORIAL_URL} target="_blank" rel="noreferrer">
                        <Iconfont icon={"iocn_BotTutorial"} />
                        <span>{$st("tutorial")}</span>
                    </a>
                </div>
                <div className={cs.infoItems}>
                    <div id="circledw_deposit_account">
                        <SignatureStep step={"1"} text={$st("signature_dw_step1")} />
                        <TransferInfoItem require label={$st("signature_dw_transfer_account_id")} value={beneficiaries?.accountIdentifier} />
                        <div className={cs.uniHint}>
                            <div className="mr-[5px]">
                                <IconWarning20pxLineS size={16} />
                            </div>
                            {$st("signature_dw_uni_hint", { br: <br /> })}
                        </div>
                    </div>
                    {isSelect === banksName.leadBank && depositMsg && (
                        <div id="circledw_deposit_account">
                            <TransferInfoItem require label={$st("signature_dw_transfer_deposit_message")} value={depositMsg} />
                            <div className={cs.uniHint}>
                                <div className="mr-[5px] text-warning">
                                    <IconWarning20pxLineS size={16} />
                                </div>
                                {$st("signature_dw_deposit_message", { br: <br /> })}
                            </div>
                        </div>
                    )}
                    {infos.map((item) => (
                        <TransferInfoItem key={item.label} label={item.label} value={item.value} />
                    ))}
                </div>
                <div className={cs.infoItems}>
                    <SignatureStep step={"2"} text={$st("signature_dw_step2")} />
                </div>
                <div className={cs.infoItems}>
                    <SignatureStep step={"3"} text={$st("signature_dw_step3")} />
                </div>
            </div>
            <div className={cs.tutorial}>
                {$st("signature_dw_tutorial", {
                    tutorial: (content) => (
                        <TReportLink to={TUTORIAL_URL} target="_blank">
                            <strong>{content}</strong>
                        </TReportLink>
                    ),
                })}
            </div>
            {banksName.leadBank && (
                <div className={cs.tutorial}>
                    {$st("signature_concat_email", {
                        light: (v) => <span className="text-primary">{v}</span>,
                    })}
                </div>
            )}
            <div className={cs.btns}>
                <TButton
                    type={"primary"}
                    size={"large"}
                    onClick={() => {
                        setIsSelect(banksName.chaseBank);
                        setCompleted();
                    }}
                >
                    {$st("circledw_transfer_completed_wire")}
                </TButton>
            </div>
        </>
    );
};

const DepositWrapper: React.FC = () => {
    const signatureDWAPI = useSignatureDWAPI();
    const [beneficiaries, setBeneficiaries] = useState<BeneficiaryRes>();
    const { $st } = useTranslators();
    const [err, setErr] = useState();
    useEffect(() => {
        if (!signatureDWAPI) {
            return;
        }
        const subscription = requestWithRetry(() => signatureDWAPI.getBeneficiaries(), { maxRetryCount: 3 }).subscribe(
            (data) => {
                setBeneficiaries(data);
            },
            (error) => {
                setErr(error);
            },
        );
        return () => subscription.unsubscribe();
    }, [signatureDWAPI]);

    return (
        <div className={cs.container}>
            {err ? (
                <div className="flex flex-col items-center">
                    <div>
                        <IconSafeguard100pxRegular size={100} />
                    </div>
                    <div>{$st("usddw_deposit_astrict")}</div>
                </div>
            ) : (
                <TSpin spinning={!beneficiaries}>{beneficiaries && <Deposit beneficiaries={beneficiaries} />}</TSpin>
            )}
        </div>
    );
};

export default withMaintenance(DepositWrapper, { payMethod: DWPayMethod.WIRE, direction: QuotaDirection.DEPOSIT });
