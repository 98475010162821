import * as React from "react";
import { Props } from "components/svg/types";

export default function IconAssetSwap(props: Props) {
    const { color } = props;
    return (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0929 1.90039C11.9386 1.90039 12.9933 2.59971 12.9933 4.6248" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
            <path d="M4.28809 13.3623C2.4424 13.3623 1.38772 12.663 1.38772 10.6379" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
            <path
                d="M8.19342 3.60025C8.04356 3.2165 7.8339 2.8627 7.5759 2.5503C6.86978 1.69529 5.80155 1.15037 4.60606 1.15037C2.47987 1.15037 0.756253 2.87399 0.756253 5.00018C0.756253 6.55312 1.67574 7.8913 3 8.5C3.17844 8.58202 3.36423 8.6508 3.55611 8.70507"
                stroke={color}
                strokeWidth="1.2"
                strokeLinecap="round"
            />
            <path
                d="M13.5 9.5C13.5 11.6262 11.7764 13.3498 9.65019 13.3498C7.524 13.3498 5.80039 11.6262 5.80039 9.5C5.80039 7.37381 7.524 5.65019 9.65019 5.65019C11.7764 5.65019 13.5 7.37381 13.5 9.5Z"
                stroke={color}
                strokeWidth="1.2"
            />
        </svg>
    );
}
