import React from "react";
import AssetDisplay from "../AssetDisplay";
import OrderBalancePie from "landings/Balance/components/TradingAccounnt/OrderBalancePie";
import BalanceCfg from "state/BalanceCfg";
import { Balance } from "../Balance";
import { useTranslators } from "src/commonUse/Locale";
import cs from "./cs.m.less";
import Overview from "../Overview";

export interface ITradeAccount {
    balanceCfg: BalanceCfg;
    onLoading: boolean;
    tradeTotalDisplay: string;
    tradeTodayProfitDisplay: string;
    tradeTodayProfitColor: string;
    totalBalanceDisplay: string;
    financeTotalDisplay: string;
    balanceStatTotalUSD: number;
    balanceStatDepositInUSD: number;
    balanceStatWithdrawInUSD: number;
    balanceStatLoading: boolean;
    financeTodayProfitDisplay: string;
    financeTodayProfitColor: string;
    fullBalance?: {
        total: {
            totalInUSD: number;
            totalInBTC: number;
            tradeTotalInUSD: number;
            financeTotalInUSD: number;
        };
    };
}
const TradeAccount = ({
    financeTotalDisplay,
    financeTodayProfitColor,
    balanceStatTotalUSD,
    balanceStatDepositInUSD,
    balanceStatWithdrawInUSD,
    balanceStatLoading,
    financeTodayProfitDisplay,
    onLoading,
    balanceCfg,
    tradeTotalDisplay,
    tradeTodayProfitDisplay,
    tradeTodayProfitColor,
    fullBalance,
    totalBalanceDisplay,
}: ITradeAccount) => {
    const { $st } = useTranslators();
    return (
        <div>
            <div className={cs.wrapper}>
                <Overview
                    balanceTotalUSD={fullBalance?.total?.totalInUSD || 0}
                    balanceTotalBTC={fullBalance?.total?.totalInBTC || 0}
                    tradeTotalUSD={fullBalance?.total?.tradeTotalInUSD || 0}
                    financeTotalUSD={fullBalance?.total?.financeTotalInUSD || 0}
                    totalBalanceDisplay={totalBalanceDisplay}
                    tradeTotalDisplay={tradeTotalDisplay}
                    financeTotalDisplay={financeTotalDisplay}
                    balanceStatTotalUSD={balanceStatTotalUSD}
                    balanceStatDepositInUSD={balanceStatDepositInUSD}
                    balanceStatWithdrawInUSD={balanceStatWithdrawInUSD}
                    balanceStatLoading={balanceStatLoading}
                    financeTodayProfitDisplay={financeTodayProfitDisplay}
                    financeTodayProfitColor={financeTodayProfitColor}
                    tradeTodayProfitDisplay={tradeTodayProfitDisplay}
                    tradeTodayProfitColor={tradeTodayProfitColor}
                />
                <OrderBalancePie walletType="trade" />
            </div>
            <Balance className={cs.balance} balanceCfg={balanceCfg} onLoading={onLoading} />
        </div>
    );
};

export default TradeAccount;
