import { PionexReportV2 } from "./ReportV2";
import { report } from "utils/GoogleTagManager";

/**
 * Web上报 Google Analytics
 * @deprecated Use {@link PionexReportV2} instead.
 */
export const PionexReport = {
    /**
     * 交易页面点击行为
     * 1.账户设置  2.系统设置  3.Trade  4.My bots  5.资产按钮  6.只显示当前交易对  7.查看历史订单
     * @param type
     */
    reportClickInfo(type: number) {
        report("pionex_exchange_page", { button: type });
    },

    /**
     * 系统设置情况
     * 1.Currency setting  2. Language setting  3.white mode  4.Reverse green and red  5.Google Authenticator  6.Fees
     * 11. theme normal 12. theme dark
     * 21. red up 22. green up 23 start migrate
     * @param index
     */
    reportSystemOption(index: number) {
        report("pionex_system_set", { button: index });
    },

    /**
     * 账户设置点击情况
     * 1.Deposit  2.Withdraw  3.Referral program  4.Balance 5.custom service  6.fees
     * @param type
     */
    reportAccountClick(type: number) {
        report("pionex_account_set", { button: type });
    },

    /**
     * 选择交易对排序情况
     * 1.PAIR  2.VOL  3.PRICE  4.CHANGE
     * @param type
     */
    reportSymbolSortTyle(type: number) {
        report("pionex_pair_rank", { button: type });
    },

    /**
     * 资产详情页面情况
     *
     * @param coin
     * @param operation 1.Deposit  2.Withdraw  3.Trade
     */
    reportCoinOperate(coin: string, operation: number) {
        report("pionex_balance_detail", { coin: coin, operation: operation });
    },

    /**
     * Deposit页面
     *
     * @param coin
     * @param dwType "deposit" | "withdraw"
     * @param operation  1.点击QR code  2.点击COPY  3.点击Transaction ID  4.点击了go trading的交易对
     */
    reportDepositInfo(coin: string, dwType: "deposit" | "withdraw", operation: number) {
        report("pionex_deposit", { coin: coin, dwType: dwType, operation: operation });
    },

    /**
     * Withdraw页面
     * @param coin
     * @param operation  1.点击submit成功  2.点击submit报错  3.点击Transaction ID  4.点击了go trading的交易对
     */
    reportWithdrawInfo(coin: string, operation: number) {
        report("pionex_withdraw", { coin: coin, operation: operation });
    },

    /**
     * 交易页面订单处
     * @param cardType  1.Trading bots  2.Manual orders
     * @param operation  "cancel" | "share" | "detail"
     * @param state 订单状态  同Order.state
     */
    reportBotCardOperate(cardType: string, state: string, operation: string) {
        report("pionex_order_operate", { tab: cardType, state, operation: operation });
    },

    /**
     *
     * @param tab 1.登录tab   2.注册tab
     * @param operation 1.点击这个tab   2.点击发送验证码   3.点击登录/注册大按钮   4.点击登录/注册后成功  5.点击登录/注册后失败  6.点击忘记密码  7.点击加入社群
     */
    pionex_log_page(tab, operation) {
        report("pionex_log_page", { tab: tab, operation: operation });
    },

    /**
     *
     * @param headertab 小按钮登录注册
     *
     */
    header_pionex_log_page(headertab) {
        report("header_pionex_log_page", { headertab: headertab });
    },

    /**
     *
     * @param operation 1What's Pionex 2Market 3CoinCap 4点击iOS 5点击Android 6点击Market的任意交易对 7点击CoinCap任意交易对
     */
    pionex_main_page(operation) {
        report("pionex_main_page", { operation });
    },

    /**
     *
     * @param operation 1成功修改用户名 2成功设置头像 3成功解绑谷歌验证 4成功修改Password
     */
    pionex_personal_set(operation: number) {
        report("pionex_personal_set", { setting: operation });
    },

    /**
     * 查看达人榜订单备注
     */
    reportExpertRemarkClick(operation: string) {
        report("pionex_contest_expert_bot_remark", { orderId: operation });
    },

    /**
     * 切换大赛tab
     */
    reportContestTabClick(tabName: string) {
        report("pionex_contest_tab_clicked", { tabName });
    },
};
