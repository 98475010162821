import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import cs from "./index.module.less";
import { useTranslators } from "commonUse/Locale";
import CardSelector from "landings/V2/CircleDW/CardSelector";
import { TButton, TCheckbox, TModal, TSpin, TTooltip } from "components/v2/TinyComps";
import { NumberCommonUtils, SafeDecimal } from "trade_utils_lib";
import TSelfNumberInput from "src/components-v2/TSelfNumberInput";
import { AddBankCardParams } from "compliance_lib";
import ResultInPage from "src/components-v2/ResultInPage";
import CardInfoForm, { CardInfoFormRef } from "landings/V2/SignatureDW/Withdraw/CardInfoForm";
import NoKyc, { useNoKycVisible } from "landings/V2/USDDW/components/NoKyc";
import { useSignatureDWAPI } from "landings/V2/SignatureDW/common";
import { useRxRequest } from "commonUse/useRequest";
import { useBalanceTradeByBaseQuote } from "commonUse/useBalance";
import { QuotaChannel, QuotaCheckType, QuotaDirection, useRemainQuota } from "utils/complianceQuota";
import { map } from "rxjs/operators";
import { ThemeName } from "state/ThemeData";
import useTheme from "commonUse/useTheme";
import { useCallbackStatic, useToggle } from "commonUse/tools";
import { useACHWithdrawConfig, useWithdrawConfig } from "landings/V2/SignatureDW/Withdraw/utils";
import { SignatureBankCard, SignatureTransferType, WithdrawParams } from "landings/V2/SignatureDW/types";
import useAccountInfo from "commonUse/useAccountInfo";
import { withMaintenance } from "landings/V2/USDDW/components/Maintenance";
import { useRequestAllChannelStatus } from "landings/V2/USDDW/hooks";
import { USDDWContext } from "landings/V2/USDDW/context";
import { useCheckQuestionnaire, useFuncInterruptByQues } from "utils/questionaire";
import { DWPayMethod } from "landings/V2/USDDW/types";
import { EMPTY_FUNC } from "utils/Utils";
import { MfaParams, VerifyCodeAction } from "bu_account_js_sdk";
import WithdrawVerifyProcess from "src/components-v2/WitdhdrawVerifyProcess";
import { useSymbol } from "@pionex-web-kit/common-business";
import csn from "classnames";
import { message } from "@pionex-web-kit/components";
import TitleCom from "landings/V2/CircleACH/components/TitleCom";
import { useOnlineConfig } from "utils/onlineConfig";
import { useACHFee } from "landings/V2/CircleACH/utils";
import NumberUtils from "utils/NumberUtils";

const USD = "USD";
const USDT = "USDT";
type QuotaType = "quota24" | "balance" | "max";

interface Props {
    className?: string;
    title?: React.ReactNode;
    onResultChange?: (state: boolean) => void; // 提交结果
    transferType?: SignatureTransferType;
    successDes?: string;
}

interface Quota {
    type: QuotaType;
    amount: number;
    displayAmount: string;
    message: string;
}

/**
 * 法币出金
 * @constructor
 */
export const PureWithdraw: React.FC<Props> = ({ className, title, transferType = SignatureTransferType.WIRE, onResultChange, successDes }) => {
    const theme = useTheme();
    const { $st } = useTranslators();
    const accountInfo = useAccountInfo();

    const signatureDWAPI = useSignatureDWAPI();
    const {
        loading,
        data: cards,
        refresh: refreshCards,
    } = useRxRequest(() => signatureDWAPI?.getBankCards(transferType).pipe(map((data) => data.slice(0, 3))), undefined, { requestOnInit: false });
    useEffect(() => {
        refreshCards();

        // 兼容进入时没有apiKey的情况
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureDWAPI]);

    // const [{ originData }] = useKycV2Info();
    // const USD_BASE = originData?.exchange === "paxos" ? "USDP" : "BUSD"; // 币对实际的BASE
    const USD_BASE = USD; // 币对实际的BASE
    const symbol = useSymbol(USD_BASE, USDT);
    const [{ baseAvailable: originUsdAvailable }] = useBalanceTradeByBaseQuote(USD_BASE, USDT); // 原始的余额

    const { details, loading: remainLoading } = useRemainQuota(QuotaChannel.FBO, QuotaDirection.WITHDRAW);
    // 滚动限额
    const quotaDetails = useMemo(() => details.find((item) => item.checkType === QuotaCheckType.WITHDRAWAL_ROLLING_QUOTA_CHECK), [details]);
    // t+n可用金额
    const TNDetails = useMemo(() => details.find((item) => item.checkType === QuotaCheckType.WITHDRAWAL_TPLUSN_QUOTA_CHECK), [details]);
    // 可用余额 = Math.min(原始余额,t+n可用金额)
    const usdAvailable = Math.min(originUsdAvailable, !TNDetails ? Number.MAX_VALUE : Number(TNDetails.quota));

    const [selectedCard, setSelectedCard] = useState<SignatureBankCard>();

    const [withdrawAmount, setWithdrawAmount] = useState("");

    const beforeDWVisible = useNoKycVisible();
    const [verifyCodeVisible, { setTrue: showVerifyCodeModal, setFalse: hideVerifyCodeModal }] = useToggle();
    const [withdrawParams, setWithdrawParams] = useState<WithdrawParams>();
    // const { submitState, submitWithVerifications } = useWithdraw(withdrawParams);
    const [success, { setFalse: setNotSuccess, setTrue: setSuccess }] = useToggle(false, { onChange: onResultChange });
    const [submitting, { setTrue: setInSubmitting, setFalse: setNotSubmitting }] = useToggle();
    const { min: achWithdrawMin, max: achWithdrawMax, free: achFree, feeRules: achFeeRules } = useACHWithdrawConfig();

    const { feeRules: wireFeeRules, min: wireWithdrawMin, limitTip, bankName } = useWithdrawConfig();

    const withdrawMin = transferType === SignatureTransferType.ACH ? achWithdrawMin : wireWithdrawMin;

    const isNumberMatchComparison = useCallback((withdrawAmount: number, expression: string): boolean => {
        const [, tag, target] = expression.match(/^(^\D*)(\d+)$/) || [];
        switch (tag) {
            case ">":
                return Number(withdrawAmount) > Number(target);
            case ">=":
                return Number(withdrawAmount) >= Number(target);
            case "<":
                return Number(withdrawAmount) < Number(target);
            case "<=":
                return Number(withdrawAmount) <= Number(target);
            case "<>":
                return Number(withdrawAmount) !== Number(target);
            case "=":
                return Number(withdrawAmount) === Number(target);
        }
        return false;
    }, []);
    const fee = useMemo(() => {
        const _amount = Number.isNaN(Number(withdrawAmount)) ? 0 : Number(withdrawAmount);
        const feeRules = transferType === SignatureTransferType.ACH ? achFeeRules : wireFeeRules;
        const rule = feeRules?.find((feeRule) => feeRule.rule?.every((expression) => isNumberMatchComparison(Number(_amount), expression)));
        if (rule?.fee !== undefined) {
            return rule.fee;
        }
        return 9.9;
    }, [withdrawAmount, transferType, achFeeRules, wireFeeRules, isNumberMatchComparison]);

    const isFree = useMemo(() => {
        return transferType === SignatureTransferType.ACH && achFree?.enable;
    }, [achFree?.enable, transferType]);

    const convertDisplayMax = useCallback(
        (amount: number) => {
            const displayMax = NumberCommonUtils.formatterDecimalToStr(amount, symbol?.amount);
            return displayMax;
        },
        [symbol?.amount],
    );

    //24小时限额
    const quota24H = useMemo(() => (!quotaDetails ? Number.MAX_VALUE : Number(quotaDetails.quota)), [quotaDetails]);

    //计算ACH限额
    const calculateAchQuota = useCallback(
        (numberInput: number): Quota | undefined => {
            //24小时限额
            const quota24DisplayAmount = convertDisplayMax(quota24H);
            const quota24Quota: Quota = {
                type: "quota24",
                amount: quota24H,
                displayAmount: quota24DisplayAmount,
                message: $st("withdraw_limit_24h_quota", { quota: quota24DisplayAmount }),
            };
            //单笔最大限额
            const maxDisplayAmount = convertDisplayMax(achWithdrawMax);
            const maxQuota: Quota = {
                type: "max",
                amount: achWithdrawMax,
                displayAmount: maxDisplayAmount,
                message: $st("withdraw_limit_maximum_transaction_amount", { amount: maxDisplayAmount }),
            };
            //余额限额
            const balanceDisplayAmount = convertDisplayMax(usdAvailable);
            const balanceQuota: Quota = {
                type: "balance",
                amount: usdAvailable,
                displayAmount: balanceDisplayAmount,
                message: $st("withdraw_limit_balance", { balance: balanceDisplayAmount }),
            };
            const quotas: Quota[] = [quota24Quota, maxQuota, balanceQuota]
                .filter((value) => value.amount < numberInput) //过滤超过的限额
                .sort((a, b) => a.amount - b.amount); //限额从小到大排序

            return quotas && quotas.length > 0 ? quotas[0] : undefined; //取限额最低的项
        },
        [$st, achWithdrawMax, convertDisplayMax, quota24H, usdAvailable],
    );

    const amountError = useMemo(() => {
        if (!withdrawAmount && withdrawAmount !== "0") {
            // 未输入内容不报错
            return;
        }
        const n = Number(withdrawAmount);
        const max = Math.min(usdAvailable, !quotaDetails ? Number.MAX_VALUE : Number(quotaDetails.quota)); // 余额，限额
        if (transferType === SignatureTransferType.ACH) {
            const achQuota = calculateAchQuota(n);
            if (achQuota && n > achQuota.amount) {
                if (Number(achQuota.displayAmount) === 0) {
                    return $st("withdraw_limit_max_zero");
                }
                return achQuota.message;
            }
        } else {
            if (!isNaN(n) && n > max) {
                const _displayMax = NumberCommonUtils.formatterDecimalToStr(max, symbol?.amount);
                // bugfix: https://pionex.atlassian.net/browse/PCO-2368
                if (Number(_displayMax) === 0) return $st("withdraw.limit.max.zero");
                return $st("withdraw.limit.max", { max: _displayMax, coin: USD });
            }
        }
        if (!isNaN(n) && n < withdrawMin) {
            return $st("withdraw.limit.min", { min: withdrawMin, coin: USD });
        }
    }, [$st, calculateAchQuota, quotaDetails, symbol?.amount, transferType, usdAvailable, withdrawAmount, withdrawMin]);

    const isShowCardForm = !selectedCard;

    const [checkedAgree, setCheckedAgree] = useState(false);
    const invokeSubmit = useCallback(
        (params: WithdrawParams) => {
            setWithdrawParams(params);
            showVerifyCodeModal();
        },
        [showVerifyCodeModal],
    );
    const handleClickSubmit = async () => {
        if (withdrawAmount === "") {
            // 未填写amount
            message.warn($st("signature_dw_withdraw_step1"));
            return;
        }
        if (!!amountError) {
            // 出金数量不合法
            return;
        }
        if (!checkedAgree) {
            // 未勾选同意
            message.warn($st("signature_dw_withdraw_not_agree"));
            return;
        }

        setInSubmitting();
        if (await interruptByQues("fiat", withdrawAmount)) {
            setNotSubmitting();
            return;
        }
        setNotSubmitting();

        if (!selectedCard) {
            // 未选择银行卡提交银行卡信息表单
            cardFormRef.current?.submit();
            return;
        }

        // 直接提交选择的银行卡
        invokeSubmit({ bankId: selectedCard?.bankId, amount: withdrawAmount });
    };
    const cardFormRef = useRef<CardInfoFormRef>(null);
    const handleCardFormFinish = (values: AddBankCardParams & { fileIds?: string }) => {
        const { fileIds, ...bankValues } = values;
        invokeSubmit({ bank: bankValues, amount: withdrawAmount, fileIds });
    };

    useEffect(() => {
        // 切换到成功状态自动滚动到顶部
        if (!success) {
            return;
        }
        setTimeout(() => {
            document.documentElement.scroll({
                top: 0,
                behavior: "smooth",
            });
        }, 100);
    }, [success]);

    useCheckQuestionnaire();
    const interruptByQues = useFuncInterruptByQues();

    const bizParams = useMemo(
        () =>
            withdrawParams && {
                bank_id: withdrawParams.bankId,
                amount: withdrawParams.amount,
                channel: QuotaChannel.FBO,
                currency: "USD",
            },
        [withdrawParams],
    );
    const handleRequestWithdraw = useCallbackStatic((mfaParams: MfaParams) =>
        signatureDWAPI!.withdraw({
            ...withdrawParams!,
            txn_id: mfaParams.txn_id,
            bank_name: bankName,
        }),
    );

    const { activity_display } = useACHFee("withdraw") ?? {};
    const fee0 = useMemo(() => transferType === SignatureTransferType.ACH && activity_display, [activity_display, transferType]);
    const actualAmount = useMemo(() => {
        if (!!amountError) {
            return "--";
        }
        if (withdrawAmount === "") {
            return "--";
        }

        const n = Number(withdrawAmount);
        if (isNaN(n)) {
            return "--";
        }

        if (isFree) {
            return n.toString();
        }

        return new SafeDecimal(n).sub(fee).toString();
    }, [amountError, fee, isFree, withdrawAmount]);
    const summary = useMemo(
        () => [
            {
                label: $st("circledw_withdraw_quota", { symbol: USD }),
                value: remainLoading ? "--" : `${NumberUtils.numberWithCommas(NumberCommonUtils.formatterDecimalToStr(quotaDetails?.quota, symbol?.amount))} ${USD}`,
                tips: $st("signature_dw_withdraw_quota_tips", { limitTip: limitTip }),
            },
            {
                label: $st("signature_withdraw_handling_fee"),
                value:
                    transferType === SignatureTransferType.ACH && achFree?.enable ? (
                        <div className="flex items-center justify-center">
                            <div>0.00 USD</div>
                            <div className="ml-6px line-through">{`${fee} ${USD}`}</div>
                            <div className="ml-6px text-white text-[8px] bg-gradient-to-r from-[#FF7028] to-[#FF9D0B] rounded-16px px-4px">{achFree.title}</div>
                        </div>
                    ) : (
                        `${fee} ${USD}`
                    ),
            },
            {
                label: $st("circledw_withdraw_actual_amount"),
                value: `${NumberUtils.numberWithCommas(actualAmount)} ${USD}`,
            },
        ],
        [$st, achFree?.enable, achFree?.title, actualAmount, fee, limitTip, quotaDetails?.quota, remainLoading, symbol?.amount, transferType],
    );

    const handleCancel = useCallback(() => {
        hideVerifyCodeModal();
        setNotSubmitting();
    }, [hideVerifyCodeModal, setNotSubmitting]);

    return (
        <div className={csn(cs.container, className)}>
            {beforeDWVisible ? (
                <NoKyc reportEvent="fiatwithdraw" type="withdraw" />
            ) : (
                <TSpin spinning={loading}>
                    {(() => {
                        if (loading) {
                            return null;
                        }
                        if (success) {
                            return <ResultInPage className={cs.success} type={"success"} des={successDes || $st("wire_withdraw_success_des")} />;
                        }

                        return (
                            <>
                                {title}
                                <div className={cs.step}>{`1.${$st("signature_dw_withdraw_step1")}`}</div>
                                <TSelfNumberInput
                                    wrapperClassName={cs.amount}
                                    size={"large"}
                                    value={withdrawAmount}
                                    onChange={setWithdrawAmount}
                                    error={amountError}
                                    placeholder={$st("circledw_history_title_withdraw_amount")}
                                    maxPrecision={2}
                                />
                                <div className={cs.summaryItem}>
                                    <div>
                                        {$st("symbol_available_balance", { symbol: USD })}
                                        <img
                                            alt={""}
                                            onClick={() => {
                                                TModal.info({
                                                    title: $st("dw_available_tips_title"),
                                                    content: $st("dw_available_tips_content", { br: <br /> }),
                                                });
                                            }}
                                            src={
                                                theme.name === ThemeName.dark
                                                    ? require("images/trading/icon_advance_setting_info_dark.png")
                                                    : require("images/trading/icon_advance_setting_info.png")
                                            }
                                            className={cs.itemTips}
                                        />
                                    </div>
                                    <div className={cs.summaryValue}>
                                        {remainLoading ? "--" : `${NumberUtils.numberWithCommas(NumberCommonUtils.formatterDecimalToStr(usdAvailable, symbol?.amount))} ${USD}`}
                                    </div>
                                </div>
                                {summary.map((item) => (
                                    <div className={cs.summaryItem} key={item.label}>
                                        <div>
                                            {item.label}
                                            {!!item.tips && (
                                                <TTooltip title={<span>{item.tips}</span>}>
                                                    <img
                                                        src={
                                                            theme.name === ThemeName.dark
                                                                ? require("images/trading/icon_advance_setting_info_dark.png")
                                                                : require("images/trading/icon_advance_setting_info.png")
                                                        }
                                                        className={cs.itemTips}
                                                    />
                                                </TTooltip>
                                            )}
                                        </div>
                                        <div className={cs.summaryValue}>{item.value}</div>
                                    </div>
                                ))}
                                <div className={cs.step2}>{`2.${$st("signature_dw_withdraw_step2")}`}</div>
                                <div className={cs.stepDes}>{$st("signature_dw_withdraw_select_bank")}</div>
                                {!!cards?.length && <CardSelector disableAdd bankCards={cards} selectedCardId={selectedCard?.bankId} onSelectCardChange={setSelectedCard} />}
                                {!isShowCardForm && (
                                    <div
                                        className={cs.switchCardForm}
                                        onClick={() => {
                                            // 清空选择，展示表单
                                            setSelectedCard(undefined);
                                        }}
                                    >
                                        {$st("signature_dw_withdraw_switch_card")}
                                    </div>
                                )}
                                {isShowCardForm && <CardInfoForm className={cs.cardForm} ref={cardFormRef} onFinish={handleCardFormFinish} transferType={transferType} />}
                                <div className={cs.tips}>{$st("signature_dw_withdraw_tips", { minAmount: withdrawMin })}</div>
                                <div className={cs.tips_email}>{$st("signature_dw_withdraw_concat_email", { light: (c) => <span className="text-primary">{c}</span> })}</div>
                                <TCheckbox checked={checkedAgree} onChange={(e) => setCheckedAgree(e.target.checked)}>
                                    {$st("signature_dw_withdraw_agree")}
                                </TCheckbox>
                                <TButton loading={submitting} className={cs.confirm} size={"large"} type={"primary"} onClick={handleClickSubmit}>
                                    {$st("common_submit")}
                                </TButton>
                                {verifyCodeVisible && withdrawParams && (
                                    <WithdrawVerifyProcess
                                        action={VerifyCodeAction.fiat_currency_withdraw}
                                        bizParams={bizParams!}
                                        onRequestWithdraw={handleRequestWithdraw}
                                        accountInfo={accountInfo}
                                        onLoad={EMPTY_FUNC}
                                        onSuccess={setSuccess}
                                        onError={setInSubmitting}
                                        onCancel={handleCancel}
                                    />
                                )}
                            </>
                        );
                    })()}
                </TSpin>
            )}
        </div>
    );
};

const Withdraw: React.FC<Props> = (props) => {
    const { $st } = useTranslators();
    const config = useOnlineConfig();

    const [success, setSuccess] = useState(false);
    return (
        <PureWithdraw
            {...props}
            transferType={SignatureTransferType.WIRE}
            title={!success ? <TitleCom title={$st("wire_withdraw_title")} /> : null}
            onResultChange={setSuccess}
            successDes={$st("wire_withdraw_success_des")}
        />
    );
};

const DefaultWithdraw = withMaintenance(Withdraw, { payMethod: DWPayMethod.WIRE, direction: QuotaDirection.WITHDRAW });

export default DefaultWithdraw;

/**
 * 非法币出入金总入口独立引用该组件
 * @constructor
 */
export const SelfFBOWithdraw: React.FC = () => {
    const { data: channelStatusInfo } = useRequestAllChannelStatus();
    return (
        <USDDWContext.Provider value={{ channelStatusInfo }}>
            <DefaultWithdraw transferType={SignatureTransferType.ACH} />
        </USDDWContext.Provider>
    );
};
