import { message } from "antd";
import { history } from "utils/History";
import { mcode } from "utils/i18n";
import saveRreurl from "utils/saveRreurl";
import { removeUserInfo } from "utils/userInfo";

export default function errorMiddleware() {
    return (next) => (action) => {
        const { error } = action;
        // 需要排除 redux-form 的错误码
        // if (error && !action.type.includes('redux-form/')) {
        if (error) {
            if (action.payload instanceof Error) {
                throw new Error(action.payload);
            } else {
                if (action.payload) {
                    // success、info、warning、error
                    message.error(mcode(action.payload));
                }
                // USER_SERVICE_0019 token 过期
                // 10002 token 为空
                if (action.payload === "USER_SERVICE_0019" || action.payload === "10002") {
                    setTimeout(() => {
                        removeUserInfo();
                        // 清除其他浏览器标签页的sessionStorage
                        // logoutSessionStorage();
                        const loginUrl = saveRreurl();
                        // 返回之前访问的页面
                        history.push(loginUrl);
                    }, 2000);
                }
            }
            // const { meta: { fallback } = {} } = action;
            // if (typeof fallback !== 'undefined') {
            //   return next({
            //     ...action,
            //     payload: fallback
            //   });
            // }
            // 当有错误信息时.则错误的action不再往外发送.
            // 当action的meta中有fallback时, 错误的action以fallback作为payload的值继续往外发送.
        } else {
            return next(action);
        }
    };
}
