import { SecurityVerification } from "bu_account_js_sdk";
import csn from "classnames";
import CryptoJS from "crypto-js";
import React, { CSSProperties, useMemo } from "react";
import { VerifyCodeAction } from "trade_asset_lib";
import Constants from "utils/Constants";
import { $st } from "utils/i18n";
import cs from "./index.m.less";

interface Props {
    account?: string; // 账号
    action?: VerifyCodeAction;
    className?: string;
    style?: CSSProperties;
    chooseReset?: SecurityVerification; // 选择哪一种重置方式
}

/**
 * 跳转重置 Google 验证码页面的入口
 */
const ResetGoogleLink = (props: Props) => {
    const { account, action, className, style, chooseReset } = props;

    const returnURL = useMemo(() => {
        const url = action === VerifyCodeAction.reset_otp ? `${Constants.origin}/my_profile` : window.location.href;

        return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(url));
    }, [action]);

    if (!account) {
        return null;
    }

    const handleLink = () => {
        window.location.href = `${Constants.accountsRoute.resetSecurityAccount}?account=${encodeURIComponent(account)}&scene=${action || "unknown"}&return_to=${returnURL}${
            chooseReset ? `&choose_reset=${chooseReset}` : ""
        }`;
    };

    const displayStr = chooseReset === SecurityVerification.otp ? $st("lost_security_account2") : $st("lost_security_account");

    return (
        <div className={csn(cs.forgetGoogle, className)} style={style} onClick={handleLink}>
            {displayStr}
        </div>
    );
};

export default ResetGoogleLink;
