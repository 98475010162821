import camelCase from "lodash/camelCase";
import snakeCase from "lodash/snakeCase";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";

/**
 * 将下划线key转换成小驼峰
 * @param param
 */
export function camelize(param: Record<string, any>) {
    if (isArray(param)) {
        return param.map((item) => camelize(item));
    }

    if (isObject(param)) {
        return Object.entries(param).reduce((preRes, [k, v], index) => {
            preRes[camelCase(k)] = camelize(v);
            return preRes;
        }, {});
    }

    return param;
}

/**
 * 将小驼峰key转换成下划线
 * @param param
 */
export function uncamelize(param: Record<string, any>) {
    if (isArray(param)) {
        return param.map((item) => uncamelize(item));
    }

    if (isObject(param)) {
        return Object.entries(param).reduce((preRes, [k, v], index) => {
            preRes[snakeCase(k)] = uncamelize(v);
            return preRes;
        }, {});
    }

    return param;
}
