import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { Modal } from "@pionex-web-kit/components";
import csn from "classnames";
import cs from "landings/V2/CircleACH/cs.m.less";
import useKycV2Info, { KycV2Info } from "commonUse/useKycInfoV2";
import useDateFormatter from "commonUse/useDateFormatter";
import { ST, useTranslators } from "commonUse/Locale";
import { LocaleIPData } from "commonUse/tools";
import { timer } from "rxjs";
import { mergeMap } from "rxjs/operators";
import moment from "moment-timezone";
import { ObservableUtils } from "trade_utils_lib";
import Constants from "utils/Constants";
import { ACH_AccountItem } from "landings/V2/CircleACH/utils";
import DateFormatter from "state/DateFormatter";

export type AgreementItem = { label: string; value?: string };

export function requestIpLoc() {
    return ObservableUtils.getV2<LocaleIPData>(`${Constants.accountServiceHost}/iploc`, {}, undefined, false);
}

/**
 * 格式化协议的授权时间
 * @param formatter
 * @param time 秒-时间戳
 */
export function formatAuthTime(formatter: DateFormatter, time?: number) {
    return `${formatter.year_minute(time ? time * 1000 : Date.now())} (${moment.tz.guess()})`;
}

/**
 * 生成协议内展示的字段
 * @param params
 */
export function genAgreementItems(params: {
    $st: ST;
    accountItem: ACH_AccountItem;
    amount: string;
    formatter: DateFormatter;
    ipInfo?: LocaleIPData;
    originData?: KycV2Info["originData"];
}) {
    const { $st, accountItem, amount, formatter, ipInfo, originData } = params;
    const { time, ip } = ipInfo ?? {};

    const timeStr = formatAuthTime(formatter, time);

    const items1 = [
        { label: $st("ach_agreement_institution_name"), value: accountItem.plaid_info.plaid_institution_name },
        { label: $st("ach_agreement_routing_num"), value: accountItem.routing_number },
        { label: $st("ach_agreement_account_num"), value: accountItem.account_number },
        { label: $st("ach_agreement_amount"), value: amount },
        { label: $st("ach_agreement_purpose"), value: $st("ach_agreement_purpose_value") },
        { label: $st("ach_agreement_auth_time"), value: timeStr },
        { label: $st("ach_agreement_auth_ip"), value: ip },
    ];

    const items2 = [
        {
            label: $st("ach_agreement_name"),
            value: [originData?.userInfo?.firstName, originData?.userInfo?.middleName, originData?.userInfo?.lastName].filter(Boolean).join(" "),
        },
        { label: $st("ach_agreement_date"), value: timeStr },
    ];

    return [items1, items2];
}

export interface AgreementProps {
    accountItem: ACH_AccountItem;
    amount: string;
    onOk?: (agreement: AgreementItem[]) => void;
    onCancel?: () => void;
    loading?: boolean;
}

export interface AgreementRef {
    /**
     * 刷新时间
     */
    refreshTime();
}

/**
 * 用户授权的协议
 * @param accountItem
 * @param amount
 * @constructor
 */
const AgreementModal = forwardRef<AgreementRef, AgreementProps>(({ accountItem, amount, onCancel, onOk, loading }, ref) => {
    const [{ originData }] = useKycV2Info();
    const formatter = useDateFormatter();

    const { $st } = useTranslators();

    const [ipInfo, setIpInfo] = useState<LocaleIPData>();
    const { time, ip } = ipInfo ?? {};

    useEffect(() => {
        // 每2分钟自动刷新一次时间
        const subscription = timer(0, 2 * 60 * 1000)
            .pipe(mergeMap(() => requestIpLoc()))
            .subscribe(setIpInfo);
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    useImperativeHandle(ref, () => ({
        refreshTime: () => {
            requestIpLoc().subscribe(setIpInfo);
        },
    }));

    const [items1, items2] = useMemo(
        () => genAgreementItems({ $st, accountItem, amount, formatter, ipInfo, originData }),
        [$st, accountItem, amount, formatter, ipInfo, originData],
    );

    return (
        <Modal
            className={cs.agreement}
            width={700}
            visible
            title={<div className={"text-center"}>{$st("ach_agreement_title")}</div>}
            onCancel={onCancel}
            okText={$st("ach_agreement_ok")}
            onOk={() => {
                onOk?.([...items1, ...items2]);
            }}
            okButtonProps={{ loading: !ip || loading }}
        >
            <div className={"py-12px px-[24px] max-h-[600px] overflow-auto"}>
                <div>{$st("ach_agreement_des1", { br: <br /> })}</div>
                {items1.map(({ label, value }) => (
                    <div key={label} className={csn(cs.agreementItem, "border-0 border-b border-dashed border-divider")}>
                        <div>{`${label}:`}</div>
                        <div>{value}</div>
                    </div>
                ))}
                <div className={cs.agreementDes2}>
                    {$st("ach_agreement_des2", {
                        br: <br />,
                        service: (content) => <a href={"mailto:service@pionex.us"}>{content}</a>,
                        italic: (content) => <span className={"italic"}>{content}</span>,
                        policy: (content) => (
                            <a target={"_blank"} href={"https://www.checkout.com/legal/privacy-policy"}>
                                <strong className={"text-primary underline"}>{content}</strong>
                            </a>
                        ),
                    })}
                </div>
                {items2.map(({ label, value }) => (
                    <div key={label} className={"flex"}>
                        <div>{`${label}:`}</div>
                        <div>{value}</div>
                    </div>
                ))}
                <div className={cs.agreementDes3}>{$st("ach_agreement_des3")}</div>
            </div>
        </Modal>
    );
});

export default AgreementModal;
