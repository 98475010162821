import { getCurrentLocale } from "utils/i18n/index";
import AppState from "state/AppState";

export function format(date: Date, fmt) {
    let o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S+": date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (const k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ((k === "S+" ? "000" : "00") + o[k]).substr(("" + o[k]).length));
        }
    }
    return fmt;
}

export enum DateFormatterType {
    ymd = 0,
    mdy = 1,
    dmy = 2,
}

export default class DateFormatter {
    type = DateFormatterType.ymd;
    _year_second = "yyyy/MM/dd hh:mm:ss";
    _year_minute = "yyyy/MM/dd hh:mm";
    _year_minute_week = "yyyy/MM/dd hh:mm E";
    _year_day = "yyyy/MM/dd";
    _month_minute = "MM/dd hh:mm";
    _month_day = "MM/dd";
    _hour_second = "hh:mm:ss";

    constructor(type: number) {
        this.type = type;
        if (type === DateFormatterType.mdy) {
            this._year_second = "MM/dd/yyyy hh:mm:ss";
            this._year_minute = "MM/dd/yyyy hh:mm";
            this._year_minute_week = "MM/dd/yyyy hh:mm E";
            this._year_day = "MM/dd/yyyy";
            this._month_minute = "MM/dd hhh:mm";
            this._month_day = "MM/dd";
        } else if (type === DateFormatterType.dmy) {
            this._year_second = "dd/MM/yyyy hh:mm:ss";
            this._year_minute = "dd/MM/yyyy hh:mm";
            this._year_minute_week = "dd/MM/yyyy hh:mm E";
            this._year_day = "dd/MM/yyyy";
            this._month_minute = "dd/MM/ hh:mm";
            this._month_day = "dd/MM";
        } else {
            this.type = DateFormatterType.ymd;
        }
    }

    year_second(timestamp = new Date().getTime()) {
        return format(new Date(timestamp), this._year_second);
    }

    year_minute(timestamp = new Date().getTime()) {
        return format(new Date(timestamp), this._year_minute);
    }

    year_minute_week(timestamp = new Date().getTime()) {
        return format(new Date(timestamp), this._year_minute_week);
    }

    year_day(timestamp = new Date().getTime()) {
        return format(new Date(timestamp), this._year_day);
    }

    month_minute(timestamp = new Date().getTime()) {
        return format(new Date(timestamp), this._month_minute);
    }

    month_day(timestamp = new Date().getTime()) {
        return format(new Date(timestamp), this._month_day);
    }

    hour_second(timestamp = new Date().getTime()) {
        return format(new Date(timestamp), this._hour_second);
    }

    static getFormatTimeLasting(timeInterval: number) {
        if (timeInterval <= 0) {
            return {
                day: 0,
                hour: 0,
                minute: 0,
                second: 0,
            };
        }
        let chaTime = timeInterval;
        let day = 1000 * 60 * 60 * 24; //一天等于毫秒数
        let hour = 1000 * 60 * 60; //一小时等于毫秒数
        let dayResult = Math.floor(chaTime / day); //

        chaTime = chaTime - dayResult * day; //减去天的毫秒数。再求小时个数
        let hourResult = Math.floor(chaTime / hour);
        chaTime = chaTime - hourResult * hour; //减去小时的毫秒数。再求分钟个数
        let minuteResult = Math.floor(chaTime / (1000 * 60));
        let secondResult = Math.floor((chaTime - minuteResult * 1000 * 60) / 1000); //减去分钟的毫秒数。再求秒的个数
        return {
            day: dayResult,
            hour: hourResult,
            minute: minuteResult,
            second: secondResult,
        };
    }
}

const TYPE_CHANGE_DATE_FORMATTER = "type_change_date_formatter";

export function dateFormatterReducer(state: DateFormatter = new DateFormatter(getCurrentLocale().startsWith("zh") ? DateFormatterType.ymd : DateFormatterType.mdy), action) {
    if (action.type === TYPE_CHANGE_DATE_FORMATTER) {
        if (action.data === state.type) {
            return state;
        }
        return new DateFormatter(action.data);
    }
    return state;
}

export function setDateFormatterType(type: DateFormatterType) {
    AppState.store.dispatch({ type: TYPE_CHANGE_DATE_FORMATTER, data: type });
}
