export interface ConvertParams {
    side: "buy" | "sell";
    base_coin: string;
    base_amount: string;
    quote_coin: string;
    quote_amount: string;
    mode?: "buy" | "sell";
}

export interface ConvertRecord {
    side: ConvertParams["side"];
    order_id: string;
    create_time: number;
    update_time: number;
    base_coin: string;
    base_amount: string;
    price: string;
    quote_coin: string;
    quote_amount: string;
    status: ConvertStatus;
    fail_reason: string;
}

export enum ConvertStatus {
    pending = "pending", // 处理中:pending
    success = "success", // 成功:pending
    fail = "fail", // 失败:pending
}
