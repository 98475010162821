import { useOpenHelpCenter } from "commonUse";
import { useGpsInfo, useIPLimits, useLocaleIpInfo } from "commonUse/tools";
import { TModal } from "components/v2/TinyComps";
import { CountryListForLite } from "landings/V2/CircleDW/BankCard/constants";
import React, { useEffect, useState } from "react";
import { $T } from "utils/i18n/trans.comp";

const noneStyle = { style: { display: "none" } };

const getCountryNameByCode = (code: string) => {
    const country = CountryListForLite.find((item) => item.code === code);
    return country?.name || code;
};

/**
 * 强制不能使用的地区处理，弹窗不允许关闭
 */
export function useLimitRegion(): React.ReactNode {
    const { ipInfo } = useLocaleIpInfo();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, limits] = useIPLimits();

    const { getGpsData } = useGpsInfo();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [region, setRegion] = useState<string>();
    const openHelpCenter = useOpenHelpCenter();

    useEffect(() => {
        if (!ipInfo || !limits?.suspended) {
            return;
        }

        const ipLimitCountry = limits.suspended.find((c) => c.country === ipInfo.country_code);
        if (ipLimitCountry) {
            const show = (name = ipInfo.region_name) => {
                setIsModalVisible(true);
                setRegion(name);
            };

            if (ipLimitCountry.regions) {
                const ipLimitRegin = ipLimitCountry.regions.find((region) => region.code === ipInfo.region_code);

                if (ipLimitRegin) {
                    // NOTE: getGPSData 如果没有获取到权限，则（error）默认表示命中
                    getGpsData().subscribe((gpsData) => {
                        const isGPSHit = gpsData.state === ipLimitRegin.name;
                        if (isGPSHit) {
                            show();
                        }
                    }, show);
                }
            } else {
                show(ipInfo.country_name || getCountryNameByCode(ipInfo.country_code));
            }
        }
    }, [ipInfo, limits?.suspended, getGpsData]);

    return (
        <TModal visible={isModalVisible} title={$T("limit_region_title")} okButtonProps={noneStyle} cancelButtonProps={noneStyle} zIndex={9999}>
            {$T("limit_region_des", {
                region,
                service: (c: any) => (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                        onClick={() => {
                            openHelpCenter();
                        }}
                        style={{ textDecoration: "underline" }}
                    >
                        {c}
                    </a>
                ),
            })}
        </TModal>
    );
}
