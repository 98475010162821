import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useBalancePaymentMethod } from "landings/V2/BuyCrypto/Buy/cards/BalancePaymentMethod";
import { useACHPaymentMethods } from "landings/V2/BuyCrypto/Buy/cards/ACHPaymentMethod";
import { FormInstance } from "@pionex-web-kit/components";
import { BuyCryptoParams } from "landings/V2/BuyCrypto/types";
import { Observable } from "rxjs";

export enum PaymentMethodChannel {
    BALANCE = "BALANCE",
    ACH = "ACH",
}

/**
 * 支付方式的抽象
 */
export interface PaymentMethod<Card extends object = object> {
    channel: PaymentMethodChannel;
    card: Card;
    refresh?: () => void;

    /**
     * 生成所有通道唯一key
     */
    getKey(): string;

    /**
     * 选择支付方式时的渲染内容
     */
    renderItem(): ReactNode;

    /**
     * 渲染额外的内容到界面上
     * @param onCancel
     */
    renderExtraContent?(onCancel: () => void): ReactNode;

    /**
     * 通道手续费
     */
    getFee(): string;

    /**
     * 预览时渲染手续费显示
     */
    renderFee?(): ReactNode;

    /**
     * 当前支付方式是否不可用
     */
    disabled(): boolean;

    /**
     * 不同支付方式额外的参数
     * @param params 原始参数
     */
    getExtraParams?(params: BuyCryptoParams): Observable<object>;

    /**
     * 不同支付方式限额提示文案
     */
    getAmountLimitText(): ReactNode;

    /**
     * 不同支付方式对金额的表单校验
     */
    amountValidator(amount: number): Promise<void>;
}

export function usePaymentMethods(form: FormInstance): { data: PaymentMethod[]; refresh: () => void; dataLoaded: boolean } {
    const balancePaymentMethod = useBalancePaymentMethod();

    const { data: achPaymentMethods, refresh: refreshACH, dataLoaded } = useACHPaymentMethods(form);

    return {
        data: useMemo(() => {
            if (!dataLoaded) {
                // 请求结束再组装数据，设置默认值需要一个完全整体的数据变化
                return [];
            }
            const methods: PaymentMethod[] = [balancePaymentMethod];

            // 加入ACH的数据
            methods.push(...achPaymentMethods);

            return methods;
        }, [achPaymentMethods, balancePaymentMethod, dataLoaded]),
        refresh: useCallback(() => {
            refreshACH();
        }, [refreshACH]),
        dataLoaded,
    };
}
