import React, { useMemo } from "react";
import { IconError20pxRegular } from "@pionex-web-kit/icons";
import Link from "components/Link";
import { Alert, Button } from "@pionex-web-kit/components";
import { useTranslators } from "commonUse/Locale";
import useAccountInfo from "commonUse/useAccountInfo";
import { OtpState } from "state/AccountInfo";
import { AccountInfoTag, AccountType } from "bu_account_js_sdk";
import cs from "./index.module.less";
import { SupportDWCoinInfo } from "TradeAPILib";
import { useBalanceAll } from "commonUse/useBalance";
import useExchangeRate from "commonUse/useExchangeRate";
import { DWType } from "landings/DepositWithdraw";
import { useKycV2Rights } from "commonUse/useKycRightsV2";

interface Props {
    currentCoin?: SupportDWCoinInfo;
    tabActiveKey: DWType;
}

/**
 * 冲提顶部提示
 */
const DWWarning: React.FC<Props> = ({ currentCoin, tabActiveKey }) => {
    const { $st } = useTranslators();
    const { otpState: googleVerify, accounts } = useAccountInfo();
    const noTwoFactor = googleVerify !== OtpState.BOUND;
    const isDeposit = tabActiveKey === DWType.deposit;
    const [
        {
            coinDW: {
                // 公共的权限
                $permission: $dwPermission,
            },
        },
    ] = useKycV2Rights();

    const { isPhoneBind, isEmailBind } = useMemo(() => {
        let isPhoneBind = false; // 是否绑定手机号
        let isEmailBind = false; // 是否绑定邮箱
        accounts?.forEach(({ account_type, account_info_tag }) => {
            if (account_info_tag === AccountInfoTag.master) {
                if (account_type === AccountType.email) {
                    isEmailBind = true;
                } else if (account_type === AccountType.phoneNumber) {
                    isPhoneBind = true;
                }
            } else if (account_type === AccountType.email) {
                isEmailBind = true;
            } else if (account_type === AccountType.phoneNumber) {
                isPhoneBind = true;
            }
        });
        return {
            isPhoneBind,
            isEmailBind,
        };
    }, [accounts]);
    const isGoogleBind = googleVerify === OtpState.BOUND; // 是否绑定谷歌验证

    const fullBalance = useBalanceAll();
    const exchangeRate = useExchangeRate();
    const totalBalance = useMemo(() => (fullBalance?.total?.totalInUSD || 0) * exchangeRate.exchangeRate, [exchangeRate.exchangeRate, fullBalance?.total?.totalInUSD]);

    /* 资产大于等于1000USDT的提示 */
    const show2FAWarning = useMemo(() => {
        return noTwoFactor && tabActiveKey === DWType.withdraw && totalBalance >= 1000;
    }, [noTwoFactor, tabActiveKey, totalBalance]);
    /* 资产小于1000USDT的提示 */
    const showEmailOrPhoneWarning = useMemo(() => {
        return tabActiveKey === DWType.withdraw && totalBalance < 1000 && [isEmailBind, isPhoneBind, isGoogleBind].filter((e) => e).length < 2;
    }, [isEmailBind, isGoogleBind, isPhoneBind, tabActiveKey, totalBalance]);

    if (!$dwPermission) {
        // 展示kyc引导时，不展示此提示组件
        return null;
    }

    if (show2FAWarning) {
        return (
            <Alert
                icon={<IconError20pxRegular size={18} />}
                className="mb-12px"
                message={
                    <div className={cs.alert_content}>
                        <span>{$st("dialog_google_guide_content")}</span>
                        <div className="flex gap-12px">
                            <Link to="/googleAuthenticator">
                                <Button size="small" shape="round" type="primary" className={cs.btn_warning}>
                                    {$st("common_go_bind")}
                                </Button>
                            </Link>
                        </div>
                    </div>
                }
                type="warning"
            ></Alert>
        );
    } else if (showEmailOrPhoneWarning) {
        return (
            <Alert
                icon={<IconError20pxRegular size={18} />}
                className="mb-12px"
                message={
                    <div className={cs.alert_content}>
                        <span>{$st("account_need_to_bind", { type: $st(isEmailBind ? "mobile_phone_number" : "email_address") })}</span>
                        <div className="flex gap-12px">
                            {!isGoogleBind && (
                                <Link to="/googleAuthenticator">
                                    <Button size="small" shape="round" className={cs.btn_warning_hollow}>
                                        {$st("to_bind_google")}
                                    </Button>
                                </Link>
                            )}
                            {!isEmailBind && (
                                <Link to="/account/bind">
                                    <Button size="small" shape="round" type="primary" className={cs.btn_warning}>
                                        {$st("to_bind_email")}
                                    </Button>
                                </Link>
                            )}
                            {!isPhoneBind && (
                                <Link to="/account/bind">
                                    <Button size="small" shape="round" type="primary" className={cs.btn_warning}>
                                        {$st("to_bind_phone")}
                                    </Button>
                                </Link>
                            )}
                        </div>
                    </div>
                }
                type="warning"
            ></Alert>
        );
    } else if (!isDeposit && currentCoin?.currency === "ETHW") {
        return <Alert className={"mb-12px"} message={$st("withdraw_warning_ethw")} type="warning" />;
    }

    return null;
};

export default DWWarning;
