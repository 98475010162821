import Constants from "utils/Constants";
import { ClientPlatform, ObservableUtils, PRODUCT_TYPE, buildClientId } from "trade_utils_lib";
import { AxiosV1Token } from "commonUse/useV1Token";
export type getRecordParams = {
    exchange: any;
    key_id: any;
};

export type RecordResult = {
    export_record: {
        create_time: number;
        expire_time: number;
        generate_time: number;
        path: string;
        status: 1 | 2 | 3;
    } | null;
    remain_num: number;
};

export interface TransactionApplyParams {
    start_time: number;
    end_time: number;
}

const getV1ClientId = () => {
    return buildClientId(PRODUCT_TYPE.PIONEX_US, ClientPlatform.WEB, process.env.VERSION);
};
export default class TaxesAPI {
    static getRecord(): Promise<RecordResult> {
        const _url = `${Constants.balanceApiHost}/export_transaction/record/`;
        return ObservableUtils.getV2<RecordResult>(_url, {}, null, true).toPromise();
    }
    static postRecord(data: TransactionApplyParams): Promise<RecordResult> {
        const _url = `${Constants.balanceApiHost}/export_transaction/apply/`;
        return ObservableUtils.postV2<RecordResult>(_url, data, null, true).toPromise();
    }
    static postRecordV1(data: TransactionApplyParams, v1token: string): Promise<RecordResult> {
        return AxiosV1Token(
            {
                method: "POST",
                url: `${Constants.balanceApiHost}/export_transaction/apply/`,
                data,
                headers: {
                    Authorization: `Bearer ${v1token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                },
            },
            v1token,
        );
    }
    static getRecordV1(v1token: string): Promise<RecordResult> {
        return AxiosV1Token(
            {
                url: `${Constants.balanceApiHost}/export_transaction/record/`,
                headers: {
                    Authorization: `Bearer ${v1token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                },
            },
            v1token,
        ).then((res) => {
            return res?.data?.data;
        });
    }
}
