import useAccountInfo from "commonUse/useAccountInfo";
import LoadingPage from "components/LoadingPage";
import { Route as ReactRoute, RouteProps } from "react-router";
import AccountManager from "utils/AccountManager";
import { IDefaultLayoutConfig, LayoutConfig } from "./Layout";
import Loadable, { Options } from "react-loadable";
import React from "react";
import { USD_DW_ROUTE_V2 } from "landings/V2/USDDW/menuRouter";
import { FromName } from "landings/Analytics/EventName";

/**
 * TODO: 重新包装Route组件：
 * - 需要支持将LayoutConfig前置到Route注册阶段，以规避页面组件异步加载导致的配置延迟更新的问题；
 * - 融合Loadable组件，同步组件网络加载状态，用户判别公共的页脚显示出来的时机。
 */

interface WrappedRouteProps<Props, Exports extends object> extends RouteProps {
    layoutConfig?: IDefaultLayoutConfig;
    loadable?: Required<Pick<Options<Props, Exports>, "loader">> & Partial<Omit<Options<Props, Exports>, "loader" | "loading">>;
}
const LoadableCache = new Map<string, any>();
const getLoadablePath = (path: string | string[]) => {
    return Array.isArray(path) ? path.join(",") : path;
};
/**
 * 支持布局配置的路由注册组件
 * @param param0
 * @returns
 */
export function RouteLC<Props = any, Exports extends object = any>({ path, layoutConfig, component, loadable, ...props }: WrappedRouteProps<Props, Exports>) {
    if (path && loadable && !component) {
        // loadable only support path specified routes.
        const loadablePath = getLoadablePath(path);
        let loadableComp = LoadableCache.get(loadablePath);
        if (!loadableComp) {
            loadableComp = Loadable<Props, Exports>({ ...loadable, loading: LoadingPage } as Options<Props, Exports>);
            LoadableCache.set(loadablePath, loadableComp);
        }
        component = loadableComp;
    }
    // 对props的包装处理不需要缓存处理，避免上层数据不能及时更新
    const id = Array.isArray(path) ? path[0] : path;
    return (
        <LayoutConfig id={id} {...layoutConfig}>
            <ReactRoute path={path} component={component} {...props} />
        </LayoutConfig>
    );
}

/**
 * 组件将检测用户是否登录，确定后才会渲染真实的路由组件。
 * 将可以规避需要登录的业务页面非法访问时，导致的报错问题。
 * @param routeProps
 * @returns
 */
export default function AuthRoute<Props = any, Exports extends object = any>(routeProps: WrappedRouteProps<Props, Exports>) {
    const { userId, isInitialized } = useAccountInfo();
    // 若账户未完成初始化，显示加载中
    if (!isInitialized) return <LoadingPage />;
    else if (!userId) {
        // 完成初始化后，没有获取到userId，则认为没有登录。
        // 此时跳转至登录页面
        AccountManager.toSign(getFrom() || FromName.auth_route, { redirectBack: true });
        return null;
    }
    return <RouteLC<Props, Exports> {...routeProps} />;
}

const FROM_PARAMS_CONFIG = { [USD_DW_ROUTE_V2.ACH._]: FromName.deposit_ach, [USD_DW_ROUTE_V2.DEBIT._]: FromName.deposit_debit, [USD_DW_ROUTE_V2.WIRE._]: FromName.deposit_wire };
/**
 * 跳转登录页时的from参数
 */
function getFrom() {
    const pathname = window.location.pathname;

    for (const k in FROM_PARAMS_CONFIG) {
        if (pathname.includes(k)) {
            return FROM_PARAMS_CONFIG[k];
        }
    }
}
