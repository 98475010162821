import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import StripeACHModal from "./StripeACHModal";
import { Modal, Spin, message, Alert } from "@pionex-web-kit/components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslators } from "commonUse/Locale";
import { createAmlTask, createPaymentIntent, fiatBuyCrypto, generateUUID, getPublicKey, getTaskStatus } from "./StripeAPI";
import { interval, of } from "rxjs";
import { mergeMap, switchMap } from "rxjs/operators";
import { StripeProvider, useStripeProvider } from "./StripeProvider";
import useTheme from "commonUse/useTheme";
import useKycV2Info from "commonUse/useKycInfoV2";
import { useOnlineConfig } from "utils/onlineConfig";
import { PayMentMethod } from "landings/V2/BuyCrypto/components/Form";
import useAccountInfo from "commonUse/useAccountInfo";
import { APIKeyInfo } from "trade_utils_lib";
import NumberUtils from "utils/NumberUtils";
import { IconWarning20pxLineS } from "@pionex-web-kit/icons";
import { useTicker } from "@pionex-web-kit/common-business";

function StripeACHEle({ onClose }: { onClose: () => void }) {
    const theme = useTheme();
    const { amount, coin, paymentInfo, setPaymentInfo } = useStripeProvider();
    const clientSecret = paymentInfo?.channel_client_secret;
    const [taskId, setTaskId] = useState("");
    const [checkResult, setCheckResult] = useState("");
    const [publicKey, setPublicKey] = useState("");
    const accountInfo = useAccountInfo();
    const { exchange, key_id } = (accountInfo?.apiKeys || [])[0] ?? ({} as APIKeyInfo);
    const stripePromise = useMemo(() => {
        return publicKey ? loadStripe(publicKey) : undefined;
    }, [publicKey]);
    const options = useMemo(() => {
        return {
            clientSecret,
            appearance: { theme: theme.dark ? "night" : "stripe" },
        } as StripeElementsOptions;
    }, [clientSecret, theme.dark]);
    const { $st } = useTranslators();

    const [{ originData }] = useKycV2Info();
    const userLevel = originData?.userLevel;

    const onlineConfig = useOnlineConfig();
    const limitConfig = onlineConfig.dw?.d?.stripe_ach;
    const { maxAmount, maxRollingAmount } = useMemo(() => {
        const limits = limitConfig?.limitGroup?.[Math.min(userLevel || 0, 3)];
        return {
            minAmount: limits?.min || 0,
            maxAmount: limits?.max || 0,
            maxRollingAmount: limits?.frequency_max || 0,
        };
    }, [userLevel, limitConfig]);
    const errorCallback = useCallback(
        (msg?: string) => {
            message.error(msg || $st("order_status_error_appear"));
            onClose();
        },
        [$st, onClose],
    );

    useEffect(() => {
        const subscription = getPublicKey().subscribe({
            next: ({ public_key }) => {
                setPublicKey(public_key);
            },
            error: () => {
                errorCallback();
            },
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [errorCallback]);
    useEffect(() => {
        if (!amount) return;
        const subscription = createAmlTask({
            tx_id: generateUUID(),
            channel: "STRIPE-ACH",
            direction: "deposit",
            coin: "USDT",
            amount,
        }).subscribe({
            next: ({ id }) => {
                setTaskId(id);
            },
            error: () => {
                errorCallback();
            },
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [amount, errorCallback]);

    useEffect(() => {
        if (!taskId) return;
        const subscription = interval(1200)
            .pipe(switchMap(() => getTaskStatus({ task_id: taskId })))
            .subscribe({
                next: ({ result, rejectTags }) => {
                    if (result === "PASSED") {
                        subscription.unsubscribe();
                        setCheckResult(result || "");
                    } else if (result === "REJECTED") {
                        subscription.unsubscribe();
                        let errDesc = "";
                        if (rejectTags?.includes("SINGLE_AMOUNT_EXCEEDED")) {
                            errDesc = $st("transaction_limit", { amount: maxAmount.toLocaleString() });
                        } else if (rejectTags?.includes("ROLLING_QUOTE_AMOUNT_EXCEEDED")) {
                            errDesc = $st("rolling_limit", { amount: maxRollingAmount.toLocaleString() });
                        } else if (rejectTags?.includes("FREQUENCY_LIMIT_EXCEEDED")) {
                            errDesc = $st("frequency_exceeds_limit");
                        }
                        errorCallback(errDesc);
                    }
                },
                error: () => {
                    errorCallback();
                },
            });
        return () => {
            subscription.unsubscribe();
        };
    }, [$st, errorCallback, maxAmount, maxRollingAmount, taskId]);

    useEffect(() => {
        if (checkResult !== "PASSED") return;
        const subscription = createPaymentIntent({
            task_id: taskId,
            amount,
            ...(coin && coin !== "USDT" ? { type: "BUYCRYPTO" } : {}),
        })
            .pipe(
                mergeMap((res) => {
                    if (coin) {
                        return fiatBuyCrypto({
                            coin,
                            deposit_order_id: res.order_id,
                            exchange,
                            key_id,
                        }).pipe(
                            mergeMap(() => {
                                return of(res);
                            }),
                        );
                    }
                    return of(res);
                }),
            )
            .subscribe({
                next: (res) => {
                    setPaymentInfo?.(res);
                },
                error: () => {
                    errorCallback();
                },
            });
        return () => {
            subscription.unsubscribe();
        };
    }, [amount, checkResult, errorCallback, setPaymentInfo, taskId, coin]);
    return (
        <>
            {clientSecret && stripePromise ? (
                <Elements stripe={stripePromise} options={options}>
                    <StripeACHModal onClose={onClose} />
                </Elements>
            ) : (
                <div className=" fixed top-0 left-0 bottom-0 right-0 z-50 bg-black/30">
                    <Spin spinning className="!max-h-screen">
                        <div className=" w-screen h-screen"></div>
                    </Spin>
                </div>
            )}
        </>
    );
}

export default function StripeACH({
    modalVisible,
    onClose,
    amount,
    coinAmount,
    coin,
    isSelect,
}: {
    modalVisible: boolean;
    onClose: () => void;
    amount?: string;
    coin?: string;
    coinAmount?: string;
    isSelect?: string;
}) {
    const [stripeACHEleVisible, setStripeACHEleVisible] = useState<boolean>();
    const { $st, $stn } = useTranslators();
    const ticker = useTicker(coin, "USD");

    const destroyStripe = useCallback(() => {
        setStripeACHEleVisible(false);
    }, []);
    useEffect(() => {
        function handlePopState() {
            window.location.reload();
        }

        window.addEventListener("popstate", handlePopState);
        return () => {
            setTimeout(() => {
                window.removeEventListener("popstate", handlePopState);
            }, 0);
        };
    }, []);
    return (
        <>
            {coin ? (
                <Modal
                    maskClosable={false}
                    keyboard={false}
                    destroyOnClose
                    visible={modalVisible}
                    onCancel={onClose}
                    wSize="small"
                    title={$st("buy_crypto_preview_title", { coin })}
                    onOk={() => {
                        onClose();
                        setStripeACHEleVisible(true);
                    }}
                    okText={$st("spot_balance_small_asset_transfer_confirm")}
                >
                    <div className="flex justify-between px-8px mb-12px text-[15px] leading-[21px] text-text-100 font-m">
                        {$st("buy_crypto_will_get")}
                        <span>{`${NumberUtils.numberWithCommas(coinAmount)} ${coin}`}</span>
                    </div>
                    <Alert icon={<IconWarning20pxLineS size={16} />} type="warning" message={$st("buy_crypto_preview_tips")} />
                    <div className="bg-bg-100 rounded-8px p-8px mt-12px text-text-200">
                        <div className="flex justify-between">
                            {$st("fund_purchasing_button")}
                            <span>${amount}</span>
                        </div>
                        <div className="flex justify-between mt-8px">
                            {$st("price_of_coin", { coin })}
                            <span>${$stn(ticker?.latest || 0)}</span>
                        </div>

                        <div className="flex justify-between mt-8px">
                            {$st("buy_crypto_paymethod")}
                            <span>{$st("mine_band_add_bank_no")}</span>
                        </div>

                        <div className="bg-divider mx-[6px] h-[1px] mt-8px" />

                        <div className="flex justify-between mt-8px font-m text-text-100">
                            {$st("buy_crypto_total")}
                            <span>${amount}</span>
                        </div>
                    </div>
                    <div className="mt-12px text-base text-text-100 whitespace-pre-wrap">{$st("your_payment_method_is_bank_account")}</div>
                </Modal>
            ) : (
                <Modal
                    maskClosable={false}
                    keyboard={false}
                    destroyOnClose
                    visible={modalVisible}
                    onCancel={onClose}
                    wSize="small"
                    title={$st("ach_link_confirm_title")}
                    onOk={() => {
                        onClose();
                        setStripeACHEleVisible(true);
                    }}
                    okText={$st("spot_balance_small_asset_transfer_confirm")}
                >
                    {isSelect === PayMentMethod.balance && (
                        <Alert className="mt-10px" icon={<IconWarning20pxLineS size={16} />} type="warning" message={$st("ach_link_confirm_des1")} />
                    )}
                    {$st("ach_link_confirm_des", { br: <br /> })}
                </Modal>
            )}
            {stripeACHEleVisible && (
                <StripeProvider amount={amount} coin={coin}>
                    <StripeACHEle onClose={destroyStripe} />
                </StripeProvider>
            )}
        </>
    );
}
