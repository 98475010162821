import LocalStorageUtils from "utils/LocalStorageUtils";
import AccountManager from "utils/AccountManager";
import AccountInfo, { TYPE_ACCOUNT_PROFIT_MODEL } from "state/AccountInfo";
import { AccountType } from "bu_account_js_sdk";

const USER_INFO_KEY = "USER_INFO_KEY";

const USER_PROFIT_MODEL = "USER_PROFIT_MODEL";

export const CN_EMAIL_SUFFIX = [
    "@163.com",
    "@sina.com",
    "@sohu.com",
    "@tom.com",
    "@qq.com",
    "@126.com",
    "@yeah.com",
    "@139.com",
    "@189.com",
    "@21cn.com",
    "@188.com",
    "@aliyun.com",
    "@foxmail.com",
    "@letv.com",
];

interface UserProfitModelInfo {
    model: number;
    userId: string;
}

interface InterUserInfo {
    token: string;

    [oth: string]: any;
}

let userInfo: InterUserInfo | null;

export const saveUserInfo = (t: InterUserInfo) => {
    userInfo = t;
    window.localStorage.setItem(USER_INFO_KEY, JSON.stringify(t));
};

export const getUserInfo = () => {
    if (userInfo) return userInfo;
    const _info = window.localStorage.getItem(USER_INFO_KEY) || "{}";
    return JSON.parse(_info);
};

export const getToken = () => {
    return getUserInfo().token;
};

export const removeUserInfo = () => {
    userInfo = null;
    window.localStorage.removeItem(USER_INFO_KEY);
};

export const isLogin = () => {
    const token = getToken();
    return token;
};

export const setUserProfitModel = (value: number) => {
    let accountInfo = LocalStorageUtils.accountInfo;
    if (accountInfo !== undefined) {
        let userProfitModelInfo: UserProfitModelInfo;
        userProfitModelInfo = {
            userId: accountInfo.userId || "",
            model: value,
        };
        window.localStorage.setItem(USER_PROFIT_MODEL + userProfitModelInfo.userId, JSON.stringify(userProfitModelInfo));
        AccountManager.dispatch(TYPE_ACCOUNT_PROFIT_MODEL, { profitModel: value });
    }
};

export const getUserProfitModel = () => {
    let accountInfo = LocalStorageUtils.accountInfo;
    if (accountInfo !== undefined) {
        let userId = accountInfo.userId || "";

        let localUserProfitStr = window.localStorage.getItem(USER_PROFIT_MODEL + userId) || "{}";
        if (localUserProfitStr !== "{}") {
            let localUserProfit = JSON.parse(localUserProfitStr);
            return localUserProfit.model;
        } else {
            return 1;
        }
    } else {
        return 1;
    }
};

const USER_GRID_ORDER_CLOSE_TYPE = "USER_GRID_ORDER_CLOSE_TYPE";

interface UserGridOrderCloseTypeInfo {
    earnCoin: number;
    userId: string;
}

export const setUserGridOrderCloseType = (value: number) => {
    let accountInfo = LocalStorageUtils.accountInfo;
    if (accountInfo !== undefined) {
        let userGridOrderCloseTypeInfo: UserGridOrderCloseTypeInfo = {
            userId: accountInfo.userId || "",
            earnCoin: value,
        };
        window.localStorage.setItem(USER_GRID_ORDER_CLOSE_TYPE + userGridOrderCloseTypeInfo.userId, JSON.stringify(userGridOrderCloseTypeInfo));
    }
};

export const getUserGridOrderCloseType = () => {
    let accountInfo = LocalStorageUtils.accountInfo;
    if (accountInfo !== undefined) {
        let userId = accountInfo.userId || "";

        let localUserGridOrderCloseTypeInfo = window.localStorage.getItem(USER_GRID_ORDER_CLOSE_TYPE + userId) || "{}";
        if (localUserGridOrderCloseTypeInfo !== "{}") {
            const localUserGridOrderCloseType: UserGridOrderCloseTypeInfo = JSON.parse(localUserGridOrderCloseTypeInfo);
            return localUserGridOrderCloseType.earnCoin;
        } else {
            return 1;
        }
    } else {
        return 1;
    }
};

export function is86AccountAndNoEmailBinding(useInfo: AccountInfo) {
    let is86 = false;
    let hasNoEmailBinding = true;
    useInfo.accounts?.map(({ account, account_type }) => {
        if (account_type === AccountType.phoneNumber && account.startsWith("+86")) is86 = true;
        if (account_type === AccountType.email) hasNoEmailBinding = false;
    });
    return is86 && hasNoEmailBinding;
}

/**
 * 是否是绑定了86手机且绑定了邮箱的账号
 */
export function is86AndEmailAccount(accountInfo: AccountInfo): boolean {
    const { accounts } = accountInfo;
    let is86Phone = false;
    let isBindEmail = false;
    accounts?.forEach((item) => {
        if (item.account_type === AccountType.email) {
            isBindEmail = true;
        } else if (item.account_type === AccountType.phoneNumber && item.account.startsWith("+86")) {
            is86Phone = true;
        }
    });

    return is86Phone && isBindEmail;
}
