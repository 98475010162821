import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { ObservableUtils } from "trade_utils_lib";
import Constants from "utils/Constants";
import { v4 as uuidv4 } from "uuid";

const BASE_URL = "/staking/v1";
const account_id = PLATFORM.PIONEX_COL ? "2" : "1";

export interface StakingProduct {
    product_id: string;
    product_name: string;
    product_description: string;
    coin: string;
    lock_days: number;
    apr: string;
    extra_apr: string;
    profit_info: {
        profit_effective_time: number;
        profit_estimate_issue_time: number;
    };
    min_penalty: string;
    promoted_duration: {
        from_ts: number;
        to_ts: number;
    };

    // 以下字段来自 getProductDetail
    active?: boolean;
    estimated_end_time?: number;
    max_amount?: string;
    min_amount?: string;
    product_desc?: string;
    product_quota?: string;
    user_quota?: string;
    profit_effective_time?: number;
    profit_estimate_issue_time?: number;
    // 当前投资额，来自getBalance
    investment_amount?: string;
    // 可领取的收益，来自getUnclaimedProfit
    unclaimed_profit?: string;
}

export interface StakingProductDetail {
    active: boolean;
    apr: string;
    coin: string;
    estimated_end_time: number;
    extra_apr: string;
    lock_days: number;
    max_amount: string;
    min_amount: string;
    min_penalty: string;
    product_desc: string;
    product_id: string;
    product_name: string;
    product_quota: string;
    user_quota: string;
    profit_info: {
        profit_effective_time: number;
        profit_estimate_issue_time: number;
    };
    profit_effective_time: number;
    profit_estimate_issue_time: number;
    promoted_duration: any;
}

export interface StakingBalance {
    product_id: string;
    coin: string;
    amount: string;
}

export interface StakePrams {
    product_id: string;
    coin: string;
    amount: string;
    repeat?: boolean;
}

export interface RedeemPrams {
    product_id: string;
    coin: string;
    amount: string;
    unstake_id: string;
}

export interface ClaimProfitsPrams {
    product_id: string;
    coin: string;
    amount: string;
}

export interface PredictedProfit {
    product_id: string;
    amount: string;
    expected_time: number;
}

export interface PaginationData<T> {
    rows: T[];
    pagination: {
        total_rows: number;
        total_pages: number;
        cur_page: number;
        cur_offset: number;
        page_size: number;
    };
    time_range: {
        from: number;
        to: number;
    };
    order: {
        field: string;
        order: "asc" | "desc";
    };
}

export enum StakingOpType {
    issue_profit = "issue_profit",
    claim_profit = "claim_profit",
}

export interface GetProfitHistoryParam {
    product_id: string;
    op_type?: string;
    page_no?: number;
    page_size?: number;
    from?: number;
    to?: number;
    field?: string; //sort field
    order?: "asc" | "desc";
}

export interface StakingProfitHistory {
    id: number;
    request_id: string;
    txid: string;
    account_id: string;
    wallet_id: string;
    product_id: string;
    op_type: string;
    coin: string;
    amount: string;
    status: "success" | "failed";
    timestamp: number;
    userId: string;
    staking_id: number;
    staking_tx_id: string;
    apr: string;
    extra_apr: string;
}

export interface StakingProfitHistoryData extends PaginationData<StakingProfitHistory> {}

export interface GetActiveOrderParam {
    product_id: string;
    page_no?: number;
    page_size?: number;
    from?: number;
    to?: number;
    field?: string; //sort field
    order?: "asc" | "desc";
}

export interface ActiveOrder {
    txid: string;
    account_id: string;
    wallet_id: string;
    product_id: string;
    coin: string;
    amount: string;
    op_type: StakingOpType;
    repeat: boolean;
    on_chain: boolean;
    status: "success" | "failed";
    timestamp: number;
    realized_timestamp: number;
    user_id: string;
    apr: string;
    days: number;
    unstake_txid: string;
    parent_txid: string;
    maturity: string | null;
    fail_reason: string;
    extra_apr: string;
    profit_effective_duration: number;
    profit_issue_duration: number;
    recur_policy: number;
    profit_info: {
        profit_effective_time: number;
        profit_estimate_issue_time: number;
    };
    accumulate_profit: {
        product_id: string;
        coin: string;
        amount: string;
    };
    client_info: {};
}

export interface ActiveOrderData extends PaginationData<ActiveOrder> {}

export interface OrderInfo {
    amount: string;
    start_profit_time: string;
    coin: string;
    end_time: string;
    profit: string;
    penalty: string;
    recur_policy: number;
}

export interface GetStakingHistoryParam {
    product_id: string;
    op_type?: string;
    page_no?: number;
    page_size?: number;
    from?: number;
    to?: number;
    order?: "asc" | "desc";
}

export interface StakingHistory {
    txid: string;
    account_id: string;
    product_id: string;
    op_type: string;
    coin: string;
    amount: string;
    status: "success" | "failed";
    timestamp: number;
    user_id: string;
}

export interface StakingHistoryData extends PaginationData<StakingHistory> {}

export interface GetProfitSumParam {
    product_id: string;
    coin: string;
    from?: string;
    to?: string;
}

export interface StakingProfitSum {
    product_id: string;
    coin: string;
    amount: string;
}

export interface StakingUserQuotaResult {
    products: StakingUserQuotaInfo[];
}

export interface StakingUserQuotaInfo {
    product_id: string;
    product_quota_available: string;
    user_quota_available: string;
}

export namespace StakingService {
    export function getProducts() {
        return ObservableUtils.getV2<StakingProduct[]>(`${BASE_URL}/products`);
    }

    export function getProductDetail(product_id: string) {
        return ObservableUtils.getV2<StakingProductDetail>(`${BASE_URL}/product`, { product_id }, null, true);
    }

    export function getBalance(product_id?: string) {
        return ObservableUtils.getV2<StakingBalance[]>(
            `${BASE_URL}/balance`,
            {
                account_id,
                product_id,
            },
            null,
            true,
        );
    }

    export function getUnclaimedProfit(coin: string, product_id: string) {
        return ObservableUtils.getV2<StakingBalance>(
            `${BASE_URL}/profit_unclaimed`,
            {
                account_id,
                product_id,
                coin,
            },
            null,
            true,
        );
    }

    export function getFullProduct(publicProduct: StakingProduct) {
        // 这些数据是否可以在同一个接口提供？
        return forkJoin([getProductDetail(publicProduct.product_id), getBalance(publicProduct.product_id), getUnclaimedProfit(publicProduct.coin, publicProduct.product_id)]).pipe(
            map(([productDetail, balances, unClaimedProfit]) => {
                let investment_amount = "0";
                const balanceInfo = balances.find((item) => item.product_id === publicProduct.product_id);
                if (balanceInfo) {
                    investment_amount = balanceInfo.amount;
                }
                const unclaimed_profit = unClaimedProfit.amount;
                const product: StakingProduct = { ...publicProduct, ...productDetail, investment_amount, unclaimed_profit };
                return product;
            }),
        );
    }

    export function stake(params: StakePrams) {
        return ObservableUtils.postV2(
            `${BASE_URL}/stake`,
            {
                account_id,
                unique_id: uuidv4(),
                ...params,
            },
            null,
            true,
        );
    }

    export function redeem(params: RedeemPrams) {
        return ObservableUtils.postV2(
            `${BASE_URL}/unstake`,
            {
                account_id,
                unique_id: uuidv4(),
                ...params,
            },
            null,
            true,
        );
    }

    export function claimProfits(params: ClaimProfitsPrams) {
        return ObservableUtils.postV2(
            `${BASE_URL}/claim_profits`,
            {
                account_id,
                unique_id: uuidv4(),
                ...params,
            },
            null,
            true,
        );
    }

    export function getStakingPredictedProfit(productId: string) {
        return ObservableUtils.getV2<PredictedProfit>(
            `${BASE_URL}/predicted_profit`,
            {
                product_id: productId,
            },
            null,
            true,
        );
    }

    export function getProfitHistoryData(params: GetProfitHistoryParam) {
        return ObservableUtils.getV2<StakingProfitHistoryData>(
            `${BASE_URL}/profit_history`,
            {
                account_id,
                ...params,
            },
            null,
            true,
        );
    }

    export function getStakingHistory(params: GetStakingHistoryParam) {
        return ObservableUtils.getV2<StakingHistoryData>(
            `${BASE_URL}/transactions`,
            {
                account_id,
                ...params,
            },
            null,
            true,
        );
    }

    export function getStakingProfitSum(params: GetProfitSumParam) {
        return ObservableUtils.getV2<StakingProfitSum>(
            `${BASE_URL}/profit_sum`,
            {
                account_id,
                op_type: "issue_profit",
                ...params,
            },
            null,
            true,
        );
    }

    export function getActiveOrderData(params: GetActiveOrderParam) {
        return ObservableUtils.getV2<ActiveOrderData>(
            `${BASE_URL}/active_staking`,
            {
                account_id,
                ...params,
            },
            null,
            true,
        );
    }

    export function getStakeOrderInfo(txid: string) {
        return ObservableUtils.getV2<OrderInfo>(`${BASE_URL}/stake/${txid}`, null, null, true);
    }

    export function updateStake(txid: string, repeat: boolean) {
        return ObservableUtils.postV2(
            `${BASE_URL}/stake/` + txid,
            {
                repeat: repeat,
            },
            null,
            true,
        );
    }

    export function getUserQuota() {
        return ObservableUtils.getV2<StakingUserQuotaResult>(
            `${BASE_URL}/rules/user_quota`,
            {
                account_id,
            },
            null,
            true,
        );
    }
}
