import { Button, Col, Row } from "antd";
import { useTranslators } from "commonUse/Locale";
import useAccountInfo from "commonUse/useAccountInfo";
import TReportLink, { Iconfont } from "components/v2/TinyComps";
import React, { Fragment, useMemo } from "react";
import { getNavMenuConfig } from "../Header.v2/constants";
import { signUpLinkProps } from "../Header.v2/utils";
import cs from "./cs.m.less";
import LocalStorageUtils from "utils/LocalStorageUtils";
import { SideTabKey } from "landings/Trade/SideTab";
import { AccountsRoute } from "utils/AccountsRoute";
import { FromName } from "landings/Analytics/EventName";
import { IConNames } from "less/iconfont/iconfont";

export default function FooterV2() {
    const { userId } = useAccountInfo();
    const {
        $st,
        intl: { locale },
    } = useTranslators();
    return (
        <footer className={cs.footer}>
            <div className={cs.container}>
                <Row>
                    <Col span={12} md={8}>
                        <h1 className={cs.leftTitle}>
                            Start <br /> trading
                        </h1>
                        {userId ? (
                            <Button className={cs.fBtn} href={LocalStorageUtils.generateFullTradePath(undefined, SideTabKey.BOT)}>
                                Create a bot
                            </Button>
                        ) : (
                            <Button className={cs.fBtn} href={AccountsRoute.signUp({ from: FromName.main_footer })} {...signUpLinkProps}>
                                {$st("sign_up_button")}
                            </Button>
                        )}
                    </Col>
                    <Col span={24} md={16}>
                        <Row>
                            <Col span={24} md={8}>
                                {useMemo(
                                    () =>
                                        getNavMenuConfig(locale).map(({ title, menus }) => {
                                            if (title === "header_menu_community")
                                                return (
                                                    <Fragment key={title}>
                                                        <h3 className={cs.fNavTitle}>{$st(title)}</h3>
                                                        <ul className={cs.fNavLis}>
                                                            {menus.map(({ icon, label, link, extra, disabled }) =>
                                                                disabled ? null : (
                                                                    <li key={label}>
                                                                        <TReportLink className={cs.footNav} to={link} target="_blank">
                                                                            <Iconfont icon={icon as IConNames} /> {$st(label)}
                                                                        </TReportLink>
                                                                    </li>
                                                                ),
                                                            )}
                                                        </ul>
                                                    </Fragment>
                                                );
                                            return null;
                                        }),
                                    [$st, locale],
                                )}
                            </Col>
                            <Col span={24} md={8}>
                                <h3 className={cs.fNavTitle}>Legal</h3>
                                <ul className={cs.fNavLis}>
                                    <li>
                                        <TReportLink to="https://www.pionex.us/blog/pionex-us-user-agreement/" target="_blank">
                                            <Iconfont icon="icon_nav_About_TermsAndConditions" /> User Agreement
                                        </TReportLink>
                                    </li>
                                    <li>
                                        <TReportLink to="https://www.pionex.us/blog/pionex-us-privacy-policy/" target="_blank">
                                            <Iconfont icon="icon_nav_About_privacy" /> Privacy Policy
                                        </TReportLink>
                                    </li>
                                    <li>
                                        <TReportLink to="https://www.pionex.us/blog/important-information-about-use-of-the-pionex-us-platform-and-risk-disclaimer/" target="_blank">
                                            <Iconfont icon="icon_nav_About_statement" /> Risk Disclaimer
                                        </TReportLink>
                                    </li>
                                    <li>
                                        <TReportLink to="https://www.pionex.us/blog/pionex-us-user-conduct-code/" target="_blank">
                                            <Iconfont icon="icon_nav_About_UserAgreement" /> User Conduct Code
                                        </TReportLink>
                                    </li>
                                    <li>
                                        <TReportLink to="https://www.pionex.us/blog/license-information/" target="_blank">
                                            <Iconfont icon="icon_Disclosure" /> Licenses
                                        </TReportLink>
                                    </li>
                                </ul>
                            </Col>
                            <Col span={24} md={8}>
                                <h3 className={cs.fNavTitle}>About</h3>
                                <ul className={cs.fNavLis}>
                                    <li>
                                        <TReportLink to="/market" target="_blank">
                                            <Iconfont icon="icon_nav_About_list" /> Coinlist
                                        </TReportLink>
                                    </li>
                                    <li>
                                        <TReportLink to="/fees" target="_blank">
                                            <Iconfont icon="icon_nav_About_Fee" /> Fees
                                        </TReportLink>
                                    </li>
                                    <li>
                                        <TReportLink to="https://www.pionex.us/blog" target="_blank">
                                            <Iconfont icon="icon_Academy" /> Academy
                                        </TReportLink>
                                    </li>
                                    <li>
                                        <TReportLink to="https://pionexus.zendesk.com/hc/en-us/categories/4430092749081-Announcements" target="_blank">
                                            <Iconfont icon="icon_nav_surport_Notifications" />
                                            Announcement
                                        </TReportLink>
                                    </li>
                                    <li>
                                        <TReportLink to="mailto:service@pionex.us" target="_blank">
                                            <Iconfont icon="icon_Mail" /> Email
                                        </TReportLink>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                        <div className={cs.addr}>500 College Rd East, Suite 240, Princeton, NJ 08540</div>
                        <div className={cs.copy}>All rights reserved © 2022 Pionex</div>
                    </Col>
                </Row>
            </div>
        </footer>
    );
}
