/*
 * @Descripttion:
 * @version:
 * @Author: Victor
 * @Date: 2020-07-29 15:14:12
 * @LastEditors: wujinlong wujinlong@bituniverse.org
 * @LastEditTime: 2024-08-14 19:26:21
 */
import { PionexReport } from "src/landings/Analytics/Report";
import { OrderUtils } from "src/utils/OrderUtils";
import { getUserGridOrderCloseType } from "src/utils/userInfo";
import { APIKeyInfo } from "trade_utils_lib";
import { ExchangeOrder, ExchangeOrderType, TradeUtils } from "TradeAPILib";

function closeOrderMiddleware(apiKey: APIKeyInfo, order: ExchangeOrder, isToQuote?: boolean) {
    let info: any = {};
    if (
        order.orderType === ExchangeOrderType.gridClassic ||
        order.orderType === ExchangeOrderType.gridPro ||
        order.orderType === ExchangeOrderType.gridV3 ||
        order.orderType === ExchangeOrderType.gridLoan ||
        order.orderType === ExchangeOrderType.gridShort ||
        order.orderType === ExchangeOrderType.gridLeverage ||
        order.orderType === ExchangeOrderType.gridLeverageShort ||
        order.orderType === ExchangeOrderType.gridV4
    ) {
        const isConditionAndNotTriggered = !!TradeUtils.getInnerOrder(order)!.isConditionAndNotTriggered;
        info = {
            convert_into_earn_coin: isConditionAndNotTriggered ? false : !!getUserGridOrderCloseType(),
        };
    }

    if (
        order.orderType === ExchangeOrderType.ig ||
        order.orderType === ExchangeOrderType.SR ||
        order.orderType === ExchangeOrderType.martingale ||
        order.orderType === ExchangeOrderType.SC
    ) {
        info = {
            convert_into_earn_coin: !!getUserGridOrderCloseType(),
        };
    }
    PionexReport.reportBotCardOperate(order.orderType, order.state, "cancel");
    // 强制不卖币
    if (isToQuote === false || isToQuote === true) {
        info.convert_into_earn_coin = isToQuote;
    }
    return OrderUtils.cancelOrder(apiKey, order, info).toPromise();
}

export default closeOrderMiddleware;
