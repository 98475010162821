import React, { useState } from "react";
import { IDWCoinBalance, useDWCoins } from "commonUse/useDW";
import { useCallback, useMemo } from "react";
import { $st } from "src/utils/i18n";
import BalanceCfg from "state/BalanceCfg";
import { CoinBalance } from "trade_asset_lib";
import cs from "../../balance.module.less";
import { ListTable } from "../ListTable";
import useAccountInfo from "commonUse/useAccountInfo";
import { SafeDecimal } from "trade_utils_lib";
import { history } from "utils/History";
import IconAssetSwap from "components/svg/IconAssetSwap";
import { TSearchInput, TCheckbox } from "components/v2/TinyComps";
import useTheme from "src/commonUse/useTheme";
import { useBalanceAll } from "commonUse/useBalance";
import useKycInfoV2 from "commonUse/useKycInfoV2";
import { SMALL_SPOT_CONDITION } from "landings/Balance/components/SpotBalanceTransfer";
import { useStakingProducts } from "landings/V2/staking/StakingPage/hooks";
import { StakingProduct } from "landings/V2/staking/StakingService";
import Constants from "utils/Constants";
import { useTicker } from "@pionex-web-kit/common-business";
import useExchangeRate from "commonUse/useExchangeRate";
import { TradeUtils } from "TradeAPILib";

export interface IBalanceProps {
    balanceCfg: BalanceCfg;
    onLoading: boolean;
    className?: string;
    dwCoinList?: IDWCoinBalance[];
}

export const listFilter = (loading: boolean, coinList: CoinBalance[], searchInput: string, hideSmall?: boolean) => {
    if (loading || !Array.isArray(coinList)) {
        return [];
    }
    let _filteredList = coinList;
    // 搜索
    if (searchInput) {
        if (searchInput === "()") {
            return [];
        }
        try {
            _filteredList = _filteredList.filter((list) => new RegExp(searchInput, "i").test(list.baseDisplay));
        } catch (e) {
            _filteredList = [];
            console.error(e);
        }
    }
    // 隐藏小额币种
    if (hideSmall) {
        _filteredList = _filteredList.filter((list) => {
            // 屏蔽BUBU
            if (PLATFORM.PIONEX_US_LIKE && list.base === "BUB") return false;
            if (list.base === "BUB" && new SafeDecimal(list.available).greaterThan(0)) {
                return true;
            }
            return new SafeDecimal(list.USD).abs().greaterThanOrEqualTo(SMALL_SPOT_CONDITION);
        });
    } else {
        // 不显示为0的资产
        _filteredList = _filteredList.filter((list) => {
            // 屏蔽BUBU
            if (PLATFORM.PIONEX_US_LIKE && list.base === "BUB") return false;
            return new SafeDecimal(list.USD).abs().greaterThan(0);
        });
    }
    // 统一修改
    return (_filteredList || []).map((item) => ({ ...item, baseDisplay: TradeUtils.parseBaseDisplay(item.baseDisplay) }));
};
export function Balance(props: IBalanceProps) {
    const accountInfo = useAccountInfo();
    const [kycInfo, kycLoading] = useKycInfoV2();
    const [coin, setCoin] = useState("ETH");
    const theme = useTheme();
    const { firstApiKey } = accountInfo;
    const { onLoading, balanceCfg, className = "" } = props;
    const balance = useBalanceAll();
    const [dwCoins] = useDWCoins();
    const [searchInput, updateSearchInput] = React.useState("");
    const { products: allProducts, refresh: refreshProducts } = useStakingProducts();
    const [products, count]: [StakingProduct[], number] = useMemo(() => {
        if (!Constants.isSiteV2) return [[], 0];
        if (!allProducts) {
            return [[], 0];
        }
        const data: StakingProduct | undefined = allProducts?.find((item) => item.coin === coin);
        if (!data) {
            return [[], 0];
        }
        let all = allProducts.filter((item) => item.coin === coin).filter((v) => new SafeDecimal(v?.investment_amount || 0).greaterThan(0));
        return [all, all.reduce((a, b) => a + Number(b?.investment_amount), 0)];
    }, [allProducts, coin]);

    const ticker = useTicker(coin, "USD");

    // 更新列表数据
    const filterCoinList = useMemo(() => {
        const ethlist = {
            quantity: count,
            frozen: count,
            USD: count * (ticker?.latest || 0),
            fullName: "Ethereum",
            currency: "USD",
            baseDisplay: coin,
            base: coin,
            available: 0,
            BTC: 0,
            USDPrice: ticker?.latest || 0,
            BTCPrice: 0,
            USDPriceChange: 0,
        };
        let flag = false;
        const datalist =
            balance?.trade?.balances.map((v) => {
                let isETH = v.base === coin;
                if (isETH) {
                    flag = true;
                }
                return {
                    ...v,
                    quantity: isETH ? v.quantity + count : v.quantity,
                    USD: isETH ? v.USD + count * (ticker?.latest || 0) : v.USD,
                    frozen: isETH ? v.frozen + count : v.frozen,
                };
            }) || [];
        if (!flag) {
            datalist.push(ethlist);
        }

        const balances = listFilter(onLoading, datalist, searchInput, balanceCfg.hideSmall);
        let result: IDWCoinBalance[] = [];
        if (dwCoins !== undefined) {
            balances.forEach((coinBalance) => {
                result.push({
                    ...coinBalance,
                    canWithdraw: dwCoins.some((item) => item.coinName === coinBalance.base && item.supportWithdraw),
                    canDeposit: dwCoins.some((item) => item.coinName === coinBalance.base && item.supportDeposit),
                });
            });
            return result;
        } else {
            return balances;
        }
    }, [balance?.trade?.balances, onLoading, searchInput, balanceCfg.hideSmall, dwCoins, coin, count, ticker?.latest]);

    const filterDebtList = useMemo(() => {
        return listFilter(onLoading, balance?.debt?.balances || [], searchInput, balanceCfg.hideSmall);
    }, [onLoading, balance?.debt?.balances, searchInput, balanceCfg.hideSmall]);

    const searchOnChange = useCallback((e) => updateSearchInput(e.target.value), []);
    const onHideSmall = useCallback((e) => {
        BalanceCfg.availableActions.refreshHideSmallStatus(e.target.checked);
    }, []);
    if (!firstApiKey) return null;
    return (
        <div className={className}>
            <div className={cs.balanceWrapper}>
                <div className={cs.headerFilter}>
                    <div className={cs.headerFilterTitle}>{$st("balance_portfolio")}</div>
                    <div className={cs.headerFilterRight}>
                        <TCheckbox checked={balanceCfg.hideSmall} onChange={onHideSmall} className={cs.hideSmall}>
                            {$st("balance.hide_small_balance")}
                        </TCheckbox>
                        {kycLoading || kycInfo.exchange === "paxos" || (
                            <div
                                className={cs.smallAssetTransfer}
                                onClick={() => {
                                    history.push("/spot_swap");
                                }}
                            >
                                <div className={cs.smallAssetSvg}>
                                    <IconAssetSwap color={theme.name === "normal" ? "#292c33" : "#f4f6fa"} />
                                </div>
                                <span
                                    style={{
                                        marginLeft: 8,
                                    }}
                                >
                                    {$st("spot_balance_small_asset_transfer")}
                                </span>
                            </div>
                        )}
                        <TSearchInput value={searchInput} onChange={searchOnChange} placeholder={$st("balance_search_coin_hint")} />
                    </div>
                </div>
                <ListTable
                    autoWidth
                    exchange={firstApiKey.exchange}
                    stakingProduct={products}
                    coinListData={filterCoinList}
                    onLoading={onLoading}
                    source={"trade"}
                    isSearchState={searchInput !== ""}
                />
            </div>

            {filterDebtList !== undefined && filterDebtList.length > 0 && (
                <div className={cs.balanceWrapper}>
                    <div className={cs.headerFilter}>
                        <div className={cs.headerFilterTitle}>
                            {`${$st("borrowed_assets")}`}
                            <div className={cs.borrowHeaderContent}>{`${$st("borrowed_assets_content")}`}</div>
                        </div>
                    </div>
                    <ListTable
                        exchange={firstApiKey.exchange}
                        coinListData={filterDebtList}
                        onLoading={false}
                        source={"debt"}
                        isSearchState={searchInput !== ""}
                        hideOperator
                        hideFrozenAndFree
                    />
                </div>
            )}
        </div>
    );
}
