import React, { useCallback } from "react";
import csn from "classnames";
import css from "./index.m.less";
import { openUrlInNewTab } from "utils/commons";
import { STREAMER_TYPE, useWebConfig } from "utils/webConfig";
import { RouteUtils } from "utils/RouteUtils";
import { FromName } from "landings/Analytics/EventName";
import { history } from "utils/History";
import { useTranslators } from "commonUse/Locale";
import AccountManager from "utils/AccountManager";
import { report } from "utils/GoogleTagManager";
export const TrialFundBanner = () => {
    const { home: _home } = useWebConfig();

    const home = _home?.streamer?.[PLATFORM._CODE] as STREAMER_TYPE;

    const handleCallback = useCallback(() => {
        report("home_banner_trade");
        if (home?.link) {
            openUrlInNewTab(RouteUtils.appendParams(home.link, { from: FromName.home_streamer }));
        }
    }, [home?.link]);
    if (!home?.enabled) return null;

    return (
        <div className={csn(css.container)}>
            <span>{home?.title || ""}</span>
            {home?.btn && (
                <div onClick={handleCallback} className={css.button}>
                    {home?.btn}
                </div>
            )}
        </div>
    );
};

export const KYCBanner = () => {
    const handleCallback = useCallback(() => {
        report("home_banner_kyc");
        history.push("/account/kyc");
    }, []);

    return (
        <div className={csn(css.container)}>
            <span>Complete the identity verification and make a deposit to win 200 USDT.</span>
            <div onClick={handleCallback} className={css.button}>
                Identify Verification
            </div>
        </div>
    );
};

export const TradeBanner = () => {
    const handleCallback = useCallback(() => {
        report("home_banner_trade");
        window.open("https://pionexus.zendesk.com/hc/en-us/articles/36606000872473-August-Trading-Campaign-2-000-USDT-Prize-Pool-Waiting-for-You-to-Claim");
    }, []);
    const { $st } = useTranslators();

    return (
        <div className={csn(css.container)}>
            <span>Trading Campaign: 2,000 USDT Prize Pool Waiting for You to Claim!</span>
            <div onClick={handleCallback} className={css.button}>
                {$st("trade_now")}
            </div>
        </div>
    );
};

export const DepositGuideBanner = () => {
    const handleCallback = useCallback(() => {
        report("home_banner_deposit");
        history.push("/newbie-rewards");
    }, []);
    const { $st } = useTranslators();

    return (
        <div className={csn(css.container)}>
            <span>New Users Exclusive: Deposit and get 200 USDT.</span>
            <div onClick={handleCallback} className={css.button}>
                {$st("balance_coin_no_data_button_title")}
            </div>
        </div>
    );
};

export const NotLoggedInBanner = () => {
    const { $st } = useTranslators();
    const handleCallback = useCallback(() => {
        report("home_banner_register");
        AccountManager.toSignUp();
    }, []);

    return (
        <div className={csn(css.container)}>
            <span>{$st("new_users_exclusive")}</span>
            <div onClick={handleCallback} className={css.button}>
                {$st("register_now")}
            </div>
        </div>
    );
};
