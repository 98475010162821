import { Props } from "components/svg/types";
import React from "react";

export default function IconTriangle(props: Props) {
    const { color, size = 14, rotate = 0, style } = props;
    return (
        <svg width={size} height={size} viewBox="0 0 14 14" style={style}>
            <polygon transform={`rotate(${rotate},7,7)`} points="7 2 14 12 0 12" fill={color} />
        </svg>
    );
}
