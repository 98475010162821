import React from "react";
import { Progress } from "@pionex-web-kit/components";
import { ProgressProps } from "@pionex-web-kit/components/lib/Progress/Progress";

/**
 * 3DS 等待中的进度条
 */
const ThreeDSProgress: React.FC<ProgressProps> = (props) => {
    const { percent = 0 } = props;
    return (
        <div className="relative">
            <Progress showInfo={false} {...props} />
            <div className={`rounded-14px w-14px h-14px absolute left-0 top-[6px] ${percent > 0 ? "bg-primary" : "bg-divider"}`} />
            <div className={`rounded-14px w-14px h-14px absolute translate-x-[-50%] left-[50%] top-[6px] ${percent >= 50 ? "bg-primary" : "bg-divider"}`} />
            <div className={`rounded-14px w-14px h-14px absolute right-0 top-[6px] ${percent >= 100 ? "bg-primary" : "bg-divider"}`} />
        </div>
    );
};

export default ThreeDSProgress;
