import { DWType, useDWPageContext } from "landings/DepositWithdraw";
import { CustomAddressView } from "landings/DepositWithdraw/components/CoinSelect/components/DepositAddress/CustomAddrssView";
import { BalanceTipView } from "landings/DepositWithdraw/components/Tips";
import React, { Fragment } from "react";
import { AddressInfo } from "TradeAPILib";

export interface IDepositAddressProps {
    errorMsg: string;
    coinAddressInfo: AddressInfo;
}

export const DepositAddress = (props: IDepositAddressProps) => {
    const { coinAddressInfo, errorMsg } = props;
    const { currentCoin } = useDWPageContext();
    return (
        <Fragment>
            <CustomAddressView errorMsg={errorMsg} coinAddressInfo={coinAddressInfo} />
            {coinAddressInfo.address && (
                <div style={{ marginTop: 10 }}>
                    <BalanceTipView currentCoin={currentCoin} dwType={DWType.deposit} />
                </div>
            )}
        </Fragment>
    );
};
