import * as React from "react";
import NumberUtils from "utils/NumberUtils";

export interface IPriceHolderProps {
    price: undefined | null | number | string;
    decimal?: number;
    holder?: string | (() => string);
}

export function PriceHolder({ price, decimal = 8, holder = `0.${Array.from({ length: decimal }).fill("0").join("")}` }: IPriceHolderProps) {
    return <span>{price ? NumberUtils.effectiveDecimal(price, 8) : holder}</span>;
}
