import { useEffect, useState } from "react";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import Constants from "utils/Constants";
import { useAsync } from "react-use";
import SparkMD5 from "spark-md5";
import { JSEncrypt } from "jsencrypt";
import { usePubkey } from "account/hook";

export function rsaEncrypt(key: string, encryptContent: string) {
    //RSA encrypt
    let encrypt = new JSEncrypt();
    encrypt.setPublicKey(key);

    /**501**/
    let encrypted = "";
    if (encryptContent.length > 501) {
        encrypted = encrypt.encrypt(encryptContent.substr(0, 501)) as string;
        encryptContent = encryptContent.substr(501, encryptContent.length - 501);
        while (encryptContent.length > 0) {
            encrypted += ("|||" + encrypt.encrypt(encryptContent.substr(0, 501))) as string;
            encryptContent = encryptContent.substr(501, encryptContent.length - 501);
        }
    } else {
        encrypted = encrypt.encrypt(encryptContent) as string;
    }

    return encrypted;
}
/**
 * @description: 获取操作系统信息
 * @return {*}
 */
export function getOsInfo() {
    var userAgent = navigator.userAgent.toLowerCase();
    var name = "";
    var version = "";
    if (userAgent.indexOf("win") > -1) {
        name = "Windows";
        if (userAgent.indexOf("windows nt 5.0") > -1) {
            version = "Windows 2000";
        } else if (userAgent.indexOf("windows nt 5.1") > -1 || userAgent.indexOf("windows nt 5.2") > -1) {
            version = "Windows XP";
        } else if (userAgent.indexOf("windows nt 6.0") > -1) {
            version = "Windows Vista";
        } else if (userAgent.indexOf("windows nt 6.1") > -1 || userAgent.indexOf("windows 7") > -1) {
            version = "Windows 7";
        } else if (userAgent.indexOf("windows nt 6.2") > -1 || userAgent.indexOf("windows 8") > -1) {
            version = "Windows 8";
        } else if (userAgent.indexOf("windows nt 6.3") > -1) {
            version = "Windows 8.1";
        } else if (userAgent.indexOf("windows nt 6.2") > -1 || userAgent.indexOf("windows nt 10.0") > -1) {
            version = "Windows 10";
        } else {
            version = "Unknown";
        }
    } else if (userAgent.indexOf("iphone") > -1) {
        name = "iPhone";
    } else if (userAgent.indexOf("mac") > -1) {
        name = "Mac";
    } else if (userAgent.indexOf("x11") > -1 || userAgent.indexOf("unix") > -1 || userAgent.indexOf("sunname") > -1 || userAgent.indexOf("bsd") > -1) {
        name = "Unix";
    } else if (userAgent.indexOf("linux") > -1) {
        if (userAgent.indexOf("android") > -1) {
            name = "Android";
        } else {
            name = "Linux";
        }
    } else {
        name = "";
    }
    return `${name}${version ? `(${version})` : ""}`;
}

/**
 * @description: 获取浏览器类型
 * @return {*}
 */
export function getUserAgentInfo() {
    var userAgent = navigator.userAgent;
    var version;
    if (/opera/i.test(userAgent) || /OPR/i.test(userAgent)) {
        version = getVersion(userAgent, "OPR/(\\d+\\.+\\d+)");
        return `Opera V${version} `;
    } else if (/compatible/i.test(userAgent) && /MSIE/i.test(userAgent)) {
        version = getVersion(userAgent, "MSIE (\\d+\\.+\\d+)");
        return `IE V${version} `;
    } else if (/Edge/i.test(userAgent)) {
        version = getVersion(userAgent, "Edge/(\\d+\\.+\\d+)");
        return `Edge V${version} `;
    } else if (/Firefox/i.test(userAgent)) {
        version = getVersion(userAgent, "Firefox/(\\d+\\.+\\d+)");
        return `Firefox V${version} `;
    } else if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent)) {
        version = getVersion(userAgent, "Safari/(\\d+\\.+\\d+)");
        return `Safari V${version} `;
    } else if (/Chrome/i.test(userAgent) && /Safari/i.test(userAgent)) {
        version = getVersion(userAgent, "Chrome/(\\d+\\.+\\d+)");
        return `Chrome V${version} `;
    } else if ("ActiveXObject" in window) {
        version = 11;
        return `IE V${version} `;
    }
}
/**
 * @description: 获取浏览器版本
 * @param {*} userAgent
 * @param {*} reg
 * @return {*}
 */
function getVersion(userAgent, reg) {
    var reBrowser = new RegExp(reg);
    reBrowser.test(userAgent);
    return parseFloat(RegExp["$1"]);
}

/**
 * @description: 设备信息参数（加密）
 * @return {*}
 */
export function useDeviceInfo() {
    const [deviceInfo, setDeviceInfo] = useState<any>();
    const { value: fpData } = useAsync(() => FingerprintJS.load().then((res) => res.get()));
    const pubkey = usePubkey();
    useEffect(() => {
        if (!fpData || !pubkey) return;
        const { audio, screenResolution, languages, vendor, canvas, plugins } = fpData.components;
        const deviceContent = {
            device_id: Constants.deviceId,
            device_name: `${getUserAgentInfo()}(${getOsInfo()})`,
            screen_resolution: screenResolution.value.join(),
            available_screen_resolution: `${document.body.clientWidth},${document.body.clientHeight} `,
            system_lang: languages.value[0][0],
            timezone: `GMT${new Date().getTimezoneOffset() / -60} `,
            timezoneOffset: new Date().getTimezoneOffset(),
            user_agent: navigator.userAgent,
            list_plugin: SparkMD5.hash(JSON.stringify(plugins.value.map(({ name }) => name).join())),
            canvas_code: SparkMD5.hash(JSON.stringify(canvas)),
            webgl_vendor: vendor?.value,
            audio: audio.value,
            platform: navigator.platform,
            web_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        setDeviceInfo(rsaEncrypt(pubkey.key_val, JSON.stringify(deviceContent)));
    }, [fpData, pubkey]);
    return deviceInfo;
}
