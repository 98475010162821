import React from "react";
import { useRxRequest } from "commonUse/useRequest";
import { TSpin, TTable } from "components/v2/TinyComps";
import { useColumns } from "landings/V2/SignatureDW/History/Withdraw";
import { NetworkUtils } from "trade_utils_lib";
import Constants from "utils/Constants";
import { map } from "rxjs/operators";
import { camelize } from "utils/camelize";
import { Table } from "@pionex-web-kit/components";

interface Props {}

/**
 * ACH出金历史记录，实际是FBO数据，transfer_type区分
 */
const WithdrawHistory: React.FC<Props> = ({}) => {
    const { loading, data } = useRxRequest(() =>
        NetworkUtils.getWithTradeAuth({
            url: `${Constants.walletApiHost}/fiatfacade/withdrawals/`,
            data: {
                transfer_type: "ach",
                channel: "fbo",
            },
        }).pipe(map((res) => camelize(res?.records ?? []))),
    );

    const columns = useColumns();

    return (
        <TSpin spinning={loading}>
            <Table scroll={{ x: "100%" }} dataSource={data} columns={columns} pagination={false} />
        </TSpin>
    );
};

export default WithdrawHistory;
