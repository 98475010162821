import axios, { AxiosRequestConfig } from "axios";
import { from, Observable, of } from "rxjs";
import { delay, flatMap, map, retryWhen, switchMap } from "rxjs/operators";
import { ObservableUtils } from "trade_utils_lib";
import AccountManager from "utils/AccountManager";
import Constants from "./Constants";
// import Constants from "./Constants";

const defaultConfig = {
    validateStatus: function (status) {
        return true;
    },
};

export function requestWithRetry(request): Observable<any> {
    let retryCount = 0;
    return request.pipe(
        retryWhen((notifier) => {
            return notifier.pipe(
                switchMap((error) => {
                    console.error(error);
                    retryCount++;
                    if (retryCount < 10) {
                        return of(0).pipe(delay(retryCount * 1000));
                    }
                    return of(0).pipe(delay(10000));
                }),
            );
        }),
    );
}
// const axios = Axios.create();

// axios.interceptors.request.use(({ $mixCommonParams, ...config }: AxiosRequestConfig): AxiosRequestConfig => {
//     // 确保之前接收 undefined 值得方式被兼容
//     const params = $mixCommonParams
//         ? Object.entries({
//               ...Constants.commonRequestParams,
//               ...config.params,
//           }).reduce((p, [k, v]) => {
//               p[k] = `${v}`;
//               return p;
//           }, {})
//         : config.params;
//     return {
//         ...config,
//         params,
//     };
// });

/**
 * @deprecated Use {@link ObservableUtils} instead.
 */
export default class Api {
    static get(url: string, config: AxiosRequestConfig = {}, needAuthorize: boolean = false, needBearer: boolean = false) {
        let requestConfig = {} as AxiosRequestConfig;
        if (config) {
            requestConfig = Object.assign({}, config, defaultConfig);
        }
        if (!requestConfig.headers) {
            requestConfig.headers = {};
        }
        if (!requestConfig.headers["Content-Type"]) {
            requestConfig.headers["Content-Type"] = "application/json";
        }
        requestConfig.params = { ...Constants.commonRequestParams, ...(requestConfig.params || {}) };

        let observable: Observable<any>;
        if (needAuthorize) {
            observable = AccountManager.shared.getAccessToken().pipe(
                flatMap((token) => {
                    if (needBearer) {
                        requestConfig.headers.Authorization = `Bearer ${token}`;
                    } else {
                        requestConfig.headers.Authorization = token;
                    }
                    return from(axios.get(url, requestConfig));
                }),
            );
        } else {
            observable = from(axios.get(url, requestConfig));
        }
        return observable.pipe(map((response) => response.data));
    }

    static post(url: string, data?: any, config: AxiosRequestConfig = {}, needAuthorize: boolean = false, needBearer: boolean = false) {
        let requestConfig = {} as AxiosRequestConfig;
        if (config) {
            requestConfig = Object.assign({}, config, defaultConfig);
        }
        if (!requestConfig.headers) {
            requestConfig.headers = {};
        }
        if (!requestConfig.headers["Content-Type"]) {
            requestConfig.headers["Content-Type"] = "application/json";
        }
        requestConfig.params = { ...Constants.commonRequestParams, ...(requestConfig.params || {}) };

        let observable: Observable<any>;
        if (needAuthorize) {
            observable = AccountManager.shared.getAccessToken().pipe(
                flatMap((token) => {
                    if (needBearer) {
                        requestConfig.headers.Authorization = `Bearer ${token}`;
                    } else {
                        requestConfig.headers.Authorization = token;
                    }
                    return from(axios.post(url, data, requestConfig));
                }),
            );
        } else {
            observable = from(axios.post(url, data, requestConfig));
        }
        return observable.pipe(map((response) => response.data));
    }

    static put(url: string, data?: any, config: AxiosRequestConfig = {}, needAuthorize: boolean = false, needBearer: boolean = false) {
        let requestConfig = {} as AxiosRequestConfig;
        if (config) {
            requestConfig = Object.assign({}, config, defaultConfig);
        }
        if (!requestConfig.headers) {
            requestConfig.headers = {};
        }
        if (!requestConfig.headers["Content-Type"]) {
            requestConfig.headers["Content-Type"] = "application/json";
        }
        requestConfig.params = { ...Constants.commonRequestParams, ...(requestConfig.params || {}) };

        let observable: Observable<any>;
        if (needAuthorize) {
            observable = AccountManager.shared.getAccessToken().pipe(
                flatMap((token) => {
                    if (needBearer) {
                        requestConfig.headers.Authorization = `Bearer ${token}`;
                    } else {
                        requestConfig.headers.Authorization = token;
                    }
                    return from(axios.put(url, data, requestConfig));
                }),
            );
        } else {
            observable = from(axios.put(url, data, requestConfig));
        }
        return observable.pipe(map((response) => response.data));
    }

    static delete(url: string, config: AxiosRequestConfig = {}, needAuthorize: boolean = false, needBearer: boolean = false) {
        let requestConfig = {} as AxiosRequestConfig;
        if (config) {
            requestConfig = Object.assign({}, config, defaultConfig);
        }
        if (!requestConfig.headers) {
            requestConfig.headers = {};
        }
        if (!requestConfig.headers["Content-Type"]) {
            requestConfig.headers["Content-Type"] = "application/json";
        }
        requestConfig.params = { ...Constants.commonRequestParams, ...(requestConfig.params || {}) };

        let observable: Observable<any>;
        if (needAuthorize) {
            observable = AccountManager.shared.getAccessToken().pipe(
                flatMap((token) => {
                    if (needBearer) {
                        requestConfig.headers.Authorization = `Bearer ${token}`;
                    } else {
                        requestConfig.headers.Authorization = token;
                    }
                    return from(axios.delete(url, requestConfig));
                }),
            );
        } else {
            observable = from(axios.delete(url, requestConfig));
        }
        return observable.pipe(map((response) => response.data));
    }

    static getData(url: string, config?: AxiosRequestConfig, needAuthorize: boolean = false, needBearer?: boolean) {
        return Api.get(url, config, needAuthorize, needBearer).pipe(
            map((data) => {
                if (data.code === 0) {
                    return data.data;
                } else {
                    throw data;
                }
            }),
        );
    }

    static postData(url: string, data?: any, config?: AxiosRequestConfig, needAuthorize: boolean = false, needBearer?: boolean) {
        return Api.post(url, data, config, needAuthorize, needBearer).pipe(
            map((data) => {
                if (data.code === 0) {
                    return data.data;
                } else {
                    throw data;
                }
            }),
        );
    }

    static putData(url: string, data?: any, config?: AxiosRequestConfig, needAuthorize: boolean = false, needBearer?: boolean) {
        return Api.put(url, data, config, needAuthorize, needBearer).pipe(
            map((data) => {
                if (data.code === 0) {
                    return data.data;
                } else {
                    throw data;
                }
            }),
        );
    }

    static deleteData(url: string, config?: AxiosRequestConfig, needAuthorize: boolean = false, needBearer?: boolean) {
        return Api.delete(url, config, needAuthorize, needBearer).pipe(
            map((data) => {
                if (data.code === 0) {
                    return data.data;
                } else {
                    throw data;
                }
            }),
        );
    }

    static getAuthorizedData(url: string, config?: AxiosRequestConfig) {
        return Api.getData(url, config, true);
    }

    static postAuthorizedData(url: string, data?: any, config?: AxiosRequestConfig) {
        return Api.postData(url, data, config, true);
    }

    static putAuthorizedData(url: string, data?: any, config?: AxiosRequestConfig) {
        return Api.putData(url, data, config, true);
    }

    static getBearerData(url: string, config?: AxiosRequestConfig) {
        return Api.getData(url, config, true, true);
    }

    static postBearerData(url: string, data?: any, config?: AxiosRequestConfig) {
        return Api.postData(url, data, config, true, true);
    }
    static putBearerData(url: string, data?: any, config?: AxiosRequestConfig) {
        return Api.putData(url, data, config, true, true);
    }
    static deleteBearerData(url: string, config?: AxiosRequestConfig) {
        return Api.deleteData(url, config, true, true);
    }
}
