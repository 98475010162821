export namespace RouteUtils {
    /**
     * url后追加query参数
     * @param url
     * @param params
     */
    export function appendParams(url: string, params?: Record<string, any>): string {
        const u = new URL(url);
        params &&
            Object.entries(params).forEach(([k, v]) => {
                v && u.searchParams.append(k, v);
            });
        return u.href;
    }
}
