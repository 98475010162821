import { Decimal } from "decimal.js";
import { ExchangeSymbol } from "TradeAPILib";

export const toFixed = (n: string | number, dec: number) => {
    const num = Number(n);
    if (isNaN(num)) return n;
    return new Decimal(n).toFixed(dec, Decimal.ROUND_DOWN).valueOf();
};

export function getSymbolPrecision(config: { symbol?: ExchangeSymbol; coin: string; defaultPrecision?: number; isMarketBuy?: boolean }): number {
    const { defaultPrecision = 4, symbol, coin, isMarketBuy = false } = config;

    if (!symbol) {
        return defaultPrecision;
    }

    if (isMarketBuy && coin === symbol.quote) {
        return symbol.precisionCost ?? defaultPrecision;
    }

    if (coin === symbol.base) {
        return symbol.amount ?? defaultPrecision;
    }

    if (coin === symbol.quote) {
        return symbol.precision ?? defaultPrecision;
    }

    return defaultPrecision;
}
