import { Alert, Button, Card, Modal, Spin } from "@pionex-web-kit/components";
import Breadcrumbs from "./Breadcrumbs";
import { useTranslators } from "commonUse/Locale";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ManualOrderStatus, OrderTypeDesc } from "TradeAPILib";
import { manualOrderTransform } from "landings/Trade/Order/Manual/manualOrderHelper";
import { ManualOrderTableData, OrderTabType } from "landings/Trade/TradeConstant";
import useDateFormatter from "commonUse/useDateFormatter";
import moment from "moment";
import { getUserGridOrderCloseType } from "utils/userInfo";
import { OrderUtils } from "utils/OrderUtils";
import useAccountInfo from "commonUse/useAccountInfo";
import { useFetchManualOrders } from "landings/PionexHistoricalOrderDetails/components-v2/ManualOrders/hooks/useFetchManualOrders";
import { useObservable } from "@pionex-web-kit/common-business";
import { useDataProvider } from "commonUse";
import { history } from "utils/History";
import { getAccountPathname } from ".";

export default function CloseManualOrder() {
    const accountInfo = useAccountInfo();
    const apiKey = (accountInfo.apiKeys || [])[0];
    const { $st } = useTranslators();
    const dataProvider = useDataProvider(accountInfo.firstApiKey);
    const [closeAllOrderLoading, setCloseAllOrderLoading] = useState<boolean>();
    const [closeOrderLoading, setCloseOrderLoading] = useState<string>();
    const [closeOrderModalVisible, setCloseOrderModalVisible] = useState<boolean>();

    const dateFormatter = useDateFormatter();

    const params = useMemo(() => {
        return {
            limit: 20,
            state: ManualOrderStatus.running,
        };
    }, []);
    const { getOrdersObserve, loading, fetch } = useFetchManualOrders();
    const { orders, hasMore } = useObservable(getOrdersObserve, {
        orders: [],
        hasMore: true,
    });
    const orderDataSource = useMemo(() => {
        if (!orders) {
            return [];
        }
        return manualOrderTransform(orders, dateFormatter, OrderTabType.running);
    }, [dateFormatter, orders]);
    const resetList = useCallback(() => {
        if (!dataProvider) return;
        fetch({
            params,
            reset: true,
        });
    }, [fetch, params, dataProvider]);
    const closeOrder = useCallback(
        (order) => {
            let info: any = {};
            if (
                order.orderType === OrderTypeDesc.GRID_CLASSIC ||
                order.orderType === OrderTypeDesc.GRID_PRO ||
                order.orderType === OrderTypeDesc.GRID_LOAN ||
                order.orderType === OrderTypeDesc.GRID_SHORT ||
                order.orderType === OrderTypeDesc.GRID_LEVERAGE
            ) {
                info = {
                    convert_into_earn_coin: !!getUserGridOrderCloseType(),
                };
            }
            return OrderUtils.cancelOrder(apiKey, order, info).toPromise();
        },
        [apiKey],
    );
    const closeOrderEvt = useCallback(
        (e: React.MouseEvent<HTMLElement, MouseEvent>, item: ManualOrderTableData) => {
            e.stopPropagation();
            setCloseOrderLoading(item.id);
            closeOrder(item.originalData)
                .then(resetList)
                .catch(() => {})
                .finally(() => {
                    setCloseOrderLoading(undefined);
                });
        },
        [closeOrder, resetList],
    );
    const closeAllOrders = useCallback(() => {
        if (!orderDataSource) return;
        setCloseOrderModalVisible(false);
        setCloseAllOrderLoading(true);
        Promise.all(orderDataSource.map((item) => closeOrder(item.originalData))).finally(() => {
            setCloseAllOrderLoading(false);
            resetList();
        });
    }, [closeOrder, orderDataSource, resetList]);

    useEffect(() => {
        resetList();
    }, [resetList]);
    useEffect(() => {
        if (!orders.length || !hasMore) return;
        fetch({
            params,
        });
    }, [orders, hasMore, params, fetch]);

    useEffect(() => {
        if (loading !== false || orders.length) return;
        history.push(getAccountPathname("/account_update"));
    }, [loading, orders]);
    return (
        <>
            <div className=" w-full flex flex-col">
                <Breadcrumbs />
                <Spin spinning={loading}>
                    <div className="flex justify-between items-center mb-12px mt-16px">
                        <div className="text-base font-b">Close Manual orders</div>
                        <Button
                            loading={closeAllOrderLoading}
                            onClick={() => {
                                setCloseOrderModalVisible(true);
                            }}
                            type="primary"
                            shape="round"
                        >
                            Close all orders
                        </Button>
                    </div>
                    <Alert className="mb-12px" message={$st("note_recreate")} type="warning" />
                    {orderDataSource.map((item) => {
                        return (
                            <Card className="w-full mb-8px" key={item.id}>
                                <div className="flex justify-between items-center">
                                    <div className="text-base font-m">{item.operation}</div>
                                    <Button
                                        loading={closeOrderLoading === item.id}
                                        size="small"
                                        type="default"
                                        shape="round"
                                        onClick={(e) => {
                                            closeOrderEvt(e, item);
                                        }}
                                    >
                                        {$st("contest_close")}
                                    </Button>
                                </div>
                                <div className="grid grid-cols-3 gap-x-8px gap-y-12px mt-12px">
                                    <div className="flex flex-col gap-4px">
                                        <div className="text-sm text-text-200">{$st("trade_symbol")}</div>
                                        <div className="text-text-100">{item.symbol}</div>
                                    </div>
                                    <div className="flex flex-col items-center gap-4px">
                                        <div className="text-sm text-text-200">{$st("history_time")}</div>
                                        <div className="text-text-100">{moment((item as any).timestamp).format("YYYY/MM/DD HH:mm:ss")}</div>
                                    </div>
                                    <div className="flex flex-col items-end gap-4px">
                                        <div className="text-sm text-text-200">{$st("trade_page_price_placeholder")}</div>
                                        <div className="text-text-100">{item.price}</div>
                                    </div>
                                    <div className="flex flex-col gap-4px">
                                        <div className="text-sm text-text-200">{$st("order_title_quantitys")}</div>
                                        <div className="text-text-100">{item.quantity}</div>
                                    </div>
                                    <div className="flex flex-col items-center gap-4px">
                                        <div className="text-sm text-text-200">{$st("order_title_filleds")}</div>
                                        <div className="text-text-100">{item.filled}</div>
                                    </div>
                                </div>
                            </Card>
                        );
                    })}
                </Spin>
            </div>
            {closeOrderModalVisible !== undefined && (
                <Modal
                    visible={closeOrderModalVisible}
                    onOk={closeAllOrders}
                    okButtonProps={{ loading: closeAllOrderLoading }}
                    onCancel={() => {
                        setCloseOrderModalVisible(false);
                    }}
                    title={$st("grid_pro_order_close_model_title")}
                >
                    {$st("sure_close_all_orders")}
                </Modal>
            )}
        </>
    );
}
