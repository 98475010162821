import { useOrderBookDataWithTimes } from "landings/Trade/Swap/tool";
import { useMemo } from "react";
import { SafeDecimal } from "trade_utils_lib";

export type SymbolDesc = { base: string; quote: string; type: "buy" | "sell" };

/**
 * 根据盘口价格预估币对中币种转换的数量
 * @param symbol
 * @param amount
 * @param frequency
 */
export function useSymbolTransferQuantity({ symbol, amount, frequency }: { symbol?: SymbolDesc; amount?: number; frequency?: number }) {
    // 盘口数据
    const orderBookData = useOrderBookDataWithTimes({ base: symbol?.base, quote: symbol?.quote, frequency });

    return useMemo(() => {
        if (!symbol) {
            return null;
        }

        if (!amount) {
            // 保证外部能拿到对应的币对
            return {
                symbol,
            };
        }
        // 从 A币种 兑换为 B币种
        const priceList = (symbol.type === "buy" ? orderBookData?.asks : orderBookData?.bids) ?? [];
        let coinCount = 0; // 兑换到的B的数量
        let totalPrice = 0; // 已花费A的数量
        let orderBookLastItem = priceList.length > 0 ? priceList[priceList.length - 1] : null; // 盘口最后一个格子的数据
        priceList.some((item) => {
            if (amount > totalPrice) {
                // 还剩下多少A 未兑换成B
                let leftPrice = new SafeDecimal(amount).sub(totalPrice).toNumber();
                // 如果把当前格子全部兑换为B 需要花多少A
                let itemTotalPrice = symbol.type === "buy" ? new SafeDecimal(item.price).mul(item.quantity).toNumber() : item.quantity;

                // 如果剩余未兑换的A 不足以全部兑换当前格子
                if (leftPrice < itemTotalPrice) {
                    // 则根据当前格子单价算出可以兑换多少B并累加到总数上
                    coinCount = new SafeDecimal(leftPrice)[symbol.type === "buy" ? "div" : "mul"](item.price).add(coinCount).toNumber();
                } else {
                    // 否则把当前格子的B全部数量兑换累加到已兑换到总数上
                    if (symbol.type === "buy") {
                        coinCount = new SafeDecimal(item.quantity).add(coinCount).toNumber();
                    } else {
                        coinCount = new SafeDecimal(item.quantity).mul(item.price).add(coinCount).toNumber();
                    }
                }

                // 每次把当前格子需要花费的A的数量累加，通过对累加的数量是否超出需要兑换的数量A, 就可以得知是否已经兑换完成
                totalPrice = new SafeDecimal(itemTotalPrice).add(totalPrice).toNumber();
                return false;
            }
            return true;
        });

        // 如果兑换完orderbook以后，仍然有A没有兑换，则使用orderbook最后一个格子的数据计算剩余能兑换的量
        if (amount > totalPrice && orderBookLastItem) {
            // 还剩下多少A未兑换成B
            let leftPrice = new SafeDecimal(amount).sub(totalPrice).toNumber();
            // 则根据当前格子单价算出可以兑换多少B并累加到总数上
            coinCount = new SafeDecimal(leftPrice)[symbol.type === "buy" ? "div" : "mul"](orderBookLastItem.price).add(coinCount).toNumber();
        }

        return {
            quantity: coinCount,
            // price: new SafeDecimal(amount).div(coinCount).toNumber(),
            symbol,
        };
    }, [amount, symbol, orderBookData?.asks, orderBookData?.bids]);
}
