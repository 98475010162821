import { APIKeyInfo } from "TradeLib/TradeTypes";
import { AddressInfo, DWHistoryInfo, DWRecordStatus, SupportDWCoinInfo, WithdrawRecordStatus } from "TradeAPILib";
import { useDataProvider, useDataProviderOnFirstApiKey } from "src/commonUse";
import { $st } from "utils/i18n/trans.comp";
import { message } from "antd";
import { useTranslators } from "commonUse/Locale";
import { useEffect, useState } from "react";
import { useCallbackStatic } from "commonUse/tools";
import { WithdrawError } from "utils/errorCode";

// 发送验证码的状态
export enum SendState {
    standby,
    pending,
    success,
    error,
}

export interface IVerificationNams {
    email_code: string;
    sms_code: string;
    google_code: string;
}

/** 解析验证码错误提示...*/
export function parseVerifyCodeErrorMsg(errorMsg: string) {
    if (errorMsg) {
        if (errorMsg.indexOf("type=otp") !== -1) {
            return `${$st("withdraw_code_verify_failed_google")}`;
        }
        if (errorMsg.indexOf("type=sms") !== -1) {
            return `${$st("sms_verify_code_error")}`;
        }
        if (errorMsg.indexOf("type=email") !== -1) {
            return `${$st("email_verify_code_error")}`;
        }
        if (errorMsg.indexOf("type=voice") !== -1) {
            return `${$st("voice_verify_code_error")}`;
        }
    }
    return `${$st("verification_code_error")}`;
}

// 正式提交提币申请
export function useWithdraw(apiKey: APIKeyInfo | undefined, coin: string) {
    const dataProvider = useDataProvider(apiKey);
    const [fields, inputFields] = useState({} as any);

    const getFormatErrorMsg = (code: number, msg: string) => {
        if (code === 40320735) {
            return $st("code_verify_error");
        } else if (code === 40300700) {
            return $st("disable_withdraw_not_whitelist_address");
        } else if (code === -90) {
            return $st("withdraw_error_cause_modify_password_after_24h");
        } else if (code === -9) {
            return $st("withdraw_invalid_code");
        } else if (code === -3) {
            return $st("withdraw_code_verify_failed_google");
        } else if (code === -8 || code === -10) {
            return $st("withdraw_code_not_exist");
        }

        return WithdrawError.parseErrorMsg(msg);
    };

    const [submitState, updateSubmitState] = useState(SendState.standby as SendState);
    const submitWidthVerifications = (verifications: IVerificationNams) => {
        return new Promise<void>((res, rej) => {
            if (dataProvider) {
                updateSubmitState(SendState.pending);
                dataProvider.api
                    .withdrawV2({ coin, ...fields, ...verifications })
                    .toPromise()
                    .then((data) => {
                        updateSubmitState(SendState.success);
                        res();
                    })
                    .catch((err) => {
                        const errMsg = getFormatErrorMsg(err.code, err.message);
                        message.error(errMsg);
                        updateSubmitState(SendState.standby);
                        rej(err);
                    });
            } else {
                rej();
            }
        }).then(() => {
            updateSubmitState(SendState.standby);
        });
    };
    return {
        submitState,
        submitWidthVerifications,
        inputFields,
    };
}

/**
 * 获取可充提的币地址
 * @param currency
 * @param apiKey
 */
export function useGetCoinAddress(currency: string, isDeposit: boolean): [boolean, string, AddressInfo] {
    const [loading, changeLoading] = useState(true);
    const [error, setError] = useState("");
    const [coinAddress, updateAddress] = useState({ address: "", currency } as AddressInfo);
    const dataProvider = useDataProviderOnFirstApiKey();
    const { $st } = useTranslators();
    // 避免翻译数据的更新触发新的数据请求
    const handleErrors = useCallbackStatic((error: any, isDeposit: boolean) => {
        if ("status" in error && "response" in error) {
            return `code: ${error.status} ${JSON.stringify(error.response)}`;
        } else if (error.code === 4031100) {
            return $st(isDeposit ? "kyc_limit_warn1" : "kyc_limit_warn2");
        } else if (error.code === 4031200) {
            return "Pionex进行了账号安全升级，检测到账号未绑定邮箱，安全等级过低，请绑定邮箱后再使用充/提币功能。";
        } else if (error.code === 4031400) {
            return error.message;
        } else {
            return `code: ${error.code} ${JSON.stringify(error.message)}`;
        }
    });
    useEffect(() => {
        if (dataProvider && currency) {
            changeLoading(true);
            dataProvider.api.requestCoinAddress(currency).subscribe(
                (data) => {
                    setError("");
                    updateAddress(data);
                    changeLoading(false);
                },
                (error) => {
                    updateAddress({ address: "", currency });
                    setError(handleErrors(error, isDeposit));
                    changeLoading(false);
                },
            );
        }
    }, [dataProvider, currency, isDeposit, handleErrors]);
    return [loading, error, coinAddress];
}

export function getDWRecordStatusDesc(record: DWHistoryInfo, currentCoin?: SupportDWCoinInfo) {
    if (record.status === DWRecordStatus.reviewing) {
        return $st("dw_record_status_reviewing");
    } else if (record.status === DWRecordStatus.confirmed) {
        return $st("dw_record_status_confirmed", {
            block: record.confirmations || 0,
            confirmation: currentCoin ? currentCoin.tradeConfirmation : 3,
        });
    } else if (record.status === DWRecordStatus.withdrawing) {
        if (record.statusInner === WithdrawRecordStatus.REMITTED && record.txId && record.confirmations === 0) {
            return $st("deposit_withdraw_remitted");
        } else {
            return $st("dw_record_status_withdrawing");
        }
    } else if (record.status === DWRecordStatus.processing) {
        return $st("dw_record_status_processing");
    } else if (record.status === DWRecordStatus.complete) {
        return $st("dw_record_status_complete");
    } else if (record.status === DWRecordStatus.failed) {
        return $st("dw_record_status_failed");
    } else if (record.status === DWRecordStatus.canceled) {
        return $st("dw_record_status_canceled");
    } else {
        return $st("dw_record_status_unknown");
    }
}

/**
 * 大额划转屏蔽
 * @param item
 */
export function checkLargeTransfer(item: DWHistoryInfo): boolean {
    // const amountDecimal: Decimal = new Decimal(item.amount);
    // if (item.currency === "BTC") {
    //   return amountDecimal.greaterThanOrEqualTo(75);
    // }

    return false;
}
