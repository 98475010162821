import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import moment from "moment";
import { SafeDecimal } from "trade_utils_lib";
import { useTranslators } from "./Locale";
import useAccountInfo from "./useAccountInfo";
import useKycV2Info, { kycV2GuideRoute, KycV2Level } from "./useKycInfoV2";
import { TModal } from "components/v2/TinyComps";
import { history } from "utils/History";
import { useDataProvider } from "commonUse";
import NumberUtils from "utils/NumberUtils";
import useUNSAFE_JoinInternalTest, { BETA_MODULE_IDS } from "./useJoinInternalTest";
import { of, Subscription } from "rxjs";
import { message } from "antd";
import { QuotaChannel, QuotaCheckType, QuotaDirection, useRemainQuota } from "utils/complianceQuota";
import { flatMap } from "rxjs/operators";
import { SupportDWCoinInfo } from "TradeAPILib";

/**
 * 获取用户KYC等级权限
 * 权限树规则：
 * - 权限节点驼峰命名
 * - 节点末端权限以 $ 符号开头
 * - 可以支持嵌套权限节点
 * @internalHook
 * @returns
 */
function useKycV2RightsController() {
    const { $st } = useTranslators();
    const [kycV2Info, kycLoading] = useKycV2Info();
    const { createTime, phoneAccount, emailAccount } = useAccountInfo();
    const { currentLevel, location: kycLocation, originData: kycOriginData } = kycV2Info;
    const isRegionOfUSA = kycLocation?.region === "USA";
    const isBetaMemberOfModule = useUNSAFE_JoinInternalTest(BETA_MODULE_IDS.circle, BETA_MODULE_IDS.credit_card);
    /**
     * 其他杂项权限
     */
    const others = useMemo(() => {
        return {
            /**
             * 美国用户并且没做二级kyc
             */
            get $USAKycLimit() {
                // 新版KYC规则：美国用户并且没做二级kyc
                return isRegionOfUSA && currentLevel !== KycV2Level.lv2;
            },
        };
    }, [currentLevel, isRegionOfUSA]);
    /**
     * 交易权限
     */
    const trade = useMemo(() => {
        /**
         * 新版kyc规则：除了lv0用户，其他等级均可通过；<!--美国用户需要 lv2 级别；-->
         * 老用户规则：都可使用
         */
        const canTrade = PLATFORM.PIONEX_US_LIKE ? currentLevel !== null && currentLevel > KycV2Level.lv1 : currentLevel !== KycV2Level.lv0; // location?.region === "USA" ? currentLevel === KycV2Level.lv2 :
        return canTrade;
    }, [currentLevel]);
    /**
     * 币种充提权限
     */
    const coinDW = useMemo(() => {
        const isNoPhoneAccountWithoutEmail = phoneAccount?.account.startsWith("+86") && !emailAccount;
        const deposit = {
            get $forbidContent() {
                // 针对中国大陆用户，不用国际化
                if (isNoPhoneAccountWithoutEmail) return "Pionex进行了账号安全升级，检测到账号未绑定邮箱，安全等级过低，请绑定邮箱后再使用充/提币功能。";
                return PLATFORM.PIONEX_US_LIKE ? $st("kyc_limit_usa") : $st("kyc_limit_warn1");
            },
        };
        const withdraw = {
            get $forbidContent() {
                // 针对中国大陆用户，不用国际化
                if (isNoPhoneAccountWithoutEmail) return "Pionex进行了账号安全升级，检测到账号未绑定邮箱，安全等级过低，请绑定邮箱后再使用充/提币功能。";
                return PLATFORM.PIONEX_US_LIKE ? $st("kyc_limit_usa") : $st("kyc_limit_warn2");
            },
        };
        // 嵌套的权限节点
        return {
            deposit,
            withdraw,
            // 是否可出入金
            get $permission() {
                // FIXME: 强制限制 86 未绑定邮箱的用户不能进行充提
                if (isNoPhoneAccountWithoutEmail) return false;
                return trade;
            },
            // 公共的权限
            get $forbidTitle() {
                if (isNoPhoneAccountWithoutEmail) return $st("account_bind_title_email");
                return $st("account_kyc");
            },
            get $forbidLink() {
                return currentLevel !== null ? (isNoPhoneAccountWithoutEmail ? "/account/bind" : kycV2GuideRoute) : null;
            },
            get $forbidBtnText() {
                return isNoPhoneAccountWithoutEmail ? $st("common_go_bind") : $st("kyc_go_do");
            },
            /**
             * 充币权限
             * @deprecated Use {@link coinDW.deposit} instead.
             */
            coinDeposit: deposit,
            /**
             * 币种出金权限树
             * @deprecated Use {@link coinDW.withdraw} instead.
             */
            coinWithdraw: withdraw,
        };
    }, [$st, currentLevel, emailAccount, phoneAccount?.account, trade]);
    /**
     * 法币出入金权限
     */
    const moneyDW = useMemo(() => {
        /**
         * 做了kyc-lv2的新用户 和 做了老kyc的老用户
         */
        const canMoneyDW = [KycV2Level.lv2, KycV2Level.oldLv2].includes(currentLevel as any);
        // 嵌套的权限节点
        return {
            get $permission() {
                return canMoneyDW;
            },

            circle: {
                /**
                 * 入口显示权限
                 */
                get $entry() {
                    if (PLATFORM.PIONEX_US_LIKE) return false;
                    /**
                     * 前期先上线繁中语言用户
                     * 注册用户时间是，新版KYC上线之后的时间。
                     */
                    return isBetaMemberOfModule[BETA_MODULE_IDS.circle] || ["TWN", "TW"].includes(kycLocation?.region as string);
                },
                /**
                 * 实际使用权限
                 */
                get $permission() {
                    if (PLATFORM.PIONEX_US_LIKE) return false;
                    /**
                     * Circle入金权限
                     * 需要排除系统中已经做了二级KYC，且是 中国大陆、新加坡 和 中国台湾 地区的用户
                     * 由于二级KYC已经过滤了中高风险国家(又称 国家黑名单)，因此，这里不需要判断所谓的中高风险国家
                     */
                    return canMoneyDW && !["CHN", "CH", "SG", "SGP", "USA", "US"].includes(kycLocation?.region as string);
                },
            },
            credit_card: {
                get $entry() {
                    if (!PLATFORM.PIONEX_MAIN) return false;
                    const kycUpdateTime = kycOriginData?.updateTime;
                    if (!kycUpdateTime) return false;
                    // 12月1号以后注册的 完成了kyc的台湾用户
                    const availableKycUpdateTime = moment(kycUpdateTime).isAfter(moment("2021-12-01").startOf("day"));
                    const availableRegion = ["TWN", "TW"].includes(kycLocation?.region as string);
                    const availableUser = availableKycUpdateTime && availableRegion;

                    return isBetaMemberOfModule[BETA_MODULE_IDS.credit_card] || availableUser;
                    // return isBetaMemberOfModule[BETA_MODULE_IDS.credit_card];
                },
            },
            /**
             * 派可[台币入金]权限
             * TODO: 完善限制规则
             */
            picol: canMoneyDW, // && location.region === 'TWN'
        };
    }, [currentLevel, isBetaMemberOfModule, kycLocation?.region]);
    return { rights: { trade, moneyDW, coinDW, ...(coinDW as Omit<typeof coinDW, "deposit" | "withdraw">), others }, loading: kycLoading };
}

// TODO: 用content进行全局共享，避免多次请求数据
export const CTXKycV2Rights = createContext({ rights: {}, loading: true } as ReturnType<typeof useKycV2RightsController>);

/**
 * 全局共享的 Provider
 * @param param0
 * @returns
 */
export function KycV2RightsProvider({ children }: { children: any }) {
    const value = useKycV2RightsController();
    return <CTXKycV2Rights.Provider value={value}>{children}</CTXKycV2Rights.Provider>;
}

/**
 * 获取权限数据
 * @returns
 */
export const useKycV2Rights = () => {
    const { rights, loading } = useContext(CTXKycV2Rights);
    const [kycV2Info] = useKycV2Info();
    return [
        rights,
        {
            // 当前KYC等级
            kycV2Info,
            loading,
        },
    ] as const;
};

export const useKycV2TradeKycModal = (callBack?: () => void) => {
    const [{ trade: tradeRight }, { loading, kycV2Info: kycInfo }] = useKycV2Rights();
    const { $st } = useTranslators();
    const tradeKycModal = useCallback(() => {
        TModal.confirm({
            title: $st("account_kyc"),
            content: (
                <div>
                    {PLATFORM.PIONEX_US_LIKE ? $st("kyc_limit_usa") : $st("kyc_limit_trade")} <br />
                    <span>{$st("complete_the_identity")}</span>{" "}
                    <a className=" text-primary hover:text-primary" href="/newbie-rewards">
                        {$st("home_act_btn")}
                    </a>
                </div>
            ),
            okText: $st("kyc_go_do"),
            onOk: () => {
                callBack && callBack();
                kycInfo.currentLevel !== null && history.push(kycV2GuideRoute, { from: "trade" });
            },
        });
    }, [$st, kycInfo.currentLevel, callBack]);
    return { tradeKycModal, tradeRight, loading };
};
/**
 * 每日剩余可提币额度
 */
export const useKycV2DailyWithdrawLeft = (currentCoin?: SupportDWCoinInfo) => {
    const [loading, setLoading] = React.useState(false);
    const [coin24hLimit, setCoin24hLimit] = React.useState<number>();
    const { details, loading: remainQuotaLoading, refresh: refreshRemainQuota } = useRemainQuota(QuotaChannel.COIN, QuotaDirection.WITHDRAW);
    const { firstApiKey } = useAccountInfo();
    const tradeAPI = useDataProvider(firstApiKey)?.api;
    const tickerRef = useRef<Subscription>();

    // 剩余滚动额度
    const rollingQuota = useMemo(() => details.find((item) => item.checkType === QuotaCheckType.COINOUT_ROLLING_QUOTA_CHECK), [details]);

    useEffect(() => {
        (async () => {
            try {
                if (!currentCoin?.coinName) {
                    return;
                }
                if (!rollingQuota) {
                    return;
                }
                let price = 0;
                if (currentCoin.coinName === "USDT") {
                    price = 1;
                } else {
                    price = await new Promise((res, rej) => {
                        if (!tradeAPI) return rej("no available trade api!");
                        if (tickerRef.current) {
                            tickerRef.current.unsubscribe();
                        }
                        setLoading(true);
                        tickerRef.current = tradeAPI.apiAdapter
                            .queryTickerByRest(currentCoin.coinName, "USD")
                            // 没有USD的币对时，使用USDT的币对获取汇率
                            .pipe(flatMap((v) => (v ? of(v) : tradeAPI.apiAdapter.queryTickerByRest(currentCoin.coinName, "USDT"))))
                            .subscribe(
                                (ticker) => {
                                    if (ticker?.latest > 0) {
                                        res(ticker.latest);
                                        // 拿到ticker数据之后
                                        tickerRef.current?.unsubscribe();
                                        setLoading(false);
                                    }
                                },
                                (e) => {
                                    setLoading(false);
                                    message.error(e.message || `Error getting ticker of coin ${currentCoin?.coinName},please contact support!`);
                                    rej(e);
                                },
                            );
                    });
                }
                if (price !== 0) {
                    const limitNumber = new SafeDecimal(rollingQuota.quota).div(price).toNumber();
                    // 提币余额理论上存在负数的情况，如果为负就显示0
                    setCoin24hLimit(limitNumber < 0 ? 0 : NumberUtils.effectiveDecimalNum(limitNumber, 8));
                } else {
                    message.error(`Error getting price of coin ${currentCoin?.coinName},please contact support!`);
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, [currentCoin?.coinName, currentCoin?.limits.kycWithdraw.daily, currentCoin?.limits.withdraw.daily, rollingQuota, setCoin24hLimit, tradeAPI, tradeAPI?.apiAdapter]);
    return [
        coin24hLimit || 0,
        loading || remainQuotaLoading,
        {
            refresh: refreshRemainQuota,
        },
    ] as const;
};
