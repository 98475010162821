import { useTranslators } from "commonUse/Locale";
import useDateFormatter from "commonUse/useDateFormatter";
import { ExchangeMTGOrder, ExchangeOrder } from "TradeAPILib";

/**
 * 马丁格尔机器人显示名称
 */
export function getMTGShowName(order: ExchangeOrder, prefix?: string) {
    const displayCoinNames =
        order.exchangeMTGOrder?.coins.length && order.exchangeMTGOrder.coins.length > 3
            ? order.exchangeMTGOrder?.coins
                  ?.slice(0, 3)
                  .map(({ baseDisplay }) => baseDisplay)
                  .concat("...")
                  .join("/")
            : order.exchangeMTGOrder?.coins.map(({ baseDisplay }) => baseDisplay).join("/");
    return `${displayCoinNames}/${order.quote} ${prefix ?? ""}`;
}

/**
 * 马丁格尔订单运行时间
 * @param exchangeOrder
 */
export function useMTGDuration(exchangeOrder: ExchangeOrder): string {
    const dateFormatter = useDateFormatter();
    const { $st } = useTranslators();

    const beginTime = exchangeOrder.exchangeMTGOrder?.triggerTime || exchangeOrder.timestamp;
    const endTime = exchangeOrder.closeTimestamp;

    if (exchangeOrder.exchangeMTGOrder?.isCondition && !exchangeOrder.exchangeMTGOrder?.conditionTriggered) {
        // 是条件单，但是未触发
        return $st("sl_pro_order_not_trigger");
    }

    return `${dateFormatter.year_second(beginTime)}-${dateFormatter.year_second(endTime)}`;
}

/**
 * 马丁格尔订单是否支持分享
 * @param exchangeMTGOrder
 */
export function isSharableMTGOrder(exchangeMTGOrder: ExchangeMTGOrder) {
    if (exchangeMTGOrder.isCondition && !exchangeMTGOrder.conditionTriggered) {
        return false;
    }

    return true;
}

export function getMTGCurrentCoin(exchangeMTGOrder: ExchangeMTGOrder) {
    return exchangeMTGOrder?.coins[0];
}
