import { ExchangeAIPOrder, ExchangeGridOrder, ExchangeOrder, ExchangeOrderSide, ExchangeSymbol, ExchangeTicker, ExchangeTradePair, exchangeTWAPOrder } from "TradeAPILib";
import ExchangeDataProvider from "src/TradeLib/ExchangeDataProvider";
import { Observable, of, zip } from "rxjs";
import { catchError, flatMap, map, take } from "rxjs/operators";
import { TradeCreateOrderError } from "@pionex-web-kit/trading-components/es/Trading/TradeCreateOrderError";
import { OrderCheckRestoreResult } from "src/utils/OrderUtils";
import TradeUtil from "@pionex-web-kit/trading-components/es/TradeLib/TradeUtils";
import NumberUtils from "./NumberUtils";
import GridCreateOrderModel from "@pionex-web-kit/trading-components/es/Trading/Grid/GridTrading/GridCreateOrderModel";
import Decimal from "decimal.js";
import AIPTradeHelper from "@pionex-web-kit/trading-components/es/Trading/AIPTrading/AIPTradeHelper";
import AIPCreateOrderModel from "@pionex-web-kit/trading-components/es/Trading/AIPTrading/AIPCreateOrderModel";
import { TWAPTradingCheckTOCreateOrder } from "@pionex-web-kit/trading-components/es/Trading/TWAPTrading/TWAPTradingContent";
import { $st } from "../utils/i18n/index";
import { BalanceProvider } from "trade_asset_lib";

interface CheckRetryOrderCommonParams {
    symbol: ExchangeSymbol;
    ticker: ExchangeTicker;
    baseBalance: number;
    quoteBalance: number;
    totalBalance: number;
}

export const RetryOrderTools = {
    checkGridOrderRestore(order: ExchangeOrder, provider: ExchangeDataProvider): Observable<OrderCheckRestoreResult> {
        const gridOrder: ExchangeGridOrder | undefined = order.exchangeGridOrder;
        if (!gridOrder || !order.exchange || !gridOrder.canRetry) {
            return of({ result: false });
        }
        const exchange = order.exchange;
        const exchangePair: ExchangeTradePair = { base: order.base, quote: order.quote };
        let createOrderModel: GridCreateOrderModel;
        //1.获取公共参数
        return this.getTickerSymbolBalance(provider, exchangePair)
            .pipe(
                flatMap((params: any) => {
                    let minPerVolume = TradeUtil.getTraingMinAmount(params.symbol, gridOrder.low);
                    minPerVolume = NumberUtils.toAmountNumBySymbol(minPerVolume, params.symbol, Decimal.ROUND_UP);
                    createOrderModel = new GridCreateOrderModel({
                        market: exchange,
                        symbol: params.symbol,
                        high: NumberUtils.toPriceNumBySymbol(gridOrder.high || 0, params.symbol),
                        low: NumberUtils.toPriceNumBySymbol(gridOrder.low || 0, params.symbol),
                        stop: NumberUtils.toPriceNumBySymbol(gridOrder.stop || 0, params.symbol),
                        grid_count: gridOrder.number || 0,
                        grid_per_volume: gridOrder.amount || 0,
                        grid_min_per_volume: minPerVolume,
                        avaliableBalance: params.totalBalance,
                        baseBalance: params.baseBalance,
                        quoteBalance: params.quoteBalance,
                        ticker: params.ticker,
                    });
                    const requiredBalancerequest = createOrderModel.checkGridRequiredBalanceRequest();
                    //2.checkRequiredBalance
                    return TradeUtil.requestGridRequireBalance(
                        provider,
                        exchange,
                        exchangePair.base,
                        exchangePair.quote,
                        requiredBalancerequest.baseAvailable,
                        requiredBalancerequest.quoteAvailable,
                        requiredBalancerequest.high,
                        requiredBalancerequest.low,
                        requiredBalancerequest.perVolume,
                        requiredBalancerequest.gridCount,
                    );
                }),
            )
            .pipe(
                map((result: any) => {
                    if (!result) {
                        throw TradeCreateOrderError.Balance_Get_Failed;
                    }
                    if (!result.enough && result.errMsg) {
                        throw result.errMsg;
                    }
                    createOrderModel.param.grid_required_result = result;
                    //3.检查所有参数
                    return createOrderModel.checkGridOrderRequest();
                }),
            )
            .pipe(
                map(() => {
                    return { result: true };
                }),
            )
            .pipe(
                catchError((error) => {
                    if (error.code) {
                        if (error.code === 10108 || error.code === 10109) {
                            ///> TODO 弹窗提醒平衡单
                            return of({ result: true });
                        }
                    }
                    let errorMsg = error;
                    if (error.msg) {
                        errorMsg = $st(error.msg, error.info);
                    }
                    return of({ result: false, msg: errorMsg });
                }),
            );
    },

    checkAIPOrderRestore(order: ExchangeOrder, provider: ExchangeDataProvider): Observable<OrderCheckRestoreResult> {
        const aipOrder: ExchangeAIPOrder | undefined = order.exchangeAIPOrder;
        if (!aipOrder || !order.exchange || !aipOrder.canRetry) {
            return of({ result: false });
        }
        const exchange = order.exchange;
        const exchangePair: ExchangeTradePair = { base: order.base, quote: order.quote };
        let commonParams: CheckRetryOrderCommonParams;
        return this.getTickerSymbolBalance(provider, exchangePair)
            .pipe(
                flatMap((result: any) => {
                    commonParams = result;
                    return AIPTradeHelper.queryLimitData(exchange, commonParams.symbol);
                }),
            )
            .pipe(
                map((result: any) => {
                    let minCost: number | undefined;
                    if (result && result.cost) {
                        minCost = result.cost.min;
                    }
                    const model = new AIPCreateOrderModel({
                        symbol: commonParams.symbol,
                        timeInterval: aipOrder.interval,
                        investmentEachTime: aipOrder.perVolume,
                        usdtBalance: commonParams.quoteBalance,
                        minCost: minCost,
                    });
                    return model.checkToCreateOrderRequest();
                }),
            )
            .pipe(
                map(() => {
                    return { result: true };
                }),
            )
            .pipe(
                catchError((error) => {
                    let errorMsg = error;
                    if (error.msg) {
                        errorMsg = $st(error.msg, error.info);
                    }
                    return of({ result: false, msg: errorMsg });
                }),
            );
    },

    checkTWAPOrderRestore(order: ExchangeOrder, provider: ExchangeDataProvider): Observable<OrderCheckRestoreResult> {
        const twapOrder: exchangeTWAPOrder | undefined = order.exchangeTWAPOrder;
        if (!twapOrder || !order.exchange || !twapOrder.canRetry) {
            return of({ result: false });
        }
        const exchange = order.exchange;
        const exchangePair: ExchangeTradePair = { base: order.base, quote: order.quote };
        let commonParams: CheckRetryOrderCommonParams;
        return this.getTickerSymbolBalance(provider, exchangePair)
            .pipe(
                flatMap((result: any) => {
                    commonParams = result;
                    return AIPTradeHelper.queryLimitData(exchange, commonParams.symbol);
                }),
            )
            .pipe(
                map((result: any) => {
                    let minCost = 0;
                    if (result && result.cost && result.cost.min) {
                        minCost = result.cost.min;
                    }
                    const side: ExchangeOrderSide = twapOrder.side === "buy" ? ExchangeOrderSide.buy : ExchangeOrderSide.sell;
                    return TWAPTradingCheckTOCreateOrder(commonParams.symbol, side, twapOrder.totalTimes, twapOrder.totalVolume, commonParams.quoteBalance, minCost);
                }),
            )
            .pipe(
                map((result: any) => {
                    return { result: result.result, msg: result.msg };
                }),
            )
            .pipe(
                catchError((error) => {
                    let errorMsg = error;
                    if (error.msg) {
                        errorMsg = $st(error.msg, error.info);
                    }
                    return of({ result: false, msg: errorMsg });
                }),
            );
    },

    ///获取retry需要验证的必要参数
    getTickerSymbolBalance(provider: ExchangeDataProvider, exchangePair: ExchangeTradePair): Observable<CheckRetryOrderCommonParams> {
        const getSymbolObserval: Observable<ExchangeSymbol> = provider.getExchangeSymbols().pipe(
            map((symbols) => {
                const result = symbols.filter((item) => {
                    return item.quote.toUpperCase() === exchangePair.quote.toUpperCase() && item.base.toUpperCase() === exchangePair.base.toUpperCase();
                });
                if (result.length <= 0) {
                    throw TradeCreateOrderError.AIP_Get_Symbol_Failed;
                }
                return result[0];
            }),
        );
        const getTickerObserval: Observable<ExchangeTicker> = provider.api.queryRestSymbolTicker(exchangePair.base, exchangePair.quote);
        const balanceOberve = BalanceProvider.instance
            .getTradeBalanceObservable({
                key: "",
                secret: "",
                extra: "",
                market: provider.api.getInnerApiKeyInfo().market,
                cloudKeyId: provider.api.getInnerApiKeyInfo().cloudKeyId,
            })
            .pipe(
                map((data) => {
                    let baseBalance = data.find((item) => item.currency === exchangePair.base);
                    let quoteBalance = data.find((item) => item.currency === exchangePair.quote);
                    return { baseBalance: baseBalance?.balance || 0, quoteBalance: quoteBalance?.balance || 0 };
                }),
            );
        return zip(getSymbolObserval, getTickerObserval, balanceOberve).pipe(
            map((result) => {
                const symbol = result[0];
                const ticker = result[1];
                const baseBalance: number = result[2].baseBalance;
                const quoteBalance: number = result[2].quoteBalance;
                const totalBalance = baseBalance * ticker.latest + quoteBalance;
                const params: CheckRetryOrderCommonParams = {
                    symbol,
                    ticker,
                    baseBalance: NumberUtils.toAmountNumBySymbol(baseBalance, symbol),
                    quoteBalance: NumberUtils.toPriceNumBySymbol(quoteBalance, symbol),
                    totalBalance: NumberUtils.toPriceNumBySymbol(totalBalance, symbol),
                };
                return params;
            }),
        );
    },
};
