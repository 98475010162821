import React, { useEffect, useState } from "react";
import { AddCardRes, CardVerifyInfo, DebitCardChannel, DebitCardStatus, PublicKeyInfo } from "landings/V2/CircleDebit/types";
import AddCheckoutCard from "../AddCheckoutCard/AddCheckoutCard";
import AddCircleCardModal from "../AddCircleCard/AddCircleCardModal";
import { useCallbackStatic } from "commonUse/tools";
import { message } from "antd";
import { $T } from "utils/i18n/trans.comp";
import DebitAPI from "landings/V2/CircleDebit/DebitAPI";
import { useCircleDebitAPI } from "landings/V2/CircleDebit/context";

interface Props {
    type: DebitCardChannel;
    publicKeyInfo: PublicKeyInfo;
    onCancel();
    onSuccess();
    startCardVerify(info: CardVerifyInfo);
}

/**
 * 绑定卡片的中间组件，转发到对应通道的实际绑定流程
 */
const BindCard: React.FC<Props> = ({ type, startCardVerify, onCancel, onSuccess, publicKeyInfo }) => {
    const circleDebitAPI = useCircleDebitAPI();

    const [verifyRunner, setRunner] = useState<Detector>();
    useEffect(() => () => verifyRunner?.destroy(), []);

    const internalCheckCardStatus = useCallbackStatic((cardId: string) => {
        if (!circleDebitAPI) return;
        setRunner(
            new Detector(
                cardId,
                circleDebitAPI,
                () => {
                    startCardVerify({ cardId, isRequired: true });
                    onSuccess();
                },
                () => {
                    setRunner(undefined);
                    onSuccess();
                },
            ),
        );
    });

    // 添加卡片的接口返回结果处理
    const handleResult = useCallbackStatic((res: AddCardRes) => {
        const { id, verifyRequired } = res;
        if (verifyRequired) {
            internalCheckCardStatus(id);
        } else onSuccess();
    });

    if (type === DebitCardChannel.CIRCLE) {
        return <AddCircleCardModal onCancel={onCancel} onResult={handleResult} loading={!!verifyRunner} />;
    }

    return <AddCheckoutCard publicKeyInfo={publicKeyInfo} onCancel={onCancel} onResult={handleResult} loading={!!verifyRunner} />;
};

export default BindCard;

class Detector {
    constructor(private cardId: string, private circleDebitAPI: DebitAPI, private startVerify: () => any, private onFinish: () => any) {
        this.run();
    }
    async run() {
        this._destroyed = false;
        const { status } = await this.circleDebitAPI.getCard(this.cardId).toPromise();
        switch (status) {
            case DebitCardStatus.PENDING: {
                setTimeout(() => {
                    if (this._destroyed) return;
                    this.run();
                }, 1e3);
                break;
            }
            case DebitCardStatus.VERIFY_REQUIRED: {
                this.startVerify();
                break;
            }
            default: {
                this.onFinish();
                this.destroy();
                if (status === DebitCardStatus.FAILED) {
                    message.error($T("debit_add_card_error"));
                }
            }
        }
    }
    private _destroyed = false;
    destroy() {
        this._destroyed = true;
    }
}
