import { Button } from "antd";
import css from "components/LoginDialog/index.module.less";
import { useCallback } from "react";
import AccountManager from "utils/AccountManager";
import { $st } from "utils/i18n";
import { TModal } from "components/v2/TinyComps";
import { FromName } from "landings/Analytics/EventName";

export function showLoginDialog(
    title?: string,
    config?: {
        from?: string;
        redirectCurrent?: boolean; // 成功后是否重定向回到当前界面
    },
) {
    const { redirectCurrent } = config || {};
    const confirmModal = TModal.confirm({
        centered: true,
        maskClosable: true,
        okText: $st("sign_up_title"),
        cancelText: $st("sign_in_title"),
        onOk: () => {
            confirmModal.destroy();
            AccountManager.toSignUp(FromName.dialog_signUp, { redirectBack: redirectCurrent });
        },
        onCancel: () => {
            confirmModal.destroy();
        },
        cancelButtonProps: {
            onClick() {
                confirmModal.destroy();
                AccountManager.toSign(FromName.dialog_sign, { redirectBack: redirectCurrent });
            },
        },
        title: $st("dw.tips.title"),
        content: title || $st("login_dialog_popup_title"),
    });
}

export const SignInButton = ({ from }: { from?: string }) => {
    const onSignIn = useCallback(() => {
        AccountManager.toSign(from || FromName.single_btn);
    }, []);

    return (
        <Button className={css.signInButtonStyle} style={{ width: 85, height: 30 }} onClick={onSignIn}>
            <span style={{ fontSize: 14, marginTop: 3 }}>{$st("sign_in_title")}</span>
        </Button>
    );
};

export const SignUpButton = ({ from }: { from?: string }) => {
    const onSignUp = useCallback(() => {
        AccountManager.toSignUp(from || FromName.single_btn);
    }, []);

    return (
        <Button className={css.signUpButtonStyle} style={{ width: 85, height: 30 }} onClick={onSignUp}>
            <span style={{ fontSize: 14, marginTop: 3 }}>{$st("sign_up_title")}</span>
        </Button>
    );
};

export interface PrimaryButtonProps {
    text: string;
    onClick: () => void;
}

export const PrimaryButton = (props: PrimaryButtonProps) => {
    const { onClick, text } = props;
    return (
        <Button className={css.primaryButtonStyle} onClick={onClick}>
            <span style={{ fontSize: 14, marginTop: 3 }}>{text}</span>
        </Button>
    );
};
