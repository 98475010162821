import { ReactNode } from "react";
import cns from "classnames";
import { IconNoData100pxBoldS } from "@pionex-web-kit/icons";
import { useTranslators } from "commonUse/Locale";

interface Props {
    iconSize?: number;
    tip?: ReactNode;
    className?: string;
}
export default function Nodata({ tip, iconSize = 100, className }: Props) {
    const { $st } = useTranslators();
    return (
        <div className={cns("w-full h-full flex justify-center items-center", className)}>
            <div className="flex flex-col items-center gap-[10px]">
                <IconNoData100pxBoldS size={iconSize} />
                <div className="text-base text-secondary">{tip ?? $st("no_data")}</div>
            </div>
        </div>
    );
}
