import React from "react";
import { $st } from "utils/i18n";
import { Button } from "antd";
import { history } from "utils/History";

export interface LeverageCoinExamModalContentProps {
    onClose: () => void;
}

const LeverageCoinExamModalContent = (props: LeverageCoinExamModalContentProps) => {
    const { onClose } = props;
    const [selectedTips, setSelectedTips] = React.useState<boolean>(false);

    return (
        <div style={{ marginBottom: 44 }}>
            <h3 style={{ fontWeight: "bold" }}>{$st("answer_to_start_leverage_coin_trade")}</h3>
            <p>{$st("learn_to_start_leverage_coin_trade_desc")}</p>
            <div onClick={() => window.open($st("leverage_coin_exam_tutorial_url"))} style={{ display: "flex", cursor: "pointer", alignItems: "center", marginTop: 10 }}>
                <span style={{ color: "#075DE3", fontWeight: "bold" }}>{$st("read_leverage_coin_tutorial")}</span>
                <img src={require("../../../images/link_icon.png")} width={16} height={16} style={{ marginLeft: 10 }} />
            </div>
            <div onClick={() => setSelectedTips(!selectedTips)} style={{ marginTop: 17, cursor: "pointer", display: "flex", alignItems: "center" }}>
                <img src={selectedTips ? require("../../../images/ic_selected.png") : require("../../../images/ic_unselected.png")} style={{ width: 18, height: 18 }} alt={""} />
                <div style={{ marginLeft: 4 }}>{$st("already_know_leverage_coin_risk")}</div>
            </div>

            <div
                style={{
                    display: "flex",
                    marginTop: 34,
                    alignItems: "center",
                    justifyContent: "flex-end",
                    position: "absolute",
                    right: 20,
                }}
            >
                <Button onClick={onClose} style={{ borderRadius: 4 }}>
                    {$st("grid_dialog_button_no")}
                </Button>
                <Button
                    onClick={() => {
                        history.push("/leverage_coin_exam");
                        onClose();
                    }}
                    disabled={!selectedTips}
                    style={{ marginLeft: 11, backgroundColor: selectedTips ? "#FF874F" : "#CCCCCC", color: "#FFFFFF", border: "none", borderRadius: 4 }}
                >
                    {$st("start_answer")}
                </Button>
            </div>
        </div>
    );
};

export default LeverageCoinExamModalContent;
