import { SymbolSortMethod } from "landings/Trade/MarketLayout/MarketPage/types";
import { ThemeName } from "state/ThemeData";
import { GridType } from "trade_lib_wrapper";
import { NumberCommonUtils } from "trade_utils_lib";
import { ExchangeID } from "TradeAPILib";
import { TradeSelectedInfo } from "TradeLib/TradeTypes";
import { getCurrentLocale } from "utils/i18n";
import AccountInfo from "../state/AccountInfo";
import { SideTabKey } from "landings/Trade/SideTab/types";
import { TradeCtxLayout } from "landings/Trade/TradeCtx";
import Cookies from "js-cookie";
import Constants from "./Constants";
import queryString from "query-string";

const KEY_ACCOUNT_INFO = "key_account_info";
const KEY_TRADE_SELECTED_API_KEY_ID = "key_trade_selected_api_key_id";
const KEY_TRADE_SELECTED_EXCHANGE = "key_trade_selected_exchange";
export const KEY_TRADE_SELECTED_SYMBOL = "key_trade_selected_route_path";
const KEY_TRADE_SELECTED_TAB = "key_trade_selected_tab";
const KEY_TRADE_CHART_RESOLUTION = "key_trade_chart_resolution";
const KEY_TRADE_CHART_STYLE = "key_trade_chart_style";
const KEY_TRADE_SYMBOL_SORT_METHOD = "key_trade_symbol_sort_method";
const KEY_TRADE_BALANCE_INFO = "key_trade_balance_info";
const KEY_SELECTED_EXCHANGE_PAIR = "key_selected_exchange_pair";

const KEY_THEME_THEME_NAME = "key_theme_theme_name";
const KEY_THEME_TICKER_REVERSED = "key_theme_ticker_reversed";

const KEY_LOCAL_SERVICE = "_key_local_service";
const KEY_SHOW_MIGRATE_DIALOG = "key_show_migrate_dialog";

const KEY_GRID_PRICE_INTERVAL_TYPE = "key_grid_price_interval_type";
const KEY_GRID_USE_MY_ANOTHER_COIN = "key_grid_use_my_another_coin";
const KEY_GRID_SLIPPAGE_CONTROL = "key_grid_slippage_control";

const HAD_OPEN_GRID_FUTURE_ORDER = "HAD_OPEN_GRID_FUTURE_ORDER";

const KEY_SHOW_BIND_GOOGLE_AUTH_DIALOG = "key_show_bind_google_auth_dialog";

export enum LocalStorageKey {
    KEY_BALANCE_DW_COINS = "key_balance_deposit_coin",
    KEY_BALANCE_ORIGINAL_CACHE = "key_balance_original_cache",
    KEY_REFERRAL_INFO = "key_referral_info",
    KEY_GRID_PROFIT_EXACT_POP = "key_show_grid_profit_exact",
    KEY_ANNOUNCEMENT = "key_announcement",
    KEY_TV_DEFAULT_INTERVAL = "key_tv_default_interval",
    KEY_TV_CHART_STATE = "key_tv_chart_state",
    KEY_TV_CHART_ADDED_MA_LINES = "key_tv_chart_added_ma_lines",
    KEY_TV_CHART_SHOW_ORDER_LINES = "key_tv_chart_show_order_lines",
    KEY_TV_CHART_PRO_FLAG = "key_tv_chart_pro_flag",
    KEY_SMART_TRADE_TRAILING_SWITCH = "key_smart_trade_trailing_switch",
    KEY_USER_HAVE_DEPOSIT_HISTORY = "key_user_have_deposit_history",
    FSA_ADVANCED_SETTING = "fsa_advanced_setting",
    FSA_REDUCE_POSITION_ADVANCED_SETTING = "fsa_reduce_position_advanced_setting",
    FSA_CLOSE_POSITION_ADVANCED_SETTING = "fsa_close_position_advanced_setting",
    KEY_FP_DID = "key_fp_did",
    KEY_SHOW_KYC_GUIDE = "show_kyc_guide",
    KEY_PREFER_VERIFY_TYPE = "key_prefer_verify_type",
    KEY_LAYOUT_INFO = "key_layout_info",
    KEY_DEFAULT_ACCOUNT_TYPE = "key_default_account_type",
    KEY_DEFAULT_TAXES_INFO = "key_default_taxes_info",
    // 公告信息已读timestamp
    KEY_ANNOUNCEMENT_READ_TIMESTAMP = "key_announcement_read_timestamp",
    RE_ORDER_DATA = "re_order_data",
    FAVOR_LIST = "FAVOR_LIST", // 自选币对
    IDENTITY_VERIFICATION_COMPLETED = "identity_verification_completed",
}

export enum TVStorageKey {
    interval = "tradingview.chart.lastUsedTimeBasedResolution",
}

export default class LocalStorageUtils {
    static get accountInfo(): AccountInfo | undefined {
        let accountInfoJson = window.localStorage.getItem(KEY_ACCOUNT_INFO);
        if (accountInfoJson && accountInfoJson !== "") {
            try {
                return { ...JSON.parse(accountInfoJson), isInitialized: true };
            } catch (error) {
                console.error(error);
            }
        }
        return undefined;
    }

    static getLayoutInfo(): TradeCtxLayout["layoutInfo"] | null {
        try {
            return JSON.parse(window.localStorage.getItem(LocalStorageKey.KEY_LAYOUT_INFO) || "");
        } catch (error) {
            return null;
        }
    }

    static getTaxesNewInfo(): boolean {
        return Boolean(window.localStorage.getItem(LocalStorageKey.KEY_DEFAULT_TAXES_INFO));
    }

    static setTaxesNewInfo() {
        window.localStorage.setItem(LocalStorageKey.KEY_DEFAULT_TAXES_INFO, "true");
    }

    static setLayoutInfo(layoutInfo: TradeCtxLayout["layoutInfo"]) {
        window.localStorage.setItem(LocalStorageKey.KEY_LAYOUT_INFO, JSON.stringify(layoutInfo));
    }

    static setAccountInfo(accountInfo: AccountInfo) {
        window.localStorage.setItem(KEY_ACCOUNT_INFO, JSON.stringify(accountInfo));
    }

    // static setHasShowKycGuide(type: boolean) {
    //     window.localStorage.setItem(LocalStorageKey.KEY_SHOW_KYC_GUIDE, JSON.stringify({ type }));
    // }

    // static getHasShowKycGuide() {
    //     return window.localStorage.getItem(LocalStorageKey.KEY_SHOW_KYC_GUIDE);
    // }

    static removeAccountInfo() {
        window.localStorage.removeItem(KEY_ACCOUNT_INFO);
    }

    static setSmartTradeTrailingSwitch(value: boolean) {
        window.localStorage.setItem(LocalStorageKey.KEY_SMART_TRADE_TRAILING_SWITCH, value ? "true" : "");
    }

    static get smartTradeTrailingSwitch(): boolean {
        return window.localStorage.getItem(LocalStorageKey.KEY_SMART_TRADE_TRAILING_SWITCH) ? true : false;
    }

    static get tradeSelectedApiKeyId() {
        return window.localStorage.getItem(KEY_TRADE_SELECTED_API_KEY_ID);
    }

    static get tradeSelectedExchange() {
        return window.localStorage.getItem(KEY_TRADE_SELECTED_EXCHANGE);
    }

    static _defaultSelectedSymbol = `BTC_USD`;

    static get tradeSelectedSymbol() {
        return window.localStorage.getItem(KEY_TRADE_SELECTED_SYMBOL) || LocalStorageUtils._defaultSelectedSymbol;
    }

    static get tradeSelectedTab(): SideTabKey {
        return (window.localStorage.getItem(KEY_TRADE_SELECTED_TAB) || SideTabKey.BOT) as SideTabKey;
    }

    static setTradeSelectedTab(tab: SideTabKey) {
        window.localStorage.setItem(KEY_TRADE_SELECTED_TAB, tab);
    }

    static removeTradeSelectedTab() {
        window.localStorage.removeItem(KEY_TRADE_SELECTED_TAB);
    }

    static setTradeSelectedApiKeyId(apiKeyId: string) {
        window.localStorage.setItem(KEY_TRADE_SELECTED_API_KEY_ID, apiKeyId);
    }

    static get tradeSelectedRoutePath() {
        return LocalStorageUtils.generateTradePath(LocalStorageUtils.tradeSelectedTab);
    }

    /**
     * 构造交易页路径
     * @param symbol base_quote
     * @param tab SideTabKey
     */
    static generateFullTradePath(symbol = LocalStorageUtils.tradeSelectedSymbol, tab = LocalStorageUtils.tradeSelectedTab) {
        return `/trade/${symbol}/${tab}`;
    }

    /**
     * 生成交易页路径
     * @param tab
     */
    static generateTradePath(tab: SideTabKey) {
        let symbol = LocalStorageUtils.tradeSelectedSymbol;
        // 兼容旧的 exchangeID 后缀路径: BTC_USDT/pionex.v2
        if (symbol.endsWith(ExchangeID.PIONEX)) {
            symbol = symbol.replace(ExchangeID.PIONEX, ExchangeID.PIONEXV2);
        }
        return `${symbol}/${tab}`;
    }

    static get selectedExchangePair():
        | undefined
        | {
              exchange: typeof ExchangeID[keyof typeof ExchangeID];
              base: string;
              quote: string;
          } {
        let data = window.localStorage.getItem(KEY_SELECTED_EXCHANGE_PAIR);
        if (!data) {
            return undefined;
        }
        try {
            const exchangePair = JSON.parse(data);
            if (exchangePair.exchange === ExchangeID.PIONEX) {
                exchangePair.exchange = ExchangeID.PIONEXV2;
            }
            return exchangePair;
        } catch (e) {
            console.error(e);
        }
        return undefined;
    }

    static setTradeSelectedInfo(tradeSelectedInfo: TradeSelectedInfo) {
        const { apiKey, symbol, exchange } = tradeSelectedInfo;
        if (apiKey) {
            window.localStorage.setItem(KEY_TRADE_SELECTED_API_KEY_ID, apiKey.key_id);
        }
        // 存储选中的币对
        window.localStorage.setItem(KEY_TRADE_SELECTED_SYMBOL, `${symbol.base}_${symbol.quote}`);
        // 存储选中的币对信息与exchangeID
        window.localStorage.setItem(
            KEY_SELECTED_EXCHANGE_PAIR,
            JSON.stringify({
                exchange: exchange,
                base: symbol.base,
                quote: symbol.quote,
            }),
        );
    }

    static get tradeChartResolution() {
        return window.localStorage.getItem(KEY_TRADE_CHART_RESOLUTION) || "60";
    }

    static get tradeChartStyle() {
        const style = window.localStorage.getItem(KEY_TRADE_CHART_STYLE) || "1";
        return NumberCommonUtils.str2number(style);
    }

    static setTradeChartResolution(resolution) {
        window.localStorage.setItem(KEY_TRADE_CHART_RESOLUTION, resolution);
    }

    static setTradeChartSeriesStyle(style) {
        window.localStorage.setItem(KEY_TRADE_CHART_STYLE, `${style}`);
    }

    static getSymbolSortMethod(): SymbolSortMethod {
        const value = window.localStorage.getItem(KEY_TRADE_SYMBOL_SORT_METHOD);
        let result;
        if (value) {
            result = SymbolSortMethod[value];
        }
        if (!result) {
            result = SymbolSortMethod.VOL_DESC;
        }
        return result;
    }

    static setSymbolSortMethod(sortMethod: SymbolSortMethod) {
        window.localStorage.setItem(KEY_TRADE_SYMBOL_SORT_METHOD, SymbolSortMethod[sortMethod]);
    }

    static get localService(): any {
        const value = window.localStorage.getItem(KEY_LOCAL_SERVICE);
        let result = {};
        if (value) {
            result = JSON.parse(value);
        }
        return result;
    }

    static setUserCacheBalanceInfo(balanceList: string) {
        window.localStorage.setItem(KEY_TRADE_BALANCE_INFO, balanceList);
    }

    static getUserCacheBalanceInfo(): string | null {
        return window.localStorage.getItem(KEY_TRADE_BALANCE_INFO);
    }

    static get themeName(): ThemeName {
        const value = Cookies.get(KEY_THEME_THEME_NAME) || window.localStorage.getItem(KEY_THEME_THEME_NAME);
        let name;
        if (!value) {
            name = ThemeName.light;
            LocalStorageUtils.setThemeName(name);
        } else {
            name = value as ThemeName;
        }
        return name;
    }

    static setThemeName(name: ThemeName) {
        window.localStorage.setItem(KEY_THEME_THEME_NAME, name);
        Cookies.set(KEY_THEME_THEME_NAME, name, { domain: Constants.currentSubdomain });
    }

    static get tickerReversed() {
        const value = window.localStorage.getItem(KEY_THEME_TICKER_REVERSED);
        if (PLATFORM.PIONEX_US_LIKE) {
            // 美国站，默认绿涨红跌
            return !value ? false : value === "true";
        }
        if (!value) {
            const reversed = getCurrentLocale().startsWith("zh-");
            LocalStorageUtils.seTickerReversed(reversed);
            return reversed;
        } else {
            return value === "true";
        }
    }

    static seTickerReversed(reversed) {
        window.localStorage.setItem(KEY_THEME_TICKER_REVERSED, reversed ? "true" : "false");
    }

    static setShowMigrateDialog(show: boolean) {
        window.localStorage.setItem(KEY_SHOW_MIGRATE_DIALOG, show ? "true" : "false");
    }

    static get showMigrateDialog() {
        const showFlag = window.localStorage.getItem(KEY_SHOW_MIGRATE_DIALOG);
        if (showFlag) {
            return showFlag === "true";
        }
        return false;
    }

    static set gridPriceIntervalType(type: GridType) {
        window.localStorage.setItem(KEY_GRID_PRICE_INTERVAL_TYPE, type);
    }

    static get gridPriceIntervalType(): GridType {
        const result = window.localStorage.getItem(KEY_GRID_PRICE_INTERVAL_TYPE);
        if (result) {
            return result as GridType;
        }
        return "arithmetic";
    }

    static set gridSlippageControl(v: string) {
        window.localStorage.setItem(KEY_GRID_SLIPPAGE_CONTROL, v);
    }

    static get gridSlippageControl(): string {
        const result = window.localStorage.getItem(KEY_GRID_SLIPPAGE_CONTROL);
        return result ?? "";
    }

    static set gridUseMyAnotherCoin(isUse: boolean) {
        window.localStorage.setItem(KEY_GRID_USE_MY_ANOTHER_COIN, isUse ? "true" : "false");
    }

    static get gridUseMyAnotherCoin(): boolean {
        const result = window.localStorage.getItem(KEY_GRID_USE_MY_ANOTHER_COIN);
        if (result) {
            return result === "true";
        }
        return false;
    }

    static get noBindGoogleAuthDialogAgain() {
        const result = window.localStorage.getItem(KEY_SHOW_BIND_GOOGLE_AUTH_DIALOG);
        if (result) {
            return result === "true";
        }
        return false;
    }

    static set noBindGoogleAuthDialogAgain(flag: boolean) {
        window.localStorage.setItem(KEY_SHOW_BIND_GOOGLE_AUTH_DIALOG, flag ? "true" : "false");
    }

    private static _getReferralCodeFromStorage() {
        // 链接中不存在邀请链接时，使用本地缓存的
        const refCodeStr = this.get(LocalStorageKey.KEY_REFERRAL_INFO);
        if (refCodeStr) {
            try {
                const refCodeInfo = JSON.parse(refCodeStr) as { code: string; expire: number };
                if (refCodeInfo.expire && refCodeInfo.expire < Date.now()) {
                    // 清除过期数据
                    this.setReferralCode(undefined, "clear");
                }
                // 不再验证过期时间
                return refCodeInfo?.code ?? "";
            } catch (e) {}
        }
    }

    static getReferralCode(fromUrl: boolean = false): string {
        const { r, ref, referral } = queryString.parse(window.location.search, { decode: true }) as Record<"r" | "ref" | "referral", undefined | string>;
        let referralCode = r || ref || referral;
        if (!referralCode || typeof referralCode !== "string") {
            // 旧的邀请码路由
            const matchResult = window.location.href.match("ref/([A-Za-z0-9]+)");
            referralCode = matchResult ? matchResult[1] : undefined;
        }
        // 若链接中携带新的链接，则覆盖本地缓存的链接
        if (referralCode) {
            this.setReferralCode(referralCode, "get-up");
        }
        // 仅从URL中获取
        if (fromUrl) {
            return referralCode || "";
        }
        if (referralCode) {
            return referralCode;
        } else {
            // 链接中不存在邀请链接时，使用本地缓存的
            return this._getReferralCodeFromStorage() || "";
        }
    }

    static getSource() {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get("source") || window.localStorage.getItem("source") || "";
    }

    static setReferralCode(code?: string, f?: string) {
        if (!code) {
            this.remove(LocalStorageKey.KEY_REFERRAL_INFO);
        }
        // 对比覆盖旧的，但同样的code不覆盖
        const cacheCode = this._getReferralCodeFromStorage();
        if (code !== cacheCode) {
            this.set(
                LocalStorageKey.KEY_REFERRAL_INFO,
                JSON.stringify({
                    code,
                    expire: Date.now() + 7 * 24 * 60 * 60 * 1000,
                }),
            );
        }
    }

    static get(key: string) {
        return window.localStorage.getItem(key);
    }

    static set(key: string, value: string) {
        window.localStorage.setItem(key, value);
    }

    static remove(key: string) {
        window.localStorage.removeItem(key);
    }

    static set hadOpenGridFutureOrder(value: string) {
        window.localStorage.setItem(HAD_OPEN_GRID_FUTURE_ORDER, value);
    }

    static getObject(key: string, defaultValue: any | null | undefined = null) {
        const data = this.get(key);
        if (!data) {
            return defaultValue;
        }
        try {
            return JSON.parse(data);
        } catch (e) {}
        return defaultValue;
    }

    static setObject(key: string, value: any) {
        if (typeof value !== "boolean" && (!value || typeof value !== "object")) {
            this.remove(key);
            return;
        }
        this.set(key, JSON.stringify(value));
    }

    static getBoolean(key: LocalStorageKey | string, defaultValue: boolean = false): boolean {
        if (defaultValue) {
            return LocalStorageUtils.get(key) !== "false";
        } else {
            return LocalStorageUtils.get(key) === "true";
        }
    }

    static setBoolean(key: LocalStorageKey | string, value: boolean) {
        LocalStorageUtils.set(key, value ? "true" : "false");
    }
}
