import React, { useEffect, useRef } from "react";
import useAccountInfo from "commonUse/useAccountInfo";
import { useDataProvider } from "src/commonUse";
import { StrategyBalanceInfo } from "TradeAPILib/dist/src/ExchangeAPIAdapter/TradeTypeDef";
import { apiKey2ExchangeApiKey, BalanceProvider, ExchangeBalanceV2 } from "trade_asset_lib";
import { APIKeyInfo } from "trade_utils_lib";
import { ExchangeOrderType, ExchangeSymbol } from "TradeAPILib";

export interface IMinMax {
    max: number | null;
    min: number | null;
}

export interface ISymbolData {
    allSymbols: ExchangeSymbol[];
    symbolMap: Map<string, ExchangeSymbol>;
    quotes: string[];
}
// 获取策略资产
export function useStrategyBalance(): [boolean, StrategyBalanceInfo[] | undefined] {
    const accountInfo = useAccountInfo();
    const apiKey = accountInfo.firstApiKey;
    const dataProvider = useDataProvider(apiKey);
    const result = useRef<StrategyBalanceInfo[] | undefined>(undefined);
    const [lastedStrategyId, setLastedStrategyId] = React.useState<number | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(true);

    useEffect(() => {
        const ob = dataProvider?.api.fetchPionexStrategyBalance(lastedStrategyId).subscribe(
            (res) => {
                if (Array.isArray(result.current)) {
                    result.current = result.current.concat(res);
                } else {
                    result.current = res;
                }
                if (res.length >= 20) {
                    setLastedStrategyId(res[res.length - 1]?.strategyId);
                } else {
                    setLoading(false);
                }
            },
            (error) => {
                if (result.current === undefined) {
                    result.current = [];
                }
                setLoading(false);
                console.log("useStrategyBalance error:" + error);
            },
        );
        return () => ob && ob.unsubscribe();
    }, [dataProvider?.api, lastedStrategyId, result]);

    return [loading, result.current];
}

export const useTotalAssetExcludeBot = (apiKey: APIKeyInfo | undefined, hideSmallBalance = true): [ExchangeBalanceV2[], boolean] => {
    const [data, setData] = React.useState<ExchangeBalanceV2[]>([] as any);
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (!apiKey) return;
        const sub = BalanceProvider.instance.getTotalAssetExcludeBot(apiKey2ExchangeApiKey(apiKey)).subscribe(
            (res) => {
                setLoading(false);
                if (hideSmallBalance) {
                    setData(res.filter((item) => Math.abs(item.balance * (item.usdPrice || 0)) >= 1).sort((a, b) => b.balance * (b.usdPrice || 0) - a.balance * (a.usdPrice || 0)));
                } else {
                    setData(res);
                }
            },
            (error) => {
                setLoading(false);
                console.log("useTotalAssetExcludeBot", error);
            },
        );

        return () => sub.unsubscribe();
    }, [apiKey, hideSmallBalance]);

    return [data, loading];
};

export interface IStrategyFrozenBalanceRenderDataInfo {
    strategyTag: number;
    strategyId: number;
    coin: string;
    frozen: number;
    symbol: string;
    orderType: string;
    base: string;
    quote: string;
    type: "manual" | "strategy";
}

export const strategyTagMap = {
    1: ExchangeOrderType.aip,
    2: ExchangeOrderType.ig,
    3: ExchangeOrderType.gridPro,
    4: ExchangeOrderType.gridClassic,
    5: ExchangeOrderType.gridLoan,
    6: ExchangeOrderType.gridShort,
    7: ExchangeOrderType.gridLeverage,
    8: ExchangeOrderType.gridLeverageShort,
    9: ExchangeOrderType.gridV3,
    10: ExchangeOrderType.gridV4,
    21: ExchangeOrderType.SL_Pro,
    30: ExchangeOrderType.smartTrade,
    31: ExchangeOrderType.fsa,
    41: ExchangeOrderType.SR,
    42: ExchangeOrderType.martingale,
};
