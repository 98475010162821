import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import cs from "./cs.m.less";
import TReportLink from "../../../components/v2/TinyComps";
import { LANGUAGE_LOCALS_MAP } from "src/utils/i18n";
import classNames from "classnames";
import DownloadAppAlert from "src/components-v2/Layout/DownloadAppAlert";
import { useTranslators } from "src/commonUse/Locale";
import useMedia from "src/components-v2/Layout/hooks/useMedia";
import { NavLeft, NavRight } from "./Navs";
import { EmptyNavController, MobileNavController } from "./NavComps";
import { DepositGuideBanner, KYCBanner, NotLoggedInBanner, TradeBanner, TrialFundBanner } from "landings/V2/HomeUS.V2/TrialFundBanner";
import useKycV2Info from "commonUse/useKycInfoV2";
import useAccountInfo from "commonUse/useAccountInfo";
import { useOpenOrderTime } from "use/useOpenOrderTime";
import { useBalanceAll } from "commonUse/useBalance";
import { SafeDecimal } from "trade_utils_lib";

export interface HeaderV2Props {
    transparent?: boolean;
    scrollTopOffset?: number;
    fixed?: boolean;
    fixedHolder?: boolean;
    showDownLoad?: boolean;
    logo?: ReactNode; // 强制设置Header的logo图片
    tradingView?: boolean;
    showBanner?: boolean;
    onMobileNavVisibleChange?(visible: boolean): void;
    onAlertClose?(): void;
}

function HeaderV2({
    transparent,
    onAlertClose,
    tradingView,
    scrollTopOffset = 60,
    fixed = true,
    fixedHolder,
    showDownLoad = false,
    logo,
    showBanner,
    onMobileNavVisibleChange,
}: HeaderV2Props) {
    const [isOutTheScrollTopOffset, setScrollTopFlag] = useState(false);
    const { userId } = useAccountInfo();
    const [{ currentLevel }] = useKycV2Info();
    const isKyc = new SafeDecimal(currentLevel).greaterThanOrEqualTo(2);
    const { haveTraded } = useOpenOrderTime();
    const fullBalance = useBalanceAll();
    const haveBalance = useMemo(() => new SafeDecimal(fullBalance?.total.totalInUSD).greaterThan(0), [fullBalance]);
    const {
        intl: { locale },
    } = useTranslators();
    // 默认fixed时，附带fixedHolder
    const _fixedHolder = fixed && typeof fixedHolder === "undefined" ? true : fixedHolder;

    useEffect(() => {
        const scrolls = (e: any) => {
            if ((window.document.scrollingElement?.scrollTop || 0) > scrollTopOffset) {
                setScrollTopFlag(true);
            } else {
                setScrollTopFlag(false);
            }
        };
        window.addEventListener("scroll", scrolls);
        return () => window.removeEventListener("scroll", scrolls);
    }, [scrollTopOffset]);
    const { isTablet, isDeviceMobile } = useMedia();
    // holder与header高度保持一致的处理
    const boxRef = useRef<HTMLDivElement>(null);
    const [holderHeight, setHolderHeight] = useState(52);
    useEffect(() => {
        if (boxRef.current) {
            // holder高度，与显示header的容器保持一致
            const updateHolderHeight = () => {
                setHolderHeight(boxRef.current?.clientHeight || 52);
            };
            setTimeout(updateHolderHeight, 50);
            window.addEventListener("resize", updateHolderHeight);
            const MutationObserver = window.MutationObserver || (window as any).WebKitMutationObserver || (window as any).MozMutationObserver;
            const observer = new MutationObserver(updateHolderHeight);
            observer.observe(boxRef.current, { childList: true, attributes: true, attributeFilter: ["style"] });
            return () => {
                observer.disconnect();
                window.removeEventListener("resize", updateHolderHeight);
            };
        }
    }, []);
    return (
        <>
            <div
                className={classNames(cs.headerContainer, fixed && cs.fixed, {
                    // [cs.transparent]: transparent && !isTablet && !isOutTheScrollTopOffset,
                    [cs.koFont]: locale === LANGUAGE_LOCALS_MAP.ko,
                    [cs.tradingView]: tradingView,
                    [cs.showBanner]: showBanner,
                })}
                ref={boxRef}
            >
                {showDownLoad && isDeviceMobile ? <DownloadAppAlert onClose={onAlertClose} /> : null}
                <header className={cs.header}>
                    <div className={cs.headerLogo}>
                        <TReportLink to="/">{logo || <img src={require("src/assets/logo-us.png")} alt="Logo" />}</TReportLink>
                    </div>
                    {isTablet ? (
                        <MobileNavController headerHeight={holderHeight} onMobileNavVisibleChange={onMobileNavVisibleChange}>
                            <NavLeft />
                            <NavRight />
                        </MobileNavController>
                    ) : (
                        <EmptyNavController>
                            <NavLeft />
                            <NavRight />
                        </EmptyNavController>
                    )}
                </header>
                {showBanner && (
                    <>
                        {userId ? (
                            <>
                                <>{!isKyc && <KYCBanner />}</>
                                <>{isKyc && !haveBalance && <DepositGuideBanner />} </>
                                <>{isKyc && haveBalance && !haveTraded && <TradeBanner />}</>
                                <>{isKyc && haveBalance && haveTraded && <TrialFundBanner />}</>
                            </>
                        ) : (
                            <NotLoggedInBanner />
                        )}
                    </>
                )}
            </div>
            <div className={cs.fixedHolder} style={{ height: holderHeight, ...(_fixedHolder ? {} : { display: "none" }) }}></div>
        </>
    );
}

export default HeaderV2;
