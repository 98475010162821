import { OrderShareResp } from "TradeAPILib";
import { useEffect, useMemo, useState } from "react";
import { useShareOrderData } from "use/useShareOrderData";
import AppState, { dispatch } from "./AppState";
import { useSelector } from "react-redux";

export const TYPE_ORDER_SHARE = "type_order_share";

export function orderShareReducer(state = null, action: { type: string; data: OrderShareResp }) {
    if (action.type === TYPE_ORDER_SHARE) {
        return action.data;
    }
    return state;
}


export function useOrderShare(): OrderShareResp | null | undefined {
    const orderShare = useSelector((state: AppState) => state.orderShare);
    const [shareId, setShareId] = useState("");
    const shareOrderData = useShareOrderData(shareId);
    useEffect(() => {
        if (!orderShare) {
            setShareId(new URLSearchParams(window.location.search).get("shareId") || "")
        }
    }, [orderShare]);
    useEffect(() => {
        if (shareOrderData) {
            dispatch(TYPE_ORDER_SHARE, shareOrderData);
        }
    }, [shareOrderData]);
    return orderShare;
};