import { APIKeyInfo } from "TradeLib/TradeTypes";
import LocalStorageUtils from "utils/LocalStorageUtils";
import { AccountInfoTag, AccountType, BUUserAccount } from "bu_account_js_sdk";
import DataSubject from "utils/DataSubject";
import ExchangeDataProvider from "TradeLib/ExchangeDataProvider";
import { $st } from "utils/i18n/trans.comp";
import _ from "lodash";
import { ExchangeID, FSARiskPlanStatus } from "TradeAPILib";
import { isPionex } from "utils/platform";
import Constants from "utils/Constants";
import { GoogleTagManager } from "utils/GoogleTagManager";

export enum OtpState {
    BOUND = "bound",
    NOT_BOUND = "not_bound",
    NOT_CONFIRMED = "not_confirmed",
}

export enum MembershipInfo {
    none = "none",
    vip0 = "vip0",
    vip1 = "vip1",
    vip2 = "vip2",
    vip3 = "vip3",
    vip4 = "vip4",
    vip5 = "vip5",
    vip6 = "vip6",
    vip7 = "vip7",
    vip8 = "vip8",

    vip = "vip", ///> 新的VIP等级中不再使用
    svip = "svip", ///> 新的VIP等级中不再使用
    mm = "mm",
}

export interface LevelInfo {
    level: RebateLevel;
    inviteNumber: number;
    icon: string;
}

export enum RebateLevel {
    rebate0 = "0",
    rebate10 = "10",
    rebate20 = "20",
    rebate30 = "30",
    rebate25 = "25",
    rebatePro = "pro",
}

export interface UserRebateLevelInfo {
    currentLevel: LevelInfo;
    nextLevel?: LevelInfo;
    currentInviteNumber: number;
}

export default interface AccountInfo {
    /**
     * 用于判别数据是否已经完成初始化，可能是登录状态，也肯恩格式非登录状态下的初始化
     */
    isInitialized: boolean;
    userId?: string;
    uid?: string;
    thirdType?: boolean;
    accounts?: BUUserAccount[];
    nickname?: string;
    avatar?: string;
    createTime?: number;
    apiKeys?: APIKeyInfo[];
    firstApiKey?: APIKeyInfo;
    masterAccount?: BUUserAccount;
    subAccount?: BUUserAccount;
    phoneAccount?: BUUserAccount;
    emailAccount?: BUUserAccount;
    otpState?: OtpState;
    referralCode?: string;
    profitModel?: number;
    fsaRiskPlanStatus?: FSARiskPlanStatus;
    passLCExam?: boolean; // 是否考过杠杆代币考试
    membership?: MembershipInfo;
    rebateLevel?: UserRebateLevelInfo; // 2.0返佣信息
    migrate_time?: number;
    app?: string;
}

export function displayNickname(accountInfo: AccountInfo) {
    if (accountInfo.nickname) {
        return accountInfo.nickname;
    }
    if (!accountInfo.accounts || accountInfo.accounts.length === 0) {
        return "";
    }

    return $st("account_nickname_not_set_tip");
}

export function getAvatarUrl(path?: string) {
    let imgUrl = "";
    if (!path) {
        imgUrl = "/user-head/pionex_default.png";
    } else if (path.startsWith("https://") || path.startsWith("/user-head/")) {
        imgUrl = path;
    } else if (path.startsWith("user-head/")) {
        imgUrl = `/${path}`;
    } else {
        imgUrl = `/user-head/${path}`;
    }
    return `${Constants.avatarHost}${imgUrl}`;
}

export const TYPE_ACCOUNT_INIT = "type_account_init";
export const TYPE_ACCOUNT_SIGN_IN = "type_account_sign_in";
export const TYPE_ACCOUNT_SIGN_OUT = "type_account_sign_out";
export const TYPE_ACCOUNT_REFRESH_INFO = "type_account_refresh_info";
export const TYPE_ACCOUNT_REFRESH_OTP = "type_account_refresh_otp";
export const TYPE_ACCOUNT_REFRESH_REFERRAL_CODE = "type_account_refresh_referral_code";
export const TYPE_ACCOUNT_REFRESH_API_KEY = "type_account_refresh_api_key";
export const TYPE_ACCOUNT_ADD_API_KEY = "type_account_add_api_key";
export const TYPE_ACCOUNT_REMOVE_API_KEY = "type_account_remove_api_key";
export const TYPE_ACCOUNT_UPDATE_NICKNAME = "type_account_update_nickname";
export const TYPE_ACCOUNT_UPDATE_AVATAR = "type_account_update_avatar";
export const TYPE_ACCOUNT_PROFIT_MODEL = "type_account_profit_model";
export const TYPE_ACCOUNT_FSA_RISK_PLAN = "TYPE_ACCOUNT_FSA_RISK_PLAN";
export const TYPE_ACCOUNT_PASS_LCE = "TYPE_ACCOUNT_PASS_LCE";
export const TYPE_ACCOUNT_MEMBERSHIP = "TYPE_ACCOUNT_MEMBERSHIP";
export const TYPE_ACCOUNT_REBATE_LEVEL = "TYPE_ACCOUNT_REBATE_LEVEL";

const calcAccountData = (data: AccountInfo): AccountInfo => {
    // 区分拆分主从账号
    let masterAccount: BUUserAccount | undefined;
    let subAccount: BUUserAccount | undefined;
    let emailAccount: BUUserAccount | undefined;
    let phoneAccount: BUUserAccount | undefined;
    for (const account of data.accounts || []) {
        switch (account.account_type) {
            case AccountType.phoneNumber:
                phoneAccount = account;
                break;
            case AccountType.email:
                emailAccount = account;
                break;
            default:
                break;
        }
        switch (account.account_info_tag) {
            case AccountInfoTag.master:
                masterAccount = account;
                break;
            default:
                subAccount = account;
                break;
        }
    }
    return { ...data, masterAccount, subAccount, phoneAccount, emailAccount, firstApiKey: data.apiKeys?.[0] };
};

export function accountInfoReducer(state: AccountInfo = { isInitialized: false }, action) {
    let accountInfo = state;
    const { type, data } = action;
    switch (type) {
        case TYPE_ACCOUNT_INIT:
            DataSubject.asyncNext(TYPE_ACCOUNT_INIT, accountInfo);
            if (data.userId) {
                ExchangeDataProvider.accountWebSocket.open(isPionex() ? ExchangeID.PIONEXV2 : undefined);
            }
            accountInfo = { ...data, isInitialized: true };
            break;
        case TYPE_ACCOUNT_SIGN_IN:
            accountInfo = { ...data, isInitialized: true };
            ExchangeDataProvider.accountWebSocket.open(isPionex() ? ExchangeID.PIONEXV2 : undefined);
            GoogleTagManager.shared.setUserId(accountInfo.userId);
            break;

        case TYPE_ACCOUNT_SIGN_OUT:
            accountInfo = { isInitialized: true };
            ExchangeDataProvider.accountWebSocket.close();
            break;

        case TYPE_ACCOUNT_REFRESH_INFO:
            if (state.userId === data.userId) {
                if (state.nickname === data.nickname && state.avatar === data.avatar && _.isEqual(state.accounts, data.accounts)) {
                    return state;
                }
                accountInfo = { ...state, ...data, isInitialized: true };
                break;
            }
            accountInfo = { ...data, isInitialized: true };
            break;

        case TYPE_ACCOUNT_REFRESH_OTP:
            if (state.otpState === data.otpState) {
                return state;
            }
            accountInfo = { ...state, ...data };
            break;

        case TYPE_ACCOUNT_REFRESH_REFERRAL_CODE:
            if (state.referralCode === data.referralCode) {
                return state;
            }
            accountInfo = { ...state, ...data };
            break;

        case TYPE_ACCOUNT_REFRESH_API_KEY:
            if (state.apiKeys === data.apiKeys) {
                return state;
            }
            if (!state.apiKeys || !data.apiKeys || state.apiKeys.length !== data.apiKeys.length) {
                accountInfo = { ...state, ...data, isInitialized: true };
                break;
            }
            let oldKeyInfoList = state.apiKeys.map((apiKey) => {
                return { keyId: apiKey.key_id, market: apiKey.exchange };
            });
            let keyInfoList = data.apiKeys.map((apiKey) => {
                return { keyId: apiKey.key_id, market: apiKey.exchange };
            });

            const changedList: Array<any> = _.differenceBy(oldKeyInfoList, keyInfoList, (keyInfo: { keyId: string; market: string }) => {
                return keyInfo.keyId.concat(keyInfo.market);
            });
            if (changedList.length > 0) {
                accountInfo = { ...state, ...data };
                break;
            }
            return state;

        case TYPE_ACCOUNT_ADD_API_KEY: {
            let apiKeys: APIKeyInfo[] = [];
            if (state.apiKeys && state.apiKeys.length > 0) {
                let existed = false;
                for (let apiKey of state.apiKeys) {
                    if (apiKey.key_id === data.key_id) {
                        existed = true;
                        break;
                    }
                }
                if (existed) {
                    return state;
                }
                apiKeys.push(...state.apiKeys);
            }
            apiKeys.push(data);
            accountInfo = { ...state, apiKeys: apiKeys };
            break;
        }

        case TYPE_ACCOUNT_REMOVE_API_KEY: {
            if (!state.apiKeys || state.apiKeys.length === 0) {
                return state;
            }
            let apiKeys: APIKeyInfo[] = [];
            for (let apiKey of state.apiKeys) {
                if (apiKey.key_id !== data.key_id) {
                    apiKeys.push(apiKey);
                }
            }
            accountInfo = { ...state, apiKeys: apiKeys };
            break;
        }

        case TYPE_ACCOUNT_UPDATE_NICKNAME:
        case TYPE_ACCOUNT_UPDATE_AVATAR:
            accountInfo = { ...state, ...data };
            break;

        case TYPE_ACCOUNT_PROFIT_MODEL: {
            if (state.profitModel === data.profitModel) {
                return state;
            }
            accountInfo = { ...state, ...data };
            break;
        }
        case TYPE_ACCOUNT_FSA_RISK_PLAN: {
            accountInfo = { ...state, fsaRiskPlanStatus: action.data.fsaRiskPlanStatus };
            break;
        }

        case TYPE_ACCOUNT_PASS_LCE: {
            accountInfo = { ...state, passLCExam: action.data.passLCExam };
            break;
        }

        case TYPE_ACCOUNT_MEMBERSHIP: {
            accountInfo = { ...state, membership: action.data.membership };
            break;
        }
        case TYPE_ACCOUNT_REBATE_LEVEL: {
            accountInfo = { ...state, rebateLevel: action.data.rebateLevel };
            break;
        }

        default:
            return state;
    }
    LocalStorageUtils.setAccountInfo(accountInfo);
    if (accountInfo.userId && window["qimoClientId"]) {
        window["qimoClientId"].userId = accountInfo.userId;
    }
    return calcAccountData(accountInfo);
}
