export default (path = "/login", paramname = "nextstep") => {
    const url = (window.location.href.match(/^https?\:\/{2}[^\/]*(.*)/) || [])[1];
    const pathName = window.location.pathname;
    const params = window.location.search;
    // 需要过滤的路由
    const urlFilter = ["/login", "/register", "/signIn"];
    let loginUrl;
    if (
        urlFilter.some((val) => {
            return pathName === val;
        })
    ) {
        loginUrl = path + params;
    } else {
        loginUrl = `${path}?${paramname}=${encodeURIComponent(url)}`;
    }
    return loginUrl;
};
