import React from "react";
import AssetDisplay from "../AssetDisplay";
import OrderBalancePie from "landings/Balance/components/TradingAccounnt/OrderBalancePie";
import css from "landings/Balance/components/TradingAccounnt/index.module.less";
import { Balance } from "../Balance/finance";
import { useTranslators } from "src/commonUse/Locale";
import cs from "../TradeAccount/cs.m.less";

export interface IFinanceAccount {
    onLoading: boolean;
    financeTotalDisplay: string;
    financeTodayProfitDisplay: string;
    financeTodayProfitColor: string;
}
const FinanceAccount = ({ onLoading, financeTotalDisplay, financeTodayProfitDisplay, financeTodayProfitColor }: IFinanceAccount) => {
    const { $st } = useTranslators();
    return (
        <div>
            <div className={css.wrapper}>
                <AssetDisplay
                    title={$st("balance_seprate_finance_title")}
                    balanceTotal={financeTotalDisplay}
                    todayProfitDisplay={financeTodayProfitDisplay}
                    todayProfitColor={financeTodayProfitColor}
                />
                <OrderBalancePie walletType="finance" />
            </div>
            <Balance className={cs.balance} onLoading={onLoading} />
        </div>
    );
};

export default FinanceAccount;
