import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MessageHandler, SnsWebSdkBaseConfig } from "@sumsub/websdk";
import SumsubWebSdk from "@sumsub/websdk-react";
import { TModal, TSpin } from "components/v2/TinyComps";
import cs from "./cs.m.less";
import { useTranslators } from "commonUse/Locale";
import { LANGUAGE_LOCALS_MAP } from "utils/i18n/index";
import useMedia from "src/components-v2/Layout/hooks/useMedia";
import { useCircleDebitAPI } from "../../context";
import { message } from "antd";
import { useCallbackStatic } from "commonUse/tools";
import { CardVerifyInfo } from "landings/V2/CircleDebit/types";

interface Props {
    visible?: boolean;
    onCancel?: () => void;
    onSuccess?: () => void;
    verifyInfo: CardVerifyInfo;
}

/**
 * sumsub 认证弹窗
 */
const SumsubVerifyModal: React.FC<Props> = ({ visible, onCancel, verifyInfo: { cardId, isRequired = false }, onSuccess }) => {
    const [accessToken, setAccessToken] = useState("");
    // 控制用户打开后，不能立即关闭。
    // const [userCloseable, setCloseable] = useState(!isRequired);
    const [loading, setLoading] = useState(false);
    const circleDebitAPI = useCircleDebitAPI();
    const getAccessToken = useCallback(() => {
        if (!circleDebitAPI) return Promise.resolve("");
        return circleDebitAPI
            ?.getSumsubVerifyAccessToken({ card_id: cardId })
            .toPromise()
            .then((res) => res.token)
            .catch((e) => {
                message.error(e.message);
                throw e;
            });
    }, [cardId, circleDebitAPI]);

    useEffect(() => {
        setLoading(true);
        getAccessToken().then(setAccessToken);
    }, []);

    const errorHandler = useCallback(() => {}, []);

    const {
        $st,
        intl: { locale, messages },
    } = useTranslators();

    const config = useMemo(() => {
        let lang = locale;
        /**
         * 受支持的语言信息
         * https://developers.sumsub.com/faq/#sdk-functionality
         */
        switch (locale) {
            case LANGUAGE_LOCALS_MAP.zhCN:
                lang = "zh";
                break;
            case LANGUAGE_LOCALS_MAP.enUS:
                lang = "en";
                break;
            case LANGUAGE_LOCALS_MAP.zhTW:
                lang = "zh-tw";
                break;
        }
        const sidePadding = 0;
        return {
            lang, //language of WebSDK texts and comments (ISO 639-1 format)
            // email: 'applicantEmail',
            // phone: 'applicantPhone',
            i18n: {
                ...messages,
                components: {
                    uploadDocument: {
                        title: {
                            bankCard: $st("sumsub_uploadDocument"),
                        },
                    },
                },
            }, // JSON of custom SDK Translations
            uiConf: {
                customCssStr: `
                .sumsub-logo {display: none;}
                .iframe2 {margin: 0;}
                .iframe2 .content {box-shadow: none;padding: 50px ${sidePadding}px;margin:0 auto;border-radius: 16px;}
                .iframe2 .desktop + .content {padding: 20px ${sidePadding}px;}
                `,
                // customCss: `${staticOrigin}/static/sumsub.css`,
                // URL to css file in case you need change it dynamically from the code
                // the similar setting at Customizations tab will rewrite customCss
                // you may also use to pass string with plain styles `customCssStr:`
            },
        } as SnsWebSdkBaseConfig;
    }, [$st, locale, messages]);

    const messageHandler: MessageHandler = useCallbackStatic((type, payload) => {
        // console.log(type, payload);
        // https://developers.sumsub.com/web-sdk/#websdk-messages
        switch (type) {
            case "idCheck.onReady": {
                setLoading(false);
                break;
            }
            case "idCheck.stepCompleted":
                if (isRequired)
                    // 用户完成提交后，让用户可以关闭窗口
                    // setCloseable(true);
                    break;
        }
    });
    return (
        <TModal className={cs.modal} width={650} visible={visible} closable footer={null} onCancel={onCancel}>
            <TSpin spinning={loading}>
                <div className={cs.sumsubVerifyModalLoading}>
                    {accessToken && (
                        <SumsubWebSdk
                            accessToken={accessToken}
                            expirationHandler={getAccessToken}
                            config={config}
                            // options={options}
                            onMessage={messageHandler}
                            onError={errorHandler}
                        />
                    )}
                </div>
            </TSpin>
        </TModal>
    );
};

export default SumsubVerifyModal;
