import { BeneficiaryBankInfoData, BeneficiaryInfoData } from "compliance_lib/src/circle/types";
import { AddBankCardParams, BankCardInfo } from "compliance_lib";
import { IVerificationNams } from "landings/DepositWithdraw/components/use";

/**
 * 入金记录
 */
export interface DepositHistoryInfo {
    orderId: string; // 入金订单ID
    createTime: number; // 毫秒级时间戳，订单的创建时间
    remitTime: number; // 毫秒级时间戳，退款，财务汇出时间
    depositTime: number; // 毫秒级时间戳，入金上账时间
    amount: string; // 浮点数，上账金额
    fee: string; // 浮点数，入金的手续费
    realAmount: string; // 浮点数，Signature Bank用户汇入金额、财务退款金额 amount + fee
    status: DepositStatus;
    approvalReason: string; // 订单审批原因
}

/**
 * 入金状态
 */
export enum DepositStatus {
    PENDING = "pending",
    SUCCESS = "success",
    FAIL = "fail",
}

/**
 * 出金记录
 */
export interface WithdrawHistoryInfo {
    orderId: string; // 出金订单ID
    createTime: number; // 毫秒级时间戳，订单的创建时间
    remitTime: number; // 毫秒级时间戳，财务汇出时间
    amount: string; // 浮点数，下账金额，用户申请出金金额
    fee: string; // 浮点数，出金的手续费
    realAmount: string; // 浮点数，财务实际汇出金额 amount - fee
    status: WithdrawStatus;
    approvalReason: string; // 订单审批原因
    transferType: "ach" | "wire";
}

/**
 * 入金状态
 */
export enum WithdrawStatus {
    PENDING = "pending",
    SUCCESS = "success",
    FAIL = "fail",
    PAID = "paid",
}

/**
 * 入金转账目标银行信息
 */
export interface BeneficiaryInfo {
    beneficiary: BeneficiaryInfoData; //收款账户信息
    beneficiaryBank: BeneficiaryBankInfoData; //收款账户信息
    depositMessage?: string | undefined;
}

export type BeneficiaryRes = { accountIdentifier: string; banks?: BeneficiaryInfo[] };

export type SignatureBankCard = Omit<BankCardInfo, "beneficiary" | "beneficiaryBank"> & { transferType?: SignatureTransferType };

export enum SignatureTransferType {
    ACH = "ach",
    WIRE = "wire",
}

export type WithdrawParams = { bankId?: string; amount: string; bank?: AddBankCardParams; fileIds?: string };
