import { Form, Input, message } from "@pionex-web-kit/components";
import React from "react";
import { useVirtualMode } from "landings/V2/BuyCrypto/hooks";
import { useTranslators } from "commonUse/Locale";
import { useGetLimit } from "landings/Trade/Swap/tool";
import { PaymentMethod } from "landings/V2/BuyCrypto/Buy/cards";
import cs from "../index.m.less";
import { FORM_KEY_AMOUNT, PayMentMethod } from "landings/V2/BuyCrypto/components/Form";
import { useBalanceTradeByBaseQuote } from "commonUse/useBalance";
import { CoinIcon } from "components/CoinIcon";
import { NumberCommonUtils, SafeDecimal } from "trade_utils_lib";

const AMOUNT_LEAST = 2;

interface Props {
    amountLimit: ReturnType<typeof useGetLimit>;
    currentPayMethodInfo?: PaymentMethod;
    handleAmountCgange: (v) => void;
    amount: string;
    isSelect?: string;
}

/**
 * 填写amount的FormItem
 */
const AmountItem: React.FC<Props> = ({ amountLimit, currentPayMethodInfo, handleAmountCgange, amount, isSelect }) => {
    const { $st } = useTranslators();
    const virtualMode = useVirtualMode();
    const [{ baseAvailable: usdAvailable }] = useBalanceTradeByBaseQuote("USD", "USDT"); // 原始的余额
    return (
        <div>
            <div className="pt-12px">{$st("buy_crypto_pay")}</div>
            <div className="flex flex-row justify-between">
                <Form.Item
                    className="mb-0"
                    name={FORM_KEY_AMOUNT}
                    rules={[
                        {
                            required: true,
                            validator: (_, value) => {
                                if (virtualMode || isSelect !== PayMentMethod.balance) {
                                    // 体验模式下，不报错
                                    if (isSelect === PayMentMethod.bank_account) {
                                        const vAmount = Number(value);
                                        if (isNaN(vAmount) || vAmount < Math.max(AMOUNT_LEAST, amountLimit?.min || 0)) {
                                            return Promise.reject($st("buy_crypto_amount_least", { least: AMOUNT_LEAST }));
                                        }
                                    }
                                    return Promise.resolve();
                                }

                                const vAmount = Number(value);
                                if (isNaN(vAmount) || vAmount < Math.max(AMOUNT_LEAST, amountLimit?.min || 0)) {
                                    return Promise.reject($st("buy_crypto_amount_least", { least: AMOUNT_LEAST }));
                                }

                                if (vAmount > (amountLimit?.max || Number.MAX_VALUE)) {
                                    return Promise.reject($st("buy_crypto_amount_exceeded", { max: amountLimit?.max }));
                                }

                                return !!currentPayMethodInfo ? currentPayMethodInfo.amountValidator(vAmount) : Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input
                        className={cs.amount}
                        value={amount}
                        size="large"
                        type="number"
                        placeholder={$st("buy_crypto_input_amount_placeholder")}
                        precision={2}
                        prefix={<span className="text-accent font-b">$</span>}
                        onChange={(e) => {
                            handleAmountCgange(e.target.value?.replace(/(^-?\d{0,5})\d*(\.\d{0,2})?\d*/, "$1$2"));
                        }}
                        autoFocus
                    />
                </Form.Item>
                <div className="flex flex-row items-center">
                    {isSelect === PayMentMethod.balance && (
                        <div
                            className="bg-bg-300 rounded-[12px] py-[8px] px-[12px] h-[40px] mr-[10px] cursor-pointer"
                            onClick={() => {
                                handleAmountCgange(NumberCommonUtils.formatterDecimalToStr(usdAvailable, 2));
                            }}
                        >
                            {$st("swap_max")}
                        </div>
                    )}
                    <div className={"flex pl-[10px]  items-center font-b text-lg h-[52px] w-[102px] bg-bg-100 rounded-12px"}>
                        <div className="flex items-center">
                            <CoinIcon target={"USD"} className="mr-8px !w-24px !h-24px" />
                            {$st("balance_usd")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AmountItem;
