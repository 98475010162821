import { HostConfigV1 } from "./index";
import Constants from "utils/Constants";

export default abstract class HostConfigV2 extends HostConfigV1 {
    static get tradeRPCSignHost() {
        if (process.env.NODE_ENV === "development") {
            return Constants.isBeta ? "https://v2.pionexusdev.com/api" : "https://www.pionex.us/api";
        }
        return `${Constants.origin}/api`;
    }

    static get webSocketHost(): string {
        if (Constants.isBeta) {
            return "wss://pionex-ticker-websocket.devus.pionexusdev.com";
        }
        return Constants.isStaging ? "wss://stream-pre.pionex.us" : "wss://stream.pionex.us";
    }
}
