import { ExchangeOrder } from "TradeAPILib";
import { Observable, of, Subject } from "rxjs";
// import { setTheme, setTickerReversed } from "state/ThemeData";

interface WebViewEventData {
    eventName: WebViewEventName;
    data?: any;
}

export enum AppPage {
    SignIn = "SignIn",
    SignUp = "SignUp",
    PIONEX = "PIONEX", // Home
    MARKET = "MARKET", // Market
    TRADE = "TRADE", // Bot
    BUY_SELL = "BUY_SELL", // Buy/Sell
    ACCOUNT = "ACCOUNT", // Account
    KYCLevelOneApplyUS = "KYCLevelOneApplyUS",
}

export interface WebViewNavigateData {
    page: AppPage;
    params?: any;
    replace?: boolean;
    closeWebView?: boolean;
}

export enum WebViewEventName {
    closeWebView = "closeWebView",
    // 新加的事件都以wv(WebView)开头，避免冲突
    baseInfo = "wvBaseInfo",
    accountInfo = "wvAccountInfo",
    navigate = "wvNavigate",
    signOut = "wvSignOut",
    openURL = "wvOpenURL",
    setTitle = "wvSetTitle",
    onBack = "wvOnBack",
    resetMfaKyc = "wvResetMfaKyc", // 重置账号多重验证，调起sumsub kyc界面
    refreshAccount = "wvRefreshAccount",
    signInV2 = "wvSignInV2",
    migrateApply = "wvMigrateApply",
    reCreateOrder = "wvReCreateOrder",
}

function postMessage(eventName: WebViewEventName, data?: any) {
    setTimeout(() => {
        window.ReactNativeWebView?.postMessage?.(JSON.stringify({ eventName, data }));
    }, 0);
}

export class ReactNativeWebView {
    public static shared = new ReactNativeWebView();

    private readonly webViewEventSubject = new Subject<WebViewEventData>();
    private readonly appVersionSubject = new Subject<number>();
    private useAppLocale = false;
    private appVersion?: number;

    private constructor() {
        const isAndroid = navigator.userAgent && navigator.userAgent.toLowerCase().indexOf("android") >= 0;
        if (isAndroid) {
            document.addEventListener("message", this.eventListener as any);
        } else {
            window.addEventListener("message", this.eventListener, true);
        }
    }

    private eventListener = (event: MessageEvent) => {
        if (!event.data || typeof event.data !== "string") {
            return;
        }
        try {
            const eventData: WebViewEventData = JSON.parse(event.data);
            const { eventName, data } = eventData;
            if (!eventName) {
                return;
            }
            if (eventName === WebViewEventName.baseInfo) {
                this.onReceiveBaseInfo(data);
            } else if (eventName === WebViewEventName.accountInfo) {
                // AccountManager.shared.initFromWebView({
                //     token: data.token,
                //     accountInfo: data.accountInfo,
                // });
            }
            this.webViewEventSubject.next(eventData);
        } catch {}
    };

    private onReceiveBaseInfo(data) {
        const { locale, theme, tickerReversed, version } = data;
        // if (this.useAppLocale && locale && LocaleConfigMap[locale]?.support) {
        //     setLocale(locale);
        // }
        if (theme) {
            // setTheme(theme);
        }
        if (tickerReversed !== undefined) {
            // setTickerReversed(tickerReversed);
        }
        if (version) {
            this.appVersion = version;
            this.appVersionSubject.next(version);
        }
    }

    public isWebView() {
        return !!window.ReactNativeWebView;
    }

    public init() {
        if (!this.isWebView()) {
            return;
        }
        // const match = (window as any)?.piData?.pathname?.match(/^\/([^\/ ]+)/);
        // const urlLocal = match ? match[1] : "";
        // this.useAppLocale = !urlLocal || !LocaleConfigMap[urlLocal]?.support;

        setTimeout(() => {
            postMessage(WebViewEventName.baseInfo);
            postMessage(WebViewEventName.accountInfo);
        }, 0);
    }

    public subscribeEventData(observer: (webViewEventData: WebViewEventData) => void) {
        return this.webViewEventSubject.subscribe({
            next: observer,

            error: (error) => {
                console.error("Unhandled error in subscribe: ", error);
            },
        });
    }

    public navigate(data: WebViewNavigateData) {
        postMessage(WebViewEventName.navigate, data);
    }

    public closeWebView() {
        postMessage(WebViewEventName.closeWebView);
    }

    public signOut() {
        postMessage(WebViewEventName.signOut);
    }

    public openURL(url: string) {
        postMessage(WebViewEventName.openURL, { url });
    }

    public setTitle(title: string) {
        postMessage(WebViewEventName.setTitle, { title });
    }

    public resetMfaKyc(data) {
        postMessage(WebViewEventName.resetMfaKyc, data);
    }

    public refreshAccountInfo() {
        postMessage(WebViewEventName.refreshAccount);
    }
    public signInV2(data: { token: string }) {
        postMessage(WebViewEventName.signInV2, data);
    }
    public migrateApply() {
        postMessage(WebViewEventName.migrateApply);
    }
    public reCreateOrder(data: ExchangeOrder) {
        postMessage(WebViewEventName.reCreateOrder, data);
    }
    public getAppVersion(): Observable<number> {
        if (!this.isWebView()) {
            return of(0);
        }
        if (this.appVersion) {
            return of(this.appVersion);
        }
        return new Observable((observer) => {
            const subscription = this.appVersionSubject.subscribe({
                next: (version) => {
                    observer.next(version);
                },

                error: (error) => {
                    console.error("Unhandled error in subscribe: ", error);
                },
            });
            observer.add(() => {
                subscription.unsubscribe();
            });
        });
    }
}
