import Constants from "utils/Constants";

export const IconManager = {
    convertToUriName(name: string): string {
        return name.toLowerCase().replace(/[.]|[+]|[ ]|[/]|[?]|[%]|[#]|[=]|[\\[]|[]]/g, "_");
    },
    getMarketIconUri(market: string) {
        return `${Constants.iconServerHost}/exchange/${IconManager.convertToUriName(market)}_3x.png`;
    },
    getCoinIconUri(coin: string): string {
        return `${Constants.iconServerHost}/coin/${IconManager.convertToUriName(coin)}_3x.png`;
    },
};
