import AppState from "state/AppState";
import getChartThemeDark from "state/ChartThemeDark";
import LocalStorageUtils from "utils/LocalStorageUtils";

export const TICKER_COLOR_GREEN = "#00B070";
export const TICKER_COLOR_RED = "#F04848";

export const TICKER_COLOR_GREEN_BG = "rgb(0,128,0,.1)";
export const TICKER_COLOR_RED_BG = "rgb(255,0,0,.1)";

const TRADING_VIEW_STYLE_PATH = PLATFORM.PIONEX_CN ? "//static.junhuizixun.cn/trading_view/cn_v1/charting_library/" : "../";
const TRADING_VIEW_STYLE_VERSION = "20190819";

export enum ThemeName {
    light = "normal",
    dark = "dark",
}

export default interface ThemeData {
    name: ThemeName;
    reversed: boolean;
    colorIncrease: string;
    colorDecrease: string;
    colorDecreaseBg: string;
    colorIncreaseBg: string;
    colorPrimary: string;
    colorAccent: string;
    colorAccent2: string;
    windowBackground: string;
    windowBackground2: string;
    colorBackground: string;
    colorBackground2: string;
    colorBackground3: string;
    colorText: string;
    colorText2: string;
    colorText5: string;
    colorText6: string;
    colorTextAccent: string;
    colorTextAccentWalk: string;
    colorTextHint: string;
    colorDivider: string;
    colorDivider2: string;
    dialogTitleBackground: string;
    colorDialogTextChecked: string;
    colorLoading: string;
    colorNav: string;
    colorWarning: string;
    colorFavor: string;
    colorBorder: string;
    colorBorder2: string;
    separator: string;
    colorEdit: string;
    colorBlue: string;
    increaseTextHeavy: string;
    tradingViewCssPath: string;
    chartTheme: any;
    indexCardTextColor: string;
    buLogoimgsvg: string;

    hoverTablesHoverBg: string;
    BotContentBg: string;
    warning: string;
    popover: string;
    inputBorder: string;
    c_C9CCD1_454B57: string;

    /**
     * 是否为暗色模式
     */
    dark: boolean;
    /**
     * 是否为红涨绿跌
     */
    primary: string;
    green: string;
    red: string;
    accent: string;
    accentSub: string;
    secondary: string;
    secondarySub: string;
    divider: string;
    listRow: string;
    card: string;
    dropDown: string;
    bg: string;
    increase: string;
    decrease: string;
}

export function createThemeData(name: ThemeName, reversed: boolean): ThemeData {
    const colorIncrease = reversed ? TICKER_COLOR_RED : TICKER_COLOR_GREEN;
    const colorDecrease = reversed ? TICKER_COLOR_GREEN : TICKER_COLOR_RED;
    const colorIncreaseBg = reversed ? TICKER_COLOR_RED_BG : TICKER_COLOR_GREEN_BG;
    const colorDecreaseBg = reversed ? TICKER_COLOR_GREEN_BG : TICKER_COLOR_RED_BG;
    let theme;

    if (name === ThemeName.light) {
        const newTheme = {
            name: ThemeName.light,
            dark: false,
            reversed,
            primary: "#ff7028",
            green: "#00b070",
            red: "#f04848",
            accent: "#292c33",
            accentSub: "#c5cbd6",
            secondary: "#9ea6b8",
            secondarySub: "#c5cbd6",
            divider: "#e4e7ed",
            listRow: "#fafbfc",
            card: "#fff",
            dropDown: "#fff",
            bg: "#f2f3f5",
            warning: "#f0ba30",
            increase: reversed ? "#f04848" : "#00b070",
            decrease: reversed ? "#00b070" : "#f04848",
        };
        theme = {
            ...newTheme,
            name: name,
            reversed: reversed,
            colorIncrease: colorIncrease,
            colorDecrease: colorDecrease,
            colorDecreaseBg,
            colorIncreaseBg,
            colorPrimary: "#1F3267",
            colorAccent: "#5793F1",
            colorAccent2: "#FF6F2C",
            windowBackground: "#F7F7F9",
            windowBackground2: "#F7F7F9",
            colorBackground: "#FFFFFF",
            colorBackground2: "#FFFFFF",
            colorBackground3: "#FFFFFF",
            colorText: "#858CA3",
            colorText2: "#576282",
            colorText5: "#999999",
            colorText6: "#052337",
            colorTextAccent: "#333333",
            colorTextAccentWalk: "#454D5E",
            colorTextHint: "#A9AEBE",
            colorDivider: "#ECECEC",
            colorDivider2: "#ECECEC",
            dialogTitleBackground: "#FFFFFF",
            colorDialogTextChecked: "#1F3267",
            colorLoading: "#00000077",
            colorNav: "#F2F2F2",
            colorWarning: "#E97042",
            colorFavor: "#FFA744",
            colorBorder: "#D9D9D999",
            colorBorder2: "#CCCCCC",
            separator: "#F2F2F2",
            colorEdit: "#1A274C",
            colorBlue: "#4d6ba6",
            increaseTextHeavy: "#1db773",
            indexCardTextColor: "#666666",
            hoverTablesHoverBg: "#f9f9f9",
            BotContentBg: "#FCFCFC",
            warning: "#DB4D4D",
            popover: "#FFFFFF",
            inputBorder: "#F0F0F2",
            c_C9CCD1_454B57: "#C9CCD1",
        };
    } else {
        const newTheme = {
            name: ThemeName.dark,
            dark: true,
            reversed,
            primary: "#ff7028",
            green: "#00b070",
            red: "#f04848",
            accent: "#f2f3f5",
            accentSub: "#828894",
            secondary: "#626773",
            secondarySub: "#434852",
            divider: "#353942",
            listRow: "#1a1e24",
            card: "#1e2229",
            dropDown: "#282c33",
            bg: "#121519",
            warning: "#f0ba30",
            increase: reversed ? "#f04848" : "#00b070",
            decrease: reversed ? "#00b070" : "#f04848",
        };
        theme = {
            ...newTheme,
            name: name,
            reversed: reversed,
            colorIncrease: colorIncrease,
            colorDecrease: colorDecrease,
            colorDecreaseBg,
            colorIncreaseBg,
            colorPrimary: "#242833",
            colorAccent: "#5793F1",
            colorAccent2: "#FF6F2C",
            windowBackground: "#191C26",
            windowBackground2: "#1F232D",
            colorBackground: "rgb(26, 30, 36)",
            colorBackground2: "#191C26",
            colorBackground3: "#242833",
            colorText: "#858CA3",
            colorText2: "#FFFFFF",
            colorText5: "#858CA3",
            colorText6: "#FFFFFF",
            colorTextAccent: "#F2F2F2",
            colorTextAccentWalk: "#B2B8C4",
            colorTextHint: "#A9AEBE",
            colorDivider: "#151820",
            colorDivider2: "#343640",
            dialogTitleBackground: "#242833",
            colorDialogTextChecked: "#5793F1",
            colorLoading: "#FFFFFF77",
            colorNav: "#272C39",
            colorWarning: "#E97042",
            colorFavor: "#FF9B2B",
            colorBorder: "#D9D9D900",
            colorBorder2: "#191c26",
            separator: "#343640",
            colorEdit: "#191C26",
            colorBlue: "#4d6ba6",
            increaseTextHeavy: "#25CF85",
            indexCardTextColor: "#fff",
            hoverTablesHoverBg: "#f9f9f9",
            BotContentBg: "#191C26",
            warning: "#DB4D4D",
            popover: "#303542",
            inputBorder: "#393E47",

            c_C9CCD1_454B57: "#454B57",
        };
    }
    theme.chartTheme = getChartThemeDark(theme);
    theme.tradingViewCssPath = `${TRADING_VIEW_STYLE_PATH}style.${name}.${TRADING_VIEW_STYLE_VERSION}.css`;

    return theme;
}

const TYPE_THEME_CHANGE_THEME = "type_theme_change_theme";
const TYPE_THEME_REVERSE_TICKER = "type_theme_reverse_ticker";

export function themeReducer(state = createThemeData(LocalStorageUtils.themeName, LocalStorageUtils.tickerReversed), action) {
    switch (action.type) {
        case TYPE_THEME_CHANGE_THEME:
            if (action.data === state.name) {
                return state;
            }
            LocalStorageUtils.setThemeName(action.data);
            return createThemeData(action.data, state.reversed);
        case TYPE_THEME_REVERSE_TICKER:
            if (action.data === state.reversed) {
                return state;
            }
            LocalStorageUtils.seTickerReversed(action.data);
            return createThemeData(state.name, action.data);
        default:
            return state;
    }
}

export function setTickerReversed(reversed: boolean) {
    AppState.store.dispatch({ type: TYPE_THEME_REVERSE_TICKER, data: reversed });
    const list = window.document.documentElement.classList;
    if (reversed) {
        list.add("ticker-reversed");
        list.remove("ticker");
    } else {
        list.remove("ticker-reversed");
        list.add("ticker");
    }
}

export function setTheme(themeName: ThemeName) {
    AppState.store.dispatch({ type: TYPE_THEME_CHANGE_THEME, data: themeName });
    const list = window.document.documentElement.classList;

    if (themeName === ThemeName.dark) {
        list.add("dark");
        list.remove("light");
        list.add("theme-dark");
        list.remove("theme-light");
    } else {
        list.add("light");
        list.remove("dark");

        list.add("theme-light");
        list.remove("theme-dark");
    }
}

export function hex2rgba(hex, alpha = 1) {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
}
