import Compressor from "compressorjs";
import SparkMD5 from "spark-md5";

export interface UploadResult {
    filename: string;
    post: {
        url: string;
        fields: {
            "Content-Type": string;
            key: string;
            policy: string;
            "x-amz-algorithm": string;
            "x-amz-credential": string;
            "x-amz-date": string;
            "x-amz-signature": string;
        };
    } | null;
}

export function calcMD5(file: File): Promise<{ md5: string; originFile: File }> {
    let chunkSize = 2097152;
    let chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    let spark = new SparkMD5.ArrayBuffer();
    let fileReader = new FileReader();

    //分次读取大文件的内容，
    function loadNext() {
        let start = currentChunk * chunkSize;
        let end = start + chunkSize >= file.size ? file.size : start + chunkSize;
        fileReader.readAsArrayBuffer(file.slice(start, end));
    }

    loadNext();
    return new Promise((resolve) => {
        fileReader.onload = (e) => {
            //对于读取的文件计算hash码。
            spark.append(e.target!.result);
            currentChunk++;
            if (currentChunk < chunks) {
                loadNext();
            } else {
                resolve({ md5: spark.end(), originFile: file });
            }
        };
    });
}

export function compressFile(file: File, options?: Compressor.Options): Promise<File> {
    const filesize = file.size / 1024 / 1024;
    // 如果指定压缩质量就按指定的值来压缩
    const defaultQuality = options?.quality;
    let quality;
    if (defaultQuality) {
        quality = defaultQuality;
    } else {
        if (filesize < 2) {
            quality = 1;
        } else if (filesize < 6) {
            quality = 0.8;
        } else if (filesize < 8) {
            quality = 0.7;
        } else {
            quality = 0.6;
        }
    }
    return new Promise((resolve, reject) => {
        return new Compressor(file, {
            ...options,
            quality,
            success(file: File) {
                resolve(file);
            },
            error(error: Error) {
                reject(error);
            },
        });
    });
}
