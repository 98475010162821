import { $st } from "utils/i18n/trans.comp";
import { combineError } from "utils/errorCode/common";

/**
 * @deprecated
 * 老的错误码和提示文案key的映射
 */
const OLD_MSG_MAP: Record<string, string> = {
    TAPI_WALLET_BELOW_MIN_LIMIT: "tapi_wallet_below_min_limit",
    TAPI_WALLET_ABOVE_MAX_LIMIT: "tapi_wallet_above_max_limit",
    TAPI_WALLET_ABOVE_DAILY_LIMIT: "tapi_wallet_above_daily_limit",
    TAPI_WALLET_BELOW_WITHDRAW_FEE: "tapi_wallet_below_withdraw_fee",
    TAPI_WALLET_AMOUNT_ILLEGAL: "tapi_wallet_amount_illegal",
    TAPI_WALLET_BALANCE_NOT_ENOUGH: "tapi_wallet_balance_not_enough",
    TAPI_WALLET_OPERATE_DB: "tapi_wallet_operate_db_failed",
    TAPI_WALLET_PARAMETER_ERROR: "tapi_wallet_parameter_error",
    TAPI_WALLET_COIN_ADDRESS_ABNORMAL: "tapi_wallet_coin_address_illegal",
    TAPI_WALLET_EXIST_WITHDRAW_TASK: "tapi_wallet_exist_withdraw_task",
    TAPI_WALLET_DEPOSIT_NOT_CONFIRM: "tapi_wallet_deposit_not_confirm",
    "code not exists": "withdraw_code_not_exist",
    "invalid code": "withdraw_invalid_code",
    TAPI_WALLET_PARAMETER_TYPE_ERROR: "tapi_wallet_parameter_type_error",
    TAPI_WALLET_OVERLOAD: "tapi_wallet_overload",
    TAPI_WALLET_INTERNAL: "tapi_wallet_internal",
    TAPI_WALLET_DESERIALIZATION: "tapi_wallet_deserialization",
    TAPI_WALLET_SERIALIZATION: "tapi_wallet_serialization",
    TAPI_WALLET_RECORD_NOT_EXIST: "tapi_wallet_record_not_exist",
    TAPI_WALLET_CONNECT_DB: "tapi_wallet_connect_db",
    TAPI_WALLET_INVALID_OPERATION: "tapi_wallet_invalid_operation",
    TAPI_WALLET_VERIFY_CODE: "tapi_wallet_verify_code",
    TAPI_WALLET_INVALID_COIN: "tapi_wallet_invalid_coin",
    TAPI_WALLET_REPEAT_REQUEST: "tapi_wallet_repeat_request",
    TAPI_WALLET_INVALID_SUB_ACCOUNT_ID: "tapi_wallet_invalid_sub_account_id",
    TAPI_WALLET_INVALID_WITHDRAW_PRECISION: "tapi_wallet_invalid_withdraw_precision",
    TAPI_WALLET_IN_BLACKLIST: "withdraw_user_in_black_list",
    TAPI_TRADE_WITHDRAW_FORBIDDEN: "tapi_trade_withdraw_forbidden",
    PROCESSING_ERROR: "Processing error. Please contact the support team.",
};

/**
 * 新的错误码放到这里，然后按指定格式(withdraw_error:xxxxxxx)定义翻译
 */
const NEW_MSG_CODES = ["TAPI_FBO_IN_BLACK_LIST_ERROR"];

export namespace WithdrawError {
    /**
     * 根据服务端返回的msg，查找是否有code对应的翻译
     * @param msg
     */
    export function parseErrorMsg(msg: string): string {
        const oldMsgKey = Object.keys(OLD_MSG_MAP).find((item) => msg.toUpperCase().indexOf(item.toUpperCase()) >= 0);
        if (!!oldMsgKey) {
            return combineError(oldMsgKey, $st(OLD_MSG_MAP[oldMsgKey]));
        }

        const newMsgKey = NEW_MSG_CODES.find((item) => msg.toUpperCase().indexOf(item.toUpperCase()) >= 0);
        if (!!newMsgKey) {
            return combineError(
                newMsgKey,
                $st({
                    id: `withdraw_error:${newMsgKey}`,
                    defaultMessage: msg,
                }),
            );
        }

        return msg;
    }
}
