import { Modal } from "@pionex-web-kit/components";
import { useTranslators } from "commonUse/Locale";
import { useShowUpgradeEntrance } from "commonUse/useV1Token";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router";
import { SafeDecimal } from "trade_utils_lib";
import { history } from "utils/History";
import { report } from "utils/GoogleTagManager";

export default function AccountUpdateModal({ visible, setVisible }: { visible?: boolean; setVisible: React.Dispatch<React.SetStateAction<boolean | undefined>> }) {
    const [showUpgradeEntrance] = useShowUpgradeEntrance();
    const { $st } = useTranslators();
    const { pathname } = useLocation();
    const onClose = useCallback(() => {
        setVisible(false);
    }, [setVisible]);
    const onOpen = useCallback(() => {
        setVisible(true);
    }, [setVisible]);
    useEffect(() => {
        if (/account_update/.test(pathname)) {
            return;
        }
        const openFn = () => {
            const lastPromptTime = new SafeDecimal(sessionStorage.getItem("lastPromptTime")).toNumber();
            if (lastPromptTime && lastPromptTime + 1000 * 60 * 60 > Date.now()) {
                return;
            }
            onOpen();
            sessionStorage.setItem("lastPromptTime", `${Date.now()}`);
        };
        setTimeout(openFn, 2000);
        const timer = setInterval(openFn, 24 * 60 * 60 * 1000);
        return () => {
            clearInterval(timer);
        };
    }, [onOpen]);
    return (
        <>
            {showUpgradeEntrance && (
                <Modal
                    title={$st("account_update")}
                    visible={visible}
                    onCancel={onClose}
                    onOk={() => {
                        report("account_update_popup");
                        onClose();
                        history.push("/balance/account_update");
                    }}
                    okText="Update"
                >
                    {$st("due_to_an_account_system_upgrade")} <br />
                    <br />
                    This is the{" "}
                    <a
                        onClick={() => {
                            report("web_pop_guide_click");
                        }}
                        className="text-primary hover:!text-primary font-m"
                        href="https://www.pionex.us/blog/guide-for-account-upgrade/"
                        target="_blank"
                    >
                        Guide for Account Upgrade
                    </a>
                    .
                </Modal>
            )}
        </>
    );
}
