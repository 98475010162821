import * as React from "react";
import { useEffect, useState, createContext } from "react";
import { of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import AccountInfo from "src/state/AccountInfo";
import ExchangeDataProvider from "src/TradeLib/ExchangeDataProvider";
import { APIKeyInfo } from "src/TradeLib/TradeTypes";
import { useCallbackStatic } from "./tools";
import { ExchangeID, ExchangeSymbol, PionexFeeRate, SymbolInfo, TickPriceInfo } from "TradeAPILib";
import AccountManager from "src/utils/AccountManager";
import Constants from "src/utils/Constants";
import { InviteInfo } from "../landings/ReferralProgram/types";

export * from "./useOpenHelpCenter";
export { default as useHelpCenterFn } from "./useHelpCenterFn.us";
export * from "./useSymbolTransferQuantity";

export interface LeverageSymbol {
    base: string;
    quote: string;
    maxLeverage: number;
}

export const QuoteCurrencyMap = {
    USDT: "Tether",
    HUSD: "HUSD",
    TUSD: "TUSD",
};

// 获取login required ExchangeDataProvider
export function useDataProvider(apiKey: APIKeyInfo | undefined) {
    const [provider, setProvider] = React.useState(undefined as undefined | ExchangeDataProvider);
    React.useEffect(() => {
        if (!apiKey) {
            return;
        }
        // 获取可充提币种列表
        const subscriber = ExchangeDataProvider.getExchangeDataProvider(apiKey).subscribe((dataProvider) => {
            setProvider(dataProvider);
        });
        // unsubscribe when unmount
        return () => subscriber.unsubscribe();
    }, [apiKey]);
    return provider;
}

export const CTXDataProvider = createContext<undefined | ExchangeDataProvider>(undefined);
/**
 * 规避组件层层传入APIKey或dataProvider，以及对dataProvider的重复创建。
 */
export function useDataProviderOnFirstApiKey() {
    return React.useContext(CTXDataProvider);
}

// 获取Pionex的APIKey
export function usePionexKey(accountInfo: AccountInfo) {
    const [pionexApiKey, updataApiKey] = React.useState<undefined | APIKeyInfo>();
    React.useEffect(() => {
        accountInfo && accountInfo.apiKeys && updataApiKey(accountInfo.firstApiKey);
    }, [accountInfo]);
    return pionexApiKey;
}

// 获取login ignored ExchangeDataProvider
export function usePublicDataProvider(exchange: string) {
    return ExchangeDataProvider.getPublicExchangeDataProvider(exchange);
}

export function useSymbolTickerInfo(coinInfo: SymbolInfo): TickPriceInfo {
    const [tickerPriceInfo, setTickerPriceInfo] = React.useState({
        base: coinInfo.base,
        quote: coinInfo.quote,
        latest: 0,
        fiat: 0,
        fullName: "",
        priceChange: 0,
    } as TickPriceInfo);
    React.useEffect(() => {
        let mbq: Array<SymbolInfo> = [coinInfo];
        const subscription = ExchangeDataProvider.getPublicExchangeDataProvider(coinInfo.market)
            .api.queryTickerWithSymbolList(mbq)
            .subscribe(
                (result: Array<TickPriceInfo>) => {
                    if (result.length > 0) {
                        setTickerPriceInfo(result[0]);
                    }
                },
                () => {},
            );

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [coinInfo]);

    return tickerPriceInfo;
}

/*
 * 获取ticker
 */
export function useDWCoinsTickerInfo(coinList: Array<string>, market: string): Map<string, TickPriceInfo> {
    const [tickerMap, setTickerMap] = React.useState(new Map());
    React.useEffect(() => {
        let mbq: Array<SymbolInfo> = [];
        coinList.forEach((coin) => {
            mbq.push({
                market: market,
                base: coin,
                quote: "USDT",
            });
        });
        const subscription = ExchangeDataProvider.getPublicExchangeDataProvider(ExchangeID.BINANCE)
            .api.queryTickerWithSymbolList(mbq)
            .subscribe(
                (result: Array<TickPriceInfo>) => {
                    let tickerResultMap = new Map();
                    result.forEach((tickerInfo) => {
                        let findResult = tickerResultMap.get(tickerInfo.base);
                        if (!findResult) {
                            tickerResultMap.set(tickerInfo.base, tickerInfo);
                        }
                    });

                    setTickerMap(tickerResultMap);
                },
                () => {},
            );

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [coinList, market]);

    return tickerMap;
}

export function useCoinsFullName(coins: Array<string>, exchange: string): Map<string, string> {
    const [fullNameMap, setFullNameMap] = React.useState(new Map());
    const tickerMap = useDWCoinsTickerInfo(coins, exchange);

    React.useEffect(() => {
        let mapTmp = new Map();
        if (tickerMap.size > 0) {
            tickerMap.forEach((value, key) => {
                mapTmp.set(key, value.fullName);
            });
        }
        mapTmp.set("USDT", QuoteCurrencyMap.USDT);
        mapTmp.set("USDTERC20", QuoteCurrencyMap.USDT);
        mapTmp.set("HUSD", QuoteCurrencyMap.HUSD);
        mapTmp.set("TUSD", QuoteCurrencyMap.TUSD);
        setFullNameMap(mapTmp);
    }, [coins, tickerMap]);

    return fullNameMap;
}

// 将coin全名合并到数据列表中
export function useMixinCoinFullName<T extends {}, K extends string>(
    market: string,
    dataList: T[] | undefined,
    coinKey: keyof T,
    coinNameKey: K,
): [Array<T & Record<K, string>> | undefined, boolean] {
    const [onLoading, updateLoading] = React.useState(true);
    const [fillFullNameList, updateList] = React.useState(undefined as Array<T & Record<K, string>> | undefined);
    const [coinList, updateCoinList] = React.useState<string[]>([]);
    React.useEffect(() => {
        if (dataList !== undefined) {
            updateCoinList(
                dataList.map((item) => {
                    return item[coinKey as string];
                }),
            );
        }
    }, [coinKey, dataList]);
    const tickerMap = useDWCoinsTickerInfo(coinList, market);
    React.useEffect(() => {
        if (dataList !== undefined) {
            // if (dataList.length > 0 && tickerMap.size > 0) {
            updateList(
                dataList.map((item) => {
                    const coin = item[coinKey] as any;
                    const fromMap = tickerMap.get(coin);
                    const fromStatic = QuoteCurrencyMap[coin];
                    return {
                        ...item,
                        [coinNameKey]: fromStatic ? fromStatic : fromMap ? fromMap.fullName : "",
                    } as T & Record<K, string>;
                }),
            );
            updateLoading(false);
        }
        // }
    }, [coinKey, coinNameKey, dataList, tickerMap]);
    return [fillFullNameList, onLoading];
}

// 获取当期那币种的可交易比对
export function useCoinsTrades(exchange: string, coin: string, autoRequest: boolean = false): [ExchangeSymbol[], () => void, boolean] {
    const [currentBaseTradeList, updateTradeList] = React.useState([] as ExchangeSymbol[]);
    const [onLoading, setLoadingState] = React.useState(false);
    const requestTradeList = useCallbackStatic(() => {
        if (onLoading || !(autoRequest && coin && exchange)) {
            return;
        }
        setLoadingState(true);
        return ExchangeDataProvider.getPublicExchangeDataProvider(exchange)
            .getExchangeSymbols()
            .pipe(
                switchMap((allSymbols) => {
                    if (coin === "HUSD") {
                        return of(allSymbols.filter((sym) => sym.base === coin.toUpperCase() || sym.quote === coin.toUpperCase()));
                    } else {
                        return of(allSymbols.filter((sym) => sym.base === coin.toUpperCase()));
                    }
                }),
            )
            .subscribe((tlist) => {
                setLoadingState(false);
                updateTradeList(tlist);
            });
    });
    React.useEffect(() => {
        const subscriber = requestTradeList();
        return () => subscriber && subscriber.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coin, exchange]);

    return [currentBaseTradeList, requestTradeList, onLoading];
}

export function useQueryFeeRate(base: string, quote: string) {
    const [feeRate, changeFeeRate] = React.useState(undefined as undefined | PionexFeeRate);
    const dataProvider = useDataProviderOnFirstApiKey();
    React.useEffect(() => {
        if (!dataProvider || !base || !quote) {
            return;
        }
        const subscription = dataProvider.api.queryPionexFeeRate(base, quote).subscribe(
            (r) => {
                changeFeeRate(r);
            },
            () => {},
        );

        return () => {
            subscription && subscription.unsubscribe();
        };
    }, [base, dataProvider, quote]);

    return feeRate;
}

export function useQueryLeverageSymbol(): LeverageSymbol[] {
    const [result, changeResult] = React.useState([] as LeverageSymbol[]);
    const dataProvider = useDataProviderOnFirstApiKey();
    React.useEffect(() => {
        if (!dataProvider) {
            return;
        }

        // 获取可充提币种列表
        const subscriber = dataProvider.api.queryLeverageSupportedConfig().subscribe(
            (config) => {
                const keys = Object.keys(config);
                const tmp = keys.map((key) => {
                    return {
                        base: key.split("_")[0],
                        quote: key.split("_")[1],
                        maxLeverage: Math.max(...config[key].leverage_ratios),
                    };
                });
                changeResult(tmp);
            },
            (error) => {
                console.log("ERROR useQueryLeverageSymbol", error);
            },
        );
        // unsubscribe when unmount
        return () => subscriber?.unsubscribe();
    }, [dataProvider]);

    return result;
}
