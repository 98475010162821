import React, { useMemo } from "react";
import cs from "./index.module.less";
import csn from "classnames";
import { useTranslators } from "commonUse/Locale";

export interface ResultProps {
    className?: string;
    type?: "success" | "normal" | "fail";
    image?: any;
    title?: string;
    des?: string;
}

/**
 * 页面内的结果展示组件
 */
const ResultInPage: React.FC<ResultProps> = ({ type = "normal", image, title: outTitle, des, className }) => {
    const { $st } = useTranslators();

    const imgSrc = useMemo(() => {
        if (image) return image;
        if (type === "success") {
            return require("images/v2/success.png");
        }
        if (type === "fail") {
            return require("images/v2/fail.png");
        }
        return image;
    }, [image, type]);

    const title = useMemo(() => {
        if (outTitle) return outTitle;
        if (type === "success") {
            return $st("common_successfully");
        }
        if (type === "fail") {
            return $st("common_failed");
        }
        return outTitle;
    }, [outTitle]);

    return (
        <div className={csn(className, cs.container)}>
            <img width="128" height="128" src={imgSrc} alt="" />
            <div className={csn(cs.title, { [cs.title_success]: type === "success" })}>{title}</div>
            {!!des && <div className={cs.des}>{des}</div>}
        </div>
    );
};

export default ResultInPage;
