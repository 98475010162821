import cs from "landings/DepositWithdraw/withdraw.module.less";
import * as React from "react";
import { $st } from "utils/i18n/trans.comp";
import { DWType } from "../..";
import { getCurrencyDisplayName } from "landings/V2/Address/common";
import { SupportDWCoinInfo } from "TradeAPILib";
import { useSpecialTips } from "landings/DepositWithdraw/components/Tips/utils";

export interface IBalanceTipViewProps {
    currentCoin: SupportDWCoinInfo;
    dwType: DWType;
}

export const BalanceTipView = (props: IBalanceTipViewProps) => {
    const { currentCoin, dwType } = props;
    const coinDisplay = React.useMemo(() => {
        return getCurrencyDisplayName(currentCoin);
    }, [currentCoin]);

    const { tipContent, tipBottom } = React.useMemo(() => {
        const memoName = currentCoin.currency === "XRP" ? "Tag" : "Memo";
        let tipContent = "";
        let tipBottom = "";
        if (dwType === "deposit") {
            if (currentCoin.currency === "MATIC") {
                tipContent = $st("deposit_matic_tip_first_line");
                tipBottom = $st("deposit_matic_tip_second_line");
            } else {
                tipContent = $st("balance_tip_content_receive", {
                    coin: coinDisplay,
                    block: currentCoin.tradeConfirmation,
                });
            }
        } else {
            if (currentCoin.currency === "MATIC") {
                tipContent = $st("withdraw_matic_tip_first_line");
            } else {
                tipContent = $st("balance_tip_content_withdraw", { coin: coinDisplay, memo: memoName });
                if (coinDisplay === "BTC") {
                    tipBottom = $st("withdraw_tip_bottom_btc_content") + $st("send_tip_bottom_content");
                } else {
                    tipBottom = $st("send_tip_bottom_content");
                }
            }
        }
        return { tipContent, tipBottom };
    }, [coinDisplay, currentCoin.currency, currentCoin.tradeConfirmation, dwType]);

    const specialTips = useSpecialTips(dwType, currentCoin);

    return (
        <div className={cs.balanceTipWrapper}>
            <div className={cs.tipFont} style={{ fontSize: 14 }}>
                {$st("balance_tip_title")}
            </div>
            <div className={cs.tipFont} style={{ fontSize: 14 }}>
                {tipContent}
                <br />
                {tipBottom}
            </div>
            {specialTips && (
                <div className={cs.tipFont} style={{ fontSize: 14 }}>
                    {specialTips}
                </div>
            )}
        </div>
    );
};
