import React from "react";
import { PaymentMethod } from "landings/V2/BuyCrypto/Buy/cards";
import { useTranslators } from "commonUse/Locale";
import { useToggle } from "commonUse/tools";
import csn from "classnames";
import { Form, FormInstance, useWatch } from "@pionex-web-kit/components";
import { FORM_KEY_PAYMENTMETHOD, PayMentMethod } from "landings/V2/BuyCrypto/components/Form";
import FormItemTransition from "utils/FormItemTransition";
import cs from "landings/V2/BuyCrypto/components/Form/index.m.less";
import { IconDropDownArrow12pxBold } from "@pionex-web-kit/icons";
import SelectPayMethodModal from "landings/V2/BuyCrypto/components/SelectPayMethod";
import { useACHActivity } from "landings/V2/CircleACH/utils";
import { NumberCommonUtils } from "trade_utils_lib";

const PayMethodItem: React.FC<{
    paymentMethods: PaymentMethod[];
    refresh: () => void;
    form: FormInstance<any>;
    amount?: string;
    selectPayMethod?: (v) => void;
    isSelect?: string;
    usdAvailable?: number;
}> = ({ paymentMethods, refresh, form, selectPayMethod, isSelect, usdAvailable }) => {
    const { $st } = useTranslators();
    const [visible, { setTrue: showModal, setFalse: hideModal }] = useToggle();
    const amount = useWatch("amount", form);
    const coin = useWatch("coin", form);
    const activity = useACHActivity("deposit");

    return (
        <>
            <div className={csn("bg-bg-200 rounded-12px p-16px pb-0")}>
                <div className="text-accent text-base">{$st("buy_crypto_select_paymethod")}</div>
                <Form.Item name={FORM_KEY_PAYMENTMETHOD} className="mb-0">
                    <FormItemTransition<string>>
                        {({ value, onChange }) => {
                            return (
                                <>
                                    <div
                                        className={csn(cs.selectChannel, "flex justify-between items-center font-b cursor-pointer text-lg py-12px")}
                                        onClick={() => {
                                            form.validateFields()
                                                .then(() => {})
                                                .catch(() => {});
                                            showModal();
                                        }}
                                    >
                                        {isSelect !== "Bank Account" ? (
                                            <>{isSelect}</>
                                        ) : (
                                            <span>
                                                {$st("nav_title_usd_deposit_ach")} <img className="h-[16px]" src={activity?.image} alt={"activity"} />
                                            </span>
                                        )}
                                        <IconDropDownArrow12pxBold size={16} />
                                    </div>
                                    {isSelect === PayMentMethod.balance && (
                                        <div className="flex flex-row justify-between">
                                            <div className="text-text-300">{$st("buy_crypto_available_balance")}</div>
                                            <div className="text-text-100 mb-[10px]">{NumberCommonUtils.formatterDecimalToStr(usdAvailable, 2)}USD</div>
                                        </div>
                                    )}
                                    <SelectPayMethodModal
                                        isSelect={isSelect}
                                        form={form}
                                        hideModal={hideModal}
                                        selectPayMethod={selectPayMethod}
                                        visible={visible}
                                        paymentMethods={paymentMethods}
                                        selectKey={value}
                                        refresh={refresh}
                                        onCancel={hideModal}
                                        onChange={onChange}
                                        amount={amount}
                                        coin={coin}
                                    />
                                </>
                            );
                        }}
                    </FormItemTransition>
                </Form.Item>
            </div>
        </>
    );
};

export default PayMethodItem;
