import { useTranslators } from "commonUse/Locale";
import useAccountInfo from "commonUse/useAccountInfo";
import * as React from "react";
import { Fragment } from "react";
import { PriceHolder } from "src/components/PriceHolder";
import { DWType, useDWPageContext } from "../..";
import cs from "../../withdraw.module.less";
import { useDWCoinBalance } from "src/commonUse/useDW";
import useKycV2Info, { KycV2Level } from "src/commonUse/useKycInfoV2";
import { Iconfont, TModal, TTooltip } from "components/v2/TinyComps";

interface ICoinDetailProps {
    type: DWType;
}

export function CoinDetail(props: ICoinDetailProps) {
    const { type } = props;
    const { $st } = useTranslators();
    const accountInfo = useAccountInfo();
    const { currentCoin, dailyWithdrawLeft } = useDWPageContext();
    const [coinBalance] = useDWCoinBalance(accountInfo.firstApiKey, currentCoin.coinName, type);
    const [{ currentLevel }] = useKycV2Info();
    return (
        <div style={{ marginTop: 10 }}>
            {type === DWType.deposit && (
                <div className={cs.balanceItemText} style={{ marginTop: 5 }}>
                    <div className={cs.titleText}>
                        {$st("receive_limit_min_amount")}
                        <TTooltip title={$st("receive_limit_min_amount_desc")}>
                            <Iconfont icon="icon_information" />
                        </TTooltip>
                    </div>
                    <div className={cs.titleBold}>
                        <PriceHolder price={currentCoin.limits.deposit.min} />
                    </div>
                </div>
            )}
            <div className={cs.balanceItemText} style={{ marginTop: 5 }}>
                <div className={cs.titleText}>{$st("dw.coin.info.available")}</div>
                <div className={cs.titleBold}>
                    <PriceHolder price={coinBalance.available} />
                </div>
            </div>
            {type === DWType.withdraw && (
                <div className={"text-secondary text-sm"}>
                    {$st("dw_coin_available_tips")}
                    <span
                        className={"text-primary underline cursor-pointer ml-[4px]"}
                        onClick={() =>
                            TModal.info({
                                title: $st("dw_available_tips_title"),
                                content: $st("dw_available_tips_content", { br: <br /> }),
                            })
                        }
                    >
                        {$st("dw_coin_available_tips_more")}
                    </span>
                </div>
            )}

            <div className={cs.balanceItemText} style={{ marginTop: 10 }}>
                <div className={cs.titleText}>{$st("dw.coin.info.balance")}</div>
                <div className={cs.titleBold}>
                    <PriceHolder price={coinBalance.quantity} />
                </div>
            </div>

            {type === DWType.withdraw && (
                <Fragment>
                    <div className={cs.balanceItemText} style={{ marginTop: 10 }}>
                        <div className={cs.titleText}>
                            {[KycV2Level.oldLv2, KycV2Level.oldLv1].includes(currentLevel as any) ? $st("withdraw_limit_text") : $st("send_limit_text1")}
                        </div>
                        <div className={cs.titleBold}>
                            <PriceHolder price={dailyWithdrawLeft} />
                        </div>
                    </div>

                    <div className={cs.balanceItemText} style={{ marginTop: 10 }}>
                        <div className={cs.titleText}>{$st("withdraw.fee.notice")}</div>
                        <div className={cs.titleBold}>
                            <PriceHolder price={currentCoin.fee} />
                        </div>
                    </div>
                    <div className={cs.balanceWarn}>{$st("send_fee_free_warn")}</div>
                </Fragment>
            )}
        </div>
    );
}
