import { QuotaChannel, QuotaDirection } from "utils/complianceQuota";

/**
 * 出入金通道状态枚举值
 */
export enum DWChannelStatus {
    CLOSE = "CLOSE",
    MAINTAIN = "MAINTAIN",
    OPEN = "OPEN",
}

/**
 * 全量出入金通道状态信息
 */
export type DWChannelStatusInfo = Record<QuotaChannel, Record<QuotaDirection, DWChannelStatus>>;

/**
 * 支付方式，法币出入金中，{@link QuotaChannel}的上级分类，对应菜单的分类
 */
export enum DWPayMethod {
    ACH = "ACH",
    DEBIT = "DEBIT",
    WIRE = "WIRE",
}
