import React from "react";
import { Wrapper } from "../StyleComponent";

const ShareHeader = () => {
    return (
        <Wrapper style={{ paddingTop: 30 }}>
            <img style={{ width: "8rem", height: "1.6rem" }} src={require("../../images/logo.png")} alt="pionex_logo" />
        </Wrapper>
    );
};

export default ShareHeader;
