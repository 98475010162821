import { BeneficiaryRes, DepositHistoryInfo, SignatureTransferType, WithdrawHistoryInfo, WithdrawParams } from "landings/V2/SignatureDW/types";
import { Observable } from "rxjs";
import { BankCardInfo } from "compliance_lib";
import { NetworkUtils } from "trade_utils_lib";
import Constants from "utils/Constants";
import { acquireKeyResolver } from "trade_token_lib";
import { map } from "rxjs/operators";
import { camelize } from "utils/camelize";
import { toBankCardInfo } from "landings/V2/SignatureDW/transform";

/**
 * Signature出入金相关API
 */
export default class SignatureDWAPI {
    /**
     * 获取入金记录
     */
    public getDepositOrders(): Observable<DepositHistoryInfo[]> {
        const url = `${Constants.walletApiHost}/fbo/signature/deposits/`;
        return acquireKeyResolver()
            .requestSigner.getSignRequestV2("GET", url)
            .pipe(map((res) => camelize(res.data?.records ?? [])));
    }

    /**
     * 获取出金记录
     */
    public getWithdrawOrders(): Observable<WithdrawHistoryInfo[]> {
        return NetworkUtils.getWithTradeAuth({
            url: `${Constants.walletApiHost}/fiatfacade/withdrawals/`,
            data: {
                transfer_type: "wire",
                channel: "fbo",
            },
        }).pipe(map((res) => camelize(res?.records ?? [])));
    }

    /**
     * 获取入金转账目标银行信息
     */
    public getBeneficiaries(): Observable<BeneficiaryRes> {
        const url = `${Constants.walletApiHost}/fbo/signature/deposit/fbo/banks/`;
        return acquireKeyResolver()
            .requestSigner.getSignRequestV2("GET", url)
            .pipe(map((res) => camelize(res.data)));
    }

    /**
     * 获取已保存的银行卡信息
     * @param transferType 需要按此转账类型过滤
     */
    public getBankCards(transferType?: SignatureTransferType): Observable<BankCardInfo[]> {
        const url = `${Constants.walletApiHost}/fbo/signature/banks/`;
        return acquireKeyResolver()
            .requestSigner.getSignRequestV2("GET", url)
            .pipe(
                map((res) => {
                    return res.data.banks.map(toBankCardInfo).filter((item) => {
                        if (transferType === SignatureTransferType.ACH) {
                            return item.transferType === SignatureTransferType.ACH;
                        }

                        if (transferType === SignatureTransferType.WIRE) {
                            // 没有type的也当做wire
                            return !item.transferType || item.transferType === SignatureTransferType.WIRE;
                        }

                        // 默认不过滤
                        return true;
                    });
                }),
            );
    }

    /**
     * 出金
     * @param params
     * @param verifyCodes 验证码
     */
    public withdraw(params: WithdrawParams & { txn_id: string; bank_name?: string }) {
        const url = `${Constants.walletApiHost}/fbo/signature/withdrawals/`;

        return NetworkUtils.postWithAccessAndSpotAuth({
            url,
            data: {
                ...(params.bank_name !== undefined && { bank_name: params.bank_name }),
                txn_id: params.txn_id,
                bank_id: params.bankId,
                bank: params.bank,
                amount: params.amount,
                file_ids: params.fileIds ? [params.fileIds] : null,
            },
        });
    }
}
