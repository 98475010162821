import Cookies from "js-cookie";
import { DEFAULT_LANGUAGE, LANGUAGE_LOCALS_MAP, LocalsMessages, SUPPORTED_LANGUAGES_LOCAL } from "./config";

const LANGUAGE_KEY = "LANGUAGE_DATA";
const LANGUAGE_VERSION_KEY = "LANGUAGE_DATA_VERSION";
const LANGUAGE_TYPE_KEY = "LANGUAGE_DATA_TYPE";

let __CURRENT_LOCALE__: LANGUAGE_LOCALS_MAP;

// 检测是否有受支持的语言
export function isSupportedLanguage(locale: string) {
    if (!locale) return false;
    return (
        /**
         * 检测直接支持的语言
         */
        SUPPORTED_LANGUAGES_LOCAL.find((it) => it.replace(/-|_/, "-").toUpperCase() === locale.replace(/-|_/, "-").toUpperCase()) ||
        SUPPORTED_LANGUAGES_LOCAL.find((it) => {
            /**
             * 通过语言前缀，检测支持的语种
             */
            const [sl] = it.split(/-|_/);
            const [ul] = locale.split(/-|_/);
            return sl.toLowerCase() === ul.toLowerCase();
        }) ||
        false
    );
}

/**
 * 缓存使用逻辑：通过Check静态资源版本号，标记是否重新请求国际化资源
 */
// 国际化本地缓存管理
const get = function (lang?: string) {
    // 如果传入了相应的lang，则需要判断是否等值
    if (lang && getLocale() !== lang) return {};
    try {
        const __d = window.localStorage.getItem(LANGUAGE_KEY);
        return __d ? JSON.parse(__d) : {};
    } catch (e) {}
    return {};
};

// export const set = function(v) {
//   const __obj = {};
//   v.forEach(item => {
//     __obj[item.code] = item.value;
//   });
//   const __d = JSON.stringify(__obj);
//   window.localStorage.setItem(LANGUAGE_KEY, __d);
// };
const set = function (obj: object) {
    window.localStorage.setItem(LANGUAGE_KEY, JSON.stringify(obj));
};

// 国际化版本管理
const getVersion = function () {
    return window.localStorage.getItem(LANGUAGE_VERSION_KEY);
};
const setVersion = function (v) {
    window.localStorage.setItem(LANGUAGE_VERSION_KEY, v);
};
// 获取支持的语言标识
/**
 * 获取用户语言
 * 优先从页面链接中查找，查找到相应的
 * 1. 地址栏
 * 2. $cookie.locale || localStorage.locale
 * 3. acceptLanguage
 * 4. 默认语言
 */
export const getComparedLocale = function (): {
    urlLocale?: string;
    supportLocale: LANGUAGE_LOCALS_MAP;
} {
    const match = window.location.pathname.match(/^\/([^\/ ]+)/);
    const urlLocale = !!match && isSupportedLanguage(match[1]);
    if (!__CURRENT_LOCALE__) {
        // cookie 和 localStorage 基本保持一致
        const storageLocale = isSupportedLanguage(Cookies.get("locale") || window.localStorage.getItem(LANGUAGE_TYPE_KEY) || "");
        const acceptLanguage = isSupportedLanguage(window.navigator.language || window.navigator["browserLanguage"]);
        // console.log(match?.[1], urlLocal, storageLocale, acceptLanguage, DEFAULT_LANGUAGE);
        const userLocale = urlLocale || storageLocale || acceptLanguage || DEFAULT_LANGUAGE;
        window.localStorage.setItem(LANGUAGE_TYPE_KEY, userLocale);
        // set cookie to support nginx language detector.
        Cookies.set("locale", userLocale, { secure: true, domain: window.location.hostname.replace(/.*(\.[^\.]+\.[^\.]+)$/, "$1") || window.location.hostname });
        __CURRENT_LOCALE__ = userLocale;
    }
    return {
        // url路径用于近似语言重置
        urlLocale: urlLocale ? match?.[1] : undefined,
        supportLocale: __CURRENT_LOCALE__,
    };
};

export const getLocale = function () {
    return getComparedLocale().supportLocale;
};
// 设置切换的语言标识
export const setLocale = function (locale: LANGUAGE_LOCALS_MAP) {
    window.localStorage.removeItem(LANGUAGE_KEY);
    window.localStorage.removeItem(LANGUAGE_VERSION_KEY);
    window.localStorage.removeItem(LANGUAGE_TYPE_KEY);
    __CURRENT_LOCALE__ = locale;
    window.localStorage.setItem(LANGUAGE_TYPE_KEY, locale);
};

// 是否已经缓存某个语言的数据
const CachedLanguages: Map<string, boolean> = new Map([[LANGUAGE_LOCALS_MAP.enUS, true]]);

// 异步加载语言包
export function getLocalFile(lang: LANGUAGE_LOCALS_MAP) {
    // 不再对同一个语言进行多次语言包加载
    if (CachedLanguages.get(lang)) {
        return Promise.resolve(LocalsMessages[lang]);
    }

    const tradingI18n = import(
        /* webpackChunkName: "tradingI18n" */ `@pionex-web-kit/trading-components/es/utils/i18n/source/locale.${lang === LANGUAGE_LOCALS_MAP.enUS ? "en" : lang}.js` // lib中英文使用的en
    ).then((mod) => mod.default);
    const siteI18n = import(/* webpackChunkName: "siteI18n" */ `./locale.${lang}`).then((mod) => mod.default);

    // 单独定义code字段，避免Webpack编译时出现不替换字段表达式的情况出现，导致线上报错。
    return (
        Promise.all([tradingI18n, siteI18n])
            // 合并trading-components和站点的的国际化数据源
            .then(([tradingRes, siteRes]) => ({ ...tradingRes, ...siteRes }))
            .then((res) => {
                // 仅当数据有效时，确定当前请求是否完成；
                if (res && Object.keys(res).length > 0) {
                    CachedLanguages.set(lang, true);
                }
                return res;
            })
    );
}

export default {
    get,
    set,
    getVersion,
    setVersion,
    getLocale,
    setLocale,
};
