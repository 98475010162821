import "babel-polyfill";
// import "antd/dist/antd.css";
import "@pionex-web-kit/components/dist/index.css";
import "@pionex-web-kit/pro-components/dist/index.css";
import "less/index.less";
import * as React from "react";
import * as ReactDOM from "react-dom";
import "swiper/dist/css/swiper.min.css";
import { initSentry } from "utils/SentryUtils";
import App from "./landings";
import { configAxios } from "utils/axiosConfig";
import LocalStorageUtils from "utils/LocalStorageUtils";
import { checkToBusinessWeb } from "utils/checkToBusinessWeb";
import { initGoogleTagManager } from "utils/GoogleTagManager";
import { ReactNativeWebView } from "utils/ReactNativeWebView";

initSentry();
// 对Axios进行全局通用参数配置
configAxios();
// 初始获取并更新一次本地邀请码
LocalStorageUtils.getReferralCode();
initGoogleTagManager();
ReactNativeWebView.shared.init();
checkToBusinessWeb();

// 服务端渲染时，不重写console.error方法
if (!window.__PRERENDER_INJECTED?.$$isRenderServer) {
    if (process.env.NODE_ENV === "production") {
        const consoleError = window.console.error;

        window.console.error = function (msg: any, ...other: any[]) {
            if (msg && typeof msg.indexOf === "function" && msg.indexOf("getDefaultProps") !== -1) {
                return;
            }
            consoleError(msg, ...other);
        };
    }
}

// if (process.env.DEBUG) {
//     const whyDidYouRender = require("@welldone-software/why-did-you-render");
//     whyDidYouRender(React, {
//         trackAllPureComponents: true,
//     });
// }

ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);
