import React, { useMemo } from "react";
import { useBalanceAll, useBalanceFinanceOtherList, useBalanceStat } from "commonUse/useBalance";
import { SafeDecimal, NumberCommonUtils } from "trade_utils_lib";
import { useTranslators } from "commonUse/Locale";
import { resetPageTitleAndDescription } from "utils/Utils";
import Overview from "../Overview";
import TradeAccount from "../TradeAccount";
import FinanceAccount from "../FinanceAccount";
import useExchangeRate from "src/commonUse/useExchangeRate";
import { useBalanceWithDW } from "src/commonUse/useDW";
import useTheme from "src/commonUse/useTheme";
import useAccountInfo from "src/commonUse/useAccountInfo";
import { useSelector } from "react-redux";
import AppState from "src/state/AppState";
import { AccountNavKeys } from "../Root";
import { BasicLayout } from "@pionex-web-kit/pro-components";
import DepositBanner from "./DepositBanner";

export interface IWalletContainerProps {
    activeTab: AccountNavKeys.overview | AccountNavKeys.trade | AccountNavKeys.finance;
}

const WalletContainer = (props: IWalletContainerProps) => {
    const { activeTab } = props;
    const balanceCfg = useSelector(({ balanceCfg }: AppState) => balanceCfg);
    const { $st } = useTranslators();
    const accountInfo = useAccountInfo();
    const apiKey = accountInfo.firstApiKey;
    const [coinList, loading] = useBalanceWithDW();
    const fullBalance = useBalanceAll();
    const theme = useTheme();
    const [balanceStat, balanceStatLoading] = useBalanceStat(apiKey);
    const exchangeRate = useExchangeRate();

    if (coinList !== undefined) {
        coinList.sort((a, b) => b.USD - a.USD);
    }

    const { totalBalanceDisplay, tradeTotalDisplay, financeTotalDisplay, financeTodayProfitDisplay, tradeTodayProfitDisplay, financeTodayProfitColor, tradeTodayProfitColor } =
        useMemo(() => {
            const totalBalanceDisplay = `${new SafeDecimal(NumberCommonUtils.toNormalPrice((fullBalance?.total?.totalInUSD || 0) * exchangeRate.exchangeRate))
                .toNumber()
                .toLocaleString()} ${exchangeRate.currency}`;
            const tradeTotalDisplay = `${new SafeDecimal(NumberCommonUtils.toNormalPrice((fullBalance?.total?.tradeTotalInUSD || 0) * exchangeRate.exchangeRate))
                .toNumber()
                .toLocaleString()} ${exchangeRate.currency}`;
            const financeTotalDisplay = `${new SafeDecimal(NumberCommonUtils.toNormalPrice((fullBalance?.total?.financeTotalInUSD || 0) * exchangeRate.exchangeRate))
                .toNumber()
                .toLocaleString()} ${exchangeRate.currency}`;
            // 理财今日盈亏
            const financeTodayProfit = new SafeDecimal(fullBalance?.total?.financeTotalInUSD || 0).minus(balanceStat.financeTotalInUSD);
            const financeTodayProfitNum = financeTodayProfit.toNumber();
            const financeTodayProfitColor = financeTodayProfitNum >= 0 ? theme.colorIncrease : theme.colorDecrease;
            const financeTodayProfitDisplay = `${new SafeDecimal(NumberCommonUtils.toNormalPrice(financeTodayProfitNum * exchangeRate.exchangeRate)).toNumber().toLocaleString()}`;
            // 交易今日盈亏
            const tradeTodayProfit = new SafeDecimal(fullBalance?.total?.tradeTotalInUSD || 0).minus(balanceStat.tradeTotalInUSD);
            const tradeTodayProfitNum = tradeTodayProfit.toNumber();
            const tradeTodayProfitColor = tradeTodayProfitNum >= 0 ? theme.colorIncrease : theme.colorDecrease;
            const tradeTodayProfitDisplay = `${new SafeDecimal(NumberCommonUtils.toNormalPrice(tradeTodayProfitNum * exchangeRate.exchangeRate)).toNumber().toLocaleString()}`;
            return {
                totalBalanceDisplay,
                tradeTotalDisplay,
                financeTotalDisplay,
                financeTodayProfitDisplay,
                tradeTodayProfitDisplay,
                financeTodayProfitColor,
                tradeTodayProfitColor,
            };
        }, [
            balanceStat.financeTotalInUSD,
            balanceStat.tradeTotalInUSD,
            exchangeRate.currency,
            exchangeRate.exchangeRate,
            fullBalance?.total?.financeTotalInUSD,
            fullBalance?.total?.totalInUSD,
            fullBalance?.total?.tradeTotalInUSD,
            theme.colorDecrease,
            theme.colorIncrease,
        ]);
    return (
        <BasicLayout.BasicContent>
            <DepositBanner />
            {fullBalance && (
                <>
                    {activeTab === AccountNavKeys.trade && (
                        <TradeAccount
                            balanceCfg={balanceCfg}
                            onLoading={loading}
                            tradeTotalDisplay={tradeTotalDisplay}
                            tradeTodayProfitDisplay={tradeTodayProfitDisplay}
                            tradeTodayProfitColor={tradeTodayProfitColor}
                            fullBalance={fullBalance}
                            totalBalanceDisplay={totalBalanceDisplay}
                            financeTotalDisplay={financeTotalDisplay}
                            balanceStatTotalUSD={balanceStat.totalInUSD}
                            balanceStatDepositInUSD={balanceStat.depositInUSD}
                            balanceStatWithdrawInUSD={balanceStat.withdrawInUSD}
                            balanceStatLoading={balanceStatLoading}
                            financeTodayProfitDisplay={financeTodayProfitDisplay}
                            financeTodayProfitColor={financeTodayProfitColor}
                        />
                    )}
                    {activeTab === AccountNavKeys.finance && (
                        <FinanceAccount
                            financeTotalDisplay={financeTotalDisplay}
                            onLoading={loading}
                            financeTodayProfitDisplay={financeTodayProfitDisplay}
                            financeTodayProfitColor={financeTodayProfitColor}
                        />
                    )}
                </>
            )}
        </BasicLayout.BasicContent>
    );
};

export default WalletContainer;
