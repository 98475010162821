import React, { Dispatch, useCallback } from "react";
import { TInput, TInputProps } from "components/v2/TinyComps";
import decStrNum from "landings/PionexHistoricalOrderDetails/components/Grid2OrderCommon/tool/decStrNum";
import useControlledValue from "commonUse/useControlledValue";

type Props = Omit<TInputProps, "onChange"> & { maxPrecision?: number; onChange?: Dispatch<string> };

/**
 * TODO (api持续补充)准备替换SelfInput的数字输入组件
 */
const TSelfNumberInput: React.FC<Props> = (props) => {
    const { onChange, maxPrecision, ...resetProps } = props;
    const [value, setValue] = useControlledValue("", { props, onChange, valueKey: "value" });

    const handleChange = useCallback(
        (e) => {
            const result = decStrNum(e.target.value, maxPrecision);
            setValue(result);
        },
        [maxPrecision, setValue],
    );

    return <TInput {...resetProps} onChange={handleChange} value={value} />;
};

export default TSelfNumberInput;
