import useTheme from "commonUse/useTheme";
import { DownloadAppPopover } from "landings/HomePage/DownloadApp/DownloadAppPopover";
import React, { useMemo, useRef } from "react";
import useAppLangInfo from "src/commonUse/useAppLangInfo";
import { $st } from "src/utils/i18n";
import { ThemeName } from "state/ThemeData";
import css from "../index.module.less";
import { LANGUAGE_LOCALS_MAP } from "utils/i18n";
import { TPopover } from "src/components/v2/TinyComps";
import QrCode from "qrcode.react";

export interface DownloadAppProps {
    showQRCode?: boolean;
    style?: React.CSSProperties;
    titleColor?: string;
}

const DownloadApp = (props: DownloadAppProps) => {
    const { showQRCode, style, titleColor } = props;
    const theme = useTheme();
    const langInfo = useAppLangInfo();
    const rootRef = useRef(null);

    const downLoadMethodsData = useMemo(() => {
        return [
            {
                ico: theme.name === ThemeName.light ? require("../../../images/home/icon/ico_apple.png") : require("../../../images/download/ic_apple_night.png"),
                methodName: `${$st("homepage_downloadapp_ios")}`,
                // action:goDownload
                action: () => {
                    // openUrlInNewTab(ALL_DOWNLOAD_URL.ios.testflight);
                },
            },
            {
                ico: require("../../../images/home/icon/ico_android.png"),
                methodName: $st("homepage_downloadapp_android"),
                // action:goDownload
                action: () => {
                    // openUrlInNewTab(ALL_DOWNLOAD_URL.android.apk);
                },
            },
        ];
    }, [langInfo, theme]);

    const downLoadMethods = useMemo(() => {
        return downLoadMethodsData.map((dwMethodItem, index) => {
            const { ico, methodName, action } = dwMethodItem;
            const isEn = langInfo.locale === LANGUAGE_LOCALS_MAP.enUS;
            const downloadView = (
                <div key={methodName} className={css.dw_method_item} onClick={action}>
                    <div className={css.dw_method_item_left}>
                        <i className={css.dw_method_item_ico} style={{ backgroundImage: `url("${ico}")` }} />
                        <span className={css.dw_method_name}>{methodName}</span>
                    </div>
                    <div className={css.dw_method_item_right}>
                        <i className={css.dw_method_item_downico} />
                    </div>
                </div>
            );

            if (index === 0) {
                return (
                    <TPopover key={methodName} placement="rightTop" content={<DownloadAppPopover isAndroid={false} />} trigger={"hover"} getPopupContainer={() => rootRef.current!}>
                        {downloadView}
                    </TPopover>
                );
            } else {
                return (
                    <TPopover key={methodName} placement="rightTop" content={<DownloadAppPopover isAndroid={true} />} trigger={"hover"} getPopupContainer={() => rootRef.current!}>
                        {downloadView}
                    </TPopover>
                );
            }
        });
    }, [downLoadMethodsData, langInfo.locale]);

    return (
        <div className={css.download_app} style={style} ref={rootRef}>
            {langInfo.locale !== LANGUAGE_LOCALS_MAP.enUS ? (
                <div className={css.download_app_left}>
                    <h3>
                        <div className={`${css.download_app_title} ${css.download_app_title_home}`} style={{ color: titleColor }}>
                            {$st("homepage_downloadapp_title_1")}
                            <br />
                            {$st("homepage_downloadapp_title_2")}
                        </div>
                    </h3>
                    <div className={css.dw_method_list}>
                        <div>{downLoadMethods}</div>
                        {showQRCode && (
                            <div className={css.download_qrcode_wrap}>
                                {/*<img className={css.download_qrcode} src={require("images/home/pionex/dow_codeImg.png")} alt="Pionex.US mobile app" />*/}
                                <QrCode value={window.location.href} size={154} includeMargin={false} />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className={css.download_app_left}>
                    <h3>
                        <div className={css.download_app_title_en} style={{ color: titleColor }}>
                            {$st("homepage_downloadapp_title_1")}
                        </div>
                        {/*<div className={css.download_app_subtitle_en} style={{ color: titleColor }}>*/}
                        {/*    {$st("homepage_downloadapp_title_2")}*/}
                        {/*</div>*/}
                    </h3>
                    <div className={css.dw_method_list}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>{downLoadMethods}</div>
                        {showQRCode && (
                            <div className={css.download_qrcode_wrap}>
                                {/*<img className={css.download_qrcode} src={require("images/home/pionex/dow_codeImg.png")} alt="Pionex.US mobile app" />*/}
                                <QrCode value={window.location.href} size={154} includeMargin={false} />
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className={css.download_app_right}>
                <img src={require("../../../images/download-cover.png")} alt="Pionex.US mobile app" />
            </div>
        </div>
    );
};

export default DownloadApp;
