import { Divider, List } from "antd";
import { CoinIcon } from "components/CoinIcon";
import { CustomDropDownAction, CustomDropDownView } from "components/DropDown";
import { SearchInput, SearchInputAction } from "components/SearchInput";
import { DWType, useDWPageContext } from "landings/DepositWithdraw";
import * as React from "react";
import { useCallback, useMemo, useRef, useState } from "react";
import { $st } from "utils/i18n";

import css from "../coinList/coinList.module.less";
import { SupportDWCoinInfo } from "TradeAPILib";

export interface CoinSelectViewProps {
    changeCurrentSelect: (coin: SupportDWCoinInfo) => void;
    type: DWType;
}

const guideLink = "https://www.youtube.com/watch?v=yHj2OTPExn8";
export const CoinSelectView = (props: CoinSelectViewProps) => {
    const { changeCurrentSelect, type } = props;
    const rootRef = useRef(null);
    const selectRef = useRef<CustomDropDownAction>(null);
    const { coinList, currentCoin } = useDWPageContext();
    const [search, changeSearch] = useState("");
    const searchInputRef = useRef<SearchInputAction>(null);
    const coinDistinct = useMemo(() => {
        const result: SupportDWCoinInfo[] = [];
        coinList.forEach((item) => {
            if (!result.some((i) => i.coinName === item.coinName)) {
                if (search) {
                    const searchUpper = search.toUpperCase();
                    if (item.coinName.toUpperCase().includes(searchUpper) || item.fullName.toUpperCase().includes(searchUpper)) {
                        result.push(item);
                    }
                } else {
                    result.push(item);
                }
            }
        });
        return result;
    }, [coinList, search]);

    const overLayIcon = useCallback(() => {
        if (currentCoin) {
            return <CoinIcon target={currentCoin.coinName} size={20} />;
        }
        return null;
    }, [currentCoin]);

    const headerDisplay = useMemo(() => {
        return `${currentCoin.coinName} (${currentCoin.fullName})`;
    }, [currentCoin]);

    const handleClick = useCallback((e) => {
        e.stopPropagation();
        window.open(guideLink);
    }, []);
    const dropDownContent = () => {
        return (
            <div className={css.contentWrapper}>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <SearchInput
                        key={"search"}
                        ref={searchInputRef}
                        searchInput={search}
                        style={css.searchInput}
                        updateSearchInput={changeSearch}
                        placeholder={$st("market.search.placeholder")}
                    />
                </div>

                <div className={css.list}>
                    <List
                        size="large"
                        dataSource={coinDistinct}
                        renderItem={(item) => {
                            const displayName = `${item.coinName}` + (item.fullName ? ` - ${item.fullName}` : "");
                            return (
                                <div
                                    className={css.item}
                                    onClick={() => {
                                        const findResult = coinDistinct.find((coin) => item.coinName === coin.coinName);
                                        if (findResult) {
                                            changeSearch("");
                                            searchInputRef.current!.clear();
                                            changeCurrentSelect(findResult);
                                            selectRef.current!.close();
                                        }
                                    }}
                                >
                                    <CoinIcon target={item.coinName} size="small" />
                                    <span className={css.text} style={{ marginLeft: 5 }}>
                                        {displayName}
                                    </span>
                                </div>
                            );
                        }}
                    />
                </div>
            </div>
        );
    };

    return (
        <div ref={rootRef}>
            <CustomDropDownView
                ref={selectRef}
                popupContainer={rootRef.current!}
                overLayContent={dropDownContent}
                overLayIcon={overLayIcon}
                overLayTitle={headerDisplay}
                onVisibleChange={(visible) => {
                    changeSearch("");
                }}
                overlayIconClass={css.dropDownIcon}
                headerClass={css.header}
                dropdownClass={type === DWType.deposit ? "flex justify-between items-center" : ""}
                dropdownOtherChildren={
                    type === DWType.deposit ? (
                        <div
                            onClick={handleClick}
                            className="border  select-none cursor-pointer flex items-center justify-center h-[20px] mr-[10px]  px-[6px] text-sm text-primary whitespace-nowrap   border-solid border-primary  rounded-20px "
                        >
                            {$st("deposit_crypto_guide")}
                        </div>
                    ) : null
                }
            />
            <Divider style={{ marginTop: 12 }} />
        </div>
    );
};
