import { useTranslators } from "commonUse/Locale";
import { Iconfont } from "components/v2/TinyComps";
import React, { memo } from "react";
import css from "./cs.m.less";
import { useZendeskWidgetReady } from "@pionex-web-kit/pro-components";
import csn from "classnames";
import { useOpenHelpCenter } from "commonUse";

const MessagerService = memo(({ show }: { show: boolean }) => {
    const { $st } = useTranslators();
    const widgetReady = useZendeskWidgetReady();
    const openHelpCenter = useOpenHelpCenter();
    return (
        <>
            <div className={csn(css.manualServiceWrap, "px-24px mobile:px-12px", { hidden: !show || !widgetReady })} onClick={openHelpCenter}>
                <Iconfont icon="icon_nav_surport_LiveChat" className={css.titleIcon} aria-hidden="true" />
                <span className={css.title}>{$st("manual_service_title")}</span>
            </div>
        </>
    );
});

export default MessagerService;
