import React from "react";
import { H4, WrapperColumn } from "../StyleComponent";
import Space from "../../components/Space";
import { useTranslators } from "commonUse/Locale";

const OpenAppLoading = () => {
    const { $st: str } = useTranslators();
    return (
        <WrapperColumn>
            <Space size={"20vh"} />
            <img src={require("../../images/app_logo.png")} style={{ width: "7rem", height: "7rem", maxWidth: "7rem", minHeight: "7rem" }} alt={"pionex_app_logo"} />
            <Space size={20} />
            <H4>{str("open_app")}</H4>
        </WrapperColumn>
    );
};

export default OpenAppLoading;
