import { message } from "antd";
import { DWHistoryList } from "landings/DepositWithdraw/components/DWHistory/HistoryList";
import { DWHistoryTable } from "landings/DepositWithdraw/components/DWHistory/HistoryTable";
import _ from "lodash";
import * as React from "react";
import { of, zip } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { useDataProvider } from "src/commonUse";
import { APIKeyInfo } from "src/TradeLib/TradeTypes";
import AccountInfo from "state/AccountInfo";
import DateFormatter from "state/DateFormatter";
import { MoveRecordInfo } from "trade_fund_lib";
import { DWHistoryInfo, DWRecordStatus, ExchangeID } from "TradeAPILib";
import { WithdrawRecordStatus } from "TradeAPILib/dist/src/ExchangeAPIAdapter/TradeTypeDef";
import { $st } from "utils/i18n/trans.comp";
import LocalStorageUtils, { LocalStorageKey } from "utils/LocalStorageUtils";
import { DWType } from "../..";
import cs from "./index.module.less";
import PubSub from "pubsub-js";
import { useCallback } from "react";
import { AssetAPILib } from "trade_asset_lib";

export const WITH_DRAW_SUCCESS = "WITH_DRAW_SUCCESS";
interface IHistoryTableProps {
    accountInfo: AccountInfo;
    dateFormatter: DateFormatter;
    DWType: DWType;
    currencies: string[];
    hideOther: boolean;
}

export function HistoryTable({ accountInfo: { firstApiKey }, currencies, DWType, hideOther, dateFormatter }: IHistoryTableProps) {
    // FIXME: 调整历史数据列表的刷新逻辑，让主控Hooks返回一个主动刷新的函数，而不是通过传参变更来控制数据刷新。
    const [refreshDWHistory, resetRefreshDWHistory] = React.useState(false);
    const [historyList, onLoading] = useDWHistories(DWType, refreshDWHistory, firstApiKey);
    const [filteredHistories, updateFiltered] = React.useState([] as DWHistoryInfo[]);
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        updateFiltered([]);
        setLoading(true);
    }, [DWType]);
    React.useEffect(() => {
        let pubSub = PubSub.subscribe(WITH_DRAW_SUCCESS, () => {
            resetRefreshDWHistory((state) => !state);
        });
        return () => {
            PubSub.unsubscribe(pubSub);
        };
    }, []);
    React.useEffect(() => {
        // 根据币种进行筛选
        const listResult = hideOther ? historyList.filter((it) => currencies.includes(it.currency)) : historyList;
        listResult.sort((a, b) => {
            return b.timestamp - a.timestamp;
        });
        updateFiltered(listResult);
        setLoading(false);
    }, [hideOther, historyList, currencies]);

    React.useEffect(() => {
        if (historyList.length > 0) {
            LocalStorageUtils.set(LocalStorageKey.KEY_USER_HAVE_DEPOSIT_HISTORY, "1");
        }
    }, [historyList]);

    const cancelWithdraw = useCancelWithdraw(refreshDWHistory, resetRefreshDWHistory, firstApiKey);
    return (
        <>
            <div className={cs.historyContent}>
                <DWHistoryTable dateFormatter={dateFormatter} onLoading={onLoading || loading} filteredHistories={filteredHistories} cancelWithdraw={cancelWithdraw} />
            </div>

            <div className={cs.historyList}>
                <DWHistoryList dateFormatter={dateFormatter} onLoading={onLoading || loading} filteredHistories={filteredHistories} cancelWithdraw={cancelWithdraw} />
            </div>
        </>
    );
}

export function useDWHistories(type: DWType, refreshDWHistory: boolean, bthubApiKey?: APIKeyInfo): [DWHistoryInfo[], boolean] {
    const dataProvider = useDataProvider(bthubApiKey);
    const [onLoading, updateLoading] = React.useState(true);
    const [coinsHistories, updateCoinsHistories] = React.useState([] as DWHistoryInfo[]);
    React.useEffect(() => {
        if (!dataProvider) {
            return;
        }
        updateLoading(true);
        const dwHistoryObservable = dataProvider.api.fetchDepositWithdrawHistoryV2("", type, 0).pipe(
            catchError((error) => {
                return of([]);
            }),
        );

        const moveObservable = dataProvider.api.requestMoveRecords("").pipe(
            catchError(() => {
                return of([] as MoveRecordInfo[]);
            }),
        );

        const subscription = zip(dwHistoryObservable, moveObservable)
            .pipe(
                map((result) => {
                    const historyResult: DWHistoryInfo[] = [...result[0]];
                    const moveRecordList: DWHistoryInfo[] = (result[1] as any)
                        .filter((moveRecordInfo) => {
                            if (moveRecordInfo.status !== WithdrawRecordStatus.FINISHED || moveRecordInfo.direction !== "IN") {
                                return false;
                            }
                            if (moveRecordInfo.tag === 1) {
                                return moveRecordInfo.subAccountId === 117512461;
                            }
                            return true;
                        })
                        .map((moveRecordInfo) => {
                            return {
                                id: moveRecordInfo.id,
                                txId: "",
                                statusInner: WithdrawRecordStatus.FINISHED,
                                currency: moveRecordInfo.currency,
                                timestamp: moveRecordInfo.timestamp,
                                type: "deposit",
                                amount: moveRecordInfo.amount.toString(),
                                fee: "",
                                address: "",
                                isActivityPrize: true,
                                tag: String(moveRecordInfo.tag),
                                status: DWRecordStatus.complete,
                                internal: true,
                            };
                        });

                    if (type === "deposit") {
                        historyResult.push(...moveRecordList);
                    }
                    return historyResult;
                }),
            )
            .subscribe(
                (result: DWHistoryInfo[]) => {
                    updateLoading(false);
                    updateCoinsHistories(_.flatten(result).sort((a, b) => b.timestamp - a.timestamp));
                },
                (error) => {
                    console.log(error);
                    updateLoading(false);
                },
            );

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [type, dataProvider, refreshDWHistory]);
    return [coinsHistories, onLoading];
}

function getFormatErrorMsg(message: string) {
    if (message.indexOf("TAPI_WALLET_INVALID_OPERATION") >= 0) {
        return $st("tapi_wallet_invalid_operation");
    } else if (message.indexOf("TAPI_WALLET_RECORD_NOT_EXIST") >= 0) {
        return $st("tapi_wallet_record_not_exist");
    } else if (message.indexOf("TAPI_WALLET_PARAMETER_ERROR") >= 0) {
        return $st("tapi_wallet_param_error_withdraw");
    }
    return parseErrorMsg(message);
}

function useCancelWithdraw(refresh: boolean, resetRefreshDWHistory: (value) => void, apiKey?: APIKeyInfo) {
    return useCallback(
        (id: string) => {
            if (!apiKey?.key_id) {
                return Promise.reject();
            }
            return new AssetAPILib()
                .withdrawCancel(id, { cloudKeyId: apiKey.key_id, market: ExchangeID.PIONEXV2 })
                .pipe(
                    tap((result) => {
                        resetRefreshDWHistory(!refresh);
                        message.success($st("cancel_withdraw_success"));
                    }),
                )
                .pipe(
                    catchError((err) => {
                        message.error($st("cancel_withdraw_failed", { reason: getFormatErrorMsg(err.message) }));
                        return of(false);
                    }),
                )
                .toPromise();
        },
        [refresh, resetRefreshDWHistory, apiKey],
    );
}

function parseErrorMsg(rpcMessage: string) {
    const startPos = rpcMessage.indexOf('"message":"');
    const endPos = rpcMessage.indexOf('"', startPos + 12);
    if (endPos > startPos) {
        return rpcMessage.substring(startPos + 11, endPos);
    }
    return rpcMessage;
}
