import React from "react";
import axios from "axios";
import { TradeUtils, BotSignalItem } from "TradeAPILib";
import { ExchangeOrder, ExchangeOrderType } from "TradeAPILib/dist/src/ExchangeAPIAdapter/TradeTypeDef";
import { CopyBotShareInfo, ShareOrderInfo } from "./types";
import { LANGUAGE_LOCALS_MAP } from "utils/i18n";
import { queryLang } from "../utils";
import { forkJoin } from "rxjs";
import { fromPromise } from "rxjs/internal-compatibility";
import { useTranslators } from "commonUse/Locale";

const DEFAULT_AVATAR = "pionex_app_default.png";

// todo 从环境里注入
const locationOrigin = "";

export const useShareIdDataForMartingale = (shareId) => {
    const [data, setData] = React.useState<any>({});
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<any>(undefined);

    React.useEffect(() => {
        if (!shareId) {
            setLoading(false);
            setError("share_id_error");
        }
        axios
            .get(`/strategy/v1.8/orders/order_share/${shareId}/`, {
                timeout: 10000,
            })
            .then((res) => {
                setLoading(false);
                setError(undefined);
                if (res.data.code === 0) {
                    const resData = res.data.data;
                    const nickname = resData.user_info.nickname;
                    const avatar = resData.user_info.head_path;
                    const orderData = resData.bu_order;
                    setData({
                        nickname,
                        avatar,
                        orderData,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(error);
            });
    }, [shareId]);

    return [data, loading, error];
};

export const useMartingaleSignal = (data: any): [BotSignalItem | undefined, boolean] => {
    const [result, setResult] = React.useState<BotSignalItem>();
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (data.orderData && data.orderData.bu_order_data && data.orderData.bu_order_data.portfolio[0]) {
            const signal_type = data.orderData.bu_order_data.portfolio[0].signal_type;
            let url = "";

            let appLang = LANGUAGE_LOCALS_MAP.zhCN;
            const sysLang = queryLang();
            if (sysLang === LANGUAGE_LOCALS_MAP.zhCN || sysLang === LANGUAGE_LOCALS_MAP.zhTW) {
                appLang = sysLang;
            } else {
                appLang = LANGUAGE_LOCALS_MAP.enUS;
            }
            if (signal_type && signal_type !== "immediately" && signal_type !== "time_lapse") {
                url = `${locationOrigin}/signal/api/v1/signal/detail?app_lang=${appLang}&signal_type=${signal_type}`;
                axios
                    .get(url, {
                        timeout: 10000,
                    })
                    .then((res) => {
                        setLoading(false);
                        if (res.data.code === 0) {
                            const signals = res.data.data;
                            if (signals) {
                                setResult(signals as BotSignalItem);
                            }
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                    });
            }
        }
    }, [data.orderData]);

    return [result, loading];
};

// 后续其他机器人用这个
export const useShareIdData = (shareId): [ShareOrderInfo, boolean, string] => {
    const { $st: str } = useTranslators();
    const [data, setData] = React.useState<ShareOrderInfo>({
        nickname: str("default_nickname"),
        avatar: `https://avatar.pionex.us/${DEFAULT_AVATAR}`,
        botType: ExchangeOrderType.gridV3,
        orderData: null,
    });
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<any>(undefined);
    React.useEffect(() => {
        if (!shareId) {
            setLoading(false);
            setError("share_id_error");
        }

        axios
            .get(`${locationOrigin}/strategy/v1.8/orders/order_share/${shareId}/`, {
                timeout: 10000,
            })
            .then((res) => {
                const responseData = res.data?.data;
                const orderData = TradeUtils.convertToExchangeOrder(responseData.bu_order) as ExchangeOrder;

                setData({
                    nickname: responseData.user_info.nickname,
                    avatar: responseData.user_info.head_path,
                    botType: orderData.orderType,
                    orderData,
                });

                setError(undefined);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [shareId]);

    return [data, loading, error];
};

// 跟单机器人用这个
export const useCopyBotShareIdData = (order_type, order_id): [CopyBotShareInfo, boolean, string] => {
    const { $st: str } = useTranslators();
    const [data, setData] = React.useState<CopyBotShareInfo>({
        nickname: str("default_nickname"),
        avatar: `https://avatar.pionex.us/${DEFAULT_AVATAR}`,
        botType: order_type,
        botName: "",
        investNum: 0, //跟单人数
        investNumAmount: "0", //跟单金额
        investCoin: "",
        orderCreateTime: 0,
        baseList: [],
        status: "",
        rota: 0,
        rota_usdt: 0,
        roi:0,
    });
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<any>(undefined);

    React.useEffect(() => {
        if (!order_id || !order_type) {
            setLoading(false);
            setError("share_id_error");
        }
        let appLang = LANGUAGE_LOCALS_MAP.zhCN;
        const sysLang = queryLang();
        if (sysLang === LANGUAGE_LOCALS_MAP.zhCN || sysLang === LANGUAGE_LOCALS_MAP.zhTW) {
            appLang = sysLang;
        } else {
            appLang = LANGUAGE_LOCALS_MAP.enUS;
        }

        const $ob = forkJoin([
            fromPromise(
                axios.get(`/kol-apis/fapi/v1/trading_bot_order?bu_order_id=${order_id}&bu_order_type=${order_type}&app_lang=${appLang}`, {
                    timeout: 10000,
                }),
            ),
            fromPromise(
                axios.get(`/kol-apis/tapi/v1/statistic/overview?bu_order_id=${order_id}&bu_order_type=${order_type}&app_lang=${appLang}`, {
                    timeout: 10000,
                }),
            ),
        ]).subscribe(
            (res) => {
                const responseData = res[0].data?.data;
                const statisticData = res[1].data?.data;
                setLoading(false);
                setData({
                    nickname: responseData.nick_name,
                    avatar: responseData.avatar,
                    botType: responseData.bu_order_type,
                    botName: responseData.bot_name,
                    investNum: responseData.invest_num,
                    investNumAmount: responseData.invest_num_amount,
                    orderCreateTime: responseData.order_create_time,
                    status: responseData.order_status,
                    baseList: responseData.base_list,
                    investCoin: responseData.investment_token,
                    roi: statisticData.rota,
                    rota: statisticData.rota,
                    rota_usdt: statisticData.rota_usdt,
                });
            },
            (error) => {
                setLoading(false);
                setError(error);
            },
        );
        return () => {
            $ob.unsubscribe();
        };
    }, [order_id, order_type]);

    return [data, loading, error];
};

export const useGetValidResource = (url: string, fallbackUrl: string) => {
    const [valid, setValid] = React.useState<boolean | undefined>(undefined);

    React.useEffect(() => {
        axios
            .head(url, {
                timeout: 2000,
            })
            .then(({ status }) => {
                if (status !== 200) return;
                setValid(true);
            })
            .catch(() => {
                setValid(false);
            });
    }, [url]);

    if (valid === undefined) return null;
    return valid ? url : fallbackUrl;
};
