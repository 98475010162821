import { Observable } from "rxjs";
import { useCallback, useEffect, useRef, useState } from "react";

type RequestReturn<T> = { data: T; error: any; loading: boolean; refresh: () => void; initialed: boolean };
type RequestConfig = { requestOnInit?: boolean };

export function useRequest<T>(requestor: () => Promise<T> | undefined): RequestReturn<T | undefined>;
export function useRequest<T>(requestor: () => Promise<T> | undefined, initialValue: T): RequestReturn<T>;
export function useRequest<T>(requestor: () => Promise<T> | undefined, initialValue: T, config?: RequestConfig): RequestReturn<T>;

/**
 * 异步获取数据的hooks
 */
export function useRequest<T>(requestor: () => Promise<T> | undefined, initialValue?: T, config?: RequestConfig): RequestReturn<T | undefined> {
    // 默认初始请求一次
    const { requestOnInit = true } = config ?? {};

    const [data, setData] = useState(initialValue);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>();
    const [initialed, setInitialed] = useState(false);
    const refreshRef = useRef<() => void>();
    refreshRef.current = () => {
        const promise = requestor();
        promise && setLoading(true);
        promise?.then(
            (res) => {
                setLoading(false);
                setData(res);
                setInitialed(true);
            },
            (e) => {
                setLoading(false);
                setError(e);
            },
        );
    };

    const refresh = useCallback(() => {
        refreshRef.current?.();
    }, []);

    useEffect(() => {
        requestOnInit && refreshRef.current?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        data,
        loading,
        error,
        refresh,
        initialed,
    };
}

export function useRxRequest<T>(requestor: () => Observable<T> | undefined): RequestReturn<T | undefined>;
export function useRxRequest<T>(requestor: () => Observable<T> | undefined, initialValue: T): RequestReturn<T>;
export function useRxRequest<T>(requestor: () => Observable<T> | undefined, initialValue: T, config?: RequestConfig): RequestReturn<T>;
export function useRxRequest<T>(requestor: () => Observable<T> | undefined, initialValue?: T, config?: RequestConfig): RequestReturn<T | undefined> {
    return useRequest(() => requestor()?.toPromise(), initialValue, config);
}
