import { useModal } from "@pionex-web-kit/components";
import { openHelpCenter } from "@pionex-web-kit/pro-components";
import { ReactNode } from "react";
import { useCallbackStatic } from "commonUse/tools";
import AccountManager from "utils/AccountManager";
import { useHelpConfig } from "state/HelpConfig";

export default function useHelpCenterFn(): [() => void, ReactNode] {
    const [modal, modalContext] = useModal();
    const config = useHelpConfig();

    const fn = useCallbackStatic(() => {
        openHelpCenter(modal, {
            onSign: () => {
                AccountManager.toSign();
            },
            desc: config?.desc,
            questions: config?.questions ?? [],
        });
    });
    return [fn, modalContext];
}
