import { of, Subject } from "rxjs";
import { Subscription } from "rxjs/internal/Subscription";
import { delay } from "rxjs/operators";

export type Action = {
    type: string;
    data?: any;
};

export default class DataSubject {
    static TYPE_ACCOUNT_SIGN_IN = "type_account_sign_in";
    static TYPE_COLLECT_LIST_CHANGED = "type_collect_list_changed";
    static TYPE_SET_SIGN_PAGE = "type_set_sign_page";

    private static subject = new Subject();

    static next(type: String, data?: any) {
        DataSubject.subject.next({ type: type, data: data });
    }

    static asyncNext(type: String, data?: any) {
        of(0)
            .pipe(delay(100))
            .subscribe(() => {
                return DataSubject.subject.next({ type: type, data: data });
            });
    }

    static subscribe(listener: (type: string, data: any) => void): Subscription {
        return DataSubject.subject.subscribe((next) => {
            let action = next as Action;
            listener(action.type, action.data);
        });
    }
}
