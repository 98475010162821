import { $st } from "utils/i18n/index";
import React, { useState } from "react";
import css from "./MarketPage.module.less";
import SortIndicator, { IndicatorType } from "../../../../components/SortIndicator";
import { SymbolSortMethod } from "./types";
import ThemeData from "state/ThemeData";

interface MarketHeaderProps {
    theme: ThemeData;
    sortMethod: SymbolSortMethod;
    setSortMethod: (sortMethod: SymbolSortMethod) => void;
    hidePercent: boolean | undefined;
    isSort?: boolean;
}

function getIndicatorType(sortMethod: SymbolSortMethod, asc: SymbolSortMethod, desc: SymbolSortMethod) {
    if (sortMethod === asc) {
        return IndicatorType.ASC;
    } else if (sortMethod === desc) {
        return IndicatorType.DESC;
    } else {
        return IndicatorType.NONE;
    }
}

export default (props: MarketHeaderProps) => {
    const { theme, sortMethod, setSortMethod, hidePercent, isSort = true } = props;
    function onClickPair(e) {
        e.stopPropagation();
        setSortMethod(sortMethod === SymbolSortMethod.PAIR_ASC ? SymbolSortMethod.PAIR_DESC : SymbolSortMethod.PAIR_ASC);
    }

    function onClickVOL(e) {
        e.stopPropagation();
        setSortMethod(sortMethod === SymbolSortMethod.VOL_DESC ? SymbolSortMethod.VOL_ASC : SymbolSortMethod.VOL_DESC);
    }

    function onClickPrice(e) {
        e.stopPropagation();
        setSortMethod(sortMethod === SymbolSortMethod.PRICE_DESC ? SymbolSortMethod.PRICE_ASC : SymbolSortMethod.PRICE_DESC);
    }

    function onClickChange(e) {
        e.stopPropagation();
        setSortMethod(sortMethod === SymbolSortMethod.CHANGE_DESC ? SymbolSortMethod.CHANGE_ASC : SymbolSortMethod.CHANGE_DESC);
    }

    const isSortByPair = sortMethod === SymbolSortMethod.PAIR_ASC || sortMethod === SymbolSortMethod.PAIR_DESC;
    //24
    const isSortByChange = sortMethod === SymbolSortMethod.CHANGE_ASC || sortMethod === SymbolSortMethod.CHANGE_DESC;
    const isSortByPrice = sortMethod === SymbolSortMethod.PRICE_ASC || sortMethod === SymbolSortMethod.PRICE_DESC;

    return (
        <div key="header" className={css.header}>
            <div className={css.headerItem} style={{ flex: 1 }}>
                <div className={css.headerItem} onClick={onClickPair}>
                    <div className={isSortByPair ? css.headerTextActive : ""}>{$st("header_pair")}</div>
                    {isSort && isSortByPair ? (
                        <SortIndicator theme={theme} indicator={getIndicatorType(sortMethod, SymbolSortMethod.PAIR_ASC, SymbolSortMethod.PAIR_DESC)} />
                    ) : undefined}
                </div>
                <div style={{ marginLeft: 2, marginRight: 2 }}>/</div>

                <div className={css.headerItem} onClick={onClickVOL}>
                    <div className={sortMethod === SymbolSortMethod.VOL_ASC || sortMethod === SymbolSortMethod.VOL_DESC ? css.headerTextActive : ""}>{$st("header_vol")}</div>
                    {isSort && !isSortByPair ? (
                        <SortIndicator theme={theme} indicator={getIndicatorType(sortMethod, SymbolSortMethod.VOL_ASC, SymbolSortMethod.VOL_DESC)} />
                    ) : undefined}
                </div>
            </div>

            {hidePercent ? (
                <>
                    <div className={css.headerItem} style={{ flex: 1, justifyContent: "flex-end" }}>
                        <div className={css.headerItem} onClick={onClickPrice}>
                            <div className={sortMethod === SymbolSortMethod.PRICE_ASC || sortMethod === SymbolSortMethod.PRICE_DESC ? css.headerTextActive : ""}>
                                {$st("header_price")}
                            </div>
                            {isSort && isSortByPrice && (
                                <SortIndicator theme={theme} indicator={getIndicatorType(sortMethod, SymbolSortMethod.PRICE_ASC, SymbolSortMethod.PRICE_DESC)} />
                            )}
                        </div>
                        <div style={{ marginLeft: 2, marginRight: 2 }}>/</div>
                        <div className={css.headerItem} onClick={onClickChange}>
                            <div className={sortMethod === SymbolSortMethod.CHANGE_ASC || sortMethod === SymbolSortMethod.CHANGE_DESC ? css.headerTextActive : ""}>
                                {$st("symbols_header_24h")}
                            </div>
                            {isSort && isSortByChange && (
                                <SortIndicator theme={theme} indicator={getIndicatorType(sortMethod, SymbolSortMethod.CHANGE_ASC, SymbolSortMethod.CHANGE_DESC)} />
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={css.headerItem} style={{ justifyContent: "flex-end", flex: 1, transform: "translateX(8px)" }} onClick={onClickPrice}>
                        <div>{$st("header_price")}</div>
                        {isSort && <SortIndicator theme={theme} indicator={getIndicatorType(sortMethod, SymbolSortMethod.PRICE_ASC, SymbolSortMethod.PRICE_DESC)} />}
                    </div>
                    <div className={css.headerItem} style={{ justifyContent: "flex-end", flex: 1 }} onClick={onClickChange}>
                        <div>{$st(hidePercent ? "symbols_header_24h" : "symbols_header_24h_Change")}</div>
                        {isSort && <SortIndicator theme={theme} indicator={getIndicatorType(sortMethod, SymbolSortMethod.CHANGE_ASC, SymbolSortMethod.CHANGE_DESC)} />}
                    </div>
                </>
            )}
        </div>
    );
};
