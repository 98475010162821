import { ST } from "commonUse/Locale";
import useAccountInfo from "commonUse/useAccountInfo";
import {
    ActiveOrder,
    PredictedProfit,
    StakingHistory,
    StakingHistoryData,
    StakingProduct,
    StakingProfitHistory,
    StakingProfitHistoryData,
    StakingProfitSum,
    StakingService,
    StakingUserQuotaInfo,
} from "landings/V2/staking/StakingService";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { forkJoin, interval } from "rxjs";
import { startWith, switchMap } from "rxjs/operators";
import DateFormatter from "state/DateFormatter";
import { requestWithRetry } from "trade_utils_lib";
import Constants from "utils/Constants";

export interface StakingInterestListItemInfo {
    id: number;
    txId: string;
    accountId: string;
    productId: string;
    opType: string;
    coin: string;
    amount: string;
    status: string;
    timestamp: number;
    userId: string;
    stakingId: number;
    stakingTxId: string;
    apr: string;
    extraApr: string;
}

export function useStakingProducts() {
    const accountInfo = useAccountInfo();
    const [refreshId, setRefreshId] = useState(0);
    const [publicStakingProducts, setPublicStakingProducts] = useState<StakingProduct[]>();
    const [stakingProductsDetails, setStakingProductsDetails] = useState<StakingProduct[]>();
    const refresh = useCallback(() => setRefreshId(Date.now()), []);

    useEffect(() => {
        const subscription = requestWithRetry(() => StakingService.getProducts()).subscribe((data) => {
            setPublicStakingProducts(data);
        });
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        if (!Constants.isSiteV2) {
            // staking 只开放v2
            return;
        }
        if (!accountInfo.userId || !publicStakingProducts || publicStakingProducts.length === 0) {
            return;
        }
        const subscription = interval(10000)
            .pipe(
                startWith(0),
                switchMap(() => {
                    return forkJoin(
                        publicStakingProducts.map((item) => {
                            return requestWithRetry(() => StakingService.getFullProduct(item), {
                                onRetry: (retryCount: number, message: string) => {
                                    console.error(message);
                                },
                            });
                        }),
                    );
                }),
            )
            .subscribe((data) => {
                setStakingProductsDetails(data);
            });
        return () => subscription.unsubscribe();
    }, [accountInfo.userId, publicStakingProducts, refreshId]);

    return useMemo(() => {
        return {
            products: stakingProductsDetails || publicStakingProducts,
            refresh,
        };
    }, [publicStakingProducts, refresh, stakingProductsDetails]);
}

export function useStakingProductDetail(product?: StakingProduct) {
    const [productDetail, setProductDetail] = useState<StakingProduct>();
    useEffect(() => {
        if (!product) {
            return;
        }
        if (product.max_amount && product.investment_amount) {
            // 已有数据详细数据，先设置再更新
            setProductDetail(product);
        } else {
            setProductDetail(undefined);
        }

        const subscription = interval(10000)
            .pipe(
                startWith(0),
                switchMap(() => {
                    return requestWithRetry(() => StakingService.getFullProduct(product), {
                        onRetry: (retryCount: number, message: string) => {
                            console.error(message);
                        },
                    });
                }),
            )
            .subscribe((data) => {
                setProductDetail(data);
            });
        return () => subscription.unsubscribe();
    }, [product]);
    return productDetail;
}

export function remainTime(endTime: number | undefined, str: ST, overDay: string, overHour: string, timesUp: string, expired?: string): string | undefined {
    if (!endTime) {
        return undefined;
    }
    const timeDiff = endTime - Date.now();
    const formatTimeDiff = DateFormatter.getFormatTimeLasting(timeDiff);
    if (timeDiff <= 0) {
        return expired;
    } else if (formatTimeDiff.day > 0) {
        return str(overDay, { day: formatTimeDiff.day });
    } else if (formatTimeDiff.hour > 0) {
        return str(overHour, { hour: formatTimeDiff.hour });
    } else {
        return str(timesUp);
    }
}

const PAGE_SIZE = 20;

export function useProfitHistoryData(coin?: string, product_id?: string) {
    const [refreshId, setRefreshId] = useState(0);
    const [profitHistories, setProfitHistories] = useState<StakingProfitHistory[]>();
    const [predictedProfit, setPredictedProfit] = useState<PredictedProfit>();
    const [pageNo, setPageNo] = useState(1);
    const dataCacheRef = useRef<StakingProfitHistoryData>();
    const [hasMore, setHasMore] = useState(true);
    const currentDataRefreshIdRef = useRef(-1);

    const refresh = useCallback(() => {
        setPredictedProfit(undefined);
        setRefreshId(Date.now());
        setPageNo(1);
        setHasMore(true);
        dataCacheRef.current = undefined;
    }, []);

    const loadMore = useCallback(() => {
        if (!hasMore || !dataCacheRef.current || dataCacheRef.current.pagination.cur_page < pageNo) {
            // 当前页的数据还没拉下来, 忽略
            return;
        }
        setPageNo(pageNo + 1);
    }, [pageNo, hasMore]);

    useEffect(() => {
        setProfitHistories(undefined);
        setPredictedProfit(undefined);
        setPageNo(1);
        setHasMore(true);
        dataCacheRef.current = undefined;
    }, []);

    useEffect(() => {
        if (!product_id) {
            return;
        }
        const subscription = interval(10000)
            .pipe(
                startWith(0),
                switchMap(() => {
                    return requestWithRetry(() => StakingService.getStakingPredictedProfit(product_id), {
                        onRetry: (retryCount: number, message: string) => {
                            console.error(message);
                        },
                    });
                }),
            )
            .subscribe((data) => {
                setPredictedProfit(data);
            });
        return () => subscription.unsubscribe();
    }, [product_id]);

    useEffect(() => {
        if (!product_id) {
            return;
        }
        if (dataCacheRef.current && dataCacheRef.current.pagination.cur_page === pageNo && currentDataRefreshIdRef.current === refreshId) {
            return;
        }
        const subscription = StakingService.getProfitHistoryData({
            product_id,
            page_no: pageNo,
            page_size: PAGE_SIZE,
        }).subscribe({
            next: (data) => {
                const { pagination } = data;
                if (pagination.cur_page > 0 && dataCacheRef.current) {
                    data.rows = [...dataCacheRef.current.rows, ...data.rows];
                }
                if (pagination.cur_page * PAGE_SIZE >= pagination.total_rows) {
                    setHasMore(false);
                }
                dataCacheRef.current = data;
                currentDataRefreshIdRef.current = refreshId;
                setProfitHistories(data.rows);
            },
            error: () => {},
        });
        return () => subscription.unsubscribe();
    }, [refreshId, pageNo, product_id]);

    return useMemo(() => {
        if (!profitHistories) {
            return { undefined, refresh, hasMore, loadMore };
        }

        const predictList =
            predictedProfit && predictedProfit?.expected_time > 0
                ? [
                      {
                          productId: predictedProfit?.product_id,
                          opType: "next_profit",
                          coin: coin,
                          amount: predictedProfit?.amount,
                          timestamp: predictedProfit?.expected_time,
                      } as StakingInterestListItemInfo,
                  ]
                : [];

        const interestList = [
            ...predictList,
            ...profitHistories
                .filter((item) => item.product_id === product_id)
                .map((item) => {
                    return {
                        id: item.id,
                        txId: item.txid,
                        accountId: item.account_id,
                        productId: item.product_id,
                        opType: item.op_type,
                        coin: item.coin,
                        amount: item.amount,
                        status: item.status,
                        timestamp: item.timestamp,
                        userId: item.userId,
                        stakingId: item.staking_id,
                        stakingTxId: item.staking_tx_id,
                        apr: item.apr,
                        extraApr: item.extra_apr,
                    } as StakingInterestListItemInfo;
                }),
        ];
        return {
            interestList,
            refresh,
            hasMore,
            loadMore,
        };
    }, [coin, hasMore, loadMore, predictedProfit, profitHistories, refresh, product_id]);
}

export function useStakingHistory(productId?: string) {
    const [refreshId, setRefreshId] = useState(0);
    const [stakingHistories, setStakingHistories] = useState<StakingHistory[]>();
    const [pageNo, setPageNo] = useState(1);
    const dataCacheRef = useRef<StakingHistoryData>();
    const [hasMore, setHasMore] = useState(true);
    const currentDataRefreshIdRef = useRef(-1);

    const refresh = useCallback(() => {
        setRefreshId(Date.now());
        setPageNo(1);
        setHasMore(true);
        dataCacheRef.current = undefined;
    }, []);

    const loadMore = useCallback(() => {
        if (!hasMore || !dataCacheRef.current || dataCacheRef.current.pagination.cur_page < pageNo) {
            // 当前页的数据还没拉下来, 忽略
            return;
        }
        setPageNo(pageNo + 1);
    }, [pageNo, hasMore]);

    useEffect(() => {
        setStakingHistories(undefined);
        setPageNo(1);
        setHasMore(true);
        dataCacheRef.current = undefined;
    }, []);

    useEffect(() => {
        if (!productId) {
            return;
        }

        if (dataCacheRef.current && dataCacheRef.current.pagination.cur_page === pageNo && currentDataRefreshIdRef.current === refreshId) {
            return;
        }

        const subscription = StakingService.getStakingHistory({
            product_id: productId,
            page_no: pageNo,
            page_size: PAGE_SIZE,
        }).subscribe({
            next: (data) => {
                const { pagination } = data;
                if (pagination.cur_page > 0 && dataCacheRef.current) {
                    data.rows = [...dataCacheRef.current.rows, ...data.rows];
                }
                if (pagination.cur_page * PAGE_SIZE >= pagination.total_rows) {
                    setHasMore(false);
                }
                dataCacheRef.current = data;
                currentDataRefreshIdRef.current = refreshId;
                setStakingHistories(data.rows);
            },
            error: () => {},
        });
        return () => subscription.unsubscribe();
    }, [refreshId, pageNo, productId]);

    return useMemo(() => {
        if (!stakingHistories) {
            return { undefined, refresh, hasMore, loadMore };
        }

        const stakingHistoryList = [
            ...stakingHistories
                .filter((item: any) => item.status === "success" && (item.op_type === "stake" || item.op_type === "un-stake") && item.product_id === productId)
                .map((item) => {
                    return {
                        txId: item.txid,
                        accountId: item.account_id,
                        productId: item.product_id,
                        opType: item.op_type,
                        coin: item.coin,
                        amount: item.amount,
                        status: item.status,
                        timestamp: item.timestamp,
                        userId: item.user_id,
                    } as StakingInterestListItemInfo;
                }),
        ];
        return {
            stakingHistoryList,
            refresh,
            hasMore,
            loadMore,
        };
    }, [stakingHistories, refresh, hasMore, loadMore, productId]);
}

export function useStakingProfitSum(product?: StakingProduct, from?: string, to?: string) {
    const [profitSum, setProfitSum] = useState<StakingProfitSum>();
    useEffect(() => {
        if (!product) {
            return;
        }

        const subscription = interval(10000)
            .pipe(
                startWith(0),
                switchMap(() => {
                    return requestWithRetry(
                        () =>
                            StakingService.getStakingProfitSum({
                                product_id: product.product_id,
                                coin: product.coin,
                                from,
                                to,
                            }),
                        {
                            onRetry: (retryCount: number, message: string) => {
                                console.error(message);
                            },
                        },
                    );
                }),
            )
            .subscribe((data) => {
                setProfitSum(data);
            });
        return () => subscription.unsubscribe();
    }, [from, product, to]);
    return profitSum;
}

export function useActiveOrders(product_id?: string) {
    const [refreshId, setRefreshId] = useState(0);
    const [activeOrders, setActiveOrders] = useState<ActiveOrder[]>();

    const refresh = useCallback(() => {
        setRefreshId(Date.now());
    }, []);

    useEffect(() => {
        if (!product_id) {
            setActiveOrders(undefined);
            return;
        }
        const subscription = requestWithRetry(() => StakingService.getActiveOrderData({ product_id, page_size: 1000, order: "asc" })).subscribe((data) => {
            setActiveOrders(data.rows);
        });
        return () => subscription.unsubscribe();
    }, [product_id, refreshId]);

    return useMemo(() => {
        return {
            activeOrders,
            refresh,
        };
    }, [activeOrders, refresh]);
}

export function useUserQuota(product_id?: string) {
    const [userQuotaInfo, setUserQuota] = useState<StakingUserQuotaInfo>();

    useEffect(() => {
        const subscription = interval(10000)
            .pipe(
                startWith(0),
                switchMap(() => {
                    return requestWithRetry(() => StakingService.getUserQuota(), {
                        onRetry: (retryCount: number, message: string) => {
                            console.error(message);
                        },
                    });
                }),
            )
            .subscribe((data) => {
                const quota = data.products.find((item) => item.product_id === product_id);
                setUserQuota(quota);
            });

        return () => {
            subscription.unsubscribe();
        };
    }, [product_id]);

    return userQuotaInfo;
}
