import { Button } from "@pionex-web-kit/components";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslators } from "commonUse/Locale";

interface SendProps {
    onClick?: () => void;
    className?: string;
}
const COUNT_DOWN_DURATION = 60; // 倒计时长
enum SendStatusType {
    waitingSend,
    sent,
    waitingResend,
}
/**
 * @description: 发送验证码按钮
 * @param {SendProps} param1
 * @return {*}
 */
function SendButton({ className, onClick }: SendProps) {
    const { $st } = useTranslators();
    const [sendStatus, setSendStatus] = useState<SendStatusType>(SendStatusType.waitingSend);
    const [restTime, setRestTime] = useState(COUNT_DOWN_DURATION);
    const sendTimer = useRef<NodeJS.Timeout>();
    const sendEvt = useCallback(() => {
        setSendStatus(SendStatusType.sent);
        onClick?.();
    }, [onClick]);
    useEffect(() => {
        if (sendStatus === SendStatusType.sent) {
            sendTimer.current = setInterval(() => {
                setRestTime((val) => {
                    if (val === 0) {
                        setSendStatus(SendStatusType.waitingResend);
                        sendTimer.current && clearInterval(sendTimer.current);
                        return COUNT_DOWN_DURATION;
                    } else {
                        return val - 1;
                    }
                });
            }, 1000);
        }

        return () => {
            sendTimer.current && clearInterval(sendTimer.current);
        };
    }, [sendStatus]);
    return (
        <Button disabled={sendStatus === SendStatusType.sent} type="primary" shape="round" size="large" className={`px-[60px] ${className}`} onClick={sendEvt}>
            {sendStatus === SendStatusType.waitingSend ? (
                <>{$st("send_verification_information")}</>
            ) : (
                <>
                    {$st("withdraw.confirmation.phone.code.resend")}
                    {sendStatus === SendStatusType.sent && <>({restTime}s)</>}
                </>
            )}
        </Button>
    );
}
export default memo(SendButton);
