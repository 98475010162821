import React, { useState } from "react";
import { IconManager } from "src/utils/IconManager";

export interface IIconCoinOrMarketProps {
    className?: string;
    type?: "market" | "coin";
    target: string;
    /**
     * @deprecated
     */
    isLeverage?: boolean;
    size?: number | "big" | "small" | "middle";
}

const sizeMap = {
    big: 40,
    middle: 30,
    small: 18,
};
const _iconUrlErrorCache = new Set();
const getHolderIconByType = (type) => {
    return type === "market" ? require("images/ic_market_default.stc.png") : require("images/ic_coin_default.stc.png");
};
const getIconUrlByType = (type, target) => IconManager[type === "market" ? "getMarketIconUri" : "getCoinIconUri"](target);
const getIconUrl = (type, target) => {
    // 缓存资源报错，若已经报过错了，则在此页面生命周期内直接使用holder图标，不再尝试。
    if (_iconUrlErrorCache.has(`${type}-${target}`)) {
        return getHolderIconByType(type);
    }
    return getIconUrlByType(type, target);
};
export function IconCoinOrMarket({ className, type = "coin", target, size = "middle" }: IIconCoinOrMarketProps) {
    const iconSize = sizeMap[size] || size;
    // 杠杆代币 显示基础币种的图标
    const [finalTarget] = useState(() => target.replace(/\d[PMLS]$/i, ""));
    const [src, setSrc] = useState(() => getIconUrl(type, finalTarget));
    // 会有复用更新的场景
    React.useEffect(() => {
        setSrc(getIconUrlByType(type, target.replace(/\d[PMLS]$/i, "")));
    }, [type, target]);

    return (
        <img
            className={className}
            style={{ width: iconSize, height: iconSize, verticalAlign: "middle" }}
            src={src}
            onError={() => {
                _iconUrlErrorCache.add(`${type}-${finalTarget}`);
                setSrc(getHolderIconByType(type));
            }}
            alt={target}
        />
    );
}

export type Props = Omit<IIconCoinOrMarketProps, "type">;

export function CoinIcon(props: Props) {
    return <IconCoinOrMarket {...props} />;
}
