import {
    IconC2CKycVerification80pxDarkS,
    IconC2CKycVerification80pxLightS,
    IconC2CSecurityDeposit80pxDarkS,
    IconC2CSecurityDeposit80pxLightS,
    IconC2CTrade28pxDarkS,
    IconC2CTrade28pxLightS,
    IconSuccess20pxBoldS,
} from "@pionex-web-kit/icons";
import css from "./index.module.less";
import { Link } from "react-router-dom";
import { useTranslators } from "commonUse/Locale";
import { Button } from "antd";
import useKycV2Info from "commonUse/useKycInfoV2";
import { useBalanceAll } from "commonUse/useBalance";
import { useMemo } from "react";
import { SafeDecimal } from "trade_utils_lib";
import { history } from "src/landings";
import { useOpenOrderTime } from "use/useOpenOrderTime";
import { report } from "utils/GoogleTagManager";
import useTheme from "commonUse/useTheme";

export default function DepositBanner() {
    const { $st } = useTranslators();
    const theme = useTheme();
    const [{ currentLevel }] = useKycV2Info();
    const isKyc = new SafeDecimal(currentLevel).greaterThanOrEqualTo(2);
    const fullBalance = useBalanceAll();
    const haveBalance = useMemo(() => new SafeDecimal(fullBalance?.total.totalInUSD).greaterThan(0), [fullBalance]);
    const { haveTraded } = useOpenOrderTime();
    const activeStepIndex = useMemo(() => {
        if (!isKyc) return 1;
        if (!haveBalance) return 2;
        return 3;
    }, [isKyc, haveBalance]);

    return (
        <>
            {!haveTraded && (
                <div className={css.banner_wrapper}>
                    <img className={css.banner_wrapper_img} src={theme.dark ? require("./assets/deposit_big_pic_dark.png") : require("./assets/deposit_big_pic.png")} alt="" />
                    <div className={css.banner_wrapper_desc}>
                        <div className={css.banner_wrapper_desc_h1}>
                            3 steps to get <span className="text-primary">200 USDT</span>
                        </div>
                        <div className={css.banner_wrapper_desc_h2}>
                            New Users Exclusive{" "}
                            <Link
                                onClick={() => {
                                    report("account_guide_learn_more");
                                }}
                                className="ml-6px !text-primary"
                                to="/newbie-rewards"
                            >
                                {$st("home_act_btn")}
                            </Link>
                        </div>
                    </div>

                    <DepositStep activeStepIndex={activeStepIndex} />
                    {activeStepIndex === 1 && (
                        <Button
                            onClick={() => {
                                report("account_guide_kyc");
                                history.push("/account/kyc");
                            }}
                            size="large"
                            className=" ml-[60px] !rounded-10px"
                            shape="round"
                            type="primary"
                        >
                            {$st("identify_verification")}
                        </Button>
                    )}
                    {activeStepIndex === 2 && (
                        <Button
                            onClick={() => {
                                report("account_guide_deposit");
                                history.push("/balance/deposit");
                            }}
                            size="large"
                            className=" ml-[60px]"
                            shape="round"
                            type="primary"
                        >
                            {$st("balance_coin_no_data_button_title")}
                        </Button>
                    )}
                    {activeStepIndex === 3 && (
                        <Button
                            onClick={() => {
                                report("account_guide_trade");
                                history.push("/trade");
                            }}
                            size="large"
                            className=" ml-[60px] !rounded-10px"
                            shape="round"
                            type="primary"
                        >
                            {$st("trade_now")}
                        </Button>
                    )}
                </div>
            )}
        </>
    );
}

function DepositStep({ activeStepIndex = 1 }: { activeStepIndex?: number }) {
    const theme = useTheme();

    return (
        <div className={css.step_wrapper}>
            <div className={`${css.step_item} ${activeStepIndex === 1 ? css.step_item_active : ""}`}>
                <div className={css.step_item_content}>
                    <div className={css.step_item_div}>
                        {theme.dark ? <IconC2CKycVerification80pxDarkS className={css.step_item_icon} /> : <IconC2CKycVerification80pxLightS className={css.step_item_icon} />}
                    </div>
                </div>
                <div className={css.step_item_text}>
                    {activeStepIndex > 1 ? <IconSuccess20pxBoldS className={css.step_item_text_icon} size={16} /> : <span className={css.step_item_text_span}>1.KYC</span>}
                </div>
            </div>
            <div className={css.step_line}></div>
            <div className={`${css.step_item} ${activeStepIndex === 2 ? css.step_item_active : ""}`}>
                <div className={css.step_item_content}>
                    <div className={css.step_item_div}>
                        {theme.dark ? <IconC2CSecurityDeposit80pxDarkS className={css.step_item_icon} /> : <IconC2CSecurityDeposit80pxLightS className={css.step_item_icon} />}
                    </div>
                </div>
                <div className={css.step_item_text}>
                    {activeStepIndex > 2 ? <IconSuccess20pxBoldS className={css.step_item_text_icon} size={16} /> : <span className={css.step_item_text_span}>2.Deposit</span>}
                </div>
            </div>
            <div className={css.step_line}></div>

            <div className={`${css.step_item} ${activeStepIndex === 3 ? css.step_item_active : ""}`}>
                <div className={css.step_item_content}>
                    <div className={css.step_item_div}>
                        {theme.dark ? <IconC2CTrade28pxDarkS className={css.step_item_icon} /> : <IconC2CTrade28pxLightS className={css.step_item_icon} />}
                    </div>
                </div>
                <div className={css.step_item_text}>
                    {activeStepIndex > 3 ? <IconSuccess20pxBoldS className={css.step_item_text_icon} size={16} /> : <span className={css.step_item_text_span}>3.Trade</span>}
                </div>
            </div>
        </div>
    );
}
