import { ExchangeOrderType } from "TradeAPILib";
import { LANGUAGE_LOCALS_MAP } from "utils/i18n";
import { CopyBotShareInfo } from "../use/types";
import { $st as str } from "utils/i18n";

export function rdp(size) {
    return (window.screen.width / 375) * size;
}

export const queryLang = () => {
    const jsSrc = (navigator.language || "en-US").toLowerCase();

    if (jsSrc.indexOf("zh") !== -1) {
        if (jsSrc.indexOf("tw") !== -1) {
            // 繁体
            return LANGUAGE_LOCALS_MAP.zhTW;
        }
        return LANGUAGE_LOCALS_MAP.zhCN;
    } else if (jsSrc.indexOf("ko") !== -1) {
        return LANGUAGE_LOCALS_MAP.ko;
    }
    return LANGUAGE_LOCALS_MAP.enUS;
};

export const queryDeviceAndroid = () => {
    const u = navigator.userAgent;
    return u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
};

export function isMobile() {
    return /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(
        navigator.userAgent,
    );
}

export function complementDigit(value, n) {
    if (`${value}`.length >= n) {
        return `${value}`;
    }
    return `${Array(n).join("0")}${value}`.slice(-n);
}

// 毫秒
export const getFormatTimeLasting = (timeInterval) => {
    if (timeInterval > 0) {
        let chaTime = timeInterval;
        const day = 1000 * 60 * 60 * 24; // 一天等于毫秒数
        const hour = 1000 * 60 * 60; // 一小时等于毫秒数
        const dayResult = Math.floor(chaTime / day); //

        chaTime = chaTime - dayResult * day; // 减去天的毫秒数。再求小时个数
        const hourResult = Math.floor(chaTime / hour);
        chaTime = chaTime - hourResult * hour; // 减去小时的毫秒数。再求分钟个数
        const minuteResult = Math.floor(chaTime / (1000 * 60));
        const secondResult = Math.floor((chaTime - minuteResult * 1000 * 60) / 1000); // 减去分钟的毫秒数。再求秒的个数
        return {
            day: complementDigit(dayResult, 2),
            hour: complementDigit(hourResult, 2),
            minute: complementDigit(minuteResult, 2),
            second: complementDigit(secondResult, 2),
        };
    } else {
        const defaultZero = complementDigit(0, 2);

        return {
            day: defaultZero,
            hour: defaultZero,
            minute: defaultZero,
            second: defaultZero,
        };
    }
};

export const handlePress = (status, shareId, onPress, str, type) => {
    if (status !== "open") {
        return alert(str("share_order_closed"));
    }

    const mobileDevice = isMobile();

    const link = `https://pionexus.page.link/?link=https%3A%2F%2Fwww.pionex.us%2Fen-US%2Fdownload%3FpageName%3DBotFeedDetail%26action%3Dcopy_trade%26shareId%3D${shareId}%26shareType%3D${type}&from=share&apn=com.pionex.us.client&isi=1567213944&ibi=com.pionex.lite.appstore&ius=downloadapp`;
    if (mobileDevice) {
        onPress();
        if (queryDeviceAndroid()) {
            setTimeout(() => {
                window.open(link);
                window.location.href = "https://download.pionex.com";
            }, 1500);
        } else {
            setTimeout(() => {
                window.location.href = link;
            }, 1500);
        }
    } else {
        window.open("https://download.pionex.com");
    }
};

export const copyTradeHandlePress = (shareInfo: CopyBotShareInfo, orderId, orderType, onPress) => {
    if (shareInfo.status !== "open") {
        return alert(str("share_order_closed"));
    }
    const mobileDevice = isMobile();
    const inviteCode = new URLSearchParams(window.location.search).get("inviteCode");
    if (mobileDevice) {
        onPress();
        const dynamicLinkUrl = `https://pionexus.page.link/?link=https%3A%2F%2Fwww.pionex.us%2Fen-US%2Fdownload%3FpageName%3DBotFeedDetail%26action%3Dcopy_trade%26shareId%3D${orderId}%26shareType%3D${orderType}&from=share&apn=com.pionex.us.client&isi=1567213944&ibi=com.pionex.lite.appstore&ius=downloadapp`;
        if (queryDeviceAndroid()) {
            window.open(dynamicLinkUrl);
            window.location.href = "https://www.pionex.us/en-US/download";
        } else {
            window.location.href = dynamicLinkUrl;
        }
    } else {
        console.log(shareInfo);
        if ((orderType === ExchangeOrderType.gridV4) && shareInfo.baseList && shareInfo.baseList.length > 0) {
            let url = `${window.location.origin}/trade/${shareInfo.baseList[0]}_${shareInfo.investCoin}/Bot?orderType=${orderType}&orderId=${orderId}${inviteCode ? `&r=${inviteCode}` : ""
                }`;
            window.open(url);
        } else {
            window.open("https://www.pionex.us/en-US/download");
        }
    }
};
