import css from "landings/Trade/MarketLayout/MarketLayout.module.less";
import * as React from "react";
import { ExchangeTradePair, TradeSelectedInfo } from "TradeLib/TradeTypes";
import { isPionex } from "utils/platform";
import SymbolView from "./SymbolView";
import csn from "classnames";
import { IconSearch20pxRegular } from "@pionex-web-kit/icons";
import { Input } from "@pionex-web-kit/components";
import cs from "./index.m.less";
import { useTranslators } from "commonUse/Locale";
import useExchangeRate from "commonUse/useExchangeRate";
import { useFavoriteSymbols } from "landings/Trade/MainPage/useFavoriteSymbol";
import { ExchangeID } from "TradeAPILib";
import { useTradeSelectedInfo } from "state/TradeSelectedInfo";
import { SideTabKey } from "landings/Trade/SideTab";
interface ISymbolLayoutProps {
    className?: string;
    active: boolean;
    onClose?: () => void;
    tradeSelectedInfo?: TradeSelectedInfo;
    collectionList?: Array<ExchangeTradePair> | undefined;
    hidePercent?: boolean;
    isDropdown?: boolean;
    onSelectSymbol?();
    targetPage?: SideTabKey;
}

export const SymbolPicker = (props: ISymbolLayoutProps) => {
    const { className, hidePercent, targetPage } = props;
    const [searchInput, updateSearchInput] = React.useState("");
    const { $st } = useTranslators();
    const exchangeRate = useExchangeRate();
    const [_tradeSelectedInfo] = useTradeSelectedInfo();
    const tradeSelectedInfo = props.tradeSelectedInfo || _tradeSelectedInfo;
    const _collectionList = useFavoriteSymbols(ExchangeID.PIONEXV2);
    const collectionList = props.collectionList || _collectionList;
    return (
        <div className={csn(`${isPionex() ? css.symbolLayout : css.isBusymbolLayout}`, className)}>
            <div className={cs.inputWrapper}>
                <Input
                    placeholder={$st("market.search.placeholder")}
                    value={searchInput}
                    onChange={(v) => {
                        updateSearchInput(v.target.value);
                    }}
                    className={cs.input}
                    allowClear
                    prefix={<IconSearch20pxRegular className={"fill-secondary w-16px h-16px"} />}
                />
            </div>
            {!!tradeSelectedInfo && (
                <SymbolView
                    hidePercent={hidePercent}
                    key={"symbol"}
                    active={props.active}
                    searchInput={searchInput}
                    exchangeRate={exchangeRate}
                    tradeSelectedInfo={tradeSelectedInfo}
                    collectionList={collectionList}
                    isDropdown={props.isDropdown}
                    onSelectSymbol={props.onSelectSymbol}
                    targetPage={targetPage}
                />
            )}
        </div>
    );
};
