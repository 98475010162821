import React, { cloneElement, useMemo } from "react";
import DataSubject from "src/utils/DataSubject";
import { useIntl } from "react-intl";
import { getRootHostName } from "src/utils/platform";
import Constants from "utils/Constants";
import { Col, Row } from "antd";
import { TModal, TReportBlockLink } from "../../../components/v2/TinyComps";
import { getNavMenuConfig } from "./constants";
import { useTranslators } from "src/commonUse/Locale";
import { report } from "utils/GoogleTagManager";
import LocalStorageUtils from "utils/LocalStorageUtils";

/**
 * @deprecated
 */
export const signLinkProps = {
    to: Constants.accountsRoute.sign,
    onClick: () => DataSubject.next(DataSubject.TYPE_SET_SIGN_PAGE, false),
};
/**
 * @deprecated
 */
export const signUpLinkProps = {
    to: { pathname: Constants.accountsRoute.signUp, state: { source: "header" } },
    onClick: () => DataSubject.next(DataSubject.TYPE_SET_SIGN_PAGE, true),
};

/**
 * @deprecated
 * @param source
 * @returns
 */
export const generateSignUpProps = (source?: string) => {
    return {
        to: { pathname: Constants.accountsRoute.signUp, state: { source: source } },
        onClick: () => DataSubject.next(DataSubject.TYPE_SET_SIGN_PAGE, true),
    };
};

export function getLocaleDownloadLink(locale: string) {
    return PLATFORM.PIONEX_US_LIKE ? `${window.location.origin}/download` : `https://download.pionex.${getRootHostName()}?lang=${locale}`;
}

export function JumpToDownloadLink({ children, source }: { children: any; source?: string }) {
    const { locale } = useIntl();
    const link = getLocaleDownloadLink(locale);
    return cloneElement(children, {
        to: link,
        target: "_blank",
        onClick: () => {
            report("go_download_app", { position: source, ref: LocalStorageUtils.getReferralCode() });
        },
    });
}

export const postMail = PLATFORM.PIONEX_US_LIKE ? "service@pionex.us" : "service@pionex.com";

export function TutorialsModal({ children, onClick }: { children: any; onClick?: () => void }) {
    const {
        intl: { locale },
        $st,
    } = useTranslators();
    const tutorials = useMemo(() => {
        const list = getNavMenuConfig(locale);
        return list
            .find(({ title }) => title === "trade_utils_guide")
            ?.menus.map(({ link, icon, label }) => {
                return (
                    <Col span={12} style={{ marginBottom: 20 }} key={label}>
                        <TReportBlockLink to={link} target="_blank" icon={icon} size={48} style={{ width: "100%" }} onClick={onClick}>
                            {$st(label)}
                        </TReportBlockLink>
                    </Col>
                );
            });
    }, [$st, locale, onClick]);
    return (
        <TModal forceRender trigger={children} title={$st("tutorial")} footer={null} zIndex={10001}>
            <Row gutter={20}>{tutorials}</Row>
        </TModal>
    );
}

export function CommunitiesModal({ children }: { children: any }) {
    const {
        intl: { locale },
        $st,
    } = useTranslators();
    const communities = useMemo(() => {
        const list = getNavMenuConfig(locale);
        return list
            .find(({ title }) => title === "header_menu_community")
            ?.menus.map(({ link, icon, label }) => (
                <Col span={12} style={{ marginBottom: 20 }} key={label}>
                    <TReportBlockLink to={link} target="_blank" icon={icon} size={48} style={{ width: "100%" }}>
                        {$st(label)}
                    </TReportBlockLink>
                </Col>
            ));
    }, [$st, locale]);
    return (
        <TModal forceRender trigger={children} title={$st("header_menu_community")} footer={null} zIndex={10001}>
            <Row gutter={24}>{communities}</Row>
        </TModal>
    );
}
