import useAppLangInfo from "commonUse/useAppLangInfo";
import { useMemo } from "react";
import { isPionexCN, isPionexSG } from "utils/platform";

export const CurrencyConfig = {
    list: [
        "USD",
        "ARS",
        "AUD",
        "BGN",
        "BRL",
        "CAD",
        "CHF",
        "CNY",
        "CZK",
        "DKK",
        "EUR",
        "GBP",
        "HKD",
        "HRK",
        "HRK",
        "IDR",
        "ILS",
        "INR",
        "JPY",
        "KRW",
        "MXN",
        "MYR",
        "NOK",
        "NZD",
        "PHP",
        "PKR",
        "PLN",
        "RON",
        "RUB",
        "SEK",
        "SGD",
        "THB",
        "TRY",
        "TWD",
        "UAH",
        "ZAR",
    ],
    localeCurrency: {
        "zh-CN": "CNY",
        "zh-TW": "TWD",
        ja: "JPY",
        ko: "KRW",
    },
};

export const getLocaleDefaultCurrency = (locale: string) => {
    if (isPionexCN()) {
        return "CNY";
    } else if (isPionexSG()) {
        return "SGD";
    } else if (PLATFORM.PIONEX_US_LIKE) {
        return "USD";
    } else {
        return CurrencyConfig.localeCurrency[locale] ?? "USD";
    }
};

export const useSortedCurrencyList = () => {
    const { locale } = useAppLangInfo();
    return useMemo(() => {
        const localeDefaultCurrency = getLocaleDefaultCurrency(locale);
        const list = [localeDefaultCurrency];
        for (const currency of CurrencyConfig.list) {
            if (currency !== localeDefaultCurrency) {
                list.push(currency);
            }
        }
        return list;
    }, [locale]);
};
