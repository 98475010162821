import moment from "moment";
import React, { useState } from "react";
import MobilePicker from "react-mobile-datepicker";
import css from "./index.m.less";
import "./index.less";
import { Iconfont } from "components/v2/TinyComps";
import useTheme from "commonUse/useTheme";
import { ThemeName } from "state/ThemeData";
import { taxesDateFormat } from "../const";
import { $st } from "utils/i18n";
interface Props {
    onChange?: (values: any) => void;
    value?: any;
}

const dateConfig = ["month", "date", "year"];

const Picker: React.FC<Props> = ({ onChange, value: _value, ...p }) => {
    const value = !!_value ? _value : [];
    const [isOpenStart_time, setIsOpenStart_time] = useState(false);
    const [isOpenEnd_time, setIsOpenEnd_time] = useState(false);
    const _onStartChange = (values = Date.now()) => {
        onChange && onChange([moment(values), value[1]]);
        setIsOpenStart_time(false);
    };
    const theme = useTheme();

    const _onEndChange = (values = Date.now()) => {
        onChange && onChange([value[0], moment(values)]);
        setIsOpenEnd_time(false);
    };
    return (
        <div className="pickCon">
            <div className={css.picker}>
                <span>
                    <input
                        type="text"
                        readOnly
                        placeholder={$st("taxes_start_time")}
                        value={value[0] ? moment(value[0]).format(taxesDateFormat) : ""}
                        onClick={() => setIsOpenStart_time(true)}
                    />
                    <Iconfont className={css.icon} icon="icon_DownArrow" />
                </span>
                -
                <span>
                    <input
                        type="text"
                        readOnly
                        placeholder={$st("taxes_end_time")}
                        value={value[1] ? moment(value[1]).format(taxesDateFormat) : ""}
                        onClick={() => setIsOpenEnd_time(true)}
                    />
                    <Iconfont className={css.icon} icon="icon_DownArrow" />
                </span>
            </div>
            <MobilePicker
                headerFormat="MM/DD/YYYY"
                theme={theme.name === ThemeName.dark ? "dark" : ""}
                value={moment(value[0]).toDate()}
                isOpen={isOpenStart_time}
                onSelect={_onStartChange}
                onCancel={() => setIsOpenStart_time(false)}
                min={moment().subtract(1, "year").startOf("year").toDate()}
                max={moment().toDate()}
                dateConfig={dateConfig}
            />
            <MobilePicker
                headerFormat="MM/DD/YYYY"
                theme={theme.name === ThemeName.dark ? "dark" : ""}
                value={moment(value[1]).toDate()}
                min={moment(value[0]).toDate()}
                max={moment().toDate()}
                isOpen={isOpenEnd_time}
                onSelect={_onEndChange}
                onCancel={() => setIsOpenEnd_time(false)}
                dateConfig={dateConfig}
            />
        </div>
    );
};

export default Picker;
