import useAccountInfo from "commonUse/useAccountInfo";
import { TMaskLoading, TSpin } from "components/v2/TinyComps";
import { useDWPageContext } from "landings/DepositWithdraw";
import { DepositAddress } from "landings/DepositWithdraw/components/CoinSelect/components/DepositAddress";
import { AttentionViewPage, queryDWLayerType } from "landings/DepositWithdraw/components/CoinSelect/components/DepositAddress/AttentionView";
import { WithdrawPage } from "landings/DepositWithdraw/components/CoinSelect/components/Withdraw";
import cssExports from "landings/DepositWithdraw/components/DWHistory/index.module.less";
import { useGetCoinAddress } from "landings/DepositWithdraw/components/use";
import cs from "landings/DepositWithdraw/withdraw.module.less";
import * as React from "react";
import { useRestrictData } from "account/hook";

interface AddressViewProps {
    isDeposit: boolean;
}

export enum DepositLayerType {
    memo = "memo",
    likely = "likely",
    matic = "matic",
}

export const AddressView = (props: AddressViewProps) => {
    const { isDeposit } = props;
    const { currentCoin } = useDWPageContext();
    const [attentionType, changeAttentionType] = React.useState(undefined as undefined | DepositLayerType);
    const [loading, errorMsg, coinAddressInfo] = useGetCoinAddress(currentCoin.currency, isDeposit);
    const [restrictData, restrictDataLoading] = useRestrictData();

    React.useEffect(() => {
        if (currentCoin.currency === "MATIC") {
            changeAttentionType(DepositLayerType.matic);
            return;
        }
        changeAttentionType(queryDWLayerType(coinAddressInfo));
    }, [coinAddressInfo, currentCoin.currency]);

    const checkShowAttention = () => {
        return attentionType !== undefined && isDeposit;
    };

    return (
        <TMaskLoading spinning={loading || (!isDeposit && restrictDataLoading)} className={cs.coinInfoItem} style={{ height: "100%", padding: 20 }}>
            {isDeposit && attentionType !== undefined ? (
                <div className={cssExports.attentionLayer}>
                    <AttentionViewPage onNext={() => changeAttentionType(undefined)} coinAddress={coinAddressInfo} />
                </div>
            ) : null}
            <div className={checkShowAttention() ? cssExports.blurLayer : cssExports.unBlurLayer}>
                {isDeposit ? <DepositAddress coinAddressInfo={coinAddressInfo} errorMsg={errorMsg} /> : <WithdrawPage errorMsg={errorMsg} restrictData={restrictData} />}
            </div>
        </TMaskLoading>
    );
};
