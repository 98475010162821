import React, { memo, useMemo } from "react";
import LinkRoute from "components/Link";
import { PionexReport } from "landings/Analytics/Report";
import TradeBtn from "landings/Balance/components/TradeBtn";
import css from "landings/Balance/components/BalanceBot/BalanceBotView.module.less";
import { $st } from "utils/i18n";
import { useToggle } from "src/commonUse/tools";
import { TPopover } from "src/components/v2/TinyComps";
import USDTSwapModal from "src/components-v2/USDTSwapModal";
import { USD_DW_ROUTE_V2 } from "landings/V2/USDDW/menuRouter";
import { Button } from "@pionex-web-kit/components";

const Btn = (props: { disabled: boolean; text: string }) => {
    const { disabled, text } = props;
    if (disabled) {
        return (
            <TPopover placement="top" content={<span className={css.popoverText}>{$st("dw.coin.not.support.tip")}</span>}>
                <Button disabled={disabled} size="small">
                    {text}
                </Button>
            </TPopover>
        );
    }

    return (
        <Button disabled={disabled} size="small">
            {text}
        </Button>
    );
};

/**
 * 获取冲提按钮信息
 * @param canDeposit
 * @param canWithdraw
 * @param base
 */
function useDWBtnInfo({ canDeposit, canWithdraw, base }: { canDeposit?: boolean; canWithdraw?: boolean; base: string }) {
    return useMemo(() => {
        return {
            enableDeposit: canDeposit || base === "USD",
            enableWithdraw: canWithdraw || base === "USD",
            depositUrl: base === "USD" ? "/balance/ach/deposit" : `/balance/deposit/${base}`,
            withdrawUrl: base === "USD" ? "/balance/ach/withdraw" : `/balance/withdraw/${base}`,
        };
    }, [base, canDeposit, canWithdraw]);
}

export interface ListButtonItemProps {
    source: string;
    base: string;
    canDeposit?: boolean;
    canWithdraw?: boolean;
    currency: string;
    exchange: string;
    coin: string;
    baseDisplay: string;
    fullName: string;
    onClickOccupy?: () => void;
}

const ListButtonItem = memo((props: ListButtonItemProps) => {
    const { source, base, canDeposit, canWithdraw, currency, exchange, baseDisplay, fullName, onClickOccupy } = props;
    const handleLinkDeposit = React.useCallback(() => {
        PionexReport.reportCoinOperate(currency, 1);
    }, [currency]);

    const handleLinkWithdraw = React.useCallback(() => {
        PionexReport.reportCoinOperate(currency, 2);
    }, [currency]);

    const handleLinkTrade = React.useCallback(() => {
        PionexReport.reportCoinOperate(currency, 3);
    }, [currency]);

    const [swapVisible, { setTrue: showSwap, setFalse: hideSwap }] = useToggle(false);

    const { enableDeposit, enableWithdraw, depositUrl, withdrawUrl } = useDWBtnInfo({ canDeposit, canWithdraw, base });

    if (source === "trade") {
        return (
            <div className="flex items-center gap-6px">
                {enableDeposit ? (
                    <LinkRoute to={depositUrl} onClickCallback={handleLinkDeposit}>
                        <Btn disabled={false} text={PLATFORM.PIONEX_US_LIKE && base === "USD" ? $st("balance.coins.option.deposit") : $st("balance.coins.option.receive")} />
                    </LinkRoute>
                ) : (
                    <Btn disabled text={PLATFORM.PIONEX_US_LIKE && base === "USD" ? $st("balance.coins.option.deposit") : $st("balance.coins.option.receive")} />
                )}
                {enableWithdraw ? (
                    <LinkRoute to={withdrawUrl} onClickCallback={handleLinkWithdraw}>
                        <Btn disabled={false} text={PLATFORM.PIONEX_US_LIKE && base === "USD" ? $st("balance.coins.option.withdraw") : $st("balance.coins.option.send")} />
                    </LinkRoute>
                ) : (
                    <Btn disabled text={PLATFORM.PIONEX_US_LIKE && base === "USD" ? $st("balance.coins.option.withdraw") : $st("balance.coins.option.send")} />
                )}
                <TradeBtn exchange={exchange} coin={base} onClick={handleLinkTrade} />
                <Button disabled={base === "BUB"} size="small" onClick={onClickOccupy}>
                    {$st("balance_frozen_details")}
                </Button>
                {PLATFORM.PIONEX_US_LIKE && base === "USD" && (
                    <>
                        <span onClick={showSwap}>
                            <Btn disabled={false} text={$st("trade_swap")} />
                        </span>
                        {swapVisible && <USDTSwapModal opposite={"USD"} visible={swapVisible} onSuccess={hideSwap} onCancel={hideSwap} isGetUSDT />}
                    </>
                )}
            </div>
        );
    }
    return null;
});

export default ListButtonItem;
