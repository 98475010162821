import React, { useMemo, useState } from "react";
import cs from "./cs.m.less";
import { useTranslators } from "commonUse/Locale";
import { TButton, TCheckbox, TForm, TInput } from "components/v2/TinyComps";
import { DebitCardUserInfo } from "landings/V2/CircleDebit/types";
import { Callbacks } from "rc-field-form/lib/interface";
import { countryText, getCircleStates, getCircleSupportCountry, isNeedState } from "landings/V2/CircleDW/BankCard/common";
import SingleSearchSelect from "src/components-v2/SingleSearchSelect";

type Step2FormData = Omit<DebitCardUserInfo, "userId" | "email" | "name">;

interface Props {
    onPre?: () => void;
    onFinish?: (userInfo: Step2FormData) => Promise<void>;
}

/**
 *  Circle Debit绑卡第二步，账单信息填写
 * @param onPre
 * @param onFinish
 * @constructor
 */
const Step2Form: React.FC<Props> = ({ onPre, onFinish }) => {
    const { $st } = useTranslators();

    const countryList = useMemo(() => getCircleSupportCountry(), []);
    const countryOpts = useMemo(() => countryList.map((item) => ({ value: item.codeAlpha3, label: countryText(item) })), []);

    const [checkedAgree, setCheckedAgree] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const handleFinish: Callbacks["onFinish"] = (values) => {
        setSubmitting(true);
        onFinish?.(values as Step2FormData).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <TForm className={cs.form} onFinish={handleFinish}>
            <div className={cs.billingTitle}>{$st("debit_add_card_billing_subtitle")}</div>
            <TForm.TItem name={"addressLine1"} labelHidden label={$st("debit_add_card_billing_street")} rules={[{ required: true }]}>
                <TInput maxLength={200} size={"large"} placeholder={$st("debit_add_card_billing_street")} />
            </TForm.TItem>
            <TForm.TItem name={"addressLine2"} labelHidden label={$st("debit_add_card_billing_street2")}>
                <TInput maxLength={200} size={"large"} placeholder={$st("debit_add_card_billing_street2")} />
            </TForm.TItem>
            <TForm.TItem name={"city"} labelHidden label={$st("debit_add_card_billing_city")} rules={[{ required: true }]}>
                <TInput size={"large"} placeholder={$st("debit_add_card_billing_city")} />
            </TForm.TItem>
            <TForm.TItem name={"postalCode"} labelHidden label={$st("debit_add_card_billing_post")} rules={[{ required: true }]}>
                <TInput maxLength={16} size={"large"} placeholder={$st("debit_add_card_billing_post")} />
            </TForm.TItem>
            <div className={cs.billingTitle}>{$st("debit_add_card_billing_country")}</div>
            <TForm.TItem name={"country"} initialValue={"USA"}>
                <SingleSearchSelect options={countryOpts} size={"large"} placeholder={$st("debit_add_card_billing_country")} />
            </TForm.TItem>

            <TForm.TItem noStyle shouldUpdate>
                {({ getFieldValue }) => {
                    const countryAlpha3 = getFieldValue("country");
                    const countryCode = countryList.find((item) => item.codeAlpha3 === countryAlpha3)?.code;
                    return (
                        <>
                            {isNeedState(countryCode ?? "") && (
                                <TForm.TItem name="district" labelHidden label={$st("debit_add_card_billing_state")} rules={[{ required: true }]}>
                                    <SingleSearchSelect
                                        size={"large"}
                                        options={getCircleStates(countryCode!).map((item) => ({ value: item.code, label: item.name }))}
                                        placeholder={$st("debit_add_card_billing_state")}
                                    />
                                </TForm.TItem>
                            )}
                        </>
                    );
                }}
            </TForm.TItem>
            <div className={cs.tips}>{$st("debit_add_card_billing_tips", { br: <br /> })}</div>
            <TCheckbox checked={checkedAgree} onChange={(e) => setCheckedAgree(e.target.checked)}>
                {$st("debit_add_card_agree")}
            </TCheckbox>
            <div className={cs.step2Btns}>
                <TButton className={cs.step2Btn} type={"bordered"} size={"large"} onClick={onPre}>
                    {$st("common_back")}
                </TButton>
                <TButton onClick={() => {}} loading={submitting} disabled={!checkedAgree} htmlType={"submit"} className={cs.step2Btn} type={"primary"} size={"large"}>
                    {$st("common_submit")}
                </TButton>
            </div>
            <div className={cs.tips}>{$st("debit_add_card_billing_tips2")}</div>
        </TForm>
    );
};

export default Step2Form;
