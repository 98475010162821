import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TModal } from "components/v2/TinyComps";
import { DebitDepositRecord, DebitDepositStatus, DepositActionType, DepositRes } from "landings/V2/CircleDebit/types";
import DebitAPI from "landings/V2/CircleDebit/DebitAPI";
import { useCircleDebitAPI } from "landings/V2/CircleDebit/context";
import { of, Subscription, timer } from "rxjs";
import { catchError, scan, skipWhile, switchMap } from "rxjs/operators";
import cs from "./index.m.less";
import { useCallbackStatic, useToggle } from "commonUse/tools";
import { message } from "@pionex-web-kit/components";
import DepositWaitingModal from "landings/V2/CircleDebit/Deposit/DepositWaitingModal";

export const THREE_DS_FAQ_URL = "https://pionexus.zendesk.com/hc/en-us/articles/10648260374041";

export enum EDepositState {
    IDLE = "IDLE",
    WAIT_3DS_URL = "WAIT_3DS_URL", // 等待3DS链接
    IN_3DS_AUTH = "IN_3DS_AUTH", // 进行3ds认证中，等待3DS认证结果
    SUBMITTING = "SUBMITTING", // 提交请求中
    APPROVING = "APPROVING", // 提交成功等待审核
    APPROVED = "APPROVED", // 审核结束
}

interface DepositState {
    state: EDepositState;
    onStateSet(): void;
    isClosable(): boolean;
    isShowCover(): boolean;
}

class DepositTimer {
    circleDebitAPI?: DebitAPI;
    orderId?: string;
    onApproved: (order: DebitDepositRecord) => void;
    updateState: (state: DepositState) => void;

    // private times: number;
    private subscription?: Subscription;

    /**
     *  开始轮询
     * @param times 轮询次数
     */
    startTimer = async (times: number) => {
        this.stopTimer();
        if (!this.circleDebitAPI || !this.orderId) {
            return;
        }
        const source = this.circleDebitAPI.depositRecord(this.orderId);
        this.subscription = timer(0, 2000)
            .pipe(
                switchMap(() =>
                    source.pipe(
                        catchError(() => of(undefined)), // 请求出错不中断轮询
                    ),
                ),
                scan<DebitDepositRecord, { record?: DebitDepositRecord; usedTimes: number }>(
                    (a, b, c) => {
                        return { usedTimes: a.usedTimes + 1, record: b };
                    },
                    { usedTimes: 0 },
                ),
                // 不需要处理情况的数据不向下发射
                skipWhile((res) => {
                    if (res.usedTimes >= times) {
                        return false;
                    }

                    if (!res.record) {
                        // 未请求到数据也跳过
                        return true;
                    }

                    return res.record.status === DebitDepositStatus.PENDING && !res.record.paymentInfo.requiredAction?.redirectUrl;
                }),
            )
            .subscribe(({ record: res, usedTimes }) => {
                if (!res) {
                    return;
                }

                if (res.status === DebitDepositStatus.SUCCESS || res.status === DebitDepositStatus.FAILED) {
                    this.updateState(new ApprovedState(this, res, this.onApproved));
                    return;
                } else if (
                    res.status === DebitDepositStatus.PENDING &&
                    res.paymentInfo.requiredAction?.type === DepositActionType.THREE_D_SECURE_REQUIRED &&
                    !!res.paymentInfo.requiredAction.redirectUrl
                ) {
                    // 获取到3ds url
                    this.updateState(new In3DSAuthState(this, res.paymentInfo.requiredAction.redirectUrl));
                    return;
                }

                if (usedTimes >= times) {
                    // 最终仍旧无结果
                    this.onTimeout();
                    return;
                }
            });
    };

    stopTimer = () => {
        this.subscription?.unsubscribe();
    };

    onTimeout = () => {
        message.error({
            content: "Request time out; please check the status in your Order History.",
        });
        this.updateState(new IDLEState(this));
    };
}

export class IDLEState implements DepositState {
    state = EDepositState.IDLE;
    timer: DepositTimer;

    constructor(timer: DepositTimer) {
        this.timer = timer;
    }

    onStateSet() {
        this.timer && this.timer.stopTimer();
    }

    isClosable(): boolean {
        return false;
    }

    isShowCover(): boolean {
        return false;
    }
}

export class SubmittingState implements DepositState {
    state = EDepositState.SUBMITTING;

    onStateSet() {}

    isClosable(): boolean {
        return false;
    }

    isShowCover(): boolean {
        return true;
    }
}

export class ApprovingState implements DepositState {
    state = EDepositState.APPROVING;
    depositRes: DepositRes;
    timer: DepositTimer;

    constructor(timer: DepositTimer, depositRes: DepositRes) {
        this.timer = timer;
        this.depositRes = depositRes;

        this.timer.orderId = depositRes.id;
    }

    onStateSet() {
        // 审核中开始轮询刷新订单信息
        this.timer.startTimer(10);
    }

    isClosable(): boolean {
        return false;
    }

    isShowCover(): boolean {
        return true;
    }
}

class ApprovedState implements DepositState {
    state = EDepositState.APPROVED;
    timer: DepositTimer;
    order: DebitDepositRecord;
    onApproved: (order: DebitDepositRecord) => void;

    constructor(timer: DepositTimer, order: DebitDepositRecord, onApproved: (order: DebitDepositRecord) => void) {
        this.timer = timer;
        this.order = order;
        this.onApproved = onApproved;
    }

    onStateSet() {
        this.timer.stopTimer();
        this.onApproved(this.order);
    }

    isClosable(): boolean {
        return false;
    }

    isShowCover(): boolean {
        return false;
    }
}

export class Wait3DSURLState extends ApprovingState {
    state = EDepositState.WAIT_3DS_URL;
}

export class In3DSAuthState implements DepositState {
    state = EDepositState.IN_3DS_AUTH;
    authUrl: string;
    timer: DepositTimer;

    constructor(timer: DepositTimer, authUrl: string) {
        this.timer = timer;
        this.authUrl = authUrl;
    }

    onStateSet() {
        // 开始认证，开始轮询更新订单信息
        this.timer.startTimer(450);
        // openUrlInNewTab(this.authUrl);
    }

    isClosable(): boolean {
        return true;
    }

    isShowCover(): boolean {
        return true;
    }
}

/**
 * 入金状态管理
 * @param onApproved
 */
export function useDepositState(onApproved: (order: DebitDepositRecord) => void) {
    const circleDebitAPI = useCircleDebitAPI();

    const [timer] = useState(() => new DepositTimer());
    useEffect(() => {
        return () => {
            timer.stopTimer();
        };
    }, [timer]);
    const [depositState, setDepositState] = useState<DepositState>(() => new IDLEState(timer));

    const changeDepositState = useCallbackStatic((s: DepositState) => {
        if (s.state === depositState.state) {
            // 状态并没变化
            return;
        }
        s.onStateSet();
        setDepositState(s);
    });
    timer.circleDebitAPI = circleDebitAPI;
    timer.updateState = changeDepositState;
    timer.onApproved = onApproved;

    const cover = useMemo(() => {
        let loading: React.ReactNode = null;
        if (depositState.isShowCover()) {
            loading = (
                <DepositWaitingModal
                    state={depositState.state}
                    onCancel={() => {
                        changeDepositState(new IDLEState(timer));
                    }}
                />
            );
        }
        return (
            <>
                {loading}
                {depositState.state === EDepositState.IN_3DS_AUTH && <ThreeDSModal url={(depositState as In3DSAuthState).authUrl} />}
            </>
        );
    }, [changeDepositState, depositState, timer]);

    return { changeDepositState, cover, timer };
}

/**
 * 3ds验证的弹窗
 * @constructor
 */
const ThreeDSModal: React.FC<{
    url: string;
}> = ({ url }) => {
    const [visible, { setTrue, setFalse }] = useToggle();

    useEffect(() => {
        // 接收到3DS认证通知，等待1.5秒后自动跳转（App打开手机浏览器，Web打开iframe）开始3DS认证
        const timout = setTimeout(() => {
            setTrue();
        }, 1500);

        return () => {
            setFalse();
            clearTimeout(timout);
        };
    }, [setFalse, setTrue, url]);

    return (
        <TModal width={1000} className={cs.tdsModal} visible={visible} zIndex={999999} footer={null} closable={true} onCancel={setFalse}>
            <iframe src={url} title="3DS Service authentication" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </TModal>
    );
};
