import { ExchangeOrder } from "TradeAPILib";
import { useSelector } from "react-redux";
import AppState, { dispatch } from "./AppState";
import { useEffect } from "react";
import LocalStorageUtils, { LocalStorageKey } from "utils/LocalStorageUtils";

export const TYPE_RE_ORDER_DATA = "type_re_order_data";

export function ReOrderDataReducer(state = null, action: { type: string; data: ExchangeOrder }) {
    if (action.type === TYPE_RE_ORDER_DATA) {
        return action.data;
    }
    return state;
}

export function useReOrderData() {
    const reOrderData = useSelector((state: AppState) => state.reOrderData);

    useEffect(() => {
        const _reOrderData = LocalStorageUtils.getObject(LocalStorageKey.RE_ORDER_DATA);
        if (!_reOrderData) return;
        localStorage.removeItem(LocalStorageKey.RE_ORDER_DATA);
        dispatch(TYPE_RE_ORDER_DATA, _reOrderData);
        return () => {
            localStorage.removeItem(LocalStorageKey.RE_ORDER_DATA);
        };
    }, []);

    return reOrderData;
}




