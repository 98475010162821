import { ConfigProvider, message } from "antd";
import { ConnectedRouter } from "connected-react-router";
import Decimal from "decimal.js";
import * as React from "react";
import { IntlProvider } from "react-intl";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { AccountInfoContext, AppLangInfoContext, DateFormatterContext, ExchangeRateContext, ThemeContext } from "src/state/Contexts";
import { disableLocaleBasename, history } from "src/utils/History";
import { DecimalExpNeg } from "trade_utils_lib";
import { I18n } from "utils/i18n";
import AppState from "../state/AppState";
import { initExchangeDataProvider } from "../TradeLib/ExchangeDataProvider";
import storeFun from "./store";
import { changeLang } from "src/commonActions/actions";
import { CTXDataProvider, useDataProvider } from "src/commonUse";
import Layout from "src/components-v2/Layout";
import { TranslatorsProvider } from "commonUse/Locale";
import { initTradingConfig, TradingComProvider } from "state/TradingComProvider";
import { ZendeskWidgetProvider } from "@pionex-web-kit/pro-components";
import { setTrackFn, SymbolDataProvider } from "@pionex-web-kit/common-business";
import Routes from "./Router.US";
import Constants from "utils/Constants";
import { AccountTokenProvider } from "state/AccountTokenProvider";
import { GlobalHookComponent } from "components/GlobalHookComponent";
import { report, usePageViewReport } from "utils/GoogleTagManager";

export { history }; // TODO 兼容老的import

Decimal.set({ toExpNeg: DecimalExpNeg });
initExchangeDataProvider();

AppState.init(storeFun(history));

SymbolDataProvider.init({ disableSwapBinance: true, disableFuturePerpetual: true });
message.config({
    top: 80,
    duration: 4,
});

initTradingConfig();

setTrackFn((result) => {
    const { key, data } = result;
    report(key, data);
});

const ZENDESK_SNIPPET_KEY = "5a313154-16fc-43ed-940e-5d208708006a";

const Root = () => {
    const {
        localeData,
        localeData: { locale, messages },
        theme,
        exchangeRate,
        dateFormatter,
        accountInfo,
    } = useSelector((state: AppState) => state);
    const dispatch = useDispatch();
    React.useEffect(() => {
        setTimeout(() => {
            // 更新一次本地已缓存的国际化数据
            dispatch(changeLang(locale));
        }, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    usePageViewReport();

    const dataProvider = useDataProvider((accountInfo?.apiKeys || [])[0]);
    return (
        <IntlProvider locale={locale} key={locale} messages={messages}>
            <ConfigProvider>
                <ZendeskWidgetProvider userId={accountInfo.userId} snippetKey={ZENDESK_SNIPPET_KEY} clientId={Constants.clientId}>
                    <TranslatorsProvider>
                        <BrowserRouter basename={disableLocaleBasename ? undefined : `/${locale}`}>
                            <I18n>
                                <ConnectedRouter history={history}>
                                    <AppLangInfoContext.Provider value={localeData}>
                                        <ThemeContext.Provider value={theme}>
                                            <DateFormatterContext.Provider value={dateFormatter}>
                                                <ExchangeRateContext.Provider value={exchangeRate}>
                                                    <AccountInfoContext.Provider value={accountInfo}>
                                                        <AccountTokenProvider>
                                                            <CTXDataProvider.Provider value={dataProvider}>
                                                                <TradingComProvider>
                                                                    <GlobalHookComponent />
                                                                    <Layout>
                                                                        <Routes />
                                                                    </Layout>
                                                                </TradingComProvider>
                                                            </CTXDataProvider.Provider>
                                                        </AccountTokenProvider>
                                                    </AccountInfoContext.Provider>
                                                </ExchangeRateContext.Provider>
                                            </DateFormatterContext.Provider>
                                        </ThemeContext.Provider>
                                    </AppLangInfoContext.Provider>
                                </ConnectedRouter>
                            </I18n>
                        </BrowserRouter>
                    </TranslatorsProvider>
                </ZendeskWidgetProvider>
            </ConfigProvider>
        </IntlProvider>
    );
};

/**
 * Top Redux Store Wrap
 */
export default () => (
    <Provider store={AppState.store}>
        <Root />
    </Provider>
);
