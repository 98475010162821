import React, { useCallback, useEffect, useMemo, useState } from "react";
import TReportLink, { TButton, TDropdownBlock, TMenu, TMenuItem, TModal, TTable, TTabs } from "components/v2/TinyComps";
import { $st } from "utils/i18n";
import { useCallbackStatic, useToggle } from "src/commonUse/tools";
import AddAddressModal from "./components/AddAddressModal";
import { AssetAddressItem, commonErrorParser, deleteAddressBookItem, getWhiteListStatus, modifyAddressBookItemWhiteListStatus, WhiteListStatus } from "./api";
import { goWithdraw } from "./common";
import cs from "./cs.m.less";
import RenameModal from "./components/RenameModal";
import { SendState } from "landings/DepositWithdraw/components/use";
import VerifyModal from "landings/DepositWithdraw/components/CoinSelect/components/Withdraw/VerifyModal";
import { message } from "antd";
import { OtpState } from "state/AccountInfo";
import useAccountInfo from "commonUse/useAccountInfo";
import { VerifyCodeAction } from "trade_asset_lib";
import OnlyWhiteList from "./components/OnlyWhitelist";
import { history } from "utils/History";
import { ColumnsType } from "antd/lib/table";
import { useRouteMatch } from "react-router";
import { SHOW_GA_BIND_GUIDE_REF } from "src/components-v2/Layout/GlobalMessages/GABindGuide";
import { useDWCoins } from "commonUse/useDW";
import { BasicLayout } from "@pionex-web-kit/pro-components";
import { useAddressBookItems } from "landings/V2/Address/hooks";
import { useTwoStepState } from "components/TwoStepVerifyModal";

export interface IAddressListProps {
    type: "all" | "whitelist";
}

const AddressList = ({ type = "all" }: IAddressListProps) => {
    const isAllAddress = type === "all";
    // 首次进入地址薄如果打开了仅向白名单出金，就需要跳转到白名单页面，这个状态是拿来维护是否已经跳转的。
    const [firstRenderIsAllAddress] = useState(isAllAddress);
    const [visible, { setTrue: showAddressModal, setFalse: hideAddressModal }] = useToggle();
    const { addressBookItems, refreshAddressBookItems } = useAddressBookItems({ onlyWhitelist: type === "whitelist" });
    const [renameModalVisible, { setTrue: showRenameModal, setFalse: hideRenameModal }] = useToggle();
    const [verifyVisible, { setTrue: showVerify, setFalse: hideVerify }] = useToggle();
    const [submitState, updateSubmitState] = useState(SendState.standby as SendState);
    const [currentData, setCurrentData] = useState<any>();
    const [onlyWhiteList, setOnlyWhiteList] = useState(false);
    const [verifyAction, setVerifyAction] = useState(VerifyCodeAction.move_to_whitelist_address);
    const { params } = useRouteMatch<{ menuType: string }>();
    const changeTab = useCallbackStatic((key: IAddressListProps["type"]) => {
        history.push(`/balance/${params.menuType}/${key}`);
    });
    const [coinList] = useDWCoins();
    const _onAddAddressClick = useCallback(() => {
        if (SHOW_GA_BIND_GUIDE_REF.showIfNeed(true)) {
            ///> 二次验证没有绑定或者确认
            return;
        }
        showAddressModal();
    }, [showAddressModal]);

    const { noTwoFactor } = useTwoStepState();

    useEffect(() => {
        if (firstRenderIsAllAddress && !noTwoFactor) {
            getWhiteListStatus().subscribe(
                (data) => {
                    // 开启仅向白名单的时候要跳转到白名单页面
                    if (data?.whitelist_status === WhiteListStatus.on) {
                        changeTab("whitelist");
                    }
                },
                (error) => {
                    commonErrorParser(error.code, $st, error.message);
                },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstRenderIsAllAddress, noTwoFactor]);
    const onAddSuccess = useCallback(() => {
        hideAddressModal();
        refreshAddressBookItems();
    }, [refreshAddressBookItems, hideAddressModal]);
    const rename = useCallback(
        (item) => {
            setCurrentData(item);
            showRenameModal();
        },
        [showRenameModal],
    );
    const onRenameOk = useCallback(() => {
        hideRenameModal();
        setCurrentData(undefined);
        refreshAddressBookItems();
    }, [refreshAddressBookItems, hideRenameModal]);
    const addWhiteList = useCallback(
        (item) => {
            const { tag, address } = item;
            TModal.confirm({
                title: $st("join_to_whitelist"),
                icon: null,
                content: $st("confirm_add_whitelist", { tag, address }),
                onOk: function () {
                    setCurrentData(item);
                    showVerify();
                    setVerifyAction(VerifyCodeAction.move_to_whitelist_address);
                },
            });
        },
        [showVerify],
    );
    const submitVerify = useCallback(
        (verifyInfo) => {
            updateSubmitState(SendState.pending);
            const action = verifyAction === VerifyCodeAction.move_to_whitelist_address ? "add" : "remove";
            const { address_id } = currentData;
            modifyAddressBookItemWhiteListStatus({
                address_id,
                action,
                sms_code: verifyInfo.sms_code,
                email_code: verifyInfo.email_code,
                otp_code: verifyInfo.google_code,
            }).subscribe(
                () => {
                    updateSubmitState(SendState.success);
                    message.success($st(action === "add" ? "add_whitelist_success" : "remove_whitelist_success"));
                    refreshAddressBookItems();
                    hideVerify();
                },
                (error) => {
                    updateSubmitState(SendState.standby);
                    commonErrorParser(error.code, $st, error.message);
                },
            );
        },
        [currentData, refreshAddressBookItems, hideVerify, verifyAction],
    );
    const removeWhiteList = useCallback(
        (item) => {
            const { tag, address } = item;
            TModal.confirm({
                title: $st("remove_from_whitelist"),
                icon: null,
                content: $st("confirm_remove_whitelist", { tag, address }),
                onOk: function () {
                    setCurrentData(item);
                    showVerify();
                    setVerifyAction(VerifyCodeAction.move_out_whitelist_address);
                },
            });
        },
        [showVerify],
    );
    useEffect(() => {
        if (!noTwoFactor) {
            const getStatus = getWhiteListStatus().subscribe(
                (data) => {
                    if (data?.whitelist_status === WhiteListStatus.on) {
                        setOnlyWhiteList(true);
                    }
                },
                (error) => {
                    commonErrorParser(error.code, $st, error.message);
                },
            );
            return () => getStatus.unsubscribe();
        }
    }, [noTwoFactor]);
    const deleteAddress = useCallback(
        (item) => {
            const { tag, address, address_id } = item;
            TModal.confirm({
                title: $st("delete"),
                icon: null,
                content: $st("confirm_delete_whitelist", { tag, address }),
                onOk: function () {
                    return new Promise<void>((resolve, reject) => {
                        deleteAddressBookItem({
                            address_id,
                        }).subscribe(
                            () => {
                                resolve();
                                message.success($st("delete_address_success"));
                                refreshAddressBookItems();
                            },
                            (error) => {
                                commonErrorParser(error.code, $st, error.message);
                                reject(error);
                            },
                        );
                    });
                },
            });
        },
        [refreshAddressBookItems],
    );

    const columns = useMemo(() => {
        const defaultCols: ColumnsType = [
            {
                title: $st("address_table_header_tag"),
                width: 200,
                dataIndex: "tag",
                key: "tag",
            },
            {
                title: <span style={{ whiteSpace: "nowrap" }}>{$st("address_table_header_coin")}</span>,
                dataIndex: "coin",
                key: "coin",
                width: 80,
                render: (data) => <span style={{ whiteSpace: "nowrap" }}>{data}</span>,
            },
            {
                title: $st("address_table_header_chain"),
                dataIndex: "chain",
                key: "chain",
                width: 150,
                render: (data, line: AssetAddressItem) => {
                    return line.protocolDisplay;
                },
            },
            {
                title: $st("address_table_header_memo"),
                dataIndex: "memo",
                key: "memo",
                width: 100,
                render: (data) => data || "--",
            },
            {
                title: $st("address_table_header_address"),
                width: 300,
                dataIndex: "address",
                key: "address",
            },
            {
                title: "",
                key: "options",
                width: 110,
                render: (item) => {
                    return (
                        <TDropdownBlock
                            overlay={
                                <TMenu>
                                    <TMenuItem disabled={!item.whitelist && onlyWhiteList} onClick={() => goWithdraw(item, coinList)}>
                                        {$st("address_table_options_withdraw")}
                                    </TMenuItem>
                                    <TMenuItem onClick={() => rename(item)}>{$st("address_table_options_rename")}</TMenuItem>
                                    {item.whitelist ? (
                                        <TMenuItem onClick={() => removeWhiteList(item)}>{$st("address_table_options_whitelist_remove")}</TMenuItem>
                                    ) : (
                                        <TMenuItem onClick={() => addWhiteList(item)}>{$st("address_table_options_whitelist")}</TMenuItem>
                                    )}
                                    <TMenuItem onClick={() => deleteAddress(item)}>{$st("address_table_options_delete")}</TMenuItem>
                                </TMenu>
                            }
                            trigger={["click"]}
                        >
                            <TReportLink className={cs.options}>{$st("address_table_options")}</TReportLink>
                        </TDropdownBlock>
                    );
                },
            },
        ];
        if (isAllAddress) {
            return [
                {
                    title: "",
                    key: "whitelist",
                    dataIndex: "whitelist",
                    width: 100,
                    render: (whitelist) => {
                        if (whitelist) {
                            return <span className={cs.whitelistFlag}>{$st("white_list")}</span>;
                        }
                        return null;
                    },
                },
                ...defaultCols,
            ];
        }
        return defaultCols;
    }, [isAllAddress, onlyWhiteList, coinList, rename, removeWhiteList, addWhiteList, deleteAddress]);
    const defaultValue = useMemo(() => ({ whitelist: onlyWhiteList }), [onlyWhiteList]);

    return (
        <BasicLayout.BasicContent>
            <TTabs
                className="w-full"
                activeKey={type}
                onChange={changeTab}
                tabBarExtraContent={
                    <TButton type="primary" className={cs.addAddressBtn} onClick={_onAddAddressClick}>
                        {$st("add_new_address")}
                    </TButton>
                }
            >
                <TTabs.TabPane key="all" tab={$st("all_address_list_title")} />
                <TTabs.TabPane key="whitelist" tab={$st("white_address_list_title")} />
            </TTabs>
            <div className={cs.addressList}>
                {!isAllAddress && <OnlyWhiteList onlyWhiteList={onlyWhiteList} setOnlyWhiteList={setOnlyWhiteList} />}
                <TTable
                    className="w-full"
                    dataSource={addressBookItems || []}
                    columns={columns}
                    rowKey="address_id"
                    loading={!addressBookItems}
                    pagination={false}
                    scroll={{ y: document.body.scrollHeight - (isAllAddress ? 200 : 320), x: "100%" }}
                    locale={{ emptyText: !addressBookItems ? " " : $st(isAllAddress ? "address_table_empty_text" : "address_whitelist_table_empty_text") }}
                />
                <AddAddressModal visible={visible} coinType="USDTERC20" onCancel={hideAddressModal} onAddSuccess={onAddSuccess} defaultValue={defaultValue} />
                <RenameModal visible={renameModalVisible} onCancel={hideRenameModal} onOk={onRenameOk} data={currentData} />
                {verifyVisible && (
                    <VerifyModal action={verifyAction} visible={verifyVisible} submitState={submitState} submitWidthVerifications={submitVerify} onCancel={hideVerify} />
                )}
            </div>
        </BasicLayout.BasicContent>
    );
};

export default AddressList;
