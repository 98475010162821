import { useMemo } from "react";
import { useOnlineConfig } from "utils/onlineConfig";

// 获取所有手续费为0的币对

export function useOnlineConfigFees(): string[] {
    const { fees } = useOnlineConfig();
    return useMemo(() => {
        let _fees =
            fees?.spot?.extra?.filter((item) => {
                return item?.fee === "0%";
            }) || [];
        return _fees.flatMap((item) => item?.symbols?.map((symbol) => symbol?.toUpperCase())).filter(Boolean) as string[];
    }, [fees]);
}
