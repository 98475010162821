import React, {
    cloneElement,
    ElementType,
    Children,
    useCallback,
    createElement,
    useMemo,
    useState,
    createContext,
    useEffect,
    useRef,
    forwardRef,
    Ref,
    useImperativeHandle,
    HTMLAttributes,
    ReactNode,
    FC,
    PropsWithChildren,
    isValidElement,
    ReactElement,
} from "react";
import {
    Modal,
    Dropdown,
    Row,
    Col,
    Menu,
    Switch,
    Button,
    Select,
    Carousel,
    Tabs,
    Table,
    Radio,
    Input,
    Checkbox,
    Spin,
    Form,
    Tooltip,
    Layout,
    Steps,
    DatePicker,
    Progress,
    Popover,
    Alert,
    Breadcrumb,
    Typography,
} from "antd";
import { ModalProps, ModalFuncProps } from "antd/lib/modal";
import csn from "classnames";
import { useToggle } from "src/commonUse/tools";
import cs from "./cs.m.less";
import { IConNames } from "src/less/iconfont/iconfont";
import { DropDownProps } from "antd/lib/dropdown";
import { PionexReportV2 } from "src/landings/Analytics/ReportV2";
import { Link, LinkProps } from "react-router-dom";
import { MenuItemProps } from "antd/es/menu/MenuItem";
import { MenuProps } from "antd/lib/menu";
import { SwitchProps } from "antd/lib/switch";
import { ButtonProps } from "antd/lib/button";
import { SelectProps, SelectValue } from "antd/lib/select";
import { CarouselProps } from "antd/lib/carousel";
import { TableProps } from "antd/lib/table";
import { RadioProps, RadioGroupProps } from "antd/lib/radio";
import { InputProps, TextAreaProps } from "antd/lib/input";
import { CheckboxGroupProps, CheckboxProps } from "antd/lib/checkbox";
import { TabsProps } from "antd/lib/tabs";
import { SpinProps } from "antd/lib/spin";
import { FormInstance, FormItemProps, FormProps } from "antd/lib/form";
import { ModalStaticFunctions } from "antd/lib/modal/confirm";
import { TooltipProps } from "antd/lib/tooltip";
import { DatePickerProps, RangePickerProps } from "antd/lib/date-picker";
import SubMenu, { SubMenuProps } from "antd/lib/menu/SubMenu";
import { LayoutProps } from "antd/lib/layout";
import { SiderProps } from "antd/es/layout/Sider";
import { StepsProps } from "antd/lib/steps";
import { ProgressProps } from "antd/lib/progress";
import { PopoverProps } from "antd/lib/popover";
import Tag, { TagProps } from "antd/es/tag";
import { AlertProps } from "antd/lib/alert";
import { BreadcrumbItemProps } from "antd/lib/breadcrumb/BreadcrumbItem";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import { history } from "utils/History";
import { LocationDescriptor } from "history";
import { TextProps } from "antd/lib/typography/Text";
import { TypographyProps } from "antd/lib/typography/Typography";
import * as FigmaIcon from "@pionex-web-kit/icons";

/**
 * -------------------------------------------------------------------------
 * @组件维护注意事项
 * 1. 新的组件请在组件代码末尾新增，严禁在中间任意位置插入编写新的组件功能；
 * 2. 定制化行为组件，请赋予一定的组件中注释进行说明，便于使用者理解。
 * 3. 该文件维护的组件基本都是基于Antd组件的封装，且无破坏性重构的，如涉及强功能定制化组件，请新开组件文件进行管理。
 * 4. 组件样式遵循涉及组件化统一规范，请勿涵盖业务定制样式「外部自行管理定制化样式」。
 * 5. 组件使用全局样式名称，以【t-*】前缀命名而来，可在其他业务组件中结合 css-module 的 :global 关键字进行样式覆盖。
 * -------------------------------------------------------------------------
 */

type IConNamesNew = keyof typeof FigmaIcon;

export type IIconProps = { icon: IConNames | React.ReactElement; size?: number | string };

type IconElem = { icon: ReactNode };
export interface IReportLinkProps<ReportType extends keyof typeof PionexReportV2>
    extends Partial<IconElem>,
        Omit<LinkProps, "to" | "href" | "children">,
        Partial<Pick<LinkProps, "to">> {
    reportType?: ReportType;
    reportData?: Parameters<typeof PionexReportV2[ReportType]>[0];
    disabled?: boolean;
    primary?: boolean;
    children?: any;
    eleType?: string;
}

const appPathReg = /^(?:\w+?\:)/;
/**
 * 行为上报组件
 */
export default function TReportLink<RT extends keyof typeof PionexReportV2>({
    eleType,
    to,
    reportType,
    reportData,
    onClick,
    children,
    disabled,
    className,
    primary,
    icon,
    target,
    rel,
    ...props
}: IReportLinkProps<RT>) {
    const isValidPath = (!!to && typeof to === "object") || (typeof to === "string" && !appPathReg.test(to));
    const hasReport = !!reportType && sessionStorage.getItem("--show-ga-report-tip--") === "show";
    // 为了安全性，新开窗口打开的链接，都需要加上这个字段
    rel = target === "_blank" ? "noreferrer" : rel;
    return createElement(
        eleType ? eleType : isValidPath ? Link : "a",
        {
            target,
            rel,
            ...props,
            ...((isValidPath ? { to: to } : { href: to }) as any),
            className: csn("t-report-link", disabled && "disabled", className, cs.tReportLink, hasReport && cs.tHasRep, primary && cs.primary),
            onClick: useCallback(
                (e) => {
                    if (disabled) {
                        e.preventDefault();
                        e.stopPropagation();
                        return;
                    }
                    reportType && reportType in PionexReportV2 && (PionexReportV2[reportType] as any)(reportData);
                    onClick && onClick(e);
                },
                [disabled, reportType, reportData, onClick],
            ),
        },
        icon ? (
            <>
                {icon}
                {children}
            </>
        ) : (
            children
        ),
    );
}

export interface ITModalRef {
    toggle(_flag?: boolean): void;
    open(): void;
    close(): void;
}
const ForTModal = forwardRef(
    /**
     * destroyInnerWhenHidden 用于标记是否在模态框隐藏时，销毁children的渲染。
     * @param props
     * @param ref
     */
    function (
        {
            trigger,
            className,
            onCancel,
            visible,
            destroyInnerWhenHidden,
            children,
            okButtonProps = {},
            cancelButtonProps = {},
            closable = false,
            zIndex,
            ...props
        }: ModalProps & { trigger?: Parameters<typeof cloneElement>[0]; children: any; destroyInnerWhenHidden?: boolean },
        ref: Ref<ITModalRef>,
    ) {
        const [_visible, { toggle, setFalse, setTrue }] = useToggle();
        const [destroyed, { toggle: toggleDestroy }] = useToggle();
        const mVisible = typeof visible === "boolean" ? visible : _visible;
        useEffect(() => {
            if (destroyInnerWhenHidden) {
                if (mVisible) toggleDestroy(false);
                // 避免缓动隐退时突兀空白。
                else
                    setTimeout(() => {
                        toggleDestroy(true);
                    }, 200);
            }
        }, [destroyInnerWhenHidden, mVisible, toggleDestroy]);
        useImperativeHandle(
            ref,
            () => ({
                toggle,
                open: setTrue,
                close: setFalse,
            }),
            [setFalse, setTrue, toggle],
        );
        return (
            <>
                {trigger
                    ? cloneElement(trigger, {
                          onClick: () => {
                              const { onClick } = trigger.props as any;
                              onClick && onClick();
                              toggle();
                          },
                      } as any)
                    : null}
                <Modal
                    visible={mVisible}
                    onCancel={onCancel || setFalse}
                    className={csn("t-modal", className, cs.tModal)}
                    closeIcon={<Iconfont icon="icon_close_normal" className={cs.tModalClose} />}
                    closable={closable}
                    children={destroyed ? null : children}
                    okButtonProps={calcTBtnStaticProps({ type: "primary", ...okButtonProps })}
                    cancelButtonProps={calcTBtnStaticProps({ type: "bordered", ...cancelButtonProps })}
                    zIndex={zIndex ?? 1049}
                    {...props}
                />
            </>
        );
    },
);
type IStaticKeys = keyof ModalStaticFunctions;
function modifyModalProps({ className, icon, ...props }: ModalFuncProps, noIcon?: boolean) {
    return { className: csn("t-modal", className, cs.tModal), icon: noIcon ? null : icon, ...props };
}
export const TModal: typeof ForTModal &
    ModalStaticFunctions & {
        open: ModalStaticFunctions["success"];
        destroyAll: () => void;
        promisify: { [k in IStaticKeys]: (p: ModalFuncProps) => Promise<any> };
        useStatic: typeof useModalStatic;
    } = ForTModal as any;
// 转换为Promise，试用与确认的场景
TModal.promisify = {} as any;
(["confirm", "error", "info", "success", "warn", "warning"] as IStaticKeys[]).map((type) => {
    // 加入自定义样式
    TModal[type] = (props: ModalFuncProps) => {
        return Modal[type](modifyModalProps(props));
    };

    // 若使用promisify的快速函数，将不能再使用返回的update和destroy操作方法。
    TModal.promisify[type] = ({ className, icon, onOk, onCancel, ...props }: ModalFuncProps) => {
        return new Promise((res, rej) => {
            Modal[type](
                modifyModalProps({
                    onOk(...arg) {
                        return res(onOk?.(arg) || arg);
                    },
                    onCancel(...arg) {
                        onCancel?.(arg);
                        return rej();
                    },
                    ...props,
                }),
            );
        });
    };
});
TModal.destroyAll = Modal.destroyAll;
export function useModalStatic(typeOrProps: IStaticKeys | ModalFuncProps, _props: ModalFuncProps = {}) {
    const isFirstP = typeof typeOrProps !== "string";
    const type = (isFirstP ? "confirm" : typeOrProps) as IStaticKeys;
    const mProps = (isFirstP ? typeOrProps : _props) as ModalFuncProps;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ins = useRef<ReturnType<typeof Modal.success>>();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const mp = useRef(mProps);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        // TODO:自定义props浅层比对，自动更新弹窗的props，现在的对比算法还存在问题；
        // const npk = Object.keys(mProps);
        // const nl = npk.length;
        // const ol = Object.keys(mp.current).length;
        // if (nl !== ol || npk.some((k) => mProps[k] !== mp.current[k])) {
        // }
        ins.current?.update(modifyModalProps(mProps, isFirstP));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mProps, isFirstP]);
    mp.current = mProps;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        // 销毁组件
        return () => ins.current?.destroy();
    }, []);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const openModal = useCallback(() => {
        ins.current = TModal[type](mp.current);
    }, [type]);
    return openModal;
}
// 支持自动销毁、更新props
TModal.useStatic = useModalStatic;

/**
 * 块级交互连接
 */
export function TReportBlockLink<RT extends keyof typeof PionexReportV2>({
    className,
    children,
    active,
    type = "default",
    blocked,
    size,
    target,
    style = {},
    ...props
}: IReportLinkProps<RT> & {
    active?: boolean;
    type?: "lined" | "default" | "primary" | "blue" | "link";
    blocked?: boolean;
    size?: number;
}) {
    return (
        <TReportLink
            {...{ ...props, ...(target === "_blank" ? { rel: "noreferrer" } : {}) }}
            target={target}
            style={{
                ...style,
                ...(size
                    ? {
                          height: `${size}px`,
                          lineHeight: `${size}px`,
                          borderRadius: `${size / 2}px`,
                      }
                    : {}),
            }}
            className={csn("t-report-block-link", className, cs.tBlockLink, cs[`type_${type}`], blocked && cs.blocked, {
                [cs.active]: active,
            })}
        >
            {children}
        </TReportLink>
    );
}

/**
 * 块级下拉组件
 */
export function TDropdownBlock({ children, overlayClassName, ...props }: DropDownProps & { children: any }) {
    return (
        <Dropdown overlayClassName={csn(overlayClassName, cs.tDropBlockOverlay)} {...props}>
            {children}
        </Dropdown>
    );
}

/**
 * 块级菜单组
 * @param param0
 */
export function TMenuBlockGroup({
    children,
    className,
    gutter = 12,
    grid,
    ...props
}: { children: any; gutter?: number; grid?: 2 | 3 | 4 | 6 | 8 | 12 | 24 } & React.HTMLAttributes<HTMLDivElement>) {
    const childLen = Children.count(children);
    return (
        <div className={csn(cs.tMenuBlockGroup, className, "t-menu-block-group", { [cs.tMBGGrid]: grid && childLen > 1 })} {...props}>
            {grid && childLen > 1 ? (
                <Row gutter={gutter}>
                    {
                        Children.map(children, (child, idx) => {
                            const marginBottom = Math.ceil((idx + 1) / grid) < Math.ceil(childLen / grid) ? gutter : undefined;
                            return (
                                <Col span={24 / grid} style={{ marginBottom }}>
                                    {child}
                                </Col>
                            );
                        }) as any
                    }
                </Row>
            ) : (
                children
            )}
        </div>
    );
}
interface IElemTypeProps {
    elementType?: Parameters<typeof createElement>[0];
}
/**
 * 块组件
 */
export function TBlock({ className, onClick, elementType = "div", noHover, ...props }: React.AllHTMLAttributes<any> & IElemTypeProps & { noHover?: boolean }) {
    const _className = csn(cs.tBlock, { [cs.link]: !!onClick }, className);
    return createElement(elementType, {
        className: csn("t-block", _className, noHover && "t-block-no-hover"),
        onClick,
        ...props,
    } as any);
}

export interface ITMenuProps extends MenuProps {
    children?: any;
    type?: "isolated" | "default";
}
export function TMenu({ className, type = "default", ...props }: ITMenuProps) {
    return <Menu className={csn("t-menu", className, cs.tMenu, cs[type])} {...props} />;
}

export function TSubMenu({ className, ...props }: SubMenuProps & { children?: any }) {
    return <SubMenu className={csn("t-submenu", cs.tSubMenu, className)} {...props} />;
}

export function TMenuItem({ className, children, ...props }: MenuItemProps) {
    return (
        <Menu.Item className={csn("t-menu-item", className, cs.tMenuItem)} {...props}>
            {children}
        </Menu.Item>
    );
}

export function TSwitch({ className, ...props }: SwitchProps) {
    return <Switch {...props} className={csn(cs.tSwitch, className)} />;
}

export type ITButtonProps = Omit<ButtonProps, "type"> & { squared?: boolean; type?: "bordered" | "blue" | ButtonProps["type"] };

/**
 * 仅对镜头的配置字段进行计算修改，不允许在这里处理时间函数和复杂数据类型。
 * @param param0
 * @returns
 */
function calcTBtnStaticProps({ className, type, size, squared, ...props }: ITButtonProps) {
    let typeClass;
    switch (type) {
        case "bordered":
        case "blue":
            typeClass = `t-button-${type}`;
            break;
    }
    return {
        className: csn("t-button", className, size && cs[size], typeClass, squared && "t-btn-squared"),
        type: (type === "bordered" ? undefined : type) as any,
        size,
        ...props,
    };
}
export function TButton({
    href,
    target,
    onClick,
    ...props
}: Omit<ITButtonProps, "href"> & { href?: LocationDescriptor; target?: React.AnchorHTMLAttributes<HTMLAnchorElement>["target"] }) {
    return createElement(Button, {
        onClick: useCallback(
            (e) => {
                if (href) {
                    if (typeof href === "string" && !href.startsWith("/")) {
                    } else {
                        e.preventDefault();
                        history.push(href);
                    }
                }
                return onClick?.(e);
            },
            [href, onClick],
        ),
        href: typeof href === "object" ? href.pathname : href,
        target,
        ...(target === "_blank" ? { rel: "noreferrer" } : {}),
        ...calcTBtnStaticProps(props),
    });
}

TButton.Group = Button.Group;

export type TSelectProps<VT> = SelectProps<VT> & { fullWidth?: boolean };
export function TSelect<VT extends SelectValue>({
    className,
    children,
    dropdownClassName,
    fullWidth,
    size,
    style = {},
    suffixIcon = <Iconfont icon={"icon_More_down"} />,
    onBlur,
    onFocus,
    showSearch,
    ...props
}: TSelectProps<VT>) {
    let [focused, setFocused] = useState<boolean>(false);
    const _onBlur = useCallback(
        (e) => {
            setFocused(false);
            onBlur?.(e);
        },
        [onBlur],
    );
    const _onFocus = useCallback(
        (e) => {
            setFocused(true);
            onFocus?.(e);
        },
        [onFocus],
    );
    return (
        <Select<VT>
            className={csn("t-select", className, size && `size-${size}`)}
            dropdownClassName={csn(dropdownClassName, "t-select-drop", size && `size-${size}`)}
            style={{ ...style, ...(fullWidth ? { width: "100%" } : {}) }}
            size={size}
            suffixIcon={focused && showSearch ? <Iconfont icon={"icon_search"} /> : suffixIcon}
            menuItemSelectedIcon={<Iconfont icon={"icon_checked"} />}
            onBlur={_onBlur}
            onFocus={_onFocus}
            showSearch={showSearch}
            {...props}
        >
            {children}
        </Select>
    );
}
TSelect.Option = Select.Option;

export function TCarousel({ className, dotTheme = "dark", dots = true, ...props }: CarouselProps & { dotTheme?: "dark" | "white" }) {
    return (
        <Carousel
            className={csn("t-carousel", className, cs.tCarousel)}
            dots={
                dots === false
                    ? dots
                    : {
                          className: csn(cs.tCarouselDots, (dots as any)?.className, cs[`dot_${dotTheme}`]),
                      }
            }
            {...props}
        />
    );
}

export function TTabs({ className, mode, ...props }: TabsProps & { mode?: "fullHeight" }) {
    return <Tabs className={csn("t-tabs", className, mode)} {...props} />;
}
TTabs.TabPane = Tabs.TabPane;

interface ITLoadingProps extends Omit<React.HTMLAttributes<HTMLSpanElement>, "className"> {
    className?: string;
    spin?: boolean;
    size?: "large" | "medium" | "small";
}
export function TLoading({ className, spin = true, size, ...props }: ITLoadingProps) {
    return <span className={csn("t-loading", spin && "anticon-spin", className, cs.tLoading, size)} {...props} />;
}

/**
 * 单纯的蒙层loading，摒弃 TSpin的复杂嵌套结构。
 * @param param0
 * @returns
 */
export function TMaskLoading({
    type = "div",
    children,
    className,
    spinning,
    size,
    loadingClassName,
    ...props
}: React.HTMLAttributes<HTMLSpanElement> & { type?: keyof React.ReactHTML; loadingClassName?: string; spinning?: boolean } & Pick<ITLoadingProps, "size">) {
    return createElement(
        type,
        { className: csn("t-mask-loading", className), ...props },
        <>
            {children}
            <div className={csn("t-mask-loading-mask", spinning && "spinning")}>
                <TLoading spin={spinning} size={size} className={loadingClassName} />
            </div>
        </>,
    );
}

export function TSpin({
    iconProps = {},
    className,
    style,
    children,
    maskTransparent,
    wrapperClassName,
    tip,
    ...props
}: SpinProps & { iconProps?: ITLoadingProps; children?: any; maskTransparent?: boolean }) {
    // 根据icon的size，计算新的 icon 的居中边距调节值
    const { size, ...iconRestProps } = iconProps;
    let iconHalfSize = 20;
    switch (size) {
        case "small":
            iconHalfSize = 15;
            break;
        case "large":
            iconHalfSize = 34;
            break;
        default:
            iconHalfSize = 20;
            break;
    }
    return (
        <Spin
            className={csn("t-spin", className)}
            // 当没有包裹子节点时，默认去掉蒙层
            wrapperClassName={csn("t-spin-wrapper", wrapperClassName, (maskTransparent || !children) && "transparent")}
            indicator={<TLoading size={size} style={{ marginTop: -iconHalfSize + (tip ? -20 : 0), marginLeft: -iconHalfSize }} {...iconRestProps} />}
            children={children ? children : style?.height || style?.minHeight ? <div style={style}></div> : undefined}
            style={style}
            tip={tip}
            {...props}
        />
    );
}

export function TTable<T extends object = any>({ className, loading = false, ...props }: TableProps<T>) {
    return (
        <Table<T>
            className={csn("t-table", className, cs.tTable)}
            loading={
                loading === true
                    ? {
                          indicator: <TLoading />,
                      }
                    : loading
            }
            {...props}
        />
    );
}
// TODO: 完善该组件其他样式功能
export function TTypography({ className, ...p }: TypographyProps) {
    return <Typography className={csn("t-typography", className)} {...p} />;
}

TTypography.TText = ({ className, ...p }: TextProps) => <Typography.Text className={csn("t-typography-text", className)} {...p} />;
TTypography.TTitle = Typography.Title;
TTypography.TParagraph = Typography.Paragraph;
export function TRadio({ className, ...props }: RadioProps) {
    return <Radio className={csn("t-radio", className, cs.tRadio)} {...props} />;
}

TRadio.Button = Radio.Button;

interface TRadioGroupProps extends RadioGroupProps {
    type?: "separate" | "collective"; // 两种模式
}

TRadio.Group = ({ className, type = "separate", ...props }: TRadioGroupProps) => {
    return <Radio.Group className={csn("t-radio-group", className, cs.tRadioGroup, cs[`tRadioGroup_${type}`])} {...props} />;
};

export function TCheckbox({ className, ...props }: CheckboxProps) {
    return <Checkbox className={csn("t-checkbox", className)} {...props} />;
}

TCheckbox.Group = Checkbox.Group;
TCheckbox.TGroup = ({ className, error, ...props }: PropsWithChildren<CheckboxGroupProps & { error?: ReactNode }>) => {
    return (
        <>
            <Checkbox.Group className={csn("t-checkbox-group", className)} {...props} />
            {error && (
                <Typography.Text type="danger" style={{ fontWeight: "bold" }}>
                    {error}
                </Typography.Text>
            )}
        </>
    );
};

type InputType = "input" | "textarea";
export type TInputProps = InputProps & { error?: string; inputType?: InputType; wrapperClassName?: string; alignEnd?: boolean };
export type TInputTextAreaProps = TextAreaProps & { error?: string; inputType?: InputType; wrapperClassName?: string; alignEnd?: boolean };

function PlaceholderWrapper(props: TInputProps | TInputTextAreaProps) {
    const { inputType = "input", value, wrapperClassName, className, onFocus, onBlur, onChange, placeholder, error, alignEnd, ...resetProps } = props;

    const [focused, setFocused] = useState(false);
    function _onFocus(e) {
        setFocused(true);
        onFocus && onFocus(e);
    }
    function _onBlur(e) {
        setFocused(false);
        onBlur && onBlur(e);
    }
    const innerValue = useRef(value);
    useEffect(() => {
        innerValue.current = value;
    }, [value]);
    function _onChange(e) {
        innerValue.current = e.target.value;
        onChange?.(e);
    }

    const hasOutValue = props.hasOwnProperty("value");
    /**
     * show up rules:
     * - has value and error;
     * - has value and placeholder;
     * - focused;
     */
    const showPlaceholderUp = useMemo(() => {
        let hasValue = !!value;
        if (!hasOutValue) {
            // 非受控组件的情况
            hasValue = !!innerValue.current;
        }
        return focused || (hasValue && !!error) || (hasValue && placeholder);
    }, [value, hasOutValue, focused, error, placeholder]);
    return (
        <div className={csn("t-input-wrapper", wrapperClassName, error ? "t-input-wrapper-has-error" : undefined)}>
            {inputType === "textarea" ? (
                <Input.TextArea
                    value={value}
                    onFocus={_onFocus}
                    onBlur={_onBlur}
                    onChange={_onChange}
                    className={csn("t-input-textarea", className)}
                    {...(resetProps as TextAreaProps)}
                />
            ) : (
                <Input value={value} onFocus={_onFocus} onBlur={_onBlur} onChange={_onChange} className={csn("t-input", className)} {...(resetProps as InputProps)} />
            )}
            <div
                className={csn("t-input-wrapper-placeholder", showPlaceholderUp && "t-input-wrapper-placeholder-up", {
                    ["t-input-wrapper-placeholder-up-end"]: alignEnd,
                })}
            >
                <span>{error || placeholder}</span>
            </div>
        </div>
    );
}
export function TInput(props: TInputProps) {
    return <PlaceholderWrapper inputType="input" {...props} />;
}
TInput.TextArea = (props: TInputTextAreaProps) => {
    return <PlaceholderWrapper inputType="textarea" {...props} />;
};

const TPassword: React.FC<TInputProps> = ({ className, ...resetProps }) => {
    const [showPwd, { toggle: toggleShowPwd }] = useToggle();

    return (
        <div className={csn("t-password", cs.tPassword, className)}>
            <TInput {...resetProps} type={showPwd ? undefined : "password"} />
            <div className={cs.showPwd}>
                <Iconfont icon={showPwd ? "icon_input_visible" : "icon_input_invisible"} onClick={toggleShowPwd} />
            </div>
        </div>
    );
};
TInput.TPassword = TPassword;

export const CTX_FormItem = createContext(
    {} as { formInstance: Omit<FormInstance, "scrollToField" | "__INTERNAL__">; formItemProps: Omit<FormItemProps, "children" | "className"> },
);

/**
 * 包装 Form表单
 * @param param0
 * @returns
 */
export function TForm({ className, ...props }: PropsWithChildren<FormProps>) {
    return <Form className={csn(className, "t-form")} {...props} />;
}
/**
 * 该组件使用RenderProps形式，结合context，共享FormItem的配置给受包裹的输入组件。
 * 并且不会显示错误提示。
 * @param param0
 */
TForm.TItem = ({ children, className, name, validateStatus, labelHidden, ...formItemProps }: FormItemProps & { labelHidden?: boolean }) => {
    return (
        <Form.Item noStyle shouldUpdate>
            {(formInstance) => {
                const { getFieldError } = formInstance;
                const extraProps = {};
                // 根据验证的输出错误类型，输出不同级别的错误字段，默认为 error 类型
                extraProps[validateStatus || "error"] = !!name && getFieldError(name)[0];
                // 非完备的节点判别，暂时仅处理函数节点
                return (
                    <Form.Item name={name} className={csn("t-form-item", className, labelHidden && "t-form-item-label-hidden")} {...formItemProps}>
                        {isValidElement(children) ? cloneElement(children, extraProps) : children}
                    </Form.Item>
                );
            }}
        </Form.Item>
    );
};

export const TTooltip = (props: TooltipProps & { zIndex?: number }) => {
    return (
        <Tooltip {...props} overlayClassName={csn(props.overlayClassName, "t-tooltip", cs.tTooltip)}>
            {props.children}
        </Tooltip>
    );
};

export const TProgress = ({ className, ...resetProps }: ProgressProps) => {
    return <Progress {...resetProps} status="normal" className={csn(className, "t-progress", cs.tProgress)} />;
};

export interface ITMenuItemConf {
    key: string;
    name: any;
    path?: LinkProps["to"];
    icon?: React.ReactElement | IConNames;
    subMenus?: ITMenuItemConf[];
    onClick?: () => void;
    classNames?: string;
}
export interface ITNavigator extends ITMenuProps {
    title?: ReactNode;
    menus: ITMenuItemConf[];
}

const _menuGenerator = (menus: ITNavigator["menus"], selectedKeys: string[]) =>
    menus.map(({ key, name, icon, path, subMenus, onClick, classNames = "" }) => {
        const title = path ? (
            <TReportLink to={path}>
                {icon}
                {name}
            </TReportLink>
        ) : (
            <span>
                {React.isValidElement(icon) ? icon : icon && <Iconfont icon={icon} />}
                {name}
            </span>
        );
        return subMenus?.length ? (
            <TSubMenu key={key} title={title} className={`${subMenus.find((menu) => selectedKeys.includes(menu.key)) ? "t-navigator-selected-sub" : ""}`}>
                {_menuGenerator(subMenus, selectedKeys)}
            </TSubMenu>
        ) : (
            <TMenuItem key={key} className={csn("t-navigator-item", classNames)} onClick={onClick}>
                {title}
            </TMenuItem>
        );
    });

export const TPageTitle: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => <div className={csn("t-page-title", className)} {...props} />;

export const TNavigator = ({ className, menus, title, mode = "inline", ...props }: ITNavigator) => {
    const { selectedKeys = [] } = props;
    return (
        <div className={csn(className, "t-navigator", cs.tNavigator)}>
            {title && <TPageTitle style={{ paddingLeft: 20 }}>{title}</TPageTitle>}
            <TMenu mode={mode} {...props}>
                {_menuGenerator(menus, selectedKeys)}
            </TMenu>
        </div>
    );
};

export function TSearchInput({ className, ...props }: InputProps) {
    return <Input className={csn("t-search-input", className)} allowClear prefix={<Iconfont icon={"icon_search"} />} {...props} />;
}

export function TLayout({ className, transparent = true, ...props }: LayoutProps & { transparent?: boolean }) {
    return <Layout className={csn("t-layout", transparent && "transparent", className)} {...props} />;
}
TLayout.TSider = ({ className, transparent = true, spaceLeft, spaceRight, style, ...props }: SiderProps & { transparent?: boolean; spaceLeft?: number; spaceRight?: number }) => {
    return (
        <Layout.Sider className={csn("t-sider", transparent && "transparent", className)} style={{ ...{ marginLeft: spaceLeft, marginRight: spaceRight }, ...style }} {...props} />
    );
};
TLayout.TContent = ({ className, ...props }: SiderProps) => {
    return <Layout.Content className={csn("t-content", className)} {...props} />;
};

export const TSteps = ({ className, children, ...resetProps }: StepsProps & { children?: React.ReactNode }) => {
    return (
        <Steps {...resetProps} className={csn("t-steps", cs.tSteps, className)}>
            {children}
        </Steps>
    );
};
TSteps.Step = Steps.Step;
export function TDateRangePicker({ className, dropdownClassName, ...otherProps }: RangePickerProps) {
    return <DatePicker.RangePicker className={csn("t-date-range-picker", className)} dropdownClassName={csn("t-date-range-picker-dropdown", className)} {...otherProps} />;
}

export function TPopover({ overlayClassName, ...props }: PopoverProps) {
    return <Popover overlayClassName={csn("t-popover-overlay", overlayClassName)} {...props} />;
}

export function TTag({ className, ...props }: TagProps) {
    return <Tag className={csn("t-tag", className)} {...props} />;
}

export function TAlert({ className, ...props }: AlertProps) {
    return <Alert className={csn("t-alert", className)} {...props} />;
}

// 面包屑导航
export function TBreadcrumb({ className, ...props }: BreadcrumbProps & { children: ReactNode }) {
    return <Breadcrumb className={csn("t-breadcrumb", className)} {...props} />;
}
// 劫持 href 的设置。原设置是a标签默认行为。
TBreadcrumb.Item = ({ href, children, ...props }: BreadcrumbItemProps & { children: ReactNode }) => (
    <Breadcrumb.Item {...props}>{href ? <TReportLink to={href}>{children}</TReportLink> : children}</Breadcrumb.Item>
);
TBreadcrumb.Separator = Breadcrumb.Separator as any;

export const TDatePicker: React.FC<DatePickerProps & { error?: string }> = ({ className, placeholder, error, ...resetProps }) => {
    return <DatePicker {...resetProps} className={csn(".t-date-picker", cs.tDatePicker, className)} placeholder={error ?? placeholder} />;
};

/**
 * 自定义主题图标访问组件
 */
export function Iconfont({ icon, className, size, style = {}, ...props }: IIconProps & React.HTMLAttributes<HTMLSpanElement>) {
    return <span className={csn("iconfont", `icon-${icon}`, className)} style={{ fontSize: size, ...style }} {...props}></span>;
}
