import { usePersistFn } from "@gcer/react-air";
import { useDataProvider } from "commonUse";
import { useCallback, useRef, useState } from "react";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import useAccountInfo from "commonUse/useAccountInfo";
import { ExchangeOrder, ManualOrderRequest, ManualOrderStatus } from "TradeAPILib";
import { requestWithRetry } from "trade_utils_lib";

export function useFetchManualOrders() {
    const accountInfo = useAccountInfo();
    const dataProvider = useDataProvider(accountInfo.firstApiKey);
    const orderSubjectRef = useRef(
        new BehaviorSubject<{
            orders: ExchangeOrder[];
            hasMore: boolean;
        }>({
            orders: [],
            hasMore: true,
        }),
    );
    const [loading, setLoading] = useState<boolean>();
    const [resetLoading, setResetLoading] = useState<boolean>(false);
    const observeRef = useRef<Subscription>();
    const fetch = usePersistFn(async ({ params, reset }: { params?: Partial<ManualOrderRequest>; reset?: boolean }) => {
        setLoading(true);

        if (reset) {
            setResetLoading(true);

            if (observeRef.current) {
                observeRef.current.unsubscribe();
                observeRef.current = undefined;
            }
        }

        const currentOrders = orderSubjectRef.current.getValue().orders;
        const tempParams: ManualOrderRequest = {
            limit: 20,
            includeStrategy: false,
        } as ManualOrderRequest;
        if (!reset) {
            if (currentOrders.length > 0) {
                tempParams.fromId = currentOrders[currentOrders.length - 1].id;
                tempParams.direct = "prev";
            }
        }

        const mergedParams = Object.assign(tempParams, params);

        observeRef.current = requestWithRetry(() => dataProvider?.api.queryManualOrders(mergedParams)!).subscribe(
            (result) => {
                let nextOrders: ExchangeOrder[];
                if (reset) {
                    nextOrders = result || [];
                } else {
                    nextOrders = [...currentOrders, ...(result || [])];
                }
                orderSubjectRef.current.next({
                    orders: nextOrders,
                    hasMore: result?.length === mergedParams.limit,
                });
                setLoading(false);
            },
            (e) => {
                console.log(e);
            },
            () => {
                setResetLoading(false);
                setLoading(false);
            },
        );
    });

    const getOrdersObserve = useCallback(() => {
        return orderSubjectRef.current;
    }, []);
    return {
        getOrdersObserve,
        loading,
        resetLoading,
        fetch,
    };
}
