import { Button, message, Modal } from "@pionex-web-kit/components";
import { RestrictData } from "account/types";
import { Checkbox } from "antd";
import TReportLink from "components/v2/TinyComps";
import Decimal from "decimal.js";
import { PionexReport } from "landings/Analytics/Report";
import { DWType, useDWPageContext } from "landings/DepositWithdraw";
import { BalanceTipView } from "landings/DepositWithdraw/components/Tips";
import { AssetAddressItem, WhiteListStatus } from "landings/V2/Address/api";
import PubSub from "pubsub-js";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useToggle } from "src/commonUse/tools";
import { useDWCoinBalance } from "src/commonUse/useDW";
import useKycV2Info, { KycV2Level } from "src/commonUse/useKycInfoV2";
import { SHOW_GA_BIND_GUIDE_REF } from "src/components-v2/Layout/GlobalMessages/GABindGuide";
import { PrimaryButton } from "src/components/LoginDialog";
import { SelfBeautyInput } from "src/components/SelfInput/SelfInput";
import { history } from "utils/History";
import NumberUtils from "utils/NumberUtils";
import cs from "../../../../withdraw.module.less";
import { WITH_DRAW_SUCCESS } from "../../../DWHistory";
import { IWithdrawDataProps, WithdrawSuccessModal } from "../../../WithdrawSuccess";
import AddressBookSeletor from "../AddressBookSeletor";
import { useDataProviderOnFirstApiKey } from "commonUse";
import { useCheckQuestionnaire, useFuncInterruptByQues } from "utils/questionaire";
import { SafeDecimal } from "trade_utils_lib";
import { useTranslators } from "commonUse/Locale";
import useAccountInfo from "commonUse/useAccountInfo";
import useDateFormatter from "commonUse/useDateFormatter";
import { parseWithdrawContent } from "account/hook";
import { OtpState } from "state/AccountInfo";
import { useBalanceAll } from "commonUse/useBalance";
import useExchangeRate from "commonUse/useExchangeRate";
import { AccountService, VerifyCodeAction } from "bu_account_js_sdk";
import WithdrawVerifyProcess from "src/components-v2/WitdhdrawVerifyProcess";
import { mergeMap } from "rxjs/operators";
import { of } from "rxjs";

interface Props {
    errorMsg: string;
    restrictData?: RestrictData;
}

/**
 * @description: 提币确认弹窗
 */
export function WithdrawConfirm({ visible, setVisible, $st, loading, params, onOk, memoName }) {
    const { coin, coinName, address, memo, availableQuantity, withdrawQuantity, fee, comment } = params;
    return (
        <Modal
            visible={visible}
            title={$st("withdraw.submit.confirm.title", params)}
            okText={$st("withdraw.submit.confirm.continue")}
            cancelText={$st("withdraw.submit.confirm.cancel")}
            onOk={() => {
                onOk?.();
            }}
            okButtonProps={{
                loading,
            }}
            onCancel={() => {
                setVisible(false);
            }}
        >
            <div>
                <div>
                    {$st("withdraw.submit.confirm.content.coin")}: {coin} - {coinName}
                </div>
                {comment && (
                    <div>
                        {$st("withdraw.submit.confirm.content.comment")}: {comment}
                    </div>
                )}
                <div>
                    {$st("withdraw.submit.confirm.content.address")}: {address}
                </div>
                {memo && (
                    <div>
                        {memoName}: {memo}
                    </div>
                )}
                <div>
                    {$st("withdraw.submit.confirm.content.availableQuantity")}: {availableQuantity} {coin}
                </div>
                <div>
                    {$st("withdraw.submit.confirm.content.withdrawQuantity")}: {withdrawQuantity} {coin}
                </div>
                <div>
                    {$st("withdraw.submit.confirm.content.fee")}: {fee} {coin}
                </div>
            </div>
        </Modal>
    );
}

export const WithdrawPage = (props: Props) => {
    const { $st } = useTranslators();
    const { errorMsg, restrictData } = props;
    const accountInfo = useAccountInfo();
    const { coinList, currentCoin, addressInitialData, changeAddressInitialData, onlyWhiteList, addressList, dailyWithdrawLeft, refreshDailyWithdrawLeft } = useDWPageContext();
    const [, { setFalse: closeChangeConfirm }] = useToggle();
    const willUnMount = useRef(false);
    const [coinBalance] = useDWCoinBalance(accountInfo.firstApiKey, currentCoin ? currentCoin.coinName : "", DWType.withdraw);
    const [addressText, changeAddressText] = useState(undefined as undefined | string);
    const [quantityText, changeQuantityText] = useState(undefined as undefined | string);
    const [memoText, changeMemoText] = useState(undefined as undefined | string);
    const [checkNoMemo, changeCheckNoMemo] = useState(false);
    const [addressError, changeAddressError] = useState(undefined as undefined | string);
    const [quantityError, changeQuantityError] = useState(undefined as undefined | string);
    const [memoError, changeMemoError] = useState(undefined as undefined | string);
    const [successData, setSuccessData] = useState<IWithdrawDataProps>();
    const [withdrawSuccessModalVisible, setWithdrawSuccessModalVisible] = useState<boolean>();
    const [withdrawConfirmVisible, setWithdrawConfirmVisible] = useState<boolean>();
    const [confirmLoading, setConfirmLoading] = useState<boolean>();
    const [startVerifyProcess, setStartVerifyProcess] = useState(false);
    const [{ currentLevel }] = useKycV2Info();
    const dateFormatter = useDateFormatter();
    const useTag = useMemo(() => {
        return currentCoin && currentCoin.withdraw_memo;
    }, [currentCoin]);
    useCheckQuestionnaire();
    const interruptByQues = useFuncInterruptByQues();

    const fullBalance = useBalanceAll();
    const exchangeRate = useExchangeRate();
    const totalBalance = useMemo(() => (fullBalance?.total?.totalInUSD || 0) * exchangeRate.exchangeRate, [exchangeRate.exchangeRate, fullBalance?.total?.totalInUSD]);

    useEffect(() => {
        if (errorMsg) {
            message.error(errorMsg);
        }
    }, [errorMsg]);

    useEffect(() => {
        if (!restrictData?.restrict_withdraw) {
            return;
        }
        const contentStr = parseWithdrawContent(restrictData, $st, dateFormatter);
        Modal.warn({
            title: $st("withdraw_forbidden_title"),
            content: contentStr,
            mobileSwitch: "drawer",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restrictData]);

    const memoName = React.useMemo(() => {
        return currentCoin.currency === "XRP" ? "Tag" : "Memo";
    }, [currentCoin.currency]);

    const addressPlaceholder = useMemo(() => {
        return addressError || $st("withdraw.input.address");
    }, [$st, addressError]);

    const memoPlaceholder = useMemo(() => {
        return memoError || memoName;
    }, [memoError, memoName]);

    const quantityPlaceholder = useMemo(() => {
        return quantityError || $st("withdraw.input.quantity");
    }, [$st, quantityError]);

    const willGetValue = useMemo(() => {
        if (quantityText && !quantityError) {
            let val;
            try {
                val = NumberUtils.effectiveDecimal(new Decimal(quantityText).sub(currentCoin.fee), currentCoin.withdrawPrecision);
            } catch (e) {
                console.error(e);
            }
            return val;
        } else {
            return undefined;
        }
    }, [currentCoin.fee, quantityText, currentCoin.withdrawPrecision, quantityError]);

    const onAddressChanged = useCallback(
        (text: string, type?: string) => {
            changeAddressText(text);
            // input输入的情况要把addressInitialData清空
            if (type === "input" && addressInitialData?.address) {
                changeAddressInitialData(undefined);
            }
        },
        [addressInitialData?.address, changeAddressInitialData],
    );
    useEffect(() => {
        let errMsg;
        if (addressText && addressText.length > 0) {
            if (addressText.length < 2) {
                errMsg = $st("withdraw.limit.address_len_error");
            } else if (currentCoin.currency === "USDTERC20" && !/^(0x|0X)/.test(addressText)) {
                errMsg = $st("withdraw.limit.address_ERC20_error");
            } else if (currentCoin.currency === "USDT" && /^(0x|0X)/.test(addressText)) {
                errMsg = $st("withdraw.limit.address_OMINI_error");
            } else if (currentCoin.currency === "BTC" || currentCoin.currency === "USDT") {
                const addressUpper = addressText.toUpperCase();
                if (addressUpper.startsWith("BC3")) {
                    errMsg = $st("withdraw_btc_lighting_invalid", { coin: currentCoin ? currentCoin.coinName : "" });
                }
            }
        }
        if (errMsg) {
            changeAddressError(errMsg);
        } else {
            changeAddressError(undefined);
        }
    }, [addressText, currentCoin, addressInitialData?.address, changeAddressInitialData, $st]);

    const onMemoChanged = useCallback((text: string) => {
        changeMemoText(text);
        if (text) {
            changeMemoError(undefined);
        }
    }, []);

    const checkBalanceEnough = useCallback(
        (quantity: string) => {
            let errorMsg;
            if (quantity) {
                const value = Number(quantity);
                if (!isNaN(value)) {
                    if (coinBalance.available === undefined) {
                        errorMsg = $st("withdraw.limit.nobalance");
                    } else if (value > coinBalance.available) {
                        if (coinBalance.available === 0) {
                            errorMsg = $st("withdraw.limit.nobalance");
                        } else {
                            errorMsg = $st("withdraw.limit.balance", {
                                balance: NumberUtils.effectiveDecimal(coinBalance.available, 8),
                                coin: currentCoin.coinName,
                            });
                        }
                    }
                }
            }

            return errorMsg;
        },
        [$st, coinBalance.available, currentCoin.coinName],
    );

    const onQuantityChanged = useCallback((text: string) => {
        changeQuantityText(/^\..*/.test(text) ? `0${text}` : text);
    }, []);
    useEffect(() => {
        let errorMsg;
        if (quantityText && quantityText.length > 0) {
            const limits = currentLevel === KycV2Level.oldLv2 ? currentCoin.limits.kycWithdraw : currentCoin.limits.withdraw;
            const min = limits.min;
            let max = Number(currentLevel) < KycV2Level.lv0 ? limits.max : dailyWithdrawLeft;
            const value = Number(quantityText);
            if (isNaN(value)) {
                errorMsg = $st("withdraw_amount_invalid");
            } else {
                const quantityDecimal: Decimal = new Decimal(value);
                if (value < min) {
                    errorMsg = $st("withdraw.limit.min", { min, coin: currentCoin.coinName });
                } else if (value > max) {
                    errorMsg = $st("withdraw.limit.max", { max, coin: currentCoin.coinName });
                } else if (checkBalanceEnough(quantityText)) {
                    errorMsg = checkBalanceEnough(quantityText);
                } else if (quantityDecimal.dp() > currentCoin.withdrawPrecision) {
                    errorMsg = $st("withdraw.limit.precision", { precision: currentCoin.withdrawPrecision });
                } else {
                    errorMsg = undefined;
                }
            }
        }
        if (errorMsg) {
            changeQuantityError(errorMsg);
        } else {
            changeQuantityError(undefined);
        }
    }, [
        currentCoin.limits.kycWithdraw,
        currentCoin.limits.withdraw,
        currentCoin.withdrawPrecision,
        currentCoin.coinName,
        checkBalanceEnough,
        quantityText,
        currentLevel,
        dailyWithdrawLeft,
        $st,
    ]);

    // 切换币种
    useEffect(() => {
        onAddressChanged("");
        onMemoChanged("");
        onQuantityChanged("");
        changeCheckNoMemo(false);
    }, [currentCoin?.currency, onAddressChanged, onMemoChanged, onQuantityChanged]);
    // 把AddressInitialData过来的地址信息初始化
    useEffect(() => {
        if (addressInitialData?.address) {
            onAddressChanged(addressInitialData.address);
            addressInitialData?.memo && onMemoChanged(addressInitialData?.memo);
        }
    }, [addressInitialData, onMemoChanged, onAddressChanged]);
    // 币种选择器切换币种之后需要释放AddressInitialData初始值
    useEffect(() => {
        if (addressInitialData?.currency && currentCoin?.currency && addressInitialData.currency !== currentCoin.currency) {
            onAddressChanged("");
            onMemoChanged("");
            changeAddressInitialData(undefined);
        }
    }, [currentCoin?.currency, addressInitialData?.currency, changeAddressInitialData, onMemoChanged, onAddressChanged]);
    // 在unmout的时候需要清理掉AddressInitialData带过来的地址
    useEffect(() => {
        // 刷新提币余额
        refreshDailyWithdrawLeft();
        return () => {
            if (willUnMount.current === false) {
                changeAddressInitialData(undefined);
                willUnMount.current = true;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onSubmitted = async () => {
        const isBCH = currentCoin.coinName === "BCH" && /^(p|q|P|Q)/.test(addressText || "");
        if (isBCH) {
            message.error($st("unsupported_address"));
            return;
        }

        // 检查是否绑定了2fa
        if (totalBalance >= 1000 && SHOW_GA_BIND_GUIDE_REF.showIfNeed(true)) {
            return;
        }
        // 检查是否被限制提币
        if (restrictData?.restrict_withdraw) {
            const contentStr = parseWithdrawContent(restrictData, $st, dateFormatter);
            Modal.warn({
                title: $st("withdraw_forbidden_title"),
                content: contentStr,
                mobileSwitch: "drawer",
            });
            return;
        }
        ///> check value
        let neeAbort = false;
        if (!addressError && !addressText) {
            changeAddressError($st("withdraw.input.address.error"));
            neeAbort = true;
        }
        if (useTag && !checkNoMemo && !memoText) {
            changeMemoError($st("withdraw_address_memo_error", { memoName }));
            neeAbort = true;
        }
        if (!quantityError) {
            if (!quantityText) {
                changeQuantityError($st("withdraw.input.quantity.error"));
                neeAbort = true;
            } else {
                const checkBalanceErr = checkBalanceEnough(quantityText);
                if (checkBalanceErr) {
                    changeQuantityError(checkBalanceErr);
                    neeAbort = true;
                }
            }
        }

        if (neeAbort || quantityError || addressError || memoError) {
            message.error($st("deposit_withdraw_submitted_error"));
            return;
        }

        if (totalBalance >= 1000 && accountInfo.otpState !== OtpState.BOUND) {
            ///> 二次验证没有绑定或者确认
            message.error($st("withdraw_failed_cause_otp_not_bind"));
            return;
        }

        if (!dataProvider || !quantityText || !accountInfo.userId) return;
        // 用来判断是否触发限额的值
        const checkOversizeAmount = currentCoin.currency.match("USD")
            ? quantityText // 如果是usd类型的币，直接比较是否触发限额
            : await dataProvider.api
                  .queryTickerByRest(currentCoin.coinName, "USD") // 没找到usd对应币对时，再找USDT的币对
                  .pipe(mergeMap((res) => (!res ? dataProvider.api.queryTickerByRest(currentCoin.coinName, "USDT") : of(res))))
                  .toPromise()
                  .then((res) => new SafeDecimal(quantityText).mul(res?.fiat || 1).valueOf());
        if (await interruptByQues("coin", checkOversizeAmount)) {
            return;
        }

        setWithdrawConfirmVisible(true);
    };

    const closeSuccessModal = useCallback(() => {
        setWithdrawSuccessModalVisible(false);
        // 刷新提币历史
        PubSub.publishSync(WITH_DRAW_SUCCESS, {});
        // 刷新提币余额
        refreshDailyWithdrawLeft();
    }, [refreshDailyWithdrawLeft]);
    const onAddressBookSelect = useCallback(
        (address: AssetAddressItem) => {
            let coinInfo = coinList.find((item) => {
                return item.coinName === address.coin && (!address.chain || item.protocol === address.chain);
            });
            if (coinInfo) {
                const data = {
                    currency: coinInfo.currency,
                    coin: coinInfo.coinName,
                    chain: coinInfo.protocol,
                    address: address.address,
                    memo: address.memo,
                    placeholder: address.tag,
                    address_id: address.address_id,
                };
                if (coinInfo.currency !== currentCoin.currency) {
                    history.push(`/balance/withdraw/${coinInfo.currency}`, { addressInitialData: data });
                } else {
                    changeAddressInitialData(data);
                }
            }
        },
        [changeAddressInitialData, coinList, currentCoin.currency],
    );
    /**
     * @description: 验证初始化事件
     * @param {*} useCallback
     * @return {*}
     */
    const verifyOnLoad = useCallback(() => {
        setConfirmLoading(false);
        setWithdrawConfirmVisible(false);
    }, []);
    /**
     * @description: 验证报错
     * @param {*} useCallback
     * @return {*}
     */
    const verifyOnError = useCallback(() => {
        setConfirmLoading(false);
    }, []);
    /**
     * @description: 取消验证
     * @param {*} useCallback
     * @return {*}
     */
    const verifyOnCancel = useCallback(() => {
        setConfirmLoading(false);
        setStartVerifyProcess(false);
    }, []);
    /**
     * @description: 验证成功
     * @param {*} useCallback
     * @return {*}
     */
    const verifyOnSuccess = useCallback(() => {
        setStartVerifyProcess(false);
        const target = addressList.find((addr) => addr.address === addressText);
        if (currentCoin.currency && addressText) {
            setSuccessData({
                coin: currentCoin.currency,
                address: addressText,
                memo: memoText,
                chain: currentCoin.protocol,
                isFromAddressBook: !!target,
            });
            setWithdrawSuccessModalVisible(true);
            closeChangeConfirm();
            changeAddressText("");
            changeQuantityText("");
        }
    }, [addressList, addressText, closeChangeConfirm, currentCoin.currency, currentCoin.protocol, memoText]);

    const dataProvider = useDataProviderOnFirstApiKey();
    const withdrawParams = useMemo(
        () => ({
            address: addressText ?? "",
            amount: quantityText ?? "",
            code: currentCoin.currency,
            ...(memoText ? { tag: memoText } : {}),
        }),
        [addressText, currentCoin.currency, memoText, quantityText],
    );
    const handleRequestWithdraw = useCallback((mfaParams) => AccountService.withdraw({ ...withdrawParams, txn_id: mfaParams.txn_id }), [accountInfo.firstApiKey, withdrawParams]);

    return (
        <div className={cs.withdraw}>
            {onlyWhiteList === WhiteListStatus.on && (
                <div className={cs.onlyWhiteList}>
                    {$st("only_use_whitelist_address_tips")}
                    <TReportLink to={"/balance/address_book/whitelist"}>{$st("white_list")}</TReportLink>
                </div>
            )}
            <div style={{ flex: 1, position: "relative" }}>
                <SelfBeautyInput
                    inPutClassName={cs.withdrawInputStyle}
                    value={addressText}
                    theme={addressError ? "danger-light" : undefined}
                    placeholder={addressInitialData?.placeholder || addressPlaceholder}
                    onValChange={(text: string) => onAddressChanged(text, "input")}
                    className={"addressbook-pop-container"}
                    disabled={onlyWhiteList === WhiteListStatus.on}
                />
                <div style={{ position: "absolute", right: 0, top: 0, bottom: 0 }}>
                    <AddressBookSeletor getPopupContainer={() => document.querySelector(".addressbook-pop-container") as HTMLDivElement} onSelect={onAddressBookSelect} />
                </div>
            </div>
            {useTag && (
                <div className={cs.editWrap}>
                    <div style={{ flex: 1 }}>
                        <SelfBeautyInput
                            inPutClassName={cs.withdrawInputStyle}
                            value={memoText}
                            disabled={checkNoMemo}
                            theme={memoError ? "danger-light" : undefined}
                            placeholder={memoPlaceholder}
                            onValChange={onMemoChanged}
                        />
                    </div>

                    <div
                        style={{
                            marginLeft: 20,
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <Checkbox
                            onChange={(e) => {
                                changeCheckNoMemo(e.target.checked);
                                changeMemoText("");
                                if (e.target.checked) {
                                    changeMemoError(undefined);
                                }
                            }}
                        >
                            {$st("withdraw_address_no_memo", { memoName })}
                        </Checkbox>
                    </div>
                </div>
            )}
            <div className={cs.editWrap}>
                <div style={{ flex: 2, position: "relative" }}>
                    <SelfBeautyInput
                        inPutClassName={cs.withdrawInputStyle}
                        value={quantityText}
                        theme={quantityError ? "danger-light" : undefined}
                        placeholder={quantityPlaceholder}
                        onValChange={onQuantityChanged}
                    />
                    <div style={{ position: "absolute", right: 0, top: 0, bottom: 0 }}>
                        <Button
                            type={"text"}
                            style={{ height: "100%", color: "#6d9fff" }}
                            onClick={() => {
                                const amount = coinBalance.available || 0;
                                const precision_amount = NumberUtils.effectiveDecimal(amount, currentCoin.withdrawPrecision);
                                changeQuantityText(precision_amount);
                                onQuantityChanged(precision_amount);
                            }}
                        >
                            {$st("withdraw.input.quantity.autoall")}
                        </Button>
                    </div>
                </div>
                <div className={cs.withdrawReceiveArea}>
                    <span>{$st("withdraw.fee.reduce.value")}</span>
                    <span>{`${willGetValue || "--"} ${currentCoin.coinName}`}</span>
                </div>
            </div>

            <div className={cs.editWrap} style={{ justifyContent: "flex-end" }}>
                <PrimaryButton onClick={onSubmitted} text={$st("withdraw.submit")} />
            </div>

            <div style={{ marginTop: 10 }}>
                <BalanceTipView currentCoin={currentCoin} dwType={DWType.withdraw} />
            </div>
            {/* 提币确认弹窗 */}
            {withdrawConfirmVisible !== undefined && (
                <WithdrawConfirm
                    visible={withdrawConfirmVisible}
                    setVisible={setWithdrawConfirmVisible}
                    $st={$st}
                    loading={confirmLoading}
                    params={{
                        coin: currentCoin.coinName,
                        coinName: currentCoin.fullName,
                        address: addressText!,
                        memo: memoText,
                        availableQuantity: coinBalance.available,
                        withdrawQuantity: NumberUtils.effectiveDecimal(quantityText!, currentCoin.withdrawPrecision),
                        fee: currentCoin.fee,
                        comment: addressInitialData?.placeholder,
                    }}
                    onOk={() => {
                        PionexReport.reportWithdrawInfo(currentCoin.currency, 1);
                        setConfirmLoading(true);
                        setStartVerifyProcess(true); // 开启提币验证流程
                    }}
                    memoName={memoName}
                />
            )}
            {/* 提币成功弹窗 */}
            {withdrawSuccessModalVisible !== undefined && <WithdrawSuccessModal visible={withdrawSuccessModalVisible} data={successData} onClose={closeSuccessModal} />}
            {startVerifyProcess && (
                <WithdrawVerifyProcess
                    action={VerifyCodeAction.withdraw}
                    bizParams={withdrawParams}
                    onRequestWithdraw={handleRequestWithdraw}
                    accountInfo={accountInfo}
                    onLoad={verifyOnLoad}
                    onSuccess={verifyOnSuccess}
                    onError={verifyOnError}
                    onCancel={verifyOnCancel}
                />
            )}
        </div>
    );
};
