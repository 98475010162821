import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { LANGUAGE_LOCALS_MAP } from "utils/i18n/config";
import { MessageHandler, SnsWebSdkBaseConfig } from "@sumsub/websdk";
import { useTranslators } from "commonUse/Locale";
import { Modal, useModal } from "@pionex-web-kit/components";
import { SnsError } from "@sumsub/websdk/types/types";
import { message } from "antd";
import { map, mergeMap, scan, skipWhile, take, tap } from "rxjs/operators";
import { interval, Subscription } from "rxjs";
import { AccountService, VerifyFaceResult } from "bu_account_js_sdk";
import { TSpin } from "components/v2/TinyComps";
import cs from "./index.m.less";
import { VerifyCodeAction } from "bu_account_js_sdk/src/AccountService";

const INTERVAL_TIMEOUT = "interval_timeout";

interface Props {
    txn_id: string;
    token: string;
    request_id: string;
    onTokenChange?: (token: string) => void;
    onSuccess?: () => void;
    onCancel?: () => void;
    usage: VerifyCodeAction;
}

/**
 * 人脸验证的弹窗
 */
const FaceVerifyModal: React.FC<Props> = ({ usage, token, request_id, txn_id, onTokenChange, onSuccess, onCancel }) => {
    const { $st } = useTranslators();
    const config = useConfig();
    const intervalResRef = useRef<Subscription>();
    const timer = useRef<ReturnType<typeof setTimeout> | undefined>();
    // 是否展示等待人脸结果的弹窗
    const [showWaitingModal, setShowWaitingModal] = useState(false);
    const [sumsubVisible, setSumsubVisible] = useState(true);
    const [modal, QuitTipModalContainer] = useModal();
    const [timeoutModal, TimeoutModalContainer] = useModal();
    const closeQuitTipModal = useRef<Function>(() => {});

    useEffect(() => {
        return () => {
            intervalResRef.current?.unsubscribe();
            if (timer.current) {
                clearTimeout(timer.current);
                timer.current = undefined;
            }
        };
    }, []);

    //  轮询结果中
    const [waitingResult, setWaitingResult] = useState(false);

    const refreshFaceToken = useCallback(() => {
        return AccountService.requestFaceParams({ txn_id: txn_id, usage })
            .pipe(
                map((item) => item.request_id),
                tap((res) => onTokenChange?.(res)),
            )
            .toPromise();
    }, [onTokenChange, txn_id, usage]);

    const waitingForVerifyFaceResult = useCallback(
        (showTip = false) => {
            // 轮询直到状态非pending
            setWaitingResult(true);
            const source = AccountService.verifyFaceResult({
                txn_id,
                usage: usage as any, // TODO 后续修改lib中的参数类型
                request_id,
            });
            intervalResRef.current?.unsubscribe();
            intervalResRef.current = interval(2000)
                .pipe(
                    scan((acc, curr) => acc + 1, 0),
                    tap((times) => {
                        // 如果是web端扫脸，10s 之后 展示等待人脸结果的弹窗提示
                        if (showTip && times > 5 && times < 90 && !showWaitingModal) {
                            setSumsubVisible(false);
                            setShowWaitingModal(true);
                        } else if (times > 90) {
                            throw new Error(INTERVAL_TIMEOUT);
                        }
                    }),
                    mergeMap(() => source),
                    skipWhile((res) => isNeedWaitFaceRes(res.result)),
                    take(1),
                )
                .subscribe(
                    (res) => {
                        if (res.result === VerifyFaceResult.PASSED) {
                            onSuccess?.();
                        } else {
                            message.error($st("face_verify_res_fail"));
                            onCancel?.();
                        }
                    },
                    (e) => {
                        if (e.message === INTERVAL_TIMEOUT) {
                            // 超时
                            message.error($st("face_verify_res_timeout"));
                            // 关闭其他弹窗
                            setSumsubVisible(false);
                            setShowWaitingModal(false);
                            closeQuitTipModal.current?.();
                            timeoutModal?.info({
                                zIndex: 1112,
                                title: $st("hint_modal_title"),
                                content: $st("face_verify_timeout_tip"),
                                onOk: () => {
                                    onCancel?.();
                                },
                            });
                        } else {
                            message.error($st("face_verify_res_fail"));
                            onCancel?.();
                        }
                    },
                );
        },
        [$st, onCancel, onSuccess, request_id, showWaitingModal, timeoutModal, txn_id, usage],
    );

    const handleMessage: MessageHandler = useCallback(
        (type, payload) => {
            switch (type) {
                // @ts-ignore
                case "idCheck.livenessCompleted":
                    // 人脸重试, 需要取消之前的轮训
                    // @ts-ignore
                    if (payload.answer === "RED") {
                        // @ts-ignore
                        if (!payload.allowContinuing) {
                            intervalResRef.current?.unsubscribe();
                            setWaitingResult(false);
                        } else {
                            // 最多扫脸三次，三次之后继续轮训结果
                            waitingForVerifyFaceResult(true);
                        }
                    }
                    break;
                case "idCheck.onReady":
                    // 计时30s, 开始轮训（因为有时候sumsub手机扫脸通过之后，web端收不到成功的消息）
                    if (timer.current) {
                        clearTimeout(timer.current);
                        timer.current = undefined;
                    }
                    timer.current = setTimeout(() => {
                        timer.current = undefined;
                        waitingForVerifyFaceResult();
                    }, 30000);

                    break;
                case "idCheck.actionCompleted":
                    if (timer.current) {
                        clearTimeout(timer.current);
                        timer.current = undefined;
                    }
                    waitingForVerifyFaceResult(true);
                    break;
            }
        },
        [waitingForVerifyFaceResult],
    );

    const onQuit = useCallback(() => {
        const res = modal.confirm({
            zIndex: 1111,
            title: $st("hint_modal_title"),
            content: $st("face_verify_waiting_quit_tip"),
            okText: "Waiting",
            cancelText: "Quit",
            onCancel: () => {
                onCancel?.();
            },
        });

        closeQuitTipModal.current = res.destroy;
    }, [onCancel, modal, $st]);

    return (
        <>
            <Modal
                zIndex={1010}
                className={cs.modal}
                width={700}
                visible={sumsubVisible}
                onCancel={onCancel}
                okButtonProps={{ style: { display: "none" } }}
                cancelButtonProps={{ loading: waitingResult }}
                closable={false}
                maskClosable={false}
            >
                <TSpin spinning={false}>
                    <SumsubWebSdk accessToken={token} expirationHandler={refreshFaceToken} config={config} onMessage={handleMessage} onError={errorHandler} />
                </TSpin>
            </Modal>

            <Modal zIndex={1011} visible={showWaitingModal} okButtonProps={{ style: { display: "none" } }} onCancel={onQuit} closable={false} maskClosable={false}>
                <div className={cs.waitingContainer}>
                    <div className={cs.waitingIcon}>
                        <img src={require("images/v2/dw/icon_apply_success_2.png")} alt="waiting" />
                    </div>
                    <div className={cs.tipText}>
                        <h3>{$st("face_verify_waiting_tip")}</h3>
                        <span>{$st("face_verify_waiting_times")}</span>
                    </div>
                </div>
            </Modal>
            {QuitTipModalContainer}
            {TimeoutModalContainer}
        </>
    );
};

export default FaceVerifyModal;

function useConfig() {
    const {
        intl: { locale, messages },
    } = useTranslators();
    return useMemo(() => {
        let lang = locale;
        /**
         * 受支持的语言信息
         * https://developers.sumsub.com/faq/#sdk-functionality
         */
        switch (locale) {
            case LANGUAGE_LOCALS_MAP.zhCN:
                lang = "zh";
                break;
            case LANGUAGE_LOCALS_MAP.enUS:
                lang = "en";
                break;
            case LANGUAGE_LOCALS_MAP.zhTW:
                lang = "zh-tw";
                break;
        }
        const sidePadding = 0;
        return {
            lang, //language of WebSDK texts and comments (ISO 639-1 format)
            // email: 'applicantEmail',
            // phone: 'applicantPhone',
            i18n: messages, //JSON of custom SDK Translations
            uiConf: {
                customCssStr: `
                .sumsub-logo {display: none;}
                .iframe2 {margin: 0;}
                .iframe2 .content {box-shadow: none;padding: 50px ${sidePadding}px;margin:0 auto;border-radius: 16px;}
                .iframe2 .desktop + .content {padding: 20px ${sidePadding}px;}
                `,
                // customCss: `${staticOrigin}/static/sumsub.css`,
                // URL to css file in case you need change it dynamically from the code
                // the similar setting at Customizations tab will rewrite customCss
                // you may also use to pass string with plain styles `customCssStr:`
            },
        } as SnsWebSdkBaseConfig;
    }, [locale, messages]);
}

const errorHandler = (err: SnsError) => {
    message.error(`[${err.code}]: ${err.error}`);
    console.error("[SUMSUB]Error: ", err);
};

/**
 * 判断结果是否需要继续轮询
 * @param res
 */
function isNeedWaitFaceRes(res: VerifyFaceResult) {
    return [VerifyFaceResult.NOT_STARTED, VerifyFaceResult.PENDING, VerifyFaceResult.RESUBMISSION].includes(res);
}
