import React from "react";
import css from "landings/Balance/components/TradingAccounnt/index.module.less";
import BalancePie from "landings/Balance/components/BalancePie";
import FinanceBalancePie from "landings/Balance/components/BalancePie/finance";
import { useTranslators } from "src/commonUse/Locale";

const OrderBalancePie = ({ walletType }: { walletType: "finance" | "trade" }) => {
    const { $st } = useTranslators();
    return (
        <div className={css.orderBalancePieWrap}>
            <div className={css.title}>{$st("balance_allocation_pie_chart_title")}</div>
            {walletType === "trade" ? <BalancePie pieType="Bots" /> : <FinanceBalancePie pieType="Bots" />}
        </div>
    );
};

export default OrderBalancePie;
