import classNames from "classnames";
import { useToggle } from "commonUse/tools";
import TReportLink, { IReportLinkProps } from "components/v2/TinyComps";
import React, { createContext, createElement, FC, PropsWithChildren, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import useMedia from "src/components-v2/Layout/hooks/useMedia";
// import useMedia from "../hooks/useMedia";
import cs from "./cs.m.less";
import { IconDropDownArrow12pxBold, IconRightarrow } from "@pionex-web-kit/icons";

export interface NavItemProps {
    title: ReactNode;
    placement?: "center" | "right" | "left";
    hideArrow?: boolean;
}

const CTXNavControl = createContext({
    closeNav() {},
});
export const useNavControl = () => {
    return useContext(CTXNavControl);
};

export const MobileNavController: FC<{ headerHeight: number; onMobileNavVisibleChange?(visible: boolean): void }> = ({ children, headerHeight, onMobileNavVisibleChange }) => {
    const [visible, { toggle, setFalse: closeNav }] = useToggle(false, { onChange: onMobileNavVisibleChange });
    // 由于顶部导航的高度可能不固定，因此，需要计算高度
    const menuHeight = window.screen.height - (headerHeight || 60);
    return (
        <CTXNavControl.Provider value={{ closeNav }}>
            <div className={classNames(cs.navMobileSwitcher, visible && cs.active)} onClick={toggle}>
                <i></i>
            </div>
            <div className={classNames(cs.navMobileWrapper, visible && cs.visible)} style={{ height: menuHeight }}>
                <div className={cs.navMobile}>
                    {children}
                    <div className={cs.navMobileBottomHolder}></div>
                </div>
            </div>
        </CTXNavControl.Provider>
    );
};
export const EmptyNavController: FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (
        <CTXNavControl.Provider
            value={{
                closeNav() {},
            }}
        >
            {children}
        </CTXNavControl.Provider>
    );
};

export function NavItem({
    title,
    children,
    placement = "left",
    hideArrow = false,
    onClick,
    innerStyle,
    innerClassName,
    navSubStyle,
    reportData,
    reportType,
    eleType,
    ...props
}: NavItemProps &
    Omit<IReportLinkProps<any>, "title"> & {
        innerStyle?: React.CSSProperties;
        innerClassName?: string;
        navSubStyle?: React.CSSProperties;
    }) {
    const { isTablet } = useMedia();
    const [visible, setVisible] = useState(false);
    const mobileNevOpen = isTablet && visible;
    const { closeNav } = useContext(CTXNavControl);
    const childrenEle = (
        <>
            <span className={classNames(cs.navItemInner, innerClassName)} onClick={() => isTablet && children && setVisible(!visible)} style={innerStyle}>
                {title}
                {children && !hideArrow && <IconDropDownArrow12pxBold className={classNames(cs.navSubArrow, mobileNevOpen && cs.mobileOpen)} size={10} />}
            </span>
            {children && (
                <div className={classNames(cs.navSubWrapper, cs[placement], mobileNevOpen && cs.mobileOpen)}>
                    <div style={navSubStyle} className={cs.navSub}>
                        {children}
                    </div>
                </div>
            )}
        </>
    );
    return (
        <>
            {children ? (
                <span
                    className={classNames(cs.navItem, isTablet || cs.notMobile, visible && cs.active, "cursor-pointer")}
                    onClick={(e) => {
                        onClick?.(e as any);
                        !children && closeNav();
                    }}
                    {...(props as any)}
                >
                    {childrenEle}
                </span>
            ) : (
                <TReportLink
                    className={classNames(cs.navItem, isTablet || cs.notMobile, visible && cs.active)}
                    reportData={reportData}
                    reportType={reportType}
                    eleType={eleType}
                    {...props}
                    onClick={(e) => {
                        onClick?.(e);
                        !children && closeNav();
                    }}
                >
                    {childrenEle}
                </TReportLink>
            )}
        </>
    );
}

export interface NavSubGroupProps {
    title?: ReactNode;
    size?: "default" | "middle";
    rows?: 2;
}

export function NavSubGroup({ title, children, size = "default", rows }: PropsWithChildren<NavSubGroupProps>) {
    return (
        <div className={classNames(cs.navSubGroupWrapper, cs[size])}>
            {title && <h5 className={cs.navSubGroupTitle}>{title}</h5>}
            <div className={classNames(cs.navSubGroup, cs[`row${rows}`])}>{children}</div>
        </div>
    );
}

export interface NavSubItemProps {
    // 渲染元素类型
    eleType?: "div" | typeof TReportLink;
    // 文本居中
    centerAlign?: boolean;
    // 是否是左右格式，且右侧是点击区域
    rightEff?: boolean;
    // 不显示hover效果
    noHoverEff?: boolean;
    // 不显示右侧hover箭头图标
    hideEffArrow?: boolean;
    // 如果有二级Menu，则为二级Menu的item
    subItems?: ReactNode;
    // 如果有二级Menu，则为二级Menu的title
    subTitle?: ReactNode;
    // 总是显示右侧箭头图标
    alwaysShowArrow?: boolean;
}

export function NavSubItem({
    eleType = TReportLink,
    centerAlign,
    hideEffArrow,
    rightEff,
    noHoverEff,
    children,
    className,
    onClick,
    alwaysShowArrow,
    icon,
    subItems,
    ...props
}: Omit<IReportLinkProps<any>, "eleType"> & NavSubItemProps) {
    const { closeNav } = useContext(CTXNavControl);
    return createElement(eleType, {
        className: classNames(cs.navItem, centerAlign && cs.centerAlign, noHoverEff && cs.noHover, rightEff && cs.rightEff, className),
        children,
        icon: !hideEffArrow ? (
            <>
                {icon && icon}
                <IconRightarrow size={16} className={classNames(cs.navItemArrowIcon, alwaysShowArrow && cs.alwaysShowArrow)} />
            </>
        ) : (
            icon && icon
        ),
        ...(rightEff
            ? { onClick }
            : {
                  onClick(e) {
                      onClick?.(e);
                      closeNav();
                  },
              }),
        ...props,
    } as any);
}

export interface NavSubMenuGroupProps extends NavSubGroupProps {}

export function NavSubMenuGroup({ title, children, size = "default", rows }: PropsWithChildren<NavSubMenuGroupProps>) {
    const [subItems, setSubItems] = useState<ReactNode | undefined>(undefined);
    const [subTitle, setSubTitle] = useState<ReactNode | undefined>(undefined);
    const { isDeviceDesktop, isTablet } = useMedia();
    const childComponents = React.Children.toArray(children);
    const childComponentsWithEvents = useMemo(() => {
        return childComponents.map((child) => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child as React.ReactElement<any>, {
                    onMouseEnter: () => {
                        setSubItems(child.props.subItems);
                        if (child.props.subTitle) {
                            setSubTitle(child.props.subTitle);
                        }
                    },
                });
            }
            return child;
        });
    }, [childComponents]);

    const onSubMenuLeave = useCallback(() => {
        setSubItems(undefined);
        setSubTitle(undefined);
    }, []);

    const mobileChildComponents = useMemo(() => {
        return childComponents.map((child) => {
            if (React.isValidElement(child) && child.props.subItems) {
                return (
                    <NavItem title={child.props.children}>
                        <div className="pl-[50px]">
                            <NavSubGroup>{child.props.subItems}</NavSubGroup>
                        </div>
                    </NavItem>
                );
            }
            return child;
        });
    }, [childComponents]);

    return (
        <>
            {!isDeviceDesktop || isTablet ? (
                <NavSubGroup>{mobileChildComponents}</NavSubGroup>
            ) : (
                <div onMouseLeave={onSubMenuLeave} className="flex">
                    <div className={classNames(cs.navSubGroupWrapper, cs[size])}>
                        {title && <h5 className={cs.navSubGroupTitle}>{title}</h5>}
                        <div className={classNames(cs.navSubGroup, cs[`row${rows}`])}>{childComponentsWithEvents}</div>
                    </div>
                    {subItems && (
                        <div className={classNames(cs.navSubMenuGroupWrapper, cs[size], subItems ? null : "hidden")}>
                            {subTitle && <h5 className={cs.navSubGroupTitle}>{subTitle}</h5>}
                            <div className={classNames(cs.navSubGroup, cs[`row${rows}`])}>{subItems}</div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
