import classnames from "classnames";
import css from "./index.module.less";

type Props = any;

export const Wrapper = ({ children, className, ...props }: Props) => (
    <div className={classnames(css.Wrapper, className)} {...props}>
        {children}
    </div>
);

export const WrapperSpaceBetween = ({ children, className, ...props }: Props) => (
    <div className={classnames(css.WrapperSpaceBetween, className)} {...props}>
        {children}
    </div>
);

export const WrapperColumn = ({ children, className, ...props }: Props) => (
    <div className={classnames(css.WrapperColumn, className)} {...props}>
        {children}
    </div>
);

export const H1 = ({ children, className, ...props }: Props) => (
    <div className={classnames(css.H1, className)} {...props}>
        {children}
    </div>
);

export const H2 = ({ children, className, ...props }: Props) => (
    <div className={classnames(css.H2, className)} {...props}>
        {children}
    </div>
);

export const H3 = ({ children, className, ...props }: Props) => (
    <div className={classnames(css.H3, className)} {...props}>
        {children}
    </div>
);

export const H4 = ({ children, className, ...props }: Props) => (
    <div className={classnames(css.H4, className)} {...props}>
        {children}
    </div>
);

export const H5 = ({ children, className, ...props }: Props) => (
    <div className={classnames(css.H5, className)} {...props}>
        {children}
    </div>
);

export const H6 = ({ children, className, ...props }: Props) => (
    <div className={classnames(css.H6, className)} {...props}>
        {children}
    </div>
);

export const RadiusWrapper = ({ children, className, ...props }: Props) => (
    <div className={classnames(css.RadiusWrapper, className)} {...props}>
        {children}
    </div>
);

export const StickyFooter = ({ children, className, ...props }: Props) => (
    <div className={classnames(css.StickyFooter, className)} {...props}>
        {children}
    </div>
);
export const Avatar = ({ children, className, ...props }: Props) => (
    <div className={classnames(css.Avatar, className)} {...props}>
        {children}
    </div>
);
export const LineProgress = ({ children, className, ...props }: Props) => (
    <div className={classnames(css.LineProgress, className)} {...props}>
        {children}
    </div>
);
export const LineProgressContent = ({ children, className, ...props }: Props) => (
    <div className={classnames(css.LineProgressContent, className)} {...props}>
        {children}
    </div>
);
