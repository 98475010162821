import AppState from "state/AppState";
import { combineEpics, ofType } from "redux-observable";
import { map, tap } from "rxjs/operators";

const EXCHANGE_HIDE_BALANCE = "exchange_hide_balance";
const EXCHANGE_HIDE_SMALL = "exchange_hide_small_asset";

export default class BalanceCfg {
    hideBalance: boolean;
    hideSmall: boolean = true;

    static get availableActions() {
        return {
            refreshBalanceShowStatus: (hide: boolean) =>
                AppState.store.dispatch({
                    type: "refresh_balance_show_status",
                    data: hide,
                }),

            refreshHideSmallStatus: (hide: boolean) =>
                AppState.store.dispatch({
                    type: "refresh_balance_hide_small_status",
                    data: hide,
                }),
        };
    }

    static reducer = (state: BalanceCfg = new BalanceCfg(), action) => {
        switch (action.type) {
            case "refresh_balance_show_status_result": {
                let result = new BalanceCfg();
                result.hideSmall = state.hideSmall;
                result.hideBalance = action.data;
                return result;
            }

            case "refresh_balance_hide_small_status_result": {
                let result = new BalanceCfg();
                result.hideSmall = action.data;
                result.hideBalance = state.hideBalance;
                return result;
            }
        }

        return state;
    };

    static refreshBalanceShowStatus = (action$, store) => {
        return action$
            .pipe(ofType("refresh_balance_show_status"))
            .pipe(
                tap((param: { type: string; data: boolean }) => {
                    window.localStorage.setItem(EXCHANGE_HIDE_BALANCE, (param.data as boolean) ? "1" : "0");
                }),
            )
            .pipe(
                map((param: { type: string; data: boolean }) => {
                    return { type: "refresh_balance_show_status_result", data: param.data };
                }),
            );
    };

    static refreshHideSmallStatus = (action$, store) => {
        return action$
            .pipe(ofType("refresh_balance_hide_small_status"))
            .pipe(
                tap((param: { type: string; data: boolean }) => {
                    window.localStorage.setItem(EXCHANGE_HIDE_SMALL, (param.data as boolean) ? "1" : "0");
                }),
            )
            .pipe(
                map((param: { type: string; data: boolean }) => {
                    return { type: "refresh_balance_hide_small_status_result", data: param.data };
                }),
            );
    };

    static get epic() {
        return combineEpics(this.refreshBalanceShowStatus, this.refreshHideSmallStatus);
    }

    static init() {
        let hideBalance = window.localStorage.getItem(EXCHANGE_HIDE_BALANCE);
        if (hideBalance !== null) {
            this.availableActions.refreshBalanceShowStatus(hideBalance === "1");
        }

        let hideSmall = window.localStorage.getItem(EXCHANGE_HIDE_SMALL);
        if (hideSmall !== null) {
            this.availableActions.refreshHideSmallStatus(hideSmall === "1");
        }
    }
}
