import React, { createContext, useState, useContext, memo, useLayoutEffect, MetaHTMLAttributes, FC, UIEvent, useMemo } from "react";
import { HeaderV2Props } from "src/components-v2/Layout/Header.v2";
import isEqual from "lodash/isEqual";
import merge from "lodash/merge";
import { useTranslators } from "commonUse/Locale";
import { useCallbackStatic } from "commonUse/tools";

export interface IDefaultLayoutConfig {
    /**
     * 页面加载
     */
    loading?: boolean;
    /**
     * 内部使用
     */
    id?: string;
    /**
     * 网页标题，作用于 document.title 节点
     */
    title?: string;
    /**
     * meta 元数据标签配置列表
     */
    metaList?: MetaHTMLAttributes<any>[];
    /**
     * 是否需要登录后才能访问？
     * @deprecated Use {@link AuthRoute} to config the page route.
     */
    requireLogin?: boolean;
    /**
     * content容器dom节点的样式
     */
    className?: string;
    /**
     * 布局根节点样式
     */
    layoutRootClassName?: string;
    /**
     * 是否使用全宽全高模式，默认是center-container模式
     */
    contentFullFill?: boolean;
    /**
     * 仅全宽
     */
    contentFullWidthOnly?: boolean;
    /**
     * 是否显示顶部导航
     */
    showHeader?: boolean;
    /**
     * 是否显示底部footer
     */
    showFooter?: boolean;
    /**
     * 是否渲染右侧工具栏
     */
    showSidebar?: boolean;
    /**
     * 作用于Layout根节点的inline-style
     */
    style?: React.CSSProperties;
    /**
     * 是否页面隐藏滚动条
     */
    hiddenScrollbar?: boolean;
    /**
     * V2版本的props配置
     */
    headerV2Props?: HeaderV2Props;
    /**
     * 是否是交易界面的布局模式
     */
    tradingLayout?: boolean;
    /**
     * 是否全局显示白色北京
     */
    whiteBg?: boolean;
    /**
     * 是否显示客服按钮
     */
    showManualServiceBtn?: boolean;
    /**
     * 使用contentFullFill模式下，监听内容部分滚动；
     */
    onContentScroll?(e: UIEvent<HTMLDivElement>);
    /**
     * 是否显示全局消息
     */
    showGlobalMessage?: boolean;
}

/**
 * 获取一般数据类型的默认配置项
 * @returns
 */
const getDefaultLayoutConfig = (overwrites?: IDefaultLayoutConfig) =>
    ({
        requireLogin: false,
        contentFullFill: false,
        // PiCol网站下需要新的默认配置
        showHeader: PLATFORM.PIONEX_COLOGY ? false : true,
        showSidebar: false,
        showFooter: true,
        hiddenScrollbar: false,
        headerV2Props: {},
        tradingLayout: false,
        showManualServiceBtn: PLATFORM.PIONEX_COLOGY ? false : true,
        showGlobalMessage: true,
        whiteBg: false,
        ...overwrites,
    } as IDefaultLayoutConfig);
/**
 * 获取需要翻译的默认配置项
 * @param $t
 * @returns
 */
const getDefaultTranslateLayoutConfig = ($t: (id: string, value?: any) => string) => ({
    // 初始网站标题
    title: $t("pionex_title"),
    // TODO: 支持 SEO meta 配置
});

export const CTXLayoutConfig = createContext([getDefaultLayoutConfig()] as const);

const _simpleId = (l: number = 8) => `${Date.now().toString(16)}${Math.round(Math.random() * 10000).toString(16)}`.slice(0, l);

const CTXNestedConfigManager = createContext(["$root" as string, (() => {}) as (data: { id: string; config?: IDefaultLayoutConfig }, type?: "remove" | "update") => void] as const);

export function useLayoutConfig({ id, ...config }: IDefaultLayoutConfig) {
    const [parentId, childConfigUpdater] = useContext(CTXNestedConfigManager);
    const [cache] = useState(() => {
        // 当前配置的ID
        const nestedId = `${parentId}-${id || _simpleId()}`;
        // 设置初始配置
        childConfigUpdater({ id: nestedId, config });
        return { nestedId, _count: 0 };
    });
    useLayoutEffect(() => {
        if (cache._count > 0) {
            childConfigUpdater({ id: cache.nestedId, config });
        } else {
            // 规避第一次配置的重复设置
            cache._count++;
        }
    });
    useLayoutEffect(() => {
        return () => {
            // 组件卸载后，对应的配置将从map集合中移除
            childConfigUpdater({ id: cache.nestedId }, "remove");
        };
    }, []);
    return useMemo(() => [cache.nestedId, childConfigUpdater] as const, []);
}

/**
 * [LayoutConfig]布局配置组件
 * 用于调整当前页面中的布局配置，每一次配置都将基于默认配置进行配置，且页面之间的配置不复用。
 *
 * **原则上，需要大家在每一个页面组件中使用。若不使用将导致当前页面渲染时会复用上一次页面的布局配置。**
 *
 * @features **特性：**
 * - 保留了页面组件对布局的控制权，与原[landings/App]组件使用方式完全一致。
 * - 配置数据将已同步的形式 commit 到 Layout组件中。
 * - 使用memo函数进行前置diff元数据类型配置字段。
 * - 页面之间配置不共享。
 * - 切换页面，配置自动重置。原有 【resetPageTitleAndDescription()】方法将被废弃
 * - 支持对配置数据的监听，默认不开启监听模式。
 * @unsuportFeaturesYet **待支持特性**
 * - 支持配置 document.title 节点
 * - 支持根据 locale 配置 html.lang 节点
 * - 支持根据传入的 metaList 数据生成 <meta /> 节点
 *
 */
export const LayoutConfig: FC<IDefaultLayoutConfig> = memo(({ children, ...props }) => {
    return <CTXNestedConfigManager.Provider value={useLayoutConfig(props)}>{children}</CTXNestedConfigManager.Provider>;
});

/**
 * layout配置数据状态控制器
 * @title Layout内部方法，外部不允许调用
 */
export function LayoutConfigRoot({ children }) {
    const { $st } = useTranslators();
    // 初始化一次网站全局初始配置
    const [configRoot] = useState(() => ({
        // 默认配置
        default: {
            // 组件卸载后，id将更新
            id: "$root",
            // 初始网站标题
            ...getDefaultTranslateLayoutConfig($st),
            ...getDefaultLayoutConfig(),
        } as IDefaultLayoutConfig,
        // 缓存
        childrenConfigMap: new Map<string, IDefaultLayoutConfig>(),
    }));
    // Layout组件实际使用的数据
    const [mergedConfig, setMergedConfig] = useState(() => [configRoot.default] as const);
    const updateChildConfig = useCallbackStatic(({ id, config }: { id: string; config?: IDefaultLayoutConfig }, type: "remove" | "update" = "update") => {
        let shouldUpdate = false;
        if (type === "remove") {
            // 移除对应缓存配置
            configRoot.childrenConfigMap.delete(id);
            shouldUpdate = true;
        } else if (config) {
            // 获取旧的配置，支持直接新增
            const oldConfig = configRoot.childrenConfigMap.get(id);
            // 将对传入的配置数据进行深度比对
            if (!oldConfig || !isEqual(oldConfig, config)) {
                // 更新对应节点缓存数据
                configRoot.childrenConfigMap.set(id, config);
                shouldUpdate = true;
            }
        }
        if (shouldUpdate) {
            // 移除和更新后，均会触发更新全局的配置
            setMergedConfig([merge({}, configRoot.default, ...configRoot.childrenConfigMap.values())]);
        }
    });
    return (
        <CTXNestedConfigManager.Provider value={useMemo(() => [configRoot.default.id!, updateChildConfig] as const, [])}>
            <CTXLayoutConfig.Provider value={mergedConfig}>{children}</CTXLayoutConfig.Provider>
        </CTXNestedConfigManager.Provider>
    );
}

export default LayoutConfig;
