import React, { useCallback, useEffect, useMemo, useState } from "react";

import cs from "./index.m.less";
import { useTranslators } from "commonUse/Locale";
import TReportLink, { Iconfont, TButton, TCheckbox, TModal, TSelect, TSpin } from "components/v2/TinyComps";
import TSelfNumberInput from "src/components-v2/TSelfNumberInput";
import { useToggle } from "commonUse/tools";
import { useCircleDebitAPI } from "../context";
import ResultInPage, { ResultProps } from "src/components-v2/ResultInPage";
import { message } from "antd";
import { ParseErrorUtils } from "trade_lib_wrapper";
import { Callbacks } from "rc-field-form/lib/interface";
import {
    CardVerifyInfo,
    DebitCard,
    DebitCardChannel,
    DebitDepositRecord,
    DebitDepositStatus,
    DepositActionType,
    ESumsubVerifyStatus,
    PublicKeyInfo,
} from "landings/V2/CircleDebit/types";
import FormItemTransition from "utils/FormItemTransition";
import { DebitDefaultCard, getCardStatusText, getStatusTips, isAllowedDepositCard, isShowFAQInResult, useCards, useDepositLimit } from "landings/V2/CircleDebit/Deposit/utils";
import NoKyc, { useNoKycVisible } from "landings/V2/USDDW/components/NoKyc";
import { CVV_MAX_LENGTH } from "landings/V2/CircleDebit/AddCircleCard/Step1Form";
import useAccountInfo from "commonUse/useAccountInfo";
import { ApprovingState, IDLEState, SubmittingState, THREE_DS_FAQ_URL, useDepositState, Wait3DSURLState } from "landings/V2/CircleDebit/Deposit/depositState";
import { getCircleFailReasonText } from "landings/V2/USDDW/utils";
import { QuotaCheckType, QuotaDirection, useRemainQuota } from "utils/complianceQuota";
import SumsubVerifyModal from "../components/SumsubVerifyModal";
import { withMaintenance } from "landings/V2/USDDW/components/Maintenance";
import TipsCover from "./TipsCover";
import BindCard from "../AddCard";
import { map, mergeMap } from "rxjs/operators";
import { fromPromise } from "rxjs/internal-compatibility";
import { Form, Spin } from "@pionex-web-kit/components";
import { DWPayMethod } from "landings/V2/USDDW/types";
import { DEBIT_CHANEL__ALL_CHANNEL, encryptCardInfo } from "landings/V2/CircleDebit/utils";
import { injectCheckoutSDK } from "landings/V2/CircleDebit/AddCheckoutCard/injectCheckoutSDK";
import { useAdvConfig } from "utils/webConfig";
import DataReporter from "landings/Analytics/DataReporter";
import NumberUtils from "utils/NumberUtils";

/**
 * 允许的最大卡片数量
 */
const MAX_CARD_COUNT = 3;

interface Props {}

/**
 * Debit卡片 入金
 */
const Deposit: React.FC<Props> = ({}) => {
    const { $st } = useTranslators();
    const accountInfo = useAccountInfo();

    const [form] = Form.useForm();

    const [bindCardShowInfo, setBindCardShowInfo] = useState<{ cardChannel: DebitCardChannel; publicKeyInfo: PublicKeyInfo }>();
    const hideBindCard = useCallback(() => {
        setBindCardShowInfo(undefined);
    }, []);

    const [coverLoading, { setTrue: showCoverLoading, setFalse: hideCoverLoading }] = useToggle();

    const circleDebitAPI = useCircleDebitAPI();
    const noKycVisible = useNoKycVisible();
    const { data: cards, refresh: refreshCards, initialed } = useCards(noKycVisible);

    const selectedCardId = Form.useWatch("cardId", form);
    const selectedCard = useMemo(() => cards?.find((item) => item.id === selectedCardId), [cards, selectedCardId]);

    // 滚动额度
    const { details: remainDetails } = useRemainQuota(DEBIT_CHANEL__ALL_CHANNEL[selectedCard?.channel ?? DebitCardChannel.CIRCLE], QuotaDirection.DEPOSIT);
    const rollingRemain = useMemo(() => remainDetails.find((item) => item.checkType === QuotaCheckType.DEPOSIT_ROLLING_QUOTA_CHECK), [remainDetails]);

    const { min: minAmount, max: maxAmount, tip: limitTip } = useDepositLimit();

    const [checkedAgree, setCheckedAgree] = useState(false);

    const [resultInfo, setResultInfo] = useState<{
        data: ResultProps;
        showSumsubSDK: boolean;
        failReason?: string;
    }>();
    useEffect(() => {
        injectCheckoutSDK();
    }, []);
    useEffect(() => {
        // 卡片列表刷新时，若无选中，自动选中一张卡片

        if (!!form.getFieldValue("cardId")) {
            // 已选择卡片，不处理
            return;
        }

        if (!accountInfo.userId) {
            return;
        }

        if (!cards?.length) {
            form.setFieldsValue({ cardId: undefined });
            return;
        }

        form.setFieldsValue({ cardId: DebitDefaultCard.getDefaultCardId(accountInfo.userId, cards) });
    }, [form, cards, accountInfo.userId]);

    const handleClickAddCard = useCallback(() => {
        if (!circleDebitAPI) {
            return;
        }
        showCoverLoading();
        circleDebitAPI
            .assignCardChannel()
            .pipe(
                mergeMap((channel) =>
                    circleDebitAPI.getPublicKey(channel).pipe(
                        map((publicKeyInfo) => ({
                            channel,
                            publicKeyInfo,
                        })),
                    ),
                ),
            )
            .subscribe(
                ({ channel, publicKeyInfo }) => {
                    setBindCardShowInfo({ cardChannel: channel, publicKeyInfo });
                },
                (e) => {
                    console.error(e);
                    message.error(ParseErrorUtils.formatterToString(e));
                },
                () => {
                    hideCoverLoading();
                },
            );
    }, [circleDebitAPI, hideCoverLoading, showCoverLoading]);
    const deleteCard = useCallback(
        async (item: DebitCard) => {
            await TModal.promisify.confirm({
                content: $st("debit_delete_card_content", {
                    br: <br />,
                    cardNum: `${item.cardInfo.network}(***${item.cardInfo.last4})`,
                }),
            });
            circleDebitAPI?.deleteCard(item.id).subscribe(
                () => {
                    message.success($st("mine_bank_unbind_action_success"));
                    refreshCards();

                    // 删除的当前选中卡，清空选中值
                    if (form.getFieldValue("cardId") === item.id) {
                        form.setFieldsValue({ cardId: undefined });
                    }
                },
                (e) => {
                    message.error(ParseErrorUtils.formatterToString(e));
                },
            );
        },
        [$st, circleDebitAPI, form, refreshCards],
    );

    // 入金结果处理
    const handleDepositResult = useCallback(
        (order: DebitDepositRecord) => {
            if (order.status === DebitDepositStatus.SUCCESS) {
                setResultInfo({
                    data: { type: "success", des: $st("debit_deposit_success", { amount: order.paymentInfo.amount, cardNum: `****${order.paymentInfo.accountNo.slice(-4)}` }) },
                    showSumsubSDK: false,
                });
                return;
            }

            if (order.status === DebitDepositStatus.FAILED) {
                let showSumsubSDK = false;
                const selectCardId = form.getFieldValue("cardId");
                const selectCard = cards?.find((item) => item.id === selectCardId);
                // 失败原因为3ds，且卡未经过sumsub认证
                if (order.failReason?.match(/^THREE_D_SECURE_/) && selectCard?.verifyStatus === ESumsubVerifyStatus.NOT_STARTED) {
                    showSumsubSDK = true;
                }

                setResultInfo({
                    data: { type: "fail", des: getCircleFailReasonText($st, order.failReason, order.failDetail) },
                    showSumsubSDK,
                    failReason: order.failReason,
                });
                return;
            }
        },
        [$st, cards, form],
    );

    const { cover: loadingCover, timer, changeDepositState } = useDepositState(handleDepositResult);
    const handleFinish: Callbacks["onFinish"] = useCallback(
        async (values) => {
            if (!checkedAgree) {
                // 未勾选同意
                message.warn($st("signature_dw_withdraw_not_agree"));
                return;
            }
            if (!circleDebitAPI) {
                return;
            }
            changeDepositState(new SubmittingState());
            // const encryptedData = await encryptDataWithPublicKey({ cvv: values["cvv"] }, publicKeyInfo.publicKey);
            const cardId = values["cardId"];
            const amount = Number(values["amount"]).toString();

            const selectedCard = cards?.find((item) => item.id === cardId);

            if (!selectedCard) {
                return;
            }

            circleDebitAPI
                .getPublicKey(selectedCard.channel)
                .pipe(
                    mergeMap((publicKeyInfo) =>
                        fromPromise(encryptCardInfo(selectedCard.channel, { cvv: values["cvv"] }, publicKeyInfo.publicKey)).pipe(
                            map((encryptedString) => ({ publicKeyInfo, encryptedString })),
                        ),
                    ),
                    mergeMap(({ publicKeyInfo, encryptedString }) =>
                        circleDebitAPI.deposit({
                            public_key_id: publicKeyInfo.keyId,
                            card_id: cardId,
                            amount: amount,
                            currency: "USD",
                            encrypted_data: encryptedString,
                        }),
                    ),
                )
                .subscribe(
                    (res) => {
                        if (res.actionType === DepositActionType.THREE_D_SECURE_REQUIRED) {
                            changeDepositState(new Wait3DSURLState(timer, res));
                        } else {
                            changeDepositState(new ApprovingState(timer, res));
                        }
                        form.resetFields();
                        form.setFieldsValue({ cardId: DebitDefaultCard.getDefaultCardId(accountInfo.userId ?? "", cards) });
                    },
                    (e) => {
                        changeDepositState(new IDLEState(timer));
                        const errorCode = e?.data?.mcode;
                        console.error(e);

                        if (errorCode === "TOO_MUCH_ORDERS") {
                            message.error($st("debit_deposit_error:TOO_MUCH_ORDERS"));
                            return;
                        }
                        message.error(`${errorCode ? `[${errorCode}]:` : ""}${$st("debit_deposit_error", { code: e?.data?.mcode })}`);
                    },
                );
        },
        [$st, accountInfo.userId, cards, changeDepositState, checkedAgree, circleDebitAPI, form, timer],
    );

    const [verifyInfo, setVerifyInfo] = useState<CardVerifyInfo>();
    const advConfig = useAdvConfig("debit_result");

    return (
        <div className={cs.container}>
            <Spin spinning={coverLoading}>
                {(() => {
                    if (!initialed) {
                        return <TSpin spinning />;
                    }
                    if (noKycVisible) {
                        return <NoKyc reportEvent="fiatdeposit" />;
                    }
                    if (!!resultInfo) {
                        // 显示结果界面
                        return (
                            <div className={cs.result}>
                                <ResultInPage {...resultInfo.data} className={"mobile:!w-[300px] !w-[500px]"} />
                                {(() => {
                                    if (resultInfo.showSumsubSDK) {
                                        return (
                                            <>
                                                <TButton
                                                    type={"primary"}
                                                    size={"large"}
                                                    className={cs.resultConfirm}
                                                    onClick={() => {
                                                        setVerifyInfo({ cardId: form.getFieldValue("cardId") });
                                                    }}
                                                >
                                                    {$st("debit_card_result_verify_card_btn")}
                                                </TButton>
                                                <div className={cs.result3DSDesc}>{$st("debit_card_result_verify_card_desc")}</div>
                                            </>
                                        );
                                    }

                                    return (
                                        <>
                                            <TButton
                                                type={"primary"}
                                                size={"large"}
                                                className={cs.resultConfirm}
                                                onClick={() => {
                                                    setResultInfo(undefined);
                                                    changeDepositState(new IDLEState(timer));
                                                }}
                                            >
                                                {$st("button_ok")}
                                            </TButton>

                                            {isShowFAQInResult(resultInfo.failReason) && (
                                                <TReportLink className={"mt-16px !text-primary"} target={"_blank"} to={THREE_DS_FAQ_URL}>
                                                    {$st("debit_result_faq")}
                                                </TReportLink>
                                            )}
                                        </>
                                    );
                                })()}
                                {!!advConfig && (
                                    <TReportLink
                                        {...advConfig.link}
                                        target={advConfig?.link?.target || "_blank"}
                                        className="mt-24px max-w-full"
                                        onClick={() => {
                                            const event = advConfig?.link?.report?.key;
                                            event && DataReporter.report(event);
                                        }}
                                    >
                                        <img src={advConfig.image} className="w-[500px] max-w-full" />
                                    </TReportLink>
                                )}
                            </div>
                        );
                    }

                    return (
                        <>
                            <div className={cs.title}>{$st("debit_deposit_title")}</div>
                            <div className={cs.selectTitleContainer}>
                                <div className={cs.selectTitle}>{$st("debit_deposit_select_card_title")}</div>
                                {!!cards?.length && cards.length < MAX_CARD_COUNT && (
                                    <span className={cs.addText} onClick={handleClickAddCard}>
                                        <Iconfont icon={"icon_add"} /> {$st("debit_deposit_add_card")}
                                    </span>
                                )}
                            </div>
                            <div className={cs.selectDes}>{$st("debit_deposit_select_card_des")}</div>
                            {!cards?.length && (
                                <div className={cs.addBtn} onClick={handleClickAddCard}>
                                    <Iconfont icon={"icon_add"} /> {$st("debit_deposit_add_card")}
                                </div>
                            )}
                            <Form onFinish={handleFinish} form={form}>
                                {!!cards?.length && (
                                    <Form.Item
                                        name={"cardId"}
                                        // label={$st("debit_deposit_select_card")}
                                    >
                                        <FormItemTransition<string>>
                                            {({ value, onChange }) => {
                                                const selectedCard = cards?.find((item) => item.id === value);
                                                const tips = !!selectedCard
                                                    ? getStatusTips($st, selectedCard, () => {
                                                          setVerifyInfo({ cardId: selectedCard.id, isRequired: false });
                                                      })
                                                    : "";
                                                return (
                                                    <>
                                                        <TSelect<string>
                                                            value={value}
                                                            onChange={(v) => {
                                                                DebitDefaultCard.cacheDefaultCard(accountInfo.userId ?? "", v);
                                                                onChange?.(v);
                                                            }}
                                                            placeholder={$st("debit_deposit_select_card")}
                                                            size={"large"}
                                                            className={cs.selectCard}
                                                            dropdownClassName={cs.selectCardDrop}
                                                        >
                                                            {cards?.map((item) => (
                                                                <TSelect.Option
                                                                    key={item.id}
                                                                    value={item.id}
                                                                    className={item.channelInvalid ? "!text-secondary-sub" : "!text-accent"}
                                                                >
                                                                    <div className={cs.selectCardItem}>
                                                                        <div className={cs.selectOptionText}>
                                                                            <span>{`***${item.cardInfo.last4} (${getCardStatusText($st, item)})`}</span>
                                                                            {item.verifyStatus === ESumsubVerifyStatus.VERIFIED && (
                                                                                <img className={cs.selectOptionSecurityIcon} src={require("./icon/icon_security.png")} />
                                                                            )}
                                                                        </div>
                                                                        <Iconfont
                                                                            className={cs.deleteCard}
                                                                            icon={"collect"}
                                                                            onClick={async (e) => {
                                                                                e.stopPropagation();
                                                                                deleteCard(item);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </TSelect.Option>
                                                            ))}
                                                        </TSelect>
                                                        {!!tips && <div className={cs.tips}>{tips}</div>}
                                                    </>
                                                );
                                            }}
                                        </FormItemTransition>
                                    </Form.Item>
                                )}
                                <div className={"relative"}>
                                    <Form.Item
                                        name={"amount"}
                                        rules={[
                                            {
                                                validator(_, value) {
                                                    const num = Number(value);
                                                    if (isNaN(num) || num < minAmount || num > maxAmount) {
                                                        return Promise.reject(
                                                            $st("debit_deposit_amount_placeholder", { min: minAmount, max: NumberUtils.numberWithCommas(maxAmount) }),
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        <TSelfNumberInput
                                            autoComplete={"off"}
                                            maxPrecision={2}
                                            size={"large"}
                                            placeholder={$st("debit_deposit_amount_placeholder", { min: minAmount, max: NumberUtils.numberWithCommas(maxAmount) })}
                                        />
                                    </Form.Item>
                                    <Form.Item shouldUpdate>
                                        {({ getFieldValue }) => {
                                            const cardId = getFieldValue("cardId");
                                            const selectedCard = cards?.find((item) => item.id === cardId);
                                            const invalidCard = !selectedCard || !isAllowedDepositCard(selectedCard);

                                            return (
                                                <>
                                                    {!invalidCard && (
                                                        <Form.Item
                                                            name={"cvv"}
                                                            // label={$st("debit_add_card_info_cvv")}
                                                            rules={[
                                                                // { required: true },
                                                                {
                                                                    validator(_, value) {
                                                                        if (!value || value.replace(/\s/g, "").length < 3) {
                                                                            return Promise.reject($st("debit_add_card_cvv_format"));
                                                                        }
                                                                        return Promise.resolve();
                                                                    },
                                                                },
                                                            ]}
                                                        >
                                                            <TSelfNumberInput
                                                                autoComplete={"off"}
                                                                maxLength={CVV_MAX_LENGTH}
                                                                size={"large"}
                                                                placeholder={$st("debit_add_card_info_cvv")}
                                                            />
                                                        </Form.Item>
                                                    )}
                                                    <div className={cs.tips}>
                                                        {$st("debit_deposit_tips", {
                                                            daily: NumberUtils.numberWithCommas(rollingRemain?.quota) ?? "--",
                                                            limitTip: NumberUtils.numberWithCommas(limitTip),
                                                            br: <br />,
                                                            strong: (t) => <strong>{t}</strong>,
                                                            remain: (t) => <strong className={cs.remain}>{t}</strong>,
                                                        })}
                                                    </div>
                                                    <TCheckbox className={cs.agree} checked={checkedAgree} onChange={(e) => setCheckedAgree(e.target.checked)}>
                                                        {$st("usddw_deposit_agree", {
                                                            agreement: (t) => (
                                                                <a
                                                                    className={"font-sb text-primary"}
                                                                    target={"_blank"}
                                                                    rel={"noreferrer"}
                                                                    href={"https://www.pionex.us/blog/chargeback-refund-policy/"}
                                                                >
                                                                    {" "}
                                                                    {t}
                                                                </a>
                                                            ),
                                                        })}
                                                    </TCheckbox>
                                                    <TButton disabled={invalidCard || !checkedAgree} htmlType={"submit"} className={cs.submit} type={"primary"} size={"large"}>
                                                        {$st("common_submit")}
                                                    </TButton>
                                                </>
                                            );
                                        }}
                                    </Form.Item>

                                    {/* 读取选中的卡片值，判断是否显示浮层 */}
                                    <Form.Item shouldUpdate className={cs.tipsCoverItem}>
                                        {({ getFieldValue }) => {
                                            const cardId = getFieldValue("cardId");
                                            const selectedCard = cards?.find((item) => item.id === cardId);
                                            return (
                                                <>
                                                    {!!selectedCard && (
                                                        <TipsCover
                                                            disableAddCard={(cards?.length ?? 0) >= MAX_CARD_COUNT}
                                                            card={selectedCard}
                                                            verifyCard={() => {
                                                                setVerifyInfo({ cardId: selectedCard.id, isRequired: false });
                                                            }}
                                                            onAddCard={handleClickAddCard}
                                                        />
                                                    )}
                                                </>
                                            );
                                        }}
                                    </Form.Item>
                                </div>
                            </Form>
                            {!!bindCardShowInfo && (
                                <BindCard
                                    type={bindCardShowInfo.cardChannel}
                                    publicKeyInfo={bindCardShowInfo.publicKeyInfo}
                                    onCancel={hideBindCard}
                                    onSuccess={() => {
                                        message.success($st("debit_add_card_success"));
                                        hideBindCard();
                                        refreshCards();
                                    }}
                                    startCardVerify={setVerifyInfo}
                                />
                            )}
                            {loadingCover}
                        </>
                    );
                })()}
            </Spin>
            {verifyInfo && <SumsubVerifyModal onCancel={() => setVerifyInfo(undefined)} visible verifyInfo={verifyInfo}></SumsubVerifyModal>}
        </div>
    );
};

export default withMaintenance(Deposit, { payMethod: DWPayMethod.DEBIT, direction: QuotaDirection.DEPOSIT });
