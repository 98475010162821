import { IconBack20pxRegular } from "@pionex-web-kit/icons";
import { useTranslators } from "commonUse/Locale";
import { history } from "utils/History";
import { getAccountPathname } from ".";
import { ReactNativeWebView } from "utils/ReactNativeWebView";

function Breadcrumbs() {
    const { $st } = useTranslators();
    if (ReactNativeWebView.shared.isWebView()) return null;
    return (
        <div
            className=" flex pt-16px items-center gap-8px cursor-pointer"
            onClick={() => {
                history.push(getAccountPathname("/account_update"));
            }}
        >
            <IconBack20pxRegular size={14} />
            <span className="text-base font-m text-text-100">{$st("account_update")}</span>
        </div>
    );
}

export default Breadcrumbs;
