import IconTriangle from "components/svg/IconTriangle";
import * as React from "react";
import ThemeData, { ThemeName } from "state/ThemeData";
import css from "./index.module.less";

export enum IndicatorType {
    NONE = 0,
    ASC = 1,
    DESC = 2,
}

export interface Props {
    theme: ThemeData;
    indicator: IndicatorType;
    activeColor?: string;
    defaultColor?: string;
}

export default function SortIndicator(props: Props) {
    const { theme, indicator } = props;

    const [activeColor, defaultColor] = React.useMemo(() => {
        const isDark = theme.name === ThemeName.dark;
        const _activeColor = props.activeColor || (!isDark ? "#292C33" : "#F2F3F5");
        const _defaultColor = props.defaultColor || (!isDark ? "#C5CBD6" : "#434852");

        return [_activeColor, _defaultColor];
    }, [props.activeColor, props.defaultColor, theme.name]);

    const upView = <IconTriangle color={indicator === IndicatorType.ASC ? activeColor : defaultColor} size={6} rotate={0} />;

    const downView = <IconTriangle color={indicator === IndicatorType.DESC ? activeColor : defaultColor} size={6} rotate={180} />;

    return (
        <div className={css.main}>
            {upView}
            {downView}
        </div>
    );
}
