import { message } from "@pionex-web-kit/components";
import { AccountService, BaseWithdrawSession, MfaParams, RequestVerifyDataParam, VerifyCodeAction, VerifyType } from "bu_account_js_sdk";
import { parseVerifyCodeErrorMsg } from "./utils";
import { CodeInputModal, CodeInputModalRef, requestGtCaptcha, requestHCaptcha, requestReCaptcha, SendType } from "@pionex-web-kit/pro-components";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import AccountManager from "utils/AccountManager";

import { useDeviceInfo } from "./hook";
import SecurityVerificationModal from "./SecurityVerificationModal";
import { useTranslators } from "commonUse/Locale";
import AccountInfo from "state/AccountInfo";
import FaceVerifyModal from "./FaceVerifyModal";
import { Observable } from "rxjs";
import { WithdrawError } from "utils/errorCode";

export interface Props<P> {
    action: VerifyCodeAction;
    bizParams: P;
    onRequestWithdraw: (mfaParams: MfaParams) => Observable<any>;

    accountInfo: AccountInfo;
    onLoad: () => void;
    onSuccess: () => void;
    onError: () => void;
    onCancel: () => void;
}

/**
 * @description: 提币、法币出金 验证流程组件
 * @return {*}
 */
function WithdrawVerifyProcess<P extends {} = {}>({ accountInfo, onLoad, onSuccess, onError, onCancel, bizParams, onRequestWithdraw, action }: Props<P>) {
    const [loaded, setLoaded] = useState(false);
    const [securityVerificationModalVisible, setSecurityVerificationModalVisible] = useState<boolean>();
    const [verifyConfig, setVerifyConfig] = useState<RequestVerifyDataParam>();
    const codeInputModalRef = useRef<CodeInputModalRef>(null);
    const [faceVerifyInfo, setFaceVerifyInfo] = useState<{ token: string; txn_id: string; request_id: string }>();

    const device_info = useDeviceInfo();
    const {
        $st,
        intl: { locale },
    } = useTranslators();

    const getFormatErrorMsg = useCallback(
        (error: any = {}) => {
            const { code, message } = error;
            if (code === 40320735 || code === 40300400) {
                return parseVerifyCodeErrorMsg(message);
            } else if (code === 40300700) {
                return $st("disable_withdraw_not_whitelist_address");
            } else if (code === -9) {
                return $st("withdraw_invalid_code");
            } else if (code === -3) {
                return $st("withdraw_code_verify_failed_google");
            } else if (code === -8 || code === -10) {
                return $st("withdraw_code_not_exist");
            }

            return WithdrawError.parseErrorMsg(message);
        },
        [$st],
    );
    // /**
    //  * @description: 提币参数
    //  * @return {*}
    //  */
    // const withdrawParams = useMemo(
    //     () => ({
    //         address: addressText ?? "",
    //         amount: quantityText ?? "",
    //         code: currentCoin.currency,
    //         ...(memoText ? { tag: memoText } : {}),
    //     }),
    //     [addressText, currentCoin.currency, memoText, quantityText],
    // );
    useEffect(() => {
        if (loaded) {
            onLoad?.();
        }
    }, [loaded, onLoad]);
    useEffect(() => {
        if (!accountInfo.firstApiKey || !device_info) {
            return;
        }
        const requestVerifyData = (param: RequestVerifyDataParam) => {
            setLoaded(true);
            setVerifyConfig(param);
            const { verifyTypes, paramData } = param;
            if (verifyTypes.includes(VerifyType.hyperlink)) {
                // 调起超链验证
                setSecurityVerificationModalVisible(true);
                return;
            }

            setSecurityVerificationModalVisible(undefined);

            if (verifyTypes.includes(VerifyType.kyc_face)) {
                if (!!param.txn_id) {
                    codeInputModalRef.current?.dismiss();
                    AccountService.requestFaceParams({ txn_id: param.txn_id, usage: action }).subscribe((res) => {
                        // 调起人脸验证
                        setFaceVerifyInfo({ request_id: res.request_id, token: res.token, txn_id: param.txn_id! });
                    });
                }

                return;
            }

            // 验证码验证
            if (param.verify_failed) {
                message.error(AccountManager.parseErrorTips(param.verify_failed));
                codeInputModalRef.current?.retry({
                    onOk: param.callback,
                });
                onError?.();
            } else {
                codeInputModalRef.current?.request({
                    params: {
                        verifyTypes,
                        action: action,
                        sendType: SendType.autoSend,
                        txn_id: param.txn_id,
                        maskAccounts: paramData.maskAccounts,
                    },
                    onOk: (data) => {
                        param.callback(data);
                    },
                    onCancel,
                });
            }
        };
        const withdrawSession = new BaseWithdrawSession(
            {
                // requestWithdraw: (mfaParams) => AccountService.withdraw({ ...withdrawParams, txn_id: mfaParams.txn_id }, accountInfo.firstApiKey!),
                buAccount: AccountManager.shared.buAccount,
                lang: locale,
                requestGtCaptcha,
                requestHCaptcha: (params) => {
                    return requestHCaptcha({ ...params, lang: "en" } as any);
                },
                requestReCaptcha: (params) => {
                    return requestReCaptcha({ ...params, lang: "en" } as any);
                },
                requestVerifyData,
                onCancel,
                onSuccess: async () => {
                    setSecurityVerificationModalVisible(undefined);
                    codeInputModalRef.current?.dismiss();
                    onSuccess?.();
                },
                onError: (error) => {
                    setSecurityVerificationModalVisible(false);
                    codeInputModalRef.current?.dismiss();
                    if (error.code === -10) {
                        // 取消不提示
                        onCancel?.();
                        return;
                    } else {
                        message.error(getFormatErrorMsg(error));
                        onCancel?.();
                    }
                },
            },
            action,
        );
        withdrawSession.startSession(bizParams, device_info, onRequestWithdraw);
    }, [accountInfo.firstApiKey, device_info, getFormatErrorMsg, locale, onCancel, onError, onRequestWithdraw, onSuccess, bizParams, action]);

    return (
        <>
            {/* 发送超链接验证弹窗 */}
            {securityVerificationModalVisible !== undefined && (
                <SecurityVerificationModal
                    visible={securityVerificationModalVisible}
                    setVisible={setSecurityVerificationModalVisible}
                    onCancel={onCancel}
                    verifyConfig={verifyConfig}
                    withdrawParams={bizParams}
                    device_info={device_info}
                />
            )}
            {/* 发送邮件短信验证码弹窗 */}
            <CodeInputModal ref={codeInputModalRef} />
            {/*人脸验证*/}
            {faceVerifyInfo && (
                <FaceVerifyModal
                    usage={action}
                    request_id={faceVerifyInfo.request_id}
                    token={faceVerifyInfo.token}
                    txn_id={faceVerifyInfo.txn_id}
                    onTokenChange={(token) => {
                        setFaceVerifyInfo((pre) => ({ ...pre!, token: token }));
                    }}
                    onSuccess={() => {
                        // 回调session，执行流程下一步
                        verifyConfig?.callback({});
                    }}
                    onCancel={() => {
                        setFaceVerifyInfo(undefined);
                        onCancel();
                    }}
                />
            )}
        </>
    );
}

export default memo(WithdrawVerifyProcess);
