import { useCallback, useEffect, useMemo, useState } from "react";
import { ObservableUtils, SafeDecimal } from "trade_utils_lib";
import { useSymbol, useSymbolData, useTicker } from "@pionex-web-kit/common-business";
import { EMPTY, timer } from "rxjs";
import { switchMap } from "rxjs/operators";
import { useCallbackStatic } from "commonUse/tools";
import Decimal from "decimal.js";
import Constants from "utils/Constants";
import { getSymbolPrecision } from "utils/decimal";
import NumberUtils from "utils/NumberUtils";
import { usePassedKycLogin } from "landings/V2/BuyCrypto/components/KycLoginClick";
import useAccountInfo from "commonUse/useAccountInfo";
import useKycV2Info, { KycV2Level, KycV2LevelStatus } from "commonUse/useKycInfoV2";
import { FormInstance } from "@pionex-web-kit/components";
import { PaymentMethod, PaymentMethodChannel } from "landings/V2/BuyCrypto/Buy/cards";
import { FORM_KEY_PAYMENTMETHOD } from "landings/V2/BuyCrypto/components/Form";
import { useKycV2TradeKycModal } from "commonUse/useKycRightsV2";
import { showLoginDialog } from "components/LoginDialog";
import { useTranslators } from "commonUse/Locale";
import { SymbolDesc, useSymbolTransferQuantity } from "commonUse";
import { useGetLimit, useOrderBookDataWithTimes } from "landings/Trade/Swap/tool";
import { useOnlineConfig } from "utils/onlineConfig";
import { CompareData } from "landings/V2/BuyCrypto/types";
import { useSwapConfig } from "utils/swapConfig";

/**
 * 轮询报价频率
 */
const DEFAULT_FREQUENCY = 5000;

/**
 * 根据盘口价格预估USD转换币种的数量
 * @param coin
 * @param amount
 * @param frequency
 */
export function useCoinTransferQuantity({ coin, amount, frequency = DEFAULT_FREQUENCY }: { coin: string; amount?: number; frequency?: number }): Nullable<{
    symbol: SymbolDesc;
    quantity?: number;
}> {
    const symbolInfo = useSymbolData();
    const getSymbolInfo = useCallback(
        ({ base, quote }) => {
            return symbolInfo?.symbolMap.get(`${base}_${quote}`);
        },
        [symbolInfo?.symbolMap],
    );
    const symbol = useMemo(() => {
        let obj: SymbolDesc = { base: coin, quote: "USD", type: "buy" };
        // 正向获取
        if (getSymbolInfo(obj)) {
            return obj;
        }

        // 尝试反向获取
        obj = {
            type: "sell",
            base: obj.quote,
            quote: obj.base,
        };
        if (getSymbolInfo(obj)) {
            return obj;
        }
    }, [coin, getSymbolInfo]);

    return useSymbolTransferQuantity({ symbol, frequency, amount });
}

/**
 * 根据用户输入的买币金额得到预计获得的币数量
 */
export function usePreviewAmount(params: { coin: string; amount?: string; fee?: string }) {
    const { coin, amount, fee } = params;

    const transferQuantity = useCoinTransferQuantity({
        coin: coin,
        amount: new SafeDecimal(1)
            .sub(fee || 0)
            .mul(new SafeDecimal(amount))
            .toNumber(),
    });
    const symbol = useSymbol(transferQuantity?.symbol.base || "", transferQuantity?.symbol.quote || "");
    const previewAmountPrecision = getSymbolPrecision({ symbol, coin, isMarketBuy: transferQuantity?.symbol.type === "buy" });
    const previewAmount = useMemo(
        () => new SafeDecimal(transferQuantity?.quantity).toFixed(previewAmountPrecision, Decimal.ROUND_DOWN),
        [previewAmountPrecision, transferQuantity?.quantity],
    );

    const ticker = useTicker(symbol?.base, symbol?.quote);
    const amountLimit = useGetLimit(symbol, ticker, transferQuantity?.symbol.type === "buy", previewAmountPrecision);

    return {
        symbol,
        amountLimit,
        previewAmount,
        previewAmountPrecision,
        previewAmountText: !transferQuantity ? "--" : NumberUtils.numberWithCommas(previewAmount).toString(),
        previewPrice: useMemo(
            () =>
                new SafeDecimal(amount || 0)
                    .div(previewAmount)
                    .toFixed(getSymbolPrecision({ symbol, coin: "USD", isMarketBuy: transferQuantity?.symbol.type === "buy" }), Decimal.ROUND_DOWN),
            [amount, previewAmount, symbol, transferQuantity?.symbol.type],
        ),
    };
}
export function useCalBuyAmount({
    baseParams,
    quoteParams,
    swapSymbol,
    isBuy,
    fee,
}: {
    swapSymbol: any;
    baseParams: { symbol: string; count?: number; needTicker: boolean };
    quoteParams: { symbol: string; count?: number; needTicker: boolean };
    isBuy: boolean;
    fee?: string;
}) {
    const swapConfig = useSwapConfig();

    const { params, validInput, precision } = useMemo(() => {
        const changeItem = baseParams.needTicker ? quoteParams : baseParams; // 手动变化的Item
        const calcItem = baseParams.needTicker ? baseParams : quoteParams; // 需要计算的item
        // 只是计算的方向，不代表实际兑换的买卖方向

        let type: Nullable<SymbolDesc["type"]> = null; // 只是计算的方向，不代表实际兑换的买卖方向

        if (!baseParams.needTicker && !quoteParams.needTicker) {
            type = null;
        } else if (calcItem.symbol === swapSymbol?.base) {
            type = "buy";
        } else if (calcItem.symbol === swapSymbol?.quote) {
            type = "sell";
        }
        const newFee = (isBuy ? Number(fee) : swapConfig?.askSpreadPer) || 0;
        let amountDecimal = new SafeDecimal(changeItem.count);
        if (baseParams.needTicker) {
            // 计算需要付出多少，加上点差
            amountDecimal = amountDecimal.mul(1 - newFee);
        } else if (quoteParams.needTicker) {
            // 计算能够得到多少，减去点差
            amountDecimal = amountDecimal.mul(1 + newFee);
        }
        const validInput = !!type; // 有效的输入

        return {
            params: {
                symbol: !validInput ? undefined : swapSymbol && { base: baseParams.symbol, quote: quoteParams.symbol, type: type! },
                amount: amountDecimal.toNumber(),
                frequency: swapConfig?.interval,
                isBuy,
            },
            validInput,
            precision: getSymbolPrecision({
                symbol: swapSymbol,
                isMarketBuy: type === "buy",
                coin: calcItem.symbol,
            }),
        };
    }, [baseParams, fee, isBuy, quoteParams, swapConfig?.askSpreadPer, swapConfig?.interval, swapSymbol]);

    const { quantity } = useBuySymbolTransferQuantity(params) || {};
    const previewAmount = useMemo(() => {
        if (baseParams.needTicker) {
            return (quantity ?? 0).toString();
        } else {
            return (baseParams.count ?? 0).toString();
        }
    }, [baseParams.count, baseParams.needTicker, quantity]);

    const previewPrice = useMemo(() => {
        if (baseParams.needTicker) {
            return new SafeDecimal(quoteParams.count).div(quantity ?? 0).toString();
        } else {
            return new SafeDecimal(quantity).div(baseParams.count ?? 0).toString();
        }
    }, [baseParams.count, baseParams.needTicker, quantity, quoteParams.count]);
    return {
        quantity: new SafeDecimal(quantity).toFixed(precision),
        previewAmount,
        previewPrice,
        validInput,
    };
}

export function useBuySymbolTransferQuantity({ symbol, amount, frequency, isBuy }: { symbol?: SymbolDesc; amount?: number; frequency?: number; isBuy?: boolean }) {
    // 盘口数据
    const orderBookData = useOrderBookDataWithTimes({ base: symbol?.base, quote: symbol?.quote, frequency });

    return useMemo(() => {
        if (!symbol) {
            return null;
        }
        if (!amount) {
            // 保证外部能拿到对应的币对
            return {
                symbol,
            };
        }
        // 从 A币种 兑换为 B币种
        const priceList = (isBuy ? orderBookData?.asks : orderBookData?.bids) ?? [];
        let coinCount = 0; // 兑换到的B的数量
        let totalPrice = 0; // 已花费A的数量
        let orderBookLastItem = priceList.length > 0 ? priceList[priceList.length - 1] : null; // 盘口最后一个格子的数据
        priceList.some((item) => {
            if (amount > totalPrice) {
                // 还剩下多少A 未兑换成B
                let leftPrice = new SafeDecimal(amount).sub(totalPrice).toNumber();
                // 如果把当前格子全部兑换为B 需要花多少A
                let itemTotalPrice = symbol.type === "buy" ? new SafeDecimal(item.price).mul(item.quantity).toNumber() : item.quantity;

                // 如果剩余未兑换的A 不足以全部兑换当前格子
                if (leftPrice < itemTotalPrice) {
                    // 则根据当前格子单价算出可以兑换多少B并累加到总数上
                    coinCount = new SafeDecimal(leftPrice)[symbol.type === "buy" ? "div" : "mul"](item.price).add(coinCount).toNumber();
                } else {
                    // 否则把当前格子的B全部数量兑换累加到已兑换到总数上
                    if (symbol.type === "buy") {
                        coinCount = new SafeDecimal(item.quantity).add(coinCount).toNumber();
                    } else {
                        coinCount = new SafeDecimal(item.quantity).mul(item.price).add(coinCount).toNumber();
                    }
                }

                // 每次把当前格子需要花费的A的数量累加，通过对累加的数量是否超出需要兑换的数量A, 就可以得知是否已经兑换完成
                totalPrice = new SafeDecimal(itemTotalPrice).add(totalPrice).toNumber();
                return false;
            }
            return true;
        });

        // 如果兑换完orderbook以后，仍然有A没有兑换，则使用orderbook最后一个格子的数据计算剩余能兑换的量
        if (amount > totalPrice && orderBookLastItem) {
            // 还剩下多少A未兑换成B
            let leftPrice = new SafeDecimal(amount).sub(totalPrice).toNumber();
            // 则根据当前格子单价算出可以兑换多少B并累加到总数上
            coinCount = new SafeDecimal(leftPrice)[symbol.type === "buy" ? "div" : "mul"](orderBookLastItem.price).add(coinCount).toNumber();
        }

        return {
            quantity: coinCount,
            // price: new SafeDecimal(amount).div(coinCount).toNumber(),
            symbol,
        };
    }, [symbol, amount, isBuy, orderBookData?.asks, orderBookData?.bids]);
}

/**
 * 比价数据
 * @param coin
 * @param amount
 * @param pionexTotalAmount pionex使用外部计算的值
 * @param precision 精度
 * @param onResult
 */
export function useCompareData({
    coin,
    amount,
    pionexTotalAmount,
    precision,
    onResult,
}: {
    coin?: string;
    amount: string;
    pionexTotalAmount?: string;
    precision?: number;
    onResult?: (data: Undefinable<CompareData[]>) => void;
}) {
    const [data, setData] = useState<CompareData[]>();
    const [loading, setLoading] = useState(false);
    const amountParams = useMemo(() => {
        if (new SafeDecimal(amount).lessThan(100)) {
            return "100";
        } else {
            return amount;
        }
    }, [amount]);

    const updateData = useCallbackStatic((res: Undefinable<CompareData[]>) => {
        const newData = (res || []).map((item) => {
            let totalAmount: number | string = item.quotation.totalAmount;

            if (item.id === "Pionex.US") {
                // pionex使用外部计算的值
                totalAmount = Number(pionexTotalAmount) || totalAmount;
            }
            return {
                ...item,
                quotation: {
                    ...item.quotation,
                    totalAmount: new SafeDecimal(totalAmount).toFixed(precision), // 对齐精度
                },
            };
        });

        const resData = newData.sort((a, b) => new SafeDecimal(b.quotation.totalAmount).sub(a.quotation.totalAmount).toNumber());

        setData(resData);
        onResult?.(resData);
    });

    useEffect(() => {
        const { host, token } = getCompareConfig();
        setLoading(true);
        const subscription = (
            !coin
                ? EMPTY
                : timer(0, DEFAULT_FREQUENCY).pipe(
                      switchMap(() =>
                          ObservableUtils.postV2(
                              `${host}/api/open/v1/exchange_quotation`,
                              {
                                  coin,
                                  amount: amountParams,
                              },
                              { "bcc-authorization": token },
                          ),
                      ),
                  )
        ).subscribe((res) => {
            updateData(res);
            setLoading(false);
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [amount, coin, updateData]);

    return { data, loading };
}

function getCompareConfig(): { host: string; token: string } {
    if (Constants.isBeta) {
        return { host: "https://buy-crypto-compare.devus.pionexusdev.com", token: "682f6a3fc30f867d066355b7302afa670385e2786b2ae188cdf21414d5ef01b6" };
    }
    return { host: "https://www.buybitcoincompare.com", token: "158a936337d7573c37977e0078533107e9994dbaa9cb1b8701cdb672990777b7" };
}

/**
 * 未做kyc或未登录状态下，指给用户初步体验的操作
 */
export function useVirtualMode(): boolean {
    const { login, kyc } = usePassedKycLogin();

    return !login || !kyc;
}

/**
 * 是否允许添加卡片
 */
export function useAllowAddCard(): { allow: boolean; processFunc?: () => void } {
    const { $st } = useTranslators();
    const { userId } = useAccountInfo();
    const [{ forwardLevelMap, currentLevel }] = useKycV2Info();
    const { tradeKycModal } = useKycV2TradeKycModal();

    const loginFunc = useCallback(() => {
        showLoginDialog($st("buy_crypto_sign"), { redirectCurrent: true });
    }, [$st]);

    if (!userId) {
        return {
            allow: false,
            processFunc: loginFunc,
        };
    }

    if (currentLevel === KycV2Level.lv2) {
        return { allow: true };
    }

    if (forwardLevelMap.get(KycV2Level.lv2)?.status === KycV2LevelStatus.reviewing) {
        // reviewing允许操作
        return { allow: true };
    }

    return { allow: false, processFunc: tradeKycModal };
}

const LOCAL_KEY_BUY_C_DEFAULT_KEY = "buy_crypto_default_key_";

/**
 * 设置默认支付方式
 * @param form
 * @param paymentMethods
 * @return 缓存默认值
 */
export function useDefaultPaymentMethod(form: FormInstance, paymentMethods: PaymentMethod[]) {
    const { userId } = useAccountInfo();

    useEffect(() => {
        if (!!form.getFieldValue(FORM_KEY_PAYMENTMETHOD)) {
            // 已选择卡片，不处理
            return;
        }

        if (!userId) {
            // 未登录默认选中余额
            form.setFieldsValue({ [FORM_KEY_PAYMENTMETHOD]: PaymentMethodChannel.BALANCE });
            return;
        }
        let defaultKey = localStorage.getItem(`${LOCAL_KEY_BUY_C_DEFAULT_KEY}${userId}`) ?? undefined;
        if (!paymentMethods?.find((item) => item.getKey() === defaultKey)) {
            // 缓存卡片已经不存在
            defaultKey = undefined;
        }
        if (!defaultKey && paymentMethods?.length) {
            defaultKey = paymentMethods[0].getKey();
        }
        form.setFieldsValue({ [FORM_KEY_PAYMENTMETHOD]: defaultKey });
    }, [form, paymentMethods, userId]);

    return useCallbackStatic((key: string) => {
        localStorage.setItem(`${LOCAL_KEY_BUY_C_DEFAULT_KEY}${userId}`, key);
    });
}

/**
 * 快捷选择的买币数量
 */
export function useShortcuts(): string[] {
    const onlineConfig = useOnlineConfig();
    return onlineConfig.buyCrypto?._?.shortAmounts || ["20", "100", "1000", "2000", "5000"];
}
export function useCoinPrice(coinBase: string) {
    const ticker = useTicker(coinBase, "USD");
    return ticker?.latest;
}
