import { useToggle } from "commonUse/tools";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Input, InputProps, message, Modal } from "@pionex-web-kit/components";
import { useTranslators } from "commonUse/Locale";
import { GlobalModalContext } from "state/GlobalModalProvider";
import useKycV2Info from "commonUse/useKycInfoV2";
import { ObservableUtils } from "trade_utils_lib";
import Constants from "utils/Constants";
import AccountManager from "utils/AccountManager";
import useAccountInfo from "commonUse/useAccountInfo";

export function useGlobalSSNConfig() {
    const [visible, { toggle: changeShow }] = useToggle();
    const [cancelable, { toggle: changeCancelable }] = useToggle();

    return { visible, changeShow, cancelable, changeCancelable };
}

export function useNoSSN() {
    const [{ originData }] = useKycV2Info();
    const useInfo = useAccountInfo();
    // fail_reason中有特定的code
    const noSSN = useMemo(() => {
        if (!useInfo.userId && useInfo.isInitialized) {
            return false;
        }
        return !!originData?.levelInfo?.some((item) => item.failReason?.some((reason) => reason === "EMPTYSSN"));
    }, [useInfo, originData?.levelInfo]);

    return noSSN;
}

/**
 * 检查是否弹窗SSN的hooks
 * @param config 配置
 * @return 检查方法，返回是否已弹窗
 */
export function useCheckShowSSN(config?: {
    // 是否主动检查
    actively?: boolean;
    // 是否可关闭
    cancelable?: boolean;
}) {
    const noSSN = useNoSSN();
    const { actively = false, cancelable = true } = config || {};

    const { ssnModalConfig } = useContext(GlobalModalContext);
    useEffect(() => {
        if (!actively) {
            return;
        }
        ssnModalConfig?.changeShow(noSSN);
        ssnModalConfig?.changeCancelable(cancelable);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [noSSN]);

    return useCallback(
        (forceCancelable = cancelable) => {
            if (noSSN && !!ssnModalConfig?.visible && !ssnModalConfig.cancelable) {
                // 之前显示了不可取消弹窗，不被改变
                return;
            }
            ssnModalConfig?.changeShow(noSSN);
            ssnModalConfig?.changeCancelable(forceCancelable);

            return noSSN;
        },
        [cancelable, noSSN, ssnModalConfig],
    );
}

/**
 * 显示SSN补充弹窗时不可取消
 */
export function useCheckShowForcedSSN() {
    const { ssnModalConfig } = useContext(GlobalModalContext);
    useEffect(() => {
        ssnModalConfig?.visible && ssnModalConfig?.changeCancelable(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ssnModalConfig?.visible]);
}

/**
 * 生成一个带拦截默认行为的点击事件
 * @param config
 */
export function useClickWithSSN(config?: Parameters<typeof useCheckShowSSN>[0]) {
    // SSN补充提示
    const checkShowSSN = useCheckShowSSN(config);

    return useCallback(
        (onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void) => {
            return (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
                const show = checkShowSSN();
                if (show) {
                    // 拦截默认的跳转行为
                    e?.preventDefault();
                } else {
                    onClick?.();
                }
            };
        },
        [checkShowSSN],
    ) as any;
}

/**
 * 限制输入格式
 * @param value
 * @param onChange
 * @param props
 * @constructor
 */
const SSNFormatInput = ({ value, onChange, ...props }: Omit<InputProps, "onChange" | "value"> & { value?: string; onChange?(v: string) }) => {
    const format = /(\d{3,3})-?(\d{1,2})?-?(\d{1,4})?.*/;
    const inputFormatter = <T extends undefined | string>(value: T, splitter: string = ""): T =>
        value?.replace(/[^\d|\-]/g, "").replace(format, (m, p1, p2, p3) => {
            return `${p1}${p2 ? `${splitter}${p2}` : ""}${p3 ? `${splitter}${p3}` : ""}`;
        }) as any;
    const [displayValue, updateDisplay] = useState(() => inputFormatter(value));
    return (
        <Input
            value={displayValue}
            onChange={(e) => {
                let value = e.target.value;
                updateDisplay(inputFormatter(value, "-"));
                onChange?.(inputFormatter(value, "-"));
            }}
            {...props}
        />
    );
};

/**
 * 补充SSN弹窗
 * @constructor
 */
export const SSNModal: React.FC = () => {
    const { $st } = useTranslators();
    const { ssnModalConfig } = useContext(GlobalModalContext);
    const [, , { update: updateKycInfo }] = useKycV2Info();

    const [ssn, setSSN] = useState<string>();
    const error = useMemo(() => {
        if (ssn === undefined) {
            // 初始未填写不提示错误
            return undefined;
        }

        return /^(\d{3,3})-(\d{2,2})-(\d{4,4})$/.test(ssn) ? undefined : $st("kyc_jumio_s1_ssn_invalid");
    }, [$st, ssn]);

    const [loading, { setTrue: showLoading, setFalse: hideLoading }] = useToggle();

    const handleSubmit = useCallback(() => {
        showLoading();
        ObservableUtils.postV2(
            `${Constants.accountServiceHost}/user/save_kyc_ssn`,
            {
                ssn,
            },
            undefined,
            true,
        ).subscribe(
            (res) => {
                ssnModalConfig?.changeShow(false);
                updateKycInfo();
            },
            (e) => {
                message.error(AccountManager.parseErrorTips(e));
            },
            () => {
                hideLoading();
            },
        );
    }, [hideLoading, showLoading, ssn, ssnModalConfig, updateKycInfo]);

    return (
        <Modal
            okButtonProps={{ disabled: !!error || !ssn, loading }}
            cancelButtonProps={{ style: ssnModalConfig?.cancelable ? undefined : { display: "none" } }}
            width={400}
            visible={ssnModalConfig?.visible}
            onCancel={() => {
                if (!ssnModalConfig?.cancelable) {
                    return;
                }
                ssnModalConfig?.changeShow?.(false);
            }}
            onOk={handleSubmit}
            title={$st("ssn_title")}
            okText={$st("common_submit")}
        >
            <div className={"mb-16px"}>{$st("ssn_des")}</div>
            <SSNFormatInput
                value={ssn}
                onChange={setSSN}
                size={"large"}
                status={error ? "error" : undefined}
                errors={error ? [error] : undefined}
                label={error ? $st("kyc_jumio_s1_ssn_invalid") : $st("kyc_jumio_s1_ssn_tin")}
            />
        </Modal>
    );
};
