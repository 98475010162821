import Axios from "axios";
import Decimal from "decimal.js";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NumberCommonUtils, SafeDecimal } from "trade_utils_lib";
import Constants from "utils/Constants";
// FIXME: this is for deprecated.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useLocation } from "react-router";
import { Observable } from "rxjs";
import { fromPromise } from "rxjs/internal-compatibility";
import { map, mergeMap, retry, shareReplay } from "rxjs/operators";
import useMedia from "src/components-v2/Layout/hooks/useMedia";

// 创建一个隔离的 Axios实例用以请求静态配置
const $axios = Axios.create();

export const useToggle = (
    initialState: boolean | (() => boolean) = false,
    { onTrue, onFalse, onChange }: { onTrue?: () => any; onFalse?: () => any; onChange?(state: boolean): void } = {},
) => {
    const [flag, setFlag] = useState<boolean>(initialState);
    const operators = {
        setTrue: useCallbackStatic(() => {
            setFlag(true);
            onTrue?.();
            onChange?.(true);
        }),
        setFalse: useCallbackStatic(() => {
            setFlag(false);
            onFalse?.();
            onChange?.(false);
        }),
        toggle: useCallbackStatic((_flag?: any) => {
            _flag = typeof _flag === "boolean" ? _flag : !flag;
            if (_flag) onTrue?.();
            else onFalse?.();
            setFlag(_flag);
            onChange?.(_flag);
        }),
    };
    return [flag, operators] as [typeof flag, typeof operators];
};

/**
 * @deprecated Use {@link useMedia} instead.
 * @param forceW
 * @returns
 */
export const useIsPhoneView = (forceW: number = 767) => {
    const [phoneView, setPhoneView] = useState(false);
    useEffect(() => {
        const checkWindowWidth = () => {
            const w = document.documentElement.clientWidth;
            if (w < (forceW || 1180)) {
                setPhoneView(true);
            } else {
                setPhoneView(false);
            }
        };
        checkWindowWidth();
        window.addEventListener("resize", checkWindowWidth, false);
        return () => window.removeEventListener("resize", checkWindowWidth);
    }, [forceW]);
    return phoneView;
};

export function usePercentInputController({
    maxInvest,
    initValue = "",
    initPercent = 0,
    precision = 2,
}: {
    maxInvest: number;
    initValue?: string;
    initPercent?: number;
    precision?: number;
}) {
    const [value, setValue] = useState(initValue);
    const [percent, setPercent] = useState(initPercent);
    const onValueChange = useCallback(
        (value: string | number) => {
            // if (Number(value) > maxInvest) {
            //     value = maxInvest;
            // }
            setValue(value.toString());
            const percent = NumberCommonUtils.formatterDecimal(new Decimal(value).div(maxInvest).mul(100), 0).toNumber();
            setPercent(Math.min(percent, 100));
        },
        [maxInvest],
    );
    const onPercentChange = useCallback(
        (e) => {
            setPercent(e);
            setValue(new SafeDecimal(maxInvest).mul(e).div(100).toFixed(precision));
        },
        [maxInvest, precision],
    );
    const reset = useCallback(() => {
        setValue(initValue);
        setPercent(initPercent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        value,
        percent,
        onValueChange,
        onPercentChange,
        reset,
    };
}

/**
 * 一个不需要管理依赖的 useCallback 缓存函数
 * 传入的被包装函数，将永远保持最新的外部引用状态，
 * 且包装后的函数索引将永远不会发生变化。因此，就不必再惯性callback函数的依赖问题了。
 * @param {(...args: any[]) => any} callback
 * @returns
 */
export function useCallbackStatic<T extends (...args: any[]) => any>(callback: T) {
    const proxyRef = useRef(callback);
    proxyRef.current = callback;
    const staticCachedCall = useCallback((...args) => {
        return proxyRef.current(...args);
    }, []);
    return staticCachedCall as T;
}

const _default_aggregate_id = "__default_agg_id";
/**
 * 创建一个聚合调用异步处理函数。
 * 它会将多次平行异步调用，在第一次调用未完成前，合并为一次主要的异步调用。
 * 同时后续的调用，将复用第一次异步调用的返回结果。
 * @param param0
 * @returns
 */
export const createAggregatedRequest = <
    D,
    P extends {
        /**
         * 判别调用是否合并的唯一标记。如果调用时传入的标记匹配到前一次传入的标记，则会被复用。
         * 注意：若传入参数不同，请记得修改该字段值，避免复用到无效数据
         */
        $identifier?: string | number;
        [k: string]: any;
    },
    E,
>({
    requester,
    expire,
}: {
    requester: (p?: P) => Promise<D>;
    // 合并后的继续缓存时长
    expire?: number;
}) => {
    let isPending = false;
    type Resolvers = ((r: D) => any)[];
    type Rejects = ((e: E) => void)[];
    interface MapData {
        timestamp: number;
        cache: null | D;
        resolves: Resolvers;
        rejects: Rejects;
    }

    let paramKeySet = new Map<string | number, MapData>();
    return (params?: P) => {
        const $identifier = params?.$identifier || _default_aggregate_id;
        // 检测判别标记，如果不同，那就不复用上一次的请求
        const shouldAggregate = paramKeySet.get($identifier);
        if (shouldAggregate && !isPending && shouldAggregate?.cache !== null && shouldAggregate?.cache !== undefined && expire && Date.now() - shouldAggregate.timestamp < expire) {
            // 缓存有效期内，直接使用cache数据
            return Promise.resolve(shouldAggregate.cache as D);
        }
        return new Promise<D>((res, rej) => {
            if (shouldAggregate && isPending) {
                const { resolves, rejects } = shouldAggregate;
                resolves.push(res);
                rejects.push(rej);
            } else {
                const resolves: Resolvers = [];
                const rejects: Rejects = [];
                resolves.push(res);
                rejects.push(rej);
                isPending = true;
                const _MapData = { timestamp: Date.now(), cache: null, resolves, rejects };
                paramKeySet.set($identifier, _MapData);
                requester(params)
                    .then((res) => {
                        if (res !== null && res !== undefined) paramKeySet.set($identifier, { ..._MapData, cache: res });
                        resolves.forEach((call) => call(res));
                    })
                    .finally(() => {
                        isPending = false;
                        // 清理已处理
                        const _map_data = paramKeySet.get($identifier);
                        if (_map_data) paramKeySet.set($identifier, { ..._map_data, rejects: [], resolves: [] });
                    })
                    .catch((err) => {
                        rejects.forEach((call) => call(err));
                        console.error(err);
                    });
            }
        });
    };
};

export interface LocaleIPData {
    city_name: string;
    /**
     * {@link Country Codes Collection | https://www.iso.org/obp/ui/#iso:pub:PUB500001:en}
     */
    country_code: string;
    country_name: string;
    ip: string;
    /**
     * {@link US-Region Code Collection | https://en.wikipedia.org/wiki/List_of_U.S._state_and_territory_abbreviations}
     */
    region_code: string;
    region_name: string;
    time: number;
}

// 中国特别行政区
const CN_SPECIAL_REGIONS = ["MO", "HK", "TW"];
let ipLocCache:
    | undefined
    | {
          time: number;
          data: LocaleIPData;
          isCNRegion: boolean;
      } = undefined;

const ipRequest = createAggregatedRequest({
    requester: () => $axios.get<{ data: LocaleIPData; code: number; message: string }>(`${Constants.accountServiceHost}/iploc`),
    expire: 5 * 60 * 1e3,
});
/**
 * 获取当前用的访问IP数据
 */
export const useLocaleIpInfo = (useCache?: boolean | number) => {
    const [loading, setLoading] = useState(true);
    const [ipInfo, setIpInfo] = useState<LocaleIPData | undefined>();
    const [isCNMainlandRegion, setIsCNMainlandRegion] = useState<boolean>();

    useEffect(() => {
        setLoading(true);

        if (!ipLocCache || Date.now() - ipLocCache.time > (useCache === false ? 0 : typeof useCache === "number" ? useCache : 60e3)) {
            ipRequest()
                .then(({ data }) => {
                    if (data.code === 0 && data.data) {
                        // 如果是中国，且不包含中国的特别行政区以及TW，则认为是中国大陆地区
                        const isCNRegion = data.data.country_code === "CN" && !CN_SPECIAL_REGIONS.includes(data.data.region_code);
                        ipLocCache = {
                            time: Date.now(),
                            data: data.data,
                            isCNRegion,
                        };
                        setIpInfo(data.data);
                        setIsCNMainlandRegion(isCNRegion);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setIpInfo(ipLocCache.data);
            setIsCNMainlandRegion(ipLocCache.isCNRegion);
            setLoading(false);
        }
    }, []);

    return { loading, ipInfo, isCNMainlandRegion };
};

/**
 * Step组件公共状态管理函数
 * @param maxStep 最大可走步数
 * @param startStep 开始步数
 * @returns
 */
export const useSteps = (maxStep: number, startStep = 0) => {
    const [step, setStep] = useState(startStep);
    const nextStep = useCallbackStatic((call?: (preStep) => void) => {
        call?.(step);
        return setStep(step >= maxStep ? maxStep : step + 1);
    });
    const prevStep = useCallbackStatic((call?: (preStep) => void) => {
        call?.(step);
        return setStep(step <= startStep ? startStep : step - 1);
    });
    const resetStep = useCallbackStatic((call?: (preStep) => void) => {
        call?.(step);
        return setStep(startStep);
    });
    // 限制数据范围
    let currentStep = step > maxStep ? maxStep : step;
    currentStep = currentStep < startStep ? startStep : currentStep;
    return [currentStep, { isFinalStep: currentStep === maxStep, hasNext: step <= maxStep, nextStep, prevStep, resetStep }] as const;
};

export function useQueryParams() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

type IServedIpAreaConfig = {
    // 开放的国家 Code
    country: string;
    // 国家名
    name: string;
    // 当前国家开放的州或者省。
    // 注意：如果对该国家全面开放，需要将该字段直接置为 true
    serve_regions?: true | Array<{ name: string; code: string }>;
    // 受限制的州，这个选项和serve_regions 是互斥配置的。
    limit_regions?: Array<{ name: string; code: string }>;
}[];

type IIPRegionSuspended = {
    // 开放的国家 Code
    country: string;
    name?: string;
    // 当前国家开放的州或者省。
    // 注意：如果对该国家全面开放，需要将该字段直接置为 true
    regions?: Array<{ name?: string; code: string }>;
}[];

interface IIPLimits {
    served: IServedIpAreaConfig;
    suspended: IIPRegionSuspended;
}

const ipLimitsRequest = createAggregatedRequest({
    requester: () => $axios.get<IIPLimits>(`${Constants.configHost}/ip_limits.json`, { headers: { "cache-control": "no-cache" } }),
    expire: 5 * 60 * 1e3,
});
export function useIPLimits() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<IIPLimits>();
    const loadData = useCallbackStatic(async () => {
        if (loading) return;
        setLoading(true);
        try {
            const { data } = await ipLimitsRequest();
            setData(data);
        } catch (e) {}
        setLoading(false);
    });
    useEffect(() => {
        loadData();
    }, []);
    return [loading, data, loadData] as const;
}

/**
 * 获取服务州的限制配置
 * @returns
 */
export function useIPLimitConfig() {
    const [loading, data, loadData] = useIPLimits();
    return [loading, data?.served, loadData] as const;
}
/**
 * 判别是否
 * @returns
 */
export function useIPLimitOfUSSite() {
    const ipData = useLocaleIpInfo();
    const { ipInfo, loading } = ipData;
    const [listLoading, limits] = useIPLimits();
    const data = useMemo(() => {
        if (!ipInfo || !limits?.served) return;
        let siteUSServed = false;
        let siteUSServedAreaName = ipInfo?.country_name;
        for (const country of limits.served) {
            if (country.country === ipInfo?.country_code) {
                // 默认为不服务
                let isServeRegion = false;
                // 优先遍历
                if (country.serve_regions !== undefined) {
                    // 当全量地区服务时
                    if (country.serve_regions === true) {
                        siteUSServed = true;
                    } else if (!ipInfo?.region_code) {
                        // 如果没有识别到准确的州，就不应该阻止用户使用
                        siteUSServed = true;
                    } else if (Array.isArray(country.serve_regions)) {
                        // 当仅对配置地区服务时
                        for (const serve_region of country.serve_regions) {
                            if (serve_region.code === ipInfo.region_code) {
                                isServeRegion = true;
                                break;
                            }
                        }
                    }
                } else if (Array.isArray(country.limit_regions) && country.limit_regions.length > 0) {
                    if (!ipInfo?.region_code) {
                        // 如果没有识别到准确的州，就不应该阻止用户使用
                        siteUSServed = true;
                    } else {
                        // 检测受限地区时，默认重置为服务所有地区
                        isServeRegion = true;
                        for (const limit_region of country.limit_regions) {
                            if (limit_region.code === ipInfo.region_code) {
                                // 符合条件时，表示明确不服务该地区
                                isServeRegion = false;
                                break;
                            }
                        }
                    }
                }
                if (isServeRegion) {
                    siteUSServed = true;
                    if (ipInfo?.region_code)
                        // 用于显示服务或受限地区名称
                        siteUSServedAreaName = `${ipInfo?.country_name} / ${ipInfo.region_name}`;
                    break;
                }
            }
        }
        return { siteUSServed, siteUSServedAreaName };
    }, [ipInfo, limits]);
    return [data, { ipData, loading: loading || listLoading, serveList: limits?.served }] as const;
}

/**
 * 获取系统定位权限并返回经纬度信息
 * @returns 定位相关信息，包含经纬度
 */
function getGeoPosition(): Promise<GeolocationCoordinates> {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (pos) => {
                // 获取系统定位成功，返回经纬度
                resolve(pos.coords);
            },
            (err) => {
                // 失败类型分为 拒绝授权1 位置不可用2 超时3
                reject(err.code);
            },
        );
    });
}

type TGeoData = { country: string; state: string };

/**
 * 根据经纬度获取定位信息的请求
 * @param param0 经纬度
 * @returns 聚合调用异步处理函数
 */
function fetchGeoPosition({ latitude, longitude }) {
    return createAggregatedRequest({
        requester: () =>
            $axios.get<{ data: TGeoData; code: number; message: string }>(`${Constants.accountServiceHost}/geo`, {
                params: {
                    lat: latitude,
                    lng: longitude,
                },
            }),
        expire: 5 * 60 * 1e3,
    });
}

/**
 * 获取gps信息，根据定位调用接口获取位置信息
 * @returns loding状态、gps信息
 */
export function useGpsInfo() {
    // 获取授权，拿到经纬度
    // 根据经纬度信息调用接口，拿到位置信息
    const gpsRef = useRef<Observable<TGeoData> | null>(null);

    const getGpsData = useCallbackStatic(() => {
        if (gpsRef.current) {
            return gpsRef.current;
        }
        return (gpsRef.current = fromPromise(getGeoPosition()).pipe(
            mergeMap((geoPos) => {
                return fetchGeoPosition(geoPos)();
            }),
            retry(1),
            map((res) => {
                return res.data.data;
            }),
            shareReplay(1),
        ));
    });

    return {
        getGpsData,
    };
}
