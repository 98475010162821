import { useCircleDebitAPI } from "landings/V2/CircleDebit/context";
import { useRxRequest } from "commonUse/useRequest";
import React, { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { DebitCard, DebitCardStatus, ESumsubVerifyStatus, FundingType } from "landings/V2/CircleDebit/types";
import { ST } from "commonUse/Locale";
import { useOnlineConfig } from "utils/onlineConfig";
import { USDDWContext } from "landings/V2/USDDW/context";
import { DWChannelStatus } from "landings/V2/USDDW/types";
import { DEBIT_CHANEL__ALL_CHANNEL } from "landings/V2/CircleDebit/utils";
import { catchError } from "rxjs/operators";
import { message } from "@pionex-web-kit/components";
import { parseError } from "utils/Utils";

/**
 * 获取debit card 列表，如果有pending状态的卡片，保持轮询更新
 */
export function useCards(disableAutoRefresh = false) {
    const circleDebitAPI = useCircleDebitAPI();
    const { channelStatusInfo } = useContext(USDDWContext);
    const [refreshTimes, setRefreshTimes] = useState(1);

    const res = useRxRequest(() =>
        circleDebitAPI?.getCards().pipe(
            catchError((err) => {
                message.error(parseError(err));
                throw err;
            }),
        ),
    );
    const { data, refresh } = res;

    useEffect(() => {
        let timeout;
        // 数据中有pending的卡片轮询刷新
        if (!disableAutoRefresh && !!data?.length && !!data?.some((item) => ![DebitCardStatus.SUCCESS, DebitCardStatus.FAILED].includes(item.status))) {
            timeout = setTimeout(() => {
                setRefreshTimes((val) => val + 1);
                refresh();
            }, 3000);
        } else clearTimeout(timeout);
        if (refreshTimes > 100) {
            clearTimeout(timeout);
        }
        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, refreshTimes, disableAutoRefresh]);

    return {
        ...res,
        data: useMemo(
            () =>
                res.data?.map((item) => {
                    // 通过本地数据，注入通道是否无效的字段值
                    return { ...item, channelInvalid: channelStatusInfo?.[DEBIT_CHANEL__ALL_CHANNEL[item.channel]].deposit !== DWChannelStatus.OPEN } as DebitCard;
                }),
            [channelStatusInfo, res.data],
        ),
    };
}

/**
 * 获取状态描述文案
 */
export function getStatusTips($st: ST, card: DebitCard, verifyCard: () => void): ReactNode {
    const ver = {
        verify: (t) => (
            <a style={{ textDecoration: "underline", fontWeight: "bold" }} onClick={verifyCard}>
                {t}
            </a>
        ),
    };
    switch (card.status) {
        case DebitCardStatus.PENDING:
            return $st("card_status_tips_pending");
        case DebitCardStatus.VERIFY_REQUIRED: {
            // 当Sumsub验证未通过，需要用户重新上传资料时
            if (card.verifyStatus === ESumsubVerifyStatus.PENDING) {
                return $st("card_status_tips_pending");
            }
            /**
             * 其他状态由{@link TipsCover}处理展示
             */
            return null;
        }
        case DebitCardStatus.SUCCESS: {
            // 当Sumsub验证未通过，需要用户重新上传资料时
            if (card.verifyStatus === ESumsubVerifyStatus.RESUBMISSION) {
                return $st("debit_card_sumsub_verify_resubmit", ver);
            }
            // 状态为approved 并且 sumsub状态不是认证通过状态
            if (ESumsubVerifyStatus.NOT_STARTED === card.verifyStatus) {
                return $st(`debit_card_sumsub_verify_status:${card.verifyStatus}`, ver);
            }
            if ([ESumsubVerifyStatus.PENDING, ESumsubVerifyStatus.REJECTED].includes(card.verifyStatus)) {
                return $st(`debit_card_sumsub_verify_status:${card.verifyStatus}`);
            }
        }
    }
    return null;
}

export function getCardStatusText($st: ST, card: DebitCard) {
    if (card.channelInvalid) {
        // 对应通道关闭
        return $st("debit_card_channel_maintenance");
    }

    if (card.status === DebitCardStatus.VERIFY_REQUIRED && card.verifyStatus === ESumsubVerifyStatus.PENDING) {
        return $st(`card_status:${DebitCardStatus.PENDING}`);
    }

    return $st(`card_status:${card.status}`);
}

export namespace DebitDefaultCard {
    const LOCAL_KEY_DEBIT_DEFAULT_CARD_ID = "debit_default_card_id_";

    export function getDefaultCardId(userId: string, cards?: DebitCard[]) {
        let cardId = localStorage.getItem(`${LOCAL_KEY_DEBIT_DEFAULT_CARD_ID}${userId}`) ?? undefined;
        if (!cards?.find((item) => item.id === cardId)) {
            // 缓存卡片已经不存在
            cardId = undefined;
        }
        if (!cardId && cards?.length) {
            cardId = cards[0].id;
        }

        return cardId;
    }

    export function cacheDefaultCard(userId: string, cardId: string) {
        localStorage.setItem(`${LOCAL_KEY_DEBIT_DEFAULT_CARD_ID}${userId}`, cardId);
    }
}

const DEFAULT_MIN_AMOUNT = 10;
const DEFAULT_MAX_AMOUNT = 2500;

/**
 * 入金金额边界
 */
export function useDepositLimit(): { min: number; max: number; tip: string } {
    const config = useOnlineConfig();
    const limit = config.dw?.d?.cir_card?.limit;

    return {
        min: limit?.min ?? DEFAULT_MIN_AMOUNT,
        max: limit?.max ?? DEFAULT_MAX_AMOUNT,
        tip: limit?.tip ?? "Daily Limit - $1,000, Weekly Limit - $3,000, Monthly Limit - $12,000.",
    };
}

/**
 * 是否在结果页显示FAQ入口
 * @param failReason
 */
export function isShowFAQInResult(failReason: string | undefined) {
    return !!failReason && ["THREE_D_SECURE_ACTION_EXPIRED", "THREE_D_SECURE_NOT_SUPPORTED", "THREE_D_SECURE_FAILURE"].includes(failReason);
}

/**
 * 是否被允许入金的卡片
 * @param card
 */
export function isAllowedDepositCard(card: DebitCard): boolean {
    if (card.channelInvalid) {
        return false;
    }
    return card?.status === DebitCardStatus.SUCCESS;
}
