const decStrNum = (value: string, precision?: number) => {
    if (!value) {
        return "";
    }
    const reg = /^[0-9.-]*$/;
    let hasPoint = false;

    let newStr = "";
    for (let i = 0; i < value.length; i++) {
        let c = value.substr(i, 1);
        if (c === "," || c === "。") {
            c = ".";
        }
        if (i === 0 && c === ".") {
            continue;
        }
        if (i !== 0 && c === "-") {
            continue;
        }
        if (c === ".") {
            if (hasPoint) {
                continue;
            } else {
                hasPoint = true;
            }
        }
        if (reg.test(c)) {
            newStr += c;
        }
    }

    if (precision !== undefined && precision !== null) {
        const pointIndex = newStr.indexOf(".");
        if (pointIndex >= 0) {
            const decimalLength = precision <= 0 ? 0 : precision + 1;
            newStr = newStr.substr(0, pointIndex + decimalLength);
        }
    }
    return newStr;
};
export default decStrNum;
