import { Modal } from "antd";
import LeverageCoinExamModalContent from "landings/Trade/BotArea/LeverageCoinExamModalContent";
import React from "react";

export function showEnterLCEDialog() {
    const modalInfo = Modal.info({});
    modalInfo.update({
        icon: null,
        centered: true,
        maskClosable: false,
        content: <LeverageCoinExamModalContent onClose={modalInfo.destroy} />,
        okButtonProps: {
            hidden: true,
        },
    });
}
