/**
 * @author Zhoukai
 * @date 2020/6/24
 * @time 2:56 下午
 * @fileName botOrderHelper.ts
 * @Description:
 */
import transformBaseExtraDisplay from "landings/PionexHistoricalOrderDetails/components/Grid2OrderCommon/calc/transformBaseExtraDisplay";
import {
    OrderClassifyType,
    OrderTabType,
    OrderTypeDisplay,
    PAGE_NUM,
    PAGE_SIZE,
    TradingBotOrderTableData,
    TEMP_ORDER_STATUS_PAUSED,
} from "landings/Trade/TradeConstant";
import { useEffect, useState } from "react";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, concatMap, distinctUntilChanged, flatMap, map, take } from "rxjs/operators";
import ExchangeRate from "state/ExchangeRate";
import ThemeData from "state/ThemeData";
import { APIKeyInfo, ExchangeTicker, NumberCommonUtils, requestWithRetry } from "trade_utils_lib";
import {
    ExchangeID,
    ExchangeOrder,
    ExchangeOrderSide,
    ExchangeOrderStatus,
    ExchangeOrderType,
    ExchangeTradePair,
    OrderInnerStatus,
    OrderTypeDesc,
    QueryBotOrderCountParam,
    QueryBotOrdersParam,
    SmartRebalanceSubType,
    SmartTradeType,
    SymbolInfo,
    TradeUtils,
    exchangeSLProOrder,
} from "TradeAPILib";
import { TradeAssetsInfo } from "TradeAPILib/dist/src/ExchangeAPIAdapter/TradeTypeDef";
import ExchangeDataProvider from "TradeLib/ExchangeDataProvider";
import { $st, LANGUAGE_LOCALS_MAP } from "utils/i18n";
import NumberUtils from "utils/NumberUtils";
import { OrderUtils } from "utils/OrderUtils";
import { getUserProfitModel } from "utils/userInfo";
import { ParseErrorUtils } from "trade_lib_wrapper";
import { getMTGShowName, isSharableMTGOrder } from "landings/PionexHistoricalOrderDetails/components/Martingale/utils";
import isCopyOrder from "landings/PionexHistoricalOrderDetails/components/Grid2OrderCommon/tool/isCopyOrder";
import { MoonBotKey } from "@pionex-web-kit/trading-components";

export function useBotOrderInfo(
    apiKey: APIKeyInfo | undefined,
    pageType: OrderTabType,
    showLoading: boolean,
    currentPairOnly: boolean,
    exchangeTradePair?: ExchangeTradePair,
    pageNo = PAGE_NUM,
    pageNum = PAGE_SIZE,
    orderType?: ExchangeOrderType,
): [ExchangeOrder[], number, boolean] {
    const [data, setData] = useState<ExchangeOrder[]>([]);
    const [count, setCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    // const [botAsset, setBotAsset] = useState<TradeAssetsInfo | undefined>();
    useEffect(() => {
        if (apiKey === undefined) {
            return;
        }
        const orderDataObserve = botOrderDataRequest(apiKey, pageType, pageNo, pageNum, currentPairOnly, exchangeTradePair, orderType);
        const orderCountObserve = botOrderCountRequest(apiKey, pageType, orderType);
        // const orderAssetObserve = botAssetRequest(apiKey);
        setLoading(true);
        const tempObserve = forkJoin(orderDataObserve, orderCountObserve).subscribe(
            (result) => {
                const exchangeList = result[0];
                const exchangeCount = result[1];
                // const botAsset = result[2];
                setData(exchangeList);
                setCount(exchangeCount);
                // setBotAsset(botAsset);
                setLoading(false);
            },
            () => {
                setLoading(false);
            },
        );
        return () => {
            tempObserve && tempObserve.unsubscribe();
        };
    }, [apiKey, currentPairOnly, exchangeTradePair, orderType, pageNo, pageNum, pageType]);

    return [data, count, loading];
}

/**
 * 获取策略订单信息
 * @param apiKey
 * @param pageType
 * @param pageNo
 * @param pageNum
 * @param orderType
 */
export function botOrderDataRequest(
    apiKey: APIKeyInfo,
    pageType: OrderTabType,
    pageNo = 0,
    pageNum = PAGE_SIZE,
    currentPairOnly: boolean,
    exchangeTradePair?: ExchangeTradePair,
    orderType?: ExchangeOrderType,
): Observable<ExchangeOrder[]> {
    const orderParam = {} as QueryBotOrdersParam;
    orderParam.status = pageType;
    orderParam.pageNo = pageNo;
    orderParam.pageNum = pageNum;
    if (orderType) {
        orderParam.orderType = orderType;
    }
    if (currentPairOnly && exchangeTradePair) {
        orderParam.base = exchangeTradePair.base;
        orderParam.quote = exchangeTradePair.quote;
    }
    // return requestWithRetry(() => ExchangeDataProvider.getQueryBotOrdersMoreObservable(apiKey, orderParam)
    //     .pipe(map((result) => result.filter((obj) => obj.orderType !== ExchangeOrderType.fsa))));
    return requestWithRetry(() => ExchangeDataProvider.getQueryBotOrdersMoreObservable(apiKey, orderParam));
    // .pipe(map((result)=> result.filter((obj)=>obj.orderType !== ExchangeOrderType.fsa))));
}

/**
 * 获取策略订单数量
 * @param apiKey
 * @param pageType
 * @param orderType
 */
export function botOrderCountRequest(apiKey: APIKeyInfo, pageType: OrderTabType, orderType?: ExchangeOrderType): Observable<number> {
    const orderParam = {} as QueryBotOrderCountParam;
    orderParam.status = pageType as "running" | "finished";
    if (orderType) {
        orderParam.orderType = orderType;
    }
    return requestWithRetry(() => ExchangeDataProvider.getBotOrdersCountObservable(apiKey, orderParam));
}

export function botAssetRequest(apiKey: APIKeyInfo): Observable<TradeAssetsInfo> {
    return requestWithRetry(() =>
        ExchangeDataProvider.getExchangeDataProvider(apiKey).pipe(flatMap((exchangeDataProvider) => exchangeDataProvider.api.queryCurrentDayProfitAndLoss())),
    );
}

export function useWebSocketMarketBotOrder(apiKey: APIKeyInfo | undefined): ExchangeOrder | undefined {
    const [order, setOrder] = useState<ExchangeOrder | undefined>(undefined as unknown as ExchangeOrder);

    useEffect(() => {
        if (apiKey === undefined) {
            return;
        }
        const tempObserve = ExchangeDataProvider.accountWebSocket
            .getBotOrderObservable()
            .pipe(
                distinctUntilChanged((obj1, obj2) => {
                    if (obj1.id === obj2.id) {
                        if (obj1.orderType === obj2.orderType) {
                            switch (obj2.orderType) {
                                case ExchangeOrderType.grid:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeGridOrder!.totalInvest === obj2.exchangeGridOrder!.totalInvest &&
                                        obj1.exchangeGridOrder!.transactionAmount === obj2.exchangeGridOrder!.transactionAmount &&
                                        obj1.closeTimestamp === obj2.closeTimestamp
                                    );
                                case ExchangeOrderType.ig:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeIGOrder!.totalInvest === obj2.exchangeIGOrder!.totalInvest &&
                                        obj1.exchangeIGOrder!.transactionAmount === obj2.exchangeIGOrder!.transactionAmount &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeIGOrder!.latestBaseAmount === obj2.exchangeIGOrder!.latestBaseAmount &&
                                        obj1.exchangeIGOrder!.latestQuoteAmount === obj2.exchangeIGOrder!.latestQuoteAmount
                                    );
                                case ExchangeOrderType.gridPro:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeGridProOrder!.totalInvest === obj2.exchangeGridProOrder!.totalInvest &&
                                        obj1.exchangeGridProOrder!.trx_history === obj2.exchangeGridProOrder!.trx_history &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeGridProOrder!.triggerTime === obj2.exchangeGridProOrder!.triggerTime &&
                                        obj1.innerStatus === obj2.innerStatus &&
                                        obj1.exchangeGridProOrder!.baseAmount === obj2.exchangeGridProOrder!.baseAmount &&
                                        obj1.exchangeGridProOrder!.quoteAmount === obj2.exchangeGridProOrder!.quoteAmount &&
                                        //下列为提取利润相关
                                        obj1.exchangeGridProOrder!.profitWithdrawn === obj2.exchangeGridProOrder!.profitWithdrawn &&
                                        obj1.exchangeGridProOrder!.latestQuoteAmount === obj2.exchangeGridProOrder!.latestQuoteAmount &&
                                        obj1.exchangeGridProOrder!.baseFeeReserve === obj2.exchangeGridProOrder!.baseFeeReserve &&
                                        obj1.exchangeGridProOrder!.quoteFeeReserve === obj2.exchangeGridProOrder!.quoteFeeReserve
                                    );
                                case ExchangeOrderType.gridClassic:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeGridClassicOrder!.totalInvest === obj2.exchangeGridClassicOrder!.totalInvest &&
                                        obj1.exchangeGridClassicOrder!.trx_history === obj2.exchangeGridClassicOrder!.trx_history &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeGridClassicOrder!.triggerTime === obj2.exchangeGridClassicOrder!.triggerTime &&
                                        obj1.innerStatus === obj2.innerStatus &&
                                        obj1.exchangeGridClassicOrder!.baseAmount === obj2.exchangeGridClassicOrder!.baseAmount &&
                                        obj1.exchangeGridClassicOrder!.quoteAmount === obj2.exchangeGridClassicOrder!.quoteAmount
                                    );
                                case ExchangeOrderType.gridLeverage:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeLeverageGridOrder!.totalInvest === obj2.exchangeLeverageGridOrder!.totalInvest &&
                                        obj1.exchangeLeverageGridOrder!.trx_history === obj2.exchangeLeverageGridOrder!.trx_history &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeLeverageGridOrder!.liquidatedTriggered === obj2.exchangeLeverageGridOrder!.liquidatedTriggered &&
                                        obj1.exchangeLeverageGridOrder!.triggerTime === obj2.exchangeLeverageGridOrder!.triggerTime &&
                                        obj1.exchangeLeverageGridOrder!.liquidatedPrice === obj2.exchangeLeverageGridOrder!.liquidatedPrice &&
                                        obj1.innerStatus === obj2.innerStatus &&
                                        obj1.exchangeLeverageGridOrder!.baseAmount === obj2.exchangeLeverageGridOrder!.baseAmount &&
                                        obj1.exchangeLeverageGridOrder!.quoteAmount === obj2.exchangeLeverageGridOrder!.quoteAmount &&
                                        obj1.exchangeLeverageGridOrder!.loanCoin === obj2.exchangeLeverageGridOrder!.loanCoin
                                    );
                                case ExchangeOrderType.gridLoan:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeLoanGridOrder!.totalInvest === obj2.exchangeLoanGridOrder!.totalInvest &&
                                        obj1.exchangeLoanGridOrder!.trx_history === obj2.exchangeLoanGridOrder!.trx_history &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeLoanGridOrder!.liquidatedTriggered === obj2.exchangeLoanGridOrder!.liquidatedTriggered &&
                                        obj1.exchangeLoanGridOrder!.triggerTime === obj2.exchangeLoanGridOrder!.triggerTime &&
                                        obj1.exchangeLoanGridOrder!.liquidatedPrice === obj2.exchangeLoanGridOrder!.liquidatedPrice &&
                                        obj1.innerStatus === obj2.innerStatus &&
                                        obj1.exchangeLoanGridOrder!.baseAmount === obj2.exchangeLoanGridOrder!.baseAmount &&
                                        obj1.exchangeLoanGridOrder!.quoteAmount === obj2.exchangeLoanGridOrder!.quoteAmount &&
                                        obj1.exchangeLoanGridOrder!.loanCoin === obj2.exchangeLoanGridOrder!.loanCoin
                                    );
                                case ExchangeOrderType.gridShort:
                                    return (
                                        obj1.state === obj2.state &&
                                        obj1.exchangeShortGridOrder!.totalInvest === obj2.exchangeShortGridOrder!.totalInvest &&
                                        obj1.exchangeShortGridOrder!.trx_history === obj2.exchangeShortGridOrder!.trx_history &&
                                        obj1.closeTimestamp === obj2.closeTimestamp &&
                                        obj1.exchangeShortGridOrder!.triggerTime === obj2.exchangeShortGridOrder!.triggerTime &&
                                        obj1.innerStatus === obj2.innerStatus &&
                                        obj1.exchangeShortGridOrder!.baseAmount === obj2.exchangeShortGridOrder!.baseAmount &&
                                        obj1.exchangeShortGridOrder!.quoteAmount === obj2.exchangeShortGridOrder!.quoteAmount &&
                                        //下列为提取利润相关
                                        obj1.exchangeShortGridOrder!.profitWithdrawn === obj2.exchangeShortGridOrder!.profitWithdrawn &&
                                        obj1.exchangeShortGridOrder!.latestQuoteAmount === obj2.exchangeShortGridOrder!.latestQuoteAmount &&
                                        obj1.exchangeShortGridOrder!.baseFeeReserve === obj2.exchangeShortGridOrder!.baseFeeReserve &&
                                        obj1.exchangeShortGridOrder!.quoteFeeReserve === obj2.exchangeShortGridOrder!.quoteFeeReserve
                                    );

                                default:
                                    return false;
                            }
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }),
            )
            .pipe(
                concatMap((exchangeOrder) => {
                    if (exchangeOrder.exchange !== apiKey.exchange) {
                        return of(undefined);
                    }
                    if (exchangeOrder.orderType === ExchangeOrderType.SR) {
                        return of(exchangeOrder);
                    }
                    if (exchangeOrder.orderType === ExchangeOrderType.martingale) {
                        return of(exchangeOrder);
                    }
                    return ExchangeDataProvider.websocketManager
                        .getTickerWebSocket()
                        .getTickerObservableByPair(ExchangeID.PIONEXV2, exchangeOrder.base, exchangeOrder.quote)
                        .pipe(take(1))
                        .pipe(
                            map((exchangeTicker) => {
                                const latestPrice = exchangeTicker.latest;
                                switch (exchangeOrder.orderType) {
                                    case ExchangeOrderType.grid: {
                                        exchangeOrder.exchangeGridOrder!.latestPrice = latestPrice;
                                        break;
                                    }
                                    case ExchangeOrderType.trailingStop: {
                                        exchangeOrder.exchangeTrailingStopLimitOrder!.latestPrice = latestPrice;
                                        break;
                                    }
                                    case ExchangeOrderType.aip: {
                                        exchangeOrder.exchangeAIPOrder!.latestPrice = latestPrice;
                                        break;
                                    }
                                    case ExchangeOrderType.ig: {
                                        exchangeOrder.exchangeIGOrder!.latestPrice = latestPrice;
                                        break;
                                    }
                                    case ExchangeOrderType.gridPro: {
                                        exchangeOrder.exchangeGridProOrder!.latestPrice = latestPrice;
                                        break;
                                    }
                                    case ExchangeOrderType.gridClassic: {
                                        exchangeOrder.exchangeGridClassicOrder!.latestPrice = latestPrice;
                                        break;
                                    }
                                    case ExchangeOrderType.gridLoan: {
                                        exchangeOrder.exchangeLoanGridOrder!.latestPrice = latestPrice;
                                        break;
                                    }
                                    case ExchangeOrderType.gridShort: {
                                        exchangeOrder.exchangeShortGridOrder!.latestPrice = latestPrice;
                                        break;
                                    }
                                    case ExchangeOrderType.gridLeverage: {
                                        exchangeOrder.exchangeLeverageGridOrder!.latestPrice = latestPrice;
                                        break;
                                    }
                                    case ExchangeOrderType.gridFuture: {
                                        exchangeOrder.exchangeFutureGridOrder!.latestPrice = latestPrice;
                                        break;
                                    }
                                    case ExchangeOrderType.gridLeverageShort: {
                                        exchangeOrder.exchangeLeverageShortGridOrder!.latestPrice = latestPrice;
                                        break;
                                    }
                                    default: {
                                        break;
                                    }
                                }
                                return TradeUtils.calcGridOrderProfit(exchangeOrder, latestPrice);
                            }),
                        )
                        .pipe(
                            catchError((err) => {
                                console.log(err);
                                return of(exchangeOrder);
                            }),
                        );
                }),
            )
            .subscribe(
                (data) => {
                    if (data?.orderType === ExchangeOrderType.SL_Pro) {
                        return;
                    }
                    // if (data?.orderType === ExchangeOrderType.fsa) {
                    //     return;
                    // }
                    setOrder(data);
                },
                () => { },
            );
        return () => {
            tempObserve && tempObserve.unsubscribe();
        };
    }, [apiKey]);
    return order;
}

// 深度克隆
export function clone(obj) {
    let copy;
    switch (typeof obj) {
        case "undefined":
            break;
        case "number":
        case "string":
        case "boolean":
            copy = obj;
            break;
        case "function":
            copy = obj.bind(copy);
            break;
        case "object":
            if (obj === null) {
                copy = null;
            } else if (toString.call(obj) === "[object Array]") {
                copy = [];
                for (let i in obj) {
                    copy.push(clone(obj[i]));
                }
            } else if (toString.call(obj) === "[object RegExp]") {
                copy = obj;
            } else {
                copy = {};
                for (let j in obj) {
                    copy[j] = clone(obj[j]);
                }
            }
    }
    return copy;
}

export function updateOrderList(exchangeOrderList: ExchangeOrder[], exchangeTradePair: ExchangeTradePair): OrderClassifyType {
    const exchangeSymbolInfBase = exchangeTradePair.base;
    const exchangeSymbolInfQuote = exchangeTradePair.quote.toUpperCase();

    let tempRunningAll = [] as ExchangeOrder[];
    let tempRunningCurrent = [] as ExchangeOrder[];
    let tempFinishedAll = [] as ExchangeOrder[];
    let tempFinishedCurrent = [] as ExchangeOrder[];

    exchangeOrderList.forEach((item) => {
        if (item.state === ExchangeOrderStatus.canceling || item.state === ExchangeOrderStatus.submitted) {
            tempRunningAll.push(item);
            // botTradeOrders.running.all.push(item);

            if (item.base === exchangeSymbolInfBase && item.quote === exchangeSymbolInfQuote) {
                tempRunningCurrent.push(item);
                // botTradeOrders.running.current.push(item);
            }
        } else {
            tempFinishedAll.push(item);
            // botTradeOrders.finished.all.push(item);
            if (item.base === exchangeSymbolInfBase && item.quote === exchangeSymbolInfQuote) {
                tempFinishedCurrent.push(item);
                // botTradeOrders.finished.current.push(item);
            }
        }
    });

    tempRunningAll.sort((a, b) => b.timestamp - a.timestamp);
    tempRunningCurrent.sort((a, b) => b.timestamp - a.timestamp);

    tempFinishedAll.sort((a, b) => (b.lastTradeTime || b.timestamp) - (a.lastTradeTime || a.timestamp));
    tempFinishedCurrent.sort((a, b) => (b.lastTradeTime || b.timestamp) - (a.lastTradeTime || a.timestamp));

    return {
        running: {
            all: tempRunningAll,
            current: tempRunningCurrent,
        },
        finished: {
            all: tempFinishedAll,
            current: tempFinishedCurrent,
        },
    } as OrderClassifyType;
}

/**
 * 手动单按照时间排序会有bug
 * @param exchangeOrderList
 * @param exchangeTradePair
 */
export function updateOrderListWithOutSort(exchangeOrderList: ExchangeOrder[], exchangeTradePair: ExchangeTradePair): OrderClassifyType {
    const exchangeSymbolInfBase = exchangeTradePair.base;
    const exchangeSymbolInfQuote = exchangeTradePair.quote.toUpperCase();

    let tempRunningAll = [] as ExchangeOrder[];
    let tempRunningCurrent = [] as ExchangeOrder[];
    let tempFinishedAll = [] as ExchangeOrder[];
    let tempFinishedCurrent = [] as ExchangeOrder[];

    exchangeOrderList.forEach((item) => {
        if (item.state === ExchangeOrderStatus.canceling || item.state === ExchangeOrderStatus.submitted) {
            tempRunningAll.push(item);
            // botTradeOrders.running.all.push(item);

            if (item.base === exchangeSymbolInfBase && item.quote === exchangeSymbolInfQuote) {
                tempRunningCurrent.push(item);
                // botTradeOrders.running.current.push(item);
            }
        } else {
            tempFinishedAll.push(item);
            // botTradeOrders.finished.all.push(item);
            if (item.base === exchangeSymbolInfBase && item.quote === exchangeSymbolInfQuote) {
                tempFinishedCurrent.push(item);
                // botTradeOrders.finished.current.push(item);
            }
        }
    });
    return {
        running: {
            all: tempRunningAll,
            current: tempRunningCurrent,
        },
        finished: {
            all: tempFinishedAll,
            current: tempFinishedCurrent,
        },
    } as OrderClassifyType;
}

/**
 * ExchangeOrder[] 数组转换为TradingBotOrderTableData[]
 * @param data
 * @param botsAsset
 * @param exchangeRate
 */
export function botOrderTransform(data: Array<ExchangeOrder>, botsAsset: TradeAssetsInfo, exchangeRate: ExchangeRate): Array<TradingBotOrderTableData> {
    return data
        .map((item) => {
            const innerOrder = TradeUtils.getInnerOrder(item);
            const base = item.baseDisplay;
            const quote = item.quote;
            // const itemAsset = botsAsset.botAssets.find((obj) => obj.buOrderId === item.id);
            // let tempTodayProfit = 0;
            // let tempTodayProfitText = `+0 ${exchangeRate.currency} (+0%)`;
            // 未触发的订单不读取利润数据
            // if (itemAsset && !innerOrder.isConditionAndNotTriggered) {
            //     tempTodayProfit = itemAsset.profitToday;
            //     tempTodayProfitText = `${NumberCommonUtils.toNormalPrice(itemAsset.profitToday * exchangeRate.exchangeRate)} ${
            //         exchangeRate.currency
            //     } (${NumberCommonUtils.toPercent((itemAsset.profitToday / itemAsset.investment) * 100, true, true)})`;
            //     if (itemAsset.profitToday >= 0) {
            //         tempTodayProfitText = "+" + tempTodayProfitText;
            //     }
            // }
            let msg = item.msg;
            if (!msg && item.orderType === ExchangeOrderType.aip && item.state === ExchangeOrderStatus.canceled) {
                msg = ExchangeOrderStatus.canceled;
            }
            const tempStatusTextResId = ParseErrorUtils.queryOrderErrorMessage(item.orderType, item.state, msg);

            switch (item.orderType) {
                case ExchangeOrderType.grid:
                case ExchangeOrderType.ig: {
                    let tempInvestment = `0 ${item.quote}`;
                    tempInvestment = NumberCommonUtils.toNormalPrice(innerOrder.totalInvest.toString()).toString() + " " + quote;
                    let tempProfit = `+0 ${quote} (0%)`;

                    if (getUserProfitModel() === 1) {
                        tempProfit = `${NumberCommonUtils.toNormalPrice(innerOrder.totalProfit)} ${quote} (${NumberCommonUtils.toPercent(
                            innerOrder.totalProfitPercent,
                            true,
                            true,
                        )})`;
                        if (innerOrder.totalProfit >= 0) {
                            tempProfit = "+" + tempProfit;
                        }
                    } else {
                        tempProfit = `${NumberCommonUtils.toNormalPrice(innerOrder.puredTotalProfit)} ${quote} (${NumberCommonUtils.toPercent(
                            innerOrder.puredTotalProfitPercent,
                            true,
                            true,
                        )})`;
                        if (innerOrder.puredTotalProfit >= 0) {
                            tempProfit = "+" + tempProfit;
                        }
                    }

                    return {
                        bot: OrderUtils.getOrderDesc(item.orderType),
                        symbol: `${base}/${quote}`,
                        investment: tempInvestment,
                        profit: innerOrder.totalProfit,
                        profitText: tempProfit,
                        id: item.id,
                        timestamp: item.timestamp,
                        originalData: item,
                        // todayProfit: tempTodayProfit,
                        // todayProfitText: tempTodayProfitText,
                        orderStatus: $st(tempStatusTextResId),
                    };
                }

                case ExchangeOrderType.trailingStop: {
                    let tempInvestment = `0 ${quote}`;
                    let tempProfit = `+0 ${quote} (0%)`;
                    if (innerOrder.investment) {
                        tempInvestment = NumberCommonUtils.toNormalPrice(innerOrder.investment.toString()).toString() + " " + quote;
                    }

                    if (innerOrder.profit && innerOrder.investment) {
                        tempProfit = `${NumberCommonUtils.toNormalPrice(innerOrder.profit)} ${quote} (${NumberCommonUtils.toPercent(
                            (innerOrder.profit / innerOrder.investment) * 100,
                            true,
                            true,
                        )})`;
                        if (innerOrder.profit > 0) {
                            tempProfit = "+" + tempProfit;
                        }
                    }

                    return {
                        bot: OrderUtils.getOrderDesc(item.orderType),
                        symbol: `${base}/${quote}`,
                        investment: tempInvestment,
                        profit: innerOrder.profit || 0,
                        profitText: tempProfit,
                        id: item.id,
                        timestamp: item.timestamp,
                        originalData: item,
                        // todayProfit: tempTodayProfit,
                        // todayProfitText: tempTodayProfitText,
                        orderStatus: $st(tempStatusTextResId),
                    };
                }
                case ExchangeOrderType.SL_Pro: {
                    let tempInvestment = `0 ${quote}`;
                    let tempProfit = `+0 ${quote} (0%)`;
                    if (innerOrder.amount) {
                        tempInvestment = `${NumberCommonUtils.toNormalPrice((innerOrder as exchangeSLProOrder).amount)} ${item.baseDisplay}`;
                    }
                    if (innerOrder.profit && innerOrder.amount) {
                        tempProfit = `${NumberCommonUtils.toNormalPrice(innerOrder.profit.toString())} ${quote} (${NumberCommonUtils.toPercent(
                            (innerOrder.profit / innerOrder.amount) * 100,
                            true,
                            true,
                        )})`;
                    }
                    if (innerOrder.profit >= 0) {
                        tempProfit = "+" + tempProfit;
                    }

                    return {
                        bot: $st("order_stop_limit"),
                        symbol: `${base}/${quote}`,
                        investment: tempInvestment,
                        profit: "--",
                        profitText: "--",
                        id: item.id,
                        timestamp: item.timestamp,
                        originalData: item,
                        // todayProfit: "--",
                        // todayProfitText: "--",
                        orderStatus: $st(tempStatusTextResId),
                    };
                }
                case ExchangeOrderType.stopLimit: {
                    let tempInvestment = `0 ${quote}`;
                    let tempProfit = `+0 ${quote} (0%)`;
                    if (innerOrder.investment) {
                        tempInvestment = `${NumberCommonUtils.toNormalPrice(innerOrder.investment)} ${quote}`;
                    }
                    if (innerOrder.profit && innerOrder.investment) {
                        tempProfit = `${NumberCommonUtils.toNormalPrice(innerOrder.profit.toString())} ${quote} (${NumberCommonUtils.toPercent(
                            (innerOrder.profit / innerOrder.investment) * 100,
                            true,
                            true,
                        )})`;
                    }
                    if (innerOrder.profit >= 0) {
                        tempProfit = "+" + tempProfit;
                    }

                    return {
                        bot: OrderUtils.getOrderDesc(item.orderType),
                        symbol: `${base}/${quote}`,
                        investment: tempInvestment,
                        profit: innerOrder.profit || 0,
                        profitText: tempProfit,
                        id: item.id,
                        timestamp: item.timestamp,
                        originalData: item,
                        // todayProfit: tempTodayProfit,
                        // todayProfitText: tempTodayProfitText,
                        orderStatus: $st(tempStatusTextResId),
                    };
                }
                case ExchangeOrderType.aip: {
                    let profitText = `+0 ${quote} (0%)`;
                    if (innerOrder.investment !== 0) {
                        profitText = `${NumberUtils.effectiveDecimal(innerOrder.profit || 0, 4)} ${quote} (${NumberCommonUtils.toPercent(
                            ((innerOrder.profit || 0) / innerOrder.investment) * 100,
                            true,
                            true,
                        )})`;
                    } else {
                        profitText = `${NumberUtils.effectiveDecimal(innerOrder.profit || 0, 4)} ${quote} (0%)`;
                    }
                    return {
                        bot: OrderUtils.getOrderDesc(item.orderType),
                        symbol: `${base}/${quote}`,
                        investment: `${NumberCommonUtils.toNormalPrice(innerOrder.investment)} ${quote}`,
                        profit: item.exchangeAIPOrder!.profit || 0,
                        profitText: profitText,
                        id: item.id,
                        timestamp: item.timestamp,
                        originalData: item,
                        // todayProfit: tempTodayProfit,
                        // todayProfitText: tempTodayProfitText,
                        orderStatus: $st(tempStatusTextResId),
                    };
                }
                case ExchangeOrderType.twap: {
                    let profitText = `+0 ${quote} (0%)`;
                    if (innerOrder.investment !== 0) {
                        if (innerOrder.side === ExchangeOrderSide.buy) {
                            profitText = `${NumberUtils.effectiveDecimal(innerOrder.profit || 0, 4)} ${quote} (${NumberCommonUtils.toPercent(
                                ((innerOrder.profit || 0) / innerOrder.investment) * 100,
                                true,
                                true,
                            )})`;
                        } else {
                            profitText = "--";
                        }
                    } else {
                        profitText = `${NumberUtils.effectiveDecimal(innerOrder.profit || 0, 4)} ${quote}`;
                    }
                    return {
                        bot: OrderUtils.getOrderDesc(item.orderType),
                        symbol: `${base}/${quote}`,
                        investment: `${NumberCommonUtils.toNormalPrice(innerOrder.investment)} ${innerOrder.side === "buy" ? quote : base}`,
                        profit: innerOrder.profit || 0,
                        profitText: profitText,
                        id: item.id,
                        timestamp: item.timestamp,
                        originalData: item,
                        // todayProfit: tempTodayProfit,
                        // todayProfitText: tempTodayProfitText,
                        orderStatus: $st(tempStatusTextResId),
                    };
                }
                case ExchangeOrderType.SR: {
                    const tempInvestment = NumberCommonUtils.toNormalPrice(innerOrder.totalInvest.toString()).toString() + " " + quote;
                    let tempProfit = `+0 ${quote} (0.00%)`;
                    if (innerOrder.isConditionAndNotTriggered) {
                    } else {
                        tempProfit = `${NumberCommonUtils.toNormalPrice(innerOrder.totalProfit)} ${quote} (${NumberCommonUtils.toPercent(
                            innerOrder.totalProfitPercent,
                            true,
                            true,
                        )})`;
                        if (innerOrder.totalProfit > 0) {
                            tempProfit = "+" + tempProfit;
                        }
                    }
                    let coins = item.exchangeSROrder?.coins || [];
                    const symbol =
                        coins.length > 3
                            ? coins
                                .slice(3)
                                .map(({ baseDisplay }) => baseDisplay)
                                .join("/") + "..."
                            : coins.map(({ baseDisplay }) => baseDisplay).join("/");
                    return {
                        bot: OrderUtils.getOrderDesc(item.orderType, item.exchangeSROrder?.subType as SmartRebalanceSubType),
                        symbol,
                        investment: tempInvestment,
                        profit: innerOrder.totalProfit,
                        profitText: tempProfit,
                        id: item.id,
                        timestamp: item.timestamp,
                        originalData: item,
                        // todayProfit: tempTodayProfit,
                        // todayProfitText: tempTodayProfitText,
                        orderStatus: $st(tempStatusTextResId),
                    };
                }
                case ExchangeOrderType.gridShort:
                case ExchangeOrderType.gridPro:
                case ExchangeOrderType.gridLoan:
                case ExchangeOrderType.gridLeverageShort:
                case ExchangeOrderType.gridLeverage:
                case ExchangeOrderType.gridFuture:
                case ExchangeOrderType.gridClassic:
                case ExchangeOrderType.gridV3: {
                    const earnCoin = transformBaseExtraDisplay(item.base, item.baseDisplay, innerOrder.earnCoin);
                    const tempInvestment = NumberCommonUtils.toNormalPrice(innerOrder.totalCost.toString()).toString() + " " + earnCoin;
                    let tempProfit = `+0 ${earnCoin} (0.00%)`;
                    if (innerOrder.isConditionAndNotTriggered) {
                    } else {
                        tempProfit = `${NumberCommonUtils.toNormalPrice(innerOrder.totalProfit)} ${earnCoin} (${NumberCommonUtils.toPercent(
                            innerOrder.totalProfitPercent,
                            true,
                            true,
                        )})`;
                        if (innerOrder.totalProfit > 0) {
                            tempProfit = "+" + tempProfit;
                        }
                    }
                    return {
                        bot: OrderUtils.getOrderDesc(item.orderType),
                        symbol: `${base}/${quote}`,
                        investment: tempInvestment,
                        profit: innerOrder.totalProfit,
                        profitText: tempProfit,
                        id: item.id,
                        timestamp: item.timestamp,
                        originalData: item,
                        // todayProfit: tempTodayProfit,
                        // todayProfitText: tempTodayProfitText,
                        orderStatus: $st(tempStatusTextResId),
                    };
                }
                case ExchangeOrderType.gridV4: {
                    const earnCoin = transformBaseExtraDisplay(item.base, item.baseDisplay, innerOrder.earnCoin);
                    const tempInvestment = NumberCommonUtils.toNormalPrice(innerOrder.totalCost.toString()).toString() + " " + earnCoin;
                    let tempProfit = `+0 ${earnCoin} (0.00%)`;
                    if (innerOrder.isConditionAndNotTriggered) {
                    } else {
                        tempProfit = `${NumberCommonUtils.toNormalPrice(innerOrder.totalProfit)} ${earnCoin} (${NumberCommonUtils.toPercent(
                            innerOrder.totalProfitPercent,
                            true,
                            true,
                        )})`;
                        if (innerOrder.totalProfit > 0) {
                            tempProfit = "+" + tempProfit;
                        }
                    }

                    return {
                        bot: isCopyOrder(item) ? (item.botName ? item.botName : OrderUtils.getOrderDesc(item.orderType)) : OrderUtils.getOrderDesc(item.orderType),
                        symbol: `${base}/${quote}`,
                        investment: tempInvestment,
                        profit: innerOrder.totalProfit,
                        profitText: tempProfit,
                        id: item.id,
                        timestamp: item.timestamp,
                        originalData: item,
                        // todayProfit: tempTodayProfit,
                        // todayProfitText: tempTodayProfitText,
                        orderStatus: $st(tempStatusTextResId),
                    };
                }
                case ExchangeOrderType.fsa: {
                    return {
                        bot: OrderUtils.getOrderDesc(item.orderType),
                        symbol: `${base}/${quote}`,
                        investment: `${NumberCommonUtils.toNormalPrice(innerOrder.investment)} ${quote}`,
                        profit: innerOrder.totalProfit,
                        profitText: `${NumberCommonUtils.toNormalPrice(innerOrder.totalProfit)} ${quote} (${NumberCommonUtils.toPercent(
                            innerOrder.totalProfitPercent,
                            true,
                            true,
                        )})`,
                        id: item.id,
                        timestamp: item.timestamp,
                        originalData: item,
                        // todayProfit: tempTodayProfit,
                        // todayProfitText: "--",
                        orderStatus: $st(tempStatusTextResId),
                    };
                }
                case ExchangeOrderType.smartTrade: {
                    let tempProfit = `+0 ${item.quote} (0.00%)`;
                    tempProfit = `${NumberCommonUtils.toNormalPrice(innerOrder.profit)} ${item.quote} (${NumberCommonUtils.toPercent(innerOrder.profitPercent, true, true)})`;
                    if (innerOrder.profit >= 0) {
                        tempProfit = "+" + tempProfit;
                    }

                    if (item.exchangeSmartTradeOrder?.smartType === SmartTradeType.smart_trade_sell_only) {
                        const isTrigger = item.exchangeSmartTradeOrder?.recordHighPrice && item.exchangeSmartTradeOrder?.recordHighPrice >= item.exchangeSmartTradeOrder.trigger;
                        if (item.state === ExchangeOrderStatus.canceled && item.msg === "canceled") {
                            tempProfit = "--";
                        } else {
                            tempProfit = isTrigger ? tempProfit : "+0(+0%)";
                        }
                        return {
                            bot: OrderUtils.getOrderDesc(ExchangeOrderType.trailingSell),
                            symbol: `${base}/${quote}`,
                            investment: `${NumberCommonUtils.toNormalPrice(innerOrder.totalInvest)} ${quote}`,
                            profit: isTrigger ? innerOrder.profit || 0 : 0,
                            profitText: tempProfit,
                            id: item.id,
                            timestamp: item.timestamp,
                            originalData: item,
                            // todayProfit: tempTodayProfit,
                            // todayProfitText: "--",
                            orderStatus: $st(tempStatusTextResId),
                        };
                    } else if (item.exchangeSmartTradeOrder?.smartType === SmartTradeType.smart_trade_buy_only) {
                        const isTrigger = item.exchangeSmartTradeOrder?.recordLowPrice && item.exchangeSmartTradeOrder?.recordLowPrice <= item.exchangeSmartTradeOrder.buy;

                        let tempProfitBuy = `+0 ${item.quote} (0.00%)`;
                        tempProfitBuy = `${NumberCommonUtils.toNormalPrice(innerOrder.profit)} ${item.baseDisplay} (${NumberCommonUtils.toPercent(
                            innerOrder.profitPercent,
                            true,
                            true,
                        )})`;
                        if (innerOrder.profit >= 0) {
                            tempProfitBuy = "+" + tempProfitBuy;
                        }
                        if (item.state === ExchangeOrderStatus.canceled && item.msg === "canceled") {
                            tempProfit = "--";
                        } else {
                            tempProfit = isTrigger ? tempProfitBuy : "+0(+0%)";
                        }
                        return {
                            bot: OrderUtils.getOrderDesc(ExchangeOrderType.trailingBuy),
                            symbol: `${base}/${quote}`,
                            investment: `${NumberCommonUtils.toNormalPrice(innerOrder.amount)} ${quote}`,
                            profit: isTrigger ? innerOrder.profit || 0 : 0,
                            profitText: tempProfit,
                            id: item.id,
                            timestamp: item.timestamp,
                            originalData: item,
                            // todayProfit: tempTodayProfit,
                            // todayProfitText: "--",
                            orderStatus: $st(tempStatusTextResId),
                        };
                    } else {
                        if (item.state === ExchangeOrderStatus.canceled && item.msg === "canceled") {
                            tempProfit = "--";
                        }
                        return {
                            bot: OrderUtils.getOrderDesc(item.orderType),
                            symbol: `${base}/${quote}`,
                            investment: `${NumberCommonUtils.toNormalPrice(innerOrder.totalInvest)} ${quote}`,
                            profit: innerOrder.profit || 0,
                            profitText: tempProfit,
                            id: item.id,
                            timestamp: item.timestamp,
                            originalData: item,
                            // todayProfit: tempTodayProfit,
                            // todayProfitText: "--",
                            orderStatus: $st(tempStatusTextResId),
                        };
                    }
                }
                case ExchangeOrderType.martingale:
                    const martinOrder = item.exchangeMTGOrder!;

                    let tempProfit = `${NumberCommonUtils.toNormalPrice(martinOrder.totalProfit)} ${quote} (${NumberCommonUtils.toPercent(
                        martinOrder.totalProfitPercent,
                        true,
                        true,
                    )})`;
                    let profit = martinOrder.totalProfit;
                    if (martinOrder.totalProfit >= 0) {
                        tempProfit = "+" + tempProfit;
                    }

                    if (martinOrder.isCondition && !martinOrder.conditionTriggered) {
                        // 未触发订单不使用盈利数据
                        tempProfit = `+0 ${item.quote} (0.00%)`;
                        profit = 0;
                    }
                    return {
                        bot: OrderUtils.getOrderDesc(item.orderType),
                        symbol: getMTGShowName(item),
                        investment: `${NumberCommonUtils.toNormalPrice(martinOrder.totalInvest)} ${quote}`,
                        profit: profit,
                        profitText: tempProfit,
                        id: item.id,
                        timestamp: item.timestamp,
                        originalData: item,
                        // todayProfit: tempTodayProfit,
                        // todayProfitText: tempTodayProfitText,
                        orderStatus: $st(tempStatusTextResId),
                    };
                default: {
                    return undefined;
                }
            }
        })
        .filter(Boolean) as TradingBotOrderTableData[];
}

export function shouldShowShareButton(orderItem: ExchangeOrder): boolean {
    if (orderItem.orderType === ExchangeOrderType.smartTrade) {
        if (orderItem.exchangeSmartTradeOrder?.smartType === SmartTradeType.smart_trade) {
            return orderItem.state !== ExchangeOrderStatus.canceled;
        } else {
            return orderItem.state === ExchangeOrderStatus.filled && !!orderItem.exchangeSmartTradeOrder && orderItem.exchangeSmartTradeOrder.profit > 0;
        }
    }

    if (orderItem.orderType === OrderTypeDesc.MARTINGALE) {
        return isSharableMTGOrder(orderItem.exchangeMTGOrder!);
    }

    return (
        orderItem.orderType === OrderTypeDesc.GRID ||
        orderItem.orderType === OrderTypeDesc.SR ||
        // orderItem.orderType === OrderTypeDesc.GRID_V3 ||
        orderItem.orderType === OrderTypeDesc.IG ||
        (orderItem.orderType === OrderTypeDesc.TRAILING_STOP_LIMIT &&
            orderItem.state === "closed" &&
            orderItem.exchangeTrailingStopLimitOrder!.profit &&
            orderItem.exchangeTrailingStopLimitOrder!.profit > 0) ||
        orderItem.orderType === OrderTypeDesc.AIP ||
        (orderItem.orderType === OrderTypeDesc.GRID_CLASSIC && !orderItem.exchangeGridClassicOrder?.isConditionAndNotTriggered) ||
        (orderItem.orderType === OrderTypeDesc.GRID_PRO && !orderItem.exchangeGridProOrder?.isConditionAndNotTriggered) ||
        (orderItem.orderType === OrderTypeDesc.GRID_V3 && !orderItem.exchangeGridV3Order?.isConditionAndNotTriggered) ||
        (orderItem.orderType === OrderTypeDesc.GRID_LOAN && !orderItem.exchangeLoanGridOrder?.isConditionAndNotTriggered) ||
        (orderItem.orderType === OrderTypeDesc.GRID_SHORT && !orderItem.exchangeShortGridOrder?.isConditionAndNotTriggered) ||
        (orderItem.orderType === OrderTypeDesc.GRID_LEVERAGE && !orderItem.exchangeLeverageGridOrder?.isConditionAndNotTriggered) ||
        (orderItem.orderType === OrderTypeDesc.GRID_LEVERAGE_SHORT && !orderItem.exchangeLeverageShortGridOrder?.isConditionAndNotTriggered) ||
        (orderItem.orderType === OrderTypeDesc.GRID_FUTURE && !orderItem.exchangeFutureGridOrder?.isConditionAndNotTriggered)
    );
}

export function shouldShowOneMoreButton(orderItem: ExchangeOrder) {
    return (
        orderItem.orderType === OrderTypeDesc.GRID ||
        orderItem.orderType === OrderTypeDesc.IG ||
        orderItem.orderType === OrderTypeDesc.TWAP ||
        orderItem.orderType === OrderTypeDesc.AIP
    );
}

export function shouldShowDetailButton(orderItem: ExchangeOrder) {
    return !(orderItem.orderType === OrderTypeDesc.STOP_LIMIT);
}

export function shouldShowLoading(orderItem: ExchangeOrder) {
    return (
        (orderItem.orderType === OrderTypeDesc.GRID &&
            orderItem.exchangeGridOrder &&
            orderItem.exchangeGridOrder.totalInvest === 0 &&
            orderItem.state === ExchangeOrderStatus.submitted) ||
        (orderItem.orderType === OrderTypeDesc.AIP &&
            orderItem.exchangeAIPOrder &&
            orderItem.exchangeAIPOrder.traded_count === 0 &&
            orderItem.state === ExchangeOrderStatus.submitted) ||
        (orderItem.orderType === OrderTypeDesc.GRID_CLASSIC &&
            orderItem.innerStatus !== OrderInnerStatus.running &&
            orderItem.state === ExchangeOrderStatus.submitted &&
            !orderItem.exchangeGridClassicOrder!.isConditionAndNotTriggered) ||
        (orderItem.orderType === OrderTypeDesc.GRID_PRO &&
            orderItem.innerStatus !== OrderInnerStatus.running &&
            orderItem.state === ExchangeOrderStatus.submitted &&
            !orderItem.exchangeGridProOrder!.isConditionAndNotTriggered) ||
        (orderItem.orderType === OrderTypeDesc.GRID_V3 &&
            orderItem.innerStatus !== OrderInnerStatus.running &&
            orderItem.state === ExchangeOrderStatus.submitted &&
            !orderItem.exchangeGridV3Order!.isConditionAndNotTriggered &&
            orderItem.innerStatus !== TEMP_ORDER_STATUS_PAUSED) ||
        (orderItem.orderType === OrderTypeDesc.GRID_LOAN &&
            orderItem.innerStatus !== OrderInnerStatus.running &&
            orderItem.state === ExchangeOrderStatus.submitted &&
            !orderItem.exchangeLoanGridOrder!.isConditionAndNotTriggered) ||
        (orderItem.orderType === OrderTypeDesc.GRID_SHORT &&
            orderItem.innerStatus !== OrderInnerStatus.running &&
            orderItem.state === ExchangeOrderStatus.submitted &&
            !orderItem.exchangeShortGridOrder!.isConditionAndNotTriggered) ||
        (orderItem.orderType === OrderTypeDesc.GRID_LEVERAGE &&
            orderItem.innerStatus !== OrderInnerStatus.running &&
            orderItem.state === ExchangeOrderStatus.submitted &&
            !orderItem.exchangeLeverageGridOrder!.isConditionAndNotTriggered) ||
        (orderItem.orderType === OrderTypeDesc.GRID_LEVERAGE_SHORT &&
            orderItem.innerStatus !== OrderInnerStatus.running &&
            orderItem.state === ExchangeOrderStatus.submitted &&
            !orderItem.exchangeLeverageShortGridOrder!.isConditionAndNotTriggered) ||
        (orderItem.orderType === OrderTypeDesc.GRID_FUTURE &&
            orderItem.innerStatus !== OrderInnerStatus.running &&
            orderItem.state === ExchangeOrderStatus.submitted &&
            !orderItem.exchangeFutureGridOrder!.isConditionAndNotTriggered) ||
        (orderItem.orderType === OrderTypeDesc.FSA && orderItem.innerStatus !== OrderInnerStatus.running && orderItem.state === ExchangeOrderStatus.submitted) ||
        (orderItem.orderType === OrderTypeDesc.SR &&
            orderItem.innerStatus !== OrderInnerStatus.running &&
            orderItem.innerStatus !== OrderInnerStatus.conditionLock &&
            orderItem.state === ExchangeOrderStatus.submitted)
    );
}

export function setOrderIcon(orderType: OrderTypeDisplay, theme?: ThemeData) {
    switch (orderType) {
        case ExchangeOrderType.martingale: {
            return require("images/botIco/martingale_bot.png");
        }
        case ExchangeOrderType.ig: {
            return require("images/botIco/ig_bot.png");
        }
        case ExchangeOrderType.aip: {
            return require("images/botIco/trading_aip_bot.png");
        }
        case ExchangeOrderType.trailingStop: {
            //追踪止盈
            return require("images/botIco/grid_ttp.png");
        }
        case ExchangeOrderType.twap: {
            return require("images/botIco/twap_bot.png");
        }
        case ExchangeOrderType.grid:
        case ExchangeOrderType.gridV3:
        case ExchangeOrderType.gridV4:
        case ExchangeOrderType.gridClassic:
        case ExchangeOrderType.gridPro: {
            return require("images/botIco/bot_house_card_grid.png");
        }
        case ExchangeOrderType.gridLoan: {
            return require("images/botIco/grid_loan_bot.png");
        }
        case ExchangeOrderType.gridShort: {
            return require("images/botIco/grid_short_bot.png");
        }
        case ExchangeOrderType.gridLeverage: {
            return require("images/botIco/grid_leverage_bot.png");
        }
        case ExchangeOrderType.gridLeverageShort: {
            return require("images/botIco/grid_leverage_short_bot.png");
        }
        case ExchangeOrderType.gridFuture: {
            // 合约网格
            return require("images/botIco/grid_future.png");
        }
        case ExchangeOrderType.smartTrade: {
            return require("images/botIco/smart_rade_bot.png");
        }
        case ExchangeOrderType.trailingBuy: {
            return require("images/botIco/trailing_buy_bot.png");
        }
        case ExchangeOrderType.trailingSell: {
            return require("images/botIco/trailing_sell_bot.png");
        }
        case ExchangeOrderType.fsa: {
            return require("images/botIco/fsa_bot.png");
        }
        case "smart_rebalance_double_coins": {
            //cff
            return require("images/botIco/bot_rebalance.png");
        }
        case "smart_rebalance_multi_coins": {
            //vv
            return require("images/botIco/signal_mutil_coin.png");
        }
        case "stopLimit": {
            return require("images/botIco/order_stop_limit_bot.png");
        }
        case MoonBotKey.btcUsdt: {
            return require("images/botIco/btc_usdt_bot.png");
        }
        case MoonBotKey.ethUsdt: {
            return require("images/botIco/eth_usdt_bot.png");
        }
        case MoonBotKey.ethBtc: {
            return require("images/botIco/bit-miner.png");
        }
        case ExchangeOrderType.SL_Pro:
        default: {
            return require("images/home/pionex/icon_linerk.svg");
        }
    }
}

export function setManualOrderIcon(side: ExchangeOrderSide, theme: ThemeData) {
    if (side === ExchangeOrderSide.buy) {
        if (theme.reversed) {
            return require("images/landingPage/manual_buy_red.png");
        } else {
            return require("images/landingPage/manual_buy_green.png");
        }
    } else {
        if (theme.reversed) {
            return require("images/landingPage/manual_sell_green.png");
        } else {
            return require("images/landingPage/manual_sell_red.png");
        }
    }
}

export function checkOrderCanRetry(order: ExchangeOrder, pageType: OrderTabType): boolean {
    if (pageType === OrderTabType.running) {
        if (order.state !== "submitted") {
            return (
                (order.orderType === OrderTypeDesc.GRID && order.exchangeGridOrder!.canRetry) ||
                (order.orderType === OrderTypeDesc.IG && order.exchangeIGOrder!.canRetry) ||
                (order.orderType === OrderTypeDesc.AIP && order.exchangeAIPOrder!.canRetry) ||
                (order.orderType === OrderTypeDesc.TWAP && order.exchangeTWAPOrder!.canRetry) ||
                (order.orderType === OrderTypeDesc.GRID_CLASSIC && order.exchangeGridClassicOrder!.canRetry) ||
                (order.orderType === OrderTypeDesc.GRID_PRO && order.exchangeGridProOrder!.canRetry) ||
                (order.orderType === OrderTypeDesc.GRID_LOAN && order.exchangeLoanGridOrder!.canRetry) ||
                (order.orderType === OrderTypeDesc.GRID_SHORT && order.exchangeShortGridOrder!.canRetry) ||
                (order.orderType === OrderTypeDesc.GRID_LEVERAGE && order.exchangeLeverageGridOrder!.canRetry) ||
                (order.orderType === OrderTypeDesc.GRID_LEVERAGE_SHORT && order.exchangeLeverageShortGridOrder!.canRetry) ||
                (order.orderType === OrderTypeDesc.GRID_FUTURE && order.exchangeFutureGridOrder!.canRetry)
            );
        }
    }
    return false;
}

export function getOrderErrorHelpLinkUrl(locale: string) {
    if (locale === LANGUAGE_LOCALS_MAP.zhTW) {
        return "https://t.me/Pionex_TW";
    } else if (locale === LANGUAGE_LOCALS_MAP.zhCN) {
        return "https://t.me/pionexcn";
    } else if (locale === LANGUAGE_LOCALS_MAP.pt) {
        return "https://t.me/Pionexbrpt";
    } else if (locale === LANGUAGE_LOCALS_MAP.es) {
        return "https://t.me/Pionexes";
    } else {
        return "https://t.me/pionexus";
    }
}

export function OrderCurrentTickerObserve(exchange: string, pairInfoList: Array<SymbolInfo>): Observable<Array<ExchangeTicker>> {
    return ExchangeDataProvider.websocketManager.getTickerWebSocket().getTickerObservableBatch(pairInfoList);
}

function toNormalPrice(value) {
    let _value = NumberCommonUtils.toNormalPrice(value);
    if (/.*(e\-\d+)$/.test(String(_value))) {
        return "0";
    }
    return _value;
}
