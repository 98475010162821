import React, { createContext, useContext, useMemo } from "react";
import DebitAPI from "landings/V2/CircleDebit/DebitAPI";

export const DebitContext = createContext<{ circleDebitAPI?: DebitAPI }>({});

/**
 * DebitData全局数据
 * @param children
 * @constructor
 */
export const DebitDataProvider: React.FC = ({ children }) => {
    const circleDebitAPI = useMemo(() => {
        return new DebitAPI();
    }, []);

    return <DebitContext.Provider value={{ circleDebitAPI }}>{children}</DebitContext.Provider>;
};

/**
 * 包裹了Provider的高阶组件
 * @param Component
 */
export function withDebitData<P extends object>(Component: React.ComponentType<P>) {
    const Wrapper: React.FC<P> = (props) => {
        return (
            <DebitDataProvider>
                <Component {...props} />
            </DebitDataProvider>
        );
    };

    return Wrapper;
}

/**
 * 从context中提取api对象
 */
export function useCircleDebitAPI() {
    const { circleDebitAPI } = useContext(DebitContext);

    return circleDebitAPI;
}
