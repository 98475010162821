import { ExchangeSymbol } from "TradeAPILib";
import { TradeSelectedInfo } from "TradeLib/TradeTypes";
import React, { useCallback, useContext, useState } from "react";
import LocalStorageUtils from "utils/LocalStorageUtils";
import { AIParamType, PresetOrderData } from "../BotArea/BotList";

export enum LayoutMode {
    DEFAULT = "default",
    FULLSCREEN = "fullscreen",
}

export enum LayoutTypes {
    AUTO = "AUTO",
    NANO = "NANO",
    MINI = "MINI",
    FULL = "FULL",
}
export interface TradeCtxData {
    tradeSelectedInfo: TradeSelectedInfo;
    changeExchangeSymbol(exchangeSymbol: string | ExchangeSymbol): void;
    updateSymbolVisible?: (flag: boolean) => void;
    presetOrderData?: PresetOrderData;
    setPresetOrderData?: React.Dispatch<React.SetStateAction<PresetOrderData | undefined>>;
    aiParam?: AIParamType;
    setAIParam?: React.Dispatch<React.SetStateAction<AIParamType | undefined>>;
}

export const TradeContext = React.createContext<TradeCtxData>({ tradeSelectedInfo: {} as any, changeExchangeSymbol() {} });

export function useTradeDataProvider(): TradeCtxData {
    return useContext<TradeCtxData>(TradeContext);
}

// 动态控制 layout
export type TradeCtxLayout = {
    layoutInfo: {
        layout: LayoutTypes;
        Manual: {
            orderBook: boolean;
            marketTrades: boolean;
            fullScreen: boolean;
        };
        Bot: {
            fullScreen: boolean;
        };
        Reverse: boolean; // 是否开启镜像模式
    };
    changeLayout: (value: { [index in keyof TradeCtxLayout["layoutInfo"]]?: TradeCtxLayout["layoutInfo"][index] }) => void;
};

export const TradeCtxLayoutContext = React.createContext<TradeCtxLayout>({ layoutInfo: {} as any, changeLayout() {} });

export function useTradeUiProvider(): TradeCtxLayout {
    return useContext<TradeCtxLayout>(TradeCtxLayoutContext);
}

function getLayoutInfo(defaultValue: TradeCtxLayout["layoutInfo"]) {
    return LocalStorageUtils.getLayoutInfo() || defaultValue;
}

export function useTradeUiInfo() {
    const [state, setState] = useState<TradeCtxLayout["layoutInfo"]>(
        getLayoutInfo({
            layout: LayoutTypes.AUTO,
            Manual: {
                orderBook: true,
                marketTrades: true,
                fullScreen: false,
            },
            Bot: {
                fullScreen: false,
            },
            Reverse: false,
        }),
    );

    const changeLayout = useCallback(
        (value: { [index in keyof TradeCtxLayout["layoutInfo"]]?: TradeCtxLayout["layoutInfo"][index] }) => {
            const info = { ...state, ...value };
            LocalStorageUtils.setLayoutInfo(info);
            setState(info);
        },
        [state],
    );
    return [state, changeLayout] as const;
}
