import { Progress, message } from "@pionex-web-kit/components";
import { useTranslators } from "commonUse/Locale";
import { useCallback, useEffect, useRef, useState } from "react";
import { interval } from "rxjs";
import { AuthType, Network, SafeDecimal } from "trade_utils_lib";
import AccountManager from "utils/AccountManager";
import Constants from "utils/Constants";
import { ReactNativeWebView } from "utils/ReactNativeWebView";

export default function Upgrading() {
    const [isUpgraded, setIsUpgraded] = useState<boolean>();
    const [token, setToken] = useState<string>();
    const { $st } = useTranslators();
    const intervalId = useRef<NodeJS.Timeout>();
    const [progressPercent, setProgressPercent] = useState(5);
    const migrateApply = useCallback(() => {
        if (isUpgraded !== undefined) return;
        Network.post({
            url: "/apis/user/migrate/apply/",
            authType: AuthType.Access,
        }).toPromise();
    }, [isUpgraded]);

    const migrateCheck = useCallback(() => {
        return Network.get({
            url: "/apis/user/migrate/check/",
            authType: AuthType.Access,
        })
            .toPromise()
            .then((res) => {
                setToken(res.manual_token || "");
                if (res.status === "COMPLETED") {
                    setIsUpgraded(true);
                } else if (res.status === "FAILED") {
                    message.error($st("convert_fail_reason"));
                    setIsUpgraded(false);
                }
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        if (ReactNativeWebView.shared.isWebView()) {
            ReactNativeWebView.shared.migrateApply();
            return;
        }
        if (isUpgraded !== undefined) {
            if (intervalId.current) clearInterval(intervalId.current);
            return;
        }
        migrateCheck().then(migrateApply);
        intervalId.current = setInterval(migrateCheck, 3000);
        return () => {
            if (intervalId.current) clearInterval(intervalId.current);
        };
    }, [migrateCheck, isUpgraded]);

    const loginByToken = useCallback((token: string) => {
        AccountManager.clearSIDCookie();
        const rediretUrl = Constants.appDomain["pionex.us.v2"];
        const url = new URL(`https://${rediretUrl}/sign?t=${token}`);

        url.searchParams.append("time", new SafeDecimal(Date.now()).div(1e3).floor().toString()); // 带上参数：开始跳转的时间
        url.searchParams.append("isFirstV2", "Y"); // 带上参数：开始跳转的时间

        window.location.href = url.href;
    }, []);

    useEffect(() => {
        if (ReactNativeWebView.shared.isWebView()) {
            return;
        }
        if (!token) return;

        loginByToken(token);
    }, [token]);

    useEffect(() => {
        const subscription = interval(350).subscribe(() => {
            setProgressPercent((val) => {
                return Math.min(97, val + 1);
            });
        });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    return (
        <div className=" w-full max-w-[448px] flex flex-col items-center">
            <div className="flex flex-col items-center mt-40px mb-16px">
                <img style={{ width: 100, height: 100 }} src={require("./assets/icon_top_success.svg")} />
                <div className=" text-center text-[28px] text-text-100 font-semibold"> Upgrading... </div>
            </div>
            <Progress percent={progressPercent} strokeColor="#00B070" showInfo={false} />

            <div className=" text-base text-text-200 mt-12px">{$st("ensure_to_upgrade_your_account")}</div>
        </div>
    );
}
