import Axios, { AxiosRequestConfig } from "axios";
import { useEffect, useRef, useState } from "react";
import {
    AuthType,
    ClientPlatform,
    Network,
    PRODUCT_TYPE,
    TradingTokenInfo,
    buildClientId,
    decryptApiSecret,
    encryptWithRSAKey,
    getTradeDependencyInstance,
    hmac,
    random32String,
} from "trade_utils_lib";
import useAccountInfo from "./useAccountInfo";
import { acquireKeyResolver } from "trade_token_lib";
import Constants from "utils/Constants";
import useKycInfoV2, { KycV2Level } from "./useKycInfoV2";
import { useWhiteList } from "use/useWhiteList";
import { report } from "utils/GoogleTagManager";
import { message } from "@pionex-web-kit/components";

const v1BaseUrl = `https://${Constants.appDomain["pionex.us"]}`;
// const v1BaseUrl = "/v1apis";

type AccessToken = {
    expire_at: number;
    token: string;
};

let ACCESS_TOKEN: AccessToken | null = null;
let ACCESS_TOKEN_PROMISE: Promise<AccessToken> | null = null;





export function getV1AccessToken(refresh?: boolean) {
    if (!refresh) {
        if (ACCESS_TOKEN) {
            return Promise.resolve(ACCESS_TOKEN);
        }
        if (ACCESS_TOKEN_PROMISE) {
            return ACCESS_TOKEN_PROMISE;
        }
    }
    ACCESS_TOKEN_PROMISE = Network.post({
        url: "/account/account/get_us_v1_token",
        authType: AuthType.Access,
    })
        .toPromise()
        .then((res) => {
            ACCESS_TOKEN = res.access_token;
            return ACCESS_TOKEN as AccessToken;
        });
    return ACCESS_TOKEN_PROMISE;
}

export function useV1AccessToken() {
    const [accessToken, setAccessToken] = useState<AccessToken>();
    const [isRefresh, setIsRefresh] = useState<boolean>();

    useEffect(() => {
        getV1AccessToken(isRefresh).then((res) => {
            setAccessToken(res);
        });
    }, [isRefresh]);

    useEffect(() => {
        const Timer = setInterval(() => {
            setIsRefresh(!!ACCESS_TOKEN && ACCESS_TOKEN?.expire_at <= Date.now() / 1000);
        }, 3000);
        return () => {
            if (Timer) {
                clearInterval(Timer);
            }
        };
    }, []);

    return accessToken;
}

function toAuthTokenTypes(authType?: AuthType) {
    switch (authType) {
        case AuthType.Access:
            return ["access"];
        case AuthType.Spot:
            return ["spot"];
        case AuthType.AccessAndSpot:
            return ["access", "spot"];
        case AuthType.AccessAndTrader:
            return ["access", "trader"];
        case AuthType.FullToken:
            return ["access", "spot", "swap_binance", "trader"];
        default:
            return [];
    }
}

const noBodyMethod = ["GET", "DELETE"];
export function configV1Signature(config: AxiosRequestConfig, authType: AuthType, tokenInfo: TradingTokenInfo) {
    let secret: any = tokenInfo.apiSecret;
    const authTokenTypes = toAuthTokenTypes(authType);
    if (authTokenTypes.includes("spot")) {
        secret = tokenInfo.apiSecret;
    } else if (authTokenTypes.includes("trader")) {
        secret = tokenInfo.traderApiSecret;
    } else if (authTokenTypes.includes("swap_binance")) {
        secret = tokenInfo.swapApiSecret;
    }
    const timestamp = new Date().getTime() - acquireKeyResolver().requestSigner.recvTimestamp;

    const fullPath = Axios.getUri(config).replace(v1BaseUrl, "");

    const urlWithoutSuffix = fullPath.startsWith("https") ? fullPath.slice(8) : fullPath;
    const hostEndPos = urlWithoutSuffix.indexOf("/");
    const urlParamWithoutHost = urlWithoutSuffix.slice(hostEndPos);

    let body = "";
    const method = config.method || "GET";
    if (!noBodyMethod.includes(method) && config.data) {
        if (typeof config.data !== "string" && config.headers["Content-Type"] === "application/json") {
            // 因为签名时需要使用body参与计算，所以这里将body转换为字符串，保证传输的body内容和参与签名的body内容一致
            // 如果params.data不是一个json对象，需要手动指定header的Content-Type
            body = JSON.stringify(config.data);
        } else {
            body = config.data;
        }
        config.data = body;
    }
    const extraHeaders = {
        Timestamp: timestamp,
        Signature: hmac(`${method}${urlParamWithoutHost}${body}${timestamp}`, secret),
    };
    if (config.headers) {
        Object.assign(config.headers, extraHeaders);
    } else {
        config.headers = extraHeaders;
    }
    return config;
}

export function AxiosV1Token(
    { url, params, headers, ...config }: AxiosRequestConfig,
    v1Token?: string,
    beforeSend?: (_config: AxiosRequestConfig) => AxiosRequestConfig,
): Promise<any> {
    const allConfigs = {
        url: `${v1BaseUrl}${url}`,
        headers: {
            ...(v1Token ? { Authorization: `Bearer ${v1Token}` } : {}),
            "Content-Type": "application/json;charset=UTF-8",
            ...headers,
        },
        params: {
            ...getTradeDependencyInstance().getRunTimeHost().commonParam,
            client_id: buildClientId(PRODUCT_TYPE.PIONEX_US, ClientPlatform.WEB, process.env.VERSION),
            ...params,
        },
        ...config,
    };
    return Axios(beforeSend ? beforeSend(allConfigs) : allConfigs);
}

let TRADING_TOKEN_INFO: TradingTokenInfo | null = null;
let TRADING_TOKEN_INFO_PROMISE: Promise<TradingTokenInfo> | null = null;

export function getV1TradingToken(accessTokenStr: string, userId: string): Promise<TradingTokenInfo> {
    if (TRADING_TOKEN_INFO) {
        return Promise.resolve(TRADING_TOKEN_INFO);
    }
    if (TRADING_TOKEN_INFO_PROMISE) {
        return TRADING_TOKEN_INFO_PROMISE;
    }
    const aesKey = random32String();
    TRADING_TOKEN_INFO_PROMISE = getTradeDependencyInstance()
        .getRSAPublicKey()
        .toPromise()
        .then((rsaPublicKey) => {
            return AxiosV1Token(
                {
                    method: "POST",
                    url: `${getTradeDependencyInstance().getRunTimeHost().manageApiHost}/trade_token/`,
                    data: { secret: encryptWithRSAKey(rsaPublicKey, aesKey) },
                    headers: { Authorization: `Bearer ${accessTokenStr}` },
                },
                accessTokenStr,
            );
        })
        .then((res) => {
            const data = res.data.data;
            const expire_at_arr: number[] = [data.spot?.expire_at, data.swap?.expire_at, data.trader?.expire_at].filter((e) => Number(e) > 0);
            const expire_at = Math.min(...expire_at_arr);
            return {
                id: userId,
                apiSecret: decryptApiSecret(data.spot.api_secret, aesKey),
                tradingToken: data.spot.token,
                awsToken: accessTokenStr,
                swapApiSecret: data.swap && data.swap.api_secret ? decryptApiSecret(data.swap.api_secret, aesKey) : "",
                swapToken: data.swap ? data.swap.token : "",
                traderToken: data.trader ? data.trader.token : "",
                traderApiSecret: data.trader ? decryptApiSecret(data.trader.api_secret, aesKey) : "",
                tokenExpire: expire_at,
            } as TradingTokenInfo;
        });
    return TRADING_TOKEN_INFO_PROMISE;
}

export function useV1TradingToken() {
    const [tradingTokenInfo, setTradingTokenInfo] = useState<TradingTokenInfo>();
    const accessToken = useV1AccessToken();
    const { userId } = useAccountInfo();
    const isFirstRun = useRef(true);
    useEffect(() => {
        if (!accessToken || !userId) {
            return;
        }
        getV1TradingToken(accessToken.token, userId).then((res) => {
            setTradingTokenInfo(res);
        }).catch(() => {
            message.error("An error occurred. Please click back and re-enter this page.", 10);
            report("v1_v2_error_occurred", {
                type: "trading_token",
            });
        });
        isFirstRun.current = false;
    }, [accessToken, userId]);
    return tradingTokenInfo;
}

export function useAxiosV1Token(config: AxiosRequestConfig, authType: AuthType = AuthType.Access, isSignature?: boolean) {
    const v1Token = useV1TradingToken();
    const [result, setResult] = useState<any>();
    const [loading, setLoading] = useState<boolean>();

    useEffect(() => {
        if (!v1Token) return;
        setLoading(true);
        AxiosV1Token(
            isSignature ? configV1Signature(config, authType, v1Token) : config,
            (() => {
                if (authType === AuthType.Spot) {
                    return v1Token.tradingToken;
                }
                return v1Token.awsToken;
            })(),
        )
            .then((res) => {
                setResult(res.data.data);
            })
            .catch((err) => { })
            .finally(() => {
                setLoading(false);
            });
    }, [v1Token, config, isSignature]);
    return {
        loading,
        result,
    };
}

let IS_USER_FROM_V1: boolean | null = null;
let IS_USER_FROM_V1_PROMISE: Promise<boolean> | null = null;

export function getIsUserFromV1() {
    if (IS_USER_FROM_V1) {
        return Promise.resolve(IS_USER_FROM_V1);
    }
    if (IS_USER_FROM_V1_PROMISE) {
        return IS_USER_FROM_V1_PROMISE;
    }
    IS_USER_FROM_V1_PROMISE = Network.get({
        url: "/account/account/is_user_from_us_v1",
        authType: AuthType.Access,
    })
        .toPromise()
        .then((res) => {
            return res.result;
        });
    return IS_USER_FROM_V1_PROMISE;
}

export function useIsUserFromV1() {
    const [isUserFromV1, setIsUserFromV1] = useState<boolean>();
    useEffect(() => {
        getIsUserFromV1().then((res) => {
            setIsUserFromV1(res);
        });
    }, []);
    return isUserFromV1;
}

/* 是否展示升级入口 */
export function useShowUpgradeEntrance() {
    const { state: isWhiteList, loading: whiteListLoading } = useWhiteList("Migrate-V1ToV2");
    return [PLATFORM.PIONEX_US && isWhiteList, whiteListLoading];
}