import { ST } from "commonUse/Locale";
import { WithdrawHistoryInfo, WithdrawStatus } from "landings/V2/SignatureDW/types";
import { useOnlineConfig } from "utils/onlineConfig";

export function useWithdrawErrorMap(): Record<string, string> | undefined {
    const { errorMap } = useOnlineConfig();
    return errorMap?.wire_withdraw_failure;
}

/**
 * 失败原因
 */
export function getFailReason($st: ST, errorMap: Record<string, string> | undefined, record: WithdrawHistoryInfo): string {
    if (record.status !== WithdrawStatus.FAIL) {
        return "";
    }

    // 返回的格式是以冒号分割
    const [code, message] = record.approvalReason.split(":");
    let text: string = $st("signature_dw_withdraw_fail_reason");

    if (!code) {
        return text;
    }

    if (code === "OTHERS") {
        return message;
    }

    if (errorMap?.[code]) {
        // 配置中匹配错误码
        text = errorMap?.[code];
    }

    return text;
}

/**
 * 不同状态的提示文案
 * @param $st
 * @param errorMap
 * @param record
 */
export function getTips($st: ST, errorMap: Record<string, string> | undefined, record: WithdrawHistoryInfo) {
    switch (record.status) {
        case WithdrawStatus.FAIL:
            return getFailReason($st, errorMap, record);
        case WithdrawStatus.PAID:
            return $st("fbo_tips_paid");
        case WithdrawStatus.PENDING:
            return record.transferType === "ach" ? $st("ach_pending_tips") : $st("wire_pending_tips");
        default:
            break;
    }
}
