import css from "./index.m.less";
import { useParams } from "react-router";
import CloseOrder from "./CloseOrder";
import CloseBotOrder from "./CloseBotOrder";
import CloseManualOrder from "./CloseManualOrder";
import Upgrade from "./Upgrade";
import Upgrading from "./Upgrading";
import Upgraded from "./Upgraded";
import { useEffect } from "react";
import { history } from "utils/History";
import { useIsUserFromV1, useShowUpgradeEntrance } from "commonUse/useV1Token";

export function getAccountPathname(pathname) {
    return /\/balance/.test(window.location.pathname) ? `/balance${pathname}` : pathname;
}

export default function AccountUpdate() {
    const { subType } = useParams<{ subType?: string }>();
    const [showUpgradeEntrance, loading] = useShowUpgradeEntrance();
    const isUserFromV1 = useIsUserFromV1();

    useEffect(() => {
        if (isUserFromV1 === undefined || loading || loading === undefined) return;
        if (isUserFromV1) {
            history.push(getAccountPathname("/account_update/upgraded"));
        } else if (showUpgradeEntrance === false) {
            history.push("/");
        }
    }, [isUserFromV1, showUpgradeEntrance, loading]);

    return (
        <div className={css.migration_wrapper}>
            {!subType && <CloseOrder />}
            {subType === "close_manual_order" && <CloseManualOrder />}
            {subType === "close_bot_order" && <CloseBotOrder />}
            {subType === "upgrade" && <Upgrade />}
            {subType === "upgrading" && <Upgrading />}
            {subType === "upgraded" && <Upgraded />}
        </div>
    );
}
