import { Subscription, timer } from "rxjs";
import { isPionex } from "utils/platform";
import ExchangeDataProvider from "TradeLib/ExchangeDataProvider";
import { exhaustMap, flatMap } from "rxjs/operators";
import { default as React, useEffect } from "react";
import { PieChartData } from "landings/Balance/components/BalancePie/BalancePieView";
import { BotTypeBalanceInfo, TradeAssetsInfo } from "TradeAPILib";
import NumberUtils from "utils/NumberUtils";
import AccountInfo from "state/AccountInfo";
import { $st } from "src/utils/i18n";
import Decimal from "decimal.js";
import { APIKeyInfo, NumberCommonUtils } from "trade_utils_lib";
import { useDataProvider } from "commonUse";
import { CoinBalance } from "trade_asset_lib";
import { BotBalanceTemp } from "landings/Balance/components/BalancePie/BotBalance.temp";

type PieChartBotData = {
    botType: string;
    botCount: number;
};

export interface PieChartListColorData {
    colorStartValue: string;
    colorEndValue: string;
}

export function useOrderBalance(accountInfo: AccountInfo, totalBalance: number, otherOrder: number): [Array<PieChartData>, boolean] {
    let [isBotsLoading, setIsBotsLoading] = React.useState(true);
    let [botTypeBalanceList, setBotTypeBalanceList] = React.useState([] as Array<PieChartData>);

    useEffect(() => {
        let tempObserve: Subscription;

        if (isPionex()) {
            if (accountInfo.userId === undefined || !accountInfo.firstApiKey) {
                return;
            }

            tempObserve = ExchangeDataProvider.getExchangeDataProvider(accountInfo.firstApiKey)
                .pipe(flatMap((exchangeDataProvider) => BotBalanceTemp.getBotTypeBalance(exchangeDataProvider)))
                .subscribe(
                    (data) => {
                        if (data !== undefined) {
                            setBotTypeBalanceList(botListTransition({ ...data, staking: otherOrder }, totalBalance));
                            setIsBotsLoading(false);
                        } else {
                            setIsBotsLoading(false);
                        }
                    },
                    (error) => {
                        console.error(error);
                        setIsBotsLoading(false);
                    },
                );
        }
        return () => {
            tempObserve && tempObserve.unsubscribe();
        };
    }, [accountInfo, otherOrder, totalBalance]);

    return [botTypeBalanceList, isBotsLoading];
}

export function ListColorTransition(type: string, index: number): PieChartListColorData {
    let colorStartValue: string;
    let colorEndValue: string;
    switch (index) {
        case 0: {
            if (type === "Bots") {
                colorStartValue = "#FE884E";
                colorEndValue = "#FE884E";
            } else {
                colorStartValue = "#F9C719";
                colorEndValue = "#F9C719";
            }
            break;
        }
        case 1: {
            if (type === "Bots") {
                colorStartValue = "#7DE72A";
                colorEndValue = "#7DE72A";
            } else {
                colorStartValue = "#F55933";
                colorEndValue = "#F55933";
            }
            break;
        }
        case 2: {
            if (type === "Bots") {
                colorStartValue = "#FF6A6D";
                colorEndValue = "#FF6A6D";
            } else {
                colorStartValue = "#23FF8D";
                colorEndValue = "#23FF8D";
            }
            break;
        }
        case 3: {
            if (type === "Bots") {
                colorStartValue = "#FFC4A8";
                colorEndValue = "#FFC4A8";
            } else {
                colorStartValue = "#009BEE";
                colorEndValue = "#009BEE";
            }
            break;
        }
        case 4: {
            if (type === "Bots") {
                colorStartValue = "#8798F7";
                colorEndValue = "#8798F7";
            } else {
                colorStartValue = "#C43AD8";
                colorEndValue = "#C43AD8";
            }
            break;
        }
        case 5: {
            if (type === "Bots") {
                colorStartValue = "#FFC760";
                colorEndValue = "#FFC760";
            } else {
                colorStartValue = "#6749BD";
                colorEndValue = "#6749BD";
            }
            break;
        }
        case 6: {
            if (type === "Bots") {
                colorStartValue = "#BBE651";
                colorEndValue = "#BBE651";
            } else {
                colorStartValue = "#6749BD";
                colorEndValue = "#6749BD";
            }
            break;
        }
        case 7: {
            if (type === "Bots") {
                colorStartValue = "#70B3F3";
                colorEndValue = "#70B3F3";
            } else {
                colorStartValue = "#6749BD";
                colorEndValue = "#6749BD";
            }
            break;
        }
        case 8: {
            if (type === "Bots") {
                colorStartValue = "#DC8EF2";
                colorEndValue = "#DC8EF2";
            } else {
                colorStartValue = "#6749BD";
                colorEndValue = "#6749BD";
            }
            break;
        }
        case 9: {
            if (type === "Bots") {
                colorStartValue = "#F8D55B";
                colorEndValue = "#F8D55B";
            } else {
                colorStartValue = "#6749BD";
                colorEndValue = "#6749BD";
            }
            break;
        }

        case 10: {
            if (type === "Bots") {
                colorStartValue = "#86DAF5";
                colorEndValue = "#86DAF5";
            } else {
                colorStartValue = "#6749BD";
                colorEndValue = "#6749BD";
            }
            break;
        }

        case 11: {
            colorStartValue = "#97BED4";
            colorEndValue = "#97BED4";
            break;
        }
        // staking
        case 12: {
            colorStartValue = "red";
            colorEndValue = "red";
            break;
        }
        default: {
            colorStartValue = "white";
            colorEndValue = "white";
        }
    }
    let colorData: PieChartListColorData;
    colorData = {
        colorStartValue: colorStartValue,
        colorEndValue: colorEndValue,
    };
    return colorData;
}

function botListTransition(data: BotTypeBalanceInfo & { staking: number }, totalUSD: number, otherOrder?: number): Array<PieChartData> {
    let botsUSD = 0;
    let BotTypeBalanceArray: PieChartBotData[] = [];
    if (data.grid !== undefined) {
        let botData: PieChartBotData;
        botData = {
            botType: $st("balance.grid_Bot"),
            botCount: NumberCommonUtils.str2number(data.grid),
        };
        BotTypeBalanceArray.push(botData);
        botsUSD += NumberCommonUtils.str2number(data.grid);
    }
    if (data.grid_leverage !== undefined) {
        let botData: PieChartBotData;
        botData = {
            botType: $st("balance.grid_leverage"),
            botCount: NumberCommonUtils.str2number(data.grid_leverage),
        };
        BotTypeBalanceArray.push(botData);
        botsUSD += NumberCommonUtils.str2number(data.grid_leverage);
    }
    if (data.grid_leverage_short !== undefined) {
        let botData: PieChartBotData;
        botData = {
            botType: $st("grid_leverage_short"),
            botCount: NumberCommonUtils.str2number(data.grid_leverage_short),
        };
        BotTypeBalanceArray.push(botData);
        botsUSD += NumberCommonUtils.str2number(data.grid_leverage_short);
    }
    if (data.grid_loan !== undefined) {
        let botData: PieChartBotData;
        botData = {
            botType: $st("balance.grid_loan"),
            botCount: NumberCommonUtils.str2number(data.grid_loan),
        };
        BotTypeBalanceArray.push(botData);
        botsUSD += NumberCommonUtils.str2number(data.grid_loan);
    }
    if (data.grid_short !== undefined) {
        let botData: PieChartBotData;
        botData = {
            botType: $st("balance.grid_short"),
            botCount: NumberCommonUtils.str2number(data.grid_short),
        };
        BotTypeBalanceArray.push(botData);
        botsUSD += NumberCommonUtils.str2number(data.grid_short);
    }
    if (data.ig !== undefined) {
        let botData: PieChartBotData;
        botData = {
            botType: $st("balance.ig_Bot"),
            botCount: NumberCommonUtils.str2number(data.ig),
        };
        BotTypeBalanceArray.push(botData);
        botsUSD += NumberCommonUtils.str2number(data.ig);
    }
    if (data.aip !== undefined) {
        let botData: PieChartBotData;
        botData = {
            botType: $st("balance.DCA_Bot"),
            botCount: NumberCommonUtils.str2number(data.aip),
        };
        BotTypeBalanceArray.push(botData);
        botsUSD += NumberCommonUtils.str2number(data.aip);
    }
    if (data.trailing_stop !== undefined) {
        let botData: PieChartBotData;
        botData = {
            botType: $st("balance.TTP_Bot"),
            botCount: NumberCommonUtils.str2number(data.trailing_stop),
        };
        BotTypeBalanceArray.push(botData);
        botsUSD += NumberCommonUtils.str2number(data.trailing_stop);
    }
    if (data.twap !== undefined) {
        let botData: PieChartBotData;
        botData = {
            botType: $st("balance.TWAP_Bot"),
            botCount: NumberCommonUtils.str2number(data.twap),
        };
        BotTypeBalanceArray.push(botData);
        botsUSD += NumberCommonUtils.str2number(data.twap);
    }

    if (data.smart_trade !== undefined) {
        let botData: PieChartBotData;
        botData = {
            botType: $st("order_type_smart_trade"),
            botCount: NumberCommonUtils.str2number(data.smart_trade),
        };
        BotTypeBalanceArray.push(botData);
        botsUSD += NumberCommonUtils.str2number(data.smart_trade);
    }

    if (data.smart_rebalance !== undefined) {
        let botData: PieChartBotData;
        botData = {
            botType: $st("trading_smart_rebalance"),
            botCount: NumberCommonUtils.str2number(data.smart_rebalance),
        };
        BotTypeBalanceArray.push(botData);
        botsUSD += NumberCommonUtils.str2number(data.smart_rebalance);
    }

    if (data.martingale !== undefined) {
        let botData: PieChartBotData;
        botData = {
            botType: $st("martingale_bot"),
            botCount: NumberCommonUtils.str2number(data.martingale),
        };
        BotTypeBalanceArray.push(botData);
        botsUSD += NumberCommonUtils.str2number(data.martingale);
    }

    // 兼容添加staking  展示
    // if (data.staking !== undefined&&data.staking!==0) {
    //     let botData: PieChartBotData;
    //     botData = {
    //         botType: "Staking",
    //         botCount: NumberCommonUtils.str2number(data.staking + ""),
    //     };
    //     BotTypeBalanceArray.push(botData);
    //     botsUSD += NumberCommonUtils.str2number(data.staking + "");
    // }

    BotTypeBalanceArray.sort((a, b) => {
        return b.botCount - a.botCount;
    });
    if (totalUSD - botsUSD > 0) {
        let botData: PieChartBotData;
        botData = {
            botType: $st("balance.free"),
            botCount: NumberUtils.effectiveDecimalNum(totalUSD - (otherOrder || 0) - botsUSD, 8),
        };
        BotTypeBalanceArray.push(botData);
    }

    let otherPercent = 0;
    let tempArr = BotTypeBalanceArray.map((obj, index) => {
        let colorData: PieChartListColorData = ListColorTransition("Bots", index);
        let dict: PieChartData;
        let percent = NumberUtils.toPercentNum(NumberUtils.effectiveDecimalNum(NumberUtils.effectiveDecimalNum(obj.botCount, 8) / totalUSD, 8, Decimal.ROUND_DOWN) * 100);
        if (percent > 100) {
            percent = 100;
        }

        if (BotTypeBalanceArray.length > 0) {
            if (index === BotTypeBalanceArray.length - 1) {
                percent = NumberUtils.toPercentNum(NumberUtils.effectiveDecimalNum(100 * 100 - otherPercent * 100, 8, Decimal.ROUND_DOWN)) / 100;
            } else {
                otherPercent = otherPercent + percent;
            }
        }
        dict = {
            item: obj.botType,
            count: obj.botCount,
            colorStartValue: colorData.colorStartValue,
            colorEndValue: colorData.colorEndValue,
            itemPercent: percent,
        };

        return dict;
    });

    if (tempArr.length === 1) {
        //避免因为精度低于8位,count 值为0,饼图异常
        tempArr[0].count = 1;
    }
    return tempArr;
}

/**
 * coinList 转换为环图所需数据
 * @param dataSource
 */
export function coinListTransition(dataSource: Array<CoinBalance>): Array<PieChartData> {
    dataSource.sort((a, b) => {
        return b.USD - a.USD;
    });
    let totalUSD = 0;
    dataSource.forEach((obj) => {
        totalUSD = NumberUtils.effectiveDecimalNum(totalUSD, 8) + NumberUtils.effectiveDecimalNum(obj.USD, 8);
    });

    if (dataSource.length >= 5) {
        let otherTotal = 0;
        let array = dataSource.slice(0, 5).filter((obj) => {
            return NumberUtils.toPercentNum(NumberUtils.effectiveDecimalNum(NumberUtils.effectiveDecimalNum(obj.USD, 8) / totalUSD, 4) * 100) >= 5;
        });
        array.forEach((obj) => {
            otherTotal = NumberUtils.effectiveDecimalNum(otherTotal, 8) + NumberUtils.effectiveDecimalNum(obj.USD, 8);
        });

        let otherPercent = 0;
        let tempArray = array.map((obj, index) => {
            let colorData: PieChartListColorData = ListColorTransition("Coins", index);
            let dict: PieChartData;
            let itemValue: string;
            if (obj.fullName !== null && obj.fullName !== undefined && !obj.fullName!.startsWith("No Full Name")) {
                itemValue = `${obj.baseDisplay} (${obj.fullName})`;
            } else {
                itemValue = `${obj.baseDisplay}`;
            }

            dict = {
                item: itemValue,
                count: NumberUtils.effectiveDecimalNum(obj.USD, 8),
                colorStartValue: colorData.colorStartValue,
                colorEndValue: colorData.colorEndValue,
                itemPercent: NumberUtils.toPercentNum(NumberUtils.effectiveDecimalNum(NumberUtils.effectiveDecimalNum(obj.USD, 8) / totalUSD, 4) * 100),
            };
            otherPercent = otherPercent + dict.itemPercent;
            return dict;
        });
        let tempValue = totalUSD - otherTotal;
        let otherDict: PieChartData;
        otherDict = {
            item: $st("balance.other"),
            count: tempValue,
            colorStartValue: "#6749BD",
            colorEndValue: "#6749BD",
            itemPercent: NumberUtils.toPercentNum(NumberUtils.effectiveDecimalNum(NumberUtils.effectiveDecimalNum(100 * 100 - otherPercent * 100, 4), 8)) / 100,
        };
        tempArray.push(otherDict);
        return tempArray;
    } else {
        let array = dataSource.filter((obj) => {
            return NumberUtils.toPercentNum(NumberUtils.effectiveDecimalNum(NumberUtils.effectiveDecimalNum(obj.USD, 8) / totalUSD, 4) * 100) >= 5;
        });
        let otherPercent = 0;
        let otherTotal = 0;

        array.forEach((obj) => {
            otherTotal = NumberUtils.effectiveDecimalNum(otherTotal, 8) + NumberUtils.effectiveDecimalNum(obj.USD, 8);
        });

        let tempArray = array.map((obj, index) => {
            let colorData: PieChartListColorData = ListColorTransition("Coins", index);
            let dict: PieChartData;

            let itemValue: string;
            if (obj.fullName !== null && obj.fullName !== undefined && !obj.fullName!.startsWith("No Full Name")) {
                itemValue = `${obj.baseDisplay} (${obj.fullName})`;
            } else {
                itemValue = `${obj.baseDisplay}`;
            }
            dict = {
                item: itemValue,
                count: NumberUtils.effectiveDecimalNum(obj.USD, 8),
                colorStartValue: colorData.colorStartValue,
                colorEndValue: colorData.colorEndValue,
                itemPercent: NumberUtils.toPercentNum(NumberUtils.effectiveDecimalNum(NumberUtils.effectiveDecimalNum(obj.USD, 8) / totalUSD, 4) * 100),
            };
            otherPercent = otherPercent + dict.itemPercent;
            return dict;
        });

        if (array.length < dataSource.length) {
            let tempValue = totalUSD - otherTotal;
            if (tempValue > 0.00001) {
                let otherDict: PieChartData;
                otherDict = {
                    item: $st("balance.other"),
                    count: tempValue,
                    colorStartValue: "#6749BD",
                    colorEndValue: "#6749BD",
                    itemPercent: NumberUtils.toPercentNum(NumberUtils.effectiveDecimalNum(NumberUtils.effectiveDecimalNum(100 * 100 - otherPercent * 100, 4), 8)) / 100,
                };
                tempArray.push(otherDict);
            }
        }

        if (tempArray.length === 1) {
            //避免因为精度低于8位,count 值为0,饼图异常
            tempArray[0].count = 1;
        }

        // 处理加一起不等于100
        const totalPercent = tempArray.reduce((sum, item) => sum + NumberUtils.toPercentNum(NumberUtils.effectiveDecimal(item.itemPercent, 2)), 0);
        if (totalPercent < 100 && tempArray[tempArray.length - 1]) {
            tempArray[tempArray.length - 1].itemPercent += (100 * 100 - totalPercent * 100) / 100;
        }

        return tempArray;
    }
}

export const useStrategyAssetsInfo = (apiKey: APIKeyInfo | undefined): [boolean, TradeAssetsInfo] => {
    const [strategyAssetsInfo, setStrategyAssetsInfo] = React.useState<TradeAssetsInfo>(undefined as unknown as TradeAssetsInfo);
    const [loading, setLoading] = React.useState<boolean>(true);
    const dataProvider = useDataProvider(apiKey);

    React.useEffect(() => {
        if (!dataProvider) {
            return;
        }
        const tempObserve = timer(0, 5000)
            .pipe(
                exhaustMap(() => {
                    return dataProvider.api.queryCurrentDayProfitAndLoss();
                }),
            )
            .subscribe(
                (data) => {
                    setLoading(false);
                    setStrategyAssetsInfo(data);
                },
                (error) => {
                    setLoading(false);
                    console.log("useStrategyAssetsInfo", error);
                },
            );
        return () => {
            tempObserve.unsubscribe();
        };
    }, [dataProvider]);

    return [loading, strategyAssetsInfo];
};
