import useTheme from "commonUse/useTheme";
import React, { useMemo, useRef } from "react";
import { ThemeName } from "state/ThemeData";
import css from "../style/index.module.less";
import QrCode from "qrcode.react";
import { IconRightArrow12pxRegular } from "@pionex-web-kit/icons";
import { openUrlInNewTab } from "utils/commons";
import { isMobile, isAndroid } from "src/utils/commons";
import { useTranslators } from "commonUse/Locale";

export interface DownloadAppProps {
    showQRCode?: boolean;
    style?: React.CSSProperties;
    titleColor?: string;
}

const downloadUrl = {
    apple: "https://apps.apple.com/cn/app/google-authenticator/id388497605",
    gp: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
};

const DownloadApp = (props: DownloadAppProps) => {
    const { showQRCode, style, titleColor } = props;
    const { $st } = useTranslators();
    const theme = useTheme();
    const isDark = theme.name === ThemeName.dark;
    const rootRef = useRef(null);

    const mobileApp = useMemo(() => {
        return (
            <>
                {!isAndroid && (
                    <div className={css.downloadItem} onClick={() => openUrlInNewTab(downloadUrl.apple)}>
                        <div className={css.left}>
                            <img src={isDark ? require("../assets/app-light.png") : require("../assets/app.png")} alt="apple" />
                            <div className={css.mdec}>{$st("google_auth_app_apple_store")}</div>
                        </div>

                        <span className="text-secondary">
                            <IconRightArrow12pxRegular size={12} />
                        </span>
                    </div>
                )}
                {isAndroid && (
                    <div className={css.downloadItem} onClick={() => openUrlInNewTab(downloadUrl.gp)}>
                        <div className={css.left}>
                            <img src={isDark ? require("../assets/gp-light.png") : require("../assets/google.png")} alt="apple" />
                            <div className={css.mdec}>{$st("google_auth_app_form_gp")}</div>
                        </div>

                        <span className="text-secondary">
                            <IconRightArrow12pxRegular size={12} />
                        </span>
                    </div>
                )}
            </>
        );
    }, [$st, isDark]);

    const pcApp = useMemo(() => {
        return (
            <>
                <div className={css.downloadItem} onClick={() => openUrlInNewTab(downloadUrl.apple)}>
                    <div className={css.left}>
                        <img src={isDark ? require("../assets/app-light.png") : require("../assets/app.png")} alt="apple" />
                        <div className={css.dec}>{$st("google_auth_app_apple_store")}</div>
                    </div>

                    <span className="text-secondary">
                        <IconRightArrow12pxRegular size={12} />
                    </span>
                </div>
                <div className={css.downloadItem} onClick={() => openUrlInNewTab(downloadUrl.gp)}>
                    <div className={css.left}>
                        <img src={isDark ? require("../assets/gp-light.png") : require("../assets/google.png")} alt="apple" />
                        <div className={css.dec}>{$st("google_auth_app_form_gp")}</div>
                    </div>

                    <span className="text-secondary">
                        <IconRightArrow12pxRegular size={12} />
                    </span>
                </div>
            </>
        );
    }, [$st, isDark]);

    return (
        <div className={css.contentwidth} style={style} ref={rootRef}>
            <img src={require("../assets/2fa.png")} alt="2fa" />
            <div className={css.auth}>Google Authenticator</div>
            <div className={css.content}>{isMobile ? mobileApp : pcApp}</div>

            <div className={css.downloadTips}>
                <QrCode value={window.location.href} size={90} fgColor={isDark ? "#fff" : "#000"} bgColor={!isDark ? "#fff" : "#000"} includeMargin={false} />
                <div className={css.description}>
                    <span className={css.title}>{$st("google_auth_app_scan_download")}</span>
                    <span className={css.type}>iOS & Android</span>
                    <span className={css.tip}>{$st("google_auth_app_tips")}</span>
                </div>
            </div>
        </div>
    );
};

export default DownloadApp;
