import React from "react";

const PButton = (props) => {
    const { text, onPress } = props;

    return (
        <div
            onClick={onPress}
            style={{
                padding: "13px 81px",
                backgroundColor: "#FF7028",
                borderRadius: 39,
                display: "inline-block",
                cursor: "pointer",
            }}
        >
            <div
                style={{
                    color: "#FFFFFF",
                    fontSize: 14,
                    fontWeight: 600,
                }}
            >
                {text}
            </div>
        </div>
    );
};

export default PButton;
