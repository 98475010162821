import Decimal from "decimal.js";
import { ExchangeSymbol } from "TradeAPILib";
import { ExchangeTradePair } from "../TradeLib/TradeTypes";
import ExchangeDataProvider from "../TradeLib/ExchangeDataProvider";
import Constants from "./Constants";
import { $stn } from "utils/i18n/trans.comp";
import { NumberCommonUtils } from "trade_utils_lib";

const DECIMAL_100 = new Decimal(100);

export default class NumberUtils {
    static toPercentNum(val: Decimal.Value): number {
        try {
            return new Decimal(val).toDecimalPlaces(2, Decimal.ROUND_DOWN).toNumber();
        } catch (e) {
            return 0;
        }
    }

    static effectiveDecimal(value: Decimal.Value, decimal: number = 2, rounding: Decimal.Rounding = Decimal.ROUND_DOWN): string {
        return NumberCommonUtils._inner_EffectiveDecimal(value, decimal, rounding).toString();
    }

    static effectiveDecimalNum(value: string | number | undefined, decimal: number = 2, rounding: Decimal.Rounding = Decimal.ROUND_DOWN): number {
        if (!value) {
            return 0;
        }
        return NumberCommonUtils._inner_EffectiveDecimal(value, decimal, rounding).toNumber();
    }

    static toPrice(value: Decimal.Value, pair: ExchangeTradePair, rounding: Decimal.Rounding = Decimal.ROUND_DOWN): string {
        let symbol = ExchangeDataProvider.getExchangeSymbol(pair);
        let precision = 4;
        if (symbol) {
            precision = symbol.precision !== undefined ? symbol.precision : 4;
        }
        return NumberCommonUtils._inner_EffectiveDecimal(value, precision, rounding).toString();
    }

    static toAmount(value: Decimal.Value, pair: ExchangeTradePair, rounding: Decimal.Rounding = Decimal.ROUND_DOWN): string {
        let symbol = ExchangeDataProvider.getExchangeSymbol(pair);
        let precision = 4;
        if (symbol) {
            precision = symbol.amount !== undefined ? symbol.amount : 4;
        }
        return NumberCommonUtils._inner_EffectiveDecimal(value, precision, rounding).toString();
    }

    /**
     * @deprecated since "Mon May 18 2020 11:43:35 GMT+0800 (中国标准时间)"
     * Replaced by NumberTradeUtils.ts
     */
    static toPriceBySymbol(value: Decimal.Value, symbol: ExchangeSymbol, rounding: Decimal.Rounding = Decimal.ROUND_DOWN): string {
        const precision = symbol.precision !== undefined ? symbol.precision : 4;
        return NumberCommonUtils._inner_EffectiveDecimal(value, precision, rounding).toString();
    }

    /**
     * @deprecated since "Mon May 18 2020 11:43:35 GMT+0800 (中国标准时间)"
     * Replaced by NumberTradeUtils.ts
     */

    static toAmountBySymbol(value: Decimal.Value, symbol: ExchangeSymbol, rounding: Decimal.Rounding = Decimal.ROUND_DOWN): string {
        const precision = symbol.amount !== undefined ? symbol.amount : 4;
        return NumberCommonUtils._inner_EffectiveDecimal(value, precision, rounding).toString();
    }

    /**
     * @deprecated since "Mon May 18 2020 11:43:35 GMT+0800 (中国标准时间)"
     * Replaced by NumberTradeUtils.ts
     */

    static toPriceNumBySymbol(value: string | number | undefined, symbol: ExchangeSymbol, rounding: Decimal.Rounding = Decimal.ROUND_DOWN): number {
        let precision = symbol.precision !== undefined ? symbol.precision : 4;
        if (!value) {
            return 0;
        }
        return NumberCommonUtils._inner_EffectiveDecimal(value, precision, rounding).toNumber();
    }

    /**
     * @deprecated since "Mon May 18 2020 11:43:35 GMT+0800 (中国标准时间)"
     * Replaced by NumberTradeUtils.ts
     */

    static toAmountNumBySymbol(value: string | number | undefined, symbol: ExchangeSymbol, rounding: Decimal.Rounding = Decimal.ROUND_DOWN): number {
        let precision = symbol.amount !== undefined ? symbol.amount : 4;
        if (!value) {
            return 0;
        }
        return NumberCommonUtils._inner_EffectiveDecimal(value, precision, rounding).toNumber();
    }

    static toShortString(value: string | number | undefined, format: boolean = false): string {
        try {
            if (value) {
                let valueDecimal: Decimal = new Decimal(value);
                if (Constants.isCN) {
                    if (valueDecimal.greaterThanOrEqualTo(100000000)) {
                        const num = Decimal.round(valueDecimal.div(new Decimal(1000000))).div(DECIMAL_100);
                        if (format) {
                            return $stn(num.toNumber()) + "亿";
                        }
                        return num + "亿";
                    } else if (valueDecimal.greaterThanOrEqualTo(10000)) {
                        const num = Decimal.round(valueDecimal.div(new Decimal(100))).div(DECIMAL_100);
                        if (format) {
                            return $stn(num.toNumber()) + "万";
                        }
                        return num + "万";
                    } else {
                        const num = Decimal.round(valueDecimal.mul(DECIMAL_100)).div(DECIMAL_100);
                        if (format) {
                            return $stn(num.toNumber());
                        }
                        return num.toString();
                    }
                } else {
                    if (valueDecimal.greaterThanOrEqualTo(1000000000000)) {
                        const num = Decimal.round(valueDecimal.div(new Decimal(10000000000))).div(DECIMAL_100);
                        if (format) {
                            return $stn(num.toNumber()) + "T";
                        }
                        return num + "T";
                    } else if (valueDecimal.greaterThanOrEqualTo(1000000000)) {
                        const num = Decimal.round(valueDecimal.div(new Decimal(10000000))).div(DECIMAL_100);
                        if (format) {
                            return $stn(num.toNumber()) + "B";
                        }
                        return num + "B";
                    } else if (valueDecimal.greaterThanOrEqualTo(1000000)) {
                        const num = Decimal.round(valueDecimal.div(new Decimal(10000))).div(DECIMAL_100);
                        if (format) {
                            return $stn(num.toNumber()) + "M";
                        }
                        return num + "M";
                    } else if (valueDecimal.greaterThanOrEqualTo(1000)) {
                        const num = Decimal.round(valueDecimal.div(new Decimal(10))).div(DECIMAL_100);
                        if (format) {
                            return $stn(num.toNumber()) + "K";
                        }
                        return num + "K";
                    } else {
                        const num = Decimal.round(valueDecimal.mul(DECIMAL_100)).div(DECIMAL_100);
                        if (format) {
                            return $stn(num.toNumber());
                        }
                        return num.toString();
                    }
                }
            } else {
                return "0";
            }
        } catch (e) {
            return "0";
        }
    }

    static wrapperToShortString(value: string | number | undefined, format: boolean = false): string {
        if (!value) return "0";
        let valueDecimal = new Decimal(value);
        if (valueDecimal.lessThanOrEqualTo(1)) {
            const num = valueDecimal.toDecimalPlaces(4, Decimal.ROUND_CEIL);
            return num.toString();
        } else {
            return NumberUtils.toShortString(value, format);
        }
    }

    static toPreFix(value: number, n: number) {
        if (`${value}`.length >= n) {
            return `${value}`;
        }
        return `${Array(n).join("0")}${value}`.slice(-n);
    }

    static numberWithCommas(number: string | number = ""): string {
        const originalStr = String(number).replace(/,/g, "");
        const hasDot = originalStr.includes(".");
        let [integer = "0", decimal = ""] = originalStr.split(".");

        const head = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return `${head}${hasDot ? "." : ""}${decimal}`;
    }
}
