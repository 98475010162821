import React, { useState } from "react";
import { PureWithdraw } from "landings/V2/SignatureDW/Withdraw";
import { useTranslators } from "commonUse/Locale";
import TitleCom from "landings/V2/CircleACH/components/TitleCom";
import { SignatureTransferType } from "landings/V2/SignatureDW/types";
import { withMaintenance } from "landings/V2/USDDW/components/Maintenance";
import { DWPayMethod } from "landings/V2/USDDW/types";
import { QuotaDirection } from "utils/complianceQuota";
import { useACHActivity } from "landings/V2/CircleACH/utils";

interface Props {}

/**
 * ACH内的出金，实际包装的Wire的出金组件
 */
const ACHWithdraw: React.FC<Props> = ({}) => {
    const { $st } = useTranslators();
    const activity = useACHActivity("withdraw");

    const [success, setSuccess] = useState(false);

    return (
        <PureWithdraw
            className="!max-w-[600px]"
            transferType={SignatureTransferType.ACH}
            title={!success ? <TitleCom title={$st("ach_withdraw_title")} /> : null}
            onResultChange={setSuccess}
            successDes={$st("ach_withdraw_success_des")}
        />
    );
};

export default withMaintenance(ACHWithdraw, { payMethod: DWPayMethod.WIRE, direction: QuotaDirection.WITHDRAW });
