import { createContext, useContext, useState } from "react";
import { PaymentInfo } from "./StripeAPI";

export const StripeContext = createContext<{
    amount?: string;
    coin?: string;
    paymentInfo?: PaymentInfo;
    setPaymentInfo?: React.Dispatch<React.SetStateAction<PaymentInfo | undefined>>;
}>({});
export function StripeProvider({ amount, coin, children }: { amount?: string; coin?: string; children?: JSX.Element }) {
    const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>();
    return (
        <StripeContext.Provider
            value={{
                amount,
                coin,
                paymentInfo,
                setPaymentInfo,
            }}
        >
            {children}
        </StripeContext.Provider>
    );
}
export function useStripeProvider() {
    return useContext(StripeContext);
}
