import { report as _report } from "utils/GoogleTagManager";

/**
 * 聚合所有数据上报
 */
class ReportProxy {
    /**
     * @deprecated Use {@link GoogleTagManager} instead.
     */
    report(eventName: string, data: object = {}) {
        _report(eventName, data as any);
    }
}

/**
 * 注册并初始化各个上报频道对象
 */
export const DataReporter = new ReportProxy();

export default DataReporter;
