import { Observable, Subject } from "rxjs";
import { multicast, refCount } from "rxjs/operators";

export enum EventType {
    ResizeKline,
    botOrderCurrentTab,
}

export interface SideBarEventInfo {
    type: EventType;
    value: string;
}

export class SideBarEvent {
    private constructor() {
        this.eventSubject = new Subject<SideBarEventInfo>();
    }

    private observable: Observable<SideBarEventInfo>;
    private eventSubject: Subject<SideBarEventInfo>;

    public static instance: SideBarEvent = new SideBarEvent();

    public sendCommand(command: SideBarEventInfo) {
        this.eventSubject.next(command);
    }

    public listenSideBar(): Observable<SideBarEventInfo> {
        if (!this.observable) {
            this.observable = this.eventSubject.pipe(
                multicast<any | undefined>(() => new Subject<any>()),
                refCount(),
            );
        }

        return this.observable;
    }
}
