import React, { useState } from "react";
import { TModal, TSpin, TSteps } from "components/v2/TinyComps";
import cs from "./cs.m.less";
import { useTranslators } from "commonUse/Locale";
import { Moment } from "moment";
import { uncamelize } from "utils/camelize";
import { encryptDataWithPublicKey } from "utils/openPgp";
import { useCircleDebitAPI } from "../context";
import { AddCardRes, DebitCardChannel } from "../types";
import Step1Form, { Step1FormData } from "./Step1Form";
import Step2Form from "./Step2Form";
import SparkMD5 from "spark-md5";
import { processAddCardError } from "./utils";
import { useOnlineConfig } from "utils/onlineConfig";
import get from "lodash/get";

const { Step } = TSteps;

export type Props = {
    onCancel();
    onResult(res: AddCardRes);
    loading?: boolean;
};

/**
 * circle-debit卡片添加弹窗
 */
const AddCircleCardModal: React.FC<Props> = ({ onCancel, onResult, loading }) => {
    const { $st } = useTranslators();
    const onLineConfig = useOnlineConfig();

    const circleDebitAPI = useCircleDebitAPI();

    const [step, setStep] = useState(0);
    const [step1Data, setStep1Data] = useState<Step1FormData>();
    return (
        <TModal className={cs.modal} visible width={650} footer={null} closable={!loading} onCancel={onCancel}>
            <TSpin tip={$st("debit_waiting_sumsub")} spinning={loading}>
                <div className={cs.title}>{$st("debit_add_card_title")}</div>
                <TSteps className={cs.steps} current={step}>
                    <Step />
                    <Step />
                </TSteps>

                {step === 0 && (
                    <Step1Form
                        initialValues={step1Data}
                        onNext={(cardInfo) => {
                            setStep1Data(cardInfo);
                            setStep(1);
                        }}
                    />
                )}
                {step === 1 && (
                    <Step2Form
                        onPre={() => {
                            setStep(0);
                        }}
                        onFinish={async (userInfo) => {
                            if (!circleDebitAPI || !step1Data) {
                                return;
                            }

                            const publicKeyInfo = await circleDebitAPI.getPublicKey(DebitCardChannel.CIRCLE).toPromise();

                            const { cvv, holderName } = step1Data;
                            const expireDate = step1Data.expireDate as Moment;
                            const cardNum = step1Data.cardNum.replace(/\s/g, "");
                            const expYear = expireDate.get("year");
                            const expMonth = expireDate.get("month") + 1;

                            const encryptedData = await encryptDataWithPublicKey(
                                {
                                    number: cardNum,
                                    cvv,
                                },
                                publicKeyInfo.publicKey,
                            );

                            const user_info = uncamelize({ ...userInfo });
                            delete user_info["address_line_1"];
                            delete user_info["address_line_2"];
                            user_info["address_line1"] = userInfo.addressLine1;
                            user_info["address_line2"] = userInfo.addressLine2;
                            user_info["name"] = holderName;

                            try {
                                const res = await circleDebitAPI
                                    .addCircleCard({
                                        card_info: {
                                            public_key_id: publicKeyInfo.keyId,
                                            encrypted_data: encryptedData.encryptedMessageWithBase64,
                                            exp_year: expYear,
                                            exp_month: expMonth,
                                            card_number: SparkMD5.hash(cardNum),
                                        },
                                        user_info: user_info,
                                    })
                                    .toPromise();
                                onResult(res);
                            } catch (e) {
                                processAddCardError($st, e, get(onLineConfig, "errorMap.debit_card_failure", {}));
                            }
                        }}
                    />
                )}
            </TSpin>
        </TModal>
    );
};

export default AddCircleCardModal;
