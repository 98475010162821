import React from "react";
import { TSelect, TSelectProps } from "components/v2/TinyComps";

const handleFilterOption = (input, option) => {
    // 通过Option上增加额外的label属性直接过滤
    return option.extraLabel.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

type Option = { value: string; label: string };
type Props = Omit<TSelectProps<Option["value"]>, "showSearch" | "filterOption" | "options"> & { options?: Option[] };

/**
 * 只显示简单文本带搜索的Select
 */
const SingleSearchSelect: React.FC<Props> = ({ options, ...resetProps }) => {
    return (
        <TSelect {...resetProps} showSearch filterOption={handleFilterOption}>
            {(options || []).map((item) => (
                <TSelect.Option
                    key={item.value}
                    value={item.value}
                    extraLabel={item.label} //过滤使用
                >
                    {item.label}
                </TSelect.Option>
            ))}
        </TSelect>
    );
};

export default SingleSearchSelect;
