import { SupportDWCoinInfo } from "TradeAPILib";
import { useTranslators } from "commonUse/Locale";
import { DWType } from "landings/DepositWithdraw";

/**
 * 特殊的提示文案
 */
export function useSpecialTips(dwType: DWType, coin: SupportDWCoinInfo): string | undefined {
    const { $st } = useTranslators();

    if (dwType !== DWType.deposit) {
        return undefined;
    }

    if (coin.coinName === "USDT" && coin.protocol === "TRC20") {
        return $st("dw_tips_not_support_contract", { coin: coin.coinName });
    }

    if ((coin.coinName === "TRX" && coin.protocol === "TRC20") || (coin.coinName === "DOT" && coin.protocol === "MAINNET")) {
        return $st("dw_tips_not_support_contract_lose");
    }

    return undefined;
}
