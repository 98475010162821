import React, { cloneElement } from "react";
import useAccountInfo from "commonUse/useAccountInfo";
import useKycV2Info, { KycV2Level, KycV2LevelStatus } from "commonUse/useKycInfoV2";
import { useKycV2TradeKycModal } from "commonUse/useKycRightsV2";
import { showLoginDialog } from "components/LoginDialog";
import { useTranslators } from "commonUse/Locale";

interface Props {
    children: JSX.Element;
}

/**
 * 拦截未登录和未做kyc的点击事件
 */
const KycLoginClick: React.FC<Props> = ({ children }) => {
    const { $st } = useTranslators();

    const props: object = {};

    const { login, kyc } = usePassedKycLogin();

    const { tradeKycModal } = useKycV2TradeKycModal();

    if (!login || !kyc) {
        props["onClick"] = (e) => {
            e?.preventDefault();
            e?.stopPropagation();

            if (!login) {
                showLoginDialog($st("buy_crypto_sign"), { redirectCurrent: true });
                return;
            }

            if (!kyc) {
                tradeKycModal();
                return;
            }
        };
    }

    return cloneElement(children, props);
};

export default KycLoginClick;

/**
 * kyc和登录状态是否通过
 */
export function usePassedKycLogin() {
    const { userId } = useAccountInfo();
    const [{ currentLevel }] = useKycV2Info();

    return {
        login: !!userId,
        kyc: currentLevel === KycV2Level.lv2,
    };
}
