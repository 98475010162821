import { createAction } from "redux-actions";
import { getLocalMessage, LANGUAGE_LOCALS_MAP } from "utils/i18n";
import { DateFormatterType, setDateFormatterType } from "state/DateFormatter";

const PREFIX = "COMMON_ACTIONS_";

export const GET_LANGUAGE_DATA = `${PREFIX}GET_LANGUAGE_DATA`;
export const REVERSE_RED_GREEN = `${PREFIX}REVERSE_RED_GREEN`;
export const CHANGE_LANGUAGE_LOCALE = `${PREFIX}CHANGE_LANGUAGE_LOCALE`;
export const LOGIN = `${PREFIX}LOGIN`;
export const LOGOUT = `${PREFIX}LOGOUT`;
export const GET_USERINFO_BY_TOKEN = `${PREFIX}GET_USERINFO_BY_TOKEN`;
export const GET_ENVIRONMENT = `${PREFIX}GET_ENVIRONMENT`;
export const GET_NOTICE = `${PREFIX}GET_NOTICE`;
export const RESET_REDUCER = `${PREFIX}RESET_REDUCER`;
export const FAST_LOGIN = `${PREFIX}FAST_LOGIN`;

// 切换语言，从本地更新环境语言包【开发临时使用】
export const changeLang = createAction(GET_LANGUAGE_DATA, (locale: LANGUAGE_LOCALS_MAP) => {
    if (locale.startsWith("zh")) {
        setDateFormatterType(DateFormatterType.ymd);
    } else {
        setDateFormatterType(DateFormatterType.mdy);
    }
    return getLocalMessage(locale);
});
