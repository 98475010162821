import { usePersistFn } from "@gcer/react-air";
import { useDataProvider } from "commonUse";
import useAccountInfo from "commonUse/useAccountInfo";
import { AxiosV1Token, useV1TradingToken } from "commonUse/useV1Token";
import {
    botOrderDataRequest,
    getWebSocketMarketBotOrder,
    mergeObject,
    OrderListStatus,
    OrderPairType,
    OrderTypeForFilter,
} from "landings/PionexHistoricalOrderDetails/borderOrderHelper";
import { OrderCurrentTickerObserve } from "landings/Trade/Order/TradingBotTable/botOrderHelper";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { BehaviorSubject, from, Subscription } from "rxjs";
import { startWith, take, throttleTime } from "rxjs/operators";
import { getTradeDependencyInstance } from "trade_utils_lib";
import { ExchangeID, ExchangeOrder, ExchangeOrderListInfo, ExchangeOrderStatus, ExchangeOrderType, ExchangeTicker, ExchangeTradePair, TradeUtils } from "TradeAPILib";
import ExchangeDataProvider from "TradeLib/ExchangeDataProvider";

export const FilterGridType = [ExchangeOrderType.gridV3, ExchangeOrderType.grid, ExchangeOrderType.gridPro, ExchangeOrderType.gridClassic, ExchangeOrderType.gridV4];
export const FilterAllType = [
    ExchangeOrderType.gridV3,
    ExchangeOrderType.SR,
    ExchangeOrderType.ig,
    ExchangeOrderType.gridLeverage,
    ExchangeOrderType.gridLoan,
    ExchangeOrderType.gridShort,
    ExchangeOrderType.gridLeverageShort,
    ExchangeOrderType.smartTrade,
    ExchangeOrderType.twap,
    ExchangeOrderType.aip,
    ExchangeOrderType.martingale,
    ExchangeOrderType.SC,
    ExchangeOrderType.fsa,
    ExchangeOrderType.SL_Pro,
    ExchangeOrderType.gridPro,
    ExchangeOrderType.gridV4,
];

function calcOrderProfit(order: ExchangeOrder, tickerMap: Map<string, ExchangeTicker>) {
    const ticker = tickerMap.get(`${order.exchange}/${order.base}/${order.quote}`);
    const innerOrder = TradeUtils.getInnerOrder(order);
    const swapTicker = tickerMap.get(`${innerOrder.swapExchange}/${innerOrder.swapBase}/${innerOrder.swapQuote}`);

    let basesTicker: ExchangeTicker[] = [];
    (order.exchangeSROrder || order.exchangeMTGOrder || order.exchangeSCOrder)?.coins.forEach((item1) => {
        const tempTicker = tickerMap.get(`${order.exchange}/${item1.base}/${order.quote}`);
        if (tempTicker !== undefined) {
            basesTicker.push(tempTicker);
        }
    });
    if (order.isRunning) {
        return TradeUtils.calcGridOrderProfit(cloneDeep(order), ticker ? Number(ticker.latest) : 0, swapTicker ? Number(swapTicker.latest) : 0, basesTicker);
    } else {
        if (order.orderType === ExchangeOrderType.fsa || order.orderType === ExchangeOrderType.cm_fsa) {
            return TradeUtils.calcGridOrderProfit(cloneDeep(order), ticker ? Number(ticker.latest) : 0, swapTicker ? Number(swapTicker.latest) : 0, basesTicker);
        } else {
            let price = 0;
            if (order.orderType === ExchangeOrderType.aip || order.orderType === ExchangeOrderType.grid) {
                price = ticker ? Number(ticker.latest) : 0;
            } else {
                price = innerOrder.closedPrice;
            }
            return TradeUtils.calcGridOrderProfit(cloneDeep(order), price, undefined, basesTicker);
        }
    }
}

export default function useFetchOrders({
    status,
    orderType,
    symbolPair,
    isWatchNewOrder = false,
}: {
    status: OrderListStatus;
    type: OrderPairType;
    orderType: OrderTypeForFilter | ExchangeOrderType | "all";
    symbolPair?: ExchangeTradePair;
    isWatchNewOrder?: boolean;
}) {
    const v1Token = useV1TradingToken();
    const accountInfo = useAccountInfo();
    const dataProvider = useDataProvider(accountInfo.firstApiKey);
    const dataProviderRef = useRef(dataProvider);
    useEffect(() => {
        dataProviderRef.current = dataProvider;
    }, [dataProvider]);
    const orderSubjectRef = useRef(
        new BehaviorSubject<
            ExchangeOrderListInfo & {
                tickerMap: Map<string, ExchangeTicker>;
            }
        >({
            tickerMap: new Map<string, ExchangeTicker>(),
            orders: [],
            nextPageToken: undefined as unknown as string,
        }),
    );
    const tickerMapRef = useRef<Map<string, ExchangeTicker>>(new Map<string, ExchangeTicker>());
    const subRef = useRef<Subscription[]>([]);
    const [loading, setLoading] = useState<boolean>();
    const [resetLoading, setResetLoading] = useState<boolean>();
    const observeRef = useRef<Subscription>();
    const subSymbolSetRef = useRef(new Set<string>());
    const fetch = usePersistFn((reset?: boolean) => {
        if (!v1Token) return;
        setLoading(true);
        observeRef.current?.unsubscribe();
        observeRef.current = undefined;
        if (reset) {
            setResetLoading(true);
            orderSubjectRef.current.next({
                tickerMap: new Map<string, ExchangeTicker>(),
                orders: [],
                nextPageToken: undefined as unknown as string,
            });
        }

        let currentOrdersInfo = orderSubjectRef.current.getValue();
        let orderTypes: ExchangeOrderType[] = [];
        if (orderType === "all" || !orderType) {
            orderTypes = FilterAllType;
        } else if (orderType === ExchangeOrderType.gridV3) {
            orderTypes = FilterGridType;
        } else if (Array.isArray(orderType)) {
            orderTypes = orderType;
        } else {
            orderTypes = [orderType];
        }

        // let tempObservable = botOrderDataRequest(dataProvider?.api!, false, status, symbolPair, reset ? undefined : currentOrdersInfo.nextPageToken!, orderTypes);
        let tempObservable = from(
            AxiosV1Token(
                {
                    url: `${getTradeDependencyInstance().getRunTimeHost().TRADE_HOST}/orders/v2/`,
                    params: {
                        bu_order_types: FilterAllType.toString(),
                        exchange: dataProvider?.api?.market,
                        status: "finished",
                    },
                },
                v1Token?.awsToken,
            ).then((res) => {
                const data = res.data;
                return {
                    nextPageToken: data.data.next_page_token,
                    orders: data.data.results
                        .map((item) => {
                            return TradeUtils.convertToExchangeOrder(item);
                        })
                        .filter((item1) => item1 !== undefined),
                } as ExchangeOrderListInfo;
            }),
        );
        observeRef.current = tempObservable.subscribe(
            (result) => {
                currentOrdersInfo = orderSubjectRef.current.getValue();
                let nextValue: typeof currentOrdersInfo;
                nextValue = {
                    nextPageToken: result.nextPageToken,
                    orders: [...currentOrdersInfo.orders, ...result.orders],
                    tickerMap: currentOrdersInfo.tickerMap,
                };
                const set = new Set<string>();
                const ls: ExchangeOrder[] = [];
                for (let order of nextValue.orders) {
                    if (!set.has(order.id)) {
                        ls.push(order);
                        set.add(order.id);
                    }
                }
                nextValue.orders = ls;
                if (status === OrderListStatus.running) {
                    nextValue.orders.sort((a, b) => b.timestamp - a.timestamp);
                }
                subSymbolSetRef.current = calcOrderSymbols(nextValue.orders);
                orderSubjectRef.current.next(nextValue);
            },
            (e) => {
                console.log(e);
            },
            () => {
                setResetLoading(false);
                setLoading(false);
            },
        );
    });

    const getOrdersObserve = useCallback(() => {
        if (status === OrderListStatus.running) {
            const sub = getWebSocketMarketBotOrder(() => ExchangeDataProvider.accountWebSocket.getBotOrderObservable(), dataProviderRef.current?.api.getInnerApiKeyInfo().market!)
                .pipe(startWith(null))
                .subscribe(async (newerOrder) => {
                    const currentOrderInfo = orderSubjectRef.current.getValue();
                    const { orders } = currentOrderInfo;
                    const nOrders = [...orders];
                    if (newerOrder) {
                        const tempExchangeOrder: ExchangeOrder = JSON.parse(JSON.stringify(newerOrder));
                        const findIndex = orders.findIndex((obj) => {
                            return obj.id === tempExchangeOrder.id;
                        });
                        if (findIndex !== -1) {
                            if (newerOrder.state === ExchangeOrderStatus.canceled) {
                                nOrders.splice(findIndex, 1);
                            } else {
                                const replacedOrder = orders[findIndex];

                                const nOrder = calcOrderProfit(
                                    TradeUtils.convertToExchangeOrder(mergeObject({ ...replacedOrder.originOrderData }, { ...tempExchangeOrder.originOrderData }))!,
                                    tickerMapRef.current,
                                );

                                nOrders.splice(findIndex, 1, nOrder);
                            }
                            orderSubjectRef.current.next({
                                ...currentOrderInfo,
                                orders: nOrders,
                                tickerMap: tickerMapRef.current,
                            });
                        } else if (isWatchNewOrder) {
                            let rOrder = newerOrder;
                            if (!newerOrder.timestamp) {
                                const result = await dataProviderRef.current?.api.getQueryGridBasicInfoObservable(newerOrder.id!, newerOrder.orderType).pipe(take(1)).toPromise();
                                if (result?.gridOrder) {
                                    rOrder = result?.gridOrder;
                                }
                            }
                            if (rOrder.timestamp > (nOrders[0]?.timestamp || 0)) {
                                const currentValue = orderSubjectRef.current.getValue();
                                if (!currentValue.orders.find((item) => item.id === rOrder.id)) {
                                    orderSubjectRef.current.next({
                                        ...currentValue,
                                        orders: [rOrder, ...currentValue.orders].sort((a, b) => b.timestamp - a.timestamp),
                                        tickerMap: tickerMapRef.current,
                                    });
                                }
                            }
                        }
                    }
                });

            subRef.current.push(sub);
        }

        const tickerOb = ExchangeDataProvider.websocketManager.getTickerWebSocket().getTickerObservableByExs([ExchangeID.PIONEXV2, ExchangeID.PIONEX_SWAP]);

        const sub1 = tickerOb.pipe(throttleTime(500)).subscribe((tickerMap) => {
            // FIXME: 用于订阅已下架但订单中仍然存在的币对
            const ls: string[] = [];
            subSymbolSetRef.current.forEach((symbol) => {
                if (!tickerMap.get(symbol)) {
                    ls.push(symbol);
                }
            });
            if (ls.length > 0) {
                const sub = OrderCurrentTickerObserve(
                    accountInfo.firstApiKey!.exchange,
                    ls.map((i) => {
                        const [base, quote] = i.split("/");
                        return {
                            quote,
                            base,
                            market: ExchangeID.PIONEXV2,
                        };
                    }),
                ).subscribe();
                sub.unsubscribe();
            }

            const currentOrderInfo = orderSubjectRef.current.getValue();
            const { orders } = currentOrderInfo;
            const nOrders = [...orders];
            nOrders.forEach((order, index) => {
                const nOrder = calcOrderProfit(order, tickerMap);
                if (nOrder) {
                    nOrders[index] = nOrder;
                }
            });
            tickerMapRef.current = tickerMap;
            orderSubjectRef.current.next({
                ...currentOrderInfo,
                orders: nOrders,
                tickerMap,
            });
        });
        subRef.current.push(sub1);
        return orderSubjectRef.current;
    }, []);

    const updateOrder = usePersistFn(async (order: Partial<ExchangeOrder>) => {
        const result = await dataProvider?.api?.apiAdapter.getQueryGridBasicInfoObservable(order.id!, order.orderType).pipe(take(1)).toPromise();
        const { gridOrder } = result!;
        const currentValue = orderSubjectRef.current.getValue();
        orderSubjectRef.current.next({
            ...currentValue,
            orders: currentValue.orders.map((item) => (item.id === order.id ? gridOrder : item)),
        });
    });

    useEffect(() => {
        return () => {
            orderSubjectRef.current.complete();
            observeRef.current?.unsubscribe();
            subRef.current.map((sub) => sub.unsubscribe());
            subRef.current = [];
        };
    }, []);

    return {
        getOrdersObserve,
        updateOrder,
        loading,
        resetLoading,
        fetch,
    };
}

function calcOrderSymbols(list) {
    const set = new Set<string>();
    list.forEach((item) => {
        if (item.orderType === ExchangeOrderType.market || item.orderType === ExchangeOrderType.limit) {
        } else if (item.orderType === ExchangeOrderType.SR || item.orderType === ExchangeOrderType.martingale || item.orderType === ExchangeOrderType.SC) {
            let coins: { base: string }[] | undefined;
            if (item.orderType === ExchangeOrderType.SR) {
                coins = item.exchangeSROrder?.coins;
            } else if (item.orderType === ExchangeOrderType.martingale) {
                coins = item.exchangeMTGOrder?.coins;
            } else if (item.orderType === ExchangeOrderType.SC) {
                coins = item.exchangeSCOrder?.coins;
            }

            coins?.forEach((item1) => {
                set.add(`${item1.base}/${item.quote}`);
            });
        } else {
            set.add(`${item.base}/${item.quote}`);
        }
        if (item.orderType === ExchangeOrderType.fsa && item.exchangeFSAOrder) {
            set.add(`${item.exchangeFSAOrder.swapBase}/${item.exchangeFSAOrder.swapQuote}`);
        }
    });
    return set;
}
