export * from "./withdraw";

/**
 * 统一整理错误码的位置
 */
export enum ErrorCode {
    ACCESS_TOKEN_EXPIRED_OLD = -16202,
    REFRESH_TOKEN_EXPIRED_OLD = -20202,
    ACCESS_TOKEN_EXPIRED = -109916,
    REFRESH_TOKEN_EXPIRED = -109917,

    INVALID_PARAMS = -109901,
}

export namespace ErrorCodeUtils {
    /**
     * 是否登录过期的错误码
     * @param code
     */
    export function isSignExpiredCode(code: number) {
        return [ErrorCode.ACCESS_TOKEN_EXPIRED_OLD, ErrorCode.REFRESH_TOKEN_EXPIRED_OLD, ErrorCode.ACCESS_TOKEN_EXPIRED, ErrorCode.REFRESH_TOKEN_EXPIRED].includes(code);
    }
}
