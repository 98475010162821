/**
 * @deprecated LayoutConfig will support this feature soon.
 * @param url
 */
const canonicalCreator = (url: string) => {
    const linkEle = document.createElement("link");
    linkEle.setAttribute("rel", "canonical");
    linkEle.setAttribute("href", url);
    const bodyEle = document.getElementsByTagName("head")[0];
    // remove old canonical
    const oldCanonical = document.getElementById("canonical");
    oldCanonical && oldCanonical.remove && oldCanonical.remove();
    linkEle.setAttribute("id", "canonical");
    bodyEle.append(linkEle);
};

export default canonicalCreator;
