import useTheme from "commonUse/useTheme";
import { PionexReport } from "landings/Analytics/Report";
import { useDWPageContext } from "landings/DepositWithdraw";
import { IDepositAddressProps } from "landings/DepositWithdraw/components/CoinSelect/components/DepositAddress/index";
import QrCode from "qrcode.react";
import React, { useMemo, useRef } from "react";
import ClipBoard from "react-copy-to-clipboard";
import { $st } from "src/utils/i18n";
import cs from "../../../../withdraw.module.less";
import { DepositLayerType } from "../../../Address";
import { getTagDisplay, queryDWLayerType } from "./AttentionView";
import { getCurrencyDisplayName } from "landings/V2/Address/common";
import { TPopover } from "src/components/v2/TinyComps";
import { Button, message } from "@pionex-web-kit/components";
import { IconSafeguard100pxRegular } from "@pionex-web-kit/icons";

export function CustomAddressView({ coinAddressInfo, errorMsg }: IDepositAddressProps) {
    const theme = useTheme();
    const rootRef = useRef(null);
    const { currentCoin } = useDWPageContext();
    React.useEffect(() => {
        if (errorMsg) {
            message.error(errorMsg);
        }
    }, [errorMsg]);

    const isMemoAddress = useMemo(() => {
        return queryDWLayerType(coinAddressInfo) === DepositLayerType.memo;
    }, [coinAddressInfo]);

    const addressQRView = useMemo(() => {
        if (coinAddressInfo && coinAddressInfo.address) {
            return (
                <div className={!currentCoin.deposit_enabled ? cs.hideQRWrapper : cs.QRWrapper}>
                    <QrCode value={coinAddressInfo.address} size={140} includeMargin={false} />
                </div>
            );
        }
        return null;
    }, [currentCoin, coinAddressInfo]);
    const memoName = useMemo(() => getTagDisplay(coinAddressInfo), [coinAddressInfo]);
    return (
        <>
            <div className={cs.customAddress} ref={rootRef}>
                <div className={cs.tipFont} style={{ fontSize: 16 }}>
                    {getCurrencyDisplayName(currentCoin) + $st("deposit.address")}:
                </div>
                {coinAddressInfo.address ? (
                    <>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <span style={{ fontSize: 16, fontWeight: "bold", color: theme.colorAccent, marginRight: 10, lineBreak: "anywhere", marginTop: 5 }}>
                                    {coinAddressInfo.address}
                                </span>

                                <TPopover placement="bottom" content={addressQRView} getPopupContainer={() => rootRef.current!} trigger={"hover"}>
                                    <img src={require("images/ic_qrcode.png")} alt={""} style={{ width: 15, height: 15, marginRight: 20, cursor: "pointer" }} />
                                </TPopover>
                            </div>

                            <ClipBoard
                                text={coinAddressInfo ? coinAddressInfo.address : ""}
                                onCopy={() => {
                                    onCopySuccess();
                                    PionexReport.reportDepositInfo(currentCoin.currency, "deposit", 2);
                                }}
                            >
                                <Button className={cs.copyBtn}>{$st("deposit.address.copy")}</Button>
                            </ClipBoard>
                        </div>
                        {isMemoAddress ? (
                            <div style={{ marginTop: 10 }}>
                                <div className={cs.tipFont}>{getTagDisplay(coinAddressInfo)}:</div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    <span style={{ fontSize: 16, fontWeight: "bold", color: theme.colorAccent, marginRight: 10 }}>{coinAddressInfo.tag}</span>

                                    <ClipBoard
                                        text={coinAddressInfo ? coinAddressInfo.tag! : ""}
                                        onCopy={() => {
                                            onCopySuccess();
                                            PionexReport.reportDepositInfo(currentCoin.currency, "deposit", 2);
                                        }}
                                    >
                                        <Button className={cs.copyBtn}>{$st("deposit.address.copy.memo", { memoName })}</Button>
                                    </ClipBoard>
                                </div>
                            </div>
                        ) : null}
                    </>
                ) : (
                    <>
                        <div className="flex flex-col items-center">
                            <div>
                                <IconSafeguard100pxRegular size={100} />
                            </div>
                            <div>Due to restrictions on your account, certain features are currently unavailable.</div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

function onCopySuccess() {
    message.success($st("tip_copy_success"));
}
