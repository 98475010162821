import React from "react";
import { WrapperColumn, H2, H4 } from "../StyleComponent";

export const DEFAULT_AVATAR = "pionex_app_default.png";

// todo 从环境里注入
const avatarLocationOrigin = window.location.hostname === "localhost" ? "https://avatar.pionex.us/" : "https://avatar.pionex.us/";

const ShareUserInfo = (props) => {
    const { nickname, avatar, title } = props;
    return (
        <WrapperColumn style={{ marginTop: 20 }}>
            <img style={{ width: "4.5rem", height: "4.5rem", borderRadius: "50%" }} src={`${avatarLocationOrigin}${avatar || DEFAULT_AVATAR}`} alt={"avatar"} />
            <H2 style={{ marginTop: 12 }}>{nickname}</H2>
            <H4 style={{ margin: "2px 20px 0" }}>{title}</H4>
        </WrapperColumn>
    );
};

export default ShareUserInfo;
