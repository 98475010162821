import Constants from "utils/Constants";

export class HostConfigV1 {
    static get accountServiceHost() {
        return "/account";
    }

    static get tickerHost() {
        return "/portfolio";
    }

    static get kLineHost() {
        return "/kline";
    }

    static get coinCapHost() {
        return "/coincap";
    }

    static get tradeServiceHost() {
        return "/strategy/v1.8";
    }

    static get iconServerHost() {
        return "/res";
    }

    static get tradeRPCHost() {
        return "/api";
    }

    static get tradeRPCSignHost() {
        if (process.env.NODE_ENV === "development") {
            return Constants.isBeta ? "https://www.pionexusdev.com/api" : "https://v1.pionex.us/api";
        }
        return `${Constants.origin}/api`;
    }

    static get feedHost() {
        return "/feed/v1.5/feed";
    }

    static get tradeFundHost() {
        return "/fund";
    }

    static get configHost() {
        if (process.env.NODE_ENV === "development") {
            return "/config";
        }
        return "https://config.pionex.us";
    }

    static get avatarHost() {
        if (Constants.isBeta) {
            return "https://avatar.pionexdev.com";
        }
        return "https://avatar.pionex.us";
    }

    static get defaultAvatar() {
        return require("src/assets/avatar-default.png");
    }

    static get webSocketHost(): string {
        if (Constants.isBeta) {
            // 美国站的测试行情使用此服务
            return "wss://stream.pionexusdev.com";
        }
        return "wss://stream-v1.pionex.us";
    }

    static get accountWebSocketHost() {
        if (Constants.isBeta) {
            return "wss://user-websocket.bitudev.com";
        }
        return this.webSocketHost;
    }

    static get publicApiHost() {
        return "/apis/papi/v1";
    }

    static get futureApiHost() {
        return "/apis/fapi/v1";
    }

    static get futureApiHostV2() {
        return "/apis/fapi/v2";
    }

    static get manageApiHost() {
        return "/apis/mapi/v1";
    }

    static get pionexSpotApiHost() {
        return "/apis/sapi/v1";
    }

    static get walletApiHost() {
        return "/wallet-api/v1";
    }

    static get balanceApiHost() {
        return "/apis/wapi/v1";
    }

    static get balanceApiHostV2() {
        return "/apis/wapi/v2";
    }

    static get brokerApiHost() {
        return "/apis/bapi/v1";
    }

    static get contestHost() {
        return "/contest";
    }

    static get defiHost() {
        return "/defi";
    }

    static get rebateHost() {
        return "/rebate";
    }

    static get messageCenter() {
        return "/kol-apis";
    }

    static get kolFHost() {
        return "/kol-apis/fapi/v1";
    }

    static get kolTHost() {
        return "/kol-apis/tapi/v1";
    }

    static get collectApiHost() {
        return "/apis/collect-api/v1/collect";
    }
}

const HostConfig = PLATFORM.PIONEX_US_V2 ? require("./HostConfig.v2").default : HostConfigV1;

export default HostConfig;
