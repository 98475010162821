import CircleDebitWrapper from "landings/V2/CircleDebit";
import DebitDeposit from "landings/V2/CircleDebit/Deposit";
import DebitHistory from "landings/V2/CircleDebit/History";
import SignatureDeposit from "landings/V2/SignatureDW/Deposit";
import SignatureWithdraw from "landings/V2/SignatureDW/Withdraw";
import SignatureHistory from "landings/V2/SignatureDW/History";
import { useTranslators } from "commonUse/Locale";
import React, { ComponentType, useMemo } from "react";
import { Iconfont } from "components/v2/TinyComps";
import ACHDeposit from "landings/V2/CircleACH";
import ACHWithdraw from "landings/V2/CircleACH/Withdraw";
import ACHHistory from "landings/V2/CircleACH/Histories";
import { IconMenuAch } from "@pionex-web-kit/icons";
import { MenuNode } from "@pionex-web-kit/pro-components";
import { NestedRoute } from "utils/commons";
import { useACHActivity } from "../CircleACH/utils";
import StripeAmount from "../BuyCrypto/StripeACH/StripeAmount";

export const USD_DW_ROUTE_V2 = NestedRoute.CompleteTree({
    _: "usdDW",
    WIRE: {
        _: "wire",
        DEPOSIT: "deposit",
        WITHDRAW: "withdraw",
        HISTORY: "history",
    },
    DEBIT: {
        _: "debit",
        DEPOSIT: "deposit",
        HISTORY: "history",
    },
    ACH: {
        _: "ach",
        DEPOSIT: "deposit",
        WITHDRAW: "withdraw",
        HISTORY: "history",
    },
});

/**
 * @deprecated Use {@link USD_DW_ROUTE_V2} instead.
 */
export namespace USD_DW_ROUTE {
    export const USD_DW = `/usdDW`;
    export const SIGNATURE = `${USD_DW}/signature`;
    export const SIGNATURE_DEPOSIT = `${SIGNATURE}/deposit`;
    export const SIGNATURE_WITHDRAW = `${SIGNATURE}/withdraw`;
    export const SIGNATURE_HISTORY = `${SIGNATURE}/history`;
}

type PureMenu = Omit<MenuNode, "children"> & { component?: ComponentType; path?: string; o_path?: string };
type Menu = PureMenu & { children: PureMenu[] };

/**
 * 美元出入金相关menu和路由配置
 */
export function useMenus(): Menu[] {
    const { $st } = useTranslators();
    const activity = useACHActivity("deposit");
    return useMemo<any>(
        () => [
            {
                key: USD_DW_ROUTE_V2.ACH._,
                path: USD_DW_ROUTE_V2.ACH._,
                name: $st("nav_title_usd_deposit_ach"),
                icon: <Iconfont icon={"icon_BankTransfer"} className="!text-xl" />,
                children: [
                    {
                        path: USD_DW_ROUTE_V2.ACH.DEPOSIT,
                        name: (
                            <div>
                                {$st("circledw_deposit")} <img className="h-[16px]" src={activity?.image} alt={"activity"} />
                            </div>
                        ),
                        component: StripeAmount,
                    },
                    {
                        path: USD_DW_ROUTE_V2.ACH.WITHDRAW,
                        name: $st("circledw_withdraw"),
                        component: ACHWithdraw,
                    },
                    {
                        path: USD_DW_ROUTE_V2.ACH.HISTORY,
                        name: $st("circledw_history"),
                        component: ACHHistory,
                    },
                ],
            },
            {
                key: USD_DW_ROUTE_V2.DEBIT._,
                path: USD_DW_ROUTE_V2.DEBIT._,
                name: $st("nav_title_usd_deposit_debit"),
                icon: <Iconfont icon={"icon_Menu_BankCard"} className="!text-xl" />,
                component: CircleDebitWrapper,
                children: [
                    {
                        path: USD_DW_ROUTE_V2.DEBIT.DEPOSIT,
                        name: $st("circledw_deposit"),
                        component: DebitDeposit,
                    },
                    {
                        path: USD_DW_ROUTE_V2.DEBIT.HISTORY,
                        name: $st("circledw_history"),
                        component: DebitHistory,
                    },
                ],
            },
            {
                key: USD_DW_ROUTE_V2.WIRE._,
                name: $st("nav_title_usd_deposit_transfer"),
                icon: <IconMenuAch size={20} />,
                children: [
                    {
                        path: USD_DW_ROUTE_V2.WIRE.DEPOSIT,
                        o_path: USD_DW_ROUTE.SIGNATURE_DEPOSIT,
                        name: $st("circledw_deposit"),
                        component: SignatureDeposit,
                    },
                    {
                        path: USD_DW_ROUTE_V2.WIRE.WITHDRAW,
                        o_path: USD_DW_ROUTE.SIGNATURE_WITHDRAW,
                        name: $st("circledw_withdraw"),
                        component: SignatureWithdraw,
                    },
                    {
                        path: USD_DW_ROUTE_V2.WIRE.HISTORY,
                        // 旧链接
                        o_path: USD_DW_ROUTE.SIGNATURE_HISTORY,
                        name: $st("circledw_history"),
                        component: SignatureHistory,
                    },
                ],
            },
        ],
        [$st],
    );
}
