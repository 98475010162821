import AccountInfo from "state/AccountInfo";
import Constants, { BusinessVersion } from "./Constants";

export const toBusinessWeb = (info?: AccountInfo) => {
    // correctHostByVersion(info?.app as BusinessVersion);
};

/**
 * 根据需要的系统版本纠正域名
 * @param version
 */
export function correctHostByVersion(version?: BusinessVersion) {
    const isSame = PLATFORM._CODE === version;
    if (version && !isSame) {
        window.location.hostname = Constants.appDomain[version];
    }
}
