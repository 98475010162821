import React, { createContext, useContext, useEffect, useCallback, useMemo, useState } from "react";
import { useMixinCoinFullName } from "src/commonUse";
import { RouteProps } from "src/utils/type";
import { ExchangeID, SupportDWCoinInfo } from "TradeAPILib";
import { AssetAddressItem, getWhiteListStatus, commonErrorParser } from "landings/V2/Address/api";
import { WhiteListStatus } from "trade_asset_lib";
import { OtpState } from "src/state/AccountInfo";
import { useLocation } from "react-router";
import { useDWCoins } from "src/commonUse/useDW";
import { Button, Checkbox } from "antd";
import { useTranslators } from "commonUse/Locale";
import useAccountInfo from "commonUse/useAccountInfo";
import useDateFormatter from "commonUse/useDateFormatter";
import { AddressView } from "landings/DepositWithdraw/components/Address";
import { CoinSelect } from "landings/DepositWithdraw/components/CoinSelect";
import { HistoryTable } from "landings/DepositWithdraw/components/DWHistory";
import cs from "landings/DepositWithdraw/withdraw.module.less";
import { history } from "utils/History";
import { useConfirmCheckModal } from "src/commonUse/useComp";
import TReportLink, { TMaskLoading, TSpin } from "src/components/v2/TinyComps";
import { LANGUAGE_LOCALS_MAP } from "src/utils/i18n";
import { useKycV2DailyWithdrawLeft, useKycV2Rights } from "commonUse/useKycRightsV2";
import { getCurrencyDisplayName } from "landings/V2/Address/common";
import { useCheckShowForcedSSN } from "landings/V2/Account/paths/Kyc-v2/components/SSN";
import DataReporter from "landings/Analytics/DataReporter";
import { KYC_EVENT_NAME } from "landings/Analytics/EventName";
import Warning from "landings/DepositWithdraw/components/Warning";
import { BasicLayout } from "@pionex-web-kit/pro-components";
import { useAddressBookItems } from "landings/V2/Address/hooks";
import { useTwoStepState } from "components/TwoStepVerifyModal";

export enum DWType {
    deposit = "deposit",
    withdraw = "withdraw",
}
interface IMRoutMatch {
    type: DWType;
    coin: string;
}

export interface IDepositWithdrawProps extends RouteProps<IMRoutMatch> {}
export interface IAddressInitialData {
    currency: string;
    coin: string;
    chain?: string;
    address: string;
    address_id?: string;
    memo?: string;
    placeholder?: string;
}
export interface DWPageCtxData {
    coinList: Array<SupportDWCoinInfo>;
    currentCoin: SupportDWCoinInfo;
    changeCurrentCoin: (coin: SupportDWCoinInfo) => void;
    addressInitialData?: IAddressInitialData;
    changeAddressInitialData: (item: IAddressInitialData | undefined) => void;
    noTwoFactor: boolean;
    onlyWhiteList: WhiteListStatus;
    addressListLoading: boolean;
    addressListLoaded: boolean;
    addressList: AssetAddressItem[];
    reFreshAddressList: () => void;
    dailyWithdrawLeft: number;
    refreshDailyWithdrawLeft: () => void;
}

const DWPageContext = createContext<DWPageCtxData>({
    coinList: [],
    currentCoin: {} as any,
    changeCurrentCoin: () => {},
    changeAddressInitialData: () => {},
    onlyWhiteList: WhiteListStatus.on,
    noTwoFactor: false,
    addressListLoading: false,
    addressListLoaded: false,
    addressList: [],
    reFreshAddressList: () => {},
    dailyWithdrawLeft: 0,
    refreshDailyWithdrawLeft: () => {},
});

export function useDWPageContext() {
    return useContext(DWPageContext);
}

export default function DepositWithdraw({ type: tabActiveKey, coin: coinType = "USDTERC20" }: { type: DWType; coin: string }) {
    const { state } = useLocation() as { state: { addressInitialData: IAddressInitialData } };
    const [currentCoin, changeCurrentCoin] = useState<SupportDWCoinInfo>();
    const [addressInitialData, changeAddressInitialData] = useState<IAddressInitialData>();
    const [coinList, loading] = useDWCoins();
    const accountInfo = useAccountInfo();
    const [onlyWhiteList, setOnlyWhiteList] = useState(WhiteListStatus.off);
    const { noTwoFactor } = useTwoStepState();
    const { addressBookItems, refreshAddressBookItems } = useAddressBookItems({ coin: currentCoin?.coinName, needCoin: true });
    const { $st } = useTranslators();
    const dateFormatter = useDateFormatter();
    const [
        {
            coinDW: {
                deposit: { $forbidContent: $depForbidContent },
                withdraw: { $forbidContent: $withdrawForbidContent },
                // 公共的权限
                $permission: $dwPermission,
                $forbidLink: $depPermLink,
                $forbidBtnText,
                $forbidTitle,
            },
        },
        { loading: kycLoading },
    ] = useKycV2Rights();
    const [dailyWithdrawLeft, dailyLeftLoading, { refresh: refreshDailyWithdrawLeft }] = useKycV2DailyWithdrawLeft(currentCoin);
    const isDeposit = tabActiveKey === DWType.deposit;
    const isMaintain = isDeposit ? !currentCoin?.deposit_enabled : !currentCoin?.withdraw_enabled;
    const [hideOtherHistory, setHideOtherHistory] = useState(false);

    const goDWDetail = useCallback(() => {
        if (!$dwPermission && $depPermLink && /kyc/.test($depPermLink || "")) {
            DataReporter.report(KYC_EVENT_NAME.KYC_EVENT_LV1, { from_web: isDeposit ? "cryptowithdraw" : "cryptowithdraw", PLATFORM: PLATFORM._CODE });
        }
        !$dwPermission ? $depPermLink && history.push($depPermLink, { from: tabActiveKey }) : window.open($st("dw_announce_url"));
    }, [$dwPermission, $depPermLink, tabActiveKey, $st, isDeposit]);
    const onCheck = useCallback((e) => setHideOtherHistory(e.target.checked), []);
    // 当前币种支持的currencies
    const currentCoinCurrencies = useMemo(() => {
        if (!coinList || !currentCoin) {
            return currentCoin ? [currentCoin.currency] : [];
        }
        return coinList.filter((item) => item.coinName === currentCoin.coinName).map((item) => item.currency);
    }, [coinList, currentCoin]);

    useEffect(() => {
        if (coinType && coinList) {
            let findResult = coinList.find((item) => item.currency === coinType && (isDeposit ? item.supportDeposit : item.supportWithdraw));
            if (!findResult) {
                const coins = coinList.filter((item) => {
                    return item.coinName === coinType && (isDeposit ? item.supportDeposit : item.supportWithdraw);
                });
                findResult = coins.find((item) => (isDeposit ? item.deposit_enabled : item.withdraw_enabled));
                if (!findResult && coins.length > 0) {
                    findResult = coins[0];
                }
            }

            if (findResult) {
                changeCurrentCoin(findResult);
            }
        }
    }, [coinType, coinList, isDeposit]);

    useEffect(() => {
        // 设置初始出金地址数据
        if (currentCoin?.currency && state?.addressInitialData) {
            const routerAddressInitialData = state.addressInitialData;
            if (routerAddressInitialData.currency === currentCoin.currency) {
                changeAddressInitialData(routerAddressInitialData);
            }
        }
    }, [currentCoin?.currency, state?.addressInitialData]);
    useEffect(() => {
        // 绑定了2fa才去请求仅向白名单提币状态
        if (accountInfo?.otpState === OtpState.BOUND) {
            getWhiteListStatus().subscribe(
                (data) => {
                    setOnlyWhiteList(data?.whitelist_status);
                },
                (error) => {
                    commonErrorParser(error.code, $st, error.message);
                },
            );
        }
    }, [$st, accountInfo?.otpState]);

    useCheckShowForcedSSN();

    if (!currentCoin) return <TSpin spinning />;

    return (
        <DWPageContext.Provider
            value={{
                coinList,
                currentCoin,
                changeCurrentCoin,
                addressInitialData,
                changeAddressInitialData,
                onlyWhiteList,
                noTwoFactor: noTwoFactor,
                addressListLoading: !addressBookItems,
                addressListLoaded: !!addressBookItems,
                addressList: addressBookItems || [],
                reFreshAddressList: refreshAddressBookItems,
                dailyWithdrawLeft,
                refreshDailyWithdrawLeft,
            }}
        >
            <BasicLayout.BasicContent>
                <Warning tabActiveKey={tabActiveKey} currentCoin={currentCoin} />
                <div className={cs.coinInfo}>
                    <TMaskLoading
                        spinning={!addressBookItems || loading || kycLoading || dailyLeftLoading}
                        className={cs.coinInfoItem}
                        style={{ paddingTop: 20, paddingBottom: 20 }}
                    >
                        <CoinSelect
                            type={tabActiveKey}
                            onSelect={(coin) => {
                                history.push(`/balance/${tabActiveKey}/${coin.currency}`);
                                if (!isDeposit)
                                    // 切换币种时，刷新提币余额
                                    refreshDailyWithdrawLeft();
                            }}
                        />
                    </TMaskLoading>
                    <div className={cs.coinInfoItemMiddle}>
                        {isMaintain || !$dwPermission ? (
                            <div className={cs.blurArea}>
                                <h4 className={cs.blurTitle}>{!$dwPermission ? $forbidTitle : $st("deposit_maintain_title", { currency: getCurrencyDisplayName(currentCoin) })}</h4>
                                <p className={cs.blurTip}>
                                    {!$dwPermission
                                        ? isDeposit
                                            ? $depForbidContent
                                            : $withdrawForbidContent
                                        : isDeposit
                                        ? $st("receive_maintain_content")
                                        : $st("send_maintain_content")}
                                </p>

                                <div style={{ position: "absolute", bottom: 25, right: 0, width: "100%" }}>
                                    <div
                                        className={cs.attentionButton}
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "flex-end",
                                        }}
                                    >
                                        <Button className={cs.button} onClick={goDWDetail}>
                                            {!$dwPermission ? $forbidBtnText : $st("deposit_maintain_check")}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={cs.AddressViewBox}>
                                <AddressView isDeposit={isDeposit} />
                            </div>
                        )}
                    </div>
                </div>
                <div className={cs.historyTable}>
                    <div className={cs.historyHeader}>
                        <h3>{$st(isDeposit ? "dw.history.title.receive" : "dw.history.title.send")}</h3>
                        <Checkbox checked={hideOtherHistory} onChange={onCheck}>
                            {$st("dw.history.only.show.current.coin")}
                        </Checkbox>
                    </div>

                    <HistoryTable DWType={tabActiveKey} currencies={currentCoinCurrencies} accountInfo={accountInfo} hideOther={hideOtherHistory} dateFormatter={dateFormatter} />
                </div>
                {/* <WalletUpgradeNotice isDeposit={isDeposit} /> */}
            </BasicLayout.BasicContent>
        </DWPageContext.Provider>
    );
}

const DW_UPGRADE_KEY = "--DW_UPGRADE_KEY--";

const getNoticeDetailLink = (locale: string) => {
    switch (locale) {
        case LANGUAGE_LOCALS_MAP.zhCN:
            return "https://pionex.zendesk.com/hc/zh-cn/articles/4408306726809";
        case LANGUAGE_LOCALS_MAP.zhTW:
            return "https://pionex.zendesk.com/hc/zh-tw/articles/4408306739481";
        default:
            return "https://pionex.zendesk.com/hc/en-us/articles/4408306753433";
    }
};

const WalletUpgradeNotice = ({ isDeposit }: { isDeposit: boolean }) => {
    const {
        intl: { locale },
        $st,
    } = useTranslators();
    const accountInfo = useAccountInfo();
    // FIXME: 后续可能需要去掉这个用户提示
    const [UpgradeModal, { show: showWalletUpgradeNotice }] = useConfirmCheckModal({
        title: $st("wallet_upgrade_title"),
        content: (
            <>
                {$st("wallet_upgrade_content")}
                <TReportLink primary target="_blank" to={getNoticeDetailLink(locale)}>
                    {$st("wallet_upgrade_link_title")}
                </TReportLink>
            </>
        ),
        checkLabel: $st("common_dont_remind_again"),
        checkLabelOkButtonControl: false,
        onOk(_, checked) {
            if (checked) {
                localStorage.setItem(`${DW_UPGRADE_KEY}-${accountInfo?.userId}`, "1");
            }
        },
    });
    useEffect(() => {
        isDeposit && accountInfo.userId && localStorage.getItem(`${DW_UPGRADE_KEY}-${accountInfo.userId}`) !== "1" && showWalletUpgradeNotice();
    }, [accountInfo.userId, isDeposit, showWalletUpgradeNotice]);
    return UpgradeModal;
};
