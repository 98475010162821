import { NestedRoute } from "utils/commons";
import { MenuNode } from "@pionex-web-kit/pro-components";
import React, { ComponentType } from "react";

export const BUY_CRYPTO_ROUTE = NestedRoute.CompleteTree({
    _: "buy-crypto",
    BUY: "buy",
    HISTORY: "history",
    FIAT_CARDS: "fiat-cards",
});

type PureMenu = Omit<MenuNode, "children"> & { Component?: ComponentType };
type Menu = PureMenu & { children?: PureMenu[] };

// export const menus: Menu[] = [
//     { key: BUY_CRYPTO_ROUTE.BUY, path: BUY_CRYPTO_ROUTE.BUY, name: "买币", icon: <Iconfont icon={"icon_BankTransfer"} className="!text-xl" />, Component: Buy },
//     {
//         key: BUY_CRYPTO_ROUTE.FIAT_CARDS,
//         path: BUY_CRYPTO_ROUTE.FIAT_CARDS,
//         name: "卡管理",
//         icon: <Iconfont icon={"icon_BankTransfer"} className="!text-xl" />,
//         Component: FiatCards,
//     },
//     { key: BUY_CRYPTO_ROUTE.HISTORY, path: BUY_CRYPTO_ROUTE.HISTORY, name: "历史订单", icon: <Iconfont icon={"icon_BankTransfer"} className="!text-xl" />, Component: History },
// ];
