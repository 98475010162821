export enum EventName {
    login_with_temp_token_success = "login_with_temp_token_success",
    register_with_temp_token_success = "register_with_temp_token_success",
    login_with_temp_token_fail = "login_with_temp_token_fail",
    login_with_temp_token_init = "login_with_temp_token_init",

    temp_bind_referral_code_success = "temp_bind_referral_code_success",
    temp_bind_referral_code_failed = "temp_bind_referral_code_failed",

    buycrypto_enter = "buycrypto_enter",
    buycrypto_channel_select = "buycrypto_channel_select",
    buycrypto_submit = "buycrypto_submit",
    buycrypto_submit_confirm = "buycrypto_submit_confirm",
    buycrypto_wire_select = "buycrypto_wire_select",
    quickusd_click = "quickusd_click",

    download_channel_click = "download_channel_click",
}

export enum KYC_EVENT_NAME {
    KYC_EVENT_LV1 = "enter_kyc_lv1",
}

export enum FromName {
    deposit_ach = "deposit_ach",
    deposit_debit = "deposit_debit",
    deposit_wire = "deposit_wire",

    main_nav = "main_nav",
    trade_swap = "trade_swap",
    trade_bot = "trade_bot",
    trade_manual = "trade_manual",

    home_banner = "home_banner",
    main_footer = "main_footer",
    home_streamer = "home_streamer",

    kyc = "kyc",

    AM_init_er = "AM_init_er",
    AM_sign_out = "AM_sign_out",

    market = "market",

    bot_order_check = "bot_order_check",
    single_btn = "single_btn",
    dialog_sign = "dialog_sign",
    dialog_signUp = "dialog_signUp",
    layout = "layout",
    auth_route = "auth_route",
}
