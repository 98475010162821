import React, { PropsWithChildren, useMemo } from "react";
import { SSNModal, useGlobalSSNConfig } from "landings/V2/Account/paths/Kyc-v2/components/SSN";
import { useHelpCenterFn } from "commonUse";

export type GlobalModalConfig = {
    ssnModalConfig?: ReturnType<typeof useGlobalSSNConfig>;
    openHelpCenter: () => void;
};

export const GlobalModalContext = React.createContext<GlobalModalConfig>({ openHelpCenter: () => {} });

/**
 * 全局弹窗状态管理
 * @param children
 * @constructor
 */
export const GlobalModalProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const ssnModalConfig = useGlobalSSNConfig();
    const [openHelpCenter, helpCenterContext] = useHelpCenterFn();

    return (
        <GlobalModalContext.Provider
            value={useMemo(
                () => ({
                    ssnModalConfig,
                    openHelpCenter,
                }),
                [ssnModalConfig, openHelpCenter],
            )}
        >
            {
                <>
                    {children}
                    <SSNModal />
                    {helpCenterContext}
                </>
            }
        </GlobalModalContext.Provider>
    );
};
