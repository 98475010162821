import React, { PropsWithChildren, useEffect, useState } from "react";
import { CardNumber, Cvv, ExpiryDate, Frames } from "frames-react";
import { Button, Input, message, Modal, Spin } from "@pionex-web-kit/components";
import cs from "./AddCheckoutCard.m.less";
import { useCircleDebitAPI } from "landings/V2/CircleDebit/context";
import { AddCardRes, Network, PublicKeyInfo } from "landings/V2/CircleDebit/types";
import { useTranslators } from "commonUse/Locale";
import { TSpin } from "components/v2/TinyComps";
import CardSample, { CardSampleProps } from "landings/V2/CircleDebit/components/CardSample";
import csn from "classnames";
import { FrameElementIdentifer } from "frames-react/types/types";
import useTheme from "commonUse/useTheme";
import { useCallbackStatic } from "commonUse/tools";
import { validateCardName } from "landings/V2/USDDW/utils";
import { ThemeName } from "state/ThemeData";
import { processAddCardError } from "landings/V2/CircleDebit/AddCircleCard/utils";
import get from "lodash/get";
import { useOnlineConfig } from "utils/onlineConfig";
import { EMPTY_FUNC } from "utils/Utils";
import { CHECKOUT_SDK_INJECTED } from "landings/V2/CircleDebit/AddCheckoutCard/injectCheckoutSDK";

export type Props = {
    publicKeyInfo: PublicKeyInfo;
    onCancel();
    onResult(res: AddCardRes);
    loading?: boolean;
};

type FormItemName = FrameElementIdentifer | "holder-name" | "Address_Line1" | "City" | "State" | "Zip" | "Country";

/**
 * 通过Checkout通道绑定卡片
 */
const AddCheckoutCard: React.FC<Props> = ({ onCancel, onResult, loading, publicKeyInfo }) => {
    const { $st } = useTranslators();
    const debitAPI = useCircleDebitAPI();
    const theme = useTheme();
    const onLineConfig = useOnlineConfig();

    const [cardSampleOri, setCardSampleOri] = useState<CardSampleProps["orientation"]>("front");
    const [cardSampleType, setCardSampleType] = useState<CardSampleProps["cardType"]>(Network.VISA);
    const [contentVisible, setContentVisible] = useState(false);

    // 兼容新版rc-dialog和Frames sdk的内部判断
    const [readyToFrames, setReadyToFrames] = useState(false);
    useEffect(() => {
        setReadyToFrames(true);
    }, []);

    const [holderName, setHolderName] = useState<string>();
    const [address_line1, setAddress_Line1] = useState<string>();
    const [city, setCity] = useState<string>();
    const [district, setDistrict] = useState<string>();
    const [postal_code, setPostal_code] = useState<string>();
    const [country, setCountry] = useState<string>("USA");

    const [errorObj, setErrorObj] = useState<{ [k in FormItemName]?: string }>({});
    const [submitting, setSubmitting] = useState(false);

    const handleClickSubmit = useCallbackStatic(async () => {
        setSubmitting(true);
        try {
            const submitData = await Frames.submitCard();
            if (!debitAPI) {
                setSubmitting(false);
                return;
            }
            const res = await debitAPI
                .addCheckoutCard({ token: submitData.token, user_info: { name: holderName, address_line1, city, district, postal_code, country } })
                .toPromise();
            onResult(res);
            setSubmitting(false);
        } catch (e) {
            processAddCardError($st, e, get(onLineConfig, "errorMap.debit_card_failure", {}));
            setSubmitting(false);
        }
    });

    // 清除某个字段的错误信息
    const clearItemError = useCallbackStatic((name: FormItemName) => {
        setErrorObj((pre) => {
            const newError = { ...pre };
            delete newError[name];

            return newError;
        });
    });
    if (!CHECKOUT_SDK_INJECTED) {
        message.error("init failed");
        return null;
    }

    return (
        <Modal wrapClassName={cs.modal} width={650} className={cs.checkoutContainer} visible onCancel={onCancel} closable={true} footer={null}>
            <TSpin tip={$st("debit_waiting_sumsub")} spinning={loading}>
                <div className={cs.title}>{$st("debit_add_card_title")}</div>
                {readyToFrames && (
                    <Frames
                        config={{
                            publicKey: publicKeyInfo.publicKey,
                            localization: {
                                cardNumberPlaceholder: "Card number",
                                expiryMonthPlaceholder: "MM",
                                expiryYearPlaceholder: "YY",
                                cvvPlaceholder: "CVV",
                            },
                            style: {
                                placeholder: {
                                    base: { color: theme.colorText5 },
                                },
                                invalid: { color: "#F04848" },
                                valid: { color: theme.name === ThemeName.dark ? theme.colorText2 : theme.colorTextAccent },
                                base: { color: theme.name === ThemeName.dark ? theme.colorText2 : theme.colorTextAccent },
                            },
                        }}
                        ready={() => {
                            // 避免sdk加载自定义样式时抖动
                            setTimeout(() => setContentVisible(true), 200);
                        }}
                        frameActivated={(e) => {}}
                        frameFocus={(e) => {
                            if (e.element === "cvv") {
                                setCardSampleOri("back");
                            }
                        }}
                        frameBlur={(e) => {
                            if (e.element === "cvv") {
                                setCardSampleOri("front");
                            }
                        }}
                        frameValidationChanged={(e) => {
                            const elem = e.element;

                            if (e.isValid || e.isEmpty) {
                                clearItemError(elem);
                            } else {
                                setErrorObj((pre) => ({ ...pre, [elem]: $st(`bind_checkout_error:${elem}`) }));
                            }
                        }}
                        cardTokenized={EMPTY_FUNC}
                        cardBinChanged={(e) => {
                            if (e.scheme === "Mastercard") {
                                setCardSampleType(Network.MASTER);
                            } else {
                                setCardSampleType(Network.VISA);
                            }
                        }}
                    >
                        {/*占位的空白*/}
                        {contentVisible || <Spin className={"h-[112px] w-full"} />}
                        <div className={contentVisible ? "" : "hidden"}>
                            <CheckoutFormItem errorObj={errorObj} name={"card-number"}>
                                <CardNumber />
                            </CheckoutFormItem>
                            <div className={cs.cvvContainer}>
                                <CheckoutFormItem errorObj={errorObj} name={"expiry-date"}>
                                    <ExpiryDate />
                                </CheckoutFormItem>

                                <CheckoutFormItem errorObj={errorObj} name={"cvv"}>
                                    <Cvv />
                                </CheckoutFormItem>
                            </div>

                            <CheckoutFormItem errorObj={errorObj} name={"holder-name"}>
                                <Input
                                    value={holderName}
                                    size={"large"}
                                    className={"mt-12px"}
                                    placeholder={$st("debit_add_card_info_holder_name")}
                                    status={errorObj["holder-name"] ? "error" : undefined}
                                    onChange={(e) => {
                                        const v = e.target.value?.toUpperCase();
                                        setHolderName(v);
                                        validateCardName($st, v)
                                            .then((res) => {
                                                clearItemError("holder-name");
                                            })
                                            .catch((e) => {
                                                setErrorObj((pre) => ({ ...pre, "holder-name": e }));
                                            });
                                    }}
                                />
                            </CheckoutFormItem>
                            <CheckoutFormItem errorObj={errorObj} name={"Address_Line1"}>
                                <Input
                                    value={address_line1}
                                    size={"large"}
                                    className={"mt-12px"}
                                    placeholder={"Address Line1"}
                                    maxLength={200}
                                    onChange={(e) => {
                                        setAddress_Line1(e.target.value);
                                        if (e.target.value) {
                                            clearItemError("Address_Line1");
                                        } else {
                                            setErrorObj((pre) => ({ ...pre, Address_Line1: "Address_Line1" }));
                                        }
                                    }}
                                />
                            </CheckoutFormItem>
                            <CheckoutFormItem errorObj={errorObj} name={"City"}>
                                <Input
                                    value={city}
                                    size={"large"}
                                    className={"mt-12px"}
                                    placeholder={"City"}
                                    maxLength={200}
                                    onChange={(e) => {
                                        setCity(e.target.value);
                                        if (e.target.value) {
                                            clearItemError("City");
                                        } else {
                                            setErrorObj((pre) => ({ ...pre, City: "City" }));
                                        }
                                    }}
                                />
                            </CheckoutFormItem>
                            <CheckoutFormItem errorObj={errorObj} name={"State"}>
                                <Input
                                    value={district}
                                    size={"large"}
                                    className={"mt-12px"}
                                    placeholder={"State"}
                                    maxLength={200}
                                    onChange={(e) => {
                                        setDistrict(e.target.value);
                                        if (e.target.value) {
                                            clearItemError("State");
                                        } else {
                                            setErrorObj((pre) => ({ ...pre, State: "State" }));
                                        }
                                    }}
                                />
                            </CheckoutFormItem>
                            <CheckoutFormItem errorObj={errorObj} name={"Zip"}>
                                <Input
                                    value={postal_code}
                                    size={"large"}
                                    className={"mt-12px"}
                                    placeholder={"Zip"}
                                    maxLength={16}
                                    onChange={(e) => {
                                        setPostal_code(e.target.value);
                                        if (e.target.value) {
                                            clearItemError("Zip");
                                        } else {
                                            setErrorObj((pre) => ({ ...pre, Zip: "Zip" }));
                                        }
                                    }}
                                />
                            </CheckoutFormItem>
                            <CheckoutFormItem errorObj={errorObj} name={"Country"}>
                                <Input
                                    disabled
                                    value={country}
                                    size={"large"}
                                    className={"mt-12px"}
                                    placeholder={"Zip"}
                                    maxLength={200}
                                    onChange={(e) => {
                                        setCountry(e.target.value);
                                    }}
                                />
                            </CheckoutFormItem>
                        </div>
                    </Frames>
                )}
                <CardSample className={cs.cardSample} cardType={cardSampleType} orientation={cardSampleOri} />

                <Button
                    disabled={!Frames.isCardValid() || !!errorObj["holder-name"] || !city || !district || !postal_code || !address_line1}
                    type={"primary"}
                    size={"large"}
                    shape={"round"}
                    className={"block mx-auto mt-16px min-w-[240px]"}
                    onClick={handleClickSubmit}
                    loading={submitting}
                >
                    {$st("common_submit")}
                </Button>
            </TSpin>
        </Modal>
    );
};

export default AddCheckoutCard;

const CheckoutFormItem: React.FC<PropsWithChildren<{ errorObj: { [k in FormItemName]?: string }; name: FormItemName }>> = ({ children, errorObj, name }) => {
    return (
        <div className={csn(cs.formItem, { [cs.formItemError]: errorObj[name] })}>
            {children}
            <div className={cs.errorLabel}>{errorObj[name]}</div>
        </div>
    );
};
