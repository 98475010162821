export const REVERSE_RED_GREEN_UP = "REVERSE_RED_GREEN_UP";
export const PRICE_SELECTED = "PRICE_SELECTED";
export const NOTIFY_CUSTOM_ORDER_CREATE = "NOTIFY_CUSTOM_ORDER_CREATE";
export const NOTIFY_ORDER_CANCEL_SUCCESS = "NOTIFY_ORDER_CANCEL_SUCCESS";

export const ORDER_TABE_ANCHOR = "ORDER_TABE_ANCHOR";

export const TAB_MANUAL = "manual";
export const TAB_MANUAL_HISTORY = "manual_history";
export const TAB_ASSETS = "assets";
export const TAB_TRADING_BOT = "trading_bot";
export const TAB_BOT_HISTORY = "bot_history";

export const NOTIFY_LOGING_TOKEN_ASYNC_FROM_APP = "NOTIFY_LOGING_TOKEN_ASYNC_FROM_APP";
export const NOTIFY_PROFIT_MODEL_CHANGE = "NOTIFY_PROFIT_MODEL_CHANGE";
