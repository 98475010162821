import {
    IconAi24pxMedium,
    IconBankCard,
    IconBankTransfer,
    IconChat24pxMedium,
    IconCopy20pxRegular,
    IconFaq24pxMedium,
    IconFeedback24pxMedium,
    IconFuturesBot20pxRegular,
    IconMail,
    IconMail24pxMedium,
    IconManual20pxRegular,
    IconNavActivity24pxRegular,
    IconNavBuyCryptoOrders24pxRegular,
    IconNavDepositUsdOrders24pxRegular,
    IconNavManualOrders24pxRegular,
    IconNavOverview24pxRegular,
    IconNavReceiveCrypto24pxRegular,
    IconNavSendCrypto24pxRegular,
    IconNavTradingBotOrders24pxRegular,
    IconNavWithdrawUsdOrders24pxRegular,
    IconPaibibao20pxRegular,
    IconSwap20pxRegular,
    IconTaxes24pxMedium,
    IconTrialFundAccount20pxRegular,
    IconWindowaccountup24pxRegular,
    IconZaixiankefu20pxRegular,
} from "@pionex-web-kit/icons";
import { Avatar, Col, Row } from "antd";
import { SwitchProps } from "antd/lib/switch";
import csn from "classnames";
import { changeLang } from "commonActions/actions";
import { useOpenHelpCenter } from "commonUse";
import { useTranslators } from "commonUse/Locale";
import { useToggle } from "commonUse/tools";
import useAccountInfo from "commonUse/useAccountInfo";
import { useBalanceAll } from "commonUse/useBalance";
import useExchangeRate from "commonUse/useExchangeRate";
import { useKycV2Rights } from "commonUse/useKycRightsV2";
import useTheme from "commonUse/useTheme";
import TReportLink, { Iconfont, TModal, TReportBlockLink, TSwitch } from "components/v2/TinyComps";
import { FromName } from "landings/Analytics/EventName";
import { PionexReport } from "landings/Analytics/Report";
import { BalancePath } from "landings/Balance/components/Root";
import { useClickWithSSN } from "landings/V2/Account/paths/Kyc-v2/components/SSN";
import { BUY_CRYPTO_ROUTE } from "landings/V2/BuyCrypto/menus";
import { useACHActivity } from "landings/V2/CircleACH/utils";
import QrCode from "qrcode.react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { RebateLevel } from "state/AccountInfo";
import { useSortedCurrencyList } from "state/CurrencyConfig";
import ExchangeRate from "state/ExchangeRate";
import { setTheme, setTickerReversed, ThemeName } from "state/ThemeData";
import { NumberCommonUtils, SafeDecimal } from "trade_utils_lib";
import AccountManager from "utils/AccountManager";
import { AccountsRoute } from "utils/AccountsRoute";
import Constants from "utils/Constants";
import { history } from "utils/History";
import LocalStorageUtils, { LocalStorageKey } from "utils/LocalStorageUtils";
import { LANGUAGE_LOCALS_LABEL_LIST, LANGUAGE_LOCALS_MAP } from "utils/i18n";
import { createRedirectParam } from "utils/redirect";
import { getLocaleDownloadLink, JumpToDownloadLink } from "../Header.v2/utils";
import useMedia from "../hooks/useMedia";
import Badge from "./Badge";
import { NavItem, NavSubGroup, NavSubItem, NavSubMenuGroup, useNavControl } from "./NavComps";
import { getNavMenuConfig } from "./constants";
import cs from "./cs.m.less";
import { useGetAnnouncementList, useNotificationUnreadCount } from "landings/Messages/hook";
import NewsTab from "./NewsTab";
import AccountUpdateModal from "./AccountUpdateModal";
import useKycInfoV2 from "commonUse/useKycInfoV2";
import { useIsUserFromV1, useShowUpgradeEntrance } from "commonUse/useV1Token";
import { SideTabKey } from "landings/Trade/SideTab";
import { report } from "utils/GoogleTagManager";
import CopyToClipboard from "react-copy-to-clipboard";
import { message } from "@pionex-web-kit/components";
import { SymbolPicker } from "landings/Trade/MarketLayout/Symbol";
import { useInitTradeSelectedInfo } from "state/TradeSelectedInfo";
import { useLocation } from "react-router";

export const postMail = "service@pionex.us";

enum ModalType {
    hide = "",
    login = "login",
    kyc_none = "kyc_none",
    kyc_verifying = "kyc_verifying",
    kyc_failed = "kyc_failed",
}

export const NavLeft = () => {
    const {
        intl: { locale },
        $st,
    } = useTranslators();
    const { isTablet } = useMedia();
    const accountInfo = useAccountInfo();
    const [
        {
            moneyDW: { circle, credit_card },
        },
    ] = useKycV2Rights();
    const [navTradeKey, setNavTradeKey] = useState(1);
    const [modalType, setModalType] = useState<ModalType>(ModalType.hide);
    const beforeTWDepositJump = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            let cancelJump = false;
            if (!accountInfo?.userId) {
                setModalType(ModalType.login);
                cancelJump = true;
            }
            if (cancelJump) {
                e.preventDefault();
            }
        },
        [accountInfo?.userId],
    );

    const openHelpCenter = useOpenHelpCenter();
    useInitTradeSelectedInfo(); // 初始化交易对
    return (
        <div className={cs.navLeft}>
            {!isTablet || (!accountInfo.userId ? <MobileSignBtn /> : <AccountNav />)}
            <NavItem
                title={
                    <>
                        {$st("buy_crypto_title")}
                        {!!isTablet && <Badge text={"Hot🔥🔥🔥"} className={"mobile:h-24px mobile:leading-[24px]"} />}
                    </>
                }
                innerClassName={isTablet ? "!justify-start items-center" : csn("my-auto !h-[32px] rounded-[50px] px-10px py-6px text-white text-base", cs.buyCrypto)}
                to={{
                    pathname: BUY_CRYPTO_ROUTE._,
                    state: {
                        from: FromName.main_nav,
                    },
                }}
            />

            <NavItem key={navTradeKey} title={$st("header_Trade")} reportData={3} reportType="reportClickInfo" to={LocalStorageUtils.generateFullTradePath()}>
                <NavSubMenuGroup>
                    <NavSubItem
                        to={LocalStorageUtils.generateFullTradePath(undefined, SideTabKey.BOT)}
                        alwaysShowArrow={true}
                        subItems={
                            !isTablet ? (
                                <NavSubGroup>
                                    <div className="min-w-[360px] h-[600px]">
                                        <SymbolPicker
                                            onSelectSymbol={() => {
                                                setNavTradeKey((val) => val + 1);
                                            }}
                                            key={SideTabKey.BOT}
                                            active={true}
                                            targetPage={SideTabKey.BOT}
                                        />
                                    </div>
                                </NavSubGroup>
                            ) : undefined
                        }
                    >
                        <div className={"flex items-center flex-row gap-16px w-[324px]"}>
                            <div className="flex items-center w-[24px]">
                                <IconFuturesBot20pxRegular size={24} className="visible" />
                            </div>
                            <div className="flex flex-1 flex-col ">
                                <div className="flex items-center">
                                    {$st("trading_bot")}
                                    <Badge text={"HOT"} />
                                </div>
                                <div className={csn(cs.overellipsis2, "text-secondary mt-[4px] text-sm pr-[10px]")}>{$st("trade_tab_spot_bot_desc")}</div>
                            </div>
                        </div>
                    </NavSubItem>
                    <NavSubItem
                        to={LocalStorageUtils.generateFullTradePath(undefined, SideTabKey.MANUAL)}
                        alwaysShowArrow={true}
                        subItems={
                            !isTablet ? (
                                <NavSubGroup>
                                    <div className="min-w-[360px] h-[600px]">
                                        <SymbolPicker
                                            onSelectSymbol={() => {
                                                setNavTradeKey((val) => val + 1);
                                            }}
                                            key={SideTabKey.MANUAL}
                                            active={true}
                                            targetPage={SideTabKey.MANUAL}
                                        />
                                    </div>
                                </NavSubGroup>
                            ) : undefined
                        }
                    >
                        <div className={"flex items-center flex-row gap-16px w-[324px]"}>
                            <div className="flex items-center w-[24px]">
                                <IconManual20pxRegular size={24} />
                            </div>
                            <div className="flex flex-1 flex-col ">
                                <div className="flex items-center">{$st("trade_side_tab_spot")}</div>
                                <div className={csn(cs.overellipsis2, "text-secondary mt-[4px] text-sm pr-[10px]")}>{$st("trade_side_tab_spot_desc")}</div>
                            </div>
                        </div>
                    </NavSubItem>
                    <NavSubItem to={LocalStorageUtils.generateFullTradePath(undefined, SideTabKey.SWAP)}>
                        <div className={"flex items-center flex-row gap-16px w-[324px]"}>
                            <div className="flex items-center w-[24px]">
                                <IconSwap20pxRegular size={24} />
                            </div>
                            <div className="flex flex-1 flex-col ">
                                <div className="flex items-center">{$st("trade_swap")}</div>
                                <div className={csn(cs.overellipsis2, "text-secondary mt-[4px] text-sm pr-[10px]")}>{$st("trade_swap_desc")}</div>
                            </div>
                        </div>
                    </NavSubItem>
                </NavSubMenuGroup>
            </NavItem>

            <NavItem title={$st("homepage_menu_dropdown_txt_market")} to="/market"></NavItem>
            <NavItem title={"Support"} eleType={"div"} placement="center">
                <NavSubGroup title="Contact">
                    {/*<NavSubItem to="/chat">*/}
                    {/*    <div className="flex items-center">*/}
                    {/*        <IconAi24pxMedium />*/}
                    {/*        <span style={{ marginLeft: 10 }}>{$st("nav_chatgpt")}</span>*/}
                    {/*    </div>*/}
                    {/*</NavSubItem>*/}

                    <NavSubItem onClick={() => openHelpCenter()}>
                        <div className="flex items-center">
                            <IconChat24pxMedium />
                            <span style={{ marginLeft: 10 }}>Live chat</span>
                        </div>
                    </NavSubItem>

                    <NavSubItem to="https://pionex.canny.io/">
                        <div className="flex items-center">
                            <IconFeedback24pxMedium />
                            <span style={{ marginLeft: 10 }}>Feedback</span>
                        </div>
                    </NavSubItem>

                    <NavSubItem target="_blank" to={`${window.location.origin}/blog`}>
                        <div className="flex items-center">
                            <Iconfont size={24} icon="icon_Academy" />
                            <span style={{ marginLeft: 10 }}>Academy</span>
                        </div>
                    </NavSubItem>

                    <NavSubItem target="_blank" to="https://www.pionex.us/blog/important-information-about-use-of-the-pionex-us-platform-and-risk-disclaimer/">
                        <div className="flex items-center">
                            <Iconfont size={24} icon="icon_nav_About_statement" />
                            <span style={{ marginLeft: 10, whiteSpace: "nowrap" }}>Risk Disclaimer</span>
                        </div>
                    </NavSubItem>
                </NavSubGroup>

                <NavSubGroup title={<div className="h-20px"> </div>}>
                    <NavSubItem to={`mailto:${postMail}`}>
                        <div className="flex items-center">
                            <IconMail24pxMedium />
                            <span style={{ marginLeft: 10 }}>Mail us</span>
                        </div>
                    </NavSubItem>

                    <NavSubItem to="https://pionexus.zendesk.com/hc/en-us/categories/4430092749081-Announcements">
                        <div className="flex items-center">
                            <Iconfont size={24} icon="icon_nav_surport_Notifications" />
                            <span style={{ marginLeft: 10 }}>Announcement</span>
                        </div>
                    </NavSubItem>

                    <NavSubItem target="_blank" to="https://pionexus.zendesk.com/hc/en-us/categories/4430093042841-FAQ">
                        <div className="flex items-center">
                            <IconFaq24pxMedium />
                            <span style={{ marginLeft: 10 }}>FAQ</span>
                        </div>
                    </NavSubItem>

                    <NavSubItem to="/taxes">
                        <span className="flex items-center gap-10px">
                            <IconTaxes24pxMedium size={24} />
                            <span>{$st("nav_taxes")}</span>
                        </span>
                    </NavSubItem>

                    <NavSubItem
                        onClick={() => {
                            openHelpCenter();
                        }}
                    >
                        <div className="flex items-center">
                            <IconZaixiankefu20pxRegular size="24" />
                            <span style={{ marginLeft: 10, whiteSpace: "nowrap" }}>{$st("dw_warning_contact")}</span>
                        </div>
                    </NavSubItem>
                </NavSubGroup>
                {useMemo(
                    () =>
                        getNavMenuConfig(locale).map(({ title, menus }, index) => {
                            if (title === "header_menu_community")
                                return (
                                    <NavSubGroup key={index} title={$st(title)}>
                                        {menus.map(({ icon, label, link, extra, disabled }) =>
                                            disabled ? null : (
                                                <NavSubItem key={label} to={link} target="_blank">
                                                    <Iconfont icon={icon as any} size={24} /> {$st(label)}
                                                </NavSubItem>
                                            ),
                                        )}
                                    </NavSubGroup>
                                );
                            return null;
                        }),
                    [$st, locale],
                )}
            </NavItem>
            <NavItem
                onClick={() => report("navbar_deposit")}
                innerClassName="!justify-start items-center"
                title={
                    <span>
                        {$st("account_deposit")} <img style={{ width: 48, height: 16 }} src={require("../../../assets/0_fee.png")} alt="" />
                    </span>
                }
                to={`/balance/ach`}
            ></NavItem>
            {(circle.$entry || credit_card.$entry) && (
                <NavItem title={$st("buy_crypto_title")}>
                    <NavSubGroup>
                        {credit_card.$entry && (
                            <NavSubItem onClick={beforeTWDepositJump} to="/buy_crypto/credit_card/deposit">
                                <IconBankCard size={24} className="mr-10px align-middle" /> {$st("buy_crypto_title_credit_card")}
                            </NavSubItem>
                        )}
                        {circle.$entry && (
                            <NavSubItem onClick={beforeTWDepositJump} to="/buy_crypto/bank_transfer/deposit">
                                <IconBankTransfer className="mr-10px align-middle" size={24} />
                                {$st("buy_crypto_title_bank_transfer")}
                            </NavSubItem>
                        )}
                    </NavSubGroup>
                </NavItem>
            )}
            {modalType === ModalType.login && (
                <TModal
                    visible
                    width={400}
                    title={$st("tw_deposit_login_modal_title")}
                    okText={$st("sign_in_button")}
                    cancelText={$st("sign_up_button")}
                    onOk={() => {
                        setModalType(ModalType.hide);
                        history.push("/sign");
                    }}
                    onCancel={() => {
                        setModalType(ModalType.hide);
                        history.push("/sign", { page: "signUp", source: "twDeposit" });
                    }}
                    zIndex={10001}
                >
                    <div />
                </TModal>
            )}
        </div>
    );
};
export const NavRight = () => {
    const { $st } = useTranslators();
    const accountInfo = useAccountInfo();
    const { isTablet } = useMedia();
    const createClickWithSSN = useClickWithSSN({ actively: false });

    const signPath = useMemo(() => {
        return AccountsRoute.sign({ from: FromName.main_nav, ...createRedirectParam() });
    }, []);
    const activity = useACHActivity("deposit");

    return (
        <div className={cs.navRight}>
            {!accountInfo.userId ? (
                isTablet || (
                    <>
                        <NavItem title={"Sign in"} to={signPath}></NavItem>
                        <NavItem title={<div className={cs.signBtn}>Register</div>} to={AccountsRoute.signUp({ from: FromName.main_nav })}></NavItem>
                    </>
                )
            ) : (
                <>
                    <NavItem title={"Orders"} placement="right">
                        <NavSubGroup size={"default"}>
                            <NavSubItem to="/orders/bot/running/all" className=" whitespace-nowrap">
                                <IconNavTradingBotOrders24pxRegular className={"mr-10px align-middle"} size={24} />
                                {$st("pionex_orders_tab_trading")}
                            </NavSubItem>
                            <NavSubItem to="/orders/manual/placed" className=" whitespace-nowrap">
                                <IconNavManualOrders24pxRegular className={"mr-10px align-middle"} size={24} />
                                {$st("pionex_orders_tab_manual")}
                            </NavSubItem>
                            <NavSubItem to="/orders/buy-crypto/bought" className=" whitespace-nowrap">
                                <IconNavBuyCryptoOrders24pxRegular className={"mr-10px align-middle"} size={24} />
                                {$st("pionex_orders_tab_buy_crypto")}
                            </NavSubItem>
                            <NavSubItem onClick={() => report("orders_deposit_usd")} to="/orders/deposit/ach" className=" whitespace-nowrap">
                                <IconNavDepositUsdOrders24pxRegular className={"mr-10px align-middle"} size={24} />
                                {$st("pionex_orders_tab_deposit")}
                            </NavSubItem>
                            <NavSubItem onClick={() => report("orders_withdraw_usd")} to="/orders/withdraw/ach" className=" whitespace-nowrap">
                                <IconNavWithdrawUsdOrders24pxRegular className={"mr-10px align-middle"} size={24} />
                                {$st("pionex_orders_tab_withdraw")}
                            </NavSubItem>
                        </NavSubGroup>
                    </NavItem>
                    <NavItem title={"Wallet"} placement="right">
                        <NavSubGroup size="middle">
                            <NavSubItem to="/balance" reportType="reportAccountClick" reportData={4}>
                                <TotalBalance />
                            </NavSubItem>
                            <NavSubItem to={BalancePath.PORTFOLIO} reportType="reportAccountClick" reportData={1}>
                                <div className=" inline-flex gap-10px items-center">
                                    <IconNavOverview24pxRegular size={24} />
                                    <div>{$st("balance_portfolio")}</div>
                                </div>
                            </NavSubItem>
                            <NavSubItem to={BalancePath.BANK} reportType="reportAccountClick" reportData={1} onClick={createClickWithSSN() as any}>
                                <div onClick={() => report("wallet_deposit_usd")} className=" inline-flex gap-10px items-center whitespace-nowrap">
                                    <IconNavDepositUsdOrders24pxRegular size={24} />
                                    <div>
                                        {$st("circledw_deposit_title")} <img className="h-[16px] ml-4px" src={activity?.image} alt={"activity"} />
                                    </div>
                                </div>
                            </NavSubItem>
                            <NavSubItem to={BalancePath.BANKWITHDRAW} reportType="reportAccountClick" reportData={1} onClick={createClickWithSSN() as any}>
                                <div onClick={() => report("wallet_withdraw_usd")} className=" inline-flex gap-10px items-center">
                                    <IconNavWithdrawUsdOrders24pxRegular size={24} />
                                    <div>{$st("circledw_withdraw_title")}</div>
                                </div>
                            </NavSubItem>
                            <NavSubItem to={BalancePath.DEPOSIT} reportType="reportAccountClick" reportData={1} onClick={createClickWithSSN() as any}>
                                <div onClick={() => report("wallet_receive_crypto")} className=" inline-flex gap-10px items-center">
                                    <IconNavReceiveCrypto24pxRegular size={24} />
                                    <div>{$st("balance_receive_crypto")}</div>
                                </div>
                            </NavSubItem>
                            <NavSubItem to={BalancePath.WITHDRAW} reportType="reportAccountClick" reportData={2} onClick={createClickWithSSN() as any}>
                                <div onClick={() => report("wallet_send_crypto")} className=" inline-flex gap-10px items-center">
                                    <IconNavSendCrypto24pxRegular size={24} />
                                    <div>{$st("balance_send_crypto")}</div>
                                </div>
                            </NavSubItem>
                        </NavSubGroup>
                    </NavItem>

                    {isTablet || <AccountNav />}
                </>
            )}
            {/* 消息通知 */}
            {PLATFORM.PIONEX_US_V2 && <MessageNav />}
            {isTablet ? (
                <NavItem title={"Download App"} to="/download"></NavItem>
            ) : (
                <NavItem title={<Iconfont icon="icon_nav_AppDownload" style={{ fontSize: "25px" }} />} placement="right" hideArrow>
                    <NavSubGroup>
                        <NavSubItem eleType={"div"} noHoverEff centerAlign>
                            <DownloadQrCode />
                        </NavSubItem>
                        <JumpToDownloadLink source="header">
                            <NavSubItem centerAlign>{$st("header_qrcode_more")}</NavSubItem>
                        </JumpToDownloadLink>
                    </NavSubGroup>
                </NavItem>
            )}
            <NavItem title={isTablet ? "Setting" : <Iconfont icon="icon_nav_setting" style={{ fontSize: "25px" }} />} placement="right" hideArrow={!isTablet}>
                <NavSubGroup>
                    {PLATFORM.PIONEX_US_LIKE || (
                        <>
                            <CurrencyModal />
                            <LanguageModal />
                        </>
                    )}
                    <NavSubItem rightEff noHoverEff>
                        {$st("green_up_red_down")} <RedGreenSwitcher />
                    </NavSubItem>
                    <NavSubItem rightEff noHoverEff>
                        {$st("setting_theme_dark")} <ThemeSwitcher />
                    </NavSubItem>
                </NavSubGroup>
            </NavItem>
            {isTablet && accountInfo.userId && (
                <NavSubItem
                    onClick={() => {
                        AccountManager.shared.signOut();
                    }}
                >
                    Sign out
                </NavSubItem>
            )}
        </div>
    );
};

const MessageNav = () => {
    const { $st } = useTranslators();
    const { isTablet } = useMedia();
    const accountInfo = useAccountInfo();
    const { count } = useNotificationUnreadCount(accountInfo.userId);
    const { data: announcementList } = useGetAnnouncementList(1);
    const [announcementReadyState, setAnnouncementReadyState] = useState(true);

    useEffect(() => {
        let prevTime = LocalStorageUtils.get(`${LocalStorageKey.KEY_ANNOUNCEMENT_READ_TIMESTAMP}`) || "0";
        prevTime = JSON.parse(prevTime);
        // 如果最新一条公告的时间比最近一次看过公告的时间小，则表示没有未阅读的新公告，不展示红点
        if (new SafeDecimal(announcementList[0]?.timestamp).lessThanOrEqualTo(new SafeDecimal(prevTime))) {
            setAnnouncementReadyState(true);
        } else {
            setAnnouncementReadyState(false);
        }
    }, [announcementList]);

    const newsTabHover = useCallback(() => {
        let prevTime = LocalStorageUtils.get(`${LocalStorageKey.KEY_ANNOUNCEMENT_READ_TIMESTAMP}`) || "0";
        prevTime = JSON.parse(prevTime);
        if (new SafeDecimal(prevTime).lessThan(new SafeDecimal(announcementList[0]?.timestamp))) {
            const curTime = JSON.stringify(Date.now());
            LocalStorageUtils.set(`${LocalStorageKey.KEY_ANNOUNCEMENT_READ_TIMESTAMP}`, curTime);
            setAnnouncementReadyState(true);
        }
        // report("message_center_expose", {
        //     isLogin: !!accountInfo.userId,
        //     is_unread_message: !!count || !announcementReadyState,
        // });
    }, [announcementList]);
    return (
        <NavItem
            hideArrow={!isTablet}
            placement="right"
            title={
                <div className="relative flex items-center h-[60px]">
                    {count > 0 ? (
                        <div
                            className={csn(
                                "absolute bg-red font-sb text-white text-[12px] pl-[5px] pr-[5px] pt-[1px] pb-[1px] rounded-full leading-[14px] right-[6px] top-[12px] translate-x-[100%]",
                                isTablet && "top-[50%] translate-y-[-50%] !right-[-8px]",
                            )}
                        >
                            {count <= 99 ? count : "99+"}
                        </div>
                    ) : (
                        !announcementReadyState && (
                            <div
                                className={csn(
                                    "absolute bg-red font-sb w-6px h-6px rounded-full right-[4px] top-[20px] translate-x-[100%]",
                                    isTablet && "top-[50%] translate-y-[-50%] !right-[-8px]",
                                )}
                            ></div>
                        )
                    )}
                    {isTablet ? <div>{$st("settings_announcement")}</div> : <IconMail size={22} />}
                </div>
            }
            onMouseLeave={newsTabHover}
        >
            <NavSubGroup size="middle">
                <NewsTab count={count} announcementReadyState={announcementReadyState} />
            </NavSubGroup>
        </NavItem>
    );
};

const MobileSignBtn = () => {
    const { closeNav } = useNavControl();
    const signPath = useMemo(() => {
        return AccountsRoute.sign({ from: FromName.main_nav, ...createRedirectParam() });
    }, []);

    return (
        <div className={cs.signBtnMobileBox}>
            <TReportLink className={cs.signBtnMobile} to={AccountsRoute.signUp({ from: FromName.main_nav })} onClick={closeNav}>
                Register
            </TReportLink>
            <TReportLink className={cs.signBtnMobile} to={signPath} onClick={closeNav}>
                Sign in
            </TReportLink>
        </div>
    );
};

const AccountNav = () => {
    const { $st } = useTranslators();
    const { isTablet } = useMedia();
    const accountInfo = useAccountInfo();
    const showRebate = accountInfo?.rebateLevel?.currentLevel?.level === RebateLevel.rebatePro;
    const [{ currentLevel }] = useKycInfoV2();

    const [accountUpdateModalVisible, setAccountUpdateModalVisible] = useState<boolean>();
    const isUserFromUsV1 = useIsUserFromV1();
    const [showUpgradeEntrance] = useShowUpgradeEntrance();

    return accountInfo.userId ? (
        <>
            <NavItem
                innerStyle={isTablet ? { paddingBottom: 20 } : undefined}
                to="/my_profile"
                title={
                    isTablet ? (
                        <NavSubItem to="/my_profile">
                            <AccountInfo />
                        </NavSubItem>
                    ) : (
                        <div className={cs.avatarPc}>
                            <Avatar src={accountInfo.avatar || Constants.defaultAvatar} className={cs.avatar} size={24} />
                        </div>
                    )
                }
                placement="right"
            >
                <NavSubGroup size="middle">
                    {isTablet || (
                        <NavSubItem to="/my_profile">
                            <AccountInfo />
                        </NavSubItem>
                    )}
                    {isUserFromUsV1 && (
                        <NavSubItem to="/balance/account_update">
                            <div className=" inline-flex gap-10px items-center text-primary">
                                <IconWindowaccountup24pxRegular size={24} />
                                <div>{$st("account_update")}</div>
                            </div>
                        </NavSubItem>
                    )}
                    {showUpgradeEntrance && (
                        <NavSubItem
                            onClick={() => {
                                report("account_update_account");
                                setAccountUpdateModalVisible(true);
                            }}
                        >
                            <div className=" inline-flex gap-10px items-center text-primary">
                                <IconWindowaccountup24pxRegular size={24} />
                                <div>{$st("account_update")}</div>
                            </div>
                        </NavSubItem>
                    )}
                    <NavSubItem to="/my_profile">
                        <IconTrialFundAccount20pxRegular size={24} className="mr-10px align-middle" />
                        Account Setting
                    </NavSubItem>

                    <NavSubItem reportType="reportAccountClick" reportData={3} to="/referral_program">
                        <IconNavActivity24pxRegular size={20} className="mr-10px align-middle" />
                        {$st("header.account.menu.referral_Program")}
                    </NavSubItem>
                    {showRebate && (
                        <NavSubItem reportType="reportAccountClick" reportData={3} to="/rebate">
                            <IconPaibibao20pxRegular size={24} className="mr-10px align-middle" />
                            {$st("header.account.menu.customer.rebate")}
                        </NavSubItem>
                    )}

                    {isTablet || (
                        <NavSubItem
                            centerAlign
                            hideEffArrow
                            onClick={() => {
                                AccountManager.shared.signOut();
                            }}
                        >
                            Sign out
                        </NavSubItem>
                    )}
                </NavSubGroup>
            </NavItem>
            {showUpgradeEntrance && <AccountUpdateModal visible={accountUpdateModalVisible} setVisible={setAccountUpdateModalVisible} />}
        </>
    ) : null;
};

const TotalBalance = () => {
    const exchangeRate = useExchangeRate();
    const fullBalance = useBalanceAll();
    const hasBalance = !!fullBalance && !!fullBalance.total;
    return (
        <div className={cs.balanceInfo}>
            <h5 className={cs.bTitle}>Total balance</h5>
            <h1>
                {`${!hasBalance ? "--" : NumberCommonUtils.toNormalPrice((fullBalance?.total.totalInUSD || 0) * exchangeRate.exchangeRate)} ${exchangeRate.currency}`}
                <small style={{ fontSize: 14, marginLeft: 8 }}>{`${
                    !hasBalance ? "--" : `≈ ${new SafeDecimal(fullBalance?.total.totalInBTC).toDecimalPlaces(8).toNumber()}`
                } BTC`}</small>
            </h1>
        </div>
    );
};

const AccountInfo = () => {
    const { $st } = useTranslators();
    const { nickname, avatar, accounts, uid } = useAccountInfo();
    const _nickname = nickname || $st("account_nickname_not_set_tip");
    return (
        <div className={cs.accountInfo}>
            <Avatar src={avatar || Constants.defaultAvatar} className={cs.avatar} alt={_nickname} size={59} />
            <div className={cs.useInfo}>
                <h3>{_nickname}</h3>
                <span className="flex flex-row items-center whitespace-nowrap">
                    <span className="mr-6px">{$st("profile_email")}:</span>
                    <div className="text-text-300">{accounts?.[0]?.account}</div>
                </span>
                {uid && (
                    <div>
                        <CopyToClipboard
                            children={
                                <span
                                    className="inline-flex cursor-pointer items-center mt-6px"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    <span className="mr-6px">{$st("profile_uid")}:</span>
                                    <span className="flex items-center">
                                        <div className="mr-6px text-text-300">{uid}</div>
                                        <IconCopy20pxRegular size={20} />
                                    </span>
                                </span>
                            }
                            text={uid ?? ""}
                            onCopy={() => {
                                message.success($st("tip_copy_success"));
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const DownloadQrCode = () => {
    const theme = useTheme();
    const isDark = theme.name === ThemeName.dark;
    const {
        intl: { locale },
    } = useTranslators();
    const props = isDark
        ? {
              bgColor: "#282c33",
              fgColor: "#ffffff",
          }
        : {
              bgColor: "#ffffff",
              fgColor: "#000000",
          };
    return (
        <div className={cs.qrBox}>
            <QrCode
                {...props}
                value={getLocaleDownloadLink(locale)}
                size={173}
                level={"H"}
                imageSettings={{
                    src: require("src/assets/logo-ori.png"),
                    height: 40,
                    width: 40,
                    excavate: true,
                }}
            />
            <p style={{ marginTop: 20 }}>Use your mobile device to scan QR code to download</p>
        </div>
    );
};

const LanguageModal = connect(null, { changeLang })(({ changeLang }: { changeLang(la: string): void }) => {
    const {
        $st,
        intl: { locale },
    } = useTranslators();
    const [visible, { toggle, setFalse }] = useToggle();
    const [local, setLocal] = useState<typeof LANGUAGE_LOCALS_LABEL_LIST[number]>(() => ({} as any));
    return (
        <>
            <NavSubItem
                onClick={() => {
                    toggle();
                }}
                rightEff
            >
                <span>{$st("language_setting")}</span>
                <strong>
                    {local.name}
                    <Iconfont icon="icon_rightarrow" />
                </strong>
            </NavSubItem>
            <TModal zIndex={99999} title={$st("language_setting")} width={500} mask visible={visible} onCancel={setFalse} getContainer={() => document.body} footer={null}>
                <Row gutter={20} className={cs.firstRowLine}>
                    {useMemo(
                        () =>
                            LANGUAGE_LOCALS_LABEL_LIST.map((lData, idx) => {
                                const { lang, name, reportNum, support } = lData;
                                if (!support) return null;
                                const isCurrent = locale === lang;
                                if (isCurrent) {
                                    setLocal(lData);
                                }
                                const span = 8;
                                return (
                                    <Col
                                        span={span}
                                        // style={idx + 1 <= 24 / span ? { borderBottomWidth: "1px", paddingTop: 0 } : undefined}
                                        key={lang}
                                    >
                                        <TReportBlockLink
                                            reportData={reportNum as any}
                                            style={{ width: "100%" }}
                                            type="link"
                                            active={isCurrent}
                                            onClick={() => {
                                                changeLang(lang);
                                                setFalse();
                                            }}
                                        >
                                            {name}
                                        </TReportBlockLink>
                                    </Col>
                                );
                            }),
                        [changeLang, locale, setFalse],
                    )}
                </Row>
            </TModal>
        </>
    );
});

export function CurrencyModal() {
    const currentExchangeRate = useExchangeRate();
    const CURRENCY_LIST = useSortedCurrencyList();
    const {
        $st,
        intl: { locale },
    } = useTranslators();
    const [local, setLocal] = useState<string>();
    const [visible, { toggle, setFalse }] = useToggle();
    return (
        <>
            <NavSubItem
                onClick={() => {
                    toggle();
                }}
                rightEff
            >
                <span>{$st("currency_setting")}</span>
                <strong>
                    {local} <Iconfont icon="icon_rightarrow" />
                </strong>
            </NavSubItem>
            <TModal zIndex={99999} title={$st("currency_setting")} visible={visible} mask onCancel={setFalse} footer={null} width={500} getContainer={() => document.body}>
                <Row gutter={20} className={cs.firstRowLine}>
                    {useMemo(() => {
                        let list = CURRENCY_LIST;
                        if (locale === LANGUAGE_LOCALS_MAP.zhCN) {
                            list = CURRENCY_LIST.sort((c) => (["CNY"].includes(c) ? -1 : 1));
                        }
                        return list.map((currency, idx) => {
                            const isCurrent = currentExchangeRate.currency === currency;
                            if (isCurrent) {
                                setLocal(currency);
                            }
                            return (
                                <Col
                                    span={8}
                                    sm={6}
                                    // style={idx + 1 <= 24 / span ? { borderBottomWidth: "1px", paddingTop: 0 } : undefined}
                                    key={currency}
                                >
                                    <TReportBlockLink
                                        reportData={1}
                                        type="link"
                                        active={isCurrent}
                                        style={{ width: "100%" }}
                                        onClick={() => {
                                            setFalse();
                                            ExchangeRate.availableActions.refreshExchangeRate(currency);
                                            PionexReport.reportSystemOption(1);
                                        }}
                                    >
                                        {currency}
                                    </TReportBlockLink>
                                </Col>
                            );
                        });
                    }, [CURRENCY_LIST, currentExchangeRate.currency, locale, setFalse])}
                </Row>
            </TModal>
        </>
    );
}

export function RedGreenSwitcher({ onChange, ...props }: SwitchProps) {
    const [greenUp, setGreenUp] = useState(() => {
        return !LocalStorageUtils.tickerReversed;
    });
    const _onChange = useCallback(
        (checked: boolean, e) => {
            const greenUpRedDown = checked;
            setGreenUp(greenUpRedDown);
            setTickerReversed(!greenUpRedDown);
            PionexReport.reportSystemOption(!greenUpRedDown ? 22 : 21);
            onChange && onChange(checked, e);
        },
        [onChange],
    );
    return <TSwitch checked={greenUp} onChange={_onChange} {...props} />;
}

export function ThemeSwitcher({ onChange, ...props }: SwitchProps) {
    const theme = useTheme();
    const isDark = theme.name === ThemeName.dark;
    const _onChange = useCallback(
        (checked: boolean, e) => {
            setTheme(checked ? ThemeName.dark : ThemeName.light);
            PionexReport.reportSystemOption(checked ? 12 : 11);
            onChange && onChange(checked, e);
        },
        [onChange],
    );
    return <TSwitch checked={isDark} onChange={_onChange} {...props} />;
}
