import React from "react";
import { Progress } from "antd";
import css from "landings/Balance/components/BalanceBot/BalanceBotView.module.less";
import { ThemeName } from "state/ThemeData";
import useTheme from "commonUse/useTheme";

export interface ListProgressItemProps {
    percent: number;
}

const ListProgressItem = (props: ListProgressItemProps) => {
    const { percent } = props;
    const theme = useTheme();

    return (
        <Progress
            status={"normal"}
            className={css.progress}
            percent={percent}
            strokeColor={"#FFC2A4"}
            trailColor={theme.name === ThemeName.light ? "#F0F0F2" : "#353942"}
            type="line"
        />
    );
};

export default React.memo(ListProgressItem);
