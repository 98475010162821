import useKycV2Info, { FundDirection, KycLevelQuotaRule, KycV2Level, useKycV2QuotaRules, useKycV2UsedQuota } from "commonUse/useKycInfoV2";
import { ObservableUtils, SafeDecimal } from "trade_utils_lib";
import { useCallback, useEffect, useMemo, useState } from "react";
import Constants from "utils/Constants";

/**
 * 法币出金限额(当前用户kyc等级对应的规则)
 */
export function useComplianceQuota(): [KycLevelQuotaRule | undefined, boolean] {
    const [map, loading] = useKycV2QuotaRules(FundDirection.WITHDRAW);
    const [{ currentLevel }] = useKycV2Info();
    const _level = currentLevel === KycV2Level.oldLv2 ? 2 : currentLevel;

    if (_level === null) {
        return [undefined, loading];
    }

    return [map.get(_level), loading];
}

/**
 * 法币可用余额
 */
export function useComplianceAvailableQuota(): [number, boolean] {
    // 配置的限额
    const [quota, quotaLoading] = useComplianceQuota();
    // 已用的额度
    const [usedQuota, usedQuotaLoading] = useKycV2UsedQuota({ direction: FundDirection.WITHDRAW, rule: "RollingQuota" });

    return useMemo(
        () => [Math.max(new SafeDecimal(quota?.quota || 0).sub(usedQuota?.quota || 0).toNumber(), 0), quotaLoading || usedQuotaLoading],
        [quota?.quota, quotaLoading, usedQuota?.quota, usedQuotaLoading],
    );
}

export enum QuotaChannel {
    COIN = "COIN",
    FBO = "FBO",
    KGI = "KGI",
    CIRCLE = "CIRCLE",
    CIRCLE_ACH = "CIRCLE-ACH",
    CIRCLE_CARD = "CIRCLE-CARD",
    CHECKOUT_CARD = "CHECKOUT-CARD",
    CHECKOUT_ACH = "CHECKOUT-ACH",
}

export enum QuotaDirection {
    DEPOSIT = "deposit",
    WITHDRAW = "withdraw",
}

export enum QuotaCheckType {
    DEPOSIT_ROLLING_QUOTA_CHECK = "DEPOSIT_ROLLING_QUOTA_CHECK",
    WITHDRAWAL_ROLLING_QUOTA_CHECK = "WITHDRAWAL_ROLLING_QUOTA_CHECK",
    WITHDRAWAL_TPLUSN_QUOTA_CHECK = "WITHDRAWAL_TPLUSN_QUOTA_CHECK",
    COINOUT_ROLLING_QUOTA_CHECK = "COINOUT_ROLLING_QUOTA_CHECK",
    COINOUT_TPLUSN_QUOTA_CHECK = "COINOUT_TPLUSN_QUOTA_CHECK",
}

interface QuotaItem {
    checkType: QuotaCheckType;
    quota: string;
    currency: string;
}

/**
 * 按资金通道获取用户剩余额度
 * @param channel
 * @param direction
 */
export function useRemainQuota(channel: QuotaChannel, direction: QuotaDirection) {
    const [details, setDetails] = useState<QuotaItem[]>([]);
    const [loading, setLoading] = useState(false);

    const refresh = useCallback(() => {
        setLoading(true);
        ObservableUtils.getV2<{ details?: QuotaItem[] }>(`${Constants.walletApiHost}/channel_remain_quota/`, { channel, direction }, {}, true).subscribe(
            (res) => {
                setDetails(res?.details ?? []);
            },
            (e) => {
                console.error(e);
            },
            () => {
                setLoading(false);
            },
        );
    }, [channel, direction]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return { details, loading, refresh };
}
