import { ajaxGet } from "rxjs/internal/observable/dom/AjaxObservable";
import Constants from "utils/Constants";
import { map } from "rxjs/operators";
import { useSelector } from "react-redux";
import AppState from "state/AppState";
import { useMemo } from "react";
import merge from "lodash/merge";
/**
 * 所有字段可空,和redux中的DeepPartial区别是数组的处理上
 */

type WINNER_LIST_TYPE = {
    month: string;
    winners: {
        [key in string]: string | number;
    };
};

export type STREAMER_TYPE = {
    title: string;
    btn: string;
    link: string;
    enabled: boolean;
};

export type HomeTypeConfig = {
    streamer: {
        "pionex.us": STREAMER_TYPE;
        "pionex.us.v2": STREAMER_TYPE;
    };
};

export type WebGuide = {
    signSuccess: {
        title: string;
        cover: string;
        route: {
            video_url: string;
            type: "link" | "video";
            link_url: string;
            report: {
                key: string;
                channel: Array<"mixpanel" | "gtag" | "ga">;
                from_web: string;
            };
        };
    }[];
};

export type AdvConfig = DeepPartial<{
    extends: string;
    image: string;
    imageDark: string;
    link: {
        to: string;
        target: string;
        report: { key: string };
    };
}>;

interface WEB_CONFIG_TYPE {
    activities: {
        winOneBTC: {
            winnerList: WINNER_LIST_TYPE[];
        };
    };
    home: HomeTypeConfig;
    webGuide: WebGuide;
    nav: { taxes: { tip: { title: string } } };
    advertisements: Record<string, AdvConfig>;
}

export type WEB_CONFIG_TYPE_PA = DeepPartial<WEB_CONFIG_TYPE>;
/**
 * ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️
 * 请注意：定义配置字段全部定义为可选字段，避免出现配置失效导致的系统崩溃或报错。
 * ⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️
 */
export type WebConfigData = DeepPartial<WEB_CONFIG_TYPE>;

export const TYPE_REFRESH_WEB_CONFIG = "type_refresh_web_config";

export class WebConfig {
    static refresh() {
        ajaxGet(`${Constants.configHost}/statics/web/web_config.json`, { "Cache-Control": "no-cache" })
            .pipe(map((res) => res.response))
            .subscribe(
                (res) => {
                    AppState.store.dispatch({ type: TYPE_REFRESH_WEB_CONFIG, data: res });
                },
                () => {
                    AppState.store.dispatch({ type: TYPE_REFRESH_WEB_CONFIG, data: {} });
                },
            );
    }

    static init() {
        this.refresh();
    }

    static reducer = (state = {}, action) => {
        switch (action.type) {
            case TYPE_REFRESH_WEB_CONFIG:
                return action.data;
        }
        return state;
    };
}

export function useWebConfig() {
    return useSelector<AppState, WebConfigData>((appState) => appState.webConfig);
}

export function useAdvConfig(k: string) {
    const { advertisements: adConfig } = useWebConfig();
    const config = useMemo(() => {
        if (!adConfig || !adConfig[k]) return null;
        const base = adConfig[k] as AdvConfig;
        if (!base) return null;
        const extendsConfig = base?.extends ? adConfig[base.extends] : null;

        // 如果继承的数据字段中没有数据，且当前字段只配置了一个继承字段，则认为当前字段为空
        // {a: null, b: {extends: 'a'}} 认为b为空
        if (!extendsConfig && Object.keys(base).length === 1 && base.extends) {
            return null;
        }

        const obj = merge(extendsConfig, base) as AdvConfig;

        return obj;
    }, [adConfig, k]);

    return config;
}
