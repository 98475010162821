export default {
    pionex_title: "Pionex US | Exchange with Built-in Crypto Trading Bot",
    common_go_bind: "Bind",
    app_short_name: "Pionex.us",
    common_load_more: "Load more",
    common_cancel_success: "Cancel purchase success",
    common_cancel_failed: "Cancel purchase failed",
    common_dont_remind_again: "Do not remind again",
    common_detail: "Details",
    common_reason: "Detail",
    common_next: "Next",
    common_back: "Back",
    common_submit: "Submit",
    common_successfully: "Successfully",
    common_failed: "Failed",
    common_unknown_error: "unknown error, please contact us by email service@pionex.sg",
    common_other: "Other",
    // 语言
    "general.loading.tips": "Loading...",
    "general.getcaptcha.countdown": "Resend ({countdown})",
    "market.search.placeholder": "Search coin name",
    "market.search.placeholder.mini": "Search",
    "market.favorites": "Picks",
    "market.currency": "Currency",
    "market.Quotechange": "Quotechange",
    "trade.setPriceAlarm.labelline": "Early warning",
    "trade.setPriceAlarm.label": "Price alert",
    "header.account.menu.logout": "Sign out",
    "header.account.menu.balance": "Balance Details",
    "header.account.menu.deposit": "Receive Crypto",
    "header.account.menu.withdraw": "Withdraw",
    "header.account.menu.referral_Program": "Referral Program",
    "header.account.menu.customer.service": "Customer Service",
    sign_up_title: "Register",
    sign_in_title: "Sign in",
    sign_up_hint_account_phone: "Phone number",
    sign_up_hint_account_email: "Email",
    sign_up_hint_password: "Password",
    sign_up_hint_passwordnew: "Password New",
    sign_up_hint_verification_code: "Verification code",
    sign_up_send_verification_code: "Send",
    sign_up_resend_time_verification_code: "Resend ({seconds}S)",
    sign_up_button: "Register",
    sign_in_button: "Sign in",
    forget_password: "Forgot password?",
    verify_hint_phone_number: "Phone number",
    verify_hintverify_hint: "You need to bind your phone number first",
    invalid_username_toast_phone: "Please input a valid phone number",
    invalid_username_toast_email: "Please input a valid email address",
    invalid_password_toast: "The password should be 8-16 characters, including 1 lowercase letter, 1 uppercase letter and 1 number",
    invalid_verification_code_toast: "The verification code is 6 digits",
    verification_code_error: "Wrong verification code",
    verification_code_not_send: "You haven't sent the verification code yet",
    sign_up_success: "Welcome to Pionex.US！",
    account_already_exists: "This account already exists. Please sign in with it",
    account_already_exists_password_error: "This account already exists. If you forgot your password, please click Forgot Password.",
    account_activity_page_title: "My account",
    sign_in_account_not_exists: "The account doesn't exist.",
    sign_in_invalid_password: "Invalid password",
    sign_out_button: "SIGN OUT",
    google_authenticator: "Google Authenticator",
    google_Authentication_chino: "(Authenticator)",
    google_authenticator_unbinded: "Unbound",
    google_authenticator_binded: "Bound",
    google_authenticator_bind_step_1: "Please paste the account name and private key into Google Authenticator.",
    google_authenticator_bind_step_2: "Get your verification code from Google Authenticator and input it：",
    google_authenticator_bind: "Bind",
    google_authenticator_bind_success: "Bound successfully.",
    google_authenticator_bind_failed: "Failed to bind.",
    google_authenticator_unbind_success: "Unbind successfully",
    google_authenticator_unbind_failed: "Failed to unbind.",
    google_authenticator_verify_title: "Verify your identity",
    google_authenticator_verify_tips: "Input the code in Google Authenticator",
    google_authenticator_unbind_tips: "Google Authenticator is used for withdrawals and sign in verification. You will lose protection if you unbind it.",
    google_authenticator_unbind_button: "Unbind",
    google_next: "CONTINUE",
    unbind_google_authenticator_title: "Unbind your Google Authenticator",
    account_migrate_title: "Account upgrade",
    account_migrate_content: "You have to upgrade your account to the new account system to sign in. Please reset your password first.",
    account_migrate_reset_password_button: "Reset password",
    verify_code_phone_tips: "Please input your verification code.",
    verify_code_email_title: "Email verification code",
    verify_code_email_tips: "Please input your email verification code.",
    verify_code_6_digit_invalid: "Please enter 6-digit code!",
    input_new_password: "Please input your new password.",
    verify_code_phone_title: "Verification code",
    reset_password_success: "Reset password successfully. Please use your new password to sign in.",
    network_error: "Network error",
    retry: "Retry",
    share_download_title: "Search&Download {app_name}",
    share_grid_qr_desc: "Best strategy in swinging market, Grid Trading. Avg annualized profit can be 100%+",
    trade_buy: "Buy",
    trade_sell: "Sell",
    trade_pricePlaceHolder: "Price",
    trade_quantityPlaceHolder: "Amount",
    trade_available: "Available balance:",
    trade_volume: "Volume:",
    trade_commsion_fee: "Commission fee:",
    trade_tip_input_price: "Please input price",
    trading_filled: "Filled",
    trade_bot_no_ai_main_title: "NO AI strategy for this trading pair",
    trade_bot_no_ai_main_sub_title: "Please tap SET MYSELF to set the parameters.",
    order_grid: "Grid trading",
    balance_need: "Volume",
    moon_grid_order_create: "Create {base}/{quote} Bot ",
    grid_bottom_text: "Profit per grid",
    grid_order_tip_sub_content1:
        "Set your price range, divide your investment into several parts and automatically buy low sell high for you ,when price goses down the bot will buy gradually and when price goes up it will also sell gradually, form which the bot help you earn lots of stable grid profits.",
    grid_order_tip_sub_content2:
        "First step: Select pair that fluctuates a lot and its base coin is undervalued\nSecond step: according to price trend, decide your grid bot price range, the highest price and the lowest price\nThird step: Decide the number of your grid\nFourth Step: Decide your investment per grid\nYou could setup Stop-loss price if you need.(optional)\n\nPlease note: Since the grid bot need to trade 7*24 ceaselessly, you need to upload your API key to BitUniverse server, so please upload your API key in the next step.",
    grid_order_tip_article_web: "Article tutorial: ",
    grid_order_tip_video_web: "Video tutorial: ",
    grid_order_low: "Lower limit price",
    grid_order_stop: "Stop-loss price (optional)",
    grid_order_amount: "Quantity per grid",
    grid_order_number: "Grids quantity",
    grid_invest: "Total investment ({quote})",
    grid_profit: "Realized profit ({quote})",
    grid_low_price: "Lower limit price ({quote})",
    gird_row_count: "Grids quantity",
    grid_unrealized_profit: "Unrealized profit ({quote})",
    grid_high_price: "Upper limit price ({quote})",
    grid_value: "Quantity per grid",
    grid_total_profit: "Total profit({quote})",
    grid_stop_loss_price: "Stop-limit price ({quote})",
    grid_stop_loss_price_1: "Stop-limit price",
    trading_bot_guide_grid_go_trading_toast: "Switch to grid bot trading",
    trading_bot_guide_grid_go_trading_choose_toast: "Please choose grid bot order",
    grid_transaction_detail: "Transaction details",
    grid_detail_title: "Transactions",
    grid_number_invalid: "Invalid grids quantity. It should be between 2 and 150",
    grid_quantity_check_err: "Current available balance is less than the required balance.",
    grid_dialog_button_no: "DISCARD",
    grid_dialog_button_yes: "YES",
    grid_recover_dialog_content:
        "In order to recover the grid bot, you need to buy {baseInfo} first. We will buy it for you with the current optimal price after you have confirmed.",
    grid_recover_dialog_content_1:
        "In order to recover the grid bot, you need to sell about {baseInfo}. We will sell it for you with the current optimal price after you have confirmed.",
    grid_not_enough_quote: "Need {count} {quote} to start grid bot",
    grid_place_use_ai_strategy: "Use AI strategy",
    grid_place_use_myself: "Set myself",
    grid_place_choose_asset: "Investment：",
    price_line_type_30d: "30D",
    price_line_type_90d: "90D",
    price_line_type_180d: "180D",
    price_line_type_1y: "1Y",
    price_line_type_full: "FULL CANDLE",
    grid_ai_strategy_title: "AI assistant",
    grid_ai_loading_tip: "Backtesting & Calculating...",
    grid_ai_no_strategy_tip: "No AI strategy for this pair，please set parameters yourself/",
    grid_ai_backtesting_title: "7 Days backtest annual return",
    grid_ai_price_range: "Price range",
    grid_ai_grid_quantity: "Grid trading bot",
    ai_symbol_tag_title: "AI assistant",
    ai_choose_tip: "Choose an AI strategy to run the grid trading bot",
    ai_request_loading_tip: "Calculating minimum required balance...",
    trade_symbol_ai_no_result: "No AI strategy now",
    ai_min_invest_warning: "The minimum required balance for this strategy is",
    grid_trade_create_loading_title: "Creating order now...",
    grid_ai_earn: "Earn",
    grid_ai_picked: "Picked",
    grid_tab_ai_create_tip: "Please drag the bar to choose your investment.",
    ai_request_detail_err: "Failed to load the minimum required balance.",
    order_circle_sell: "SELL",
    order_circle_buy: "BUY",
    order_circle_grid: "GRID",
    tip_article: "Check",
    custom_order_cancel_warning_title: "Are you sure to cancel this order？",
    custom_order_cancel_warning_content: "If you have already created a grid trading bot, and this order is related to it. Your bot may be calcelled if you cancel this order.",
    cancel_order_title: "Grid trading bot cancellation",
    cancel_order_desc: "If you canceled, the sever will stop running grid bot and will cancel all the placed orders.",
    order_title_price: "Price ({quote})",
    order_title_quantity: "Quantity ({base})",
    order_title_filled: "Filled ({base})",
    order_type_limit: "Limit",
    order_type_market: "Market",
    button_ok: "OK",
    button_cancel: "Cancel",
    button_confirm: "Confirm",
    button_discard: "Discard",
    button_verify: "Verify",
    button_copy: "Copy",
    grid_order_lasting_time: "{day}d {hour}h {minute}m {second}s",
    trailing_stop_limit_not_support_tips: "We haven't supported {market} for Trailing Take Profit. Supported exchanges：{supportList}",
    order_trailing_stop_limit: "TRAILING TAKE PROFIT",
    trailing_take_profit: "TRAILING TAKE PROFIT",
    trailing_stop_limit_order_buy_price: "Buy price",
    trailing_stop_limit_order_sell_price: "Sell price",
    trailing_stop_limit_order_trigger_price: "Trigger price",
    trailing_stop_limit_order_average_buy_price: "Average buy price",
    trailing_stop_limit_order_trailing_percent: "Trailing percent",
    trailing_stop_limit_order_buy_quantity: "Buy quantity",
    trailing_stop_limit_order_sell_quantity: "Sell quantity",
    trailing_stop_limit_take_the_profit: "Confirm Profit",
    trailing_stop_limit_confirm_the_loss: "Confirm Loss",
    trailing_stop_limit_confirm_the_loss_toast: "Don't be sad. You'll make profits next time!",
    trailing_stop_limit_cancel_order_title: "Cancel Trailing Take Profit",
    trailing_stop_limit_cancel_order_content: "Are you sure to cancel this order？",
    order_status_canceling: "Cancelling",
    realized_profit: "Realized profit",
    unrealized_profit: "Unrealized profit",
    add_exchange_btn: "Import new exchange",
    order_stop_limit: "Stop Limit",
    order_trigger_price: "Trigger price",
    order_trigger_sell_price: "Sell price",
    order_trigger_buy_price: "Buy price",
    order_trigger_limit_buy_price: "BUY",
    order_trigger_limit_sell_price: "SELL",
    trailing_stop_limit_share_title: "Trailing take profit, taking only {percent}% risk to get profit",
    trailing_stop_limit_share_content: "Best strategy for the pumping market. Auto profit trading bot using API.",
    trailing_stop_limit_share_avg_buy_price: "Buy price",
    trailing_stop_limit_share_trigger_price: "Trigger price",
    trailing_stop_limit_share_trailing_percent: "Trailing percent",
    //量化订单 手动订单的两个人tab"":"",
    quantitative_Order: "BOT",
    wanual_Order: "MANUAL",
    grid_launch_failed: "Failed to calculate grid bot order number",
    grid_invalid_number: "Wrong number format",
    grid_err_error_duplicate_grid:
        "The grids quantity you set is too high. We can't generate that many orders in the current price range. Please decrease the grids quantity and create order again.",
    grid_err_error_manual: "Unknown error. Please try again later or contact the support team.",
    //donwloadapp
    downloadapp: "Download app",
    // apikey 页面
    importkeytitle_title: "Welcome to use BitUniverse grid trading bot",
    API_Key: "API Key",
    Secret_Key: "Secret Key",
    key_import_extra: "Passphrase",
    key_import_extra_email: "Email",
    submint_Import: "Import",
    annualized_return: "Estimated annual return",
    annualized_completrimporkey_iwantstategy: "I want to set my own strategy",
    //google
    "wxewmimg.text": "1.Please use your Google Authenticator to scan this QR code",
    "wxexright.txt": "If you cannot scan this QR code, you can import the above 16-bit private key into your Google Authenticator.",
    "verification.text": "Input the verification code from Google Authenticator",
    verification_cod: "",
    verification_text:
        "Please write down the 16-bit private key on paper and keep it safe. If you lose your phone, you can restore your Google Authenticator using this private key.",
    verification_bottom_text: "You can reset your Google Authenticator by applying with email, and it may take at most 7 days to deal with.",
    key_import_tip: "Importing API Key...",
    key_import_failed_not_login: "Please sign in before importing the API Key.",
    key_import_invalid_apikey: "Please import a valid API Key",
    key_import_invalid_secret: "Please import a valid Secret Key",
    key_import_invalid_extra: "Please import a valid Passphrase",
    key_import_invalid_extra_email: "Please import a valid Email",
    key_import_failed_not_success: "Imported successfully",
    key_import_failed_not_failure: "Failed to import. Reason:",
    key_import_loading: "Checking and importing...",
    // 导航上的设置
    heard_setting: "Settings",
    // "Currency_setting":'Currency setting',
    language_setting: "Language setting",
    // "Dark_mode":'Color mode',
    // "Google_Authenticator":'Google_Authenticator',
    strategy_picked: "Picked",
    delet_key: "Delete",
    enter_delet_key: "Are you sure to delete？",
    restore_key: "Delete the API Key",
    reverse_green_red: "Reverse Red/Green",
    red_up_green_down: "Red as bull market",
    green_up_red_down: "Green as bull market",
    videotexts: "Your browser doesn't support playing video. Please update or use Chrome.",
    // header
    header_pair_vol: "PAIR/VOL",
    header_pair: "PAIR",
    header_vol: "VOL",
    header_price: "PRICE",
    header_24hchange: "24H CHANGE",
    quotestchart_24hight: "24H High",
    quotestchart_24low: "24H Low",
    quotestchart_24vol: "24H Vol",
    quotestchart_24cap: "Coin Cap:",
    quotestchart_24rank: "Cap Rank:",
    quotestchart_Circulate_Supply: "Circulation:",
    quotestchart_Quantity: "Quantity",
    running_stoplimit: "STOP LIMIT",
    running_gridtrading: "GRID TRADING",
    running_trailing: "TRAILING TAKE PROFIT",
    running_manual: "MANUAL",
    running_buysell: "BUY SELL",
    running_runningstatus: "Realized profit:",
    running_parameters: "Parameters:",
    running_total_profit: "Total profit:",
    running_fee: "Fee",
    running_Unrealized_profit: "Unrealized profit:",
    running_zanwudingdan: "No order",
    tebl_ai: "AI",
    historyTable: "History orders",
    running_grids: "Grids",
    gridtrading_Gridsquantity: "Grids quantity:",
    gridtrading_Advisor: "By AI Advisor",
    note_video: "Your browser doesn't support playing videos.",
    favor_success: "Picked successfully",
    unfavor_success: "Removed from picks",
    keylist_model_title: "Delete API Key",
    keylist_model_contect: "Are you sure to delete this API Key?",
    keylist_model_quedingbutton: "CONFIRM",
    keylist_model_shanchubutton: "DISCARD",
    // 后添加
    bitu_grid_trading: "GRID TRADING",
    bitu_grid_trailing_take_profit: "TRAILING TAKE PROFIT",
    bitu_grid_stop_limit: "STOP LIMIT",
    bitu_grid_buysell: "BUY/SELL",
    runningCard_nodata: "No grid trading orders.",
    runningCard_nodata_limitorder: "No manual orders",
    runningCard_nodata_stopLimit: "No stop limit orders",
    runningCard_nodata_trailingTakeProfit: "No trailing take profit orders",
    cancel_order_success: "Cancelled the order successfully.",
    cancel_order_failed: "Failed to cancel the order. Reason:",
    price_range: "Price range",
    history_price_text: "Price",
    history_quantity: "Quantity",
    history_time: "Time",
    running_orders: "Running orders",
    running_order: "Running orders",
    light_mode: "Light mode",
    dark_mode: "Dark mode",
    history_orders: "History orders",
    currency_setting: "Currency setting",
    login_choose_exchange: "Sign in to choose the exchange",
    app_global_loading: "Loading...",
    trade_key_delete_content: "Are you sure to delete {market}'s API Key? Your Bot orders will also be cancelled if you have created.",
    no_data: "No data",
    done: "DONE",
    key_import_exist_error: "Cannot import existed keys",
    index_Protection: "Competitive Trading Community",
    index_encryption:
        "Pionex.US will hold trading competition regularly, the scores and ranks of traders will be recorded on blockchain forever. Top rankers could set up quant fund in Pionex.US and share profits.",
    index_Trading_bot: "Trading Bots",
    index_tabs_productSection: "Grid Trading",
    index_tabs_Investment: "DCA (Dollar-Cost Averaging)",
    index_tabs_Rebalanced: "TWAP",
    index_tabs_Trailing_Take_Profit: "Trailing Take Profit",
    index_tabs_Parentrs: "Partners and Investors",
    mac_cryptocurrency1: "A new species is born.",
    mac_cryptocurrency2: "The pioneer of cryptocurrency brokers.",
    // Balance
    "balance.hide_small_balance": "Hide small balance",
    "balance.coins.option.deposit": "Deposit",
    "balance.coins.option.receive": "Receive",
    "balance.coins.option.withdraw": "Withdraw",
    "balance.coins.option.send": "Send",
    "balance.coins.option.trade": "Trade",
    "balance.table.coin": "Coin",
    "balance.table.full_name": "Full name",
    "balance.table.quantity": "Quantity",
    "balance.table.balance.usd": "Value",
    "balance.table.frozen_balance.usd": "Occupied",
    "balance.table.equivalant.btc": "Equivalent BTC",
    // Deposit ant Withdraw
    "dw.tips.title": "Tips",
    "deposit.tips.content":
        "Send only {coin} to this deposit address. Sending any other coin or token to this address may result in the loss of your deposit. Coins will be deposited immediately after 10 network confirmations After making a deposit, you can track its progress on the history page.",
    "deposit.address": "Address",
    "deposit.address.qrcode": "QR CODE",
    "deposit.address.copy": "COPY",
    "deposit.address.copy.memo": "COPY",
    "withdraw.input.address": "Address",
    "withdraw.input.address.error": "Please input withdraw address.",
    "withdraw.input.quantity": "Quantity",
    "withdraw.input.quantity.error": "Please input the quantity.",
    "withdraw.input.quantity.autoall": "All",
    "withdraw.fee.notice": "Transaction Fee",
    "withdraw.fee.reduce.value": "You will get",
    "withdraw.submit": "SUBMIT",
    "withdraw.submit.confirm.title": "Are you sure to send your {coin}",
    "withdraw.submit.confirm.content": `
    Coin Name: {coin} - {coinName}
    <br/>
    To Address: {address}
    <br/>
    Available Quantity: {availableQuantity} {coin}
    <br/>
    Withdraw Quantity: {withdrawQuantity} {coin}
    <br/>
    Fee: {fee} {coin}
  `,
    "withdraw.submit.confirm.content_memo": `
    Coin Name: {coin} - {coinName}
    <br/>
    To Address: {address}
    <br/>
    {memoName}: {memo}
    <br/>
    Available Quantity: {availableQuantity} {coin}
    <br/>
    Withdraw Quantity: {withdrawQuantity} {coin}
    <br/>
    Fee: {fee} {coin}
  `,
    "withdraw.submit.confirm.content.coin": "Coin Name",
    "withdraw.submit.confirm.content.address": "To Address",
    "withdraw.submit.confirm.content.availableQuantity": "Available Quantity",
    "withdraw.submit.confirm.content.withdrawQuantity": "Sending Quantity",
    "withdraw.submit.confirm.content.fee": "Fee",
    "withdraw.submit.confirm.content.comment": "Tag name",
    "withdraw.submit.confirm.cancel": "DISCARD",
    "withdraw.submit.confirm.continue": "CONTINUE",
    "withdraw.confirmation.title": "Confirmation",
    "withdraw.confirmation.phone.code": "Input your SMS verification code",
    "withdraw.confirmation.email.code": "Input your email verification code",
    "withdraw.confirmation.phone.code.error": "Invalid verification code.",
    "withdraw.confirmation.email.code.error": "Invalid verification code.",
    "withdraw.confirmation.phone.code.send": "Send",
    "withdraw.confirmation.phone.code.resend": "Resend",
    "withdraw.confirmation.google.code": "Input the verification code from Google Authenticator.",
    "withdraw.confirmation.google.code.error": "Invalid Google 2FA code.",
    "withdraw.confirmation.confirm": "CONFIRM",
    "withdraw.confirmation.confirm.success": "Submitted successfully!",
    "withdraw.confirmation.phone.label": "Phone Number",
    "withdraw.confirmation.emial.label": "Email",
    "withdraw.confirmation.phone.code.label": "SMS verification code",
    "withdraw.confirmation.email.code.label": "Email verification code",
    "withdraw.confirmation.google.code.label": "Google authentication code",
    "withdraw.tips.content": "Please confirm in your email after you have submitted.",
    "withdraw.tips.content_dos": "The withdraw time may take a bit longer, usually within 24 Hours. We're in beta version and focus more on asset security which takes more time.",
    "withdraw.balance.not.enough": "No enough balance",
    "withdraw.limit.min": "The sending amount must be greater than {min} {coin}",
    "withdraw.limit.max": "The sending amount should be less than {max} {coin}",
    "withdraw.limit.max.zero": "Insufficient available balance",
    "withdraw.limit.balance": "The sending amount should be less than available amount {balance} {coin}",
    "withdraw.limit.nobalance": "You have no coin.",
    withdraw_limit_24h_quota: "The quantity should be smaller than {quota}",
    withdraw_limit_maximum_transaction_amount: "Maximum transaction amount {amount}",
    withdraw_limit_balance: "The quantity should be smaller than {balance}",
    withdraw_limit_max_zero: "Insufficient available balance",
    "withdraw.limit.address_len_error": "The sending address you input is too short to be legal, please input once again",
    "withdraw.limit.address_OMINI_error": "The sending address you input doesn't match OMNI format, please input once again",
    "withdraw.limit.address_ERC20_error": "The sending address you input doesn't match ERC20 format, please input once again",
    withdraw_success_content: "Crypto Sending Request Successful",
    withdraw_success_content1: "Your crypto sending request has been confirmed. You can track its progress by the sending history.",
    withdraw_success_content2:
        "Your withdrawal request has been confirmed. However, the withdrawal just opened in the early stage, all withdrawals will be processed together at 8 am - 9 am (UTC) every day. Thanks for your patience.",
    withdraw_success_btn: "Check withdrawal history",
    withdraw_limit_text: "24H withdraw limit",
    withdraw_no_reserve_tip: "{coin} deposit/withdrawal has just opened in the early stage, all withdrawals will be processed together from 8 am - 9 am (UTC) every day.",
    face_verify_timeout_tip: "If the review process is taking longer than expected, please contact customer service.",
    face_verify_waiting_tip: "Reviewing, please wait",
    face_verify_waiting_quit_tip: "A review is in progress. Please note, premature quit could impact the withdrawal.",
    face_verify_waiting_times: "The review usually takes around 3 minutes.",
    hint_modal_title: "Hint",
    deposit_maintain_title: "{currency} is under Maintenance",
    deposit_maintain_content: "Deposit will be ready to use when maintenance is completed",
    receive_maintain_content: "Receive will be ready to use when maintenance is completed",
    withdraw_maintain_content: "Withdraw will be ready to use when maintenance is completed",
    send_maintain_content: "Send will be ready to use when maintenance is completed",
    deposit_maintain_check: "Details",
    withdraw_no_reserve_pop: "All {coin} withdrawals will be processed together from 8 am - 9 am (UTC) every day,  your withdrawal will be confirmed after it's processed",
    balance_tip_title: "Tips:",
    balance_tip_content:
        "Send only {coin} to this address. Sending any other coin or token to this address may result in the loss and can not be returned. Coins will be deposited immediately after {block} blocks confirmation. You can track the process below.",
    balance_tip_content_receive:
        "Send only {coin} to this address. Sending any other coin or token to this address may result in the loss and can not be returned. Coins will be received immediately after {block} blocks confirmation. You can track the process below.",
    balance_tip_content_withdraw:
        "Please make sure the {coin} address you input is correct. If you input a wrong address or forget to input some necessary information (like {memo} for some coin), Pionex.US won't cover the loss for you. ",
    balance_tip_bottom_content: "Pionex.US has supported depositing through smart contract now, but it'll take about 24H to confirm.",
    withdraw_tip_bottom_btc_content: "Pionex.US doesn't support withdrawal through BTC lightning network.",
    withdraw_tip_bottom_content: "For your assets safety, it needs manual reviews for big amount withdrawal. Usually it takes 10-30 minutes.",
    send_tip_bottom_content: "For your assets safety, it needs manual reviews for big amount transfer. Usually it takes 10-30 minutes.",
    balance_coin_select_title: "Choose coin",
    "dw.history.status.ok": "Complete",
    "dw.history.status.pending": "Pending",
    "dw.history.status.failed": "Failed",
    "dw.history.status.submitted": "Pending",
    "dw.history.title.deposit": "Deposit history",
    "dw.history.title.receive": "Receive crypto history",
    "dw.history.title.withdraw": "Withdrawal history",
    "dw.history.title.send": "Send crypto history",
    "dw.history.table.coin": "Coin",
    "dw.history.table.quantity": "Quantity",
    "dw.history.table.time": "Time",
    "dw.history.table.transaction.id": "Transaction ID",
    "dw.history.table.address": "Address",
    "dw.history.table.status": "Status",
    "dw.history.status.cancel": "Cancel",
    "dw.history.status.canceled": "Canceled",
    "dw.history.only.show.current.coin": "Only show current coin",
    "dw.coin.go.trading.btns": "Go trading",
    "dw.coin.info.balance": "Total balance",
    "dw.coin.info.frozen": "Frozen",
    "dw.coin.info.available": "Available",
    "dw.coin.not.support.tip": "Deposit and withdraw are not supported so far.",
    pionex_balance: "Balance",
    header_Trade: "Trade",
    header_my_Bots: "My Bots",
    balance_details: "Balance Details",
    balance_hide: "Hide Balance",
    balance_show: "Show Balance",
    balance_loading_tip: "Loading balance...",
    deposit_withdraw_more: "Deposit/Withdraw is here",
    the_investment_text: "The investment should be no bigger than",
    tradingtake_titletext: "Please set the parameters.",
    takeProfit_useai: "Set myself",
    takeProfit_setmyself: "Use AI strategy",
    order_manual_title: "Manual",
    order_buy_crypro_title: "Buy Crypto",
    order_buy_crypto_bought: "Bought orders",
    order_buy_crypto_sold: "Sold orders",
    order_buy_crypto_convert: "Convert orders",
    order_buy_crypto_bought_title: "Bought(Placed Orders)",
    order_buy_crypto_sold_title: "Sold(Placed Orders)",
    order_buy_crypto_convert_title: "Convert(Placed Orders)",
    order_buy_crypto_buy_now: "No data,deposit now",
    history_order_manual_title: "History Manual Orders",
    order_trading_bot_title: "Trading Bot",
    history_order_trading_bot_title: "History Trading Bots",
    show_this_pair_only: "Show this pair only",
    order_detail_text: "Detail",
    order_share_text: "Share",
    order_title_time: "Time",
    order_title_operation: "Operation",
    order_title_pair: "Pair",
    order_title_prices: "Price",
    order_title_quantitys: "Quantity",
    order_title_avg_price: "Avg Price",
    order_title_filleds: "Filled",
    order_history_nav_title: "Order History",
    order_title_bot: "Bot",
    order_title_investment: "Investment",
    order_title_profit: "Profit",
    order_cancel_text: "Cancel",
    order_canceling_text: "Canceling",
    tip_copy_success: "Copied successfully",
    custom_order_cancel_title: "Are you sure to cancel this order?",
    share_way_title: "Choose a method for sharing",
    referral_link: "Referral link",
    referral_code: "Referral code",
    referral_rules_title: "Referral rules",
    referral_rules_content: `
  1. Invite your friends to register by sending them your referral link and teach them how to create a grid trading order.
    <br/>
    2. You'll get 50 BUB when their trading volume have hit 20,000 USDT."`,
    referral_history_reward: "History Reward",
    referral_bub_rank: "BUB Rank",
    referral_invitees: "Invitees",
    referral_today_invitees: "Today's invitees",
    referral_today_reward: "Today's reward",
    list_title_account: "Account",
    list_title_sign_up: "Register",
    list_title_grid_Trading: "Grid Trading",
    list_title_grid_reward: "Reward",
    referral_used: "Used",
    referral_not_used: "Not used",
    referral_yes: "Yes",
    referral_no: "No",
    list_title_rank: "Rank",
    list_title_user: "User",
    list_title_bub: "BUB",
    referral_code_place_holder: "Referral code (optional)",
    bot_recover_tip_balance: "This order is accidentally cancelled because your {quote} balance is not enough. Please buy some first.",
    bot_recover_tip_invalid_api_balance: "This order is accidentally cancelled because the exchange API is not stable.",
    bot_recover_tip_default: "This order is accidentally cancelled because of unknown error.",
    bot_recover_tip_invalid_api: "This order is accidentally cancelled because your API Key invalid.",
    bot_recover_tip_user_cancel: "This order is accidentally cancelled because you manually cancelled an order in the exchange",
    // 策略广场卡片
    strateg_search_coin_name: "Search coin name",
    strateg_Min: "Min",
    strateg_Hour: "Hour",
    strateg_Day: "Day",
    strateg_Required_balance: "Required balance",
    manuelmaket_trade_Commission: "Commission fee:",
    manuelmaket_trade_quantity_each_time: " Buy quantity each time:",
    strateg_TWAP: "TWAP (Time-weighted average price)",
    strateg_stop_limit: "Stop limit",
    pionex_HistoricalOrderDetails: "My trading bots",
    all_title_desc: "All",
    grid_title_desc: "Grid Trading (old)",
    stop_limit_title_desc: "Stop Limit",
    trailing_stop_title_desc: "Trailing Take Profit",
    aip_title_desc: "Recurring Buy",
    account_sign_expired: "You haven't signed out for more than 24 hours. Please sign in again to keep safe.",
    operate_limit_buy: "Buy limit",
    operate_limit_sell: "Sell limit",
    operate_market_buy: "Buy market",
    operate_market_sell: "Sell market",
    volume_label_short: "Vol:",
    grid_param_high: "High Price",
    grid_param_low: "Low Price",
    grid_current_price: "Current Price",
    grid_param_grids: "Grids",
    grid_param_transactions: "Transactions",
    grid_param_lasting: "Lasting time",
    grid_param_per_vol: "Quantity per grid",
    grid_param_stop: "Stop-loss",
    trading_bot_tab_grid_content_title: "Grid Trading Bot",
    trading_bot_tab_aip_content_title: "Recurring Buy",
    trading_bot_tab_ttp_content_title: "Trailing Take Profit",
    trading_bot_tab_sl_content_title: "Stop Limit",
    trading_bot_tab_twap_content_title: "TWAP",
    trading_bot_tab_grid_content_desc: "Make profits in a swing market",
    trading_bot_tab_ttp_content_desc: "Use TTP when you think the price will pump",
    trading_bot_tab_sl_content_desc: "Set limit price ahead of time",
    trading_bot_tab_aip_content_desc: "From bear to the moon",
    trading_bot_tab_twap_content_desc: "Time-weighted average price",
    trading_bot_tab_score_label: "Current market fitness :",
    trading_bot_tab_create_label: "Create",
    trading_page_tab_bot: "Trading Bot",
    trading_page_tab_limit: "MANUAL LIMIT",
    trading_page_tab_market: "MANUAL MARKET",
    trading_bot_market_buy_price_desc: "Buy with market price",
    trading_bot_market_sell_price_desc: "Sell with market price",
    trading_bot_market_buy_amount: "Quantity",
    trading_bot_market_sell_amount: "Quantity",
    grid_detail_stop: "Stop",
    grid_detail_transactions: "TRANSACTIONS",
    grid_detail_share: "Share",
    grid_transaction_time: "Time",
    grid_transaction_profit: "Profit",
    grid_transaction_buy_limit_tile: "BUY LIMIT",
    grid_transaction_sell_limit_tile: "SELL LIMIT",
    grid_transaction_price: "Price",
    grid_transaction_quantity: "Quantity",
    grid_transaction_total: "Total",
    grid_transaction_avg_price: "Avg Price",
    custom_order_cancel_all_text: "Cancel all",
    custom_order_cancel_all_warning_title: "Are you sure to cancel all manual orders?",
    custom_order_cancel_all_warning_content: "Are you sure to cancel all manual orders? Cancellation could cause misfuncitons of current bots ",
    grid_order_canceled_warning_title: "Are you sure to cancel this bot？",
    grid_order_canceled_warning_content: "Are you sure to cancel this bot? The bot cannot be recovered if canceled",
    bot_order_canceled_warning_title: "Are you sure to cancel this bot？",
    bot_order_canceled_warning_content: `Bot type: {orderType}</br>Pair: {pair}`,
    trading_twap_buy_period: "Buy period",
    trading_twap_sell_period: "Sell period",
    trading_twap_total_investment_placeHolder: "Total investment",
    trading_twap_total_sales_placeHolder: "Total sales",
    trading_view_time_line_name: "Time",
    trading_view_short_period_minute: "m",
    trading_view_short_period_hour: "H",
    trading_view_short_period_day: "D",
    trading_view_short_period_week: "W",
    order_state_submitted: "Submitted",
    order_state_canceling: "Canceling",
    order_state_canceled: "Canceled",
    order_state_filled: "Filled",
    order_state_partial_filled: "Partial filled",
    aipcard_total_balance: "Total Balance",
    aipcard_mainContent_promp: "Today's market drops. DCA helps you to reduce the average buy price!",
    aipcard_average_buy_price: "Average buy price",
    aipcard_today_profit: "Investment",
    aipcard_frequency: "Frequency",
    aipcard_unit_investment: "Unit investment",
    aipcard_transactions: "Transactions",
    aipcard_stop: "Stop",
    aipcard_share: "Share",
    symbolview_pickes: "Picks",
    askslist_ccumulated: "Sum",
    askslist_quantity: "Quantity",
    bot_share_slogan: "{days} days profit of grid trading",
    bot_share_annual_return: " Estimated annual return {annual_return} ",
    bot_share_pair: "Trading pair",
    bot_share_lasting_time: "Lasting time",
    bot_share_transactions: "Transactions",
    bot_share_bottom_tip: "Grid bot is the best strategy in a swinging bear market. Average annual return reaches 100%+",
    bot_share_lasting_time_value: "{days}d {hours}h {minutes}m",
    bot_share_down_load: "DOWNLOAD PICTURE",
    aip_transaction_price: "Price",
    aip_transaction_quantity: "Quantity",
    customer_service_button: "Got it",
    customer_service_title: "Customer service",
    aip_transaction_next_interval: "{time} left to invest another {vol} {quote} of {base}",
    aip_transaction_hour: "Hour",
    twap_title_desc: "TWAP",
    "stop-limit_title_desc": "Stop Limit",
    aip_share_slogan: "DCA {base} {days}days profit",
    aip_annual_text: "{profit} if DCA from Jan/1 2019",
    twap_complete_percent: "{percent}% Complete",
    twap_avg_buy_price: "Average buy price",
    twap_avg_sell_price: "Average sell price",
    twap_buy_progress_completed: "{amount} {base} bought",
    twap_buy_progress_left: "{amount} {quote} left",
    twap_sell_progress_completed: "{amount} {quote} got",
    twap_sell_progress_left: "{amount} {base} left",
    twap_buy_next_invest: "{time} left to buy another {amount} {quote} of {base}",
    twap_sell_next_invest: "{time} left to sell another {amount} {base}",
    bot_type_grid: "Grid Trading",
    bot_type_stop_limit: "Stop Limit",
    bot_type_trailing_stop: "Trailing Take Profit ",
    bot_type_aip: "Recurring Buy",
    bot_type_twap: "TWAP",
    button_stop: "STOP",
    button_transactions: "Transactions",
    button_share: "Share",
    order_title_fee: "Fee",
    // 手续费说明
    "fees.transaction.title": "Transaction Fees",
    "fees.transaction.maker": "Maker",
    "fees.transaction.taker": "Taker",
    "fees.withdrawal.title": "Withdrawal fees",
    "fees.withdrawal.table.coin": "Coin / Token",
    "fees.withdrawal.table.min": "Minimum withdrawal",
    "fees.withdrawal.table.daily": "Maximum daily withdrawal",
    "fees.withdrawal.table.daily.kyc": "Maximum daily withdrawal(KYC)",
    "fees.withdrawal.table.fee": "Withdraw fee",
    "fees.to.deposit.tip": "Notes: Free to deposit. We only support several coins/tokens to deposit in the early version and will support more gradually.",
    "fees.deposit.th.bank": "Receiving Bank",
    "fees.deposit.th.limit": "Deposit Limit",
    "fees.deposit.th.fees": "Deposit Fees",
    "fees.deposit.th.speed": "Deposit Speed",
    "fees.deposit.th.hodling": "Deposit Holding Time",
    "fees.deposit.th.withdraw.limit": "Withdrawal Limit",
    "fees.deposit.th.withdraw.fees": "Withdrawal Fees",
    "fees.deposit.tc.ach": "Bank Account (ACH)",
    "fees.deposit.tc.debit": "Debit Card",
    "fees.deposit.tc.wire": "Wire Transfer",
    prev_title: "Prev",
    next_title: "Next",
    Starting_the_bot: "Starting the bot",
    app_option_fees: "Fees",
    order_retry_tip: "This order is accidentally canceled. You can cancel this order or try to recover it",
    order_retry_restore: "Recover",
    order_retry_cancel: "Discard",
    bot_delete_retry_order_warning_title: "Cancel this order",
    bot_delete_retry_order_content: "Are you sure to cancel this order? It can't be recovered if you cancel it",
    bot_restore_success: "Recover order successfully",
    bot_restore_failed: "Failed to recover this order，reason：",
    account_search_filter_empty: "No search result",
    bot_share_fixedTrategynew: "7×24 Auto-running",
    bot_share_fixedTrategy: "DCA",
    bot_share_bannertext: "FROM BEAR TO THE MOON",
    timlineJustNow: "2019.6 on board",
    timlinePast: "2019.6 now",
    timlineFuture: "Future",
    grid_transaction_empty: "No transactions yet",
    shejiao_link: "https://t.me/+P2ezWZWM6qMxZDM9",
    twitter_link: "https://twitter.com/pionex.us",
    email_link: "service@pionex.us",
    telegram_link: "https://t.me/+P2ezWZWM6qMxZDM9",
    facebook_link: "https://www.facebook.com/pionexcom",
    bu_invite_link: " https://t.me/butrader",
    sign_up_hint_lock: "Referral code (optional)",
    forget_joinerweima: "JOIN TELEGRAM",
    customer_name: "Telegram",
    order_retry_warning_tip: "It's accidentally canceled because you manually canceled an order.",
    aip_top_title_trend_down: "It's dropping today. DCA helps to decrease average buy price!",
    aip_top_title_trend_shock: "It's swinging today. DCA helps to hold more coins!",
    aip_top_title_trend_up: "It's rocketing today. DCA helps to make trending profits!",
    account_type_email: "Email",
    account_type_phone: "Phone",
    input_place_holder_phone: "Please input your phone number here.",
    input_place_holder_email: "Please input your email address here.",
    input_place_holder_password: "Input your password here.",
    input_place_holder_verify_code: "Input verification code here.",
    all_rights_reserved: "© 2019, Pionex.US, Inc. Ltd. All Rights Reserved.",
    withdraw_amount_invalid: "Input amount is invalid",
    withdraw_verify_code_sent: "Verification code has been sent!",
    not_provide_service_title: "Notice",
    not_provide_service_content:
        "The current account does not support logging in to Pionex.US. If you have any questions, you can contact Pionex.US customer service for consultation.",
    legal_announcement_title: "Legal announcement",
    legal_announcement_content: "Pionex.US doesn't provide services for users of mainland China and US because of their regulations. Sorry for the inconvenience.",
    sign_in_support_tip: "BitUniverse account supported",
    aipcard_total_profit: "Total Profit",
    balance_search_coin_hint: "Search coin name",
    referral_rules_content2: `You can activate 20% commission rebate after you invite 2 skilled invitees.<br/>
        Skilled invitees refer to the invitees who have used the Grid Trading Bots and whose volume has been more than 20,000USDT.`,
    list_title_total_invitees: "Total invitees",
    list_title_invitees: "Invitees",
    list_title_trading_volume: "Trading volume",
    bu_key_balance_detail: "Balance",
    grid_information_url: "https://medium.com/pionex/pionex-grid-trading-bot-e79c64d4ce32",
    dca_information_url: "https://medium.com/pionex/pionex-dollar-cost-averaging-1f5fe24dbcb4",
    twap_information_url: "https://medium.com/pionex/pionex-time-weighted-average-price-twap-2afd4bce05f8",
    ttp_information_url: "https://medium.com/pionex/pionex-trailing-take-profit-bot-56fd00901d5e ",
    ig_information_url: "https://medium.com/pionex/pionex-infinity-grid-bot-9a14a75c80df",
    grid_loan_information_url: "https://medium.com/@mario_40812/new-trading-strategy-credit-grid-on-pionex-edb385702b96",
    grid_leverage_information_url: "https://medium.com/@mario_40812/new-trading-strategy-leveraged-grid-on-pionex-46bb117c7bbe",
    grid_short_information_url: "https://medium.com/@mario_40812/new-trading-strategy-reverse-grid-on-pionex-68605955c1bd",
    pionex_share_desc: "Pionex.US, quantify your trading",
    ig_trading_profit_per_rate: "Profit per grid",
    ig_trading_desc: "It has the lowest price but not the highest price. No worry of running exceeding your grids.",
    transactionAmount_title: "({amount} transactions)",
    ig_trading_min_investment: "Minimum investment",
    ig_title_desc: "Infinity Grid Bot",
    none_order_to_be_cancel: "You have no order to delete.",
    grid_param_current_price: "Current price({quote})",
    grid_bot_lasting_time_title: "Lasting time",
    // 无线网格交易大赛第一页join页
    indefiniteGridCompetition_bannertitle: "Competition Rules",
    indefiniteGridCompetition_bannerButton: "JOIN COMPETITION",
    indefiniteGridCompetition_content_title: "Participation Awards - Up to 100BUB",
    indefiniteGridCompetition_bodypart1:
        "1. Create an “Infinity Grid Bot” on Pionex.US. You'll get the BUB awards according to your Funds-Level and Lasting Time. The Bot has to make more than 50 transactions to be valid in this event and participants can create multiple bots to accelerate the speed of earning BUB.",
    indefiniteGridCompetition_bodypart2: "1. Start an LTC grid trading bot (LTC/XX) with more than $500 USDT.",
    indefiniteGridCompetition_bodypart2_1: "2. The bot should generate more than 50 transactions and last for more than 48 hours.",
    indefiniteGridCompetition_bodypart3:
        "3. The Funds-Level is determined by the funds with single bot. Such as, one participant create two 400 USDT bots. Both of the bots won't qualified cause they didn't reach the lowest requirement 500 USDT.",
    indefiniteGridCompetition_ranking_content_title_dostext: "Trading Volume King - 1,000 BUB",
    indefiniteGridCompetition_bodypart4: "1. Only the trading volume of “Grid Trading Bot” and “Infinity Grid Bot” will be counted.",
    indefiniteGridCompetition_bodypart5: "2. Top 10 participants will be awarded the following prize",
    indefiniteGridCompetition_bodypart6:
        "* The trading volume of previous trading king was 1,200,000 USDT in 7 days. There's the minimum trading volume for each rank: Rank 1 — Trading Volume should be higher than 2,000,000 USDT, Rank 2 — Trading Volume should be higher than 1,000,000 USDT, Rank 3 — Trading Volume should be higher than 500,000 USDT, Rank 4~10 — Trading Volume should be higher than 100,000 USDT",
    indefiniteGridCompetition_bodypart7:
        "** Rule of the trading fee discount: the trading fee will be rebated on monthly basis (including the trading fee in the competition period). Only the trading volume from “Grid Trading Bot” and “Infinity Grid Bot” will be rebated. Base currency rebated for buy orders, quote currency rebated for sell orders.",
    indefiniteGridCompetition_ranking_content_title_tres: "Annualized Profit Competition — Win 300 BUB even with small funds",
    indefiniteGridCompetition_bodypart8:
        "1. During the event period, participants will also join the “Annualized Profit Return” Leaderboard, if their fund of infinite grid bot is more than 500 USDT and the lasting time should be more than 48 hours",
    indefiniteGridCompetition_bodypart9:
        "2. Participants could create multiple bots to compete for the championship. Only the highest score will be recorded under the same account.",
    indefiniteGridCompetition_ranking_content_title_cuatros: "Notes：",
    indefiniteGridCompetition_bodypart10: "1. Pionex.US reserves the right to cancel or amend the Competition or Competition Rules at our sole discretion.",
    indefiniteGridCompetition_bodypart11:
        "2. Risk Warning: Digital currency is a high-level risk investment. Investors should be cautious in purchasing and pay attention to investment risks.",
    indefiniteGridCompetition_bodypart12:
        "3. Winner can pick one of the following 20 assets to list on Pionex.US: LINK, HC, XTZ, XEM, ZEC, LSK, BTG, QTUM, DOGE, VET, BAT, OMG, DCR, BTT, KMD, NPXS, BTM, WAVES, ZRX, BTS",
    // indexborder1
    indefiniteGridCompetition_dataindex1: "Funds-Level",
    indefiniteGridCompetition_dataindex2: "Lasting Time of Grid Bot (eg.)",
    // indexborder2
    indefiniteGridCompetition_FundsLevel: "Funds Level",
    indefiniteGridCompetition_BUBAwards: "BUB Awards",
    indefiniteGridCompetition_Superprivilege: "Super privilege",
    indefiniteGridCompetition_tableaddress1: "The privilege to list an asset on Pionex.US, 0.03%(40% off) trading fee for one month",
    indefiniteGridCompetition_tableaddress2: "0.04%(20% off) trading fee for one month",
    indefiniteGridCompetition_tableaddress3: "0.045%(10% off) trading fee for one month",
    infiniTegridText: "Trading Competition",
    liveCompetition: "Live competition",
    whitePaper: "What's Pionex.US",
    competition_infinitegrid: "Infinlty Grid Trading Competition",
    Kingof_Trading_left: "Trading Volume King- $3,000 LTC+600 BUB",
    Kingof_Trading_right: "Annualized Profit King- $5,000 VNS",
    Participation_award: "Participation Award-",
    Join_competition: "Join competition now and got 100 BUB of participation award ",
    join_Now: "Join Now",
    infinitegrid_competition: "{coinName} Grids Trading Competition",
    // link
    leader_telegram_link: "https://t.me/+P2ezWZWM6qMxZDM9",
    leader_mediumcircle_link: "https://medium.com/pionex",
    leader_twitter_link: "https://twitter.com/pionex.us",
    leader_reddit_link: "https://www.reddit.com/r/Pionex.US/",
    rules: "Rules",
    indefiniteGridCompetition_notjoinButton: "Register",
    activity_sign_time: "2019/08/21 02:00 ~ 2019/08/31 02:00 (UTC)",
    activity_sign_time_left: "{days} days {hours} Hours {minutes} Minutes left",
    activity_sign_rank: "Rank",
    activity_sign_trader: "Trader",
    activity_sign_volume: "Volume(USDT)",
    activity_sign_profit: "Annualized Return",
    LevelMore_uno: "Level 1:More than 500 USDT",
    LevelMore_dos: "Level 2:More than 1000 USDT",
    LevelMore_tres: "Level 3:More than 1500 USDT",
    LevelMore_cuatro: "Level 4:More than 2000 USDT",
    iction_activty_time: "2019/07/09 0:00 AM ~ 2019/07/23 0:00 AM (UTC)",
    leaderboard_bannerimg: "leader_banner_list_en",
    leaderboard_bannerimg2: "leader_banner_list2",
    banner_current: "2019 4th",
    balance_grid_profit: "Grids Profit",
    balance_grid_profit_info: "(Includes Grid Trading Bot & Infinity Grids Bot. Fee not deducted)",
    balance_grid_today_profit: "Today's Profit",
    balance_grid_7day_profit: "7-Day Profit",
    balance_grid_30day_profit: "30-Day Profit",
    balance_grid_lifetime_profit: "Lifetime Profit",
    chart_type_hourly: "HOURLY",
    chart_type_daily: "DAILY",
    chart_type_monthly: "MONTHLY",
    "balance.mine_balance": "My Balance",
    "balance.other": "Others ",
    "balance.group_by": "Group by",
    "balance.Bots": "Bots",
    "balance.Coins": "Coins",
    "balance.grid_Bot": "Grid Trading",
    "balance.ig_Bot": "Infinity Grids",
    "balance.DCA_Bot": "DCA",
    "balance.TTP_Bot": "TTP",
    "balance.TWAP_Bot": "TWAP",
    "balance.free": "Free",
    "balance.Detail": "Details",
    OPERATIONS: "Operations",
    tapi_wallet_below_min_limit: "The quantity you input is smaller than the minimum withdrawal requirement.",
    tapi_wallet_above_max_limit: "The quantity you input is greater than the maximum withdrawal requirement.",
    tapi_wallet_above_daily_limit: "The quantity you input is greater than the daily maximum withdrawal requirement.",
    tapi_wallet_below_withdraw_fee: "The quantity you input is smaller than the fee.",
    tapi_wallet_amount_illegal: "Please input a valid quantity.",
    tapi_wallet_balance_not_enough: "You don't have enough balance.",
    tapi_wallet_operate_db_failed: "Failed to write in the database. Please join telegram group and contact the admins: https://t.me/+P2ezWZWM6qMxZDM9",
    tapi_wallet_parameter_error:
        "Some withdrawal parameter is wrong. If you have more questions, please join telegram group and contact the admins: https://t.me/+P2ezWZWM6qMxZDM9",
    tapi_wallet_coin_address_illegal: "The input address is wrong. If you have more questions, please join telegram group and contact the admins: https://t.me/+P2ezWZWM6qMxZDM9",
    tapi_wallet_exist_withdraw_task:
        "You have another incompleted withdraw. If you have more questions, please join telegram group and contact the admins: https://t.me/+P2ezWZWM6qMxZDM9",
    tapi_wallet_deposit_not_confirm:
        "Your one deposit hasn't been confirmed. If you have more questions, please join telegram group and contact the admins: https://t.me/+P2ezWZWM6qMxZDM9",
    tapi_wallet_invalid_operation: "Invalid operation. If you have more questions, please join telegram group and contact the admins: https://t.me/+P2ezWZWM6qMxZDM9",
    tapi_wallet_record_not_exist: "Order not exist. If you have more questions, please join telegram group and contact the admins: https://t.me/+P2ezWZWM6qMxZDM9",
    tapi_wallet_param_error_withdraw:
        "Withdrawal parameter is wrong, If you have more questions, please join telegram group and contact the admins: https://t.me/+P2ezWZWM6qMxZDM9",
    tapi_wallet_parameter_type_error: "Invalid parameters",
    tapi_wallet_overload: "The server is overloading now. Please try again later.",
    tapi_wallet_internal: "Internal error. Please try again later.",
    tapi_wallet_deserialization: "Failed to fetch the data. Please refresh the page and input again.",
    tapi_wallet_serialization: "Failed to generate the json file. Please refresh the page and input again.",
    tapi_wallet_connect_db: "Failed to connect the server. Please refresh the page and input again.",
    tapi_wallet_verify_code: "Invalid verification code.",
    tapi_wallet_invalid_coin: "We haven't supported this coin's withdrawal.",
    tapi_wallet_repeat_request: "Repeated requirement.",
    tapi_wallet_invalid_sub_account_id: "Sub-account ID error",
    tapi_wallet_invalid_withdraw_precision: "Invalid precision. Please shorten the quantity number decimals.",
    withdraw_user_in_black_list: "Your account is not allowed to withdraw for security issue. Please contact the support team.",
    every: "Every",
    time_span_now: "NOW",
    time_span_7d: "7D",
    time_span_1m: "1M",
    CHECK_DETAILED_TXS: "CHECK DETAILED TXS",
    hold_detail_title: "TAP × TO FOLD DETAILED TXS",
    order_title_volume: "Volume",
    close_order_loading: "Loading...",
    label_grid_number: "{number} Grids",
    grid_profit_title: "PROFITS (Fee deducted)",
    grid_check_detail_txs: "CHECK DETAILED TXS",
    grid_hide_detail_txs: "Close transactions",
    aip_Investment_history: "Investment history",
    twap_Investment_history: "TWAP history",
    Investment_history_Value: " ({count} times = {InvestmentCount})",
    aip_running_total_profit: "Total profit",
    order_trigger_average_price: "Price",
    aip_And_Twap_None_Deal: "No transactions so far",
    grid_no_profit: "No grid profit so far",
    creating_order_tip: "Creating the order...",
    creating_order_done: "Created successfully",
    every_month_title: "{count} {quote} per {number}  Month",
    every_week_title: "{count} {quote} per {number} Week",
    every_day_title: "{count} {quote} per {number} Day",
    every_hour_title: "{count} {quote} per {number} Hour",
    every_minute_title: "{count} {quote} per {number} Minute",
    // 充币提示tip
    tipscontent_dos_red: "Pionex.US doesn't support depositing through smart contract now, such as deposit from Wirex, foxbit, Bitstamp.",
    go_check: "GO CHECK",
    new_version_home1: " Welcome to Pionex.US!",
    new_version_home2: "BitUniverse App",
    new_version_home3: "Web",
    new_version_home4: "Quantify Your Trading",
    new_version_home5: "World's 1st exchange designed for cryptocurrency trading bots",
    new_version_home6: "Trading Bot + AI Strategy",
    new_version_home7: "Grid Trading, Infinity Grids, DCA, TWAP, Trailing Take Profit",
    new_version_home8: "Competitive Trading Community",
    new_version_home9: "Hold trading competition regularly, and top ones can build fund in Pionex.US and share profits.",
    new_version_home10: "Low Commission Fee",
    new_version_home11: "The minimum commission fee of Pionex.US is 0.05%",
    new_version_home12: "JOIN TELEGRAM",
    new_version_home13: "TRADE",
    new_version_home14: "TRADE",
    new_version_home15: "Trade everywhere.",
    new_version_home16: "The app version is supported by BitUniverse",
    new_version_home17: " How's Pionex.US comparing to other exchanges?",
    new_version_home18: " Five Trading Bots. Make more money when sleeping.",
    new_version_home19: " Low commission fee. Save more money when trading.",
    new_version_home20: " As good liquidity as Binance. An aggregate exchange.",
    new_version_home21: "Is Pionex.US safe?",
    new_version_home22:
        "Pionex.US is registered in Singapore. It's collaborated with BitUniverse team, and BitUniverse has raised more than 10M USD funds from Banya Capital, Shunwei Capital and Zhenge Capital. Your deposit coins security are guaranteed by BitUniverse team.",
    header_support: "Support",
    only_first_links1: "Only the first 200 participants can get the award.",
    only_first_links2: "Retweet and tag a friend in the comment of this tweet >",
    firstPrize: "First Prize",
    secondPrize: "Second Prize",
    thirdPrize: "Third Prize",
    registrAtionRulesAwards: "Awards",
    registrAtionRulesQuota: "Quota",
    shartInviteLinkText:
        '"Pionex is an exchange designed for the trading bot. Great liquidity which aggregates from multiple other exchanges, 16 trading bots built-in, and very low trading fee - 0.05%. Join us today 👉',
    tab_what_is_pionex: "What's Pionex.US",
    tab_market: "Market",
    tab_coin_cap: "CoinCap",
    market_header_pair: "Pair",
    market_header_price: "Price",
    market_header_price_fiat: "Equivalent price",
    market_header_24h_change: "24H Change",
    market_header_24h_volume: "24H Volume",
    market_header_ai_annualized_return: "AI annualized return",
    coin_cap_total: "Total cap",
    coin_cap_24h_change: "24H Change",
    coin_cap_supported_coins: "Supported coins",
    coin_cap_header_coin: "Coin",
    coin_cap_header_price: "Price",
    coin_cap_header_24h_change: "24H Change",
    coin_cap_header_cap: "Cap",
    coin_cap_header_circulating: "Circulation",
    sign_tips_balance: "Sign in first to check your balance",
    sign_tips_trade: "Sign in or register first to create trading bots",
    button_more: "More",
    setting_theme: "Theme",
    setting_theme_normal: "Light mode",
    setting_theme_dark: "Dark mode",
    header_support_center: "Support center",
    pionex_account_already_exists: "This account has been registered in BitUniverse or Pionex.US. You can just sign in with it.",
    order_profit_modal_set_text: "Advanced setting",
    order_profit_modal_title_text: "Grid profit setting",
    order_profit_modal_profit_text: "Profit from price increment + Profit from fluctuation",
    order_profit_modal_recommend_text: "(Recommended)",
    order_profit_modal_pured_profit_text: "Profit from fluctuation only",
    order_profit_modal_change_success: "Has been applied for all grid trading bots created after 2019/08/09 04:00(UTC)",
    dca_order_starting: "Creating",
    canceling_order_tip: "Cancelling the order...",
    canceling_order_done: "Cancelled",
    asset_tab_trade_title: "Trade Account",
    asset_tab_fund_title: "PiHodl Account",
    fund_activity_title: "My bidding status(will reveal on 08/20/2019 06:00 UTC)",
    fund_activity_sub_title: "Will snapshot the BUB and Pionex.US balance data on 08/20/2019 02:00 UTC.",
    fund_activity_name: "PiHodl Arbitrage Investment Plan No.{ver} - {currency} version",
    fund_activity_my_lot: "My bids: {lot}(bidding number{start_num}-{end_num})",
    fund_activity_my_lot_zero: "My bids: 0",
    fund_activity_match_lot: "Success: {lot}",
    fund_activity_total_amount: "Total quota: {amount} {currency}",
    fund_activity_buy: "BUY",
    fund_activity_buy_more: "BUY MORE",
    fund_activity_buy_done: "BOUGHT",
    fund_activity_buy_end: "BOUGHT",
    fund_activity_my_invest_title: "My CoinGain Investment",
    fund_activity_my_invest_sub_title:
        "Tip: The profit will show and accumulate every Saturday, it is accumulated from the day you purchased, and the return rate is calculated by this accumulated profit and your investment.",
    fund_activity_invest_title: "Investment: {amount} {currency}",
    fund_activity_profit_title: "Profit: {profit} {currency}",
    fund_activity_annualized_title: "Annualized return: {percent}",
    fund_activity_end_title: "End date: {time}",
    fund_activity_withdraw: "SELL",
    fund_activity_buy_success: "Bought successfully.",
    fund_activity_buy_failed: "Failed to buy. Reason: {reason}",
    fund_activity_buy_inactive_time_invalid: "Not started yet.",
    fund_activity_buy_no_lot: "You failed to bid.",
    fund_activity_buy_no_asset: "You don't have enough balance.",
    fund_activity_sell_inactive_time_invalid: "It hasn't been the sell time.",
    fund_buy_confirm_title: "Confirmation of deposit",
    fund_confirm_buy: "CONFIRM",
    fund_buy_loading_tips: "Transferring your coins into the PiHodl Account. This may take about 4-5 seconds.",
    fund_sell_confirm_title: "Confirmation of withdrawal",
    fund_confirm_sell: "CONFIRM",
    fund_sell_loading_tips: "Transferring your coins into your trading account. This may take about 4-5 seconds.",
    fund_sell_confirm_description: "We will transfer your {balance}{coinName}(including {profit}{coinName} profit) to your trading account.",
    url_error_bad_request: "Request error.",
    url_error_unauthorized: "Not signed in.",
    url_error_order_not_found: "Failed to find the order.",
    url_error_invalid_param: "Requirement for parameters error.",
    url_error_account_in_transfer: "Transferring",
    url_error_insufficient: "No enough balance.",
    url_error_fund_not_found: "Failed to find the investment plan info.",
    url_error_invalid_price: "Invalid price",
    url_error_invalid_amount: "Invalid quantity",
    url_error_buy_time_expired: "You didn't buy before the deadline.",
    url_error_sell_time_expired: "It hasn't been the end time.",
    fund_asset_tip: "Redeemed on {date}",
    fund_buy_success: "Bought successfully.",
    fund_sell_success: "Withdrawn successfully.",
    fund_agreement_name: "《Investment Agreement》",
    fund_cancel_button_text: "Discard",
    fund_ok_text: "Confirm",
    fund_cancel_text: "Cancel",
    fund_available_balance: "Available quota is {quota} {coinName}.",
    fund_input_limit: "Buy quantity: (should be multiples of {limit})",
    fund_not_enough_balance: "You don't have enough {balance} in your PiHodl account. Please decrease the buy quantity or deposit more {coinName}.",
    fund_not_enough_quota: "You only have the quota for {quota}{coinName}. Please buy the quantity no more than {quota}{coinName}.",
    fund_read_agreement: "I've read and agree",
    fund_introduce_title: "Investment Plan",
    fund_introduce_content: "PiHodl Arbitrage Investment Plan is managed by the top team in the cryptocurrency industry, and has been running for more than 80 weeks.",
    fund_profit_charts: "The profit line chart",
    fund_profit_charts_USDT: "USDT version",
    fund_profit_charts_BTC: "BTC version",
    fund_profit_card_title_USDT: "PiHodl Arbitrage Investment Plan - USDT version",
    fund_profit_card_title_BTC: "PiHodl Arbitrage Investment Plan - BTC version",
    fund_end_time: "Lock-up period: 08/24/2019 - 09/23/2019",
    fund_profit_per_year: "Estimated annualized return",
    fund_tag_low_risk: "Low risk",
    fund_tag_start_min_amount: "Starts from {amount} {coinName}",
    fund_tag_lock_up_days: "{days} Days of Lock-up period",
    fund_tag_total_pool: "Total pool:",
    fund_tag_usdt_one: "Low risk",
    fund_tag_usdt_two: "Starts from 1,000 USDT",
    fund_tag_usdt_three: "30 Days of Lock-up period",
    fund_tag_usdt_four: "Total pool: 100,000 USDT",
    url_error_buy_time_started: "It hasn't started.",
    url_error_transfer_failed: "Filed to transfer coins.",
    url_error_rpc_transfer_failed: "Internal Error. Please join telegram group to ask admins: https://t.me/+P2ezWZWM6qMxZDM9",
    url_error_transfer_stuck: "Failed to transfer.",
    url_error_invalid_invite_code: "Invalid invite code",
    // Litecoin 交易大赛
    table1rewarduno:
        "Top 10 winners of the trading volume king can share a pool of Litecoin according to their trading volume. Only the volume from the grid trading bot with the base of LTC, BTC, and ETH will be counted. (eg. LTC/XX, BTC/XX, ETH/XX)",
    tableTresTitle1: "1-10Total turnover",
    tableTresTitle2: "1-10Total",
    tableTresContent1: "≥500$ Ten thousand",
    tableTresContent2: "≥300$ Ten thousand",
    tableTresContent3: "≥200$ Ten thousand",
    tableTresContent4: "30LTC（$3,000)",
    tableTresContent5: "20LTC（$2,000)",
    tableTresContent6: "10LTC（$1,000)",
    table2rewarduno: "Top 3 Winners will get extra BUB token as awards",
    tableUnoTitle1: "Awards",
    tableUnoContent1: "1",
    tableUnoContent2: "2",
    tableUnoContent3: "3",
    tableUnoContent4: "300BUB",
    tableUnoContent5: "200BUB",
    tableUnoContent6: "100BUB",
    tableDosTitle1: "Rank",
    tableDosTitle2: "Awards",
    tableDosContent1: "$500 woth of VNS",
    tableDosContent2: "$300 woth of VNS for each winner",
    tableDosContent3: "$200 woth of VNS for each winner",
    tableDosContent4: "$100 woth of VNS for each winner",
    tableDosContent5: "$50 woth of VNS for each winner",
    settings_profile_title: "Personal info",
    settings_profile_photo: "Avatar",
    settings_show_account: "Show account",
    change_name_title: "Change your nickname",
    change_name_input_hint: "Please input nickname",
    profile_nickname: "Nickname",
    profile_uid: "UID",
    profile_email: "E-mail",
    change_nickname_failed: "Failed to modify nickname.",
    change_nickname_already_existed: "This nickname has been occupied.",
    change_nickname_invalid: "Your nickname has sensitive words, please fill in a new one",
    change_avatar_failed: "Failed to upload the picture to server.",
    tableCuatroTitle1: "Maker",
    tableCuatroTitle2: "Taker",
    registratPrize: "Prize:",
    registratrule: "Rule:",
    registratSpecialNote: "Special Note: ",
    index_bodypart: "Exchange fee standard",
    index_bodypartUno: "0.1 LTC (Litecoin) for every eligible participant",
    index_bodypartDos: "Participants need to have a net deposit with more than $500 USDT worth of assets to Pionex.US during the event period.",
    RegistrationRules_bodypart1: "To be eligible for the award 1 and 3, you need to create a Litecoin Grid Trading Bot and meet the requirements:",
    registrationRules_indefiniteGridCompetition_content_title: "Participate Awards",
    registrationRules_indefiniteGridCompetition_bodypart1: "First come, first served. Only the first 200 participants can get the awards.",
    registrationRules_ranking_content_title_dostext: "Trading Volume King",
    registrationRules_bodypart_top:
        "Top 10 winners of the trading volume king can share a pool of Litecoin according to their trading volume. Only the volume from the grid trading bot with the base of LTC, BTC, and ETH will be counted. (eg. LTC/XX, BTC/XX, ETH/XX)",
    registrationRules_bodypart4: "Dividing $3,000 worth of Litecoin plus 600BUB",
    registrationRules_bodypart5:
        "Open grid trading orders in Pionex.US (excluding unlimited grid), only open trading pairs with LTC, BTC, ETH, USDT as base (ie LTC/USDT, LTC/BTC, BTC/USDT, ETH/BTC, ETH/ USDT, USDT/HUSD) The trading volume of the grid trading will be counted. According to the total transaction amount, the first place to receive the reward must be worth more than 1 million US dollars during the competition period; the second place must meet the turnover of 500,000 US dollars during the competition; the third place must meet the turnover of 300,000 US dollars during the competition. Get extra special rewards.",
    registrationRules_bodypart6: "$5000 Worth of VNS to Give Away for 50 Winners",
    registrationRules_ranking_content_title_tres: "Annualized Profit KingGrid skill king",
    registrationRules_bodypart8: "You're able to create multiple bots. The highest annualized profit will be recorded as your score on the leaderboard.",
    registrationRules_bodypart9: "Notes: the Annualized Return is calculated by deducting the trading fee of different exchanges without calculating rebates and discounts, etc.",
    registrationRules_bodypart10:
        "1. Pionex.US reserves the right to disqualify accounts that are deemed to be engaging in wash trading or display attributes of market manipulation.",
    registrationRules_bodypart11: "2. Rewards will be issued within 7 days after the competition ends.",
    registrationRules_bodypart12:
        "3. Pionex.US reserves the right to cancel or amend any activity or activity rules at our sole discretion.Trading volume stats will now be updated every hour.",
    indefiniteGridCompetition_joinedButton: "Registered",
    indefiniteGridCompetition_join_success: "Register successfully",
    indefiniteGridCompetition_join_fail: "Failed to register.",
    bodypart_top_h2: "The size of the Litecoin Pool will vary according to the sum of Top10 players:",
    bodypart_top1: "-$1,000 worth of Litecoin Pool: the total trading volume of the top 10 players reach 2 Million USD",
    bodypart_top2: "-$2,000 worth of Litecoin Pool: the total trading volume of the top 10 players reach 3 Million USD",
    bodypart_top3: "-$3,000 worth of Litecoin Pool: the total trading volume of the top 10 players reach 5 Million USD",
    fund_logic_error_balance_insufficient: "Insufficient balance in trading account",
    fund_logic_error_fund_balance_insufficient: "Insufficient balance in fund account",
    fund_logic_error_create_order: "Fund purchase failed",
    fund_transfer_button_text: "TRANSFER",
    fund_section_title_buy: "PiHodl Subscription",
    fund_withdraw_end_text: "Funds will be unfrozen at {time}",
    tableExchange: "Exchange",
    // 币计划第二期
    fund_title: "Purchase PiHodl Investment Plan Season {num}",
    expected_annualized_rate_of_return: " Expected Annualized Return Rate",
    buy_now: "Purchase Now({timer} left)",
    end_purchase: "Subscription ended",
    fund_finished: "purchased. Current remaining amount",
    allInputs: "Transfer All",
    yiqiangwanHaiyouEn: "purchased.",
    yiqiangwanHaiyou: "Current remaining amount",
    transferFrom: "From",
    transferTo: "To",
    isBan: "Ver.",
    fund_tag_computed_profit_date: "Interest Calculation Period",
    trading_account: "Trading Account",
    investment_account: "PiHodl Account",
    my_trading: "Balance of the trading account: ",
    my_investment: "Balance of the PiHodl Account: ",
    fund_buy_confirm_description:
        "You are going to purchase {inputValue} {coinName} of PiHodl Investment, your No.{ver} funds and interests will be redeemed automatically after 28 days.",
    fund_buy_privilege_confirm_description: "You are going to purchase {inputValue} {name} of PiHodl Investment.",
    transfer_title: "Transfer",
    fund_transfer_in_success: "Transfer Success",
    fund_transfer_out_success: "Transfer Success",
    fund_transfer_text_hint: "Transfer Amount",
    fund_not_start: "Purchase not started",
    share_to_your_friends_title: "Share to your friends email",
    share_to_your_friends_description: "invite your friends to make more profits, and you will get up to 100 BUB as reward!",
    share_to_your_friends_input_placeholder: "Input their emails here. Use “,“ to separate",
    share_to_your_friends_tips1: "Share to Pionex.US's twitter to show your excellent strategy",
    share_to_your_friends_tips2: "Please make sure your friends allow you to share their email addresses to us.",
    download_picture_title: "Or download the picture directly",
    email_input_none_error: "Input the email to share",
    email_input_error: "Invalid email address",
    withdraw_failed_cause_otp_not_bind: "You haven't bound Google Authenticator, so you can not withdraw.",
    balance_deposit_warning: "Pionex.US doesn't support depositing through smart contract now, such as deposit from Wirex, foxbit, Bitstamp, Coinflex, Nicehash.",
    balance_deposit_usdt_switch: "On which blockchain:",
    classificationFirst: "( Low risk )",
    classificationSecond: "( Medium risk )",
    classificationThird: "( High risk )",
    aiAnnualized7d: "AI Annualized Return(7D)",
    bot_share_annual_return_card: "Annualized Return",
    account_account: "Account",
    account_password: "Password",
    account_password_encrypted: "Encrypted",
    account_email_confirmation: "Email verification",
    account_phone_confirmation: "Phone verification",
    account_email_confirmation_confirmed: "Verified",
    account_kyc: "Identity verification",
    account_kyc_info: "Identity verification",
    coming_soon: "Coming soon",
    button_reset: "Reset",
    change_avatar_failed_image_to_large: "The selected picture's size is too big. Please select another one.",
    account_nickname_not_set_tip: "Nickname not set",
    balance_detail: "Detail",
    balance_deposit: "Deposit",
    balance_withdraw: "Withdraw",
    referral_get_bub: "Get {amount}BUB/invitee",
    cancel_withdraw_success: "Cancelled withdrawal successfully.",
    cancel_withdraw_failed: "Failed to cancel withdrawal. Reason: {reason}",
    cancel_withdraw_warning_title: "Are you sure to cancel withdrawal?",
    cancel_withdraw_warning_content: "After confirmation, your withdrawal won't be applied.",
    "withdraw_reject:RISKY_ACCOUNT": "Rejected, Risky account",
    "withdraw_reject:SYSTEM_BUSY": "Rejected, System busy",
    "withdraw_reject:HICOIN_REJECTED": "Rejected. Retry or contact admin.",
    "withdraw_reject:INVALID_ADDRESS": "Rejected, Invalid address",
    "withdraw_reject:EXCEED_DAILY_QUOTA": "Rejected, daily limit exceeded",
    "withdraw_reject:INSUFFICIENT_VALID_BALANCE": "Rejected, insufficient balance",
    "withdraw_reject:UNKNOWN": "Rejected, Unknown",
    "withdraw_reject:ACCOUNT_IN_BLACKLIST": "Rejected, Blacklist",
    deposit_withdraw_from_pionex: "From Pionex.US",
    deposit_withdraw_activity_prize: "Activity prize",
    shartcard_bannerText: " World's 1st exchange designed for crypto trading bots",
    detailcontent_botSratus: "Bot status",
    shartCard_fotter_text1: "· 5 Bots for you to make profit even when sleeping. Grid Trading, Infinity Grids, DCA (Dollar-cost averaging), TWAP, Trailing Take Profit",
    shartCard_fotter_text2: "· 0.05% Commission fee, save more money when trading",
    shartCard_fotter_text3: "· Binance-level liquidity",
    google_authenticator_bind_button: "BIND",
    change_name_length_hint: "At most 15 characters",
    riskWarning: "This refers to Grid trading only",
    tabSelectDecimals: "decimals",
    aipPannualText: "{profit}",
    aipPannualText_Chiled: "if DCA from Jan/1 2019",
    myDcaNumberText: "My DCA Bot on pionex.us",
    myInfinityNumberText: "My Infinity Grids Bot on pionex.us ",
    myGridTradingNumberText: "My Grid Trading Bot on pionex.us",
    fund_not_sold_out: "Sold out",
    fund_not_started: "Purchase in {timer}",
    now_annualized_rate_of_return: "Annualized return",
    kyc_none: "Unverified",
    kyc_failed: "Failed",
    kyc_verifying: "In process",
    kyc_success: "Verified",
    kyc_title: "KYC review",
    kyc_upload_failed: "Failed to upload picture. Please try again.",
    kyc_submit_success_title: "Submitted successfully, waiting for verification",
    kyc_submit_success: "KYC review usually takes 1-3 business days (Saturday and Sunday are off work). Thanks for your patience. We will review as soon as possible.",
    kyc_submit_error: "Submission failed, please check your network environment or resubmit KYC. Error code: {error}",
    kyc_STEP1: "STEP1: Fill in personal Information",
    kyc_STEP2: "STEP2: Upload Certification Photo",
    kyc_input_name: "Full name",
    kyc_input_xing: "Last name",
    kyc_input_zjxing: "Middle name",
    kyc_input_ming: "First name",
    kyc_input_IdCode: "ID code",
    kyc_Radio_tiptext: "Choose certification type:",
    kyc_Radio_passport: "Passport",
    kyc_Radio_idCard: "ID Card",
    kyc_Radio_idCard1: "ID Card 、Passport 、Driving License ",
    kyc_Radio_driverLicense: "Driving License",
    kyc_Bbutton_submint: "SUBMIT",
    kyc_isPassport: "Please input your Passport number",
    kyc_isIdCard: "Please input your ID card number",
    kyc_isDriverLicense: "Please input your Driving License number",
    kyc_selectmessage_countryList: "Select can not be empty",
    kyc_IdCodemessage1: "Cannot be empty.",
    kyc_IdCodemessage2: "Numbers only",
    kyc_IdCodemessage3: "The password can not be greater than 30 characters.",
    kyc_ID_number_error: "身份证号格式错误",
    kyc_verify_error_param_invalid: "Invalid parameters.",
    kyc_verify_error_name_invalid: "Invalid names.",
    kyc_verify_error_invalid_id: "Wrong ID identification number",
    kyc_verify_error_service_busy: "ID verification system is busy. Please try again later",
    kyc_verify_error_id_not_exist: "ID card number does not exist",
    kyc_verify_error_verify_failed: "Your ID code and name don't match.",
    kyc_verify_error_unknown: "Unknown error. Please try again later or contact the support team.",
    coinpedia_title: "Coin list",
    coinpedia_coin_title: "Coin",
    coinpedia_circulation_title: "Circulation",
    coinpedia_official_web_title: "Official website",
    coinpedia_block_web_title: "Block website",
    coinpedia_introduction_title: "Introduction",
    coinpedia_introduction_check: "CHECK",
    kyc_img_dragorClick: "Drag the photo or click here to upload the Front of the document you chose before",
    kyc_img_IntroductionFmian: "Drag the photo or click here to upload the Back of the document you chose before",
    kyc_img_thisPhoto_exceeds: "This Photo exceeds 2MB.Please choose another one to upload",
    kyc_img_upload_type: "You can only upload JPG/PNG file!",
    kyc_img_upload_size: "Image must smaller than 2MB!",
    kyc_img_footerTextWerning: "All photo File sizes should be 100kb-2MB, JPG and PNG format only",
    kyc_img_smallestSize: "Should be greater than 100KB",
    kyc_upload_REPLACEPIC: "REPLACE PIC",
    kyc_upload_choosefile: "Choose file",
    kyc_choose_Country: "You must choose a country or region",
    kyc_Country_Region: "Country/Region",
    kyc_check_reasons: "Check reasons >",
    kyc_imgmoren_text:
        'Hold up the document you chose before and a handwritten note with the phrase "Pionex.US + today\'s date".Please make sure that your face is clearly visible and the ID information is clearly readable and unobstructed.',
    kyc_tipie_name_kong: "Please input your nickname!",
    kyc_button_apply: "Apply",
    kyc_button_detail: "Detail",
    kyc_input_name_last_name: "Please Required",
    kyc_input_nameXing: "Surname",
    kyc_input_nameMing: "Name",
    kyc_input_airX: "Last name cannot be empty",
    kyc_input_airM: "Name cannot be empty",
    kyc_Idcallbackcard: "Only number and English character are allowed. Don't type special symbol or other characters here.",
    kyc_sample: "Example",
    fund_redeem_text: "Redeem",
    fund_redeeming_text: "Redeeming...",
    fund_redeem_force: "Force to redeem",
    fund_asset_detail: "Balance details",
    fund_redeem_success: "Redeemed successfully.",
    fund_redeem_failed: "Failed to redeem.",
    annual_plan_circle: "Give quota to loyal users who are eligible to purchase.",
    annual_plan_pie: "Purchase or redeem at anytime and make profits at every second.",
    annual_plan_details: "DETAILS",
    annual_plan_Balance: "My left quota",
    fund_privilege_buy: "PURCHASE NOW",
    fund_order_detail: "Balance details",
    fund_order_action_buy: "PURCHASE",
    fund_order_action_sell: "REDEEM",
    yearCardmSliderTextDepict_text: "My {name} quota:",
    redemption_title: "Redeem",
    redemption_content: "After you have confirmed to redeem PiHodl, your coins and interests will be back to your PiHodl Account on the Saturday of the week you submit to redeem.",
    redemption_footerbutton: "CONFIRM",
    // 新彈窗
    normal_redemption_title: "Confirmation for redeem your PiHodl Standard -",
    thisnormal_redemption_content: "Your fund and profit will be back to your PiHodl Account on this Saturday.",
    nextnormal_redemption_content: "Your fund and profit will be back to your PiHodl Account next Saturday.",
    pro_redemption_title: "Confirmation for redeem your PiHodl Pro-",
    isprothis_redemption_content:
        "Your fund and profit will be back to your PiHodl Account on this Saturday. The investment quota you consumed will not be available after the redemption.",
    ispronext_redemption_content:
        "Your fund and profit will be back to your PiHodl Account next Saturday. The investment quota you consumed will not be available after the redemption.",
    fund_activity_yearly: "PiHodl Basic - {fundCurrency} version",
    fund_activity_yearly_privilege: "PiHodl Pro - {fundCurrency} version",
    fund_activity_monthly_privilege: "PiHodl {fundCurrency} - monthly version",
    fund_tag_none_limit: "No limit",
    fund_tag_auto_run: "Purchase and redeem on a weekly basis",
    fund_buy_amount_title: "Purchase quantity:",
    fund_current_year_profit: "Current annualized return:",
    fund_action_buy_ing: "Purchasing...",
    fund_action_buy_cancel: "Cancel purchase",
    fund_action_sell_cancel: "Cancel redeem",
    fund_cancel_dialog_content: "Confirm cancel?",
    "balance.mine_balance_paibibao": "Account Balance",
    fund_action_settling: "Settling",
    app_fund_entry: "PiHodl",
    fund_dialog_yearly_link_text: "《Distribution plan》",
    kyc_button_Resubmit: "Resubmit",
    header_app_fund_Whats: "What's PiHodl",
    header_app_fund_Purchase: "PiHodl account",
    fundindexBanner_Title: "PiHodl",
    fundindexBannercontent_Text1: "High-frequency arbitrage, Calendar Spread Arbitrage，10%+ EAR",
    fundindexBannercontent_Text2: "Earn more BTC by PiHodl BTC version，or earn stable income by USDT version",
    fundindexBannercontent_Text3: "Risk control by Pionex.US quantitative platform",
    fundBuynow_title: "PiHodl Basic",
    fundBuynow_titledos: "Expected Annualized Return",
    fundBuynow_tags1: "Invest BTC or USDT",
    fundBuynow_tags2: "Expected Annualized Return 10%+",
    fundBuynow_tags3: "Starts from: 0.01BTC / 100USDT",
    fundBuynow_tags4: "Purchase and redeem on a weekly basis",
    fundBuynow_text:
        "PiHodl is the top Bitcoin arbitrage service, which provides 10%+ annualized increase for the Bitcoin amount. It offers the highest return rate compared to all the existing services, 3x compared to Binance Lending and 5x compared to crypto.com",
    fundBuynow_text1:
        "PiHodl arbitrage has been running for more than 80 weeks with a very low drawdown (0.8%). It remains 0% drawdown since the beginning of this year. The PiHodl team is the top market maker in several exchanges, which has the lowest trading fee and the highest maker rebate。",
    fundBuynow_button: "PURCHASE NOW",
    fundContent_Team: "How can PiHodl achieve high-frequency arbitrage",
    fundContentTeam_list_title1: "Very low transaction fee",
    fundContentTeam_list_title2: "Extremely fast trade execution",
    fundContentTeam_list_title3: "7x24 trading and risk control",
    fundContentTeam_list_title4: "Extreme large fund scale and long performance period",
    fundContentTeam_list_conText1:
        "Having a extremely low transaction fee is the base for performing high-frequency trading. Under extremely large transactions, the lower the fee, the more opportunities you can capture.",
    fundContentTeam_list_conText2:
        "The strong system engineering capability enables the arbitrage system to capture fleeting arbitrage opportunities and place orders in the first place. This represents the ability to secure systems, communicate extremely high performance and high-speed data analysis and cleaning.",
    fundContentTeam_list_conText3:
        "The cryptocurrency market is running 24/7 in the world. The stability of the service of each exchange is different from that of the traditional market. At the same time, the volatility is much higher than the traditional market, so it needs stronger fault tolerance, redundancy and risk control to ensure the arbitrage system able to operate without interruption and obtain the most arbitrage opportunities.  ",
    fundContentTeam_list_conText4:
        "Short-term capture of arbitrage opportunities to achieve high returns does not represent that a large-scale operation can sustain high returns and low retracements. The size of the funds managed by the arbitrage system and the historical running time are the ultimate criteria for the reliability of an arbitrage product.",
    fundContent_Team_shili: "How is the comprehensive trading strength of the PiHodl team？",
    fundContent_Risk_agreement1: "1.pionex.us has launched the PiHodl service, which is supported as the idled cryptocurrency management service for users from pionex.us.",
    fundContent_Risk_agreement2:
        "2.pionex.us provides risk management service for PiHodl users who have transferred assets to PiHodl investment plan, relying on the Pionex.US strict risk control system, which can fully ensure the security of assets and then gain profits comfortably.",
    fundContent_Risk_agreement3:
        "3.When using the PiHodl service, users will unconditionally authorize pionex.us to allocate and release assets reasonably according to the PiHodl rules of the platform.",
    fundContent_Risk_agreement4: "4.When using the service, users should abide by the relevant national laws and ensure that the source of assets is legal and compliant.",
    fundContent_Risk_agreement5:
        "5.When using the service, users should be fully aware of the risks in cryptocurrency investment and operate cautiously within your sphere of ability.",
    fundContent_Risk_agreement6:
        "6.All investment actions made on pionex.us by users represent the true investment intentions, and users shall unconditionally accept the potential risks and benefits associated with the investment decisions.",
    fundContent_Risk_agreement7:
        "7.pionex.us retains the right to suspend and terminate the PiHodl investment plan service, and pionex.us platform can suspend and terminate the PiHodl investment plan service at any time as necessary.",
    fundContent_Risk_agreement8:
        "8.pionex.us platform assumes no responsibility for the loss of assets due to some irresistible factors such as runaway or bankruptcy on third-party exchanges. At present, there are five arbitrage exchanges where the funds are located: OKEx, Binance, Huobi, BitMex, and BitFinex.",
    fundContent_Risk_agreement9:
        "9.Because some irresistible factors such as network latency, computer system failure and, etc may cause PiHodl Investment plan to pause, stop, or deviate. And pionex.us will do our best to ensure the project but not promise PiHodl Investment plan system whether it can run stable and effective with these irresistible factors happening. Besides, If the final investment result is not the same with the expectations because of the above irresistible factors, which pionex.us will not undertake any responsibility for.",
    fundContentTeam_strength_title1: "Chief quantitative strategist",
    fundContentTeam_strength_title2: "Chief system architect",
    fundContentTeam_strength1:
        "7 years work experience on Wall Street hedge fund, hold the post of senior architect on IT department involves field of program trading, risk control, information security, software infrastructure and so on",
    fundContentTeam_strength2:
        "10 years work experience on Tencent and Alibaba，has long been engaged in product architecture and R&D in the fields of distributed computing, distributed storage, cloud computing, and big data. He has extensive experience in high complexity, high reliability, high service and other projects, and has published several patents in China and the United States.",
    fund_Recharge: "Deposit",
    fund_transfer: "Transfer to PiHodl account",
    fund_purchasing: "Purchase PiHodl",
    fund_button_Recharge: "Deposit",
    fund_transfer_button: "Transfer",
    fund_purchasing_button: "Purchase",
    fund_Select_currency: "Choose currency",
    fund_PurchaseProcess_gmcg: "Purchase PiHodl successfully",
    fund_PurchaseProcess_jieShao: "Purchase PiHodl successfully",
    fund_PurchaseProcess_promotionQualification1: "Recommend Pionex.US to your friends and get BUB and get qualification of purchase PiHodl pro (20%+ EAR)",
    fund_PurchaseProcess_promotionQualification2: "Purchase more",
    fund_balance_invest: "Invested",
    fund_activity_my_hodl_title: "My PiHodl",
    fund_redeem_auto: "Redeem in {num} days",
    fund_redeem_auto_hour: "Redeem in {num} hours",
    fund_redeem_finish: "Redeemed",
    fund_purchase_finish: "Purchased",
    fund_redeem_finish_extra: "Redeem on {date}",
    fund_purchase_finish_extra: "Purchase on {date}",
    fund_redeem_warning: "{amount} Redeeming (Will be settled down this Saturday)",
    fund_tab_live_title: "Live plans",
    fund_tab_history_title: "History plans",
    risk_warning: "Risk Warning",
    fund_title_plan: "Plan",
    fund_title_annualized_return: "Annualized Return",
    fund_title_quota: "Quota",
    fund_title_duration: "Duration",
    fund_purchase_sold_tip: "{percent} Sold",
    fund_purchase_sold_left: "{amount} {currency} left",
    fund_purchase_pro_rules: "The Pro version is for high-end users. You are able to purchase only if you own BUB or have coins in trade account",
    fund_purchase_rule_link: "RULES >",
    fundHomeRecommended_purchase: " Recommend you to purchase PiHodl, High-frequency arbitrage plan",
    fund_total_profit_rate: "Profit/Accumulative return rate",
    fund_purchase_pescess: "Invite friends",
    fund_trese_step: "3 Steps to Purchase PiHodl",
    fund_cardPro_slidersolid: "My quota: {amount} {currency}",
    fund_cardPro_sliderleft: "Remaining amount:",
    fund_purchasing_warning: "{amount} Purchasing (Will be settled down this Saturday)",
    fund_purchasing_warning_next: "{amount} Purchasing (Will be settled down next Saturday)",
    fund_redeeming_warning: "{amount} Redeeming (Will be settled down this Saturday)",
    fund_redeeming_warning_next: "{amount} Redeeming (Will be settled down next Saturday)",
    fund_balance_ProfitAnnualized: "Profit/Annualized return",
    fund_loginwhiring: "Please register/login before purchasing or redeeming the money.",
    fund_chartTip_leijishouyi: "Accumulative return rate:",
    fund_purchase_transfer_available: "{currency} (Available {amount} {currency})",
    account_session_timeout: "You have been staying in this interface for too long time. Please retry.",
    fund_loginwhiring_title: "Welcome to the Pionex.US",
    fund_annualized_title: "History annualized profit: {value}%",
    fund_annualized_week_title: "7D Annualized profit: {value}%",
    fund_purchase_failed_not_enough: "Purchase failed",
    fund_redeem_failed_not_enough: "Redeem failed",
    // 三步购买
    bannerWapper_textTetle: "PiHodl Basic-BTC/USDT",
    bannerWapper_contentTextYearlBTC1: "BTC 7D annualized profit",
    bannerWapper_contentTextYearlBTC2: "History annualized profit",
    bannerWapper_contentTextYEAR_PrivilegelUSDT1: "USDT 7D annualized profit",
    bannerWapper_contentTextYEAR_PrivilegelUSDT2: "History annualized profit",
    purchase_Redemption_Rules: "Rules of PiHodl purchase and redemption",
    regulationImgTop_PurchaseRedemption: "Purchase and Redemption",
    regulationImgTop_PurchaseTime1: "20:00AM Friday (UTC)",
    regulationImgTop_PurchaseTime2: "20:00AM Saturday (UTC)",
    regulationImgTop_PurchaseTime3: "20:00AM Friday (UTC)",
    regulationImgTop_PurchaseTime4: "20:00AM Saturday (UTC)",
    regulationImgSettlementDate1: "Settlement date",
    regulationImgBottomText2: "Period for Interest Calculations",
    regulationImgBottomText3: "Period for Interest Calculations",
    regulationImgBottomText4_1: "Reinvest every week",
    regulationImgBottomText4_2: "Purchase and redemption cut-off",
    regulationImgBottomText4_3: "Start or finish making profit",
    regulationImgBottomText4_4: "Can't purchase or redeem on settlement date",
    standardCurveChart_title: "PiHodl basic net value curve",
    fundContent_contentText1: "1:Start to confirm purchase/redemption request on 12:00 (UTC) every Friday; purchase/redemption confirmed on 04:00 (UTC) every Saturday",
    fundContent_contentText2: "2:Your PiHodl will be reinvested every week if not redeemed，which can be continually reinvested until 26 Sep, 2020",
    fundContent_contentText3: "3:The PiHodl profit is not in a fix rate, the profit of the current week will be announced on 04:00 (UTC) every Saturday",
    fundContent_contentText4: "4:All your asset will be redeemed one-time after you redeem and you can hold it and not redeem all the time until 26 Sep, 2020",
    threeStepPurchaseBalancePaibibao: "PiHodl account balance:",
    threeStepPurchaseBalancetexttrading: "Trading account balance:",
    threeStepPurchaseBalance1: "{amount} {currency}",
    threeStepPurchaseBalance2: "{amount} {currency}",
    threeStepPurchaseBalance3: "This Saturday",
    thisAxisPurchaseBalance: "This Saturday",
    nextWeekPurchaseBalance: "Next Saturday",
    threeStepPurchaseBalance4: "Start making profit",
    fund_chartTip_netWorth: "Accumulated net value:",
    timeTable_wepper_title1: "Monday",
    timeTable_wepper_title2: "Tuesday",
    timeTable_wepper_title3: "Wednesday",
    timeTable_wepper_title4: "Thursday",
    timeTable_wepper_title5: "Friday",
    timeTable_wepper_title6: "Saturday",
    timeTable_wepper_title7: "Sunday",
    trade_theme_modal_title: "Come and try a new theme~",
    trade_theme_modal_light: "Light mode",
    trade_theme_modal_dark: "Dark mode",
    button_save: "Save",
    // header my bot
    pionex_mybot: "My Orders",
    pionex_meNue_tab: "My manual orders",
    pionex_orders_tab_trading: "Trading Bot orders",
    pionex_orders_tab_manual: "Manual orders",
    pionex_orders_tab_buy_crypto: "Buy Crypto orders",
    pionex_orders_tab_deposit: "Deposit USD orders",
    pionex_orders_tab_withdraw: "Withdraw USD orders",
    order_one_more_text: "RE-CREATE",
    order_one_more_not_support: "Not supported yet",
    //download
    PerGrid_text_uno: "Commission fee of Pionex.US is only 0.05%",
    PerGrid_text_dos: "Pionex.US is incubated by BitUniverse",
    PerGrid_text_tres: "You could use BitUniverse account to log in directly",
    PerGrid_text_Join: "Join Pionex.US Group",
    PerGrid_text_Download_button: "Download Pionex.US App",
    PerGrid_Content_textUno: "Contrast of Pionex.US with other exchanges",
    PerGrid_Content_textDos:
        "The average commission fee of BitUniverse user is around 0.08%, the profit per grid deducted by commission fee is 0.44%. Below is the contrast of each exchange to BU average profit per grid 0.44%.",
    PerGrid_profit: "Profit per grid change",
    PerGrid_quantitative_tableTitle: "Contrast of infinity grids with grid trading",
    PerGrid_quantitative_tableLeft1: "Grid",
    PerGrid_quantitative_tableLeft2: "Grids quantity",
    PerGrid_quantitative_tableLeft3: "Price range",
    PerGrid_quantitative_tableLeft4: "Suitable",
    PerGrid_quantitative_tableLeft5: "Strategy period",
    PerGrid_quantitative_tableCenterUno: "Infinity",
    PerGrid_quantitative_tableCenterDos: "Hard to run out of range",
    PerGrid_quantitative_tableCenterTres: "Rise and swing market",
    PerGrid_quantitative_tableCenterCuatro: "Medium and long term",
    PerGrid_quantitative_tableRightUno: "Easy to run out of range",
    PerGrid_quantitative_tableRightDos: "Swing market",
    PerGrid_quantitative_tableRightTres: "Medium and short term",
    PerGrid_unexpected_tableTitle: "Contrast of Pionex.US with other exchanges",
    PerGrid_unexpected_tableLeft1: "No need to upload API",
    PerGrid_unexpected_tableLeft2: "API KEY Never Expired",
    PerGrid_unexpected_tableLeft3: "High Stability",
    PerGrid_unexpected_tableheader: "Others",
    app_download: "App Download",
    testflgo_Download: "Go Download",
    basic_Drawer_contenttitle1: "How to download a Pionex.US TestFlight version？",
    drawer_text1: "You will enter a 2-Step TestFlight guide page after click download. Follow the guide, first download TestFlight App, then go back to download Pionex.US App ",
    basic_Drawer_contenttitle2: "What should I do if I failed during downloading? ",
    drawer_text2: "You could go back to the TestFlight guide page, follow the guide and try one more time.",
    drawer_text3: "If you have more questions, please join telegram group for help",
    drawer_jointext: "Join telegram",
    experience_free: "Download Pionex.US APP and trade everywhere",
    app_download_botton_text: "Trade everywhere",
    wechantdownloadBg_text: "Please copy this link into your browser, then download",
    deposit_withdraw_memo_attention: "Attention",
    deposit_withdraw_memo_attention_content: "Both {memoName} and address are necessary for {coin} deposit to be successful",
    deposit_withdraw_memo_attention_check: "I am aware that a {memoName} is necessary for deposit, or the asset will be lost",
    deposit_address_memo_tittle: "Both {memoName} and address are necessary for {coin} deposit to be successful",
    deposit_address_coin: "{coin} address:",
    deposit_address_memo: "{coin} {memoName}:",
    withdraw_address_no_memo: "No {memoName}",
    withdraw_address_memo_error: "Please input {memoName}",
    dw_record_status_reviewing: "Reviewing",
    dw_record_status_withdrawing: "Reviewed, withdrawing",
    dw_record_status_processing: "Processing",
    dw_record_status_complete: "Complete",
    dw_record_status_confirmed: "{block}/{confirmation} Confirmed",
    dw_record_status_failed: "Failed",
    dw_record_status_canceled: "Canceled",
    dw_record_status_unknown: "Unknown",
    zengArrowup: " ",
    zengArrowdown: " ",
    deposit_large_transfer_warning:
        "Notice: It is a big amount of deposit, which triggers transferring to cold wallet. It may cost more time, you could contact customer services to speed up",
    deposit_prepare_not_enough_warning:
        "Notice: Pionex.US is an aggregated exchange, occasionally, your asset needs to be transferred to aggregated pool then deposit is done and you could place orders",
    withdraw_large_transfer_warning:
        "Notice: It is a big amount of withdrawal, which triggers transferring from cold wallet. It may cost more time, you could contact customer services to speed up",
    withdraw_prepare_not_enough_warning:
        "Notice: Pionex.US is an aggregated exchange, occasionally, your withdrawal asset needs to be transferred from aggregated pool then can withdrawal broadcast on blockchain",
    dw_warning_contact: "Contact",
    dw_warning_join_telegram: "JOIN TELEGRAM",
    dw_attention_next: "NEXT",
    dw_history_record_notice: "Notice",
    account_too_many_requests: "Your IP connection is interrupted, please switch internet or retry 5 minutes later",
    tutorial: "Tutorial",
    appStoreNot_open_yet: "Not open yet",
    link_style_mnjy_link: " ",
    link_style_mnjy_text: " ",
    downloadFaq_testflig: "Testflight Version Download FAQ-",
    // partial_Redemption_notEnough_Btc:"BTC Non-partial redemption reasons",
    // partial_Redemption_notEnough_Usdt:"USDT Non-partial redemption reasons",
    // partial_Redemption_notEnough_BtcContent:"This is only available for users who invested more than 1 BTC",
    // partial_Redemption_notEnough_UsdtContent:"This is only available for users who invested more than 10,000 USDT",
    // partial_Redemption_notEnough_button:"OK",
    // partial_Redemption_fund_redeeming_warning: "{amount} redeeming (will be finished on Saturday)",
    // partial_Redemption_fund_redeeming_warning_next: "{amount} redeeming (will be finished next Saturday)",
    // partial_Redemption_inputs:"Redemption amount",
    // partial_Redemption_message_defaultbtc:"Received Fund = Unit * Latest settlement net value ",
    // partial_Redemption_message_defaultusdt:"Received Fund = Unit * Latest settlement net value ",
    // normal_partial_Redemption: "Redemption amount your PiHodl Standard-",
    // pro_partial_Redemption: "Redemption amount your PiHodl Pro-",
    // partial_Redemption_fundContent_contentText1:"According to your redemption unit, you'll receive your fund back when we announce the latest unit price on Saturday.",
    // partial_Redemption_fundContent_contentText2:"According to your redemption unit, you'll receive your fund back when we announce the latest unit price next Saturday.",
    // pro_partial_Redemption_fundContent_contentText1:"According to your redemption unit, you'll receive your fund back when we announce the latest unit price on Saturday. The pro quota you consumed will not be available after the redemption",
    // pro_partial_Redemption_fundContent_contentText2:"According to your redemption unit, you'll receive your fund back when we announce the latest unit price next Saturday. The pro quota you consumed will not be available after the redemption",
    // redeeming_text_dodos:"Redeeming all",
    // redeeming_text_amedia:"Partial redemption",
    // partial_Redemption_youunos_tambint:"There is a redemption in progress",
    // partial_Redemption_fund_redeem_success: "Successful application for partial redemption",
    // partial_Redemption_fund_redeem_failed: "Application for partial redemption failed",
    // allpartial_Redemption_fund_redeem_success: "Apply for all redemptions successfully",
    // allpartial_Redemption_fund_redeem_failed: "Application for full redemption failed",
    // partial_Redemption_quantity:"The quantity",
    partial_redemption: "PARTIALLY REDEEM",
    partial_redemption_all: "ALL REDEEM",
    partial_Redemption_btc_min: "The quantity should be ≥ 0.001and multiplies by 0.001",
    partial_Redemption_btc_Insufficientbalance: "No enough balance",
    partial_Redemption_multiplecontent_btc: "The quantity should be ≥ 0.001 and multiplies by 0.001",
    partial_Redemption_multiplecontent_usdt: "The quantity should be ≥ 10 and multiplies by 10",
    partial_Redemption_usdt_min: "The quantity should be ≥ 10 and multiplies by 10",
    partial_Redemption_usdt_Insufficientbalance: "No enough balance",
    partial_Redemption_processing: "Redeeming",
    partial_Redemption_Dodos: "Redeemable unit",
    redeeming_text_dodos: "Redeeming",
    redeeming_text_amedia: "Redeeming",
    partial_Redemption_notEnough_Btc: "You cannot use redeem part",
    partial_Redemption_notEnough_Usdt: "You cannot use redeem part",
    partial_Redemption_notEnough_BtcContent: "t needs your invest above 1BTC",
    partial_Redemption_notEnough_UsdtContent: "t needs your invest above 10000USDT",
    partial_Redemption_notEnough_button: "CONFIRM",
    partial_Redemption_fund_redeeming_warning: "{amount} Redeeming (Will be settled on Saturday)",
    partial_Redemption_fund_redeeming_warning_next: "{amount} Redeeming (Will be settled next Saturday)",
    revision_redeeming_warning: "Redeeming (Will be settled on Saturday)",
    revision_redeeming_warning_next: "Redeeming (Will be settled next Saturday)",
    header_activity: "Activity",
    header_activity_url: "https://pionex.zendesk.com/hc/en-us/articles/360043322274",
    partial_Redemption_inputs: "Enter the unit",
    fee_rate_detail: "https://pionex.zendesk.com/hc/en-us/articles/360041226753-Pionex.US-Market-Maker-Program",
    // partial_Redemption_message_defaultbtc:"Received Fund = Unit * Latest seceived Fund = Unit * Latest settlement Unit Price到手金额 = 赎回份额 * 最新结算净值",
    partial_Redemption_message_defaultbtc: "Received Fund = Unit * ",
    partial_Redemption_message_defaultusdt: "Received Fund = Unit * ",
    prompt_defaul_on: "Latest settlement unit price",
    prompt_default_next: "Latest settlement unit price",
    normal_partial_Redemption: "Redeemable unit: ",
    pro_partial_Redemption: "Redeemable unit: ",
    partial_Redemption_fundContent_contentText1: "According to your redemption unit, you'll receive your fund back when we announce the latest unit price on Saturday. ",
    partial_Redemption_fundContent_contentText2: "According to your redemption unit, you'll receive your fund back when we announce the latest unit price next Saturday. ",
    pro_partial_Redemption_fundContent_contentText1:
        "According to your redemption unit, you'll receive your fund back when we announce the latest unit price on Saturday. The pro quota you consumed will not be available after the redemption.",
    pro_partial_Redemption_fundContent_contentText2:
        "According to your redemption unit, you'll receive your fund back when we announce the latest unit price next Saturday. The pro quota you consumed will not be available after the redemption",
    partial_Redemption_youunos_tambint: "redeeming",
    partial_Redemption_fund_redeem_success: "Successfully applied for redemption part",
    partial_Redemption_fund_redeem_failed: "Failed applied for redemption part",
    allpartial_Redemption_fund_redeem_success: "Successfully applied for redemption all",
    allpartial_Redemption_fund_redeem_failed: "Failed applied for redemption all",
    partial_Redemption_quantity: "UNIT",
    grid_param_attitude: "Optimal Market Condition",
    grid_param_invest_earn: "Invest & earn",
    grid_order_high_price: "Upper limit price",
    grid_order_low_price: "Lowest price",
    grid_param_init_price: "Start price",
    trade_profit_per_grid: "Profit per grid",
    grid_param_trailing: "Trailing",
    grid_param_stop_loss: "Stop loss price",
    grid_transaction_to_be_sold: "To be sold",
    grid_pro_order_close_model_title: "Confirmation",
    grid_pro_order_close_model_cancelText: "DISCARD",
    grid_pro_order_close_model_okText: "STOP",
    grid_pro_order_close_model_zuokong_sure: "Please buy",
    grid_pro_order_info_pro_TotalInvest: "Investment",
    grid_pro_order_info_pro_Totalprofit: "Total profit",
    grid_pro_order_info_pro_Gridprofit: "Grid profit",
    grid_pro_order_info_pro_Unrealizedprofit: "Unrealized profit",
    grid_pro_order_info_pro_Lowestprice: "Lowest price",
    grid_pro_order_info_pro_Currentprice: "Current price",
    grid_pro_order_info_pro_Highestprice: "Highest price",
    grid_pro_order_info_pro_Historytxs: "History txs",
    grid_pro_order_FillHistory_Totalbuyorders: "Total buy orders",
    grid_pro_order_FillHistory_Totalsellorders: "Total sell orders",
    grid_pro_order_FillHistory_NetSellprice: "Net sell price",
    grid_pro_order_FillHistory_buyPrice: "Buy Price",
    grid_pro_order_FillHistory_sellPrice: "Sell price",
    gridPro_title_desc: "Grid Trading",
    gridPro_trading_desc: "Designed for Pro traders",
    grid_pro_prediction_title: "1.Choose your prediction of {pair}",
    gridPro_trading_twap_buy_period3: "3.Set parameters",
    grid_pro_arithmetic: "Arithmetic",
    grid_pro_geometric: "Geometric",
    grid_pro_bullish_earn_quote_desc: "Buy {earn_coin} low and sell them in higher price",
    grid_pro_bearish_earn_base_desc: "Sell {earn_coin} high and buy them back in lower price",
    prolist_trailing: "Trailing",
    pro_partial_model_cancel: "Cancel",
    pro_partial_model_continue: "Continue",
    pro_partial_model_title_text: "Fluctuant and then bullish",
    gridsAddonAfter: "Grids",
    holder_TriggerPriceplaceholder: "Trigger price",
    holder_StopLossPriceplaceholder: "Stop-loss price",
    holder_ProfitTriggerPriceplaceholder: "Take profit price",
    grid_pro_order_title: "Grid Trading Pro",
    grid_classic_order_title: "Grid Trading",
    grid_pro_order_FillHistory_Placed_Daily_Legend: "Daily transactions",
    grid_pro_order_FillHistory_Placed_Balance_Legend: "Balance",
    grid_pro_order_FillHistory_Placed_Tooltip_Date: "Date:",
    grid_pro_order_FillHistory_Placed_Tooltip_Balance: "Balance:",
    grid_pro_order_FillHistory_Placed_Tooltip_Profit: "Profit:",
    grid_pro_order_FillHistory_Placed_Tooltip_Transactions: "Transactions:",
    grid_pro_order_info_classic_annualized: "Annualized return",
    grid_pro_order_info_pro_Annualized: "Annualized return",
    market_trading_big_amount_warning: "Hint",
    market_trading_big_amount_warning_desc:
        "The placed volume is very big and it might cause a dip and make you lose. Please reset the order or we recommend you to use limit orders.",
    still_continue: "Proceed anyway",
    create_order_repeat: "Discard",
    dialog_google_guide_title: "Please enable Google authenticator",
    dialog_google_guide_content: "It's not safe to trade without Google authenticator. Please enable right now",
    dialog_google_guide_button: "Continue",
    account_sms_code_error_limit: "The verification code was wrong. Please try again after 1 minute.",
    grid_classic_title_desc: "Grid Trading Classic (old)",
    grid_pro_title_desc: "Grid Trading",
    grid_param_take_profit: "Take profit",
    grid_transaction_sell: "sell",
    grid_transaction_buy: "buy",
    grid_transaction_to_sold: "The bought {value} will be sold when the price increases",
    grid_transaction_bottom_tip: "This order was placed when the bot was created, so the buy price is low and grid profit is high",
    grid_pro_order_close_model_zuoduo: "Are you sure you want to stop the {symbol} Grid Trading Pro bot? And do you want us to auto sell your {base} into {quote}?",
    grid_pro_order_close_model_zuokong: "Are you sure you want to stop the {symbol} Grid Trading Pro bot? And do you want us to auto buy {base} used the rest of {quote}?",
    grid_pro_order_close_model_zuoduo_sure: "Please sell",
    grid_pro_order_close_model_zuoduo_cancle: "Don't sell",
    grid_pro_order_close_model_zuokong_cancle: "Don't buy",
    grid_pro_order_info_pro_swingearn: "(Bullish {swingCoin}, Earn {earnCoin})",
    grid_pro_order_info_pro_downearn: "(Bearish {swingCoin}, Earn {earnCoin})",
    grid_pro_order_info_pro_lastIngTime: "Lasting {lastIngTime} ({createTime} created)",
    grid_pro_order_info_pro_Todaytxs: "24H txs",
    grid_pro_order_info_pro_day: "day",
    grid_pro_order_info_pro_Details: "DETAIL",
    grid_pro_order_FillHistory_Assetchange: "Asset change",
    grid_pro_order_FillHistory_Placed: "Placed",
    grid_pro_order_FillHistory_Parameter: "Parameter",
    grid_pro_order_FillHistory_BuyIndex: "Buy {index}",
    grid_pro_order_FillHistory_SellIndex: "Sell {index}",
    grid_pro_order_FillHistory_Netbuyprice: "Net buy price",
    grid_pro_order_FillHistory_QuantityGrid: "Quantity/grid:",
    grid_pro_order_FillHistory_CurrentPrice: "Current price:",
    grid_pro_invest_earn_title: "2.Which coin do you want to invest and earn",
    grid_pro_choose_invest_earn: "{coin}",
    prolist_conditional: "Conditional",
    gird_use_my_coin: "Use my {coin}",
    grid_pro_use_the_another_pair_coin_desc:
        "After enabling this button, you agree to sell your {coin} (at most {amount} {coin} available)in market price to invest in the Grid Trading Bot.",
    pro_partial_model_title: "Confirmation",
    trading_stop_profit_price: "Take profit price",
    holder_LowerPricePlaceholder: "Lower limit",
    holder_UpperPriceplaceholder: "Upper limit",
    holder_UpperPriceplaceholder_grid: "Upper limit",
    holder_Amplitudeplaceholder: "Amplitude (30%-50%)",
    informationModel_title: "Please input the following parameters",
    trade_invest_range_min: "≥ {min} ",
    grid_prediction: "Choose your prediction",
    trade_invest_range_available: "Available balance",
    grid_pro_order_share_tip: "Share your order",
    grid_pro_order_FillHistory_Placed_Week_Legend: "Daily transactions",
    grid_pro_order_FillHistory_Placed_Month_Legend: "Daily transactions",
    grid_param_attitude_content: "{value} fluctuate and then bullish",
    profit_adjust_not_set: "Not set",
    grid_transaction_detail_fee_deduct: "Fee deducted",
    INVALID_TOP_BOTTOM: "The upper limit should be greater than the lower limit.",
    INVALID_ROW: "Invalid grid number",
    INVALID_TREND: "Please choose bullish or bearish attitude",
    INVALID_GRID_TYPE: "Please choose arithmetic or geometric",
    INVALID_EARN_COIN: "Please choose what you want to invest and earn",
    LOSS_STOP_MUST_GREAT_THAN_ZERO: "Stop loss price should be greater than 0",
    LOSS_STOP_MUST_LESS_THAN_BOTTOM: "Stop loss price should be less than the lower limit price",
    LOSS_STOP_MUST_GREAT_THAN_TOP: "Stop loss price should be greater than the upper limit price",
    PROFIT_STOP_MUST_GREAT_THAN_ZERO: "Take profit price should be greater than 0",
    PROFIT_STOP_MUST_GREAT_THAN_TOP: "Take profit price should be greater than the upper limit price",
    PROFIT_STOP_MUST_LESS_THAN_BOTTOM: "Take profit price should be less than the lower limit price",
    NOT_FOUND_OPEN_PRICE: "Cannot get the current price",
    DUPLICATE_GRID: "The price range is too small or the grid number is too big",
    TOP_PRICE_IS_TOO_HIGH: "The highest limit price is too high",
    BOTTOM_PRICE_IS_TOO_LOW: "The lowest limit price is too low",
    trade_error_balance_not_enough: "No enough balance.",
    trade_create_order_success: "Created successfully.",
    grid_param_attitude_content_short: "{value} fluctuate and then bearish",
    dialog_google_guide_content2:
        "By checking this, you will be asked to input email/SMS verification when you sign in, and you can also enable Google authenticator in the settings afterwards.",
    dialog_google_guide_do_not_notify: "Do not notify me again",
    google_icator: "Google Authenticator",
    google_importkeytitle: " ",
    countryListNameChina: "China",
    countryListNameHangkang: "HangKang",
    countryListNameMacao: "Macao",
    countryListNameTaiWan: "TaiWan",
    grid_transaction_bottom_tip_negative:
        "It will buy some {base} when creating the bot. Because of the market fluctuation, the average buy price was higher than this grid's selling price resulting in the negative profit",
    loan_grid_title_desc: "Margin Grid",
    short_grid_title_desc: "Reverse Grid",
    leverage_grid_title_desc: "Leveraged Grid",
    grid_liquidated_price: "Liquidation price",
    grid_annualized: "Grid/Total annualized",
    grid_when_trade_tip: "The bot will be started when the {base} price drops to {price} {quote}",
    grid_when_trade_tip1: "The bot will be started when {base} price increases to {price} {quote}",
    grid_order_isnot_running: "Not started yet",
    grid_pro_order_info_createTime_not_start: "Not started yet ({createTime} created)",
    grid_order_change_start_price: "Modify trigger price",
    "balance.grid_leverage": "Leveraged Grid",
    "balance.grid_loan": "Margin Grid",
    "balance.grid_short": "Reverse Grid",
    grid_leverage_danger_tip: "Current price is {leverageDangerPercent} percent close to liquidation price, be careful.",
    grid_leverage_danger_close_title: "Stop the bot？",
    grid_dialog_button_cancle: "Cancel",
    order_type_grid: "GRID TRADING",
    order_type_ig: "INFINITY GRIDS",
    order_type_dca: "DCA",
    order_type_twap: "TWAP",
    order_type_ttp: "TRAILING STOP",
    order_type_stop_limit: "Stop Limit",
    grid_trading_pro: "Grid Trading Pro",
    grid_loan: "Margin Grid",
    grid_short: "Reverse Grid Bot",
    grid_leverage: "Leveraged Grid",
    bot_house_card_lever_describe: "Multiply your profit by adding leverage",
    bot_house_card_lending_describe: "Borrow funds to run the Grid Trading Bot",
    bot_house_card_rg_describe: "Sell crypto high, buy back low",
    market_label1: "Sideways",
    market_label2: "Bullish",
    market_label3: "One direction",
    market_label4: "Bearish",
    market_label5: "Flexible",
    market_label6: "Bullish",
    market_label_aip: "Bearish",
    market_label_smart_trade: "Sideways",
    credit_grid_leverage: "Leverage",
    trade_leverage_setting: "Leverage setting",
    grid_order_price_distance: "Price Range",
    grid_order_trx_24h_trx_history: "24H txs/Total txs",
    borrowed_assets: "Borrowed assets",
    borrowed_assets_content: "(The following coins will be deducted from the total balance)",
    bot_house_card_grid_describe: "24/7 buy low and sell high",
    bot_house_card_ig_describe: "Earn profit while hodling",
    bot_house_card_trailing_stop_describe: "Avoid selling too early in a bull market",
    bot_house_card_twap_describe: "Designed for whales, split order and reduce slippage",
    bot_house_card_aip_describe: "Buy crypto regularly, costs amortization for medium and long terms",
    grid_interset_totle: "Total Interest",
    grid_interset_date: "Date",
    grid_interset_num: "Interest rate",
    grid_interset_rate: "Interest",
    grid_close_order_list_total_profit: "Total/Profit",
    grid_order_close_warning_text: "Do you confirm to stop {symbol} grid bot？",
    grid_pro_content: " Are you sure you want to stop the {base}/{quote} Grid Trading bot? And do you want us to auto sell your {base1} into {quote1}",
    grid_pro_content1: " Are you sure you want to stop the {base}/{quote}Grid Trading bot, and let us buy back {base1} with {quote1}?",
    grid_pro_sell: "Please Sell",
    grid_pro_buy: "Please buy",
    grid_pro_not_sell: "Don't Sell",
    grid_pro_not_buy: "Don't need to",
    lasting: "Active for",
    grid_order_lasting_day: "d",
    grid_order_lasting_hour: "h",
    grid_order_lasting_minute: "m",
    grid_order_lasting_second: "s",
    grid_pro_order_create_time: "(Created {createTime})",
    headder_tabPush: "Trading Competition",
    all_data_complete: "All records has been loaded",
    // 2020/04/08
    grid_leverage_danger_close_tip: "The current price is {leverageDangerPercent} away from liquidation price. Do you want to stop the bot to stop loss?",
    trailing_stop_highest_touch_price: "Highest price hit",
    trailing_stop_average_selling_price: "Avg price sold",
    // 2020/04/10
    vitationalBigEra: "Pionex.US Trading Tournament",
    vitationalParticipants: "# of Participant",
    vitationalRankingName: "Daily Ranking/Name",
    vitationalBotSingularHighestYield: "Highest ROI",
    vitationaCurrentIncome: "Daily Profit",
    vitationaHighestYield: "Max. Drawdown",
    vitationaBotSingular: "Max. Profit %",
    vitationalending: "Margin Grid",
    vitationaposition: "Grid Bot",
    vitationalever: "Leveraged Grid",
    vitationatakeProfit: "Trailing Take Profit",
    assetChangcurve: "Balance (Hourly Update)",
    bigTimeTitle: "The Account for Pionex.US Tournament",
    bigTimeAccountpositions: "Balance",
    bigTimeTotalrevenue: "Total Profit",
    bigTimeTotalreturn: "Profit %",
    bigTimeCurrentorder: "Current Bots",
    bigTimeCumulativeorder: "Accumulated Bots",
    bigTimeBillingWinRate: "Win Rate",
    ankinginthisissue: "Season Rank:",
    talentranking: "TopTrader Rank:",
    numberofpoints: "Pionex.US Point:",
    fundingorder: "Top 15 bots (rank by investment)",
    ruleContestDetails_title: "Intro",
    currentIssue: "Leaderboard",
    talentList: "TopTrader",
    rewardSettings: "Awards",
    eachCompetition: "1. 每次比赛前三名有BUB奖励",
    firstplace: "第一名：1000BUB",
    secondplace: "第二名：600BUB",
    thirdplace: "第三名：300BUB",
    fourthPlace: "第四--第六名：100BUB",
    fifthPlace: "第七--第十名：50BUB",
    demonKing: "2. “大魔王” 奖，Haze 会担任本期大魔王参赛，比赛结果成绩超过 Haze ",
    demonKingChiled: "额外奖励 200BUB",
    gameCycle: "比赛周期:",
    gameCycleChiled1: "每期两周，周一 12 点至下下周一 11 点 59",
    gameCycleChiledTitle2: "第一期比赛时间:",
    gameCycleChiled2: "2020/03/23 12:00 - 2020/03/03/06 11:59",
    addWechantCod_title: "参赛群二维码",
    addWechantCod_content_text: "想参与的用户可以扫描加群报名",
    description_vitationaCurrentIncome: "快照时间点参赛者账户的收益率 ",
    description_vitationaCurrentIncome2: "([当前净值-比赛开始净值]/比赛开始净值)",
    description_vitationaHighestYield: "按照小时快照过程中产生的最高收益率",
    description_vitationaBotSingular: "每个小时统计到的收益率系列中，最高收益率值减去最低收益率值",
    description_vitationaMaximumDrawdown: "截止统计时间点，总计开启的机器人量化订单数量",
    field_titlebox: "Description",
    field_DesTitle1: "Balance",
    field_DesTitle2: "Total Profit:",
    field_DesTitle3: "Total Profit %:",
    field_DesTitle4: "Annualized Profit:",
    field_DesTitle5: "Win Rate:",
    field_DesTitle6: "Current Bots",
    field_DesTitle7: "Accumulated Bots",
    field_DescriptionText1: "Latest snapshot of the balance (USDT)",
    field_DescriptionText2: "Latest snapshot of the total profit",
    field_DescriptionText3: "Latest profit / initial investment",
    field_DescriptionText4: "The annualized according to tournament period and total profit %",
    field_DescriptionText5: "Profitable bots / total bots",
    field_DescriptionText6: "The number of active bots",
    field_DescriptionText7: "The number of total bots",
    contest_number: "SEASON {number}",
    contest_rules_desc_1: " ",
    // 海外大赛规则暂无这一条
    contest_rules_desc_2: "TopTraders will be able to win Pionex.US Points by joining the tournament. 1 Pionex.US Point = 10 BUB",
    contest_rules_desc_3:
        "The Pionex.US Points could be accumulated by attending each tournament. With the points, TopTraders can generate some revenue by allowing others copying their trades.",
    contest_rules_desc_4: "1. Top 7 winner will get PPs in the Daily Ranking every day.",
    contest_rules_desc_5: "2. Top 10 winners of the season 3 will get PP as follows.",
    contest_rules_desc_6: "3. Special Awards: participants who have a better ranking than “HAZE” will get PP according to daily ranking (1 PP) and the final ranking (10 PP).",
    contest_rules_desc_8: "Participants could choose between BUB or USDT according to their PP amount. (PP still stay in your account).",
    contest_rules_desc_9: "Tournament Period: 2 weeks (14 days)",
    contest_rules_desc_11: "比赛期间通过比赛账户在比赛周期内表现的收益率排名，计算方法如下",
    contest_rules_desc_12: "榜单按照当日收益率排名，当日收益率计算起止时间为（今日12:00 - 次日12:00)",
    contest_rules_desc_13: "比赛过程中禁止充值和提现，申购，赎回派币宝等转移资产的行为",
    contest_not_start: "Not started yet",
    contest_no_more: "No more records",
    contest_expert_join_time: "Active Since",
    contest_expert_game_points: "# of Point",
    contest_balance: "Balance",
    contest_gain_bot_percent: "Win Rate",
    contest_day_rank: "Daily Profit Rank",
    contest_chart_tooltips_title: "USDT/Profit %",
    contest_user_balance_pie_chart_title: "Current Portfolio",
    contest_view_all: "Show More",
    contest_close: "Close",
    contest_invest_value: "Investment",
    contest_invest_value_2: "Init. Balance",
    contest_loss_profit: "P/L",
    contest_bot_running_time: "Lasting Time",
    contest_balance_unchanged: "No Balance Change",
    contest_live_expert: "Pionex.US TopTrader",
    contest_expert_balance_update_time: "Hourly balance snapshot since",
    contest_balance_change_line: "Balance (Daily Update)",
    contest_expert_balance_line_tooltips_title_1: "Balance",
    contest_expert_balance_line_tooltips_title_2: "Withdrawal",
    contest_expert_balance_line_tooltips_title_3: "PiHodl Subscribe",
    contest_expert_balance_line_tooltips_title_4: "PiHodl Redeem",
    contest_expert_balance_line_tooltips_title_5: "Lending",
    contest_expert_balance_line_tooltips_title_6: "Redeem",
    contest_joined_contest: "Tournament Records",
    contest_no_joined_contest: "No Records Available",
    contest_expert_join_time_title: "Active Since",
    contest_timeline_balance: "Balance",
    contest_loan_balance: "Borrowed Balance",
    contest_current_day_profit_percent: "Daily Profit %",
    contest_history_trade_records: "History Records",
    contest_order_open: "Open",
    contest_order_close: "Closed",
    contest_close_order_reason: "Reached trigger price",
    contest_expert_type_contest: "Top Investor",
    contest_expert_type_kol: "Influencer",
    contest_expert_type_official: "Editor's Choice",
    contest_total_profit_rank: "Ranking",
    contest_all_contest: "Rank by total profit",
    // 2020-4-17
    grid_pro_order_info_pro_margin: "Margin",
    margin_setting_additional_margin: "Add margin",
    margin_setting_reduced_margin: "Withdraw margin",
    margin_setting_additional_margin_place_holder: "Input additional margin",
    margin_setting_reduced_margin_place_holder: "Input reduced margin",
    trade_available_balance: "Available balance",
    margin_setting_liquidation_risk_rate_after_additional: "Margin level after added",
    margin_setting_liquidation_risk_rate_safe: "Safe margin level",
    margin_setting_additional_margin_button: "ADD NOW",
    margin_setting_additional_not_input: "Please input margin to add",
    margin_setting_reduced_margin_available: "Withdrawable margin",
    margin_setting_liquidation_risk_rate_after_reduced: "Margin level after withdrawn",
    margin_setting_reduced_margin_button: "WITHDRAW NOW",
    margin_setting_reduced_not_input: "Please input magin to be withdrawn",
    margin_setting_reduced_margin_not_enough: "No enough withdrawable margin",
    margin_setting_current_price: "Index",
    margin_setting_liquidation_after_change_down: "How far from liquidation",
    margin_setting_liquidation_after_change_up: "How far from liquidation",
    margin_setting_liquidation_risk_rate: "Liquidation bar",
    margin_setting_liquidation_current_risk_rate: "Current margin level",
    grid_pro_open_order_content1: "Confirming investment...",
    grid_pro_open_order_content2: "Borrowing {value}...",
    grid_pro_open_order_content3: "Reserving fee...",
    grid_pro_open_order_content4_1: "Buying {base} to place orders...\n Have bought {count}{base}",
    grid_pro_open_order_content4_2: "Selling {base}...\nNow left {count} {base}",
    grid_pro_open_order_content5: "Placing the orders {current}/{total}...",
    grid_pro_cancel_order_content1: "Cancelling orders {current}/{total}...",
    grid_pro_cancel_order_content2: "Returning {value}...",
    grid_pro_cancel_order_content3_2: "Buying back {base}...\n{count} {quote} left to be used to buy {base}",
    grid_pro_cancel_order_content3_1: "Selling {base}...\n{count} {base} left to be sold",
    grid_pro_cancel_order_content4: "Confirming investment...",
    trade_grid_per_volume: "Quantity per grid",
    margin_setting_reduced_success: "Withdrawn successfully",
    margin_setting_reduced_failed: "Failed to withdraw",
    margin_setting_additional_failed: "Failed to add margin",
    margin_setting_additional_success: "Added successfully",
    grid_pro_order_liquidating: "The bot is being liquidated",
    trade_canceling_loading_title: "Canceling the order",
    // 2020-4-17
    join_contest: "Register for the tournament",
    contest_rules_desc_7: " ",
    // 海外大赛规则暂无这一条
    contest_rules_desc_14: " ",
    // 海外大赛规则暂无这一条
    contest_rules_desc_15: " ",
    // 海外大赛规则暂无这一条
    contest_rules_desc_17: " ",
    // 海外大赛规则暂无这一条
    contest_rules_desc_10: "Season { number } Period: { startTime } - { endTime }  (UTC)",
    contest_rules_desc_16: "Participants could choose between BUB or USDT according to their PP amount. (PP still stay in your account).",
    contest_rules_desc_18: `Participants will be ranked by th percentage change of their account balance.\n (Final Balance - Initial Balance) / Initial Balance. \n Initial Balance: the initial balance of your account when the tournament start. \n Final Balance: the balance of your account when the tournament end. \n The ranking will be ranked by the percentage change of the daily profit. \n 2. Participants are not allowed to deposit, withdraw, or to use PiHodl during the tournament period.`,
    contest_rules_desc_19: "比赛期间通过实盘账户在比赛周期内表现的收益率排名，计算方式如下",
    contest_rules_desc_21: "Participants can accumulate PP by attending each season of the tournament, which is the points that we select as TopTrader on Pionex.US.",
    contest_rules_desc_22:
        "Notice: \n" +
        "If there's any withdrawal, deposit, PiHodl redemption, or PiHodl subscription, you'll be excluded from the Daily Ranking and the Final Ranking. But you're still allowed to join the Daily Ranking the day after that.",
    contest_rules_desc_23: "净值按照快照时间币价，使用USDT计算。",
    contest_rules_desc_24: "派网账户拥有资产最少1000U及以上并填写本页右上角报名表即可参赛。",
    contest_current_profit: "Daily Profit %",
    contest_total_profit_ranking: "总收益率排名",
    contest_season_3_intro: `To encourage great traders to show how they used the Pionex.US trading tools, Pionex.US holds a series of trading tournaments. Participants could get Pionex.US Points, BUB, or USDT as rewards by winning the tournament. Other users could check and learn how top traders traded by clicking their profile.
    \n In Season 3, there're 2 different leaderboards! One is for "total profit" (USD), another is for "ROI" (%). Participants are allowed to win both awards with the same account on each leaderboard.`,
    contest_season_3_awards_1: `Participants could earn Pionex.US Points (PP) by winning the tournament. You'll get BUB or USDT with the following ratio, 1 PP = 10 BUB or 1 PP = 5 USDT.
    \n Participants can accumulate PP by attending each season of the tournament, which is the points that we select as TopTrader on Pionex.US.
    \n According to your ranking on "Total Profit" or "ROI", you can get awards as following.
    \n 1. Top 7 winner will get PPs in the Daily Ranking every day.`,
    contest_season_3_awards_2: "2. Top 10 winners of the season 3 will get PP as follows",
    contest_season_3_awards_3: `3. Special Awards: participants who have a better ranking than “HAZE” will equally share a PP pool according to daily ranking (20 PP) and the final ranking (100 PP)
    \n Tournament Period: 2 weeks (14 days)
    \n Season 3 Period: 2020/04/27 04:00 - 2020/05/11 03:59 (UTC)`,
    contest_season_3_rules: `1. Participants will be ranked by the percentage change of their account balance.\n (Final Balance - Initial Balance) / Initial Balance
    \n Initial Balance: the initial balance of your account when the tournament start.\n Final Balance: the balance of your account when the tournament end.
    \n The ranking will be ranked by the percentage change of the daily profit.
    \n 2. Participants are not allowed to deposit, withdraw, or to use PiHodl during the tournament period.
  `,
    contest_season_3_notice: `Notice: 
    If there's any withdrawal, deposit, PiHodl redemption, or PiHodl subscription, you'll be excluded from the Daily Ranking and the Final Ranking. But you're still allowed to join the Daily Ranking the day after that.`,
    contest_season_3_how_to_join: `How to join season 3?`,
    contest_season_3_how_to_join_content:
        "Users with more than 200 USDT assets on Pionex.US could join the tournament. Register through the sign-up form on the upper right of this page.",
    contest_new_explain: `Daily ROI: the rate of return according to the daily snapshot.
    \n ([Current Balance - The Previous Day's Balance]/The Previous Day's Balance)
    \n The period of the daily ROI calculation: 04:00 ~ next day 04:00 (UTC)
    \n Total ROI: the rate of return compared to the initial balance at the snapshot moment.
    \n ([Current Balance - Initial Balance]/Initial Balance)
    \n The period of the total ROI calculation: 4/27 04:00 ~ 5/11 04:00 (UTC)
    \n N/A: Participants who have deposits, withdrawals, or PiHodl redemption and subscription will be considered as violating the competition rules. Their daily profit ranking and total profit ranking will be N/A.`,
    contest_profit_rate_leaderboard: "ROI",
    contest_profit_value_leaderboard: "Total Profit",
    contest_day_profit_value: "Daily Profit",
    contest_total_profit: "Total Profit",
    contest_total_profit_value_rank: "Total Profit Ranking",
    contest_new_explain_profit_value: `Daily Profit: the profit compared to the daily snapshot
   \n [Current Balance - Net Deposit - The Previous Day's Balance]
   \n The duration for daily profit: 04:00 ~ next day 04:00 (UTC)
   \n Total Profit: the total profit compared to the initial balance.
   \n [Current Balance - Net Deposit - Initial Balance]
   \n The duration for total profit: 4/27 04:00 ~ 5/11 04:00 (UTC)
   \n N/A: participants who violate the tournament rules will have N/A on their ranking.`,
    contest_expert_remark_bot_switch: "Check records with Trading Idea",
    contest_expert_remark_bot_title: "Trading Idea",
    contest_user_profit_value_rank: "Profit Rank (Daily/Overall): {number1}/{number2}",
    contest_user_profit_rate_rank: "ROI Rank (Daily/Overall): {number1}/{number2}",
    contest_user_desc: `Balance: the equivalent balance in USD
     \n Profit: the net profit of the participant.
     \n Daily ROI: the rate of return according to the daily snapshot.
     \n ([Current Balance - The Previous Day's Balance]/The Previous Day's Balance)
     \n The duration for daily ROI: 04:00 ~ next day 04:00 (UTC)
     \n ROI: the rate of return compared to the initial balance at the snapshot moment.
     \n ([Current Balance - Initial Balance]/Initial Balance)
     \n The duration for ROI: 4/27 04:00 ~ 5/11 04:00 (UTC)
     \n Annualized Return: period returns re-scaled to a period of one year
     \n Current Order: the number of active bots at the snapshot.
     \n Accumulated Order: the number of all the bots at the sanpshot.
     \n Win Rate: Profitable orders divided by total orders.
     \n MDD: The highest ROI minus the lowst ROI in a hourly snapshot basis.`,
    // 2020-4-22
    contest_commendatory_order: "Discover",
    contest_show_rule_button: "Tournament Rules (expand for more details)",
    contest_hide_rule_button: "Collapse",
    contest_daily_profit_top5: "Moon Ranking Top5 ",
    contest_total_profit_top5: "ROI Ranking Top5 ",
    contest_trade_count_top5: "Transaction Ranking Top5 ",
    contest_running_time_top5: "Marathon Ranking Top5 ",
    trade_symbol: "Pair",
    contest_order_create_time: "Created at { date }",
    contest_current_profit_value: "Profit",
    contest_total_invest_value: "Total Investment",
    contest_running_time: "Lasting Time",
    contest_trade_count: "Transaction Times",
    contest_times: "Times",
    contest_hour: "h",
    contest_find_order_tips1: "orders with the highest ROI which lasted more than 24 hours.",
    contest_find_order_tips2: "orders with the highest ROI since the beginning of the tournament.",
    contest_find_order_tips3: "orders with the most transactions which lasted more than 24 hours.",
    contest_find_order_tips4: "orders with the highest investment and the longest lasting time.",
    // 2020-4-30
    bot_house_card_lever_short: "Leveraged Reverse Grid",
    short_leverage_grid_title_desc: "Leveraged Reverse Grid",
    contest_btc_rules: `大赛简介：
     \n 亲爱的派网用户，派网交易机器人实盘榜单是给普通用户展示自己交易技能和学习他人交易技巧的榜单。点击本页报名按钮，可以申请入驻榜单，每周一，我们会更新一次名单。对于每月表现优异的用户，我们将邀请他参与『官方直播间』活动，直播自己的交易课程。未来优先享受带单权利。
     `,
    contest_season_4_time: "Register at any time and update the list every Monday",
    contest_daily_rank: "Daily",
    contest_month_rank: "Monthly",
    contest_participant: "Participant List",
    order_number: "Order ID",
    view_order_details_login_tips: "Log in and check the parameter of the bot.",
    grid_leverage_short: "Leveraged Reverse Grid",
    bot_house_card_lever_short_describe: "Use leverage in Reverse Grid Bot to earn more coins",
    interest_returned_title: "Returned",
    interest_borrowed_title: "Borrowed",
    future_grid_title_desc: "Futures Grid",
    future_information_url: "https://medium.com/@mario_40812/binance-futures-grids-bot-tutorial-12787a4c0b7a",
    deposit_withdraw_etc_attention_content: "This is ETC(Ethereum Classic) but not ETH(Ethereum), and they are two different chains.",
    deposit_withdraw_etc_attention_check: "I have confirmed and understand if i deposit the wrong coins, they can not be found back.",
    grid_future_long: "(Long{coin})",
    grid_future_short: "(Short{coin})",
    transfer_profit_error_20002: "Releasing other profits. Please try again later.",
    transfer_profit_error_30003: "Invalid coin",
    transfer_profit_error_30004: "Invalid quantity",
    transfer_profit_error_no_support: "There is no profit to release.",
    grid_profit_extract_content_bottom: "Part of your grid profits will be re-invested in the bot, the Releasable Grid Profit is less than the Grid Profit",
    grid_profit_extract_content_bottom1:
        "Because of the precision, in order to create the bot successfully, we might have locked more funds. Hence you can release that part, which makes the releasable profit higher than the grid profit.",
    grid_profit_extract_1: "Releasable",
    grid_profit_extract_2: "Released",
    grid_profit_extract_confirm: "Release",
    grid_pro_profit_extract: "Release Profit",
    balance_summary_title: "Total balance",
    balance_summary_right_title: "24H Change",
    balance_trade_title: "Trading account",
    balance_fund_title: "PiHodl account",
    balance_fund_detail: "Details >",
    balance_pie_title: "Trading account allocation",
    balance_bot_title: "Trading account's profit",
    balance_coin_title: "Trading account details",
    balance_bot_subTitle: "RUNNING BOTS",
    balance_bot_right_title: "Today's profit",
    balance_bot_row_type: "My bots",
    balance_bot_row_pair: "Trading pairs",
    balance_bot_row_investment: "Investment",
    balance_bot_row_profit_loss: "Today's/ History profit",
    balance_coin_list_title: "Trading account details",
    balance_coin_list_row_available: "Tradable",
    balance_coin_hold_title: "MY HOLDING ASSETS",
    balance_bot_no_data_title: "No running bots",
    balance_bot_no_data_content: "Select trading bots from the bot house to start the profitable journey!",
    balance_bot_no_data_button_title: "Create a bot",
    balance_coin_no_data_title: "No asset",
    balance_coin_no_data_content: "Deposit more, earn more!",
    balance_coin_no_data_button_title: "Deposit Now",
    grid_close_order_btn_tip: "Stop the bot",
    grid_profit_extract_btn_tip: "Release profit",
    grid_profit_extract_success: "Released profit successfully! You released",
    no_running_bot: "No running bot",
    create_bot_order: "Create a bot",
    balance_pie_order_no_data: "No bot",
    balance_pie_coin_no_data: "No asset",
    symbol_tab_type_pionex_leverage: "LEVERAGED",
    support_tab_blog: "Blog",
    order_msg_user_cancel: "Manually cancelled",
    order_msg_profit_stop: "Auto took profit",
    order_msg_loss_stop: "Auto stopped loss",
    order_status_canceled: "Canceled",
    order_status_traded: "Traded",
    ///> new
    modified_trade_take_condition_price: "Modify trigger price",
    modified_trade_stop_loss_price: "Modify stop loss price",
    modified_trade_stop_profit_price: "Modify take profit price",
    modify_succeeded: "Modified successfully.",
    grid_profit_extract_content_bottom2: "The releasable profit is 0 because your profits are used as reserved transaction fee.",
    sign_in_header_text: "Welcome back to the world’s first exchange with built-in trading bots",
    sign_up_header_text: "Welcome to the world’s first exchange with built-in trading bots",
    sign_up_protocol_disagree: "Disagree",
    dont_have_account: "Don't have an account?",
    already_have_account: "Already have an account?",
    account_input_placeholder: "Email",
    password_input_placeholder: "Password",
    sign_in_success: "Signed in successfully!",
    invalid_username_toast: "Please input a valid account name",
    account_deposit: "Deposit",
    account_receive: "Receive Crypto",
    account_check_bots: "Check bots",
    latest_news_title: "Latest news of Pionex.US",
    invite_recommend: "recommend you to use Pionex.US",
    withdraw_btc_lighting_invalid: "Haven't supported the {coin} lightning network. Please change to another address not beginning with bc.",
    tv_order_line_eyes_desc: "Show buy/sell price levels",
    homepage_download_alert_desc: "Automate your trades on Pionex.US App.",
    homepage_menu_dropdown_txt_earn: "Earn",
    homepage_menu_item_txt_pionex_quota: "Listed markets",
    homepage_menu_item_txt_btc_quota: "CoinCap",
    homepage_menu_dropdown_txt_market: "Market",
    homepage_serve_link_name_email: "Email",
    homepage_ad_banner_text_box_quantify: "AUTOMATED",
    homepage_ad_banner_text_box_you: "Crypto",
    homepage_ad_banner_text_box_trading: "Trading Bot",
    homepage_lasting_time_day: "DAYS",
    homepage_lasting_time_hour: "hr",
    homepage_lasting_time_min: "min",
    homepage_lasting_time_sec: "sec",
    homepage_lasting_time_logo: "Pionex.US",
    homepage_lasting_time_title: "has been operated safely for",
    homepage_qual_name_licensed: "Licensed",
    homepage_qual_name_licensed_text_1: "Money Services Business by FinCEN",
    homepage_qual_name_aggregated: "Aggregated",
    homepage_qual_name_aggregated_text: "We aggregated the liquidity from Binance and Huobi, so your funds are securely stored in them.",
    homepage_qual_name_backed: "Backed",
    homepage_qual_name_backed_text: "Pionex.US is tech-supported by BitUniverse, and invested by Banyan Capital, Shunwei Capital and Zhenge Capita",
    homepage_botIntro_examples: "Examples",
    homepage_botIntro_examples_text: "More examples of how people use trading bots?",
    homepage_botIntro_fee: "Fee rebate",
    homepage_botIntro_fee_text: "Up to 50% fee rebate + BUB (BitUniverse Token)",
    homepage_botIntro_newbots: "Vote for new bots",
    homepage_botIntro_newbots_text: "Join our facebook trading group",
    homepage_botIntro_tutorial: "Arbitrage Bot",
    homepage_botIntro_videos: "How to earn passive income with arbitrage bot.",
    homepage_bot_support_title: "Automate your trading with these 12 Trading Bots",
    homepage_bot_support_title_tip:
        "Forget about the monthly subscription fee for automated trading bots. Pionex provides FREE crypto bot built within the cryptocurrency exchange.",
    homepage_bot_support_title_text: "Make money when BTC fluctuates in 2,000-20,000",
    homepage_downloadapp_ios: "iOS",
    homepage_downloadapp_android: "Android",
    homepage_downloadapp_title_1: "Trade Anytime, Anywhere",
    homepage_downloadapp_title_2:
        "Download the mobile application to track your strategy statistics, create grid trading bot, DCA bot, and Arbitrage Bot. Manage your Bitcoin trading robot anywhere with the mobile app.",
    homepage_botname_gridtradingbot: "Grid Trading Bot",
    homepage_botname_leveragedgridbot: "Leveraged Grid Bot",
    homepage_botname_margingridbot: "Margin Grid Bot",
    homepage_botname_reversegridbot: "Reverse Grid Bot",
    homepage_botname_infinitygridbot: "Infinity Grid Bot",
    homepage_botname_dcabot: "DCA Bot",
    homepage_botname_twapbot: "TWAP Bot",
    homepage_botname_ttpbot: "TTP Bot",
    homepage_botname_leveragedreversebot: "Leveraged Reverse Bot",
    homepage_commu_telegram: "Telegram",
    homepage_commu_twitter: "Twitter",
    homepage_commu_facebook: "Facebook",
    homepage_commu_reddit: "Reddit",
    homepage_commu_medium: "Medium",
    homepage_commu_operation_title: "Auto trade from now on",
    homepage_pionex_rights: "2022, Pionex.US All Rights Reserved",
    homepage_bot_list_title: "Bots",
    homepage_communities_list_title: "Communities",
    coin_leverage_target_lev: "Target leverage",
    coin_leverage_current_lev: "Current leverage",
    coin_leverage_last_balancing: "Last rebalance",
    coin_leverage_basket: "Basket",
    coin_leverage_basket_value: "Basket value",
    coin_leverage_futures: "Futures",
    title_can_not_receive_code: "Can not receive the code?",
    content_can_not_receive_code: "Sometimes the SMS will be blocked by the carrier. You can try again or receive a call to get the code.",
    verify_code_sms: "SMS",
    verify_code_call: "Call",
    dw_coin_limit_info_url: "https://pionex.zendesk.com/hc/en-us/articles/360040244593",
    dw_announcement_url: "https://pionex.zendesk.com/hc/en-us/categories/4409944240153-Announcements",
    // 1v1客服
    trade_guide_param_title_1: "Profit yours. Loss, Ours.",
    trade_guide_param_url_1: "https://medium.com/pionex/create-grid-bot-on-pionex-profit-yours-loss-ours-for-new-users-28351026d344",
    trade_guide_param_title_2: "Grid Trading Bot Tutorial",
    trade_guide_param_url_2: "https://www.pionex.us/blog/pionex-grid-bot/",
    trade_guide_param_title_3: "Pionex.US Referral program",
    trade_guide_param_url_3: "https://www.pionex.us/blog/pionex-referral-campaign-en/",
    trade_guide_trade_title_1: "Infinity Grids Tutorial",
    trade_guide_trade_url_1: "https://www.pionex.us/blog/pionex-infinity-grid-bot/",
    trade_guide_trade_title_2: "Leveraged Grid Tutorial",
    trade_guide_trade_url_2: "https://www.pionex.us/blog/leveraged-grid-bot/",
    trade_guide_trade_title_3: "Margin Grid Tutorial",
    trade_guide_trade_url_3: "https://www.pionex.us/blog/margin-grid-bot/",
    trade_guide_trade_title_4: "What's Pionex.US Leveraged Token",
    trade_guide_trade_url_4: "https://pionex.zendesk.com/hc/en-us/articles/360049185674",
    trade_guide_title: "Pionex.US Guide",
    trade_advanced_guide_title: "Advanced Guide",
    trade_expert_bilibili_url: "",
    trade_guide_expert_latest_order: "The latest bot from Top Trader",
    trade_expert: "Top Trader",
    trade_expert_active_since: "Check more trading records",
    total_open_order: "Bots",
    trade_utils_guide: "Tutorials",
    my_total_bots: "Grid Summary",
    my_max_profit: "Max. Profit Per Bot",
    my_max_long_order: "Longest Running Time Per Bot",
    my_total_order_running_time: "Running Time of the Bots (total)",
    my_grid_bot_exclude_ig: "Infinity grid bot excluded",
    my_grid_bot_statistics_date: "Since 2020/03/24",
    tab_order_book: "Orderbook",
    tab_transactions: "Transactions",
    order_title_profit_today: "Today's profit",
    show_this_pair_only_1: "Show only {base}/{quote}",
    ticker_bar_fiat_price: "Fiat price",
    tv_interval_more: "More",
    tv_choose_indicator: "Choose indicators",
    tv_reset_indicator: "Reset indicators",
    tv_indicator: "Indicators",
    symbol_favor_login_tip: "Please sign in first to check the picked pairs",
    login_dialog_popup_title: "Please sign in or register first",
    order_title_status: "Status",
    order_history_title: "History",
    running_history_title: "My Bots' Details",
    trading_limit: "Limit",
    trading_market: "Market",
    trading_page_tab_manual: "Manual",
    trading_advanced_stop_limit_pro: "Stop Limit",
    trading_advanced_stop_limit_pro_desc: "Stop profit or loss in time",
    grid_detail_quantity: "Quantity",
    grid_current_price_pro: "Current price",
    sl_pro_order_status: "Status",
    sl_pro_order_not_trigger: "Untriggered",
    sl_pro_order_trigger: "Triggered",
    grid_pro_created: "Created",
    trade_buy_price: "Buy price",
    trade_sell_price: "Sell price",
    trade_capital_amount: "QUANTITY",
    sl_pro_order_detail_des: "Click the button on the right side to get more details",
    home_order_no_sign_content: "Please sign in or register first\nto check your orders.",
    separate_buy: "Grid buy",
    separate_sell: "Grid sell",
    sepatate_trading_create_error: "The order of which price is {price} was in error. Error code: {error}",
    side_bar_title: "Quick dock",
    side_bar_change_tips: "Switch to {activeName}",
    side_bar_dark_theme: "Dark mode",
    side_bar_normal_theme: "Light mode",
    advanced_grid_bots: "Advanced grid bots",
    other_grid_bots: "Other bots",
    tip_used_scene: "Fitting markets：",
    tap_auto_fill_text: "Tap here to auto fill in the AI parameters",
    tutorial_bot: "Tutorial of Grid Trading Bot",
    advanced_settings: "Advanced settings",
    order_type_smart_trade: "Smart Trade",
    tv_full_screen: "Full screen",
    tv_full_screen_close: "Exit full screen",
    tv_normal_type: "Switch to standard",
    tv_pro_type: "Switch to Pro",
    home_peck_sygna_title1: "AML & CFT",
    home_peck_sygna_title2: "Partener:",
    home_peck_sygna_sb: "Sygna Bridge",
    home_peck_sygna_pe: "Peckshield",
    home_sg_about_title: "About",
    home_sg_about_announcement: "Announcement",
    home_sg_about_agreement: "User Agreement",
    home_sg_about_terms: "Terms",
    home_sg_about_support: "Support",
    symbol_picker_pull_close: "Click this area to close the window",
    down_load_gp_version: "Google Play version",
    down_load_gp_version_desc: "Download from Google Play",
    down_load_apk_version: "APK version",
    down_load_apk_version_desc: "Download using apk file",
    down_load_app_store_version: "AppStore version",
    down_load_app_store_version_desc: "Download from AppStore",
    down_load_testflight_version: "TestFlight version",
    down_load_testflight_version_desc: "Download the latest version from TestFlight to try the new functions",
    sign_up_in_add_favor_symbol: "You need to sign in first to pick trading pairs.",
    sign_up_in_create_order: "You need to sign in first to create a bot.",
    download_button_text: "Download",
    header_trade_game: "Leaderboard",
    order_title_close_time: "Filled time",
    expert_order_details_close_order_time: "Close Time",
    expert_order_details_open_price: "Entry Price",
    expert_order_details_create_price: "Entry Price",
    expert_order_details_top: "Upper Limit",
    expert_order_details_bottom: "Lower Limit",
    expert_order_details_order_type: "Type",
    expert_order_details_arithmetic: "Arithmetic",
    expert_order_details_geometric: "Geometric",
    expert_order_details_create_order_time: "Create",
    expert_order_details_update_order_time: "Update",
    expert_order_details_direction: "Attitude",
    expert_order_details_bearish: "Bearish",
    expert_order_details_bullish: "Bullish",
    ROI: "ROI",
    bounty_create_create: "Create",
    side_bar_big_kline: "Expanded chart",
    side_bar_small_kline: "Standard chart",
    side_bar_switch_to_theme_dark: "Switch to dark mode",
    // 新的气泡提示字段
    side_bar_switch_to_theme_normal: "Switch to light mode",
    // 新的气泡提示字段
    side_bar_switch_to_small_kline: "Switch to standard chart",
    // 新的气泡提示字段
    side_bar_switch_to_big_kline: "Switch to expanded chart",
    // 新的气泡提示字段
    manual_order_filled_buy: "Bought {count}{base}，average price {price}",
    manual_order_filled_sell: "Sold {count}{base}，average price {price}",
    order_canceled_warning_content_type: "Order type",
    dw_history_internal_address: "Internal address",
    dw_history_internal_hash: "This one is internal transaction",
    my_dca_number_text_sg: "My DCA Bot on pionex.sg",
    my_infinity_number_text_sg: "My Infinity Grids Bot on pionex.sg ",
    my_grid_trading_number_text_sg: "My Grid Trading Bot on pionex.sg",
    side_bar_jump_to_my_trade_page: "My bots",
    side_bar_switch_to_ump_to_my_trade_page: "Tap to entry bots details page",
    deposit_withdraw_title: "Transfer",
    deposit_withdraw_submitted_error: "Invalid parameters. Please fix following the hints.",
    deposit_withdraw_remitted: "Withdrawn",
    smart_trade_bots: "Trailing bots",
    trailing_buy_order_type: "Trailing Buy",
    trailing_sell_order_type: "Trailing Sell",
    bot_house_card_smart_trade_desc: "Set buy price, (trailing) take profit price and stop loss price at one time",
    deposit_withdraw_ltc_invalid_address: "We haven't supported LTC lightning network, so the address can not begin with ltc",
    home_sg_privacy_policy: "User Privacy Policy",
    market_label7: "Bearish",
    trade_expected_vol: "Expected Vol",
    symbol_picker_start_new_trade: "Trade",
    coin_introduction: "Introduction",
    leverage_low_price_cannot_create_title: "Failed to create the bot",
    leverage_low_price_cannot_create_content:
        "The current price of this leveraged token is lower than 0.1USDT, so the risk is very high and not favourible for using bots. Please use manual trading if you still want to buy it.\nBesides, we might reverse split the shares in the future, and you won't suffer any loss during the split.",
    order_status_error_appear: "Error occurred",
    loginUp_haved_pionex: "Log in with Pionex.US",
    loginUp_haved_bituniverse: "Log in with BitUniverse",
    loginUp_by_phone: "Phone",
    loginUp_by_email: "Email",
    invalid_password_toast_1: "8-16 characters only",
    account_referrals: "Referrals",
    not_set: "Not set",
    set_big: "Set",
    fill_investor_referrals_title: "Fill in the referral code",
    investor_referrals_code: "Referral code",
    had_fill_referrals_code: "You've filled in the referral code",
    can_not_fill_again: "the code can't be modified",
    fill_referrals_tip:
        "The referrer's code can only be filled in within 14 days after the registration or 3 days after registration if you have created any bots.Please confirm that the inputted code is correct. The modification will be forbidden after flling.",
    filled_success: "Fill in successfully",
    fill_referrals_error_not_found: "The user corresponding to the referral code is not found, please check the entered code",
    fill_referrals_error_expired: "The referral code can only be filled in within 14 days after the first login. It has exceeded the valid period and cannot be filled in now",
    fill_referrals_error_had_bound: "It has been filled in successfully and cannot be modified",
    fill_referrals_error_had_order: "You have already created trading bots' order and only new customers can fill the referral code.",
    fill_referrals_error_had_circle: "This user has already invited other people and you can't use his/her referral code",
    invalid_username_toast_phone_1: "Invalid phone number",
    error_not_fill_investor_referrals: "Missing referrer invitation code",
    // defi 挖矿
    fund_tab_defi_mint: "DEFI挖矿",
    profit_release_avg_price: "Avg buy price",
    profit_release_breakeven_price: "Breakeven price",
    smart_trade_average_selling_price: "Avg sell price",
    smart_trade_sell_trigger_price: "Sell trigger price",
    condition_grid_tip: "The bot will be started when the {base} price drops to {price} {quote}",
    condition_grid_tip1: "The bot will be started when {base} price increases to {price} {quote}",
    condition_grid_tip_fee: ",and you won't be charged interest before it's started.",
    leverage_grid_tip: "The current price is less than {value} from the liquidation price. Please pay attention to the risk.",
    grid_share_profit_negative: "Temporary loss for wrong prediction",
    grid_share_trend_grid: "{base} is fluctuating",
    grid_share_trend_leverage: "{base} is going up",
    grid_share_trend_loan_quote: "{base} is going up",
    grid_share_trend_loan_base: "{base} is going down",
    grid_share_trend_ig: "{base} is going up",
    grid_share_trend_short: "{base} is going down",
    grid_share_grid_slogan: "I use Grid Trading Bot",
    grid_share_grid_aip: "I use Recurring Buy Bot",
    grid_share_leverage_slogan: "I use Leveraged Grid Bot",
    grid_share_loan_slogan: "I use Margin Grid Bot",
    grid_share_ig_slogan: "I use Infinity Grid Bot",
    grid_share_short_slogan: "I use Reverse Grid Bot",
    grid_share_trend_smart_trade: "{base} is pumping",
    grid_share_smart_trade_slogan: "I use Smart Trade",
    grid_share_leverage_short_slogan: "I use Leveraged Reverse Grid",
    other_bot_order_cancel_warning_content: "Your {BotName} will be terminated after confirmation and the bought coins won't be automatically sold.",
    not_enough_balance: "No enough balance",
    invalid_api_key: "Your API is invalid. Please check if you have disabled the permission or deleted it.",
    no_orderbook: "No orderbook",
    error_get_ask1_failed: "Failed to get bid and ask data",
    "withdraw.limit.precision": "Wrong precision of withdrawal amount, max precision should be {precision}",
    bot_house_card_trailing_sell: "Trailing Sell",
    bot_house_card_trailing_buy: "Trailing Buy",
    trade_cost_quantity: "Buy volume",
    trailing_stop_lowest_touch_price: "Min touched price.",
    trade_invest_range_max: "Required ≤ {max}",
    trade_input_note_please_close: "Please input close note.",
    trade_max_rebound: "Max rebounce",
    trade_total_vol: "Buy volume",
    trade_trading_buy_expect_buyIn: "Estimated buy quantity",
    trade_trading_buy_extra_buyIn: "Extra earn",
    smart_trade_limit_price: "Limit order",
    smart_trade_buy_base_avg_price: "Avg buy price",
    trade_all: "All",
    grid_share_smart_trade_buy_only_slogan: "I use Trailing Buy Bot",
    grid_share_smart_trade_sell_only_slogan: "I use Trailing Sell Bot",
    //need translate
    profit_release_total_title: "Total {coin}",
    profit_release_total_buy_order_title: "Total buy orders",
    profit_release_total_sell_order_title: "Total sell orders",
    profit_release_total_reserved_fee: "Reserved fee",
    order_detail_title_profit: "#/Profit",
    order_detail_title_fee: "Fee",
    order_detail_tab_name_balance: "Profit/Loss Chart",
    order_detail_tab_name_place: "Placed Orders",
    order_detail_tab_name_parameters: "Parameters",
    order_detail_tab_name_transactions: "Transactions",
    order_detail_tab_name_girdChartV7: "7 day change",
    order_detail_tab_name_girdChartV30: "30 day change",
    order_twap_next_investment: "Next investment",
    order_twap_asset_allocation: "Asset allocation",
    order_twap_complete_title: "Completed",
    order_dca_total_balance_title: "Total balance",
    bot_list_no_data_all: "There is no running bot",
    bot_list_no_data_orderType: "There is no running {botType} bot",
    profit_release_grid_order_high: "Upper limit price",
    profit_release_released_profit: "Released profit",
    grid_pro_order_setting_text: "Setting",
    grid_pro_order_interest_text: "Interest",
    grid_pro_order_share_text: "Share",
    grid_close_order_btn_text: "Stop the Bot",
    grid_pro_order_setting_tip: "Profit mode setting",
    grid_pro_order_interest_tip: "Detailed interest information",
    order_error_reason_show_title: "Error",
    order_error_reason_show_content: "Error code:",
    order_error_reason_show_content_1: "Please feel free to contact us in the telegram group if you have any doubts",
    order_error_reason_show_ok: "Join telegram group",
    aipcard_total_invested_asset: "Total investment",
    bot_house_card_trailing_buy_desc: "Best tool to buy the dip",
    smart_trade_title_desc: "Smart Trade",
    grid_placed_how_far_fill: "How far to fill",
    aipcard_total_invested_asset_sell: "Sold quantity",
    chart_title1: "24H txs: {count1} / Total txs: {count2}",
    grid_pro_condition_cancel_content: "Are you sure you want to stop the {base}/{quote} Grid Trading bot?",
    trade_detail_net_buy_cost: "Holdings buy price",
    trade_detail_net_sell_cost: "Holdings sell price",
    // 新版资产详情页
    // 复用client
    future_assets: "Assets",
    spot_overview: "Spot",
    future_overview: "Futures",
    future_no_analytics: "Pionex.US Futures is based on Binance broker system, and we are not able to provide data analytics so far.",
    transfer_spot_to_future_success: "Transferred to the futures account.",
    transfer_future_to_spot_success: "Transferred to the trading account.",
    asset_change_30d: "30D balance change",
    trading_account_details: "Trading account details",
    trading_account_details_holding: "Holdings",
    balance_allocation_pie_chart_title: "Balance allocation",
    balance_list_occupation: "Occupation",
    futures_balance: "Futures account (Wallet balance + Unrealized profit)",
    futures_wallet_balance: "Wallet balance",
    future_unrealized_profit: "Unrealized profit",
    balance_portfolio: "Overview",
    futures_account_details: "Future account details",
    spot_today_profit: "Today's profit allocation",
    futures_account_balance: "Futures account",
    spot_account_balance: "Spot account",
    fund_account_balance: "PiHodl account",
    transfer_from: "From",
    transfer_to: "To",
    home_page_team_desc_tittle: "Pionex.US Team",
    home_page_team_desc_desc: "The Singapore based team is experienced in finance.",
    home_page_bloomberg_title: "Bloomberg",
    home_page_bloomberg_middle_text: "Reported Pionex.US's innovation",
    home_page_bloomberg_desc_text: "Pionex.US is a leading cryptocurrency bot platform that provides robot trading service.",
    home_page_grid_trading_bot_desc: "How to use Grid Trading Bot to make money?",
    team_desc_ceo_name: "Daniel Seng-Cheng YEO",
    team_desc_ceo_title: "Pionex.US Global CEO",
    team_desc_ceo_desc:
        "Daniel has more than 30 years of experience in Finance industry, with Hong Kong SFC Securities Qualification Paper 1 RO for type 2 license，SFC. He was the Senior Vice President in KGI Futures Ltd (HK SFC ADW991), and Deputy GM, MD and Responsible Officer for SFC type 2 License in Founder Futures Ltd. He also worked for Goldman Sachs, HSBC before.",
    team_desc_cxo_name: "Larry Toh",
    team_desc_cxo_title: "Pionex.US Chief Compliance Officer",
    team_desc_cxo_desc:
        "Larry joined Pionex.US in June 2020.  Larry is qualified as a solicitor of New South Wales, Australia in 2008 and started his career in a private law firm before joining United Overseas Bank Limited as an in house legal counsel supporting the Global Market Investment Management division in 2009. He joined the Securities and Fund Services industry in 2012 working as product manager to the trustee product division of Citibank (Hong Kong) Limited. Having worked across various jurisdictions in Asia, his expertise lies in Hong Kong and Singapore legal and regulatory matters, product authorization, licensing, technology related compliance, legal entity structuring.",
    grid_share_bottom_tip: "Newbies can enjoy no-loss bot trading privilege.",
    bot_house_card_ct_arbitraging: "Spot-Futures Arbitrage Bot",
    year_back_7: "7D APR",
    ct_arbitraging_back_data_tip: "Tip: The APR is calculated by the history data. The higher the leverage is, the higher the profit is, but the risk is also higher.",
    cross_time_price_offset_now: "Current price gap",
    fsa_order_fs_profit: "Arbitrage profit",
    fsa_order_next_rate: "Next funding fee",
    fsa_today_year_back: "Today's APR",
    fsa_history_year_back: "History APR",
    fsa_opening_position_cancel_content: "You have opened the position of {value}. Do you want to keep arbitraging with this or close all positions right now?",
    fsa_opened_position_cancel_content: "Are you sure you want to close the {base} Spot-Futures Arbitrage Bot?",
    fsa_cancel_optionA: "Keep the position",
    fsa_cancel_optionB: "Close all position",
    fsa_order_status_step_bottom: "Current price difference control: {direction}{value}. \nIf you need to speed up, please tap the lightning button.",
    fsa_order_status_step_bottom_1: "Current price difference control: {direction}{value}",
    fsa_order_open_status_1: "1/3 Allocating your fund...",
    fsa_order_open_status_2: "2/3 Configuring the Futures settings...",
    fsa_order_open_status_3: "3/3 Opening position...",
    fsa_order_open_status_content: "Opened {current}/{total}{quote}. Current price gap: {value}",
    fsa_order_close_status_1: "1/2 Closing position...",
    fsa_order_close_status_2: "2/2 Gathering fund...",
    fsa_order_liquidate_tip: "There will be liquidation risk after the coin price has increased by {value}, so we will auto close the bot after price has reached {price} {quote}.",
    fsa_order_adjust_differential: "Price gap control",
    fsa_modify_loss_profit: "Price gap control setting",
    fsa_order_close_status_content: "{current} {base} to be closed. Current price gap: {value}",
    trade_error_no_future_api_key: "Failed to get Futures API Key. Please sign out and then sign in again.",
    fsa_error_had_cross_positions:
        "Because the margin might be locked when you have a crossed position with a very big fluctuation, so we don't allow to create an arbitrate bot when you have crossed positions. Please close these coins' positions first: {symbols}",
    fsa_error_had_position: "You have placed manual orders in futures or created a bot. Please cancel it and then create the new arbitrage bot.",
    fsa_close_order_had_cross_positions:
        "We detected that you have crossed positions in your Futures account. Your released margin might be locked by it, so the funds can not be transferred to the spot account. Are you sure to close this bot?",
    fsa_close_order_error_of_verify_order: "Failed to detect the position. Please contact the support. Error code: {error}",
    fsa_order_gap_change_max: "Max price gap is {max}",
    fsa_order_gap_change_min: "Min price gap is {min}",
    fsa_order_gap_change_range: "Please input a number between -100 to 100",
    fsa_order_operation_add_position: "Add investment",
    fsa_order_operation_reduce_position: "Withdraw investment",
    fsa_order_add_position_total: "Additional investment {total}{quote}",
    fsa_order_add_position_max: "Max available is {max}{quote}",
    fsa_order_reduce_position_max: "Max withdrawable number can not be greater than 90%",
    fsa_order_reduce_position_max_title: "Max withdrawable",
    fsa_order_add_position_none_input: "Please input the number",
    fsa_order_reduce_position_none_input: "Please input the number",
    fsa_order_price_offset_now: "Current price gap: {current}",
    future_spot_income_funding_blank: "In settlement.",
    bot_house_card_ct_arbitraging_desc: "15%-50% APR with low risk",
    fsa_order_add_position_success: "Adding investment begins",
    fsa_order_reduce_position_success: "Withdrawing investment begins",
    fsa_order_open_status_4: "Adding investment...",
    fsa_order_open_status_4_content: "Added {current} {quote}. Current price gap: {value}",
    fsa_order_open_status_5: "Withdrawing investment...",
    fsa_order_open_status_5_content: "{current} {base} position to be closed. Current price gap: {value}",
    fsa_order_adjust_position_status_content: "Transferring the funds",
    fsa_order_add_position_total_need: "Funds to be add",
    fsa_order_reduce_position_total_need: "Proportion to be withdrawn",
    fsa_order_reduce_position_range_min: "(Min withdraw should > {min})",
    fsa_order_add_position_range_min: "(Min add should ≥ {min})",
    fsa_order_reduce_position_range_min_not_enough: "The current investment is less than the min required precision. So you can not withdraw. Please stop the bot directly.",
    fsa_order_adjust_position_had_cross_positions: "We detected that you have crossed positions. Please close first.",
    fsa_error_query_position: "Failed to get position status. Error code: {error}",
    fsa_order_profit_withDrawn_had_cross_positions:
        "We detected that you have crossed positions. If you release the profit now, your profit might be locked in the Futures account. Are you sure to release?",
    fsa_order_profit_withDrawn_max: "Max releasable: {max}",
    fsa_order_profit_withDrawn_success: "{amount} {quote} released",
    fsa_order_adjust_position_interrupt_success: "Stopped creating successfully.",
    fsa_order_open_position_interrupt_success: "Stopped adding investment successfully",
    future_margin_error_operation_forbidden: "You have an arbitrage bot now, so you can not deduct or add margin.",
    fsa_order_add_position_interrupt: "{current} {quote} added. Are you sure to stop adding investment?",
    fsa_title_desc: "Spot-Futures Arbitrage",
    future_spot_income_open_position_fee: "Open fee",
    future_spot_income_close_position_fee: "Close fee",
    future_spot_income_realized_pnl: "Realized profit after closed",
    future_spot_income_liquidate_realized_pnl: "Realized profit after liquidated",
    future_spot_income_insurance_fee: "Liquidation fee",
    future_spot_income_adl_realized_pnl: "Realized profit after auto deleveraged",
    future_spot_income_funding_fee: "Arbitrage profit",
    future_spot_income_funding_expect: "Est arbitrage profit",
    grid_detail_profit_withdrawn: "The releasable profit",
    grid_share_fsa_slogan: "I use Arbitrage Bot",
    grid_share_trend_fsa: "Earn interests everyday",
    grid_share_fsa_title_7_days: "7D APR",
    grid_share_fsa_pair: "Coin",
    future_spot_income_annualized: "APR",
    loading_more_data: "Loading data...",
    list_unfold: "Unfold",
    list_hidden: "Fold",
    annualized_7_days: "7D APR",
    annualized_30_days: "30D APR",
    annualized_180_days: "180D APR",
    fsa_order_reduce_position_interrupt: "{current} {base} to be deducted. Are you sure to stop?",
    fsa_order_operation_margin: "Margin management (Advanced)",
    fas_advanced_setting: "Advanced settings",
    fsa_order_operation_margin_content:
        "Because the arbitrage bot will use Futures to short, you have margin in that isolated Futures account. You can add or withdraw margin to change the liquidation price, and this will make the bot run longer or increase your fund utilization rate. Your total investment will be added or deducted after.",
    fsa_order_operation_margin_content_none: "Don't remind me again",
    fsa_order_close_gap_tip_content: "Now the price gap is {current}, which is high and you might lose this part when closing. Are you sure you want to close now?",
    fsa_order_reduce_position_gap_tip_content:
        "The current price difference is {current}, and a bit high. You might lose this price difference if you cancel the order now. When you withdraw the investment, a part of your releasable profit will become released profit. Are you sure to withdraw investment?",
    fsa_order_gap_option_A: "Fast mode (Max gap {max})",
    fsa_order_gap_option_B: "Default mode (Max gap {max})",
    fsa_order_profit_bottom_tip:
        "Tip: arbitrage profit is the margin in the Futures. So when your Futures part is under loss, you won't be able to release the profit. You can withdraw investment or close the bot to get all your profits.",
    fsa_order_reduce_position_interrupt_success: "Stopped withdrawing successfully",
    fsa_order_details_event: "Item",
    fsa_order_details_current_annualized: "APR",
    pionex_description:
        "Pionex.US is the best crypto trading bot currently available, 24/7 trading automatically in the cloud. Easy to use, powerful and extremely safe. Trade your cryptocurrency now with Pionex.US, the automated crypto trading bot.",
    pionex_og_title: "Crypto Trading Robot | Free Crypto Trading Bot | Pionex.US",
    pionex_keywords: "crypto, cryptocurrency, crypto trading bot, trading bot, cryptocurrency trading bot, automated trading",
    pionex_og_description:
        "Pionex.US is the best crypto trading bot currently available, 24/7 trading automatically in the cloud. Easy to use, powerful, and extremely safe. Trade your cryptocurrency now with Pionex.US trading robot, the automated crypto trading bot.",
    future_margin_input_placeholder: "Input margin({coin})",
    future_margin_current_value: "Current margin",
    future_margin_additional_max: "Max available funds",
    future_margin_reduced_max: "Max withdrawable",
    future_margin_additional_liq_price: "Liquidation price after adding",
    future_margin_reduced_liq_price: "Liquidation price after withdrawal",
    margin_setting_add_margin_not_enough: "No enough funds to add margin",
    future_funding_fee: "Funding fee",
    fsa_order_operation_close_order: "Close the bot",
    grid_profit_extract_3: "Non-releasable profit",
    fsa_order_price_gap_bottom_tip_1: "Tip: The lower the price gap control is, the more quickly it will be when you create the bot or add investment",
    fsa_order_price_gap_bottom_tip:
        "Tip: The higher the price gap control is, the more quickly it will be when you close the bot or withdraw investment. But you may lose that much slippage.",
    fsa_order_adjust_position_tip: "({direction}{current}, still waiting. Please modify price gap control if you want to speed it up.）",
    fsa_order_gap_price_change: "Edit price gap control",
    fsa_order_open_status_6: "Rebalancing the leverage...",
    fsa_order_open_status_6_content_3: "Adding margin",
    dw_coin_main_chain_desc: "Mainnet",
    manual_service_title: "Support",
    manual_service_side_bar_desc: "Chat with support",
    request_time_out: "Network timeout. Please switch network or try again later.",
    future_spot_income_transfer_in_margin: "Add margin",
    future_spot_income_transfer_out_margin: "Withdraw margin",
    future_spot_income_transfer_out_profit: "Release profit",
    future_spot_income_reduce_leverage_reduce_position: "Auto deleverage - deduct position from spot",
    future_spot_income_reduce_leverage_charge_margin: "Auto deleverage - add position to futures",
    future_spot_income_add_position: "Add investment",
    future_spot_income_reduce_position: "Withdraw investment",
    fsa_subtract_position_desc: "The profit and APR will be recalculated based on the investment after withdrawing investment.",
    fsa_add_position_desc: "The profit and APR will be recalculated based on the investment after adding investment.",
    fas_auto_reduce_leverage_desc:
        "To avoid being liquidated when the coin price is rising, the bot will auto close a part of positions, and transfer the funds from spot account to futures account. Your investment won't be changed, but the liquidation price will be increased to keep the bot safe.",
    fsa_add_position_fee: "Adding investment fee",
    fsa_reduce_position_fee: "Withdrawing investment fee",
    fsa_reduce_leverage_fee: "Auto deleverage fee",
    fsa_reduce_position_realized_pnl: "Withdrawing investment profit",
    data_waiting_for_resolution: "Data to be analyzed",
    fsa_order_close_status_retrieve_data: "Waiting for Binance futures data...",
    fund_overview: "PiHodl",
    error_qimo_not_loaded_desc: "The support system is updating. Please refresh later.",
    manual_service_maintain_tip:
        "Hello, now the livechat support team is not available. The service time is 1:00-15:00(UTC). If you have any question, you can join our telegram group to ask: https://t.me/+P2ezWZWM6qMxZDM9.\n" +
        "We are also hiring more staff to 7/24 serve our users. Thanks for understanding.",
    manual_service_join_kakao: "Join Kakao",
    future_spot_income_deduct_strategy_fee: "Arbitrage bot server fee",
    future_spot_income_deduct_insurance_fee: "Arbitrage SAFU",
    fsa_price_gap_change_sub: "Click the Accelerate button to accelerate the process",
    fsa_order_adjust_position_tip_1: "({direction}{current}，Click the Accelerate button to accelerate the process)",
    fsa_gap_limit_max: "Max",
    fsa_gap_change_topspeed_title: "Accelerate",
    fsa_gap_change_topspeed_content: "The price difference control determins how fast your bot can be canceled. Set Max to ignore the difference control to accelerate the speed.",
    grid_pro_order_cancel_double_check_content:
        "The market making activities on trading pair {base}/{quote} is temporarily suspended. Trading on this trading pair at this time might cause a huge loss. Do you still want to close the bot now?",
    fsa_joined: "Joined",
    fsa_have_not_joined: "Haven't joined",
    grid_pro_order_cancel_maintained_content_1:
        "{base}/{quote} is now under maintenance,so you can only close the bot without selling {base},and you can sell later after maintenance. Are you sure to close the bot?",
    grid_pro_order_cancel_maintained_content_2:
        "{base}/{quote} is now under maintenance,so you can only close the bot without buy back {base},and you can buy back later after maintenance. Are you sure to close the bot?",
    can_not_create_bot: "Can not create the bot",
    create_the_bot: "Create the bot",
    bot_cancel_error_50: "As {symbol} is now under maintenance, you could not cancel the bot at this time. Please pay attention to the announcement.",
    fsa_gap_change_topspeed_content_1:
        "The price difference control determines the speed of adding investment. Set 'Max' to ignore the difference control to accelerate the speed.",
    fsa_gap_change_topspeed_content_2:
        "The price difference control determines the speed of withdrawing investment. Set 'Max' to ignore the difference control to accelerate the speed.",
    fsa_gap_change_topspeed_content_3:
        "The price difference control determines how fast your bot can be canceled. Set 'Max' to ignore the difference control to accelerate the speed.",
    fsa_gap_change_topspeed_content_4:
        "The price difference control determines how fast your bot can be created. Set 'Max' to ignore the difference control to accelerate the speed.",
    fsa_cancel_error_50: "{symbol} is now under maintenance, so it can not be traded. You can check the latest status in the announcement center.",
    leverage_coin_exam_tutorial_url: "https://pionex.zendesk.com/hc/en-us/articles/360049185674-What-are-Pionex-Leveraged-Tokens",
    grid_order_stuck_button_progress_order_cancel: "The bot was stuck for more than 5 minutes. \nTap the right button to cancel.",
    leverage_coin_test: "Leveraged tokens test",
    question_not_answer_end: "You haven't finished. Please continue.",
    re_answer: "Answer again",
    leverage_coin_test_answer_failed: "Failed to submit",
    leverage_coin_test_answer_failed_desc:
        "You chose the wrong option in the No.{indexs} question. You must pass the test to enable leveraged tokens trading. Please check the right answers and then tap the Retest button below.",
    leverage_coin_test_answer_success: "You passed the test",
    leverage_coin_test_answer_success_desc: "Now you can start trading leveraged tokens",
    enter: "Submit",
    submit_error: "Failed to submit. Please try again later or contact the support team. Error code: {error}",
    answer_to_start_leverage_coin_trade: "Pass the test to enable leveraged tokens",
    learn_to_start_leverage_coin_trade_desc: "You need to answer all questions correctly to enable leveraged tokens trading. Please first read:",
    read_leverage_coin_tutorial: "Leveraged tokens tutorial",
    already_know_leverage_coin_risk: "I have understood the risk",
    start_answer: "Start answering",
    read_tutorial_tip: 'Please read the tutorial and confirm all details, and then first check "I have understood the risk".',
    fsa_order_deduct_status: "Accumulating Arbitrage SAFU",
    leverage_coin_exam_state_query_error: "Failed to get the test status. Please check if you have signed in, or contact the support.",
    // 每个页面的title和description
    homepage_title: "Crypto Trading Robot | Free Crypto Trading Bot | Pionex.US",
    homepage_description:
        "Pionex.US is the best crypto trading bot currently available, 24/7 trading automatically in the cloud. Easy to use, powerful, and extremely safe. Trade your cryptocurrency now with Pionex.US trading robot, the automated crypto trading bot.",
    tc_page_title: "Crypto signals | Crypto Trading Signals | Pionex.US Trading Bot",
    tc_page_description: "Get free crypto signals from professional traders on Pionex.US. Trade with crypto trading signals on Pionex.US automated trading bot platform.",
    referral_page_program_title: "Pionex.US Referral Program",
    referral_page_program_description:
        "Pionex.US has the most profitable referral program. You can earn up to 50% commissions in the crypto referral program on Pionex.US. Invite and earn today.",
    market_page_title: "Bitcoin live marketcap, chart, and info | Pionex.US",
    market_page_description:
        "Explore live Crypto Price Chart in USD and other currencies and cryptocurrencies. Get today's real-time crypto price index, current value️ on major exchanges, market capitalization, and more data.",
    coincap_page_title: "Crypto live marketcap, chart, and info | Pionex.US",
    coincap_page_description:
        "Explore live Crypto Price Chart in USD and other currencies and cryptocurrencies. Get today's real-time crypto price index, current value️ on major exchanges, market capitalization, and more data.",
    fees_page_title: "Pionex.US Trading Fee | 0.1%",
    fees_page_description:
        "Pionex.US has the lowest trading fee available according to major exchanges. Only charges 0.05% for the spot market and don't need to hold any platform tokens. Come trade on Pionex.US with the lowest trading fee.",
    googleAuthenticator_page_title: "Pionex.US Google 2FA | Pionex.US",
    orderdetails_page_title: "Order Details | Pionex.US Trading Bot",
    verification_page_title: "Pionex.US KYC",
    team_page_title: "Pionex.US Team & Background",
    team_page_description: "Pionex.US team is based in Singapore and has a track record on financial regulation.",
    download_page_title: "The Best Crypto Trading Bot App | Pionex.US",
    download_page_description: "Pionex.US is the leading crypto trading bot platform that is robust, effective, and free. Download the Pionex.US app and get it for free.",
    coinlist_page_title: "Crypto info and official website | Pionex.US",
    coinlist_page_description:
        "Explore live Crypto Price Chart in USD and other currencies and cryptocurrencies. Get today's real-time crypto price index, current value️ on major exchanges, market capitalization, and more data.",
    balance_page_title: "Account Balance | Pionex.US",
    my_profile_page_title: "Pionex US Accounts",
    reset_password_page_title: "Pionex | Exchange with Built-in Crypto Trading Bot",
    sign_page_title: "Start Pionex.US Trading Bot for Free | Pionex.US",
    withdraw_page_title: "Crypto Withdrawal | Pionex.US",
    trade_page_title: "{base} price chart Pionex.US",
    trade_page_description:
        "Explore live {base} Price Chart in {quote} and other currencies and cryptocurrencies. Get today's real-time {base} price index, current value️ on major exchanges, market capitalization, and more data.",
    fsa_error_query_future_open_order: "Failed to get futures information. Error code: {error}",
    fsa_error_had_limit_order: "You have placed {symbol} manual orders in futures. Please cancel it and then create the arbitrage bot.",
    leverage_merge_announce_title: "Reverse Split of {coin}",
    leverage_merge_split_title: "During the reverse split period:",
    leverage_merge_split_action_desc:
        "1. All existing bot trading orders and manual orders trading on {coin} will be canceled automatically. All the {coin} and USDT frozen by trading bots will be returned to users' spot accounts directly.\n" +
        "2. Tradings on {coin} and {coin1} will be suspended.\n" +
        "3. We will then take a snapshot of {coin} balances in users' accounts and begin the reverse token split of {coin} tokens at a ratio of {multiplier} {coin} (before the reverse split) = 1 {coin} (after the reverse split).\n" +
        "4. The balances of the {coin} will be updated, and the tradings for {coin} and {coin1} will be opened at this time.\n" +
        'Please check the {coin} asset in "My holding assets balance" after the reverse split.',
    leverage_merge_done_announce_content:
        "Dear users,\n" +
        "To provide a better trading experience for our users, {coin} have completed a 1-for-{multiplier} reverse token split, starting at {time}, {year}.{month}.{day}. The total asset value of {coin}, that a user held will not be affected by the reverse split.",
    leverage_merge_announce_content:
        "Dear Users,\n" +
        "To provide a better trading experience for our users, {coin} will undergo a 1-for-{multiplier} reverse token split, starting at {time}, {year}.{month}.{day}. The process will take approximately 30 minutes, and the total asset value of {coin}, that a user held will not be affected by the reverse split.",
    leverage_merge_announce_about: "About the reverse split of the Pionex.US Leveraged Token",
    leverage_merge_announce_about_content:
        "When the leveraged token price is less than 0.05 USDT, to avoid having any adverse effect on users' trading experience, Pionex.US will undergo a reverse token split on that leveraged token.\n" +
        "A reverse token split is a process that consolidates the existing number of issued tokens into a smaller number of proportionally more valuable tokens.\n" +
        "Based on the ratio of the {multiplier}-to-1 reverse split of {coin}, if the price of {coin} is 0.05 USDT and a user holds {multiplier} {coin} before the reverse split, the user will end up holding 1 {coin} worth {value} USDT each after the reverse split. The total value of the {coin} holding by the user will not change and remain {value} USDT before or after the reverse split.",
    leverage_low_price_warning_title: "Failed to create.",
    leverage_low_price_warning_content:
        "The leveraged token's price has been below 0.1 USDT, and it has a high probability of being reverse split 1 for 50. If you want to continue trading, please use manual trading instead.",
    leverage_merge_risk_title: "Reverse split risk warning",
    leverage_merge_risk_content: "The leveraged token's price has been below 0.06 USDT, and it has a high probability of being reverse split 1 for 50.",
    leverage_merge_risk_stop_limit_content: "The stop limit order trigger price has been below 0.06 USDT, and it has a high probability of being reverse split 1 for 50.",
    leverage_merge_risk_continue_create_order: "Continue buying",
    leverage_merge_risk_not_remind: "Don't remind me in one week",
    leverage_merge_info_tips: "{coin} was reverse split 1 for {multiplier} at {time} {month}/{day}/{year}",
    leverage_merge_announce_click_tip: "Tap the following link to check more information",
    leverage_merge_click_link: "Leveraged token knowledge",
    leverage_coin_exam_submit: "Submit",
    leverage_coin_exam_pass: "Passed",
    leverage_coin_exam_not_pass: "Not passed",
    fsa_total_profit_pro: "Total profit",
    fsa_other_fee_title: "Fee",
    spot_balance_small_asset_transfer: "Dust Collector",
    spot_balance_small_asset_transfer_unselect_all: "Unselect All",
    spot_balance_small_asset_transfer_select_all: "Select All",
    spot_balance_small_asset_transfer_available: "Value",
    spot_balance_small_asset_do_transfer: "Convert",
    spot_balance_small_asset_transfer_confirm: "Confirm",
    spot_balance_small_asset_quantity: "Total amount：",
    spot_balance_small_asset_fee: "Transaction fee：",
    spot_balance_small_asset_real_amount: "After fee deducted：",
    spot_balance_small_transfer_success: "Success!",
    spot_balance_small_transfer_failed: "Converted Failed",
    spot_balance_small_transfer_failed_no_price: "Error occurs, could not find the trading pairs",
    spot_balance_small_transfer_interval_limit: "You could only convert small balance to USDT for once within 6 hours",
    spot_balance_small_transfer_no_coins: "No valid balance to convert",
    tapi_trade_withdraw_forbidden: "Your account is forbidden for withdrawing. Please contact the support.",
    spot_balance_small_transfer_select_info_1: "Selected coins: ",
    spot_balance_small_transfer_select_info_2: " You will get: ",
    sign_success_kakao_title: "KaKao talk",
    main_page_ko_naver_title: "Naver blog",
    leverage_stuck_jump_url: "https://pionex.zendesk.com/hc/en-us/articles/900007030803-Pionex-will-launch-2L-2S-leverage-tokens-to-replace-3L-3S-leverage-tokens",
    leverage_stuck_bot_title: "Can't create trading bot",
    leverage_stuck_manual_title: "Can't buy",
    leverage_stuck_bot_content: "This leverage token is risky and you can't create new trading bot. If you want to trade with leverage, please use 2L/2S leverage token instead.",
    leverage_stuck_manual_content: "This leverage token is risky and you can't buy manually. If you want to trade with leverage, please use 2L/2S leverage token instead.",
    leverage_stuck_trade_continue: "Trade {base}",
    leverage_stuck_list_show: "Get restricted list",
    future_spot_income_reduce_leverage_realized_pnl: "Realized profit after auto-deleverage",
    // 0419
    header_support_live_chart: "Live chat",
    header_support_faq: "FAQ",
    header_support_youtube: "Video",
    header_qrcode_desc: "Use your mobile device to scan QR code to download",
    header_qrcode_more: "More Options",
    header_menu_community: "Community",
    setting_theme_dark_desc: "Change the theme except for the front page",
    homepage_commu_discord: "Discord",
    home_header_img: "pionex automated trading bot",
    home_header_slogan: "Auto trade cryptocurrency with",
    home_header_slogan_hl: "{botCount} Free built-in trading bots",
    home_header_get_bots_btn: "Get bots for free",
    home_header_get_bots_email: "Input your email",
    home_header_get_bots_phone: "Input phone number",
    home_act_grid_bot_h1: "Grid Trading Bot",
    home_act_grid_bot_sub: "Make profits from the volatility, with 0.05% fee",
    home_act_grid_bot_p1: "The bot can help you to auto buy low and sell high in a price range. Even when you are sleeping, working or having a holiday, the bot never stops.",
    home_act_grid_bot_p2: "The bot can also help to buy the dip, and earn more coins than holding.",
    home_act_grid_bot_alt: "Pionex.US Grid Trading Bot",
    home_act_arb_bot_h1: "Arbitrage Bot",
    home_act_arb_bot_sub: "Earn 15%-50% APR with extremely low risk",
    home_act_arb_bot_p1: "The bot utilizes the Futures market which is powered by Binance broker system, to earn interests (funding fee) every 8 hours.",
    home_act_arb_bot_p2: "You can invest from $50 with no lockup period.",
    home_act_arb_bot_alt: "Pionex.US Crypto Arbitrage Bot",
    home_act_trade_h1: "Trade with USD",
    home_act_trade_sub: "Deposit or withdraw using your bank account",
    home_act_trade_p1: "If you want to use this function, you need to install Pionex.US Lite app first. Pionex.US standard version only supports transferring cryptocurrencies. ",
    home_act_trade_p2: "Note: This function is not available in all countries/regions.",
    home_act_trade_alt: "Buy Bitcoin and Ethereum",
    home_act_btn: "Learn more",
    home_press_art: "Press & Articles",
    home_press_bloomberg: "Pionex.US is a rising star which has more than 100,000 users with 5 billion trading volume per month.",
    home_press_bloomberg_alt: "pionex reported by bloomberg",
    home_press_bitcion: "With {botCount} automated trading tools, Pionex.US acquires lots of users in 2020 and gets viral in 2021.",
    home_press_bitcion_alt: "Pionex.US reported by bitcoin dot com",
    home_art_tile1: "Crypto Arbitrage Bot",
    home_art_content1:
        "Pionex.US arbitrage bot helps investors seize arbitrage opportunities in the volatile crypto market. Passive income with low risks between 15% and 50% APR.",
    home_art_tile2: "Grid Trading Bot",
    home_art_content2: "Buy low and sell high with the Pionex.US grid trading bot. Check out how you can make profits with the automated trading bot.",
    home_art_tile3: "Arbitrage 101",
    home_art_content3:
        "Why you should NOT do crypto arbitrage manually? Crypto arbitrage has been discussed between crypto traders. Check out how arbitrage work in crypto market.",
    home_art_tile4: "The best moment to buy Bitcoin in 2021",
    home_art_content4: "What's the best strategy to invest in Bitcoin? Check out how to use grid trading bot to fight against the market!",
    home_secure_title: "Security",
    home_secure_title_sub: "Pionex.US has been operating safely for (days)",
    home_secure_agg_alt: "Binance broker",
    home_secure_agg_title: "Aggregated",
    home_secure_agg_content:
        "Pionex.US aggregates the liquidity from Binance and Huobi near the ticker price. Pionex.US is one of the biggest brokers of Binance, and one of the biggest market makers of Huobi in the world.",
    home_secure_back_alt: "investors of pionex",
    home_secure_back_title: "Backed",
    home_secure_back_content: "Pionex.US is invested by Gaorong Capital, Shunwei Capital and ZhenFund for more than 10,000,000 USD",
    home_secure_license_alt: "Pionex.US licence",
    home_secure_license_title: "Licensed",
    home_secure_license_content: "Pionex.US has acquired MSB(Money Services Business) license by FinCEN",
    home_reviews_title: "Reviews",
    home_reviews_it_title1: "Absalom",
    home_reviews_it_title2: "Tom Hudson Bloor",
    home_reviews_it_title3: "webb32503",
    home_reviews_it_title4: "DaChronMan",
    home_reviews_it_content1:
        "Pionex.US helps me to fight against the volatile crypto market with the cryptocurrency trading robot! I don't need to worry about the market pump and dump while I sleep anymore!",
    home_reviews_it_content2:
        "Plenty of trading strategies and lots of built-in trading bots that are free! Took a while to learn about each strategy and the parameter settings, but after that, I really enjoy the automated trading with the bot.",
    home_reviews_it_content3:
        "I love using Pionex.US grid trading bot with alt-coins. It helps me to lower the risks and automatically buy and sell between each swinging market. Automated your trade with Pionex.US!",
    home_reviews_it_content4:
        "I hate how the Bitcoin price fluctuates. Thank god that I found Pionex.US spot-futures arbitrage bot. The arbitrage bot helps me to generate steady income, and I'm kinda enjoying it.",
    home_reviews_post_on: "Posted on",
    home_foot_join: "Join Pionex.US now",
    home_foot_download_btn: "Download app",
    home_foot_menu_about_title: "About",
    home_foot_menu_about_team: "Team info",
    home_foot_menu_about_terms: "Terms and conditions",
    home_foot_menu_about_fee: "Fee",
    home_foot_menu_about_coin: "Coin list",
    home_get_bots_modal_title: "You have already got an account with this email",
    home_get_bots_modal_content: "You can directly sign in with this account to start using the bos.",
    bots_success_title: "Got successfully",
    bots_success_content: "Check your email inbox for more information. Register with this email to start using the bot.",
    bots_success_btn_tutorial: "Check tutorials",
    bots_success_btn_download: "Download app",
    trade_manual_leverage_amount_left: "Remaining quota",

    /* 注册落地页start */
    bot_invite_header_h1: "Never Miss Any",
    bot_invite_header_h2: "Fluctuation",
    bot_invite_header_sub: "Catch Every Tiny Profits with the Automated Trading Bot",
    bot_invite_form_recommend: "recommend you to try Pionex.US",
    bot_invite_form_email: "Email",
    bot_invite_form_email_hint: "Invalid email address",
    bot_invite_form_email_require: "Please enter the email",
    bot_invite_form_password: "Password",
    bot_invite_form_password_hint: "The password must between 8 to 16 characters。",
    bot_invite_form_password_require: "Please set the password",
    bot_invite_features_title: "Features of the Bot",
    bot_invite_features_sleep_h1: "Make Money While You Sleep",
    bot_invite_features_sleep_p: "24/7 completely automate trading. Free yourself from concentrating on the volatile market!",
    bot_invite_features_dip_h1: "Buy the Dip",
    bot_invite_features_dip_p: "Decrease your average buying price dramantically in a sudden price drop.",
    bot_invite_features_extra_h1: "Earn More Bitcoin than HODL",
    bot_invite_features_extra_p: "Start the bot with the bitcoin you hold and earn more bitcoin from the fluctuantions！",
    bot_invite_trade_worldwide: "Trade with Traders Worldwide",
    bot_invite_faq_title: "FAQ",
    bot_invite_faq_1_title: "Can I start a bot with less than $100?",
    bot_invite_faq_1_p:
        "Yes, you can. The minimum investment amount depends on the token you invest in and the strategy you choose. And some of the bots can be started with an investment of even less than $20.",
    bot_invite_faq_2_title: "How the grid bot works?",
    bot_invite_faq_2_p:
        "Grid trading bot can be understood as a net with many sell and buy orders, which can auto buy low sell high to make profits as long as the current price is within the price range (net range). As long as the market fluctuates, the bot will keep buying low and selling high to make profits.",
    bot_invite_faq_3_title: "How much profit could I make with the grid bot?",
    bot_invite_faq_3_p:
        "We could not assure any profit. It depends on your choice and the market conditions. However, when trading with the grid bot, the grid profit will cover some of the loss from the token's price drop.",
    bot_invite_faq_4_title: "Is it safe trading in Pionex.US?",
    bot_invite_faq_4_p:
        "Firstly, Pionex.US got the Money Services Business license issued by FinCEN. Secondly, as one of the biggest brokers in Binance, Pionex.US aggregated the liquidity from Binance and Huobi, and your funds are securely stored in them. Last but not least, Pionex.US keeps 100% reserve, ready for immediate withdrawal on demand.",
    bot_invite_verify_modal_title: "Please enter the verification code.",
    bot_invite_verify_modal_content:
        "The verification code has been sent to {email}, please enter the verification code. The mail with the code might be judged as spam, please check with cautious.",
    bot_invite_resend_verification_code: "Resend",
    bot_invite_send_verification_code: "Send",
    bot_invite_resend_time_verification_code: "Resend({seconds}S)",
    bot_invite_verification_code: "Verification code",
    view_related_announcements: "Check announcement",
    leverage_coin_trading_country_disable: "Leveraged tokens not available",
    bot_trading_country_disable: "{bot} bot not available",
    bot_trading_country_disable_details: "Check details",
    verification_code_send_too_quick: "requests for verification code are too often",
    grid_detail_dialog_advance_balance: "Pre placed orders",
    grid_detail_list_footer_text: "Now we use dynamic placing orders tech, and we only place 100-120 orders near current price.",
    grid_v3_title_desc: "Grid Trading",
    trade_quantity: "Quantity",
    sr_operation: "Operation",
    sr_transaction: "Transactions",
    sr_next_rebalance_time: "Next Rebalacing",
    sr_hold_coin_change: "Balance change",
    trading_view_interval_tab_minute: "{interval}m",
    trading_view_interval_tab_hour: "{interval}H",
    trading_view_interval_tab_day: "{interval}D",
    trading_view_interval_tab_week: "{interval}W",
    trading_view_interval_tab_month: "{interval}M",
    trading_view_interval_tab_year: "{interval}Y",
    trading_view_interval_tab_all: "All",
    sr_trade_content:
        "The bot will rebalance every {minute} minutes to keep the occupation of 2 coins are both 50%. When one coin price is increasing too fast or decreasing too fast, the bot will sell it and buy the other one. If the volume gap is lower than the minimum required trading volume, the bot will skip that time of rebalancing.",
    sr_record_item_none: "The price changes smaller than minimum required volume, so the bot skipped this time.",
    trading_double_coin_rebalance: "Dual-coin mode",
    trading_double_coin_rebalance_desc: "Simplified settings. HODL 2 valuable assets.",
    smart_rebalance_to_choose_coin: "Choose a coin",
    sr_current_hold_coin_1: "Current balance",
    sr_init_hold_coin_1: "Balance when opening",
    trading_multi_coin_rebalance_desc: "Customized settings. HODL your own index.",
    sr_cancel_content_and: " and ",
    sr_cancel_content: "Are you sure to close {coins1} Dual-coin Rebalancing Bot? Do you want us to sell {coins2}?",
    sr_cancel_content_1: "Are you sure to close the {name} Multi-coins Rebalancing Bot? Do you want us to sell all your {count} coins?",
    sr_cancel_status_open_content_1: "Buying {coins} and other {count} coins. To avoid slippage, we will use VWAP to buy {count} coins.",
    sr_cancel_status_close_content_1: "Selling {coins} and other {count} coins. To avoid slippage, we will use VWAP to sell {count} coins.",
    add_a_new_coin: "Add new coins",
    trading_multi_coin_rebalance: "Multi-coins mode",
    trading_smart_rebalance: "Rebalancing Bot",
    trading_smart_rebalance_desc: "Create your own index",
    grid_trading_moon: "Strategy",
    grid_trading_moon_desc: "Ultra-wide range grid bot, an alternative to hold for long term",
    grid_trading_moon_btc_usdt: "Bitcoin Moon Bot",
    grid_trading_moon_eth_usdt: "Ethereum Moon Bot",
    grid_trading_moon_url: "https://www.pionex.us/blog/zh-cn/pionex-ultrawidegridtradingbot/",
    balance_withdraw_sending: "Sending",
    code_verify_error: "Invalid verification code",
    disable_withdraw_not_whitelist_address: "You have enabled withdraw to the whitelist only function, so you can not withdraw to any other addresses.",
    withdraw_error_cause_modify_password_after_24h: "You cannot withdraw in 24 hours after you have changed your password. Contact support if it's urgent.",
    withdraw_invalid_code: "Invalid verification code.",
    withdraw_code_verify_failed_google: "Invalid Google 2FA code.",
    withdraw_code_not_exist: "This verification code has expired. Please resend and re-input.",

    /* kol_rebate */
    kol_rebate_mine: "My earnings",
    kol_rebate_details: "Details",
    kol_rebate_header_my_rebate_ratio: "My commission rate",
    kol_rebate_header_total_fee: "Total commission(USDT)",
    kol_rebate_header_total_people: "Total invitees",
    kol_rebate_header_total_trade_people: "Total used invitees",
    kol_rebate_invite_link_tab: "My Referral links",
    kol_rebate_invite_status_tab: "Invitees detail",
    kol_rebate_invite_link_add_btn: "Add new link",
    kol_rebate_invite_links_comment: "Note",
    kol_rebate_invite_links_ratio: "Friends Receive",
    kol_rebate_invite_links_code: "Referral code",
    kol_rebate_invite_links_people: "Total used invitees/Total invitees",
    kol_rebate_invite_links_add_modal_title: "Add new link",
    kol_rebate_invite_links_add_modal_content:
        "Your base commission rate is{percent}%，please select the friends' commission kickback rate (The rate for your friends could not be edited once the link is generated.)",
    kol_rebate_invite_links_add_modal_comment_pre: "Please set the note for your link to help memorizing",
    kol_rebate_invite_links_add_modal_comment_placeholder: "Note(Optional)",
    kol_rebate_invite_links_add_modal_ok: "Generate your link",
    kol_rebate_invite_links_defualt: "default",
    kol_rebate_invite_links_table_actions_edit: "edit",
    kol_rebate_invite_links_table_actions_default: "set as default",
    kol_rebate_invite_links_table_actions_copy: "copy link",
    kol_rebate_invite_links_default_modal_title: "set as default",
    kol_rebate_invite_links_default_modal_content:
        "After setting this link as default, your friends will receive {percent}% when trading. The link will set as your referral link in App and website. Are you sure you want to set this link as the default link?",
    kol_rebate_invite_links_edit_modal_title: "Edit",
    kol_rebate_invite_links_edit_modal_content: "Please enter the note for link",
    kol_rebate_invite_status_user_name: "Friends Account ID",
    kol_rebate_invite_status_user_grid: "Rebate",
    kol_rebate_invite_status_start_use: "Used",
    kol_rebate_invite_status_not_use: "Not used",
    kol_rebate_invite_bind_expired: "Beyond the valid time for binding",
    kol_rebate_invite_bind_max: "You can create up to 20 referral links",
    kol_rebate_rebate_details_timestamp: "Date",
    kol_rebate_rebate_details_value: "Rebate",
    "header.account.menu.customer.rebate": "Rebate",
    kol_rebate_err_40000300: "Invalid Parameter",
    kol_rebate_err_40001100: "超出边界",
    kol_rebate_err_40300600: "Failed to operate with the database",
    kol_rebate_err_40300700: "Failed to upgrade",
    kol_rebate_err_40300500: "Failed to send the email",
    kol_rebate_err_40300800: "Failed to bind the ",
    kol_rebate_err_50001300: "Internal Error",
    kol_rebate_err_50001400: "Internal Error in exchanges",
    kol_rebate_err_50001500: "Failed to get the current price",
    kol_rebate_err_no_auth: "No permission to use this platform",

    /* kol_rebate */
    account_forget_password_new_password: "New password",
    "header.account.menu.address": "Address book",
    address_book_title: "Address book",
    white_address_list_title: "Whitelist",
    all_address_list_title: "All addresses",
    address_add_page_title: "Add a new address",
    white_list: "Whitelist",
    more_setting: "More settings",
    withdraw_with_address: "Send to this address",
    rename: "Rename",
    join_to_whitelist: "Add to the whitelist",
    remove_from_whitelist: "Remove from the whitelist",
    delete: "Delete",
    whitelist_status_turn_on_success: "Enabled Withdraw to whitelist address only function successfully",
    whitelist_status_turn_off_success: "Disabled Withdraw to whitelist address only function successfully",
    whitelist_status_turn_on_failed: "Failed to enable Withdraw to whitelist address only function. Error code: {error}",
    whitelist_status_turn_on_title: "Enable Withdraw to whitelist address only function",
    whitelist_status_turn_off_title: "Disabled Withdraw to whitelist address only function",
    whitelist_status_turn_on_desc: "After enabling, you can only withdraw your coins to the addresses in your whitelist.",
    whitelist_status_turn_off_desc: "After disabling, you can withdraw your coins to any address.",
    whitelist_status_turn_off_failed: "Failed to Disabled Withdraw to whitelist address only function. Error code: {error}",
    whitelist_withdraw_only: "Withdraw to whitelist address only",
    whitelist_withdraw_only_desc: "After opening, your account will only be able to withdraw coins to the addresses in the whitelist!",
    balance_equivalence: "balance_equivalence",
    add_new_address: "Add a new address",
    address_tag_name: "Tag name",
    add_address_factor_desc: "You can not add an address before enabling Google 2FA",
    open_whitelist_address_factor_desc: "You can not enable whitelist before enabling Google 2FA",
    address_tag_input_placeholder: "Input the tag name",
    add_address_success: "Added the address successfully",
    address_unknown_error: "Failed. Reason:{message}. Error code:{errorCode}",
    whitelist_empty: "You haven't added any address into the whitelist. Please tap add button below.",
    address_book_empty: "You haven't added any address. Please tap add button below.",
    add_address_whitelist_success: "Added the address successfully",
    remove_whitelist_success: "Removed the address successfully",
    add_whitelist_success: "Added to the whitelist successfully",
    confirm_remove_whitelist: "Are you sure to remove the {tag}({address}) from the whitelist?",
    confirm_add_whitelist: "Are you sure to add the {tag}({address}) into the whitelist?",
    confirm_delete_whitelist: "Are you sure to delete {tag} ({address})?",
    new_address_tag_name: "Input a new tag name",
    delete_address_success: "Deleted the address successfully",
    modify_address_tag: "Modified the tag successfully",
    open_only_dw_whitelist_address_confirm_button: "Enable",
    not_a_whitelist_address: "The address is not in the whitelist",
    address_tag_too_long: "1-28 Characters allowed",
    duplicate_address: "You have used the address",
    duplicate_address_with_name: "You have used the address({dupTag})",
    duplicate_tag: "You have used the tag name",
    address_not_exists: "The address doens't exist",
    coin_chain_title: "{coin} address",
    only_use_whitelist_address_tips: "You have enabled “Withdraw to the whitelist only” function, so you can not withdraw to any other addresses.",
    on_address_tips: "You haven't added any {coin} address. Please tap the button below to add.",
    another_whitelist_address_tips: "You haven't added any {coinAndChain} address in the whitelist.",
    another_whitelist_address_title: "{coin}'s other chains whitelist address",
    another_address_tips: "You haven't added any {coinAndChain} address.",
    another_address_title: "Other {coin} addresses",
    add_withdraw_address: "Add this address to the address book",
    mul_add_address_error: "Added this address before",
    add_address_form_address: "Address",
    add_address_form_memo: "MEMO",
    add_address_form_memo_error: "Please fill in {memoName}",
    address_table_header_tag: "Address Remarks",
    address_table_header_coin: "Currency",
    address_table_header_chain: "Based on which chain",
    address_table_header_memo: "MEMO",
    address_table_header_address: "Address",
    address_table_options: "Operation",
    address_table_options_withdraw: "Withdraw coins",
    address_table_options_rename: "Rename",
    address_table_options_whitelist: "Add whitelist",
    address_table_options_whitelist_remove: "Remove from the whitelist",
    address_table_options_delete: "Delete",
    address_2fa_auth_content: "For your account security, the use of this function requires Google secondary verification, please try again after binding Google authenticator.",
    address_seletor_others_open: "Expand",
    address_seletor_others_close: "Close",
    address_address_input_placeholder: "Please enter the address",
    address_table_empty_text: "Address has not been added yet",
    address_whitelist_table_empty_text: "Whitelist address has not been added yet",
    withdraw_fee_free_warn: "It won’t charge a fee for internal address withdrawals. Please ignore the fee and the target address will receive all funds.",
    send_fee_free_warn: "It won’t charge a fee for internal address transfers. Please ignore the fee and the target address will receive all funds.",
    // market
    market_menu_title: "Market",
    market_menu_spot: "Spot",
    market_menu_leverage: "Leverage",
    market_menu_ranks: "Ranks",
    market_symbol_not_avaliabe: "this coin hasn't been listed on Pionex.US",
    market_picks_not_login_description: "Please sign in or register first to check your picked coins",
    market_search_placeholder: "Search coin name",
    market_search_history: "Search History",
    market_search_top: "Top Search",
    market_search_noresult: "No result",
    market_collect_notlogin: "Please sign in before picking",
    market_table_column_coin: "Coin",
    market_table_column_cap: "Cap ({currency})",
    market_table_column_circulation: "Circulation",
    market_table_column_circulation_occupation: "Circulation/Occupation",
    market_table_column_pair: "Pair",
    market_table_column_meaning: "Meaning",
    market_table_column_price: "Price",
    market_table_column_faitprice: "Fiat price({currency})",
    market_table_column_dayvolume: "24H Volume",
    market_table_column_daychange: "24H Change",
    market_table_column_nextFundingFee: "Next funding fee",
    market_table_column_apr: "{time} APR",
    market_table_column_meaning_long: " {multip}x Long",
    market_table_column_meaning_short: " {multip}x Short",
    market_leverage_tab_all: "All",
    market_leverage_main_area: "Leveraged tokens of top cap coins",
    market_leverage_1_2x_area: "The other 1~2x tokens",
    market_leverage_3x_area: "The other 3x tokens",
    market_leverage_other_area: "The other leveraged tokens",
    market_ranks_arbitrage_topcap_text: "Top cap coins with lower risk to arbitrage",
    market_ranks_arbitrage_othercap_text: "Other coins",
    market_ranks_coincap_nomore: "no more data",
    market_overview: "Market Overview",
    market_ranks_tab_coincap: "CoinCap",
    market_ranks_tab_arbitrage: "Arbitrage",
    market_ranks_tab_gainer: "Gainer",
    market_ranks_tab_loser: "Loser",
    market_spot_tab_picks: "Picks",
    balance_frozen_status: "Occupation status",
    no_frozen_balance: "There is no occupied {baseDisplay}({fullName}) in you account",
    rebalanced_bot: "Rebalancing Bot",
    smart_trade_bot: "Trailing Bot",
    order_history_manual: "Manual order",
    balance_frozen_details: "Occupation",
    balance_frozen_use_amout: "Amount",
    balance_frozen_use_type: "Type",

    /** profit batch extracts **/
    batch_release_profit: "Batch-release profit",
    batch_release_profit_desc: "Release profits from multiple Grid Trading Bots once a time",
    release_profit_wrap_desc: "You can choose to only release the profit of this bot,or batch-release profits from all Grid Trading bots.",
    release_this_bot: "Just this bot",
    batch_release: "All grid trading bots",
    batch_release_success_toast: "You have successfully released profits from {success}/{total} bots",
    stop_batch_release_tips: "Bad network. You can keep waiting or stop releasing. The incomplete ones might be done in background.",
    batch_release_progress: "Releasing profits from {success}/{total} bots...",
    batch_release_cancel: "Stop",
    batch_release_failed: "Failed to withdraw profits in batches, please try again later!",
    profit_batch_loading_tip: "Searching for orders that can release profit",
    deposit_matic_tip_first_line:
        "Pionex.US only supports depositing from ERC20 chain. Please do not transfer MATIC from other networks (polygon, etc.). Depositing from other networks may result in a loss and do not support retrievals. ",
    deposit_matic_tip_second_line:
        "If you are not sure whether you are transferring from the ERC20 chain, please test with a minimum withdrawable amount. And deposit the residual amount after receiving the coins for testing.",
    withdraw_matic_tip_first_line: "Please ensure that the withdrawal address supports the ERC20 chain. Withdrawing to other networks might cause a loss.",
    deposit_matic_layer_tip: "Pionex.US only supports depositing from ERC20 chain, please do not transfer MATIC from the other networks.",
    deposit_matic_layer_check_content: "I understand that I have to deposit from the ERC20 chain. Transferring from other networks will cause a loss.",
    profile_kyc_tips:
        "KYC can improve your account security and single-day withdrawal limit.\n" +
        "For users who have not passed KYC, the 24-hour withdrawal limit is 15,000 USDT or 1 BTC;\n" +
        "For users who have passed KYC, the 24-hour withdrawal limit is 500,000 USDT or 30 BTC.\n",
    no_running_bot_orders: "No running bot",
    see_marcket_maker_rule: "View market maker rules",
    // Swap
    trade_swap: "Convert",
    swap_usdt_input_placeholder: "You will get",
    swap_hint: "The swap function will use market order to buy/sell coins, so there might be a little slippage (usually<0.1%).",
    swap_choose_empty_text: "Choose",
    swap_from: "From",
    swap_to: "To",
    trade_swap_error_low_amount: "Failed to swap. The minimum required quantity is {amount}",
    trade_swap_error_high_amount: "Failed to swap. The max quantity limit is {amount}",
    trade_swap_error_low_cost: "Failed to swap. The minimum required volume is {cost}",
    trade_swap_error_high_cost: "Failed to swap. The max investment limit is {cost}",
    swap_success: "Successful Swap！",
    // wallet
    balance_nav_title: "Account",
    balance_nav_overview: "Account",
    balance_nav_trade_account: "Trade",
    balance_nav_finance_account: "Earn",
    balance_overview_chart_sum: "Balance",
    balance_overview_chart_time: "Date",
    balance_seprate_title: "Balance Distribution",
    balance_seprate_trade_title: "My trading account balance",
    balance_seprate_finance_title: "Earn Balance",
    balance_seprate_sum_title: "Total balance",
    balance_seprate_profit_title: "Today's profit",
    balance_finance_swap: "Asset(Arbitrage)",
    balance_finance_others: "Asset(Earn)",
    balance_finance_deposit: "Savings",
    balance_finance_funds_type: "AssetType",
    "balance_finance_funds_type.SWAP": "Hedge funds",
    "balance_finance_funds_type.CM_SWAP": "Hedge funds",
    "balance_finance_funds_type.ARBITRAGE_STRATEGY": "Spot(Arbitrage)",
    "balance_finance_funds_type.DEPOSIT": "Savings",
    balance_account_overview: "Overview",
    balance_account_overview_period: "balance change",
    balance_finance_funds_empty: "Your balance is at 0",
    balance_finance_no_data: "You have no asset",
    account_verify_code_send: "Send",
    account_bind_already_exists: "This email has been bind to another account.",
    account_bind_already_exists_PhoneOrEmail: "This {type} has been bind to another account.",
    account_bind_phone_success_tip: "You can now log in with this phone number.",
    account_bind_email_success_tip: "You can now log in with this Email address.",
    account_bind_sms_title: "Please input the SMS code sent to {sms}. The code is valid for 5 mins.",
    account_bind_email_title: "Please input the code sent to {email}. The code is valid for 5 mins.",
    account_bind_ga_2fa_tip: "Please input the code from Google Authenticator",
    google_authenticator_tips_input: "Input the code from your Google Authenticator app",
    account_bind_voice: "Receive a voice code",
    account_phone_auth_title: "Email Authentication",
    account_email_auth_title: "SMS Authentication",
    account_phone_auth_placeholder: "Please input the phone number",
    account_email_auth_placeholder: "Please input the Email",
    account_bind_phone_success_title: "SMS authentication successfully",
    account_bind_phone_success_email: "Email Authentication successfully",
    account_bind_back: "Back",
    account_bind_title_phone: "SMS Authentication",
    account_bind_title_email: "Email Authentication",
    account_bind_des_phone: "After successfully completing the SMS authentication, you can use the SMS to log in",
    account_bind_des_email: "After successfully completing the Email authentication, you can use the Email to log in",
    pre_step: "Back",
    next_step: "Next ",
    homepage_serve_link_name_announcement: "Announcement",
    sg_ip_signup_alert_title: "Singapore citizen accessibility confirmation",
    sg_ip_signup_alert_content:
        "It's detected that your IP is in {area}, so {site} is not available to you. If you are not a {area} citizen, please confirm and then click Continue.",
    sg_ip_signup_alert_check: "I confirm that I'm not a {area} citizen",
    ip_service_content: "According to the local law, the services are temporarily unavailable for Chinese citizens. ",
    ip_service_tip:
        "The services are temporarily unavailable for users in the following countries and areas: Mainland China, the United States, Afghanistan, Cuba, Iran, North Korea, Syria, Ontario Canada",
    acc_86_bind_title: "Bind your email address first",
    acc_86_bind_tip:
        "Pionex.US will upgrade the account security level on November 1. Please bind your mailbox as soon as possible before the upgrade date to enhance account security. Mobile phone users who have not bound their mails after November 1st will not be able to create new bots.",
    acc_mail_cn_tip: "Pionex.US does not support e-mail address registration service provided by mail service providers in mainland China.",
    acc_mail_cn_tip_phone_email: "Pionex.US does not support {type} address registration service provided by mail service providers in mainland China.",
    wallet_upgrade_title: "Wallet system is updated",
    wallet_upgrade_content:
        "To provide safer, faster deposit/withdrawal service, we have updated our wallet service, so that the deposit addresses are renewed. You can still deposit your coins into the old addresses, while we recommend you to use the new addresses to enjoy better service. There will be minimum deposit limitation after the upgrade.",
    wallet_upgrade_link_title: "Check announcement",
    kol_rebate_details_amout: "Rebate amount",
    kol_rebate_details_date: "Date of registration",
    kol_rebate_details_recode_30: "View 30-day rebate details",
    kol_rebate_details_total: "Rebate",
    kol_rebate_type: "Rebate Type",
    kol_rebate_details_chart_total: "Rebate",
    kol_rebate_details_spot_total: "Spot rebate",
    kol_rebate_details_leveraged_total: "Leveraged Token Rebate",
    kol_rebate_details_arbitrage_total: "Spot-Futures Arbitrage Rebate",
    kol_rebate_no_data: "No data",
    kol_rebate_status_have: " Rebated",
    kol_rebate_status_not_have: "Not rebated",
    kol_rebate_invite_status_account_use: "Account rebate status",
    rebate_details_chart_value_alias: "Rebate",
    kol_rebate_title: "Rebate",
    kol_rebate_details_total_data: "Overview",
    kol_rebate_details_total_data_ratio: "Rebate Ratio",
    kol_rebate_details_total_data_sum: "Rebate ({coin})",
    kol_rebate_details_total_data_open: "Total rebate number of users",
    kol_rebate_details_total_data_invite: "Total number of invitees",
    kol_rebate_date_warn_utc: "UTC time is used as the standard to calculate the rebate data",
    sr_advance_rebalance_mode: "Rebalance mode",
    sr_switch_periodic: "Periodic",
    sr_switch_threshold: "Threshold",
    sr_rules_modify_by_time_interval_none: "Pause rebalancing",
    sr_rebalance_mode_desc_title: "What is rebalance mode?",
    order_name_change: "Modify bot name",
    order_name_change_tip: "Input name",
    sr_add_position_des: "Hint: Your profit ratio will be re-calculated based on the total amounts after adding investment.",
    sr_reduce_position_des: "Hint: your investment and profit will be withdrawn proportionally.",
    sr_add_position_success: "Added",
    sr_order_reduce_position_total_need: "Amount",
    sr_order_change_coins: "Change coins",
    sr_order_change_coins_invest_exceed: "Operation failed as the total amount is insufficient when placing the order. Please add a small amount of investment to your orders.",
    smart_rebalance_coin_need_more: "You need at least choose {min} coins",
    sr_order_change_coins_low_balance: "The total amount of investment in this order is insufficient to change the coins. You have to add investment to {min} first.",
    sr_order_change_coins_too_more: "You can choose up to {max} coins",
    sr_rules_modify_title: "Rebalance mode",
    sr_rules_modify_by_time_des: "The bot will maintain the proportion of each coins in your portfolio by rebalancing at your preset interval.",
    sr_rules_modify_by_percent_des:
        "When one of the coins in the portfolio crosses outside the bounds of their desired allocations, the bot will rebalance to restore the preset proportion.",
    trade_changed_success: "Changed successfully",
    sr_order_condition_des: "The order will be triggered under these {count} circumstances",
    sr_order_change_coins_record: "Balance after #{index} coin alteration",
    grid_share_sr_rebalance_count: "Transactions",
    grid_share_trend_sr: "{coins} is going up",
    grid_share_sr_double_slogan: "I use Rebalancing Bot",
    sr_order_rebalance_unabled: "Stopped",
    sr_order_period_threshold: "Deviated ±{period}",
    order_name_change_success: "Modified",
    order_name_change_failed: "Failed to modify",
    sr_reduce_position_success: "Withdrawn",
    sr_order_add_position_max: "Maximum available",
    smart_rebalance_percent_can_not_more_than_one: "The sum of proportions should =100%",
    sr_order_reduce_position_min: "The amount should be greater than {value} {currency}",
    sr_condition_cancel_content: "Are you sure you want to stop the {coins} rebalance bot？",
    sr_coin_percent_need_greater_or_equal: "Proportion should ≥ {value}",
    sr_order_reduce_position_max: "Maximum withdrawable {max} {quote}",
    sr_rebalance_name: "Remark name",
    change: "Modify",
    current_invest: "Current Investment Amount",
    sr_current_coins: "Currently Selected Coins",
    sr_rebalance_mode_title_periodic: "How long does the time change before the next rebalance",
    sr_rebalance_mode_title_threshold: "How much does the proportion change before the next rebalance",
    sr_order_trigger_price: "Order Trigger Price",
    sr_order_trigger_price_hint: "The bot will start running when any coin reached its trigger price",
    sr_order_coins_distribute: "Currency distribution after change",
    sr_order_trigger_hint: "The current order will be triggered when the {symbol} price reaches {triggerPrice}",
    home_foot_menu_about_privacy: "Privacy policy",
    home_foot_menu_about_disclaimer: "Disclaimer",
    home_foot_menu_about_user: "User conduct code",
    sr_order_coins_change: "Change coins",
    sr_filter_none_rebalance: "Hide no-rebalance history",
    sr_reduce_position_record_des: "Due to the limitation of the minimum required trading volume, the actual withdraw amount might be slightly different with your presert amount.",
    // 产品要求所有语言均显示繁体
    // tw-deposit
    tw_deposit_header_title: "台幣入金",
    tw_deposit_header_menu_deposit: "入金",
    tw_deposit_header_menu_orders: "歷史訂單",
    tw_deposit_login_modal_title: "請先登入/註冊",
    tw_deposit_kyc_none_modal_title: "實名認證",
    tw_deposit_kyc_none_modal_content: "檢測到您還未進行實名認證，請完成實名認證後再進行台幣入金",
    tw_deposit_kyc_none_modal_ok: "去認證",
    tw_deposit_kyc_verifying_modal_title: "實名認證審核中",
    tw_deposit_kyc_verifying_modal_content: "檢測到您的實名認證正在審核中，我們將在1-3個工作日內審核完成",
    tw_deposit_kyc_failed_modal_title: "實名認證審核失敗",
    tw_deposit_kyc_failed_modal_content: "您的實名認證審核失敗，原因是：{reason}，請重新認證",
    tw_deposit_kyc_failed_modal_ok: "重新認證",
    tw_deposit_create_order_exchange: "當前匯率",
    tw_deposit_create_order_type_shop: "超商轉帳",
    tw_deposit_create_order_type_atm: "ATM轉帳",
    tw_deposit_create_order_type_placeholder: "選擇付款方式",
    tw_deposit_create_order_type_require: "請選擇付款方式",
    tw_deposit_create_order_bank_placeholder: "請選擇匯款至下列合作銀行",
    tw_deposit_create_order_bank_error: "請選擇匯款銀行",
    tw_deposit_create_order_amout_placeholder: "填寫入金金額 3,000 ～ {max} 台幣",
    tw_deposit_create_order_amout_precision: "入金金額僅支持整數金額",
    tw_deposit_create_order_fee: "手續費",
    tw_deposit_create_order_get: "您預計將得到(已扣手續費)",
    tw_deposit_create_order_btn: "產生繳費代碼",
    tw_deposit_history_orders: "歷史訂單",
    tw_deposit_create_order_orders_btn: "歷史訂單",
    tw_deposit_create_order_bank_BOT: "004-台灣銀行",
    tw_deposit_create_order_bank_Taishin: "812-台新銀行",
    tw_deposit_create_order_bank_HNCB: "008-華南銀行",
    tw_deposit_create_order_bank_MegaBank: "017-兆豐銀行",
    tw_deposit_create_order_bank_FirstBank: "007-第一銀行",
    tw_deposit_orders_tab_pending: "待繳費",
    tw_deposit_orders_tab_comp: "已完成",
    tw_deposit_orders_tab_closed: "已關閉",
    tw_deposit_orders_deadline: "繳費期限",
    tw_deposit_orders_paycode: "繳費代碼/虛擬帳號",
    tw_deposit_orders_pay_way: "付款方式",
    tw_deposit_orders_deposit_amount: "購買數量",
    tw_deposit_orders_each_price: "單價",
    tw_deposit_orders_pay_amount: "繳費金額",
    tw_deposit_orders_create_time: "創建時間",
    tw_deposit_orders_pay_time: "付款時間",
    tw_deposit_orders_status_paid: "待上帳",
    tw_deposit_orders_status_comp: "已上帳",
    tw_deposit_orders_de: "付款時間",
    tw_deposit_orders_cancel_reason: "取消原因",
    tw_deposit_orders_cancel_reason_manul: "手動取消",
    tw_deposit_orders_cancel_reason_expired: "繳費超時",
    tw_deposit_orders_cancel_reason_fail: "訂單生成失敗",
    tw_deposit_orders_cancel_reason_mismatch: "上帳失敗",
    tw_deposit_orders_cancel_reason_tip: "請 <link>聯系客服</link> 幫忙解決.",
    tw_deposit_failed_bank_match: "繳費卡號與綁定卡號不同",
    tw_deposit_failed_paid_amount: "轉帳金額與繳費金額不符",
    tw_deposit_orders_mk_title: "你可以在以下超商完成轉帳繳費",
    tw_deposit_orders_mk_link: "{shop} 超商繳費代碼使用教學",
    tw_deposit_create_order_info_deadline: "繳費期限",
    tw_deposit_create_order_info_code_title_atm: "ATM繳費虛擬帳號",
    tw_deposit_create_order_info_code_title_shop: "超商繳費代碼",
    tw_deposit_create_order_info_amount: "繳費金額",
    tw_deposit_create_order_info_pay_type: "付款方式",
    tw_deposit_create_order_info_shop: "支持繳費超商",
    tw_deposit_create_order_info_shop_support: "支持超商",
    tw_deposit_info_guide: "請您保存虛擬繳費代碼。至全台支持的4家超商輸入虛擬繳費代碼，核對信息，進行繳費即可。",
    tw_deposit_info_guide_link: "超商轉帳使用教學",
    tw_deposit_create_order_infor_warn: "注意",
    tw_deposit_create_order_infor_warn0: "請務必使用您{bank_number}的銀行卡進行繳費。",
    tw_deposit_create_order_infor_warn01: "轉帳金額需要與繳費金額相同，避免造成款項退款。",
    tw_deposit_create_order_infor_warn1: "您可在歷史訂單頁面查看該訂單進度",
    tw_deposit_create_order_infor_warn2_email: "充值成功後我們會發送Email通知至您的電子信箱（{email}），請注意查收",
    tw_deposit_create_order_infor_warn2_sms: "充值成功後我們會發簡訊至您的手機（{phone}），請注意查收",
    tw_deposit_create_order_infor_download: "下載訂單圖片",
    tw_deposit_create_order_infor_download_name: "Pionex.US台幣入金",
    tw_deposit_create_order_infor_cancel: "取消訂單",
    tw_deposit_create_order_loading: "訂單生成中...",
    tw_deposit_create_order_infor_cancel_content: "您確定要取消該訂單嗎？",
    tw_deposit_create_order_confirm_content_type: "繳費方式",
    tw_deposit_create_order_confirm_content_pay_amout: "購買金額",
    tw_deposit_create_order_confirm_content_warn: "注意：購買匯率以點擊確認時的實時匯率為準",
    tw_deposit_create_order_infor_shop_title: "{shop} 超商",
    tw_deposit_create_order_infor_shop_guide: "查看 {shop} 超商繳費代碼使用教學",
    tw_deposit_create_order_infor_titlt_shop: "請前往超商繳費",
    tw_deposit_create_order_infor_titlt_atm: "請前往ATM轉帳",
    tw_deposit_create_order_error_40020945: "取消失敗",
    tw_deposit_create_order_error_42920849: "當前訂單數量已到達上限",
    tw_deposit_create_order_error_50021350: "發生錯誤，請重試",
    tw_deposit_create_order_error_40020343: "參數錯誤",
    tw_deposit_create_order_error_40020944: "訂單匯率與下單時差距過大",
    tw_deposit_create_order_error_40021146: "當前購買金額已超出區間",
    tw_deposit_create_order_error_40420547: "不存在當前訂單",
    tw_deposit_create_order_processing: "正在开发中，敬请期待",
    tw_deposit_create_order_kyc_title: "请进行實名認證",
    tw_deposit_create_order_kyc_content: "完成實名認證後才可以使用”超商代碼繳費”轉帳購買USDT",
    tw_deposit_create_order_kyc_title_verifying: "實名認證正在審核中",
    tw_deposit_create_order_kyc_content_verifying: "您的實名認證正在審核中，審核成功後您可使用”超商代碼繳費”購買USDT",
    tw_deposit_create_order_kyc_title_failed: "實名認證失敗",
    tw_deposit_create_order_kyc_content_failed: "您的實名認證失敗請重新認證",
    tw_deposit_create_order_details: "查看詳情",
    tw_deposit_create_order_kyc_btn: "去認證",
    tw_deposit_create_order_kyc_btn_verifying: "審核中",
    tw_deposit_create_order_kyc_btn_success: "已完成",
    tw_deposit_create_order_kyc_btn_faild: "重新認證",
    tw_deposit_create_order_atm_content: "請先完成實名認證並綁定您自己的銀行帳號",
    tw_deposit_create_order_atm_verify_step1: "1.完成實名認證",
    tw_deposit_create_order_atm_verify_step2: "2.綁定銀行帳號",
    tw_deposit_create_order_atm_verify_btn: "去綁定",
    tw_deposit_create_order_atm_verify_btn_rebind: "重新綁定",
    tw_deposit_create_order_atm_bind_title: "綁定銀行帳號",
    tw_deposit_create_order_atm_bind_failed: "綁定失敗",
    tw_deposit_create_order_atm_bind_content: "請務必使用您本人的銀行帳號進行綁定，使用非本人銀行帳號會導致綁定失敗",
    tw_deposit_create_order_atm_bind_bank: "請選擇開戶銀行",
    tw_deposit_create_order_atm_bind_card_number: "請填寫銀行帳號",
    tw_deposit_create_order_atm_bind_name: "請填寫持卡人姓名大寫英文拼音",
    tw_deposit_create_order_atm_bind_name_format: "請正確填寫持卡人姓名",
    tw_deposit_create_order_atm_bind_card_number_format: "請正確填寫銀行帳號",
    tw_deposit_create_order_atm_bind_step2_title:
        "請提交 {bank_number} 的銀行卡的照片或3個月內的銀行帳單，帳單或圖片內需同時包含您的姓名和該銀行帳號（建議可拍金融卡背面），否則將導致審核失敗。",
    tw_deposit_create_order_atm_bind_step2_title1: "1. 請提交 {bank_number} 的銀行卡圖片或3個月內的銀行帳單。",
    tw_deposit_create_order_atm_bind_step2_title2: "2. 圖片或帳單內需同時包含您的姓名和該銀行卡帳號（通常會在金融卡背面）。",
    tw_deposit_create_order_atm_bind_step2_title3: "3. 如畫面中出現安全碼，請先遮蔽安全碼後再上傳文件。",
    tw_deposit_create_order_atm_bind_step2_title4: "4. 銀行卡實體卡需上傳正面和背面兩張圖片，存折或帳單上傳一張照片。",
    tw_deposit_create_order_atm_bind_step2_warn: "上傳文件，僅支持jpeg、png、pdf格式，文件大小在100K～10M之間",
    tw_deposit_create_order_atm_bind_step2_upload: "點擊上傳文件",
    tw_deposit_create_order_atm_bind_success_title: "提交成功，預計在1～3個工作日內完成審核。",
    tw_deposit_create_order_atm_bind_file_size: "圖片大小要小於10M",
    tw_deposit_create_order_atm_bind_file_size_min: "圖片大小要大於100k",
    tw_deposit_create_order_atm_bind_file_size_type: "僅支持jpeg、png、pdf格式",
    tw_deposit_create_order_atm_bind_change_photo: "更換文件",
    tw_deposit_create_order_atm_bind_info_title: "綁定銀行帳號資訊",
    tw_deposit_create_order_atm_bind_info_bank: "開戶銀行",
    tw_deposit_create_order_atm_bind_info_bank_num: "銀行帳號",
    tw_deposit_create_order_atm_bind_info_bank_warn: "注意：請務必使用您本人的金融帳號進行綁定，否則將會導致綁定失敗。（暫不支持信用卡綁定）",
    tw_deposit_create_order_kyc_valid_title: "實名認證",
    tw_deposit_create_order_info_pay_bank_account: "付款銀行卡",
    tw_deposit_bind_failed_modal_content: "您的銀行卡綁定失敗，原因是：{reason}",
    tw_deposit_create_order_atm_bind_warn: "預計在1～3個工作日內完成審核",
    tw_deposit_create_order_atm_rebind: "重新綁定",
    tw_deposit_create_order_atm_code: "銀行代碼",
    tw_deposit_create_order_atm_account: "銀行帳號",
    bot_guide: "Click here for tutorial",
    kyc_back_my_profile: "Back to my profile",
    Kyc_back_tw_deposit: "Return to Taiwan Dollar deposit",
    mtg_current_round_tips: "You will make {x} profit when {coin} price {y} in this round.Check more rounds in the Transactions tab.",
    mtg_current_base_amount: "{base} holdings",
    mtg_current_quote_amount: "{quote} holdings",
    order_average_cost: "Average cost",
    martingale_bot: "DCA Bot (Martingale)",
    martingale_bot_desc: "Auto reinvest, auto-compound",
    gird_pro_annualized_3: "Arbitrage/Total APR",
    mtg_order_card_round: "Rounds",
    grid_transaction_detail_total: "Total",
    mtg_take_profit_price: "{coin} Take profit",
    martingale_take_profit_ratio: "Take profit ratio",
    martingale_price_scale: "Price scale",
    martingale_safety_orders: "Safety orders",
    grid_pro_content_bot: "Are you sure you want to stop the {bot} of {base}/{quote}? Do you want us to help you automatically sell {base1} into {quote1}?",
    trade_page_price_placeholder: "Price",
    grid_transaction_buy_fee: "Buy fee",
    grid_transaction_sell_fee: "Sell fee",
    trade_order_category_total: "Total",
    mtg_transaction_today: "24H rounds",
    mtg_transaction_total_rounds: "Total rounds",
    mtg_transaction_avg_rounds: "Daily rounds",
    mtg_transaction_detail_desc: "One round means several buys and one sell. Martingale Trading Bot helps to 7/24 auto DCA buy and sell in one time to arbitrage from volatility.",
    grid_share_martingale_slogan: "I use Martingale Bot",
    martingale_order_volume_scale: "Volume scale",
    mtg_stop_scaling_price: "Stop scaling price",
    martingale_order_stop_scale_price_tip: "The bot won't buy more coins if the coin price has surpassed this price you set.",
    grid_transaction_part_sold: "Partially sold",
    mtg_round_info: "Round",
    // eg: Round #3
    grid_transaction_detail_title: "Detail",
    mtg_condition_cancel_content: "Are you sure you want to stop the {coin} Martingale bot？",
    // kyc
    kyc_basic_title: "Identity verification",
    kyc_l0_title: "Unverified",
    kyc_l1_title: "LV.1 verification",
    kyc_l2_title: "LV.2 verification",
    kyc_l0_head: "LV.0",
    kyc_l1_head: "LV.1",
    kyc_l2_head: "LV.2",
    kyc_lv_state_pending: "{lv} reviewing, the reviewing will take about {day} workdays.",
    kyc_lv_ver_failed: "{lv} reviewing failed",
    kyc_lv_ver_failed_btn: "Check",
    kyc_lv_tip: "Current level",
    kyc_go_do: "Verify",
    kyc_go_upgrade: "Upgrade",
    kyc_lv_limits_title: "Features & limits",
    kyc_lv_limit_trade: "Trading limits",
    kyc_lv_limit_cdep: "Deposit limits",
    kyc_lv_limit_mdep: "Fiat limits",
    kyc_lv_limit_daily_deps: "Daily withdrawal limit",
    kyc_lv_req_title: "Requirements",
    kyc_l1_location: "Country or region",
    kyc_l1_phone: "SMS verification",
    kyc_l2_email: "Email verification",
    kyc_l2_idcard: "Government-issued ID",
    kyc_l2_face: "Face verification",
    kyc_l1_step1_title: "1. Please select your residential country/region.",
    kyc_l1_step2_title: "2. Please enter your phone number and pass SMS verification.",
    kyc_l1_to_l2: "Unable to receive SMS verification code? Try other methods",
    kyc_l1_holder_country: "Select residential country/region",
    kyc_l1_phone_invalid: "Wrong phone number",
    kyc_l1_q1: "Please confirm you are not from China, or any sanctioned country(i.e., D.P.R Korea, Iran, Syria, Cuba, Afghanistan, Crimea)",
    kyc_l1_q2: "Please confirm you are not sanctioned or restricted by any international organizations, countries, or government.",
    kyc_l1_q3: "Please confirm you have no criminal record, are not under an investigation, and are not accused.",
    kyc_l1_q4: "Please confirm you are over 18 years old",
    kyc_l1_qs_required: "Please check all the check boxes!",
    kyc_limit_warn1: "You must finish LV.1 identity verification before depositing!",
    kyc_limit_warn2: "You must finish LV.1 identity verification before withdrawing!",
    kyc_limit_trade: "You must finish LV.1 identity verification before trading!",
    kyc_limit_usa: "According to your local law, you must finish identity verification before trading.",
    withdraw_limit_text1: "24H withdraw limit",
    send_limit_text1: "24H send limit",
    kyc_lv_failed:
        'Your KYC authentication failed. If you have any questions, please send an email titled "KYC authentication failed" to service@pionex.us for help, and provide your Pionex.US account in the email.',
    kyc_lv1_duplicated: "This phone number has been verified and cannot be verified again",
    kyc_lv1_skip_lv2: "Need a higher withdrawal limit? Click here to start LV.2 verification",
    kyc_lv2_country_other: "Others",
    kyc_lv2_country_other_tip: "If your country or region is not included in the LV.1 verification , you can try to go to the LV.2 verification",
    kyc_required_on_login: "Before depositing and trading, please take 1-2 minutes to verify your identity first.",
    complete_the_identity: "Complete the identity verification and make a deposit to win  200 USDT.",
    cannot_create_manual_trade: "Not be able to place manual order",
    cannot_create_bot_desc:
        "Pionex.US has performed an account system upgrading, accounts without binding the email will not be able to create new bots. For your account's safety, please bind an email as soon as possible.\n How to bind the email: Account - Security - 2-Factor authentication - Email authentication",
    tw_deposit_trial_run: "試運營已結束",
    // circle 出入金
    circledw_title: "USD Deposit/Withdraw",
    circledw_deposit: "Deposit",
    circledw_withdraw: "Withdraw",
    circledw_bankCard: "Card Management",
    circledw_history: "Order History",
    circledw_bankCard_title: "Bank Card Management",
    circledw_bankCard_add: "Add",
    circledw_bankCard_tips: "Please note:",
    circledw_bankCard_tips1: `1. You can only perform the "USD deposit" with verified cards.`,
    circledw_bankCard_tips2: `2. You can only perform the "USD withdrawal" with the cards you have already completed "USD deposit" with it.`,
    circledw_bankCard_tips3: "3. You can add up to 3 cards",
    circledw_addCard_title: "Add Card",
    circledw_addCard_tips: "Please note：You can only add your own bank card information.",
    circledw_addCard_info: "Bank information",
    circledw_addCard_country: "Please select the country or region where your bank is located",
    circledw_addCard_located: "City where the bank is located",
    circledw_addCard_iban_code: "IBAN code",
    circledw_addCard_iban_code_err: "Invalid IBAN code",
    circledw_addCard_post_code: "Post code",
    circledw_addCard_post_code_none: "Input postcode",
    circledw_addCard_post_code_err: "Invalid postcode. It can only include numbers, space and letters.",
    circledw_addCard_aba_routing: "ABA routing number（Wire）",
    circledw_addCard_bank_number: "Account number ",
    circledw_addCard_swift_code: "SWIFT code (8 or 11 digits)",
    circledw_addCard_swift_err: "Invalid SWIFT code",
    circledw_addCard_step2_tips: "Please make sure that the personal address information you fill in is consistent with the address information reserved at the bank.",
    circledw_addCard_step2_1: "1.BillingDetails",
    circledw_addCard_step2_name: "Name",
    circledw_addCard_step2_name_err: "Valid input should be in English and include space, e.g. AAA BBB",
    circledw_addCard_step2_country: "Please select your country or region",
    circledw_addCard_step2_city: "City",
    circledw_addCard_step2_street: "Street address",
    circledw_addCard_step2_2: "2.Please upload proof of address for the last 6 months",
    circledw_addCard_step2_warn: "Required:",
    circledw_addCard_step2_warn1:
        "1. Be sure to upload an address proof document containing your name, residential address, issuance date, and the name of the issuing organization.",
    circledw_addCard_step2_warn2:
        "2. In order to improve the approval rate, it is recommended to use:{br}Bank statement with your name and address;{br}Bills for shared facilities (water, gas, mobile phones, internet bills)",
    circledw_addCard_step2_warn3: "3. Please upload a picture or PDF file, screenshots are not accepted.",
    circledw_addCard_step2_warn_file: "File format only supports png, jpg, pdf, file size 100Kb-10Mb",
    circledw_addCard_success_tips:
        "Your card information has been submitted successfully. The reviewing will be completed within 1-3 business days. The result will be notified to you though email or SMS. You can also check the result on the bank card management.",
    circledw_addCard_success_btn: "Back to card management",
    mine_bank_aba_code_input_none: " Input ABA code",
    mine_bank_iban_code_input_none: "Input IBAN code",
    mine_bank_iban_code: "IBAN code",
    mine_band_info: "Bank info",
    mine_band_cardholder_info: "Billing Details",
    mine_bank_aba_code_input_error: "Invalid ABA code",
    mine_band_add_bank_number_none: "Input bank account",
    mine_band_add_bank_city_none: "Input which city this bank is located in",
    mine_bank_iban_code_input_error: "Invalid IBAN code",
    mine_bank_iban_country_no_match_country: "IBAN code doesn't match the selected country.",
    mine_bank_swift_code_input_error: "Invalid SWIFT code",
    mine_bank_swift_code_input_none: "SWIFT code",
    mine_band_add_bank_number_aba_input_error: "Invalid bank account",
    mine_bank_swift_country_no_match_country: "The SWIFT code does not match the selected country",
    mine_band_cardholder_city_none: "Input city",
    mine_band_add_info_not_english_error: "Please input in English",
    mine_band_cardholder_address_none: "Input street address",
    mine_band_cardholder_postal_none: "Input postcode",
    mine_band_cardholder_postal_format_error: "Invalid postcode. It can only include numbers, space and letters.",
    mine_bank_add_step_3_uploadImg: "Upload image",
    mine_bank_add_step_3_uploadDoc: "Please upload supporting documents",
    mine_bank_unbind_action_success: "Deleted successfully",
    bank_add_error_40090001: "API Key is missing or malformed",
    bank_add_error_40090002: "Error with the JSON object passed in the request body",
    bank_add_error_40090003: "API Key used with request does not have one of the roles authorized to call the API endpoint",
    bank_add_error_40091032: "An invalid number was provided when creating a card",
    bank_add_error_40091051: "The payment id requested was not found",
    bank_add_error_40091068: "Attempting to create a marketplace payment with a merchant that is not associated with the marketplace",
    bank_add_error_40091069: "Unable to find the wallet account referenced in a marketplace create payment request",
    bank_add_error_40091070: "Marketplace info was not provided with a marketplace create payment request",
    bank_add_error_40091076: "The payment amount was greater than the maximum allowed limit or below the minimum allowed limit",
    bank_add_error_40091077: "Payment amount must be greater than zero",
    bank_add_error_40091078: "An invalid currency value was used when making a payment",
    bank_add_error_40091083: "The idempotency key used when making a request was used with another payment. Please retry with a different value",
    bank_add_error_40091084: "A cancel or refund request cannot be canceled",
    bank_add_error_40091085: "A cancel or refund request cannot be refunded",
    bank_add_error_40091086: "This payment was already canceled",
    bank_add_error_40091087: "Total amount to be refunded exceeds payment amount",
    bank_add_error_40091088: "An invalid source account was specified in a payout or transfer request",
    bank_add_error_40091089: "Unable to find the source account specified in a payout or transfer request",
    bank_add_error_40091091: "Could not find a bank with that routing number when creating wire bank account",
    bank_add_error_40091092: "Invalid IBAN used in request to create a wire bank account",
    bank_add_error_40091093: "The source account has insufficient funds for the payout or transfer amount",
    bank_add_error_40091094: "When creating a card or bank account the billing last name must be provided",
    bank_add_error_40091096: "An encryption key id must be provided if request includes encrypted data",
    bank_add_error_40091097: "Attempting to cancel or refund a failed payment",
    bank_add_error_40092003: "The blockchain address has already been associated with the account",
    bank_add_error_40092004: "The blockchain address must first be verified before it can be used as a destination in a transfer request",
    bank_add_error_40092005: "The blockchain type used as a transfer destination is not supported",
    bank_add_error_40092006: "The wallet type specified when creating an end user wallet is not supported",
    bank_add_error_40020324: "CIRCLE_API_ERROR",
    bank_add_error_40020325: "Exceeded the addable limit. You can not add anymore.",
    bank_add_error_40020327: "This bank card has been deleted",
    bank_add_error_40020928: "If you failed to add this bank account, you won't be able to reverse.",
    bank_add_error_40020929: "If you failed to add this bank account, you won't be able to withdraw.",
    bank_add_error_40920630: "You have added this card.",
    circle_bank_status_LOGGED: "Reviewing. Review time: usually 1-3 work days",
    circle_bank_status_APPROVED: "Verified, can deposit",
    circle_bank_status_APPROVED_w: "Verified, can deposit and withdraw",
    circle_bank_status_REJECTED: "Failed to pass the verification.Please delete the card and try again。\n Reason：{reason}。",
    circle_bank_title: "Card Information",
    mine_band_add_bank_name: "Bank name",
    mine_band_add_bank_no: "Bank account",
    circle_bank_country: "Bank country",
    circle_bank_address: "Bank address",
    circle_bank_swift: "SWIFT",
    circle_bank_iban: "IBAN code",
    circle_bank_user_name: "Name",
    circle_bank_user_country: "Country/Region",
    circle_bank_user_city: "City",
    circle_bank_user_address: "Street address",
    circle_add_bankcard: "Add",
    circle_bankcard_delete: "Delete the card",
    circle_bankcard_delete_warn:
        "After deleting thecard, you will no longer be able to use this bank account for deposit and withdrawal. If you want to use this bank account, you need to add it again. ",
    circledw_deposit_hint: `Note: 
1. Please use your own bank account to wire, and fill in the information according to the bank’s requirements.
2. Please note that you must fill in the recipient, otherwise the wire will fail, and the bank will also charge a handling fee and a refund of 50 US dollars .
3. After the deposit is received, the funds can be traded normally, but you cannot withdraw to other addresses or withdraw funds within three days.`,
    circledw_deposit_to_hint: "View tips",
    circledw_deposit_my_bank: "My Bank",
    circledw_deposit_transfer_info: "USD Wire Transfer Information",
    circledw_guide_step0: "If the bank account and Pionex.US account owner do not match, the funds will be returned. Third party payment processors are not accepted.",
    circledw_guide_step1: "Use the following information to initiate a wire transfer from your bank account. ",
    circledw_guide_step2: 'Include your account identifier in your bank’s "memo" or "instructions" field. Failure to do so will return your deposit.',
    step_done: "Understand",
    circledw_deposit_choose_card: "Choose deposit card",
    circledw_withdraw_choose_card: "Choose withdraw card",
    circledw_card_selector_add: "Add other cards",
    circledw_deposit_title: "Deposit USD",
    circledw_withdraw_title: "Withdraw USD",
    circledw_withdraw_tips: "Tips：Only the bank card that has completed the deposit can use the withdrawal function",
    circledw_withdraw_swap_tips: "Not enough USDC? Click here to swap USDT to USDC",
    circledw_withdraw_handling_fee: "Handling fee",
    circledw_withdraw_actual_amount: "Actual withdrawal amount",
    circledw_kyc_success: "Verified",
    circledw_kyc_unverified: "Verify",
    circledw_kyc_verifying: "Reviewing",
    circledw_kyc_verified: "Verified",
    circledw_kyc_failed: "Failed",
    circledw_bank_logged: "Reviewing",
    circledw_bank_rejected: "Failed",
    circledw_deposit_fee_tips:
        "The handling fee is composed of two parts: the wire transfer fee and the fee charged by circle. Circle will charge a handling fee of 2 USD, and other fees will be charged by your bank and the intermediary bank. If the amount you finally receive is different from your wire amount, it may be caused by this reason.",
    circledw_bank_name: "Bank name",
    circledw_transfer_account_id: "Note to recipient",
    circledw_transfer_recipient_name: "Recipient Name",
    circledw_transfer_recipient_address: "Recipient Address",
    circledw_transfer_bank_address: "Bank Address",
    circledw_transfer_bank_country: "Bank Country",
    circledw_transfer_bank_post_code: "Bank Post Code",
    circledw_transfer_swift: "SWIFT",
    circledw_transfer_account_number: "Account Number",
    circledw_transfer_completed_wire: "I have completed the wire",
    circledw_deposit_success: "Your deposit information has been successfully submitted. You will receive the wire within 1-5 business days.",
    circledw_verify_title: "Please verify your identity before depositing.",
    circledw_verify_kyc_title: "Complete verification",
    circledw_verify_kyc_des: "Provide an ID document and confirmation selfie to complete the verification.",
    circledw_verify_bank_title: "Verify bank card",
    circledw_verify_bank_des: "Verify your own bank card for deposit and withdrawal",
    circledw_withdraw_bank: "withdraw bank",
    withdraw_success_title: "Successful withdrawal application",
    circledw_kyc_failed_details: "KYC verification failed. Reason：{reason}，please try again",
    circledw_bank_bind_failed_details: "Failed bank card authentication. Reason：{reason}",
    circledw_bank_inactive: "Only the bank card that has completed the deposit can use the withdrawal function, please complete a deposit first",
    circledw_withdraw_success_des:
        "Your withdrawal information has been successfully submitted. We will initiate a withdrawal within one working day. It is estimated that you will receive the wire within 1-5 business days.",
    circledw_withdraw_again: "Deposit again",
    circledw_history_withdraw: "Withdraw History",
    circledw_history_deposit: "Deposit History",
    circledw_history_title_amount: "Amount（Minus handling fee）",
    circledw_history_title_withdraw_amount: "Withdraw amount",
    circledw_history_title_actual_amount: "Actual amount",
    circledw_history_title_start_time: "Starting time",
    circledw_history_title_account_num: "Account number",
    circledw_history_title_time: "Time",
    status_deposit_ing: "pending",
    status_deposit_success: "Success",
    status_deposit_failed: "Failed",
    status_withdraw_success: "Success",
    status_withdraw_failed: "Failed",
    status_withdraw_ing: "Withdrawing",
    status_withdraw_approving: "Reviewing",
    status_withdraw_canceled: "Canceled",
    withdraw_bank_cancel_success_toast: "Cancelled successfully",
    withdraw_bank_cancel_error_toast: "Failed to cancell",
    circledw_history_swap_usdt: "Swap USDT",
    circledw_add_card_name_tips: "Please fill in the name of the bank card holder",
    circledw_withdraw_quota: "24H withdraw limit",
    circledw_bank_failed_default_reason: "Sorry, your bank account authentication failed. Please try again.",
    circledw_bank_select_state: "Please select your state",
    circle_bank_state: "State",
    circle_withdraw_error_not_enough: "Your withdrawal amount has exceeded the amount you can withdraw today",
    circle_withdraw_error_exceed: "The withdrawal limit has been exceeded, and the deposit amount of fiat currency within 3 business days cannot be withdrawn",
    usdt_swap_title: "Swap {from} to {to}",
    usdt_swap_amount_placeholder: "Please enter the swap amount",
    symbol_available_balance: "{symbol} available balance",
    common_double_confirm_title: "Double check",
    common_length_max: "No more than {length}",
    account_bind_input_phone: "Please fill in your phone number",
    account_bind_input_email: "Please fill in your Email",
    circle_kyc_title: "KYC not verified",
    circle_kyc_content: "Please complete the KYC before adding a bank account",
    circle_kyc_ok: "Verify",
    auth_login_origin: `提示：请确认授权请求来自 {host}`,
    auth_login_title: "Pionex.US提示",
    auth_login_content: "请先登录Pionex.US，再继续下一步操作",
    auth_confirm_title: "账号授权提示",
    auth_confirm_content: "允许Pionex.US账号授权第三方账号，并且提供登录服务。",
    auth_confirm_authed: "已授权第三方网站使用Pionex.US账号登录",
    auth_confirm_allow: "允许",
    deposit_limit_min_amount: "Minimum deposit limit",
    receive_limit_min_amount: "Minimum receive limit",
    deposit_limit_min_amount_desc: "If the deposited amount is below the minimum deposit limit, the fund will not arrive and could not be retrieved.",
    receive_limit_min_amount_desc: "If the received amount is below the minimum receive limit, the fund will not arrive and could not be retrieved.",
    order_details_paused: "Order has been paused",
    kyc1_change_channel: "Resend SMS message with another telecom company",
    kyc1_send_voice: "Receive verification code from the phone call",
    kyc1_confirm_to_2: "Skip Lv.1 by completing the Lv.2 verification directly",
    kyc1_send_remain_times: "{used} company used, {remain} remain(s).",
    kyc1_des_for_2: "Lv.2 verification include LV.1 privilege and increase the withdrawal limit.",
    kyc1_channel_used_up: "Tried all available SMS channels",
    sr_change_symbol_repeat: "Do not add the same coin repeatedly",
    home_foot_terms_service: "Terms of service",
    // Jumio KYC step 1
    kyc_jumio_title: "Verify your identity",
    kyc_jumio_s1_region: "Select residence country/region",
    kyc_jumio_s1_desc: "In order to comply with US regulations, we are required to verify your identity.",
    kyc_jumio_s1_ssn_tip:
        "Your SSN/TIN will only be used to verify your identity. It will be stored securely and will not be shared without your permission unless required by law.",
    kyc_jumio_s1_tin_tip: "Your TIN will only be used to verify your identity. It will be stored securely and will not be shared without your permission unless required by law.",
    kyc_jumio_s1_province: "Select State",
    kyc_jumio_s1_province_content:
        "Currently Pionex.US is available in following States only: <list></list> If you are a resident within any state listed above, please select the identification documents you may submit for our further review.",
    kyc_jumio_s1_province_none: "I am not a resident of any state listed above",
    kyc_jumio_s1_province_none_tip: "Sorry, we are temporarily unable to serve your state, we will support as soon as possible and notify you by email.",
    kyc_jumio_s1_province_continue: "Continue",
    kyc_jumio_s1_province_quit: "Quit",
    kyc_jumio_s1_doc_type: "Select the document type to be uploaded",
    kyc_jumio_s1_doc_type_id: "Driver’s License or State ID",
    kyc_jumio_s1_doc_type_hk_1: "State ID or Passport",
    kyc_jumio_s1_doc_type_pass: "Passport & valid Proof of Address",
    kyc_jumio_s1_fname: "First Name",
    kyc_jumio_s1_mname: "Middle Name (optional)",
    kyc_jumio_s1_lname: "Last Name",
    kyc_jumio_s1_gender: "Gender",
    kyc_jumio_s1_gender_male: "Male",
    kyc_jumio_s1_gender_female: "Female",
    kyc_jumio_s1_birth: "Date of Birth",
    kyc_jumio_s1_ssn_tin: "Please fill in SSN/TIN",
    kyc_jumio_s1_tin: "Your TIN (e.g., 212-13-8282)",
    kyc_jumio_s1_hk_tin: "Please enter HKID.",
    kyc_jumio_s1_ssn_invalid: "Invalid SSN/TIN formate.",
    kyc_jumio_s1_tin_invalid: "Invalid TIN formate.",
    account_reset_ga_2fa: "Reset Google Authenticator",
    ga_phone_unbind_message: "Message",
    ga_unbind_message_content: "Reset Google Authenticator",
    ga_unbind_message_send: "Send to",
    ga_phone_unbind_message_Text: 'Please send a SMS with the phone you registered in Pionex.US to {phone} with the content "Reset Google 2FA".',
    ga_phone_unbind_message_Tip: "We will review it manually to keep safe. The process will take up to 3 work days and we will send the reset result to your phone.",
    account_set_password_placeholder: "Tap to input password",
    account_set_account_placeholder: "Tap to input the account",
    ga_email_unbind_second_Title: "Please answer the following questions to reset the authenticator. We will reset it after manually reviewed.",
    ga_email_unbind_question_1: "Date of Registration(Year-Month)",
    ga_email_unbind_question_2: "Nickname",
    ga_email_unbind_question_3: "Which bot and with which pair have you created?",
    ga_email_unbind_question_2_placeholder: `Please fill out the column with "None" if you haven't set the nickname`,
    trade_choose_bot: "Choose a bot",
    ga_unbind_too_quick_error: "You can only reset Google Authenticator once within 3 days.",
    ga_reset_lost_2fa: "Lost Google 2FA?",
    reset_2fa_unused_tradeBot: "I haven't created any bot yet",
    reset_2fa_email_message_tip:
        "Hint： To ensure your account security, please answer all the following questions about your account activities. Pionex.US will review your answers and decide whether to reset the authenticator. The review will take approximately three days, and the result will be sent to you through email.",
    ga_email_unbind_question_3_tips: "Only select one coin if it is a multi-coin mode bot",
    buy_crypto_title: "Buy Crypto",
    buy_crypto_disclaimer: "Disclaimer",
    buy_crypto_disclaimer_content:
        "You are leaving Pionex and going to www.simplex.com (“Simplex”). Simplex is an independent platform owned by a third party, and all related credit card payments services are provided by Simplex. Should you have any questions regarding service provided by Simplex, please contact Simplex Customer Service directly. Pionex is not responsible for any loss or damage caused by using this service, and is not responsible for any of the contents, advertisements, products, services, or other materials on third party sites. This link does not represent an endorsement or solicitation of any products or services provided by any third party.",
    buy_crypto_about_simplex_title: "Service Provider Details",
    buy_crypto_about_simplex_content: `<h3>Why Buy Crypto via Cards with Simplex?</h3>
    <div>
        <p>
            <b>Fast:</b> Average 10-30 mins for cryptocurrency to reach your wallet
        </p>
        <p>
            <b>Convenient:</b> Both Visa and MasterCard accepted
        </p>
        <p>
            <b>Service:</b> For any questions, Simplex offers 24/7 live chat support
        </p>
    </div>
    <h3>Disclaimer</h3>
    <div>
        You are leaving Pionex and going to www.simplex.com (“Simplex”). Simplex is an independent platform owned by a third party, and all related credit card payments
        services are provided by Simplex. Should you have any questions regarding service provided by Simplex, please contact Simplex Customer Service directly. Pionex
        is not responsible for any loss or damage caused by using this service, and is not responsible for any of the contents, advertisements, products, services, or
        other materials on third party sites. This link does not represent an endorsement or solicitation of any products or services provided by any third party.
    </div>`,
    buy_crypto_title_credit_card: "Credit Card",
    buy_crypto_title_bank_transfer: "Bank Transfer",
    buy_crypto_result_success:
        "Purchase is done. The order information will be sent to your e-mail as soon as possible and you will get your cryptocurrencies soon. If you fail to receive the information, please contact Customer Support of Simplex: support@simplex.com.",
    buy_crypto_result_fail_title: "Unsuccessfully！",
    buy_crypto_status_pending: "Pending",
    buy_crypto_status_failed: "Failed",
    buy_crypto_status_cancelled: "Cancelled",
    buy_crypto_status_success: "Paid",
    credit_card_step1_title: "Buy Crypto with Fiat Currency",
    credit_card_step2_title: "Select a service provider",
    credit_card_step3_title: "Payment details",
    credit_card_fiat_label: "I will spend",
    credit_card_will_recive: "You will get",
    credit_card_will_recive_report: "get",
    credit_card_fiat_error: "Please enter quantity ({range})",
    credit_card_fiat_placeholder: "Please enter quantity ({range})",
    credit_card_purchase_info_symbol: "Crypto Coin",
    credit_card_purchase_info_amount: "Total including fee",
    credit_card_purchase_info_amount_tips: "Please check the exact transaction fees on the service provider's official website.",
    credit_card_purchase_info_account: "Pionex Account",
    credit_card_purchase_info_provider: "Service Provider",
    credit_card_disclaimer_checker: "I have read and agree to the disclaimer",
    credit_card_about_simplex: "Learn more",
    credit_card_got_it: "OK，got it！",
    credit_card_verify_title: "Please verify your identity before depositing.",
    credit_card_table_column_order_no: "Order No.",
    credit_card_table_column_service_provider: "Service Provider",
    credit_card_table_column_total_amount: "Total Amount",
    credit_card_table_column_date: "Date & Time",
    account_signin_error_stuck_temp: "Account frozen for 30 mins due to verification failure or multiple incorrect password attempts. Please retry later.",
    account_verify_error: "The input user name or password is wrong",
    account_password_format_tip: "The password must contains 8 to 16 characters with both numbers and letters.",
    menu_drawer_info: "Click here to call out the menu drawer",
    menu_drawer_ok: "Got it",
    stable_convert_title: "Convert {from} to {to}",
    stable_convert_submit: "Convert",
    stable_convert_from: "From",
    stable_convert_to: "To",
    stable_convert_amount_placeholder: "Please enter the convert amount",
    stable_convert_tips: "The ratio of conversion is 1:1, your total assets will not change",
    signature_dw_withdraw_step1: "Please enter the amount you intend to withdraw",
    signature_dw_withdraw_step2: "Select or fill in the withdrawal bank card",
    signature_dw_withdraw_select_bank: "You can withdraw with the card that has completed the deposit or fill in a new receiving bank card",
    signature_dw_withdraw_bank_info: "Fill in the bank information",
    signature_dw_withdraw_profile_info: "Fill in personal information",
    signature_dw_withdraw_tips:
        "Withdraw will be normally processed within one business day, but may be delayed due to public holidays. Pionex is not responsible for delays or fees caused by the bank or intermediary bank you use. US Dollar withdrawals less than ${minAmount} will not be processed. Please contact our support team for more information. Please note that you can only deposit or transfer funds from a bank account with the same name as the registrant or entity of the account with Pionex. Other wire transfers will be returned and fees will be charged upon the recipient account of the returned fund.",
    signature_dw_withdraw_agree: "I have read and fully understand the terms above",
    signature_dw_withdraw_concat_email: "If you would like to contact Pionex.us with questions, please e-mail <light>service@pionex.us</light>.",
    signature_dw_withdraw_switch_card: "I will withdraw with another bank",
    signature_dw_history_order_id: "Order No",
    signature_dw_history_status: "Status",
    signature_dw_history_fee: "Fee",
    signature_dw_history_time: "Time",
    signature_dw_history_deposit_realAmount: "Deposit Received",
    signature_dw_history_deposit_amount: "Amount",
    signature_dw_history_withdraw_amount: "Amount",
    signature_dw_withdraw_not_agree: "please agree ",
    signature_dw_withdraw_balance_tips: "Excluding deposits within 24 hours.",
    signature_dw_withdraw_quota_tips: "{limitTip} We calculate the 24H withdraw limit based on the amount you have withdrawn in the past 24 hours.",
    signature_dw_error_parameter_error: "parameter error",
    signature_dw_error_balance_not_enough: "insufficient balance",
    signature_dw_withdraw_fail_reason: "Withdraw failed, please contact customer service",
    signature_dw_deposit_fail_reason: "Deposit failed, please contact customer service",
    signature_dw_add_card_name_tips: "Notice that the card holder name must match kyc name",
    signature_dw_guide_step2: "Include your unique reference code in the memo / reference field when sending your wire. Your account will not be credited if you fail to do so.",
    signature_dw_deposit_hint: `<strong>Important:</strong>
1. Transfer USD via  <strong>{banksName}</strong> to fund your Pionex.US balance.{br}
2. Please kindly note that we accept USD deposit from <strong>your own bank account ONLY</strong>. USD deposit from any other bank account will be rejected, subject to a $50 flat fee for return funds.{br}
3. Please make sure to use <strong>Unique reference code </strong>as your bank transfer’s message when you send the wire. This is used to match the deposit to your Pionex.US account.{br}
4. Pionex<strong> does not charge fees </strong>on wire deposits and we will credit your account according to the actual amount received.{br}
5. For security reasons, <strong>a 24-hour holding</strong> time is implemented for the equivalent amount (in USD and crypto) of your wire transfer deposit upon its arrival. Additionally, if our risk control is triggered, <strong>a 7-day holding</strong> period will be implemented, and we may take other measures to control the risk if necessary.{br}`,
    signature_dw_deposit_hint_lead_bank: `<strong>Important:</strong>
1. Transfer USD  via <strong>{banksName}</strong> to fund your Pionex.US balance.{br}
2. Please kindly note that we accept USD deposit from <strong>your own bank account ONLY</strong>. USD deposit from any other bank account will be rejected, subject to a $50 flat fee for return funds.{br}
3. Please make sure to use <strong>Unique reference code,Deposit Message </strong>as your bank transfer’s message when you send the wire. This is used to match the deposit to your Pionex.US account.{br}
4. For security reasons, <strong>a 24-hour holding</strong> time is implemented for the equivalent amount (in USD and crypto) of your wire transfer deposit upon its arrival. Additionally, if our risk control is triggered, <strong>a 7-day holding</strong> period will be implemented, and we may take other measures to control the risk if necessary.{br}
5. The <strong>Deposit fees</strong> range from $40 to $150, and this fee is charged by bank. Pionex <strong>does not charge fees</strong> on wire deposits and we will credit your account according to the actual amount received.{br}{str1}$25,000.01, Fee: $40.00.{br}$25,000.01 - $75,000.00, Fee:$50.{br}$75,000.01-$100,000.00, Fee:$75.00.{br}$100,000.01-$225,000.00, Fee:$100.00.{br}{str2}$225,000.00, Fee:$150.00.{br}`,
    signature_dw_deposit_transfer_info: "USD Recipient Details",
    signature_dw_transfer_account_id: "Unique reference code",
    signature_dw_transfer_deposit_message: "Deposit message",
    signature_dw_transfer_recipient_name: "Recipient name",
    signature_dw_transfer_recipient_address: "Recipient address",
    signature_dw_transfer_recipient_address_line_one: "Address line 1",
    signature_dw_transfer_recipient_address_line_two: "Address line 2",
    signature_dw_transfer_account_number: "Recipient account number",
    signature_dw_bank_name: "Receiving bank name",
    signature_dw_transfer_bank_address: "Receiving bank address",
    signature_dw_transfer_aba: "Receiving bank routing number(ABA)",
    signature_dw_transfer_swift: "Receiving bank SWIFT code",
    signature_dw_deposit_success_title: "You're all set!",
    signature_dw_deposit_success_title2: "We will notify you by email once we've received your funds.",
    signature_dw_deposit_success:
        "Pionex.US is not responsible for any delays in the wire transfer due to your bank or the intermediary banks used by your bank. In general, you can expect to receive the wire transfer within 1-3 business days.",
    signature_dw_title: "USD Deposit",
    signature_dw_step1: "Tap to copy Pionex.US account details.",
    signature_dw_step2: "Open your bank App/Website and pay the above account to deposit.",
    signature_dw_step3: "Use your USD balance directly to start trading.",
    signature_dw_tutorial: "Making your first deposit or running into issues? Read our<tutorial> Tutorial </tutorial>to avoid any unnecessary delays.",
    signature_concat_email: "You can contact us via email <light>service@pionex.us</light>",
    signature_dw_uni_hint: "Please use the Unique reference code as the transfer message to match the deposit to your account.",
    signature_dw_deposit_message: "Please include a Deposit Message to help the bank process your transfer efficiently.",
    usIPModal_title_kyc_failed: "KYC failed",
    usIPModal_got_it: "Got it",
    kyc2_reason_not_support:
        "We have detected that you are from {regionName}, unfortunately we are temporarily unable to support your state. Pionex.US will come to {regionName} soon.{br}Currently, Pionex.US supports {serveRegionNames}. If you are a resident of these states, please let us know by email. Email: service@pionex.us",
    common_network_exception: "Network exception, please check the network and try again.",
    signature_deposit_title: "Wire Transfer Instructions",
    questionnaire_title: "Please provide additional information",
    questionnaire_select: "Please select",
    questionnaire_des:
        "We detected that your account has significant deposit/withdrawal records recently. Please take 1 minute to fill out the following questionnaire for your account's safety before you keep using Pionex.US. Thank you for your cooperation.",
    nav_title_usd_deposit_transfer: "Wire Transfer",
    nav_title_usd_deposit_debit: "Debit Card",
    nav_title_usd_deposit_ach: "Bank Account",
    debit_add_card_title: "Add Card",
    debit_add_card_info_subtitle: "Card Details",
    debit_add_card_info_tips: "Pionex.US works with Circle to process your deposits via cards (MasterCard/VISA only).",
    debit_add_card_info_num: "Card number",
    debit_add_card_info_holder_name: "Cardholder name",
    debit_add_card_info_expire: "Expiry",
    debit_add_card_info_cvv: "CVV",
    debit_add_card_billing_subtitle: "Billing address",
    debit_add_card_billing_street: "Street Address",
    debit_add_card_billing_street2: "Street Address(Line 2)",
    debit_add_card_billing_city: "City",
    debit_add_card_billing_post: "Post Code",
    debit_add_card_billing_country: "Country",
    debit_add_card_billing_state: "State",
    debit_add_card_billing_tips:
        "I am linking my bank because I want to use deposited funds to trade cryptocurrency.{br}Nobody else is instructing me to create a Pionex.US account or link my bank account.",
    debit_add_card_billing_tips2: "If someone else is instructing you to deposit with promises of free money, they are likely attempting to defraud you.",
    debit_add_card_agree: "I agree",
    debit_deposit_title: "Debit Card Deposit",
    debit_deposit_select_card_title: "Select a card to deposit",
    debit_deposit_select_card_des: "USD can be deposited via Debit Card from a bank account under the same name as the owner of this Pionex.US account.",
    debit_deposit_tips:
        "1. <strong>Deposit Fees: $0.30 + 2.9%</strong> per transaction." +
        "{br}2. <strong>Deposit Limit:</strong> <remain>${daily}</remain> of daily limit available. {limitTip}" +
        "{br}3. <strong>Processing Time:</strong> Card deposits are usually credited within a few seconds! If it takes longer, it's because the charge is undergoing review by our external processor." +
        "{br}4. <strong>Withdraw Limit:</strong> For settlement and security reasons, you will not be able to withdraw the equivalent amount (in USD and crypto) of your card deposits for 10 full days. If there is any sign of unauthorized activity, fraud or potential chargeback, we may hold your funds for up to <strong>60 days.</strong>",
    debit_deposit_add_card: "Add Card",
    debit_deposit_amount_placeholder: "Amount（{min}-{max}）",
    debit_deposit_success:
        "You just requested a transfer of ${amount} from your debit card ({cardNum}) to your Pionex.US account. If you do not recognize this behavior, please change your password and 2FA, and contact customer support.",
    debit_delete_card_content: "Delete this card？{br}{br}{cardNum}",
    debit_add_card_success: "Add successfully",
    debit_add_card_num_format: "16-digit bank card number required",
    debit_add_card_cvv_format: "3-digit CVV code required",
    debit_deposit_select_card: "Select Card",
    "card_status:SUCCESS": "Approved",
    "card_status:VERIFY_REQUIRED": "Upload Card",
    "card_status:FAILED": "Rejected",
    "card_status:PENDING": "Pending",
    "card_status:REAUTHENTICATION": "Expired",
    card_status_tips_reject1: "{reason}.Please try to link another card.",
    card_status_tips_reject2:
        "This card is rejected. The submitted card details may have been invalid, or the Cardholder information may not have matched the owner of this Pionex.US account. Please link another card.",
    card_status_tips_pending:
        "This card is pending review. You will be able to use it to deposit once it is approved. Until then, you can select another card or try adding another. Most cards are approved immediately.",
    "card_reject_reason:INTERNAL_ERROR": "Verification failed due to an unknown reason",
    "card_reject_reason:VERIFICATION_FAILED": "Verification failed due to an unknown reason",
    "card_reject_reason:VERIFICATION_FRAUD_DETECTED": "Card suspected to be used for fraud",
    "card_reject_reason:RISK_DENIED": "Bank account denied by Checkout Risk Service, please remove it and try another account.",
    "card_reject_reason:VERIFICATION_NOT_SUPPORTED_BY_ISSUER": "Issuer bank was unable to process the transaction",
    "card_reject_reason:VERIFICATION_STOPPED_BY_ISSUER": "A stop has been placed on the card",
    "card_reject_reason:CARD_FAILED": "Verification failed due to a problem with the card such as the card number does not exist",
    "card_reject_reason:CARD_INVALID": "The card number was invalid",
    "card_reject_reason:CARD_ADDRESS_MISMATCH": "The billing address provided in the card creation request did not match the one recorded by the issuer bank",
    "card_reject_reason:CARD_ZIP_MISMATCH": "The postal code provided in the card creation request did not match the one recorded by the issuer bank",
    "card_reject_reason:CARD_CVV_INVALID": "Incorrect CVV value provided",
    "card_reject_reason:CARD_EXPIRED": "Card expired",
    "card_reject_reason:CARD_NOT_HONORED": "The issuing bank did not authorize the card",
    "card_reject_reason:CARD_ACCOUNT_INELIGIBLE": "The card is not linked to an eligible bank account",
    "card_reject_reason:CARD_LIMIT_VIOLATED": "The amount or frequency of payments exceeded card limits",
    "card_reject_reason:CARD_CVV_REQUIRED": "The cvv is either incorrect or missing",
    "card_reject_reason:THREE_D_SECURE_NOT_SUPPORTED": "3DS transactions are not supported by the issuing bank",
    "card_reject_reason:THREE_D_SECURE_INVALID_REQUEST": "The 3DS request was submitted with invalid parameters",
    "card_reject_reason:REPEAT_BIND_CARD":
        "This is an existed card was uploaded by someone else, please try to upload another card. You can contact support if the issue persists.",
    debit_add_card_unsupported_num: "Only visa or mastercard supported",
    debit_card_sumsub_verify_require:
        "You still need to upload the card details to continue the process. The deposit is available when all the confirmations completed. <verify>Continue Now</verify>",
    debit_card_sumsub_verify_resubmit:
        "Please retake the photo so that the following information could be easily read: the card number, the cardholder’s name, and expiration date. Also make sure the photo is bright and clear and edited photos cannot be accepted. <verify>Reupload</verify>",
    "debit_card_sumsub_verify_status:NOT_STARTED":
        "Upload the card details to verify your card. Small deposits will be more convenient after verification. <verify>Verify Now</verify>",
    "debit_card_sumsub_verify_status:PENDING":
        "This card is pending verification. You could still deposit during the confirmation, small deposits will be more convenient after verification.",
    "debit_card_sumsub_verify_status:REJECTED": "This card failed verification. You could contact our customer service for further information.",
    debit_record_deposit_from: "Deposit from",
    debit_add_card_error: "Uploading your card was not successful. Please check your card details and try again. You can contact support if issue persists.",
    debit_deposit_error: "Please try again or contact our customer service.",
    nav_fbo_des1: "1-3 business days",
    nav_fbo_des2: "Better for large amounts, 0% fees for deposit",
    nav_debit_des1: "Instant",
    nav_debit_des_recommend: "Recommended",
    nav_debit_des2: "Better for small amounts. Funds will arrive instantly",
    debit_deposit_cover_submitting: "Submitting payment request..",
    debit_deposit_cover_submitted: "Submitting payment request",
    debit_deposit_cover_3ds: "Awaiting 3D Secure authentication",
    "debit_deposit_callback_res:success": "Verification Success, please go back to Pionex.US to Continue.",
    "debit_deposit_callback_res:fail": "Verification Failure, please go back to Pionex.US and try again.",
    "circle_deposit_fail_reason:PAYMENT_FAILED": "Payment failed due to an unknown reason",
    "circle_deposit_fail_reason:PAYMENT_FRAUD_DETECTED": "Suspected fraud detected by Issuing bank. Please contact your bank directly to resolve.",
    "circle_deposit_fail_reason:PAYMENT_DENIED": "Payment denied by Checkout Risk Service.",
    "circle_deposit_fail_reason:PAYMENT_NOT_SUPPORTED_BY_ISSUER": "Your issuer bank was unable to process the transaction.",
    "circle_deposit_fail_reason:PAYMENT_NOT_FUNDED": "There were insufficient funds to cover the payment amount.",
    "circle_deposit_fail_reason:PAYMENT_STOPPED_BY_ISSUER": "A stop has been placed by the issuer or yourself.",
    "circle_deposit_fail_reason:PAYMENT_CANCELED": "Payment was canceled.",
    "circle_deposit_fail_reason:PAYMENT_RETURNED": "ACH payment was returned.",
    "circle_deposit_fail_reason:PAYMENT_FAILED_BALANCE_CHECK": "Payment failed the Plaid balance check due to insufficient funds.",
    "circle_deposit_fail_reason:PAYMENT_UNPROCESSABLE": "The order could not be processed.",
    "circle_deposit_fail_reason:CARD_FAILED": "The payment failed due to a problem with the card such as an incorrect card number.",
    "circle_deposit_fail_reason:CARD_INVALID": "The card number was invalid.",
    "circle_deposit_fail_reason:CARD_CVV_INVALID": "Incorrect CVV value provided.",
    "circle_deposit_fail_reason:CARD_EXPIRED": "Card expired",
    "circle_deposit_fail_reason:CARD_LIMIT_VIOLATED": "The amount or frequency of payments exceeded card limits.",
    "circle_deposit_fail_reason:CARD_NOT_HONORED": "The issuing bank did not approve the payment.",
    "circle_deposit_fail_reason:CARD_ACCOUNT_INELIGIBLE": "The card is not linked to an eligible bank account.",
    "circle_deposit_fail_reason:CARD_RESTRICTED": "Issuer has declined the transaction because the card cannot be used for this type of transaction.",
    "circle_deposit_fail_reason:UNAUTHORIZED_TRANSACTION": "The payment was not authorized",
    "circle_deposit_fail_reason:BANK_ACCOUNT_INELIGIBLE": "The account is not eligible and no other default account was found.",
    "circle_deposit_fail_reason:BANK_TRANSACTION_ERROR": "The bank reported an error processing the transaction.",
    "circle_deposit_fail_reason:INVALID_ACCOUNT_NUMBER": "The account number is invalid or missing.",
    "circle_deposit_fail_reason:INVALID_WIRE_RTN": "The wire routing number is invalid.",
    "circle_deposit_fail_reason:INVALID_ACH_RTN": "The ACH routing number is invalid.",
    "circle_deposit_fail_reason:REF_ID_INVALID": "Push payment reference Id not recognized.",
    "circle_deposit_fail_reason:ACCOUNT_NAME_MISMATCH": "Account name doesn't match Checkout resource account name.",
    "circle_deposit_fail_reason:ACCOUNT_NUMBER_MISMATCH": "Push payment account number does not match Checkout resource account number.",
    "circle_deposit_fail_reason:ACCOUNT_INELIGIBLE": "Ineligible fiat account due to invalid type or state.",
    "circle_deposit_fail_reason:WALLET_ADDRESS_MISMATCH": "Wallet address does not match sender address for signet.",
    "circle_deposit_fail_reason:CUSTOMER_NAME_MISMATCH": "Your full name doesn't match sender name.",
    "circle_deposit_fail_reason:INSTITUTION_NAME_MISMATCH": "Institution name doesn't match sender name.",
    "circle_deposit_fail_reason:AML_FAILED": "Submit failed, the amount or frequency of payments exceeded the limits.",
    "circle_deposit_fail_reason:CREATE_CIRCLE_PAYMENT_FAILED": "Create payment order failed.",
    "circle_deposit_fail_reason:THREE_D_SECURE_ACTION_EXPIRED":
        "You took too long to finish the 3DS process or the transaction was blocked by the card issuing bank. You could contact your bank to try to unblock your card before trying again. We’d suggest that you try another card if the issue persists.",
    "circle_deposit_fail_reason:THREE_D_SECURE_FAILURE": "failed the 3DS authentication step",
    "circle_deposit_fail_reason:REFUND_CIRCLE_PAYMENT_FAILED": "Refund failed, please keep the screenshot and contact our customer service.",
    "circle_deposit_fail_reason:BALANCE_NOT_ENOUGH":
        "Insufficient balance, your bank account balance must be greater than 100% of the total amount of this ACH order and your previously unsettled ACH orders.",
    "circle_deposit_fail_reason:PAYMENT_SOURCE_NOT_FOUND": "Could not find your ACH account, please keep the screenshot and contact our customer service.",
    "circle_deposit_fail_reason:CIRCLE_ACH_PLAID_ERROR": "Plaid is having an issue connecting, please try another bank account.",
    debit_deposit_timeout: "Request time out; please check the status in your Order History.",
    limit_region_title: "Service Suspended for Restricted Regions",
    limit_region_des:
        "We recognize that you are trying to access Pionex.US from a {region} IP address, please note that per our User Agreement, we are unable to serve any users from a {region} IP.For withdrawal only, please contact our <service>customer service.</service>",
    swap_usdt_hint: "The swap function will use market order to buy coins, so there might be a little slippage (usually<0.1%).",
    dw_available_tips_title: "How we calculate your available balance",
    dw_available_tips_content:
        "1. Excluding the funds occupied by your active trading bots and uncompleted orders.{br}{br}2. The funds deposited in USD have a holding period that we will exclude the amount of these funds.{br}Wire: Standard  - 24 hours; Risk-detected - 7 days.{br}Debit Card: Standard - 10 days; Risk-detected - 60 days.{br}ACH: Standard - 14 days; Risk-detected - 60 days.{br}{br}3. You could check the details in your Deposit History",
    debit_agree: "I have read and agree with the terms above.",
    tutorials_beginning: "Tutorial for beginners",
    deposit_via_wire: "How to Deposit via Wire",
    deposit_wire_content: "A wire Transfer deposit is a more convenient way to make you transfer a large amount of funds from your bank account to Pionex.US easily.",
    debit_card: "How to Deposit via Debit Card",
    debit_card_content:
        "A Debit Card deposit is a decent way to experience the Pionex.US Grid Trading bot with a small amount of funds. You could start your first trading bot with only tens of USD.",
    create_Trading_Bot: "How to Create Your First Bot",
    create_Trading_Bot_content: "Several steps to set up a trading bot. Using trading bots can avoid erroneous trading decisions caused by human factors to a greater extent. ",
    create_bot_sign_button: "Create a bot",
    signature_withdraw_handling_fee: "Withdrawal fee",
    set_up_account: "How to set up an account",
    trade_tab_bot: "Bot",
    beginner_available_balance: "Available balance:",
    beginner_create_order_btn: "Create {symbol} Bot",
    beginner_banner_text: "How to Create my frist bot",
    beginner_grid_text: "Grid Trading AI Strategy",
    beginner_grid_text_moon: "Grid Trading Moon Strategy",
    beginner_grid_text1: "Grid Profit (7D)",
    beginner_grid_text2: "Max drawdown (7D)",
    beginner_invest_amount: "Total investment",
    market_trades: "Market Trades",
    swap: "Convert",
    swap_selectionCoin: "Choose a coin",
    swap_balance_available: "Available",
    swap_balance: "Balance",
    swap_tips: "The swap function will use market order to buy/sell coins, so there might be a little slippage (usually<0.1%).",
    swap_history: "History",
    swap_max: "MAX",
    swap_selectTitle: "Select a token",
    swap_selectPlaceholder: "Search coin name",
    pro_mostPopular: "Most popular",
    symbols_header_24h: "24H",
    symbols_header_24h_Change: "CHANGE",
    trade_struct_default: "Default",
    trade_struct_no_orderbook: "No OrderBook",
    trade_struct_fullscreen: "Full Screen",
    symbols_mode_watchlist: "Watchlist",
    symbols_mode_mini: "Watchlist mini",
    symbols_mode_close: "Close",
    not_enough_balance_sell: "Not enough balance",
    not_enough_balance_swap: "You don't have any {coin} to convert. Try buying some to get started.",
    trade_menu_title: "Trade",
    trade_menu_Bot: "Bot",
    trade_menu_Manual: "Manual",
    trade_menu_Swap: "Swap",
    withdraw_upLoadDoc: "3.Upload supporting documents",
    withdraw_upLoadDocDesc: "You are only allowed to withdraw money with your own card, so you need to prove that the card belongs to you.",
    withdraw_upLoadDocRequire: "Required:",
    withdraw_upLoadDocRequireDec: `
    Please provide the proof of ownership of the account. <strong>The proof must contain your name and your bank account number.</strong> The following items may contain relevant information, and you can upload any of them (Bank Statement, Bank credentials page or A letter from the bank){br}
Please note that we only support png, jpeg, jpg, pdf and file size must be within 10KB - 10 MB.`,
    withdrawable_Time: "Withdrawable Time",
    signature_dw_history_time_New: "Update Time",
    deposit_create_order_atm_bind_step2_upload: "Upload payment receipt (must include name, date, amount)",
    deposit_create_order_atm_bind_change_photo: "Edit",
    deposit_create_order_atm_bind_file_size: "Image size should be less than 10M",
    deposit_create_order_atm_bind_file_size_min: "Image size should be larger than {min}k ",
    deposit_create_order_atm_bind_file_size_type: "Only supports jpeg, jpg, png, pdf formats",
    fbo_upload_failed: "Failed to upload image, please try again",
    sign_up_institution_title: "Institution？",
    sign_up_institution_desc: "Create an institutional account here",
    sign_up_institution_modal_title: "Create Institutional Account",
    sign_up_institution_modal_desc:
        'If you would like to register an institutional account, please send an email with the subject "Create Institutional Account" to <mail>compliance.operation@pionex.us</mail> and we will tell you what information you need to provide.',
    taxes: "Taxes",
    taxes_titie: "Export transaction records",
    taxes_frequency: " exporting times remaining/month",
    taxes_hint:
        "No more than 24 hours at most.    You can download the file through APP or the web site. The link is only valid within the next 7 days after generating. Please download in time.",
    taxes_select: "Select the export time",
    taxes_last_record: "Last application record",
    taxes_not: "Not yet applied",
    taxes_has_expire: "The current transaction log file has been transitioned, if you need to download it, start over",
    taxes_last: "Last application",
    taxes_export_account: "Account:",
    taxes_export_time: "Time range：",
    taxes_export_confirm: "Confirmation",
    taxes_last_year: "Last Year",
    taxes_current_year: "This Year",
    taxes_customer: "Freely selected time period",
    taxes_download: "Download",
    taxes_processing: "Processing",
    taxes_processing_warn: "You have another application under process. Please re-submit after the process is completed.",
    taxes_expire_time: "Valid until {time}, please download within the validity period",
    taxes_successfully: "successfully ",
    taxes_start_time: "start time",
    taxes_end_time: "end time",
    debit_card_result_verify_card_btn: "Verify your card",
    debit_card_result_verify_card_desc: "You will be less likely to encounter payment failures after Card Verification",
    fm_arbitrage_expect_apr: "APR",
    trade_mode_tooltip_light: "Light mode",
    trade_mode_tooltip_dark: "Dark mode",
    trade_mode_tooltip_customer_service: "Customer service",
    nav_ach_des2: "Quick transfers via ACH. Funds are instantly available for trading",
    "deposit_fail_reason:AML_FAILED": "Submit failed, the amount is over the available limit",
    // ACH
    ach_title: "Deposit USD via ACH",
    ach_sub: "Pionex.US uses Plaid and Circle to allow you to safely and securely deposit funds from your bank account.",
    ach_tip_1:
        "ACH deposits aren't always early credited due to risk purposes, it usually takes 4-6 business days for the funds to clear through the ACH network and land in your Pionex.US account.",
    ach_tip_2: "$ {amount} of daily limit available.",
    ach_tip_3: "You will not be able to withdraw the equivalent amount (in USD and crypto) of your ACH deposit for 10 days.",
    ach_bind_failed: "Authorization failed, please try again.",
    ach_deposit_auth: "I agree with the terms above.",
    ach_connect_another: "CONNECT A BANK ACCOUNT",
    ach_deposit_success: "Your deposit of {amount} USD has been confirmed, you can trade now.",
    ach_account_del_confirm: "Are you sure you want to delete this account?",
    ach_account_del_success: "Account deleted successfully!",
    ach_select_account: "Select account",
    ach_link_confirm_title: "Link Your Bank Account",
    ach_link_confirm_des:
        "You are linking your bank account under the same name as the owner of this Pionex.US account.{br}{br}Nobody else is instructing you to create a Pionex.US account or link your bank account.",
    ach_link_confirm_des1: "Insufficient balance, will use bank account for purchase!",
    ach_agreement_title: "Authorization",
    ach_agreement_des1:
        "I authorize Checkout LLC or its affiliate, on behalf of Pionex.US, to electronically debit my account (and, if necessary, electronically credit my account) at the financial institution indicated by me as follows:",
    ach_agreement_des2:
        `By clicking "I authorize" below, I consent and authorize Checkout LLC or its affiliate on behalf of Pionex.US, to debit my account for a one-time electronic funds transfer (EFT) payment for the amount and on the date identified above. I also authorize Checkout LLC or its affiliate to debit or credit my account to correct any errors or process any refunds.{br}<italic>PLEASE NOTE: You are authorizing a one-time payment that will begin processing immediately. If there is a problem with the transaction, you may contact</italic> <service>service@pionex.us</service><italic>, however, there is no guarantee of cancellation.</italic>` +
        "{br}{br}" +
        "Scheduled in advance payments may be revoked by contacting <service>service@pionex.us</service> 3 business days prior to the scheduled payment." +
        "{br}{br}" +
        "I agree with Checkout <policy>Privacy Policy</policy>" +
        "{br}{br}" +
        "I understand that as of today's date, this payment authorization is valid.",
    ach_agreement_des3: "I consent to sign this authorization electronically.",
    ach_agreement_name: "Name",
    ach_agreement_date: "Date",
    ach_agreement_institution_name: "Financial Institution Name",
    ach_agreement_routing_num: "Routing Number",
    ach_agreement_account_num: "Account Number",
    ach_agreement_amount: "Amount",
    ach_agreement_purpose: "Purpose of Payment",
    ach_agreement_purpose_value: "To purchase and trade cryptocurrency on Pionex (pionex.us)",
    ach_agreement_auth_date: "Authorization Date",
    ach_agreement_ok: "I authorize",
    "ach_card_status:PENDING":
        "This account is pending review. You will be able to use it to deposit once it is approved. Until then, you can select another account or try adding another. Most situations are approved immediately.",
    "ach_card_status:FAILED":
        "We couldn't verify your account with the provided credentials, or the Accountholder information may not have matched the owner of this Pionex.US account. Please link another account.",
    ach_deposit_success_title: "Submitted Successfully",
    ach_deposit_success_btn: "Check My Balance",
    ach_deposit_success_des:
        "You can check your account balance when the funds are credited." +
        "{br}" +
        "Please keep in mind:" +
        "{br}" +
        "1. Ensure your <strong>bank account balance is sufficient</strong> until the order status is “Complete”." +
        "{br}" +
        "2. You cannot withdraw the equivalent amount (in USD and crypto) of your ACH deposit for a <strong>14-day</strong> holding period. If there is any sign of unauthorized activity or fraud, we may hold your funds for up to 60 days.",
    ach_add_account: "Add Account",
    ach_history_amount: "Amount (including fees)",
    ach_tip:
        "1. <strong>Deposit Fees: </strong>{fee}" +
        "{br}2. <strong>Deposit Limit: </strong>{maxAmount} limit available. {userLevelLimit}" +
        "{br}3. <strong>Processing Time:</strong> ACH deposits aren't always early credited due to risk purposes. During this period, you may notice the funds debited from your bank account and not yet credited to your Pionex.US account." +
        "{br}4. <strong>Withdraw Limit:</strong> For settlement and security reasons, you will not be able to withdraw the equivalent amount (in USD and crypto) of your ACH deposits for <strong>14 full days</strong>. If there is any sign of unauthorized activity, fraud or potential reversal, we may hold your funds for up to 60 days.",
    ach_tip2:
        "1. <strong>Deposit Fees: </strong>{fee}" +
        "{br}2. <strong>Deposit Limit: </strong>{maxAmount} {userLevelLimit}" +
        "{br}3. <strong>Processing Time:</strong> ACH deposits aren't always early credited due to risk purposes. During this period, you may notice the funds debited from your bank account and not yet credited to your Pionex.US account." +
        "{br}4. <strong>Withdraw Limit:</strong> For settlement and security reasons, you will not be able to withdraw the equivalent amount (in USD and crypto) of your ACH deposits for <strong>14 full days</strong> from the time Pionex.US received the payment. If there is any sign of unauthorized activity, fraud, or potential reversal, we may hold your funds for up to 60 days from the time Pionex.US received the payment.",
    ach_0fee_text: "{fee} Fees (Limited Time)",
    ach_0fee_des: " {fee} (Limited Time Offer)",
    ach_fee: "{fee} per transaction.",
    usddw_channel_maintenance: "Under maintenance...",
    usddw_deposit_agree: "I agree with the Terms above and <agreement>Chargeback/Refund Policy.</agreement>",
    usddw__ach_deposit_agree: "I have read and undertake the <agreement>Joint Account Policy.</agreement>",
    usddw_deposit_astrict: "Due to restrictions on your account, certain features are currently unavailable.",
    balance_wallet: "Wallet",
    balance_crypto: "Crypto",
    balance_receive_crypto: "Receive Crypto",
    balance_send_crypto: "Send Crypto",
    balance_usd: "USD",
    search_coin_placeholder: "Search a coin",
    protocol_name: "Network",
    account_withdraw_fee: "Withdrawal fee",
    "fees.deposit.table.min": "Minimum deposit",
    "see.kyc.rule": "View KYC authentication terms",
    "fees.withdrawal.daily.kyc.none": "KYC LV.0",
    "fees.withdrawal.daily.kyc.level.1": "KYC LV.1",
    "fees.withdrawal.daily.kyc.level.2": "KYC LV.2",
    "fees.withdrawal.daily.kyc.level.current": "Current KYC level：Level{currentLevel}",
    fee_transaction_info: "{fee} for the trading pairs based on {symbol} , including the following pairs:",
    fee_transaction_info_other: "0.1% for all transactions",
    fee_transaction_info_note: "Notes: We reserve the right to change the trading fee.",
    fee_transaction_notice: "Notes: We reserve the right to change the trading fees based on business conditions.",
    sign_up_learning_call: "Schedule 1-on-1 learning call",
    sign_up_learning_call_title: "1-on-1 Learning Session",
    sign_up_learning_call_content: "30  minutes  coaching  call  by  Pionex team ",
    sign_up_learning_ok: "Schedule",
    sign_up_limit_offer: "Limited Offer",
    sign_up_schedule_now: "Schedule Now",
    sign_up_length_error: "Please enter 8-16 characters",
    sign_up_characters_err: "Please contain both letters and numbers",
    sign_up_learning_content: "New to Pionex? Schedule a 30 minutes 1-on-1 onboarding call with our customer team. We will guide you on how to use the trading bots.",
    signature_deposit_subtitle: "To add funds, send a wire from your bank to Pionex.US using the details below. We will email you when the transfer is complete.",
    spot_no_watchlist: "You haven't added any coin/trading pair in the watchlist.",
    spot_main_trend_assets: "Popular Pairs",
    spot_add_to_fav: "Add to Favorites",
    spot_add_others_fav: "Add Other Pairs",
    "debit_add_card_fail:CIRCLE_CARD_DUPLICATE_BIND":
        "This is an existed card was uploaded by someone else, please try to upload another card. You can contact support if the issue persists.",
    "debit_add_card_fail:CHANNEL_CLOSED": "Unknown error. Please try again later or contact the support team.",
    "debit_add_card_fail:PAYMENT_CHANNEL_MAINTENANCE": "Service is under maintenance, please try again later.",
    "debit_add_card_fail:CIRCLE_KYC_NOT_PASSED": "This service is temporarily unavailable for you, please use other methods to deposit.",
    "debit_add_card_fail:UPSTREAM_ERROR": "Uploading your card was not successful, failure reason {message}. Please check your card details and try again.",
    wallet_overview_trade:
        "If you have bots or swap orders using this coin, your assets in bots or limit orders won't be tradable until the bots are closed or the orders executed.",
    account_login: "Sign in",
    account_registration: "Sign up",
    refresh_now: "Refresh",
    refresh_retry: "Refresh QR code",
    scan_code_with_new_app: "Scan this QR code with <a>the latest version of Pionex.US app</a> to sign in instantly.",
    ensure_app_version: "Please update to {version} or above",
    QR_code_has_expired: "QR code expired",
    login_failed: "Failed to sign in",
    select_area_code: "Select area code",
    wait_for_app_confirmation: 'Plese click "confirm" on your app',
    deposit_crypto_guide: "Video Tutorial",
    logging_in: "Signing in",
    debit_resubmission_cover_tips_title:
        "Please retake the photo so that the following information could be easily read: the card number, the cardholder’s name, expiration date. Also make sure the photo is bright and clear and edited photos cannot be accepted.",
    debit_resubmission_cover_btn: "Reupload",
    debit_upload_cover_tips_title: "Please upload the photo of your card and make sure the following information can be easily read:",
    debit_upload_cover_tips_li0: "-The card number.",
    debit_upload_cover_tips_li1: "-The cardholder's name.",
    debit_upload_sample_screenshot: "Sample of screenshot:",
    debit_upload_wait_continue: "Waiting for you to continue",
    debit_upload_cover_tips_li2: "-The expiration date.",
    debit_upload_cover_tips_btn: "Upload",
    debit_reject_cover_tips_btn: "Add another card",
    debit_add_card_limit: "Too many cards bound yet, please delete first.",
    ach_agreement_auth_time: "Authorization Date and Time",
    ach_agreement_auth_ip: "IP Address",

    ach_expired_cover_tips_title: "The verification of this connected account has expired. Please take 1 minute to re-verify it and then you can deposit.",
    ach_expired_cover_btn: "Verify Now",

    twap_aip_combine_title: "TWAP",
    order_create: "Create {base}/{quote} Bot ",
    martingale_stop_loss_label: "Percentage (one decimal, optional)",
    mtg_add_position_des: "Hint: Your added investment will be used to immediately buy coins with the market price to lower your average buy price.",
    mtg_reduce_position_des: "Hint: Your investment and profit will be withdrawn proportionally. This round will be stopped, and a new round will be started after withdrawing",
    mtg_add_position_status_des: "We will DCA buy {coin} to avoid slippage",
    mtg_reduce_position_status_des: "We will DCA sell {coin} to avoid slippage",
    mtg_add_position_status_title: "Adding investment of {coin}...",
    mtg_reduce_position_status_title: "Withdrawing investment of {coin}...",
    mtg_reduce_position_ban: "Withdrawing investment from this bot is not allowed as the total profit is negative or the remaining funds are less than the minimum required limit.",
    mtg_add_and_withdraw_history: "History",
    mtg_round_withdraw_tip: "This round was terminated because of withdrawing investment",
    mtg_transaction_round_reduce_title: "Release",
    martingale_renew_warning_content:
        "After renewing, the current round will be immediately terminated. All placed orders will be canceled, and all your holding assets will be sold right after that. The bot will start a new round.\nYou'll be able to check the details in the Transactions tab",
    martingale_renew_warning_profit_this_round: "Profit of this round",
    martingale_renew_success_toast: "Renew success",
    martingale_renew_failed_toast: "Renew failed",
    martingale_renew_failed_same_round: "Renewing",
    martingale_renew_failed_invalid_round: "Renew failed, please try again",
    invite_users_to_become_grid_talents: "Invite friends and earn 20% commission rebates",
    referral_program_tab_title: "Rules",
    referral_program_tab_my_rebate: "My earnings",
    Referral_programm_title: "Details",
    invitation_universal_share_title:
        "Come and trade with the automated trading bots in Pionex! Pionex offers 16 bots suitable for various market conditions for completely free! Claim your bot now:",
    invitation_fsa_share_title:
        "Earn 15%-50% APR with the Pionex arbitrage bot！You can take the profit every 8 hours and withdraw your investment at any time. Come and claim your bot for completely free:",
    invitation_gridTrading_share_title:
        "Come and try Pionex grid bot with me！The bot can help you to buy low sell high 24/7 and completely free yourself from concentrating on the volatile crypto market. Claim your grid bot for free:",
    sign_up_title_1: "General",
    check_trading_pairs: "Check trading pairs",
    check_trading_pairs_link: "https://pionexus.zendesk.com/hc/en-us/articles/8525075929369",
    total_commission: "Total commission",
    today_commission: "Today's commission",
    grid_talent_desc: "Skilled invitees refer to the invitees who have used the Grid Trading Bots and whose volume has been more than 20,000 USDT.",
    referral_used_qualified: "Skilled",
    detailed_rules_content_3: "The commissions includes all the transaction fee.",
    pro_referrer_title: "Pro referrer",
    pro_referrer_content:
        "If you are able to invite more than 100 users to run the bot, you can apply to be a Pro referrer via email service@pionex.us, and you can enjoy up to 50% commissions.",
    bub_content:
        "You will get 25 BUB when your invitee's trading volume has reached 20,000 USDT using Grid Trading Bot. When you start to become a Pro referrer, you won't get BUB anymore.",
    congratulation: "Congratulations!",
    commission_update: "Update",
    get_3_types_of_rebate: "After activation, you will receive commissions on the following fees from all of your invitees: ",
    some_spot_trading_pairs: "The transaction fees on the maker orders of some specific trading pairs in the spot market",
    all_lever_tokens: "The transaction fees on all the orders of leveraged tokens",
    term_arbitrage_fee: "The fee revenue from the arbitrage bot(exclude fees depositing to SAFU fund)",
    rebate_data: "Rebate Dashboard",
    last_updated_on: "Last updated: ",
    referral_program_my_code: "My referral code",
    mine_invitation_link_title: "My Invitation link",
    friends_bill_first_time: "Friends Who Started Trading",
    complete_friends_bill_first_time: "Friends Who Started Trading",
    inviting_link: "My invitation link ",
    detailed_rules_content_1: "Earn a 20% commission rebate from all the invitees after you invite 2 skilled invitees.",
    detailed_rules_content_2: "Skilled invitees refer to the invitees who have used the Grid Trading Bots and whose volume has been more than 20,000USDT.",
    grid_talent: "Skilled Invitees",
    invitation_materials: "Invitation material",
    first_billing: "Create first order",
    introduction_materials: "Introduction material",
    create_the_first_trading_robot: "If your friends want to know more about Pionex trading robos, you can share these information to let them create the first bot easily.",
    preferred_investment_strategy:
        "The grid trading robot has always been the preferred investment strategy of Pionex users. It  can help you buy low sell high automatically 7/24.",
    news_report_regulatory: "Press",
    safety_is_in_doubt: "If your friends still have questions about the security of Pionex, you can share these links to let them know more about us.",
    through_12_free_trading_robots: "With 16 free trading bots, Pionex has gained a lot of users in 2020 and has become more well-known in 2021.",
    digital_currency_related_licenses:
        "Pionex has obtained a digital currency-related license (MSB: Money Services Business) issued by tThe Financial Crimes Enforcement Network (FinCEN)",
    opening_method: "Rebate Rule",
    extra_rewards: "Additional rewards",
    settlement_method: "Settlement method",
    settled_every_hour: "The rebate is settled every hour, and will be directly refunded into your wallet.",
    congratulations_on_opening_20_rebate: "Congratulations on opening {percent} commission rebate",
    open_percent_rebate: "Activate {percent} commission rebate",
    grid_talent_invited: "Skilled invitees",
    click_to_open_20_commission_rebate: "You have successfully invited {number} skilled invitees, click to get {percent} commission rebate",
    invite_more_friends: "Invite more friends and earn more rebates",
    rebate_source: "Commission Rebate",
    invited_friend_account: "Invitee's account number",
    dual_currency_investment_30_days: "Dual investment is a very low-risk financial product provided by Pionex. Around 30% APY.",
    msb_license_plate: "MSB License",
    invite_commission_total: "Total commission",
    invite_commission_today: "Today's commission",
    invite_commission_yesterday: "Yesterday's commission",
    invite_rebate_rule: "Rebate Rule",
    invite_rebate_rule_content_1:
        "You can earn a 20% commission rebate from all the invitees who register Pionex.US with your invitation link or enter your referral code during their registration process.",
    invite_title_sub: "After invitation, you will receive commissions on the following fees from all of your invitees:",
    invite_title_sub_info: "The transaction fees on the market orders of some specific trading pairs in the spot market",
    invite_first_rebate: "Traded",
    invite_rebate_code: "Invalid invitation code. Please enter the invitation code of pionex.us.",

    dw_tips_not_support_contract: "Depositing {coin} through smart contract is temporarily not supported.",
    dw_tips_not_support_contract_lose:
        "Depositing through smart contract is not supported for this coin. You will lose your asset and the fund will not be able to retrieve if you use smart contract deposit.",

    bot_strategy_earn_usd: "Earn USD from fluctuations",
    bot_strategy_earn_bit: "Earn Bitcoin from fluctuations",
    bot_strategy_grid_desc: "A Bitcoin miner - without using electricity",
    bot_strategy_grid_name: "Bitcoin Growth Bot",

    bot_list_title: "Create a new bot",
    layout_set: "Layout settings",
    layout_order: "Orderbook (in manual tab)",
    layout_market: "Market trades  (in manual tab)",
    layout_Search: "Search coin bar",
    layout_desc: "Mini mode is recommended when the resolution is greater than 1,800, and full mode is recommended when the resolution is greater than 1,920.",
    layout_auto: "Auto adaptive",
    layout_nano: "Nano mode",
    layout_mini: "Mini mode",
    layout_full: "Full mode",

    withdraw_forbidden_title: "Sending crypto is temporarily forbidden",
    withdraw_forbidden_content_reset_password: "You have changed the password recently",
    withdraw_forbidden_content_bind_otp: "You have changed the Google authenticator recently",
    withdraw_forbidden_content_unbind_otp: "You have disabled the Google authenticator recently",
    withdraw_forbidden_content_reset_otp: "You have reset the Google authenticator recently",
    withdraw_forbidden_content_reset_account: "You have disabled the SMS or email authentication recently",
    withdraw_forbidden_content_suffix: ". For your account's safety, please send crypto after {time}.",
    withdraw_forbidden_content_default:
        "For your account's safety, withdrawal is temporarily forbidden. Please contact {app_short_name} customer support if you have any questions.",
    withdraw_forbidden_content_remote_login: "Noticed your account was accessed from a new IP address",
    no_data_coin: "No coins found",
    bot_symbol_guide_btc: "Buy Bitcoin in a dip",
    bot_symbol_guide_name: "Bitcoin Moon",
    bot_symbol_guide_miner: "Earn Bitcoin from fluctuation",
    manual_Buy_with: "Deposit more",

    "ach_link_token_error:CIRCLE_ACH_ACCOUNT_TOO_MUCH": "Only one bank account can be kept for ACH transfer. Please remove it if you want to switch to a different account.",
    "ach_link_token_error:CIRCLE_ACH_TRY_TOO_MUCH": "Limit exceeded, 24 hours to bind again.",
    "ach_link_token_error:CIRCLE_ACH_STATUS_INVALID": "This account is not valid, please try another account.",
    "ach_bind_account_error:CIRCLE_ACH_NOT_SUPPORT_JOINT_ACCOUNT": "Joint Account is not supported currently, please try another account.",
    "ach_bind_account_error:CIRCLE_ACH_NAME_NOT_MATCH":
        "The bank account holder's name doesn't match your Pionex.US account holder's name. Make sure to use a matching bank account and try again.",
    "ach_deposit_error:CIRCLE_ACH_RE_AUTHENTICATION": "The verification of this connected account has expired. Please re-verify it first.",
    "ach_deposit_error:CIRCLE_PARAMETER_ERROR": "Parameter error, please try later.",
    "ach_deposit_error:CIRCLE_ACH_ACCOUNT_INVALID": "Invalid account.",
    "ach_deposit_error:DATA_NOT_FOUND": "We can not find your account.",
    "ach_deposit_error:CIRCLE_PAYMENT_CARD_NOT_MATCH":
        "The bank account holder's name doesn't match your Pionex.US account holder's name. Make sure to use a matching bank account and try again.",
    "ach_deposit_error:CIRCLE_KYC_NOT_PASSED": "Identity verification failed.",
    "ach_deposit_error:PAYMENT_CHANNEL_MAINTENANCE": "Under maintenance, please try later.",
    "ach_deposit_error:CIRCLE_BALANCE_NOT_ENOUGH": "Insufficient balance, please check your bank account balance.",
    ach_deposit_error_unknown: "Sorry, the ACH-card binding failed with unknown error, please contact our customer service.",
    unknown_error_code: "UNKNOWN ERROR:{code}",
    ach_bind_account_unknown_error: "Sorry, The ACH-card binding is failed with unknown error, please contact our manuel service.",

    "ach_deposit_status_tips:EARLY_CREDIT": "An early credited order still waiting for the bank settlement.",
    "ach_deposit_status_tips:DEDUCTED": "The amount has been deducted due to your insufficient bank account balance or it was an ACH Return Order.",

    "ach_deposit_status:PENDING": "PENDING",
    "ach_deposit_status:SUCCESS": "COMPLETE",
    "ach_deposit_status:FAILED": "FAILED",
    "ach_deposit_status:EARLY_CREDIT": "EARLYCREDITED",
    "ach_deposit_status:DEDUCTED": "DEDUCTED",

    "ach_bind_account_circle_error:VERIFICATION_FAILED": "Verification failed due to an unknown reason, please remove it and try another account.",
    "ach_bind_account_circle_error:BANK_ACCOUNT_AUTHORIZATION_EXPIRED": "The authorization information expired, please remove it and try another account.",
    "ach_bind_account_circle_error:BANK_ACCOUNT_ERROR": "Account information is unreachable with the provided credentials, please remove it and try another account.",
    "ach_bind_account_circle_error:BANK_ACCOUNT_INELIGIBLE": "Unable to find an eligible account with the provided credentials, please remove it and try another account.",
    "ach_bind_account_circle_error:BANK_ACCOUNT_NOT_FOUND": "Failed to find a bank account with the provided account number, please remove it and try another account.",
    "ach_bind_account_circle_error:BANK_ACCOUNT_UNAUTHORIZED": "Verification failed due to invalid credentials, please remove it and try another account.",
    "ach_bind_account_circle_error:UNSUPPORTED_ROUTING_NUMBER": "The ACH routing number is not supported, please remove it and try another account.",
    "ach_bind_account_circle_error:RISK_DENIED": "Bank account denied by Checkout Risk Service, please remove it and try another account.",
    "ach_bind_account_circle_error:CIRCLE_ACH_PLAID_ERROR": "Plaid is having an issue connecting, please try another bank account.",
    "ach_bind_account_circle_error:MANUAL_REJECT":
        "The bank account holder's name doesn't match your Pionex.US account holder's name. Make sure to use a matching bank account and try again.",

    ach_deposit_tutorial: "Making your first deposit or running into issues? Read our <tutorial>ACH FAQ</tutorial> to avoid any delays.",

    dw_announce_url: "https://pionexus.zendesk.com/hc/en-us/sections/4940481615385-Wallet-Upgrade-and-Maintenance",
    ga_reset_email_tips:
        "Tip: To protect the security of your account, you need to answer all the questions. Pionex.US will decide whether to reset your Google Authenticator based on the answers you provide. We will complete the review within 3 business days and we will notify you by email.",
    ga_email_no_bot: "No bot orders",
    account_verify_default_phone_title: "Please input the SMS code",
    kyc_change_verify_type: "Change verification method",
    optional_verify_phone_type: "Auth by SMS",
    optional_verify_email_type: "Auth by Email",
    optional_verify_otp_type: "Auth by Google code",
    lost_security_account: "security verification unavailable?",
    lost_security_account2: "Lost Google Authenticator?",
    account_bind_2fa_before_unbind: "Please enable google authenticator before disabling other 2-Factor authentication",
    account_bind_phone_before_unbind_email: "Please enable SMS auth before disabling email\n",
    account_bind_email_before_unbind_phone: "Please enable email auth before disabling SMS\n",
    account_phone_unbind_success: "SMS auth disabled",
    account_email_unbind_success: "Email auth disabled",
    account_unbind_phone: "Disable SMS auth",
    "header.account.menu.accSetting": "Account Settings",
    account_unbind_hint_content: "Withdrawals will be disabled for 24 hours and sign up with the unbound phone/email is also forbidden within {day} days after unbinding.",
    account_unbind_email: "Disable email auth",
    unbinding_prompt: "Tips",
    user_transfer_login_failed_detail: "This account is not registered from Pionex.com. Please go to the website where you registered to sign in.",
    user_transfer_login_failed_join_community: "Join the official community if you have any questions",
    ga_unbind_account_title: "Please input the Google verification code.",
    ga_unbind_account_title_tips: "Hint: Unbind the Google Authenticator may cause serious security problem, we highly recommend you not to unbind the authenticator.",
    ga_bind_install_product_title: "1st step: install Google Authenticator",
    ga_bind_input_code_title: "2nd step: input account & key to get verification code",
    google_auth_bind_step_3: "Please input the Google verification code.",
    ga_bind_install_product_next: "Installed. Continue",
    google_authenticator_account_name: "Account name:",
    google_authenticator_key: "Key:",
    account_bind_ga_success: "You have already bound the Google Authenticator.",
    account_bind_ga_success_tip: "Your account is more secured",
    account_bind_ga_record_key:
        "Please record the [Key] on your notebook or somewhere safe and do not upload it to the internet. If you uninstall or lose your Google Authenticator, You can restore with the key.",
    account_bind_ga_key: "16-Digit key",
    account_bind_ga_code_error:
        "Wrong verification code. Google verification code will be reset every 30s, please input in time. Please check the time of your phone to make sure your system time is the same as the internet time. ",
    ga_email_unbind_message_Text: 'Please send an email with the email you registered in Pionex to {email} with the content "Reset Google 2FA".',
    ga_email_unbind_message_bottom_tip: "Pionex support team will contact you through email and help you unbind the Google Authenticator within 3 work days.",
    google_bind_authenticator: "Google Authenticator",
    google_bind_authenticator_des: "Google Authenticator",
    google_bind_step1: 'Open the Google Authenticator and tap the "+" button on the lower right and then choose the "Scan QR Code',
    ga_bind_install_entry: "Download and install Google Authenticator",
    account_chance_error_signIn: " The account will be temporarily frozen for {time}min if you fail the another {count} tries.",
    account_verify_error_1: "Incorrect account/ password. Or sign in with an unregistered account.",
    google_auth_app_scan_download: "Scan QR code to download",
    google_auth_app_tips: "Please use your mobile device to scan QR code to download",
    google_auth_app_apple_store: "Download from App Store",
    google_auth_app_form_gp: "Download from Google play",

    dw_no_email: "For security reasons, please bind your account email before using ACH.",

    ssn_title: "Submit Your SSN",
    ssn_des:
        "You need to submit your SSN/TIN before using Pionex.US. Your SSN will be only used to verify your identity, and we will not share your SSN with others without your permission unless required by law. Thanks for your support and understanding.",
    ssn_placeholder: "SSN",
    close_account_searching_asset: "The system is checking the balance and orders for your account, please try again later",
    close_account_success: "Deleted",
    close_account_check_asset_page_desc: "The following assets are still in your account. Please empty your account before deletion.",
    close_account_asset_withdraw_press_text: "Withdraw",
    close_account_asset_withdraw: "The balance is greater than {total}USDT. Please withdraw your assets before deletion.",
    close_account_asset_swap_small_balance_press_text: "Convert",
    close_account_asset_swap_small_balance: "Convert all small assets to USDT",
    close_account_asset_close_manual_trade_press_text: "Cancel",
    close_account_asset_close_manual_trade: "Please cancel all open orders in manual trading",
    close_account_asset_close_bots_press_text: "Close",
    close_account_asset_close_bots: "Please close all the bots",
    close_account_tips_sheet_desc:
        "The operation is irreversible. All the related data will be deleted and cannot be recovered after deletion. \nRe-sign up or enable the 2-Factor authentication with the  phone/email number is forbidden within 30 days after deletion.Please delete the account with caution.",
    close_account_tips_sheet_title: "Deleted account",
    close_account_tips_sure_button_text: "Confirmation",
    close_account_tips10: "The phone/email number cannot be used to enable the 2-Factor authentication for other accounts within 30 days after deletion.",
    close_account_tips9: "The account will auto log out after deletion.",
    close_account_tips8: "Please make sure that there are no open orders.",
    close_account_tips7: "Please make sure that there are no pending deposits/withdrawals.",
    close_account_tips6: "All the notification services from Pionex.US will become unavailable after deletion.",
    close_account_tips5: "Please make sure that all your assets have been transferred. After deletion, your assets can not be returned.",
    close_account_tips4: "The account and all your functions will be disabled.",
    close_account_tips3: "Re-verify with the account's verified personal information is forbidden within 30 days after deletion.",
    close_account_tips2: "Re-sign up with the account is forbidden within 30 days after deletion.",
    close_account_tips1: "All the related data will be deleted and cannot be recovered after deletion.",
    close_account_tips_page_desc: "Delete account",
    close_account: "Delete account",
    close_account_finance_withdraw_press_text: "Redeem",
    close_account_finance_withdraw: "Please redeem all the funds from earning products.",
    ga_bind_button_next: "Next step",
    verify_kyc_close_user_error: "Failed to verify. The 2 numbers don't match.",
    verify_kyc_input_tip_title: "Please fill in the unique code on your {type} ({value}) that you verified on Pionex.US.",
    close_user_verify_kyc_title: "To ensure the safety of your account, we need to verify your identity information before deleting the account.",
    verify_kyc_passport: "Passport",
    verify_kyc_id_card: "Government-issued ID",
    verify_kyc_driving_license: "Driving license",
    verify_kyc_input_placeholder: "ID number",
    code_verify_title: "Authentication",
    withdraw_submit: "Submit",
    trade_new_guide_title: "Switch trading mode",
    trade_new_guide_desc: "You can choose three trading modes (Bot/Manual/Swap) as you like here.",
    trade_new_guide_closeBtn_text: "Got it!",
    trade_market_select_pair: "You can select different trading pairs here.",
    trade_market_select_pair_title: "Search coin name",
    account_password_required: "The password is required",

    debit_result_faq: "What is 3D Secure (3DS) & FAQ?",

    account_locked_error_signIn: "Account frozen temporarily due to multiple incorrect password attempts. Retry after {time} mins.",
    credit_card_error_too_many_request: "Too many requests",
    withdraw_verify_code_sent_error: "The verification code was sent too fast, please try again in 1 minute",
    ga_reset_question_had_submitted: "The application has been submitted successfully and is under manually reviewing now.",
    account_user_not_exists: "Log in with this account is forbidden.",
    account_signUp_bind_3301_error: "It's not supported to use the phone number from this country/district.",
    account_locked_error: "Operating failed. The account has been frozen for {minutes} minutes. Please try again after auto-unfrozen.",
    hyperlink_expired: "The link is expired and exceeds the security authentication time. Please reaccess the link for security authentication.",
    data_has_been_used: "The disabled mobile phone/email address can’t be used to bind on Pionex again within {day} days",
    trade_error_unknown: "Unknown error. Please try again later or contact the support team.",
    swap_sell_available: "Available",
    swap_sell_placeholder: "Input quantity",
    swap_buy_balance: "Balance",
    swap_no_enough_balance: "Not enough balance",
    swap_exceed_max_limit: "Maximum quantity {max}",
    swap_exceed_min_limit: "Minimum quantity {min}",
    swap_sign: "Sign in to Convert",
    swap_continue_title: "Hint",
    swap_continue_button: "I know",
    swap_continue_content: "This pairs is suspended for trading. Please check the announcement.",

    security_verification: "Security Authentication",
    tip_send_verification_information_to_phone:
        'The current account exists risk-taking behavior. Please click the "Send Verification Code" button and receive the verification code from your mobile phone. In order not to affect your withdrawal, please complete the security authentication process within 5 minutes.',
    tip_send_verification_information_to_mailbox:
        'The current account exists risk-taking behavior. Please click the "Send Verification Code" button and receive the verification code from your mailbox. In order not to affect your withdrawal, please complete the security authentication process within 5 minutes.',
    accepted_mailbox: "Receiving Mailbox",
    accepted_phone: "Receiving Mobile Phone",
    send_verification_information: "Send Verification Code",
    switch_to_mailbox_reception: "Switch to receiving mailbox",
    switch_to_phone_reception: "Switch to receiving mobile phone",
    send_verification_information_to_phone: "Send verification code to mobile phone",
    send_verification_information_to_mailbox: "Send verification code to mailbox",

    face_verify_res_timeout: "Timed out, please try again.",
    face_verify_res_fail: "Verification error, please try again.",
    dw_coin_available_tips: "If available not equal to the total balance,",
    dw_coin_available_tips_more: "see here",

    deposit_3ds_modal_title: "Processing",
    share_tips: "World’s 1st exchange in-built trading bots",
    signature_deposit_subtitle2: "If someone else is instructing you to deposit with promises of free money, they are likely attempting to defraud you.",

    fm_staking_title: "Staking",
    fm_staking_apr: "APR",
    fm_staking_period: "Duration",
    fm_staking_period_day: "{day}D",
    fm_staking_dialog_title: "Stake",
    fm_redeem_dialog_title: "Select order to redeem",
    fm_staking_range: "Locked amount {min} ~ {max} {currency}",
    fm_staking_max: "Max",
    fm_staking_range_error: "Stake {min} ~ {max} {currency}",
    fm_staking_not_input_error: "Please input amount",
    fm_staking_success: "{action} successfully",
    fm_staking_failed: "Failed to {action}",
    fm_staking_left_quota: "Available quota",
    fm_staking_product_period: "Locked up period",
    fm_staking_agree_policy: "I have read and agree to the ",
    fm_staking_confirm_sheet_title: "{action} confirmed",
    fm_staking_confirm_amount: "Amount",
    fm_staking_confirm_start_time: "Rewards start date",
    fm_staking_confirm_end_time: "Due date",
    fm_staking_confirm_estimated_profit: "Rewards",
    fm_staking_confirm_profit_issue_time: "Rewards issuance",
    fm_staking_confirm_demand_profit_issue_time: "Paid hourly",
    fm_staking_confirm_redeem_fee: "fee",
    fm_staking_confirm_redeem_fee_desc:
        "No fee is needed for redeeming the flexible product or after the lock-up period. Redeem in advance will charge a fee of 50% of accruing rewards (At least {minPenalty}{currency}).",
    fm_staking_confirm_redeem_time_cost: "Redemption period",
    fm_staking_confirm_redeem_time_cost_desc:
        "The redeemed quantity will be returned immediately in most cases. However, in some extreme conditions, it will take about 24 hours to finish redemption. ",
    fm_staking_confirm_auto_restake: "Auto-stake",
    fm_staking_confirm_auto_restake_desc:
        "Automatically re-stake a product that has expired to its previous staking immediately. No lock-up period for auto-stake, and you will enjoy the same APR as the previous product before redemption.",
    fm_staking_enabled: "Enabled",
    fm_staking_disabled: "Disabled",
    fm_staking_balance_empty_hint: "You haven't staked {coin}, no history records.",
    fm_staking_early_unstake: "Redeem in advance",
    fm_staking_asset_currency_value: "Investment = {amount} {currency}",
    fm_staking_stake_detail_title: "{coin} Staking({product})",
    fm_staking_demand_deposit: "Flexible",
    fm_staking_fund_insuffient: "Failed to stake, not enough available quota",
    fm_redeem_sheet_remaining_days: "Remaining lock-up time",
    fm_redeem_sheet_times_up: "Expired",
    fm_redeem_sheet_left_days: "{day}D left",
    fm_redeem_total_profit: "Cumulative rewards",
    fm_redeem_amount: "Redeemable investment",
    fm_staking_staking_redeem: "Redemption",
    fm_staking_next_issue_time_diff: "{day}D {hour}H {minute}min {second}s",
    fm_staking_extra_profit: "Extra rewards",
    fm_staking_claim_failed: "Release failed. Please try again later",
    fm_redeem_no_redeemable_asset: "No redeemable investment",
    fm_staking_demand_effective_time: "Immediately",
    fm_staking_demand_maturity: "No lock-up period",
    fm_redeem_sheet_left_hours: "{hour}H left",
    fm_redeem_sheet_almost_times_up: "Due soon",
    fm_staking_issue_profit_too_small: "Rewards accumulating",
    fm_staking_agreement: "Pionex Staking Agreement",
    fm_staking_insufficient: "Not enough balance({constraint})",
    fm_arbitrage_detail_profit_withdrawn: "The releasable rewards",
    fm_arbitrage_un_invest: "Haven't invested",
    fm_arbitrage_release_interests: "Release rewards",
    fm_arbitrage_24_APR: "Yesterday's APR",
    fm_arbitrage_forbid_des: "Investments and redemptions for lending are closed during {time1}-{time2} daily.",
    fm_arbitrage_detail_title: "{base} lending",
    fm_arbitrage_24_profit: "Yesterday's rewards",
    fm_arbitrage_history_profit: "Cumulative rewards",
    fm_arbitrage_invest: "Invest",
    fm_arbitrage_redeem: "Redeem",
    fm_arbitrage_detail_interests: "rewards",
    fm_arbitrage_invest_redeem: "Transfer",
    fm_arbitrage_detail_empty: "You haven't invest {base} lending yet.",
    fm_arbitrage_detail_tip:
        "Rewards will be paid out to your earning account directly but will not re-invest. Please release the rewards as soon as possible. The released funds will be the tradable funds in your trading account.",
    fm_arbitrage_rule_des: "Tap the cards below to earn daily rewards on your idle coins by lending. There are no lock-up period for lending and the rewards paid out daily.",
    fm_arbitrage_redeem_max: "Redeemable ≤{max}",
    fm_arbitrage_redeem_min: "Redeemable ≥{min}",
    fm_arbitrage_invest_success: "Invested",
    fm_arbitrage_redeem_success: "Redeemed",
    fm_arbitrage_grant_interest: "Rewards",
    fm_arbitrage_collect_interest: "Release rewards",
    fm_arbitrage_next_interest: "Est rewards",
    fm_arbitrage_invest_trade: "Invest",
    fm_arbitrage_redeem_trade: "Redeem",
    fm_arbitrage_release_interests_all: "Release all rewards",
    fm_arbitrage_release_interests_all_des:
        "Release interests from multiple lending products once at a time. The rewards will be released to your trading account as tradable funds.",
    fm_arbitrage_release_success_toast: "Released {success}/{total} coins",
    fm_arbitrage_changed: '"Arbitrage" upgrade to "Earn"',
    tabBar_arbitrage_1: "Earn",
    fm_arbitrage_error_40020300: "Investments and redemptions for the coin are temporally closed.",
    fm_arbitrage_profit_error_40021102: "Not enough releasable rewards",
    fm_arbitrage_invest_error_40021102: "Not enough available balance",
    fm_arbitrage_redeem_error_40021102: "Not enough redeemable balance",
    fm_arbitrage_invest_error_40021103: "No available investment quota",
    fm_arbitrage_redeem_error_40021103: "No available redemption quota",
    fm_arbitrage_error_40020901: "Investments and redemptions are closed during settlement periods.",
    fm_arbitrage_release_interests_success: "Released",
    fm_arbitrage_release_interests_failed: "Release failed",
    fm_arbitrage_release_interests_none: "No releasable rewards",
    fm_arbitrage_invest_input_toast: "Please input the investment amount",
    fm_arbitrage_redeem_input_toast: "Please input the redemption amount",
    fm_arbitrage_forbid_invest_des: "Investments are closed during {time1}-{time2}.",
    fm_arbitrage_forbid_redeem_des: "Redemptions are closed during {time1}-{time2}.",
    fm_arbitrage_withdraw_error: "No releasable rewards",
    fm_arbitrage_expect_profit_desc: "Accrue rewards from tomorrow",
    fm_staking_operate_account_insufficient_balance:
        "The total remaining quota for fast redemption is insufficient. We are now withdrawing from the pool. Please try again after several hours.",
    fm_staking_warning_in_china: "Sorry that the Staking service is unavailable to citizens in your country/area.",
    time_left: "{time} left",
    fm_staking_confirm_daily_profit_issue_time: "{date}，paid daily",
    fm_staking_extra_apr_expired_in_days: "Extra rewards will be expired in {day}D",
    fm_staking_extra_apr_expired_in_hours: "Extra rewards will be expired in {hour}H",
    fm_staking_extra_apr_expired_soon: "Extra rewards will be expired soon",
    redeem: "Redeem",
    fm_staking_repeat_enabled: "Auto-staking enabled",
    fm_staking_repeat_disabled: "Auto-staking disabled",
    fm_staking_repeat_setting: "Auto-staking",
    fm_staking_repeat_setting_error_msg: "Operation failed. Please try again 2 minutes later.",
    fm_staking_terms_url: "https://www.pionex.us/blog/pionex-us-staking-service-reminders/",
    fm_staking_my_staking: "My staking",
    fm_staking_stake_title: "{coin} Staking",
    fm_staking_redeem_title: "Redeem {coin}({duration})",
    fm_staking_stake_invest_amount_range: "Available quota",
    fm_staking_claim_success_title: "Rewards released",
    fm_staking_claim_success_content: "The {amount} rewards have been released to your trading account.",
    fm_staking_read_terms: "I have read and agree to the {terms}",
    fm_staking_terms: "《{app} Staking Agreement》",
    fm_staking_read_terms_error: "Please click the check box to agree to the Pionex Staking Agreement after finishing reading",
    staking_take_profit: "Released rewards",
    kol_login_modal_ok_text: "Sign in",
    create_order_failed: "Failed to create order",
    create_order_failed_not_serve_ip:
        "It is detected that you are may be a {region} user. According to the local compliance requirements, {app} cannot provide services to users in {region}. You can now close orders and withdraw coins normally, but other functions will be limited. If you are not a {region} user, you can get rid of this restriction by completing KYC.",
    // create_order_failed_us_ip:
    //     "It is recognized that your IP is located in {region}. Due to local regulations, certain cryptocurrency or trading tools are no longer available to you. Existing orders and sales will not be affected. If you are a U.S. user, you need to migrate to Pionex.US to use in the future. For non-U.S. users, please complete our KYC procedures. Restrictions will be removed upon review by {app}.{restricted_range}",
    create_order_failed_us_ip:
        "It is detected that your IP is in the {region}. According to the local compliance requirements, some coins or trading tools are not allowed for you. Running orders and token sales will not be affected. If you have any questions, please ask customer service for help.{restricted_range}",
    create_order_failed_us_ip_restricted_range: "Tap here to view details of restrictions.",
    // create_order_failed_us_kyc:
    // "It seems you might be a U.S. user. Due to local regulations, certain cryptocurrency or trading tools are no longer available to you. Existing orders and sales will not be affected. You need to migrate to Pionex.US to use in the future. If you have any questions, please ask customer service for help.{restricted_range}",
    create_order_failed_us_kyc:
        "It is detected that your KYC is in the United States. According to the local compliance requirements, some coins or trading tools are not allowed for you. Running orders and token sales will not be affected. If you have any questions, please ask customer service for help.{restricted_range}",
    faq_recommended: "Recommended for You",
    faq_recommended_q1_label: "No available funds to withdraw",
    faq_recommended_q1_url: "https://pionexus.zendesk.com/hc/en-us/articles/10646650478489",
    faq_recommended_q2_label: "How to reset google authenticator",
    faq_recommended_q2_url: "https://pionexus.zendesk.com/hc/en-us/articles/10078951453977",
    faq_recommended_q3_label: "Invalid google verification code",
    faq_recommended_q3_url: "https://pionexus.zendesk.com/hc/en-us/articles/10078485746585",
    faq_recommended_q4_label: `"Incorrect password" error message`,
    faq_recommended_q4_url: "https://pionexus.zendesk.com/hc/en-us/articles/9757048183833-What-to-do-if-the-password-is-wrong",
    faq_recommended_q5_label: "No available funds to create bots",
    faq_recommended_q5_url: "https://pionexus.zendesk.com/hc/en-us/articles/10646978340633",
    faq_recommended_q6_label: `What does "Release profit" mean`,
    faq_recommended_q6_url: "https://pionexus.zendesk.com/hc/en-us/articles/10455981259929",
    faq_recommended_q7_label: "3DS verification failed",
    faq_recommended_q7_url: "https://pionexus.zendesk.com/hc/en-us/articles/10648260374041",
    faq_recommended_q8_label: "Adding funds to running bots",
    faq_recommended_q8_url: "https://pionexus.zendesk.com/hc/en-us/articles/11391908863513-How-do-I-add-funds-to-the-running-grid-bot-",
    faq_recommended_q9_label: "How to bind google authenticator",
    faq_recommended_q9_url: "https://pionexus.zendesk.com/hc/en-us/articles/9786194607513",
    customer_service_center: "Customer Support",
    customer_service_manual: "Live Support",
    faq_desc: "Check the FAQ below for an instant solution. If the FAQ doesn't solve your problem, please click the button below to contact our online support team.",
    faq_category_account: "Account functions",
    faq_category_dw: "Crypto/Fiat deposit and withdraw",
    faq_category_asset: "Assets",
    faq_category_other: "Others",
    faq_account_reset_2fa: "Reset Google authenticator",
    faq_account_download_txs: "Download transaction history",
    faq_account_vip: "VIP/Referral program",
    faq_dw_withdraw: "Tutorial for withdrawal",
    faq_dw_deposit: "Tutorial for deposit",
    faq_dw_deposit_credit_card: "Tutorial for credit card deposit",
    faq_dw_deposit_progress: "Check the status of deposit",
    faq_dw_withdraw_progress: "Check the status of withdrawal",
    faq_dw_deposit_not_arrived: "Deposit not arrived?",
    faq_asset_view_assets: "Tutorial for checking assets",
    faq_asset_frozen: 'What is "Occupation status"?',
    faq_other_cant_create_bot: "Failed to create a bot",
    faq_other_bot_error: "The bot closed because an error occurred?",
    faq_account_reset_2fa_url: "https://pionex.zendesk.com/hc/en-us/articles/8251933706521-How-to-reset-Google-Authenticator-Google-2FA-",
    faq_account_download_txs_url: "https://pionex.zendesk.com/hc/en-us/articles/8285283486105-How-to-download-Transaction-History-Statement-App-",
    faq_account_vip_url: "https://pionex.zendesk.com/hc/en-us/articles/900006821623--The-trading-pairs-included-in-the-VIP-program-commission-rebate",
    faq_dw_withdraw_url: "https://pionex.zendesk.com/hc/en-us/articles/4407439202585-How-to-Withdraw-from-Pionex-Web-",
    faq_dw_deposit_url: "https://pionex.zendesk.com/hc/en-us/articles/4408214735641-How-to-Deposit-Crypto-Web-",
    faq_dw_deposit_credit_card_url: "https://pionex.zendesk.com/hc/en-us/articles/8282879767705-Buy-Crytpo-with-a-Credit-Card-Simplex",
    faq_dw_deposit_progress_url: "https://pionex.zendesk.com/hc/en-us/articles/4407279199001",
    faq_dw_withdraw_progress_url: "https://pionex.zendesk.com/hc/en-us/articles/4407410122905-How-to-view-Withdrawal-status-from-Pionex",
    faq_dw_deposit_not_arrived_url: "https://pionex.zendesk.com/hc/en-us/articles/8254518723481-Deposit-has-not-arrived-",
    faq_asset_view_assets_url: "https://pionex.zendesk.com/hc/en-us/articles/8376303184537-How-to-view-account-assets",
    faq_asset_frozen_url: "https://pionex.zendesk.com/hc/en-us/articles/4407669982233-Why-is-my-assets-have-been-frozen-occupied-",
    faq_other_cant_create_bot_url: "https://pionex.zendesk.com/hc/en-us/articles/8316453284889-Why-can-t-I-create-a-trading-bot-",
    faq_other_bot_error_url: "https://pionex.zendesk.com/hc/en-us/articles/8313896429849-How-to-solve-Bots-error-",
    zendesk_visitor_input_contact: "Please enter your email\nto start the online chat",
    zendesk_sign_title: "Sign in and get better services",
    zendesk_sign_desc: "Please enter your email to start the online chat",
    zendesk_sign_button: "Sign in (Recommended)",
    zendesk_visitor_button: "Continue as guest",
    zendesk_visitor_place_holder_contact: "Please enter email",
    win1btc_title: "Win 1 {coin}",
    win1btc_btc: "Bitcoin",
    win1btc_sub_title: "with Bitcoin Growth Trading Bot",
    win1btc_event: "EVENT",
    win1btc_event_end: "Event ends in",
    win1btc_days: "Days",
    win1btc_tickets: "Tickets",
    win1btc_my_tickets: "My Tickets",
    win1btc_global_tickets: "Global Tickets",
    win1btc_list_title: "Winner List - {month}",
    win1btc_email_tip: "If you have any questions, please email to",
    win1btc_email_url: "Service@pionex.us",
    win1btc_get_more_title: "Get more {tickets} to win",
    win1btc_complete: "Complete",
    win1btc_get_num_tickets: "Get {num}",
    win1btc_ticket: "Ticket",
    win1btc_user: "user",
    win1btc_users: "users",
    win1btc_winnner_subtitle: "{users} winning {amount} Bitcoin",
    win1btc_month_1: "JANUARY",
    win1btc_month_2: "FEBRUARY",
    win1btc_month_3: "MARCH",
    win1btc_month_4: "APRIL",
    win1btc_month_5: "MAY",
    win1btc_month_6: "JUNE",
    win1btc_month_7: "JULY",
    win1btc_month_8: "AUGUST",
    win1btc_month_9: "SEPTEMBER",
    win1btc_month_10: "OCTOBER",
    win1btc_month_11: "NOVEMBER",
    win1btc_month_12: "DECEMBER",
    win1btc_coming_soon: "Coming Soon",
    win1btc_tickets_desc: "The more Tickets you got, {enhance}",
    win1btc_tickets_desc_enhance: "the higher chance you win",
    trade_order_book_all: "Order Book",
    trade_order_book_sell: "Sell Order",
    trade_order_book_buy: "Buy Order",
    order_title_total: "Total",
    order_title_total_avg: "Avg.Price:",
    order_title_total_sum: "Sum {base}:",

    debit_waiting_sumsub: "Waiting for confirmation...",

    "bind_checkout_error:card-number": "Only visa or mastercard supported",
    "bind_checkout_error:expiry-date": "Please enter a valid expiry date",
    "bind_checkout_error:cvv": "Please enter a valid cvv",
    deposit_amount_insufficient_limit: "Insufficient available limit",
    debit_card_channel_maintenance: "Maintenance",
    otp_code_error_tip_url: "https://www.pionex.us/blog/how-to-solve-2fa-code-error/",
    verify_kyc_placeholder_1: "Please fill in the Identity Number",
    verify_kyc_placeholder: "Please fill in the information of {type}：{num} that you verified on Pionex for identity verification.",
    sms_verify_code_error: "Wrong SMS code",
    voice_verify_code_error: "Wrong voice code",
    email_verify_code_error: "Wrong email code",
    otp_code_error_tip_title: "Google 2FA error?",
    account_user_not_exists_1: "Account has not been registered, please sign up first.",
    password_at_least: "The password should be at least {number} characters with both mumbers and letters.",
    password_cannot_exceed: "The password cannot exceed {number} characters with both mumbers and letters.",
    password_cannot_exceed_number: "The password cannot exceed {number} characters.",
    password_contain_letters_and_numbers: "The password with both mumbers and letters.",

    sign_failed_title: "Failed to sign in",
    sign_failed_btn: "Sign in again",

    "debit_deposit_error:TOO_MUCH_ORDERS": "Too many attempts in 24 hrs",

    order_canceled_by_admin_cancel_title: "Cancelled by system",
    ach_joint_title: "Seems to be a joint account, further verification is required",
    ach_joint_first_title: "Is this account a joint account?",
    ach_joint_first_title_yes: "Yes",
    ach_joint_first_title_no: "No",
    ach_joint_second_title: "Document Required",
    ach_joint_second_content: "Please provide the government-issued ID of the other joint account holders.",
    ach_joint_second_example: "For example:",
    ach_joint_second_example_item1: "Driver License",
    ach_joint_second_example_item2: "State ID",
    ach_joint_second_content_no:
        "Please provide your bank account ownership certificate, confirming that your bank account is registered in your name, along with relevant bank information.",
    ach_joint_second_content_pending_title: "Pending Review",
    ach_joint_second_content_pending_content: "This account is pending review. You will be able to use it to deposit once it is approved. Until then, please do not remove it.",
    ach_joint_upload_size: "Please note that we only support png, jpeg, jpg, pdf and file size must be within 10KB - 10 MB.",
    ach_joint_upload_tips: "If you can’t provide any documents, please change to connect another account.",
    win1btc_event_sign_tip: "Sign in to Check My Progress",

    kyc_jumio_s1_doc_tips: "You need to upload this document in the next step",

    account_need_to_bind: "Your account’s security level is too low, please bind {type} or Google Authenticator with your account to enable the withdrawal.",
    to_bind_email: "Bind Email",
    to_bind_phone: "Bind Phone",
    to_bind_google: "Bind Google Authenticator",
    email_address: "email address",
    mobile_phone_number: "mobile phone number",
    manual_order_state: "Order Status",
    manual_order_export: "Export",
    manual_order_placed_menu_title: "Placed orders",
    martingale_error_unrealized_stop_loss_percentage_min: "Stop loss percentage should ＞{min}",
    martingale_error_stop_loss_percentage_min: "Stop-loss percentage should be less than {min}",
    grid_adjust_params_action: "Edit price range",
    unrealized_profit_no_records: "You don't have any records yet.",
    btd_reinevst_target_price_tip: "Reinvested strike will be set with price at {delta} drop in settlement price",
    gc_reinevst_target_price_tip: "Reinvested strike will be set with price at {delta} rise in settlement price",
    manual_history_canceled: "Canceled",
    manual_history_filled: "Filled",
    manual_history_all: "All",
    grid_pro_line_transactions: "Transactions:",
    chart_title2: "Paused / Total txs: {count2}",
    grid_detail_stop_order: "The bot has been stopped.",
    grid_transaction: "Transactions",
    future_position_leverage: "Leverage",
    fsa_params_next_reduce_position_price: "Next auto-deleverage price",
    smart_trade_average_pre_selling_price: "Est sell price",
    smart_trade_average_pre_buying_price: "Est buy price",
    grid_placed_buy_price: "Buy price",
    grid_share_aip_slogan: "I use TWAP Bot",
    order_cancel_order_title: "Confirm to cancel order",
    grid_pro_closed: "closed",
    sr_close_hold_coin_1: "Balance when closing",
    trade_order_error_tip: "The order ran abnormally.",
    trade_order_error_tip_link_text: "Click for details",
    grid_detail_dialog_title_allocation: "Allocation of {coin}",
    trade_order_category_amount: "Amount",
    grid_detail_dialog_total_balance: "Total",
    grid_detail_profit_fluctuation: "Reserve fund",
    grid_pro_line_date: "Date:",
    profit: "Profit",
    grid_hold_detail: "Details",
    grid_params_detail: "Parameters",
    grid_transaction_avg: "Avg txs/day",
    trading_bot_buy_and_sell_cycle_title: "Flying wheel strategy",
    trading_bot_buy_and_sell_cycle_des: "Use put option based Buy-the-Dip and call option based Covered Gain to arbitrage by auto-reinvestment.",
    trading_bot_bds_target_price: "Please Enter the Target Buy Price",
    trading_bot_bds_target_sell_price: "Sell at the buy price of Buy-the-Dip",
    trading_bot_bds_target_price_sell: "Please Enter the Target Sell Price",
    tarding_bot_bds_common_mode_tips_1:
        "Note： Buy at the target buy price, will keep earning interest before the price drops to that level at settlement. After buying the crypto, sell them at the buy price, will earn interest before cryptos are sold.",
    tarding_bot_bds_common_mode_tips_2:
        "Note： Buy at the target buy price, will keep earning interest before the price drops to that level at settlement. After buying the crypto, sell them at the sell price, will earn interest before cryptos are sold.",
    tarding_bot_bds_common_mode_tips_3: "Tips: Interest is earned from options premium",
    trading_bot_btd_cg_bot: "Flying wheel strategy",
    trading_bot_buy_and_sell_closed_by_no_products_reason: "Could not find products",
    trading_bot_btd_cg_closed_for_no_products:
        "Could not find products that match with the auto-investment rule within 30 minutes after the last settlement, the bot has been automatically closed.",
    trading_bot_buy_and_sell_closed_by_user_reason: "Manually close",
    trading_bot_buy_and_sell_closed_by_user_tip: "Flying wheel strategy has been closed, the settlement information is as follows:",
    all_orders: "All orders",
    order_overview: "Overview",
    grid_position_add_position_des_1: "As the prices fluctuate wildly while adding investment, some previous {quote} holdings has been used to buy {base}.",
    grid_position_add_position_des_2: "As the prices fluctuate wildly while adding investment, the bot has to sell some {base} and realized the profit for these holdings.",
    grid_position_close: "Close",
    grid_position_trans_in: "Invested",
    grid_position_add_position: "Add investment",
    grid_position_adjust_position: "Edit price range",
    grid_pro_profit_add_position: "Profit after adding investment",
    trade_sell_quantity: "Sell quantity",
    grid_position_add_position_quote_amount: "Reserved {quote} amount",
    grid_pro_profit_add_position_des: "Some sell orders are filled and generated profit due to the high volatility during adding investment.",
    table_title_buysSellsNumber: "Quantity",
    table_title_avgPrice: "Average price",
    grid_position_add_position_des_3: "This is the price at which you started the bot.",
    search_phone_areacode: "Select area code",
    grid_add_position_des:
        "Note: Your added investment will be used to immediately buy coins with the market price.\nYour profit ratio will be re-calculated based on the total amounts after adding investment.",
    grid_position_change_record: "Balance change history",
    grid_add_position_step1: "1/2 Buying {base}...\n The bot will buy {base} using TWAP to reduce slippage",
    grid_add_position_step2: "2/2 placing orders {current}/{total}...",
    profit_release_bottom_1:
        "Release a part of your grid profits, or funds that are not used in the bot because of precision. The released funds will be the tradable funds in your spot account.",
    twap_buy_progress_completed_1: "Bought",
    grid_detail_dialog_total_holding: "Total holding",
    grid_detail_dialog_net_price_content:
        "It's your average buying cost after buying low and selling high. You won't have any loss as long as the current price is higher than breakeven price. If it is negative, it means you won't lose even if you sell the remained coins with a negative price. (it is opposite when using reverse or short bots)",
    grid_set_stop_loss_delay_bottom_text_1: "When the coin price hits {price}, the bot will sell all the coins immediately.",
    grid_set_stop_profit_delay_bottom_text_1: "When the coin price hits {price}, the bot will sell all the coins immediately.",
    grid_set_stop_loss_delay_bottom_text:
        "Stop loss delay will prevent the bot from closing immediately due to erroneous candle wicks.\nWhen the coin price hits {price}, a {delay} seconds timer activates. The bot will stop only if the price doesn't recover before the timer expires.",
    grid_set_stop_profit_delay_bottom_text:
        "When the coin price hits {price}, a {delay} seconds timer activates. The bot will stop only if the price doesn't fall below the take profit price when the timer expires.",
    grid_set_spl_delay_value: "Delay time (sec, 5-30)",
    grid_set_stop_loss_delay_title: "Stop loss delay",
    grid_set_stop_profit_delay_title: "Take profit delay",
    grid_v3_pnl_while_change_profit_stop_des:
        "The follow-up grid profit and the actual trading price when taking profit will cause a large difference between the estimated PnL and the actual one.",
    grid_v3_pnl_while_change_loss_stop_des:
        "The follow-up grid profit and the actual trading price when stopping loss will cause a large difference between the estimated PnL and the actual one.",
    grid_order_high: "Upper limit",
    sr_take_profit_percentage_tip: "Tips : Your coins will be all sold when your total profit hits that percentage , and the bot will be closed",
    future_position_close_predict_pnl: "Estimated profit",
    grid_adjust_params_step1: "1/3 Cancelling orders...",
    grid_adjust_params_step2: "2/3 Trading {base}...\nThe bot will buy or sell {base} using TWAP to reduce slippage",
    grid_adjust_params_step3: "3/3 Placing orders... \n{current} orders have been placed, {total} orders remain...",
    grid_position_trans_in_adjust_params: "Adding investment ",
    grid_pro_unrealized_profit_des: "The PnL includes the unrealized PnL resulting from current holdings and the profit realized when editing parameters.",
    grid_pro_unrealized_profit_summation_detail: "Historical Realized PnL",
    grid_pro_unrealized_profit_current: "Unrealized PnL",
    grid_pro_unrealized_profit_summation_detail_des:
        'After editing price range, the unrealized profit before editing will be realized and recorded in the "Historical realized PnL"',
    grid_pro_unrealized_profit_to_position_record: "Check the edited history",
    grid_pro_unrealized_profit_history: "Realized PnL",
    grid_adjust_des_1: "The bot buys some {base} with the previous holding {quote} to meet the requirement of the new parameters.",
    grid_position_adjust_params_des: "No tradings during the editing.",
    grid_adjust_params_bottom_des: "You need to invest additional {amount} {quote} to meet the minimum limit of the new parameters.",
    grid_adjust_params_add_position_needed_des:
        "The new parameter requires at least {count} {quote} to operate. The edit is unavailable because the balance in your account is not enough.",
    grid_adjust_params_trading_params_equal: "Same parameters as the original. No need to edit.",
    grid_adjust_params_add_position_action_des: "Hint: Increasing investment is required for the minimum limit of the new parameters.",
    grid_adjust_params_add_position_needed: "Increasing investment is required",
    grid_adjust_params_add_position_confirm: "Add",
    grid_adjust_params_add_position_action: "Further invest needed",
    grid_position_open_position_quote_amount: "Reserved {quote} amount",
    grid_adjust_params_buy_in_base_amount: "Expected buy amount of {base}",
    grid_adjust_params_add_position_expected: "Will increase investment",
    grid_pro_price_interval: "Price range",
    grid_adjust_params_confirm_base_buy: "The bot will buy about {amount} {base} after editing the parameters.",
    grid_adjust_params_advise_type1_des:
        "The edit will cause loss. According to the new parameters, the bot will sell {baseAmount} {base} immediately which will cause around {quoteAmount} {quote} loss. ",
    trade_upper_price: "Upper limit",
    trade_lower_price: "Lower limit",
    twap_transactions: "Transactions",
    twap_perpetual_mode: "Perpetual mode",
    twap_fix_number_mode: "Limited mode",
    twap_sell_next_time: "Next sell time",
    twap_buy_next_invest_amount: "Next buy quantity",
    twap_sell_next_invest_amount: "Next sell quantity",
    twap_buy_current_traded_count: "Transactions",
    twap_sell_current_traded_count: "Transactions",
    twap_sell_next_invest_1: "Sold value",
    twap_buy_next_invest_1: "Bought quantity",
    sold_quantity: "Sold quantity",
    twap_sell_progress_completed_1: "Sold",
    aipcard_buy_amount: "Bought quantity",
    twap_buy_next_time: "Next buy time",
    twap_detail_header_buy_des: "Because of precision limit , we may buy a bit less or more than {count} {coin} each time",
    market_page_type_leverage: "Leveraged",
    market_type_spot: "Spot",
    account_trading_fee_maker: "Maker: {maker}%",
    account_trading_fee_taker: "Taker: {taker}%",
    pionex_vip_mm_title_desc: "market maker",
    kyc_level_drawing_amount_per_day: "Maximum daily withdrawal: {amount}",
    kyc_level_drawing_amount_per_day_desc: "<tooltip>Maximum daily withdrawal</tooltip> is different under different KYC levels. Rules are as follows：",
    view_all_vip_level_fees: "View all VIP fees",
    trade_enter: "Confirm",
    price_range_check_box_title: "I confirm that my price range is correct",
    price_range_check_price_range: "Edited price range",
    trade_trigger_price: "Trigger price",
    price_range_check_title: "Abnormal price range",
    grid_adjust_params_advise_newly_increased_grid: "Additional grids",
    grid_adjust_params_advise_origin_grid: "Original grids",
    grid_adjust_params_advise_answer:
        "Recommend parameters will keep the original grids and add the additional grids below the current gap with your further increased investment.\n Your {base} will not be sold because the original grids are retained.",
    grid_adjust_params_advise_why: 'Why you need "Recommended parameters"?',
    grid_adjust_params_advise_type2_des: "Avoid selling {base} by increasing investment.",
    grid_adjust_params_advise_type2: "Use recommended parameters",
    grid_adjust_params_advise_row: "grids",
    grid_adjust_params_advise_type1: "Remain my settings",
    grid_adjust_params_advise_count_max: " Max",
    grid_adjust_params_advise_type1_des_2:
        "The edit might cause loss. The upper limit of the new parameters is too low, which might cause {amount} {quote} loss when the bot sell the current {count} {baseDisplay} holdings.",
    grid_adjust_params_advise_title: "Recommendation",
    parameters: "Parameters",
    grid_adjust_params_advise_confirm: "Adopt",
    grid_adjust_params_confirm_adjust_after: "Parameters after editing",
    grid_adjust_params_confirm_base_sell: " The bot will sell about {amount} {base} after editing the parameters. ",
    grid_adjust_params_confirm_base_percent_2: "Proportion of {base}(After editing)",
    grid_adjust_params_confirm_base_percent_1: "Proportion of {base}(Before editing)",
    grid_adjust_params_confirm_invest_not_enough: "Edit failed. The fund available doesn't meet the requirement of the minimum investment for the new parameters.",
    grid_position_adjust_params_success: "Start editing",
    grid_adjust_params_grid_count_tip1: "The upper limit should be greater than the lower limit",
    grid_adjust_params_grid_count_tip2: "Grids(Required≥2)",
    grid_adjust_params_grid_count_tip3: "Grids(Required≤{count})",
    grid_adjust_params_grid_count_tip4: "The profit per grid (Required≥0)",
    grid_adjust_params_forbidden: "Edit unavailable. Editing price range is not allowed while the bot is paused.",
    bot_order_cancel_reason_title: "Cancelation",
    statistic_total_profit: "Total Profit",
    dual_auto_buy_agv_price: "Avg. price for buy",
    auto_order: "Auto orders",
    no_cap_on_reward: "With no cap on rewards",
    send_experience_money: "Send trial fund",
    playingMethods: "Introduction to activity",
    Friends_open_official_orders_through_their_own_funds: "You can receive additional 12,888 USDT trial funds after your friends invest with their funds.",
    invite_text_usdt: " invite you to try Pionex. Pionex will give you a 1288 USDT dual investment trial fund after registration!",

    order_bot_bds_advanced_confirm_sell_rate: "Sell crypto at price rise",
    trading_bot_bds_advanced_mode_sell_price1: "Sell at buy price",
    dual_invest_auto_invest_title: "Auto re-invest",
    trading_lowest_tolerance_apy: "Lowest tolerance APY",
    trading_longest_settlement_period: "Longest tolerance settlement period(Days)",
    signal_timeline_open: "Open",
    signal_timeline_close: "Stop",
    tarding_bot_bds_advanced_mode_buy: "Buy low at percentage price drop",
    tarding_bot_bds_advanced_mode_sell: "Sell high at percentage price rise",
    tarding_bot_bds_advanced_mode_product_estimate: "Product Estimation",
    dual_invest_polyline_choose_days_item_today: "Today",
    trading_bot_bds_advanced_no_product: "Could not find a product that matches with current parameters, please modify the percentage price drop.",
    trading_bot_btd_cg_bot_cg_interest: "Interest of Covered Gain",
    trading_bot_btd_cg_advance_tips: "Pro Mode will automatically match the best product without manual selection",
    trade_create_init_sell_avg_price: "Initial avg sell price",
    trade_create_init_buy_base_avg_price: "Initial avg buy price",
    trade_create_init_sell_base: "Initial sell quantity",
    trade_create_init_buy_base: "Initial buy quantity",
    grid_transaction_detail_desc: "One transaction means one buy and one sell. Grid Trading Bot helps to 7/24 auto buy low and sell high in the range you set.",
    trade_pledge_invest: "Margin",
    interest_total_title: "Total",
    interest_empty: "No interest charged yet",
    trading_selector_no_base_toast: "Please choose the coin to buy, then retry.",
    tarding_bot_bds_advanced_mode_no_buy_min: "Please enter the percentage price drop.",
    tarding_bot_bds_advanced_mode_no_sell_min: "Please enter the percentage buy price rise.",
    trading_bot_bds_advanced_mode_search_no_product: "There is no product at this time, please modify the parameters to filter products.",
    trading_bot_bds_target_sell_price_title: "Sell at Buy Price",
    trading_bot_bds_advanced_mode_sell_price2: "The buy price rise",
    tarding_bot_bds_advanced_mode_tips_1:
        "Note: Buy crypto at the percentage price drop, will keep earning interest before successfully buying the dip. When the crypto is bought at the bottom price, sell them at the buy price, will keep earning interest before cryptos are sold.",
    tarding_bot_bds_advanced_mode_tips_2:
        "Note: Buy crypto at the percentage price drop, will keep earning interest before successfully buying the dip. When the crypto is bought at the bottom price, sell them at percentage buy price rise, will keep earning interest before cryptos are sold.",
    order_bot_bds_confirm_protol_title: "I have fully understood the benefits and risks of Flying wheel strategy.",
    current_state: "Current status",
    swap_input_tip: "Please enter quantity",
    swap_input_range: "Quantity ({range})",
    swap_order_by_cap: "Order by market cap",
    swap_order_by_name: "Order by coin name",
    swap_order_by_balance: "Order by available balance",
    transaction_auto_buy_avg_price: "Avg bought price when stopped",
    transaction_auto_buy_amount: "Bought amount when stopped",
    transaction_auto_sold_avg_price: "Avg sold price when stopped",
    transaction_auto_sold_amount: "Sold amount when stopped",
    grid_position_close_quote_amount: "Previous {quote} holdings",
    grid_position_trans_out: "Got",
    grid_position_close_price: "Price when closing",
    grid_position_close_des_2: 'Choose "Don\'t sell" when closing the bot, you can check the fund in the trading account.',
    grid_position_close_des_1_1: "Unable to sell the remaining {amount} {base}.",
    all_order_cancel: "Cancel all",
    all_order_cancel_coin_text: "Coin",
    all_order_cancel_ok: "Confirm.",
    all_order_cancel_radio_all: "Cancel all {text} manual orders.",
    all_order_cancel_radio_buy: "Only cancel all {text} manual buy orders.",
    all_order_cancel_radio_sell: "Only cancel all {text} manual sell orders.",
    trade_layout_guide_tip:
        "You can adjust the position of the trading module here, choose if you want to display the Order book and the Market trades. You can also choose the style of the coin search bar.",
    trade_layout_guide_title: "Layout settings",
    reserve_asset: "Asset",
    market_search_placeholder_mini: "Search",
    twap_order_combine: "TWAP",
    aip_order_combine: "Recurring Buy",
    grid_transaction_bottom_tip_1:
        "This buy order is the initial buy order after creating/adding investment/editing price range. The buy price is the initial buy price or the avg price after the operation. So the buy price is low, the profit is higher and the fee is not calculated separately.",
    mtg_add_and_withdraw_history_empty_text: "You haven't added or withdrawn any investment",
    mtg_round_renew_tip: "This round was terminated because of renewing.",
    martingale_error_action_not_in_range: "Failed to operate, the current price is out of the price range.",
    trade_frozen_quantity_tip:
        "If you have bots or swap orders using this coin, your assets in bots or limit orders won't be tradable until the bots are closed or the orders executed.",
    grid_error_grid_too_more: "Too many grids. Required ≤{max} at the current price range.",
    order_cancel_error: "Operation failed, the order has been cancelled",

    fbo_addCard_aba_routing: "ABA routing number",
    fbo_addCard_transfer_type_wire: "Wire Transfer",
    fbo_addCard_transfer_type_ach: "ACH",
    fbo_addCard_transfer_type: "Please select your withdrawal method (Wire or ACH)",

    "usddw_nokyc_title:deposit": "Please verify your identity before depositing.",
    "usddw_nokyc_title:withdraw": "Please verify your identity before withdrawal.",
    taxes_share_info: "<agreement> Share </agreement> to get Tax Report ${free} worth with No Charge (for {freeTaxYear} tax season)",
    sign_up_in_for_taxes: "You need to sign in first.",
    nav_taxes: "Taxes",
    taxes_disclaimer: "Please read through the <agreement> Disclaimer</agreement> before downloading.",
    taxes_vip: "Tax consulting webinar for VIP users",
    taxes_vip_modal_title: "1 on 1 tax consultation only for VIP users",
    taxes_new_user_title: " New user , no tax report yet ?",
    taxes_completed: "Sent successfully",
    taxes_completed_desc: "Reports have been downloaded to your computer, please check",
    taxes_progress_tips: "Pioenx.US is working hard on your trasaction data, estimated to be completed in 5 seconds.",
    taxes_modal_content_tips1: "1. Invitees need to complete the identity verification.",
    taxes_modal_content_tips2: "2. Rewards will be settled weekly and we will contact you by email.",
    taxes_modal_button_copy: "Copy invitation link",
    taxes_modal_button_cancel: "Cancel",
    taxes_feature_money: "{taxesYear} Profit & Loss {money}",
    taxes_no_transactions: "No transactions in {taxesYear}",

    fbo_tips_paid: "Pionex.US has paid successfully and is expected to receive the funds within 1-3 business days",

    fbo_ach_0fee_des: " {fee} (Limited Time)",

    taxes_calculated_tips: "Tips: The cumulative Pnl will only be calculated for the transaction you sold. It may be different from the profit and loss you understand.",
    trial_fund_money: "<agreement>0.0015</agreement>Bitcoin",
    trial_fund_tag: "Pionex.US reserve the right of final interpretation for the activity.",
    trial_fund_kyc_title: "Identity Verification",
    trial_fund_kyc: "In order to comply with US regulations, all users are required to verify their identity. Please complete the verification before trying the trial fund.",
    TrialFund_0fvnd: "Sorry, the event is only open to specific users. Thank you for your attention.",
    TrialFund_xk2kp: "Exclusive welcome gift",
    TrialFund_a7ukk: "Get trial fund for free",
    TrialFund_cwj23: "Official Bonus    Test Bitcoin Growth Bot with zero risk",
    TrialFund_155il: "How to use the trial fund?",
    TrialFund_1cs8z: "Get trial fund",
    TrialFund_pw28h: "Your Trial Fund has Arrived！",
    TrialFund_kbush: "0.0015 Bitcoin has arrived in your account.\n You can test our Bitcoin Growth Bot without any cost now.",
    TrialFund_h8ngl: "Try the bot",
    TrialFund_ofsce: 'Choose "Bitcoin Growth Bot"',
    TrialFund_vd97a: "Invest with 0.0015 Bitcoin, and keep it running",
    TrialFund_0k33v: "Enjoy the profit from the bot",
    TrialFund_70ztj: "Failed to receive trial funds, please contact customer support.",
    TrialFund_nks7x: "Open in the Pionex.US app?",
    TrialFund_vmyno: "Cancel",
    TrialFund_mhaud: "Open",

    question_reviewing_title: "Questionnaire is under review",
    question_reviewing_des:
        'The questionnaire you filled out is under review, and we will complete the review within 24 hours at most. If you are in an emergency, please click "Contact" below. We will expedite it for you.',
    question_required_ok: "Fill out",
    question_required_title: "Questionnaire for key customers",
    question_required_des:
        "Due to compliance requirements and security reasons, we may ask for additional documents and information, or hold your funds longer. Please answer in time, otherwise your normal withdrawal will be affected.",
    question_title: "Questionnaire for key customer",
    question_success: "Success",
    question_res_des:
        "The questionnaire you filled out is under review, and we will complete the review within 24 hours at most. You cannot withdraw funds temporarily until the review is completed.",
    question_attachment_des:
        "1. You can upload any of them: Recent bank statement with sufficient balance, salary slip, the transaction history which generates your wealth, or any other similar proof." +
        "{br}2. Please note that we only support png, jpeg, jpg, pdf and the file size must be within 10KB - 10 MB.",

    buy_crypto_loading: "In quick buy, it takes about 10-20 seconds.",
    buy_crypto_loading_sell: "In quick sell, it takes about 10-20 seconds.",
    buy_crypto_loading_convert: "In quick convert, it takes about 10-20 seconds.",
    buy_crypto_history: "History",
    buy_crypto_order_id: "Order ID",
    buy_crypto_asset: "Asset",
    buy_crypto_buy_coin: "Coin",
    buy_crypto_buy_amount: "Quantity",
    buy_crypto_buy_tips: "The quantity is for reference only, and the system will place the order based on the trading volume",
    buy_crypto_convert_tips:
        "There may be some spread and slippage when converting crypto using this feature. lf you want to convert at a favorable price, you can use a limit order in Pro trading",
    buy_crypto_fiat_amount: "Cost",
    buy_crypto_account_number: "Account number",
    buy_crypto_channel: "Payment Method",
    buy_crypto_channel_fee: "Fees",
    buy_crypto_status: "Status",
    buy_crypto_time: "Time",
    buy_crypto_create_time: "Create Time",
    buy_crypto_update_time: "Update Time",
    buy_crypto_fail_reason: "Fail Reason",
    buy_crypto_fee_free: "FREE",
    buy_crypto_ach_title_reauth: "Account expired",
    buy_crypto_ach_title_pending: "Bank account is under review",
    buy_crypto_ach_title_failed: "Failed to upload the bank account",
    buy_crypto_timeout: "Timed out, we will try again. You can view the results on the History Order.",
    buy_crypto_available_balance: "Available balance",
    buy_crypto_available_ach: "Remaining: {remain}",
    buy_crypto_amount_least: "At least {least} dollars",
    buy_crypto_amount_least_sell: "At least {least} {coin}",
    buy_crypto_amount_exceeded: "Maximum amount is {max}",
    buy_crypto_no_available: "No sellable assets",
    buy_crypto_select_coin: "Choose asset",
    buy_crypto_input_amount: "Enter amount",
    buy_crypto_input_amount_placeholder: "Amount",
    buy_crypto_estimated: "Estimated buy quantity",
    buy_crypto_select_paymethod: "Choose payment method",
    buy_crypto_paymethod_usd_balance: "USD Balance",
    buy_crypto_compare_tips: "The following data is provided by",
    buy_crypto_compare_link: "Buy Crypto Compare",
    buy_crypto_will_get: "You will get",
    buy_crypto_paymethod: "Payment method",
    buy_crypto_default_paymethod: "BALANCE",
    buy_crypto_purchase: "Buy",
    buy_crypto_fee: "Fees",
    buy_crypto_total: "Total",
    buy_crypto_res_title_failed: "Failed",
    buy_crypto_res_title_timeout: "Timeout",
    buy_crypto_res_btc_timeout: "History Order",
    buy_crypto_res_success: "Purchase successfully",
    buy_crypto_res_success_sell: "Sold successfully",
    buy_crypto_res_success_convert: "Conversion successfully",
    buy_crypto_preview_title: "Confirm Buy {coin}",
    buy_crypto_preview_title_sell: "Confirm Sell {coin}",
    buy_crypto_preview_title_convert: "Confirm Convert {coin}",
    buy_crypto_withdrawable: "Available for withdrawal",
    buy_crypto_buy: "Buy",
    buy_crypto_sell: "Sell",
    buy_crypto_convert: "Convert",
    buy_crypto_compare_title: "Real buy price of {coin}",
    buy_crypto_compare_best: "Best",
    buy_crypto_compare_less: "{percent}",
    buy_crypto_compare_slogan: "Buy Bitcoin & Crypto",
    buy_crypto_receive: "You'll receive",
    buy_crypto_pay: "You Pay",
    buy_crypto_sell_pay: "You'll Sell",
    "buy_crypto_status:success": "Success",
    "buy_crypto_status:fail": "Failed",
    "buy_crypto_status:pending": "Pending",
    "buy_crypto_status:cancel": "Canceled",
    "buy_crypto_status:completed": "Complete",
    buy_crypto_agree: "I agree with the <policy>Chargeback/Refund Policy</policy>. I also understand that I can withdraw the purchased cryptocurrencies after 14 days.",

    buy_crypto_preview_tips: "The final quantity bought may differ from that shown due to price fluctuations.",
    buy_crypto_preview_convert_tips: "Due to price fluctuations, the final quantity exchanged may differ from the displayed quantity.",
    buy_crypto_sell_title: "Just {fee} steps to sell your {coin} for {quote}",
    buy_crypto_convert_title: "Discover the <light>fast</light> and <light>easy</light> way to change crypto ",
    buy_crypto_sell_title1: "Bitcoin, Ethereum, Tether, Solana,and more popular crypto",
    buy_crypto_convert_title1: "<light>Secure, Fast, and Reliable!</light>",
    buy_crypto_convert_title2: "between <light>260+</light> convert pairs or USD",
    buy_crypto_sell_tips:
        "There may be some spread and slippage when converting crypto using this feature. lf you want to convert at a favorable price, you can use a limit order in Pro trading",
    buy_crypto_sell_use_bank: "Purchase using a bank account",
    email_or_phone_verify_tip: "To enhance security, please complete either {verifyMode} or Google authentication before trading or creating bots.",
    factor_auth_content: "You haven't enabled Google authentication, so your account's security level is low. Please enable first and then trade or create the bots.",
    bind_otp_radio_title: "Enable Google Authenticator",
    bind_email_radio_title: "Enable Email authentication",
    bind_phone_radio_title: "Enable SMS authentication",
    notify_wont_today: "Don't remind me again today",
    factor_auth_title: "2 Factor authentication",
    bind_two_step_verify_title: "Please enable Two-factor authentication",
    mtg_multi_order_name: "Composite DCA Bot",
    grid_v3_bot: "grid bot",
    annualized_return_share: "Annualized",
    // lasting: "Lasting",
    grid_transaction_share: "Rounds",
    fund_unit: "shares",
    grid_order_lasting_day_u: "D",
    grid_order_lasting_hour_u: "H",
    grid_order_lasting_minute_u: "m",
    grid_order_lasting_second_u: "s",
    martingale_price_range: "Price range",
    martingale_price_scale_2: "Price deviation",
    martingale_take_profit_percentage: "Take profit percentage",
    martingale_take_profit_trailing_percentage: "Trailing percent",
    martingale_price_scale_trailing_percentage: "Max rebounce",
    martingale_DIY_shares: "shares",
    martingale_DIY_trailing: "Trailing",
    trade_signal: "Signal",
    martingale_signal_immediately: "Immediately",
    martingale_signal_time_lapse: "Time-lapse",
    martingale_signal_time_lapse_with_unit: "Time-lapse($time$s)",
    copy_bot_list_nnt: "Users",
    copy_bot_list_amount: "AUM",
    copy_bot_list_bot_lasting_roi: "ROI since created",
    quotation_mark: "{content}",
    copy_feed_view_all: "Check detail",

    // new
    share_introduce: "This is my {symbol}{strategy_bot}", // eg: 这是我的BTC/USDT马丁格尔机器人
    share_nickname: "This is $nickname$", // eg:  我是Haze
    profit_rate: "Profit rate",
    snapshot_on: "Snapshot on {date}",
    martingale_rounds: "Rounds",
    copy_in_one_click: "Copy in one click",
    share_order_closed: "The bot has been stopped.",
    martingale_stop_loss_percentage: "Stop loss percentage(Total profit)",
    unrealized_stop_loss_percentage: "Stop loss percentage(Unrealized profit)",
    open_app: "Opening Pionex...",
    number_of_grids: "Grids",
    profit_rate_per_grid: "Profit/grid",
    take_profit_price: "Take profit price",
    stop_loss_price: "Stop loss price",
    transactions: "Txs",

    default_nickname: "Pionex",

    share_sr_enter_alert_trigger_threshold_desc: "Deviated", // eg: 佔比變化超過 10%

    subscription_success_confirm: "Start copying",
    confirm_copy_order_closed_title: "The bot is closed",
    confirm_copy_order_closed_continue: "The creator has closed the bot, are you sure you still want to copy the parameters?",

    buy_crypto_wire_title: "Buy Crypto via Wire Transfer",
    buy_crypto_wire_des:
        "If you want to buy crypto by wire transfer, your funds will be in your Pionex.US account after receiving it, and you can use the account balance to buy crypto directly after receiving it.",

    kyc_res_guide_title: "Buy Bitcoin & Crypto at a Better Price",
    kyc_res_guide_buy_crypto: "Buy Crypto",
    kyc_res_guide_buy_crypto_btn: "Buy",

    buy_crypto_sign: "You need to sign in first to buy crypto.",
    buy_crypto_link_new: "Link new account",
    buy_crypto_link_more: "More payment methods are coming soon",

    convert_pairs: "Pairs",
    convert_buy_amount: "Buy amount",
    convert_sell_amount: "Sell amount",
    convert_fail_reason: "Unknown error, please contact customer service for help",

    buy_crypto_more_coinbase: "Get {per} more than Coinbase",
    buy_crypto_content_title: "Buy and sell cryptocurrency at the <light>Best Price</light>",
    chatgpt_goto_tv: "Backtest on TradingView",
    chatgpt_send: "Send",
    chatgpt_tips:
        "Describe your strategy and let PionexGPT write the code for you.\nFor example: write me a trend-following strategy that uses MACD and RSI, and then add filters to lower the drawdown.",
    chatgpt_tab_create: "PionexGPT",
    chatgpt_tab_sub: "GPT Marketplace",
    chatgpt_send_1: "Explain the code",
    chatgpt_copy_gotv: "Copy & backtest on TradingView",
    chatgpt_tip_1: "Popular question",
    chatgpt_tip_more: "View more",
    chatgpt_year: "1-year ROI",
    chatgpt_pt_1: "Give me a grid strategy",
    chatgpt_pt_2: "Give me a DCA strategy",
    chatgpt_pt_3: "I want a trailing stop strategy",
    chatgpt_pt_4: "I want a Bollinger Bands strategy",
    chatgpt_pt_5: "Strategy to captures channel breakouts",
    chatgpt_pt_6: "Please write me a Ichimoku strategy",
    chatgpt_pt_7: "Use MACD to buy & sell",
    chatgpt_pt_8: "Give me a momentum strategy",
    chatgpt_pt_9: "I need a RSI strategy",
    chatgpt_step1: "Create strategy",
    chatgpt_step1_desc: "Describe your strategy to PionexGPT",
    chatgpt_step2: "Backtest on TradingView",
    chatgpt_step2_desc: "Backtest and optimize parameters on TradingView",
    chatgpt_step3: "Automate strategy on Pionex",
    chatgpt_step3_desc: "Use signal bot to trade the strategy automatically",
    chatgpt_title: "You can ask me the following questions or describe your own trading strategy in the input box",
    chatgpt_use_pionex_tips: "Join the community to discuss scripts",
    chatgpt_use_pionex_text: "Join the Telegram",
    chatgpt_goto_url: "https://t.me/pionexen",
    chatgpt_placeholder: "Please tell me how would you like to trade?",
    chatgpt_help_me: "I want to modify parameters",
    chatgpt_more_action: "More",
    chatgpt_how_create: "How to automate your trading strategy?",
    chatgpt_see_all_examples: "Check all",
    chatgpt_all_examples: "Examples",
    chatgpt_re_strategy: "This strategy is similar to your strategy",
    chatgpt_re_indicators: "This strategy also refer {tag} for entry & exit",
    chatgpt_re_earnings: "This strategy may help you achieve your earning target",
    chatgpt_re_coins: "This strategy also trades {tag}",
    chatgpt_re_null: "Too complicated? Try copying strategy",
    chatgpt_goto_strategy: "Check details",

    chatgpt_market_table_1: "Signal Provider",
    chatgpt_market_table_2: "Strategy Name",
    chatgpt_market_table_3: "30D ROI",
    chatgpt_market_table_4: "1Y ROI",
    chatgpt_market_table_5: "90D ROI",
    chatgpt_market_table_6: "Cost",
    chatgpt_market_table_detail: "Detail",
    chatgpt_share_ratio: "profit-sharing",
    chatgpt_market_free: "FREE",
    chatgpt_market_private: "monthly fee required",
    chatgpt_goto_login: "Please sign in/up before stating the conversation",

    buy_crypto_register: "Create account",
    nav_chatgpt: "PionexGPT",

    "withdraw_error:TAPI_FBO_IN_BLACK_LIST_ERROR": "Your account is not allowed to withdraw for security issues. Please contact the support team.",

    "kyc_fail_reason:DEFAULT": `"Your verification has failed. For assistance, please email service@pionex.us with the title "Identity verification failed" and include your Pionex.US  account in the email."`,
    "kyc_fail_reason:BLACKLIST": `Your verification has failed. For assistance, please email service@pionex.us with the title "Identity verification failed" and include your Pionex.US  account in the email.`,
    "kyc_fail_reason:MRZ_CHECK_FAILED": "Sorry, your KYC failed. Your document cannot be recognized, please upload again or upload a new document",
    "kyc_fail_reason:PUNCHED_DOCUMENT": "Sorry, your KYC failed. Your document is punched, please replace the file and upload it again",
    "kyc_fail_reason:CHIP_DATA_MANIPULATED": "Sorry, your KYC failed, please send an email to service@pionex.us for help.",
    "kyc_fail_reason:MISMATCH_PRINTED_BARCODE_DATA": "Sorry, your KYC failed. Your KYC information does not match, please re-upload",
    "kyc_fail_reason:MISMATCHING_DATA_REPEATED_FACE": "Sorry, your KYC failed. Your KYC information does not match, please re-upload",
    "kyc_fail_reason:MISMATCH_HRZ_MRZ_DATA": "Sorry, your KYC failed. Your KYC information does not match, please re-upload",
    "kyc_fail_reason:SUPERIMPOSED_TEXT": "Sorry, your KYC failed. Your document cannot be recognized, please upload again or upload a new document",
    "kyc_fail_reason:MISMATCH_FRONT_BACK": "Sorry, your KYC failed. Your KYC information does not match, please re-upload",
    "kyc_fail_reason:PHOTOCOPY_BLACK_WHITE": "Sorry, your KYC failed. We cannot accept uploading black and white photos.Please upload again",
    "kyc_fail_reason:PHOTOCOPY_COLOR": "Sorry, your KYC failed. Your document cannot be recognized, please upload again or upload a new document",
    "kyc_fail_reason:DIGITAL_COPY": "Sorry, your KYC failed. Your document cannot be recognized, please upload again or upload a new document",
    "kyc_fail_reason:NOT_READABLE_DOCUMENT": "Sorry, your KYC failed. Your document cannot be recognized, please upload again or upload a new document",
    "kyc_fail_reason:NO_DOCUMENT": "Sorry, your KYC failed. Your document cannot be recognized, please upload again or upload a new document",
    "kyc_fail_reason:MISSING_BACK": "Sorry, your KYC failed. Your document cannot be recognized, please upload again or upload a new document",
    "kyc_fail_reason:WRONG_DOCUMENT_PAGE": "Sorry, your KYC failed. Your document cannot be recognized, please upload again or upload a new document",
    "kyc_fail_reason:MISSING_SIGNATURE": "Sorry, your KYC failed. The document you uploaded is missing the signature, please upload a new document",
    "kyc_fail_reason:CAMERA_BLACK_WHITE": "Sorry, your KYC failed. We cannot accept uploading black and white photos.Please upload again",
    "kyc_fail_reason:INVALID_WATERMARK": "Sorry, your KYC failed. The photo you uploaded contains a watermark, please remove the watermark and upload it again",
    "kyc_fail_reason:MISSING_FRONT": "Sorry, your KYC failed. Your document cannot be recognized, please upload again or upload a new document",
    "kyc_fail_reason:NOT_SUPPORT_PROVINCE":
        "According to the legal requirements of your state, Pionex.US is temporarily unable to serve you, and we will notify you by email when we can serve you.",
    "kyc_fail_reason:UNRECOGNIZED_PROVINCE": "Your state information can not be recognized, please contact our customer service for help. Email: service@pionex.us.",
    "kyc_fail_reason:AGE_REQUIREMENT_MISMATCH": "Your application was rejected because you don't meet the age requirement (18 years old).",
    "kyc_fail_reason:EXPIRATION_DATE": "The identity document should be valid for at least 1 month from the date of submission.",
    "kyc_fail_reason:BLOCKLIST": "Sorry, we are unable to serve you due to the relevant platform terms.",
    "kyc_fail_reason:WRONG_USER_REGION": `Sorry, your region is not supported. Please <licence>click here</licence> to check which regions are currently supported by Pionex.US.`,
    "kyc_fail_reason:DUPLICATE":
        "Your application was rejected because you have already submitted your documents on another account. If you have any questions, please contact service@pionex.us.",
    "kyc_fail_reason:UNSATISFACTORY_PHOTOS":
        "Unfortunately, the required fields of the document are not readable or visible. Please upload a new photo. Make sure the photo is bright and clear and that all corners of the document are visible.",
    "kyc_fail_reason:GRAPHIC_EDITOR": "Edited images are not accepted. Please upload an original image.",
    "kyc_fail_reason:BAD_PROOF_OF_IDENTITY": "The uploaded document is not acceptable or no longer valid. Please upload a different document.",
    "kyc_fail_reason:BAD_SELFIE": "Your selfie is unclear, please take a new one.",

    "error_code:-101107": "Close rebate failed, please retry in 15 minutes.",

    "sign_error:-109901": "Request time out, please try again.",

    two_step_type_phone: "SMS",
    two_step_ok: "Enable",

    occupy_type_other: "Manual order and others",

    wire_withdraw_title: "Withdraw via Wire Transfer",
    ach_withdraw_title: "Withdraw to Bank Account",
    ach_withdraw_success_des:
        "Your withdrawal information has been successfully submitted. We will initiate a withdrawal within one working day. It is estimated that you will receive the wire within 1-5 business days.",
    wire_withdraw_success_des:
        "Your withdrawal information has been successfully submitted. We will initiate a withdrawal within one working day. It is estimated that you will receive the wire within 1-5 business days.",

    wire_pending_tips: "Usually takes 1-3 business days.",
    ach_pending_tips: "Usually takes 1-5 business days.",
    unsupported_address: "Unsupported address type",
    reset_email_with_apple_title: "Reset email in Pionex.US",
    reset_email_with_apple_content: "We have detected a change to your Apple ID email. Would you like to update your email in Pionex.US as well?",
    disable_email_change_info: "After disconnecting and changing your Google or Apple ID account, please use the account password to log in.",
    account_delete_kyc_error: "Incorrect ID Number",
    mine_bank_name_input_none: " Input bank name",
    fbo_addCard_aba_bank_name: "Bank Name",
    bank_name_tips: "Please double-check the bank name, empty or incorrect bank names will be rejected.",

    smart_copy_title_desc: "Copy Strategy",
    smart_copy: "Copy strategy",
    count_d: "d",
    count_hour: "h",
    count_min: "m",
    copy_bot_buy_and_sell_holing_coin_profit: "Holdings PNL",
    grid_v4_accumulated_title: "Accumulated arbitrage/PnL",
    gird_pnl_by_USDT: "Total profit in USDT:",
    grid_v4_accumulated_arbitrage_title: "Accumulated arbitrage = Available arbitrage + Withdrawn arbitrage",
    grid_v4_accumulated_profit_title: "Accumulated PnL = Accumulated arbitrage + Holdings PnL",
    grid_v4_profit_undrawn_profit: "Available arbitrage",
    grid_v4_profit_drawn_profit_history: "Withdrawn amount",
    grid_v4_profit_drawn_profit_history_to_go: "Check the withdrawn history",
    grid_v4_accumulated_arbitrage_des: "Accumulated arbitrage: The sum of the profit of each grid that has fully completed",
    grid_v4_accumulated_profit_des: "Accumulated PnL: The sum of all profit generated since the bot was created, including the withdrawn arbitrage",
    grid_v4_title_desc: "Grid trading",
    smart_invest_adviser_select_coin: "Select Coin",

    gpt_sign_tips: "You need to sign in first to use PionexGPT.",
    sumsub_uploadDocument: "Upload the picture of the debit card front page.",
    us_name: "USA",
    hk_name: "Hong Kong",
    kyc_hk_verify_title1: "The products and services available on",
    pionex_name: "pionex.us",
    kyc_hk_verify_title2:
        "are not intended for individuals or institutional users in Hong Kong. Nothing on this website is intended to be construed as a solicitation of any user in Hong Kong.",
    settings_announcement: "Announcement",
    header_message_center: "Messages",
    message_system_tab_title: "System Messages",
    message_announcement_tab_title: "Announcement",
    message_all_read_text: "read all",
    message_new_count: "<style>{count}</style>pending",
    message_scroll_loading_text: "Scroll down the page for more information",
    message_scroll_no_loading_text: "There is no further information available.",
    service_maintenance_error_tip: "The service is under maintenance, please choose another option.",
    taxex_share_modal_item1: "Invite 1 user to get $1,000 worth of tax report (for {freeTaxesYear} tax season)",
    taxex_share_modal_item2: "Invite 3 users to join tax consulting webinar for VIP users",
    taxes_share_title: "Share to get free report",
    you_will_get: "You will get",
    purchase_failed: "Purchase Failed",
    transaction_limit: "Transaction limit cannot exceed {amount} USD",
    rolling_limit: "The rolling limit cannot exceed {amount} USD",
    frequency_exceeds_limit: "Frequency exceeds limit",
    fs_property_structured_see_more: "More",
    identify_verification: "Identify Verification",
    unable_to_join: "Unable to join",
    trade_now: "Trade Now",
    before_depositing: "Before depositing and trading, please take 1-2 minutes to verify your identity first.",
    buy_crypto_choose_asset_header: "In the past 24 hours",
    buy_crypto_choose_asset_header_best: "Better prices than other exchanges!",
    buy_crypto_choose_asset_search_no_data: "No Crypto Assets",
    post_upgrade: "Post-Upgrade",
    pre_upgrade: "Pre-Upgrade",
    due_to_an_account_system_upgrade:
        "Due to an upgrade of our account system, we kindly request that you complete upgrading your account at your earliest convenience. Failure to complete the upgrade will result in the suspension of trading, withdrawal, and deposit functions.",
    ensure_to_upgrade_your_account:
        "Please ensure to upgrade your account.The process will take approximately one minute. Note that after the upgrade, the deposit and withdrawal addresses will change.",
    upgrade_now: "Upgrade now",
    account_update: "Account update",
    sure_close_all_orders: "Are you sure to close all orders?",
    note_recreate: "Note: You can recreate the orders immediately after you complete the upgrade!",
    recreate: "Recreate",
    recreate_manual_orders: "Recreate manual orders",
    recreate_bot_orders: "Recreate bot orders",
    view_current_account: "Click here to view the content of the current account",
    recreate_closed_orders: "You can recreate closed orders and view your transaction history here.",
    trading_bot: "Trading bot",
    trade_tab_spot_bot_desc: "Auto-trading 24/7 without monitoring, no trading experience needed",
    trade_side_tab_spot: "Spot Trading",
    trade_side_tab_spot_desc: "Invest in trending assets and capture more opportunities",
    trade_swap_desc: "Instant convert",
    trade_post_only: "Post only",
    vip_status_fee_level: "Fee Level",
    vip_status_my_fee_level: "My VIP Level",
    vip_status_spot_fee: "Spot Trading Fee",
    vip_status_leverage_fee: "Leveraged Token Trading Fee",
    vip_status_future_fee: "Futures Trading Fee",
    vip_status_table_level: "VIP Status",
    vip_status_table_spotAndLeverage: "Spot & Leveraged Token",
    vip_status_table_leveraged: "Leveraged",
    vip_status_vol: "Trading Volume in the Past 30 Days",
    vip_status_spot_vol: "Spot Trading Volume in the Past 30 Days",
    vip_status_future_vol: "Futures Trading Volume in the Past 30 Days",
    vip_status_next_level_require: "To upgrade to {level}, you need: ",
    vip_status_table_maker_taker: "Maker/Taker",
    vip_status_maker: "Maker {maker}",
    vip_status_taker: "Taker {taker}",
    vip_status_update_tips: "Note: VIP status will be updated daily at 4:00 AM (UTC time).",
    vip_status_login: "Sign in to view the details.",
    "balance.table.equivalant.usdt": "Equivalent USDT",
    vip_fees_update_level: "Level up",
    future_level: "Fee",
    future_level_item: "VIP",
    future_level_maker: "Maker",
    future_level_taker: "Taker",
    future_fee_level: "Fee level",
    top_strategies: "Top strategies",
    grid_trading_moon_btc_eth_desc: "A Bitcoin miner - without using electricity",
    grid_trading_moon_btc_eth: "Bitcoin Growth",
    trade_order_category_price_avg: "Avg. Price",
    trade_order_consignment_price: "Order Price",
    trade_order_consignment_qty: "Order Quantity",
    trade_order_category_change: "Change",
    cancel_order_by_single_title: "Cancel",
    cancel_order_by_single_content: "Are you sure you want to cancel this order?",
    cancel_order_by_single_fail: "Failed to cancel the order",
    cancel_order_by_single_success: "Successfully canceled the order",
    create_time: "Create time",
    payment_received_time: "Payment received time",
    price_of_coin: "Price of {coin}",
    your_payment_method_is_bank_account:
        "Your payment method is bank account. You are linking your bank account under the same name as the owner of this Pionex.US account.\n\nNobody else is instructing you to create a Pionex.US account or link your bank account.",
    buy_via_bank_account: "Buy via bank account",
    register_now: "Register Now",
    new_users_exclusive: "New Users Exclusive: Register and get 200 USDT.",
    register_to_get_u: "Register to get 200 USDT",
    buynow: "Buy Now",
    create_bot: "Create bot",
    identity_verification_completed: "Identity verification completed",
    deposit_trade_now: "You can deposit and trade now, make a deposit to win 200 USDT.",
    invite_now: "Invite Now",
    email_address_placeholder: "Email address",
    ach_history_upload: "Upload payment receipt",
    ach_history_upload_click: "Upload payment receipt",
    ach_history_upload_success: "Uploaded successfully!",
    ach_history_upload_modal_title: "Upload your payment receipt",
    ach_history_upload_modal_ok: "Send",
};
