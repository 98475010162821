import React, { useMemo } from "react";
import { TTooltip } from "components/v2/TinyComps";
import { ColumnsType } from "antd/lib/table";
import useDateFormatter from "commonUse/useDateFormatter";
import { useTranslators } from "commonUse/Locale";
import { DepositHistoryInfo, DepositStatus } from "landings/V2/SignatureDW/types";
import cs from "../../cs.m.less";
import useTheme from "commonUse/useTheme";
import { ThemeName } from "state/ThemeData";
import { useSignatureDWAPI } from "landings/V2/SignatureDW/common";
import { useRxRequest } from "commonUse/useRequest";
import USDTSwapModal from "src/components-v2/USDTSwapModal";
import { useToggle } from "commonUse/tools";
import { Spin, Table } from "@pionex-web-kit/components";

const USD = "USD";

function useColumns(showSwapUSDT: () => void): ColumnsType<DepositHistoryInfo> {
    const dateFormatter = useDateFormatter();
    const { $st } = useTranslators();
    const theme = useTheme();

    return useMemo(() => {
        const columns: ColumnsType<DepositHistoryInfo> = [
            {
                title: $st("signature_dw_history_order_id"),
                dataIndex: "orderId",
                key: "orderId",
            },
            {
                title: $st("signature_dw_history_deposit_realAmount"),
                dataIndex: "realAmount",
                key: "realAmount",
                render: (value, record, index) => {
                    return `${value} ${USD}`;
                },
            },
            {
                title: $st("signature_dw_history_status"),
                dataIndex: "status",
                key: "status",
                render: (value, record, index) => {
                    return (
                        <div>
                            {value}
                            {record.status === DepositStatus.FAIL && (
                                <TTooltip title={<span>{$st("signature_dw_deposit_fail_reason")}</span>}>
                                    <img
                                        src={
                                            theme.name === ThemeName.dark
                                                ? require("images/trading/icon_advance_setting_info_dark.png")
                                                : require("images/trading/icon_advance_setting_info.png")
                                        }
                                        className={cs.itemTips}
                                    />
                                </TTooltip>
                            )}
                        </div>
                    );
                },
            },
            {
                title: $st("signature_dw_history_deposit_amount"),
                dataIndex: "amount",
                key: "amount",
                render: (value, record, index) => {
                    return `${value} ${USD}`;
                },
            },
            {
                title: $st("signature_dw_history_fee"),
                dataIndex: "fee",
                key: "fee",
                render: (value, record, index) => {
                    return `${value} ${USD}`;
                },
            },
            {
                title: $st("signature_dw_history_time_New"),
                dataIndex: "updateTime",
                key: "updateTime",
                render: (value, record, index) => {
                    return value ? <div>{dateFormatter.year_second(value)}</div> : "-";
                },
            },
            {
                title: $st("withdrawable_Time"),
                dataIndex: "withdrawableTime",
                key: "withdrawableTime",
                render: (value, record, index) => {
                    return value ? <div>{dateFormatter.year_second(value)}</div> : "-";
                },
            },
            {
                title: " ",
                dataIndex: "operation",
                key: "operation",
                render: () => {
                    return (
                        <div className={cs.optWrapper}>
                            <div className={cs.operation} onClick={showSwapUSDT}>
                                {$st("circledw_history_swap_usdt")}
                            </div>
                        </div>
                    );
                },
            },
        ];

        columns.forEach((item) => (item.width = `${100 / columns.length}%`));
        return columns;
    }, [$st, dateFormatter, showSwapUSDT, theme.name]);
}

interface Props {}

/**
 * Signature入金记录
 */
const DepositHistory: React.FC<Props> = ({}) => {
    const signatureDWAPI = useSignatureDWAPI();
    const { loading, data } = useRxRequest(() => signatureDWAPI?.getDepositOrders());

    const [swapVisible, { setTrue: setSwapShow, setFalse: setSwapHide }] = useToggle(false);

    const columns = useColumns(setSwapShow);

    return (
        <Spin spinning={loading}>
            <Table scroll={{ x: "100%" }} dataSource={data} columns={columns} pagination={false} />
            <USDTSwapModal opposite={"USD"} visible={swapVisible} onSuccess={setSwapHide} onCancel={setSwapHide} isGetUSDT />
        </Spin>
    );
};

export default DepositHistory;
