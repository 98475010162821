import { GET_LANGUAGE_DATA } from "./actions";
import { handleActions } from "redux-actions";
import { getCurrentLocale, getCurrentMessage } from "utils/i18n";

export type LocaleData = {
    locale: string;
    messages: any;
};

const messages = getCurrentMessage();

export const localeData = handleActions(
    {
        [GET_LANGUAGE_DATA]: (state, { payload }) => {
            return payload;
        },
    },
    {
        locale: getCurrentLocale(),
        messages,
    },
);
