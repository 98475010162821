import { Button } from "antd";
import { useTranslators } from "commonUse/Locale";
import useTheme from "commonUse/useTheme";
import React, { CSSProperties } from "react";
import { ThemeName } from "state/ThemeData";
import { LANGUAGE_LOCALS_MAP } from "utils/i18n/config";
import css from "../index.module.less";
import Constants from "utils/Constants";
import useAppLangInfo from "commonUse/useAppLangInfo";
import { openUrlInNewTab, isMobile } from "src/utils/commons";
import { isDeviceMobile } from "src/components-v2/Layout/hooks/useMedia";
import DataReporter from "landings/Analytics/DataReporter";
import { EventName } from "landings/Analytics/EventName";
import LocalStorageUtils from "utils/LocalStorageUtils";

const DYNAMIC_LINK = `https://pionexus.page.link/?link=${encodeURI(
    `https://www.pionex.us/en-US/download?ref=${LocalStorageUtils.getReferralCode()}`,
)}&apn=com.pionex.us.client&isi=1567213944&ibi=org.pionex.us&utm_campaign=official-download&utm_medium=download&utm_source=official-web`;

export const ALL_DOWNLOAD_URL = {
    ios: {
        testflight: "https://testflight.apple.com/join/a79DMx62",
        appStore: {
            [LANGUAGE_LOCALS_MAP.enUS]: isDeviceMobile ? DYNAMIC_LINK : "https://apps.apple.com/us/app/pionex-us-buy-btc-and-eth/id1567213944",
            [LANGUAGE_LOCALS_MAP.zhTW]: "https://apps.apple.com/us/app/pionex-us-buy-btc-and-eth/id1567213944",
            [LANGUAGE_LOCALS_MAP.zhCN]: "https://apps.apple.com/us/app/pionex-us-buy-btc-and-eth/id1567213944",
        },
    },
    android: {
        gp: isDeviceMobile ? DYNAMIC_LINK : "https://play.google.com/store/apps/details?id=com.pionex.us.client&referrer=utm_source%3Dofficialweb",
        // apk: `${Constants.pionexApkHost}/apk/pionex.apk`,
        apk: "https://config.pionex.us/statics/app/apk/pionex_us.apk", //TODO 先用着，有了美国站的host了再换
    },
};

export interface DownloadAppPopoverItemProps {
    iconSource: string;
    title: string;
    desc: string;
    downloadUrl: any;
    style?: CSSProperties;
    reportType: "app_store" | "google_play" | "apk";
}

const DownloadAppPopoverItem = (props: DownloadAppPopoverItemProps) => {
    const { iconSource, title, desc, downloadUrl, style } = props;
    const disableDownload = downloadUrl === "none";
    const { $st: _$st } = useTranslators();

    if (disableDownload) {
        return (
            <div className={`${css.download_app_popover_item} ${css.download_app_popover_item_disable}  ${isMobile ? css.download_app_popover_item_mobile : ""}`} style={style}>
                <img className={`${css.platform_icon} ${isMobile ? css.platform_icon_mobile : ""}`} src={iconSource} alt={"download_icon"} />
                <div className={`${css.download_app_desc_wrap} ${isMobile ? css.download_app_desc_wrap_mobile : ""}`}>
                    <span className={`${css.download_app_popover_item_title} ${isMobile ? css.download_app_popover_item_title_mobile : ""}`}>{title}</span>
                    <br />
                    <span className={`${css.download_app_popover_item_desc} ${isMobile ? css.download_app_popover_item_desc_mobile : ""}`}>{desc}</span>
                </div>
                <div>
                    <button style={{ fontFamily: "none", lineHeight: "26px" }} className={`${css.download_app_button_disable} ${isMobile ? css.download_app_button_mobile : ""}`}>
                        {_$st("download_button_text")}
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className={`${css.download_app_popover_item} ${isMobile ? css.download_app_popover_item_mobile : ""}`} style={style}>
            <img className={`${css.platform_icon} ${isMobile ? css.platform_icon_mobile : ""}`} src={iconSource} alt={"download_icon"} />

            <div className={`${css.download_app_desc_wrap} ${isMobile ? css.download_app_desc_wrap_mobile : ""}`}>
                <span className={`${css.download_app_popover_item_title} ${isMobile ? css.download_app_popover_item_title_mobile : ""}`}>{title}</span>
                <br />
                <span className={`${css.download_app_popover_item_desc} ${isMobile ? css.download_app_popover_item_desc_mobile : ""}`}>{desc}</span>
            </div>
            <div>
                <button
                    style={{ fontFamily: "none", lineHeight: "26px" }}
                    onClick={() => {
                        openUrlInNewTab(downloadUrl);
                        DataReporter.report(EventName.download_channel_click, { channel: props.reportType });
                    }}
                    className={`${css.download_app_button} ${isMobile ? css.download_app_button_mobile : ""}`}
                >
                    {_$st("download_button_text")}
                </button>
            </div>
        </div>
    );
};

export interface DownloadAppPopoverProps {
    isAndroid: boolean;
    style?: React.CSSProperties;
}

export const DownloadAppPopover = (props: DownloadAppPopoverProps) => {
    const { style } = props;
    const theme = useTheme();
    const { $st: _$st } = useTranslators();
    const appLangInfo = useAppLangInfo();

    const downloadUrl: { ios: { testflight: string; appStore: string }; android: { gp: string; apk: string } } = React.useMemo(() => {
        return {
            ios: {
                testflight: ALL_DOWNLOAD_URL.ios.testflight,
                appStore: ALL_DOWNLOAD_URL.ios.appStore[appLangInfo.locale] || ALL_DOWNLOAD_URL.ios.appStore[LANGUAGE_LOCALS_MAP.enUS],
            },
            android: {
                gp: ALL_DOWNLOAD_URL.android.gp,
                apk: ALL_DOWNLOAD_URL.android.apk,
            },
        };
    }, [appLangInfo.locale]);

    if (props.isAndroid) {
        return (
            <div className={isMobile ? css.download_app_popover_mobile : css.download_app_popover} style={style}>
                <DownloadAppPopoverItem
                    reportType="google_play"
                    style={{ marginTop: 10 }}
                    iconSource={theme.name === ThemeName.light ? require("src/images/download/icon_gp.png") : require("src/images/download/icon_gp_night.png")}
                    title={_$st("down_load_gp_version")}
                    desc={_$st("down_load_gp_version_desc")}
                    downloadUrl={downloadUrl.android.gp}
                />
                <DownloadAppPopoverItem
                    reportType="apk"
                    iconSource={theme.name === ThemeName.light ? require("src/images/download/icon_apk.png") : require("src/images/download/icon_apk_night.png")}
                    title={_$st("down_load_apk_version")}
                    desc={_$st("down_load_apk_version_desc")}
                    downloadUrl={downloadUrl.android.apk}
                />
            </div>
        );
    } else {
        return (
            <div className={isMobile ? css.download_app_popover_mobile : css.download_app_popover} style={style}>
                <DownloadAppPopoverItem
                    reportType="app_store"
                    style={{ marginTop: 10 }}
                    iconSource={
                        theme.name === ThemeName.light
                            ? downloadUrl.ios.appStore === "none"
                                ? require("src/images/download/app_store_disable.png")
                                : require("src/images/download/app_store.png")
                            : downloadUrl.ios.appStore === "none"
                            ? require("src/images/download/app_store_disable_night.png")
                            : require("src/images/download/ic_appstore_night.png")
                    }
                    title={_$st("down_load_app_store_version")}
                    desc={_$st("down_load_app_store_version_desc")}
                    downloadUrl={downloadUrl.ios.appStore}
                />
                {/*<DownloadAppPopoverItem*/}
                {/*    iconSource={theme.name === ThemeName.normal ? require("src/images/download/ic_testflight.png") : require("src/images/download/ic_testflight_night.png")}*/}
                {/*    title={_$st("down_load_testflight_version")}*/}
                {/*    desc={_$st("down_load_testflight_version_desc")}*/}
                {/*    downloadUrl={downloadUrl.ios.testflight}*/}
                {/*/>*/}
            </div>
        );
    }
};
