import React, { useState, useEffect, useCallback } from "react";
import { TModal, TInput } from "components/v2/TinyComps";
import { $st } from "utils/i18n";
import { AssetAddressItem, modifyAddressBookItemTag, commonErrorParser } from "../../api";

const RenameModal = ({ visible, onCancel, onOk, data }: { visible: boolean; onCancel: () => void; onOk: () => void; data: AssetAddressItem | null }) => {
    const [val, setVal] = useState(data?.tag || "");
    const [address, setAddress] = useState(data?.address || "");
    const [error, setError] = useState("");
    const [submiting, setSubmiting] = useState(false);
    const valid = useCallback((val: string, cb?: (string) => void) => {
        let msg = "";
        if (!val) {
            msg = $st("new_address_tag_name");
        }
        const _val = val.trim();
        if (!_val) {
            msg = $st("new_address_tag_name");
        }
        if (_val && _val.length > 28) {
            msg = $st("address_tag_too_long");
        }
        if (msg) {
            setError(msg);
            return;
        }
        setError("");
        cb && cb(_val);
    }, []);
    const onChange = useCallback(
        (e) => {
            const val = e.target.value;
            setVal(val);
            valid(val);
        },
        [valid],
    );
    const _onOk = useCallback(() => {
        valid(val, (_val) => {
            if (!data?.address_id) return;
            setSubmiting(true);
            modifyAddressBookItemTag(data?.address_id, _val).subscribe(
                (data) => {
                    onOk();
                    setSubmiting(false);
                },
                (error) => {
                    commonErrorParser(error.code, $st, error.message);
                    setSubmiting(false);
                },
            );
        });
    }, [data?.address_id, onOk, val, valid]);
    // 每次弹窗都重置最新数据
    useEffect(() => {
        if (visible && data?.tag) {
            setVal(data?.tag);
            setAddress(data?.address);
        }
    }, [visible, data?.tag, data?.address]);
    return (
        <TModal visible={visible} onCancel={onCancel} title={$st("rename")} onOk={_onOk} okButtonProps={{ loading: submiting }}>
            <TInput size="large" placeholder={$st("address_tag_name")} value={val} onChange={onChange} error={error} />
            <div style={{ marginTop: 15 }}>
                <div>{$st("add_address_form_address")}:</div>
                <div>{address}</div>
            </div>
        </TModal>
    );
};
export default RenameModal;
