import { history } from "utils/History";
import { AssetAddressItem } from "trade_asset_lib";
import { SupportDWCoinInfo } from "TradeAPILib";
import { $st } from "utils/i18n/trans.comp";

export const WITHDRAW_INITIAL_DATA = "WITHDRAW_INITIAL_DATA";

export const goWithdraw = (item: AssetAddressItem, coinList: SupportDWCoinInfo[]) => {
    if (item.coin) {
        const coinInfo = coinList.find((c) => c.coinName === item.coin && c.protocol === item.chain);
        if (coinInfo) {
            history.push(`/balance/withdraw/${coinInfo.currency}`, {
                addressInitialData: {
                    currency: coinInfo.currency,
                    coin: item.coin,
                    address: item.address,
                    memo: item.memo,
                    placeholder: item.tag,
                },
            });
        }
    }
};

export function getCurrencyDisplayName(coinInfo: SupportDWCoinInfo) {
    return `${coinInfo.coinName}(${coinInfo.protocolDisplay})`;
}
