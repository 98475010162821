import { DebitCardChannel } from "landings/V2/CircleDebit/types";
import { QuotaChannel } from "utils/complianceQuota";
import { encryptDataWithPublicKey } from "utils/openPgp";
import SparkMD5 from "spark-md5";
import { ST } from "commonUse/Locale";

/**
 * Debit内部channel枚举值和全量channel的枚举值映射
 */
export const DEBIT_CHANEL__ALL_CHANNEL = { [DebitCardChannel.CIRCLE]: QuotaChannel.CIRCLE_CARD, [DebitCardChannel.CHECKOUT]: QuotaChannel.CHECKOUT_CARD };

/**
 * 是否禁止Checkout通道
 */
export const DISABLE_CHECKOUT = false;

/**
 * 加密卡片信息，根据不同的通道加密方式不一样，因为checkout的publicKey不能用来作为加密
 * @param type
 * @param dataToEncrypt
 * @param publicKey
 */
export function encryptCardInfo(type: DebitCardChannel, dataToEncrypt: { [k: string]: any }, publicKey: string): Promise<string> {
    if (type === DebitCardChannel.CIRCLE) {
        return encryptDataWithPublicKey(dataToEncrypt, publicKey).then((res) => res.encryptedMessageWithBase64);
    }

    return Promise.resolve(SparkMD5.hash(JSON.stringify(dataToEncrypt)));
}

/**
 * 校验Debit卡号
 * @param $st
 * @param value
 */
export function validateDebitCardNum($st: ST, value = "") {
    if (!value) {
        return Promise.reject($st("debit_add_card_num_format"));
    }
    if (!value.startsWith("4") && !value.startsWith("5")) {
        // 4开头为Visa，5开头为Master Card，若用户提交的卡号信息非4、5开头
        return Promise.reject($st("debit_add_card_unsupported_num"));
    }
    if (value.replace(/\s/g, "").length !== 16) {
        return Promise.reject($st("debit_add_card_num_format"));
    }
    return Promise.resolve();
}
