import { interval } from "rxjs";
import { scan, takeWhile } from "rxjs/operators";

export namespace ProgressUtils {
    /**
     * 进度条动画到指定位置
     * @param mill
     * @param start
     * @param to
     */
    export function progressTo(mill: number, start: number, to: number) {
        return interval(100).pipe(
            scan((acc, value) => acc + ((to - start) / mill) * 100, start),
            takeWhile((v) => v < to),
        );
    }
}
