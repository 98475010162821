import React, { useCallback, useMemo, useState } from "react";
import TReportLink, { Iconfont, TDropdownBlock, TLoading, TMenu, TMenuItem } from "components/v2/TinyComps";
import { $st } from "utils/i18n";
import cs from "./cs.m.less";
import { Divider } from "antd";
import AddAddressModal from "landings/V2/Address/components/AddAddressModal";
import { useToggle } from "src/commonUse/tools";
import { useDWPageContext } from "src/landings/DepositWithdraw";
import { AssetAddressItem, WhiteListStatus } from "src/landings/V2/Address/api";
import { SHOW_GA_BIND_GUIDE_REF } from "src/components-v2/Layout/GlobalMessages/GABindGuide";
import { Dropdown } from "@pionex-web-kit/components";

interface IAddressBookSeletorProps {
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
    onSelect: (item: AssetAddressItem) => void;
}
const AddressBookSeletor = ({ getPopupContainer, onSelect }: IAddressBookSeletorProps) => {
    const { currentCoin, onlyWhiteList, noTwoFactor, addressList, addressListLoading: loading, addressListLoaded: loaded, reFreshAddressList } = useDWPageContext();
    const currentCoinName = currentCoin.coinName;
    const [toggle, setToggle] = useState(false);
    const [visible, setVisible] = useState(false);
    const [addNEwVisible, { setTrue: showAddNew, setFalse: hideAddNew }] = useToggle();
    const { showAddressList, otherChainAddressList, displayCoinChain } = useMemo(() => {
        const showAddressList: AssetAddressItem[] = [];
        const otherChainAddressList: AssetAddressItem[] = [];
        addressList.forEach((address) => {
            if (address.chain === currentCoin.protocol) {
                showAddressList.push(address);
            } else {
                otherChainAddressList.push(address);
            }
        });
        const displayCoinChain = `${currentCoin.coinName}(${currentCoin.protocolDisplay})`;
        return { showAddressList, otherChainAddressList, displayCoinChain };
    }, [addressList, currentCoin.coinName, currentCoin.protocol]);
    const _onChange = useCallback(
        ({ key }) => {
            const targetAddress = addressList.find((address) => address.address_id === key);
            if (targetAddress) {
                onSelect(targetAddress);
                setVisible(false);
            }
        },
        [addressList, onSelect],
    );
    const onAddNewSuccess = useCallback(() => {
        hideAddNew();
        reFreshAddressList();
    }, [hideAddNew, reFreshAddressList]);

    const onOpenAddressBook = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (SHOW_GA_BIND_GUIDE_REF.showIfNeed(true)) {
            return;
        }
        setVisible(true);
    }, []);
    const showAddressListOptions = useMemo(() => {
        return showAddressList.map(({ address_id, memo, tag, address }) => (
            <TMenuItem key={address_id} className={cs.addressBookMenuItem}>
                <div>{`${$st("address_tag_name")}: ${tag}`}</div>
                <div>{`${$st("address_table_header_address")}: ${address}`}</div>
                {memo && <div>{`${$st("address_table_header_memo")}: ${memo}`}</div>}
            </TMenuItem>
        ));
    }, [showAddressList]);
    const otherChainAddressListOptions = useMemo(() => {
        return otherChainAddressList.map(({ address_id, memo, tag, address, chain, coin, whitelist, protocolDisplay }) => {
            return (
                <TMenuItem key={address_id} className={cs.addressBookMenuItem}>
                    <div>
                        {`${$st("address_tag_name")}: ${tag}`}
                        <span className={cs.addressTag}>{protocolDisplay}</span>
                        {!onlyWhiteList && whitelist && <span className={cs.addressTag}>{$st("white_list")}</span>}
                    </div>
                    <div>{`${$st("address_table_header_address")}: ${address}`}</div>
                    {memo && <div>{`${$st("address_table_header_memo")}: ${memo}`}</div>}
                </TMenuItem>
            );
        });
    }, [onlyWhiteList, otherChainAddressList]);
    const defaultValue = useMemo(() => ({ whitelist: onlyWhiteList === WhiteListStatus.on }), [onlyWhiteList]);
    return (
        <>
            <Dropdown
                visible={visible}
                onVisibleChange={setVisible}
                overlayClassName={`${cs.addressBookOverlay} !left-0 !top-[38px]`}
                getPopupContainer={getPopupContainer}
                overlay={
                    <>
                        <div className={cs.addressBookMenus}>
                            <div className={cs.addressBookMenuContent}>
                                <div className={cs.addressBookMenuTitle}>
                                    {$st("coin_chain_title", {
                                        coin: displayCoinChain,
                                    })}
                                </div>
                                {loading && !loaded && (
                                    <div className={cs.addressBookMenuEmpty}>
                                        <TLoading />
                                    </div>
                                )}
                                {loaded && addressList?.length > 0 && (
                                    <TMenu selectedKeys={[]} onClick={_onChange}>
                                        {showAddressListOptions}
                                    </TMenu>
                                )}
                                {loaded && !addressList?.length && (
                                    <div className={cs.addressBookMenuEmpty}>
                                        {onlyWhiteList
                                            ? $st("another_whitelist_address_tips", { coinAndChain: displayCoinChain })
                                            : $st("another_address_tips", { coinAndChain: displayCoinChain })}
                                    </div>
                                )}
                                {otherChainAddressList.length > 0 && (
                                    <div>
                                        <div className={cs.addressBookMenuTitle}>
                                            {$st("another_address_title", { coin: currentCoinName })}
                                            <TReportLink
                                                onClick={() => {
                                                    setToggle(!toggle);
                                                }}
                                            >
                                                {toggle ? $st("address_seletor_others_close") : $st("address_seletor_others_open")}{" "}
                                                <Iconfont className={toggle ? cs.openOthers : cs.closeOthers} icon="icon_More_down" />
                                            </TReportLink>
                                        </div>
                                        {toggle && (
                                            <TMenu selectedKeys={[]} onClick={_onChange}>
                                                {otherChainAddressListOptions}
                                            </TMenu>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <Divider />
                        <div className={cs.addressBookAddNew}>
                            <TReportLink
                                onClick={() => {
                                    setVisible(false);
                                    showAddNew();
                                }}
                            >
                                {$st("add_new_address")}
                            </TReportLink>
                        </div>
                    </>
                }
                trigger={noTwoFactor ? [] : ["click"]}
            >
                <TReportLink className={cs.addressBookBtn} onClick={onOpenAddressBook}>
                    {$st("address_book_title")}
                </TReportLink>
            </Dropdown>
            <AddAddressModal defaultValue={defaultValue} visible={addNEwVisible} onCancel={hideAddNew} onAddSuccess={onAddNewSuccess} coinType={currentCoin.currency} />
        </>
    );
};

export default AddressBookSeletor;
