/**
 * @author author
 * @date 2023/2/2
 * @time 14:27
 * @fileName index.tsx
 * @Description:
 */

import React from "react";
import { useCopyBotShareIdData } from "../use";
import { NumberCommonUtils, SafeDecimal } from "trade_utils_lib";
import OpenAppLoading from "../components/OpenAppLoading";
import ShareHeader from "../components/ShareHeader";
import { H3, Wrapper, WrapperColumn } from "../components/StyleComponent";
import { Spin } from "antd";
import bot_order_none_white from "../images/bot_order_none_white.png";
import ShareUserInfo from "../components/ShareUserInfo";
import Space from "../components/Space";
import OrderAnnualizedV2 from "../components/OrderAnnualizedV2";
import CopyButton from "../components/CopyButton";
import { useParams } from "react-router-dom";
import { copyTradeHandlePress, isMobile } from "../utils/index";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslators } from "commonUse/Locale";

const antIcon = <LoadingOutlined style={{ fontSize: 48, color: "rgb(255, 112, 40)" }} spin />;

const CopyBotShareTemplate = () => {
    const routerParams = useParams();
    const { order_type, order_id } = routerParams as any;
    const mobileDevice = isMobile();
    const [showOpenApp, setShowOpenApp] = React.useState(false);
    const [data, loading, error] = useCopyBotShareIdData(order_type, order_id);
    const { $st } = useTranslators();

    const profitPercent = React.useMemo(() => {
        return NumberCommonUtils.toPercent(new SafeDecimal(data.rota_usdt).mul(100), true, true);
    }, [data.roi, data.rota_usdt]);

    if (showOpenApp) {
        return <OpenAppLoading />;
    }

    if (loading) {
        return (
            <div>
                <ShareHeader />
                <Wrapper style={{ marginTop: "30%" }}>
                    <Spin indicator={antIcon} />
                </Wrapper>
            </div>
        );
    }

    if (data.status !== "open") {
        return (
            <div>
                <ShareHeader />
                <WrapperColumn style={{ marginTop: "45%" }}>
                    <img src={bot_order_none_white} style={{ width: 160, height: 160 }} alt={"close_order"} />
                    <H3 style={{ marginTop: 10 }}>{$st("share_order_closed")}</H3>
                </WrapperColumn>
            </div>
        );
    }

    return (
        <div style={!mobileDevice ? { maxWidth: 750, margin: "0 auto" } : {}}>
            <ShareHeader />
            <ShareUserInfo
                nickname={data.nickname}
                avatar={data.avatar}
                title={$st("share_introduce", {
                    symbol: " ",
                    strategy_bot: $st("quotation_mark", { content: data.botName }),
                })}
            />
            <Space size={20} />

            <OrderAnnualizedV2
                profitPercent={profitPercent}
                details={[
                    ["copy_bot_list_nnt", data.investNum],
                    ["lasting", data.orderCreateTime],
                    ["copy_bot_list_amount", `${NumberCommonUtils.toNormalPrice(data.investNumAmount)} ${data.investCoin}`],
                ]}
            />

            <CopyButton
                title={$st("copy_feed_view_all")}
                onPress={() => {
                    copyTradeHandlePress(data, order_id, order_type, () => setShowOpenApp(true));
                }}
                sticky={mobileDevice}
            />
        </div>
    );
};

export default CopyBotShareTemplate;
