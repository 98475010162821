/**
 * @author Zhoukai
 * @date 2021/4/7
 * @time 8:12 下午
 * @fileName index.tsx
 * @Description: 小额资产兑换
 */

import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import css from "./index.module.less";
import { useTranslators } from "commonUse/Locale";
import { Checkbox, List, message, Modal } from "antd";
import { Button, Breadcrumb } from "@pionex-web-kit/components";
import useAccountInfo from "commonUse/useAccountInfo";
import { useBalanceAll } from "commonUse/useBalance";
import useTheme from "commonUse/useTheme";
import { LayoutConfig } from "src/components-v2/Layout";
import ListCoinNameItem from "landings/Balance/components/ListTable/ListCoinNameItem";
import CustomAntdEmpty from "components/Antd/CustomEmpty";
import { CoinBalance } from "trade_asset_lib";
import { getTradeDependencyInstance, NumberCommonUtils } from "trade_utils_lib";
import { zip } from "rxjs";
import { acquireKeyResolver } from "trade_token_lib";
import { $st } from "utils/i18n";
import FSAModalFooter from "landings/PionexHistoricalOrderDetails/components/FSA/FSAAction/Components/FSAModalFooter";

export const SMALL_SPOT_CONDITION = 10;

export default () => {
    const { $st: str } = useTranslators();
    const theme = useTheme();
    const accountInfo = useAccountInfo();
    const balanceInfo = useBalanceAll();
    const [result, setResult] = useState<CoinBalance[]>([]);
    const [checkedList, setCheckedList] = useState([] as Array<string>);
    const [transferLoading, setTransferLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (balanceInfo !== undefined) {
            setResult(
                balanceInfo.trade.balances.filter((item) => {
                    const availableUSD = item.available * item.USDPrice;
                    return availableUSD < SMALL_SPOT_CONDITION && availableUSD > 0 && item.base !== "USDT";
                }),
            );
        }
    }, [balanceInfo]);

    const checkAll = useCallback(() => {
        const isAllChecked = checkedList.length === result?.length;

        if (isAllChecked) {
            setCheckedList([]);
        } else {
            const tmp: Array<string> = [];
            result?.forEach((info) => {
                const findIndex = tmp.findIndex((item) => item === info.base);
                if (findIndex < 0) {
                    tmp.push(info.base);
                }
            });
            setCheckedList(tmp);
        }
    }, [checkedList.length, result]);

    const checkSingle = useCallback(
        (item) => {
            const checkedIndex = checkedList.findIndex((i) => i === item.base);
            if (checkedIndex >= 0) {
                checkedList.splice(checkedIndex, 1);
                setCheckedList([...checkedList]);
            } else {
                checkedList.push(item.base);
                setCheckedList([...checkedList]);
            }
        },
        [checkedList],
    );

    const checkedValueAmount = useMemo(() => {
        let count = 0;
        result?.forEach((balanceInfo) => {
            const findIndex = checkedList.findIndex((currency) => currency === balanceInfo.base);
            if (findIndex >= 0) {
                count += balanceInfo.available * balanceInfo.USDPrice;
            }
        });
        return count;
    }, [result, checkedList]);
    const ListHeaderView = useMemo(() => {
        const headerTitleStr = ["balance.table.coin", "balance.table.quantity", "spot_balance_small_asset_transfer_available"];
        return (
            <div className={css.listHeader}>
                {headerTitleStr.map((item, index) => (
                    <div className={css.listHeaderItem}>
                        {index === 0 && (
                            <div className={css.itemCheckBox}>
                                <Checkbox className={css.checkboxAll} onChange={checkAll} checked={result.length > 0 && checkedList.length === result.length} />
                            </div>
                        )}
                        <div style={{ color: theme.colorText5 }}>
                            <span>{str(item)}</span>
                        </div>
                    </div>
                ))}
            </div>
        );
    }, [checkAll, checkedList.length, result.length, str, theme.colorText5]);

    const onSwapConfirm = useCallback(() => {
        if (accountInfo && accountInfo.firstApiKey) {
            const url = `${getTradeDependencyInstance().getRunTimeHost().pionexSpotApiHost}/swap/`;
            if (!checkedList.length) {
                setVisible(false);
                setTransferLoading(false);
                return;
            }

            const convertObservables = checkedList.map((currency) => {
                return acquireKeyResolver().requestSigner.getSignRequestV2("POST", url, JSON.stringify({ source_coin: currency }));
            });

            setTransferLoading(true);
            zip(...convertObservables).subscribe(
                (result) => {
                    setVisible(false);
                    setTransferLoading(false);
                    setCheckedList([]);
                    message.success($st("spot_balance_small_transfer_success"));
                    window.localStorage.setItem("asset_swap_interval_limit" as any, `${Date.now()}`);
                },
                (error) => {
                    setVisible(false);
                    setTransferLoading(false);
                    if (error && error.message && error.message.indexOf("TAPI_TRADE_SWAP_NO_PRICE") >= 0) {
                        message.error($st("spot_balance_small_transfer_failed_no_price"));
                    } else {
                        message.error($st("spot_balance_small_transfer_failed"));
                    }
                },
            );
        }
    }, [accountInfo, checkedList]);

    const transferAction = useCallback(() => {
        let result = window.localStorage.getItem("asset_swap_interval_limit" as any);
        if (result === "0" || Date.now() - Number(result) >= 6 * 60 * 60 * 1000) {
            setVisible(true);
        } else {
            message.error($st("spot_balance_small_transfer_interval_limit"));
        }
    }, []);

    return (
        <LayoutConfig requireLogin>
            <div className="px-36px py-24px">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={"/balance/trade"}>{$st("balance_portfolio")}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{$st("spot_balance_small_asset_transfer")}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className={css.pageHeader}>
                <div className={css.pageHeaderInner}>
                    <div>
                        <span>{str("spot_balance_small_transfer_select_info_1")}</span>
                        <span style={{ color: theme.colorAccent2 }}>{checkedList.length}</span>
                        <span style={{ marginLeft: 20 }}>{str("spot_balance_small_transfer_select_info_2")}</span>
                        <span style={{ color: theme.colorAccent2 }}>{NumberCommonUtils.effectiveDecimal(checkedValueAmount, 6)}</span>
                        <span>{" USDT"}</span>
                    </div>
                    <Button type={"primary"} className={css.transferButton} disabled={!checkedList.length} onClick={transferAction}>
                        {str("spot_balance_small_asset_do_transfer")}
                    </Button>
                </div>
            </div>
            {ListHeaderView}
            <div className={css.listView}>
                <List
                    dataSource={result}
                    loading={false}
                    pagination={false}
                    renderItem={(item, index) => (
                        <div className={index % 2 === 0 ? `${css.itemContainer} ${css.itemContainerColor2}` : `${css.itemContainer} ${css.itemContainerColor1}`}>
                            <div className={css.subItem}>
                                <div className={css.itemCheckBox}>
                                    <Checkbox className={css.checkboxItem} onChange={() => checkSingle(item)} checked={checkedList.findIndex((i) => i === item.base) >= 0} />
                                </div>
                                <ListCoinNameItem baseDisplay={item.baseDisplay} coin={item.baseDisplay} fullName={item.fullName || ""} />
                            </div>
                            <div className={css.subItem}>
                                <span>{NumberCommonUtils.effectiveDecimal(item.available, 8)}</span>
                            </div>
                            <div className={css.subItem}>
                                <span>{NumberCommonUtils.effectiveDecimal(item.available * item.USDPrice, 6)}</span>
                            </div>
                        </div>
                    )}
                    locale={{
                        emptyText: <CustomAntdEmpty loading={false} dataLength={result?.length} no_data_value={str("spot_balance_small_transfer_no_coins")} />,
                    }}
                />
            </div>
            <Modal className={css.modalContainer} title={$st("spot_balance_small_asset_transfer_confirm")} visible={visible} closable={false} footer={null}>
                <div>
                    <div className={css.modalItemContainer}>
                        <span>{str("spot_balance_small_asset_quantity")}</span>
                        <span>{`${NumberCommonUtils.effectiveDecimal(checkedValueAmount, 6)} USDT`}</span>
                    </div>
                    <div className={css.modalItemContainer}>
                        <span>{str("spot_balance_small_asset_fee")}</span>
                        <span>{`${NumberCommonUtils.effectiveDecimal(checkedValueAmount * 0.0005, 6)} USDT`}</span>
                    </div>
                    <div className={css.modalItemContainer}>
                        <span>{str("spot_balance_small_asset_real_amount")}</span>
                        <span>{`${NumberCommonUtils.effectiveDecimal(checkedValueAmount * 0.9995, 6)} USDT`}</span>
                    </div>
                </div>
                <FSAModalFooter onCommit={onSwapConfirm} setVisible={setVisible} submitting={transferLoading} disable={transferLoading} />
            </Modal>
        </LayoutConfig>
    );
};
