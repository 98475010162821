import useAccountInfo from "commonUse/useAccountInfo";
import { useEffect, useMemo, useState } from "react";
import { AuthType, Network } from "trade_utils_lib";
import Constants from "utils/Constants";

let OPEN_ORDER_TIME: AccountFirstOrderTime;
let OPEN_ORDER_TIME_PROMISE: Promise<AccountFirstOrderTime>;

export interface AccountFirstOrderTime {
    dual_auto: number; // 双币
    future_grid: number; // 合约网格
    grid: number; //网格机器人
    manual_perp: number; //合约手动单
    manual_spot: number; //现货手动单
    staking: number; // staking 属性
}

export const getOpenOrderTime = (bu_order_type?: string) => {
    if (OPEN_ORDER_TIME) {
        return Promise.resolve(OPEN_ORDER_TIME);
    }
    if (OPEN_ORDER_TIME_PROMISE) {
        return OPEN_ORDER_TIME_PROMISE;
    }
    OPEN_ORDER_TIME_PROMISE = Network.get<AccountFirstOrderTime>({
        url: `${Constants.messageCenter}/tapi/v1/open_order_time`,
        authType: AuthType.FullToken,
        data: bu_order_type ? { bu_order_type } : {},
    }).toPromise();
    return OPEN_ORDER_TIME_PROMISE;
};

export const useOpenOrderTime = (bu_order_type?: string) => {
    const accountInfo = useAccountInfo();
    const [openOrderTime, setOpenOrderTime] = useState<AccountFirstOrderTime | null>();

    useEffect(() => {
        if (!accountInfo.userId) {
            return;
        }
        getOpenOrderTime(bu_order_type)
            .then((result) => {
                setOpenOrderTime(result);
            })
            .catch(() => {
                setOpenOrderTime(null);
            });
    }, [accountInfo.userId, bu_order_type]);
    const haveTraded = useMemo(() => {
        if (!openOrderTime) return undefined;
        return Object.keys(openOrderTime).some((k) => openOrderTime[k]);
    }, [openOrderTime]);
    return {
        openOrderTime,
        haveTraded,
    };
};
