import classNames from "classnames";
import React, { useState, useCallback, useEffect, useRef, useMemo } from "react";
import css from "./index.module.less";
import Form from "./commpoents/Form";
import TaxesAPI, { RecordResult, TransactionApplyParams } from "./taxesApi";
import moment from "moment";
import { useTranslators } from "commonUse/Locale";
import { taxesDateFormat } from "./commpoents/const";
import useAccountInfo from "commonUse/useAccountInfo";
import { Button, Modal, Spin, message } from "@pionex-web-kit/components";
import { useV1AccessToken } from "commonUse/useV1Token";

enum RecordType {
    processing = 1,
    success,
}

export default function () {
    const FormRef = useRef<any>(null);
    const { $st } = useTranslators();
    const [recordInfo, setRecordInfo] = useState<RecordResult>();
    const [transactionApplyParams, setTransactionApplyParams] = useState<TransactionApplyParams>();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const { masterAccount, migrate_time } = useAccountInfo();
    const v1AccessToken = useV1AccessToken();
    const handleSubmit = useCallback(() => {
        FormRef.current?.submit();
    }, []);
    const handleFormFinish = (values: any) => {
        setTransactionApplyParams(values);
        setVisible(true);
    };

    const getRecordInfo = useCallback(() => {
        if (!v1AccessToken?.token) return;
        setLoading(true);
        TaxesAPI.getRecordV1(v1AccessToken?.token)
            .then((res) => {
                setLoading(false);
                setRecordInfo(res);
            })
            .catch((err) => {
                setLoading(false);
            });
    }, [v1AccessToken?.token]);
    useEffect(() => {
        getRecordInfo();
    }, [getRecordInfo]);
    const invokedSubmit = useCallback(() => {
        if (!v1AccessToken?.token) return;
        setVisible(false);
        if (recordInfo?.export_record?.status === RecordType.processing) {
            message.warn($st("taxes_processing_warn"));
            return;
        }
        if (transactionApplyParams) {
            setLoading(true);
            // 兼容主站迁移用户 导出最小时间为迁出时间 时间为毫秒级
            let _number = isNaN(migrate_time as number) ? 0 : Number(migrate_time);
            const _startTime = Math.max(transactionApplyParams.start_time, moment(_number).unix());
            TaxesAPI.postRecordV1(
                {
                    ...transactionApplyParams,
                    start_time: _startTime,
                    end_time: Math.max(transactionApplyParams.end_time, _startTime),
                },
                v1AccessToken?.token,
            )
                .then((res) => {
                    message.success($st("taxes_successfully"));
                    getRecordInfo();
                })
                .catch((err) => {
                    message.error(err?.message);
                    setLoading(false);
                })
                .finally(() => {
                    FormRef.current?.reset();
                });
        }
    }, [$st, getRecordInfo, migrate_time, recordInfo?.export_record?.status, transactionApplyParams, v1AccessToken?.token]);
    const handleDownload = useCallback(() => {
        if (recordInfo?.export_record?.path) {
            window.open(recordInfo.export_record.path);
        }
    }, [recordInfo?.export_record?.path]);

    const dataRange = useMemo(() => {
        if (!transactionApplyParams) return null;
        const { start_time, end_time } = transactionApplyParams;
        const formatter = (data) => moment.unix(data).format(taxesDateFormat);
        return `${formatter(start_time)} - ${formatter(end_time)}`;
    }, [transactionApplyParams]);

    const Record = useMemo(() => {
        switch (recordInfo?.export_record?.status) {
            case RecordType.processing:
                return (
                    <div className={classNames(css.recordInfo, css.recordInfoPeng)}>
                        <span>
                            {$st("taxes_last")}({moment.unix(recordInfo?.export_record.create_time).format(taxesDateFormat)}）
                        </span>
                        <span> {$st("taxes_processing")}</span>
                    </div>
                );
            case RecordType.success:
                return moment().isSameOrAfter(moment.unix(recordInfo?.export_record.expire_time)) ? (
                    <div className={classNames(css.recordInfo)}>
                        <span>
                            {$st("taxes_last")}({moment.unix(recordInfo?.export_record.create_time).format(taxesDateFormat)}）
                        </span>
                        <Button type="primary" className={classNames(css.recordDownload)} disabled>
                            {$st("taxes_download")}
                        </Button>
                    </div>
                ) : (
                    <div className={classNames(css.recordInfo, css.recordInfoSuccess)}>
                        <span>
                            {$st("taxes_last")}({moment.unix(recordInfo?.export_record.create_time).format(taxesDateFormat)}）
                        </span>
                        <Button type="primary" onClick={handleDownload} className={classNames(css.recordDownload, css.recordDownloadSuccess)}>
                            {$st("taxes_download")}
                        </Button>
                    </div>
                );
            default:
                return (
                    <div className={classNames(css.recordInfo)}>
                        <span>{$st("taxes_not")}</span>
                        <Button type="primary" className={classNames(css.recordDownload)} disabled>
                            {$st("taxes_download")}
                        </Button>
                    </div>
                );
        }
    }, [$st, handleDownload, recordInfo?.export_record?.create_time, recordInfo?.export_record?.expire_time, recordInfo?.export_record?.status]);

    return (
        <>
            <Spin spinning={loading}>
                <div className={css.container}>
                    <div className={css.content}>
                        <p className={classNames(css.title, "text-center")}>{$st("taxes_titie")}</p>
                        <p className={css.warn}>
                            <span className={css.remain_num}>{recordInfo?.remain_num}</span> {$st("taxes_frequency")}
                        </p>
                        <Form ref={FormRef} onFinish={handleFormFinish} />
                        <p className={css.tips}>{$st("taxes_hint")}</p>
                        <Button type="primary" onClick={handleSubmit} className={css.button}>
                            Submit
                        </Button>
                        <div className={css.record}>
                            <p className={css.recordTitle}>{$st("taxes_last_record")}</p>
                            {Record}
                            {!!recordInfo?.export_record?.expire_time &&
                                (moment().isSameOrAfter(moment.unix(recordInfo?.export_record.expire_time)) ? (
                                    <p className={css.has_expire}>{$st("taxes_has_expire")}</p>
                                ) : (
                                    <p className={css.expire_time}>
                                        {$st("taxes_expire_time", { time: moment.unix(recordInfo?.export_record.expire_time).format(taxesDateFormat) })}
                                    </p>
                                ))}
                        </div>
                    </div>
                </div>
            </Spin>
            <Modal
                centered
                onOk={invokedSubmit}
                onCancel={() => setVisible(false)}
                className={css.main}
                maskClosable={false}
                visible={visible}
                width={400}
                title={$st("taxes_export_confirm")}
            >
                <div className={css.modalCon}>
                    <div className={css.info}>
                        <span>{$st("taxes_export_account")}</span>
                        <span>{masterAccount?.account || ""}</span>
                    </div>
                    <div className={css.info}>
                        <span>{$st("taxes_export_time")}</span>
                        <span>{dataRange}</span>
                    </div>
                    <p className={css.warnInfo}>{$st("taxes_hint")}</p>
                </div>
            </Modal>
        </>
    );
}
