import { useEffect, useState } from "react";
import DataSubject from "src/utils/DataSubject";
import { ExchangeTradePair } from "TradeLib/TradeTypes";
import AccountManager from "utils/AccountManager";
import { ExchangeID } from "TradeAPILib";

export function useFavoriteSymbols(exchange: string = ExchangeID.PIONEXV2): Array<ExchangeTradePair> {
    const [favorList, changeFavorList] = useState(AccountManager.shared.getCollectionList(exchange) as ExchangeTradePair[]);

    useEffect(() => {
        const dataSubscription = DataSubject.subscribe((type, data) => {
            if (type === DataSubject.TYPE_COLLECT_LIST_CHANGED) {
                const favorites = AccountManager.shared.getCollectionList(exchange) as ExchangeTradePair[];
                changeFavorList(favorites ? [...favorites] : []);
            }
        });
        return () => dataSubscription && dataSubscription.unsubscribe();
    }, [exchange]);

    return favorList;
}
