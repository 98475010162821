import SideBar, { SideBarViewRef } from "src/components-v2/Layout/SideBar";
import React, { FC, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AppState from "state/AppState";
import { getLocaleDefaultCurrency } from "state/CurrencyConfig";
import ExchangeRate, { EXCHANGE_RATE_KEY } from "state/ExchangeRate";
import AccountManager from "utils/AccountManager";
import css from "./cs.m.less";
import RedGreenManager from "./RedGreanManager";
import HeaderV2 from "./Header.v2";
import classnames from "classnames";
import { TLoading } from "src/components/v2/TinyComps";
import { CTXLayoutConfig, LayoutConfig, LayoutConfigRoot } from "./Config";
import MessagerService from "./MessagerService";
import GlobalMessages from "./GlobalMessages";
import PubSub from "pubsub-js";
import { LAYOUT_EVENT_CONTENT_GO_TOP, usePageDescription } from "./utils";
import { OuterWrappers } from "./Wrappers";
import useMedia from "./hooks/useMedia";
import { useIntl } from "react-intl";
import Footer from "./Footer.v2";
import { PionexReportV2 } from "landings/Analytics/ReportV2";
import { useLimitRegion } from "commonUse/useLimitRegion";
import { useCheckShowSSN } from "landings/V2/Account/paths/Kyc-v2/components/SSN";
import { useOnlineConfig } from "utils/onlineConfig";
import { CTXJoinBeta } from "commonUse/useJoinInternalTest";
import { FromName } from "landings/Analytics/EventName";
import { DepositGuide } from "components/DepositGuideModal";

export * from "./Config";

export const TRADE_PAGE_KLINE_SIZE = "trade_page_kline_size";

/**
 * [Layout]组件 和 [LayoutConfig]组件 是 原[landings/App]组件 的抽象分离组件。
 * #### [Layout]组件
 * 该用于统一布局的框架（如公共的header、sidebar等组件），已经被提升至全局<Router/>节点之上，大家在业务代码中不需要使用。
 *
 * **注：**
 * - 分离后的Layout组件，解决了原有页面切换是导致页面整体卸载重绘的问题。
 * - 当下切换页面仅会卸载、重绘公共布局组件之外的页面业务组件，大大提高了业务页面的渲染性能，降低了用户等待时间。
 *
 * #### [LayoutConfig]组件
 * **原则上，需要大家在每一个页面组件中使用该组件。若页面组件不使用该配置组件，将导致当前页面渲染时会复用上一次页面的布局配置。**
 *
 * 该组件的用法与原有的 [landings/App]组件 完全一致，只是现在是一个纯配置组件，没有任何DOM节点。
 * @see {@link LayoutConfig}
 */
const LayoutOrigin: FC<{ children?: ReactNode | (({}) => ReactNode) }> = ({ children }) => {
    const [
        {
            id,
            loading,
            requireLogin,
            showManualServiceBtn,
            className,
            layoutRootClassName,
            contentFullFill,
            contentFullWidthOnly: contentFullWidth,
            showHeader,
            showFooter,
            style,
            showSidebar,
            hiddenScrollbar,
            headerV2Props = {},
            tradingLayout,
            whiteBg,
            showGlobalMessage,
            onContentScroll,
        },
    ] = useContext(CTXLayoutConfig);

    const { betaTestData } = useOnlineConfig();
    const { _registerBetaModuleId } = useContext(CTXJoinBeta);
    useEffect(() => {
        if (!!betaTestData) {
            _registerBetaModuleId(betaTestData);
        }
    }, [_registerBetaModuleId, betaTestData]);
    const { accountInfo } = useSelector(({ accountInfo }: AppState) => ({
        accountInfo,
    }));
    const { locale } = useIntl();
    const sidebarRef = React.useRef<SideBarViewRef>(null);
    const { isMobile, isTablet, isOpenInWebview } = useMedia();
    //检测是否登录
    useEffect(() => {
        if (requireLogin && accountInfo.isInitialized && !accountInfo.userId) {
            AccountManager.toSign(FromName.layout, { redirectBack: true });
        }
        if (PLATFORM.PIONEX_US_LIKE && accountInfo.isInitialized) {
            if (accountInfo.userId)
                // 若已经登录了，则不再上报新用户统计
                window.localStorage.setItem("--us-new-user-ana-registered--", "1");
            else {
                // 上报新用户的访问
                PionexReportV2.reportNewUserViewing();
            }
        }
    }, [requireLogin, accountInfo.userId, accountInfo.isInitialized]);

    useEffect(() => {
        if (window.localStorage.getItem(EXCHANGE_RATE_KEY)) {
            return;
        }
        ExchangeRate.availableActions.refreshExchangeRate(getLocaleDefaultCurrency(locale));
    }, [locale]);

    const limitModal = useLimitRegion();
    useCheckShowSSN({ actively: true });

    const handleResizeSidebar = React.useCallback(() => {
        if (sidebarRef.current && sidebarRef.current.resize) {
            sidebarRef.current.resize();
        }
    }, []);

    // 处理一些Layout的UI功能
    const appWrapRef = useRef<HTMLDivElement>(null);
    const fullFillBoxRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const goTop = PubSub.subscribe(LAYOUT_EVENT_CONTENT_GO_TOP, (e) => {
            appWrapRef.current?.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            fullFillBoxRef.current?.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        });
        return () => {
            PubSub.unsubscribe(goTop);
        };
    }, []);

    usePageDescription();

    const [mobileNavVisible, setMobileNavVisible] = useState(false);

    const isReady = !requireLogin || !!accountInfo?.userId;
    const showLoading = !isReady || loading;
    return (
        <div
            id="app_wrap"
            ref={appWrapRef}
            style={style}
            onScroll={onContentScroll}
            onClick={handleResizeSidebar}
            className={classnames(
                layoutRootClassName,
                css.appWrap,
                whiteBg && css.whiteBg,
                // loading时，强制全屏，以免出现颜色分层的想象
                (contentFullFill || tradingLayout || loading) && css.fullFill,
                contentFullWidth && css.fullWidth,
                tradingLayout && !isTablet && css.tradingLayout,
                hiddenScrollbar && css.hiddenScrollbar,
                locale === "ko" && css.fontKo,
                mobileNavVisible && css.overflowHidden,
            )}
        >
            {showHeader && !isOpenInWebview && <HeaderV2 {...headerV2Props} tradingView={tradingLayout} onMobileNavVisibleChange={setMobileNavVisible} />}
            <div className={css.bodyBox}>
                <div className={classnames(css.fullFillWrap)}>
                    <div
                        className={classnames(contentFullFill && css.fullFillBox, !(contentFullWidth || contentFullFill || tradingLayout) && "center-container", className)}
                        ref={fullFillBoxRef}
                        onScroll={onContentScroll}
                        // 用于解决短内容时，footer显示排版问题
                        style={showFooter ? { minHeight: "80vh" } : {}}
                    >
                        <LayoutConfig id="layout">{typeof children === "function" ? children({}) : children}</LayoutConfig>
                    </div>
                    <div className={css.pageLoading} style={showLoading ? undefined : { display: "none" }}>
                        <TLoading />
                    </div>
                </div>
                <div
                    id="sider_popover"
                    style={
                        showSidebar && !isMobile
                            ? {
                                  ...(!tradingLayout
                                      ? {
                                            position: "fixed",
                                            top: 52,
                                            right: 0,
                                            height: "100%",
                                        }
                                      : { position: "relative" }),
                                  width: 48,
                              }
                            : { display: "none" }
                    }
                >
                    <SideBar ref={sidebarRef} />
                </div>
            </div>
            {showFooter && !isOpenInWebview && <Footer />}
            {/*/!* 显示的的控制隐藏按钮。若没有指定，或为true时，都会走原有的判别逻辑 *!/*/}
            <MessagerService show={!mobileNavVisible ? (isOpenInWebview ? false : !!showManualServiceBtn) : false} />
            <RedGreenManager />
            {showGlobalMessage && !isOpenInWebview && <GlobalMessages />}
            {limitModal}
            <DepositGuide />
        </div>
    );
};

export const Layout: FC = (props) => (
    <LayoutConfigRoot>
        <OuterWrappers>
            <LayoutOrigin {...props} />
        </OuterWrappers>
    </LayoutConfigRoot>
);

export default Layout;
