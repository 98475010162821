import { ACH_AccountItem, deleteACHAccount, getStatusTips, useACHAmountLimit, useACHFee, useBankList, useUserLevelLimit } from "landings/V2/CircleACH/utils";
import React, { ReactNode, useMemo } from "react";
import { PaymentMethod, PaymentMethodChannel } from "landings/V2/BuyCrypto/Buy/cards/index";
import { ST, useTranslators } from "commonUse/Locale";
import { Iconfont, TButton } from "components/v2/TinyComps";
import useSignRequest from "commonUse/useSignRequest";
import { FormInstance, Modal } from "@pionex-web-kit/components";
import { LinkPlaidWrap } from "landings/V2/CircleACH/plaid";
import { BuyCryptoFailReason, BuyCryptoParams } from "../../types";
import { getCircleFailReasonText } from "landings/V2/USDDW/utils";
import { forkJoin, Observable } from "rxjs";
import { NumberCommonUtils, ObservableUtils, SafeDecimal } from "trade_utils_lib";
import Constants from "utils/Constants";
import { LocaleIPData } from "commonUse/tools";
import { KycV2Info, KycV3OriginData } from "commonUse/useKycInfoV2";
import { genAgreementItems, requestIpLoc } from "landings/V2/CircleACH/AgreementModal";
import useDateFormatter from "commonUse/useDateFormatter";
import { map } from "rxjs/operators";
import cs from "../index.m.less";
import { FORM_KEY_PAYMENTMETHOD } from "landings/V2/BuyCrypto/components/Form";

type Config = {
    $st: ST;
    locale: string;
    onCardDeleted: (card: ACH_AccountItem) => void;
    onACHBindSucceed: () => void;
    genAgreementItems: (amount: string, ipInfo?: LocaleIPData, originData?: KycV2Info["originData"]) => ReturnType<typeof genAgreementItems>;
    maxAmount: number;
    rollingMaxAmount: number;
    userLevelLimitText?: string;
};

const Com: React.FC<{ card: ACH_AccountItem; disableDelete?: boolean } & Pick<Partial<Config>, "onCardDeleted">> = ({ card, onCardDeleted, disableDelete }) => {
    const { status, account_number, plaid_info } = card;
    const { $st } = useTranslators();
    const signRequest = useSignRequest();

    return (
        <div className="flex justify-between items-center flex-1">
            {/*<Iconfont icon="icon_BankTransfer" />*/}
            <span>
                {plaid_info?.plaid_institution_name} ({account_number || "********"}) <span>({$st(`card_status:${status}`)})</span>
            </span>
            {disableDelete || (
                <Iconfont
                    className="deleteIcon"
                    icon="collect"
                    onClick={(e) => {
                        e.stopPropagation();
                        deleteACHAccount(signRequest, card, () => {
                            onCardDeleted?.(card);
                        });
                    }}
                />
            )}
        </div>
    );
};

const PendingModal: React.FC<{ card: ACH_AccountItem; onCancel: () => void }> = ({ onCancel, card }) => {
    const { $st } = useTranslators();

    return (
        <Modal visible title={$st("buy_crypto_ach_title_pending")} cancelButtonProps={{ style: { display: "none" } }} onOk={onCancel}>
            <div>{getCardText(card)}</div>
            <div className={cs.tipsTitle}>{$st("ach_joint_second_content_pending_content")}</div>
        </Modal>
    );
};

const REAUTHModal: React.FC<{ onCancel: () => void; card: ACH_AccountItem; onACHBindSucceed: () => void }> = ({ onCancel, card, onACHBindSucceed }) => {
    const { $st } = useTranslators();

    return (
        <Modal
            visible
            title={$st("buy_crypto_ach_title_reauth")}
            cancelButtonProps={{ style: { display: "none" } }}
            footer={
                <div className="flex justify-end">
                    <TButton className="mr-16px" type={"bordered"} onClick={onCancel}>
                        {$st("button_cancel")}
                    </TButton>
                    <LinkPlaidWrap onACHBindSucceed={onACHBindSucceed} ach_account_id={card.id}>
                        <TButton type={"primary"}>{$st("ach_expired_cover_btn")}</TButton>
                    </LinkPlaidWrap>
                </div>
            }
        >
            <div>{getCardText(card)}</div>
            <div className={cs.tipsTitle}>{$st("ach_expired_cover_tips_title")}</div>
        </Modal>
    );
};

const FailedModal: React.FC<{ onCancel: () => void; card: ACH_AccountItem }> = ({ onCancel, card }) => {
    const { $st } = useTranslators();
    const text = getStatusTips($st, card);

    return (
        <Modal visible title={$st("buy_crypto_ach_title_failed")} cancelButtonProps={{ style: { display: "none" } }} onOk={onCancel}>
            <div>{getCardText(card)}</div>
            <div className={"text-[16px]  text-accent"}>{text}</div>
        </Modal>
    );
};

const Fee: React.FC = () => {
    const { $st } = useTranslators();
    const { default_display, activity_display } = useACHFee() ?? {};

    return (
        <span>
            <span className="line-through">{default_display}</span>
            <span className="text-green">{$st("buy_crypto_fee_free")}</span>
        </span>
    );
};

/**
 * 生成ACH支付方式的hooks
 * @param form
 */
export function useACHPaymentMethods(form: FormInstance) {
    const { $st, locale } = useTranslators();
    const formatter = useDateFormatter();
    const { maxAmount, rollingMaxAmount } = useACHAmountLimit();
    const userLevelLimitText = useUserLevelLimit();

    const { bankList, getBankList, listLoading, dataLoaded } = useBankList(false);

    return {
        data: useMemo(
            () =>
                bankList.map(
                    (item) =>
                        new ACHPaymentMethod(PaymentMethodChannel.ACH, item, {
                            userLevelLimitText,
                            maxAmount,
                            rollingMaxAmount,
                            $st,
                            locale,
                            onACHBindSucceed: getBankList,
                            onCardDeleted: (card) => {
                                getBankList();
                                // 删除的当前选中卡，选中初始值
                                if (form.getFieldValue(FORM_KEY_PAYMENTMETHOD) === ACHPaymentMethod.genKey(PaymentMethodChannel.ACH, item)) {
                                    form.setFieldsValue({ payMethod: PaymentMethodChannel.BALANCE });
                                }
                            },
                            genAgreementItems: (amount, ipInfo, originData) => {
                                return genAgreementItems({ $st, accountItem: item, amount, formatter, ipInfo, originData });
                            },
                        }),
                ),
            [$st, bankList, form, formatter, getBankList, locale, maxAmount, rollingMaxAmount, userLevelLimitText],
        ),
        refresh: getBankList,
        loading: listLoading,
        dataLoaded,
    };
}

/**
 * ACH卡片支付的实现
 */
export class ACHPaymentMethod implements PaymentMethod<ACH_AccountItem> {
    channel: PaymentMethodChannel;
    card: ACH_AccountItem;
    config: Config;

    constructor(channel: PaymentMethodChannel, card: ACH_AccountItem, config: Config) {
        this.card = card;
        this.channel = channel;
        this.config = config;
    }

    static getFailReason($st: ST, { [PaymentMethodChannel.ACH]: achReason }: BuyCryptoFailReason): Undefinable<string> {
        if (!achReason?.failReason && !achReason?.failDetail) {
            return achReason?.msg;
        }
        return getCircleFailReasonText($st, achReason?.failReason, achReason?.failDetail);
    }

    static genKey(channel: PaymentMethodChannel, card: ACH_AccountItem) {
        return `${channel}_${card.id}`;
    }

    getKey(): string {
        return ACHPaymentMethod.genKey(this.channel, this.card);
    }

    renderItem(): ReactNode {
        return <Com card={this.card} onCardDeleted={this.config.onCardDeleted} />;
    }

    renderExtraContent(onCancel: () => void): React.ReactNode {
        switch (this.card.status) {
            case "PENDING":
                return <PendingModal card={this.card} onCancel={onCancel} />;
            case "REAUTHENTICATION":
                return <REAUTHModal onCancel={onCancel} card={this.card} onACHBindSucceed={this.config.onACHBindSucceed} />;
            case "FAILED":
                return <FailedModal onCancel={onCancel} card={this.card} />;
            default:
                return null;
        }
    }

    getFee(): string {
        return "0";
    }

    renderFee(): React.ReactNode {
        return <Fee />;
    }

    disabled(): boolean {
        return this.card.status !== "SUCCESS";
    }

    getExtraParams(params: BuyCryptoParams): Observable<object> {
        return forkJoin([
            ObservableUtils.getV2<KycV3OriginData>(`${Constants.accountServiceHost}/user/get_kyc_info_v3`, { lang: this.config.locale }, {}, true),
            requestIpLoc(),
        ]).pipe(map(([{ kycInfo }, ipInfo]) => ({ agreement: JSON.stringify(this.config.genAgreementItems(params.fiat_amount, ipInfo, kycInfo)) })));
    }

    getAmountLimitText(): React.ReactNode {
        const { $st, userLevelLimitText } = this.config;

        return (
            <div className="text-accent-sub">
                {userLevelLimitText}
                <div>
                    {$st("buy_crypto_available_ach", {
                        remain: <span className="font-sb text-accent"> ${NumberCommonUtils.formatterDecimalToStr(this.config.rollingMaxAmount, 2)}</span>,
                    })}
                </div>
            </div>
        );
    }

    amountValidator(amount: number): Promise<void> {
        const { $st, maxAmount } = this.config;

        if (new SafeDecimal(amount).greaterThan(maxAmount)) {
            return Promise.reject($st("buy_crypto_amount_exceeded", { max: maxAmount }));
        }
        return Promise.resolve();
    }
}

function getCardText(card: ACH_AccountItem) {
    const { account_number, plaid_info } = card;
    return `${plaid_info?.plaid_institution_name} (${account_number || "********"})`;
}
