import React, { useMemo, useCallback } from "react";
import { $st } from "src/utils/i18n";
import { useStrategyBalance, useTotalAssetExcludeBot, IStrategyFrozenBalanceRenderDataInfo, strategyTagMap } from "./hooks";
import useAccountInfo from "commonUse/useAccountInfo";
import { TLoading } from "src/components/v2/TinyComps";
import { SafeDecimal, NumberCommonUtils } from "trade_utils_lib";
import { ExchangeOrderType } from "TradeAPILib";
import { OrderUtils } from "src/utils/OrderUtils";
import cs from "./cs.m.less";
import { history } from "utils/History";
import { StakingProduct } from "landings/V2/staking/StakingService";
import AccountManager from "utils/AccountManager";
import { Modal } from "@pionex-web-kit/components";
import { IconRightArrow12pxRegular } from "@pionex-web-kit/icons";
import { useSymbolData } from "@pionex-web-kit/common-business";

const OccupyStatus = ({
    visible,
    base,
    baseDisplay,
    fullName,
    onCancel,
    onOk,
    filterStrategyTag,
    stakingProduct,
}: {
    visible: boolean;
    base: string;
    baseDisplay: string;
    fullName: string;
    onCancel: () => void;
    onOk: () => void;
    filterStrategyTag?: number[];
    stakingProduct?: StakingProduct[];
}) => {
    const accountInfo = useAccountInfo();
    const apiKey = accountInfo.firstApiKey;
    const [strategyBalanceLoading, strategyBalance] = useStrategyBalance();
    const [totalAssetExcludeBot] = useTotalAssetExcludeBot(apiKey, false);
    const symbolData = useSymbolData();
    const frozenAssetExcludeBot: IStrategyFrozenBalanceRenderDataInfo[] = useMemo(() => {
        return totalAssetExcludeBot
            .filter((asset) => new SafeDecimal(asset.frozen).greaterThan(0) && asset.currency === base)
            .map((res) => {
                const symbolInfo = symbolData?.symbolMap.get(`${res.currency}_${"USDT"}`);
                const v: IStrategyFrozenBalanceRenderDataInfo = {
                    type: "manual",
                    strategyTag: 9999,
                    strategyId: 9999,
                    coin: symbolInfo?.base ?? res.currency,
                    frozen: res.frozen,
                    symbol: symbolInfo?.baseDisplay ?? res.currency,
                    orderType: $st("occupy_type_other"),
                    base: symbolInfo?.base ?? res.currency,
                    quote: "",
                };
                return v;
            });
    }, [base, symbolData?.symbolMap, totalAssetExcludeBot]);
    const getStrategySymbol = useCallback(
        (coin1, coin2) => {
            if (!coin2) {
                if (symbolData && symbolData.symbolMap && symbolData.symbolMap.get(`${coin1}_${"USDT"}`)) {
                    return {
                        base: symbolData.symbolMap.get(`${coin1}_${"USDT"}`)?.base ?? coin1,
                        quote: "",
                        symbol: symbolData.symbolMap.get(`${coin1}_${"USDT"}`)?.baseDisplay ?? coin1,
                    };
                } else {
                    return {
                        base: "",
                        quote: coin1,
                        symbol: coin1,
                    };
                }
            }
            if (symbolData && symbolData.symbolMap && symbolData.symbolMap.get(`${coin1}_${coin2}`)) {
                return {
                    base: symbolData.symbolMap.get(`${coin1}_${coin2}`)?.base ?? coin1,
                    quote: symbolData.symbolMap.get(`${coin1}_${coin2}`)?.quote ?? coin2,
                    symbol: `${symbolData.symbolMap.get(`${coin1}_${coin2}`)?.baseDisplay ?? coin1}/${coin2}`,
                };
            } else {
                return {
                    base: coin2,
                    quote: coin1,
                    symbol: `${coin2}/${coin1}`,
                };
            }
        },
        [symbolData],
    );
    const [strategyTag1, strategyTag30] = useMemo(() => {
        const strategyTag1 = `${OrderUtils.getOrderDesc(ExchangeOrderType.aip)}/${OrderUtils.getOrderDesc(ExchangeOrderType.twap)}`;
        const strategyTag30 = $st("smart_trade_bot");
        return [strategyTag1, strategyTag30];
    }, []);

    const renderData = useMemo(() => {
        const result: IStrategyFrozenBalanceRenderDataInfo[] = [];
        if (strategyBalance && strategyBalance.length) {
            strategyBalance.forEach((item) => {
                if (item.balances && Array.isArray(item.balances)) {
                    const coinInfo = item.balances.find((balance) => balance.coin === base);
                    if (coinInfo && strategyTagMap.hasOwnProperty(item.strategyTag)) {
                        // 过滤不需要显示的交易类型
                        if (filterStrategyTag && filterStrategyTag.length && filterStrategyTag.includes(item.strategyTag)) {
                            return;
                        }
                        // 41囤币宝单独处理
                        if (item.strategyTag === 41) {
                            const srSymbol = item.balances
                                .filter((balance) => balance.coin !== "USDT")
                                .map((balance) => {
                                    const symbolInfo = symbolData?.symbolMap.get(`${balance.coin}_${"USDT"}`);
                                    return symbolInfo?.baseDisplay ?? balance.coin;
                                })
                                .join("/");
                            result.push({
                                type: "strategy",
                                strategyTag: item.strategyTag,
                                strategyId: item.strategyId,
                                coin: coinInfo.coin,
                                frozen: new SafeDecimal(coinInfo.frozen).add(coinInfo.free).toNumber(),
                                symbol: srSymbol,
                                orderType: $st("rebalanced_bot"),
                                base: "",
                                quote: "",
                            });
                            return;
                        }
                        const symbolInfo = getStrategySymbol(item.balances[0]?.coin ?? "", item.balances[1]?.coin ?? "");
                        result.push({
                            type: "strategy",
                            strategyTag: item.strategyTag,
                            strategyId: item.strategyId,
                            coin: coinInfo.coin,
                            frozen: new SafeDecimal(coinInfo.frozen).add(coinInfo.free).toNumber(),
                            symbol: symbolInfo.symbol,
                            orderType: item.strategyTag === 1 ? strategyTag1 : item.strategyTag === 30 ? strategyTag30 : OrderUtils.getOrderDesc(strategyTagMap[item.strategyTag]),
                            base: symbolInfo.base, // item.balances[0].coin,
                            quote: symbolInfo.quote, // item.balances[1]?.coin ?? "",
                        });
                    }
                }
            });
        }

        if (frozenAssetExcludeBot.length > 0 && strategyBalance) {
            return result.concat(frozenAssetExcludeBot);
        }
        return result;
    }, [base, frozenAssetExcludeBot, getStrategySymbol, strategyBalance, strategyTag1, strategyTag30, symbolData?.symbolMap, filterStrategyTag]);
    const jumpToTrade = useCallback((item) => {
        if (item.type === "manual") {
            history.push("/orders/manual/placed");
        } else {
            if (strategyTagMap[item.strategyTag] === ExchangeOrderType.aip) {
                history.push(`/orders/bot/running`);
            } else {
                history.push(`/orders/bot/running/${strategyTagMap[item.strategyTag]}`);
            }
        }
    }, []);
    const handleItemClick = useCallback(
        (item) => {
            jumpToTrade(item);
        },
        [jumpToTrade],
    );
    const renderItems = useMemo(() => {
        return renderData.map((item) => {
            const { frozen, symbol, strategyTag, orderType, strategyId } = item;
            return (
                <div key={strategyId} className={cs["list-item"]} onClick={() => handleItemClick(item)}>
                    <div className={cs["list-item-title"]}>{`${$st("balance_frozen_use_amout")}: ${NumberCommonUtils.toNormalPrice(frozen)}`}</div>
                    <div className={cs["list-item-content"]}>{`${$st("balance_frozen_use_type")}: ${symbol} ${strategyTag === 41 ? $st("rebalanced_bot") : orderType}`}</div>
                    <IconRightArrow12pxRegular size={12} className="iconfont" />
                </div>
            );
        });
    }, [renderData, handleItemClick]);

    const renderStakingData = useMemo(() => {
        if (base === "ETH") {
            return stakingProduct?.map((v) => (
                <div key={v.product_id} className={cs["list-item"]} onClick={() => history.push(AccountManager.getStakingPath())}>
                    <div className={cs["list-item-title"]}>{`${$st("balance_frozen_use_amout")}: ${NumberCommonUtils.toNormalPrice(v.investment_amount)}`}</div>
                    <div className={cs["list-item-content"]}>{`${$st("balance_frozen_use_type")}:Staking(${
                        v.lock_days === 0 ? $st("fm_staking_demand_deposit") : $st("fm_staking_period_day", { day: v.lock_days })
                    }) `}</div>
                    <IconRightArrow12pxRegular size={12} className="iconfont" />
                </div>
            ));
        }
        return null;
    }, [base, stakingProduct]);

    return (
        <Modal className={cs["ocupy-modal"]} visible={visible} title={`${base}(${$st("balance_frozen_details")})`} onCancel={onCancel} onOk={onOk}>
            {strategyBalanceLoading ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TLoading />
                </div>
            ) : renderData.length > 0 || (renderStakingData && renderStakingData.length > 0) ? (
                <div className={cs.list}>
                    {renderItems} {renderStakingData}
                </div>
            ) : (
                <div className={cs.empty}>{$st("no_frozen_balance", { baseDisplay, fullName: fullName || baseDisplay })}</div>
            )}
        </Modal>
    );
};

export default OccupyStatus;
