import * as React from "react";
import { useCallback, useMemo } from "react";
import { $st } from "src/utils/i18n";
import { CoinBalance } from "trade_asset_lib";
import cs from "../../balance.module.less";
import { ListTable } from "../ListTable";
import useAccountInfo from "commonUse/useAccountInfo";
import { TSearchInput } from "components/v2/TinyComps";
import { useBalanceArbitrageList, useBalanceFinanceOtherList } from "src/commonUse/useBalance";
import { listFilter } from "./index";
import { SafeDecimal } from "trade_utils_lib";
export interface IBalanceProps {
    onLoading: boolean;
    className?: string;
}
export function Balance(props: IBalanceProps) {
    const accountInfo = useAccountInfo();
    const apiKey = (accountInfo.apiKeys || [])[0];
    const { onLoading, className = "" } = props;
    const coinList = useBalanceArbitrageList();
    const othersCoinList = useBalanceFinanceOtherList();
    const [searchInput, updateSearchInput] = React.useState("");
    const [searchInputOthers, updateSearchInputOthers] = React.useState("");

    // 更新列表数据
    const { filterCoinList, othersList } = useMemo(() => {
        const filterCoinList = listFilter(
            onLoading,
            coinList.filter((coin) => new SafeDecimal(coin.quantity).toDecimalPlaces(8).greaterThan(0)),
            searchInput,
        );
        let handleOthersCoinList: CoinBalance[] = [];
        if (othersCoinList !== undefined) {
            handleOthersCoinList = othersCoinList
                .filter((coin) => new SafeDecimal(coin.quantity).toDecimalPlaces(8).greaterThan(0))
                .map((obj) => {
                    return {
                        ...obj,
                        quantity: obj.quantity,
                        frozen: obj.frozen,
                        USD: obj.USD,
                        BTC: obj.BTC,
                    };
                });
        }
        const othersList = listFilter(false, handleOthersCoinList, searchInputOthers).sort((a, b) => b.USD - a.USD);
        return { filterCoinList, othersList };
    }, [onLoading, coinList, searchInput, othersCoinList, searchInputOthers]);
    const searchOnChange = useCallback((e) => updateSearchInput(e.target.value), []);
    const searchOthersOnChange = useCallback((e) => updateSearchInputOthers(e.target.value), []);
    if (!apiKey) {
        return <></>;
    }
    return (
        <div className={className}>
            <div className={cs.balanceWrapper}>
                <div className={cs.headerFilter}>
                    <div className={cs.headerFilterTitle}>{$st("balance_finance_swap")}</div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <TSearchInput value={searchInput} onChange={searchOnChange} placeholder={$st("balance_search_coin_hint")} />
                    </div>
                </div>
                <ListTable exchange={apiKey.exchange} coinListData={filterCoinList} onLoading={onLoading} source={"arbitrage"} isSearchState={searchInput !== ""} />
            </div>

            {othersList !== undefined && othersList.length > 0 && (
                <div className={cs.balanceWrapper}>
                    <div className={cs.headerFilter}>
                        <div className={cs.headerFilterTitle}>{`${$st("balance_finance_others")}`}</div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <TSearchInput value={searchInputOthers} onChange={searchOthersOnChange} placeholder={$st("balance_search_coin_hint")} />
                        </div>
                    </div>
                    <ListTable
                        exchange={apiKey.exchange}
                        coinListData={othersList}
                        onLoading={false}
                        source={"deposit"}
                        isSearchState={searchInputOthers !== ""}
                        hideOperator
                        hideFrozenAndFree
                    />
                </div>
            )}
        </div>
    );
}
