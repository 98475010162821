import { Button, Dropdown, List, Menu, message } from "antd";
import useTheme from "commonUse/useTheme";
import CustomAntdEmpty from "components/Antd/CustomEmpty";
import { CoinIcon } from "components/CoinIcon";
import { useDWPageContext } from "landings/DepositWithdraw";
import cssExports from "landings/DepositWithdraw/components/DWHistory/index.module.less";
import { getDWRecordStatusDesc } from "landings/DepositWithdraw/components/use";
import * as React from "react";
import { useDWCoins } from "src/commonUse/useDW";
import DateFormatter from "state/DateFormatter";
import { DWHistoryInfo, DWRecordStatus, SupportDWCoinInfo } from "TradeAPILib";
import { $st } from "utils/i18n/trans.comp";
import { OrderUtils } from "utils/OrderUtils";
import { history } from "utils/History";
import AddAddressModal, { IDefaultValue } from "landings/V2/Address/components/AddAddressModal";
import { WhiteListStatus } from "src/landings/V2/Address/api";
import { useToggle } from "src/commonUse/tools";
import { layoutGoTop } from "src/components-v2/Layout/utils";
import { TLoading } from "components/v2/TinyComps";
import { getBlockExplorerUrl } from "landings/DepositWithdraw/components/DWHistory/utils";
import ClipBoard from "react-copy-to-clipboard";

export interface IDWHistoryProps {
    dateFormatter: DateFormatter;
    onLoading: boolean;
    filteredHistories: Array<DWHistoryInfo>;
    cancelWithdraw: (id: string) => Promise<boolean>;
}

interface ListRowItem {
    type: "content" | "warning";
    data: DWHistoryInfo;
}

export const renderTransactionId = (item: DWHistoryInfo, coinList: SupportDWCoinInfo[]) => {
    const { isActivityPrize, approvalReason, type, status, txId, currency } = item;
    const coinInfo = coinList.find((coin) => coin.currency === currency);

    const isInternalTransfer = txId.indexOf("internal_") >= 0;
    if (isActivityPrize) {
        return <span>{$st("deposit_withdraw_activity_prize")}</span>;
    } else if (isInternalTransfer) {
        return <span>{$st("dw_history_internal_hash")}</span>;
    }

    if (type === "withdraw" && status === "failed") {
        if (!approvalReason) {
            return txId || "-";
        }
        // 错误码统一采用这种格式，
        return <span>{$st("withdraw_reject:" + approvalReason) || $st("withdraw_reject:UNKNOWN")}</span>;
    }

    if (!txId) {
        return "-";
    }

    if (!coinInfo) {
        return <span className={"break-words font-base"}>{`${txId.substr(0, 10)}...${txId.substr(txId.length > 55 ? 55 : txId.length - 15)}`}</span>;
    }

    const txUrl = getBlockExplorerUrl({ coinName: coinInfo.coinName, protocol: coinInfo.protocol, txId });
    const displayTxId = `${txId.substr(0, 10)}...${txId.substr(txId.length > 55 ? 55 : txId.length - 15)}`;
    if (txUrl) {
        return (
            <a target="_blank" href={txUrl} style={{ wordBreak: "break-word", fontSize: "0.9rem" }} rel="noreferrer">
                {displayTxId}
            </a>
        );
    } else {
        return (
            <ClipBoard
                text={txId}
                onCopy={() => {
                    message.success($st("tip_copy_success"));
                }}
            >
                <span className={"cursor-pointer"}>{displayTxId}</span>
            </ClipBoard>
        );
    }
};

export const renderAddress = (item: DWHistoryInfo) => {
    if (item.isActivityPrize) {
        return <span>{$st("deposit_withdraw_from_pionex")}</span>;
    }
    return <span style={{ wordBreak: "break-word", fontSize: "0.9rem" }}>{item.address}</span>;
};

export const DWHistoryTable = (props: IDWHistoryProps) => {
    const { onLoading, filteredHistories, cancelWithdraw, dateFormatter } = props;
    const { currentCoin, coinList, changeAddressInitialData, noTwoFactor, addressList, onlyWhiteList, reFreshAddressList } = useDWPageContext();
    const [dataList, changeDataList] = React.useState([] as Array<ListRowItem>);
    const theme = useTheme();
    const [dwCoin]: any = useDWCoins();
    const [addAdressVals, setAddAdressVals] = React.useState({ whitelist: onlyWhiteList === WhiteListStatus.on } as IDefaultValue);
    const [addNEwVisible, { setTrue: showAddNew, setFalse: hideAddNew }] = useToggle();
    const [whitelistCointype, setWhitelistCointype] = React.useState("");
    const getCoinMsg = React.useMemo(() => {
        return (coinType) => {
            if (!dwCoin || !dwCoin.length) {
                return "";
            }
            return dwCoin.find((coin) => {
                return coin.currency === coinType;
            });
        };
    }, [dwCoin]);

    React.useEffect(() => {
        const data: Array<ListRowItem> = [];
        filteredHistories.forEach((item) => {
            data.push({
                type: "content",
                data: item,
            });

            if (item.status === DWRecordStatus.processing) {
                data.push({
                    type: "warning",
                    data: item,
                });
            }
        });

        changeDataList(data);
    }, [filteredHistories]);
    React.useEffect(() => {
        const isOn = onlyWhiteList === WhiteListStatus.on;
        if (addAdressVals.whitelist !== isOn) {
            setAddAdressVals({ ...addAdressVals, whitelist: isOn });
        }
        // addAdressVals?.address 发生改变才是真正需要依赖的
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onlyWhiteList, setAddAdressVals, addAdressVals?.address]);
    const recordOptionsClick = React.useCallback(
        ({ key }, item: DWHistoryInfo) => {
            const coinInfo = coinList.find((c) => c.currency === item.currency);
            if (!coinInfo) {
                message.error($st("network_error"));
                return;
            }
            if (key === "withdraw") {
                const data = {
                    currency: item.currency,
                    coin: coinInfo.coinName,
                    chain: coinInfo.protocol,
                    address: item.address,
                    memo: item.tag,
                };
                if (item.currency !== currentCoin.currency) {
                    history.push(`/balance/withdraw/${item.currency}`, { addressInitialData: data });
                } else {
                    changeAddressInitialData(data);
                }
                layoutGoTop();
            }
            if (key === "whitelist") {
                if (noTwoFactor) {
                    message.warn($st("add_address_factor_desc"));
                    return;
                }
                const isInWhitelist = addressList.find((addr) => addr.address === item.address);
                if (isInWhitelist) {
                    message.warn($st("mul_add_address_error"));
                } else {
                    setWhitelistCointype(item.currency);
                    setAddAdressVals({
                        coin: coinInfo.coinName,
                        chain: coinInfo.protocol,
                        address: item.address,
                        memo: item.tag,
                    });
                    showAddNew();
                }
            }
        },
        [addressList, changeAddressInitialData, coinList, currentCoin.currency, noTwoFactor, showAddNew],
    );
    const cancelClick = React.useCallback(
        (item: DWHistoryInfo) => {
            OrderUtils.wrapOrderCancelConfirmModal({
                title: $st("cancel_withdraw_warning_title"),
                content: $st("cancel_withdraw_warning_content"),
                onOk: () => cancelWithdraw(item.id),
            });
        },
        [cancelWithdraw],
    );
    const renderActionButton = React.useCallback(
        (item: DWHistoryInfo) => {
            if (item.type === "withdraw" && item.status === DWRecordStatus.reviewing && item.id) {
                return (
                    <Button className={cssExports.recordButton} style={{ fontSize: 12, color: theme.colorTextAccent }} size="small" ghost={true} onClick={() => cancelClick(item)}>
                        {$st("dw.history.status.cancel")}
                    </Button>
                );
            }
            if (item.type === "withdraw" && item.status !== DWRecordStatus.reviewing && item.id) {
                return (
                    <Dropdown
                        overlay={
                            <Menu className="" onClick={(params) => recordOptionsClick(params, item)}>
                                {onlyWhiteList === WhiteListStatus.off && <Menu.Item key="withdraw">{$st("withdraw_with_address")}</Menu.Item>}
                                <Menu.Item key="whitelist">{$st("add_withdraw_address")}</Menu.Item>
                            </Menu>
                        }
                        trigger={["click"]}
                    >
                        <Button className={cssExports.recordButton} style={{ fontSize: 12, color: theme.colorTextAccent }} size="small" ghost={true}>
                            {$st("address_table_options")}
                        </Button>
                    </Dropdown>
                );
            }
            return null;
        },
        [cancelClick, recordOptionsClick, theme.colorTextAccent, onlyWhiteList],
    );

    const renderOrderItem = React.useCallback(
        (item: DWHistoryInfo) => {
            const coinInfo = coinList.find((coin) => coin.currency === item.currency);
            const coin = coinInfo?.coinName || item.currency;

            return (
                <div className={cssExports.depositListWrapper}>
                    <div style={{ flex: 2 }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <CoinIcon target={coin} size="small" />
                            <span style={{ marginLeft: 5 }}>{coin}</span>
                        </div>
                    </div>

                    <div style={{ flex: 3 }}>
                        <span>{item.amount}</span>
                    </div>

                    <div style={{ flex: 4 }}>
                        <span>{dateFormatter.year_second(item.timestamp)}</span>
                    </div>

                    <div style={{ flex: 9 }}>{renderAddress(item)}</div>

                    <div style={{ flex: 7 }}>{renderTransactionId(item, coinList)}</div>

                    <div style={{ flex: 2 }}>
                        <span>{getDWRecordStatusDesc(item, coinInfo)}</span>
                    </div>

                    <div style={{ flex: 2, display: "flex", justifyContent: "flex-end" }}>
                        {
                            // 不在coinList里的币不显示操作
                            coinInfo && renderActionButton(item)
                        }
                    </div>
                </div>
            );
        },
        [coinList, dateFormatter, renderActionButton],
    );

    const renderHeader = React.useCallback(() => {
        return (
            <div className={cssExports.depositListWrapper} style={{ height: 40 }}>
                <div style={{ flex: 2, color: theme.colorText }}>
                    <span>{$st("dw.history.table.coin")}</span>
                </div>

                <div style={{ flex: 3, color: theme.colorText }}>
                    <span>{$st("dw.history.table.quantity")}</span>
                </div>

                <div style={{ flex: 4, color: theme.colorText }}>
                    <span>{$st("dw.history.table.time")}</span>
                </div>

                <div style={{ flex: 9, color: theme.colorText }}>{$st("dw.history.table.address")}</div>

                <div style={{ flex: 7, color: theme.colorText }}>{$st("dw.history.table.transaction.id")}</div>

                <div style={{ flex: 2, color: theme.colorText }}>
                    <span>{$st("dw.history.table.status")}</span>
                </div>

                <div style={{ flex: 2 }}></div>
            </div>
        );
    }, [theme.colorText]);

    const renderWarningItem = React.useCallback(
        (item: DWHistoryInfo) => {
            const coin = item.currency;
            const coinMsg = getCoinMsg(coin);

            return (
                <div className={cssExports.listPopStyle}>
                    <div className={cssExports.popWarningTopArrow} />
                    <div style={{ flex: 1, flexDirection: "row", alignItems: "center", display: "flex", marginBottom: 5 }}>
                        <span style={{ fontSize: 14 }}>
                            {item.type === "deposit"
                                ? $st("deposit_prepare_not_enough_warning")
                                : coinMsg.noReserve
                                ? $st("withdraw_no_reserve_pop", { coin })
                                : $st("withdraw_prepare_not_enough_warning")}
                        </span>
                    </div>
                </div>
            );
        },
        [coinList, getCoinMsg],
    );
    const renderItem = React.useCallback(
        (item, index) => {
            return (
                <div className={index % 2 === 0 ? cssExports.evenIndexItem : cssExports.oddIndexItem}>
                    {item.type === "content" ? renderOrderItem(item.data) : renderWarningItem(item.data)}
                </div>
            );
        },
        [renderOrderItem, renderWarningItem],
    );
    const locale = React.useMemo(
        () => ({
            emptyText: <CustomAntdEmpty loading={onLoading} dataLength={dataList.length} />,
        }),
        [dataList.length, onLoading],
    );
    const onAddSuccess = React.useCallback(() => {
        reFreshAddressList();
        hideAddNew();
    }, [reFreshAddressList, hideAddNew]);
    return (
        <div className={cssExports.listStyle}>
            <List
                header={renderHeader()}
                pagination={false}
                loading={{ spinning: onLoading, indicator: <TLoading size="small" /> }}
                dataSource={dataList}
                renderItem={renderItem}
                locale={locale}
            />
            <AddAddressModal
                coinType={whitelistCointype}
                defaultValue={addAdressVals}
                visible={addNEwVisible}
                onCancel={hideAddNew}
                onAddSuccess={onAddSuccess}
                isFromWithdrawSuccess
            />
        </div>
    );
};
