import { ObservableUtils, ExchangeSymbol, SafeDecimal } from "trade_utils_lib";
import Constants from "src/utils/Constants";
import { catchError, map, flatMap } from "rxjs/operators";
import { of, Observable } from "rxjs";
import { ExchangeOrderSide, TradeAPI } from "TradeAPILib";
import { MarketTradeType, StringFormatter, MarketTradingProcessor } from "trade_lib_wrapper";
import { useState, useEffect } from "react";
import { TModal } from "src/components/v2/TinyComps";
import { fromPromise } from "rxjs/internal-compatibility";

// 返回是否去开单
export const CheckMarketTradingCost = (
    symbol: ExchangeSymbol,
    curPrice: number,
    side: ExchangeOrderSide,
    api: TradeAPI,
    invest: string,
    tradeType: MarketTradeType,
    $st: StringFormatter,
): Observable<boolean> => {
    return MarketTradingProcessor.queryWarningMaxAmount(symbol, curPrice, 0.1, side, api)
        .pipe(
            map((result) => {
                /// return 是否需要弹窗警告
                if (tradeType === "cost") {
                    // quantity 为cost, 转换成大约可以买成的量
                    const amount = new SafeDecimal(invest).div(new SafeDecimal(curPrice)).toNumber();
                    return amount > result.maxAmount;
                } else {
                    return new SafeDecimal(invest).greaterThan(result.maxAmount);
                }
            }),
            catchError((e) => {
                console.log(e);
                return of(false);
            }),
        )
        .pipe(
            flatMap((needAlert) => {
                // 返回是否同意开单
                if (needAlert) {
                    return fromPromise(
                        TModal.promisify
                            .confirm({
                                title: $st("market_trading_big_amount_warning"),
                                content: $st("market_trading_big_amount_warning_desc"),
                                okText: $st("still_continue"),
                                cancelText: $st("create_order_repeat"),
                            })
                            .then(() => true)
                            .catch(() => false),
                    );
                }
                return of(true);
            }),
        );
};

export interface SwapCoinItem {
    base: string;
    minOrderNum: number;
    maxOrderNum: number;
}
export interface SwapUSDTConfigType {
    minQuantity: number;
    maxQuantity: number;
}
let refreshFlag = false;
let cache = {
    swapCoinList: [
        {
            base: "BTC",
            minOrderNum: 0.0001,
            maxOrderNum: 0.1,
        },
        {
            base: "ETH",
            minOrderNum: 0.0001,
            maxOrderNum: 1,
        },
    ],
    swapUSDTConfig: {
        minQuantity: 5,
        maxQuantity: 2000,
    },
};

export function useSwapConfig() {
    const [swapConfig, setSwapConfig] = useState<{
        swapUSDTConfig: SwapUSDTConfigType;
        swapCoinList: SwapCoinItem[];
    }>(() => cache);
    useEffect(() => {
        if (refreshFlag) {
            return;
        }
        const swapCoinListObserve = ObservableUtils.createGetRequestObservable(
            `${Constants.configHost}/swap_config.json`,
            { "Cache-Control": "no-cache" },
            (response) => response,
        ).pipe(
            catchError((err) => {
                return of(null);
            }),
        );
        swapCoinListObserve.subscribe(
            (resultData) => {
                if (resultData) {
                    refreshFlag = true;
                    cache = resultData;
                    setSwapConfig(resultData);
                }
            },
            (err) => {
                console.log(err);
            },
        );
    }, []);
    return swapConfig;
}
