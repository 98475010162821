import { message, Tabs } from "@pionex-web-kit/components";
import { useQueryLeverageSymbol } from "src/commonUse";
import MarketPage, { scrollToTop } from "landings/Trade/MarketLayout/MarketPage";
import css from "landings/Trade/MarketLayout/Symbol/SymbolView/symbolview.module.less";
import React, { useCallback, useMemo, useRef } from "react";
import { ExchangeSymbol, ExchangeTicker } from "TradeAPILib";
import { ExchangeTradePair, TradeSelectedInfo } from "TradeLib/TradeTypes";
import AccountManager from "utils/AccountManager";
import { useTickerMapActive } from "use/useTicker";
import uniqBy from "lodash/uniqBy";
import { LoginGroup } from "landings/Trade/components/NotLogin";
import classNames from "classnames";
import useShow from "./useShow";
import { useTranslators } from "commonUse/Locale";
import useAccountInfo from "commonUse/useAccountInfo";
import ExchangeRate from "state/ExchangeRate";
import { SideTabKey } from "landings/Trade/SideTab";

const TAB_STAR = "STAR";
const TAB_LEVERAGE = "LEVERAGE";
export interface ISymbolViewProps {
    active: boolean;
    searchInput: string;
    exchangeRate: ExchangeRate;
    tradeSelectedInfo: TradeSelectedInfo;
    collectionList: Array<ExchangeTradePair> | undefined;
    hidePercent?: boolean;
    nano?: boolean;
    onSelectSymbol?();
    isDropdown?: boolean;
    targetPage?: SideTabKey;
}

export const QUOTE_WEIGHT = {
    [TAB_STAR]: 120,
    USD: 110,
    USDT: 109,
    [TAB_LEVERAGE]: 100,
    BTC: 90,
    ETH: 80,
    BNB: 70,
    HUSD: 60,
};

export type ExchangeSymbolData = Pick<ExchangeSymbol, keyof ExchangeSymbol> &
    Record<"favor", boolean> &
    Record<"ticker", ExchangeTicker | undefined> &
    Record<"leverage", number | undefined>;

export default function SymbolView(props: ISymbolViewProps) {
    const { tradeSelectedInfo, searchInput, collectionList, active, hidePercent, targetPage } = props;
    const [currentTab, changeCurrentTab] = React.useState<string>();
    const [symbolList, updateSymbolList] = React.useState([] as Array<ExchangeSymbolData>);
    const [categoryList, changeCategoryList] = React.useState([] as Array<string>);
    const [originalCategory, changeOriginalCategory] = React.useState(new Map<string, Array<ExchangeSymbolData>>());

    const { $st } = useTranslators();
    const tickerMap = useTickerMapActive(active);
    const accountInfo = useAccountInfo();
    const leverageConfig = useQueryLeverageSymbol();
    const dive = useRef<HTMLDivElement | null>(null);

    const isLogin = !!accountInfo.userId;

    const onCollectionChanged = useCallback(
        (symbol, collection) => {
            AccountManager.shared.changeCollection(
                {
                    exchange: tradeSelectedInfo.exchange,
                    base: symbol.base,
                    quote: symbol.quote,
                },
                collection,
            );
            if (collection) {
                message.success($st("favor_success"));
            } else {
                message.success($st("unfavor_success"));
            }
        },
        [$st, tradeSelectedInfo.exchange],
    );

    React.useEffect(() => {
        let categoryListTmp: Array<string> = [];
        const symbolMap = new Map<string, Array<ExchangeSymbolData>>();

        if (tradeSelectedInfo.allSymbols.length > 0) {
            const leverageList: ExchangeSymbolData[] = [];
            const starList: ExchangeSymbolData[] = [];

            tradeSelectedInfo.allSymbols.forEach((symbol) => {
                const category: string = symbol.quote;
                const ticker = tickerMap ? tickerMap.get(`${symbol.base}/${symbol.quote}`) : undefined;
                const futureConfig = (leverageConfig || []).find((value) => symbol.base === value.base && symbol.quote === value.quote);
                const favor = (collectionList || []).find((favorite) => symbol.base === favorite.base && symbol.quote === favorite.quote);
                if (!categoryListTmp.some((key) => key === symbol.quote)) {
                    categoryListTmp.push(symbol.quote);
                }

                const symbolData: ExchangeSymbolData = {
                    ...symbol,
                    favor: favor !== undefined,
                    ticker: ticker ? ticker : undefined,
                    leverage: futureConfig ? futureConfig.maxLeverage : undefined,
                };

                if (favor !== undefined) {
                    starList.push(symbolData);
                }
                if (symbol.isLeverage) {
                    leverageList.push(symbolData);
                } else {
                    const symbolList = symbolMap.get(category);
                    if (symbolList) {
                        symbolList.push(symbolData);
                    } else {
                        symbolMap.set(category, [symbolData]);
                    }
                }
            });

            const categoryList: any[] = [];

            symbolMap.set(TAB_STAR, starList);
            if (leverageList.length > 0) {
                categoryListTmp = [TAB_STAR, TAB_LEVERAGE, ...categoryListTmp];
                symbolMap.set(TAB_LEVERAGE, leverageList);
            } else {
                categoryListTmp = [TAB_STAR, ...categoryListTmp];
            }

            changeOriginalCategory(symbolMap);
            categoryList.push(...categoryListTmp);
            changeCategoryList(categoryList);
        }
    }, [tradeSelectedInfo.allSymbols, collectionList, tickerMap, leverageConfig]);

    // 拍平，获取全量的币对
    const allSymbols = useMemo(() => [...originalCategory.values()].flatMap((vs) => [...vs]), [originalCategory]);
    const searchUpper = useMemo(() => searchInput.toUpperCase(), [searchInput]);

    const filterSplitHead = useCallback((item) => item.symbol.toUpperCase().indexOf(searchUpper) === 0, [searchUpper]);
    const filterSplitContent = useCallback((item) => item.symbol.toUpperCase().indexOf(searchUpper) > 0, [searchUpper]);
    const filterOtherHead = useCallback((item) => (item.baseDisplay + item.quoteDisplay).toUpperCase().indexOf(searchUpper) === 0, [searchUpper]);
    const filterOtherContent = useCallback((item) => (item.baseDisplay + item.quoteDisplay).toUpperCase().indexOf(searchUpper) > 0, [searchUpper]);
    const defaultSort = useCallback((b, a) => (a?.ticker?.quoteVol || 0) - (b?.ticker?.quoteVol || 0), []);

    const filterListByStr = useCallback(
        (list: ExchangeSymbolData[], str: string = "", type: string) => {
            return uniqBy(
                (() => {
                    if (str.indexOf("/") === 0) {
                        return list.filter(filterSplitContent);
                    }
                    if (str.indexOf("/") > 0) {
                        return [...list.filter(filterSplitHead), ...list.filter(filterSplitContent)];
                    }
                    return [...list.filter(filterOtherHead), ...list.filter(filterOtherContent)];
                })(),
                "symbol",
            )
                .sort(defaultSort)
                .map((e) => ({ ...e, type }));
        },
        [defaultSort, filterOtherContent, filterOtherHead, filterSplitContent, filterSplitHead],
    );
    React.useEffect(() => {
        if (originalCategory && currentTab) {
            let filterResult = originalCategory.get(currentTab) || [];
            if (searchInput) {
                let otherResult: ExchangeSymbolData[] = allSymbols.filter((s) => !filterResult.includes(s));
                if (searchUpper.indexOf("/") === 0) {
                    filterResult = filterResult.filter(filterSplitContent).sort(defaultSort);
                    otherResult = otherResult.filter(filterSplitContent).sort(defaultSort);
                } else if (searchUpper.indexOf("/") > 0) {
                    filterResult = [...filterResult.filter(filterSplitHead).sort(defaultSort), ...filterResult.filter(filterSplitContent).sort(defaultSort)];
                    otherResult = [...otherResult.filter(filterSplitHead).sort(defaultSort), ...otherResult.filter(filterSplitContent).sort(defaultSort)];
                } else {
                    const tabHead = [...filterResult.filter(filterOtherHead).sort(defaultSort)];
                    const tabContent = [...filterResult.filter(filterOtherContent).sort(defaultSort)];
                    const otherHead = [...otherResult.filter(filterOtherHead).sort(defaultSort)];
                    const otherContent = [...otherResult.filter(filterOtherContent).sort(defaultSort)];

                    filterResult = [...tabHead, ...otherHead];
                    otherResult = [...tabContent, ...otherContent];
                }

                filterResult = uniqBy(filterResult.concat(otherResult), "symbol"); // 添加去重
            }

            updateSymbolList(filterResult);
        }
    }, [
        originalCategory,
        currentTab,
        defaultSort,
        filterListByStr,
        filterOtherContent,
        filterOtherHead,
        filterSplitContent,
        filterSplitHead,
        originalCategory,
        searchInput,
        searchUpper,
        allSymbols,
    ]);

    React.useEffect(() => {
        changeCurrentTab(tradeSelectedInfo.symbol.quote);
    }, [tradeSelectedInfo.symbol.quote]);

    const show = useShow(dive.current);

    const tabItems = useMemo(() => {
        return categoryList
            .sort((a, b) => {
                const leftWeight = QUOTE_WEIGHT[a] || 0;
                const rightWeight = QUOTE_WEIGHT[b] || 0;
                return rightWeight - leftWeight;
            })
            .map((category) => {
                let display = category;
                if (category === TAB_STAR) {
                    display = $st("symbolview_pickes");
                } else if (category === TAB_LEVERAGE) {
                    display = $st("symbol_tab_type_pionex_leverage");
                }
                return {
                    key: category,
                    label: display,
                };
            });
    }, [$st, categoryList]);

    return (
        <div className={css.container} ref={dive}>
            {!props.nano && (
                <div className={classNames(css.tabStyle, { [css.hiddenx]: show }, { [css.tabsSpecial]: props.isDropdown })}>
                    <Tabs
                        animated={false}
                        activeKey={currentTab}
                        onChange={(activeKey) => {
                            changeCurrentTab(activeKey);
                            scrollToTop();
                        }}
                        type="text"
                        moreMode="slide"
                        items={tabItems}
                    />
                </div>
            )}

            {/*搜索时不展示登录提示*/}
            {currentTab === TAB_STAR && !isLogin && !searchInput.trim() ? (
                <LoginGroup title={$st("symbol_favor_login_tip")} />
            ) : (
                <MarketPage
                    onSelectSymbol={props.onSelectSymbol}
                    symbol={tradeSelectedInfo.symbol}
                    symbolList={symbolList}
                    onCollectionChanged={onCollectionChanged}
                    exchange={tradeSelectedInfo.exchange}
                    hidePercent={hidePercent}
                    nano={props.nano}
                    isDropdown={props.isDropdown}
                    isSort={!searchInput}
                    targetPage={targetPage}
                />
            )}
        </div>
    );
}
