import { Button, List } from "antd";
import CustomAntdEmpty from "components/Antd/CustomEmpty";
import { TLoading } from "components/v2/TinyComps";
import { useDWPageContext } from "landings/DepositWithdraw";
import { IDWHistoryProps, renderAddress, renderTransactionId } from "landings/DepositWithdraw/components/DWHistory/HistoryTable";
import cs from "landings/DepositWithdraw/components/DWHistory/index.module.less";
import { getDWRecordStatusDesc } from "landings/DepositWithdraw/components/use";
import css from "landings/DepositWithdraw/withdraw.module.less";
import React from "react";
import { DWHistoryInfo, DWRecordStatus } from "TradeAPILib";
import { $st } from "utils/i18n/trans.comp";
import { OrderUtils } from "utils/OrderUtils";

export const DWHistoryList = (props: IDWHistoryProps) => {
    const { onLoading, filteredHistories, cancelWithdraw, dateFormatter } = props;
    const { coinList } = useDWPageContext();

    return (
        <List
            loading={{ spinning: onLoading, indicator: <TLoading size="small" /> }}
            dataSource={filteredHistories}
            renderItem={(item: DWHistoryInfo) => {
                const coinInfo = coinList.find((coin) => coin.currency === item.currency);
                const coin = coinInfo?.coinName || item.currency;

                return (
                    <div className={cs.card_style}>
                        {item.status === DWRecordStatus.processing || item.status === DWRecordStatus.withdrawing ? (
                            <div
                                className={css.dwHistoryWarning}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "#1F3267",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 10,
                                    paddingBottom: 25,
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        color: "white",
                                    }}
                                >
                                    {$st("dw_history_record_notice")}
                                </div>

                                <div style={{ fontSize: 14, color: "white", marginTop: 15 }}>
                                    {item.type === "deposit" ? $st("deposit_prepare_not_enough_warning") : $st("withdraw_prepare_not_enough_warning")}
                                </div>
                            </div>
                        ) : null}
                        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                            <div className={cs.dwHistoryItem}>
                                <div className={cs.row} style={{ width: "40%" }}>
                                    <span className={cs.title}>{$st("dw.history.table.status")}</span>
                                    <span className={cs.content}>{getDWRecordStatusDesc(item, coinInfo)}</span>
                                </div>

                                <div className={cs.row} style={{ flex: 1, marginLeft: 20 }}>
                                    <span className={cs.title}>{$st("dw.history.table.coin")}</span>
                                    <span className={cs.content}>{coin}</span>
                                </div>

                                <div className={cs.row} style={{ flex: 1, marginLeft: 20 }}>
                                    <span className={cs.title}>{$st("dw.history.table.quantity")}</span>
                                    <span className={cs.content}>{item.amount}</span>
                                </div>
                            </div>

                            <div className={cs.dwHistoryItem}>
                                <div className={cs.row} style={{ width: "40%" }}>
                                    <span className={cs.title}>{$st("dw.history.table.time")}</span>
                                    <span className={cs.content} style={{ wordBreak: "break-all" }}>
                                        {dateFormatter.year_second(item.timestamp)}
                                    </span>
                                </div>

                                <div className={cs.row} style={{ flex: 1, marginLeft: 20 }}>
                                    <span className={cs.title}>{$st("dw.history.table.address")}</span>
                                    {renderAddress(item)}
                                </div>
                            </div>

                            <div className={cs.dwHistoryItem}>
                                <div className={cs.row} style={{ flex: 1 }}>
                                    <span className={cs.title}>{$st("dw.history.table.transaction.id")}</span>
                                    {renderTransactionId(item, coinList)}
                                </div>
                            </div>

                            <div className={cs.dwHistoryItem} style={{ justifyContent: "fle-end", alignItems: "flex-end" }}>
                                <div className={cs.row} style={{ flex: 1, alignItems: "flex-end" }}>
                                    {item.type === "withdraw" && item.status === DWRecordStatus.reviewing && item.id ? (
                                        <Button
                                            type="primary"
                                            style={{ width: 100 }}
                                            size="small"
                                            block
                                            onClick={() => {
                                                OrderUtils.wrapOrderCancelConfirmModal({
                                                    title: $st("cancel_withdraw_warning_title"),
                                                    content: $st("cancel_withdraw_warning_content"),
                                                    onOk: () => cancelWithdraw(item.id),
                                                });
                                            }}
                                        >
                                            {$st("dw.history.status.cancel")}
                                        </Button>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }}
            locale={{
                emptyText: <CustomAntdEmpty loading={onLoading} dataLength={filteredHistories.length} />,
            }}
        />
    );
};
