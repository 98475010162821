import { Store } from "redux";
import DateFormatter from "./DateFormatter";
import ExchangeRate from "state/ExchangeRate";
import { isPromise } from "src/utils/promise";
import { isObservable } from "rxjs";
import AccountInfo from "./AccountInfo";
import AccountManager from "../utils/AccountManager";
import BalanceCfg from "state/BalanceCfg";
import ThemeData, { setTheme, setTickerReversed } from "state/ThemeData";
import LocalStorageUtils from "utils/LocalStorageUtils";
import { LocaleData } from "commonActions/reducers";
import { OnlineConfig, OnlineConfigData } from "utils/onlineConfig";
import { WebConfig, WebConfigData } from "utils/webConfig";
import { HelpConfig, HelpConfigRedux } from "state/HelpConfig";
import { TSwapConfig } from "landings/Trade/Swap/tool";
import { SwapConfig } from "utils/swapConfig";
import { ExchangeOrder, OrderShareResp } from "TradeAPILib";
import { TradeSelectedInfo } from "@pionex-web-kit/common-business";

export default class AppState {
    localeData: LocaleData;
    balanceCfg: BalanceCfg;
    accountInfo: AccountInfo;
    dateFormatter: DateFormatter;
    exchangeRate: ExchangeRate;
    theme: ThemeData;
    router: any;
    onlineConfig: OnlineConfigData;
    webConfig: WebConfigData;
    helpConfig: HelpConfig;
    swapConfig: TSwapConfig | null;
    orderShare: OrderShareResp;
    reOrderData?: ExchangeOrder;
    copyBotData?: ExchangeOrder;
    tradeSelectedInfo?: TradeSelectedInfo | null;
    static store: Store;

    static init(store: Store) {
        this.store = store;
        AccountManager.shared.init();
        ExchangeRate.init();
        BalanceCfg.init();
        OnlineConfig.init();
        WebConfig.init();
        setTheme(LocalStorageUtils.themeName);
        setTickerReversed(LocalStorageUtils.tickerReversed);
        HelpConfigRedux.init();
        SwapConfig.init();
    }
}

/**
 * 处理Rx的Action操作
 * type 必须，需要触发的 action 名称
 * optionFunc 非必须，用于处理当前action的调用
 * optionFunc 函数的最后一个参数为 Store 对象，用于自定义 action 的触发
 */
export function handleRxAction<T = any, B = any>(type: string, optionFunc: (...a: Array<T | Store>) => B = () => undefined as any): (...a: T[]) => B {
    return function (...args) {
        let result: any = optionFunc(...[...args, AppState.store]);
        let payload = result;
        // 将observable数据转换成Promise后进行处理
        if (isObservable(result)) {
            result = result.toPromise();
        }
        if (isPromise(result)) {
            // 处理Promise
            result.then((res) => {
                let error = false;
                if (res.data) {
                    payload = res.data;
                } else {
                    payload = res.message;
                    error = true;
                }
                AppState.store.dispatch({
                    type,
                    payload,
                    error,
                });
                return res;
            });
        } else {
            AppState.store.dispatch({
                type,
                payload,
            });
        }
        return payload;
    };
}

export function dispatch(type: string, data: any) {
    AppState.store.dispatch({ type, data });
}
