import Constants from "utils/Constants";
import { RouteUtils } from "utils/RouteUtils";
import LocalStorageUtils from "./LocalStorageUtils";
import { createRedirectParam } from "./redirect";

export namespace AccountsRoute {
    export function sign<P extends { from?: string }>(params?: P, { redirectBack }: { redirectBack?: boolean } = {}) {
        return RouteUtils.appendParams(Constants.accountsRoute.sign, {
            ref: LocalStorageUtils.getReferralCode(),
            source: LocalStorageUtils.getSource(),
            ...params,
            ...(redirectBack ? createRedirectParam() : {}),
        });
    }

    export function signUp<P extends { from?: string }>(params?: P, { redirectBack }: { redirectBack?: boolean } = {}) {
        return RouteUtils.appendParams(Constants.accountsRoute.signUp, {
            ref: LocalStorageUtils.getReferralCode(),
            source: LocalStorageUtils.getSource(),
            ...params,
            ...(redirectBack ? createRedirectParam() : {}),
        });
    }
}
