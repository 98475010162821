import React from "react";
import { LayoutConfig } from "src/components-v2/Layout";

export default function LoadingPage({ error, retry, timedOut, pastDelay }: { error?: boolean; timedOut?: boolean; retry?(); pastDelay?: boolean }) {
    if (error && process.env.DEBUG) {
        console.log(error);
        // TODO: 给一个错误页面进行替代
        // When the loader has errored
        return (
            <div>
                react-loadable error, check console for detail. <button onClick={retry}>Retry</button>
            </div>
        );
    } else if (timedOut) {
        // When the loader has taken longer than the timeout
        return (
            <div>
                Taking a long time... <button onClick={retry}>Retry</button>
            </div>
        );
    } else if (pastDelay) {
        // 直接调用框架的加载动画
        return <LayoutConfig id="loadingPage" loading showFooter={false} />;
    } else {
        return null;
    }
}
