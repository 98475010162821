import { Dispatch, useCallback, useState } from "react";

/**
 * 允许受控或不受控的类似useState的hooks，表现不完全一致，不支持setState传入函数
 * @param initialValue
 * @param options
 */
export default function useControlledValue<S, P extends object>(initialValue: S, options?: { props?: P; valueKey?: keyof P; onChange?: Dispatch<S> }): [S, Dispatch<S>] {
    const [innerState, setInnerState] = useState(initialValue);
    const { props, valueKey, onChange } = options || {};

    // 是否受控
    const controlled = props && valueKey && valueKey in props;
    const outValue = valueKey && props?.[valueKey];

    const setState = useCallback(
        (s: S) => {
            setInnerState(s);
            onChange?.(s);
        },
        [onChange],
    );

    return [controlled ? (outValue as any) : innerState, setState];
}

// ex: const [sendStatus, setSendStatus] = useControlledValue( "idle", { props: props, valueKey: "sendStatus", onChange: onSendStatusChange });
