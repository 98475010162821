import csn from "classnames";
import cs from "landings/V2/SignatureDW/Deposit/index.module.less";
import React from "react";

export const SignatureStep = ({ step, text }: { step: string; text: string }) => {
    return (
        <div className="flex items-center pl-[10px] pt-[20px]">
            <div className={csn(cs.step, "text-white")}>{step}</div>
            <div className="text-text-100 ml-[5px] text-base font-medium">{text}</div>
        </div>
    );
};
