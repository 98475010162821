import { createContext } from "react";
import ThemeData, { createThemeData } from "state/ThemeData";
import ExchangeRate from "state/ExchangeRate";
import DateFormatter, { DateFormatterType } from "state/DateFormatter";
import AccountInfo from "state/AccountInfo";
import LocalStorageUtils from "utils/LocalStorageUtils";
import { LocaleData } from "commonActions/reducers";
import { getCurrentLocale, getCurrentMessage } from "utils/i18n/index";

export const ThemeContext = createContext<ThemeData>(createThemeData(LocalStorageUtils.themeName, LocalStorageUtils.tickerReversed));

export const ExchangeRateContext = createContext<ExchangeRate>(new ExchangeRate());

export const DateFormatterContext = createContext<DateFormatter>(new DateFormatter(DateFormatterType.ymd));

export const AccountInfoContext = createContext<AccountInfo>({ isInitialized: false });

export const AppLangInfoContext = createContext<LocaleData>({
    locale: getCurrentLocale(),
    messages: getCurrentMessage(),
});
