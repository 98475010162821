import { ObservableUtils } from "trade_utils_lib";
import Constants from "utils/Constants";
import React, { useEffect } from "react";
import useAccountInfo from "commonUse/useAccountInfo";
import { Modal } from "@pionex-web-kit/components";
import { useTranslators } from "commonUse/Locale";
import { history } from "src/landings";
import { useOpenHelpCenter } from "commonUse";
import { useCallbackStatic } from "commonUse/tools";

export const QUESTIONNAIRE_PATHS = { OLD_QUESTIONNAIRE: "/survey/basic_questionnaire", QUESTIONNAIRE_PATH: "/questionnaire" };

export enum QuestionnaireStatus {
    notRequired = "notRequired", // 不需要填写
    required = "required", // 需要填写
    reviewing = "reviewing", // 审核中
    complete = "complete", // 审核完成
}

/**
 * 初始化时检查是否问卷弹窗
 */
export function useCheckQuestionnaire() {
    const { userId } = useAccountInfo();
    const interruptByQues = useFuncInterruptByQues();

    useEffect(() => {
        if (Object.values(QUESTIONNAIRE_PATHS).some((path) => window.location.pathname.indexOf(path) >= 0)) {
            // 在独立的问卷页面，不做处理
            return;
        }
        if (!userId) {
            return;
        }

        interruptByQues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);
}

/**
 * 获取问卷状态
 * @param withdrawType 出金类型
 * @param amount 出金金额(USD)
 */
export function requestQuestionnaireStatus(withdrawType?: string, amount?: string) {
    return ObservableUtils.getV2<QuestionnaireStatus>(
        `${Constants.walletApiHost}/aml/questionnaire_status/`,
        {
            type: "asset",
            withdraw_type: withdrawType,
            amount,
        },
        null,
        true,
    );
}

/**
 * 返回一个函数（用来判断是否需要引导问卷填写，并当前中断操作）
 */
export function useFuncInterruptByQues() {
    const { $st } = useTranslators();
    const openHelpCenter = useOpenHelpCenter();

    return useCallbackStatic(async (withdrawType?: "coin" | "fiat", amount?: string) => {
        const status = await requestQuestionnaireStatus(withdrawType, amount).toPromise();
        if (status === QuestionnaireStatus.required) {
            Modal.confirm({
                title: $st("question_required_title"),
                content: $st("question_required_des"),
                closable: false,
                okText: $st("question_required_ok"),
                onOk: () => {
                    history.push(QUESTIONNAIRE_PATHS.QUESTIONNAIRE_PATH);
                },
            });
            return true;
        }

        if (status === QuestionnaireStatus.reviewing) {
            Modal.confirm({
                title: $st("question_reviewing_title"),
                content: $st("question_reviewing_des"),
                closable: false,
                okText: $st("dw_warning_contact"),
                onOk: openHelpCenter,
            });
            return true;
        }

        return false;
    });
}
