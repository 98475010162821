import React, { useMemo, useState } from "react";
import { Dropdown, Form, Input, Menu } from "@pionex-web-kit/components";
import { CoinIcon } from "components/CoinIcon";
import { IconBotNoData100pxBoldLightS, IconDropDownArrow12pxBold, IconSearch20pxRegular } from "@pionex-web-kit/icons";
import { BuyCryptoConfig } from "landings/V2/BuyCrypto/types";
import { useTranslators } from "commonUse/Locale";
import FormItemTransition from "utils/FormItemTransition";
import { useDWCoins } from "commonUse/useDW";
import { DropdownCoinItem } from "landings/V2/BuyCrypto/components/Form/items/DropdownCoinItem";
import cs from "../index.m.less";
import { ExchangeSymbol } from "TradeAPILib";

interface Props {
    coins?: BuyCryptoConfig["coins"];
    handleCoinAmountChange: (v) => void;
    coinAmount: string;
    coinPrecison: number | undefined;
}

const DEFAULT_QUOTE = "USD";

/**
 * 选择币种的FormItem
 */
const SelectCoinItem: React.FC<Props> = ({ coins, handleCoinAmountChange, coinAmount, coinPrecison }) => {
    const { $st } = useTranslators();
    const [dwCoins] = useDWCoins();
    const allCoinsMap = useMemo(() => {
        return new Map(dwCoins.map((item) => [item.coinName, item]));
    }, [dwCoins]);
    const [isSearch, setIsSearch] = useState<string>("");
    const finallyCoins = useMemo(() => {
        if (isSearch.length !== 0) {
            return coins?.filter((item) => {
                return item.coin.includes(isSearch.toUpperCase());
            });
        } else {
            return coins;
        }
    }, [coins, isSearch]);
    const variableRegex = new RegExp(`(^-?\\d{0,5})\\d*(\\.\\d{0,${coinPrecison}})?\\d*`);
    return (
        <div className="pt-[12px]">
            <div>{$st("buy_crypto_receive")}</div>
            <div className="flex flex-row justify-between">
                <Form.Item name="coinAmount">
                    <Input
                        type="number"
                        className={cs.amount}
                        precision={coinPrecison}
                        value={coinAmount}
                        prefix={<span className="text-accent font-b">≈</span>}
                        onChange={(e) => handleCoinAmountChange(e.target.value?.replace(variableRegex, "$1$2"))}
                    />
                </Form.Item>
                <Form.Item className="mb-0" name="coin" rules={[{ required: true }]}>
                    <FormItemTransition<string>>
                        {({ value, onChange }) => {
                            const coinInfo = coins?.find((item) => item.coin === value);
                            return (
                                <Dropdown
                                    className="bg-bg-100 rounded-12px"
                                    // overlayClassName={cs.dropdown}
                                    placement="bottomRight"
                                    trigger={["click"]}
                                    overlay={
                                        <Menu className="w-[370px]">
                                            <div className="px-[12px] pt-[10px]">
                                                <Input
                                                    prefix={<IconSearch20pxRegular className={"fill-secondary w-16px h-16px"} />}
                                                    onChange={(v) => {
                                                        setIsSearch(v.target.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="px-[12px] pt-[10px]">
                                                <span className="text-primary text-sm">{$st("buy_crypto_choose_asset_header_best")}</span>
                                                <span className="text-text-300 bg-text-500 rounded-2px p-2px text-[8px] ml-14px">{$st("buy_crypto_choose_asset_header")}</span>
                                            </div>
                                            <div className="h-[400px] overflow-y-auto pb-[5px]">
                                                {finallyCoins?.length !== 0 ? (
                                                    finallyCoins?.map(({ coin }) => {
                                                        return (
                                                            <div>
                                                                <Menu.Item
                                                                    className="block"
                                                                    key={coin}
                                                                    onClick={(v) => {
                                                                        onChange?.(coin);
                                                                    }}
                                                                >
                                                                    <DropdownCoinItem coin={coin} quote={DEFAULT_QUOTE} allCoinsMap={allCoinsMap} />
                                                                </Menu.Item>
                                                                <div className="bg-divider h-1px mx-[12px]" />
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <div className="h-full flex flex-col items-center">
                                                        <div className="text-center">
                                                            <IconBotNoData100pxBoldLightS size={100} />
                                                        </div>
                                                        <div>{$st("buy_crypto_choose_asset_search_no_data")}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </Menu>
                                    }
                                    onItemClick={(v) => {
                                        onChange?.(v.key);
                                    }}
                                >
                                    <div className="flex justify-center items-center font-b cursor-pointer  text-lg h-[52px] px-[10px] mobile:px-[20px]">
                                        <div className="flex items-center">
                                            {!!coinInfo && (
                                                <>
                                                    <CoinIcon target={coinInfo.coin} className="mr-8px !w-24px !h-24px" />
                                                    {coinInfo.coin}
                                                </>
                                            )}
                                            <IconDropDownArrow12pxBold size={14} className="ml-12px" />
                                        </div>
                                    </div>
                                </Dropdown>
                            );
                        }}
                    </FormItemTransition>
                </Form.Item>
            </div>
        </div>
    );
};
export default SelectCoinItem;
