import { Button, Dropdown, Menu, Spin } from "@pionex-web-kit/components";
import LinkRoute from "components/Link";
import React from "react";
import { useCoinsTrades } from "src/commonUse";
import { $st } from "src/utils/i18n";
import { history } from "utils/History";

function TradeBtn({ exchange, coin, onClick }: { exchange: string; coin: string; onClick: () => void }) {
    const [haveRequested, updateHR] = React.useState(false);
    const [currentBaseTradeList, requestTradeList, onLoading] = useCoinsTrades(exchange, coin, true);
    const [visibleState, changeVisibleState] = React.useState(false);

    function onVisibleChange(visible: boolean) {
        if (visible && !haveRequested) {
            requestTradeList();
            updateHR(true);
        }
        changeVisibleState(visible);
    }

    const disabled = currentBaseTradeList.length === 0 || coin === "USDT";

    if (currentBaseTradeList.length !== 0) {
        return (
            <Dropdown
                disabled={disabled}
                visible={visibleState}
                onVisibleChange={onVisibleChange}
                overlay={
                    <Menu>
                        {onLoading ? (
                            <Menu.Item style={{ textAlign: "center" }}>
                                <Spin />
                            </Menu.Item>
                        ) : (
                            currentBaseTradeList.map(({ base, symbol, baseDisplay, quote }) => {
                                return (
                                    <LinkRoute key={base} to={`/trade/${symbol.replace("/", "_")}/Bot`} onClickCallback={onClick}>
                                        <Menu.Item>{`${baseDisplay}/${quote}`}</Menu.Item>
                                    </LinkRoute>
                                );
                            })
                        )}
                    </Menu>
                }
                placement="bottomRight"
            >
                <Button size="small" disabled={disabled}>
                    {$st("balance.coins.option.trade")}
                </Button>
            </Dropdown>
        );
    } else {
        return (
            // 跳转到一个不存在的币对，通过from参数选择convert兑换的币种
            <Button size="small" onClick={() => history.push(`/trade/${coin}_X/Swap`, { from: coin })}>
                {$st("balance.coins.option.trade")}
            </Button>
        );
    }
}

export default React.memo(TradeBtn);
