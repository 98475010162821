import { useTranslators } from "commonUse/Locale";
import TitleCom from "landings/V2/CircleACH/components/TitleCom";
import { useACHActivity } from "landings/V2/CircleACH/utils";
import cs from "./cs.m.less";
import StripeACH from ".";
import React, { useCallback, useMemo, useState } from "react";
import { SafeDecimal } from "trade_utils_lib";
import { useAllowAddCard } from "../hooks";
import { Button, Form, Input, useForm, useWatch } from "@pionex-web-kit/components";
import useKycV2Info from "commonUse/useKycInfoV2";
import { useOnlineConfig } from "utils/onlineConfig";
import NoKyc, { useNoKycVisible } from "landings/V2/USDDW/components/NoKyc";

export default function StripeAmount() {
    const { $st } = useTranslators();
    const activity = useACHActivity("deposit");
    const [stripeAchFlag, setStripeAchFlag] = useState<boolean>();
    const { allow: allowAddCard, processFunc: addCardProcess } = useAllowAddCard();
    const [form] = useForm();
    const beforeDWVisible = useNoKycVisible();
    const amount = useWatch("amount", form);
    const openStripe = useCallback(() => {
        if (!allowAddCard) {
            addCardProcess?.();
            return;
        }
        setStripeAchFlag(true);
    }, [addCardProcess, allowAddCard]);
    const [{ originData }] = useKycV2Info();
    const userLevel = originData?.userLevel;
    const onlineConfig = useOnlineConfig();
    const limitConfig = onlineConfig.dw?.d?.stripe_ach;
    const { minAmount, maxAmount, maxRollingAmount } = useMemo(() => {
        const limits = limitConfig?.limitGroup?.[Math.min(userLevel || 0, 3)];
        return {
            minAmount: limits?.min || 0,
            maxAmount: limits?.max || 0,
            maxRollingAmount: limits?.frequency_max || 0,
        };
    }, [userLevel, limitConfig]);
    return (
        <>
            <div className={cs.container}>
                {beforeDWVisible ? (
                    <NoKyc reportEvent="fiatdeposit" />
                ) : (
                    <>
                        <TitleCom title={$st("ach_title")} activity={activity} />
                        <Form form={form} onFinish={openStripe}>
                            <Form.Item
                                name="amount"
                                rules={[
                                    {
                                        validator(_, value) {
                                            if (!value) {
                                                return Promise.reject($st("buy_crypto_input_amount"));
                                            }
                                            if (value && new SafeDecimal(value).lessThan(minAmount)) {
                                                return Promise.reject($st("buy_crypto_amount_least", { least: minAmount }));
                                            }
                                            if (value && new SafeDecimal(value).greaterThan(maxAmount)) {
                                                return Promise.reject(`At most ${maxAmount.toLocaleString()} dollars`);
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    type="number"
                                    precision={2}
                                    size={"large"}
                                    placeholder={
                                        maxAmount
                                            ? $st("debit_deposit_amount_placeholder", {
                                                  min: minAmount,
                                                  max: maxAmount.toLocaleString(),
                                              })
                                            : $st("ach_agreement_amount")
                                    }
                                />
                            </Form.Item>
                            <div className={cs.bottomTips}>
                                {$st("ach_tip2", {
                                    br: <br />,
                                    fee: <strong className={"text-primary"}>{$st("ach_0fee_des", { fee: "0%" })}</strong>,
                                    maxAmount: "",
                                    strong: (t) => <strong className={"text-accent"}>{t}</strong>,
                                    userLevelLimit: `$${maxRollingAmount.toLocaleString()} limit for any rolling 10-day.`,
                                })}
                            </div>
                            <div className="flex justify-center mt-20px">
                                <Button style={{ minWidth: 200 }} htmlType={"submit"} size="large" type="primary">
                                    {$st("common_submit")}
                                </Button>
                            </div>
                        </Form>
                    </>
                )}
            </div>
            {stripeAchFlag !== undefined && (
                <StripeACH
                    modalVisible={stripeAchFlag}
                    onClose={() => {
                        setStripeAchFlag(false);
                    }}
                    amount={amount}
                />
            )}
        </>
    );
}
