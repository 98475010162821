import { useBalanceAll } from "commonUse/useBalance";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormatNumberOptions, useIntl } from "react-intl";
import { OrderBookData, OrderBookEntry } from "TradeLib/TradeTypes";
import { ExchangeSymbol, ExchangeTicker, NumberCommonUtils, SafeDecimal } from "trade_utils_lib";
import { filter, map, mergeMap, throttleTime } from "rxjs/operators";
import { Observable, of } from "rxjs";
import ExchangeDataProvider from "TradeLib/ExchangeDataProvider";
import { ExchangeID } from "TradeAPILib";
import { useTicker } from "@pionex-web-kit/common-business";
import { CoinCapPreview } from "landings/V2/Market/hooks/useCoinCapRanks";
import { useSwapConfig } from "utils/swapConfig";
import { SymbolDesc, useSymbolTransferQuantity } from "commonUse";
import { SwapState } from "landings/Trade/Swap/helpers";
import { getSymbolPrecision } from "utils/decimal";
import { SwapAPI } from "landings/Trade/Swap/SwapAPI";
import { RxTools } from "utils/RxTools";
import { ConvertStatus } from "landings/Trade/Swap/types";

export interface SwapCoin {
    coin: string;
    coinDisplay: string;
    caps: string | number;
    nameDec?: string;
}

export const queryOrderBookObservable = (
    market?: string,
    base?: string,
    quote?: string,
    optionis?: {
        frequency?: number; // 数据获取频率
    },
): Observable<OrderBookData | undefined> => {
    if (!base || !quote || !market) {
        return of(undefined);
    }
    return ExchangeDataProvider.websocketManager
        .getOrderBookWebSocket()
        .getOrderBookObservable(market, base, quote)
        .pipe(
            throttleTime(optionis?.frequency || 0),
            filter((data) => {
                return data.base === base && data.quote === quote;
            }),
            map((data) => {
                if (!data) {
                    return undefined;
                }
                const newBids: OrderBookEntry[] = data.bids as OrderBookEntry[];
                const newAsks: OrderBookEntry[] = data.asks as OrderBookEntry[];
                return {
                    clean: data.clean,
                    base: data.base,
                    quote: data.quote,
                    bids: newBids,
                    asks: newAsks,
                };
            }),
        );
};

export type MapsRank = {
    caps: string | number | undefined;
    nameDec: string | undefined;
};

export interface IEnhanceData extends SwapCoin {
    supportSymbols: ExchangeSymbol[];
    supportCoins: (SwapCoin & { targetSymbol?: ExchangeSymbol; caps?: string | number; nameDec?: string })[];
}

export function getCaps(symbol: CoinCapPreview, exchangeRate): MapsRank {
    return {
        caps: symbol.supply * symbol.price * exchangeRate.exchangeRate,
        nameDec: symbol.name,
    };
}
function enhanceData(symbols: ExchangeSymbol[], enhanceDataMap: Map<string, IEnhanceData>, ranks: Map<CoinCapPreview["symbol"], MapsRank>, exception?: string) {
    symbols.forEach((symbol) => {
        const { quote, quoteDisplay = quote, base, baseDisplay = base } = symbol;
        function setSwapMap(fromCoin: string, fromCoinDisplay: string, toCoin: string, toCoinDisplay: string) {
            let _enhancedData = enhanceDataMap.get(fromCoin);
            if (_enhancedData) {
                if (!_enhancedData.supportSymbols.includes(symbol)) {
                    _enhancedData.supportSymbols.push(symbol);
                }
                if (!_enhancedData.supportCoins.some(({ coin }) => coin === toCoin)) {
                    _enhancedData.supportCoins.push({
                        coin: toCoin,
                        coinDisplay: toCoinDisplay,
                        caps: ranks.get(toCoin)?.caps || 0,
                        nameDec: ranks.get(toCoin)?.nameDec || "",
                        targetSymbol: symbol,
                        // caps:NumberUtils.toShortString(symbol.supply * symbol.price * exchangeRate.exchangeRate, true)
                    });
                }
            } else {
                _enhancedData = {
                    coin: fromCoin,
                    coinDisplay: fromCoinDisplay,
                    supportSymbols: [symbol],
                    nameDec: ranks.get(fromCoin)?.nameDec,
                    caps: ranks.get(fromCoin)?.caps || 0,
                    supportCoins: [
                        {
                            coin: toCoin,
                            coinDisplay: toCoinDisplay,
                            targetSymbol: symbol,
                            caps: ranks.get(toCoin)?.caps || 0,
                            nameDec: ranks.get(toCoin)?.nameDec || "",
                        },
                    ],
                };
            }
            enhanceDataMap.set(fromCoin, _enhancedData);
        }
        if (quote !== exception) setSwapMap(quote, quoteDisplay, base, baseDisplay);
        if (base !== exception) setSwapMap(base, baseDisplay, quote, quoteDisplay);
    });
    return enhanceDataMap;
}

export default enhanceData;

export function useBalanceAllToMap() {
    const balanceAll = useBalanceAll();
    const balanceAllMap = useMemo(() => {
        const map = new Map();
        balanceAll?.trade.balances.forEach((balanceItem) => {
            map.set(balanceItem.base, balanceItem);
        });
        return map;
    }, [balanceAll?.trade.balances]);
    return balanceAllMap;
}

/**
 * 将科学计数法转换为字符串的浮点数
 * @param num
 * @returns
 */
export function toNonExponential(num: number | string): string {
    const sn = num.toString();
    if (/e/.test(sn)) {
        const m = sn.match(/\d(?:\.(\d*))?e([+-]\d+)/);
        return m?.[1] && m?.[2] ? Number(sn).toFixed(Math.max(0, (m[1] || "").toString().length - Number(m[2]))) : sn;
    } else {
        return sn;
    }
}

/**
 * 基于 intl 的千分位格式化方法
 * @returns
 */
export function useNumberFormatter() {
    const intl = useIntl();
    return function enhancedNumberFormatter(number: number | string, opts?: FormatNumberOptions) {
        // 先去掉科学计数法
        const nonExNum = toNonExponential(number);
        // 将整数区与浮点区拆开
        const [integer, float] = nonExNum.split(".");
        // 对整数位进行千分位处理，最后拼接小数位
        return `${intl.formatNumber(Number(integer), opts)}${float ? `.${float}` : ""}`;
    };
}

export type TSwapConfigQuotes = {
    coin: string;
    maxAmount: number;
    maxQuality: number;
};
export type TSwapConfigBases = {
    coin: string;
    maxAmount: number;
};
export type TSwapSymbolConfig = {
    symbol: string;
    askMaxAva?: number;
    bidMaxAva?: number;
    askMinAva?: number;
    bidMinAva?: number;
    askMaxPer?: number;
    bidMaxPer?: number;
};
export type TSwapConfig = {
    interval: number;
    askMaxPer: number;
    bidMaxPer: number;
    askSpreadPer: number; // 卖盘点差
    bidSpreadPer: number; // 买盘点差
    abLimits: TSwapSymbolConfig[];
    abLimitsV2: TSwapSymbolConfig[];
};

const DEFAULT_MAX_PER = 0.005;

export const useSwapSymbolLimit = ({ base, quote, currentPrice }: { base?: string; quote?: string; currentPrice?: number }) => {
    const swapConfig = useSwapConfig();
    const abLimitsMap = useMemo(() => {
        const obj: { [k: string]: TSwapSymbolConfig } = {};
        (PLATFORM.PIONEX_US_V2 ? swapConfig?.abLimitsV2 : swapConfig?.abLimits)?.map((item) => {
            obj[item.symbol] = item;
        });
        return obj;
    }, [swapConfig?.abLimits, swapConfig?.abLimitsV2]);

    // 取得当前币种的配置
    const swapSymbolConfig = useMemo(() => {
        if (!swapConfig || !base || !quote || !currentPrice) return null;
        const symbolStr = `${base}_${quote}`;
        const config = abLimitsMap[symbolStr];
        if (config) {
            return config;
        }
        return {
            symbol: symbolStr,
            askMaxPer: swapConfig?.askMaxPer,
            bidMaxPer: swapConfig?.bidMaxPer,
        } as TSwapSymbolConfig;
    }, [abLimitsMap, base, currentPrice, quote, swapConfig]);

    // 盘口数据
    const orderBookData = useOrderBookDataWithTimes({ base, quote, frequency: swapConfig?.interval });

    // 计算ask和bid的数据
    return useMemo(() => {
        let askMaxCalc = 0;
        const askPriceLimit = new SafeDecimal(currentPrice).mul(1 + (swapSymbolConfig?.askMaxPer || DEFAULT_MAX_PER)).toNumber();
        orderBookData?.asks.some((item) => {
            if (item.price <= askPriceLimit) {
                askMaxCalc = new SafeDecimal(item.price).mul(item.quantity).add(askMaxCalc).toNumber();
                return false;
            }
            return true;
        });
        let bidMaxCalc = 0;
        const bidPriceLimit = new SafeDecimal(currentPrice).mul(1 - (swapSymbolConfig?.bidMaxPer || DEFAULT_MAX_PER)).toNumber();
        orderBookData?.bids.some((item) => {
            if (item.price >= bidPriceLimit) {
                bidMaxCalc = new SafeDecimal(item.quantity).add(bidMaxCalc).toNumber();
                return false;
            }
            return true;
        });

        return {
            ...swapSymbolConfig,
            askMaxCalc,
            bidMaxCalc,
        };
    }, [currentPrice, swapSymbolConfig, orderBookData?.asks, orderBookData?.bids]);
};

// 限制频率获取orderbook数据
export function useOrderBookDataWithTimes({
    base,
    quote,
    frequency = 1000,
}: {
    base?: string;
    quote?: string;
    frequency?: number; // 请求频率
}) {
    const [orderBookData, setOrderBookData] = useState(undefined as OrderBookData | undefined);

    useEffect(() => {
        const ob = queryOrderBookObservable(ExchangeID.PIONEXV2, base, quote, { frequency }).subscribe(
            (data) => {
                setOrderBookData(data);
            },
            () => setOrderBookData(undefined),
        );
        return () => {
            ob.unsubscribe();
        };
    }, [base, frequency, quote]);
    return orderBookData;
}

export function useGetLimit(swapSymbol: ExchangeSymbol | undefined, ticker: ExchangeTicker | undefined, swapSymbolTradeDirection: boolean, amountPrecision: number) {
    // 可用余额格式化
    const getAvailableBalance = useCallback(({ count, precision = 4 }: { count?: number | string; precision?: number }) => {
        return NumberCommonUtils.formatterDecimalToStr(count, precision);
    }, []);

    const swapSymbolLimit = useSwapSymbolLimit({
        base: swapSymbol?.base,
        quote: swapSymbol?.quote,
        currentPrice: ticker && swapSymbol ? +NumberCommonUtils.formatterDecimalToStr(ticker?.latest, swapSymbol?.precision) : 0,
    });
    // 可输入的范围
    return useMemo(() => {
        if (!swapSymbol) return null;
        const maxDataArray: number[] = [];
        const minDataArray: number[] = [];

        if (swapSymbolTradeDirection) {
            if (swapSymbol?.limit?.spending.min) {
                minDataArray.push(swapSymbol?.limit?.spending.min);
            }
            if (swapSymbolLimit.askMinAva) {
                minDataArray.push(swapSymbolLimit.askMinAva);
            }
            const minPrecision = `${swapSymbol?.limit?.spending.min}`.split(".")?.[1]?.length || amountPrecision;
            if (swapSymbol?.limit?.spending.max) {
                maxDataArray.push(Number(swapSymbol.limit.spending.max));
            }
            if (swapSymbolLimit.askMaxCalc) {
                maxDataArray.push(Number(getAvailableBalance({ count: swapSymbolLimit.askMaxCalc, precision: minPrecision })));
            }
            if (swapSymbolLimit.askMaxAva) {
                maxDataArray.push(swapSymbolLimit.askMaxAva);
            }
        } else {
            if (swapSymbol?.limit?.dumping.min) {
                minDataArray.push(swapSymbol?.limit?.dumping.min);
            }
            if (swapSymbolLimit.bidMinAva) {
                minDataArray.push(swapSymbolLimit.bidMinAva);
            }
            // 取最小精度配置上的精度
            const minPrecision = `${swapSymbol?.limit?.dumping.min}`.split(".")?.[1]?.length || amountPrecision;
            if (swapSymbol?.limit?.dumping.max) {
                maxDataArray.push(Number(swapSymbol.limit.dumping.max));
            }
            // 算出来的
            if (swapSymbolLimit.bidMaxCalc) {
                maxDataArray.push(Number(getAvailableBalance({ count: swapSymbolLimit.bidMaxCalc, precision: minPrecision })));
            }
            // 配置的
            if (swapSymbolLimit.bidMaxAva) {
                maxDataArray.push(swapSymbolLimit.bidMaxAva);
            }
        }

        return {
            // min 对比取最大的限制
            min: Math.max.apply(null, minDataArray) as number,
            // max 对比取最小的限制
            max: Math.min.apply(null, maxDataArray) as number,
        };
    }, [
        swapSymbol,
        swapSymbolTradeDirection,
        swapSymbolLimit.askMinAva,
        swapSymbolLimit.askMaxCalc,
        swapSymbolLimit.askMaxAva,
        swapSymbolLimit.bidMinAva,
        swapSymbolLimit.bidMaxCalc,
        swapSymbolLimit.bidMaxAva,
        amountPrecision,
        getAvailableBalance,
    ]);
}

export function useTickerSwap(swapSymbol, swapConfig): ExchangeTicker | undefined {
    const ticker = useTicker(swapSymbol?.base, swapSymbol?.quote, { duration: swapConfig?.interval });
    return swapSymbol ? ticker : undefined;
}

/**
 * 根据币对中一个币的输入计算另一个币的值
 */
export function useCalcPreview({ swapState, swapSymbol }: { swapState: SwapState; swapSymbol: Undefinable<ExchangeSymbol> }) {
    const swapConfig = useSwapConfig();

    const { params, validInput, precision } = useMemo(() => {
        const changeItem = swapState.from.needTicker ? swapState.to : swapState.from; // 手动变化的Item
        const calcItem = swapState.from.needTicker ? swapState.from : swapState.to; // 需要计算的item
        let type: Nullable<SymbolDesc["type"]> = null; // 只是计算的方向，不代表实际兑换的买卖方向

        if (!swapState.from.needTicker && !swapState.to.needTicker) {
            type = null;
        } else if (calcItem.symbol === swapSymbol?.base) {
            type = "buy";
        } else if (calcItem.symbol === swapSymbol?.quote) {
            type = "sell";
        }

        let amountDecimal = new SafeDecimal(changeItem.count);
        const fee = (type === "buy" ? swapConfig?.bidSpreadPer : swapConfig?.askSpreadPer) || 0;
        if (swapState.from.needTicker) {
            // 计算需要付出多少，加上点差
            amountDecimal = amountDecimal.mul(1 + fee);
        } else if (swapState.to.needTicker) {
            // 计算能够得到多少，减去点差
            amountDecimal = amountDecimal.mul(1 - fee);
        }

        const validInput = !!type; // 有效的输入

        return {
            params: {
                symbol: !validInput ? undefined : swapSymbol && { base: swapSymbol.base, quote: swapSymbol.quote, type: type! },
                amount: amountDecimal.toNumber(),
                frequency: swapConfig?.interval,
            },
            validInput,
            precision: getSymbolPrecision({
                symbol: swapSymbol,
                isMarketBuy: type === "buy",
                coin: calcItem.symbol,
            }),
        };
    }, [swapConfig?.askSpreadPer, swapConfig?.bidSpreadPer, swapConfig?.interval, swapState.from, swapState.to, swapSymbol]);

    const { quantity } = useSymbolTransferQuantity(params) || {};

    return {
        quantity: new SafeDecimal(quantity).toFixed(precision),
        validInput,
    };
}

/**
 * 请求执行convert，轮询结果
 * @param apiKey
 * @param params
 */
export function requestConvert(...[apiKey, params]: Parameters<typeof SwapAPI.applyConvert>) {
    return SwapAPI.applyConvert(apiKey, params).pipe(
        mergeMap(({ order_id }) =>
            SwapAPI.getSingleRecord(apiKey, order_id).pipe(RxTools.retryWithDelay((res) => !res?.status || res.status === ConvertStatus.pending, { delayMill: 1000, count: 10 })),
        ),
    );
}
