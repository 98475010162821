import { useTranslators } from "commonUse/Locale";
import PubSub from "pubsub-js";
import { useEffect } from "react";

export const LAYOUT_EVENT_CONTENT_GO_TOP = "content_go_top";
export const layoutGoTop = () => {
    PubSub.publish(LAYOUT_EVENT_CONTENT_GO_TOP, true);
};
window["layoutGoTop"] = layoutGoTop;

/**
 * 一些页面的老配置数据
 */
export function usePageDescription() {
    const { $st } = useTranslators();
    useEffect(() => {
        if (window.location.pathname.indexOf("/trade/") !== -1) {
            return;
        }
        window.document.title = $st("pionex_title");
        document.querySelector('meta[name="description"]')?.setAttribute("content", $st("pionex_description"));
        document.querySelector('meta[name="keywords"]')?.setAttribute("content", $st("pionex_keywords"));
        document.querySelector('meta[property="og:title"]')?.setAttribute("content", $st("pionex_title"));
        document.querySelector('meta[property="og:description"]')?.setAttribute("content", $st("pionex_og_description"));
        document.querySelector('meta[property="og:title"]')?.setAttribute("content", $st("pionex_og_title"));
    }, [$st]);
}
