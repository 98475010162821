import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, FormInstance, message, Modal, Spin } from "@pionex-web-kit/components";
import { BuyCryptoConfig, BuyCryptoParams } from "landings/V2/BuyCrypto/types";
import { PaymentMethod, usePaymentMethods } from "landings/V2/BuyCrypto/Buy/cards";
import { useTranslators } from "commonUse/Locale";
import { useAllowAddCard, useCalBuyAmount, useDefaultPaymentMethod, usePreviewAmount, useShortcuts } from "landings/V2/BuyCrypto/hooks";
import { ACH_AccountItem } from "landings/V2/CircleACH/utils";
import TReportLink from "components/v2/TinyComps";
import csn from "classnames";
import cs from "./index.m.less";
import { IconHistory24px } from "@pionex-web-kit/icons";
import KycLoginClick from "landings/V2/BuyCrypto/components/KycLoginClick";
import DataReporter from "landings/Analytics/DataReporter";
import { EventName } from "landings/Analytics/EventName";
import PayMethodItem from "landings/V2/BuyCrypto/components/Form/items/PayMethodItem";
import SelectCoinItem from "landings/V2/BuyCrypto/components/Form/items/SelectCoinItem";
import AmountItem from "landings/V2/BuyCrypto/components/Form/items/AmountItem";
import { NumberCommonUtils, SafeDecimal } from "trade_utils_lib";
import useMedia from "src/components-v2/Layout/hooks/useMedia";
import useAccountInfo from "commonUse/useAccountInfo";
import { useLocation } from "react-router";
import { useBalanceTradeByBaseQuote } from "commonUse/useBalance";
import StripeACH from "../../StripeACH";
import { history } from "landings";
import { USD_DW_ROUTE_V2 } from "landings/V2/USDDW/menuRouter";
import { useTickerMap } from "use/useTicker";
import { useSymbol, useTicker } from "@pionex-web-kit/common-business";
import { useGetLimit } from "landings/Trade/Swap/tool";
import useKycV2Info from "commonUse/useKycInfoV2";
import { useOnlineConfig } from "utils/onlineConfig";

export type PreviewAmountInfo = Pick<ReturnType<typeof usePreviewAmount>, "previewAmount" | "previewAmountPrecision">;

interface Props {
    className?: string;
    config?: BuyCryptoConfig;
    onFinish?: (paymentMethod: PaymentMethod, params: BuyCryptoParams) => void;
    form?: FormInstance;
    onPreviewAmount?: (previewAmountInfo: PreviewAmountInfo) => void;
}

export const FORM_KEY_PAYMENTMETHOD = "payMethod";
export const FORM_KEY_AMOUNT = "amount";

const AMOUNT_LEAST = 2;

export enum PayMentMethod {
    balance = "USD Balance",
    bank_account = "Bank Account",
    wire_transfer = "Wire Transfer",
}

const sortFixed = ["BTC", "ETH", "USDT"];
/**
 * 买币填写的表单
 */
const BuyCryptoForm: React.FC<Props> = ({ className, config, onFinish, form: outForm, onPreviewAmount }) => {
    const { $st } = useTranslators();
    const { isTablet } = useMedia();
    const { userId } = useAccountInfo();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const selectedCoin = params.get("coin");
    const { allow: allowAddCard, processFunc: addCardProcess } = useAllowAddCard();
    const [{ baseAvailable: usdAvailable }] = useBalanceTradeByBaseQuote("USD", "USDT"); // 原始的余额
    const coinsMap = useTickerMap();
    const [form] = Form.useForm(outForm);
    const currentCoin: string = Form.useWatch("coin", form);
    const currentPayMethod: string = Form.useWatch(FORM_KEY_PAYMENTMETHOD, form);
    const amount = Form.useWatch("amount", form);
    const coinAmount = Form.useWatch("coinAmount", form);
    const [isAmount, setIsAmount] = useState(false);
    const [isCoinAmount, setIsCoinAmount] = useState(false);
    const coinInfo = useSymbol(currentCoin, "USD");
    const ticker = useTicker(currentCoin, "USD");
    const buyPrecision = coinInfo?.precisionCost ?? 4;
    const coins = useMemo(() => {
        if (coinsMap && config) {
            const coin = config.coins.map((item) => {
                const coinsObj = coinsMap.get(`${item.coin}/USD`);
                return { ...item, quoteVol: coinsObj?.quoteVol || 0 };
            });
            return coin.sort((a, b) => {
                const aIndex = sortFixed.indexOf(a.coin);
                const bIndex = sortFixed.indexOf(b.coin);
                if (aIndex !== -1 && bIndex !== -1) {
                    return aIndex - bIndex;
                }
                if (aIndex !== -1) return -1;
                if (bIndex !== -1) return 1;
                return b?.quoteVol - a?.quoteVol;
            });
        }
    }, [coinsMap, config]);

    const { data: paymentMethods, refresh, dataLoaded } = usePaymentMethods(form);
    useEffect(() => {
        if (selectedCoin) {
            form.setFieldsValue({ coin: selectedCoin });
            return;
        }
        if (!config?.coins.length) {
            return;
        }
        form.setFieldsValue({ coin: config.coins[0].coin });
    }, [config?.coins, config?.coins.length, form, selectedCoin]);

    const coinPrecison = useMemo(() => {
        if (!coinInfo) {
            return 0;
        }
        return coinInfo.amount;
    }, [coinInfo]);
    const currentCoinInfo = useMemo(() => {
        return coins?.find((item) => item.coin === currentCoin);
    }, [coins, currentCoin]);
    const currentPayMethodInfo = useMemo(() => {
        return paymentMethods.find((item) => item.getKey() === currentPayMethod);
    }, [currentPayMethod, paymentMethods]);
    const channel = useMemo(
        () => currentCoinInfo?.channels.find((item) => item.channel === currentPayMethodInfo?.channel),
        [currentCoinInfo?.channels, currentPayMethodInfo?.channel],
    );

    const [{ originData }] = useKycV2Info();
    const userLevel = originData?.userLevel;

    const onlineConfig = useOnlineConfig();
    const limitConfig = onlineConfig.dw?.d?.stripe_ach;
    const { maxAmount, maxRollingAmount } = useMemo(() => {
        const limits = limitConfig?.limitGroup?.[Math.min(userLevel || 0, 3)];
        return {
            minAmount: limits?.min || 0,
            maxAmount: limits?.max || 0,
            maxRollingAmount: limits?.frequency_max || 0,
        };
    }, [userLevel, limitConfig]);
    // const {  previewAmountText, previewAmountPrecision, amountLimit } = usePreviewAmount({ coin: currentCoin, amount, fee: channel?.fee });
    const {
        quantity: previewQuantity,
        previewAmount,
        previewPrice,
    } = useCalBuyAmount({
        swapSymbol: coinInfo,
        baseParams: { symbol: currentCoin, count: Number(coinAmount), needTicker: isAmount },
        quoteParams: { symbol: "USD", count: Number(amount), needTicker: isCoinAmount },
        isBuy: true,
        fee: channel?.fee,
    }); // 预计得到
    const amountLimit = useGetLimit(coinInfo, ticker, true, buyPrecision);
    useEffect(() => {
        // 设置默认输入额度
        form.setFieldsValue({ amount: "100" });
        form.validateFields();
        setIsAmount(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (usdAvailable > 0.01) {
            setIsSelect(PayMentMethod.balance);
        } else {
            setIsSelect(PayMentMethod.bank_account);
        }
    }, [usdAvailable]);

    useEffect(() => {
        if (isAmount) {
            form.setFieldsValue({ coinAmount: previewQuantity });
            return;
        }
        if (isCoinAmount) {
            form.setFieldsValue({ amount: previewQuantity });
            return;
        }
    }, [form, isAmount, isCoinAmount, previewQuantity]);
    const [stripeAchFlag, setStripeAchFlag] = useState<boolean>();
    const cacheDefaultPaymentMethod = useDefaultPaymentMethod(form, paymentMethods);
    const shortcuts = useShortcuts();
    const [isSelect, setIsSelect] = useState<string>();
    const selectPayMethod = useCallback((value) => {
        setIsSelect(value);
    }, []);
    const handleAmountCgange = useCallback(
        (value) => {
            form.setFieldsValue({ amount: value });
            setIsCoinAmount(false);
            setIsAmount(true);
            form.validateFields();
        },
        [form],
    );
    const handleCoinAmountChange = useCallback(
        (value) => {
            form.setFieldsValue({ coinAmount: value });
            setIsAmount(false);
            setIsCoinAmount(true);
            form.validateFields();
        },
        [form],
    );

    const submit = useCallback(() => {
        if (isSelect === PayMentMethod.balance) {
            if (Number(amount) > usdAvailable) {
                if (new SafeDecimal(amount).greaterThan(maxAmount)) {
                    message.error($st("transaction_limit", { amount: maxAmount.toLocaleString() }));
                    return;
                }
                setStripeAchFlag(true);
                return;
            }
            const fiatAmount = amount;
            const params: BuyCryptoParams = {
                // TODO 暂时只有ACH
                ach_account_id: (currentPayMethodInfo!.card as ACH_AccountItem).id,
                channel_fee: "0",
                channel: channel!.channel,
                buy_amount: new SafeDecimal(previewAmount).toFixed(buyPrecision).toString(),
                buy_coin: currentCoin,
                buy_price: previewPrice,
                fiat_amount: fiatAmount,
            };
            onFinish?.(currentPayMethodInfo!, params);

            DataReporter.report(EventName.buycrypto_submit, {
                fiat_amount: params.fiat_amount,
                channel: params.channel,
                buy_coin: params.buy_coin,
            });
        } else if (isSelect === PayMentMethod.bank_account) {
            if (!amount || !allowAddCard) {
                addCardProcess?.();
                return;
            }
            if (new SafeDecimal(amount).lessThan(2)) {
                message.warning($st("buy_crypto_amount_least", { least: 2 }));
                return;
            }
            setStripeAchFlag(true);
        } else {
            Modal.confirm({
                title: $st("buy_crypto_wire_title"),
                content: $st("buy_crypto_wire_des"),
                okText: $st("fbo_addCard_transfer_type_wire"),
                onOk: () => {
                    history.push("/balance/wire");
                },
            });
        }
    }, [
        $st,
        addCardProcess,
        allowAddCard,
        amount,
        buyPrecision,
        channel,
        currentCoin,
        currentPayMethodInfo,
        isSelect,
        maxAmount,
        onFinish,
        previewAmount,
        previewPrice,
        usdAvailable,
    ]);
    const onSubmit = async () => {
        try {
            await form.validateFields();
            submit();
        } catch (errorInfo) {
            if (errorInfo.errorFields) {
                errorInfo.errorFields.forEach((error) => {
                    if (error.errors.includes($st("buy_crypto_amount_exceeded", { max: NumberCommonUtils.formatterDecimalToStr(usdAvailable, 2) }))) {
                        submit();
                    }
                });
            }
        }
    };
    return (
        <>
            <Form form={form} className={className} labelWrap={true}>
                <KycLoginClick>
                    <TReportLink to={"/orders/buy-crypto/bought"} className="mobile:mb-8px text-accent flex items-center justify-end mt-20px mobile:hidden">
                        <IconHistory24px size={20} className="mr-6px" /> {$st("buy_crypto_history")}
                    </TReportLink>
                </KycLoginClick>
                <Spin spinning={!dataLoaded}>
                    <div className={"rounded-12px px-16px mt-[20px] mb-[10px] bg-bg-200 pb-[20px] mobile:mt-0"}>
                        <AmountItem
                            isSelect={isSelect}
                            handleAmountCgange={handleAmountCgange}
                            amount={amount}
                            amountLimit={amountLimit}
                            currentPayMethodInfo={currentPayMethodInfo}
                        />
                    </div>
                    <div className={"bg-bg-200 rounded-12px px-16px mb-[10px]"}>
                        <SelectCoinItem coinPrecison={coinPrecison} handleCoinAmountChange={handleCoinAmountChange} coinAmount={coinAmount} coins={coins} />
                    </div>
                    <div className={`${isTablet && !userId ? "hidden" : "block"}`}>
                        <PayMethodItem
                            usdAvailable={usdAvailable}
                            form={form}
                            paymentMethods={paymentMethods}
                            refresh={refresh}
                            selectPayMethod={selectPayMethod}
                            isSelect={isSelect}
                        />
                        <KycLoginClick>
                            <Button
                                disabled={new SafeDecimal(amount).greaterThan(amountLimit?.max ?? 0) && isSelect === PayMentMethod.balance}
                                className={csn("w-full  mt-[10px]", cs.submitBtn)}
                                size="large"
                                type="primary"
                                onClick={onSubmit}
                            >
                                {Number(amount) > usdAvailable && isSelect === PayMentMethod.balance ? $st("buy_via_bank_account") : $st("buy_crypto_buy")}
                            </Button>
                        </KycLoginClick>
                        <div className="text-text-300 font-normal pt-[10px] text-center">{$st("buy_crypto_buy_tips")}</div>
                    </div>
                </Spin>
            </Form>
            {stripeAchFlag !== undefined && (
                <StripeACH
                    isSelect={isSelect}
                    modalVisible={stripeAchFlag}
                    onClose={() => {
                        setStripeAchFlag(false);
                    }}
                    amount={amount}
                    coin={currentCoin}
                    coinAmount={coinAmount}
                />
            )}
        </>
    );
};

export default BuyCryptoForm;
