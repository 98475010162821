import React from "react";
import { withDebitData } from "landings/V2/CircleDebit/context";

interface Props {}

/**
 * 包裹整个Debit模块
 */
const CircleDebitWrapper: React.FC<Props> = ({ children }) => {
    return <>{children}</>;
};

/**
 * 注入数据
 */
export default withDebitData(CircleDebitWrapper);
