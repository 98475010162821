import { useLocalStorage } from "@pionex-web-kit/common-business";
import { BUAccountTokenUsage } from "bu_account_js_sdk";
import { useEffect } from "react";
import { of } from "rxjs";
import { delay } from "rxjs/operators";
import useAccountInfo from "commonUse/useAccountInfo";
import AccountManager, { KEY_ACCOUNT_TOKEN } from "utils/AccountManager";
import DataReporter from "landings/Analytics/DataReporter";

export const AccountTokenProvider = ({ children }) => {
    const accountInfo = useAccountInfo();
    const [token] = useLocalStorage<any>(KEY_ACCOUNT_TOKEN);

    useEffect(() => {
        if (!accountInfo.isInitialized) {
            return;
        }
        const subscription = of(0)
            .pipe(delay(500)) // 延迟一会再处理，等待数据同步
            .subscribe({
                next: () => {
                    const tokenStorage = AccountManager.shared.buAccount.tokenStorage;
                    if (!tokenStorage) {
                        return;
                    }
                    const localRefreshToken = token?.refresh?.token;
                    const localAccessToken = token?.access?.token;
                    const localAccessTokenExpireAt = token?.access?.expireAt;
                    const currentRefreshToken = tokenStorage.getToken(BUAccountTokenUsage.refresh)?.token;
                    const currentAccessToken = tokenStorage.getToken(BUAccountTokenUsage.access)?.token;
                    const currentAccessTokenExpireAt = tokenStorage.getToken(BUAccountTokenUsage.access)?.expireAt;
                    if (localAccessToken === currentAccessToken) {
                        // token 没变化
                        return;
                    }
                    if (!localAccessToken) {
                        // 暂不处理没有localAccessToken情况，如果在其他窗口退出登录，本窗口会自动弹出退出登录的提示窗
                        return;
                    }
                    const changeInfo = {
                        userId: accountInfo.userId,
                        signExpiredModalShown: AccountManager.shared.signExpiredModalShown,
                        localRefreshToken: !!localRefreshToken,
                        currentRefreshToken: !!currentRefreshToken,
                        localAccessToken: !!localAccessToken,
                        currentAccessToken: !!currentAccessToken,
                        localAccessTokenExpireAt,
                        currentAccessTokenExpireAt,
                    };
                    if (accountInfo.userId) {
                        // 本窗口已登录
                        if (currentAccessToken) {
                            if (localRefreshToken !== currentRefreshToken) {
                                if (AccountManager.shared.signExpiredModalShown) {
                                    // 在其他窗口重新登录了,刷新一下当前页面
                                    DataReporter.report("account_token_provider", { action: "reload", ...changeInfo });
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 500);
                                } else {
                                    // refresh token变了，没过期弹窗，暂不处理
                                    DataReporter.report("account_token_provider", { action: "ignore", ...changeInfo });
                                }
                            } else if (!localAccessTokenExpireAt || !currentAccessTokenExpireAt) {
                                // 可能有非法token，暂不处理
                                DataReporter.report("account_token_provider", { action: "ignore", ...changeInfo });
                            } else if (localAccessTokenExpireAt <= currentAccessTokenExpireAt) {
                                // 在本窗口刷了access token，不需要处理
                                DataReporter.report("account_token_provider", { action: "ignore", ...changeInfo });
                            } else {
                                // 在其他窗口刷了access token
                                DataReporter.report("account_token_provider", { action: "update", ...changeInfo });
                                AccountManager.shared.disableForceRefreshExpire = Date.now() + 10000;
                                AccountManager.shared.buAccount.updateToken({
                                    use: BUAccountTokenUsage.access,
                                    token: localAccessToken,
                                    expireAt: localAccessTokenExpireAt,
                                });
                            }
                        } else {
                            // 未知场景，理论上不会出现
                            DataReporter.report("account_token_provider", { action: "ignore", ...changeInfo });
                        }
                    } else {
                        // 本窗口未登录
                        if (!currentAccessToken) {
                            // 在其他窗口登录了， 本窗口重新初始化账户
                            DataReporter.report("account_token_provider", { action: "init", ...changeInfo });
                            AccountManager.shared.initFromLocal();
                        } else {
                            // 其他场景，可能是本窗口退出登录
                            DataReporter.report("account_token_provider", { action: "ignore", ...changeInfo });
                        }
                    }
                },
                error: () => {},
            });
        return () => subscription.unsubscribe();
    }, [token?.access?.token, accountInfo.isInitialized, accountInfo.userId, token?.access?.expireAt, token?.refresh?.token]);

    return children;
};
