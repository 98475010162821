import { Axis, Chart, Geom } from "bizcharts";
import React from "react";
import useTheme from "commonUse/useTheme";

export interface MiniLineChartEntry {
    x: number;
    y: number;
}

interface MiniLineChartProps {
    entries: MiniLineChartEntry[];
    lineColor: string | undefined;
    priceStr: string | undefined;
}

export const MiniLineChart = ({ entries, lineColor, priceStr }: MiniLineChartProps) => {
    let startColor = `${lineColor}00`;
    let endColor = `${lineColor}60`;
    return (
        <div>
            <Chart key={lineColor} padding={{ top: 0, right: 0, bottom: 0, left: 0 }} width={100} height={32} data={priceStr ? entries : []}>
                <Axis name="x" visible={false} />
                <Axis name="y" visible={false} />
                <Geom
                    type="area"
                    position="x*y"
                    active={false}
                    style={{
                        fill: `l(270) 0:${startColor} 1:${endColor}`,
                    }}
                />
                <Geom type="line" position="x*y" color={lineColor} size={0.5} active={false} />
            </Chart>
        </div>
    );
};
