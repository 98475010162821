import React from "react";
import { LayoutConfig } from "src/components-v2/Layout";
import { Result } from "antd";
import { $st } from "src/utils/i18n";
import { Iconfont, TReportBlockLink } from "src/components/v2/TinyComps";
import { generateSignUpProps, JumpToDownloadLink } from "src/components-v2/Layout/Header.v2/utils";
import cs from "./cs.m.less";
import { TutorialsModal } from "src/components-v2/Layout/Header.v2/utils";
import { useLocation } from "react-router";

export default function GetBotsSuccess() {
    const { state } = useLocation();
    const signUpLinkProps = generateSignUpProps("after_got_bot");
    return (
        <LayoutConfig showSidebar={false}>
            <Result
                className={cs.results}
                status="success"
                title={$st("bots_success_title")}
                subTitle={$st("bots_success_content")}
                icon={<img src={require("images/v2/home/icon_succesess.svg")} alt={""} />}
                extra={
                    <p className={cs.tips}>
                        <div className={cs.btns}>
                            <TReportBlockLink blocked type="primary" size={45} {...signUpLinkProps} to={{ ...signUpLinkProps.to, state }}>
                                <Iconfont icon="icon_SignUp" /> {$st("sign_up_button")} <Iconfont icon="icon_LearnMore_A1" />
                            </TReportBlockLink>
                            <TutorialsModal>
                                <TReportBlockLink blocked type="blue" size={45}>
                                    <Iconfont icon="icon_nav_surport_Tutorial" /> {$st("bots_success_btn_tutorial")}
                                    <Iconfont icon="icon_LearnMore_A1" />
                                </TReportBlockLink>
                            </TutorialsModal>
                            <JumpToDownloadLink source={"after_got_bot"}>
                                <TReportBlockLink blocked type="lined" size={45}>
                                    <Iconfont icon="icon_nav_AppDownload" /> {$st("bots_success_btn_download")} <Iconfont icon="icon_LearnMore_A1" />
                                </TReportBlockLink>
                            </JumpToDownloadLink>
                        </div>
                    </p>
                }
            />
        </LayoutConfig>
    );
}
