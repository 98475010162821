import { ReactNode } from "react";
import { LANGUAGE_LOCALS_MAP } from "utils/i18n";

export const getNavMenuConfig = (locale: string) => {
    const isCN = locale === LANGUAGE_LOCALS_MAP.zhCN;
    const isTW = locale === LANGUAGE_LOCALS_MAP.zhTW;
    const isES = locale === LANGUAGE_LOCALS_MAP.es;
    return [
        {
            title: "header_menu_community",
            menus: [
                {
                    extra: "telegram",
                    icon: "icon_nav_telegrame",
                    label: "homepage_commu_telegram",
                    link: PLATFORM.PIONEX_US_LIKE ? "https://t.me/pionexus" : isCN ? "https://t.me/pionexcn" : isTW ? "https://t.me/Pionex_TW" : "https://t.me/pionexus",
                },
                {
                    extra: "discord",
                    icon: "icon_nav_Community_Discord",
                    label: "homepage_commu_discord",
                    link: "https://discord.gg/dZbvbeXK46",
                },
                {
                    extra: "twitter",
                    icon: "icon_nav_Community_Twitter",
                    label: "homepage_commu_twitter",
                    link: PLATFORM.PIONEX_US_LIKE ? "https://twitter.com/PionexUS" : "https://twitter.com/PionexUS",
                },
                // {
                //     extra: "facebook",
                //     icon: "icon_nav_Community_facebook",
                //     label: "homepage_commu_facebook",
                //     link: PLATFORM.PIONEX_US_LIKE ? "https://www.facebook.com/pionexglobal" : "https://www.facebook.com/pionexglobal",
                // },
                {
                    extra: "reddit",
                    icon: "icon_nav_Community_Reddit",
                    label: "homepage_commu_reddit",
                    disabled: PLATFORM.PIONEX_US_LIKE,
                    link: PLATFORM.PIONEX_US_LIKE ? "https://www.reddit.com/r/Pionex/" : "https://www.reddit.com/r/Pionex/",
                },
            ],
        },
        {
            title: "home_foot_menu_about_title",
            menus: [
                // TODO: 等待法务上线
                // {
                //     icon: "icon_nav_About_TeamInfo",
                //     label: "home_foot_menu_about_team",
                //     link: "/team",
                // },
                // {
                //     icon: "icon_nav_About_TermsAndConditions",
                //     label: "home_foot_menu_about_terms",
                //     link: "",
                // },
                {
                    icon: "icon_nav_About_Fee",
                    label: "home_foot_menu_about_fee",
                    link: "/fees",
                },
                {
                    icon: "icon_nav_About_list",
                    label: "home_foot_menu_about_coin",
                    link: "/coinlist",
                },
                {
                    icon: "icon_nav_About_privacy",
                    label: "home_foot_menu_about_privacy",
                    link: "https://www.pionex.us/blog/privacy-policy/",
                },
                {
                    icon: "icon_nav_About_statement",
                    label: "home_foot_menu_about_disclaimer",
                    link: "https://www.pionex.us/blog/disclaimer/",
                },
                {
                    icon: "icon_nav_About_UserAgreement",
                    label: "home_foot_menu_about_user",
                    link: "https://www.pionex.us/blog/pionex-user-conduct-code/",
                },
                {
                    icon: "icon_TermsOfService",
                    label: "home_foot_terms_service",
                    link: "https://www.pionex.us/blog/pionex-terms-of-service/",
                },
            ],
        },
        {
            title: "trade_utils_guide",
            menus: [
                {
                    icon: "icon_nav_About_blog",
                    label: "support_tab_blog",
                    link: isCN
                        ? "https://www.pionex.us/blog/zh-cn/"
                        : isTW
                        ? "https://www.pionex.us/blog/zh"
                        : isES
                        ? "https://www.pionex.us/blog/es/"
                        : "https://www.pionex.us/blog",
                },
                {
                    icon: "icon_nav_About_youtube",
                    label: "header_support_youtube",
                    link: isCN
                        ? "https://b23.tv/yYa5xE"
                        : isTW
                        ? "https://www.youtube.com/channel/UCLH1LUycVyqCKwbnGSfvtFA"
                        : "https://www.youtube.com/channel/UCyrwYO_v1sFnZnEYk-NWYMw",
                },
                {
                    icon: "icon_nav_About_FAQ",
                    label: "header_support_faq",
                    link: isCN
                        ? "https://pionex.zendesk.com/hc/zh-cn/categories/4409936703513-%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98"
                        : isTW
                        ? "https://pionex.zendesk.com/hc/zh-tw/categories/4409936703513-%E5%B8%B8%E8%A6%8B%E5%95%8F%E9%A1%8C"
                        : "https://pionex.zendesk.com/hc/en-us/categories/4409936703513-FAQ",
                },
            ],
        },
    ] as { title: string; menus: { icon: ReactNode; label: string; link: string; extra?: string; disabled?: boolean }[] }[];
};
