import React from "react";
import { Network } from "landings/V2/CircleDebit/types";
import useTheme from "commonUse/useTheme";
import { ThemeName } from "state/ThemeData";

const IMAGE = {
    [Network.VISA]: {
        front: { [ThemeName.light]: require("../assets/visa_front.png"), [ThemeName.dark]: require("../assets/visa_front_dark.png") },
        back: { [ThemeName.light]: require("../assets/visa_back.png"), [ThemeName.dark]: require("../assets/visa_back_dark.png") },
    },
    [Network.MASTER]: {
        front: { [ThemeName.light]: require("../assets/master_front.png"), [ThemeName.dark]: require("../assets/master_front_dark.png") },
        back: { [ThemeName.light]: require("../assets/master_back.png"), [ThemeName.dark]: require("../assets/master_back_dark.png") },
    },
};

export interface CardSampleProps {
    className?: string;
    cardType?: Network;
    orientation?: "front" | "back";
}

/**
 * 卡片信息位置展示
 * @constructor
 */
const CardSample: React.FC<CardSampleProps> = ({ className, cardType = Network.VISA, orientation = "front" }) => {
    const theme = useTheme();

    return (
        <div className={className}>
            <img alt={""} className={"w-full"} src={IMAGE[cardType][orientation][theme.name]} />
        </div>
    );
};

export default CardSample;
