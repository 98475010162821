import React from "react";
import { DebitCard, DebitCardStatus, ESumsubVerifyStatus } from "landings/V2/CircleDebit/types";
import cs from "landings/V2/CircleDebit/Deposit/index.m.less";
import { TButton } from "components/v2/TinyComps";
import { useTranslators } from "commonUse/Locale";
import { message } from "antd";
import { getCircleCardRejectText } from "landings/V2/USDDW/utils";
import CardSample from "landings/V2/CircleDebit/components/CardSample";
import cardCs from "../AddCheckoutCard/AddCheckoutCard.m.less";
interface Props {
    card: DebitCard;
    verifyCard: () => void;
    onAddCard: () => void;
    disableAddCard: boolean;
}

/**
 * 禁止操作时覆盖的内容
 * @constructor
 */
const TipsCover: React.FC<Props> = ({ card, verifyCard, onAddCard, disableAddCard }) => {
    const { $st } = useTranslators();
    const content = (() => {
        if (card.channelInvalid) {
            // 对应通道维护时，不展示浮层
            return null;
        }

        switch (card.status) {
            case DebitCardStatus.FAILED:
                return (
                    <>
                        <div className={cs.tipsTitle}>{getCircleCardRejectText($st, card.failReason, card.failDetail)}</div>
                        <TButton
                            type={"primary"}
                            onClick={() => {
                                if (disableAddCard) {
                                    message.info($st("debit_add_card_limit"));
                                    return;
                                }
                                onAddCard();
                            }}
                        >
                            {$st("debit_reject_cover_tips_btn")}
                        </TButton>{" "}
                    </>
                );

            case DebitCardStatus.VERIFY_REQUIRED:
                if (card.verifyStatus === ESumsubVerifyStatus.RESUBMISSION) {
                    return (
                        <>
                            <div className={cs.tipsTitle}>{$st("debit_resubmission_cover_tips_title")}</div>
                            <div className="text-accent my-12px font-semibold">{$st("debit_upload_sample_screenshot")}</div>
                            <CardSample className={cardCs.cardSample} />
                            <TButton type={"primary"} onClick={verifyCard}>
                                {$st("debit_resubmission_cover_btn")}
                            </TButton>
                        </>
                    );
                }

                if (card.verifyStatus !== ESumsubVerifyStatus.PENDING) {
                    return (
                        <>
                            <div className="text-center mb-20px">
                                <img width={"90"} height={"76"} src={require("../../../../assets/card_uploading.png")} />
                                <div className="text-accent font-semibold mt-10px">{$st("debit_upload_wait_continue")}</div>
                            </div>
                            <div className={cs.tipsTitle}>{$st("debit_upload_cover_tips_title")}</div>
                            {[$st("debit_upload_cover_tips_li0"), $st("debit_upload_cover_tips_li1"), $st("debit_upload_cover_tips_li2")].map((item) => (
                                <div key={item}>{item}</div>
                            ))}
                            <div className="text-accent my-12px font-semibold">{$st("debit_upload_sample_screenshot")}</div>
                            <CardSample className={cardCs.cardSample} />
                            <TButton type={"primary"} onClick={verifyCard}>
                                {$st("debit_upload_cover_tips_btn")}
                            </TButton>
                        </>
                    );
                }

                return null;

            default:
                return null;
        }
    })();

    return content && <div className={cs.tipsCover}>{content}</div>;
};

export default TipsCover;
