import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import Constants from "utils/Constants";
import { useCallbackStatic } from "./tools";
import useAccountInfo from "./useAccountInfo";
import { ObservableUtils } from "trade_utils_lib";
import TReportLink, { TModal } from "components/v2/TinyComps";
import { history } from "utils/History";
import { LANGUAGE_LOCALS_MAP } from "utils/i18n";
import throttle from "lodash/throttle";
import { useIntl } from "react-intl";
import { LocationDescriptor } from "history";
import { message } from "antd";
import { platformForAPI } from "utils/platform";
import { useExtraProcessReasons } from "landings/V2/Account/paths/Kyc-v2/utils";
import { ST } from "./Locale";
// 新旧用户时间分界线

// KYC受限地区语言与对应权限的判别规则逻辑
// enum RIGHTS {
//     OLD_LV1 = "可交易、可充提，提币限额为1.5w",
//     OLD_LV2 = "可交易、可充提，提币限额为30w",
//     LV0 = "不可交易、不可充提",
//     LV1 = "可交易、可充提，提币额度为2k",
//     LV2 = "可交易、可充提，提币额度为100w",
// }

// function getRightType(kycData: any) {
//     // 判别是否有KYC数据（不管新老）
//     const hasKycData = !!kycData;
//     const language = "enUS";
//     if (hasKycData) {
//         // 当前KYC数据中对应的KYC等级
//         const kycLevel: "LV1" | "LV2" = "LV1";
//         // 当前KYC数据中限制的 region 值
//         const kycRegion = "USA";
//         // 判别是否是受限制的国家
//         const isLimitedRegion = kycRegion in ["USA", "CHN"];

//         // 当检测到是受限国家时
//         if (isLimitedRegion) {
//             // 没做KCY
//             if (kycLevel === "LV1") {
//                 return RIGHTS.OLD_LV1;
//             } else {
//                 // kycLevel === "LV2"
//                 return RIGHTS.OLD_LV2;
//             }
//         } else {
//             // 不是受限国家时，该是什么等级就是什么等级
//             return RIGHTS[kycLevel];
//         }
//     } else {
//         // 是否新用户
//         const isNewUser = true;
//         // 如果是受限制语言
//         if (language in ["enUS", "zhCN"]) {
//             return RIGHTS.OLD_LV1;
//         } else {
//             // 如果是个新用户，就默认是 LV0
//             if (isNewUser) {
//                 return RIGHTS.LV0;
//             } else {
//                 // 老用户则是老的规则
//                 return RIGHTS.OLD_LV1;
//             }
//         }
//     }
// }

export enum KycV2LevelStatus {
    notStarted = "NOT_STARTED", //未认证
    // US站点有所调整，新增了Pending状态表示用户正在进行KYC的异步提交流程（流程状态不可被监听的情况下）
    pending = "PENDING", // 认证进行中
    reviewing = "REVIEWING", //审核中
    manualReviewing = "MANUAL_REVIEW",
    IDVPassed = "IDV_PASSED", //IDV(身份认证)认证通过，可认为是一种特殊的审核中状态，这时需要补充地址证明。
    passed = "PASSED", //验证通过
    failed = "FAILED", //验证失败
    halfFailed = "HALF_FAILED", //验证失败，但可重新提交;
}

export enum KycV2Level {
    /**
     * 所有其他老用户「应该走老的判断逻辑的用户」
     * 用户依然享有：交易、充币、提币 权限
     */
    /**
     * 做了kyc的老用户
     */
    oldLv2 = -2,
    /**
     * 未做KYC的老用户
     */
    oldLv1 = -1,
    /**
     * 新版KYC-L0
     */
    lv0 = 0,
    /**
     * 新版KYC-L1
     */
    lv1 = 1,
    /**
     * 新版KYC-L2
     */
    lv2 = 2,
}
export interface KycV2Info extends Pick<KycV2OriginData, "exchange"> {
    /**
     * 当前KYC的level
     */
    currentLevel: null | KycV2Level;
    /**
     * 用户可以使用的kyc版本
     */
    kycDestination: "v1" | "v2" | null;
    /**
     * 认证level的Map映射，便于获取对应level的状态数据（字段已经转换）
     */
    forwardLevelMap: Map<number, { level: KycV2Level; status: KycV2LevelStatus; reasons?: KycV2Lv2FailReasons[]; reasonTexts?: string[] }>;
    /**
     * kyc位置信息
     */
    location?: Pick<KycV2OriginData, "province" | "region">;
    /**
     * KYC源数据
     */
    originData?: KycV2OriginData;
}
interface KycV2OriginData {
    userId?: string;
    /**
     * KYC引擎
     * - 1: 老KYC
     * - 2: 新KYC
     */
    engineVersion?: 1 | 2;
    /**
     * 用户所属站点，主站、新加坡、（美国）
     * 主站：MAIN
     */
    site?: string;
    /**
     * 地区 参考标准 {@link ISO_3166 | https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3} or {@link IBM-Support | https://www.ibm.com/support/pages/country-or-region-codes-0}
     */
    region?: string;
    /**
     * 省份/州 参考标准 {@link https://en.wikipedia.org/wiki/ISO_3166-2:US}
     */
    province?: string;
    /**
     * 标记使用哪个底层交易所（当前仅US站点需要）
     */
    exchange?: "pionex" | "paxos";
    /**
     * KYC验证等级信息
     */
    levelInfo?: {
        level?: number;
        /**
         * 验证状态
         * - NOT_STARTED：未认证
         * - REVIEWING：审核中
         * - PASSED: 通过
         * - HALF_FAILED：未通过（可重新提交）
         * - FAILED: 未通过（最终失败，用户要清退）
         */
        result?: KycV2LevelStatus;
        /**
         * 失败原因的key列表
         */
        failReason?: KycV2Lv2FailReasons[];
        /**
         * 失败原因描述信息，可能支持国际化
         */
        failReasonDetails?: string[];
        /**
         * KYC平台
         * - SUMSUB: Sumsub
         * - JUMIO: Jumio
         * - LOCAL: 派网
         */
        verificationPlatform?: string;
        /**
         * 外部kyc认证url，只有verificationPlatform=JUMIO才可能有
         */
        externalVerificationWebUrl?: string;
    }[];
    /**
     * KYC用户信息
     */
    userInfo?: {
        /**
         * 电话，由一级kyc带入
         */
        phone?: string;
        firstName?: string;
        lastName?: string;
        middleName?: string;
        /**
         * 性别
         * - M: 男
         * - F: 女
         * - U: 未知
         */
        gender?: "M" | "F" | "U";
        /**
         * SSN号类型
         */
        ssnTinType: "SSN" | "ITIN";
        /**
         * SSN TIN号
         */
        ssnTin: string;
        /**
         * 遵循规则：https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
         */
        country: string;
        /***
         * 省/州，kyc识别出的证件地区 {@link US: ISO 3166-2:US - Wikipedia | https://en.wikipedia.org/wiki/ISO_3166-2:US}
         */
        province: string;
        city: string;
        /**
         * 邮编
         */
        postalCode: string;
        address: string;
        /**
         * 生日，yyyy-MM-dd
         */
        dob?: string;
        /**
         * 证件列表
         */
        docInfo?: {
            /**
             * 证件类型：
             * - ID_CARD：身份证
             * - PASSPORT：护照
             * - DRIVING_LISENCE：驾照
             */
            docType?: string;
            /**
             * 证件号码
             */
            docNumber?: string;
        }[];
    };
    /**
     * 用户信用等级
     */
    userLevel?: number;
    createTime?: number;
    updateTime?: number;
}
export interface KycV3OriginData {
    // 是否使用新的判别规则
    isNewRule: boolean;
    // 是否是开放的地区，包括语言
    isOpenRegion: boolean;
    // 是否判定为新用户
    isNewUser: boolean;
    // 当前用户的KYC等级
    kycLevel: 0 | 1 | 2;
    // 用户的原始KYC数据
    kycInfo: KycV2OriginData;
}
// 默认数据
const getDefaultKycInfo = (): KycV2Info => ({ currentLevel: null, forwardLevelMap: new Map(), kycDestination: null });

// 集中管理引导进行新版KYC的路径，避免多个站点下不一样导致调整太多
export const kycV2GuideRoute = PLATFORM.PIONEX_US_LIKE ? "/account/kyc/level2" : "/account/kyc/level1";
/**
 * 根据kyc状态跳转返回kyc界面路由
 * @param kycDestination
 */
export function getKycEntryRouter(kycDestination: KycV2Info["kycDestination"], from: string): undefined | LocationDescriptor {
    return kycDestination ? (kycDestination === "v1" ? "/verification" : { pathname: kycV2GuideRoute, state: { from } }) : undefined;
}

// Context of Kyc info
const CTXKycInfo = createContext({
    data: getDefaultKycInfo(),
    update() {
        return Promise.resolve();
    },
    loading: true,
    _throttledUpdater() {},
    initialized: false,
    error: undefined as any,
});

export const KycInfoProvider: FC = ({ children }) => {
    const { locale } = useIntl();
    const accountInfo = useAccountInfo();
    const [data, setData] = useState<KycV2Info>(() => getDefaultKycInfo());
    const [loading, setRefreshing] = useState(false);
    const [error, setError] = useState();
    // 判别是否完成了KYC的初始化数据请求，即便是失败了也算
    const [initialized, setInitialized] = useState(false);
    const update = useCallbackStatic(async () => {
        if (loading || !accountInfo.userId || !accountInfo.createTime) return;
        setRefreshing(true);
        // 若为老用户，且老用会未进行KYC时，则使用特殊标记 oldLv_1。
        // 正常情况使用v2版本初始等级lv0
        let currentLevel: KycV2Info["currentLevel"] = null;
        const forwardLevelMap: KycV2Info["forwardLevelMap"] = new Map();
        const location: KycV2Info["location"] = {};
        let kycDestination: KycV2Info["kycDestination"] = null;
        try {
            const {
                /**
                 * 判别是否为老用户
                 * 这里将时间后移了。因此，台湾时间节点【"2021-12-01T14:00:00+00:00"】之后注册的繁中或KYC台湾地区的新用户将受到如下影响
                 * - 未做KYC的，从 lv0 变为 lv_1
                 * - 做了一级KYC的，将从 lv1 变为 lv_1
                 */
                // isNewUser,
                /**
                 * 服务端判别的
                 */
                isOpenRegion,
                /**
                 * 是否使用新KYC的等级限制规则
                 */
                isNewRule,
                /**
                 * 当前kyc等级
                 */
                kycLevel,
                kycInfo,
                // TODO: 可能需要将site信息传递给接口，让接口输出对应的 flowName 用户sumsub 的Token获取。
            } = (await ObservableUtils.getV2(`${Constants.accountServiceHost}/user/get_kyc_info_v3`, { lang: locale }, {}, true).toPromise()) as KycV3OriginData;
            if (isOpenRegion) {
                kycDestination = "v2";
            } else {
                kycDestination = "v1";
            }
            // 当前用户使用的平台
            let kycExchange: KycV2Info["exchange"] = "pionex";
            // 如果有kyc数据
            if (kycInfo) {
                const { levelInfo, region, province, exchange } = kycInfo;
                if (exchange) kycExchange = exchange;
                location.region = region;
                location.province = province;
                // 排除受限制的国家
                // 通过新老用户来判别初始化用户等级
                currentLevel = kycLevel || KycV2Level.lv0;
                // 受支持的 region 或 语言
                // 遍历kyc等级列表
                levelInfo?.forEach((lv) => {
                    if (!lv) return;
                    const { level, result: status, failReason: reasons, failReasonDetails: reasonTexts } = lv;
                    if (level && status) {
                        /**
                         * 保存该数据，用于显示审核原因和状态
                         */
                        forwardLevelMap.set(level, { status, reasons, reasonTexts, level });
                        if (level === KycV2Level.lv2) {
                            // 老KYC的状态也使用新的kKYC二级状态
                            forwardLevelMap.set(KycV2Level.oldLv2, { status, reasons, reasonTexts, level: KycV2Level.oldLv2 });
                        }
                        // 更新更高的等级
                        if (status === "PASSED" && (currentLevel as number) < level) {
                            currentLevel = level;
                        }
                    }
                });
                // 强制使用老规则
                if (!isNewRule) {
                    // 即便是做了KYC，但未开放新规则时，强制映射为旧的KYC等级限制规则。
                    currentLevel = (currentLevel as KycV2Level) === KycV2Level.lv2 ? KycV2Level.oldLv2 : KycV2Level.oldLv1;
                }
            } else {
                if (isNewRule) {
                    // 新用户则执行新的权限规则
                    currentLevel = KycV2Level.lv0;
                } else {
                    // 老用户，则执行旧的KYC权限规则
                    currentLevel = KycV2Level.oldLv1;
                }
            }
            // 正常更新
            setData({ currentLevel, forwardLevelMap, originData: kycInfo, location, kycDestination, exchange: kycExchange });
        } catch (e) {
            console.error(e);
            setError(e);
        }
        setInitialized(true);
        setRefreshing(false);
    });
    const _throttledUpdater = useCallback(
        throttle(
            () => {
                update();
                // 截流方法间隔1分钟才能更新一次
            },
            1 * 60e3,
            {
                trailing: false,
            },
        ),
        [update],
    );
    useEffect(() => {
        let prevPathname = "";
        // 当真实页面切换时，重新请求KYC数据，但是不会频繁地请求
        const closer = history.listen(({ pathname }) => {
            if (prevPathname !== pathname) {
                _throttledUpdater();
                prevPathname = pathname;
            }
        });
        return () => {
            closer();
            _throttledUpdater.cancel();
        };
    }, [_throttledUpdater, update]);
    useEffect(() => {
        // 理论上，初始状态，只会出发一次请求
        accountInfo.userId && accountInfo.createTime && update();
        // 当退出登录时，需要取消初始化状态
        if (!accountInfo.userId) setInitialized(false);
        // 语言变化时，需要触发更新，但这里不需要依赖 local字段，因为语言变化，会在最上层组件触发全局组件重新装载，届时将导致两次相同的数据请求出现
    }, [accountInfo.userId, accountInfo.createTime, update]);
    return <CTXKycInfo.Provider value={{ data, update, _throttledUpdater, loading, initialized, error }}>{children}</CTXKycInfo.Provider>;
};
export default function useKycV2Info() {
    const { data, loading, update, initialized, error } = useContext(CTXKycInfo);
    return [
        data,
        loading,
        {
            update,
            initialized,
        },
        error,
    ] as const;
}

export interface KycLevelQuotaRule {
    // kyc等级
    kyc_level: number;
    // 限额，-1为不限额
    quota: string;
    // 限额周期
    // day：天
    // year：年
    period: string;
    // 币种
    currency: "USD";
}

/**
 * 资金方向
 */
export enum FundDirection {
    OUT = "out", //提币（默认值）
    WITHDRAW = "withdraw", //出金
}

/**
 * 获取用户提币限额数据
 * @param direction  资金方向out 提币（默认值）withdraw 出金
 * @returns
 */
export function useKycV2QuotaRules(direction?: FundDirection) {
    const accountInfo = useAccountInfo();
    const [ruleMap, setRuleMap] = useState<Map<KycLevelQuotaRule["kyc_level"], KycLevelQuotaRule>>(() => new Map());
    const [loading, setRefreshing] = useState(false);
    const refresh = useCallbackStatic(async () => {
        if (!accountInfo.userId || loading) return;
        setRefreshing(true);
        try {
            const data = await ObservableUtils.getV2<KycLevelQuotaRule[]>(`${Constants.walletApiHost}/quota_rules/`, { site: platformForAPI(), direction }, null, true).toPromise();
            const lvMap = new Map();
            data.forEach((r) => lvMap.set(Number(r.kyc_level), r));
            setRuleMap(lvMap);
        } catch (e) {
            message.error(e.message || "Error getting quota rules,please retry it later!");
            console.error(e);
        }
        setRefreshing(false);
    });
    useEffect(() => {
        refresh();
    }, [refresh]);
    return [ruleMap, loading, { refresh }] as const;
}

export interface UsedQuota {
    quota?: string;
    currency?: string;
}
/**
 * 获取用户24小时内已使用提币额度
 * @param params 额外参数(direction:提币方向，rule:计算规则。提币无效，TPlusN：入金T+N限制 RollingQuota：滚动限额)
 * @returns
 */
export function useKycV2UsedQuota(params?: { direction?: FundDirection; rule?: "TPlusN" | "RollingQuota" }) {
    const accountInfo = useAccountInfo();
    const [usedQuota, setQuota] = useState<UsedQuota>({});
    const [loading, setRefreshing] = useState(false);
    const refresh = useCallbackStatic(async () => {
        if (!accountInfo.userId || loading) return;
        setRefreshing(true);
        try {
            const data = await ObservableUtils.getV2<UsedQuota>(`${Constants.walletApiHost}/used_quota/`, { site: platformForAPI(), ...params }, null, true).toPromise();
            setQuota(data);
        } catch (e) {
            message.error(e.message || "Error getting used quota,please retry it later!");
            console.error(e);
        }
        setRefreshing(false);
    });
    useEffect(() => {
        refresh();
    }, [refresh]);
    return [usedQuota, loading, { refresh }] as const;
}
export function useShowKycReasonModal() {
    const extraProcessReasons = useExtraProcessReasons();

    return useCallback(
        (params: ShowKycReasonParams) => {
            if (extraProcessReasons(params.forwardLevelInfo?.reasons)) {
                // 额外的处理
                return;
            }
            showKycReasonModal(params);
        },
        [extraProcessReasons],
    );
}

type ShowKycReasonParams = {
    $st;
    locale: string;
    forwardLevelInfo: ReturnType<KycV2Info["forwardLevelMap"]["get"]>;
    kycDestination?: ReturnType<typeof useKycV2Info>[0]["kycDestination"];
    onOk?();
    okText?: ReactNode;
};

/**
 * 展示普通code的纯文本解析
 * @param locale
 * @param $st
 * @param forwardLevelInfo
 * @param kycDestination
 * @param onOk
 * @param okText
 */
const showKycReasonModal = ({ locale, $st, forwardLevelInfo, kycDestination, onOk, okText }: ShowKycReasonParams) => {
    const verifyErrorCodes = forwardLevelInfo?.reasons;
    const verifyErrorTexts = forwardLevelInfo?.reasonTexts;
    const verifyFailed = forwardLevelInfo?.status === KycV2LevelStatus.failed;
    // KYC原生描述文案
    const reasonText = verifyErrorTexts?.map((t) => t.split(/\n/).map((e) => <p>{e}</p>));
    const reasonTextList: any[] = [];
    const _reasonCodeTextMap = new Map<string, any>();
    verifyErrorCodes?.map((code) => {
        // 英文文案
        const enTxt = transferFailReasonText($st, code);
        if (enTxt) {
            _reasonCodeTextMap.has(enTxt) || _reasonCodeTextMap.set(enTxt, <p>{enTxt}</p>);
        }
    });
    /**
     * 优先，使用自定义code解析的错误描述文案（且需要去重）
     * 其次，使用KYC审核返回的描述文案
     * 最后，以上都为空的情况下，使用code作为展示
     */
    if (_reasonCodeTextMap.size > 0) reasonTextList.push(..._reasonCodeTextMap.values());
    else if (reasonText?.length) reasonTextList.push(...reasonText);
    else if (verifyErrorCodes?.length) reasonTextList.push(...verifyErrorCodes.map((code) => <p>{code}</p>));
    else {
        reasonTextList.push(<p>{transferFailReasonText($st, "FRAUDSTER")}</p>);
    }

    TModal.error({
        title: verifyFailed ? $st("kyc_failed") : $st("common_reason"),
        cancelButtonProps: { style: { display: "none" } },
        maskClosable: true,
        onOk,
        okText,
        content: (
            /**
             * 旧KYC原因显示 reasonTexts中的内容
             *
             * 新KYC审核错误显示规则：
             * - 若kyc失败，且不能继续： 则显示独立文案，不显示sumsub的文案；
             * - 若kyc错误，可以继续：
             *   - 1. 若是繁中语言下： 仅显示所有code的繁中翻译映射，每个都需要换行；
             *   - 2. 若不是繁中：则显示sumsub返回的原因描述。
             */
            <div>{kycDestination === "v1" ? reasonText : verifyFailed ? $st("kyc_lv_failed") : reasonTextList}</div>
        ),
    });
};

/**
 * kyc地区是否是大陆地区
 * @param region
 */
export function isCNRegion(region: string) {
    return region === "CHN" || region === "CN";
}

/**
 * Reason codes from {@link sumsub | https://developers.sumsub.com/api-reference/#getting-verification-results}
 */
export type KycV2Lv2FailReasons =
    // reviewRejectType: [FINAL]
    // Description: Forgery attempt has been made
    | "FORGERY"

    // reviewRejectType: [FINAL]
    // Description: Documents supplied are templates, downloaded from internet
    | "DOCUMENT_TEMPLATE"

    // reviewRejectType: [RETRY]
    // Description: Documents have low-quality that does not allow definitive conclusions to be made
    | "LOW_QUALITY"

    // reviewRejectType: [FINAL]
    // Description: An applicant has been created by mistake or is just a spam user (irrelevant images were supplied)
    | "SPAM"

    // reviewRejectType: [RETRY]
    // Description: Documents supplied are not relevant for the verification procedure
    | "NOT_DOCUMENT"

    // reviewRejectType: [FINAL]
    // Description: A user photo (profile image) does not match a photo on the provided documents
    | "SELFIE_MISMATCH"

    // reviewRejectType: [RETRY]
    // Description: A document that identifies a person (like a passport or an ID card) is not valid
    | "ID_INVALID"

    // reviewRejectType: [FINAL]
    // Description: When a client does not accept applicants from a different country or e.g. without a residence permit
    | "FOREIGNER"

    // reviewRejectType: [FINAL]
    // Description: This applicant was already created for this client, and duplicates are not allowed by the regulations
    | "DUPLICATE"

    // reviewRejectType: [RETRY]
    // Description: When avatar does not meet the client's requirements
    | "BAD_AVATAR"

    // reviewRejectType: [FINAL]
    // Description: When applicants from certain regions/countries are not allowed to be registered
    | "WRONG_USER_REGION"

    // reviewRejectType: [RETRY]
    // Description: Some information is missing from the document, or it's partially visible
    | "INCOMPLETE_DOCUMENT"

    // reviewRejectType: [FINAL]
    // Description: User is blacklisted
    | "BLACKLIST"

    // reviewRejectType: [RETRY]
    // Description: There were problems with the photos, like poor quality or masked information
    | "UNSATISFACTORY_PHOTOS"

    // reviewRejectType: [RETRY]
    // Description: Some pages of a document are missing (if applicable)
    | "DOCUMENT_PAGE_MISSING"

    // reviewRejectType: [RETRY]
    // Description: Document is damaged
    | "DOCUMENT_DAMAGED"

    // reviewRejectType: [FINAL]
    // Description: Regulations violations
    | "REGULATIONS_VIOLATIONS"

    // reviewRejectType: [FINAL]
    // Description: Data or documents of different persons were uploaded to one applicant
    | "INCONSISTENT_PROFILE"

    // reviewRejectType: [RETRY]
    // Description: Applicant data does not match the data in the documents
    | "PROBLEMATIC_APPLICANT_DATA"

    // reviewRejectType: [RETRY]
    // Description: Additional documents required to pass the check
    | "ADDITIONAL_DOCUMENT_REQUIRED"

    // reviewRejectType: [FINAL]
    // Description: Age requirement is not met (e.g. cannot rent a car to a person below 25yo)
    | "AGE_REQUIREMENT_MISMATCH"

    // reviewRejectType: [FINAL]
    // Description: Not enough experience (e.g. driving experience is not enough)
    | "EXPERIENCE_REQUIREMENT_MISMATCH"

    // reviewRejectType: [FINAL]
    // Description: The user is involved in illegal actions
    | "CRIMINAL"

    // reviewRejectType: [RETRY]
    // Description: The address from the documents doesn't match the address that the user entered
    | "WRONG_ADDRESS"

    // reviewRejectType: [RETRY]
    // Description: The document has been edited by a graphical editor
    | "GRAPHIC_EDITOR"

    // reviewRejectType: [RETRY]
    // Description: The user has been deprived of the document
    | "DOCUMENT_DEPRIVED"

    // reviewRejectType: [FINAL]
    // Description: The user does not correspond to Compromised Person Politics
    | "COMPROMISED_PERSONS"

    // reviewRejectType: [FINAL]
    // Description: The user belongs to the PEP category
    | "PEP"

    // reviewRejectType: [FINAL]
    // Description: The user was found in the adverse media
    | "ADVERSE_MEDIA"

    // reviewRejectType: [FINAL]
    // Description: Fraudulent behavior was detected
    | "FRAUDULENT_PATTERNS"

    // reviewRejectType: [FINAL]
    // Description: The user was found on sanction lists
    | "SANCTIONS"

    // reviewRejectType: [RETRY]
    // Description: All checks were not completed
    | "NOT_ALL_CHECKS_COMPLETED"

    // reviewRejectType: [RETRY]
    // Description: Front side of the document is missing
    | "FRONT_SIDE_MISSING"

    // reviewRejectType: [RETRY]
    // Description: Back side of the document is missing
    | "BACK_SIDE_MISSING"

    // reviewRejectType: [RETRY]
    // Description: The user uploaded screenshots
    | "SCREENSHOTS"

    // reviewRejectType: [RETRY]
    // Description: The user uploaded black and white photos of documents
    | "BLACK_AND_WHITE"

    // reviewRejectType: [RETRY]
    // Description: The user should upload translation of his document
    | "INCOMPATIBLE_LANGUAGE"

    // reviewRejectType: [RETRY]
    // Description: The user uploaded expired document
    | "EXPIRATION_DATE"

    // reviewRejectType: [RETRY]
    // Description: The user uploaded the document without signatures and stamps
    | "UNFILLED_ID"

    // reviewRejectType: [RETRY]
    // Description: The user uploaded a bad selfie
    | "BAD_SELFIE"

    // reviewRejectType: [RETRY]
    // Description: The user uploaded a bad video selfie
    | "BAD_VIDEO_SELFIE"

    // reviewRejectType: [RETRY]
    // Description: Face check between document and selfie failed
    | "BAD_FACE_MATCHING"

    // reviewRejectType: [RETRY]
    // Description: The user uploaded a bad ID document
    | "BAD_PROOF_OF_IDENTITY"

    // reviewRejectType: [RETRY]
    // Description: The user uploaded a bad proof of address
    | "BAD_PROOF_OF_ADDRESS"

    // reviewRejectType: [RETRY]
    // Description: The user uploaded a bad proof of payment
    | "BAD_PROOF_OF_PAYMENT"

    // reviewRejectType: [RETRY]
    // Description: The user should upload a special selfie (e.g. selfie with paper and date on it)
    | "SELFIE_WITH_PAPER"

    // reviewRejectType: [FINAL]
    // Description: There was an attempt to bypass liveness check
    | "FRAUDULENT_LIVENESS"

    // reviewRejectType: [RETRY]
    // Description: Some unclassified reason
    | "OTHER"

    // reviewRejectType: [RETRY]
    // Description: Provided info doesn't match with recognized from document data
    | "REQUESTED_DATA_MISMATCH"

    // reviewRejectType: [RETRY]
    // Description: Custom reject label
    | "OK"

    // reviewRejectType: [RETRY]
    // Description: Could not establish the entity's control structure
    | "COMPANY_NOT_DEFINED_STRUCTURE"

    // reviewRejectType: [RETRY]
    // Description: Could not identify and duly verify the entity's beneficial owners
    | "COMPANY_NOT_DEFINED_BENEFICIARIES"

    // reviewRejectType: [RETRY]
    // Description: Beneficiaries are not validated
    | "COMPANY_NOT_VALIDATED_BENEFICIARIES"

    // reviewRejectType: [RETRY]
    // Description: Representatives are not defined
    | "COMPANY_NOT_DEFINED_REPRESENTATIVES"

    // reviewRejectType: [RETRY]
    // Description: Representatives are not validated
    | "COMPANY_NOT_VALIDATED_REPRESENTATIVES"
    | "EMPTYSSN"

    // 派网合规
    | "BLOCKLIST";

/**
 * 获取错误原因翻译
 * @param $st
 * @param code
 */
export function transferFailReasonText($st: ST, code: string) {
    // 相同文案的code映射
    const codeMap = {
        FRAUDULENT_PATTERNS: "BLACKLIST",
        FORGERY: "BLACKLIST",
        COMPROMISED_PERSONS: "BLACKLIST",
        SELFIE_MISMATCH: "BLACKLIST",
    };

    return $st(
        { id: `kyc_fail_reason:${codeMap[code] || code}`, defaultMessage: $st("kyc_fail_reason:DEFAULT") },
        {
            licence: (c) => (
                <a className="underline text-primary hover:text-primary" href={"https://www.pionex.us/blog/license-information/"} target={"_blank"}>
                    {c}
                </a>
            ),
        },
    );
}
