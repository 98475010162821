import { useRxRequest } from "commonUse/useRequest";
import { ObservableUtils } from "trade_utils_lib";
import Constants from "utils/Constants";
import { platformForAPI } from "utils/platform";
import { DWChannelStatusInfo } from "landings/V2/USDDW/types";
import { QuotaChannel } from "utils/complianceQuota";
import { useContext } from "react";
import { USDDWContext } from "landings/V2/USDDW/context";

/**
 * 请求所有通道的状态
 */
export function useRequestAllChannelStatus() {
    return useRxRequest(() => ObservableUtils.getV2<DWChannelStatusInfo>(`${Constants.walletApiHost}/channel_status/`, { site: platformForAPI() }, null, true));
}

/**
 * 从context中获取对应通道的状态
 * @param channel
 */
export function useChannelStatus(channel: QuotaChannel) {
    const { channelStatusInfo } = useContext(USDDWContext);

    return channelStatusInfo?.[channel];
}
