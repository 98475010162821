import Constants from "utils/Constants";
import { acquireKeyResolver } from "trade_token_lib";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { AuthType, Network } from "trade_utils_lib";

/**
 * @description: 生成32位UUID
 * @return {*}
 */
export function generateUUID() {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    return S4() + S4() + "" + S4() + "" + S4() + "" + S4() + "" + S4() + S4() + S4();
}

export function createAmlTask(data: Record<string, any>): Observable<{ id: string; status: string }> {
    const url = `${Constants.walletApiHost}/aml/create_task/`;
    return Network.post({
        url,
        authType: AuthType.Access,
        data,
    });
}

export function getTaskStatus(data: Record<string, any>): Observable<{ id: string; status: string; result?: string; rejectTags?: string[] }> {
    const url = `${Constants.walletApiHost}/aml/get_task_status/`;
    return Network.get({
        url,
        authType: AuthType.Access,
        data,
    });
}

export function getPublicKey(): Observable<{ public_key: string }> {
    const url = `${Constants.walletApiHost}/stripe/ach/public_key/`;
    return acquireKeyResolver()
        .requestSigner.getSignRequestV2("GET", url)
        .pipe(map((res) => res.data));
}

export interface PaymentInfo {
    order_id: string;
    channel_order_id: string;
    channel_client_secret: string;
}

export function createPaymentIntent(data: Record<string, any>): Observable<PaymentInfo> {
    const url = `${Constants.walletApiHost}/stripe/ach/create_payment_intent/`;
    return acquireKeyResolver()
        .requestSigner.getSignRequestV2("POST", url, JSON.stringify(data))
        .pipe(map((res) => res.data));
}

export interface BuyCryptoInfo {
    order_id: string;
    base_ret: {
        code: 0;
        reason: string;
        msg: string;
    };
}

export function fiatBuyCrypto(data: Record<string, any>): Observable<BuyCryptoInfo> {
    const url = `${Constants.balanceApiHostV2}/fiat/buycrypto`;
    return Network.post({
        url,
        authType: AuthType.FullToken,
        data,
    });
}

export function getOrderInfo(data: Record<string, any>): Observable<{ pay_status: string; err_desc: string }> {
    const url = `${Constants.walletApiHost}/stripe/ach/get_order_info/`;
    return Network.get({
        url,
        data,
        authType: AuthType.Spot,
    });
}

export function getOrderList(data: Record<string, any>): Observable<any> {
    const url = `${Constants.walletApiHost}/stripe/ach/get_order_list/`;
    return Network.get({
        url,
        data,
        authType: AuthType.Spot,
    });
}

export function cancelPayment(data: Record<string, any>): Observable<{ id: string; status: string }> {
    const url = `${Constants.walletApiHost}/stripe/ach/cancel_payment/`;
    return Network.post({
        url,
        authType: AuthType.Spot,
        data,
    });
}
