import { requestWithRetry } from "trade_utils_lib";
import { fromPromise } from "rxjs/internal-compatibility";
import Axios from "axios";
import { TSwapConfig } from "landings/Trade/Swap/tool";
import Constants from "utils/Constants";
import AppState from "state/AppState";
import { useSelector } from "react-redux";

export const TYPE_REFRESH_SWAP_CONFIG = "type_refresh_swap_config";

export class SwapConfig {
    static refresh() {
        const url = `${Constants.configHost}/statics/common/trade/swap_config_v3.json`;
        requestWithRetry(
            () =>
                fromPromise(
                    Axios.get<TSwapConfig>(url, {
                        headers: { "Cache-Control": "no-cache" },
                    }).then((resp) => resp.data),
                ),
            { maxRetryCount: 5 },
        ).subscribe((res) => {
            AppState.store.dispatch({ type: TYPE_REFRESH_SWAP_CONFIG, data: res });
        });
    }

    static init() {
        this.refresh();
    }

    static reducer = (state = null, action) => {
        switch (action.type) {
            case TYPE_REFRESH_SWAP_CONFIG:
                return action.data;
        }
        return state;
    };
}

export function useSwapConfig() {
    return useSelector<AppState, TSwapConfig | null>((appState) => appState.swapConfig);
}
