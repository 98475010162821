import React, { useCallback, useEffect, useRef, useState } from "react";
import csn from "classnames";
import { SideTabKey } from "./types";
import css from "./index.m.less";
import {
    IconBot20pxRegular,
    IconDarkMode20pxRegular,
    IconLayout20pxRegular,
    IconLightMode20pxRegular,
    IconManual20pxRegular,
    IconStaff20pxRegular,
    IconSwap20pxRegular,
} from "@pionex-web-kit/icons";
// import { Tooltip } from "@pionex-web-kit/components";
import { Tooltip } from "antd";
import TradeLayoutConfig from "../components/TradeLayoutConfig/index";
import classNames from "classnames";
import { ST, useTranslators } from "commonUse/Locale";
import useTheme from "commonUse/useTheme";
import { useHelpCenterFn } from "commonUse";
import { setTheme, ThemeName } from "state/ThemeData";
export { SideTabKey };

export const getMenus = ($st: ST) => {
    return [
        { icon: <IconBot20pxRegular className={css.tabIcon} />, label: $st("trade_tab_bot"), value: SideTabKey.BOT },
        { icon: <IconManual20pxRegular className={css.tabIcon} />, label: $st("trading_page_tab_manual"), value: SideTabKey.MANUAL },
        { icon: <IconSwap20pxRegular className={css.tabIcon} />, label: $st("trade_swap"), value: SideTabKey.SWAP },
    ];
};

interface Props {
    activeKey?: SideTabKey;
    onTabChange?: (tab: SideTabKey) => void;
    reverse?: boolean;
}

/**
 * 交易页面的侧边Tab组件
 */
const SideTab: React.FC<Props> = ({ onTabChange, activeKey, reverse }) => {
    const { $st } = useTranslators();
    const { name: themeName } = useTheme();
    const [openHelpCenter, modalContext] = useHelpCenterFn();
    return (
        <div
            className={classNames(
                "flex flex-col items-center w-[60px] h-full border-0 border-solid border-divider dark:border-black bg-list-row ",
                reverse ? "border-l-[1px]" : "border-r-[1px]",
            )}
        >
            {modalContext}
            <div className={"flex-1"}>
                <div id="botTrade" className={css.width}>
                    {getMenus($st).map(({ icon, label, value }) => (
                        <div
                            onClick={onTabChange?.bind(null, value)}
                            className={csn("flex flex-col items-center  text-secondary py-[16px] fill-secondary hover:cursor-pointer hover:fill-accent hover:text-accent", {
                                "!text-primary !fill-primary": activeKey === value,
                            })}
                            key={value}
                        >
                            {icon}
                            <span className={"text-sm"}>{label}</span>
                        </div>
                    ))}
                </div>
            </div>
            <Tooltip
                overlayClassName={classNames(css.tool_tip_layout, "tool_tip_layout_content", { [css.reverseLayout]: reverse })}
                align={{
                    offset: reverse ? [-126, 50] : [10, 50],
                }}
                arrowPointAtCenter
                placement={reverse ? "leftBottom" : "rightBottom"}
                title={TradeLayoutConfig}
                trigger="click"
            >
                <div id="tool_tip_layout_icon" className="w-[55px] h-[55px] flex justify-center pt-[18px] translate-y-[-18px]">
                    <IconLayout20pxRegular className={"block text-center mb-[30px] hover:fill-accent hover:cursor-pointer fill-secondary "} />
                </div>
            </Tooltip>
            <Tooltip
                overlayClassName={css.tool_tip}
                mouseEnterDelay={1}
                mouseLeaveDelay={0}
                placement="right"
                title={
                    <div className={css.tag_box}>
                        <div>{$st(themeName === ThemeName.dark ? "side_bar_switch_to_theme_normal" : "side_bar_switch_to_theme_dark")}</div>
                    </div>
                }
            >
                {themeName === ThemeName.dark ? (
                    <IconLightMode20pxRegular
                        className={"block text-center mb-[30px] hover:fill-accent hover:cursor-pointer fill-secondary"}
                        onClick={() => {
                            setTheme(ThemeName.light);
                        }}
                    />
                ) : (
                    <IconDarkMode20pxRegular
                        className={"block text-center mb-[30px] hover:fill-accent hover:cursor-pointer fill-secondary"}
                        onClick={() => {
                            setTheme(ThemeName.dark);
                        }}
                    />
                )}
            </Tooltip>
            <Tooltip
                overlayClassName={css.tool_tip}
                mouseEnterDelay={1}
                mouseLeaveDelay={0}
                placement="right"
                title={
                    <div className={css.tag_box}>
                        <div>{$st("manual_service_side_bar_desc")}</div>
                    </div>
                }
            >
                <IconStaff20pxRegular className={"block text-center mb-[30px] hover:fill-accent hover:cursor-pointer fill-secondary"} onClick={openHelpCenter} />
            </Tooltip>
        </div>
    );
};

export default SideTab;
