const PionexClassName = {
    flexRow: "pionex-flex-row",
    flexColumn: "pionex-flex-column",
    cursorPointer: "pionex-cursor-pointer",

    tradingUp: "pionex-trading-up",
    tradingDown: "pionex-trading-down",

    tradingUpBg: "pionex-trading-up-bg",
    tradingDownBg: "pionex-trading-down-bg",

    colorTextAccent: "pionex-color-text-accent",
    colorText: "pionex-color-text",
    textAccent: "pionex-text-accent",
    text: "pionex-text",
    seesaw: "pionex-seesaw",
    bottomlength: "pionex-bottomlength",
    cardpaddingherader: "pionex-cardpaddingherader",
    cardcontent: "pionex-cardcontent",
    divider: "pionex-divider",
    numberFont: "number-font-family",
};

export default PionexClassName;
