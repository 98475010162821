import Constants from "utils/Constants";
import { Alpha2Code } from "i18n-iso-countries";
import { AbaInfo, abasInfo, CIRCEL_SUPPORT_WIRE_COUNTRY_CODE, CountryInfoV2, CountryListForLite, FORBIN_COUNTRY_CODE, SUPPORT_IBAN_COUNTRY_CODE } from "./constants";
import { extractBIC } from "ibantools";
import { BankType } from "compliance_lib";
import { KycV2LevelStatus } from "commonUse/useKycInfoV2";

const stateMap = require("../assets/stateCodes.json");

export const MAX_CARD_COUNT = 3;

/**
 * 根据circel 支持电汇的国家列表，返回可供选择国家代码
 */
export function getCircleSupportCountry(): CountryInfoV2[] {
    return CountryListForLite.filter((item) => {
        const a = CIRCEL_SUPPORT_WIRE_COUNTRY_CODE.find((item1) => {
            return item1 === item.code;
        });
        return a !== undefined;
    }).filter((item) => {
        return !FORBIN_COUNTRY_CODE.some((item1) => item.code === item1);
    });
}

/**
 * 获取国家对应的州
 * @param countryCode
 */
export function getCircleStates(countryCode: string): { code: string; name: string }[] {
    const states = stateMap[countryCode];
    return states || [];
}

/**
 * 是否需要选择州
 * @param countryCode
 */
export function isNeedState(countryCode: string) {
    return ["US", "CA"].includes(countryCode);
}

export const countryText = (item) => {
    if (Constants.isCN) {
        return item.cname;
    } else if (Constants.isTW) {
        return item.twName;
    } else {
        return item.name;
    }
};

export function isSupportIban(country: CountryInfoV2["code"]): boolean {
    return SUPPORT_IBAN_COUNTRY_CODE.some((item) => item === country);
}

export function getBankType(country: CountryInfoV2["code"]): BankType {
    if (country === "US") {
        return BankType.ABA;
    }
    return isSupportIban(country) ? BankType.IBAN : BankType.SWIFT;
}

export function getAbaInfo(routingNumber: string): AbaInfo | null {
    if (!routingNumber || routingNumber.length !== 9) {
        return null;
    }
    for (const item of abasInfo) {
        if (item.aba === routingNumber) {
            return item;
        }
    }
    return null;
}

export interface SwiftBankInfoTypeV1 {
    bank: string;
    branch: null | string;
    city: string;
    countryCode: Alpha2Code;
    id: number; // 无意义，可忽略
    swiftCode: string;
}

export async function loadBankInfoBySwift(swift: string): Promise<SwiftBankInfoTypeV1 | undefined> {
    const details = extractBIC(swift);
    if (!details || !details.countryCode || !details.valid) {
        return undefined;
    }
    const banks = await import(/* webpackChunkName: "[request]" */ `./AllCountries/${details.countryCode}.json`);
    if (!banks) {
        return undefined;
    }
    const bank = banks.list.find((b) => {
        if (b.swift_code === swift) {
            return true;
        }
        if (swift.endsWith("XXX")) {
            // 兼容带XXX的swift_code，FCBKTWTPXXX等同于FCBKTWTP
            return swift.replace("XXX", "") === b.swift_code;
        }
        return false;
    });
    if (!bank) {
        return undefined;
    }
    return { ...bank, countryCode: details.countryCode };
}

/**
 * kyc状态是否允许添加银行卡
 */
export function isAllowAddCard(kycStatus?: KycV2LevelStatus) {
    return kycStatus === KycV2LevelStatus.passed || kycStatus === KycV2LevelStatus.reviewing;
}
