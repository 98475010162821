import React, { memo, useEffect, useState } from "react";
import { Chart, Geom, Tooltip, Axis } from "bizcharts";
import { SafeDecimal } from "trade_utils_lib";
import useDateFormatter from "src/commonUse/useDateFormatter";
import { $st } from "src/utils/i18n";
import { BalanceHistoryOriginInfo } from "trade_asset_lib";
import useTheme from "src/commonUse/useTheme";
import useExchangeRate from "src/commonUse/useExchangeRate";

function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    if (!hex) return;
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
}
interface IOverviewChart {
    balanceHistoryData: BalanceHistoryOriginInfo["items"];
    periodPnlColor: string;
}
const OverviewChart = ({ balanceHistoryData, periodPnlColor }: IOverviewChart) => {
    const theme = useTheme();
    const dateFormater = useDateFormatter();
    const exchangeRate = useExchangeRate();
    const [, setFlag] = useState(false);
    const { chartData, scale, chartColor } = React.useMemo(() => {
        const chartData =
            balanceHistoryData.map((item) => {
                return {
                    type: "type",
                    value: new SafeDecimal(item.total_in_usd).mul(exchangeRate.exchangeRate).toFixed(2),
                    date: item.timestamp,
                    displayDate: dateFormater.month_minute(item.timestamp * 1000),
                };
            }) || [];
        const minValue = Math.floor(+chartData.reduce((a, b) => (a < +b.value ? a : b.value), +chartData[0].value));
        const maxValue = Math.ceil(+chartData.reduce((a, b) => (a > +b.value ? a : b.value), +chartData[0].value));

        function parseNumber(num: number, type: "ceil" | "floor") {
            const length = `${num}`.length;
            if (num < 10) {
                return 0;
            }
            return Math[type](num / Math.pow(10, length - 1)) * Math.pow(10, length - 1);
        }

        const scale = {
            date: {
                type: "linear",
                minLimit: balanceHistoryData.length ? balanceHistoryData[0].timestamp : 0,
                maxLimit: balanceHistoryData.length ? balanceHistoryData[balanceHistoryData.length - 1].timestamp : 0,
            },
            value: {
                minLimit: parseNumber(minValue, "floor"),
                alias: `${$st("balance_overview_chart_sum")}(${exchangeRate.currency})`,
                type: "linear",
                tickCount: 5,
                tickInterval: (parseNumber(maxValue, "ceil") - parseNumber(minValue, "floor")) / 5,
            },
            displayDate: {
                alias: $st("balance_overview_chart_time"),
            },
        };
        const profitColorRgb = hexToRgb(periodPnlColor);
        const chartColor = profitColorRgb
            ? `l (90) 0:rgba(${profitColorRgb.r}, ${profitColorRgb.g}, ${profitColorRgb.b}, 0.3) 1:rgba(${profitColorRgb.r}, ${profitColorRgb.g}, ${profitColorRgb.b}, 0)`
            : "";
        return { chartData, scale, chartColor };
    }, [balanceHistoryData, periodPnlColor, dateFormater, exchangeRate.currency, exchangeRate.exchangeRate]);
    // 解决涨跌颜色不生效的bug
    useEffect(() => {
        setFlag(true);
        const timer = setTimeout(() => {
            setFlag(false);
        });
        return () => clearTimeout(timer);
    }, [periodPnlColor]);
    return (
        <Chart padding={{ top: 10, right: 120, bottom: 5, left: 0 }} height={120} scale={scale} animate={false} forceFit data={chartData}>
            <Tooltip
                showTitle={false}
                g2-tooltip={{
                    borderRadius: "12px",
                    backgroundColor: theme.name === "normal" ? "#ffffff" : "#33373D",
                    color: theme.name === "normal" ? "#292C33" : "#F4F6FA",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
                }}
                g2-tooltip-marker={{ display: "none" }}
                crosshairs={{
                    style: {
                        stroke: theme.name === "normal" ? "#9FA7B8" : "#4C525D",
                    },
                }}
            />
            <Geom type="line" size={2} position="date*value*displayDate" color={periodPnlColor} tooltip={"displayDate*value"} />
            <Geom type="areaStack" shape="smooth" tooltip={false} position="date*value*displayDate" color={["type", [chartColor]]} />
            <Axis
                name="value"
                position="right"
                grid={{ type: "line", lineStyle: { strokeOpacity: 0 } }}
                label={{
                    offset: 90,
                    formatter: (text) => new SafeDecimal(text).toNumber().toLocaleString(),
                    textStyle: { fill: theme.name === "normal" ? "#9FA7B8" : "#4C525D", textAlign: "right" },
                }}
            />
            <Axis name="date" visible={false} />
        </Chart>
    );
};

export default memo(OverviewChart);
