import { Input } from "antd";
import css from "components/SearchInput/searchinput.module.less";
import _ from "lodash";
import React, { forwardRef, Ref, useCallback, useImperativeHandle, useState } from "react";

const Search = Input.Search;

export interface ISearchInputProps {
    placeholder: string;
    searchInput: string;
    style?: string;
    updateSearchInput: (text: string) => void;
}

export interface SearchInputAction {
    clear: () => void;
}

const SearchInputView = (props: ISearchInputProps, ref: Ref<SearchInputAction>) => {
    const _search = _.debounce(props.updateSearchInput);
    const onSearchChanged = useCallback((e) => {
        _search(e.target.value);
        changeValue(e.target.value);
    }, []);

    useImperativeHandle(
        ref,
        () => ({
            clear: () => {
                changeValue("");
            },
        }),
        [],
    );

    const [value, changeValue] = useState("");

    return (
        <div className={`${css.searchStyle} ${props.style}`}>
            <Search value={value} allowClear={true} className={css.search} placeholder={props.placeholder} onChange={onSearchChanged} />
        </div>
    );
};

export const SearchInput = forwardRef(SearchInputView);
