import { AccountType, BUUserAccount, OtpState, VerifyType } from "bu_account_js_sdk";
import useAccountInfo from "commonUse/useAccountInfo";
import { useDWCoins } from "commonUse/useDW";
import { AssetAddressItem, AssetAddressItemResp } from "landings/V2/Address/api";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NetworkUtils, requestWithRetry } from "trade_utils_lib";
import Constants from "utils/Constants";

export function useAddressBookItems({ onlyWhitelist, coin, needCoin }: { onlyWhitelist?: boolean; coin?: string; needCoin?: boolean }) {
    const accountInfo = useAccountInfo();
    const [refreshId, setRefreshId] = React.useState(0);
    const [coinList] = useDWCoins();

    const [addressBookItems, setAddressBookItems] = useState<AssetAddressItem[]>();

    const refreshAddressBookItems = useCallback(() => {
        setRefreshId(Date.now());
    }, []);

    useEffect(() => {
        if (!accountInfo.userId || accountInfo.otpState !== OtpState.BOUND) {
            return;
        }
        if (needCoin) {
            setAddressBookItems(undefined);
            if (!coin) {
                return;
            }
        }
        let url = `${Constants.walletApiHost}/get_address/`;
        if (onlyWhitelist) {
            url = `${Constants.walletApiHost}/get_whitelist_address/`;
        } else if (coin) {
            url = `${Constants.walletApiHost}/get_address_with_coin/`;
        }
        const subscription = requestWithRetry(() => {
            return NetworkUtils.getV2<AssetAddressItemResp>({
                url,
                data: coin ? { coin } : undefined,
                authorize: true,
            });
        }).subscribe((data) => {
            setAddressBookItems(data.address || []);
        });
        return () => subscription.unsubscribe();
    }, [accountInfo.otpState, accountInfo.userId, coin, needCoin, onlyWhitelist, refreshId]);

    return useMemo(() => {
        if (!coinList || coinList.length === 0 || !addressBookItems) {
            return { addressBookItems: accountInfo.otpState !== OtpState.BOUND ? [] : undefined, refreshAddressBookItems };
        }
        const items = addressBookItems.map((_item) => {
            // 转换chain字段，填充protocolDisplay字段
            const item = { ..._item };
            const coin = coinList.find((coinItem) => {
                if (coinItem.coinName !== item.coin) {
                    return false;
                }
                if (coinItem.protocol.toUpperCase() === item.chain?.toUpperCase()) {
                    return true;
                }
                if (coinItem.protocol === "BEP20" && item.chain === "BEP20(BSC)") {
                    return true;
                }
                return false;
            });
            if (coin) {
                item.chain = coin.protocol;
                item.protocolDisplay = coin.protocolDisplay;
            } else {
                item.chain = item.chain || "";
                item.protocolDisplay = item.chain || "";
            }
            return item;
        });

        return { addressBookItems: items, refreshAddressBookItems };
    }, [addressBookItems, coinList, refreshAddressBookItems]);
}

export const useVerifyTypes = () => {
    const accountInfo = useAccountInfo();

    const [mainAccount, otherAccount] = useMemo(() => {
        let main: BUUserAccount | undefined;
        let other: BUUserAccount | undefined;
        if (accountInfo.accounts) {
            if (accountInfo.accounts.length > 0) {
                main = accountInfo.accounts[0];
            }
            if (accountInfo.accounts.length > 1) {
                other = accountInfo.accounts[1];
            }
        }
        return [main, other];
    }, [accountInfo.accounts]);

    const [hasSMS, hasEmail] = useMemo(() => {
        const sms = mainAccount?.account_type === AccountType.phoneNumber || otherAccount?.account_type === AccountType.phoneNumber;
        const email = mainAccount?.account_type === AccountType.email || otherAccount?.account_type === AccountType.email;
        return [sms, email];
    }, [mainAccount, otherAccount]);

    const verifyTypes = useMemo(() => {
        const types: VerifyType[] = [VerifyType.verify_otp];
        if (hasSMS) {
            types.push(VerifyType.sms_code);
        }
        if (hasEmail) {
            types.push(VerifyType.email_code);
        }
        return types;
    }, [hasEmail, hasSMS]);

    return verifyTypes;
};
