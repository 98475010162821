import React from "react";

export interface ListNumberItemProps {
    value: string | number;
}

const ListBasicShowItem = (props: ListNumberItemProps) => {
    const { value } = props;

    return <div>{value}</div>;
};

export default React.memo(ListBasicShowItem);
