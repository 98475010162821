import ExchangeDataProvider from "@pionex-web-kit/common-business/es/trade/ExchangeDataProvider";
import { Observable, of } from "rxjs";
import { acquireKeyResolver } from "trade_token_lib";
import { getTradeDependencyInstance, PRODUCT_TYPE } from "trade_utils_lib";
import { ILocalStorage } from "trade_utils_lib/dist/src/Storage";
import AccountManager from "utils/AccountManager";
import Constants from "utils/Constants";
import { isPionex } from "utils/platform";
import { SymbolDataProvider } from "@pionex-web-kit/common-business";
import AppState from "state/AppState";

export default ExchangeDataProvider;

/**
 * 初始化lib中的ExchangeDataProvider
 */
export function initExchangeDataProvider() {
    ExchangeDataProvider.init({
        getAccessToken: (force?: boolean) => {
            return AccountManager.shared.getAccessToken(force || acquireKeyResolver().tradingTokenManager.consumeForceUpdateAccessToken());
        },
        getUserId() {
            return of(AppState.store.getState().accountInfo.userId);
        },

        getTradingToken: () => {
            return acquireKeyResolver().tradingTokenManager.requestTradingBotToken();
        },

        getPlatform: () => {
            return isPionex() ? PRODUCT_TYPE.PIONEX : PRODUCT_TYPE.BU;
        },
        getSymbolInfo(symbol, market) {
            return SymbolDataProvider.shared.getCachedSymbolData().symbolMap.get(symbol);
        },
        instanceLocalStorage: () => {
            return {
                getItem(key: string): Promise<string | null> {
                    return new Promise<string | null>((resolve) => {
                        resolve(window.localStorage.getItem(key));
                    });
                },
                removeItem(key: string): Promise<void> {
                    return new Promise<void>((resolve) => {
                        window.localStorage.removeItem(key);
                        resolve();
                    });
                },
                setItem(key: string, value: string): Promise<void> {
                    return new Promise<void>((resolve) => {
                        window.localStorage.setItem(key, value);
                        resolve();
                    });
                },
            } as ILocalStorage;
        },

        getApiKeyId: (apiKey: any) => {
            return of(apiKey.cloudKeyId);
        },

        getRSAPublicKey: () => {
            let rsaPublicKey =
                "-----BEGIN PUBLIC KEY-----\n" +
                "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwaycr10UIcv5d1v7VBgr\n" +
                "0VaX2fHAnODvdYOsTz2fkHYnRvuEZ5UqVskj3Ow5UXyOainWwnwUCgFzeTb9+PPS\n" +
                "zddQ/QJ7vN4AozYNjE+wneRmUHxkCjpvC5uCkaCmJGKgSKrZYd/8c6P38efv04IL\n" +
                "/KJkgYwA0pGzypMruJp8ihNoOiTA5vo3hwAgkSQEQjgqR4Q02qmD886y8DtnHS1N\n" +
                "FmX36+Gr7KNLneTFz/p2iFRzZhe6d3uh3RD6P9kXgqkNetRNxf+/1Kvip9V0Tmbi\n" +
                "89mrZDdhm1gqHidtuEKexhwipj0JNEdDdZ5lC4JUjzJkxvvoTBqAJy5MkoSBgWPT\n" +
                "hwIDAQAB\n" +
                "-----END PUBLIC KEY-----";
            if (Constants.isBeta) {
                rsaPublicKey =
                    "-----BEGIN PUBLIC KEY-----\n" +
                    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3aNGfoNiiPu1/k6LisRT\n" +
                    "HRejMPmodveMWbI3r25WH6DozjbWuXlU6cEZhskAikEQywAYwLLaZIbGy3XVdWZV\n" +
                    "El/2b9p6fLhw3Tc74waItcc7nmRC8+KJOb+LmUoXOIdfMq++hQQxtkemR3ssogFm\n" +
                    "RF9QgUTsCp3rI+gR255hRIm+9y1Q7UWnHdz2TANT6WJr/x9jOssIPGs07O8fWU4c\n" +
                    "vPn8I6nUBbJGxy/+2deBxiuB9QFbmV1NjvObD+k9W72yiJsRO00+wz/GCbfAfIz5\n" +
                    "cv4A7lXj9POWC68tCpCdn5HKH2UTX4U/2ZLPLVbKxKB0MjrrQjCS4zuWJLxqbWrJ\n" +
                    "4QIDAQAB\n" +
                    "-----END PUBLIC KEY-----";
            }
            return of(rsaPublicKey);
        },

        getRunTimeHost: () => {
            return {
                /**
                 * @deprecated 公共参数已经使用全局的 Axios 中间件进行注入。
                 */
                commonParam: Constants.commonRequestParams,
                tickerHost: Constants.tickerHost,
                TRADE_HOST: Constants.tradeServiceHost,
                TRADE_RPC_HOST: Constants.tradeRPCHost,
                TRADE_RPC_SIGN_HOST: Constants.tradeRPCSignHost,
                FUND_HOST: Constants.tradeFundHost,
                CONFIG_HOST: Constants.configHost,
                webSocketHost: Constants.webSocketHost,
                accountWebSocketHost: Constants.accountWebSocketHost,
                publicApiHost: Constants.publicApiHost,
                futureApiHost: Constants.futureApiHost,
                futureApiHostV2: Constants.futureApiHostV2,
                manageApiHost: Constants.manageApiHost,
                walletApiHost: Constants.walletApiHost,
                balanceApiHost: Constants.balanceApiHost,
                pionexSpotApiHost: Constants.pionexSpotApiHost,
                iconServerHost: Constants.iconServerHost,
                avatarHost: Constants.avatarHost,
                accountHost: Constants.accountServiceHost,
                rebateHost: `${Constants.rebateHost}/api`,
            };
        },

        getIsChina: () => {
            // return I18n.global_language.isZhHans;
            return Constants.isCN;
        },
    });
}
