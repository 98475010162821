import moment, { Moment } from "moment";
import { format } from "path";
import { $st } from "./i18n";

export function isValid(value?: any) {
    return value !== undefined && value !== null;
}
/**
 * @deprecated 请参考 {@link LayoutConfig} 组件 metaList 配置字段使用方式。
 */
export function changeMetaDescriptionEleContentText(text: string) {
    const metaList = document.getElementsByTagName("meta");
    for (let i = 0; i < metaList.length; i++) {
        if (metaList[i].getAttribute("name") === "description") {
            metaList[i].content = text;
        }
    }
}

/**
 * @deprecated 请参考 {@link LayoutConfig} 组件 title 配置字段使用方式。
 */
export function resetPageTitleAndDescription(str) {
    document.title = str("pionex_title");
    changeMetaDescriptionEleContentText(str("pionex_description"));
}

export enum FundErrorCode {
    enumBalanceInSufficient = 100,
    enumFundBalanceInSufficient = 101,
    enumFundCreateOrderFailed = 102,
}
export function parseError(error): string {
    if ("code" in error) {
        switch (error.code) {
            case -1:
                return $st("url_error_bad_request");
            case -2:
                return $st("url_error_unauthorized");
            case -3:
                return $st("url_error_order_not_found");
            case -4:
                return $st("url_error_invalid_param");
            case -5:
                return $st("url_error_account_in_transfer");
            case -6:
            case -12:
                return $st("url_error_insufficient");
            case -7:
                return $st("url_error_fund_not_found");
            case -8:
                return $st("url_error_invalid_price");
            case -9:
                return $st("url_error_invalid_amount");
            case -10:
                return $st("url_error_buy_time_expired");
            case -11:
                return $st("url_error_sell_time_expired");
            case -13:
                return $st("url_error_buy_time_started");
            case -14:
                return $st("url_error_transfer_failed");
            case -15:
                return $st("url_error_rpc_transfer_failed");
            case -16:
                return $st("url_error_transfer_stuck");
            case -22:
                return $st("url_error_invalid_invite_code");

            case FundErrorCode.enumBalanceInSufficient:
                return $st("fund_logic_error_balance_insufficient");
            case FundErrorCode.enumFundBalanceInSufficient:
                return $st("fund_logic_error_fund_balance_insufficient");
            case FundErrorCode.enumFundCreateOrderFailed:
                return $st("fund_logic_error_create_order");
            default:
                return error.message;
        }
    } else {
        return JSON.stringify(error);
    }
}

export function formatterUsDate(time: number, format?: string) {
    // unix 时间
    return moment.unix(time).format(format || "MM-DD-YYYY");
}

/**
 * 脱敏邮箱
 * @returns {string|*|string}
 */
export function desensitizedEmail(email) {
    let tempEmail = email;
    if (!tempEmail) {
        return "";
    }
    const atIndex = tempEmail.indexOf("@");
    tempEmail = tempEmail.substr(0, 3) + "****" + tempEmail.substr(atIndex);
    return tempEmail;
}

/**
 * 脱敏手机号
 * @returns {string|*|string}
 */
export function desensitizedPhone(phoneNumber) {
    let tempPhone = phoneNumber;
    if (!tempPhone) {
        return "";
    }
    tempPhone = tempPhone.substr(0, 6) + "****" + tempPhone.substr(-4);
    return tempPhone;
}

export const EMPTY_FUNC = () => {};

export function parseErrorMessage(error) {
    return `${error.code}:${error.message}`;
}

export function isMobile() {
    return /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|webOS|android/i.test(
        navigator.userAgent,
    );
}

export const isDeviceAndroid = () => {
    const u = navigator.userAgent;
    return u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
};
