import { ExchangeOrder, ExchangeOrderType, TradeUtils } from "TradeAPILib";
import { useSelector } from "react-redux";
import AppState, { dispatch } from "./AppState";
import { useEffect, useMemo } from "react";
import { queryOrderFeedListTradingBotOrder } from "@pionex-web-kit/trading-components";

export const TYPE_COPY_BOT_DATA = "type_copy_bot_data";

export function CopyBotDataReducer(state = null, action: { type: string; data: ExchangeOrder }) {
    if (action.type === TYPE_COPY_BOT_DATA) {
        return action.data;
    }
    return state;
}

export function useCopyBotData() {
    const copyBotData = useSelector((state: AppState) => state.copyBotData);
    const { orderId, orderType } = useMemo(() => {
        return {
            orderId: new URLSearchParams(window.location.search).get("orderId"),
            orderType: new URLSearchParams(window.location.search).get("orderType")
        }
    }, []);
    useEffect(() => {
        if (!orderId || orderType !== ExchangeOrderType.gridV4) return;

        const $ob = queryOrderFeedListTradingBotOrder(orderId, orderType, false).subscribe({
            next: (data) => {
                dispatch(TYPE_COPY_BOT_DATA, TradeUtils.convertToExchangeOrder(data.order_data["payload"]));
            },
            error: () => { }
        }
        );
        return () => $ob.unsubscribe();
    }, [orderId, orderType]);

    return copyBotData;
}




