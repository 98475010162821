import { useDataProviderOnFirstApiKey } from "commonUse";
import useAccountInfo from "commonUse/useAccountInfo";
import { useEffect, useState } from "react";
import { OrderShareResp } from "TradeAPILib";

export const useShareOrderData = (shareId: string): OrderShareResp | null | undefined => {
    const [orderData, setOrderData] = useState<OrderShareResp | null | undefined>(null);
    const dataProvider = useDataProviderOnFirstApiKey();
    const accountInfo = useAccountInfo();
    useEffect(() => {
        if (!shareId || !accountInfo.isInitialized) return;
        if (!dataProvider) {
            setOrderData(undefined);
            return;
        }
        const observable$ = dataProvider.api.queryOrderShare(shareId).subscribe({
            next: (result) => {
                setOrderData(result);
            },

            error: (error) => {
                console.log(error);
                console.error("Unhandled error in subscribe: ", error);
            },
        });
        return () => {
            observable$.unsubscribe();
        };
    }, [accountInfo.isInitialized, dataProvider, shareId]);
    return orderData;
};
