import clasnames from "classnames";
import omit from "lodash/omit";
import * as React from "react";
import { Route, RouteComponentProps, withRouter } from "react-router";
import cs from "./Link.module.less";

export interface LinkProps extends RouteComponentProps<any> {
    component?: any;
    type?: "default" | "grey";
    className?: string;
    activeClassName?: string;
    exact?: boolean;
    strict?: boolean;
    children?: any;
    to: string;

    onJumpTo?(): void;

    [t: string]: any;

    onClickCallback?: () => void;
}

class Link extends React.Component<LinkProps, {}> {
    jumpTo = (e: React.MouseEvent<HTMLLinkElement>) => {
        if (this.props.onClickCallback) {
            this.props.onClickCallback();
        }
        const { to, history, onJumpTo } = this.props;
        if (onJumpTo) {
            e.preventDefault();
            onJumpTo();
            return;
        }
        if (to.match(window.location.origin) && e.target["tagName"] === "A") {
            return;
        }
        e.preventDefault();
        history.push(to);
    };

    render() {
        const { component = "a", exact, strict, activeClassName, location, type = "default", children, to, className, ...other } = this.props;
        const otherOmit = omit(other, ["history", "match", "staticContext", "onClickCallback"]);
        const C = component;
        if (component === "a") {
            otherOmit["href"] = to;
        }
        return (
            <Route
                path={to}
                exact={exact}
                strict={strict}
                location={location}
                children={({ match }) => {
                    const isActive = !!match;
                    const cls = clasnames(className, cs[`link-${type}`], isActive && activeClassName);
                    return (
                        <C className={cls} {...otherOmit} onClick={this.jumpTo}>
                            {children}
                        </C>
                    );
                }}
            />
        );
    }
}

export default withRouter<LinkProps, any>(Link);
