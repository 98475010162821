import { PaymentMethodChannel } from "landings/V2/BuyCrypto/Buy/cards";

export interface BuyCryptoConfig {
    coins: {
        channels: {
            channel: PaymentMethodChannel;
            fee: string; // 该通道买币手续费点差 例：0.01 （1%）
        }[];
        coin: string;
    }[];
}

export interface BuyCryptoParams {
    ach_account_id?: string; // channel = ACH通道 必传
    buy_amount: string; // 预购买数量：前端通过报价以及点差后，计算购买数量
    buy_coin: string; // 购买币种
    buy_price: string; // 购买报价：前端获取的order book 中报价
    channel: PaymentMethodChannel; // 购买渠道
    channel_fee: string; // 通道入金手续费
    fiat_amount: string; // 用户输入法币金额
}

export enum BuyCryptoStatus {
    pending = "pending", // 处理中:pending
    success = "success", // 成功:pending
    fail = "fail", // 失败:pending
    completed = "completed",
}

export interface BuyCryptoRecord {
    account_number: string;
    buy_amount: string;
    buy_coin: string;
    buy_frequency: string;
    buy_price: string;
    channel: string;
    channel_fee: string; // 通道入金手续费
    create_time: number;
    fail_reason?: string;
    fiat_amount: string;
    fiat_currency: string;
    order_id: string;
    status: BuyCryptoStatus;
    update_time: number;
    pay_time: number;
    proof_img?: string;
    need_proof?: string;
}

export type BuyCryptoFailReason = DeepPartial<{
    innerReason: string;
    [PaymentMethodChannel.ACH]: {
        code: string;
        msg: string;
        failReason: string;
        failDetail: string;
    };
}>;

export interface QuoteInfo {
    fee: number;
    spread: number;
    showPrice: string | number;
    totalAmount: string | number;
    price: string | number;
}

/**
 * 比价信息
 */
export interface CompareData {
    id: string;
    name: string;
    icon: string;
    quotation: QuoteInfo;
}
