import { Button, message } from "@pionex-web-kit/components";

import { ISelfBeautyInputProps, SelfBeautyInput } from "components/SelfInput/SelfInput";
import React, { useCallback, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Subject } from "rxjs";
import { VerifyCodeAction, VerifyCodeType } from "trade_asset_lib";
import { ParseErrorUtils } from "trade_lib_wrapper";
import AccountManager from "utils/AccountManager";
import { $st } from "utils/i18n";
import cs from "../../input/index.module.less";

export interface CodeInputViewProps {
    isOptInput?: boolean;
    code?: string;
    codeError?: string;
    token?: string;
    inputProps?: ISelfBeautyInputProps;
    action: VerifyCodeAction;
    verifyCodeType?: VerifyCodeType;
    codePlaceholder: string;
    onCodeChanged: (text: string) => void;
    autoSend?: boolean;
    emailCodeIdChange?(value: string);
}

export type CounterStatus = "pending" | "ok" | "failed";

export const CodeInputView = (props: CodeInputViewProps) => {
    const { code, codeError, codePlaceholder, onCodeChanged, action, verifyCodeType, token, isOptInput, inputProps, autoSend } = props;
    const verifySubject = useRef(new Subject<CounterStatus>());
    const { locale } = useIntl();

    const [counterStart, resetCountStatus] = React.useState({ status: "failed" as CounterStatus });
    const counterRef = useRef({ currentCount: 60 });
    const [counter, changeCounter] = React.useState(60);

    useEffect(() => {
        if (action === VerifyCodeAction.login || action === VerifyCodeAction.register || action === VerifyCodeAction.forgetPassword) {
            resetCountStatus({ status: "ok" });
        }
    }, [action]);

    const sendVerifyCodeCb = useCallback(
        ({ isAutoSend }) => {
            verifySubject.current.next("pending");
            AccountManager.shared.sendVerifyCode(action, verifyCodeType ? [verifyCodeType] : [], locale, token).subscribe(
                (res) => {
                    if (verifyCodeType === VerifyCodeType.sms || verifyCodeType === VerifyCodeType.email) {
                        !isAutoSend && message.success($st("withdraw_verify_code_sent"));
                    } else {
                        !isAutoSend && message.success($st("withdraw_request_voice_success"));
                    }
                    if (props.emailCodeIdChange) {
                        props.emailCodeIdChange(res?.data?.data?.email_code_id || "");
                    }
                    verifySubject.current.next("ok");
                },
                (error) => {
                    if (error.code === -4) {
                        message.error($st("verification_code_send_too_quick"));
                    } else {
                        message.error(ParseErrorUtils.formatterToString(error));
                    }
                    verifySubject.current.next("failed");
                },
            );
        },
        [action, verifyCodeType, locale, token],
    );

    useEffect(() => {
        const subscription = verifySubject.current.subscribe(
            (result) => {
                resetCountStatus({ status: result });
            },
            (error) => {
                resetCountStatus({ status: "failed" });
            },
        );
        return () => subscription && subscription.unsubscribe();
    }, []);
    useEffect(() => {
        // 挂载时自动发送验证码
        if (!autoSend) {
            return;
        }
        sendVerifyCodeCb({ isAutoSend: autoSend });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let timerObj;
        if (counterStart.status === "ok") {
            timerObj = setInterval(() => {
                counterRef.current.currentCount = counterRef.current.currentCount - 1;
                if (counterRef.current.currentCount === 0) {
                    resetCountStatus({ status: "failed" });
                    changeCounter(60);
                    counterRef.current.currentCount = 60;
                    clearInterval(timerObj);
                    return;
                }
                changeCounter(counterRef.current.currentCount);
            }, 1000);
        }

        return () => {
            timerObj && clearInterval(timerObj);
        };
    }, [counterRef, counterStart]);

    const getButtonText = () => {
        if (counterStart.status === "pending") {
            return $st("balance_withdraw_sending");
        } else if (counterStart.status === "failed") {
            return $st("withdraw.confirmation.phone.code.send");
        } else {
            return `${counter} s`;
        }
    };

    return (
        <div className={cs.editWrap}>
            <div style={{ flex: 1, position: "relative" }}>
                <SelfBeautyInput
                    inPutClassName={cs.inputBeauty}
                    shadowClassName={cs.shadowInputStyle}
                    value={code}
                    theme={codeError ? "danger-light" : undefined}
                    placeholder={codePlaceholder}
                    onValChange={onCodeChanged}
                    {...inputProps}
                />

                {!isOptInput ? (
                    <div style={{ position: "absolute", right: 0, top: 0, bottom: 0 }} className={cs.sendButton}>
                        <div
                            style={{
                                display: "flex",
                                height: "100%",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                className={cs.sendBtn}
                                loading={counterStart.status === "pending"}
                                size="small"
                                type={"primary"}
                                onClick={sendVerifyCodeCb}
                                disabled={counterStart.status !== "failed"}
                            >
                                {getButtonText()}
                            </Button>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
