import { Modal } from "@pionex-web-kit/components";
import { IconWarning20pxBoldS } from "@pionex-web-kit/icons";
import { AccountService, RequestVerifyDataParam, VerifyCodeAction, VerifyCodeType } from "bu_account_js_sdk";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { interval, NEVER, of, Subscription } from "rxjs";
import { startWith, mergeMap, take } from "rxjs/operators";
import AccountManager from "utils/AccountManager";
import { getOsInfo, getUserAgentInfo } from "./hook";
import SendButton from "./SendButton";
import { useTranslators } from "commonUse/Locale";
import { useLocaleIpInfo } from "commonUse/tools";

interface SecurityVerificationModalProps {
    visible: boolean;
    setVisible: (flag: boolean | undefined) => void;
    verifyConfig?: RequestVerifyDataParam;
    withdrawParams?: any;
    device_info: string;
    onCancel?: () => void;
}
/**
 * @description: 安全验证弹窗
 * @param {SecurityVerificationModalProps} param1
 * @return {*}
 */
function SecurityVerificationModal({ visible, setVisible, onCancel, verifyConfig, withdrawParams, device_info }: SecurityVerificationModalProps) {
    const {
        $st,
        intl: { locale },
    } = useTranslators();
    const [verifyMethod, setVerifyMethod] = useState<VerifyCodeType>(VerifyCodeType.email);
    const [verifyItems, setVerifyItems] = useState<any>();
    const [emailIsResend, seEmailIsResend] = useState(false);
    const [smsIsResend, setSmsIsResend] = useState(false);
    const [emailCodeId, setEmailCodeId] = useState("");
    const [smsCodeId, setSmsCodeId] = useState("");
    const $sendTimer = useRef<Subscription>();
    const { ipInfo: ipData } = useLocaleIpInfo();
    const changeVerifyMethod = useCallback(() => {
        setVerifyMethod((val) => (val === VerifyCodeType.email ? VerifyCodeType.sms : VerifyCodeType.email));
    }, []);
    /* 发送超链接 */
    const sendHyperlinkEvt = useCallback(() => {
        if (!verifyConfig || !device_info) return;
        AccountService.sendHyperlink({
            txn_id: verifyConfig.txn_id,
            usage: VerifyCodeAction.withdraw,
            lang: locale,
            type: verifyMethod,
            is_resend: verifyMethod === VerifyCodeType.email ? emailIsResend : smsIsResend,
            device_name: `${getUserAgentInfo()}(${getOsInfo()})`,
            ip_location: ipData?.city_name,
            biz_start_time: Date.now(),
            biz_params: withdrawParams,
            device_info,
        } as any).subscribe(({ code_id }) => {
            if (verifyMethod === VerifyCodeType.email) {
                setEmailCodeId(code_id);
            } else {
                setSmsCodeId(code_id);
            }
        });
    }, [device_info, emailIsResend, ipData?.city_name, locale, smsIsResend, verifyConfig, verifyMethod, withdrawParams]);
    const emailSendEvt = useCallback(() => {
        seEmailIsResend(true);
        sendHyperlinkEvt();
    }, [sendHyperlinkEvt]);
    const smsSendEvt = useCallback(() => {
        setSmsIsResend(true);
        sendHyperlinkEvt();
    }, [sendHyperlinkEvt]);
    const cancelEvt = useCallback(() => {
        setVisible(false);
        onCancel?.();
    }, [onCancel, setVisible]);
    /**
     * @description: 验证id和类型，保证用户怎么切都是正确的轮询
     * @param {*} useMemo
     * @return {*}
     */
    const verifyParams = useMemo(() => {
        if (verifyMethod === VerifyCodeType.email && emailCodeId) {
            return {
                type: VerifyCodeType.email,
                code_id: emailCodeId,
            };
        } else if (verifyMethod === VerifyCodeType.sms && smsCodeId) {
            return {
                type: VerifyCodeType.sms,
                code_id: smsCodeId,
            };
        } else if (emailCodeId) {
            return {
                type: VerifyCodeType.email,
                code_id: emailCodeId,
            };
        } else if (smsCodeId) {
            return {
                type: VerifyCodeType.sms,
                code_id: smsCodeId,
            };
        } else {
            return;
        }
    }, [emailCodeId, smsCodeId, verifyMethod]);
    useEffect(() => {
        const verify_items = (verifyConfig as any)?.paramData?.verify_items;
        if (!verify_items) return;
        const { items: verifyItems } = verify_items?.find(({ items }) => (items?.find(({ verify_type }) => verify_type === "hyperlink") ? true : false)) ?? {};
        if (!verifyItems) return;
        setVerifyItems(verifyItems);
        setVerifyMethod(verifyItems[0]?.target_type);
    }, [verifyConfig]);
    useEffect(() => {
        if (!visible || !verifyConfig || !verifyParams) return;
        const { txn_id = "", callback } = verifyConfig;
        const { code_id, type } = verifyParams;
        $sendTimer.current?.unsubscribe();
        $sendTimer.current = interval(5000)
            .pipe(
                startWith(0),
                mergeMap(() => {
                    return AccountService.verifyHyperlink({
                        txn_id,
                        usage: VerifyCodeAction.withdraw,
                        lang: "zh-CN",
                        type,
                        code_id,
                    }).pipe(
                        mergeMap(({ is_confirmed }) => {
                            if (is_confirmed) {
                                return of({ code_id });
                            } else {
                                return NEVER;
                            }
                        }),
                    );
                }),
                take(1),
            )
            .subscribe(
                () => {
                    callback({});
                },
                (error) => {
                    Modal.info({
                        title: $st("not_provide_service_title"),
                        content: AccountManager.parseErrorTips(error),
                    });
                    cancelEvt();
                },
            );
        return () => {
            $sendTimer.current?.unsubscribe();
        };
    }, [$st, cancelEvt, emailCodeId, smsCodeId, verifyConfig, verifyMethod, verifyParams, visible]);
    return (
        <Modal
            wSize="small"
            visible={visible}
            onCancel={cancelEvt}
            closable
            maskClosable={false}
            title=" "
            footer={null}
            afterClose={() => {
                setVisible(undefined);
            }}
        >
            <div className="flex flex-col items-center pt-[15px]">
                <IconWarning20pxBoldS className=" w-[100px] h-[100px]" />
                <div className="text-center text-accent text-xl font-m mt-[20px]">{$st("security_verification")}</div>
                <div className="text-base text-accent mt-[12px]">
                    {$st(verifyMethod === VerifyCodeType.email ? "tip_send_verification_information_to_mailbox" : "tip_send_verification_information_to_phone")}
                </div>
                <div className="w-full mt-12px mb-[50px] text-base text-accent font-m">
                    {$st(verifyMethod === VerifyCodeType.email ? "accepted_mailbox" : "accepted_phone")}:{" "}
                    {verifyItems?.find(({ target_type }) => target_type === verifyMethod).target_mask}
                </div>
                <SendButton className={verifyMethod === VerifyCodeType.email ? "" : "hidden"} onClick={emailSendEvt} />
                <SendButton className={verifyMethod === VerifyCodeType.sms ? "" : "hidden"} onClick={smsSendEvt} />
                {verifyItems && verifyItems?.length > 1 && (
                    <a className=" mt-20px text-base text-primary" onClick={changeVerifyMethod}>
                        {$st(verifyMethod === VerifyCodeType.email ? "switch_to_phone_reception" : "switch_to_mailbox_reception")}
                    </a>
                )}
            </div>
        </Modal>
    );
}
export default memo(SecurityVerificationModal);
