import Cache, { isSupportedLanguage, getLocalFile, getLocale } from "./cache";
import { AjaxResponse } from "../type";
import { LANGUAGE_LOCALS_MAP, LocalsMessages } from "./config";
import { I18n } from "./trans.comp";
import "./third.part";
// 国际化函数接口
export * from "./trans.comp";
export * from "./config";

export default I18n;

// 获取用户设置的语言标识
let CurrentLocale = getLocale();
// 获取第一次缓存的数据，确保下次不会出现没有解析的问题；
if (CurrentLocale !== LANGUAGE_LOCALS_MAP.enUS) LocalsMessages[CurrentLocale] = Cache.get(CurrentLocale);
// 确保英语语言模式下采用最新直接引入的数据

// 获取当前的语言tag，优先获取本地系统语言，如果为不支持的系统语言，这切换到默认语言；
export const getCurrentLocale = getLocale;

// 获取当前语言的翻译数据
export const getCurrentMessage = () => {
    if (CurrentLocale !== LANGUAGE_LOCALS_MAP.enUS) {
        return { ...LocalsMessages[LANGUAGE_LOCALS_MAP.enUS], ...(LocalsMessages[CurrentLocale] || {}) };
    }
    return LocalsMessages[LANGUAGE_LOCALS_MAP.enUS];
};

// 监听语言变化
const __LocalChangeListenerMap = new Map();
export function onLocaleChange(listener: (lang: string) => void) {
    function unregister() {
        __LocalChangeListenerMap.delete(listener);
    }
    __LocalChangeListenerMap.set(listener, unregister);
    return unregister;
}

// 切换获取国际化数据
export function getLocalMessage(lang: LANGUAGE_LOCALS_MAP) {
    // 设置本地语言
    const _support = isSupportedLanguage(lang);
    // 如果支持则更新
    if (!_support) {
        return Promise.resolve({
            result: false,
            data: null,
        } as AjaxResponse);
    }
    // 如果语言不是当前语言，则触发切换监听
    if (CurrentLocale !== lang) {
        __LocalChangeListenerMap.forEach((_, listener) => {
            listener(lang);
        });
    }
    Cache.setLocale(lang);
    CurrentLocale = lang;
    return new Promise((res, rej) => {
        getLocalFile(CurrentLocale)
            .then((langData) => {
                // 将英语作为默认语言，替补其他语言未翻译的字段
                langData = { ...LocalsMessages[LANGUAGE_LOCALS_MAP.enUS], ...langData };
                // 将语言提上日程
                LocalsMessages[CurrentLocale] = langData;
                Cache.set(langData);
                res({
                    result: true,
                    data: {
                        locale: CurrentLocale,
                        messages: langData,
                    },
                } as AjaxResponse);
            })
            .catch(rej);
    });
}

// 解析错误码，直接从源数据输出
export function mcode(code: string) {
    const message = LocalsMessages[CurrentLocale];
    return message[code] || code;
}
