import Constants from "utils/Constants";
import { acquireKeyResolver } from "trade_token_lib";
import { map } from "rxjs/operators";
import { camelize } from "utils/camelize";
import { Observable, of } from "rxjs";
import { AddCardRes, APIDebitCard, DebitCard, DebitCardChannel, DebitDepositRecord, DepositRes, PublicKeyInfo } from "landings/V2/CircleDebit/types";
import { DISABLE_CHECKOUT } from "landings/V2/CircleDebit/utils";

/**
 * CircleDebit 接口请求
 */
export default class DebitAPI {
    public addCard(data: Record<string, any>): Observable<AddCardRes> {
        const url = `${Constants.walletApiHost}/circle/cards/`;
        return acquireKeyResolver()
            .requestSigner.getSignRequestV2("POST", url, JSON.stringify(data))
            .pipe(map((res) => camelize(res.data)));
    }

    public getSumsubVerifyAccessToken({ card_id }: { card_id: string }): Observable<{ token: string }> {
        const url = `${Constants.walletApiHost}/circle/cards/access_token/?card_id=${card_id}`;
        return acquireKeyResolver()
            .requestSigner.getSignRequestV2("GET", url)
            .pipe(map((res) => camelize(res.data)));
    }

    public getPublicKey(channel: DebitCardChannel): Observable<PublicKeyInfo> {
        const url = `${Constants.walletApiHost}/circle/public_key/?channel=${channel}`;
        return acquireKeyResolver()
            .requestSigner.getSignRequestV2("GET", url)
            .pipe(map((res) => camelize(res.data)));
    }

    public getCard(id: string): Observable<DebitCard> {
        const url = `${Constants.walletApiHost}/circle/cards/${id}/`;
        return acquireKeyResolver()
            .requestSigner.getSignRequestV2("GET", url)
            .pipe(map((res) => camelize(res.data)));
    }

    public getCards(): Observable<APIDebitCard[]> {
        const url = `${Constants.walletApiHost}/circle/cards/`;
        return acquireKeyResolver()
            .requestSigner.getSignRequestV2("GET", url)
            .pipe(map((res) => camelize(res.data ?? [])));
    }

    public addCircleCard(data: { user_info: object; card_info: object }): Observable<AddCardRes> {
        return this.addCard({
            ...data,
            channel: DebitCardChannel.CIRCLE,
            enhance_verification: true, // 所有类型的卡都走加强验证
        });
    }

    public deleteCard(id: string): Observable<any> {
        const url = `${Constants.walletApiHost}/circle/cards/${id}/`;
        return acquireKeyResolver().requestSigner.getSignRequestV2("DELETE", url);
    }

    public deposit(data: Record<string, any>): Observable<DepositRes> {
        const url = `${Constants.walletApiHost}/circle/cards/deposits/`;
        return acquireKeyResolver()
            .requestSigner.getSignRequestV2("POST", url, JSON.stringify(data))
            .pipe(map((res) => camelize(res.data)));
    }

    public depositRecords(): Observable<DebitDepositRecord[]> {
        // TODO 暂时不支持分页
        const url = `${Constants.walletApiHost}/circle/cards/deposits/?page_no=1&page_size=200`;
        return acquireKeyResolver()
            .requestSigner.getSignRequestV2("GET", url)
            .pipe(map((res) => camelize(res.data.records ?? [])));
    }

    /**
     * 单个入金记录
     * @param id
     */
    public depositRecord(id: string): Observable<DebitDepositRecord> {
        const url = `${Constants.walletApiHost}/circle/cards/deposits/${id}/`;
        return acquireKeyResolver()
            .requestSigner.getSignRequestV2("GET", url)
            .pipe(map((res) => camelize(res.data)));
    }

    /**
     * 从服务器获取添加卡片的通道
     */
    public assignCardChannel(): Observable<DebitCardChannel> {
        const url = `${Constants.walletApiHost}/circle/cards/channel/`;
        if (DISABLE_CHECKOUT) {
            // 原本按比例分配通道，固定分配CIRCLE
            return of(DebitCardChannel.CIRCLE);
        }

        return acquireKeyResolver()
            .requestSigner.getSignRequestV2("GET", url)
            .pipe(map((res) => camelize(res.data.channel)));
    }

    /**
     * 通过Checkout通道添加卡片
     * @param data
     */
    public addCheckoutCard(data: { token: string; user_info: { name?: string; address_line1?: string; city?: string; district?: string; postal_code?: string; country?: string; } }): Observable<AddCardRes> {
        return this.addCard({ ...data, channel: DebitCardChannel.CHECKOUT });
    }
}
