import React, { ReactNode, useMemo, useState } from "react";
import { ModalProps } from "antd/lib/modal";
import { useTranslators } from "./Locale";
import { useToggle, useCallbackStatic } from "./tools";
import { TModal, TCheckbox } from "src/components/v2/TinyComps";

export interface ConfirmCheckModalProps extends Omit<ModalProps, "visible" | "onOk"> {
    visible?: boolean | (() => boolean);
    content?: ReactNode;
    addonContent?: ReactNode;
    // 是否需要点击同意的checkbox，才能继续点击确定按钮
    checkLabel?: false | ReactNode;
    // checkLabel的check状态是否影响ok button;
    checkLabelOkButtonControl?: boolean;
    iniLabelChecked?: boolean;
    computedProps?({ labelChecked: boolean }): [ConfirmCheckModalProps];
    onOk?(e: React.MouseEvent<HTMLElement, MouseEvent>, labelChecked: boolean): void;
    onLabelCheckChange?(flag: boolean): void;
}
/**
 * 调用一个抽象的有二次check交互的弹窗控制器
 * @注意
 * 缺点：
 * - 由于该方法仅是对业务代码的一层复用抽象，函数内的状态变更也是会触发或影响调用组件的 render-update;
 * - 仅支持函数组件
 * 优点：
 * - 抽象层便于理解与使用，调用和执行逻辑流程清晰；
 * - 类型输出明确，不需要对称式的调用申明。
 * @param propsConf
 */
export const useConfirmCheckModal = (inputProps: ConfirmCheckModalProps = {}) => {
    const { $st } = useTranslators();
    const [innerProps, updateInnerProps] = useState<ConfirmCheckModalProps>({});
    const [innerVisible, { setTrue, setFalse }] = useToggle(inputProps.visible);
    const initCheckState = inputProps.iniLabelChecked || false;
    const [labelChecked, setChecked] = useState(initCheckState);
    const [comptedProps] = useMemo(() => {
        return innerProps.computedProps?.({ labelChecked }) || inputProps.computedProps?.({ labelChecked }) || [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [innerProps, labelChecked]);
    const {
        onOk,
        onCancel,
        onLabelCheckChange,
        visible,
        checkLabelOkButtonControl = true,
        okButtonProps,
        content,
        addonContent,
        checkLabel = $st("fund_read_agreement"),
        ...props
    } = { ...inputProps, ...innerProps, ...comptedProps };
    const _onOk = useCallbackStatic((e) => {
        setFalse();
        onOk?.(e, labelChecked);
        // 关闭后，重置
        updateInnerProps({});
        setChecked(initCheckState);
    });
    const _onCancel = useCallbackStatic((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setFalse();
        onCancel?.(e);
        // 关闭后，重置
        updateInnerProps({});
        setChecked(initCheckState);
    });
    const _onCheckedChange = useCallbackStatic((e) => {
        const checkState = e.target.checked;
        setChecked(checkState);
        onLabelCheckChange?.(checkState);
    });
    return [
        <TModal
            visible={innerVisible}
            closable={false}
            maskClosable={false}
            okButtonProps={{ ...okButtonProps, ...(!checkLabelOkButtonControl || checkLabel === false ? {} : { disabled: !labelChecked }) }}
            onOk={_onOk}
            onCancel={_onCancel}
            {...props}
        >
            <div>{content}</div>
            {checkLabel !== false && (
                <div style={{ marginTop: 10 }}>
                    <TCheckbox checked={labelChecked} onChange={_onCheckedChange}>
                        {checkLabel}
                    </TCheckbox>
                </div>
            )}
            {addonContent}
        </TModal>,
        {
            visible: innerVisible,
            show: useCallbackStatic((newProps?: ConfirmCheckModalProps) => {
                newProps && updateInnerProps(newProps);
                setTrue();
            }),
            update: useCallbackStatic((newProps: ConfirmCheckModalProps) => {
                updateInnerProps({ ...innerProps, ...newProps });
            }),
        },
    ] as const;
};
