import React, { MouseEventHandler, PropsWithChildren, ReactNode, useCallback, useState } from "react";
import { FormInstance, Modal, ModalProps } from "@pionex-web-kit/components";
import { PaymentMethod } from "landings/V2/BuyCrypto/Buy/cards";
import { useTranslators } from "commonUse/Locale";
import { IconRightArrow12pxRegular } from "@pionex-web-kit/icons";
import { useCallbackStatic } from "commonUse/tools";
import StripeACH from "../StripeACH";
import { useACHActivity } from "landings/V2/CircleACH/utils";
import { useBalanceTradeByBaseQuote } from "commonUse/useBalance";
import { PayMentMethod } from "landings/V2/BuyCrypto/components/Form";

interface Props extends ModalProps {
    selectKey?: string;
    paymentMethods: PaymentMethod[];
    refresh: () => void;
    onChange?: (v: string | undefined) => void;
    amount?: string;
    coin?: string;
    selectPayMethod?: (v: string) => void;
    hideModal?: () => void;
    form: FormInstance<any>;
    isSelect?: string;
}

/**
 * 选择支付方式的弹窗
 */
const SelectPayMethodModal: React.FC<Props> = ({ selectKey, onChange, paymentMethods, refresh, amount, coin, selectPayMethod, hideModal, form, isSelect, ...resetProps }) => {
    const { $st } = useTranslators();

    const [extraContent, setExtraContent] = useState<ReactNode>();
    const clearExtraContent = useCallbackStatic(() => {
        setExtraContent(undefined);
    });

    const [stripeAchFlag, setStripeAchFlag] = useState<boolean>();
    const activity = useACHActivity("deposit");
    const [{ baseAvailable: usdAvailable }] = useBalanceTradeByBaseQuote("USD", "USDT"); // 原始的余额

    return (
        <>
            <Modal {...resetProps} maskClosable={false} keyboard={false} title={$st("buy_crypto_select_paymethod")} wSize="small" className="h-600px" closable footer={null}>
                {paymentMethods.map((item) => (
                    <>
                        <LinkItemWrapper
                            isSelect={isSelect === PayMentMethod.balance}
                            onClick={() => {
                                selectPayMethod?.(PayMentMethod.balance);
                                hideModal?.();
                                form.validateFields();
                            }}
                        >
                            {item.renderItem()}
                            <div className="flex justify-between items-center mt-[7px] text-secondary">
                                <div>{$st("buy_crypto_available_balance")}</div>
                                <div>{usdAvailable}USD</div>
                            </div>
                        </LinkItemWrapper>
                    </>
                ))}
                <div className="mt-20px text-base text-secondary">{$st("buy_crypto_link_new")}</div>
                <LinkItemWrapper
                    isSelect={isSelect === PayMentMethod.bank_account}
                    onClick={() => {
                        selectPayMethod?.(PayMentMethod.bank_account);
                        hideModal?.();
                        form.validateFields();
                    }}
                >
                    <span>
                        {$st("nav_title_usd_deposit_ach")} <img className="h-[16px]" src={activity?.image} alt={"activity"} />
                    </span>
                </LinkItemWrapper>
                <LinkItemWrapper
                    isSelect={isSelect === PayMentMethod.wire_transfer}
                    onClick={() => {
                        selectPayMethod?.(PayMentMethod.wire_transfer);
                        hideModal?.();
                        form.validateFields();
                    }}
                >
                    {$st("fbo_addCard_transfer_type_wire")}
                </LinkItemWrapper>
                <div className="mt-8px text-sm text-secondary">{$st("buy_crypto_link_more")}</div>
            </Modal>
            {extraContent}
            {stripeAchFlag !== undefined && (
                <StripeACH
                    modalVisible={stripeAchFlag}
                    onClose={() => {
                        setStripeAchFlag(false);
                    }}
                    amount={amount}
                />
            )}
        </>
    );
};

export default SelectPayMethodModal;

const LinkItemWrapper: React.FC<PropsWithChildren<{ onClick?: MouseEventHandler; isSelect?: boolean }>> = ({ children, onClick, isSelect }) => {
    return (
        <div
            className={`flex flex-col  py-14px px-12px mt-12px border border-solid ${isSelect ? "border-primary" : "border-divider"} rounded-10px cursor-pointer`}
            onClick={onClick}
        >
            {children}
        </div>
    );
};

function LinkItemCollapse({ title, children }: { title: ReactNode; children: ReactNode }) {
    const [isCollapse, setIsCollapse] = useState(true);
    const onClick = useCallback(() => {
        setIsCollapse(!isCollapse);
    }, [isCollapse]);
    return (
        <div className="flex flex-col px-12px mt-12px border border-solid border-divider rounded-10px">
            <div className="flex py-14px justify-between items-center cursor-pointer" onClick={onClick}>
                {title}
                <IconRightArrow12pxRegular className={`text-secondary-sub ${isCollapse ? "rotate-90" : ""}`} size={12} />
            </div>
            {isCollapse && children}
        </div>
    );
}
