import { Form } from "antd";
import React, { useImperativeHandle, useState, forwardRef, useCallback } from "react";
import csn from "classnames";
import { useTranslators } from "commonUse/Locale";
import cs from "./index.m.less";
import { TDateRangePicker, TSelect } from "components/v2/TinyComps";
import { timeRangeTypes, timeRangeValueS } from "./const";
import Picker from "./datePicker";
import useMedia from "src/components-v2/Layout/hooks/useMedia";
import moment from "moment";

interface Props {
    onFinish?: (value: any) => void;
}
export interface FormRef {
    submit: () => void;
}
export default forwardRef<FormRef, Props>(({ onFinish: outFinish }, ref) => {
    const { isSM } = useMedia();
    const { $st } = useTranslators();
    const [form] = Form.useForm();
    const [showPicker, setShowPicker] = useState(false);
    const onFinish = (values) => {
        let _values;
        if (values.range !== "customer") {
            _values = timeRangeValueS[values.range];
        } else {
            _values = {
                start_time: values.time ? moment(values.time[0]).unix().valueOf() : null,
                end_time: values.time ? moment(values.time[1]).unix().valueOf() : null,
            };
        }
        outFinish && outFinish(_values);
    };
    const onFinishFailed = () => {};
    const onValuesChange = (changeValue) => {
        if (changeValue.range) setShowPicker(changeValue.range === "customer");
    };
    useImperativeHandle(ref, () => ({ submit: () => form.submit(), reset: () => form.resetFields() }));

    const disabledDate = useCallback((current) => {
        return current && !(current > moment().subtract(3, "year").startOf("year") && current < moment());
    }, []);

    return (
        <Form className={csn(cs.formStyle)} form={form} onFinish={onFinish} onValuesChange={onValuesChange} onFinishFailed={onFinishFailed}>
            <Form.Item name="range" rules={[{ required: true }]}>
                <TSelect size="large" placeholder={$st("taxes_select")}>
                    {timeRangeTypes.map((item) => {
                        return (
                            <TSelect.Option key={item.key} value={item.key}>
                                {$st(item.text)}
                                {item._text ? ` (${item._text}) ` : null}
                            </TSelect.Option>
                        );
                    })}
                </TSelect>
            </Form.Item>
            {showPicker && (
                <Form.Item
                    className={cs.pickerMrb}
                    name="time"
                    rules={[
                        () => ({
                            validator(_, value = []) {
                                if (Array.isArray(value) && value[0] && value[1]) {
                                    return Promise.resolve();
                                }
                                return Promise.reject("time fail");
                            },
                        }),
                    ]}
                >
                    {isSM ? <Picker /> : <TDateRangePicker format="MM-DD-YYYY" allowClear disabledDate={disabledDate} className={cs.picker} size="small" />}
                </Form.Item>
            )}
        </Form>
    );
});
