import * as React from "react";
import { connect } from "react-redux";
import PionexClassName from "src/utils/PionexClassName";
import AppState from "state/AppState";
import ThemeData from "state/ThemeData";

function RedGreenManager({ theme }: { theme: ThemeData }) {
    return (
        <style>
            {`.${PionexClassName.tradingUp} {
          color: ${theme.colorIncrease}
        }
        .${PionexClassName.tradingUpBg} {
            background-color: ${theme.colorIncrease}
        }
        .${PionexClassName.tradingDownBg} {
            background-color: ${theme.colorDecrease}
        }
        .${PionexClassName.tradingDown} {
          color: ${theme.colorDecrease}
        }`}
        </style>
    );
}

export default connect((state: AppState) => ({
    theme: state.theme,
}))(RedGreenManager);
