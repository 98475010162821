import { getMeta } from "utils/commons";
import axios, { AxiosTransformer } from "axios";
import { $st } from "utils/i18n/trans.comp";

export function configAxios() {
    const csrfToken = getMeta("csrf-token");
    if (csrfToken) {
        axios.defaults.headers.common["csrf-token"] = csrfToken;
    }

    transformCommonError();
}

/**
 * 转换一些公共的错误
 */
export function transformCommonError() {
    if (!Array.isArray(axios.defaults.transformResponse)) {
        if (axios.defaults.transformResponse) {
            axios.defaults.transformResponse = [axios.defaults.transformResponse];
        } else axios.defaults.transformResponse = [];
    }

    (axios.defaults.transformResponse as AxiosTransformer[]).push((data, headers) => {
        if (typeof data.code === "number" && data.code === 0) {
            // 未报错
            return data;
        }

        if (!!data.message) {
            // 转换错误信息中的message内容，是否提示还是交由外部控制
            return { ...data, message: $st({ id: `error_code:${data.code}`, defaultMessage: parseCommonErrorMsg(data.message) }, data.data) };
        }
        return data;
    });
}

/**
 * 处理message字段中的信息
 * @param message
 */
export function parseCommonErrorMsg(message: string): string {
    if (/invalid timestamp/i.test(message)) {
        return $st("invalid_timestamp_check_time");
    }
    return message;
}
