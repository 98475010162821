import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnsType, Table, message, Button, Modal } from "@pionex-web-kit/components";
import { useTranslators } from "commonUse/Locale";
import { BuyCryptoRecord, BuyCryptoStatus } from "landings/V2/BuyCrypto/types";
import TReportLink, { Iconfont, TTooltip } from "components/v2/TinyComps";
import { FailReasonUtils } from "landings/V2/BuyCrypto/failReason";
import {
    IconFailed20pxBoldS,
    IconInformation16pxBoldS,
    IconLoadFailed100pxBoldS,
    IconPending20pxBoldS,
    IconSuccess20pxBoldS,
    IconUpload40,
    IconWaiting100pxBoldS,
} from "@pionex-web-kit/icons";
import useDateFormatter from "commonUse/useDateFormatter";
import NumberUtils from "utils/NumberUtils";
import Constants from "utils/Constants";
import { AuthType, Network } from "trade_utils_lib";
import { Observable } from "rxjs";
import CopyToClipboard from "react-copy-to-clipboard";
import cs from "landings/V2/SignatureDW/cs.m.less";
import Uploader from "landings/V2/USDDW/components/Uploader";

export function getOrderList(data: Record<string, any>): Observable<any> {
    const url = `${Constants.walletApiHost}/stripe/ach/get_order_list/`;
    return Network.get({
        url,
        data,
        authType: AuthType.Spot,
    });
}
interface Props {}

const newStatus = {
    SUCCEED: "success",
    FAILED: "fail",
    PENDING: "pending",
    CANCELED: "cancel",
    COMPLETED: "completed",
};

const AchStripeHistory: React.FC<Props> = () => {
    const [imgErrors, setImgErrors] = useState<string>("");
    const [file, setFile] = useState<string>("");
    const [isUpload, setIsUpload] = useState<boolean>(false);
    const [orderId, setOrderId] = useState<string | undefined>("");
    const [pageIndex, setPageIndex] = useState(1);
    const pageSize = useMemo(() => 20, []);
    const { $st } = useTranslators();
    const requestData = useMemo(() => {
        return { limit: pageSize, page: pageIndex - 1 };
    }, [pageIndex, pageSize]);
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState(true);
    const records = useMemo(() => {
        if (!data?.records) return [];
        return data?.records.map(
            ({
                coin: buy_coin,
                transfer_amount: buy_amount,
                amount: fiat_amount,
                fee_amount: fee,
                status,
                create_time,
                update_time,
                transfer_time,
                pay_time,
                order_id,
                channel_tag: channel,
                account_id: account_number,
                err_desc: fail_reason,
                proof_img,
                need_proof,
            }) => {
                return {
                    buy_coin,
                    buy_amount,
                    fee,
                    fiat_amount,
                    status: newStatus[status] || status,
                    create_time,
                    update_time,
                    transfer_time,
                    pay_time,
                    order_id,
                    channel,
                    account_number,
                    fail_reason,
                    proof_img,
                    need_proof,
                };
            },
        );
    }, [data?.records]);

    const uploadModal = useCallback(
        (order_id?: string) => {
            setOrderId(order_id);
            setIsUpload(!isUpload);
        },
        [isUpload],
    );

    const refresh = useCallback(() => {
        setLoading(true);
        getOrderList(requestData).subscribe({
            next: (res) => {
                setLoading(false);
                setData(res);
            },
            error: () => {
                setLoading(false);
            },
        });
    }, [requestData]);

    const columns = useColumns({ uploadModal });

    const send = useCallback(() => {
        if (!!imgErrors) {
            return;
        }
        return Network.post({
            url: `${Constants.walletApiHost}/stripe/ach/update_proof/`,
            authType: AuthType.Spot,
            data: {
                order_id: orderId,
                proof_img: file,
            },
        }).subscribe(() => {
            setIsUpload(!isUpload);
            setOrderId("");
            refresh();
        });
    }, [file, imgErrors, isUpload, orderId, refresh]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    return (
        <div className=" mb-[30px]">
            <Table
                loading={loading}
                scroll={{ x: "100%" }}
                dataSource={records}
                columns={columns}
                pagination={{
                    position: ["bottomLeft"],
                    total: data?.total ?? 0,
                    pageSize,
                    showSizeChanger: false,
                    onChange: (e) => {
                        setPageIndex(e);
                    },
                }}
            />
            <Modal
                title={<div className="text-xl text-accent">{$st("ach_history_upload_modal_title")}</div>}
                visible={isUpload}
                onCancel={() => setIsUpload(!isUpload)}
                onOk={send}
                okButtonProps={{
                    disabled: !file,
                }}
                okText={<span>{$st("ach_history_upload_modal_ok")}</span>}
            >
                <Uploader key={orderId} className={cs.upload} onFileUploaded={setFile} setImgErrors={setImgErrors} />
            </Modal>
        </div>
    );
};

export default AchStripeHistory;

function useColumns({ uploadModal }: { uploadModal: (order_id: string) => void }) {
    const { $st } = useTranslators();
    const dateFormatter = useDateFormatter();

    return useMemo(() => {
        const columns: ColumnsType<BuyCryptoRecord> = [
            {
                title: $st("signature_dw_history_order_id"),
                dataIndex: "order_id",
                key: "order_id",
                render: (id) => {
                    return (
                        <CopyToClipboard text={id} onCopy={() => message.success($st("tip_copy_success"))}>
                            <TReportLink>
                                {id.slice(0, 8)}... <Iconfont icon="icon_copy" className={cs.copy} />
                            </TReportLink>
                        </CopyToClipboard>
                    );
                },
            },
            {
                title: $st("debit_record_deposit_from"),
                dataIndex: "id",
                key: "id",
                render: () => {
                    return "stripe";
                },
            },
            {
                title: $st("buy_crypto_status"),
                dataIndex: "status",
                key: "status",
                render: (value, record) => {
                    return (
                        <div className="flex items-center">
                            <div className="flex items-center">
                                {value === "fail" && <IconFailed20pxBoldS size={20} className="mr-4px" />}
                                {value === "pending" && <IconPending20pxBoldS size={20} className="mr-4px" />}
                                {value === "success" && <IconWaiting100pxBoldS size={20} className="mr-4px" />}
                                {value === "cancel" && <IconLoadFailed100pxBoldS size={22} className="mr-4px" />}
                                {value === "completed" && <IconSuccess20pxBoldS size={20} className="mr-4px" />}
                                {$st(`buy_crypto_status:${value}`)}
                                {value === BuyCryptoStatus.fail && record.fail_reason && (
                                    <TTooltip title={<span>{FailReasonUtils.extractFailReason($st, record.fail_reason)}</span>}>
                                        <IconInformation16pxBoldS size={18} className="ml-4px" />
                                    </TTooltip>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                title: $st("ach_history_amount"),
                dataIndex: "fiat_amount",
                key: "fiat_amount",
                render: (value) => {
                    return NumberUtils.numberWithCommas(value) || "--";
                },
            },
            {
                title: $st("running_fee"),
                dataIndex: "fee",
                key: "fee",
                render: (value) => {
                    return <>${value}</>;
                },
            },

            {
                title: $st("create_time"),
                dataIndex: "create_time",
                key: "create_time",
                render: (value) => {
                    return value ? <div>{dateFormatter.year_second(value)}</div> : null;
                },
            },
            {
                title: $st("payment_received_time"),
                dataIndex: "pay_time",
                key: "pay_time",
                render: (value, record) => {
                    return record.status === BuyCryptoStatus.completed && !!value ? <div>{dateFormatter.year_second(value)}</div> : null;
                },
            },

            {
                title: $st("withdrawable_Time"),
                dataIndex: "pay_time",
                key: "pay_time",
                render: (value, record) => {
                    return record.status === BuyCryptoStatus.completed && !!value ? <div>{dateFormatter.year_second(value + 14 * 24 * 60 * 60 * 1000)}</div> : null;
                },
            },
            {
                title: $st("ach_history_upload"),
                dataIndex: "receipt",
                key: "receipt",
                render: (value, record) => {
                    return record.proof_img ? (
                        <span className="text-green whitespace-nowrap">{$st("ach_history_upload_success")}</span>
                    ) : (
                        record.need_proof && (
                            <Button className={cs.achHistoryBtn} type="primary" onClick={() => uploadModal(record.order_id)}>
                                <IconUpload40 size={16} />
                                <span className="ml-4px">{$st("ach_history_upload_click")}</span>
                            </Button>
                        )
                    );
                },
            },
        ];

        return columns;
    }, [$st, dateFormatter, uploadModal]);
}
