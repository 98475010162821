import { useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { history } from "utils/History";

export enum Redirect_Type {
    REDIRECT_TYPE = "redirectUrl",
}

function defaultUrl() {
    return window.location.href.replace(window.location.origin, "").replace(history.basename || "", "");
}

export function createRedirectParam(url: string = defaultUrl()) {
    return { [Redirect_Type.REDIRECT_TYPE]: encodeURIComponent(url) };
}
export function createRedirectQueryPart(url: string = defaultUrl()) {
    return `${[Redirect_Type.REDIRECT_TYPE]}=${encodeURIComponent(url)}`;
}
export function redirect_url(history, search: string) {
    const value = new URLSearchParams(search).get(Redirect_Type.REDIRECT_TYPE);
    if (value) {
        history.push(decodeURIComponent(value));
        return true;
    }
    return false;
}
export function useRedirect() {
    const history = useHistory();
    const { search } = useLocation();
    return useCallback(() => {
        return redirect_url(history, search);
    }, [history, search]);
}
