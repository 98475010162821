import React, { useMemo } from "react";
import { DWConfig } from "utils/onlineConfig";

/**
 * 标题部分，有配置活动需要展示活动
 * @constructor
 */
const TitleCom: React.FC<{
    title?: string;
    activity?: DeepPartial<DWConfig["d"]["cir_ach"]["activity"]["web"]>;
}> = ({ title, activity }) => {
    const { image, link } = activity ?? {};

    const activityEle = useMemo(() => {
        const img = <img className={"block mb-16px m-auto w-[70%]"} src={image} alt={"activity"} />;
        if (!image) {
            return null;
        }

        if (!link) {
            return img;
        }

        return (
            <a href={link} target="_blank" rel="noreferrer">
                {img}
            </a>
        );
    }, [image, link]);

    return (
        <>
            {activityEle}
            <div className="mx-auto mb-40px text-center font-sb text-[28px] text-accent">{title}</div>
        </>
    );
};

export default TitleCom;
