import { Component, default as React } from "react";
import { $st } from "utils/i18n/trans.comp";
import cs from "./index.module.less";

export type CustomAntdEmptyProps = {
    dataLength: number;
    loading: boolean;
    no_data_value?: string;
    height?: number;
    style?;
};

export default class CustomAntdEmpty extends Component<CustomAntdEmptyProps> {
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const { dataLength, loading, no_data_value } = this.props;

        return (
            <div>
                {!(loading && dataLength === 0) ? (
                    <div
                        style={{
                            ...this.props.style,
                            height: this.props.height ? this.props.height : 100,
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        {/*<div>*/}
                        {/*  <img src={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: 60, height: 60 }}/>*/}
                        {/*</div>*/}
                        <span className={cs.no_data_title}>{no_data_value ? no_data_value : $st("no_data")}</span>
                    </div>
                ) : (
                    <div style={{ height: 100 }} />
                )}
            </div>
        );
    }
}
