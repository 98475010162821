import React, { useState } from "react";
import cs from "./cs.m.less";
import { useTranslators } from "commonUse/Locale";
import { TButton, TDatePicker, TForm, TInput } from "components/v2/TinyComps";
import { Callbacks } from "rc-field-form/lib/interface";
import moment, { Moment } from "moment";
import TSelfNumberInput from "src/components-v2/TSelfNumberInput";
import { validateCardName } from "landings/V2/USDDW/utils";
import { Store } from "antd/lib/form/interface";
import CardSample from "../components/CardSample";
import { Network } from "landings/V2/CircleDebit/types";
import { validateDebitCardNum } from "landings/V2/CircleDebit/utils";

export const CVV_MAX_LENGTH = 3;

export interface Step1FormData {
    cardNum: string;
    holderName: string;
    cvv: string;
    expireDate: Moment;
}

interface Props {
    onNext?: (cardInfo: Step1FormData) => void;
    initialValues?: Store;
}

/**
 * DebitCard绑卡第一步，填写银行卡信息
 * @constructor
 */
const Step1Form: React.FC<Props> = ({ onNext, initialValues }) => {
    const { $st } = useTranslators();
    const handleFinish: Callbacks["onFinish"] = (values) => {
        onNext?.(values as Step1FormData);
    };

    const [focusCVV, setFocusCVV] = useState(false);

    return (
        <TForm className={cs.form} onFinish={handleFinish} initialValues={initialValues}>
            <div className={cs.subTitle}>{$st("debit_add_card_info_subtitle")}</div>
            <div className={cs.cardInfoTips}>{$st("debit_add_card_info_tips")}</div>
            <TForm.TItem
                name={"cardNum"}
                labelHidden
                label={$st("debit_add_card_info_num")}
                rules={[
                    { required: true },
                    {
                        validator(_, value) {
                            return validateDebitCardNum($st, value);
                        },
                    },
                ]}
                normalize={(value) => value?.replace(/(\d{4})(?=\d)/g, "$1 ")}
            >
                <TSelfNumberInput maxLength={19} size={"large"} placeholder={$st("debit_add_card_info_num")} autoComplete={"off"} />
            </TForm.TItem>
            <TForm.TItem
                name={"holderName"}
                labelHidden
                label={$st("debit_add_card_info_holder_name")}
                normalize={(value) => value.toUpperCase()}
                rules={[
                    { required: true },
                    {
                        validator: (_, originValue) => validateCardName($st, originValue),
                    },
                ]}
            >
                <TInput size={"large"} placeholder={$st("debit_add_card_info_holder_name")} />
            </TForm.TItem>
            <div className={cs.cardCVVContainer}>
                <TForm.TItem name={"expireDate"} labelHidden label={$st("debit_add_card_info_expire")} rules={[{ required: true }]}>
                    <TDatePicker
                        disabledDate={(date) => {
                            const year = date.get("year");
                            return year > 2099 || year < moment().get("year");
                        }}
                        format={"MM/YY"}
                        picker={"month"}
                        placeholder={$st("debit_add_card_info_expire")}
                    />
                </TForm.TItem>
                <TForm.TItem
                    name={"cvv"}
                    labelHidden
                    label={$st("debit_add_card_info_cvv")}
                    rules={[
                        { required: true },
                        {
                            validator(_, value) {
                                if (!value || value.replace(/\s/g, "").length < 3) {
                                    return Promise.reject($st("debit_add_card_cvv_format"));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <TSelfNumberInput
                        maxLength={CVV_MAX_LENGTH}
                        size={"large"}
                        autoComplete={"off"}
                        placeholder={$st("debit_add_card_info_cvv")}
                        onFocus={() => setFocusCVV(true)}
                        onBlur={() => setFocusCVV(false)}
                    />
                </TForm.TItem>
            </div>

            <TForm.TItem shouldUpdate>
                {({ getFieldValue }) => {
                    return (
                        <CardSample
                            className={cs.cardSample}
                            cardType={getFieldValue("cardNum")?.startsWith("5") ? Network.MASTER : Network.VISA}
                            orientation={focusCVV ? "back" : "front"}
                        />
                    );
                }}
            </TForm.TItem>

            <TButton htmlType={"submit"} className={cs.step1Btn} type={"primary"} size={"large"}>
                {$st("common_next")}
            </TButton>
        </TForm>
    );
};

export default Step1Form;
