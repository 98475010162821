import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Input, InputNumber } from "antd";
import { InputProps } from "antd/es/input";
import { InputNumberProps } from "antd/es/input-number";
import "./index.less";
import decStrNum from "src/landings/PionexHistoricalOrderDetails/components/Grid2OrderCommon/tool/decStrNum";
import { SafeDecimal } from "trade_utils_lib";
import { Iconfont, TTooltip } from "components/v2/TinyComps";

function precisionToStep(precision) {
    if (precision === undefined || precision === null || isNaN(Number(precision))) {
        return undefined;
    }

    const zeroCount = Number(precision) - 1;
    if (zeroCount <= -1) {
        return 1;
    }

    const precisionZero = new Array(zeroCount).fill("0").join("");
    return Number(`0.${precisionZero}1`);
}

export interface ISelfBeautyInputProps extends InputProps {
    autoHolder?: "holder-top";
    onValChange?: (value: string | undefined, e: React.ChangeEvent<HTMLInputElement>) => void;
    theme?: "danger-light" | "gray-light" | "red-light" | "green-light";
    inPutClassName?: string;
    shadowClassName?: string;
    shadowTextClassName?: string;
    onFocus?: (ev: any) => any;
    onBlur?: (ev: any) => any;
}

interface InputDataProps {
    inputEl?: HTMLInputElement | null;
    focus?: boolean;
}

export const SelfBeautyInput = (props: ISelfBeautyInputProps) => {
    const {
        autoHolder = "holder-top",
        theme = "gray-light",
        onValChange,
        inPutClassName,
        shadowClassName,
        shadowTextClassName,
        onFocus,
        onBlur,
        placeholder,
        className,
        value,
        onChange,
        disabled,
        defaultValue,
        style,
        ...extraProps
    } = props;

    const SelfBeautyInputClassNames = useMemo(() => {
        return `self-beauty-input${theme ? ` ${theme}` : ""}${className ? ` ${className}` : ""}`;
    }, [theme, className]);

    const realPlaceholder = useMemo(() => {
        if (autoHolder) {
            return undefined;
        }
        return placeholder;
    }, [autoHolder, placeholder]);

    const inputBox = useRef(null as HTMLDivElement | null);
    const inputData = useRef({} as InputDataProps);
    const [shadowPlaceholderActive, setShadowPlaceholderActive] = useState(false);

    const checkHolderActive = useCallback(
        (val: string | number | string[] | undefined) => {
            if (!autoHolder) {
                setShadowPlaceholderActive(false);
                return;
            }
            if (inputData.current["focus"]) {
                setShadowPlaceholderActive(true);
                return;
            }
            if (val === undefined || val === "" || val === null) {
                setShadowPlaceholderActive(false);
            } else {
                setShadowPlaceholderActive(true);
            }
        },
        [autoHolder],
    );

    useEffect(() => {
        if (!inputData.current["inputEl"]) {
            const inputEl = inputBox.current!.querySelector("input");
            inputData.current["inputEl"] = inputEl;
        }

        const tempFoucs = (ev: FocusEvent) => {
            onFocus && onFocus(ev);
            if (!autoHolder) {
                return;
            }
            inputData.current["focus"] = true;
            checkHolderActive(value as any);
        };
        const tempBlur = (ev: FocusEvent) => {
            onBlur && onBlur(ev);
            if (!autoHolder) {
                return;
            }
            inputData.current["focus"] = false;
            checkHolderActive(value as any);
        };
        inputData.current["inputEl"]!.addEventListener("focus", tempFoucs, false);
        inputData.current["inputEl"]!.addEventListener("blur", tempBlur, false);

        return () => {
            inputData.current["inputEl"]!.removeEventListener("focus", tempFoucs);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            inputData.current["inputEl"]!.removeEventListener("blur", tempBlur);
        };
    }, [autoHolder, value, onFocus, onBlur, checkHolderActive]);

    const inputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const realVal = e.target.value;
            onValChange && onValChange(realVal, e);
            onChange && onChange(e);
            if (autoHolder) {
                checkHolderActive(realVal);
            }
        },
        [onValChange, onChange, checkHolderActive, autoHolder],
    );

    useEffect(() => {
        if (autoHolder) {
            checkHolderActive(value as any);
        }
    }, [value, autoHolder, checkHolderActive]);

    const shadowPlaceholder = useMemo(() => {
        if (!autoHolder) {
            return null;
        }

        const placeholderClassName = `shadow-placeholder${autoHolder ? ` ${autoHolder}` : ""}${shadowPlaceholderActive ? ` active` : ""} ${shadowClassName}`;
        return (
            <div
                className={placeholderClassName}
                onClick={() => {
                    inputData.current["inputEl"]!.focus();
                }}
            >
                <span className={`placeholder-text ${shadowTextClassName}`}>{placeholder}</span>
            </div>
        );
    }, [autoHolder, placeholder, shadowPlaceholderActive, shadowClassName, shadowTextClassName]);

    return (
        <div className={SelfBeautyInputClassNames} style={style} ref={inputBox}>
            <Input className={inPutClassName} onChange={inputChange} value={value} disabled={disabled} defaultValue={defaultValue} placeholder={realPlaceholder} {...extraProps} />
            {shadowPlaceholder}
        </div>
    );
};

interface SelfBeautyNumberInputProps extends InputNumberProps {
    stringValue?: string;
    autoHolder?: "holder-top";
    theme?: "danger-light" | "gray-light" | "red-light" | "green-light";
    inPutClassName?: string;
    zeroAsUndefined?: boolean;
    stepBtn?: "step-row" | "step-col";
    stepBtnType?: "step-btn-arrow" | "step-btn-add";
    alwaysStepBtn?: boolean;
    onChangedString?: (value: string) => void; // 和onChange只能留一個, 也沒必要同時支持
    onFocus?: (ev: any) => any;
    onBlur?: (ev: any) => any;
    tips?: React.ReactNode;
}

// 和SelfBeautyInput类似
export const SelfBeautyNumberInput = (props: SelfBeautyNumberInputProps) => {
    const {
        autoHolder = "holder-top",
        theme = "gray-light",
        inPutClassName,
        zeroAsUndefined = true,
        stepBtn,
        stepBtnType = "step-btn-add",
        alwaysStepBtn = true,
        onFocus: outFocus,
        onBlur: outBlur,
        placeholder,
        className,
        value,
        stringValue,
        onChange,
        onChangedString,
        disabled,
        defaultValue,
        min = 0,
        tips,
        ...extraProps
    } = props;

    const realValue: number | undefined = useMemo(() => {
        if (stringValue !== undefined && stringValue !== null) {
            return stringValue ? Number(stringValue) : undefined;
        }
        return zeroAsUndefined ? (value === 0 ? undefined : value) : value;
    }, [value, stringValue, zeroAsUndefined]);

    const SelfBeautyInputClassNames = useMemo(() => {
        return `self-beauty-input${theme ? ` ${theme}` : ""}${stepBtn ? ` ${stepBtn}` : ""}${stepBtnType ? ` ${stepBtnType}` : ""}${alwaysStepBtn ? ` ${"always-step-btn"}` : ""}${
            className ? ` ${className}` : ""
        }`;
    }, [theme, stepBtn, stepBtnType, alwaysStepBtn, className]);

    const realPlaceholder = useMemo(() => {
        if (autoHolder) {
            return undefined;
        }
        return placeholder;
    }, [autoHolder, placeholder]);

    const inputBox = useRef(null as HTMLDivElement | null);
    const inputData = useRef({} as InputDataProps);
    const [shadowPlaceholderActive, setShadowPlaceholderActive] = useState(false);

    const checkHolderActive = useCallback(
        (val: string | number | string[] | undefined) => {
            if (!autoHolder) {
                setShadowPlaceholderActive(false);
                return;
            }
            if (inputData.current["focus"]) {
                setShadowPlaceholderActive(true);
                return;
            }
            if (val === undefined || val === "" || val === null) {
                setShadowPlaceholderActive(false);
            } else {
                if (zeroAsUndefined && Number(val) === 0) {
                    setShadowPlaceholderActive(false);
                } else {
                    setShadowPlaceholderActive(true);
                }
            }
        },
        [autoHolder, zeroAsUndefined],
    );

    // 聚焦时显示提示入口
    const [tipsVisible, setTipsVisible] = useState(false);
    const onFocus = useCallback(
        (ev) => {
            outFocus?.(ev);
            setTipsVisible(true);
        },
        [outFocus],
    );
    const onBlur = useCallback(
        (ev) => {
            outBlur?.(ev);
            setTipsVisible(false);
        },
        [outBlur],
    );

    useEffect(() => {
        if (!inputData.current["inputEl"]) {
            const inputEl = inputBox.current!.querySelector("input");
            inputData.current["inputEl"] = inputEl;
        }

        const tempFoucs = (ev: FocusEvent) => {
            onFocus && onFocus(ev);

            if (!autoHolder) {
                return;
            }
            inputData.current["focus"] = true;
            checkHolderActive(realValue);
        };
        const tempBlur = (ev: FocusEvent) => {
            onBlur && onBlur(ev);

            if (!autoHolder) {
                return;
            }
            inputData.current["focus"] = false;
            checkHolderActive(realValue);
        };
        inputData.current["inputEl"]!.addEventListener("focus", tempFoucs, false);
        inputData.current["inputEl"]!.addEventListener("blur", tempBlur, false);

        return () => {
            inputData.current["inputEl"]!.removeEventListener("focus", tempFoucs);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            inputData.current["inputEl"]!.removeEventListener("blur", tempBlur);
        };
    }, [autoHolder, realValue, onFocus, onBlur, checkHolderActive]);

    const inputChange = useCallback(
        (val: string | number | undefined) => {
            if (onChangedString) {
                const parse = typeof val === "number" ? new SafeDecimal(val).toString() : "";
                onChangedString(parse);
            } else {
                onChange && onChange(val);
            }
            if (autoHolder) {
                checkHolderActive(val);
            }
        },
        [onChange, onChangedString, checkHolderActive, autoHolder],
    );

    useEffect(() => {
        if (autoHolder) {
            checkHolderActive(realValue);
        }
    }, [realValue, autoHolder, checkHolderActive]);

    const shadowPlaceholder = useMemo(() => {
        if (!autoHolder) {
            return null;
        }

        const placeholderClassName = `shadow-placeholder${autoHolder ? ` ${autoHolder}` : ""}${shadowPlaceholderActive ? ` active` : ""}`;
        return (
            <div
                className={placeholderClassName}
                onClick={() => {
                    inputData.current["inputEl"]!.focus();
                }}
            >
                <span className="placeholder-text">{placeholder}</span>
            </div>
        );
    }, [autoHolder, placeholder, shadowPlaceholderActive]);

    return (
        <div className={SelfBeautyInputClassNames} ref={inputBox}>
            <InputNumber
                className={inPutClassName}
                onChange={inputChange}
                value={realValue}
                disabled={disabled}
                defaultValue={defaultValue}
                placeholder={realPlaceholder}
                min={min}
                {...extraProps}
            />
            {shadowPlaceholder}
            {!!tips && tipsVisible && (
                <TTooltip overlay={tips} placement={"topRight"}>
                    <Iconfont icon={"icon_question"} className={"self-beauty-tips"} />
                </TTooltip>
            )}
        </div>
    );
};

interface SelfBeautyNumberInputMaxPrecisionProps extends SelfBeautyNumberInputProps {
    maxPrecision?: number;
    rmEndZeroWhenBlur?: true;
}

export const SelfBeautyNumberInputMaxPrecision = (props: SelfBeautyNumberInputMaxPrecisionProps) => {
    const {
        maxPrecision,
        parser,
        stepBtn,
        step,
        autoHolder = "holder-top",
        theme = "gray-light",
        zeroAsUndefined = true,
        stepBtnType = "step-btn-add",
        alwaysStepBtn = true,
        rmEndZeroWhenBlur = true,
        onFocus,
        onBlur,
        min = 0,
        formatter,
        ...extraProps
    } = props;

    const realParser = useCallback(
        (displayValue: string) => {
            const result = decStrNum(displayValue, maxPrecision);
            return parser ? parser(result) : result;
        },
        [maxPrecision, parser],
    );

    const realStep = useMemo(() => {
        return step === undefined && stepBtn ? precisionToStep(maxPrecision) : step;
    }, [step, stepBtn, maxPrecision]);

    const [isBlur, setIsBlur] = useState(true);
    const realOnFocus = useCallback(
        (ev) => {
            rmEndZeroWhenBlur && stepBtn && setIsBlur(false);
            onFocus && onFocus(ev);

            // 以下为光标跳到最后，如出问题打开
            // const el = ev.target;
            // const valLen = el.value.length;
            // el.setSelectionRange(valLen, valLen);
        },
        [onFocus, rmEndZeroWhenBlur, stepBtn],
    );
    const realOnBlur = useCallback(
        (ev) => {
            rmEndZeroWhenBlur && stepBtn && setIsBlur(true);
            onBlur && onBlur(ev);
        },
        [onBlur, rmEndZeroWhenBlur, stepBtn],
    );

    const realFormatter = useMemo(() => {
        if (!rmEndZeroWhenBlur || !isBlur || !stepBtn) {
            return formatter;
        }
        return (value: string | number | undefined) => {
            return formatter ? formatter(value) : value === undefined ? "" : isNaN(Number(value)) ? value.toString() : Number(value).toString();
        };
    }, [formatter, isBlur, rmEndZeroWhenBlur, stepBtn]);

    return (
        <SelfBeautyNumberInput
            stepBtn={stepBtn}
            parser={realParser}
            step={realStep}
            autoHolder={autoHolder}
            theme={theme}
            zeroAsUndefined={zeroAsUndefined}
            stepBtnType={stepBtnType}
            alwaysStepBtn={alwaysStepBtn}
            min={min}
            formatter={realFormatter}
            onFocus={realOnFocus}
            onBlur={realOnBlur}
            {...extraProps}
        />
    );
};
export default SelfBeautyNumberInputMaxPrecision;
