import React, { memo, useState } from "react";
import { Chart, Coord, Axis, Geom, Label } from "bizcharts";
import DataSet from "@antv/data-set";
import { SafeDecimal } from "trade_utils_lib";
import { $st } from "src/utils/i18n";
const { DataView } = DataSet;
const color = ["#FFA776", "#809CFF"];
const cols = {
    percent: {
        formatter: (val) => {
            val = new SafeDecimal(val * 100).toFixed(2) + "%";
            return val;
        },
    },
};
const SeprateChart = ({ tradeTotalUSD, financeTotalUSD }: { tradeTotalUSD: number; financeTotalUSD: number }) => {
    const [dv] = useState(() => new DataView());
    const data = [
        { item: $st("balance_nav_trade_account"), value: new SafeDecimal(tradeTotalUSD).toDecimalPlaces(4).toNumber() },
        { item: $st("balance_nav_finance_account"), value: new SafeDecimal(financeTotalUSD).toDecimalPlaces(4).toNumber() },
    ];
    dv.source(data).transform({
        type: "percent",
        field: "value",
        dimension: "item",
        as: "percent",
    });

    return (
        <Chart height={200} width={360} data={dv} scale={cols} padding={[5, 100, 5, 120]}>
            <Coord type={"theta"} radius={1} innerRadius={0.65} />
            <Axis name="percent" />
            <Geom
                type="intervalStack"
                position="percent"
                color={["item", color]}
                tooltip={[
                    "item*percent",
                    (item, percent) => {
                        percent = new SafeDecimal(percent * 100).toFixed(2) + "%";
                        return {
                            name: item,
                            value: percent,
                        };
                    },
                ]}
            >
                <Label
                    content="percent"
                    htmlTemplate={(text, item, index) => `<div style="color: ${color[index || 0]}; white-space: nowrap; font-size: 14px">${item.point.item}: ${text}</div>`}
                />
            </Geom>
        </Chart>
    );
};

export default memo(SeprateChart);
