import Animated from "animated/lib/targets/react-dom";
import { ExchangeSymbolData } from "landings/Trade/MarketLayout/Symbol/SymbolView";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CoinIcon } from "src/components/CoinIcon";
import { NumberCommonUtils } from "trade_utils_lib";
import { ExchangeSymbol } from "TradeAPILib";
import PionexClassName from "utils/PionexClassName";
import NumberUtils from "../../../../utils/NumberUtils";
import css from "./MarketItem.module.less";
import { useTradeDataProvider } from "../../TradeCtx";
import csn from "classnames";
import { IconFavorite16pxBold } from "@pionex-web-kit/icons";
import ZeroFee from "src/components-v2/Layout/ZeroFee";
import ExchangeRate from "state/ExchangeRate";
import ThemeData from "state/ThemeData";
import { useTranslators } from "commonUse/Locale";
import { useOnlineConfigFees } from "commonUse/useOnlineFees";
import { useTradeSelectedInfo } from "state/TradeSelectedInfo";
import { SideTabKey } from "landings/Trade/SideTab";

export interface MarketItemProps {
    exchange: string;
    symbol: ExchangeSymbolData;
    selected: boolean;
    exchangeRate: ExchangeRate;
    theme: ThemeData;
    hidePercent?: boolean;
    nano?: boolean;
    onSelectSymbol?: () => void;
    onCollectionChanged(symbol: ExchangeSymbol, collection: boolean);
    isDropdown?: boolean;
    targetPage?: SideTabKey;
}

export default function MarketItem(props: MarketItemProps) {
    const { symbol, selected, theme, onCollectionChanged, hidePercent, targetPage } = props;
    const { $stn } = useTranslators();
    const fee = useOnlineConfigFees();

    const [priceDisplay, changePriceDisplay] = useState({
        price: "",
        fiat: "",
    });

    const [quoteVol, changeQuoteVol] = useState("0");
    const [, changeExchangeSymbol] = useTradeSelectedInfo();
    const animatedStart = useRef(0);
    const opacityAnimatedValue = useRef(new Animated.Value(1));
    const breathAnimated = useRef<any>(null);

    const onFavorChanged = useCallback(
        (e) => {
            e.stopPropagation();
            onCollectionChanged(symbol, !symbol.favor);
        },
        [onCollectionChanged, symbol],
    );

    useEffect(() => {
        const price = symbol.ticker ? symbol.ticker.latest : 0;
        const pricePrecision = symbol.precision ? symbol.precision : 4;
        const fiat = symbol.ticker ? (symbol.ticker.fiat || 0) * props.exchangeRate.exchangeRate : 0;
        changePriceDisplay({
            price: $stn(price, { maximumFractionDigits: pricePrecision }),
            fiat: $stn(fiat, { minimumFractionDigits: 2 }),
        });
    }, [symbol?.ticker?.latest, symbol?.precision, symbol?.ticker, props.exchangeRate?.exchangeRate, $stn]);

    useEffect(() => {
        const quoteVol = symbol.ticker ? NumberUtils.toShortString(symbol.ticker.quoteVol, true) : "0";
        changeQuoteVol(quoteVol);
    }, [symbol?.ticker, symbol?.ticker?.quoteVol]);

    useEffect(() => {
        const now = Date.now();
        if (now - animatedStart.current >= 1200) {
            breathAnimated.current = Animated.sequence([
                Animated.timing(opacityAnimatedValue.current, {
                    toValue: 0.5,
                    duration: 400,
                }),
                Animated.timing(opacityAnimatedValue.current, {
                    toValue: 0.5,
                    duration: 100,
                }),
                Animated.timing(opacityAnimatedValue.current, {
                    toValue: 1,
                    duration: 500,
                }),
            ]);
            breathAnimated.current.start();
            animatedStart.current = now;
        }
    }, [priceDisplay.price]);

    useEffect(() => {
        return () => breathAnimated.current && breathAnimated.current.stop();
    }, []);

    const selectSymbol = useCallback(() => {
        changeExchangeSymbol(symbol as any, targetPage);
        if (props.onSelectSymbol) {
            props.onSelectSymbol();
        }
    }, [changeExchangeSymbol, props.onSelectSymbol, symbol]);

    const [isChangeUp, displayValue] = useMemo(() => {
        const changePercent = symbol.ticker && symbol.ticker.changePercent ? symbol.ticker.changePercent : 0;
        const displayValue = NumberCommonUtils.toPercent(changePercent * 100);
        const isChangeUp = changePercent >= 0;

        return [isChangeUp, displayValue];
    }, [symbol.ticker]);

    const animatedChangePercent = useMemo(() => {
        if (hidePercent) {
            return null;
        }
        return (
            // eslint-disable-next-line react/jsx-pascal-case
            <Animated.div className={`${css.tickerShow}`} style={{ opacity: opacityAnimatedValue.current }}>
                <div className={`${css.animatedContainer} ${isChangeUp ? PionexClassName.tradingUpBg : PionexClassName.tradingDownBg}`}>{displayValue}</div>
            </Animated.div>
        );
    }, [displayValue, isChangeUp, hidePercent]);
    const animatedChangePercentHidePercent = useMemo(() => {
        return (
            // eslint-disable-next-line react/jsx-pascal-case
            <Animated.div className={`${css.tickerShow}`} style={{ opacity: opacityAnimatedValue.current }}>
                <div className={`${css.animatedContainer} ${isChangeUp ? "!text-increase" : "!text-decrease"}  !justify-end`}>{displayValue}</div>
            </Animated.div>
        );
    }, [displayValue, isChangeUp]);

    const animatedChangePercentDefault = useMemo(() => {
        if (hidePercent) {
            return null;
        }
        return (
            // eslint-disable-next-line react/jsx-pascal-case
            <Animated.div className={`${css.tickerShowDefault}`} style={{ opacity: opacityAnimatedValue.current }}>
                <div className={`${css.animatedContainer} ${isChangeUp ? "!text-increase" : "!text-decrease"}`}>{displayValue}</div>
            </Animated.div>
        );
    }, [displayValue, isChangeUp, hidePercent]);

    const itemClass = csn(css.container, "symbol_item hover:cursor-pointer hover:bg-bg dark:hover:bg-drop-down", {
        "!border-l-primary bg-bg dark:bg-drop-down": selected,
        "dark:!bg-divider": selected && props.isDropdown,
        "dark:hover:!bg-divider": props.isDropdown,
    });

    return !props.nano ? (
        <div className={itemClass} onClick={selectSymbol}>
            <div className={css.symbolLayout}>
                <div className="relative" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    {<CoinIcon className={csn(css.exchangeIcon, "mr-8px")} isLeverage={symbol.isLeverage} target={symbol.base} size={16} />}
                    <div className={"text-accent text-sm mr-[2px]"}>{`${symbol.baseDisplay}/${symbol.quote}`}</div>
                    {fee.includes(symbol.symbol.toUpperCase()) && <ZeroFee className="absolute !px-[6px]  right-0  leading-[15px]  translate-x-full" />}
                </div>

                <div className={css.iconContainer}>
                    <IconFavorite16pxBold onClick={onFavorChanged} className={csn("w-16px h-16px fill-secondary-sub hover:cursor-pointer", { "!fill-warning": symbol.favor })} />
                    <div className={`${css.code} text-sm text-secondary`}>{quoteVol}</div>
                </div>
            </div>
            <div className={css.center_content}>
                <div className={`text-sm leading-[17px]  ${isChangeUp ? "text-increase" : "text-decrease"}`}>{priceDisplay.price}</div>
                {hidePercent ? (
                    animatedChangePercentHidePercent
                ) : (
                    <div className={"text-sm leading-[16px] mt-[3px] text-secondary"}>{`= ${priceDisplay.fiat} ${props.exchangeRate.currency}`}</div>
                )}
            </div>
            {animatedChangePercent}
        </div>
    ) : (
        <div className={itemClass} onClick={selectSymbol}>
            <div className={css.symbolLayout}>
                <div className="relative" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    {<CoinIcon className={csn(css.exchangeIcon, "mr-8px")} isLeverage={symbol.isLeverage} target={symbol.base} size={16} />}
                    <div className={"text-accent text-sm mr-[2px]"}>{`${symbol.baseDisplay}/${symbol.quote}`}</div>
                </div>
            </div>
            <div className={css.center_content}>
                <div className={`leading-[17px] text-sm ${isChangeUp ? "text-increase" : "text-decrease"}`}>{priceDisplay.price}</div>
            </div>
            {animatedChangePercentDefault}
        </div>
    );
}
