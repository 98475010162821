import { ST } from "commonUse/Locale";
import { message } from "antd";
import { DebitErrorType } from "utils/onlineConfig";

/**
 * Debit绑卡错误处理
 * @param $st
 * @param e
 */
export function processAddCardError($st: ST, e: any, errMap: { [key in DebitErrorType]: string }) {
    if (!!e?.data?.mcode) {
        const msg = errMap[e.data.mcode as DebitErrorType]?.replace("{message}", getErrMessageByString(e.message).message || "Unknown");
        // 是否有对应code的翻译
        message.error(msg || errMap.DEFAULT || $st("debit_add_card_error"));
    } else {
        message.error($st("debit_add_card_error"));
    }
    console.error(e);
}

function getErrMessageByString(error: string): { code?: number; message?: string } {
    try {
        let matchInfo = error.match(/\{.*\}$/);
        return Array.isArray(matchInfo) ? JSON.parse(matchInfo[0]) : {};
    } catch (error) {}

    return {};
}
