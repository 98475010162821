import { createMessage, encrypt, readKey } from "openpgp";
/**
 * Encrypt card data function
 */
export async function encryptDataWithPublicKey(dataToEncrypt: { [k: string]: any }, base64PublicKeyString: string) {
    // 解密 base64 编码，获取到真实的公钥字符串【包含页头和页脚的】。
    const armoredKey = atob(base64PublicKeyString);
    // 读取公钥数据，结构 Key
    const encryptionKeys = await readKey({ armoredKey });
    // 对数据主体（JSONString）进行加密
    const message = await createMessage({ text: JSON.stringify(dataToEncrypt) });
    // 调用加密算法
    const encryptedMessage = await encrypt({
        message,
        encryptionKeys,
    });
    const encryptedMessageWithBase64 = btoa(encryptedMessage.toString());
    return {
        encryptedMessage,
        encryptedMessageWithBase64,
    };
}
