import { Alert, Button, Card, message } from "@pionex-web-kit/components";
import { IconSuccess100pxBoldS } from "@pionex-web-kit/icons";
import { useDataProvider } from "commonUse";
import { useTranslators } from "commonUse/Locale";
import useAccountInfo from "commonUse/useAccountInfo";
import { AxiosV1Token, configV1Signature, useV1TradingToken } from "commonUse/useV1Token";
import { FilterAllType } from "landings/PreUpgradeHistory/BotHistory/BotOrders/hooks/useFetchOrders";
import { useCallback, useEffect, useState } from "react";
import { AuthType, getTradeDependencyInstance } from "trade_utils_lib";
import { history } from "utils/History";
import { getAccountPathname } from ".";
import { Link } from "react-router-dom";
import { ReactNativeWebView } from "utils/ReactNativeWebView";
import { report } from "utils/GoogleTagManager";

export default function Upgraded() {
    const { $st } = useTranslators();
    const v1Token = useV1TradingToken();
    const accountInfo = useAccountInfo();
    const dataProvider = useDataProvider(accountInfo.firstApiKey);
    const [manualOrdersCount, setManualOrdersCount] = useState(-1);
    const [borOrdersCount, setBotOrdersCount] = useState(-1);
    useEffect(() => {
        if (PLATFORM.PIONEX_US) {
            history.push(getAccountPathname("/account_update"));
        }
    }, []);
    useEffect(() => {
        if (!v1Token) return;
        AxiosV1Token(
            {
                url: `${getTradeDependencyInstance().getRunTimeHost().pionexSpotApiHost}/all_orders/`,
                params: {
                    limit: 20,
                    include_strategy: false,
                    state: "FILLED,CANCELED",
                },
            },
            v1Token?.tradingToken,
            (config) => configV1Signature(config, AuthType.Spot, v1Token),
        )
            .then((res) => {
                setManualOrdersCount(res?.data?.data?.length || 0);
            })
            .catch(() => {
                message.error("An error occurred. Please click back and re-enter this page.", 10);
                report("v1_v2_error_occurred", {
                    type: "manual_orders",
                });
            });
    }, [v1Token]);

    useEffect(() => {
        if (!v1Token) return;
        AxiosV1Token(
            {
                url: `${getTradeDependencyInstance().getRunTimeHost().TRADE_HOST}/orders/v2/`,
                params: {
                    bu_order_types: FilterAllType.toString(),
                    exchange: dataProvider?.api?.market,
                    status: "finished",
                },
            },
            v1Token?.awsToken,
        )
            .then((res) => {
                setBotOrdersCount(res?.data?.data?.results?.length || 0);
            })
            .catch(() => {
                message.error("An error occurred. Please click back and re-enter this page.",10);
                report("v1_v2_error_occurred", {
                    type: "bot_orders",
                });
            });
    }, [v1Token, dataProvider]);

    const toOrderHistory = useCallback((url: string) => {
        if (ReactNativeWebView.shared.isWebView()) {
            message.info("Please check it in Account > Account Update on the web.");
            return;
        }
        history.push(url);
    }, []);
    return (
        <div className=" w-full mx-auto max-w-[448px] flex flex-col items-center">
            <div className="flex flex-col items-center mt-40px mb-16px">
                <IconSuccess100pxBoldS size={100} />
                <div className=" text-center text-[28px] text-text-100 font-semibold"> Upgrade completed</div>
            </div>

            <div className=" text-base text-[#F04848] font-m">
                The account upgrade is completed. Please pay special attention to the fact that the deposit address has been changed. Here is the updated address:
            </div>

            <div className=" w-full flex flex-col mt-16px">
                <li className=" list-disc text-primary text-base font-m">
                    <Link className="text-primary hover:text-primary" to="/balance/deposit">
                        deposit address
                    </Link>
                </li>
                <li className=" list-disc text-primary text-base font-m">
                    <Link className="text-primary hover:text-primary" to="/balance/withdraw">
                        withdrawal address
                    </Link>
                </li>
            </div>

            <Alert
                className="mt-16px"
                type="warning"
                message={
                    <>
                        <li className="list-disc"> {$st("recreate_closed_orders")}</li>
                        <li className="list-disc">
                            Here is the upgrade guide{" "}
                            <a
                                onClick={() => {
                                    report("account_update_link");
                                }}
                                style={{ color: "inherit", textDecoration: "underline" }}
                                target="_blank"
                                href="https://www.pionex.us/blog/guide-for-account-upgrade/"
                            >
                                link
                            </a>
                            .
                        </li>
                        <li className="list-disc">
                            If you have any questions, feel free to contact 24/7{" "}
                            <a
                                onClick={() => {
                                    report("account_update_support");
                                }}
                                style={{ color: "inherit", textDecoration: "underline" }}
                                target="_blank"
                                href="https://static.zdassets.com/web_widget/latest/liveChat.html?v=10#key=pionexus.zendesk.com"
                            >
                                customer support
                            </a>{" "}
                            or email service@pionex.us.
                        </li>
                    </>
                }
            />
            <Card className="w-full mt-16px">
                <div className=" flex flex-col gap-24px">
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col flex-1 gap-4px">
                            <div className="text-base font-m text-text-100">{manualOrdersCount > 0 ? $st("recreate_manual_orders") : "Manual history"}</div>
                            {manualOrdersCount > 0 && <div className=" text-sm text-text-300">{manualOrdersCount > 10 ? "More than 10" : manualOrdersCount} orders</div>}
                        </div>
                        <Button
                            onClick={() => {
                                report("recreate_manual");
                                history.push("/pre_upgrade_history/manual");
                            }}
                            type="primary"
                            shape="round"
                            className="ml-16px w-[100px]"
                        >
                            {manualOrdersCount === 0 ? "View" : $st("recreate")}
                        </Button>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col flex-1 gap-4px">
                            <div className="text-base font-m text-text-100">{borOrdersCount > 0 ? $st("recreate_bot_orders") : "Bots history"}</div>
                            {borOrdersCount > 0 && <div className=" text-sm text-text-300">{borOrdersCount > 10 ? "More than 10" : borOrdersCount} orders</div>}
                        </div>
                        <Button
                            onClick={() => {
                                report("recreate_bot");
                                history.push("/pre_upgrade_history/bots");
                            }}
                            type="primary"
                            shape="round"
                            className="ml-16px w-[100px]"
                        >
                            {borOrdersCount === 0 ? "View" : $st("recreate")}
                        </Button>
                    </div>


                    <div className="flex items-center justify-between">
                        <div className="flex flex-col flex-1 gap-4px">
                            <div className="text-base font-m text-text-100">Crypto history</div>
                            <div className=" text-sm text-text-300"></div>
                        </div>
                        <Button
                            onClick={() => {
                                toOrderHistory("/pre_upgrade_history/crypto");
                            }}
                            type="secondary"
                            shape="round"
                            className="ml-16px w-[100px]"
                        >
                            View
                        </Button>
                    </div>


                    <div className="flex items-center justify-between">
                        <div className="flex flex-col flex-1 gap-4px">
                            <div className="text-base font-m text-text-100">Convert history</div>
                            <div className=" text-sm text-text-300"></div>
                        </div>
                        <Button
                            onClick={() => {
                                toOrderHistory("/pre_upgrade_history/convert");
                            }}
                            type="secondary"
                            shape="round"
                            className="ml-16px w-[100px]"
                        >
                            View
                        </Button>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col flex-1 gap-4px">
                            <div className="text-base font-m text-text-100">Buy Crypto history</div>
                            <div className=" text-sm text-text-300"></div>
                        </div>
                        <Button
                            onClick={() => {
                                toOrderHistory("/pre_upgrade_history/buy-crypto");
                            }}
                            type="secondary"
                            shape="round"
                            className="ml-16px w-[100px]"
                        >
                            View
                        </Button>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col flex-1 gap-4px">
                            <div className="text-base font-m text-text-100">Bank Account history</div>
                            <div className=" text-sm text-text-300"></div>
                        </div>
                        <Button
                            onClick={() => {
                                toOrderHistory("/pre_upgrade_history/ach");
                            }}
                            type="secondary"
                            shape="round"
                            className="ml-16px w-[100px]"
                        >
                            View
                        </Button>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex flex-col flex-1 gap-4px">
                            <div className="text-base font-m text-text-100">Debit Card history</div>
                            <div className=" text-sm text-text-300"></div>
                        </div>
                        <Button
                            onClick={() => {
                                toOrderHistory("/pre_upgrade_history/debit");
                            }}
                            type="secondary"
                            shape="round"
                            className="ml-16px w-[100px]"
                        >
                            View
                        </Button>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="flex flex-col flex-1 gap-4px">
                            <div className="text-base font-m text-text-100">Wire Transfer history</div>
                            <div className=" text-sm text-text-300"></div>
                        </div>
                        <Button
                            onClick={() => {
                                toOrderHistory("/pre_upgrade_history/wire");
                            }}
                            type="secondary"
                            shape="round"
                            className="ml-16px w-[100px]"
                        >
                            View
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
}
