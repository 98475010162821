import "animate.css/animate.min.css";
import useTheme from "commonUse/useTheme";
import React from "react";
import "react-html5video/dist/styles.css";
import { LayoutConfig } from "src/components-v2/Layout";
import DownloadApp from "./DownloadGoogleAuth";
import ThemeData from "state/ThemeData";

const Download = () => {
    const theme: ThemeData = useTheme();
    return (
        <LayoutConfig contentFullWidthOnly whiteBg>
            <DownloadApp showQRCode titleColor={theme.colorText6} />
        </LayoutConfig>
    );
};

export default Download;
