import { message, Modal } from "@pionex-web-kit/components";
import { NOTIFY_ORDER_CANCEL_SUCCESS } from "landings/Trade/PUBSUB_EVENT";
import PubSub from "pubsub-js";
import { ReactElement } from "react";

import { Observable, of, zip } from "rxjs";
import { catchError, flatMap, map, mergeMap, tap } from "rxjs/operators";
import { ExchangeOrder, ExchangeOrderStatus, ExchangeOrderType, ExchangeTicker, OrderTypeDesc, SmartRebalanceSubType } from "TradeAPILib";
import ExchangeDataProvider from "TradeLib/ExchangeDataProvider";
import { APIKeyInfo } from "TradeLib/TradeTypes";
import { $st } from "utils/i18n";

import { getUserGridOrderCloseType } from "utils/userInfo";
import { RetryOrderTools } from "./RetryOrderTools";

export interface OrderCancelProps {
    title: string;
    content: string | ReactElement;
    okText?: string;
    cancelText?: string;
    onOk?: () => void;
    onCancel?: () => void;
    onCancelSuccess?(result: string): void;
    apiKey?: APIKeyInfo;
    order?: ExchangeOrder;
}

export interface OrderCheckRestoreResult {
    result: boolean;
    msg?: string;
}

export const OrderUtils = {
    getOrderDesc(orderType: string, subType?: SmartRebalanceSubType) {
        if ([OrderTypeDesc.GRID, OrderTypeDesc.GRID_V3, OrderTypeDesc.GRID_V4].includes(orderType)) {
            return $st("bot_type_grid");
        } else if (orderType === OrderTypeDesc.STOP_LIMIT) {
            return $st("bot_type_stop_limit");
        } else if (orderType === OrderTypeDesc.TRAILING_STOP_LIMIT) {
            return $st("bot_type_trailing_stop");
        } else if (orderType === OrderTypeDesc.AIP) {
            return $st("aip_order_combine");
        } else if (orderType === OrderTypeDesc.TWAP) {
            return $st("twap_order_combine");
        } else if (orderType === OrderTypeDesc.IG) {
            return $st("ig_title_desc");
        } else if (orderType === OrderTypeDesc.GRID_CLASSIC) {
            return $st(`${orderType}_title_desc`);
        } else if (orderType === OrderTypeDesc.GRID_PRO) {
            return $st(`${orderType}_title_desc`);
        } else if (orderType === OrderTypeDesc.GRID_LOAN) {
            return $st(`${orderType}_title_desc`);
        } else if (orderType === OrderTypeDesc.GRID_SHORT) {
            return $st(`${orderType}_title_desc`);
        } else if (orderType === OrderTypeDesc.GRID_LEVERAGE) {
            return $st(`${orderType}_title_desc`);
        } else if (orderType === OrderTypeDesc.GRID_LEVERAGE_SHORT) {
            return $st(`${orderType}_title_desc`);
        } else if (orderType === OrderTypeDesc.GRID_FUTURE) {
            return $st(`${orderType}_title_desc`);
        } else if (orderType === OrderTypeDesc.SL_PRO) {
            return $st("bot_type_stop_limit");
        } else if (orderType === OrderTypeDesc.MARKET) {
            return $st("order_type_market");
        } else if (orderType === OrderTypeDesc.LIMIT) {
            return $st("order_type_limit");
        } else if (orderType === OrderTypeDesc.SMART_TRADE) {
            return $st("order_type_smart_trade");
        } else if (orderType === ExchangeOrderType.trailingBuy) {
            return $st("bot_house_card_trailing_buy");
        } else if (orderType === ExchangeOrderType.trailingSell) {
            return $st("bot_house_card_trailing_sell");
        } else if (orderType === ExchangeOrderType.fsa || orderType === ExchangeOrderType.cm_fsa) {
            return $st("bot_house_card_ct_arbitraging");
        } else if (orderType === ExchangeOrderType.SR) {
            return $st("trading_smart_rebalance");
        } else if (orderType === ExchangeOrderType.martingale) {
            return $st("martingale_bot");
        } else if (orderType === ExchangeOrderType.SC) {
            return $st("smart_copy");
        }
        return orderType;
    },

    getRecoverOrderTipMsg(errMsg: string | undefined, base: string, quote: string) {
        if (errMsg === "error_balance_not_enough_in_run_time" || errMsg === "not_enough_balance") {
            return $st("bot_recover_tip_balance", { quote: quote.toUpperCase() });
        } else if (errMsg === "error_user_cancel_grid_order") {
            return $st("bot_recover_tip_user_cancel");
        } else if (errMsg === "invalid_api_key") {
            return $st("bot_recover_tip_invalid_api");
        } else {
            return $st("bot_recover_tip_default");
        }
    },

    cancelOrder(apiKey: APIKeyInfo, order: ExchangeOrder, info?: any) {
        return of(apiKey)
            .pipe(
                flatMap((keyInfo) => {
                    if (keyInfo) {
                        return ExchangeDataProvider.getExchangeDataProvider(keyInfo).pipe(
                            mergeMap((exchangeDataProvider) => {
                                return exchangeDataProvider.api.cancelOrderObservable(order, info);
                            }),
                        );
                    } else {
                        throw Error();
                    }
                }),
            )
            .pipe(
                tap(() => {
                    message.success($st("cancel_order_success"));
                    PubSub.publishSync(NOTIFY_ORDER_CANCEL_SUCCESS, { orderId: order.id });
                }),
            )
            .pipe(
                catchError((err) => {
                    if (err.code === -30002 && err.message === "invalid bu_order status") {
                        message.error(`${$st("order_cancel_error")}`);
                    } else if (err.message !== undefined) {
                        message.error(`${$st("cancel_order_failed")} ${JSON.stringify(err.message)}`);
                    } else if (err.response !== undefined && err.response.message !== undefined) {
                        message.error(`${$st("cancel_order_failed")} ${JSON.stringify(err.response.message)}`);
                    } else {
                        message.error(`${$st("cancel_order_failed")}`);
                    }
                    return of("");
                }),
            );
    },

    interruptFsaAdjustPosition(apiKey: APIKeyInfo, order: ExchangeOrder) {
        return of(apiKey)
            .pipe(
                flatMap((keyInfo) => {
                    if (keyInfo) {
                        return ExchangeDataProvider.getExchangeDataProvider(keyInfo).pipe(
                            mergeMap((exchangeDataProvider) => {
                                return exchangeDataProvider.api.interruptFsaAdjustPosition(order.orderType, order.id);
                            }),
                        );
                    } else {
                        throw Error();
                    }
                }),
            )
            .subscribe(
                (result) => {
                    if (result === order.id) {
                        if (order.exchangeFSAOrder?.addingPosition) {
                            message.info($st("fsa_order_open_position_interrupt_success"));
                        } else if (order.exchangeFSAOrder?.reducingPosition) {
                            message.info($st("fsa_order_reduce_position_interrupt_success"));
                        } else {
                            message.info($st("fsa_order_adjust_position_interrupt_success"));
                        }
                    }
                },
                (err) => {
                    message.info(err.message);
                },
            );
    },

    /**
     * 恢复订单逻辑
     * @param apiKey
     * @param order
     */
    restoreOrder(order: ExchangeOrder, apiKey?: APIKeyInfo) {
        const apiKeyInfo: APIKeyInfo = apiKey || {
            exchange: order.exchange || "",
            key_id: order.key_id || "",
            alias: "",
        };
        let exchangeDataProvider: ExchangeDataProvider;
        return of(apiKeyInfo)
            .pipe(
                flatMap((apiKey) => {
                    if (!apiKey.exchange || !apiKey.key_id) {
                        throw "api key error: " + JSON.stringify(apiKey);
                    }
                    return ExchangeDataProvider.getExchangeDataProvider(apiKey);
                }),
            )
            .pipe(
                flatMap((provider) => {
                    exchangeDataProvider = provider;
                    return this.checkRestoreOrder(exchangeDataProvider, order);
                }),
            )
            .pipe(
                flatMap((result: OrderCheckRestoreResult) => {
                    if (result.result) {
                        return exchangeDataProvider.api.recoverGridOrder(order.id);
                    } else {
                        throw result.msg || "unknow error";
                    }
                }),
            )
            .pipe(
                map((result) => {
                    if (!result) {
                        throw "-1";
                    }
                }),
            )
            .pipe(
                catchError((error) => {
                    if (error.message) {
                        throw JSON.stringify(error.message);
                    }
                    throw error;
                }),
            );
    },

    checkRestoreOrder(provider: ExchangeDataProvider, order: ExchangeOrder): Observable<OrderCheckRestoreResult> {
        switch (order.orderType) {
            case OrderTypeDesc.GRID:
                return RetryOrderTools.checkGridOrderRestore(order, provider);
            case OrderTypeDesc.AIP:
                return RetryOrderTools.checkAIPOrderRestore(order, provider);
            case OrderTypeDesc.TWAP:
                return RetryOrderTools.checkTWAPOrderRestore(order, provider);
            default:
                return of({ result: false, msg: "not support recover" });
        }
    },

    cancelAllOrder(apiKey: APIKeyInfo, orderList: Array<ExchangeOrder>) {
        return of(apiKey)
            .pipe(
                flatMap((keyInfo) => {
                    if (keyInfo) {
                        return ExchangeDataProvider.getExchangeDataProvider(keyInfo).pipe(
                            mergeMap((exchangeDataProvider) => {
                                const obserableList = orderList.map((orderInfo) => {
                                    return exchangeDataProvider.api.cancelOrderObservable(orderInfo);
                                });

                                return zip(...obserableList);
                            }),
                        );
                    } else {
                        throw Error();
                    }
                }),
            )
            .pipe(
                tap(() => {
                    message.success($st("cancel_order_success"));
                }),
            )
            .pipe(
                catchError((err) => {
                    message.error(`${$st("cancel_order_failed")} ${JSON.stringify(err.response.message)}`);
                    return of("");
                }),
            );
    },

    wrapOrderCancelConfirmModal(param: OrderCancelProps) {
        Modal.confirm({
            mobileSwitch: "drawer",
            title: param.title,
            content: param.content,
            okText: param.okText || $st("button_confirm"),
            cancelText: param.cancelText || $st("button_discard"),
            onOk:
                param.onOk ||
                (async () => {
                    if (param.apiKey && param.order) {
                        let info: any = {};
                        if (
                            param.order.orderType === OrderTypeDesc.GRID_CLASSIC ||
                            param.order.orderType === OrderTypeDesc.GRID_PRO ||
                            param.order.orderType === OrderTypeDesc.GRID_LOAN ||
                            param.order.orderType === OrderTypeDesc.GRID_SHORT ||
                            param.order.orderType === OrderTypeDesc.GRID_LEVERAGE
                        ) {
                            info = {
                                convert_into_earn_coin: !!getUserGridOrderCloseType(),
                            };
                        }
                        const result = await OrderUtils.cancelOrder(param.apiKey, param.order, info).toPromise();
                        param.onCancelSuccess?.(result);
                        return result;
                    }
                    return new Promise((resolve, reject) => {
                        reject(Error(""));
                    });
                }),
            onCancel: param.onCancel || (() => {}),
        });
    },

    stateToDisplayText(state: ExchangeOrderStatus) {
        switch (state) {
            case "submitted":
                return $st("order_state_submitted");
            case "canceling":
                return $st("order_state_canceling");
            case "canceled":
            case "closed":
                return $st("order_state_canceled");
            case "filled":
                return $st("order_state_filled");
            case "partial_filled":
                return $st("order_state_partial_filled");
            default:
                return state;
        }
    },

    /**
     * 不同的订单类型过滤出需要的ticker
     * @param tickerMap
     * @param order
     */
    filterOrderTicker(tickerMap: Map<string, ExchangeTicker>, order: ExchangeOrder): ExchangeTicker[] {
        if (order.orderType === ExchangeOrderType.SR || order.orderType === ExchangeOrderType.martingale || order.orderType === ExchangeOrderType.SC) {
            let baseTickers: ExchangeTicker[] = [];

            const coins: { base: string }[] = order.exchangeSROrder?.coins || order.exchangeMTGOrder?.coins || order.exchangeSCOrder?.coins || [];
            coins?.forEach((item1) => {
                const tempTicker = tickerMap.get(`${order.exchange}/${item1.base}/${order.quote}`);
                if (tempTicker !== undefined) {
                    baseTickers.push(tempTicker);
                }
            });

            return baseTickers;
        }

        return Array.from(tickerMap.values());
    },

    /**
     * 获取订单显示的币种icon
     * @param order
     */
    getIconBase(order: ExchangeOrder): string {
        if (order.orderType === ExchangeOrderType.martingale) {
            return order.exchangeMTGOrder?.coins[0].base ?? "";
        }
        return order.base;
    },
};
