import React, { useEffect, useMemo } from "react";
import { useTranslators } from "commonUse/Locale";
import { resetPageTitleAndDescription } from "utils/Utils";
import { Iconfont } from "src/components/v2/TinyComps";
import WalletContainer from "../WalletContainer";
import DepositWithdraw, { DWType } from "src/landings/DepositWithdraw";
import { useHistory, useParams } from "react-router";
import AddressWrap, { IAddressListProps } from "src/landings/V2/Address";
import Taxes from "../Taxes";
import PreUpgrade from "../PreUpgrade";
import {
    IconNavAboutUserAgreement,
    IconNavReceiveCrypto24pxRegular,
    IconNavSendCrypto24pxRegular,
    IconTradingAccount,
    IconWindowaccountup24pxRegular,
} from "@pionex-web-kit/icons";
import { ProLayout } from "@pionex-web-kit/pro-components";
import { useOpenHelpCenter } from "commonUse";
import { useACHActivity } from "landings/V2/CircleACH/utils";
import AccountUpdate from "landings/AccountUpdate";
import { useIsUserFromV1, useShowUpgradeEntrance } from "commonUse/useV1Token";
import DataReporter from "landings/Analytics/DataReporter";
import { DepositUSD } from "landings/Balance/components/DepositUSD";
import { report } from "utils/GoogleTagManager";
import useAccountInfo from "commonUse/useAccountInfo";
import DateFormatter from "state/DateFormatter";
import useKycV2Info from "commonUse/useKycInfoV2";
import { useKycV2Rights } from "commonUse/useKycRightsV2";

export enum AccountNavKeys {
    account_all = "account_all",
    overview = "overview",
    address_book = "address_book",
    trade = "trade",
    finance = "finance",
    pre_upgrade = "pre_upgrade",
    account_update = "account_update",
}

export enum TaxesType {
    taxes = "taxes",
}

enum DWTypeExtends {
    depositUsd = "depositUsd",
    // withdrawUsd = "withdrawUsd",
}
export enum DepositType {
    ach = "ach",
    wire = "wire",
    debit = "debit",
}

export default function Root() {
    const { $st } = useTranslators();
    const openHelpCenter = useOpenHelpCenter();
    const history = useHistory();
    const activity = useACHActivity("deposit");

    const { menuType: activeTab, subType } = useParams<{ menuType: AccountNavKeys | DWType | TaxesType | DWTypeExtends | DepositType; subType?: string }>();

    const isUserFromUsV1 = useIsUserFromV1();
    const [showUpgradeEntrance] = useShowUpgradeEntrance();

    React.useEffect(() => {
        window.document.title = $st("balance_page_title");
        return () => resetPageTitleAndDescription($st);
    }, [$st]);

    useEffect(() => {
        if (activeTab === AccountNavKeys.account_update) {
            DataReporter.report("account_update_bar");
        } else if (activeTab === DepositType.ach) {
            report("sidebar_deposit_account");
        } else if (activeTab === DepositType.wire) {
            report("sidebar_deposit_wiretransfer");
        } else if (activeTab === DepositType.debit) {
            report("sidebar_deposit_debitcard");
        } else if (activeTab === DWType.deposit) {
            report("sidebar_receive_crypto");
        } else if (activeTab === DWType.withdraw) {
            report("sidebar_send_crypto");
        }
    }, [activeTab]);

    if (!activeTab) {
        history.replace(`/balance/${AccountNavKeys.trade}`);
        return null;
    }

    let content: any = null;
    switch (activeTab) {
        case AccountNavKeys.address_book:
            content = <AddressWrap type={subType as IAddressListProps["type"]} />;
            break;
        // case DWTypeExtends.withdrawUsd:
        //     content = (
        //         <div className="bg-card">
        //             <SelfFBOWithdraw />
        //         </div>
        //     );
        //     break;
        case DWTypeExtends.depositUsd:
            break;
        case DWType.withdraw:
        case DWType.deposit:
            content = <DepositWithdraw type={activeTab} coin={subType || "USDTERC20"} />;
            break;
        case TaxesType.taxes:
            content = <Taxes />;
            break;
        case DepositType.ach:
            content = <DepositUSD type={DepositType.ach} />;
            break;
        case DepositType.wire:
            content = <DepositUSD type={DepositType.wire} />;
            break;
        case DepositType.debit:
            content = <DepositUSD type={DepositType.debit} />;
            break;
        case AccountNavKeys.pre_upgrade:
            content = <PreUpgrade />;
            break;
        case AccountNavKeys.account_update:
            content = <AccountUpdate />;
            break;

        default:
            content = <WalletContainer activeTab={activeTab === AccountNavKeys.account_all ? AccountNavKeys.overview : activeTab} />;
            break;
    }

    return (
        <ProLayout
            menus={[
                {
                    icon: <Iconfont icon={"icon_Account"} className="!text-xl" />,
                    name: $st("balance_portfolio"),
                    path: `/balance/${AccountNavKeys.trade}`,
                },
                {
                    icon: <Iconfont icon={"icon_Menu_Deposit"} className="!text-xl" />,
                    key: DWType.deposit,
                    name: $st("circledw_deposit_title"),
                    children: [
                        {
                            name: (
                                <span>
                                    {$st("nav_title_usd_deposit_ach")} <img style={{ width: 48, height: 16 }} src={require("../../../../assets/0_fee.png")} alt="" />
                                </span>
                            ) as any,
                            path: `/balance/${DepositType.ach}`,
                        },
                        {
                            name: $st("nav_title_usd_deposit_debit"),
                            path: `/balance/${DepositType.debit}`,
                        },
                        {
                            name: $st("nav_title_usd_deposit_transfer"),
                            path: `/balance/${DepositType.wire}`,
                        },
                    ],
                },
                {
                    icon: <IconNavReceiveCrypto24pxRegular size={20} />,
                    name: $st("balance_receive_crypto"),
                    path: `/balance/${DWType.deposit}`,
                },
                {
                    icon: <IconNavSendCrypto24pxRegular size={20} />,
                    name: $st("balance_send_crypto"),
                    path: `/balance/${DWType.withdraw}`,
                },
                {
                    icon: <IconNavAboutUserAgreement size={20} />,
                    path: `/balance/${AccountNavKeys.address_book}`,
                    name: $st("address_book_title"),
                },
                ...(PLATFORM.PIONEX_US_V2 && isUserFromUsV1
                    ? [
                          {
                              icon: <IconTradingAccount size={20} />,
                              key: "taxes",
                              name: $st("taxes"),
                              children: [
                                  {
                                      name: $st("post_upgrade"),
                                      path: `/balance/${TaxesType.taxes}`,
                                  },
                                  {
                                      name: $st("pre_upgrade"),
                                      path: `/balance/pre_upgrade`,
                                  },
                              ],
                          },
                          {
                              icon: <IconWindowaccountup24pxRegular size={20} />,
                              path: `/balance/${AccountNavKeys.account_update}/upgraded`,
                              name: $st("account_update"),
                          },
                      ]
                    : [
                          {
                              icon: <IconTradingAccount size={20} />,
                              name: $st("taxes"),
                              path: `/balance/${TaxesType.taxes}`,
                          },
                      ]),
                ...(showUpgradeEntrance
                    ? [
                          {
                              icon: <IconWindowaccountup24pxRegular size={20} />,
                              path: `/balance/${AccountNavKeys.account_update}`,
                              name: $st("account_update"),
                          },
                      ]
                    : []),
            ]}
            openHelpCenter={openHelpCenter}
        >
            <div className="min-h-[calc(100vh_-_60px)]">{content}</div>
        </ProLayout>
    );
}

export const BalancePath = {
    DEPOSIT: "/balance/deposit",
    WITHDRAW: "/balance/withdraw",
    PORTFOLIO: "/balance/trade",
    BANK: "/balance/ach",
    BANKWITHDRAW: "/balance/ach/withdraw",
};
