import { Modal, message } from "@pionex-web-kit/components";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useTranslators } from "commonUse/Locale";
import { LocaleIPData } from "commonUse/tools";
import useKycV2Info from "commonUse/useKycInfoV2";
import { requestIpLoc } from "landings/V2/CircleACH/AgreementModal";
import { useCallback, useEffect, useMemo, useState } from "react";
import { timer } from "rxjs";
import { mergeMap } from "rxjs/operators";
import cs from "./cs.m.less";
import csn from "classnames";
import { useStripeProvider } from "./StripeProvider";
import { cancelPayment } from "./StripeAPI";
import moment from "moment-timezone";
import { IconInfo16pxBoldS } from "@pionex-web-kit/icons";
import DataReporter from "landings/Analytics/DataReporter";
import { useLocation } from "react-router";

function StripeACHModal({ onClose }: { onClose: () => void }) {
    const stripe = useStripe();
    const elements = useElements();

    const { paymentInfo } = useStripeProvider();
    const orderId = paymentInfo?.order_id;
    const [visible, setVisible] = useState<boolean>(true);
    const [stripeConfirmVisible, setStripeConfirmVisible] = useState<boolean>();

    const onOk = useCallback(
        async (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (!stripe || !elements) {
                return;
            }
            elements
                .submit()
                .then((res) => {
                    if (!res.error) {
                        setVisible(false);
                        setStripeConfirmVisible(true);
                    }
                })
                .catch(() => {});
        },
        [elements, stripe],
    );
    const onCancel = useCallback(() => {
        cancelPayment({ order_id: orderId }).subscribe({
            next: () => {},
            error: () => {},
        });
        setVisible(false);
        setTimeout(() => {
            onClose();
        }, 1000);
    }, [onClose, orderId]);
    const openPaymentElement = useCallback(() => {
        setVisible(true);
        setStripeConfirmVisible(false);
    }, []);
    return (
        <>
            <Modal maskClosable={false} keyboard={false} title=" " visible={visible} onCancel={onCancel} onOk={onOk}>
                <div className="min-h-[300px]">
                    <form>
                        <PaymentElement />
                    </form>
                </div>
            </Modal>
            {stripeConfirmVisible && <StripeACHConfirm onClose={openPaymentElement} />}
        </>
    );
}

export function StripeACHConfirm({ onClose }: { onClose: () => void }) {
    const [visible, setVisible] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const stripe = useStripe();
    const elements = useElements();
    const { paymentInfo } = useStripeProvider();
    const orderId = paymentInfo?.order_id;
    const channel_order_id = paymentInfo?.channel_order_id;
    const { amount, coin } = useStripeProvider();
    const { pathname } = useLocation();

    const [{ originData }] = useKycV2Info();

    const { $st } = useTranslators();

    const [ipInfo, setIpInfo] = useState<LocaleIPData>();
    const { ip } = ipInfo ?? {};

    useEffect(() => {
        // 每2分钟自动刷新一次时间
        const subscription = timer(0, 2 * 60 * 1000)
            .pipe(mergeMap(() => requestIpLoc()))
            .subscribe(setIpInfo);
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    const onOk = useCallback(() => {
        if (!stripe || !elements) {
            return;
        }
        if (pathname.indexOf("/usdDW/ach/deposit") > -1) {
            DataReporter.report("ach_stripe_web_deposit");
        } else {
            DataReporter.report("ach_stripe_web_buy_crypto");
        }
        setLoading(true);
        stripe
            .confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}/buy-crypto-completed?order_id=${orderId}&channel_order_id=${channel_order_id}${coin ? `&coin=${coin}` : ""}`,
                },
            })
            .then((result) => {
                setLoading(false);
                if (result.error) {
                    // 支付失败或者有错误，这时候你可以更新你的 UI 来显示错误信息
                    message.error(result.error.message);
                } else {
                    // 支付已经成功完成，这时你可以更新你的 UI 来显示成功信息
                }
            })
            .catch((error) => {
                setLoading(false);
                message.error(JSON.stringify(error));
                // 这里处理任何其他可能出现的错误
            });
    }, [channel_order_id, elements, orderId, stripe]);
    const onCancel = useCallback(() => {
        setVisible(false);
    }, []);
    const timeStr = useMemo(() => {
        return `${moment.tz("Etc/GMT+5").format("MM/DD/YYYY HH:mm")} (EST)`;
    }, []);
    return (
        <>
            <Modal
                maskClosable={false}
                keyboard={false}
                okButtonProps={{ loading }}
                afterClose={onClose}
                title="Authorization"
                visible={visible}
                onCancel={onCancel}
                onOk={onOk}
                okText={$st("ach_agreement_ok")}
                destroyOnClose
            >
                <div className={"py-12px px-[24px] max-h-[600px] overflow-auto"}>
                    <div>
                        I authorize Stripe or its affiliate, on behalf of{" "}
                        <a href="https://www.pionex.us" target="_blank" rel="noreferrer">
                            Pionex.US
                        </a>
                        , to electronically debit my account (and, if necessary, electronically credit my account) at the financial institution indicated by me as follows:
                    </div>
                    <div className={csn(cs.agreementItem, "border-0 border-b border-dashed border-divider")}>
                        <div>{$st("ach_agreement_amount")}:</div>
                        <div>{amount} USD</div>
                    </div>
                    <div className={csn(cs.agreementItem, "border-0 border-b border-dashed border-divider")}>
                        <div>{$st("running_fee")}:</div>
                        <div>0 USD</div>
                    </div>
                    <div className={csn(cs.agreementItem, "border-0 border-b border-dashed border-divider")}>
                        <div>{$st("you_will_get")}:</div>
                        <div>{amount} USD</div>
                    </div>
                    <div className={csn(cs.agreementItem, "border-0 border-b border-dashed border-divider")}>
                        <div>{$st("ach_agreement_purpose")}:</div>
                        <div>
                            To purchase and trade cryptocurrency on{" "}
                            <a href="https://www.pionex.us" target="_blank" rel="noreferrer">
                                Pionex.US
                            </a>
                        </div>
                    </div>

                    <div className={csn(cs.agreementItem, "border-0 border-b border-dashed border-divider")}>
                        <div>{$st("ach_agreement_name")}:</div>
                        <div>{[originData?.userInfo?.firstName, originData?.userInfo?.middleName, originData?.userInfo?.lastName].filter(Boolean).join(" ")}</div>
                    </div>

                    <div className={csn(cs.agreementItem, "border-0 border-b border-dashed border-divider")}>
                        <div>{$st("ach_agreement_auth_time")}:</div>
                        <div>{timeStr}</div>
                    </div>
                    <div className={csn(cs.agreementItem, "border-0 border-b border-dashed border-divider")}>
                        <div>{$st("ach_agreement_auth_ip")}:</div>
                        <div>{ip}</div>
                    </div>
                    <div className="flex text-primary font-b pt-12px">
                        <IconInfo16pxBoldS size={16} className="mt-2px" />
                        <div className="flex-1 ml-4px">
                            Please ensure that the name on your bank account matches the name on your Pionex account. Otherwise, we will initiate a refund process, which will incur
                            refund fees.
                        </div>
                    </div>
                    <div className={cs.agreementDes2}>
                        By clicking "I authorize" below, I consent and authorize Stripe or its affiliate on behalf of{" "}
                        <a href="https://www.pionex.us" target="_blank" rel="noreferrer">
                            Pionex.US
                        </a>
                        , to debit my account for a one-time electronic funds transfer (EFT) payment for the amount and on the date identified above. I also authorize Stripe or its
                        affiliate to debit or credit my account to correct any errors or process any refunds. PLEASE NOTE: You are authorizing a one-time payment that will begin
                        processing immediately. If there is a problem with the transaction, you may contact{" "}
                        <a target="_blank" href="mailto:service@pionex.us" rel="noreferrer">
                            service@pionex.us
                        </a>
                        , however, there is no guarantee of cancellation.
                        <br />
                        <br />
                        Scheduled in advance payments may be revoked by contacting{" "}
                        <a target="_blank" href="mailto:service@pionex.us" rel="noreferrer">
                            service@pionex.us
                        </a>{" "}
                        3 business days prior to the scheduled payment.
                        <br />
                        <br />I understand that as of today's date, this payment authorization is valid.
                    </div>
                    <div className={cs.agreementDes3}>{$st("ach_agreement_des3")}</div>
                </div>
            </Modal>
        </>
    );
}

export default StripeACHModal;
