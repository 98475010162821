import React, { FC } from "react";
import { MediaProvider } from "./hooks/useMedia";
import { KycInfoProvider } from "commonUse/useKycInfoV2";
import { KycV2RightsProvider } from "commonUse/useKycRightsV2";
import { JoinBetaTestProvider } from "commonUse/useJoinInternalTest";
import { GlobalModalProvider } from "state/GlobalModalProvider";

// 包裹在Layout组件外层的容器组件集合
export const OuterWrappers: FC = ({ children }) => {
    return (
        <JoinBetaTestProvider>
            <KycInfoProvider>
                <KycV2RightsProvider>
                    <MediaProvider>
                        <GlobalModalProvider>{children}</GlobalModalProvider>
                    </MediaProvider>
                </KycV2RightsProvider>
            </KycInfoProvider>
        </JoinBetaTestProvider>
    );
};
