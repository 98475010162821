// 生成一个简单ID
const getSimpleUID = () => (Date.now() + Math.round(Math.random() * 200000)).toString(16);

const MESSAGE_SOURCE = "pionex-frame-bradge";
interface BridgeMessage {
    source: typeof MESSAGE_SOURCE;
    mode: messageMode;
    type: string;
    data?: any;
    uid: string;
    error?: any;
}
enum messageMode {
    connect = "connect",
    request = "request",
    response = "response",
}

/**
 * 父子Frame框架通信桥
 */
export default class FrameBridge {
    private _isDebug = process.env.NODE_ENV !== "production" || localStorage.FrameBridge_isDebug;
    private _logLevel = Number(localStorage.FrameBridge_logLevel) || 0;
    // 创建一个bridge示例
    static create = (...p: ConstructorParameters<typeof FrameBridge>) => new FrameBridge(...p);
    // 获取父节点window对象
    static windowParent = window.parent === window ? null : window.parent;
    // 目标点对点的origin，如果存在多页面
    private _targetOrigin?: string;
    public get isConnectionReady() {
        return !!this._targetOrigin;
    }
    constructor(
        private targetSource: Window,
        public config: { validOrigins: false | Location["origin"][]; targetOrigin?: string; timeout?: number; onConnect?(or: FrameBridge); onDisconnect?(or: FrameBridge) },
    ) {
        // 注册事件回调
        window.addEventListener("message", this._messageHandler);
        if (config.targetOrigin) {
            // 建立一个点对点的连接
            this._createConnectionToChildAndKeep(config.targetOrigin);
        }
    }
    private _isDestroyed: boolean = false;
    /**
     * 销毁事件订阅
     */
    destroy = () => {
        this._isDestroyed = true;
        this._messageBank.clear();
        this._executeOnConnectQueue = [];
        this.config.validOrigins = [];
        clearTimeout(this._connectionKeeper);
        window.removeEventListener("message", this._messageHandler);
    };

    private _onConnect = () => {
        this.config.onConnect?.(this);
        this._executeOnConnectQueue.forEach((call) => call());
        this._executeOnConnectQueue = [];
    };
    private _onDisconnect = () => {
        this.config.onDisconnect?.(this);
    };
    private _executeOnConnectQueue: Array<() => any> = [];
    private _executeOnConnect = (call: () => any) => {
        if (this._targetOrigin) {
            call();
        } else this._executeOnConnectQueue.push(call);
    };
    private _connectionKeeper: any;
    private _createConnectionToChildAndKeep = (targetOrigin: string) => {
        const keeper = async () => {
            if (this._isDestroyed) return;
            clearTimeout(this._connectionKeeper);
            try {
                await this._originSendMessage<string>("connect", { mode: messageMode.connect }, targetOrigin)
                    .then(({ data: connectOrigin }) => {
                        this._isDebug && this._logLevel >= 1 && console.info(`PFB_CONNECTION[OK]:${connectOrigin}`);
                        // 链接成功后，绑定目标 origin
                        if (connectOrigin && this._targetOrigin !== connectOrigin) {
                            // 嵌入的子级窗口，已经建立链接
                            this._targetOrigin = connectOrigin;
                            this._onConnect();
                        }
                    })
                    .catch((error) => {
                        this._isDebug && this._logLevel >= 1 && console.error(`PFB_CONNECTION[FAILED]:${targetOrigin}`, error);
                        console.error(error);
                        // 连接超时，或报错
                        // 嵌入的子级窗口，链接断开
                        this._targetOrigin = undefined;
                        this._onDisconnect();
                    });
            } catch (e) {
                console.error(e);
            }
            // 间隔3秒发起一次握手
            this._connectionKeeper = setTimeout(keeper, 500);
        };
        keeper();
    };
    private _messageBank = new Map<string, (data: any, source: MessageEvent["source"]) => any | Promise<any>>();
    private _messageHandler = async (event: MessageEvent) => {
        if (this._isDestroyed) return;
        const { origin: fromOrigin, data: message, source } = event;
        try {
            // 判断source来源是否可靠，确保是当前通信的窗口，避免出现多窗口数据篡流的情况
            const sameSource = source === this.targetSource;
            const isValidOrigins = this.config.validOrigins === false || this.config.validOrigins.includes(fromOrigin);
            const messageValid = typeof message === "object" && message.source === MESSAGE_SOURCE;
            if (sameSource && isValidOrigins && messageValid) {
                const { mode, type: rType, uid: rUid, data } = message as BridgeMessage;
                /**
                 * 请求类型消息处理
                 * A -----Request------> B
                 * A <-----Reply-------- B
                 */
                if (mode === messageMode.request) {
                    let responseData, error;
                    try {
                        const requestHandler = this._messageBank.get(rType);
                        if (requestHandler) {
                            responseData = await requestHandler(data, source);
                        } else error = `There has no request handler of type[${rType}], please`;
                    } catch (err) {
                        // 输出错误信息
                        error = err;
                    }
                    this._isDebug && this._logLevel >= 1 && console.info("PFB_HANDLE[REQUEST]:", JSON.stringify(message), responseData, fromOrigin);
                    await this._originSendMessage(rType, { mode: messageMode.response, uid: rUid, data: responseData, error }, fromOrigin);
                } else if (mode === messageMode.connect) {
                    // 特殊处理connect类型的消息。建立链接，并绑定当前邀请链接的origin信息
                    if (this._targetOrigin !== fromOrigin) {
                        // 收到连接请求后，开始建立连接
                        // FIXME: 这里是否需要建立新的状态保持？
                        this._createConnectionToChildAndKeep(fromOrigin);
                    }
                    this._isDebug && this._logLevel >= 1 && console.info("PFB_HANDLE[CONNECT]:", JSON.stringify(message), fromOrigin);
                    await this._originSendMessage(rType, { mode: messageMode.response, uid: rUid, data: window.location.origin }, fromOrigin);
                }
                this._isDebug && this._logLevel >= 2 && console.warn("PFB_HANDLE[UNTRACKED_REQUEST]:", JSON.stringify(message), fromOrigin);
            }
            this._isDebug &&
                this._logLevel >= 3 &&
                console.log(
                    "PFB_HANDLE[INVALID_UNTRACKED_REQUEST]:",
                    `sameSource:${sameSource};isValidOrigins:${isValidOrigins};messageValid:${messageValid}[${[JSON.stringify(message)]}]`,
                    fromOrigin,
                );
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * 注册相应请求的回调函数，函数返回目标数据
     */
    onRequest = <D extends any>(type: string, dataCallback: (data: any, source: MessageEvent["source"]) => D | Promise<D>) => {
        this._messageBank.set(type, dataCallback);
        return () => {
            this._messageBank.delete(type);
        };
    };
    /**
     * 从 frame 获取数据
     */
    get = <D extends any>(type: string, data?: any) => {
        return this.post<D>(type, data);
    };
    post = <D extends any>(type: string, data: any) => {
        return new Promise<{ data: D; source: MessageEvent["source"]; type: string; error?: any }>((resolve, reject) => {
            this._executeOnConnect(() => {
                console.log("start-_executeOnConnect");
                this._originSendMessage<D>(type, { data }, this._targetOrigin).then(resolve).catch(reject);
            });
        });
    };
    /**
     * 向 frame post 数据
     */
    private _originSendMessage = <D extends any>(type: string, messageConfig: Partial<BridgeMessage> = {}, targetOrigin: string | undefined) => {
        if (this._isDestroyed) return Promise.reject(Error("PFB_ERROR:instance destroyed!"));
        return new Promise<{ data: D; source: MessageEvent["source"]; type: string; error?: any }>((resolve, reject) => {
            try {
                // 使用执行函数发起请求，确保在连接成功后发起。附带请求id标记连接
                if (!targetOrigin) return reject(Error(`PFB_ERROR:no reachable target origin.[FROM:${window.location.origin}]`));
                const uid = messageConfig?.uid || getSimpleUID();
                // 处理事件回调
                const transact = async (event: MessageEvent) => {
                    try {
                        const { origin: fromOrigin, data: message, source } = event;
                        // 判断source来源是否可靠，确保是当前通信的窗口，避免出现多窗口数据篡流的情况
                        const sameSource = source === this.targetSource;
                        const isValidOrigins = this.config.validOrigins === false || this.config.validOrigins.includes(fromOrigin);
                        const messageValid = typeof message === "object" && message.source === MESSAGE_SOURCE;
                        if (sameSource && isValidOrigins && messageValid) {
                            const { mode, type: rType, uid: rUid, data, error } = message as BridgeMessage;
                            /**
                             * 校验请求，仅处理response类型
                             * A -----Request------> B
                             * A <-----Reply-------- B
                             */
                            if (uid === rUid && mode === messageMode.response && type === rType) {
                                // 如果是本次应答，则去掉超时器
                                clearTimeout(timeoutTimer);
                                this._isDebug && this._logLevel >= 1 && console.info("PFB_REPLY:", JSON.stringify(message), fromOrigin);
                                // 返回合法的数据
                                resolve({ type: rType, data, source, error });
                                // 关闭连接，确保仅触发一次
                                window.removeEventListener("message", transact);
                            }
                            this._isDebug && this._logLevel >= 2 && console.warn("PFB_REPLY[UNTRACKED]:", JSON.stringify(message), fromOrigin);
                        }
                        this._isDebug &&
                            this._logLevel >= 3 &&
                            console.log(
                                "PFB_REPLY[INVALID_UNTRACKED]:",
                                `sameSource:${sameSource};isValidOrigins:${isValidOrigins};messageValid:${messageValid}[${[JSON.stringify(message)]}]`,
                                fromOrigin,
                            );
                    } catch (error) {
                        reject(error);
                    }
                };
                let timeoutTimer;
                // 仅在消息类型不是response类型时，监听返回(因为response类型消息不回返回状态)
                if (messageConfig.mode !== messageMode.response) {
                    window.addEventListener("message", transact);
                    // 请求超时
                    timeoutTimer = setTimeout(() => {
                        reject(Error(`PFB_REQUEST_TIMEOUT:[${type}]-${uid}`));
                        window.removeEventListener("message", transact);
                    }, this.config.timeout || 10e3);
                }
                // 创建本次监听
                const messageData = { source: MESSAGE_SOURCE, mode: messageMode.request, type, uid, ...messageConfig } as BridgeMessage;
                this._isDebug && this._logLevel >= 1 && console.info("PFB_SEND:", JSON.stringify(messageData), { from: window.location.origin, to: targetOrigin });
                this.targetSource.postMessage(messageData, targetOrigin);
            } catch (error) {
                reject(error);
            }
        });
    };
}

/*** ----------使用示例--------- ***/

/**
 * 父窗口服务组件用例
 */
// useEffect(() => {
//     // 创建一个连接子窗口的连接示例
//     const childBridge = FrameBridge.create(window, { validOrigins: ["https://www.picol.com", "https://www.picol.com.tw"] });

//     // 这里主服务需要注册一个处理某些时间的回调，以响应从服务对应数据请求
//     childBridge.onRequest("userInfo", () => {
//         // 同步返回
//         // return {userId: 1};
//         // 异步返回模式
//         return ajax.get(`/api/userInfo`);
//     });

//     childBridge.onRequest("action-1", ({ data }) => {
//         // 不返回数据，只做动作
//         if (data === "jump") history.push("/some/path");
//     });

//     if (true) {
//         childBridge.post("someData", { data: { id: 12 } });
//     }
// }, []);

/**
 * 子窗口服务组件用例
 */
// const [userInfo, setUserInfo] = useState();
// useEffect(() => {
//     // 创建一个连接主窗口的桥示例
//     const mainBridge = FrameBridge.create(window, { validOrigins: ["https://www.pionex.com", "http://www.pionex.cc"] });
//     (async () => {
//         try {
//             // 从主窗口服务中获取用户信息
//             const { data } = await mainBridge.get<{ userId: number }>("userInfo");
//             if (data.userId === 1) {
//                 setUserInfo(data);
//             }
//         } catch (error) {
//             console.error(error);
//         }
//     })();
//     // 从服务也可以注册事件响应回调，来响应主服务的数据请求
//     mainBridge.onRequest("action-b", ({ data }) => {
//         if (data === "jump") history.push("/some/path");
//     });
//     // 务必销毁桥实例
//     return () => mainBridge.destroy();
// }, []);
