import LoadingPage from "components/LoadingPage";
import React from "react";
import Loadable from "react-loadable";
import { Redirect, Switch, withRouter } from "react-router";
import { LangHistory } from "src/utils/History";
import { RouteProps } from "src/utils/type";
import canonicalCreator from "src/utils/canonicalCreator";
import V2GetBotsSuccess from "landings/V2/GetBotsSuccess";
import Download from "./Download";
import GoogleAuthDownload from "./GoogleAuthDownload";

import AuthRoute, { RouteLC } from "src/components-v2/AuthRoute";
import { USD_DW_ROUTE_V2 } from "landings/V2/USDDW/menuRouter";
import { QUESTIONNAIRE_PATHS } from "utils/questionaire";
import FrameBridge from "utils/frameBridge";
import useMedia from "src/components-v2/Layout/hooks/useMedia";
import LocalStorageUtils from "utils/LocalStorageUtils";
import CopyBotShareTemplate from "./shareBot/CopyBot";
import Constants from "utils/Constants";
import { SideTabKey } from "landings/Trade/SideTab";

export const Trade = Loadable({
    loader: () => import(/* webpackChunkName: "trade" */ "./Trade"),
    loading: LoadingPage,
});

export const TrialFund = Loadable({
    loader: () => import(/* webpackChunkName: "TrialFund" */ "./TrialFund"),
    loading: LoadingPage,
});

export const NewTaxes = Loadable({
    loader: () => import(/* webpackChunkName: "Taxes" */ "./Taxes"),
    loading: LoadingPage,
});

const CloseAccountPage = Loadable({
    loader: () => import(/* webpackChunkName: "Apimanage" */ "landings/CloseAccount/CloseAccount"),
    loading: LoadingPage,
});
const SupportUS = Loadable({
    loader: () => import(/* webpackChunkName: "support_us" */ "./V2/SupportUS"),
    loading: LoadingPage,
});

const WinOneBtc = Loadable({
    loader: () => import(/* webpackChunkName: "WinOneBtc" */ "landings/V2/WinOneBtc"),
    loading: LoadingPage,
});

// const Trade = Loadable({
//     loader: () => import(/* webpackChunkName: "trade" */ "./Trade"),
//     loading: LoadingPage,
// });

// const NewTrade = Loadable({
//     loader: () => import(/* webpackChunkName: "trade" */ "./Trade/index2"),
//     loading: LoadingPage,
// });

const SignPage = Loadable({
    loader: () => import(/* webpackChunkName: "sign_page" */ "account/SignPage"),
    loading: LoadingPage,
});

const HistoricalOrderDetails = Loadable({
    loader: () => import(/* webpackChunkName: "pionex_historical_order_details" */ "./PionexHistoricalOrderDetails"),
    loading: LoadingPage,
});
const Balance = Loadable({
    loader: () => import(/* webpackChunkName: "balance" */ "./Balance"),
    loading: LoadingPage,
});
const GoogleAuthenticator = Loadable({
    loader: () => import(/* webpackChunkName: "google_authenticator" */ "./googleAuthenticator"),
    loading: LoadingPage,
});
const AccountBind = Loadable({
    loader: () => import(/* webpackChunkName: "account_bind" */ "./AccountBind"),
    loading: LoadingPage,
});

const AccountUnBind = Loadable({
    loader: () => import(/* webpackChunkName: "account_unbind" */ "./AccountUnBind"),
    loading: LoadingPage,
});
const ReferralProgram = Loadable({
    loader: () => import(/* webpackChunkName: "referral_program" */ "./ReferralProgram"),
    loading: LoadingPage,
});

const FeeAnnounce = Loadable({
    loader: () => import(/* webpackChunkName: "fee_announce" */ "./FeeAnnounce"),
    loading: LoadingPage,
});

const MyProfile = Loadable({
    loader: () => import(/* webpackChunkName: "my_profile" */ "src/landings/MyProfile"),
    loading: LoadingPage,
});

const KycV2 = Loadable({
    loader: () => import(/* webpackChunkName: "kyc_v2" */ "./V2/Account/paths/Kyc-v2"),
    loading: LoadingPage,
});

const TeamInfoPage = Loadable({
    loader: () => import(/* webpackChunkName: "TeamInfo" */ "./TeamInfo"),
    loading: LoadingPage,
});

const CoinPediaPage = Loadable({
    loader: () => import(/* webpackChunkName: "coin_pedia" */ "./CoinPedia"),
    loading: LoadingPage,
});

const NewPionexMarketPage = Loadable({
    loader: () => import(/* webpackChunkName: "v2_market" */ "landings/V2/Market"),
    loading: LoadingPage,
});

const V2Rebate = Loadable({
    loader: () => import(/* webpackChunkName: "rebate_page" */ "./V2/Rebate"),
    loading: LoadingPage,
});

// const TWDepositMainSite = Loadable({
//     loader: () => import(/* webpackChunkName: "TWDeposit-CreateOrder" */ "landings/V2/TWDeposit/MainSite"),
//     loading: LoadingPage,
// });

const CircleDW = Loadable({ loader: () => import(/* webpackChunkName: "CircleDW" */ "landings/V2/CircleDW"), loading: LoadingPage });

const DebitDepositResult = Loadable({ loader: () => import(/* webpackChunkName: "USDDW" */ "landings/V2/CircleDebit/Deposit/CallbackResult"), loading: LoadingPage });

const LeverageCoinExam = Loadable({
    loader: () => import(/* webpackChunkName: "LeverageCoinExam" */ "landings/LeverageCoinExam"),
    loading: LoadingPage,
});

const SpotBalanceTransfer = Loadable({
    loader: () => import(/* webpackChunkName: "SpotBalanceTransfer" */ "./Balance/components/SpotBalanceTransfer"),
    loading: LoadingPage,
});

const Chatgpt = Loadable({
    loader: () => import(/* webpackChunkName: "chat-gpt" */ "./Chatgpt/Chatgpt"),
    loading: LoadingPage,
});

const MessageCenter = Loadable({
    loader: () => import(/* webpackChunkName: "MessageCenter" */ "landings/Messages"),
    loading: LoadingPage,
});

const KOLLanding = Loadable({
    loader: () => import(/* webpackChunkName: "KOLLanding" */ "landings/KOLLanding"),
    loading: LoadingPage,
});
const KOLLandingEvent = Loadable({
    loader: () => import(/* webpackChunkName: "KOLLanding" */ "landings/KOLLanding/event"),
    loading: LoadingPage,
});
const NewbieRewards = Loadable({
    loader: () => import(/* webpackChunkName: "NewbieRewards" */ "landings/KOLLanding/NewbieRewards"),
    loading: LoadingPage,
});

const ActivityLanding = Loadable({
    loader: () => import(/* webpackChunkName: "ActivityLanding" */ "landings/KOLLanding/ActivityLanding"),
    loading: LoadingPage,
});
const AccountUpdate = Loadable({
    loader: () => import(/* webpackChunkName: "AccountUpdate" */ "landings/AccountUpdate"),
    loading: LoadingPage,
});
const PreUpgradeHistory = Loadable({
    loader: () => import(/* webpackChunkName: "PreUpgradeHistory" */ "landings/PreUpgradeHistory"),
    loading: LoadingPage,
});

interface IRouterProps extends RouteProps {
    history: LangHistory;
}
const R: React.SFC<IRouterProps> = (props) => {
    const { isTablet } = useMedia();
    // FIXME: remove the function
    canonicalCreator(window.location.href);
    return (
        <Switch {...props}>
            {/***********************/}
            {/* UnAuthorized Routes */}
            {/***********************/}
            {/* 首页 */}
            <RouteLC
                exact
                path={["/", "/index.html"]}
                loadable={{
                    loader: () => import(/* webpackChunkName: "Home" */ "./V2/HomeUS.V2"),
                }}
                layoutConfig={{
                    contentFullWidthOnly: true,
                    showSidebar: false,
                    showManualServiceBtn: true,
                    headerV2Props: { fixedHolder: false, transparent: true, showDownLoad: true, showBanner: true },
                }}
            />
            {/* 仅在 PRERENDER 模式下注册该路由，用于渲染 SPA UI框架 */}
            {window.__PRERENDER_INJECTED?.$$isRenderServer && <RouteLC exact path={"/_spa"} component={() => <LoadingPage pastDelay />} />}
            {/* 交易主界面 */}
            <RouteLC
                layoutConfig={{
                    contentFullFill: true,
                    showFooter: false,
                    showManualServiceBtn: false,
                }}
                path={["/trade/:symbol/:tab?", "/trade"]}
                render={(p) => {
                    const { match } = p;
                    if (match.params.tab === SideTabKey.SWAP) {
                        return <Redirect to={"/buy-crypto/convert"} />;
                    }
                    if (match.params.symbol) {
                        const TradeV = Trade as any;
                        return <TradeV {...p} />;
                    }
                    return <Redirect to={`/trade/${LocalStorageUtils.tradeSelectedSymbol}/Bot`} />;
                }}
            />
            {/*/!* 交易主界面 *!/*/}
            {/*<RouteLC path="/trade" component={Trade} />*/}
            {/* 行情 */}
            <RouteLC
                path="/market"
                component={NewPionexMarketPage}
                layoutConfig={{
                    contentFullWidthOnly: true,
                    showFooter: false,
                    showManualServiceBtn: false,
                }}
            />

            <RouteLC
                path="/taxes"
                component={NewTaxes}
                layoutConfig={{
                    contentFullWidthOnly: true,
                    // contentFullWidthOnly: true,
                    // showFooter: false,
                    // showManualServiceBtn: false,
                    // contentFullFill:"#000"
                }}
            />
            <RouteLC path="/trial-fund" component={TrialFund} />

            {/* 手续费说明 */}
            <RouteLC exact path="/fees" component={FeeAnnounce} />
            {/* about */}
            {/*<RouteLC exact path="/team" component={TeamInfoPage} />*/}
            {/* 下载 */}
            <RouteLC exact path="/download" component={Download} />

            <RouteLC exact path="/downloadGoogleAuth" component={GoogleAuthDownload} />
            {/* staking 暂时屏蔽 */}
            {/* {Constants.isSiteV2 && <RouteLC path="/staking" component={StakingPage} layoutConfig={{ showFooter: false, showManualServiceBtn: false }} />} */}

            {/* 币种列表 */}
            <RouteLC path="/coinlist" component={CoinPediaPage} />
            {/* 登陆 */}
            <RouteLC
                path="/sign"
                component={SignPage}
                layoutConfig={{
                    showHeader: false,
                    showFooter: false,
                    showGlobalMessage: false,
                    showSidebar: false,
                    showManualServiceBtn: false,
                }}
            />
            {/* 注册 */}
            <RouteLC
                path="/signUp"
                component={SignPage}
                layoutConfig={{
                    showHeader: false,
                    showFooter: false,
                    showGlobalMessage: false,
                    showSidebar: false,
                    showManualServiceBtn: false,
                }}
            />
            {/* 获取机器人 */}
            {/* <RouteLC exact path="/bot_invite" component={V2BotInvite} /> */}
            <RouteLC exact path="/get_bots_success" component={V2GetBotsSuccess} />
            {/* Debit入金 3DS 回调 */}
            <RouteLC path={`${USD_DW_ROUTE_V2.DEBIT.DEPOSIT}/result/:type?`} component={DebitDepositResult} />
            {/* KOL邀请页 */}
            <RouteLC
                path="/partners/:name"
                loadable={{
                    loader: () => import(/* webpackChunkName: "Partners" */ "./V2/PartnersUS"),
                }}
            />

            <RouteLC exact path="/support" component={SupportUS} />
            <RouteLC
                exact
                path="/competition"
                loadable={{
                    loader: () => import(/* webpackChunkName: "competition_us" */ "./V2/CompetitionUS"),
                }}
            />

            {/*********************/}
            {/* Authorized Routes */}
            {/*********************/}
            {/* 买币 */}
            <RouteLC
                path={"/buy-crypto"}
                layoutConfig={{
                    // showFooter: false,
                    // requireLogin: true,
                    hiddenScrollbar: true,
                    // contentFullFill: true,
                    whiteBg: isTablet,
                    headerV2Props: {
                        showDownLoad: true,
                    },
                }}
                loadable={{
                    loader: () => import(/* webpackChunkName: "BuyCrypto" */ "landings/V2/BuyCrypto"),
                }}
            />
            {/* 入金成功 */}
            <RouteLC
                path={"/buy-crypto-completed"}
                layoutConfig={{
                    hiddenScrollbar: true,
                    whiteBg: isTablet,
                    headerV2Props: {
                        showDownLoad: true,
                    },
                }}
                loadable={{
                    loader: () => import(/* webpackChunkName: "BuyCryptoSuccess" */ "landings/V2/BuyCrypto/StripeACH/PaymentCompletedPage"),
                }}
            />
            <AuthRoute
                path="/convert/history"
                layoutConfig={{
                    hiddenScrollbar: true,
                    whiteBg: true,
                }}
                loadable={{
                    loader: () => import(/* webpackChunkName: "BuyCrypto" */ "landings/Trade/Swap/History"),
                }}
            />
            {/* 杠杆代币交易测试 */}
            <AuthRoute path="/leverage_coin_exam" component={LeverageCoinExam} />
            {/* google 验证码绑定 */}
            <AuthRoute path="/googleAuthenticator" component={GoogleAuthenticator} />
            {/* 交易历史订单 */}
            <Redirect path="/order/:type/:orderType" to="/orders/bot/history" />
            {/* 账户资产主入口路由 */}
            <AuthRoute
                layoutConfig={{
                    contentFullWidthOnly: true,
                    showSidebar: false,
                    showFooter: false,
                    showManualServiceBtn: false,
                }}
                exact
                path={"/balance/:menuType?/:subType?"}
                component={Balance}
            />
            {/* 个人中心 */}
            <AuthRoute path="/my_profile" component={MyProfile} />
            {/* -----------账户相关页面-START-------- */}
            {/* KYC V2 */}
            <AuthRoute path="/account/kyc" component={KycV2} />
            {/* 账号绑定 */}
            <AuthRoute path="/account/bind" component={AccountBind} />
            <AuthRoute
                path="/account/unbind/:unbindType"
                component={AccountUnBind}
                layoutConfig={{
                    contentFullWidthOnly: true,
                }}
            />
            {/* -----------账户相关页面-END-------- */}
            {/* 交易订单历史详情 */}
            <AuthRoute
                path="/orders"
                layoutConfig={{
                    contentFullWidthOnly: true,
                    showFooter: false,
                }}
                component={HistoricalOrderDetails}
            />

            <Redirect path="/orderdetails/:orderType?" to="/orders/bot/running" />
            {/* 小额资产兑换 */}
            <AuthRoute path="/spot_swap" component={SpotBalanceTransfer} />
            {/* 返佣 */}
            <AuthRoute path={["/rebate"]} component={V2Rebate} />
            {/* 推广奖励 */}
            <AuthRoute path="/referral_program" component={ReferralProgram} />

            <AuthRoute
                layoutConfig={{
                    contentFullWidthOnly: true,
                    showSidebar: false,
                    showManualServiceBtn: PLATFORM.PIONEX_COLOGY ? false : !isTablet,
                    showHeader: !PLATFORM.PIONEX_COLOGY,
                    showGlobalMessage: PLATFORM.PIONEX_COLOGY ? !FrameBridge.windowParent : true,
                    requireLogin: PLATFORM.PIONEX_COLOGY ? !FrameBridge.windowParent : true,
                    whiteBg: isTablet,
                }}
                exact
                path="/close-account"
                component={CloseAccountPage}
            />
            {/* <AuthRoute exact path="/tw_deposit/*" component={TWDepositMainSite} /> */}
            {/* circle 出入金 */}
            <AuthRoute path="/circle_dw" component={CircleDW} />
            {/* 问卷调查 */}
            <AuthRoute
                exact
                path={QUESTIONNAIRE_PATHS.QUESTIONNAIRE_PATH}
                loadable={{
                    loader: () => import(/* webpackChunkName: "Questionnaire" */ "landings/V2/Questionnaire"),
                }}
                layoutConfig={{
                    showFooter: false,
                    requireLogin: true,
                    whiteBg: true,
                }}
            />
            <Redirect to={QUESTIONNAIRE_PATHS.QUESTIONNAIRE_PATH} from={QUESTIONNAIRE_PATHS.OLD_QUESTIONNAIRE} />
            {/* USD 出入金 */}
            <AuthRoute
                path={USD_DW_ROUTE_V2._}
                layoutConfig={{
                    showFooter: false,
                    showManualServiceBtn: false,
                    requireLogin: true,
                    hiddenScrollbar: true,
                    contentFullFill: true,
                    whiteBg: true,
                }}
                loadable={{
                    loader: () => import(/* webpackChunkName: "USDDW" */ "landings/V2/USDDW"),
                }}
            />

            {Constants.isSiteV2 && (
                <RouteLC
                    exact
                    path={"/copy-bot/:order_type/:order_id"}
                    layoutConfig={{
                        className: "bg-white",
                        contentFullWidthOnly: true,
                    }}
                    component={CopyBotShareTemplate}
                />
            )}

            {/* ChatGPT */}
            {/*<RouteLC*/}
            {/*    path="/chat/:code?"*/}
            {/*    component={Chatgpt}*/}
            {/*    layoutConfig={{*/}
            {/*        showFooter: false,*/}
            {/*        showManualServiceBtn: false,*/}
            {/*        contentFullFill: true,*/}
            {/*    }}*/}
            {/*/>*/}

            {/* staking 暂时屏蔽 */}
            {/* {Constants.isSiteV2 && (
                <RouteLC
                    exact
                    path={"/stake-landing"}
                    component={StakingLanding}
                    layoutConfig={{
                        contentFullWidthOnly: true,
                        showSidebar: false,
                        showManualServiceBtn: false,
                    }}
                />
            )} */}

            {/* TODO: 新增404页面，而不是直接重定向 */}
            <RouteLC
                layoutConfig={{
                    showFooter: false,
                    showManualServiceBtn: false,
                    contentFullWidthOnly: true,
                    headerV2Props: {
                        fixedHolder: false,
                        transparent: true,
                        showBanner: false,
                    },
                }}
                path="/win-one-btc"
                component={WinOneBtc}
            />
            {/* 系统消息 */}
            {PLATFORM.PIONEX_US_V2 && (
                <RouteLC
                    path="/message"
                    component={MessageCenter}
                    layoutConfig={{
                        contentFullWidthOnly: true,
                        showFooter: false,
                        showManualServiceBtn: false,
                    }}
                />
            )}

            <RouteLC path="/KOL-landing/:refCode?" component={KOLLanding} layoutConfig={{ showFooter: false, showManualServiceBtn: false, contentFullFill: true }} />
            <RouteLC path="/KOL-landing-event/:refCode?" component={KOLLandingEvent} layoutConfig={{ showFooter: false, showManualServiceBtn: false, contentFullFill: true }} />
            <RouteLC path="/newbie-rewards/:refCode?" component={NewbieRewards} layoutConfig={{ showFooter: false, contentFullFill: true }} />
            {PLATFORM.PIONEX_US_V2 && (
                <RouteLC path="/activity-landing/:refCode?" component={ActivityLanding} layoutConfig={{ showFooter: false, showManualServiceBtn: false, contentFullFill: true }} />
            )}
            {PLATFORM.PIONEX_US_V2 && (
                <RouteLC
                    layoutConfig={{
                        contentFullWidthOnly: true,
                        showSidebar: false,
                        showFooter: false,
                        showManualServiceBtn: false,
                    }}
                    path="/pre_upgrade_history/:subType?"
                    component={PreUpgradeHistory}
                />
            )}
            <RouteLC
                layoutConfig={{
                    contentFullWidthOnly: true,
                    showSidebar: false,
                }}
                path="/account_update/:subType?"
                component={AccountUpdate}
            />

            <Redirect to="/" />
        </Switch>
    );
};

const W: any = withRouter(R);

export default W;
