import { useMemo } from "react";
import SignatureDWAPI from "landings/V2/SignatureDW/SignatureDWAPI";
import useAccountInfo from "commonUse/useAccountInfo";
import { CircleDWAPI } from "compliance_lib";

/**
 * 获取Signature API调用实例
 */
export function useSignatureDWAPI() {
    return useMemo(() => {
        return new SignatureDWAPI();
    }, []);
}
