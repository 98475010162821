import React, { useEffect } from "react";
import { useConfirmCheckModal } from "src/commonUse/useComp";
import { useTranslators } from "src/commonUse/Locale";
import useAccountInfo from "src/commonUse/useAccountInfo";
import { is86AccountAndNoEmailBinding } from "src/utils/userInfo";
import { history } from "utils/History";
import PubSub from "pubsub-js";
import GuideBindGADialog from "./GABindGuide";

export const EVENT_OF_ACCOUNT_86_ONLY = "--ACCOUNT_86_ONLY_EVENT_KEY--";
// 避免弹出两次
let shown = false;
export function Account86OnlyNotice() {
    const { $st } = useTranslators();
    const [Comp, { show }] = useConfirmCheckModal({
        onOk() {
            history.push("/account/bind");
        },
        onCancel() {
            sessionStorage.setItem(EVENT_OF_ACCOUNT_86_ONLY, "1");
        },
        checkLabel: false,
        okText: $st("common_go_bind"),
        title: $st("acc_86_bind_title"),
        content: $st("acc_86_bind_tip"),
    });
    const accountInfo = useAccountInfo();
    useEffect(() => {
        const _show = () => {
            if (is86AccountAndNoEmailBinding(accountInfo) && !shown) {
                show();
                shown = true;
            }
        };
        _show();
        const token = PubSub.subscribe(EVENT_OF_ACCOUNT_86_ONLY, () => {
            _show();
        });
        return () => PubSub.unsubscribe(token);
    }, [accountInfo, show]);
    return Comp;
}

export default function GlobalMessages() {
    return (
        <>
            <Account86OnlyNotice />
            <GuideBindGADialog />
        </>
    );
}
