import React, { useCallback, useEffect } from "react";
import cs from "./index.module.less";
import { Iconfont, TButton, TModal, TSpin } from "components/v2/TinyComps";
import { useTranslators } from "commonUse/Locale";
import { history } from "utils/History";
import useKycV2Info, { getKycEntryRouter, KycV2Level, KycV2LevelStatus, useShowKycReasonModal } from "commonUse/useKycInfoV2";
import { message } from "antd";
import { KYC_EVENT_NAME } from "landings/Analytics/EventName";
import DataReporter from "landings/Analytics/DataReporter";

/**
 * 是否展示前置引导
 */
export function useNoKycVisible() {
    const [{ currentLevel }] = useKycV2Info();
    return currentLevel !== KycV2Level.lv2 && currentLevel !== KycV2Level.oldLv2;
}

/**
 * 出入金条件未满足的
 */

const NoKyc: React.FC<{ reportEvent?: string; type?: "deposit" | "withdraw" }> = ({ reportEvent, type = "deposit" }) => {
    const {
        $st,
        intl: { locale },
    } = useTranslators();
    const [{ forwardLevelMap, kycDestination }, loading, _, error] = useKycV2Info();

    useEffect(() => {
        !!error && message.error($st("common_network_exception"), 4);
        // 错误信息变化时提示
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    // 入金需要满足新旧KYC二级等级即可。
    const kycLv2Info = forwardLevelMap.get(KycV2Level.lv2) || forwardLevelMap.get(KycV2Level.oldLv2);
    const toKyc = useCallback(() => {
        if (!!reportEvent) {
            DataReporter.report(KYC_EVENT_NAME.KYC_EVENT_LV1, { from_web: reportEvent, PLATFORM: PLATFORM._CODE });
        }
        const route = getKycEntryRouter(kycDestination, "circleDW");
        route && history.push(route);
    }, [kycDestination, reportEvent]);

    const showKycReasonModal = useShowKycReasonModal();
    // 查看kyc失败原因
    const handleClickKycError = useCallback(() => {
        showKycReasonModal({ $st, locale, forwardLevelInfo: kycLv2Info });
    }, [$st, kycLv2Info, locale, showKycReasonModal]);

    if (!!error) {
        // kyc请求出错不展示内容
        return null;
    }

    return (
        <TSpin spinning={loading}>
            {!loading && (
                <div className={cs.container}>
                    <div className={cs.title}>{$st(`usddw_nokyc_title:${type}`)}</div>
                    <div className={cs.verifyItem}>
                        <div className={cs.verifyText}>
                            <div className={cs.verifyTitle}>{$st("circledw_verify_kyc_title")}</div>
                            {kycLv2Info?.status === KycV2LevelStatus.halfFailed || kycLv2Info?.status === KycV2LevelStatus.failed ? (
                                <div className={cs.verifyFailed} onClick={handleClickKycError}>{`${$st("common_detail")} >`}</div>
                            ) : (
                                <div className={cs.verifyDes}>{$st("circledw_verify_kyc_des")}</div>
                            )}
                        </div>
                        {kycLv2Info?.status === KycV2LevelStatus.passed ? (
                            <div className={cs.verifySuccess}>
                                <Iconfont icon="icon_succeed" />
                                {$st("circledw_kyc_verified")}
                            </div>
                        ) : (
                            <TButton className={cs.verifyBtn} type="primary" size="small" disabled={kycLv2Info?.status === KycV2LevelStatus.reviewing} onClick={toKyc}>
                                {kycLv2Info?.status === KycV2LevelStatus.reviewing
                                    ? $st("circledw_kyc_verifying")
                                    : kycLv2Info?.status === KycV2LevelStatus.halfFailed || kycLv2Info?.status === KycV2LevelStatus.failed
                                    ? $st("circledw_kyc_failed")
                                    : $st("circledw_kyc_unverified")}
                            </TButton>
                        )}
                    </div>
                </div>
            )}
        </TSpin>
    );
};

export default NoKyc;
