export interface BlockExplorerParams {
    protocol: string;
    coinName: string;
    txId?: string;
    address?: string;
}

export function getBlockExplorerUrl(params: BlockExplorerParams): string | null {
    const { txId, address } = params;
    const protocolUpper = params.protocol.toUpperCase();
    const coinNameAndProtocolUpper = `${params.coinName}-${protocolUpper}`;
    const txIdOrAddress = txId || address || "";
    if (coinNameAndProtocolUpper === "BTC-MAINNET") {
        return `https://www.blockchain.com/explorer/${txId ? "transactions" : "addresses"}/btc/${txIdOrAddress}`;
    } else if (protocolUpper === "ERC20" || coinNameAndProtocolUpper === "ETH-MAINNET") {
        return `https://etherscan.io/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else if (protocolUpper === "BEP20" || protocolUpper === "BEP20(BSC)") {
        return `https://bscscan.com/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "ADA-MAINNET") {
        return `https://explorer.cardano.org/en/${txId ? "transaction?id" : "address?address"}=${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "ALGO-MAINNET") {
        return `https://allo.info/${txId ? "tx" : "account"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "APT-MAINNET") {
        return `https://explorer.aptoslabs.com/${txId ? "txn" : "account"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "ATOM-MAINNET") {
        return `https://www.mintscan.io/cosmos/${txId ? "txs" : "account"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "BCH-MAINNET") {
        return `https://www.blockchain.com/explorer/${txId ? "transactions" : "addresses"}/bch/${txIdOrAddress}`;
    } else if (protocolUpper === "BEP2") {
        return `https://explorer.bnbchain.org/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "BSV-MAINNET") {
        return `https://explorer.bitquery.io/bitcoinsv/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else if (protocolUpper === "TRC20") {
        return `https://tronscan.org/#/${txId ? "transaction" : "address"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "DASH-MAINNET") {
        return `https://explorer.dash.org/insight/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "DOGE-MAINNET") {
        return `https://dogechain.info/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "DOT-MAINNET") {
        return `https://blockchair.com/polkadot/${txId ? "extrinsic" : "address"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "EOS-MAINNET") {
        return `https://bloks.io/${txId ? "transaction" : "account"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "ETC-MAINNET") {
        return `https://etc.blockscout.com/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else if (protocolUpper === "ETHPOW") {
        return `https://www.oklink.com/ethw/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else if (protocolUpper === "ARBITRUM") {
        return `https://arbiscan.io/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else if (protocolUpper === "OPTIMISM") {
        return `https://optimistic.etherscan.io/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "FIL-MAINNET") {
        return `https://filfox.info/${txId ? "message" : "address"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "FTM-MAINNET") {
        return `https://ftmscan.com/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else if (protocolUpper === "SOLANA" || coinNameAndProtocolUpper === "SOL-MAINNET") {
        return `https://explorer.solana.com/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "LTC-MAINNET") {
        return `https://blockchair.com/litecoin/${txId ? "transaction" : "address"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "LUNC-MAINNET") {
        return `https://atomscan.com/terra/${txId ? "transactions" : "accounts"}/${txIdOrAddress}`;
    } else if (protocolUpper === "MATIC" || coinNameAndProtocolUpper === "MATIC-MAINNET") {
        return `https://polygonscan.com/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "NEAR-MAINNET") {
        return `https://nearblocks.io/${txId ? "txns" : "address"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "VET-MAINNET") {
        return `https://explore.vechain.org/${txId ? "transactions" : "accounts"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "XLM-MAINNET") {
        return `https://stellarchain.io/${txId ? "transactions" : "accounts"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "XRP-MAINNET") {
        return `https://xrpscan.com/${txId ? "tx" : "account"}/${txIdOrAddress}`;
    } else if (coinNameAndProtocolUpper === "XTZ-MAINNET") {
        return `https://tzstats.com/${txIdOrAddress}`;
    } else if (protocolUpper === "OMNI") {
        return `https://omniexplorer.info/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else if (protocolUpper === "HECO") {
        return `https://hecoinfo.com/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else if (protocolUpper === "SUI") {
        return `https://suiexplorer.com/${txId ? "txblock" : "address"}/${txIdOrAddress}`;
    } else if (protocolUpper === "OORT") {
        return `https://mainnet-scan.oortech.com/${txId ? "tx" : "address"}/${txIdOrAddress}`;
    } else {
        return null;
    }
}
