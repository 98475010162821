import { NOTIFY_CUSTOM_ORDER_CREATE } from "landings/Trade/PUBSUB_EVENT";
import { ManualOrderTableData, OrderTabType } from "landings/Trade/TradeConstant";
import PubSub from "pubsub-js";
import { useEffect, useState } from "react";
import { Observable, zip } from "rxjs";
import { distinctUntilChanged, filter, flatMap } from "rxjs/operators";
import { APIKeyInfo, NumberCommonUtils, requestWithRetry } from "trade_utils_lib";
import { ExchangeID, ExchangeOrder, ExchangeOrderStatus, ExchangeOrderType, QueryCustomOrderParam, SLProOrderListInfo, SLProOrderListParams, TradeUtils } from "TradeAPILib";
import ExchangeDataProvider from "TradeLib/ExchangeDataProvider";
import { ExchangeTradePair } from "TradeLib/TradeTypes";
import { $st } from "utils/i18n";
import NumberUtils from "utils/NumberUtils";
import { OrderUtils } from "utils/OrderUtils";
import DateFormatter from "state/DateFormatter";

export function useManualOrder(
    apiKey: APIKeyInfo | undefined,
    param: QueryCustomOrderParam,
    queryOpen: boolean,
    slProParam: SLProOrderListParams,
    repeat?: boolean,
): [ExchangeOrder[] | undefined, string | undefined, boolean] {
    const [data, setData] = useState<ExchangeOrder[]>(undefined as unknown as ExchangeOrder[]);
    const [loading, setLoading] = useState<boolean>(true);
    const [slProListNextPageToken, setSlProListNextPageToken] = useState<undefined | string>();
    useEffect(() => {
        if (apiKey === undefined) {
            return;
        }
        const manualObserve = manualOrderDataRequest(apiKey, param, queryOpen, repeat);
        const slProObserve = querySLProDataRequest(apiKey, slProParam);
        const orderObservable = zip(manualObserve, slProObserve).subscribe(
            (result) => {
                let manualList = result[0];
                let SLList = result[1].orders;
                setData(manualList.concat(SLList));
                setSlProListNextPageToken(result[1].nextPageToken);
                setLoading(false);
            },
            (err) => {
                console.warn(err);
                setLoading(false);
            },
        );
        return () => {
            if (orderObservable) {
                orderObservable.unsubscribe();
            }
        };
    }, [apiKey]);

    return [data, slProListNextPageToken, loading];
}

export function manualOrderDataRequest(apiKey: APIKeyInfo, param: QueryCustomOrderParam, queryOpen: boolean, repeat?: boolean): Observable<ExchangeOrder[]> {
    // 交易库中已经根据ticker缓存设置了涨跌 - 20200226
    return requestWithRetry(() =>
        ExchangeDataProvider.getExchangeDataProvider(apiKey).pipe(flatMap((exchangeDataProvider) => exchangeDataProvider.api.queryCustomOrderPage(param, queryOpen, repeat))),
    );
}

export function querySLProDataRequest(apiKey: APIKeyInfo, param: SLProOrderListParams): Observable<SLProOrderListInfo> {
    return requestWithRetry(() =>
        ExchangeDataProvider.getExchangeDataProvider(apiKey).pipe(flatMap((exchangeDataProvider) => exchangeDataProvider.api.querySLProOrderList(param))),
    );
}

export function useManualOrderWebSocket(
    manualOrderWebsocketObserve: (market: string) => Observable<ExchangeOrder>,
    botOrderWebsocketObserve: (market: string) => Observable<ExchangeOrder>,
    market: string,
): ExchangeOrder | undefined {
    const [order, setOrder] = useState<ExchangeOrder>(undefined as unknown as ExchangeOrder);

    useEffect(() => {
        let tempWebsocket;
        let tempWebsocket1;
        tempWebsocket = manualOrderWebsocketObserve(market).subscribe(
            (result) => {
                setOrder(result);
            },
            (error) => {},
        );
        tempWebsocket1 = botOrderWebsocketObserve(market)
            .pipe(filter((data) => data.orderType === ExchangeOrderType.SL_Pro))
            .pipe(
                distinctUntilChanged((obj1, obj2) => {
                    if (obj1.id === obj2.id) {
                        if (obj1.orderType === obj2.orderType) {
                            return (
                                obj1.state === obj2.state &&
                                obj1.innerStatus === obj2.innerStatus &&
                                obj1.exchangeSLProOrder!.filled_value === obj2.exchangeSLProOrder!.filled_value &&
                                obj1.exchangeSLProOrder!.triggerTime === obj2.exchangeSLProOrder!.triggerTime
                            );
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }),
            )
            .subscribe(
                (result) => {
                    setOrder(result);
                },
                (error) => {},
            );

        return () => {
            tempWebsocket && tempWebsocket.unsubscribe();
            tempWebsocket1 && tempWebsocket1.unsubscribe();
        };
    }, [manualOrderWebsocketObserve, botOrderWebsocketObserve]);

    return order;
}

export function useManualOrderDataRequestByOrderOperation(
    apiKey: APIKeyInfo | undefined,
    param: QueryCustomOrderParam,
    slProParam: SLProOrderListParams,
    queryOpen: boolean,
    repeat?: boolean,
): [ExchangeOrder[] | undefined, string | undefined] {
    const [data, setData] = useState<ExchangeOrder[]>(undefined as unknown as ExchangeOrder[]);
    const [slProListNextPageToken, setSlProListNextPageToken] = useState<undefined | string>();
    useEffect(() => {
        if (!queryOpen || apiKey === undefined) {
            return;
        }
        const manualObserve = manualOrderDataRequest(apiKey, param, queryOpen, repeat);
        const slProObserve = querySLProDataRequest(apiKey, slProParam);
        const listener = PubSub.subscribe(NOTIFY_CUSTOM_ORDER_CREATE, () => {
            zip(manualObserve, slProObserve).subscribe(
                (result) => {
                    let manualList = result[0];
                    let SLList = result[1].orders;
                    setData(manualList.concat(SLList));
                    setSlProListNextPageToken(result[1].nextPageToken);
                },
                (error) => {
                    console.log(error);
                },
            );
        });
        return () => {
            if (listener) {
                listener.remove();
            }
        };
    }, [apiKey]);
    return [data, slProListNextPageToken];
}

export function manualOrderTransform(data: Array<ExchangeOrder>, dateFormatter: DateFormatter, orderStatus: OrderTabType): Array<ManualOrderTableData> {
    const list: Array<ManualOrderTableData> = [];
    data.forEach((item) => {
        const innerOrder = TradeUtils.getInnerOrder(item);
        if (innerOrder) {
            let operate;
            if (innerOrder.side === "buy") {
                operate = $st("grid_transaction_buy") + "(" + OrderUtils.getOrderDesc(item.orderType) + ")";
            } else {
                operate = $st("grid_transaction_sell") + "(" + OrderUtils.getOrderDesc(item.orderType) + ")";
            }
            let filledStr = "";
            let feeStr = "";
            if (item.state === ExchangeOrderStatus.canceled) {
                filledStr = "0 (" + $st("order_state_canceled") + ")";
                feeStr = "--";
            } else {
                if (innerOrder.amount !== 0) {
                    filledStr = `${innerOrder.filled_amount} (${NumberCommonUtils.toPercent((innerOrder.filled_amount / innerOrder.amount) * 100, false)})`;
                } else {
                    filledStr = `${innerOrder.filled_amount}`;
                }

                feeStr = `${NumberUtils.effectiveDecimal(innerOrder.fee, 8)} ${innerOrder.feeCoin === item.base ? item.baseDisplay : innerOrder.feeCoin}`;
            }
            const isRunningSLPro = item.isRunning && item.orderType === ExchangeOrderType.SL_Pro;

            let temptimeStampStr = "";
            if (orderStatus === OrderTabType.running) {
                temptimeStampStr = dateFormatter.month_minute(item.timestamp);
            } else {
                if (item.lastTradeTime) {
                    temptimeStampStr = dateFormatter.month_minute(item.lastTradeTime);
                } else {
                    temptimeStampStr = "--";
                }
            }
            const pair = {
                base: item.base,
                quote: item.quote,
                exchange: item.exchange ? item.exchange : ExchangeID.PIONEXV2,
            } as ExchangeTradePair;
            //过滤掉限价止盈止损
            //TODO 使用新的数据接口 src/landings/PionexHistoricalOrderDetails/components-v2/ManualOrders/hooks/useFetchManualOrders.tsx
            item.orderType !== ExchangeOrderType.SL_Pro &&
                list.push({
                    id: item.id,
                    operation: operate,
                    symbol: `${item.baseDisplay}/${item.quote.toUpperCase()}`,
                    price: isRunningSLPro ? "" : innerOrder.price !== 0 ? NumberUtils.toPrice(innerOrder.price, pair) : "--",
                    quantity: isRunningSLPro ? "" : innerOrder.amount !== 0 ? innerOrder.amount : "--",
                    avgPrice: isRunningSLPro ? "" : innerOrder.avgDealPrice ? NumberUtils.toPrice(innerOrder.avgDealPrice, pair) : "--",
                    filled: isRunningSLPro ? "" : filledStr,
                    fee: feeStr,
                    feeCoin: innerOrder.feeCoin === item.base ? item.baseDisplay : innerOrder.feeCoin,
                    originalData: item,
                    timeStamp: temptimeStampStr,
                });
        }
    });
    return list;
}
