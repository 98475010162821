import React, { createContext, FC, useContext, useEffect, useRef, useState } from "react";
import throttle from "lodash/throttle";
// 排除prerender模式下的匹配工作。
const __isPrerenderMode = window.__PRERENDER_INJECTED?.$$isRenderServer;
const ua = window.navigator.userAgent;
const isDeviceAndroid = __isPrerenderMode ? false : /android/i.test(ua);
const isDeviceIPhone = __isPrerenderMode ? false : /iphone/i.test(ua);
const isDeviceIPad = __isPrerenderMode ? false : /ipad/i.test(ua);
const isDeviceWeChat = __isPrerenderMode ? false : /micromessenger/i.test(ua);
export const isDeviceMobile = __isPrerenderMode ? false : /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|mobile/i.test(ua);
const isDeviceDesktop = !isDeviceAndroid && !isDeviceIPhone && !isDeviceIPad && !isDeviceMobile;
const isPlatformBaidu = __isPrerenderMode ? false : /baidu/i.test(ua);
const isOpenInWebview = !!window.ReactNativeWebView;

const calcMedia = () => {
    const screenWidth: number = document.documentElement.clientWidth;
    // mobile
    const isXS = screenWidth < 576,
        // tablet[pad]
        isSM = screenWidth < 768,
        isSMrange = screenWidth >= 576 && isSM,
        // desktop mini
        isMD = screenWidth < 992,
        isMDrange = screenWidth >= 768 && isMD,
        // desktop wide
        isLG = screenWidth < 1200,
        isLGrange = screenWidth >= 992 && isLG,
        // desktop hd
        isXL = screenWidth < 1600,
        isXLrange = screenWidth >= 1200 && isXL,
        // desktop large
        isXXL = screenWidth >= 1600;
    return {
        ua,
        // 被识别的设备
        isDeviceAndroid,
        isDeviceIPhone,
        isDeviceMobile,
        isDeviceIPad,
        isDeviceDesktop,
        isDeviceWeChat,
        // 平台识别
        isPlatformBaidu,
        // 屏幕宽度
        screenWidth,
        // 各类屏幕宽度
        isXS,
        isMobile: isXS,
        isSM,
        isSMrange,
        isTablet: isSM,
        isTabletRange: isSMrange,
        isMD,
        isMDrange,
        isDesktopMini: isMD,
        isDesktopMiniRange: isMDrange,
        isLG,
        isLGrange,
        isDesktopWide: isLG,
        isDesktopWideRange: isLGrange,
        isXL,
        isXLrange,
        isDesktopHD: isXL,
        isDesktopHDRange: isXLrange,
        isXXL,
        isDesktopLarge: isXXL,
        isOpenInWebview,
    };
};

export const onWindowResize = (call: (_media: ReturnType<typeof calcMedia>) => void) => {
    const checkWindowWidth = () => {
        call(calcMedia());
    };
    window.addEventListener("resize", checkWindowWidth, false);
    return () => window.removeEventListener("resize", checkWindowWidth);
};

export const CTXMedia = createContext(calcMedia());

export const MediaProvider: FC = ({ children }) => {
    const [media, setMedia] = useState(calcMedia);
    useEffect(() => {
        if (__isPrerenderMode) return;
        const throttleChecker = throttle(setMedia, 300, { trailing: true, leading: true });
        return onWindowResize(throttleChecker);
    }, []);
    return <CTXMedia.Provider value={media}>{children}</CTXMedia.Provider>;
};

/**
 * 获取响应式设备数据
 * @returns {ReturnType<calcMedia>}
 */
export const useMedia = ({ onResize }: { onResize?(_media: ReturnType<typeof calcMedia>): void } = {}) => {
    useEffect(() => {
        if (__isPrerenderMode) return;
        return onResize && onWindowResize(onResize);
    }, [onResize]);
    return useContext(CTXMedia);
};

export default useMedia;
