import React, { PropsWithChildren, useContext } from "react";
import { QuotaDirection } from "utils/complianceQuota";
import { TSpin } from "components/v2/TinyComps";
import { useTranslators } from "commonUse/Locale";
import { DWChannelStatus, DWPayMethod } from "landings/V2/USDDW/types";
import cs from "./index.m.less";
import { USDDWContext } from "landings/V2/USDDW/context";
import { getPayMethodStatus } from "landings/V2/USDDW/utils";

interface Props {
    payMethod: DWPayMethod;
    direction: QuotaDirection;
}

/**
 * 展示通道内容维护中的包裹组件
 */
const Maintenance: React.FC<PropsWithChildren<Props>> = ({ children, payMethod, direction }) => {
    const { $st } = useTranslators();
    const { channelStatusInfo } = useContext(USDDWContext);

    // const channelStatus = useChannelStatus(channel);

    if (!channelStatusInfo || getPayMethodStatus(payMethod, channelStatusInfo, direction) === DWChannelStatus.OPEN) {
        // 未获取到数据 || 通道状态正常
        return <>{children}</>;
    }
    return <TSpin className={cs.tipsSpin} spinning indicator={<div />} tip={$st("usddw_channel_maintenance")} />;
};

export default Maintenance;

/**
 * 状态蒙层
 */
export function withMaintenance<P extends object>(Component: React.ComponentType<P>, config: Props) {
    const Wrapper: React.FC<P> = (props) => {
        return (
            <Maintenance {...config}>
                <Component {...props} />
            </Maintenance>
        );
    };

    return Wrapper;
}
