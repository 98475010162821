import { SignatureBankCard } from "landings/V2/SignatureDW/types";
import { BankType, BankStatus } from "compliance_lib";

export function toBankCardInfo(item: any): SignatureBankCard {
    let tempBankNumber = "";
    let tempBankCode = "";
    let tempBankType = BankType.SWIFT;
    if (item.bank_account_iban.length > 0) {
        tempBankNumber = item.bank_account_iban;
        tempBankCode = item.bank_account_iban;
        tempBankType = BankType.IBAN;
    }
    if (item.bank_account_other.account_number.length > 0) {
        tempBankNumber = item.bank_account_other.account_number;
        tempBankCode = item.bank_account_other.swift_bic;
        tempBankType = BankType.SWIFT;
    }
    if (item.bank_account_us.account_number.length > 0) {
        tempBankNumber = item.bank_account_us.account_number;
        tempBankCode = item.bank_account_us.routing_number;
        tempBankType = BankType.ABA;
    }
    let tempBankName = item.bank_address.bank_name;
    if (item.bank_address.line1.length > 0) {
        tempBankName += item.bank_address.line1;
    }
    if (item.bank_address.line2.length > 0) {
        tempBankName += item.bank_address.line2;
    }
    return {
        bankId: item.bank_id,
        bankNumber: tempBankNumber,
        bankName: tempBankName,
        bankType: tempBankType,
        transferType: item.transfer_type,
        bankCode: tempBankCode,
        bankStatus: BankStatus.approved,
        reason: item.reason,
        trackingRef: item.tracking_ref,
        bankActivated: item.bank_activated,
        billingDetails: {
            name: item.billing_details.name,
            city: item.billing_details.city,
            country: item.billing_details.country,
            line1: item.billing_details.line1,
            line2: item.billing_details.line2,
            district: item.billing_details.district,
            postalCode: item.billing_details.postal_code,
        },
        bankAddress: {
            bankName: item.bank_address.bank_name,
            city: item.bank_address.city,
            country: item.bank_address.country,
            line1: item.bank_address.line1,
            line2: item.bank_address.line2,
            district: item.bank_address.district,
        },
    };
}
