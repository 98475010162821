import { localeData } from "commonActions/reducers";
import { connectRouter, routerMiddleware } from "connected-react-router";
import errorMiddleware from "middlewares/error";
import { epicMiddleWare, rootEpic } from "middlewares/Observable";
import promiseMiddleware from "middlewares/promise";
import thunkMiddleware from "middlewares/thunk";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { accountInfoReducer } from "state/AccountInfo";
import BalanceCfg from "state/BalanceCfg";
import { dateFormatterReducer } from "state/DateFormatter";
import ExchangeRate from "state/ExchangeRate";
import { themeReducer } from "state/ThemeData";
import { OnlineConfig } from "utils/onlineConfig";
import { HelpConfigRedux } from "state/HelpConfig";
import { WebConfig } from "utils/webConfig";
import { SwapConfig } from "utils/swapConfig";
import { LangHistory } from "src/utils/History";
import { sentryMiddleware } from "utils/SentryUtils";
import { orderShareReducer } from "state/OrderShare";
import { ReOrderDataReducer } from "state/ReOrderData";
import { CopyBotDataReducer } from "state/CopyBotData";
import { TradeSelectedInfoReducer } from "state/TradeSelectedInfo";

const middlewareList = [epicMiddleWare, thunkMiddleware, promiseMiddleware, errorMiddleware, sentryMiddleware];

const appReducers = {
    localeData: localeData,
    accountInfo: accountInfoReducer,
    dateFormatter: dateFormatterReducer,
    exchangeRate: ExchangeRate.reducer,
    balanceCfg: BalanceCfg.reducer,
    theme: themeReducer,
    onlineConfig: OnlineConfig.reducer,
    helpConfig: HelpConfigRedux.reducer,
    webConfig: WebConfig.reducer,
    swapConfig: SwapConfig.reducer,
    orderShare: orderShareReducer,
    reOrderData: ReOrderDataReducer,
    copyBotData: CopyBotDataReducer,
    tradeSelectedInfo: TradeSelectedInfoReducer,
};

export default (history: LangHistory) => {
    const store = createStore(combineReducers({ router: connectRouter(history), ...appReducers }), compose(applyMiddleware(routerMiddleware(history), ...middlewareList)));

    epicMiddleWare.run(rootEpic);
    return store;
};
