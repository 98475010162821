import Breadcrumbs from "./Breadcrumbs";
import BotOrderCards from "./BotOrderCards";

export default function CloseBotOrder() {
    return (
        <div className=" w-full flex flex-col">
            <Breadcrumbs />
            <BotOrderCards />
        </div>
    );
}
