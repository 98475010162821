import { acquireKeyResolver } from "trade_token_lib";
import Constants from "utils/Constants";
import { ObservableUtils } from "trade_utils_lib";
export type getRecordParams = {
    exchange: any;
    key_id: any;
};

export type RecordResult = {
    export_record: {
        create_time: number;
        expire_time: number;
        generate_time: number;
        path: string;
        status: 1 | 2 | 3;
    } | null;
    remain_num: number;
};

export interface TransactionApplyParams {
    start_time: number;
    end_time: number;
}

export default class TaxesAPI {
    static getRecord(): Promise<RecordResult> {
        const _url = `${Constants.balanceApiHost}/export_transaction/record/`;
        return ObservableUtils.getV2<RecordResult>(_url, {}, null, true).toPromise();
    }
    static postRecord(data: TransactionApplyParams): Promise<RecordResult> {
        const _url = `${Constants.balanceApiHost}/export_transaction/apply/`;
        return ObservableUtils.postV2<RecordResult>(_url, data, null, true).toPromise();
    }
}
