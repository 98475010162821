import { LoadingOutlined } from "@ant-design/icons";
import DataSet from "@antv/data-set";
import { List, Spin } from "antd";
import { Axis, Chart, Coord, Geom, Guide, Label, Tooltip } from "bizcharts";
import CustomAntdEmpty from "components/Antd/CustomEmpty";
import React from "react";
import ThemeData from "state/ThemeData";
import NumberUtils from "utils/NumberUtils";
import cs from "./balancePieView.module.less";
import { TSpin } from "src/components/v2/TinyComps";

const { Html } = Guide;
const { DataView } = DataSet;

export type PieChartData = {
    item: string;
    count: number;
    colorStartValue: string;
    colorEndValue: string;
    itemPercent: number;
};

export interface BalancePieViewProps {
    dataSource: Array<PieChartData>;
    totalFiat: number;
    isLoading: boolean;
    theme: ThemeData;
    height?: number;
    width?: number;
    showLabel?: boolean;
    noPadding?: boolean;
    pieType: "Coins" | "Bots";
}

export class BalancePieView extends React.Component<BalancePieViewProps> {
    dv = new DataView();
    renderItem = (item) => {
        return (
            <div className={cs.pieListItemView}>
                <span className={cs.pieListItemNameView}>
                    <span className={cs.pieListItemNameImgView} style={{ background: item.colorEndValue }} />
                    <span style={{ fontSize: 14 }}>{item.item}</span>
                </span>
                <span className={cs.pieListItemValueView}>{`${NumberUtils.toPercentNum(NumberUtils.effectiveDecimal(item.itemPercent, 2))} %`}</span>
            </div>
        );
    };
    render() {
        const { showLabel = true, noPadding = false, dataSource, isLoading } = this.props;
        const { dv } = this;
        // 需要过滤到为0的类型
        const showData = dataSource.filter((data) => data.itemPercent !== 0);
        const colorsData = showData.map((item) => {
            return item.colorEndValue;
        });
        dv.source(showData).transform({
            type: "percent",
            field: "count",
            dimension: "item",
            as: "percent",
        });
        const cols = {
            percent: {
                formatter: (val) => {
                    val = val * 100 + "%";
                    return val;
                },
            },
        };

        return (
            <div className={cs.pieView}>
                <div className={cs.loadingView}>{isLoading && <TSpin />}</div>
                {!noPadding && <div className={cs.pieLeftNoneView} />}
                <Chart height={this.props.height || 136} data={dv} width={this.props.width || 136} scale={cols} forceFit padding="auto">
                    <Coord type={"theta"} innerRadius={0.75} />
                    {/*<Axis name="percent"/>*/}
                    <Tooltip showTitle={false} itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>' />
                    <Geom
                        type="intervalStack"
                        position="percent"
                        color={["item", colorsData]}
                        shape="sliceShape"
                        tooltip={[
                            "item*itemPercent",
                            (item, itemPercent) => {
                                itemPercent = NumberUtils.toPercentNum(NumberUtils.effectiveDecimal(itemPercent, 2)) + "%";
                                return {
                                    name: item,
                                    value: itemPercent,
                                };
                            },
                        ]}
                        size={25}
                    >
                        {showLabel && (
                            <Label
                                content="percent"
                                formatter={(val, item) => {
                                    let value = NumberUtils.toPercentNum(NumberUtils.effectiveDecimal(item.point.itemPercent, 2));
                                    return value + "%";
                                }}
                                textStyle={{
                                    fill: this.props.theme.colorTextAccent,
                                    fontVariant: "small-caps",
                                }}
                                offset={25}
                                labelLine={{}}
                            />
                        )}
                    </Geom>
                </Chart>
                <div className={cs.pieListView}>
                    <div style={{ flex: 1 }}>
                        <List
                            // loading={this.props.isLoading}
                            loading={false}
                            style={{ marginLeft: 10, marginRight: 10 }}
                            dataSource={showData}
                            grid={{ gutter: 16, column: 1 }}
                            renderItem={this.renderItem}
                            locale={{
                                emptyText: <CustomAntdEmpty loading={this.props.isLoading} dataLength={this.props.dataSource.length} />,
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
