import { Modal } from "@pionex-web-kit/components";
import { useTranslators } from "commonUse/Locale";
import { useBalanceAll } from "commonUse/useBalance";
import useKycV2Info from "commonUse/useKycInfoV2";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SafeDecimal } from "trade_utils_lib";
import { history } from "src/landings";
import { useLocation } from "react-router";
import { LocalStorageKey } from "utils/LocalStorageUtils";
import { report } from "utils/GoogleTagManager";

export function DepositModal({ visible, onCancel }: { visible?: boolean; onCancel: () => void }) {
    const { $st } = useTranslators();

    return (
        <Modal
            visible={visible}
            title={$st("identity_verification_completed")}
            okText={$st("balance_coin_no_data_button_title")}
            onOk={() => {
                report("pop_up_kyc_done_deposit");
                history.push("/newbie-rewards");
            }}
            onCancel={onCancel}
        >
            <div>
                {$st("deposit_trade_now")}
                <a
                    onClick={() => {
                        report("pop_up_kyc_done_learn_more");
                    }}
                    className=" text-primary hover:text-primary"
                    href="/newbie-rewards"
                >
                    {$st("home_act_btn")}
                </a>
            </div>
        </Modal>
    );
}

export function DepositGuide() {
    const [{ currentLevel }, loading, initialized] = useKycV2Info();
    const isKyc = new SafeDecimal(currentLevel).greaterThanOrEqualTo(2);
    const fullBalance = useBalanceAll();
    const haveBalance = useMemo(() => new SafeDecimal(fullBalance?.total.totalInUSD).greaterThan(0), [fullBalance]);
    const [depositModalVisible, setDepositModalVisible] = useState<boolean>();
    const { pathname } = useLocation();
    const isExcludePage = useMemo(() => {
        return ["/newbie-rewards"].includes(pathname);
    }, [pathname]);
    const closeDepositModal = useCallback(() => {
        setDepositModalVisible(false);
    }, []);

    useEffect(() => {
        const timeStr = new SafeDecimal(localStorage.getItem(LocalStorageKey.IDENTITY_VERIFICATION_COMPLETED)).toNumber();
        if (loading === false && initialized && isKyc && !haveBalance && !isExcludePage && (!timeStr || (timeStr && timeStr + 30 * 24 * 60 * 60 * 1000 < Date.now()))) {
            localStorage.setItem(LocalStorageKey.IDENTITY_VERIFICATION_COMPLETED, `${Date.now()}`);
            setDepositModalVisible(true);
        } else {
            setDepositModalVisible(undefined);
        }
    }, [isKyc, haveBalance, isExcludePage, loading, initialized]);

    return <>{depositModalVisible !== undefined && <DepositModal visible={depositModalVisible} onCancel={closeDepositModal} />}</>;
}
