import { ST } from "commonUse/Locale";
import { CountryInfoV2, CountryListForLite } from "landings/V2/CircleDW/BankCard/constants";
import { useOnlineConfig } from "utils/onlineConfig";
import { WithdrawError } from "utils/errorCode";

const WITHDRAW_ERROR_KEY: Record<number, string> = {
    "-90": "withdraw_error_cause_modify_password_after_24h",
    ///> -3 谷歌二次验证错误
    "-3": "withdraw_code_verify_failed_google",
    ///> -8 还未发送验证码
    "-8": "withdraw_code_not_exist",
    ///> -9 短信或者邮件验证码错误
    "-9": "withdraw_invalid_code",
    ///> -10 验证码过期
    "-10": "withdraw_code_not_exist",
    //TODO
    40020300: "signature_dw_error_parameter_error",
    40021102: "signature_dw_error_balance_not_enough",
};

function getWithdrawError($st: ST, code: number, msg: string): string {
    ///> 验证码错误
    const codeErrorKey = WITHDRAW_ERROR_KEY[code];
    if (!!codeErrorKey) {
        return $st(codeErrorKey);
    }

    if (!msg) {
        return $st("grid_err_error_manual");
    }

    return WithdrawError.parseErrorMsg(msg);
}

/**
 * FBO的高危地区
 */
const SIGNATURE_FORBIN_CODES = [
    "AF",
    "AL",
    "BB",
    "BY",
    "BJ",
    "BO",
    "BA",
    "BW",
    "BR",
    "BF",
    "BI",
    "KH",
    "CV",
    "CF",
    "TD",
    "CO",
    "KM",
    "CG",
    "CD",
    "HR",
    "CU",
    "DO",
    "GQ",
    "ER",
    "GA",
    "GH",
    "GT",
    "GN",
    "GW",
    "HT",
    "IS",
    "ID",
    "IR",
    "IQ",
    "JM",
    "KE",
    "KP",
    "LB",
    "LS",
    "LY",
    "MK",
    "MG",
    "MV",
    "ML",
    "MT",
    "MR",
    "MU",
    "MX",
    "MN",
    "ME",
    "MA",
    "MZ",
    "MM",
    "NP",
    "NI",
    "NE",
    "NG",
    "PK",
    "PS",
    "PA",
    "PG",
    "PY",
    "PH",
    "RU",
    "SA",
    "SN",
    "RS",
    "SL",
    "SO",
    "SS",
    "SD",
    "SY",
    "TG",
    "TR",
    "TM",
    "UG",
    "UA",
    "VE",
    "VN",
    "YE",
    "ZW",
    "LA",
    "CW",
];

const FORBID_CODE_BLACK_LIST2 = ["CN", "HK", "TW"];

/**
 * 剔除高危地区
 */
export function getFBOSupportCountries(): CountryInfoV2[] {
    return CountryListForLite.filter((item) => {
        return !SIGNATURE_FORBIN_CODES.includes(item.code) && !FORBID_CODE_BLACK_LIST2.includes(item.code);
    });
}

/**
 * Signature出金配置
 */
export function useWithdrawConfig() {
    const { dw } = useOnlineConfig();
    return {
        bankName: dw?.w?.wire?.bankName,
        feeRules: dw?.w?.wire?.feeRules,
        fee: dw?.w?.wire?.fee ?? 9.99,
        min: dw?.w?.wire?.min || 50,
        limitTip: dw?.w?.wire?.limit?.tip ?? "Daily Withdraw Limit - $500,000.",
    };
}

/**
 * ACH 出金相关配置
 */
export const useACHWithdrawConfig = () => {
    const { dw } = useOnlineConfig();
    return {
        min: dw?.w?.ach?.limit?.min || 2,
        max: dw?.w?.ach?.limit?.max || Number.MAX_VALUE,
        feeRules: dw?.w?.ach?.feeRules,
        free: dw?.w?.ach?.free,
    };
};
