import * as React from "react";
import {
    FormatDateOptions,
    FormatNumberOptions,
    FormatPluralOptions,
    FormatRelativeTimeOptions,
    FormattedDate,
    FormattedMessage,
    FormattedNumber,
    FormattedTime,
    IntlShape,
    MessageDescriptor,
    useIntl,
} from "react-intl";

// ID部分可传配置对象
export type IDObj = MessageDescriptor;

export interface InterTranslate {
    intl: any;
    $t(id: string | IDObj, values?: object | false): string;
    // $T(id: string, values?: object | false): string;
    $T(id: string | IDObj, values?: { [key: string]: any }): JSX.Element;
    $td(value: any, options?: object | false): string;
    $tt(value: any, options?: object | false): string;
    $tr(value: any, options?: object | false): string;
    $tn(value: any, options?: object | false): string;
    $tp(value: any, options?: object | false): string;
}

let intlRef: IntlShape;

// 全局快捷国际化
export function $T(id: string | IDObj, values?: { [key: string]: any }): JSX.Element {
    return <FormattedMessage {...(typeof id === "string" ? { id } : id)} values={values} />;
}

export function $st(id: string | IDObj, values?: { [key: string]: any }): string {
    return intlRef.formatMessage(typeof id === "string" ? { id } : id, values);
}

export function $td(value: any, options?: FormatDateOptions): JSX.Element {
    return <FormattedDate value={value} {...(options || {})} />;
}

export function $std(value: any, options?: FormatDateOptions): string {
    return intlRef.formatDate(value, options);
}

export function $tt(value: any, options?: FormatDateOptions): JSX.Element {
    return <FormattedTime value={value} {...(options || {})} />;
}

export function $stt(value: any, options?: FormatDateOptions): string {
    return intlRef.formatTime(value, options);
}

export function $str(value: any, unit?: Parameters<typeof intlRef.formatRelativeTime>[1], options?: FormatRelativeTimeOptions): string {
    return intlRef.formatRelativeTime(value, unit, options);
}

const wrapOption = (value: Parameters<Intl.NumberFormat["format"]>[0], option: FormatNumberOptions) => {
    // 处理最大有效小数位数
    if (value >= 100) {
        option.maximumFractionDigits = 2;
        option.minimumFractionDigits = 2;
    } else if (value >= 1) {
        option.maximumFractionDigits = 4;
        option.minimumFractionDigits = 4;
    } else {
        option.maximumSignificantDigits = 4;
    }
    return option;
};

export function $tn(value: Parameters<Intl.NumberFormat["format"]>[0], option: FormatNumberOptions = {}): JSX.Element {
    return <FormattedNumber value={value as number} {...wrapOption(value, option)} />;
}

export function $stn(value: Parameters<Intl.NumberFormat["format"]>[0], option: FormatNumberOptions = {}): string {
    return intlRef.formatNumber(value, wrapOption(value, option));
}

export function $stp(value: any, options?: FormatPluralOptions): string {
    return intlRef.formatPlural(value, options);
}

export function I18n(props: any) {
    intlRef = useIntl();
    return props.children;
}
