import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import { $st } from "utils/i18n";
import css from "../../index.module.less";

interface Props {
    onCommit: () => void;
    setVisible: (value: boolean) => void;
    submitting: boolean;
    disable: boolean;
}

const FSAModalFooter = (props: Props) => {
    const { onCommit, setVisible, submitting, disable } = props;
    return (
        <div className={css.modalFooter}>
            <div style={{ marginRight: 12 }} className={`${css.commonButton}`} onClick={() => setVisible(false)}>
                {$st("button_cancel")}
            </div>
            <div
                onClick={() => {
                    if (!submitting) {
                        onCommit();
                    }
                }}
                className={`${css.commonButton} ${css.modalConfirm} ${disable ? css.disableButton : ""}`}
            >
                {submitting ? <LoadingOutlined style={{ fontSize: 16 }} spin={true} /> : $st("button_confirm")}
            </div>
        </div>
    );
};
export default FSAModalFooter;
