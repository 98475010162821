import ExchangeDataProvider from "TradeLib/ExchangeDataProvider";
import { BotTypeBalanceInfo, ExchangeOrderType } from "TradeAPILib";
import { Observable, of } from "rxjs";
import { getTradeDependencyInstance, ObservableUtils, SafeDecimal } from "trade_utils_lib";
import { catchError, flatMap } from "rxjs/operators";

/**
 * TODO remove
 * @deprecated
 */
export namespace BotBalanceTemp {
    function shouldCheckStrategyOrder(exchangeDataProvider: ExchangeDataProvider): boolean {
        return (
            exchangeDataProvider.api.checkMarketSupportOrderType(ExchangeOrderType.grid) ||
            exchangeDataProvider.api.checkMarketSupportOrderType(ExchangeOrderType.gridPro) ||
            exchangeDataProvider.api.checkMarketSupportOrderType(ExchangeOrderType.gridV3) ||
            exchangeDataProvider.api.checkMarketSupportOrderType(ExchangeOrderType.gridV4) ||
            exchangeDataProvider.api.checkMarketSupportOrderType(ExchangeOrderType.gridClassic) ||
            exchangeDataProvider.api.checkMarketSupportOrderType(ExchangeOrderType.ig) ||
            exchangeDataProvider.api.checkMarketSupportOrderType(ExchangeOrderType.aip) ||
            exchangeDataProvider.api.checkMarketSupportOrderType(ExchangeOrderType.twap) ||
            exchangeDataProvider.api.checkMarketSupportOrderType(ExchangeOrderType.gridLoan) ||
            exchangeDataProvider.api.checkMarketSupportOrderType(ExchangeOrderType.gridLeverage) ||
            exchangeDataProvider.api.checkMarketSupportOrderType(ExchangeOrderType.gridLeverageShort) ||
            exchangeDataProvider.api.checkMarketSupportOrderType(ExchangeOrderType.gridFuture) ||
            exchangeDataProvider.api.checkMarketSupportOrderType(ExchangeOrderType.stopLimit) ||
            exchangeDataProvider.api.checkMarketSupportOrderType(ExchangeOrderType.trailingStop)
        );
    }

    /**
     *
     * @link TradeAPILib/src/TradingBot/BaseBotApiAdapter.ts/getBotTypeBalance
     * TODO remove 为了尽快解决bug，但是不升级lib引起其他问题。 后续会使用新的饼图结构直接删除这里的逻辑
     * 增加了gridV4的支持
     */
    export function getBotTypeBalance(exchangeDataProvider: ExchangeDataProvider): Observable<BotTypeBalanceInfo> {
        return getTradeDependencyInstance()
            .getAccessToken()
            .pipe(
                flatMap((token) => {
                    if (!token || !shouldCheckStrategyOrder(exchangeDataProvider)) {
                        return of(undefined);
                    }
                    let url = ObservableUtils.buildUrlWithCommonParams(
                        `${getTradeDependencyInstance().getRunTimeHost().TRADE_HOST}/order/order_balance`,
                        getTradeDependencyInstance().getRunTimeHost()["commonParam"],
                    );
                    let headers = {
                        Authorization: token.toString(),
                    };
                    return ObservableUtils.createGetRequestObservable(url, headers, (resp) => {
                        let botTypeBalance: BotTypeBalanceInfo;

                        const tempGridV1 = resp.data.grid ? new SafeDecimal(resp.data.grid) : undefined;
                        const tempGridClassic = resp.data.grid_classic ? new SafeDecimal(resp.data.grid_classic) : undefined;
                        const tempGridPro = resp.data.grid_pro ? new SafeDecimal(resp.data.grid_pro) : undefined;
                        const tempGridV3 = resp.data.grid_v3 ? new SafeDecimal(resp.data.grid_v3) : undefined;
                        const tempGridV4 = resp.data.grid_v4 ? new SafeDecimal(resp.data.grid_v4) : undefined;

                        let tempGridV2 = new SafeDecimal(0);

                        if (tempGridV1 !== undefined) {
                            tempGridV2 = tempGridV2.add(tempGridV1);
                        }
                        if (tempGridClassic !== undefined) {
                            tempGridV2 = tempGridV2.add(tempGridClassic);
                        }
                        if (tempGridPro !== undefined) {
                            tempGridV2 = tempGridV2.add(tempGridPro);
                        }
                        if (tempGridV3 !== undefined) {
                            tempGridV2 = tempGridV2.add(tempGridV3);
                        }
                        if (tempGridV4 !== undefined) {
                            tempGridV2 = tempGridV2.add(tempGridV4);
                        }

                        botTypeBalance = {
                            ig: resp.data.ig ? resp.data.ig : undefined,
                            twap: resp.data.twap ? resp.data.twap : undefined,
                            aip: resp.data.aip ? resp.data.aip : undefined,
                            trailing_stop: resp.data.trailing_stop ? resp.data.trailing_stop : undefined,
                            grid_classic: resp.data.grid_classic ? resp.data.grid_classic : undefined,
                            grid_pro: resp.data.grid_pro ? resp.data.grid_pro : undefined,
                            grid_V1: resp.data.grid ? resp.data.grid : undefined,
                            grid_loan: resp.data.loan_grid ? resp.data.loan_grid : undefined,
                            grid_short: resp.data.short_grid ? resp.data.short_grid : undefined,
                            grid_leverage: resp.data.leverage_grid ? resp.data.leverage_grid : undefined,
                            grid: tempGridV2.toString() === "0" ? undefined : tempGridV2.toString(),
                            grid_leverage_short: resp.data.short_leverage_grid ? resp.data.short_leverage_grid : undefined,
                            smart_trade: resp.data.smart_trade ? resp.data.smart_trade : undefined,
                            smart_rebalance: resp.data.smart_rebalance ? resp.data.smart_rebalance : undefined,
                            martingale: resp?.data?.martingale ?? undefined,
                        };

                        return botTypeBalance;
                    });
                }),
            )
            .pipe(
                catchError(() => {
                    return of(undefined);
                }),
            );
    }
}
