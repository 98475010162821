/*
 * @author Zhoukai
 * @date 2020/6/24
 * @time 5:32 下午
 * @fileName TradeConstant.ts
 * @Description: 交易常量
 */
import { MoonBotKey } from "@pionex-web-kit/trading-components";
import React, { ReactNode } from "react";
import { ExchangeOrder, ExchangeSymbol, ExchangeOrderType, TradeAssetsInfo } from "TradeAPILib";

export enum OrderTabType {
    running = "running",
    finished = "finished",
}

export enum ListPagingType {
    infiniteScroll = "infiniteScroll",
    pagination = "pagination",
}

export const PAGE_SIZE = 10;
export const PAGE_NUM = 0;

export interface TradingBotOrderTableData {
    bot: string;
    symbol: string;
    investment: string;
    profit: number; //用于红涨绿跌设置
    profitText: string; //真正展示利润值的文本
    id: string;
    timestamp: number;
    originalData: ExchangeOrder;
    todayProfit: number;
    todayProfitText: string;
    orderStatus: string;
}

//FIXME 避免升级lib引起其他问题
export const TEMP_ORDER_STATUS_PAUSED = "paused" as any;

export interface PageNationData {
    current: number;
    pageSize: number;
    pageSizeOptions: Array<string>;
    showQuickJumper: boolean;
    showSizeChanger: boolean;
    total: number;
    hideOnSinglePage?: boolean;
    itemRender?: (page, type: "page" | "prev" | "next", originalElement) => React.ReactNode;
}

export interface ManualOrderTableData {
    id: string;
    operation: string;
    symbol: string;
    price: string;
    quantity: number;
    avgPrice: string;
    filled: string;
    fee: string;
    feeCoin: string;
    timeStamp: string;
    originalData: ExchangeOrder;
}

export interface BotTableOrderInfo {
    orderData: Array<ExchangeOrder>;
    orderDataCount: number;
    orderAssets: TradeAssetsInfo;
}

export interface OrderClassifyType {
    running: {
        all: ExchangeOrder[];
        current: ExchangeOrder[];
    };
    finished: {
        all: ExchangeOrder[];
        current: ExchangeOrder[];
    };
}

export enum SummaryTabNames {
    "asset" = "asset",
    "placed" = "placed",
    "parameter" = "parameter",
    "details" = "details",
    "profitRelease" = "profitRelease",
    "margin" = "margin",
    "girdChartV7" = "girdChartV7",
    "girdChartV30" = "girdChartV30",
    "balanceChange" = "balanceChange",
}

export interface OrderSummaryTabData {
    name: SummaryTabNames;
    title: string;
    tabComponent: ReactNode;
    disabled: boolean;
}

export interface FSAOrderActionParams {
    type: FSAOrderAction;
    order: ExchangeOrder;
    spotSymbol?: ExchangeSymbol;
    swapSymbol?: ExchangeSymbol;
}

export enum FSAOrderAction {
    adjustDifferential = "adjustDifferential",
    addPosition = "addPosition",
    reducePosition = "reducePosition",
    profitRelease = "profitRelease",
    adjustMargin = "adjustMargin",
    closePosition = "closePosition",
}

export enum FSAOrderGapTip {
    reducePosition = "reducePosition",
    closePosition = "closePosition",
}


export enum OrderAction {
    addPosition = "addPosition",
    reducePosition = "reducePosition",
}

export interface OrderActionParams {
    type: OrderAction;
    order?: ExchangeOrder;
    spotSymbol?: ExchangeSymbol;
    swapSymbol?: ExchangeSymbol;
}

export const regPos = /^[+-]?\d*\.?\d*$/; //判断是否是数字。
export const MIN_PROFIT_WITHDRAWN_VALUE = 0.00000001;
export type OrderTypeDisplay = ExchangeOrderType | MoonBotKey | "smart_rebalance_double_coins" | "smart_rebalance_multi_coins" | "stopLimit";
