import { Card as ACard } from "antd";
import cns from "classnames";
import styles from "./Card.module.less";
import React, { useMemo } from "react";

type ACardProps = Parameters<typeof ACard>[0];

interface Props extends ACardProps {}

export function Card(props: Props) {
    const { className, ...p } = props;
    return <ACard className={cns(styles.feeCard, className)} {...p} />;
}
