import { RcCustomRequestOptions } from "antd/lib/upload/interface";
import { calcMD5, compressFile } from "utils/uploadFile";
import { ObservableUtils } from "trade_utils_lib";
import Constants from "utils/Constants";
import Axios from "axios";

interface GetUploadUrlRes {
    filename: string;
    method?: string;
    uploaded: boolean;
    url?: string;
}

const axiosIns = Axios.create();
export function customUploadRequest({ uploadProps }: { uploadProps: RcCustomRequestOptions }): Promise<{ fileName: string; file: File }> {
    return (uploadProps.file.type === "application/pdf" ? Promise.resolve(uploadProps.file) : Promise.resolve(uploadProps.file))
        .then(calcMD5)
        .then(
            ({ md5, originFile }) =>
                ObservableUtils.post(
                    `${Constants.accountServiceHost}/user/get_bank_cert_upload_url_v2`,
                    {
                        content_type: uploadProps.file.type,
                        file_hash: md5,
                    },
                    null,
                    true,
                )
                    .toPromise()
                    .then((result) => ({
                        result,
                        originFile,
                    })) as Promise<{ result: GetUploadUrlRes; originFile: Blob }>,
        )
        .then(async ({ result: uploadResult, originFile }) => {
            if (uploadResult?.url) {
                // 使用一个新的实例来上传文件，避免受到公共参数配置的干扰。
                await axiosIns.put(uploadResult.url, originFile);
                return { fileName: uploadResult.filename, file: uploadProps.file };
            } else if (uploadResult.uploaded) {
                return { fileName: uploadResult.filename, file: uploadProps.file };
            }
            throw Error("Got no upload url!");
        });
}
